import React from 'react'
import { Container, Row, Card, CardBody, Col } from 'reactstrap'

import ModalHeader from '../../../components/ModalHeader'
import { useResize } from '../../../helpers/hooks'

const RequestContract = () => {
  const isMobile = useResize()

  return (
    <div className='page-content min-vh-100 p-0 m-0'>
      <Container fluid>
        <ModalHeader />

        <Row className='justify-content-center'>
          <Col md={8}>
            <Row
              className={`${
                !isMobile
                  ? 'justify-content-center'
                  : 'justify-content-between align-items-center'
              } p-4`}
            >
              {isMobile ? (
                <h2 className='font-weight-bold'>Full time request</h2>
              ) : (
                <h1 className='font-weight-bold'>Full time request</h1>
              )}
            </Row>
            <Card className={isMobile ? 'step-container-content' : ''}>
              <Col className='p-0'>
                <CardBody className={' p-4'}>
                  <Col className='p-0'>
                    {/* <FullTimeForm onComplete={(v) => send.startFetch(v)} loading={send.isLoading}/> */}
                  </Col>
                </CardBody>
              </Col>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default RequestContract
