import React from 'react'
import { Badge } from 'reactstrap'
import styled from 'styled-components'

import BadgeX from '../../../components/Table/BadgeX'
import Button from '../../../components/ui/button'

export const PERK_STATUS = {
  new: 'new',
  pending: 'pending',
  accepted: 'accepted',
}

export default function PerkCard({
  perk,
  onClickPerk,
  gettingPerk,
  clickedPerk,
}) {
  return (
    <PerkCardShadow className='d-flex flex-column rounded border border-light px-4 py-3'>
      <div className='d-flex justify-content-between align-items-start'>
        {!perk?.product_logo ? (
          <div
            className='bg-light rounded mb-2'
            style={{ width: 80, height: 80, objectFit: 'contain' }}
          />
        ) : (
          <img
            src={perk.product_logo}
            alt=''
            style={{ width: 80, height: 80, objectFit: 'contain' }}
            className='rounded mb-2'
          />
        )}

        {!perk?.product_category ? null : (
          <Badge className='bg-light font-size-14 text-secondary py-1 px-2'>
            {perk.product_category}
          </Badge>
        )}
      </div>
      <h2 className='rp-font-gilroyB text-gray-h'>{perk?.product_name}</h2>
      {!perk?.shortDesc ? null : <p className='text-muted'>{perk.shortDesc}</p>}
      <div
        className='d-flex flex-row flex-wrap mt-2'
        style={{ gap: '0.25rem' }}
      >
        {!perk?.new_deal ? null : <BadgeX status='warning'>New Deal</BadgeX>}
        {!perk?.new_user_only ? null : (
          <BadgeX status='primary'>For new users</BadgeX>
        )}
        {/* <BadgeX status='warning'>Unlimited</BadgeX>
        <BadgeX status='danger'>Popular</BadgeX> */}
      </div>

      <hr className='w-100' />

      {!perk?.description ? null : (
        <p className='rp-font-bold text-gray-h'>{perk.description}</p>
      )}
      {/* <BadgeX className='p-2 mb-3' status='success'>
        Save up to $5.000
      </BadgeX> */}

      {/* <div className='d-flex align-items-center' style={{ gap: '0.5rem' }}>
        <span
          style={{ width: 24, height: 24 }}
          className='bg-soft-success rounded-pill d-flex justify-content-center align-items-center'
        >
          <i className='bx bx-check font-size-14 text-success' />
        </span>
        <p className='mb-0 text-gray-h'>Add credits to your account</p>
      </div> */}

      <div className='mt-auto'>
        <hr className='mx-n4' />
        <ActionAndStatus
          status={perk?.offer_status}
          onClick={() => onClickPerk(perk)}
          loading={gettingPerk && clickedPerk?.id === perk?.id}
        />
      </div>
    </PerkCardShadow>
  )
}

function ActionAndStatus({ status, onClick, loading }) {
  switch (status) {
    case PERK_STATUS.new: {
      return (
        <Button
          type='button'
          size='sm'
          loading={loading}
          disabled={loading}
          onClick={onClick}
          outline
          className='border-0 ml-n2'
          iconRight={
            <i
              className='bx bx-chevron-right font-size-16'
              style={{ marginLeft: -4, marginRight: -4 }}
            />
          }
        >
          Get Perk
        </Button>
      )
    }

    case PERK_STATUS.pending: {
      return (
        <button
          className='rp-btn-nostyle hover:bg-light text-muted'
          onClick={onClick}
        >
          Pending approval
        </button>
      )
    }

    case PERK_STATUS.accepted: {
      return (
        <Button
          type='button'
          size='sm'
          color='success'
          loading={loading}
          disabled={loading}
          onClick={onClick}
          outline
          className='border-0 ml-n2'
          iconRight={
            <i
              className='bx bx-chevron-right font-size-16'
              style={{ marginLeft: -4, marginRight: -4 }}
            />
          }
        >
          Redeemed
        </Button>
      )
    }
  }

  return null
}

const PerkCardShadow = styled.div`
  transition: box-shadow 100ms;

  &:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05),
      0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  }
`
