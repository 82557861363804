import moment from 'moment'
import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Col,
  Container,
  Label,
  ModalBody,
  Row,
  Table,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  FormGroup,
} from 'reactstrap'
import toastr from 'toastr'

import archiveIcon from '../../assets/images/download.svg'
import ActionsDropdown from '../../components/ActionsDropdown'
import CustomDatePicker from '../../components/Forms/CustomDatePicker/CustomDatePicker'
import CustomSelect from '../../components/Forms/CustomSelect/CustomSelect'
import NoContent from '../../components/NoContent'
import SearchBar from '../../components/SearchBar'
import BadgeX from '../../components/Table/BadgeX'
import StyledTd from '../../components/Table/StyledTd'
import StyledTh from '../../components/Table/StyledTh'
import { StyledH3, StyledH6, StyledP } from '../../components/Typo'
import Button from '../../components/ui/button'
import Loader from '../../components/ui/loader'
import Pagination from '../../components/ui/pagination'
import WiseButton from '../../components/WiseButton'
import { userTypes } from '../../helpers/enum'
import { useFetch, useResize } from '../../helpers/hooks'
import {
  downloadReceipt,
  downloadWithdrawalReceipt,
  exportStatement,
  exportTransaction,
  exportTransactions,
  getPaymentHistory,
  getTransactionDetails,
  getTransactionsMonths,
  getTrxDetails,
} from '../../services/api'
import openFile from '../../utils/file/open'
import { getCurrencyFormatter } from '../../utils/formatters/currency'
import noTransactionsImage from './../../assets/images/no-transactions.svg'
import AccountInfo from './components/AccountInfo'
import DetailModal from './components/DetailModal'

const getStatusColor = (status) => {
  switch (status) {
    case 'Processing':
      return 'warning'
    case 'Paid':
      return 'success'
    case 'Overdue':
      return 'danger'
    default:
      return 'primary'
  }
}
const getTooltipText = (status) => {
  switch (status) {
    case 'Processing':
      return 'Company indicated that they have sent the payment and it has not been received yet'
    case 'Paid':
      return 'Company payment received and disbursed'
    case 'Overdue':
      return 'Company has not paid yet'
  }
}
const INTERCOM_ZINDEX_MAGIC_NUMBER = '2147483002'
const TransactionCard = ({
  actions,
  order,
  handleDownloadInvoice,
  handleClickInfo,
}) => {
  const user = useSelector((state) => state.Account?.user)
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: order?.currency?.code || 'USD',
  })

  const isClient = user?.type === userTypes.COMPANY
  const isContractor = user?.type === userTypes.CONTRACTOR
  const [showInfo, setShowInfo] = useState(false)
  return (
    <div
      className='py-0 mb-3 bg-white rounded position-relative'
      style={{
        boxShadow: '0px 1px 0px #DFE1E6',
        border: '1px solid #E7E8F2',
      }}
    >
      <ContractorStatusDescriptionModal
        isOpen={showInfo}
        toggle={() => setShowInfo(false)}
        order={order}
      />
      <div className='p-3  border-bottom d-flex justify-content-between align-items-center'>
        <div>
          <p
            style={{ fontWeight: 'bold' }}
            className='text-gray-h font-size-14 mb-0'
          >
            {order.transaction_name}
          </p>
          <p
            style={{ fontWeight: '400' }}
            className='text-primary font-size-12 mb-0'
          >
            {order.ref}
          </p>
        </div>
        <div
          className='d-flex justify-content-between'
          onClick={() => setShowInfo(true)}
        >
          <StatusBadge order={order} />
        </div>
      </div>
      <div className='p-3 d-flex flex-column' style={{ gap: '1rem' }}>
        <div className='d-flex align-items-center justify-content-between align-items-center'>
          <StyledH6 className='text-dark font-weight-normal mb-0 font-size-14'>
            Date
          </StyledH6>
          <StyledH6 className='font-weight-normal mb-0 text-muted font-size-14'>
            {moment(order.created_at * 1000).format('MM/DD/YYYY HH:mm')}
          </StyledH6>
        </div>
        <div className='d-flex mb-0 align-items-center justify-content-between align-items-center'>
          <StyledH6 className='text-dark font-weight-normal mb-0 font-size-14'>
            Total
          </StyledH6>
          <StyledH6 className='text-muted font-size-14 font-weight-normal mb-0'>
            {formatter.format(order.amount)}
          </StyledH6>
        </div>
        {isClient || (isContractor && order?.details?.length > 0) ? (
          <div className='d-flex' style={{ gap: '0.5rem' }}>
            {actions.map(({ content, onClick }, index) => {
              return (
                <Button
                  size='sm'
                  color='light'
                  outline
                  onClick={onClick}
                  key={index}
                >
                  {content}
                </Button>
              )
            })}
          </div>
        ) : null}
      </div>
      {isClient ? (
        <div className={`${order.status === 'Paid' && 'px-3 pb-3'}`}>
          {order.status === 'Paid' ? (
            <WiseButton
              textColor={'dark'}
              onClick={() => handleDownloadInvoice(order)}
              bk='white'
              border
              minWidth='100%'
              text='Receipt'
              icon={
                <img
                  src={archiveIcon}
                  alt={'archive icon'}
                  style={{ width: 16, marginRight: 8 }}
                />
              }
            />
          ) : order?.method?.id === 3 ? (
            <WiseButton
              textColor='dark'
              onClick={() => handleClickInfo(order)}
              bk='white'
              border
              minWidth='100%'
              text='View Bank details'
              icon={
                <img
                  src={archiveIcon}
                  alt='archive icon'
                  style={{ width: 16, marginRight: 8 }}
                />
              }
            />
          ) : null}
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

function getBodyFromFilters(filters, isClient) {
  const body = { page: filters.page }

  if (filters.query) {
    body.query = filters.query
  }

  if (isClient) {
    body.month = filters.month?.value
  } else {
    body.year = filters.year?.value
  }

  return body
}

const Transactions = () => {
  const isMobile = useResize()

  const user = useSelector((state) => state.Account?.user)
  const isClient = user?.type === userTypes.COMPANY
  const isContractor = user?.type === userTypes.CONTRACTOR

  const [showExportModal, setShowExportModal] = useState(false)
  const [showExportStatement, setShowExportStatement] = useState(false)

  const [filters, setFilters] = useState({
    month: null,
    year: null,
    query: null,
    page: 1,
  })

  const [years, setYears] = useState([])

  const months = useFetch({
    action: getTransactionsMonths,
    autoFetch: true,
    onComplete: (data) => {
      if (data?.months?.length > 0) {
        const theMonth = data?.months[0]
        setFilters((prev) => ({ ...prev, month: theMonth }))

        if (isContractor) {
          const theYear = {
            label: data?.months[0].value.slice(0, 4),
            value: data?.months[0].value.slice(0, 4),
          }
          setFilters((prev) => ({ ...prev, year: theYear }))
        }

        // Get unique years using a Set
        const years = data.months.reduce((prev, curr) => {
          const newYear = curr?.value?.slice(0, 4)
          return prev.add(newYear)
        }, new Set())

        // set years as an array filtering out falsy values
        setYears(Array.from(years).filter(Boolean))
      }
    },
  })

  const {
    data: trx,
    isLoading: loadingTrx,
    paginator,
  } = useFetch(
    {
      action: getPaymentHistory,
      autoFetch: true,
      body: getBodyFromFilters(filters, isClient),
    },
    [filters],
  )

  const handleSearch = (query) => {
    setFilters((prev) => ({ ...prev, query }))
  }

  return (
    <div className='page-content mb-lg-5'>
      <StyledH3
        min={'22px'}
        max={'32px'}
        style={{ fontFamily: 'gilroyB' }}
        className={'text-dark mb-3 mb-md-4'}
      >
        Transactions
      </StyledH3>
      {months.isLoading ? (
        <Loader minHeight='30rem' />
      ) : (
        <Container fluid className='p-0 m-0'>
          <div
            className={isMobile ? null : 'bg-white rounded'}
            style={{ boxShadow: isMobile ? null : '0px 1px 0px #DFE1E6' }}
          >
            <Container fluid>
              <Row className='py-4 px-sm-4'>
                <Col xs={4} md={2} className='p-0 mb-3 mb-md-0'>
                  <CustomSelect
                    options={
                      isClient
                        ? months?.data?.months
                        : years.map((e) => ({ label: e, value: e }))
                    }
                    value={isClient ? filters.month : filters.year}
                    onChange={(value) => {
                      const key = isClient ? 'month' : 'year'
                      setFilters((prev) => ({ ...prev, [key]: value }))
                    }}
                    withSearch
                    searchable={false}
                    classNamePrefix='with-search'
                  />
                </Col>
                <Col xs={8} md='auto' className='p-0 flex-grow-1'>
                  <SearchBar
                    withFilter
                    query={filters?.query}
                    onQueryChanged={handleSearch}
                    className='mr-md-2'
                  />
                </Col>
                <div className='d-md-none w-100'></div>
                {isContractor && (
                  <div>
                    <button
                      className='btn btn-primary'
                      onClick={() => setShowExportStatement(true)}
                    >
                      Export Statement
                    </button>
                  </div>
                )}
              </Row>
            </Container>

            {loadingTrx ? (
              <Loader minHeight='30rem' />
            ) : !loadingTrx && trx?.transactions?.length === 0 ? (
              <div>
                <NoContent
                  headline='Payments and Transactions'
                  subtitle={
                    isClient
                      ? 'All your payment transactions will be shown here'
                      : 'All your payments & withdrawal transactions will be shown here'
                  }
                  image={noTransactionsImage}
                />
              </div>
            ) : loadingTrx ? (
              <Loader minHeight='20rem' />
            ) : (
              <>
                {isMobile ? (
                  <Col
                    className='p-3 bg-white rounded'
                    style={{ boxShadow: '0px 1px 0px #DFE1E6' }}
                  >
                    {trx?.transactions?.map((item, key) => (
                      <TransactionLine item={item} key={'_order_' + key} />
                    ))}
                  </Col>
                ) : (
                  <div className={isClient && 'table-with-dropdown'}>
                    <Table
                      responsive
                      className='table-centered table-nowrap text-muted mb-0'
                    >
                      <thead className='thead-light'>
                        <tr
                          style={{
                            borderTop: 'hidden',
                            borderBottom: '1px solid #E7E8F2',
                          }}
                        >
                          <StyledTh>
                            <p className='text-white mb-0'>.</p>
                          </StyledTh>
                          <StyledTh>Transaction ID</StyledTh>
                          <StyledTh style={{ width: '200px' }}>Date</StyledTh>
                          <StyledTh>Name</StyledTh>
                          <StyledTh>Status</StyledTh>
                          <StyledTh textRight>Total</StyledTh>
                          {isClient && <th />}
                        </tr>
                      </thead>
                      <tbody>
                        {trx?.transactions?.map((item, key) => (
                          <TransactionLine item={item} key={'_order_' + key} />
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}

                <div
                  className={`mt-2 mt-md-0 d-flex justify-content-md-end p-3${
                    isMobile ? ' bg-white rounded' : ''
                  }`}
                >
                  <Pagination
                    innerClass='pagination mb-0'
                    activePage={filters?.page}
                    onChange={(page) => {
                      setFilters((prev) => ({ ...prev, page }))
                    }}
                    itemsCountPerPage={paginator?.per_page ?? 50}
                    totalItemsCount={paginator?.total ?? 0}
                  />
                </div>
              </>
            )}
          </div>
        </Container>
      )}
      {showExportModal && (
        <FilterModal
          toggle={() => setShowExportModal(false)}
          isOpen={showExportModal}
          title='Export'
        />
      )}
      {showExportStatement && (
        <FilterModal
          toggle={() => setShowExportStatement(false)}
          isOpen={showExportStatement}
          title='Export Statement'
          action={exportStatement}
          statement
          fileName='Statement'
        />
      )}
    </div>
  )
}

const TransactionLine = ({ item }) => {
  const [expanded, setExpanded] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showAccountInfo, setShowAccountInfo] = useState(false)
  const [isOpenDetail, setIsOpenDetail] = useState(false)
  const [showActions, setShowActions] = useState(false)
  const [downloadingReceipt, setDownloadingReceipt] = useState(false)

  const user = useSelector((state) => state.Account?.user)
  const isMobile = useResize()

  const isClient = user?.type === userTypes.COMPANY
  const isContractor = user?.type === userTypes.CONTRACTOR

  const formatter = getCurrencyFormatter(item?.currency)

  const details = useFetch({ action: getTrxDetails })

  const handleViewDetails = (trx) => {
    setIsOpenDetail(true)
    details.startFetch({ id: trx?.id })
  }
  const info = useFetch({ action: getTransactionDetails })

  const exportTrx = useFetch({
    action: exportTransaction,
    onError: toastr.error,
    onComplete: (data) => {
      openFile(data, `${item?.ref}.xls`)
    },
  })

  const handleDownloadInvoice = (transaction) => {
    window.analytics.track('Clicked download receipt', {
      transaction_id: transaction.ref,
    })
    downloadReceipt(transaction.ref, user?.token, transaction.token).then(
      (r) => {
        openFile(r?.data, `${transaction?.ref}.pdf`)
      },
    )
  }

  const handleClickInfo = (e) => {
    setShowAccountInfo(true)
    info.startFetch({ id: e?.id })
  }
  const handleClickTrxInfo = () => {
    setShowInfo(true)
  }
  const handleExport = (e) => {
    exportTrx.startFetch({ transaction_id: e?.id })
  }

  const createdAtDate = moment(item.created_at * 1000).format(
    'DD/MM/YYYY hh:mm',
  )

  function handleDownloadWithdrawalReceipt(transaction) {
    setDownloadingReceipt(true)
    downloadWithdrawalReceipt(transaction.ref, user?.token, transaction.token)
      .then((r) => {
        openFile(r?.data, `receipt-${transaction?.ref}-${createdAtDate}.pdf`)
      })
      .catch(() => {
        toastr.error('Error downloading receipt', 'Error')
      })
      .finally(() => {
        setDownloadingReceipt(false)
      })
  }

  const actions = isContractor
    ? [
        { content: 'View details', onClick: () => handleClickTrxInfo() },
        item?.type?.id !== 2
          ? null
          : {
              content: (
                <>
                  {downloadingReceipt ? (
                    <i className='bx bx-loader bx-spin mr-1' />
                  ) : null}
                  Download receipt
                </>
              ),
              onClick: () =>
                downloadingReceipt
                  ? null
                  : handleDownloadWithdrawalReceipt(item),
            },
      ]
    : [
        {
          content: 'Download statement',
          onClick: () => handleDownloadInvoice(item),
        },
        { content: 'Export to CSV', onClick: () => handleExport(item) },
      ]

  if (item.status !== 'Paid' && isClient) {
    actions.unshift({
      content: 'View bank details',
      onClick: () => handleClickInfo(item),
    })
  }

  return (
    <>
      {isMobile ? (
        <TransactionCard
          handleClickInfo={handleClickInfo}
          handleDownloadInvoice={handleDownloadInvoice}
          order={item}
          actions={actions.filter(Boolean)}
        />
      ) : (
        <>
          <tr style={{ borderBottom: '1px solid #E7E8F2' }}>
            <td className='text-center'>
              {item?.works?.length !== 0 && item.status !== 'Paid (off-cycle)' && (
                <button
                  className='d-flex p-1 rp-btn-nostyle text-dark'
                  onClick={() => setExpanded((t) => !t)}
                >
                  <i className='bx bx-plus font-size-12' />
                </button>
              )}
            </td>
            <StyledTd>
              <p
                className='text-dark font-size-14 mb-0'
                style={{ fontWeight: '400' }}
              >
                {item.ref}
              </p>
            </StyledTd>
            <StyledTd myStyle={{ width: isClient ? '80%' : 70 }}>
              {createdAtDate}
            </StyledTd>
            <StyledTd>
              {item.transaction_name}{' '}
              {item.type?.id === 2 && false && (
                <a
                  className='text-primary'
                  onClick={() => handleViewDetails(item)}
                >
                  View details
                </a>
              )}
            </StyledTd>
            <StyledTd>
              <div>
                <BadgeX
                  id={`trx_c${item?.ref}`}
                  className={
                    'd-inline-flex font-size-14  rounded bg-soft-' +
                    getStatusColor(item.status)
                  }
                  color={'white'}
                  pill
                >
                  <p className={'mb-0 text-' + getStatusColor(item.status)}>
                    {item.status}
                  </p>
                  {isContractor ? (
                    <>
                      <i
                        className={`bx bx-info-circle ml-2 text-${getStatusColor(
                          item.status,
                        )} font-size-18`}
                      />
                      <UncontrolledTooltip
                        placement='bottom'
                        target={`trx_c${item?.ref}`}
                      >
                        {getTooltipText(item.status)}
                      </UncontrolledTooltip>
                    </>
                  ) : null}
                </BadgeX>
              </div>
            </StyledTd>

            <StyledTd className='text-right'>
              {formatter.format(isClient ? item.total : item.amount)}
            </StyledTd>

            {(isClient || (isContractor && item?.details?.length > 0)) && (
              <td>
                <ActionsDropdown
                  isOpen={showActions}
                  toggle={() => setShowActions((t) => !t)}
                  options={actions}
                />
              </td>
            )}
          </tr>
          {expanded && (
            <>
              {item?.works?.map((t) => {
                const formatter = new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: t?.currency || 'USD',
                })
                return (
                  <tr
                    style={{
                      backgroundColor: '#F4F5F7',
                      borderLeft: '1.5px solid  var(--primary)',
                      borderBottom: '1px solid #eee',
                    }}
                    className='font-weight-light font-size-12 '
                    key={'_trans' + t?.id}
                  >
                    <th />
                    <StyledTd>{t.contract_ref}</StyledTd>
                    <StyledTd
                      colSpan={isClient ? 1 : 2}
                      myStyle={{
                        width: isClient ? '80%' : 120,
                        marginBottom: 0,
                      }}
                    >
                      <div>
                        <p className={'font-weight-bold'}>{t?.name}</p>
                        <p
                          className={'mb-0'}
                          style={{
                            whiteSpace: t?.details.includes('Period from')
                              ? 'pre-line'
                              : 'inherit',
                          }}
                        >
                          {t?.details}
                        </p>
                        {t.attributes?.map((a) => (
                          <div key={`attr-${a?.name}`}>
                            <span className='text-muted'>{a?.name}: </span>
                            <span>{a?.value}</span>
                            <br />
                          </div>
                        ))}
                      </div>
                    </StyledTd>
                    {isClient ? (
                      <StyledTd colSpan={2}>
                        <Col>
                          <Row className='align-items-center flex-nowrap'>
                            {!t.contractor?.photo ? (
                              <div className='avatar-xs'>
                                <span className='avatar-title rounded-circle'>
                                  {t.contractor?.first_name?.charAt(0)}
                                </span>
                              </div>
                            ) : (
                              <div>
                                <img
                                  className='rounded-circle avatar-xs'
                                  src={t.contractor?.photo}
                                  alt=''
                                />
                              </div>
                            )}
                            <div
                              className='font-size-14 ml-2 rp-font-bold text-gray-h'
                              style={{ marginLeft: 10 }}
                              translate='no'
                            >
                              {t.contractor?.first_name}{' '}
                              {t.contractor?.last_name}
                            </div>
                          </Row>
                        </Col>
                      </StyledTd>
                    ) : (
                      <StyledTh />
                    )}
                    <td>
                      <div>
                        <p
                          style={{
                            width: 78,
                            marginBottom: 0,
                            textAlign: 'right',
                          }}
                        >
                          {formatter.format(t?.amount)}
                        </p>
                      </div>
                    </td>
                    {isClient && <StyledTd />}
                    {isContractor && <td />}
                  </tr>
                )
              })}
            </>
          )}
        </>
      )}
      <Modal
        isOpen={showAccountInfo}
        toggle={() => {
          setShowAccountInfo(false)
        }}
        // centered={true}
      >
        <div className='modal-header'>
          <h5 className='modal-title mt-0'>Account Info</h5>
          <button
            type='button'
            onClick={() => {
              setShowAccountInfo(false)
            }}
            className='close'
            data-dismiss='modal'
            aria-label='Close'
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <AccountInfo loading={info.isLoading} data={info.data} />
        <div className='p-2' />
      </Modal>
      <Modal
        isOpen={showInfo}
        toggle={() => {
          setShowInfo(false)
        }}
        centered={true}
      >
        <div className='modal-header'>
          <h5 className='modal-title mt-0'>Transaction Info</h5>
          <button
            type='button'
            onClick={() => {
              setShowInfo(false)
            }}
            className='close'
            data-dismiss='modal'
            aria-label='Close'
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <AccountInfo data={{ data: item.details }} withCopy={false} />
        <div className='p-2' />
      </Modal>
      {isOpenDetail && (
        <DetailModal
          data={details.data}
          loading={details.isLoading}
          isOpen={isOpenDetail}
          toggle={() => setIsOpenDetail(false)}
        />
      )}
    </>
  )
}

export const FilterModal = ({
  isOpen,
  toggle,
  title,
  action = exportTransactions,
  fileName = 'transactions',
  statement,
}) => {
  const [format, setFormat] = useState(null)
  const [type, setType] = useState(null)
  const [month, setMonth] = useState(null)
  const user = useSelector((state) => state?.Account?.user)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const getType = () => {
    switch (format?.value) {
      case 'excel':
        return 'xls'
      case 'csv':
        return 'csv'
      default:
        return 'pdf'
    }
  }
  const callBack = (r) => {
    const url = window.URL.createObjectURL(new Blob([r]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${fileName}.${getType()}`) // or any other extension
    document.body.appendChild(link)
    link.click()
    toggle()
  }
  const exportTrx = useFetch({
    action,
    onComplete: callBack,
  })
  const handleExportTrx = () => {
    if ((!format || !type) && !statement) {
      toastr.error('Format and type are required')
      return
    }
    if (!statement && !month) {
      toastr.error('Month is required!')
      return
    } else if (statement && !startDate && !endDate) {
      toastr.error('Dates are required!')
      return
    }
    const body = !statement
      ? {
          month: moment(month).format('YYYY-MM'),
        }
      : {
          from: moment(startDate).format('YYYY-MM-DD'),
          to: moment(endDate).format('YYYY-MM-DD'),
        }

    if (!statement) {
      body.type = type?.value
      body.format = format?.value
    }
    exportTrx.startFetch(body)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered unmountOnClose>
      <div className='modal-content'>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <Row className='p-4'>
          <Col>
            {!statement && (
              <>
                <FormGroup>
                  <label>Type</label>
                  <CustomSelect
                    options={[
                      { label: 'simple', value: 'simple' },
                      { label: 'detailed', value: 'detailed' },
                    ]}
                    onChange={setType}
                    value={type}
                  />
                </FormGroup>
                <FormGroup>
                  <label>Format</label>
                  <CustomSelect
                    options={[
                      { label: 'excel', value: 'excel' },
                      { label: 'csv', value: 'csv' },
                    ]}
                    onChange={setFormat}
                    value={format}
                  />
                </FormGroup>
              </>
            )}
            {statement && user?.type !== 'client' ? (
              <>
                <FormGroup>
                  <Label className='col-form-label pt-0 pt-0'>From</Label>
                  <Col className='p-0'>
                    <CustomDatePicker
                      value={startDate}
                      handleOnChange={setStartDate}
                    />
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Label className='col-form-label pt-0 pt-0'>To</Label>
                  <Col className='p-0'>
                    <CustomDatePicker
                      value={endDate}
                      handleOnChange={setEndDate}
                    />
                  </Col>
                </FormGroup>
              </>
            ) : (
              <></>
            )}
            {!statement && (
              <FormGroup>
                <label>Month</label>
                <CustomDatePicker
                  showMonthYearPicker
                  placeholder='Month'
                  dateFormat='MMM - yyyy'
                  value={month}
                  handleOnChange={setMonth}
                />
              </FormGroup>
            )}
          </Col>
        </Row>
      </div>
      <div className='modal-footer'>
        <Row>
          <button className='btn btn-outline-light mr-2' onClick={toggle}>
            Cancel
          </button>
          <button className='btn btn-primary' onClick={handleExportTrx}>
            {exportTrx.isLoading && (
              <i className='bx bx-loader bx-spin font-size-16 align-middle mr-2' />
            )}
            Export
          </button>
        </Row>
      </div>
    </Modal>
  )
}
function ContractorStatusDescriptionModal({ isOpen, toggle, order }) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      backdrop={true}
      contentClassName='align-self-end rounded-top pb-4'
      zIndex={INTERCOM_ZINDEX_MAGIC_NUMBER}
      style={{ height: 'auto' }}
    >
      <ModalHeader toggle={toggle}>
        <div>
          <p
            style={{ fontWeight: 'bold' }}
            className='text-gray-h font-size-14 mb-0'
          >
            {order.transaction_name}
          </p>
          <p
            style={{ fontWeight: '400' }}
            className='text-primary font-size-12 mb-0'
          >
            {order.ref}
          </p>
        </div>
      </ModalHeader>
      <ModalBody>
        <StatusBadge order={order} />
        <div className='text-muted px-1 pt-3'>
          {getTooltipText(order?.status)}
        </div>
      </ModalBody>
    </Modal>
  )
}
function StatusBadge({ order }) {
  return (
    <BadgeX
      style={{ height: 'fit-content' }}
      className={`d-inline-flex font-size-14  rounded bg-soft-${getStatusColor(
        order?.status,
      )}`}
      color='white'
      pill
    >
      <StyledP
        min={'12px'}
        max={'14px'}
        className={`mb-0 text-${getStatusColor(order?.status)}`}
      >
        {order?.status}
      </StyledP>
      <i
        className={`bx bx-info-circle ml-2 text-${getStatusColor(
          order.status,
        )} font-size-18`}
      />
    </BadgeX>
  )
}
export default withTranslation()(Transactions)
