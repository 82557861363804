import cx from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { Col, Container, Row, UncontrolledTooltip } from 'reactstrap'
import toastr from 'toastr'

import dueIcon from '../../assets/images/activity-due-payment.svg'
import pendingIcon from '../../assets/images/activity-pending-payments.svg'
import noBirthdays from '../../assets/images/no_birthdays.svg'
import upcoming from '../../assets/images/upcoming_payment.svg'
import ActionCenter from '../../components/ActionCenter'
import { StyledH2, StyledH4 } from '../../components/Typo'
import Button from '../../components/ui/button'
import Shimmer from '../../components/ui/shimmer'
import { userTypes } from '../../helpers/enum'
import { useFetch, usePermissions } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import {
  getBirthdays,
  getClientInfo,
  getClientToDoList,
  getContractorInfo,
  getCurrentMonthPayments,
  getOutstandingPayments,
  getPendingApprovalPayments,
  getRecentContracts,
  getUpcomingPayments,
} from '../../services/api'
import { track } from '../../utils/analytics'
import { deleteCookie, getCookie, setCookie } from '../../utils/cookies'
import { TFA_REMINDER } from '../../utils/cookies/cookie-names'
import { getCurrencyFormatter } from '../../utils/formatters/currency'
import usePrevious from '../../utils/use-previous'
import { usePayrollApprovalEnabled } from '../CompanySetting'
import ContractList from '../Contract/ContractList/ContractList'
import {
  ChartCard,
  ChartHeading,
  HeadCountPerCountry,
  SpendingPerMonth,
} from '../reports'
import ContractorOverview from './ContractorOverview'
import EmployeeOverview from './EmployeeOverview'
import { StyledImg, StyledMiniWidget, StylePart } from './mini-widget'

const useHasChanged = (val) => {
  const prevVal = usePrevious(val)
  return prevVal?.id !== val?.id
}

export default function Activity() {
  const history = useHistory()

  const user = useSelector((state) => state.Account?.user)
  const lastPath = useSelector((state) => state.Account?.lastPath)
  const display = user?.type

  const userInfo = useFetch({
    action: user?.type === 'client' ? getClientInfo : getContractorInfo,
    autoFetch: true,
    onComplete: (data) => {
      if (!navigator.cookieEnabled) {
        return
      }
      const twoFa = data?.is_2fa_enabled
      const cookieValue = getCookie(TFA_REMINDER)

      if (twoFa) {
        if (cookieValue) {
          deleteCookie(TFA_REMINDER)
        }
      } else {
        if ((!cookieValue || cookieValue !== user?.token) && !lastPath) {
          setCookie(TFA_REMINDER, user?.token)
          history.push('/2fa-reminder')
        }
      }
    },
  })

  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company,
  )

  const isEmployee = useSelector(
    (state) => state.userProfile?.userProfile?.contractor_type === 'employee',
  )

  const tagSession = () => {
    if (window.smartlook) {
      window.smartlook('identify', user?.id, {
        name: `${user?.first_name} ${user?.last_name}`,
        email: user?.email,
      })
    }
  }

  const hasCompanyChanged = useHasChanged(company)

  const todo = useFetch(
    {
      action: getClientToDoList,
      initResult: [],
      autoFetch: true,
      onError: (err) => toastr.error(err),
      onComplete: () => {
        tagSession()
      },
    },
    [hasCompanyChanged],
  )

  const { hasAccess } = usePermissions()

  return display === userTypes.COMPANY ? (
    <>
      <div className='page-content'>
        <Container fluid className='p-0 m-0'>
          <h1
            style={{ fontFamily: 'gilroyB', marginBottom: 14 }}
            className='   text-dark font-size-32 '
          >
            Hi, <span translate='no'>{user?.first_name}</span>
          </h1>
          <h2
            style={{ marginBottom: 32, fontWeight: '400' }}
            className='text-secondary font-size-16'
          >
            Here is a recap of your activity
          </h2>

          <ActionCenter data={todo.data} isLoading={todo?.isLoading} />

          <Col className='p-0 m-0'>
            <div style={{ marginBottom: 32 }}>
              <Row className='mx-n2' style={{ gap: '1rem 0' }}>
                <UpcomingPaymentsWidget />
                <OutstandingPaymentsWidget />
                <CurrentMonthPaymentsWidget />
              </Row>
            </div>
          </Col>
          {hasAccess(permissions.viewDashboardGraph) && (
            <>
              <Row className='mx-n3' style={{ gap: 'var(--size-32) 0' }}>
                <Col xs={12} className='px-3'>
                  <SpendingPerMonth hasCompanyChanged={hasCompanyChanged} />
                </Col>
                <Col md={6} className='px-3'>
                  <HeadCountPerCountry hasCompanyChanged={hasCompanyChanged} />
                </Col>
                <Col md={6} className='px-3'>
                  <UpcomingBirthdays />
                </Col>
                <Col xs={12} className='px-3'>
                  <RecentContracts />
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </>
  ) : isEmployee ? (
    <EmployeeOverview />
  ) : (
    <ContractorOverview userInfo={userInfo?.data} />
  )
}

function RecentContracts() {
  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company,
  )
  const hasCompanyChanged = useHasChanged(company)
  const { data: contracts, isLoading } = useFetch(
    {
      action: getRecentContracts,
      autoFetch: true,
    },
    [hasCompanyChanged],
  )

  return (
    <ChartCard className={{ 'd-none d-block': contracts?.length > 0 }}>
      <ChartHeading
        title={
          contracts?.length > 0 ? (
            <div className='d-flex align-items-center justify-content-between'>
              <h4 className='mb-1 font-size-24 text-gray-h rp-font-bold rp-font-gilroyB'>
                Recent contracts
              </h4>
              <Link
                to={'/contracts'}
                className='text-primary ml-2 font-size-14 d-flex align-items-center align-self-end'
              >
                View all
                <i className='bx  text-primary    bx-chevron-right  font-size-6 align-middle ml-9' />
              </Link>
            </div>
          ) : (
            'Recent contracts'
          )
        }
      />
      {isLoading ? (
        <div style={{ '--s-height': '44px' }}>
          <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
          <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
          <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
          <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
          <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
          <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
        </div>
      ) : (
        <Container
          fluid
          className='position-relative rounded-bottom'
          style={{ minHeight: 400 }}
        >
          <ContractList Orders={contracts} />
        </Container>
      )}
    </ChartCard>
  )
}
function UpcomingPaymentsWidget() {
  const user = useSelector((state) => state.Account?.user)
  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company,
  )
  const hasCompanyChanged = useHasChanged(company)

  const { data: upcomingPayments, isLoading } = useFetch(
    {
      action: getUpcomingPayments,
      autoFetch: true,
    },
    [hasCompanyChanged],
  )

  const formatter = getCurrencyFormatter(company?.currency?.code)

  return isLoading ? (
    <Col lg={4} className='px-2'>
      <div className='bg-white p-3'>
        <Shimmer width='100%' height={140} />
      </div>
    </Col>
  ) : upcomingPayments ? (
    <Col lg={4} className='px-2'>
      <StyledMiniWidget>
        <StylePart
          className='border-bottom justify-content-between align-items-center d-flex gap-6'
          style={{ minHeight: 83 }}
        >
          <div className='justify-content-between align-items-center d-flex gap-12'>
            <StyledImg alt='icon' src={upcoming} />

            <h5 className='mb-0 text-gray-700'>
              Upcoming Payments
              <>
                <i
                  id={`info-repo`}
                  className='bx bx-info-circle font-size-16 ml-1'
                />
                <UncontrolledTooltip placement='top' target={`info-repo`}>
                  List of payments in the current payment cycle, including due
                  and overdue payments
                </UncontrolledTooltip>
              </>
            </h5>
          </div>

          <Button
            tag={Link}
            onClick={() => {
              track('Clicked upcoming payment', { email_id: user?.email })
            }}
            to='/upcoming-payment'
            color='link'
            className='px-0'
            iconRight={<i className='bx bx-chevron-right align-middle' />}
          >
            Details
          </Button>
        </StylePart>

        <StylePart>
          <div>
            <StyledH2
              style={{ fontWeight: '600' }}
              max='24px'
              min='22px'
              className='text-gray-h mb-0'
            >
              {formatter.format(upcomingPayments)}
            </StyledH2>
          </div>
        </StylePart>
      </StyledMiniWidget>
    </Col>
  ) : null
}

function OutstandingPaymentsWidget() {
  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company,
  )
  const hasCompanyChanged = useHasChanged(company)
  const { data: outstandingPayments, isLoading } = useFetch(
    {
      action: getOutstandingPayments,
      autoFetch: true,
    },
    [hasCompanyChanged],
  )

  const { hasAccess } = usePermissions()
  const user = useSelector((state) => state.Account?.user)

  const formatter = getCurrencyFormatter(company?.currency?.code)

  return isLoading ? (
    <Col lg={4} className='px-2'>
      <div className='bg-white p-3'>
        <Shimmer width='100%' height={140} />
      </div>
    </Col>
  ) : outstandingPayments ? (
    <Col lg={4} className='px-2'>
      <StyledMiniWidget>
        <StylePart
          className='border-bottom justify-content-between align-items-center d-flex gap-6'
          style={{ minHeight: 83 }}
        >
          <div className='justify-content-between align-items-center d-flex gap-12'>
            <StyledImg alt='icon' src={dueIcon} />

            <h5 className='mb-0 text-gray-700'>
              Due
              <i
                id='info-repo-outs'
                className='bx bx-info-circle font-size-16 ml-2'
              />
              <UncontrolledTooltip placement='top' target='info-repo-outs'>
                List of payments due within 5 days and overdue payments
              </UncontrolledTooltip>
            </h5>
          </div>

          {hasAccess(permissions.ViewUnpaidPayments) && (
            <>
              {hasAccess(permissions.PrepareTransactions) ? (
                <Button
                  tag={Link}
                  onClick={() => {
                    track('Clicked pay', { email_id: user?.email })
                  }}
                  to='/payment'
                >
                  Pay
                </Button>
              ) : (
                <Button
                  tag={Link}
                  onClick={() => {
                    track('Clicked pay', { email_id: user?.email })
                  }}
                  to='/payment'
                  color='link'
                  className='px-0'
                  iconRight={<i className='bx bx-chevron-right align-middle' />}
                >
                  Details
                </Button>
              )}
            </>
          )}
        </StylePart>

        <StylePart>
          <div>
            <StyledH2
              style={{ fontWeight: '600' }}
              max='24px'
              min='22px'
              className='text-gray-h mb-0'
            >
              {formatter.format(outstandingPayments?.due)}
            </StyledH2>
            <StyledH2
              style={{ fontWeight: '600' }}
              max={'18px'}
              min={'18px'}
              className='text-danger mb-0'
            >
              {formatter.format(outstandingPayments?.overdue)}
              (Overdue)
            </StyledH2>
          </div>
        </StylePart>
      </StyledMiniWidget>
    </Col>
  ) : null
}

function CurrentMonthPaymentsWidget() {
  const user = useSelector((state) => state.Account?.user)
  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company ?? {},
  )

  const hasCompanyChanged = useHasChanged(company)

  const { data: pendingApprovalPayments, isPendingLoading } = useFetch(
    {
      action: getPendingApprovalPayments,
      autoFetch: true,
    },
    [hasCompanyChanged],
  )
  const { data: currentMonthPayments, isLoading } = useFetch(
    {
      action: getCurrentMonthPayments,
      autoFetch: true,
    },
    [hasCompanyChanged],
  )

  const payrollApprovalEnabled = usePayrollApprovalEnabled()
  const isPending = payrollApprovalEnabled && pendingApprovalPayments?.amount

  const isNotApprover = !pendingApprovalPayments?.is_approver

  const formatter = getCurrencyFormatter(company?.currency?.code)

  return isLoading || isPendingLoading ? (
    <Col lg={4} className='px-2'>
      <div className='bg-white p-3'>
        <Shimmer width='100%' height={140} />
      </div>
    </Col>
  ) : pendingApprovalPayments || currentMonthPayments ? (
    <Col lg={4} className='px-2'>
      <StyledMiniWidget>
        <StylePart
          className='border-bottom justify-content-between align-items-center d-flex gap-6'
          style={{ minHeight: 83 }}
        >
          <div className='justify-content-between align-items-center d-flex gap-12'>
            <StyledImg alt='icon' src={isPending ? pendingIcon : dueIcon} />

            <h5 className='mb-0 text-gray-700'>
              {isPending ? 'Pending review' : 'Paid this month'}
            </h5>
          </div>

          {isPending ? (
            <Button
              tag={Link}
              onClick={() => {
                track('Clicked pending review', { email_id: user?.email })
              }}
              to='/review-payments'
              color={isNotApprover ? 'link' : 'primary'}
              className={isNotApprover ? 'px-0' : undefined}
              iconRight={
                isNotApprover ? (
                  <i className='bx bx-chevron-right align-middle' />
                ) : undefined
              }
            >
              {isNotApprover ? 'Details' : 'Review'}
            </Button>
          ) : (
            <Link
              onClick={() => {
                track('Clicked paid this month', { email_id: user?.email })
              }}
              to='/transactions'
              className='text-primary ml-2 font-size-14 d-flex align-items-center'
            >
              Details
              <i className='bx text-primary bx-chevron-right font-size-6 align-middle ml-9' />
            </Link>
          )}
        </StylePart>

        <StylePart>
          <div>
            <StyledH2
              style={{ fontWeight: '600' }}
              max='24px'
              min='22px'
              className='text-gray-h mb-0'
            >
              {formatter.format(
                isPending
                  ? pendingApprovalPayments?.amount
                  : currentMonthPayments,
              )}
            </StyledH2>
          </div>
        </StylePart>
      </StyledMiniWidget>
    </Col>
  ) : null
}

function UpcomingBirthdays() {
  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company,
  )
  const hasCompanyChanged = useHasChanged(company)
  const { data: birthdays, isLoading } = useFetch(
    {
      action: getBirthdays,
      autoFetch: true,
    },
    [hasCompanyChanged],
  )

  return (
    <ChartCard style={{ minHeight: 480 }}>
      <ChartHeading title='Upcoming Birthdays' />

      <div
        className={cx('px-3', {
          'blur-sm pointer-events-none': birthdays?.length <= 0,
        })}
      >
        {isLoading || !birthdays ? (
          <Shimmer width='100%' height={355} />
        ) : (
          <div style={{ paddingBottom: '24px' }}>
            {birthdays?.length === 0 && (
              <Col className='py-3'>
                <Row className='justify-content-center align-items-center'>
                  <img style={{ maxWidth: '160px' }} src={noBirthdays} alt='' />
                </Row>
                <Row className='justify-content-center mt-3'>
                  <StyledH4
                    min={'16px'}
                    max={'18px'}
                    mid='16px'
                    className={'text-dark'}
                  >
                    No birthdays in the next 30days
                  </StyledH4>
                </Row>
              </Col>
            )}
            {birthdays &&
              birthdays.map((contractor, index) => (
                <div
                  key={contractor?.id}
                  style={{ padding: '0 24px', fontSize: '14px' }}
                >
                  <div className='d-flex justify-content-between align-items-center mb-3'>
                    <div
                      className='country d-flex align-items-center'
                      style={{ flex: 2, fontWeight: 'bold' }}
                    >
                      <div style={{ marginRight: '.8rem' }}>
                        {!contractor.avatar ? (
                          <div className='avatar-xs'>
                            <span className='avatar-title rounded-circle'>
                              {contractor?.first_name
                                ? contractor?.first_name?.charAt(0)
                                : contractor?.email?.charAt(0)}
                            </span>
                          </div>
                        ) : (
                          <div>
                            <img
                              className='rounded-circle avatar-xs'
                              src={contractor?.avatar}
                              alt=''
                            />
                          </div>
                        )}
                      </div>
                      {contractor.first_name} {contractor.last_name}
                    </div>
                    <div
                      className='value d-flex justify-content-end align-items-center'
                      style={{ flex: 1 }}
                    >
                      <div>
                        <div
                          className='d-flex align-items-center'
                          style={{ color: '#777F9E' }}
                        >
                          {contractor.today ? (
                            <div>
                              <span
                                role='img'
                                aria-label='waving-hand'
                                style={{
                                  fontSize: '20px',
                                  marginRight: '.5rem',
                                }}
                              >
                                👋
                              </span>
                              <span> Today</span>
                            </div>
                          ) : (
                            contractor.date
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {index !== birthdays.length - 1 && <hr />}
                </div>
              ))}
          </div>
        )}
      </div>
    </ChartCard>
  )
}
