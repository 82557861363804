import { AvForm } from 'availity-reactstrap-validation'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import toastr from 'toastr'

import TextInput from '../../components/Forms/TextInput'
import RadioGroup from '../../components/RadioGroup'
import Button from '../../components/ui/button'
import { useResize } from '../../helpers/hooks'
import { segmentBatch, sendUserInvitation } from '../../services/api'

const addUserForm = 'addUserForm'

const AddUserModal = ({ isOpen, toggle, onComplete }) => {
  const staticData = useSelector((state) => state?.Layout?.staticData)
  const user = useSelector((state) => state?.Account?.user)
  const [loading, setLoading] = useState(false)

  const userTypeOptions = staticData?.roles?.map((e) => ({
    value: e.id,
    label: e.name,
    description: e?.description,
  }))

  const [selectedType, setSelectedType] = useState(
    staticData?.roles.map((e) => ({ value: e.id, label: e.name }))[0],
  )

  const isMobile = useResize()
  const formRef = useRef()
  const handleSubmitInvite = (e, v) => {
    setLoading(true)
    sendUserInvitation(user?.token, { ...v, role_id: selectedType?.value })
      .then((r) => {
        if (r.data.success) {
          window.analytics.track('Added a user', {
            'new-user-first-name': v?.first_name,
            'new-user-last-name': v?.last_name,
            'new-user-email-id': v?.email,
            'new-user-role': selectedType?.label,
          })
          segmentBatch({
            batch: [
              {
                type: 'identify',
                userId: v.email,
                traits: {
                  ...v,
                  role: selectedType?.label,
                },
              },
              {
                type: 'track',
                userId: v.email,
                event: 'Invited-to-Company',
                ...v,
                role: selectedType?.label,
              },
            ],
          }).then()
          toastr.success(r.data.message)
          onComplete()
          toggle()
        } else {
          toastr.error(
            r.data.data?.error ||
              r.data?.error ||
              r.data?.message ||
              'Error occurred',
            'Error',
          )
        }
      })
      .catch((e) => {
        toastr.error('An error occurred', 'Error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    setSelectedType(
      staticData?.roles.map((e) => ({ value: e.id, label: e.name }))[0],
    )
  }, [staticData])
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>New User</ModalHeader>
      <ModalBody>
        <AvForm
          className='form-horizontal'
          ref={formRef}
          onValidSubmit={handleSubmitInvite}
          id={addUserForm}
        >
          <FormGroup className='mb-md-4 mb-0 p-0 m-0 d-block' row>
            <TextInput
              name='email'
              type='email'
              className='form-control'
              label='Email'
              id='email'
            />
          </FormGroup>
          <FormGroup
            className='select2-container mb-md-4 mb-3 p-0 m-0 d-block'
            row
          >
            <label className='font-size-14 font-weight-bold'>Role</label>
            <RadioGroup
              defaultValue={selectedType}
              options={userTypeOptions}
              onChange={setSelectedType}
            />
          </FormGroup>
        </AvForm>
      </ModalBody>
      <ModalFooter className={isMobile ? 'fixed-bottom' : ''}>
        <Button
          outline
          type='button'
          color='light'
          onClick={toggle}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          color='primary'
          loading={loading}
          disabled={loading}
          formId={addUserForm}
        >
          Save changes
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default AddUserModal
