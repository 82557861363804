import cx from 'classnames'
import React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'

import './nav-tabs.scss'

export default function NavTabs({
  className,
  options,
  activeOption,
  onClickOption,
}) {
  return (
    <Nav tabs className={cx('rp-nav-tabs', className)}>
      {options?.map((option) => (
        <NavItem key={option?.value}>
          <NavLink
            className={cx(
              'font-size-14 text-gray-600 bg-transparent px-2 py-1',
              {
                'active rp-font-semibold cursor-default':
                  activeOption === option?.value,
              },
            )}
            onClick={() => onClickOption(option)}
            tag='button'
            type='button'
          >
            {option?.label}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  )
}
