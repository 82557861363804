import { format, isValid, parseISO } from 'date-fns'
import React from 'react'
import { Table } from 'reactstrap'
import styled from 'styled-components'

import { ReactComponent as TransactionsIllustration } from '../../../assets/images/transactions-illustration.svg'
import Button from '../../../components/ui/button'
import Loader from '../../../components/ui/loader'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'

function formatTrxDate(date) {
  const parsedDate = parseISO(date)
  const isValidDate = isValid(parsedDate)

  if (isValidDate) {
    return format(new Date(date), 'MMM dd, yyyy')
  } else {
    return date
  }
}

export default function LatestTransactions({
  data,
  withArrow,
  setSelectedTrx,
  setShow,
  isLoading,
}) {
  if (isLoading) {
    return <Loader minHeight='max(40vh, 440px)' />
  }

  if (!data || !Array.isArray(data) || data?.length <= 0) {
    return (
      <div
        style={{ minHeight: 'max(40vh, 440px)' }}
        className='align-items-center d-flex flex-column justify-content-center p-4 rp-font-gilroyB text-center'
      >
        <TransactionsIllustration />
        <h4>No transactions yet</h4>
        <p className='font-size-16 text-muted mb-0 pb-3'>
          Your transactions will show here
        </p>
      </div>
    )
  }

  function handleShowDetails(trx) {
    setSelectedTrx(getDetails(trx))
    setShow(true)
  }

  return (
    <>
      <div className='d-flex flex-column d-md-none gap-16'>
        {data.map((trx) => {
          return (
            <TransactionCard
              key={trx?.transaction_id}
              trx={trx}
              withArrow={withArrow}
              onShowDetails={() => {
                handleShowDetails(trx)
              }}
            />
          )
        })}
      </div>

      <Table
        responsive
        className='table-centered table-nowrap text-muted mb-0 d-none d-md-table'
      >
        <thead className='thead-light'>
          <tr>
            <th className='border-top-0 rounded' />
            <th className='border-top-0'>Merchant name</th>
            <th className='border-top-0'>Category</th>
            <th className='border-top-0'>Date</th>
            <th className='border-top-0'>Transaction Type</th>
            <th className='border-top-0'>Card Type</th>
            <th className='border-top-0 text-right'>Amount</th>
            <th className='border-top-0 rounded' />
          </tr>
        </thead>

        <tbody className='rounded'>
          {data.map((trx) => {
            return (
              <TransactionLine
                key={trx?.transaction_id}
                trx={trx}
                withArrow={withArrow}
                onShowDetails={() => {
                  handleShowDetails(trx)
                }}
              />
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

const TransactionInfo = styled.div`
  border-radius: 2px;
  transition: all 100ms ease-in-out;

  :hover {
    box-shadow: 0px 0px 0px 4px var(--slate-50);
    background-color: var(--slate-50);
  }
`

function getDetails(trx) {
  const formatter = getCurrencyFormatter(trx?.authorization?.total_currency)
  const amount = formatter.format(trx?.authorization?.total_amount)

  return {
    transaction_ref: trx?.transaction_ref,
    items: [
      {
        title: 'Details',
        data: [
          // { title: 'Balance', value: amount },
          {
            title: 'Amount',
            value: amount,
          },
          {
            title: 'Merchant name',
            value: trx?.merchant_details?.name,
          },
          {
            title: 'Category',
            value: getMerchantCategory(
              trx?.merchant_details?.merchant_category_code,
            ),
          },
          {
            title: 'Date',
            value: formatTrxDate(trx?.updated_at ?? trx.updatedAt),
          },
          {
            title: 'Transaction type',
            value: <TrxType trx={trx} />,
          },
          {
            title: 'Card type',
            value: trx?.card_form_factor,
          },
        ],
      },
      // {
      //   title: 'Status',
      //   data: [
      //     { title: 'Indicator', value: trx?.indicator },
      //     { title: 'Type', value: trx?.type },
      //   ],
      // },
    ],
  }
}

function Merchant({ logo, name }) {
  return (
    <div className='d-flex align-items-center flex-nowrap' style={{ gap: 14 }}>
      {!logo ? null : (
        <img className='rounded-circle avatar-xs' src={logo} alt='' />
      )}
      {!name ? null : <span style={{ color: '#4A4E5B' }}>{name}</span>}
    </div>
  )
}

function getMerchantCategory(mcc) {
  const mccNumber = Number(mcc)
  if (mccNumber <= 1499) {
    return 'Agricultural Services'
  } else if (mccNumber <= 2999) {
    return 'Contracted Services'
  } else if (mccNumber <= 4799) {
    return 'Transportation Services'
  } else if (mccNumber <= 4999) {
    return 'Utility Services'
  } else if (mccNumber <= 5599) {
    return 'Retail Outlet Services'
  } else if (mccNumber <= 5699) {
    return 'Clothing Stores'
  } else if (mccNumber <= 7299) {
    return 'Miscellaneous Stores'
  } else if (mccNumber <= 7999) {
    return 'Business Services'
  } else if (mccNumber <= 8999) {
    return 'Professional Services and Membership'
  } else if (mccNumber <= 9999) {
    return 'Government Services'
  }
  return 'Other'
}

const arrowUp = 'bx bx-up-arrow-alt text-success'
const arrowDown = 'bx bx-down-arrow-alt text-warning'

export const trxIcon = {
  Purchase: arrowDown,
  Offload: arrowDown,
  Withdraw: arrowDown,
  'Money Transfer Debit': arrowDown,

  'Top up': arrowUp,
  Topup: arrowUp,
}

function getIconFromTrxItem(trx) {
  const isReversed = trx?.is_reversed

  if (isReversed) {
    return trxIcon.Topup
  }

  return trxIcon[trx?.authorization?.transaction_type]
}

function getTrxTypeText(trx) {
  const isReversed = trx?.is_reversed
  const reversedText = isReversed ? ' (Reversed)' : ''
  return `${trx?.authorization?.transaction_type}${reversedText}`
}

function TrxType({ trx }) {
  const icon = getIconFromTrxItem(trx)

  return (
    <span className='align-items-center d-flex gap-4'>
      {!icon ? null : <i className={`${icon} font-size-24`} />}{' '}
      {getTrxTypeText(trx)}
    </span>
  )
}

export function TransactionLine({ trx, onShowDetails, withArrow }) {
  const formatter = getCurrencyFormatter(trx?.authorization?.total_currency)
  const amount = formatter.format(trx?.authorization?.total_amount)
  const icon = getIconFromTrxItem(trx)

  return (
    <tr>
      <td>
        <div
          style={{ width: 40, height: 40 }}
          className='align-items-center bg-slate-100 d-flex justify-content-center rounded-circle'
        >
          {!icon ? null : <i className={`${icon} font-size-24`} />}
        </div>
      </td>
      <td>
        <Merchant name={trx?.merchant_details?.name} />
      </td>
      <td>
        {getMerchantCategory(trx?.merchant_details?.merchant_category_code)}
      </td>
      <td>{formatTrxDate(trx?.updated_at ?? trx?.updatedAt)}</td>
      <td>{getTrxTypeText(trx)}</td>
      <td className='rp-capitalize'>{trx?.card_form_factor}</td>
      <td className='text-right'>{amount}</td>
      <td className='text-center'>
        {!withArrow ? null : (
          <button
            className='rp-btn-nostyle p-1 d-inline-flex align-items-center justify-content-center'
            onClick={onShowDetails}
          >
            <i className='bx bx-chevrons-right font-size-16 align-middle' />
          </button>
        )}
      </td>
    </tr>
  )
}

export function TransactionCard({ trx, onShowDetails, withArrow }) {
  const formatter = getCurrencyFormatter(trx?.authorization?.total_currency)
  const amount = formatter.format(trx?.authorization?.total_amount)

  const infoItems = [
    { label: 'Date', value: formatTrxDate(trx?.updated_at ?? trx.updatedAt) },
    {
      label: 'Merchant',
      value: <Merchant name={trx?.merchant_details?.name} />,
    },
    {
      label: 'Transaction Type',
      value: <TrxType trx={trx} />,
    },
    {
      label: 'Card Type',
      value: <span className='rp-capitalize'>{trx?.card_form_factor}</span>,
    },
    { label: 'Amount', value: amount },
  ]

  return (
    <div
      className='py-3 border border-light d-flex flex-column'
      style={{ gap: '1rem', borderRadius: 6 }}
    >
      <div className='d-flex flex-column px-3 gap-8'>
        {infoItems.map(({ label, value }, index) => {
          return (
            <TransactionInfo
              key={index}
              className='d-flex justify-content-between text-gray-h'
            >
              <div className='text-muted'>{label}</div>
              <div>{value}</div>
            </TransactionInfo>
          )
        })}
      </div>

      {!withArrow ? null : (
        <div className='px-3'>
          <Button
            color='light'
            outline
            onClick={onShowDetails}
            size='sm'
            iconRight={<i className='bx bx-chevron-right font-size-22' />}
            block
          >
            Details
          </Button>
        </div>
      )}
    </div>
  )
}
