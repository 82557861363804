import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { withRouter } from 'react-router'
// i18n
import { withTranslation } from 'react-i18next'

// Redux Store
import {
  changeSidebarType,
  showRightSidebarAction,
  toggleLeftmenu,
  updateAdminToken,
} from '../../../../store/actions'

import BurgerMenuPanel from '../../../../components/BurgerMenuPanel'
import { useFetch, useResizeForSideBar } from '../../../../helpers/hooks'
import { logoutAdmin } from '../../../../services/api'
import HeaderLogoLink from '../../../../components/Common/header-logo'

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false)
  const isMobile = useResizeForSideBar()

  const dispatch = useDispatch()
  const logout = useFetch({
    action: logoutAdmin,
    withAdminAccess: true,
    onComplete: () => {
      dispatch(updateAdminToken(null))
    },
  })
  return (
    <React.Fragment>
      <header id='page-topbar'>
        {!!isMobile && (
          <BurgerMenuPanel open={openMenu} hide={() => setOpenMenu(false)} />
        )}
        <div className='navbar-header pr-0 pr-md-2'>
          <div className='d-flex'>
            <HeaderLogoLink />
          </div>
          <div className='d-flex' style={{ gap: '0.25rem' }}>
            <button
              type='button'
              onClick={() => setOpenMenu(true)}
              className='btn font-size-16 header-item d-md-none'
            >
              <i className='fa fa-fw fa-bars'></i>
            </button>
            <button
              type='button'
              className='rp-btn-nostyle text-primary p-4'
              onClick={logout.startFetch}
            >
              {logout.isLoading ? (
                <i className='bx bx-loader bx-spin align-middle' />
              ) : (
                'Logout'
              )}
            </button>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default withRouter(
  connect(mapStatetoProps, {
    showRightSidebarAction,
    toggleLeftmenu,
    changeSidebarType,
  })(withTranslation()(Header)),
)
