import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { logoutUser } from '../../services/api'

import { logout } from '../../store/actions'
import { initUserProfile } from '../../store/profile/actions'

const Logout = (props) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.Account?.user)

  useEffect(() => {
    if (user?.token) {
      logoutUser(user?.token).then((res) => {})
      dispatch(logout())
      dispatch(initUserProfile())
      if (window.Intercom) {
        window.Intercom('shutdown')
      }
      window.analytics.reset()
    }
    props.history.push('/login')
  })

  return <></>
}

export default withRouter(Logout)
