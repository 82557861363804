import cx from 'classnames'
import React from 'react'
import { Alert, Col, Row } from 'reactstrap'

import alfardanLogo from '../../assets/images/alfardan.png'
import Loader from '../../components/ui/loader'
import { getCurrencyFormatter } from '../../utils/formatters/currency'

const WithdrawReview = ({
  onBack,
  bankInfo,
  loading,
  method,
  title = 'Withdraw',
  receptionLabel = 'You will receive:',
  loadingMinHeight = '30rem',
}) => {
  const formatter = getCurrencyFormatter(bankInfo?.currency?.code, undefined, {
    maximumSignificantDigits: method?.id === 5 ? 6 : undefined,
  })

  const usdFormatter = getCurrencyFormatter()
  const baseFormatter = getCurrencyFormatter(bankInfo?.base_currency?.code)

  if (loading) {
    return <Loader minHeight={loadingMinHeight} />
  }

  return (
    <Row className='justify-content-center'>
      <Col>
        <Row className='justify-content-center'>
          {!!bankInfo && (
            <Col className='font-size-14'>
              <div className='d-flex flex-column mb-3' style={{ gap: '1rem' }}>
                <div className='d-flex justify-content-between px-3 pt-3 mb-2'>
                  <h3
                    className='rp-font-gilroyB rp-font-bold font-size-18 m-0'
                    style={{ color: '#424652' }}
                  >
                    {title}
                  </h3>
                  {method?.id === 1 &&
                    bankInfo?.details?.provider === 'alfardan' && (
                      <div className='p-0 flex-column align-items-end'>
                        <div className='font-size-10 text-right text-muted justify-content-center'>
                          <span>Powered by</span>
                        </div>
                        <Row className='justify-content-center'>
                          <img height={36} src={alfardanLogo} alt='' />
                        </Row>
                      </div>
                    )}
                </div>
                {!bankInfo?.subtotal ? null : (
                  <InfoDisplay
                    label='Amount'
                    value={baseFormatter.format(bankInfo.subtotal)}
                    muted
                  />
                )}
                {!bankInfo?.fee ? null : (
                  <InfoDisplay
                    label='Transfer fee'
                    value={baseFormatter.format(bankInfo?.fee)}
                    muted
                  />
                )}

                {!bankInfo?.rate ? null : (
                  <InfoDisplay
                    label='Conversion rate'
                    value={baseFormatter.format(bankInfo?.rate)}
                    muted
                  />
                )}

                <InfoDisplay
                  label={receptionLabel}
                  value={
                    !bankInfo?.details?.coinbase
                      ? `${
                          !bankInfo?.total_usd
                            ? ''
                            : `(${usdFormatter.format(bankInfo?.total_usd)})`
                        } ${formatter.format(bankInfo?.total)}`
                      : `${
                          !bankInfo?.total_usd
                            ? ''
                            : usdFormatter.format(bankInfo?.total_usd)
                        } in ${bankInfo?.currency?.code}`
                  }
                />

                {bankInfo?.details?.should_arrive && (
                  <InfoDisplay
                    label='Should arrive'
                    value={bankInfo?.details?.should_arrive}
                    muted
                  />
                )}
              </div>
              <div className='d-flex flex-column mb-3' style={{ gap: '1rem' }}>
                <div
                  className='mx-3'
                  style={{ borderTop: '1px solid #E7E8F2' }}
                />

                {!method ? null : (
                  <>
                    <div className='d-flex justify-content-between px-3'>
                      <div className='font-weight-bold m-0 p-0'>Method</div>
                      <button
                        type='button'
                        className='rp-btn-nostyle text-primary rp-font-gilroyB rp-font-bold p-0'
                        onClick={onBack}
                      >
                        Edit
                      </button>
                    </div>
                    <div
                      className='mx-3'
                      style={{ borderTop: '1px solid #E7E8F2' }}
                    />

                    <div className='d-flex flex-column mx-3'>
                      {bankInfo?.warnings?.map((warning, index) => {
                        return (
                          <Alert
                            className='mb-0 p-2 font-size-12 bg-soft-primary border border-soft-primary text-primary'
                            color='primary'
                            key={index}
                          >
                            <p
                              dangerouslySetInnerHTML={{ __html: warning }}
                              className='mb-0'
                            />
                          </Alert>
                        )
                      })}
                    </div>

                    <InfoDisplay label='Method' value={method?.label} muted />
                  </>
                )}

                {bankInfo?.details?.details?.map((e, i) => (
                  <InfoDisplay
                    key={`detail-${i}`}
                    label={e?.name}
                    value={e?.value}
                    muted
                  />
                ))}
              </div>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  )
}

function InfoDisplay({ value, label, muted }) {
  return (
    <div
      className={cx('d-flex justify-content-between font-weight-light px-3', {
        'text-muted': muted,
      })}
    >
      <p className='mb-0 rp-capitalize'>{label}</p>
      <p className='mb-0 text-gray-h'>{value}</p>
    </div>
  )
}

export default WithdrawReview
