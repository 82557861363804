import cx from 'classnames'
import React from 'react'
import { Badge, Card, CardBody, Col, Container, Row } from 'reactstrap'

import { useCustomResize } from '../../helpers/hooks'

const StepContainer = ({
  title,
  description,
  cardClassName = '',
  children,
  index,
  total,
  loading,
  onNext,
  onBack,
  noBack,
  backText = 'Back',
  nextText = 'Continue',
  nextType = 'button',
  nextFormId,
  disableNext = false,
  minHeight,
  isFlat,
  noFooter,
  width,
}) => {
  const isMobile = useCustomResize({ minWidth: 768 })

  return (
    <>
      <Container fluid>
        {title && (
          <Row
            className={`${
              !isMobile
                ? 'justify-content-center'
                : 'justify-content-between align-items-center'
            } py-4`}
          >
            {isMobile ? (
              <h2
                className='font-weight-bold mb-0'
                style={{ color: '#424652' }}
              >
                {title}
              </h2>
            ) : (
              <h1
                className='font-weight-bold mb-0'
                style={{ color: '#424652' }}
              >
                {title}
              </h1>
            )}
            {isMobile && total > 1 && !isNaN(index) && (
              <Badge className='bg-soft-primary text-primary p-2'>{`${
                index + 1
              }/${total}`}</Badge>
            )}
          </Row>
        )}
        {!description ? null : (
          <Row className='justify-content-center px-4 pb-4 pt-0'>
            {description}
          </Row>
        )}
      </Container>

      <Card
        style={{ maxWidth: 820, margin: '0 auto', width }}
        className={cx({ 'step-container-content': isMobile }, cardClassName)}
      >
        <Col className='p-0'>
          <CardBody
            className={`${isFlat ? 'p-0' : ' p-4'}`}
            style={{ minHeight }}
          >
            <Col className='p-0'>{children}</Col>
          </CardBody>
          {!noFooter && (
            <div
              className={`modal-footer ${
                isMobile ? 'fixed-bottom bg-white' : ''
              }`}
            >
              <Col>
                <Row className='justify-content-between'>
                  {!noBack && index !== 0 ? (
                    <button
                      onClick={onBack}
                      type='button'
                      className='btn btn-outline-light'
                    >
                      {backText}
                    </button>
                  ) : (
                    <div />
                  )}
                  <button
                    disabled={disableNext || loading}
                    onClick={onNext}
                    className='btn btn-primary'
                    type={nextType}
                    form={nextFormId}
                  >
                    {loading && (
                      <i className='bx bx-loader bx-spin font-size-16 align-middle mx-2' />
                    )}
                    {nextText}
                  </button>
                </Row>
              </Col>
            </div>
          )}
        </Col>
      </Card>
    </>
  )
}

export default StepContainer
