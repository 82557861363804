import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { Button, Col, Container, Row } from 'reactstrap'
import styled from 'styled-components'

import Alert from '../../../../components/ui/alert'
import { useResize } from '../../../../helpers/hooks'
import { getCurrencyFormatter } from '../../../../utils/formatters/currency'
import { countryCodeToName } from '../../utils/localizationHelpers'
import EmployeeTaxView from './EmployeeTaxView'
import EmployerTaxView from './EmployerTaxView'

export const numberWithCommas = (number) =>
  Math.round(number)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

const StyledSwitch = styled(Button)`
  background-color: ${(props) => (props.$checked ? '#114EF7' : '#fff')};
  padding: 9px 58px;
  border: ${(props) =>
    props.$checked ? '1px solid #114EF7' : '1px solid #E7E8F2'};
  margin: 0;
  margin-bottom: ${(props) => (props.$isMobile ? '10px' : 0)};
  border-radius: 0;
  border-top-left-radius: ${(props) => (props.$left ? '20px' : 0)};
  border-bottom-left-radius: ${(props) => (props.$left ? '20px' : 0)};
  border-top-right-radius: ${(props) => (props.$right ? '20px' : 0)};
  border-bottom-right-radius: ${(props) => (props.$right ? '20px' : 0)};

  &:hover {
    background-color: #114ef7 !important;

    & > p {
      color: #fff !important;
    }
  }
`

const QuoteView = ({ quote }) => {
  const [monthly, setMonthly] = useState(false)
  const isMobile = useResize()

  const formatter = getCurrencyFormatter(
    quote?.input?.convert_to?.code,
    'en-US',
    { minimumFractionDigits: 0, currencyDisplay: 'code' },
  )

  return (
    <>
      <ReactTooltip effect='solid' />

      <Container className='mx-auto mx-md-0 py-4 border-top'>
        <Row className='justify-content-center'>
          <StyledSwitch
            $isMobile={isMobile}
            onClick={() => {
              setMonthly((m) => !m)
            }}
            $checked={monthly === false}
            $left={true}
            type='button'
          >
            <p
              style={{
                color: monthly === false ? '#fff' : '#777F9E',
                fontWeight: monthly === false ? 'bold' : 'normal',
              }}
              className='mb-0'
            >
              Month
            </p>
          </StyledSwitch>
          <StyledSwitch
            $isMobile={isMobile}
            onClick={() => {
              setMonthly((m) => !m)
            }}
            $checked={monthly === true}
            $right={true}
            type='button'
          >
            <p
              style={{
                color: monthly === true ? '#fff' : '#777F9E',
                fontWeight: monthly === true ? 'bold' : 'normal',
              }}
              className='mb-0'
            >
              Year
            </p>
          </StyledSwitch>
          <Row className='w-100 justify-content-center py-3'>
            <Row className='w-100 justify-content-center'>
              <div>
                <span
                  style={{ fontFamily: 'inter' }}
                  className='font-light font-size-14 font-weight-bold  text-gray-500 '
                >
                  Gross annual salary{' '}
                </span>
                <span
                  style={{ fontFamily: 'inter' }}
                  className='font-light font-size-14  font-weight-bold text-gray-500 '
                >
                  {formatter.format(quote?.input?.salary)}
                </span>
              </div>
            </Row>
          </Row>
        </Row>

        <Row
          className='justify-content-center mx-n4.5'
          style={{ gap: '2rem 0' }}
        >
          <Col md={6} className='px-2'>
            <EmployerTaxView quote={quote} isMonthly={!monthly} />
          </Col>

          <Col md={6} className='px-2'>
            <EmployeeTaxView quote={quote} isMonthly={!monthly} />
          </Col>
        </Row>

        <Alert
          color='light'
          className='bg-transparent border-transparent text-secondary px-1 mt-3 mb-0 font-size-12'
        >
          Employment cost simulation for {countryCodeToName(quote?.input?.code)}{' '}
          is an estimate based on the country’s local taxation and compliance,
          excluding RemotePass management fee & taxes. Contributions may vary
          based on employee’s personal information.
        </Alert>
      </Container>
    </>
  )
}

export default QuoteView
