import Moment from 'moment'
import { extendMoment } from 'moment-range'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Badge, Col, Row, Spinner } from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'

import ActionsDropdown from '../../../components/ActionsDropdown'
import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import Toggle from '../../../components/Forms/Toggle/Toggle'
import NoContent from '../../../components/NoContent'
import StyledTd from '../../../components/Table/StyledTd'
import StyledTh from '../../../components/Table/StyledTh'
import TableComp from '../../../components/Table/TableComp'
import { StyledH5 } from '../../../components/Typo'
import Button from '../../../components/ui/button'
import { userTypes } from '../../../helpers/enum'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  approveTimeOff,
  cancelTimeOff,
  deleteTimeOff,
  getAdminTimesOff,
  getFullTimeTimeOffs,
  getTimesOff,
  rejectTimeOff,
} from '../../../services/api'
import TimeOffModal from '../components/TimeOffModal'
import timeOffEmpty from './../../../assets/images/time-off-empty.svg'

const moment = extendMoment(Moment)

export const getTimeOffStatusColor = (status) => {
  switch (status) {
    case 'Pending approval':
      return 'warning'
    case 'Approved':
      return 'success'
    case 'Declined':
      return 'danger'
    case 'Unpaid':
      return 'danger'
    case 'Cancelled':
      return 'danger'
    default:
      return 'primary'
  }
}

const TimesOff = () => {
  const [isOpen, setIsOpen] = useState(false)
  const contract = useSelector((state) => state?.Contract?.details)
  const isEmployee = useSelector(
    (state) => state.userProfile?.userProfile?.contractor_type === 'employee',
  )
  const location = useLocation()
  const isAdmin = location.pathname?.startsWith('/admin/')

  const user = useSelector((state) => state?.Account?.user)
  const { hasAccess } = usePermissions()
  const [toExclude, setToExclude] = useState([])
  const timesOff = useFetch(
    {
      action: isAdmin
        ? getAdminTimesOff
        : isEmployee
        ? getFullTimeTimeOffs
        : getTimesOff,
      withAdminAccess: isAdmin,
      initResult: [],
      autoFetch: contract || isEmployee,
      body: { contractId: contract?.id },
      onComplete: (data) => {
        const toExclude = data?.list
          ?.filter((t) => [2, 3].includes(t.status?.id))
          .reduce((prev, curr) => {
            const start = moment(curr?.from, 'YYYY-MM-DD')
            const end = moment(curr?.to, 'YYYY-MM-DD')
            const range = moment.range(start, end)
            const arrayOfDates = Array.from(range.by('days'))
            const toBeExcluded = arrayOfDates.map((e) => e.toDate())
            return [...prev, ...toBeExcluded]
          }, [])
        setToExclude(toExclude)
      },
    },
    [contract?.id, isEmployee],
  )

  const Days = () =>
    timesOff.data?.list?.length === 0 ? (
      <div />
    ) : (
      <div className='py-3 d-flex gap-16 font-size-14 rp-font-gilroyB justify-content-md-end'>
        <div>
          <span className='px-1'>Paid: </span>
          {!timesOff?.data?.days_paid || timesOff?.data?.days_paid === 0
            ? '--'
            : `${timesOff?.data?.days_paid} days`}
        </div>
        <div>
          <span className='px-1'>UnPaid: </span>
          {!timesOff?.data?.days_unpaid || timesOff?.data?.days_unpaid === 0
            ? '--'
            : `${timesOff?.data?.days_unpaid} days`}
        </div>
      </div>
    )
  return (
    <>
      {(!contract && !isEmployee) || timesOff.isLoading ? (
        <Col style={{ minHeight: '50vh' }}>
          <Row
            style={{ minHeight: '50vh' }}
            className='justify-content-center align-items-center'
          >
            <Spinner type='grow' className='mr-2' color='primary' />
          </Row>
        </Col>
      ) : (
        <>
          {timesOff?.data?.list?.length === 0 ? (
            <div className='p-2'>
              <NoContent
                subtitle='Submitted time off requests will be shown here'
                image={timeOffEmpty}
                withAction={
                  contract?.status?.id === 7
                    ? false
                    : !(
                        !(
                          hasAccess(permissions.addExpense) ||
                          contract?.can_approve ||
                          user?.type === userTypes.CONTRACTOR
                        ) ||
                        isAdmin ||
                        (contract?.type === 'Full Time' &&
                          user?.type === userTypes.CONTRACTOR &&
                          !isEmployee)
                      )
                }
                actionTitle='Add time off'
                onAction={() => {
                  setIsOpen(true)
                }}
                advanced={false}
              />
            </div>
          ) : (
            <Container>
              <Col>
                <Row className='align-items-center m-0 justify-content-between py-3'>
                  <StyledH5 min='16px' max='24px'>
                    Time Off
                  </StyledH5>
                  <Col className='d-none d-md-block'>
                    <Days />
                  </Col>
                  {contract?.status?.id === 7 ? null : (
                    <button
                      disabled={
                        !(
                          hasAccess(permissions.addTimeOff) ||
                          contract?.can_approve ||
                          user?.type === userTypes.CONTRACTOR
                        ) ||
                        isAdmin ||
                        (contract?.type === 'Full Time' &&
                          user?.type === userTypes.CONTRACTOR &&
                          !isEmployee)
                      }
                      className='btn btn-primary'
                      onClick={() => setIsOpen(true)}
                    >
                      Add time off
                    </button>
                  )}
                  <Col sm={12} className='d-md-none px-0'>
                    <Days />
                  </Col>
                </Row>
              </Col>
              {timesOff.isLoading ? (
                <Col style={{ minHeight: '50vh' }}>
                  <Row
                    style={{ minHeight: '50vh' }}
                    className='justify-content-center align-items-center'
                  >
                    <Spinner type='grow' className='mr-2' color='primary' />
                  </Row>
                </Col>
              ) : (
                <>
                  <Col
                    className='px-3 pb-3 w-100 d-md-none'
                    style={{
                      backgroundColor: '#FFFFFF',
                      boxShadow: '0px 1px 0px #DFE1E6',
                      borderRadius: 4,
                    }}
                  >
                    {timesOff.data?.list?.map((order, key) => {
                      return (
                        <Line
                          key={'_order_' + key}
                          item={order}
                          onUpdate={() => {
                            timesOff.startFetch({ contractId: contract?.id })
                          }}
                        />
                      )
                    })}
                  </Col>
                  <Col className='p-0 d-none d-md-block'>
                    <div className={!isAdmin && 'table-with-dropdown'}>
                      <div className='table-responsive'>
                        <TableComp>
                          <thead className='thead-light'>
                            <tr
                              style={{
                                borderTop: 'hidden',
                                borderBottom: '1px solid #E7E8F2',
                              }}
                            >
                              <StyledTh>Start Date</StyledTh>
                              <StyledTh>End Date</StyledTh>
                              <StyledTh>Amount</StyledTh>
                              <StyledTh>Status</StyledTh>
                              <StyledTh>Days</StyledTh>
                              <StyledTh>Deducted</StyledTh>
                              <StyledTh />
                              <StyledTh />
                            </tr>
                          </thead>
                          <tbody>
                            {timesOff.data?.list?.map((order, key) => {
                              return (
                                <Line
                                  key={'_order_' + key}
                                  item={order}
                                  onUpdate={() => {
                                    timesOff.startFetch({
                                      contractId: contract?.id,
                                    })
                                  }}
                                />
                              )
                            })}
                          </tbody>
                        </TableComp>
                      </div>
                    </div>
                  </Col>
                </>
              )}
            </Container>
          )}
        </>
      )}
      {isOpen && (
        <TimeOffModal
          show={isOpen}
          hide={() => {
            setIsOpen(false)
            timesOff.startFetch({ contractId: contract?.id }, true)
          }}
          toExclude={toExclude}
        />
      )}
    </>
  )
}

const StyledCard = styled.div`
  background-color: #ffffff;
  border: 1px solid #e7e8f2;
  box-sizing: border-box;
  border-radius: 4px;
`

const Line = ({ onUpdate, item, toExclude }) => {
  const location = useLocation()
  const isAdmin = location.pathname?.startsWith('/admin/')
  const contract = useSelector((state) => state?.Contract?.details)
  const user = useSelector((state) => state?.Account?.user)
  const isEmployee = useSelector(
    (state) => state.userProfile?.userProfile?.contractor_type === 'employee',
  )
  const [showCancel, setShowCancel] = useState(false)
  const { hasAccess } = usePermissions()

  const [editing, setEditing] = useState(false)
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: contract?.currency?.code || 'USD',
  })
  const remove = useFetch({
    action: deleteTimeOff,
    onComplete: onUpdate,
  })
  const approve = useFetch({
    action: approveTimeOff,
    onComplete: onUpdate,
  })
  const decline = useFetch({
    action: rejectTimeOff,
    onComplete: onUpdate,
  })
  const removeTimeOff = () => {
    if (!isAdmin) {
      remove.startFetch({
        time_off_id: item?.id,
      })
    }
  }
  const handleDeclineTimeOff = () => {
    decline.startFetch({
      time_off_id: item?.id,
    })
  }
  const handleApproveTimeOff = (cycles) => {
    approve.startFetch({
      time_off_id: item?.id,
      cycles,
    })
  }

  const canDelete =
    item?.status?.id === 2 &&
    ((contract?.type === 'Full Time' && isEmployee) ||
      contract?.type !== 'Full Time')

  const cancel = useFetch({
    action: cancelTimeOff,
    onError: toastr.error,
    onComplete: onUpdate,
  })

  const [showActions, setShowActions] = useState(false)
  const actions = []
  if (canDelete) {
    actions[0] = {
      // eslint-disable-next-line react/display-name
      component: () => <div className='text-danger font-size-16'>Delete</div>,
      onClick: removeTimeOff,
    }
  }

  if (item.can_cancel) {
    actions[1] = {
      // eslint-disable-next-line react/display-name
      component: () => <div className='text-danger font-size-16'>Cancel</div>,
      onClick: () => setShowCancel(true),
    }
  }

  const infoItems = [
    { label: 'From', value: item?.from },
    { label: 'To', value: item?.to },
    { label: 'Amount', value: formatter.format(item?.total) },
    { label: 'Days', value: item?.days },
  ]

  return (
    <>
      <ConfirmationModal
        toggle={() => setShowCancel(false)}
        isOpen={showCancel}
        title='Cancel Time off'
        message={
          <>
            <p>Are you sure you want to cancel this time off?</p>
            <p>
              Any associated deductions with this time off will be cancelled as
              well
            </p>
          </>
        }
        onConfirm={() => cancel.startFetch({ time_off_id: item?.id })}
        confirmLoading={cancel.isLoading}
        buttonColor='danger'
        caption='Cancel Time off'
        negativeCaption='Ignore'
      />

      <StyledCard className='mb-3 d-md-none'>
        <div className='p-3 border-bottom d-flex justify-content-between align-items-center'>
          <Badge
            className={`font-size-12 rounded bg-soft-${getTimeOffStatusColor(
              item.status?.name,
            )}`}
            color='white'
            pill
          >
            <p
              style={{ paddingTop: 2, paddingBottom: 2 }}
              className={`mb-0  text-${getTimeOffStatusColor(
                item.status?.name,
              )}`}
            >
              {item.status?.name}
            </p>
          </Badge>
          <div className='text-danger font-size-20 ml-3'>
            {item?.status?.name !== 'Approved' &&
              (hasAccess(permissions.deleteTimeOff) ||
                user?.type === userTypes.CONTRACTOR) && (
                <>
                  {remove.isLoading ? (
                    <i className='bx bx-loader bx-spin' />
                  ) : (
                    <button
                      className='rp-btn-nostyle p-1 text-current d-flex'
                      type='button'
                      onClick={removeTimeOff}
                    >
                      <i className='bx bx-trash-alt' />
                    </button>
                  )}
                </>
              )}
          </div>
        </div>
        <div className='p-3'>
          {infoItems.map(({ label, value }) => {
            return (
              <div
                className=' mb-3 d-flex justify-content-between align-items-center'
                key={value}
              >
                <p className='mb-0 font-size-14 font-weight-light'>{label}</p>
                <p className='mb-0 font-size-14 font-weight-light'>{value}</p>
              </div>
            )
          })}

          <div className='d-flex justify-content-between align-items-center'>
            <p className='mb-0 font-size-14 font-weight-light'>Deducted</p>
            <Toggle
              id={`SwitchApplied${item?.id}`}
              disabled
              check={item?.applied}
              change={() => {}}
            />
          </div>

          {item?.status?.name === 'Pending approval' &&
            user?.type === userTypes.COMPANY && (
              <div className='d-flex align-items-center justify-content-end pt-3 gap-8'>
                <Button
                  color='danger'
                  size='sm'
                  style={{ minWidth: 100 }}
                  disabled={
                    !(
                      hasAccess(permissions.rejectTimeOff) ||
                      contract?.can_approve
                    ) ||
                    isAdmin ||
                    decline.isLoading
                  }
                  onClick={handleDeclineTimeOff}
                  loading={decline.isLoading}
                >
                  Decline
                </Button>
                <Button
                  color='success'
                  size='sm'
                  style={{ minWidth: 100 }}
                  disabled={
                    !(
                      hasAccess(permissions.approveTimeOff) ||
                      contract?.can_approve
                    ) ||
                    isAdmin ||
                    approve.isLoading
                  }
                  onClick={() => {
                    if (contract.type === 'Full Time') {
                      handleApproveTimeOff()
                    } else {
                      setEditing(true)
                    }
                  }}
                  loading={approve.isLoading}
                >
                  Approve
                </Button>
              </div>
            )}
        </div>
      </StyledCard>

      <tr
        style={{ borderBottom: '1px solid #E7E8F2' }}
        className='d-none d-md-table-row'
      >
        <StyledTd>{item?.from}</StyledTd>
        <StyledTd>{item?.to}</StyledTd>
        <StyledTd>
          {item?.total === 0 ? '--' : formatter.format(item?.total)}
        </StyledTd>
        <StyledTd>
          <div>
            <Badge
              className={`font-size-12 rounded bg-soft-${getTimeOffStatusColor(
                item?.status?.name,
              )}`}
              color='white'
              pill
            >
              <p
                style={{ paddingTop: 2, paddingBottom: 2 }}
                className={`mb-0 text-${getTimeOffStatusColor(
                  item?.status?.name,
                )}`}
              >
                {item?.status?.name}
              </p>
            </Badge>
          </div>
        </StyledTd>
        <StyledTd>{item?.days}</StyledTd>
        <StyledTd>
          <Toggle
            id={`SwitchApplied${item?.id}`}
            disabled
            check={item?.applied}
            change={() => {}}
          />
        </StyledTd>
        <StyledTd>
          {item?.status?.name === 'Pending approval' &&
            user?.type === userTypes.COMPANY && (
              <div className='d-flex flex-wrap' style={{ gap: '0.325rem' }}>
                <button
                  className='btn btn-danger btn-sm rounded'
                  onClick={handleDeclineTimeOff}
                  disabled={
                    !(
                      hasAccess(permissions.rejectTimeOff) ||
                      contract?.can_approve
                    ) || isAdmin
                  }
                >
                  {decline.isLoading && <i className='bx bx-loader bx-spin' />}{' '}
                  Decline
                </button>
                <button
                  className='btn btn-success btn-sm rounded'
                  onClick={() => {
                    if (contract.type === 'Full Time') {
                      handleApproveTimeOff()
                    } else {
                      setEditing(true)
                    }
                  }}
                  disabled={
                    !(
                      hasAccess(permissions.approveTimeOff) ||
                      contract?.can_approve
                    ) || isAdmin
                  }
                >
                  {approve.isLoading && <i className='bx bx-loader bx-spin' />}{' '}
                  Approve
                </button>
              </div>
            )}
        </StyledTd>
        <StyledTd>
          {(item.can_cancel || canDelete) && (
            <>
              {remove.isLoading ? (
                <i className='bx bx-loader bx-spin text-danger font-size-16 ml-3' />
              ) : (
                <ActionsDropdown
                  options={actions}
                  isOpen={showActions}
                  toggle={() => setShowActions((t) => !t)}
                />
              )}
            </>
          )}
        </StyledTd>
      </tr>

      {editing && (
        <TimeOffModal
          show={editing}
          hide={(cycles) => {
            setEditing(false)
            if (cycles) {
              handleApproveTimeOff(cycles)
            }
          }}
          toExclude={toExclude}
          data={item}
        />
      )}
    </>
  )
}

const Container = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 1px 0px #dfe1e6;
  border-radius: 4px;
`
export default TimesOff
