import { AvField, AvForm } from 'availity-reactstrap-validation'
import React, { useCallback, useMemo, useState } from 'react'
import { slide as Slide } from 'react-burger-menu'
import {
  Card,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Popover,
  Spinner,
} from 'reactstrap'
import toastr from 'toastr'

import BadgeX from '../../../../components/Table/BadgeX'
import Button from '../../../../components/ui/button'
import DataTable from '../../../../components/ui/data-table'
import { useFetch } from '../../../../helpers/hooks'
import {
  addBeneficiary as addBeneficiaryAction,
  getAllBeneficiaries,
  getBankDetails as getBankDetailsAction,
} from '../../../../services/api'

export default function Beneficiaries() {
  const [creatingId, setCreatingId] = useState(null)
  const [missingFields, setMissingFields] = useState(null)
  const [bankAccountDetails, setBankAccountDetails] = useState(null)

  const {
    data: beneficiaries,
    isLoading,
    startFetch: refreshBeneficiariesList,
  } = useFetch({
    action: getAllBeneficiaries,
    withAdminAccess: true,
    autoFetch: true,
  })

  const { startFetch: addBeneficiary } = useFetch({
    action: addBeneficiaryAction,
    withAdminAccess: true,
    checkSuccess: true,
    onComplete: (data, body) => {
      if (data?.missing_info?.length > 0) {
        setMissingFields({ body, fields: data?.missing_info })
      } else {
        refreshBeneficiariesList()
      }
      setCreatingId(null)
    },
    onError: (error) => {
      setCreatingId(null)
      toastr.error(error)
    },
  })

  const handleAddBeneficiary = useCallback(
    function handleAddBeneficiary(data, missingInfos) {
      const id = data?.id
      setCreatingId(id)

      const body = { bank_account_id: id, provider: data?.provider }
      if (missingFields) {
        body.missing_infos = missingInfos
      }

      addBeneficiary(body)
    },
    [addBeneficiary, missingFields],
  )

  function handleAddBeneWithMissingInfo(values) {
    const data = {
      id: missingFields?.body?.bank_account_id,
      provider: missingFields?.body?.provider,
    }

    setMissingFields(null)

    const missingInfo = Object.entries(values).map(([key, value]) => ({
      key,
      value,
    }))
    handleAddBeneficiary(data, missingInfo)
  }

  const {
    startFetch: getBankDetails,
    data: bankDetails,
    isLoading: loadingDetails,
  } = useFetch({
    action: getBankDetailsAction,
    withAdminAccess: true,
  })

  const showBankDetails = useCallback(
    (bankAccountId) => {
      getBankDetails({ id: bankAccountId })
      document.body.classList.add('modal-open')
      setBankAccountDetails([])
    },
    [getBankDetails],
  )

  const columns = useMemo(() => {
    return [
      {
        Header: 'Bank Account ID',
        accessor: 'id',
        Cell: ({ cellData: bankAccountId }) => {
          return (
            <button
              className='rp-btn-nostyle text-primary'
              onClick={() => showBankDetails(bankAccountId)}
            >
              {bankAccountId}
            </button>
          )
        },
      },
      { Header: 'Holder Name', accessor: 'holder_name' },
      { Header: 'Country', accessor: 'country' },
      { Header: 'Currency', accessor: 'currency' },
      { Header: 'Provider', accessor: 'provider' },
      {
        Header: 'Action',
        style: { width: 200 },
        Cell: ({ rowData }) => {
          return (
            <ActionsColumn
              loading={creatingId === rowData?.id}
              onClickAction={() => handleAddBeneficiary(rowData)}
              rowData={rowData}
            />
          )
        },
      },
    ]
  }, [creatingId, handleAddBeneficiary, showBankDetails])

  return (
    <div className='page-content'>
      <div
        className='d-flex justify-content-between'
        style={{ marginBottom: '2rem' }}
      >
        <h1 className='mb-0 rp-font-gilroyB'>Beneficiaries</h1>
      </div>

      <Card className='p-3'>
        {isLoading ? (
          <div
            style={{ minHeight: '50vh' }}
            className='d-flex justify-content-center align-items-center'
          >
            <Spinner type='grow' color='primary' />
          </div>
        ) : (
          <DataTable responsive columns={columns} data={beneficiaries} />
        )}

        <MissingFieldsModal
          isOpen={!!missingFields}
          fields={missingFields?.fields}
          onSubmit={handleAddBeneWithMissingInfo}
          toggle={() => setMissingFields(null)}
        />
      </Card>

      <BankDetailsSlider
        isOpen={!!bankAccountDetails}
        toggle={() => setBankAccountDetails(null)}
        details={bankDetails}
        isLoading={loadingDetails}
      />
    </div>
  )
}

const missingFieldsFormId = 'missingFieldsFormId'
function MissingFieldsModal({ fields = [], isOpen, toggle, onSubmit }) {
  function handleSubmit(_, values) {
    onSubmit(values)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered unmountOnClose>
      <ModalHeader toggle={toggle}>Missing Fields</ModalHeader>
      <ModalBody>
        <AvForm id={missingFieldsFormId} onValidSubmit={handleSubmit}>
          {fields.map(({ id, description }) => (
            <AvField
              key={id}
              name={id}
              label={
                <>
                  {id}{' '}
                  <span className='font-weight-light text-muted'>
                    ({description})
                  </span>
                </>
              }
              required
            />
          ))}
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <Button color='light' outline onClick={toggle}>
          Cancel
        </Button>
        <Button type='submit' formId={missingFieldsFormId}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

function BankDetailsSlider({ details = [], isLoading, isOpen, toggle }) {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        zIndex: 1500,
      }}
    >
      <Slide
        onClose={() => {
          toggle?.()
          document.body.classList.remove('modal-open')
        }}
        className='bg-white'
        styles={{
          bmMenu: { padding: '1.5rem' },
          bmItemList: { height: 'auto' },
        }}
        isOpen={isOpen}
        right
      >
        {isLoading ? (
          <div
            style={{ minHeight: '100vh' }}
            className='d-flex justify-content-center align-items-center'
          >
            <Spinner color='primary' />
          </div>
        ) : (
          <>
            <h3 className='mb-3'>Bank account details</h3>
            {details.map(({ name, value }) => {
              return (
                <FormGroup key={name}>
                  <Label tag='div' className='mb-2'>
                    {name}
                  </Label>
                  <Input value={value} readOnly />
                </FormGroup>
              )
            })}
          </>
        )}
      </Slide>
    </div>
  )
}

function ActionsColumn({ loading, onClickAction, rowData }) {
  if (rowData?.kyc_verification === true) {
    return (
      <>
        <div>KYC In Progress</div>

        {rowData?.kyc_requirements?.map(({ issues }, index) => {
          return (
            <KycRequirement
              key={index}
              id={`${rowData?.id}-${index}`}
              issues={issues}
            />
          )
        })}
      </>
    )
  }

  return (
    <>
      <Button
        size='sm'
        outline
        loading={loading}
        disabled={loading}
        onClick={onClickAction}
      >
        Add beneficiary
      </Button>
    </>
  )
}

function KycRequirement({ issues, id }) {
  const [isOpen, setIsOpen] = useState(false)

  const targetId = `kyc-requirement-${id}`

  function openPopover() {
    setIsOpen(true)
  }
  function closePopover() {
    setIsOpen(false)
  }
  function togglePopover() {
    setIsOpen((open) => !open)
  }

  return (
    <>
      <Popover
        placement='top'
        isOpen={isOpen}
        trigger='legacy'
        target={targetId}
        toggle={togglePopover}
        popperClassName='shadow-sm'
      >
        <IssuesDisplay issues={issues} />
      </Popover>

      <BadgeX
        className='font-size-12 cursor-pointer'
        status='info'
        id={targetId}
        onMouseEnter={openPopover}
        onMouseLeave={closePopover}
      >
        More info
      </BadgeX>
    </>
  )
}

export function IssuesDisplay({ issues }) {
  return (
    <div className='p-3'>
      {issues?.map((issue, index) => {
        return (
          <div key={index}>
            <h6 className='font-size-14 mb-3 rp-font-gilroyB text-gray-h'>
              {issue?.title}
            </h6>
            <h6 className='font-size-12 rp-font-light text-gray-h'>
              Requirements:
            </h6>
            <div
              className='d-flex flex-column text-muted'
              style={{ gap: '0.5rem' }}
            >
              {issue?.requirements.map((req, i, arr) => {
                return (
                  <div key={i}>
                    <h6 className='text-current'>
                      {req.requirement_description}
                    </h6>
                    <ul className='mb-0'>
                      {req?.sub_requirements.map((subreq) => {
                        return (
                          <li key={subreq?.requirement_id}>
                            {subreq?.requirement_description}
                          </li>
                        )
                      })}
                    </ul>
                    {i < arr.length - 1 && (
                      <span className='mt-2 mb-1 h6'>{issue.operator}</span>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}
