import React, { forwardRef } from 'react'

// eslint-disable-next-line react/display-name
const Toolbar = forwardRef(({ quillInstance }, ref) => {
  return (
    <div
      ref={ref}
      style={{
        position: 'fixed',
        backgroundColor: 'white',
        width: '100%',
        height: '50px',
        alignItems: 'center',
        display: quillInstance.current ? 'flex' : 'none',
        zIndex: 9,
      }}
      className='border-bottom border-light'
    >
      {/* <span className="ql-formats"> */}
      {/*    <select className="ql-font"></select> */}
      {/*    <select className="ql-size"></select> */}
      {/* </span> */}
      <span className='ql-formats'>
        <button className='ql-bold'></button>
        <button className='ql-italic'></button>
        <button className='ql-underline'></button>
      </span>
      <span className='ql-formats'>
        <button className='ql-list' value='ordered'></button>
        <button className='ql-indent' value='-1'></button>
        <button className='ql-indent' value='+1'></button>
      </span>
      <span className='ql-formats'>
        <button className='ql-link'></button>
      </span>
    </div>
  )
})

export default Toolbar
