import React from 'react'
import { Modal } from 'reactstrap'
import { submitMilestone } from '../../../services/api'
import { useSelector } from 'react-redux'
import { useResize } from '../../../helpers/hooks'

const SubmitMilestoneModal = ({
  toggle,
  isOpen,
  milestone,
  onMilestoneSubmitted,
  data,
}) => {
  const user = useSelector((state) => state?.Account?.user)
  const isMobile = useResize()
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title mt-0'>
            Submit {data?.type === 'Full Time' ? 'invoice' : 'milestone'}
          </h5>
          <button
            type='button'
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={toggle}
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <div className='modal-body'>
          <p>{`Are you sure you would like to submit this ${
            data?.type === 'Full Time' ? 'Invoice' : 'work'
          } for approval?`}</p>
        </div>
        <div className={`modal-footer ${isMobile ? 'fixed-bottom' : ''}`}>
          <button
            type='button'
            className='btn btn-outline-light'
            data-dismiss='modal'
            onClick={toggle}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => {
              submitMilestone(user?.token, { work_id: milestone?.id })
                .then((r) => {
                  if (r.data.success) {
                    toggle()
                    onMilestoneSubmitted()
                  }
                  console.log(r)
                })
                .catch((e) => {
                  console.log(e)
                })
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default SubmitMilestoneModal
