import React, { useState } from 'react'
import { Coins } from '@phosphor-icons/react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'
import { StyledH1, StyledH2 } from '../../../components/Typo'
import { useFetch, useResize } from '../../../helpers/hooks'
import RequestModal from './RequestModal'
import { createInsuranceRequest } from '../../../services/api'
import { RedIndicator } from '../../Activity/physical-card-banner'

const Plan = ({
  features,
  title,
  price,
  desc,
  currency,
  plan,
  onUpdate,
  ages,
  country,
  dependents,
  ctaText = 'Get Started',
  insuranceAllowance,
}) => {
  const isMobile = useResize()

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    maximumFractionDigits: 0,
    currency,
  })
  const prepareReq = useFetch({
    action: createInsuranceRequest,
    onComplete: onUpdate,
  })

  const [isOpen, setIsOpen] = useState(false)
  const onSelectPlan = () => {
    setIsOpen(true)
  }
  const onBuy = (v) => {
    setIsOpen(false)
    const body = {
      ...v,
      plan_id: plan.id,
      country_id: country,
    }
    prepareReq.startFetch(body)
  }
  return (
    <Card className='mt-3 border border-1'>
      <Row className='p-4'>
        <Col>
          {insuranceAllowance?.status === 'active' && (
            <>
              <RedIndicator />
              <div className='d-flex flex-column p-4 gap-6 mb-3 bg-primary-20 rounded'>
                <Coins size={28} className='text-primary align-self-center' />
                <span className='text-center p-3'>
                  You have a monthly {insuranceAllowance?.currency?.symbol}
                  {insuranceAllowance?.amount} allowance. You can use it for{' '}
                  {plan?.label}.
                </span>
                <button
                  onClick={onSelectPlan}
                  className='flex-grow-1 btn btn-primary justify-content-center'
                >
                  {prepareReq.isLoading && (
                    <i className='bx bx-loader bx-spin align-middle mr-2' />
                  )}
                  Activate Now
                </button>
              </div>
            </>
          )}

          <StyledH1
            style={{
              fontFamily: 'gilroyB',
              fontWeight: 'bold',
              marginBottom: 24,
            }}
            min='16px'
            max='24px'
          >
            {title}
          </StyledH1>
          <StyledH2
            style={{
              fontFamily: 'gilroyB',
              fontWeight: 'bold',
              marginBottom: 12,
            }}
            min={'16px'}
            max={'24px'}
          >
            {formatter.format(price)}
            <span className='text-secondary'>/mo</span>
          </StyledH2>
          <h3
            style={{ marginBottom: 32, fontWeight: '400' }}
            className='text-secondary font-size-12'
          >
            {desc}
          </h3>
          <Row>
            {features.map((e, i) => (
              <Col
                key={`feature-${i}`}
                style={{ minWidth: isMobile ? '100%' : '20vw' }}
              >
                <Row className='p-0 m-0 align-items-center mb-4'>
                  {e?.included ? (
                    <div
                      className='bg-soft-success rounded-circle align-items-center justify-content-center mr-2'
                      style={{
                        height: 24,
                        width: 24,
                        paddingLeft: 2,
                        paddingTop: 2,
                      }}
                    >
                      <i className='bx bx-check text-success font-size-20 text-center' />
                    </div>
                  ) : (
                    <div
                      className='bg-soft-danger rounded-circle align-items-center justify-content-center mr-2'
                      style={{ height: 24, width: 24, paddingLeft: 3 }}
                    >
                      <i
                        className='mdi mdi-window-close text-danger font-size-18 text-center '
                        style={{ lineHeight: '24px' }}
                      />
                    </div>
                  )}
                  <h1 className='font-size-14 font-weight-bold my-0'>
                    {e.name}
                  </h1>
                  <i
                    id={`feature-${plan?.id}-${i}`}
                    className='bx bx-help-circle mx-2 text-secondary font-size-18'
                  />
                  <UncontrolledTooltip
                    placement='top'
                    target={`feature-${plan?.id}-${i}`}
                  >
                    {e.details}
                  </UncontrolledTooltip>
                </Row>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      {!insuranceAllowance && (
        <Row className='p-0 m-0 justify-content-end'>
          <div className='w-100 p-4 d-flex'>
            <button
              onClick={onSelectPlan}
              className='flex-grow-1 btn btn-primary justify-content-center'
            >
              {prepareReq.isLoading && (
                <i className='bx bx-loader bx-spin align-middle mr-2' />
              )}
              {ctaText}
            </button>
          </div>
        </Row>
      )}

      <RequestModal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        onConfirm={onBuy}
        dependents={dependents}
        planeName={plan.name}
      />
    </Card>
  )
}

const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 0px #dfe1e6;
  border-radius: 4px;
`

Plan.propTypes = {
  isPopular: PropTypes.bool,
  onSelect: PropTypes.func,
  desc: PropTypes.string,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      checked: PropTypes.bool,
    }),
  ),
}

export default Plan
