import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, withRouter } from 'react-router'
import { useLocation } from 'react-router-dom'
import {
  Badge,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap'
import styled from 'styled-components'

import addCardImage from '../../../assets/images/add-card.png'
import ActionsDropdown from '../../../components/ActionsDropdown'
import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import NoContent from '../../../components/NoContent'
import BadgeX from '../../../components/Table/BadgeX'
import StyledTd from '../../../components/Table/StyledTd'
import StyledTh from '../../../components/Table/StyledTh'
import TableComp from '../../../components/Table/TableComp'
import { StyledH5 } from '../../../components/Typo'
import Loader from '../../../components/ui/loader'
import { userTypes } from '../../../helpers/enum'
import { useFetch, usePermissions, useResize } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  getAdminExpenses,
  getAdminExpensesMonths,
  getExpenses,
  getExpensesMonths,
  getFullTimeExpenses,
  getFullTimeExpensesMoths,
  revertExpense,
} from '../../../services/api'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import { BxIcon } from '../../AdminPanel/pages/Transactions'
import ExpenseModal from '../components/ExpensesModal'

export const getStatusColor = (status) => {
  switch (status) {
    case 'Paid':
      return 'success'
    case 'Approved':
      return 'success'
    case 'Signed':
      return 'success'
    case 'Unpaid':
      return 'danger'
    case 'Declined':
      return 'danger'
    case 'Processing':
      return 'warning'
    case 'Pending approval':
      return 'warning'
    case 'Pending':
      return 'warning'
    default:
      return 'primary'
  }
}
const Expenses = ({ contractLoading }) => {
  const [show, setShow] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [month, setMonth] = useState(null)
  const contract = useSelector((state) => state?.Contract?.details)
  const isEmployee = useSelector(
    (state) => state.userProfile?.userProfile?.contractor_type === 'employee',
  )
  const user = useSelector((state) => state?.Account?.user)
  const location = useLocation()
  const isAdmin = location.pathname?.startsWith('/admin/')

  const bankAccounts = useSelector((state) => state.userProfile.userProfile)

  const { hasAccess } = usePermissions()
  const history = useHistory()
  const isMobile = useResize()
  const months = useFetch(
    {
      autoFetch: !!contract?.id || isEmployee,
      withAdminAccess: isAdmin,
      action: isAdmin
        ? getAdminExpensesMonths
        : isEmployee
        ? getFullTimeExpensesMoths
        : getExpensesMonths,
      body: { contract_id: contract?.id },
      onComplete: (data) => {
        setMonth(data?.months[0])
      },
    },
    [contract?.id],
  )

  const list = useFetch(
    {
      action: isAdmin
        ? getAdminExpenses
        : isEmployee
        ? getFullTimeExpenses
        : getExpenses,
      withAdminAccess: isAdmin,
      checkSuccess: true,
      body: { contract_id: contract?.id, month: month?.value },
      autoFetch: contract?.id && month?.value,
    },
    [contract?.id, month?.value, months?.data?.months?.length],
  )

  const loading = contractLoading || list.isLoading || months.isLoading

  const hideTable = loading || months?.data?.months.length <= 1
  const showEmptyContent = !loading && months?.data?.months.length <= 1

  const handleButtonClick = () => {
    if (isEmployee) {
      if (!bankAccounts['bank-accounts']?.length) {
        setShowModal(true)
      } else {
        setShow(true)
      }
    } else {
      setShow(true)
    }
  }

  function refreshData() {
    months.startFetch({ contract_id: contract?.id }, false)
    list.startFetch({ contract_id: contract?.id, month: month?.value }, false)
  }

  return (
    <Container
      withShadow={!hideTable}
      style={{ backgroundColor: !hideTable ? '#FFFFFF' : 'transparent' }}
    >
      {hideTable ? null : (
        <Row className='m-0 p-0'>
          <Col
            xs={12}
            md={7}
            className={'  m-0 mb-3 p-3 p-md-4 mb-md-0 d-none d-md-block'}
          >
            <StyledH5 min={'16px'} max={'24px'}>
              Expenses
            </StyledH5>
          </Col>

          <Col xs={12} md={5}>
            <Row className='p-4'>
              <Col xs={12} md={6} className='p-0'>
                <CustomSelect
                  className='mb-0'
                  name='expenses'
                  onChange={(e) => setMonth(e)}
                  options={months?.data?.months}
                  value={month}
                />
              </Col>
              <Col xs={12} md={0} className='p-0 d-md-none'>
                <div className='border-top my-3 d-block d-md-none' />
              </Col>
              <Col xs={12} md={6} className='p-0'>
                <div className='w-100 pl-md-2'>
                  <button
                    disabled={
                      !(
                        hasAccess(permissions.addExpense) ||
                        contract?.can_approve ||
                        user?.type === userTypes.CONTRACTOR
                      ) ||
                      isAdmin ||
                      contract?.status?.id !== 4 ||
                      (contract?.type === 'Full Time' &&
                        user?.type === userTypes.CONTRACTOR &&
                        !isEmployee)
                    }
                    className='btn btn-primary btn-block'
                    onClick={handleButtonClick}
                  >
                    Add expense
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {loading ? (
        <Loader minHeight='50vh' />
      ) : (
        <Row className={'p-0 m-0'}>
          {isMobile ? (
            <Col
              className='px-3 w-100'
              style={{
                backgroundColor: showEmptyContent ? 'transparent' : '#FFFFFF',
                boxShadow: '0px 1px 0px #DFE1E6',
                borderRadius: 4,
              }}
            >
              {showEmptyContent ? (
                <NoContent
                  headline='No Expenses'
                  subtitle='Submitted expenses will be shown here'
                  withAction={
                    !(
                      !(
                        hasAccess(permissions.addExpense) ||
                        contract?.can_approve ||
                        user?.type === userTypes.CONTRACTOR
                      ) ||
                      isAdmin ||
                      contract?.status?.id !== 4 ||
                      (contract?.type === 'Full Time' &&
                        user?.type === userTypes.CONTRACTOR &&
                        !isEmployee)
                    )
                  }
                  actionTitle={'Add expense'}
                  onAction={handleButtonClick}
                  advanced={false}
                />
              ) : (
                <>
                  {list.data?.map((expense, i) => (
                    <ExpenseCard
                      index={i}
                      key={'_order_' + i}
                      expense={expense}
                    />
                  ))}
                </>
              )}
            </Col>
          ) : (
            <Col className='p-0'>
              {showEmptyContent ? (
                <div className='p-2'>
                  <NoContent
                    headline='No Expenses'
                    subtitle='Submitted expenses will be shown here'
                    withAction={
                      !(
                        !(
                          hasAccess(permissions.addExpense) ||
                          contract?.can_approve ||
                          user?.type === userTypes.CONTRACTOR
                        ) ||
                        isAdmin ||
                        contract?.status?.id !== 4 ||
                        (contract?.type === 'Full Time' &&
                          user?.type === userTypes.CONTRACTOR &&
                          !isEmployee)
                      )
                    }
                    actionTitle={'Add expense'}
                    onAction={handleButtonClick}
                    advanced={false}
                  />
                </div>
              ) : list.isLoading ? (
                <div
                  className='d-flex justify-content-center align-items-center'
                  style={{ minHeight: 170 }}
                >
                  <Spinner type='grow' color='primary' />
                </div>
              ) : list?.data?.length <= 0 ? (
                <div
                  className='d-flex justify-content-center align-items-center py-4'
                  style={{ minHeight: 170 }}
                >
                  <NoContent
                    subtitle='No expenses found, please change your filters.'
                    advanced={false}
                  />
                </div>
              ) : (
                <div className={!isAdmin && 'table-with-dropdown'}>
                  <div className='table-responsive'>
                    <TableComp>
                      <thead className='thead-light'>
                        <tr
                          style={{
                            borderTop: 0,
                            borderBottom: '1px solid #E7E8F2',
                          }}
                        >
                          <StyledTh>Expense</StyledTh>
                          <StyledTh>Category</StyledTh>
                          <StyledTh>Date</StyledTh>
                          <StyledTh>Amount</StyledTh>
                          <StyledTh>Status</StyledTh>
                          <StyledTh>Actions</StyledTh>
                        </tr>
                      </thead>
                      <tbody>
                        {list.data?.map((item, key) => (
                          <ExpenseLine
                            key={`exp-${key}`}
                            isAdmin={isAdmin}
                            contract={contract}
                            item={item}
                            refreshData={refreshData}
                          />
                        ))}
                      </tbody>
                    </TableComp>
                  </div>
                </div>
              )}
            </Col>
          )}
        </Row>
      )}
      {show && (
        <ExpenseModal
          show={show}
          hide={() => {
            setShow(false)
            refreshData()
          }}
        />
      )}
      <NoticeModal
        history={history}
        showModal={showModal}
        hide={() => setShowModal(false)}
      />
    </Container>
  )
}

const ExpenseLine = ({ item, contract, isAdmin, refreshData }) => {
  const user = useSelector((state) => state?.Account?.user)
  const [showImg, setShowImg] = useState(false)
  const [confirmCancel, setConfirmCancel] = useState(false)
  const isEmployee = useSelector(
    (state) => state.userProfile?.userProfile?.contractor_type === 'employee',
  )

  const history = useHistory()

  const formatter = getCurrencyFormatter(item?.contract_currency?.code)

  const revert = useFetch({ action: revertExpense, onComplete: refreshData })

  return (
    <>
      <tr style={{ borderBottom: '1px solid #E7E8F2' }}>
        <StyledTd>{item?.name}</StyledTd>
        <StyledTd>{item?.category?.name}</StyledTd>
        <StyledTd>{item?.date}</StyledTd>
        <StyledTd>
          {!isEmployee && formatter.format(item?.amount)}
          <BaseAmount
            baseAmount={item?.base_amount}
            baseCurrency={item?.base_currency}
          />
        </StyledTd>
        <StyledTd>
          <div className='w-100'>
            {item.status?.id === 2 ? (
              <a
                className='text-primary font-size-14 d-flex align-items-center'
                onClick={() => {
                  if (!isAdmin) {
                    history.push({
                      pathname: '/contract/Expenses/detail',
                      state: { expense: item, contract },
                    })
                  }
                }}
              >
                Review
              </a>
            ) : (
              <Col>
                <Row>
                  <BadgeX
                    name={
                      item.payment_status === 'Unpaid'
                        ? item.status?.name
                        : item.payment_status
                    }
                    status={getStatusColor(
                      item.payment_status === 'Unpaid'
                        ? item.status?.name
                        : item.payment_status,
                    )}
                    textStatus={getStatusColor(
                      item.payment_status === 'Unpaid'
                        ? item.status?.name
                        : item.payment_status,
                    )}
                  />
                </Row>
              </Col>
            )}
          </div>
        </StyledTd>
        <StyledTd>
          {user?.type === userTypes.COMPANY && (
            <ExpenseActions
              onStartRevert={() => {
                setConfirmCancel(true)
              }}
              item={item}
              showImagePreviewModal={() => setShowImg(true)}
            />
          )}
        </StyledTd>
      </tr>
      <ImageModal
        show={showImg}
        hide={() => setShowImg(false)}
        photo={item?.photo}
      />
      <ConfirmationModal
        toggle={() => setConfirmCancel(false)}
        isOpen={confirmCancel}
        title={'Revert Expense'}
        message={'Are you sure you want to revert this expense'}
        onConfirm={() => {
          setConfirmCancel(false)
          revert.startFetch({ expense_id: item.id })
        }}
      />
    </>
  )
}

const NoticeModal = ({ showModal, hide, history }) => {
  return (
    <Modal isOpen={showModal} toggle={hide}>
      <div className='modal-content'>
        <div className='modal-body'>
          <Row className='justify-content-center text-center'>
            <Col md={10}>
              <h2>Add Bank account</h2>
              <h6 className='text-muted'>
                Add your bank account details to receive expense reimbursements
              </h6>
              <Row className='justify-content-center'>
                <Col sm='6' xs='8'>
                  <div>
                    <img src={addCardImage} alt='' className='img-fluid' />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className='modal-footer'>
          <button type='button' className='btn btn-link' onClick={hide}>
            Cancel
          </button>
          <button
            type='button'
            className='btn btn-primary'
            data-dismiss='modal'
            onClick={() => history.push('/settings#accounts')}
          >
            Add Bank Account
          </button>
        </div>
      </div>
    </Modal>
  )
}

const Container = styled.div`
  background-color: #ffffff;
  box-shadow: ${(props) =>
    props.withShadow ? '0px 1px 0px #DFE1E6' : '0px 0px 0px #DFE1E6'};
  border-radius: 4px;
`

const StyledCard = styled.div`
  background-color: #ffffff;
  border: 1px solid #e7e8f2;
  box-sizing: border-box;
  border-radius: 4px;
`

const ExpenseCard = (props) => {
  const { expense } = props
  const contract = useSelector((state) => state?.Contract?.details)
  const location = useLocation()
  const isAdmin = location.pathname?.startsWith('/admin/')
  const history = useHistory()
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: contract?.currency?.code || 'USD',
  })
  // const user = useSelector(state => state?.Account?.user)
  const [showImg, setShowImg] = useState(false)
  const downloadExpense = (base64) => {
    // const url = window.URL.createObjectURL(new Blob())
    const link = document.createElement('a')
    link.href = base64
    link.setAttribute('download', 'expense.pdf') // or any other extension
    document.body.appendChild(link)
    link.click()
  }
  return (
    <StyledCard className={'mb-3'}>
      <div
        className={
          'p-3 border-bottom d-flex justify-content-between align-items-center w-100'
        }
      >
        {expense.status?.id === 2 ? (
          <a
            onClick={() => {
              if (!isAdmin) {
                history.push({
                  pathname: '/contract/Expenses/detail',
                  state: { expense, contract },
                })
              }
            }}
            className={'text-primary font-size-14 d-flex align-items-center'}
          >
            Review
          </a>
        ) : (
          <>
            <Badge
              className={`font-size-12 rounded bg-soft-${getStatusColor(
                expense.status?.name,
              )}`}
              color='white'
              pill
            >
              <p
                style={{ paddingTop: 2, paddingBottom: 2 }}
                className={`mb-0  text-${getStatusColor(expense.status?.name)}`}
              >
                {expense.status?.name}
              </p>
            </Badge>
            <div className='p-1' />
            <Badge
              className={`font-size-12 rounded bg-soft-${getStatusColor(
                expense.payment_status,
              )}`}
              color='white'
              pill
            >
              <p
                style={{ paddingTop: 2, paddingBottom: 2 }}
                className={`mb-0  text-${getStatusColor(
                  expense.payment_status,
                )}`}
              >
                {expense.payment_status}
              </p>
            </Badge>
          </>
        )}
      </div>
      <div className='p-3'>
        <div className='mb-3 d-flex justify-content-between align-items-center'>
          <p className={'mb-0 font-size-14 font-weight-light'}>Expense</p>
          <p className={'mb-0 font-size-14 font-weight-light'}>
            {expense?.name}
          </p>
        </div>
        <div
          className={' mb-3 d-flex justify-content-between align-items-center'}
        >
          <p className={'mb-0 font-size-14 font-weight-light'}>Date</p>
          <p className={'mb-0 font-size-14 font-weight-light'}>
            {expense?.date}
          </p>
        </div>
        <div
          className={' mb-3 d-flex justify-content-between align-items-center'}
        >
          <p className={'mb-0 font-size-14 font-weight-light'}>Amount</p>
          <p className={'mb-0 font-size-14 font-weight-light'}>
            {formatter.format(expense?.amount)}
          </p>
        </div>
        <div className={'   d-flex justify-content-between align-items-center'}>
          <p className={'mb-0 font-size-14 font-weight-light'}>Category</p>
          <p className={'mb-0 font-size-14 font-weight-light'}>
            {expense?.category?.name}
          </p>
        </div>
        {!!expense?.photo && (
          <div className={'d-flex justify-content-end align-items-center mt-3'}>
            {expense?.photo.includes('/pdf') ? (
              <a onClick={() => downloadExpense(expense?.photo)}>
                <i className='bx bxs-file-pdf text-primary font-size-24' />
              </a>
            ) : (
              <a onClick={() => downloadExpense(expense?.photo)}>
                <img
                  style={{ height: 40, width: 40 }}
                  src={expense?.photo}
                  alt={''}
                />
              </a>
            )}
            <ImageModal
              show={showImg}
              hide={() => setShowImg(false)}
              photo={expense?.photo}
            />
          </div>
        )}
      </div>
    </StyledCard>
  )
}

export const ImageModal = ({
  show,
  hide,
  photo,
  title = 'Expense Photo',
  downloadLink,
}) => {
  return (
    <Modal isOpen={show} toggle={hide} centered size='lg' scrollable>
      <ModalHeader toggle={hide}>{title}</ModalHeader>
      <ModalBody>
        {!!downloadLink && (
          <Row className='justify-content-center mb-2'>
            <a className='mx-2 btn btn-primary' href={downloadLink} download>
              Download
            </a>
          </Row>
        )}
        <img
          src={photo}
          alt=''
          className='border d-block mx-auto rounded'
          style={{ width: 'auto', minHeight: '50vh', maxHeight: '80vh' }}
        />
        <a
          href={photo}
          target='_blank'
          rel='noreferrer'
          className='text-center d-block mt-2'
        >
          Open image in a new tab
        </a>
      </ModalBody>
    </Modal>
  )
}
function ExpenseActions({ item, showImagePreviewModal, onStartRevert }) {
  const [isOpen, setIsOpen] = useState(false)

  const downloadExpense = (base64) => {
    const link = document.createElement('a')
    link.href = base64
    link.setAttribute('download', 'expense.pdf') // or any other extension
    document.body.appendChild(link)
    link.click()
  }

  const expensesOptions = [
    item.payment_status === 'Unpaid' &&
      item.status.id === 3 && {
        id: 2,
        onClick: onStartRevert,
        content: (
          <>
            <BxIcon name='bx bx-history' className='font-size-14 text-muted' />
            <span>Revert</span>
          </>
        ),
      },
    item?.photo && {
      component: () => <span className='text-dark'>Preview</span>,
      onClick: showImagePreviewModal,
    },
    item?.file && {
      component: () => <span className='text-dark'>Download</span>,
      onClick: () => downloadExpense(item?.file),
    },
  ].filter(Boolean)

  if (expensesOptions.length <= 0) return null

  return (
    <ActionsDropdown
      options={expensesOptions}
      isOpen={isOpen}
      toggle={() => setIsOpen((t) => !t)}
    />
  )
}

export const BaseAmount = ({ baseAmount, baseCurrency }) => {
  const isEmployee = useSelector(
    (state) => state.userProfile?.userProfile?.contractor_type === 'employee',
  )
  const contract = useSelector((state) => state?.Contract?.details)

  const baseFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: baseCurrency?.code || 'USD',
  })
  const sameCurrency =
    (contract?.currency?.code || 'USD') === (baseCurrency?.code || 'USD')
  return isEmployee ? (
    <span className={'ml-1  '}> {baseFormatter.format(baseAmount)} </span>
  ) : sameCurrency ? (
    ''
  ) : (
    <span className={'ml-1  text-primary'}>
      ({baseFormatter.format(baseAmount)})
    </span>
  )
}

export default withRouter(withTranslation()(Expenses))
