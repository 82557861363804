import cx from 'classnames'
import React, { useState } from 'react'
import { Col, Popover, PopoverBody, Row } from 'reactstrap'
import styled from 'styled-components'

import {
  ACH,
  BANK_TRANSFER,
  BREX,
  CASH_POINT,
  COINBASE,
  CREDIT_CARD,
  MERCURY,
  PAYONEER,
  PAYPAL,
  SEPA_DEBIT,
  WALLET,
  WISE_AUD,
  WISE_EUR,
  WISE_GBP,
  WISE_USD,
} from '../../core/config/payment-methods'
import { useResize } from '../../helpers/hooks'
import { StyledH5 } from '../Typo'
import bankAccount from './../../assets/images/bankAccount.svg'
import bankAccountSelected from './../../assets/images/bankAccount_selected.svg'
import mercuryLogo from './../../assets/images/brands/mercury-black.png'
import wiseLogo from './../../assets/images/brands/wise.svg'
import brexLogo from './../../assets/images/brands/brex.png'
import cash from './../../assets/images/cash.svg'
import cashGray from './../../assets/images/cashGray.svg'
import coinbaseIcon from './../../assets/images/coinbase_icon.svg'
import coinbaseIconInactive from './../../assets/images/coinbase_icon_inactive.svg'
import creditCard from './../../assets/images/creditCard.svg'
import payoneer from './../../assets/images/payoneer-logo.png'
import paypal from './../../assets/images/paypal.svg'
import paypalSelected from './../../assets/images/paypal_selected.svg'
import sepaLogo from './../../assets/images/sepa_logo.svg'
import unlockPopover from './../../assets/images/unlock_popover.svg'
import walletIcon from './../../assets/images/Walleticon.svg'

const getDesiredText = (id) => {
  switch (id) {
    case 1:
      return '4% Stripe processing fees will apply'
    case 4:
      return 'SEPA Direct Debit is only available to clients with an EU Bank account'
    case 5:
      return 'ACH Direct Debit is only available to clients with a US Bank account'
    default:
      return 'Hi, I have a bank account and would like to request access to bank transfer'
  }
}
const getDesiredTitle = (id) => {
  switch (id) {
    case 1:
      return 'Unlock Credit Card method'
    case 4:
      return 'Unlock SEPA Direct Debit'
    case 5:
      return 'Unlock ACH payment'
    default:
      return 'Unlock method'
  }
}
const CardSelector = ({ options, onSelect, value }) => {
  const isMobile = useResize()
  return (
    <div
      className='d-flex flex-column flex-md-row flex-wrap'
      style={{ '--wrapper-gap': '1rem', gap: 'var(--wrapper-gap)' }}
    >
      {options.map((e, i) => (
        <CardItem
          key={e.id}
          selected={value?.id === e?.id}
          styles={{
            flexBasis: isMobile
              ? undefined
              : 'calc(50% - var(--wrapper-gap)/2)',
          }}
          i={i}
          item={e}
          onSelect={() => {
            onSelect(e)
          }}
          isWithdraw
        />
      ))}
    </div>
  )
}

const methodIcon = {
  [BANK_TRANSFER.id]: { icon: bankAccount, activeIcon: bankAccountSelected },
  [PAYPAL.id]: { icon: paypal, activeIcon: paypalSelected },
  [PAYONEER.id]: { icon: payoneer },
  [CASH_POINT.id]: { icon: cashGray, activeIcon: cash },
  [COINBASE.id]: { icon: coinbaseIconInactive, activeIcon: coinbaseIcon },
  [WALLET.id]: { icon: walletIcon },
  [CREDIT_CARD.id]: { icon: creditCard },
  [SEPA_DEBIT.id]: { icon: sepaLogo },
  [ACH.id]: { icon: bankAccount, activeIcon: bankAccountSelected },
  [MERCURY.id]: { icon: mercuryLogo },
  [WISE_USD.id]: { icon: wiseLogo },
  [WISE_EUR.id]: { icon: wiseLogo },
  [WISE_GBP.id]: { icon: wiseLogo },
  [WISE_AUD.id]: { icon: wiseLogo },
  [BREX.id]: { icon: brexLogo },
}

export const CardItem = ({
  selected,
  item,
  onSelect,
  isEditing,
  isWithdraw,
  requestAccess,
  styles,
}) => {
  const [showAccessModal, setShowAccessModal] = useState(false)

  const icons = methodIcon[item.id]
  const image = selected ? icons?.activeIcon ?? icons?.icon : icons?.icon

  const imageFilter = !isEditing
    ? undefined
    : !selected && !icons?.activeIcon
    ? 'grayscale(100%)'
    : undefined

  return !isWithdraw ? (
    <div>
      {item.id !== 8 && (
        <Popover
          placement='top'
          isOpen={showAccessModal}
          toggle={() => setShowAccessModal(false)}
          target={`pm_popover_${item.id}`}
          style={{
            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 16px 24px',
          }}
          trigger='legacy'
          className='unbo-popover'
        >
          <PopoverBody className='p-0'>
            <div>
              <div className='d-flex p-2 align-items-start justify-content-start'>
                <img
                  src={unlockPopover}
                  width={16}
                  height={16}
                  className='ml-2 mt-2'
                  alt=''
                />

                <div className='pl-2'>
                  <div className='px-2 pt-2'>
                    <StyledH5
                      className='font-size-16'
                      style={{ color: '#424652' }}
                    >
                      {getDesiredTitle(item?.id)}
                    </StyledH5>
                  </div>

                  <div className='px-2 text-secondary font-size-12'>
                    <p style={{ color: '#777F9E', marginBottom: '0.4rem' }}>
                      {getDesiredText(item?.id)}
                    </p>
                  </div>
                </div>
              </div>

              <div
                className='px-3 py-3'
                style={{ borderTop: '1px solid #E7E8F2' }}
              >
                <button
                  type='button'
                  className='btn btn-primary rounded btn-sm btn-block py-2 font-size-14 font-weight-normal'
                  style={{ color: 'white', fontFamily: 'Inter' }}
                  onClick={() => requestAccess(item)}
                >
                  Proceed
                </button>
              </div>
            </div>
          </PopoverBody>
        </Popover>
      )}
      <button
        className='p-0 border-0 bg-transparent text-left'
        style={{ width: '100%' }}
        id={`pm_popover_${item.id}`}
        type='button'
        onClick={() => {
          if (item?.enabled) {
            onSelect()
          } else {
            setShowAccessModal(true)
          }
        }}
      >
        <StyledCard
          className={`align-items-center p-3 m-0 mb-3 ${
            selected ? 'bg-soft-primary' : ''
          } ${!item?.enabled ? 'bg-soft-secondary' : 'hover:bg-slate-50'}`}
          style={{ paddingBottom: '0.8rem', paddingTop: '0.8rem' }}
        >
          {item?.type !== 'sepa_debit' ? (
            <div
              className='rounded mr-2 bg-soft-secondary d-flex justify-content-center align-items-center'
              style={{ height: '38px', width: '38px' }}
            >
              <img
                src={image}
                style={{ height: '24px', width: '24px', filter: imageFilter }}
                alt=''
              />
            </div>
          ) : (
            <img
              className='rounded mr-2 bg-soft-secondary'
              src={image}
              style={{ height: '38px', width: '38px', filter: imageFilter }}
              alt=''
            />
          )}
          <Row
            className='p-0 m-0 pl-1 align-items-center justify-content-between'
            style={{ flex: 1 }}
          >
            <Col className='m-0 p-0'>
              <div className={'text-dark font-size-14'}>{item?.label} </div>
              {!isEditing && (
                <div className={'text-secondary font-size-12'}>
                  {item?.eta
                    ? `${item?.description} - ${item?.eta}`
                    : item.description}
                </div>
              )}
            </Col>
          </Row>
          {item?.enabled ? (
            <i className='mdi mdi-chevron-right text-secondary font-size-20 ' />
          ) : (
            <i className='fas fa-lock text-secondary font-size-20 ' />
          )}
        </StyledCard>
      </button>
    </div>
  ) : (
    <button
      className={cx('p-3 border bg-transparent rounded text-left', {
        'text-primary border-primary': selected,
        'text-secondary border-light': !selected,
      })}
      style={{ width: '100%', position: 'relative', ...styles }}
      type='button'
      onClick={onSelect}
    >
      <span
        className={
          selected
            ? 'border border-primary radio-button-select-card'
            : 'border border-light'
        }
        style={{
          '--size': '1rem',
          position: 'absolute',
          top: 'var(--size)',
          right: 'var(--size)',
          width: 'var(--size)',
          height: 'var(--size)',
          borderRadius: 'var(--size)',
        }}
      />
      <img
        src={image}
        className='mb-3'
        alt=''
        style={{ height: '24px', width: 'auto', filter: imageFilter }}
      />
      <div>
        <h5 className={cx({ 'text-primary': selected })}>{item.label}</h5>
        <p
          className={cx('font-size-12 mb-0', {
            'text-primary': selected,
            'text-secondary': !selected,
          })}
        >
          {item.description}
        </p>
      </div>
    </button>
  )
}

export const UnlockPopover = () => {}
const StyledCard = styled(Row)`
  border: 1px solid #e7e8f2;
  box-sizing: border-box;
  border-radius: 4px;
`

export default CardSelector
