import React from 'react'
import { Col, FormGroup, Label, Modal } from 'reactstrap'
import WiseButton from '../../../components/WiseButton'
import styled from 'styled-components'
import ControlledInput from '../../../components/ControlledInput'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import ControlledSelect from '../../../components/ControlledSelect'
import ControlledDatePicker from '../../../components/ControlledDatePicker'

const schema = yup.object().shape({
  policy_number: yup.string().required(),
  cycle: yup.number().required(),
  first_payment_date: yup.string().required(),
})

const ApproveModal = ({ isOpen, toggle, onSubmit }) => {
  const {
    control,
    handleSubmit,

    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: { ages: [{ age: null }] },
  })

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='modal-header p-3 p-md-4'>
          <h5 className='modal-title mt-0'>Active your insurance plan now?</h5>
          <button
            type='button'
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={toggle}
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <div className='modal-content'>
          <div className='modal-body p-4 m-0'>
            <FormGroup className='d-inline p-0 m-0 '>
              <Label
                style={{ whiteSpace: 'nowrap' }}
                className='col-form-label p-0 m-0 mb-2 font-size-14'
              >
                Policy Number
              </Label>
              <ControlledInput
                className='mb-3'
                name={'policy_number'}
                error={errors.policy_number}
                control={control}
              />
            </FormGroup>
            <FormGroup className='d-inline p-0 m-0'>
              <Label
                style={{ whiteSpace: 'nowrap' }}
                className='col-form-label p-0 m-0 mb-2 font-size-14'
              >
                Payment Cycle
              </Label>
              <ControlledSelect
                className='mb-3'
                error={errors.cycle}
                control={control}
                name='cycle'
                options={[
                  { label: '1st of the month', value: 1 },
                  { label: '15th of the month', value: 15 },
                ]}
              />
            </FormGroup>
            <FormGroup className='d-inline p-0 m-0'>
              <Label
                style={{ whiteSpace: 'nowrap' }}
                className='col-form-label p-0 m-0 my-2 font-size-14'
              >
                First Payment Date
              </Label>
              <ControlledDatePicker
                className='mb-3'
                control={control}
                name='first_payment_date'
                error={errors.first_payment_date}
                min={new Date().toISOString()}
              />
            </FormGroup>
          </div>
          <StyledFooter className='modal-footer p-3 p-md-4 justify-content-between justify-content-md-end'>
            <WiseButton
              border
              bk={'white'}
              textColor={'dark'}
              type='button'
              text={' Cancel'}
              bold
              width={'auto'}
              minWidth={'40%'}
              onClick={toggle}
            />

            <WiseButton
              text={'Approve'}
              bold
              width={'auto'}
              minWidth={'40%'}
              type='submit'
            />
          </StyledFooter>
        </div>
      </form>
    </Modal>
  )
}

const StyledFooter = styled(Col)`
  @media (max-width: 767px) {
    margin-top: 65vh;
  }
`

export default ApproveModal
