import * as moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Col, Row, Table, UncontrolledTooltip } from 'reactstrap'
import Collapse from 'reactstrap/lib/Collapse'
import Input from 'reactstrap/lib/Input'
import styled from 'styled-components'
import toastr from 'toastr'

import pay from '../../assets/images/pay.svg'
import payHover from '../../assets/images/payhover.svg'
import Avatar from '../../components/Avatar'
import Toggle from '../../components/Forms/Toggle/Toggle'
import { StyledH6 } from '../../components/Typo'
import WiseButton from '../../components/WiseButton'
import { usePermissions, useResize } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import { updateToPayList } from '../../store/payment/actions'
import { getCurrencyFormatter } from '../../utils/formatters/currency'
import ContractRef from '../AdminPanel/components/ContractRef'
import { getPaymentIds } from '../payInvoices'

const PaymentList = ({
  data,
  onInvoiceSelected,
  withStatus,
  withAdvance,
  onAdvanceSet,
  isAdmin,
}) => {
  const [opened, setOpened] = useState(-1)
  const [list, setList] = useState(data)
  const [expanded, setExpanded] = useState(-1)
  const { hasAccess } = usePermissions()
  const history = useHistory()
  const user = useSelector((state) => state.Account?.user)
  const dispatch = useDispatch()
  const isMobile = useResize()
  useEffect(() => {
    setList(data)
  }, [data])

  const handleInvoiceChecked = (i) => {
    onInvoiceSelected(i)
  }
  const handleClickPay = (e) => {
    const ids = getPaymentIds([e])
    dispatch(updateToPayList(ids))
    history.push('/pay-invoices')
  }
  return (
    <Row className='p-0 m-0'>
      {isMobile ? (
        <Col className='p-3 m-0'>
          {list?.map((item, index) => (
            <PaymentListCard
              key={item?.contract_ref + '-' + index}
              index={index}
              item={item}
              onAdvanceSet={onAdvanceSet}
              list={list}
              setList={(data) => setList(data)}
              handleClickPay={handleClickPay}
              opened={opened}
              setOpened={(key) => setOpened(key)}
              withAdvance={withAdvance}
              user={user}
              hasAccess={hasAccess}
              handleInvoiceChecked={handleInvoiceChecked}
              isAdmin={isAdmin}
            />
          ))}
        </Col>
      ) : (
        <Col xs='12' className='p-0 m-0'>
          <div className='table-responsive bg-white border rounded'>
            <Table className='table table-centered table-nowrap text-muted'>
              <thead className='thead-light'>
                <tr
                  style={{
                    borderTop: 'hidden',
                    borderBottom: '1px solid #E7E8F2',
                  }}
                >
                  <th
                    style={{ fontWeight: '500' }}
                    className='text-dark font-size-14'
                  />
                  <th
                    style={{ fontWeight: '500' }}
                    className='text-dark font-size-14'
                  >
                    Contract Ref
                  </th>
                  <th
                    style={{ fontWeight: '500' }}
                    className='text-dark font-size-14'
                  >
                    Name
                  </th>
                  <th
                    style={{ fontWeight: '500' }}
                    className='text-dark font-size-14'
                  >
                    Contract Name
                  </th>
                  <th
                    style={{ fontWeight: '500' }}
                    className='text-dark font-size-14'
                  >
                    Total
                  </th>

                  {withStatus && (
                    <th className='border-top-0 text-muted'>Payment Status</th>
                  )}

                  <th
                    style={{ fontWeight: '500' }}
                    className='text-dark font-size-14'
                  />
                </tr>
              </thead>
              <tbody>
                {list?.map((item, index) => {
                  const formatter = getCurrencyFormatter(item.currency?.code)

                  return (
                    <React.Fragment key={item?.contract_ref + '-' + index}>
                      <tr>
                        <td>
                          {item?.payments?.length !== 0 && (
                            <button
                              className='rp-btn-nostyle d-flex text-current p-1 font-size-14'
                              onClick={() => {
                                setExpanded(expanded === index ? -1 : index)
                              }}
                            >
                              {expanded === index ? (
                                <i className='bx bx-minus' />
                              ) : (
                                <i className='bx bx-plus' />
                              )}
                            </button>
                          )}
                        </td>
                        <td
                          className='text-primary font-size-14'
                          style={{ fontWeight: '400' }}
                        >
                          <ContractRef
                            isAdmin={isAdmin || false}
                            contractId={item.contract_ref}
                          />
                        </td>
                        <td
                          className='text-dark font-size-14'
                          style={{ fontWeight: '400' }}
                        >
                          <Col>
                            <Row className='align-items-center flex-nowrap'>
                              <Avatar
                                photo={item.payments?.[0]?.contractor?.photo}
                                name={
                                  item.payments?.[0]?.contractor?.first_name
                                }
                                flag={item.payments?.[0]?.contractor?.flag}
                              />
                              <div
                                style={{ fontWeight: '600', marginLeft: 10 }}
                              >
                                {item.payments?.[0]?.contractor?.first_name}{' '}
                                {item.payments?.[0]?.contractor?.last_name}
                              </div>
                            </Row>
                          </Col>
                        </td>
                        <td
                          className='text-dark font-size-14'
                          style={{ fontWeight: '400' }}
                        >
                          <div
                            style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                            }}
                          >
                            {item.contract_name}
                          </div>
                        </td>
                        <td className='text-dark font-size-14'>
                          {formatter.format(item.total_amount)}
                        </td>
                        {user?.type === 'client' &&
                          hasAccess(permissions.PrepareTransactions) && (
                            <td>
                              <Col className={'p-0 m-0'}>
                                <Row className='justify-content-center p-0 m-0'>
                                  {withAdvance ? (
                                    <>
                                      <Col sm={8} className={'p-0 m-0'}>
                                        {item.can_pay ? (
                                          <StyledSvg
                                            icon={pay}
                                            hoverIcon={payHover}
                                            alt={'pay icon'}
                                          >
                                            <Link
                                              className='font-weight-bold mb-3'
                                              onClick={() => {
                                                handleClickPay(item)
                                              }}
                                            >
                                              <StyledH6
                                                id='pay-tooltip'
                                                className={
                                                  'ml-4 text-dark font-weight-light font-size-14'
                                                }
                                              >
                                                Pay
                                              </StyledH6>
                                              <UncontrolledTooltip
                                                placement='top'
                                                target='pay-tooltip'
                                              >
                                                Click to pay
                                              </UncontrolledTooltip>
                                            </Link>{' '}
                                          </StyledSvg>
                                        ) : (
                                          <>
                                            <Link
                                              className='font-weight-bold mb-3'
                                              onClick={() => {
                                                setOpened(index)
                                              }}
                                            >
                                              <span id='pay-tooltip'>
                                                Off-cycle Payment
                                              </span>
                                              <UncontrolledTooltip
                                                placement='top'
                                                target='pay-tooltip'
                                              >
                                                Click to pay
                                              </UncontrolledTooltip>
                                            </Link>

                                            <Collapse isOpen={index === opened}>
                                              <Row className='mt-2 flex-nowrap'>
                                                <Col sm={7}>
                                                  <Input
                                                    type='text'
                                                    onKeyPress={(e) => {
                                                      if (
                                                        !/[0-9.]/.test(e.key)
                                                      ) {
                                                        e.preventDefault()
                                                      }
                                                    }}
                                                    step='any'
                                                    onChange={(event) => {
                                                      if (
                                                        parseFloat(
                                                          event.target.value,
                                                        ) > item.amount
                                                      ) {
                                                        toastr.error(
                                                          'Amount should be less or equal ' +
                                                            item.amount,
                                                        )
                                                        return
                                                      }
                                                      setList(
                                                        list.map((p) => ({
                                                          ...p,
                                                          advance: parseFloat(
                                                            event.target.value,
                                                          ),
                                                        })),
                                                      )
                                                    }}
                                                    value={item.advance}
                                                    placeholder='Amount'
                                                  />
                                                </Col>
                                                <Link
                                                  className='btn btn-primary'
                                                  color='primary'
                                                  onClick={() => {
                                                    onAdvanceSet(item)
                                                  }}
                                                >
                                                  Pay
                                                </Link>
                                              </Row>
                                            </Collapse>
                                          </>
                                        )}
                                      </Col>
                                    </>
                                  ) : (
                                    <div className='custom-control pl-0'>
                                      {!isAdmin && (
                                        <Toggle
                                          id={`invoiceToPay${index}`}
                                          check={item.selected}
                                          change={() => {
                                            handleInvoiceChecked(index)
                                          }}
                                        />
                                      )}
                                    </div>
                                  )}
                                </Row>
                              </Col>
                            </td>
                          )}
                      </tr>

                      {expanded === index && (
                        <>
                          {item?.payments?.map((payment, z) => {
                            const formatter = getCurrencyFormatter(
                              payment.currency?.code,
                            )

                            return (
                              <>
                                {payment?.works?.map((t) => (
                                  <tr
                                    className='font-size-14 bg-gray-bg'
                                    key={'_trans' + t?.id}
                                  >
                                    <td className='p-0' style={{ height: 100 }}>
                                      <div
                                        style={{
                                          borderLeft:
                                            '4px solid var(--primary)',
                                        }}
                                        className='h-100'
                                      />
                                    </td>
                                    <td
                                      colSpan={3}
                                      className='text-dark font-size-14'
                                    >
                                      <p className='rp-font-gilroyB rp-font-bold'>
                                        {t?.name}
                                      </p>
                                      <p className='mb-0'>{t?.details}</p>
                                    </td>

                                    <td
                                      colSpan={user?.type === 'client' ? 2 : 1}
                                      className='text-dark font-size-14'
                                    >
                                      <div>
                                        <p>{formatter.format(t?.amount)}</p>
                                        <StyledH6
                                          className={`${
                                            moment(payment?.due_date).diff(
                                              new Date(),
                                            ) < 0
                                              ? 'text-danger'
                                              : 'text-dark'
                                          }   font-size-14  font-weight-normal mb-0 `}
                                        >
                                          Due date : {payment?.due_date}
                                        </StyledH6>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </>
                            )
                          })}
                        </>
                      )}
                    </React.Fragment>
                  )
                })}
              </tbody>
            </Table>
          </div>
        </Col>
      )}
    </Row>
  )
}

const PaymentListCard = (props) => {
  const {
    item,
    index,
    handleInvoiceChecked,
    user,
    hasAccess,
    withAdvance,
    setOpened,
    handleClickPay,
    // opened,
    // onAdvanceSet,
    // list,
    // setList,
    isAdmin,
  } = props
  const formatter = getCurrencyFormatter(item.currency?.code)

  return (
    <div className='bg-white border mb-3 position-relative rounded rp-shadow-2'>
      <div className='p-3 border-bottom d-flex justify-content-between align-items-center'>
        <div>
          <p
            style={{ fontWeight: 'bold' }}
            className='text-dark font-size-14 mb-0'
          >{`${item?.payments?.[0].contractor?.first_name}  ${item?.payments?.[0].contractor?.last_name}`}</p>
          <ContractRef
            isAdmin={isAdmin || false}
            contractId={item.contract_ref}
          />
        </div>
        <div className='d-flex justify-content-between'>
          {user?.type === 'client' &&
            hasAccess(permissions.PrepareTransactions) &&
            !withAdvance &&
            !isAdmin && (
              <Toggle
                id={`invoiceToPay${index}`}
                check={item.selected}
                change={() => handleInvoiceChecked(index)}
              />
            )}
        </div>
      </div>
      <div className='p-3'>
        <div className='d-flex mb-3 align-items-center justify-content-between align-items-center'>
          <StyledH6
            className={'text-dark   font-weight-normal mb-0   font-size-14'}
          >
            Contract Name
          </StyledH6>
          <StyledH6 className='font-weight-normal mb-0  text-dark   font-size-14'>
            {item?.contract_name}
          </StyledH6>
        </div>
        {/* <div className="d-flex mb-3 align-items-center justify-content-between align-items-center"> */}
        {/*  <StyledH6 className={'text-dark  font-weight-normal mb-0    font-size-14'}>Due Date</StyledH6> */}
        {/*  <StyledH6 className={`${moment(e.due_date).diff(new Date()) < 0 ? 'text-danger' : 'text-dark'}   font-size-14  font-weight-normal mb-0 `}> */}
        {/*    { e.due_date}</StyledH6> */}
        {/* </div> */}
        <div className='d-flex mb-0 align-items-center justify-content-between align-items-center'>
          <StyledH6
            className={'text-dark  font-weight-normal mb-0    font-size-14'}
          >
            Total
          </StyledH6>
          <StyledH6 className='text-dark   font-size-14  font-weight-normal mb-0 '>
            {formatter.format(item.total_amount)}
          </StyledH6>
        </div>
        {!!withAdvance && (
          <Col sm={8} className={'p-0 m-0 mt-3'}>
            {item.can_pay ? (
              <WiseButton
                className='font-weight-bold mb-3'
                onClick={() => {
                  handleClickPay(item)
                }}
                text={'Pay'}
                minHeigh={'40px'}
                minWidth={'100%'}
                icon={
                  <img
                    src={pay}
                    style={{ width: '1rem', marginRight: 12 }}
                    alt={'pay icon'}
                  />
                }
                bk={'white'}
                textColor={'dark'}
                border
              />
            ) : (
              <>
                <WiseButton
                  className='font-weight-bold mb-3'
                  onClick={() => {
                    setOpened(index)
                  }}
                  text={'Off-cycle Payment'}
                  minHeigh={'40px'}
                  minWidth={'100%'}
                  icon={
                    <img
                      src={pay}
                      style={{ width: '1rem', marginRight: 12 }}
                      alt={'pay icon'}
                    />
                  }
                  bk={'white'}
                  textColor={'dark'}
                  border
                />
              </>
            )}
          </Col>
        )}
      </div>
    </div>
  )
}

const StyledSvg = styled.div`
  width: 1rem;
  height: 1rem;
  h6 {
    font-family: 'Inter';
  }
  background-image: ${(props) => ` url( ${props.icon} )`};
  &:hover {
    background-image: ${(props) => ` url( ${props.hoverIcon} )`};
    h6 {
      color: #114ef7 !important;
      font-family: 'Inter';
    }
  }
`

export default PaymentList
