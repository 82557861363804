import React, { useState } from 'react'
import { Card, CardBody, CardTitle, Col, Row, Spinner, Table } from 'reactstrap'

import Toggle from '../../components/Forms/Toggle/Toggle'
import { useFetch } from '../../helpers/hooks'
import { getCustomFields, removeAttribute } from '../../services/api'
import CustomFieldModal from './components/CustomFieldModal'

export const getIcon = (type) => {
  switch (type) {
    case 'date':
      return 'mdi-calendar'
    case 'text':
      return 'mdi-format-text'
    case 'selection':
      return 'mdi-arrow-down-drop-circle'
  }
}
const CustomFields = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState([])
  const [toUpdate, setToUpdate] = useState(null)
  const [deleting, setDeleting] = useState(-1)
  const attributes = useFetch({
    action: getCustomFields,
    onComplete: setData,
    autoFetch: true,
  })

  const remove = useFetch({
    action: removeAttribute,
    onComplete: () => attributes.startFetch(null, false),
  })
  const handleRemove = (attr, i) => {
    setDeleting(i)
    remove.startFetch({ attribute_id: attr?.id })
  }
  return (
    <Card className='mb-0 rp-rounded-top-0'>
      <CardBody>
        <Col style={{ minHeight: '50vh' }}>
          <Row className='mt-3 justify-content-between'>
            <CardTitle>Custom Fields</CardTitle>
            <button
              className='btn btn-primary'
              onClick={() => {
                setIsOpen(true)
              }}
            >
              Add custom Field
            </button>
          </Row>
          <Row>
            {attributes.isLoading ? (
              <Col style={{ minHeight: '50vh' }}>
                <Row
                  style={{ minHeight: '50vh' }}
                  className='justify-content-center align-items-center'
                >
                  <Spinner type='grow' className='mr-2' color='primary' />
                </Row>
              </Col>
            ) : (
              <div className='table-responsive mt-4'>
                {data?.length > 0 && (
                  <Table className='table table-centered  table-nowrap'>
                    <thead className='thead-light'>
                      <tr
                        style={{
                          borderTop: 'hidden',
                          borderBottom: '1px solid #E7E8F2',
                        }}
                      >
                        <th
                          style={{ fontWeight: '500' }}
                          className='text-dark font-size-14'
                        >
                          Name
                        </th>
                        <th
                          style={{ fontWeight: '500' }}
                          className='text-dark font-size-14'
                        >
                          Type
                        </th>
                        <th
                          style={{ fontWeight: '500' }}
                          className='text-dark font-size-14 text-center'
                        >
                          Required
                        </th>
                        <th
                          style={{ fontWeight: '500' }}
                          className='text-dark font-size-14 text-right'
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((attr, i) => {
                        return (
                          <tr key={'attr-' + attr?.id}>
                            <td>{attr.name}</td>
                            <td>
                              <i className={`mdi ${getIcon(attr.type)} mr-1`} />{' '}
                              {attr.type}
                            </td>
                            <td>
                              <Row className='justify-content-center'>
                                <Toggle
                                  id={'SwitchRequired'}
                                  check={!!attr?.required}
                                  disabled
                                />
                              </Row>
                            </td>
                            <td className='text-right'>
                              <button
                                type='button'
                                className='rp-btn-nostyle'
                                onClick={() => {
                                  setToUpdate(attr)
                                  setIsOpen(true)
                                }}
                              >
                                <i className='bx bx-edit-alt font-size-14 mx-3' />
                              </button>
                              {!attr.used && (
                                <button
                                  type='button'
                                  className='rp-btn-nostyle'
                                  onClick={() => handleRemove(attr, i)}
                                >
                                  {remove.isLoading && deleting === i ? (
                                    <i className='bx bx-loader bx-spin text-danger font-size-14' />
                                  ) : (
                                    <i className='bx bx-trash-alt text-danger font-size-14' />
                                  )}
                                </button>
                              )}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                )}
                <div className='py-lg-5 my-4' />
              </div>
            )}
          </Row>
        </Col>
        {isOpen && (
          <CustomFieldModal
            toUpdate={toUpdate}
            isOpen={isOpen}
            toggle={() => {
              setIsOpen(false)
              setToUpdate(null)
            }}
            onAdded={() => attributes.startFetch(null, false)}
          />
        )}
      </CardBody>
    </Card>
  )
}

export default CustomFields
