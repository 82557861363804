import cx from 'classnames'
import React from 'react'

export default function InputFeedback({
  children,
  className,
  color = 'danger',
}) {
  return (
    <small className={cx(`font-size-11 d-block text-${color}`, className)}>
      {children}
    </small>
  )
}
