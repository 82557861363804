import React from 'react'
import {
  Col,
  Container,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Row,
} from 'reactstrap'
import styled from 'styled-components'

const StyledToggleDiv = styled.div`
  height: calc(1.5em + 1.3rem + 2px);
  border: ${({ isOpen }) => `1px solid ${isOpen ? '#bbbdd6' : '#E7E8F2'}`};
  box-shadow: ${({ isOpen }) =>
    !isOpen ? null : '0 0 0 2px rgb(198 200 224 / 41%)'};
  border-radius: 4px;
  cursor: pointer;

  @media (min-width: 769px) {
    border-radius: 4px 0 0 4px;
    border-width: 1px 0px 1px 1px;
  }

  @media (max-width: 768px) {
    background-color: white;
  }
`

const StyledDropdownMenu = styled(DropdownMenu)`
  width: 100%;
  z-index: 1020;
  border: 1px solid #e7e8f2;

  @media (min-width: 769px) {
    width: 538px;
  }
`

const FiltersDropdown = ({
  isOpen,
  toggle,
  tagsFilters,
  statusFilters,
  filterByStatus,
  filterByTag,
  clearFilters,
  tagQueries,
  statusQueries,
}) => {
  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle tag='div'>
        <StyledToggleDiv className='d-flex align-items-center justify-content-between w-100 px-2'>
          <span className='text-gray-h font-size-14'>Filters</span>

          <i className='bx bxs-down-arrow' style={{ fontSize: 8 }} />
        </StyledToggleDiv>
      </DropdownToggle>
      <StyledDropdownMenu className='rounded py-0' flip={false}>
        <div
          className='d-flex align-items-center justify-content-between w-100 px-3 border-bottom'
          style={{ height: 'calc(1.5em + 1.3rem + 2px)' }}
        >
          <span className='font-size-14 text-gray-h'>Filter by</span>
          <button
            className='rp-btn-nostyle text-muted font-size-12'
            onClick={() => clearFilters()}
          >
            Reset All
          </button>
        </div>

        <Container fluid>
          <Row>
            <Col className='py-3 border-right h-100' md={6}>
              <div className='mb-2 text-gray-h font-size-12'>Status</div>
              {statusFilters?.map((e, i) => {
                return (
                  <Label
                    key={`drop-${i}`}
                    className='cursor-pointer d-block text-gray-h font-size-14 pl-4'
                    style={{ fontFamily: 'inter' }}
                  >
                    <Input
                      checked={statusQueries.includes(e.value)}
                      type='checkbox'
                      onChange={(t) => {
                        toggle()
                        filterByStatus(t.target.checked, e.value)
                      }}
                    />{' '}
                    {e.label}
                  </Label>
                )
              })}
            </Col>
            <Col md={6} className='py-3'>
              <div className='mb-2 text-gray-h font-size-12'>Tags</div>
              {tagsFilters?.map((e, i) => {
                return (
                  <Label
                    key={`drop-${i}`}
                    className='cursor-pointer d-block text-gray-h font-size-14 pl-4'
                    style={{ fontFamily: 'inter' }}
                  >
                    <Input
                      checked={tagQueries.includes(e.id)}
                      type='checkbox'
                      onChange={(t) => {
                        toggle()
                        filterByTag(t.target.checked, e.id)
                      }}
                    />{' '}
                    {e.name}
                  </Label>
                )
              })}
            </Col>
          </Row>
        </Container>
      </StyledDropdownMenu>
    </Dropdown>
  )
}

export default FiltersDropdown
