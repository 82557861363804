import { combineReducers } from 'redux'

// Front
import Layout from './layout/reducer'

// Authentication
import Login from './auth/login/reducer'
import Account from './auth/register/reducer'
import ForgetPassword from './auth/forgetpwd/reducer'
import Profile from './auth/profile/reducer'
import Payment from './payment/reducer'
import userProfile from './profile/reducer'
import Withdraw from './withdraw/reducer'
import Contract from './contract/reducer'
import xero from './xero/reducer'
import qb from './qb/reducer'
import ns from './ns/reducer'
import hiBob from './hiBob/reducer'
import sapling from './sapling/reducer'
import fresh from './fresh/reducer'
import zoho from './zoho/reducer'
import bamboo from './bamboo/reducer'
import page from './page/reducer'

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  userProfile,
  Payment,
  Withdraw,
  Contract,
  xero,
  qb,
  ns,
  fresh,
  zoho,
  hiBob,
  sapling,
  bamboo,
  page,
})

export default rootReducer
