import cx from 'classnames'
import React from 'react'

export default function Heading({ children, className }) {
  return (
    <h2
      className={cx('rp-font-gilroyB', className)}
      style={{ color: 'var(--gray-h)' }}
    >
      {children}
    </h2>
  )
}
