import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import {
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'

import checkgreen from '../../../../../assets/images/checkgreen.svg'
import { StyledH2, StyledH5, StyledH6 } from '../../../../../components/Typo'
import WiseButton from '../../../../../components/WiseButton'
import { userTypes } from '../../../../../helpers/enum'
import {
  useFetch,
  usePermissions,
  useResize,
} from '../../../../../helpers/hooks'
import permissions from '../../../../../helpers/permissions'
import {
  approveExpense,
  deleteExpense,
  rejectExpense,
} from '../../../../../services/api'
import ExpenseModal from '../../../../Contract/components/ExpensesModal'
import { BaseAmount } from '../../../../Contract/ContractPage/Expenses'

const ExpenseDetails = () => {
  const user = useSelector((state) => state?.Account?.user)
  const isEmployee = useSelector(
    (state) => state.userProfile?.userProfile?.contractor_type === 'employee',
  )

  const location = useLocation()
  const history = useHistory()
  const { expense, contract } = location?.state ?? {}
  const [show, setShow] = useState(false)
  const [show2, setShow2] = useState(false)
  const [showNotified, setShowNotified] = useState(true)
  const isLoading = false
  const { hasAccess } = usePermissions()

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: contract?.currency?.code || 'USD',
  })

  const remove = useFetch({
    action: deleteExpense,
    onComplete: () => {
      history.goBack()
    },
  })
  const approve = useFetch({
    action: approveExpense,
    onComplete: () => {
      history.goBack()
    },
  })
  const reject = useFetch({
    action: rejectExpense,
    onComplete: () => {
      history.goBack()
    },
  })
  const downloadExpense = (base64) => {
    // const url = window.URL.createObjectURL(new Blob())
    const link = document.createElement('a')
    link.href = base64
    link.setAttribute('download', 'expense.pdf') // or any other extension
    document.body.appendChild(link)
    link.click()
  }
  return (
    <div className='page-content position-relative'>
      {user?.type === userTypes.CONTRACTOR && !!showNotified && (
        <StyledNotif
          className={
            'p-3 px-md-5 px -3 d-flex justify-content-between align-items-center'
          }
        >
          <div className={'d-flex align-items-start'}>
            <img
              src={checkgreen}
              alt={'check success'}
              style={{ width: '1rem', marginRight: 16 }}
            />
            <StyledH6 className={'font-size-16 font-weight-light mb-0'}>
              This expense will be paid out in your next regular payroll.
              {/* <span onClick={() => alert('clicked')} */}
              {/*  style={{ borderBottom: '1px solid #4A4E5B' }}>&nbsp;cancel approval.</span> */}
            </StyledH6>
          </div>
          <span onClick={() => setShowNotified(false)}>
            {' '}
            <i className='bx bx-x font-size-24 align-middle mr-1  ' />
          </span>
        </StyledNotif>
      )}

      {isLoading ? (
        <Col style={{ minHeight: '30rem' }}>
          <Row
            style={{ minHeight: '30rem' }}
            className='justify-content-center align-items-center'
          >
            <Spinner type='grow' className='mr-2' color='primary' />
          </Row>
        </Col>
      ) : (
        <Container fluid className={'p-0 m-0'}>
          {showNotified && user?.type === userTypes.CONTRACTOR && (
            <div style={{ minHeight: '70px' }} />
          )}
          <Col>
            <Row className='align-items-center'>
              <a onClick={() => history.goBack()}>
                <i className='bx bx-arrow-back pr-2 font-size-20' />
              </a>
              <StyledH2
                max={'32px'}
                min={'22px'}
                className={'text-dark font-weight-bold '}
              >
                Expense details
              </StyledH2>
            </Row>
          </Col>
          <Row className={'p-0 m-0 mt-3'}>
            <Col xs={12} md={6} className={'p-0 m-0  mb-3 mb-md-0'}>
              <StyledCol className={' mr-md-3 p-5 p-md-5 m-0'}>
                <div
                  className='d-flex '
                  style={{
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {expense?.photo ? (
                    <>
                      {expense?.file !== '' ? (
                        <i
                          style={{ fontSize: '3rem' }}
                          className='bx bxs-file-pdf text-primary'
                        />
                      ) : (
                        <img
                          src={expense?.photo}
                          style={{ width: '100%' }}
                          alt={'expense image'}
                        />
                      )}
                    </>
                  ) : (
                    <p>No receipt provided</p>
                  )}
                </div>
              </StyledCol>
            </Col>

            <Col xs={12} md={6} className={' p-0 m-0'}>
              <StyledCol>
                <div className={'px-3 px-md-4 pt-3 pt-md-4'}>
                  <div className={'mb-4'}>
                    <StyledH5 style={{ marginBottom: 12 }} max={'14px'}>
                      Expense
                    </StyledH5>
                    <StyledH6
                      className={
                        'text-secondary font-weight-light font-size-14'
                      }
                    >
                      {expense?.name}
                    </StyledH6>
                  </div>
                  <div className={'mb-4'}>
                    <StyledH5 style={{ marginBottom: 12 }} max={'14px'}>
                      Date
                    </StyledH5>
                    <StyledH6
                      className={
                        'text-secondary font-weight-light font-size-14'
                      }
                    >
                      {expense?.date}
                    </StyledH6>
                  </div>
                  <div className={'mb-4'}>
                    <StyledH5 style={{ marginBottom: 12 }} max={'14px'}>
                      Amount
                    </StyledH5>
                    <StyledH6
                      className={
                        'text-secondary font-weight-light font-size-14'
                      }
                    >
                      {!isEmployee && formatter.format(expense?.amount)}
                      <BaseAmount
                        baseAmount={expense?.base_amount}
                        baseCurrency={expense?.base_currency}
                      />
                    </StyledH6>
                  </div>
                </div>
                <div
                  className={
                    'px-3 px-md-4 py-3 pt-md-4 border-top d-flex justify-content-between'
                  }
                >
                  {user?.type === userTypes.COMPANY ? (
                    <div className='d-flex align-items-center justify-content-between w-100 '>
                      {expense?.file && (
                        <WiseButton
                          onClick={() => {
                            downloadExpense(expense?.file)
                          }}
                          text={'Download'}
                          minHeight={'48px'}
                          height={'48px'}
                          width={'7rem'}
                          minWidth={'100%'}
                        />
                      )}
                      <div
                        className={`d-flex ${
                          expense?.file
                            ? 'justify-content-end'
                            : 'justify-content-around'
                        }`}
                        style={{ width: expense?.file ? '60%' : '100%' }}
                      >
                        <WiseButton
                          loading={reject.isLoading}
                          onClick={() => {
                            setShow2(true)
                          }}
                          disabled={
                            !(
                              hasAccess(permissions.rejectExpense) ||
                              contract?.can_approve
                            )
                          }
                          className={'mr- mr-md-1'}
                          minHeight={'48px'}
                          text={'Decline'}
                          width={'7rem'}
                          height={'48px'}
                          minWidth={'100%'}
                          bk={'danger'}
                        />
                        <WiseButton
                          loading={approve.isLoading}
                          onClick={() => {
                            approve.startFetch({ expense_id: expense?.id })
                          }}
                          disabled={
                            !(
                              hasAccess(permissions.approveExpense) ||
                              contract?.can_approve
                            )
                          }
                          text={'Approve'}
                          minHeight={'48px'}
                          height={'48px'}
                          width={'7rem'}
                          minWidth={'100%'}
                          bk={'success'}
                        />
                      </div>
                    </div>
                  ) : (contract?.type === 'Full Time' && isEmployee) ||
                    contract?.type !== 'Full Time' ? (
                    <div
                      className={
                        'mb-3 mb-md-0 w-100 d-flex justify-content-end'
                      }
                    >
                      <WiseButton
                        loading={remove.isLoading}
                        onClick={() => {
                          remove.startFetch({ expense_id: expense?.id })
                        }}
                        text={'Delete Expense'}
                        height={'48px'}
                        minHeight={'48px'}
                        minWidth={'100%'}
                        bk={'danger'}
                      />
                    </div>
                  ) : null}
                </div>
              </StyledCol>
            </Col>
          </Row>
          <ExpenseModal show={show} hide={() => setShow(false)} />
          <ConfirmModal
            show={show2}
            hide={() => setShow2(false)}
            reject={(reason) => {
              reject.startFetch({ expense_id: expense?.id, reason })
            }}
          />
        </Container>
      )}
    </div>
  )
}

export const ConfirmModal = (props) => {
  const { show, hide, reject } = props
  const isMobile = useResize()
  const [reason, setReason] = useState('')
  return (
    <div>
      <Modal
        isOpen={show}
        toggle={hide}
        centered={true}
        className={'confirmModal'}
      >
        <ModalHeader className={'border-0 pb-0'} toggle={hide}>
          <label>Reason</label>
        </ModalHeader>
        <ModalBody>
          <textarea
            style={{
              padding: 16,
              color: '#777F9E',
              fontSize: 14,
              fontWeight: 'normal',
              backgroundColor: '#FFFFFF',
              border: '1px solid #E7E8F2',
              width: '100%',
              borderRadius: 4,
            }}
            name={'raison'}
            value={reason}
            rows={isMobile ? '4' : '1'}
            onChange={(e) => setReason(e.target.value)}
          />
        </ModalBody>
        <ModalFooter
          className={'d-flex justify-content-between justify-content-md-end '}
        >
          <button className='btn btn-outline-light' onClick={hide}>
            Cancel
          </button>
          <button
            className='btn btn-danger'
            onClick={() => {
              if (!reason) {
                toastr.error('Please set a reason!')
                return
              }
              reject(reason)
              hide()
            }}
          >
            Decline
          </button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

const StyledCol = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 1px 0px #dfe1e6;
  border-radius: 4px;
  height: 100%;
`
const StyledNotif = styled.div`
  z-index: 9;
  position: absolute;
  left: 0;
  right: 0;
  top: 70px;
  min-height: 66px;
  @media (max-width: 425px) {
    top: 55px;
  }

  background-color: #ffffff;
  box-shadow: inset 0px -1px 0px #eaeaea;
`

export default ExpenseDetails
