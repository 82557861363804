import { AvForm, AvInput } from 'availity-reactstrap-validation'
import cx from 'classnames'
import moment from 'moment'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import CurrencyInput from 'react-currency-input-field'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  Col,
  Container,
  FormGroup,
  InputGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from 'reactstrap'
import toastr from 'toastr'

import ControlledSelect from '../../../../components/ControlledSelect'
import CustomDatePicker from '../../../../components/Forms/CustomDatePicker/CustomDatePicker'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import Alert from '../../../../components/ui/alert'
import { useFetch, useResize } from '../../../../helpers/hooks'
import { getOccurrences, updateProrata } from '../../../../services/api'
import { mapCurrencyToOption } from '../../../../utils/map-to-option'
import useRecurringExpenses from '../../hooks/use-recurring-expenses'
import styles from './payment-form.module.scss'
import { customOption, customSingleValue } from './select-components'

export function getCurrencyFromTaxCountry(currencies = [], currencyCode) {
  return currencies.find((c) => c.code === currencyCode)
}

function getErrors(checks = []) {
  if (checks.length === 0) {
    return true
  }

  return checks.filter(({ check }) => check).map(({ error }) => error)
}

const currencyInputAttrs = {
  autoComplete: 'off',
  allowDecimals: true,
  decimalsLimit: 2,
  decimalSeparator: '.',
  groupSeparator: ',',
}

const PaymentForm = forwardRef(function PaymentForm(
  {
    data,
    duplicatingData,
    onSubmit,
    isAmend,
    amendData,
    isUpdate,
    setTempData,
    tempData,
    isAdmin,
  },
  ref,
) {
  const occurrences = useFetch({ action: getOccurrences })

  const toDuplicate = useSelector((state) => state.Contract.toDuplicate)
  const isMobile = useResize()
  const firstPaymentMoment = moment(
    amendData?.first_payment_date || data?.first_payment_date,
    'YYYY-MM-DD',
  )
  const defaultStartDate =
    amendData?.start_date ||
    (toDuplicate ? new Date() : data?.start_date ?? new Date())

  const startDateMoment = moment(defaultStartDate)
  const endDateMoment =
    !amendData?.end_date && !data?.end_date
      ? null
      : moment(amendData?.end_date || data?.end_date)

  const [prorataCalculationTimes, setProrataCalculationTimes] = useState(0)
  const staticData = useSelector((state) => state.Layout.staticData)
  const savedFrequency = data?.frequency
    ? { value: data.frequency.id, label: data.frequency.name }
    : staticData?.frequencies
        ?.map((e) => ({
          value: e.id,
          label: e.name,
        }))
        .find((elm) => elm.value === data?.frequency_id)

  const savedCurrency = data?.currency
    ? mapCurrencyToOption(data.currency, 'id')
    : staticData?.currencies
        ?.filter((o) => o.id === data?.currency_id)
        ?.map((c) => mapCurrencyToOption(c, 'id'))
        .find((elm) => elm.value === data?.currency_id)

  const [frequencyId, setFrequencyId] = useState(
    amendData?.frequency_id || data?.frequency_id || data?.frequency
      ? savedFrequency
      : null,
  )

  const [currency, setCurrency] = useState(getDefaultCurrency())

  function getDefaultCurrency() {
    if (tempData?.currencyChangedManually && data?.currency) {
      return data?.currency
    }

    if (amendData?.currency_id || data?.currency_id || data?.currency) {
      return savedCurrency
    }

    if (data?.tax?.currency) {
      const newCurrency = getCurrencyFromTaxCountry(
        staticData?.currencies,
        data.tax.currency,
      )
      return newCurrency
    }

    const taxResidenceCountryCurrency = staticData?.countries?.filter(
      (country) => country.id === data?.tax_residence_id,
    )[0]?.currency

    const newCurrency = staticData?.currencies?.filter(
      (item) => item.code === taxResidenceCountryCurrency,
    )[0]?.id

    return newCurrency
  }

  const [startDate, setStartDate] = useState(
    startDateMoment?.isValid() ? new Date(startDateMoment.toString()) : null,
  )
  const [endDate, setEndDate] = useState(
    endDateMoment?.isValid() ? new Date(endDateMoment.toISOString()) : null,
  )
  const [amount, setAmount] = useState(
    amendData?.amount || data?.amount || duplicatingData?.amount,
  )
  const [paygType, setPaygType] = useState(
    amendData?.rate_id || {
      label: data?.rate_id?.name,
      value: data?.rate_id?.id,
    },
  )

  const savedOccurrence = data?.occurrence
    ? { value: data.occurrence.id, label: data.occurrence.name }
    : occurrences.data
        ?.filter((o) => o.frequency_id === frequencyId?.value)
        ?.map((e) => ({ value: e.id, label: e.name }))
        .find((elm) => elm.value === data?.occurrence_id)

  const [occurrence, setOccurrence] = useState(
    amendData?.occurrence_id || data?.occurrence_id || data?.occurrence
      ? savedOccurrence
      : null,
  )
  const [firstPayment, setFirstPayment] = useState(
    amendData?.first_payment_date || isAmend
      ? null
      : firstPaymentMoment.isValid()
      ? {
          label: firstPaymentMoment.format('dddd, Do MMM'),
          value: firstPaymentMoment.format('YYYY-MM-DD'),
        }
      : null,
  )
  const [isProrata, setIsProrata] = useState(
    isAmend
      ? false
      : !!(amendData?.first_payment_prorata || data?.first_payment_prorata),
  )
  const [prorataValue, setProrataValue] = useState(
    amendData?.prorata_amount ||
      data?.prorata_amount ||
      data?.first_payment_amount,
  )

  const prorata = useFetch({
    action: updateProrata,
    onComplete: (data) => {
      setProrataValue(data?.amount)
    },
  })
  const {
    control,
    formState: { errors },
  } = useForm({})

  const didMountRef = useRef(false)

  useEffect(() => {
    if (startDate) {
      occurrences.startFetch({
        start_date: moment(startDate).format('YYYY-MM-DD'),
      })
    }
    if (didMountRef.current) {
      setFirstPayment(null)
    } else {
      didMountRef.current = true
    }
  }, [startDate])

  useEffect(() => {
    if (tempData?.currencyChangedManually) {
      return () => {}
    }

    const tax = data?.tax_residence
      ? data?.tax_residence?.id
      : data?.tax_residence_id

    const cur = staticData?.currencies?.filter(
      (item) =>
        item.code ===
        staticData?.countries?.filter((countr) => countr.id === tax)[0]
          ?.currency,
    )[0]

    if (data && data.tax_residence_id && cur) {
      const currency = mapCurrencyToOption(cur, 'id')

      setCurrency(currency)
    } else if (amendData?.currency_id || data?.currency_id || data?.currency) {
      setCurrency(savedCurrency)
    } else {
      const usdCurrency = mapCurrencyToOption(
        staticData?.currencies?.find((c) => c.code === 'USD'),
        'id',
      )
      setCurrency(usdCurrency)
    }
  }, [data])

  useEffect(() => {
    if (prorataCalculationTimes > 0) {
      prorata.startFetch({
        amount,
        first_payment_date: firstPayment?.value,
        frequency_id: frequencyId?.value,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        occurrence_id: occurrence?.value,
        end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
      })
    }
    setProrataCalculationTimes(prorataCalculationTimes + 1)
  }, [firstPayment, amount, startDate, endDate, isProrata])

  const handleValidSubmit = useCallback(
    (e, v) => {
      const bodyData = {
        ...v,
        amount,
        currency,

        first_payment_date: firstPayment?.value,
        end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
        start_date: moment(startDate).format('YYYY-MM-DD'),

        occurrence_id: isAmend
          ? occurrence
          : data?.occurrence_id
          ? data.occurrence_id
          : occurrence?.value,
        frequency_id: isAmend ? frequencyId : frequencyId?.value,
        rate_id: isAmend ? paygType : paygType?.value,
        currency_id: isAmend ? currency : currency?.value,
      }

      if (isProrata) {
        bodyData.prorata_amount = prorataValue
      } else {
        delete bodyData.prorata_amount
      }
      if (!endDate) delete bodyData.end_date

      const errors = getErrors([
        { check: !startDate, error: 'Start Date is required' },
        { check: !amount, error: 'Amount is required' },
        { check: !currency, error: 'Amount currency is required' },
        {
          check: !(
            data?.type?.toUpperCase() !== 'PAY AS YOU GO' || paygType?.value
          ),
          error: 'Unit (of work) is required',
        },
        { check: !frequencyId, error: 'Frequency of payments is required' },
        {
          check: !(occurrence || data?.occurrence_id),
          error: 'Payments occurrence is required',
        },
        {
          check: !firstPayment,
          error: 'Date for the first payment is required',
        },
        {
          check: !(isProrata ? !!prorataValue : true),
          error: 'Prorata is required',
        },
      ])

      if (errors.length > 0) {
        toastr.error(errors[0])
        return
      }

      if (
        amount &&
        firstPayment &&
        frequencyId &&
        startDate &&
        currency &&
        (occurrence || data?.occurrence_id) &&
        (data?.type?.toUpperCase() !== 'PAY AS YOU GO' || paygType?.value) &&
        (isProrata ? !!prorataValue : true)
      ) {
        onSubmit(bodyData)
      } else {
        toastr.error('You need to fill all required fields')
      }
    },
    [
      amount,
      currency,
      data.occurrence_id,
      data?.type,
      endDate,
      firstPayment,
      frequencyId,
      isAmend,
      isProrata,
      occurrence,
      onSubmit,
      paygType,
      prorataValue,
      startDate,
    ],
  )

  const contract = useSelector((state) => state.Contract?.details)
  const { data: recurringExpenses } = useRecurringExpenses({
    isAdmin,
    contract,
  })

  const [showRecurringExpensesMessage, setShowRecurringExpensesMessage] =
    useState(false)

  function handleCurrencyChange(value) {
    const activeExpenses = recurringExpenses?.filter(
      (expense) => expense.status === 'active',
    )

    if (activeExpenses?.length > 0) {
      setShowRecurringExpensesMessage(true)
    }

    setCurrency(value)
    setTempData({ currencyChangedManually: true })
  }

  return (
    <AvForm
      ref={ref}
      className='align-items-center'
      onValidSubmit={handleValidSubmit}
    >
      <Container>
        {!showRecurringExpensesMessage ? null : (
          <Row>
            <Col>
              <Alert color='info'>
                If the currency is changed, this contract’s active recurring
                expenses will be deactivated.
              </Alert>
            </Col>
          </Row>
        )}

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label className='col-form-label pt-0 pt-0'>
                Start Date:
                <span className='text-danger font-size-16 ml-1'>*</span>
              </Label>
              <Col className='p-0'>
                <CustomDatePicker
                  value={startDate}
                  handleOnChange={setStartDate}
                  dateFormat='dd/MM/yyyy'
                  inputClassName={styles.datePicker}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className='col-form-label pt-0'>End Date:</Label>
              <Col className='p-0'>
                <CustomDatePicker
                  value={endDate}
                  handleOnChange={setEndDate}
                  placeholder='(Optional)'
                  dateFormat='dd/MM/yyyy'
                  minDate={startDate}
                  inputClassName={styles.datePicker}
                  handleClear={() => setEndDate(null)}
                  clearable
                />
              </Col>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className='col-form-label pt-0'>
                Amount:
                <span className='text-danger font-size-16 ml-1'>*</span>
              </Label>
              <InputGroup>
                <CurrencyInput
                  {...currencyInputAttrs}
                  id='contract-currency'
                  name='amount'
                  className={cx('form-control', styles.datePicker)}
                  placeholder='Amount'
                  type='text'
                  onKeyPress={(e) => {
                    if (!/[0-9.]/.test(e.key)) {
                      e.preventDefault()
                    }
                  }}
                  value={amount}
                  onValueChange={setAmount}
                  // currency input props
                  allowDecimals={true}
                  decimalsLimit={2}
                  decimalSeparator='.'
                  groupSeparator=','
                />

                <ControlledSelect
                  className={cx(
                    'input-group-text p-0',
                    styles.selectAddonAppend,
                  )}
                  selectStyles={{
                    control: (provided) => ({ ...provided, width: '100%' }),
                    valueContainer: (provided) => ({
                      ...provided,
                      paddingLeft: 0,
                    }),
                  }}
                  placeholder='Currency'
                  error={errors.currency_id}
                  control={control}
                  name='currency_id'
                  options={staticData?.currencies?.map((c) =>
                    mapCurrencyToOption(c, 'id'),
                  )}
                  value={currency}
                  onChange={handleCurrencyChange}
                  classNamePrefix='RS-Addon'
                  customComponents={{
                    Option: customOption,
                    Value: customSingleValue,
                    SingleValue: customSingleValue,
                  }}
                  leftDir={isMobile}
                />
              </InputGroup>
            </FormGroup>
          </Col>

          {data?.type?.toUpperCase() === 'PAY AS YOU GO' && (
            <Col md={6}>
              <FormGroup>
                <label className='col-form-label pt-0'>
                  Unit (of work):
                  <span className='text-danger font-size-16 ml-1'>*</span>
                </label>
                <div>
                  <CustomSelect
                    className='border-0'
                    name='rate_id'
                    onChange={setPaygType}
                    value={paygType}
                    isSearchable={false}
                    options={staticData?.rates?.map((e) => ({
                      value: e.id,
                      label: e.name,
                    }))}
                  />
                </div>
              </FormGroup>
            </Col>
          )}

          <Col md={6}>
            <FormGroup style={{ zIndex: 9 }}>
              <Label className='col-form-label pt-0'>
                Frequency of payments:
                <span className='text-danger font-size-16 ml-1'>*</span>
              </Label>
              <CustomSelect
                onChange={(s) => {
                  setFrequencyId(s)
                  setOccurrence(null)
                  setFirstPayment(null)
                }}
                value={frequencyId}
                name='frequency_id'
                isSearchable={false}
                options={staticData?.frequencies?.map((e) => ({
                  value: e.id,
                  label: e.name,
                }))}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            {(frequencyId !== null || data?.occurrence_id) && (
              <FormGroup style={{ zIndex: 8 }}>
                <Label className='col-form-label pt-0'>
                  When would you process payments?
                  <span className='text-danger font-size-16 ml-1'>*</span>
                </Label>
                <CustomSelect
                  name='occurrence_id'
                  onChange={(s) => {
                    setOccurrence(s)
                    setFirstPayment(null)
                  }}
                  isSearchable={false}
                  value={occurrence || savedOccurrence}
                  styles={simpleStyles}
                  options={occurrences.data
                    ?.filter(
                      (o) =>
                        o.frequency_id ===
                        (data?.frequency_id
                          ? data.frequency_id
                          : frequencyId?.value),
                    )
                    ?.map((e) => ({
                      value: e.id,
                      label: e.name,
                    }))}
                />
              </FormGroup>
            )}
          </Col>
          <Col md={6}>
            {(occurrence !== null || data?.occurrence_id) && (
              <FormGroup style={{ zIndex: 8 }}>
                <Label className='col-form-label pt-0'>
                  Date for the first payment:
                  <span className='text-danger font-size-16 ml-1'>*</span>
                </Label>
                <CustomSelect
                  name='first_payment'
                  onChange={(s) => {
                    setFirstPayment(s)
                  }}
                  isSearchable={false}
                  value={firstPayment}
                  styles={simpleStyles}
                  options={
                    occurrences.data?.find((o) => o.id === occurrence?.value)
                      ?.dates
                  }
                />
              </FormGroup>
            )}
          </Col>
          {['Fixed', 'Full time'].includes(data?.type) &&
            firstPayment !== null && (
              <>
                <Col md={6}>
                  <FormGroup className='pt-2' style={{ zIndex: 9 }}>
                    <Col>
                      <Row
                        className='flex-row position-relative'
                        style={{ zIndex: 0 }}
                      >
                        <div
                          className='custom-control custom-switch align-items-center'
                          dir='ltr'
                        >
                          <AvInput
                            name='first_payment_prorata'
                            type='checkbox'
                            className='custom-control-input'
                            onChange={(e) => {
                              setIsProrata(e.target.checked)
                            }}
                            id='customSwitch1'
                            defaultChecked={isProrata}
                          />
                          <label
                            className='custom-control-label'
                            htmlFor='customSwitch1'
                          >
                            <span>
                              Prorata on the first payment{' '}
                              <i id='prorata' className='bx bx-info-circle' />
                            </span>
                            <UncontrolledTooltip
                              placement='top'
                              target='prorata'
                            >
                              Pro-rata is calculated based on the days worked in
                              the 1st cycle [days worked * (amount / days in a
                              1st cycle)]
                            </UncontrolledTooltip>
                          </label>
                        </div>
                      </Row>
                    </Col>
                  </FormGroup>
                </Col>
                <Col md={6} />
              </>
            )}
          <Col md={6}>
            {['Fixed', 'Full time'].includes(data?.type) &&
              isProrata &&
              firstPayment !== null && (
                <FormGroup>
                  <Label className='col-form-label pt-0'>
                    Amount of the first payment
                    <span className='text-danger font-size-16 ml-1'>*</span>
                  </Label>
                  <div style={{ position: 'relative' }}>
                    <div
                      className='text-bold d-flex align-items-center'
                      style={{
                        position: 'absolute',
                        zIndex: 910,
                        left: 15,
                        top: 0,
                        bottom: 0,
                      }}
                    >
                      {currency?.symbol}
                    </div>
                    <CurrencyInput
                      {...currencyInputAttrs}
                      name='prorata_amount'
                      type='text'
                      onKeyPress={(e) => {
                        if (!/[0-9.]/.test(e.key)) {
                          e.preventDefault()
                        }
                      }}
                      value={prorataValue}
                      required
                      placeholder='Prorata'
                      id='prorata_amount'
                      className='form-control'
                      onValueChange={setProrataValue}
                      style={{
                        paddingLeft: `calc(24px + ${currency?.symbol?.length}ch)`,
                      }}
                      // currency input props
                      allowDecimals={true}
                      decimalsLimit={2}
                      decimalSeparator='.'
                      groupSeparator=','
                    />
                  </div>
                </FormGroup>
              )}
          </Col>
        </Row>
      </Container>
    </AvForm>
  )
})

const simpleStyles = {
  menu: () => ({
    position: 'absolute',
    backgroundColor: 'white',
    zIndex: 99999,
    width: '100%',
    borderRadius: '3px',
    borderColor: '#dddddd',
    borderWidth: '1px',
    borderStyle: 'solid',
  }),
  dropdownIndicator: () => ({
    color: '#114EF7',
    paddingRight: '10px',
    paddingLeft: '10px',
  }),
  indicatorSeparator: () => ({
    width: 0,
  }),
}

export default PaymentForm
