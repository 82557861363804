import React, { forwardRef, useEffect, useState } from 'react'
import { AvForm } from 'availity-reactstrap-validation'
import {
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  UncontrolledTooltip,
} from 'reactstrap'
import { useSelector } from 'react-redux'

import ContractSelect from '../../CreateContract/ContractSelect'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import { contractTypes } from '../../../../helpers/enum'

const ComplianceForm = forwardRef(function ComplianceForm(
  {
    onSubmit,
    data,
    templates,
    amendData,
    uploaded,
    onUploadStatusChanged,
    type,
    isAmend,
  },
  ref,
) {
  const staticData = useSelector((state) => state.Layout.staticData)
  const attachmentType = ['default', 'uploaded', 'custom']
  const getContractMedia = () => {
    if (amendData?.template) {
      return 2
    }
    if (amendData?.rw_file) {
      return 0
    }
    if (amendData?.file) {
      return 1
    }
    return 0
  }
  const [notice, setNotice] = useState(
    amendData?.notice_period || data?.notice_period || 15,
  )
  const [file, setFile] = useState(null)
  const [contractMedia, setContractMedia] = useState(
    data?.template_type || amendData?.template_type
      ? attachmentType.indexOf(data?.template_type || amendData?.template_type)
      : getContractMedia(),
  )
  const [template, setTemplate] = useState(null)
  // const [taxResidence, setTaxResidence] = useState(
  //   amendData?.taxResidence ||
  //     (data?.tax_residence
  //       ? { label: data?.tax_residence.name, value: data?.tax_residence.id }
  //       : null),
  // )

  const [label, setLabel] = useState({ value: '1', label: 'Contractor' })

  const dataTemplate = templates?.find((e) => e?.id === data?.template_id)

  useEffect(() => {
    setTemplate(
      dataTemplate
        ? { value: dataTemplate?.id, label: dataTemplate?.name }
        : amendData?.template,
    )
  }, [amendData?.template, dataTemplate])

  useEffect(() => {
    return () => {
      setFile(null)
      setTemplate(null)
    }
  }, [contractMedia])

  useEffect(() => {
    if (staticData) {
      const initLabel = staticData?.contractor_types?.find(
        (e) =>
          e.name === amendData?.contractor_name ||
          e.name === data?.contractor_name,
      )
      if (initLabel) {
        setLabel({ value: initLabel?.id, label: initLabel?.name })
      }
    }
  }, [amendData?.contractor_name, data?.contractor_name, staticData])

  return (
    <AvForm
      ref={ref}
      onValidSubmit={(e, v) => {
        onSubmit(
          e,
          { ...v, contractor_name: label?.label },
          file,
          notice,
          contractMedia,
          contractMedia ? template : null,
        )
      }}
    >
      <Row className='justify-content-center'>
        <Col>
          {!isAmend && (
            <Row className='justify-content-center'>
              <ContractSelect
                onContractChanged={(i) => {
                  setContractMedia(i)
                  if (i === 0) {
                    setFile(null)
                  }
                }}
                value={contractMedia}
                onFileUploaded={setFile}
                templates={templates}
                template={template}
                uploaded={uploaded}
                onTemplateSelected={setTemplate}
                onUploadStatusChanged={onUploadStatusChanged}
              />
            </Row>
          )}
          <Row className='pt-3'>
            {type !== contractTypes.FULL_TIME && (
              <Col md={6}>
                <FormGroup>
                  <Label for='basicpill-pancard-input6'>
                    Notice period (days) to terminate the contract
                    <span
                      className='text-danger font-size-16 mx-1'
                      style={{ lineHeight: 1 }}
                    >
                      *
                    </span>
                  </Label>
                  <InputGroup>
                    <Input
                      // name="notice_period"
                      type='text'
                      onKeyPress={(e) => {
                        if (!/[0-9.]/.test(e.key)) {
                          e.preventDefault()
                        }
                      }}
                      id='noticeDays'
                      className='form-control'
                      value={notice}
                      onChange={(e) => {
                        setNotice(e.target.value)
                      }}
                    />
                    <InputGroupAddon addonType='append'>
                      <InputGroupText className='bg-transparent text-muted'>
                        Days
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
            )}
            {type !== contractTypes.FULL_TIME && !isAmend && (
              <Col md={6}>
                <FormGroup>
                  <Label for='basicpill-pancard-input6'>
                    Contractor Label
                    <i id='contractorName' className='bx bx-info-circle' />
                    <UncontrolledTooltip
                      placement='right'
                      target='contractorName'
                    >
                      By default this label is set to ‘Contractor’. You can
                      change it to; Consultant, Freelancer, Expert, Service
                      provider…etc. If you change this label, the change will be
                      reflected on the Agreement as well as the Contract
                      details. *Avoid using Employee as a label, if you are
                      using our contract template.
                    </UncontrolledTooltip>
                  </Label>

                  <CustomSelect
                    onChange={setLabel}
                    value={label}
                    options={staticData?.contractor_types?.map((e) => ({
                      value: e.id,
                      label: e.name,
                    }))}
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </AvForm>
  )
})

export default ComplianceForm
