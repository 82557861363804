import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Popover, PopoverBody } from 'reactstrap'

import { ReactComponent as CardComingSoon } from '../../../assets/images/card-coming-soon.svg'
import Button from '../../../components/ui/button'
import FEATURE_FLAGS from '../../../config/feature-flags'
import { CARD_TYPE_ENUM } from '../utils/card-types-status'
import { PendingPhysicalCard } from '../components/pending-card'

export function isPhysicalCardPending({ status, type }) {
  return (
    type === CARD_TYPE_ENUM.PHYSICAL && ['pending', 'issued'].includes(status)
  )
}

export default function PhysicalCardStates({
  alreadyHasPhysicalCard,
  cardOrder,
}) {
  const history = useHistory()
  const [popoverOpen, setPopoverOpen] = useState(false)

  const cardOrderStatus = cardOrder?.status
  const cardOrderType = cardOrder?.card_type

  // ready to ship, shipped
  const cardOrderShippingStatus = cardOrder?.shipping_details?.shipping_status

  const canActivatePhysicalCard = cardOrderShippingStatus === 'shipped'

  const physicalCardPending = isPhysicalCardPending({
    status: cardOrderStatus,
    type: cardOrderType,
  })

  const activateCardButtonDisabled =
    !cardOrderShippingStatus || !canActivatePhysicalCard

  function handleActivateCard() {
    history.push('/cards/activate-physical-card')
    window.scroll(0, 0)
  }

  return (
    <>
      {!FEATURE_FLAGS.REMOTEPASS_PHYSICAL_CARDS ? (
        <PhysicalCardNotAvailable />
      ) : physicalCardPending ? (
        <>
          <PendingPhysicalCard />

          <div className='align-items-md-end border-top d-flex flex-column gap-8 p-4 w-100'>
            <div
              className='position-relative'
              style={{ alignSelf: 'flex-end' }}
            >
              <Button
                onClick={handleActivateCard}
                disabled={activateCardButtonDisabled}
              >
                Activate card
              </Button>
              <div
                className={`position-absolute inset-0 rounded ${
                  activateCardButtonDisabled ? '' : ' d-none'
                }`}
                onMouseOver={() => setPopoverOpen(true)}
                onMouseOut={() => setPopoverOpen(false)}
                id='activate-card-button'
              />
            </div>
          </div>
          <Popover
            placement='top'
            target='activate-card-button'
            trigger='click'
            isOpen={popoverOpen}
            popperClassName='z-1010 shadow-lg'
          >
            <PopoverBody
              className='text-center text-gray-h rp-font-gilroyB'
              style={{ maxWidth: '24ch' }}
            >
              Your card should arrive within 15 business days.
            </PopoverBody>
          </Popover>
        </>
      ) : !alreadyHasPhysicalCard ? (
        <RequestPhysicalCard />
      ) : null}
    </>
  )
}

function PhysicalCardNotAvailable() {
  return (
    <div className='align-items-center d-flex flex-column gap-2 justify-content-center flex-grow-1'>
      <CardComingSoon />

      <p className='font-size-24 mb-0 rp-font-gilroyB text-gray-h text-center'>
        Physical card coming soon
      </p>
      <p
        className='mb-0 text-center font-size-16 text-gray-600'
        style={{ maxWidth: '36ch' }}
      >
        You will get a notification when physical cards are available.
      </p>
    </div>
  )
}

function RequestPhysicalCard() {
  return (
    <div className='align-items-center d-flex flex-column gap-2 justify-content-center flex-grow-1'>
      <CardComingSoon />

      <p className='font-size-24 mb-0 rp-font-gilroyB text-gray-h text-center'>
        Request your physical card
      </p>
      <p
        className='mb-0 text-center font-size-16 text-gray-600'
        style={{ maxWidth: '36ch' }}
      >
        Physical cards are accepted globally on the Mastercard Network.
      </p>
    </div>
  )
}
