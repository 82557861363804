import React from 'react'
import { Card, CardBody, Row } from 'reactstrap'

import { StyledH3 } from '../../components/Typo'
import Loader from '../../components/ui/loader'
import { useFetch } from '../../helpers/hooks'
import { getIntegration } from '../../services/api'
import IntegrationCard from './components/IntegrationCard'

const Integrations = () => {
  const {
    data: integrations,
    isLoading,
    startFetch: fetchIntegrations,
  } = useFetch(
    {
      action: getIntegration,
      autoFetch: true,
    },
    [],
  )

  return (
    <Card className='rp-rounded-top-0 mb-0'>
      <CardBody>
        {!isLoading ? null : <Loader minHeight='max(40vh, 380px)' />}

        {integrations?.map((cat, index) => (
          <React.Fragment key={index}>
            <StyledH3
              className={'font-size-24 font-weight-bold mt-3 pb-2 ml-3'}
            >
              {cat?.category}
            </StyledH3>
            <Row className='mt-3 m-0 p-0'>
              {cat?.list?.map((e, i) => (
                <IntegrationCard
                  key={`integration-${e.id}-${i}`}
                  item={e}
                  onUpdate={fetchIntegrations}
                />
              ))}
            </Row>
          </React.Fragment>
        ))}
      </CardBody>
    </Card>
  )
}

export default Integrations
