import cx from 'classnames'
import React from 'react'
import { components } from 'react-select'

import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import { FlagIcon } from '../../../components/ui/flag-icon'

function getOptionIndex(options, value) {
  return options.findIndex((option) => option?.currency?.id === value)
}

export function CustomBalanceOption(props) {
  const balance = props.data
  const formatter = getCurrencyFormatter(balance?.currency?.code)

  const index = getOptionIndex(props.options, balance?.currency?.id)

  const isLast = index === props.options.length - 1

  return (
    <components.Option
      className={cx({ 'border-bottom': !isLast })}
      {...props}
      innerProps={{
        ...props.innerProps,
        style: {
          padding: '4px 12px',
          color: props.isDisabled ? '#777f9e' : undefined,
          backgroundColor: props.isDisabled ? '#f5f6f7' : undefined,
        },
      }}
    >
      <div
        className='d-flex align-items-center justify-content-between font-size-18'
        style={{ gap: '0.5rem', height: 48 }}
      >
        <div className='d-flex' style={{ gap: '0.5rem' }}>
          <FlagIcon
            code={balance?.currency?.code}
            size='28px'
            className='border'
            style={{ filter: props.isDisabled ? 'grayscale(0.9)' : undefined }}
          />

          <span className='rp-font-gilroyB'>{balance?.currency?.code}</span>
        </div>
        <span className='rp-font-gilroyB'>
          {formatter.format(balance.balance)}
        </span>
      </div>
    </components.Option>
  )
}
