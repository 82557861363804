import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { Modal, ModalBody } from 'reactstrap'
import styled from 'styled-components'

import arrowUp from '../../assets/images/arrowUp.svg'
import rpLogoWhite from '../../assets/images/identity/logo_main.png'
import { StyledH4, StyledH5 } from '../../components/Typo'
import {
  loginUserSuccessful,
  saveRoute,
  updateDeepLink,
} from '../../store/auth/register/actions'

const StyledDiv = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 0px 8px #eaeaea, inset 0px -1px 0px #eaeaea;

  > img {
    padding: 28px 0;
    height: 80px;
  }
`

const LogoutLink = styled(Link)`
  width: 100%;
  display: block;
  text-align: center;
  font-size: 14px;
  text-decoration: underline !important;
`

const StyledTitleCont = styled.button`
  box-shadow: 0px 1px 0px #dfe1e6;

  &:hover {
    box-shadow: 0px 0px 0px 2px #315ce7 inset;
  }
`

const UserTypeChooser = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.Account?.user)
  const link = useSelector((state) => state.Account?.link)
  const history = useHistory()
  const lastPath = useSelector((state) => state.Account?.lastPath)

  const handleLoginResponse = (data) => {
    dispatch(loginUserSuccessful(data))
    if (lastPath) {
      dispatch(saveRoute(null))
      history.push(lastPath)
    } else if (link && typeof link === 'string') {
      history.push(link)
      dispatch(updateDeepLink(null))
    }
  }

  return (
    <Modal
      style={{ maxWidth: '100%', height: '100%' }}
      isOpen={true}
      className='p-0 m-0 switch-modal'
      modalClassName='bg-gray-bg'
      contentClassName='bg-transparent'
    >
      <StyledDiv className='d-flex justify-content-center align-items-center'>
        <img src={rpLogoWhite} alt='' />
      </StyledDiv>
      <ModalBody
        className='p-md-0 m-md-0 bg-transparent'
        style={{ height: 'calc(100vh - 80px)' }}
      >
        <div style={{ width: '100%', maxWidth: 500 }}>
          <div
            className='text-center mb-3 mb-md-4 mb-5'
            style={{ marginTop: '5rem' }}
          >
            <StyledH5 min={'22px'} max={'32px'}>
              Welcome back {user?.[0]?.first_name}!
            </StyledH5>
            <p className='text-muted mb-0 mt- font-size-14'>
              Choose a profile below to pick up where you left off.
            </p>
          </div>
          <div className='d-flex flex-column pb-3' style={{ gap: '1rem' }}>
            {user?.length <= 0
              ? null
              : user?.map((user, i) => (
                  <StyledTitleCont
                    key={i}
                    onClick={() => {
                      handleLoginResponse(user)
                    }}
                    className='d-flex justify-content-between align-items-center text-left w-100 bg-white rounded border-0'
                    style={{ padding: '25px 30px', gap: 10 }}
                  >
                    <div
                      className='d-flex justify-content-between align-items-center'
                      style={{ gap: 20, flex: 1 }}
                    >
                      {!user?.photo ? (
                        <div className='avatar-sm mx-auto'>
                          <span
                            className={`rounded-circle text-white font-size-16 ${
                              user?.type === 'client'
                                ? 'avatar-title-green'
                                : 'avatar-title'
                            }`}
                          >
                            {user?.first_name
                              ? user?.first_name?.charAt(0)
                              : user?.email?.charAt(0)}
                          </span>
                        </div>
                      ) : (
                        <img
                          src={user?.photo}
                          style={{
                            width: 50,
                            height: 50,
                            objectFit: 'cover',
                            borderRadius: '50%',
                          }}
                        />
                      )}

                      <div style={{ flex: 1 }}>
                        <StyledH4
                          min='16px'
                          max='16px'
                          className='mb-0 text-dark'
                          style={{
                            fontFamily: 'GilroyB',
                            fontWeight: 'bold',
                          }}
                        >
                          {user?.type === 'client' ? 'Client' : 'Contractor'}{' '}
                          account
                        </StyledH4>
                        <p
                          style={{
                            marginBottom: 0,
                            marginTop: 6,
                            fontSize: 14,
                            color: '#76809d',
                          }}
                        >
                          {user?.type === 'client'
                            ? user?.company_name
                            : user?.first_name + ' ' + user?.last_name}
                        </p>
                      </div>
                    </div>
                    <img
                      style={{
                        maxWidth: '25px',
                        transform: 'rotate(90deg)',
                      }}
                      src={arrowUp}
                    />
                  </StyledTitleCont>
                ))}
            <LogoutLink style={{ color: '#315CE7' }} to='/logout'>
              Logout
            </LogoutLink>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default UserTypeChooser
