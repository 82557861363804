import * as yup from 'yup'

export const infoFormFields = [
  {
    label: 'First Name:',
    name: 'first_name',
    type: 'text',
    disabled: true,
    rules: { required: 'This field is required' },
  },
  {
    label: 'Middle Name:',
    name: 'middle_name',
    type: 'text',
    disabled: true,
  },
  {
    label: 'Last Name:',
    name: 'last_name',
    type: 'text',
    disabled: true,
    rules: { required: 'This field is required' },
  },
  {
    label: 'Birth Date:',
    name: 'birthday',
    type: 'date',
    disabled: true,
    rules: { required: 'This field is required' },
  },
  {
    label: 'Email:',
    name: 'email',
    type: 'email',
    disabled: true,
    rules: { required: 'This field is required' },
  },
  {
    label: 'Mobile phone:',
    name: 'mobile',
    type: 'tel',
    disabled: true,
    rules: { required: 'This field is required' },
  },
  {
    label: 'Nationality:',
    name: 'nationality',
    type: 'nationality',
    disabled: true,
    rules: { required: 'This field is required' },
  },
  {
    label: 'Gender:',
    name: 'gender',
    type: 'gender',
    rules: { required: 'This field is required' },
  },
  {
    label: 'Preferred name on the card:',
    placeholder: 'Preferred name on the card',
    name: 'preferred_name',
    type: 'text',
    rules: {
      required: 'This field is required',
      max: 25,
      maxMessage: 'This field should be at most 25 characters',
    },
  },
]

function getSelectYupValidation(field) {
  const selectSchema = yup.object().required(field.rules.required)

  return field?.isMulti
    ? yup.array(selectSchema).required(field.rules.required)
    : selectSchema
}

export function addInfoFormValidation(baseSchema, fields) {
  let newSchema = baseSchema ?? yup.object()

  fields.forEach((field) => {
    if (field?.rules?.required) {
      const selectFieldTypes = ['select', 'nationality', 'gender', 'title']
      const fieldSchema = selectFieldTypes.includes(field.type)
        ? getSelectYupValidation(field)
        : field.rules?.max
        ? yup
            .string()
            .max(field.rules.max, field.rules.maxMessage)
            .required(field.rules.required)
        : yup.string().required(field.rules.required)

      newSchema = newSchema.concat(
        yup.object().shape({ [field.name]: fieldSchema }),
      )
    }
  })

  return newSchema
}

export function getDefaultInfoValues(user) {
  return {
    first_name: user?.first_name,
    middle_name: user?.middle_name,
    last_name: user?.last_name,
    email: user?.email,
    mobile: user?.phone,
    rp_user_id: user?.id.toString(),
    birthday: new Date(user?.birth_date),
  }
}
