import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from 'reactstrap'

import NoContent from '../../../../../components/NoContent'
import StyledTh from '../../../../../components/Table/StyledTh'
import TableComp from '../../../../../components/Table/TableComp'
import TableH from '../../../../../components/Table/TableH'
import { CONTRACT_TYPES } from '../../../../../core/config/contract-types'
import { CONTRACT_STATUS } from '../../../../../helpers/enum'
import { useFetch } from '../../../../../helpers/hooks'
import { getContractDetailAdmin } from '../../../../../services/api'
import { updateContract } from '../../../../../store/contract/actions'
import ContractDetail from '../../../../Contract/ContractDetail'
import { MilestoneCard, WorkCard } from '../../../../Contract/ContractPage'
import Documents from '../../../../Contract/ContractPage/Documents'
import AdminEmployeeDocuments from '../../../../Contract/ContractPage/employee-documents'
import Expenses from '../../../../Contract/ContractPage/Expenses'
import PaymentHistory from '../../../../Contract/ContractPage/Payments'
import Settings from '../../../../Contract/ContractPage/Settings'
import TimesOff from '../../../../Contract/ContractPage/TimesOff'
import Invoices from '../../../../Invoices'
import emptyDocs from './../../../../../assets/images/empty_docs.png'

const tabs = [
  { label: 'Contract Details', id: '5' },
  { label: 'Settings', id: '12' },
  { label: 'Payments', id: '6' },
  { label: 'Documents', id: '7' },
  {
    label: 'Employee Documents',
    id: '13',
    show: (type) => type === CONTRACT_TYPES.FULL_TIME,
  },
  {
    label: 'Milestones',
    id: '8',
    show: (type) => type === CONTRACT_TYPES.MILESTONES,
  },
  {
    label: 'Submitted Work',
    id: '8',
    show: (type) => type === CONTRACT_TYPES.PAY_AS_YOU_GO,
  },
  { label: 'Expenses', id: '9' },
  { label: 'Time off', id: '10' },
  { label: 'Invoices', id: '11' },
]

const ContractDetailAdmin = (props) => {
  const [activeTab, setActiveTab] = useState('5')
  // const history = useHistory()
  const user = useSelector((state) => state.Account?.user)
  const dispatch = useDispatch()
  const id = new URLSearchParams(props.location.search).get('id')
  const contractDetails = useFetch(
    {
      autoFetch: true,
      action: getContractDetailAdmin,
      withAdminAccess: true,
      body: { id },
      onComplete: (data) => {
        dispatch(updateContract(data))
      },
    },
    [id],
  )

  const isContractOngoing =
    contractDetails.data?.status?.id === CONTRACT_STATUS.ONGOING.value

  const update = () => {
    contractDetails.startFetch({ id })
  }
  useEffect(() => {
    return () => {
      dispatch(updateContract(null))
    }
  }, [])
  return (
    <div className='page-content'>
      {contractDetails.isLoading ? (
        <Col style={{ minHeight: '30rem' }}>
          <Row
            style={{ minHeight: '30rem' }}
            className='justify-content-center align-items-center'
          >
            <Spinner type='grow' className='mr-2' color='primary' />
          </Row>
        </Col>
      ) : (
        <Container fluid>
          <Nav tabs className='nav-tabs-custom'>
            {tabs.map(({ id, label, show }) => {
              const showTab =
                typeof show === 'function'
                  ? show(contractDetails.data?.type)
                  : true

              if (!showTab) return null

              return (
                <NavItem key={id}>
                  <NavLink
                    style={{ cursor: 'pointer' }}
                    className={classnames({ active: activeTab === id })}
                    onClick={() => setActiveTab(id)}
                  >
                    {label}
                  </NavLink>
                </NavItem>
              )
            })}
          </Nav>

          <TabContent activeTab={activeTab} className='p-0'>
            <TabPane tabId='5' className='py-3'>
              <ContractDetail
                isAdmin
                history={props.history}
                id={id}
                data={contractDetails.data}
              />
            </TabPane>
            <TabPane tabId='9' className='py-3'>
              <Expenses />
            </TabPane>
            <TabPane tabId='10' className='py-3'>
              <TimesOff />
            </TabPane>
            <TabPane tabId='6' className='py-3'>
              <PaymentHistory contract={contractDetails.data} isAdmin />
            </TabPane>
            <TabPane tabId='7' className='py-3'>
              {contractDetails.data?.contractor ? (
                <Documents
                  isAdmin
                  docs={[contractDetails.data?.contractor]}
                  isContract
                  contractId={contractDetails.data?.id}
                />
              ) : (
                <Card>
                  <CardBody>
                    <NoContent
                      headline={'No documents added'}
                      subtitle={
                        user?.type === 'client'
                          ? 'All compliance documents will be shown here when added by the contractor'
                          : 'Your KYC & compliance documents will be shown here'
                      }
                      image={emptyDocs}
                    />
                  </CardBody>
                </Card>
              )}
            </TabPane>
            <TabPane tabId='13' className='py-3'>
              <AdminEmployeeDocuments isContractOngoing={isContractOngoing} />
            </TabPane>
            {contractDetails.data?.type === CONTRACT_TYPES.PAY_AS_YOU_GO && (
              <TabPane tabId='8' className='py-3'>
                {contractDetails.data?.works?.length > 0 && (
                  <Col className='p-0'>
                    {contractDetails.data?.works?.map((e, i) => (
                      <WorkCard key={`work-${i}`} e={e} isAdmin />
                    ))}
                  </Col>
                )}
              </TabPane>
            )}
            {contractDetails.data?.type === CONTRACT_TYPES.MILESTONES && (
              <TabPane tabId='8' className='py-3'>
                <Card
                  style={{
                    backgroundColor: '#FFFFFF',
                    boxShadow: '0px 1px 0px #DFE1E6',
                    borderRadius: 4,
                  }}
                  className='overflow-hidden p-0 m-0'
                >
                  {contractDetails.data?.milestones?.length > 0 && (
                    <Col>
                      <div className='overflow-visible'>
                        <TableH
                          title={CONTRACT_TYPES.MILESTONES}
                          leftSide={''}
                        />
                        <div className='table-with-dropdown'>
                          <TableComp>
                            <thead className='thead-light'>
                              <tr>
                                <StyledTh>Name</StyledTh>
                                <StyledTh>Amount</StyledTh>
                                <StyledTh>Status</StyledTh>
                                <StyledTh></StyledTh>
                              </tr>
                            </thead>
                            <tbody>
                              {contractDetails.data?.milestones?.map((e, i) => {
                                return (
                                  <MilestoneCard
                                    key={`milestone-${i}`}
                                    e={e}
                                    i={i}
                                    data={contractDetails.data}
                                    isAdmin
                                  />
                                )
                              })}
                            </tbody>
                          </TableComp>
                        </div>
                      </div>
                    </Col>
                  )}
                </Card>
              </TabPane>
            )}
            <TabPane tabId='11' className='py-3'>
              <Invoices isAdmin contractId={contractDetails.data?.id} />
            </TabPane>
            <TabPane tabId='12' className='py-3'>
              <Settings isAdmin onUpdate={update} />
            </TabPane>
          </TabContent>
        </Container>
      )}
    </div>
  )
}

export default ContractDetailAdmin
