import cx from 'classnames'
import React from 'react'
import styled from 'styled-components'

export default function OptionRadioButtons({
  options,
  name,
  onChange,
  value: globalValue,
  isDisabled,
  error,
  size = 'md',
}) {
  return (
    <div
      className='d-flex flex-column flex-md-row'
      style={{
        '--gap': size === 'sm' ? 'var(--size-12)' : 'var(--size-16)',
        gap: 'var(--gap)',
      }}
    >
      {options?.map(({ value, label, description, disabled, icon }) => {
        const isOptionDisabled = isDisabled || disabled
        const isOptionChecked = globalValue === value

        return (
          <WrapperLabel
            key={value}
            className='flex-grow-1'
            style={{ flexBasis: 'calc(50% - calc(var(--gap) / 2))' }}
          >
            <input
              name={name}
              value={value}
              type='radio'
              className='position-absolute opacity-0 w-0 h-0 appearance-none'
              onChange={() => {
                if (!isOptionDisabled) {
                  onChange(value)
                }
              }}
              disabled={isOptionDisabled}
              checked={isOptionChecked}
              error={error}
            />
            <OptionContent
              className={cx('d-flex align-items-center p-3 border rounded')}
              $size={size}
            >
              {!icon ? null : (
                <span className='align-items-center d-flex'>{icon}</span>
              )}
              <LabelDescription
                size={size}
                label={label}
                description={description}
                isOptionChecked={isOptionChecked}
              />
            </OptionContent>
          </WrapperLabel>
        )
      })}
    </div>
  )
}

function LabelDescription({ size, label, description, isOptionChecked }) {
  return (
    <div
      className={cx('d-flex flex-column', {
        'gap-6': size === 'sm',
        'gap-10': size === 'md',
      })}
    >
      <div
        style={{ lineHeight: 1 }}
        className={cx({
          'font-size-14': size === 'sm',
          'font-size-16': size === 'md',
          'text-gray-h': !isOptionChecked,
        })}
      >
        {label}
      </div>
      {!description ? null : (
        <div
          style={{
            lineHeight: 1,
            color: isOptionChecked ? null : 'var(--gray-600)',
          }}
          className={cx({
            'font-size-12': size === 'sm',
            'font-size-14': size === 'md',
          })}
        >
          {description}
        </div>
      )}
    </div>
  )
}

const OptionContent = styled.div`
  gap: ${({ $size }) => ($size === 'sm' ? 'var(--size-10)' : 'var(--size-16)')};
  transition: box-shadow 0.1s ease;
  border-color: var(--gray-b) !important;

  > i.bx {
    transition: transform 160ms ease-in-out;
  }
`

const WrapperLabel = styled.label`
  cursor: pointer;
  margin-bottom: 0;

  :focus-within ${OptionContent} {
    box-shadow: 0px 4px 9px 0px var(--slate-200);
  }

  > input[type='radio']:disabled + ${OptionContent} {
    color: var(--gray-600);

    :hover {
      cursor: not-allowed;
    }
  }

  > input[type='radio']:not(:disabled) + ${OptionContent} {
    color: var(--gray-h);

    :hover {
      background-color: var(--gray-100);
    }
  }

  > input[type='radio']:checked + ${OptionContent} {
    color: var(--primary);
    border-color: currentColor !important;

    > i.bx {
      transform: rotate(-5deg);
    }
  }
`
