import React, { useEffect, useState } from 'react'
import { Col, Input, Row } from 'reactstrap'
import { useForm } from 'react-hook-form'
import ControlledSelect from '../../../components/ControlledSelect'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { submitAccountMapping } from '../../../store/xero/actions'
import PropTypes from 'prop-types'
import { useResize } from '../../../helpers/hooks'
import { qbSubmitAccountMapping } from '../../../store/qb/actions'
import { freshSubmitAccountMapping } from '../../../store/fresh/actions'
import { submitZohoAccountMapping } from '../../../store/zoho/actions'
import { submitNetSuiteAccountMapping } from '../../../store/ns/actions'

const XeroAccountMapping = ({
  onSubmitted = () => {},
  context,
  contractor,
  isUpdate,
  platform = 'Xero',
}) => {
  const [categories, setCategories] = useState([])
  const isMobile = useResize()

  const data = useSelector((state) => {
    switch (platform) {
      case 'Xero':
        return state.xero?.preData
      case 'Quickbooks':
        return state.qb?.preData
      case 'Freshbooks':
        return state.fresh?.preData
      case 'Zoho':
        return state.zoho?.preData
      case 'NetSuite':
        return state.ns?.preData
    }
  })

  const defaultMapping = useSelector((state) => {
    switch (platform) {
      case 'Xero':
        return state.xero?.accountMapping
      case 'Quickbooks':
        return state.qb?.accountMapping
      case 'Freshbooks':
        return state.fresh?.accountMapping
      case 'Zoho':
        return state.zoho?.accountMapping
      case 'NetSuite':
        return state.ns?.accountMapping
    }
  })
  useEffect(() => {
    setCategories(data?.categories || [])
  }, [data])

  const dispatch = useDispatch()

  let defaultValues = {}
  if (
    contractor?.categories?.length > 0 &&
    Array.isArray(contractor?.categories)
  ) {
    contractor.categories.forEach((e) => {
      switch (platform) {
        case 'Xero':
          defaultValues[e.category_id] = e.xero_id
          break
        case 'Quickbooks':
          defaultValues[e.category_id] = e.qb_id
          break
        case 'Freshbooks':
          defaultValues[e.category_id] = e.fresh_id
          break
        case 'Zoho':
          defaultValues[e.category_id] = e.zoho_id
          break
        case 'NetSuite':
          defaultValues[e.category_id] = e.ns_id
          break
      }
    })
  } else if (
    !!contractor?.categories &&
    !Array.isArray(contractor?.categories)
  ) {
    defaultValues = contractor?.categories
  } else {
    defaultValues = defaultMapping
  }

  const getSchema = () => {
    const schema = {}
    categories.forEach((c, i) => {
      schema[`${c.category_id}`] = yup.string().required()
    })
    return yup.object().shape(schema)
  }
  const {
    control,
    handleSubmit,

    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(getSchema()),
    defaultValues,
  })

  const onSubmit = (values) => {
    onSubmitted(values)
    if (!contractor && !isUpdate) {
      switch (platform) {
        case 'Xero':
          dispatch(submitAccountMapping(values))
          break
        case 'Freshbooks':
          dispatch(freshSubmitAccountMapping(values))
          break
        case 'Quickbooks':
          dispatch(qbSubmitAccountMapping(values))
          break
        case 'Zoho':
          dispatch(submitZohoAccountMapping(values))
          break
        case 'NetSuite':
          dispatch(submitNetSuiteAccountMapping(values))
          break
      }
    }
  }

  const accounts =
    platform === 'Freshbooks'
      ? data?.accounts?.reduce(
          (acc, current) => [
            ...acc,
            { value: current.account_id.toString(), label: `${current.name}` },
            ...current.list.map((a) => ({
              value: a.account_id.toString(),
              label: ` ${a.name}`,
              isChild: true,
            })),
          ],
          [],
        )
      : data?.accounts?.map((a) => ({
          value: a.account_id,
          label: platform === 'Xero' ? `${a.account_id} - ${a.name}` : a.name,
        }))

  return (
    <form
      id={`xeroAccountMappingFrom-${context}`}
      autoComplete='off'
      className='form-horizontal m-0'
      onSubmit={(event) => {
        if (event.target.id === `xeroAccountMappingFrom-${context}`) {
          handleSubmit(onSubmit)(event)
        }
      }}
    >
      <Row className='align-items-center w-100 justify-content-between p-0 m-0 mt-3 d-none d-md-flex'>
        <Col className='mb-1 pr-3'>
          <Row>
            <h6>Categories</h6>
          </Row>
        </Col>

        <Col className='mb-1 pl-3'>
          <Row className='pl-3'>
            <div>
              <i className='bx bx-right-arrow-alt font-size-24 text-white align-center' />
            </div>
            <h6>Accounts</h6>
          </Row>
        </Col>
      </Row>
      {categories.map((e, i) => {
        return (
          <Row
            key={`${e.category_id}-${i}`}
            className='align-items-center w-100 mb-4 mb-md-3 mb-md-0 flex-md-row flex-column justify-content-between p-0 m-0'
          >
            <Col className='p-0 m-0 mb-1 pr-md-3'>
              <Input value={e.name} name={e.category_id} disabled />
            </Col>
            <div>
              {isMobile ? (
                <i className='bx bx-down-arrow-alt font-size-24 text-primary align-center' />
              ) : (
                <i className='bx bx-right-arrow-alt font-size-24 text-primary align-center' />
              )}
            </div>
            <Col md={6} className='p-0 m-0 mb-1 pl-md-3'>
              <ControlledSelect
                name={`${e.category_id}`}
                error={errors[`${e.category_id}`]}
                control={control}
                options={accounts}
              />
            </Col>
          </Row>
        )
      })}
      <button
        form={`xeroAccountMappingFrom-${context}`}
        type={'submit'}
        id={`submit-xero-account-mapping-${context}`}
        className='d-none'
      />
    </form>
  )
}

XeroAccountMapping.propTypes = {
  context: PropTypes.string.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool,
}

export default XeroAccountMapping
