import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Spinner, TabContent, TabPane } from 'reactstrap'
import { useFetch } from '../../../helpers/hooks'
import {
  connectZoho,
  getZohoConnection,
  setupZoho,
} from '../../../services/api'
import toastr from 'toastr'
import ModalHeader from '../../../components/ModalHeader'
import Steps from '../../../components/Steps'
import StepContainer from '../../../components/Steps/StepContainer'
import AccountMappingStep from './XeroIntegration/accountMappingStep'
import VendorsSettingStep from './XeroIntegration/vendorsSettingsStep'
import FinalStep from './XeroIntegration/finalStep'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import {
  updateZohoPreData,
  updateZohoRequest,
  initZoho,
  updateZohoOrganizations,
} from '../../../store/zoho/actions'
import OrganizationSelect from './XeroIntegration/organizationSelect'

const ZohoBooksLoading = () => {
  const [activePage, setActivePage] = useState(0)

  const location = useLocation()
  const history = useHistory()

  const dispatch = useDispatch()
  const code = new URLSearchParams(location.search).get('code')

  const organization = useSelector((state) => state.zoho?.organization)
  const organizations = useSelector((state) => state.zoho?.organizations)

  const zohobooksConnection = useFetch({
    action: getZohoConnection,
    autoFetch: !!code,
    successAction: updateZohoPreData,
  })

  const getToken = useFetch({
    action: connectZoho,
    body: {
      code,
    },
    autoFetch: !!code,
    onComplete: (data) => {
      if (data?.organizations?.length) {
        dispatch(updateZohoOrganizations(data?.organizations))
      } else {
        if (data?.synched) {
          history.push('/settings/integrations')
        } else {
          zohobooksConnection.startFetch()
        }
      }
    },
    onError: (err) => {
      toastr.error(err)
      history.push('/settings/integrations')
    },
  })

  const request = useSelector((state) => state.zoho.request)

  const update = useFetch(
    {
      action: setupZoho,
      onError: toastr.error,
      onComplete: () => {
        dispatch(updateZohoRequest(null))
        setActivePage(activePage + 1)
      },
    },
    [request],
  )

  useEffect(() => {
    if (request) update.startFetch(request)
  }, [request])

  useEffect(() => {
    return () => {
      dispatch(initZoho())
    }
  }, [])

  useEffect(() => {
    if (organization) {
      getToken.startFetch({
        organization_id: organization,
        token: getToken.data?.token,
      })
    }
  }, [organization])

  const hasMultipleOrganization = organizations?.length > 1 && !organization
  const steps = ['Account Mapping', 'Vendor Mapping', 'Completed']
  return (
    <div className='page-content min-vh-100 p-0 m-0'>
      {zohobooksConnection.isLoading || getToken.isLoading ? (
        <Container style={{ minHeight: '100vh' }}>
          <Col style={{ minHeight: '100vh' }}>
            <Row
              style={{ minHeight: '100vh' }}
              className='justify-content-center align-items-center'
            >
              <Spinner type='grow' color='primary' />
            </Row>
          </Col>
        </Container>
      ) : (
        <Container fluid>
          <ModalHeader
            action={() => {
              history.push('/settings/integrations')
            }}
          >
            {!hasMultipleOrganization && (
              <Steps activeTab={activePage} data={steps} />
            )}
          </ModalHeader>
          <Row className='justify-content-center'>
            <Col md={6}>
              <TabContent
                activeTab={activePage}
                className='twitter-bs-wizard-tab-content'
              >
                {hasMultipleOrganization ? (
                  <TabPane tabId={0}>
                    <StepContainer
                      title='Select Organization'
                      index={0}
                      total={steps.length}
                      disableNext={!organization}
                      onNext={() => setActivePage(activePage + 1)}
                      onBack={() => setActivePage(activePage - 1)}
                    >
                      <OrganizationSelect platform='Zoho' />
                    </StepContainer>
                  </TabPane>
                ) : (
                  <>
                    <TabPane tabId={0}>
                      <StepContainer
                        title='Account Mapping'
                        index={1}
                        total={steps.length}
                        onNext={() => {
                          document
                            .getElementById('submit-xero-account-mapping-step')
                            .click()
                        }}
                        loading={false}
                        isFlat
                        onBack={() => setActivePage(activePage - 1)}
                      >
                        <AccountMappingStep
                          platform={'Zoho'}
                          onSubmitted={() => setActivePage(activePage + 1)}
                          loading={getToken.isLoading}
                        />
                      </StepContainer>
                    </TabPane>
                    <TabPane tabId={1}>
                      <VendorsSettingStep
                        platform={'Zoho'}
                        onBack={() => setActivePage(activePage - 1)}
                        loading={getToken.isLoading}
                        onSubmitted={(values) => {
                          dispatch(updateZohoRequest(values))
                        }}
                      />
                    </TabPane>
                    <TabPane tabId={2}>
                      <FinalStep platform={'Zoho'} />
                    </TabPane>
                  </>
                )}
              </TabContent>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  )
}

export default ZohoBooksLoading
