import React from 'react'
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'

import ControlledInput from '../../../../components/ControlledInput'
import InputFeedback from '../../../../components/ui/input-feedback'
import LabelContent from './label-content'

export default function ProbationPeriodField({
  control,
  errors,
  jurisdiction,
}) {
  return (
    <>
      <label>
        <LabelContent
          required
          tooltipId='probation-period-tooltip'
          tooltipText={`Per ${jurisdiction?.name}, the maximum probation period is 6 months from the Start Date.`}
        >
          Probation period:
        </LabelContent>
      </label>

      <InputGroup className='w-100 flex-nowrap'>
        <ControlledInput
          className='rounded-right-0'
          control={control}
          name='probation_period'
          type='number'
          min={0}
          showError={false}
        />

        <InputGroupAddon addonType='append'>
          <InputGroupText>Calendar Days</InputGroupText>
        </InputGroupAddon>
      </InputGroup>

      {!errors?.probation_period?.message ? null : (
        <InputFeedback className='mt-1'>
          {errors.probation_period.message}
        </InputFeedback>
      )}
    </>
  )
}
