import React, { useState } from 'react'
import { Spinner } from 'reactstrap'
import { useSelector } from 'react-redux'

import { ReactComponent as RequestIllustration } from '../../assets/images/perks-request.svg'
import { ReactComponent as PendingIllustration } from '../../assets/images/perks-pending.svg'
import { useFetch } from '../../helpers/hooks'
import { perksPreConditions, requestPerks } from '../../services/api'

import PerksStatusPage from './components/perks-status-page'
import PreConditionsModal from './components/pre-conditions-modal'
import PerksList from './screens/perks-list'
import { Redirect } from 'react-router'
import { userTypes } from '../../helpers/enum'

export default function RemotepassPerks() {
  const user = useSelector((state) => state.Account?.user)

  if (user?.type !== userTypes.COMPANY) {
    return <Redirect to='/activity' />
  }

  return (
    <div className='page-content'>
      <PerksPageContent />
    </div>
  )
}

export const loadingElement = (
  <div
    className='d-flex justify-content-center align-items-center'
    style={{ minHeight: '60vh' }}
  >
    <Spinner type='grow' color='primary' />
  </div>
)

function PerksPageContent() {
  const {
    isLoading: isConditionsLoading,
    data: perksPreConditionsData,
    startFetch: fetchPreConditions,
  } = useFetch({
    action: perksPreConditions,
    autoFetch: true,
  })

  if (isConditionsLoading) {
    return loadingElement
  }

  if (perksPreConditionsData?.perk_request_status === 'pending') {
    return (
      <PerksStatusPage
        illustration={PendingIllustration}
        heading='You request is pending approval'
        body='You will be notified by email once your request is approved.'
      />
    )
  }

  if (
    perksPreConditionsData?.is_qualified === true &&
    perksPreConditionsData?.perk_request_status === 'approved'
  ) {
    return <PerksList />
  }

  return <RequestPerks refetchData={fetchPreConditions} />
}

function RequestPerks({ refetchData }) {
  const [showPreConditionsModal, setShowPreConditionsModal] = useState(null)

  const { startFetch: rerequestPerksAccess } = useFetch({
    action: requestPerks,
    onComplete: () => {
      refetchData()
    },
  })

  const { isLoading: isConditionsLoading, startFetch: getPreConditions } =
    useFetch({
      action: perksPreConditions,
      onComplete: (data) => {
        if (data.is_qualified === true) {
          rerequestPerksAccess()
        } else {
          setShowPreConditionsModal(data)
        }
      },
    })

  return (
    <>
      <PerksStatusPage
        illustration={RequestIllustration}
        heading='Perks and Discounts'
        body='Access 220+ perks and 150k+ discounts on the most popular software and tools that every company needs.'
        actionText='Activate Perks'
        actionBtnProps={{
          onClick: getPreConditions,
          type: 'button',
          loading: isConditionsLoading,
          disabled: isConditionsLoading,
        }}
      />

      <PreConditionsModal
        isOpen={!!showPreConditionsModal}
        toggle={() => setShowPreConditionsModal(null)}
        conditions={showPreConditionsModal?.pre_conditions}
      />
    </>
  )
}
