import React from 'react'
import { Col, Container, Row, Spinner, Table, Card, Button } from 'reactstrap'
import { useHistory } from 'react-router'

import { useFetch } from '../../../../helpers/hooks'
import {
  deleteTemplateAdmin,
  getAdminTemplateList,
  updateTemplateAdminStatus,
} from '../../../../services/api'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import ADMIN_PERMISSIONS from '../../../../config/admin-permissions'
import { StyledH5 } from '../../../../components/Typo'
import useHasPermission from '../../../../helpers/hooks/admin/has-permission'

const Templates = () => {
  const history = useHistory()
  const templates = useFetch({
    action: getAdminTemplateList,
    withAdminAccess: true,
    autoFetch: true,
  })
  const createNewTemplate = () => {
    history.push({
      pathname: '/admin/template-editor',
      state: { template_id: 0 },
    })
  }

  const hasEditPermission = useHasPermission(ADMIN_PERMISSIONS.MANAGE_TEMPLATES)

  return (
    <div className='page-content'>
      <Container fluid className={'p-0 m-0'}>
        <Row className='justify-content-end mb-3 mb-md-4 p-0 m-0'>
          <Col xs={12} md={12} className={'p-0 m-0'}>
            <StyledH5 min='22px' max='32px'>
              Templates
            </StyledH5>
          </Col>
        </Row>

        <Card>
          {templates.isLoading ? (
            <div
              className='d-flex justify-content-center align-items-center'
              style={{ minHeight: 145 }}
            >
              <Spinner type='grow' color='primary' />
            </div>
          ) : (
            <>
              {!hasEditPermission ? null : (
                <Col>
                  <Row className='justify-content-end p-3'>
                    <button
                      className='btn btn-primary'
                      onClick={createNewTemplate}
                    >
                      + New Template
                    </button>
                  </Row>
                </Col>
              )}
              <div className='table-responsive'>
                <Table className='table table-centered table-nowrap text-muted'>
                  <thead className='thead-light'>
                    <tr className='text-muted'>
                      <th className='border-top-0 text-muted'>Country</th>
                      <th className='border-top-0 text-muted'>Status</th>
                      <th className='border-top-0 text-muted text-md-right'>
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {templates.data?.map((template, i) => (
                      <TemplateLine
                        template={template}
                        key={`temp${i}`}
                        onUpdate={() => templates.startFetch(null, true)}
                        hasEditPermission={hasEditPermission}
                      />
                    ))}
                    <div className='p-5' />
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </Card>
      </Container>
    </div>
  )
}

const TemplateLine = ({ template, onUpdate, hasEditPermission }) => {
  const history = useHistory()
  const changeStatus = useFetch({
    action: updateTemplateAdminStatus,
    withAdminAccess: true,
    onComplete: onUpdate,
  })
  const deleteTemplate = useFetch({
    action: deleteTemplateAdmin,
    withAdminAccess: true,
    onComplete: onUpdate,
  })
  const editTemplate = (id) => {
    history.push({
      pathname: '/admin/template-editor',
      state: { template_id: id, isAdmin: true },
    })
  }
  const handleStatusChange = (id, v) => {
    changeStatus.startFetch({ template_id: id, active: v?.value })
  }
  const statusOptions = [
    { value: 0, label: 'Draft' },
    { value: 1, label: 'Active' },
  ]
  return (
    <tr>
      <td>{template.name}</td>
      <td>
        <div style={{ minWidth: 120 }}>
          {changeStatus.isLoading ? (
            <i className='bx bx-loader bx-spin font-size-16 align-middle mx-2' />
          ) : !hasEditPermission ? (
            <span>{statusOptions[template.active]?.label}</span>
          ) : (
            <CustomSelect
              options={statusOptions}
              defaultValue={statusOptions[template.active]}
              onChange={(v) => handleStatusChange(template.id, v)}
            />
          )}
        </div>
      </td>
      <td>
        {!hasEditPermission ? null : (
          <div className='d-flex' style={{ gap: '0.5rem' }}>
            <Button color='primary' onClick={() => editTemplate(template?.id)}>
              Edit
            </Button>
            <Button
              color='danger'
              outline
              onClick={() =>
                deleteTemplate.startFetch({ template_id: template?.id })
              }
            >
              {deleteTemplate.isLoading && (
                <i className='bx bx-loader bx-spin font-size-16 align-middle mx-2'></i>
              )}
              Remove
            </Button>
          </div>
        )}
      </td>
    </tr>
  )
}

export default Templates
