import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import rpLogoWhite from '../../assets/images/identity/logo_main.png'

function SimpleHeader() {
  return (
    <div
      className='d-flex align-items-center justify-content-center bg-white py-4 text-center rp-border'
      style={{ height: 70 }}
    >
      <img src={rpLogoWhite} style={{ height: '24px' }} />
    </div>
  )
}

export default function TwoFaReminderPage() {
  const userType = useSelector((state) => state.Account?.user?.type)
  const settingsLink =
    userType === 'client' ? '/profile-settings#security' : '/settings#security'

  return (
    <div style={{ minHeight: '100vh' }}>
      <SimpleHeader />

      <div
        className='d-flex flex-column justify-content-center mx-auto px-4 py-4 px-md-0'
        style={{ maxWidth: 576, minHeight: 'calc(100vh - 74px)' }}
      >
        <h1 className='rp-font-gilroyB'>Enable Two-Factor Authentication.</h1>
        <h3 className='rp-font-gilroyB'>Secure your RemotePass account.</h3>
        <p
          className='font-size-16 mt-2 rp-font-gilroyB text-muted'
          style={{ maxWidth: '48ch' }}
        >
          Recommended! Add an extra layer of security for your account by
          enabling{' '}
          <b style={{ fontWeight: 900, color: '#505568' }}>
            Two-factor Authentication
          </b>{' '}
          to make sure that you, and only you, can access it.
        </p>

        <div>
          <Link
            to={settingsLink}
            className='btn btn-primary font-size-14 rp-font-gilroyB'
          >
            Enable 2FA Now
          </Link>
        </div>

        <Link to='/activity' className='mt-3'>
          Skip
        </Link>
      </div>
    </div>
  )
}
