import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  CardBody,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap'
import classnames from 'classnames'
import CurrencyInput from 'react-currency-input-field'
import ReactQuill from 'react-quill'
import { useSelector } from 'react-redux'

import ControlledCurrencyInput from '../../../components/ControlledCurrencyInput'
import ControlledInputLabel from '../../../components/ControlledInputLabel'
import ControlledSelect from '../../../components/ControlledSelect'
import { customOption, customSingleValue } from './FullTimeForm'
import { useFetch } from '../../../helpers/hooks'
import { convertAmount, getLocalCurrencies } from '../../../services/api'
import Button from '../../../components/ui/button'

const QuotationForm = ({
  initialData,
  onSubmit,
  loading,
  currency,
  amount,
  salaryCurrency,
  forGenerator,
  readOnly = false,
}) => {
  const defaultArray = [
    {
      id: 1,
      first_month_payroll: {
        name: 'Gross Salary',
        value: Number(amount) / 12,
      },
      monthly_payroll: {
        name: 'Gross Salary',
      },
    },
    {
      id: 2,
      first_month_payroll: {
        name: 'Statutory costs',
      },
      monthly_payroll: {
        name: 'Statutory costs',
      },
    },
    {
      id: 3,
      first_month_payroll: {
        name: 'Management fees',
      },
      monthly_payroll: {
        name: 'Management fees',
      },
    },
    {
      id: 4,
      first_month_payroll: {
        name: 'Health Insurance',
      },
      monthly_payroll: {
        name: 'Health Insurance',
      },
    },
  ]

  const schema = yup.object().shape({
    setup_fee: yup.number().required(),
    deposit_amount: yup.number().required(),
    currency_code: yup.string().required(),
    yearly_gross: yup.number().required(),
    markup: yup
      .number()
      .required()
      .min(0, 'Min value 0.')
      .max(100, 'Max value 100.'),
    country_id: forGenerator ? yup.number().required() : yup.number(),
  })

  const { handleSubmit, control, watch, setValue, formState } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      payroll: defaultArray,
      deposit_amount: initialData?.deposit_amount,
      setup_fee: initialData?.setup_fee,
      currency_code: initialData?.currency_code || salaryCurrency,
      yearly_gross: initialData?.yearly_gross || amount,
      markup: initialData?.markup || 0,
    },
  })

  const { errors } = formState

  const [array, setArray] = useState(
    initialData
      ? initialData?.first_month_payroll?.map((e, i) => ({
          first_month_payroll: e,
          monthly_payroll: initialData?.monthly_payroll[i],
        }))
      : defaultArray,
  )
  const [scope, setScope] = useState(initialData?.additional_text || '')
  const countries = useSelector((state) => state?.Layout?.staticData?.countries)

  const onAppend = () => {
    const newArray = [...array]
    const newValue = {
      id: array?.length + 1,
      first_month_payroll: {
        name: 'Custom Field',
      },
      monthly_payroll: {
        name: 'Custom Field',
      },
    }
    newArray.splice(2, 0, newValue)
    setArray(newArray)
    setTimeout(() => {
      setValue('payroll', newArray)
    }, 500)
  }

  const onChangeValue = (i, name, value) => {
    const newArray = array.map((f, index) => {
      const newF = { ...f }
      if (index === i) {
        newF[name] = { ...newF[name], value }
        if (name === 'first_month_payroll') {
          newF.monthly_payroll = { ...newF.monthly_payroll, value }
        }
      }
      return newF
    })
    setArray(newArray)
    setTimeout(() => {
      setValue('payroll', newArray)
    }, 500)
  }

  const onChangeName = (i, name, value) => {
    const newArray = array.map((f, index) => {
      const newF = { ...f }
      if (index === i) {
        newF[name] = { ...newF[name], name: value }
        if (name === 'first_month_payroll') {
          newF.monthly_payroll = { ...newF.monthly_payroll, name: value }
        }
      }
      return newF
    })
    setArray(newArray)
    setTimeout(() => {
      setValue('payroll', newArray)
    }, 500)
  }

  const validateArray = () => {
    return !array.find(
      (e) =>
        e.first_month_payroll?.value === undefined ||
        e.monthly_payroll?.value === undefined,
    )
  }

  const onValidate = (v) => {
    const isArrayValid = validateArray()
    const currencyCode = currencies.data?.find(
      (e) => e.code === v?.currency_code,
    )?.code
    if (isArrayValid) {
      onSubmit({
        ...v,
        currency_code: currencyCode,
        first_month_payroll: array.map((e) => e.first_month_payroll),
        monthly_payroll: array.map((e) => e.monthly_payroll),
        additional_text: scope,
      })
    }
  }

  const currencies = useFetch({
    action: getLocalCurrencies,
    autoFetch: true,
    initResult: [],
  })

  const exchange = useFetch({
    action: convertAmount,
    withAdminAccess: true,
    onComplete: (data) => {
      if (typeof data === 'number') {
        setValue('yearly_gross', data)
      }
    },
  })

  useEffect(() => {
    exchange.startFetch({
      amount,
      source: currencies.data?.find((e) => e.id === salaryCurrency)?.code,
      target: currencies.data?.find((e) => e.id === watch('currency_code'))
        ?.code,
    })
  }, [watch('currency_code')])

  const localCurrency = currencies?.data?.find(
    (e) => e.id === watch('currency_code'),
  )?.code

  return (
    <div>
      <CardBody className='m-0 p-0'>
        <Form
          autoComplete='off'
          className='form-horizontal m-0 pt-3 pt-md-4'
          onSubmit={handleSubmit(onValidate, (err) => {
            console.log(err)
          })}
        >
          <fieldset disabled={readOnly}>
            <Row className='p-0 m-0 mb-4' style={{ gap: '1rem 0' }}>
              <Col md={6} sm={12}>
                <FormGroup className='mb-0'>
                  <Label className='col-form-label pt-0'>
                    Gross salary
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <InputGroup style={{ zIndex: 96 }}>
                    <ControlledCurrencyInput
                      control={control}
                      name='yearly_gross'
                      error={errors.yearly_gross}
                      isDisabled={readOnly}
                    />
                    <InputGroupAddon
                      style={{ width: '125px' }}
                      addonType='append'
                    >
                      <InputGroupText
                        className={classnames({
                          'p-0': true,
                          'border-danger': !!errors.currency_code,
                        })}
                        style={{ width: '100%', border: 0 }}
                      >
                        <ControlledSelect
                          error={errors.currency_code}
                          control={control}
                          name='currency_code'
                          options={currencies?.data?.map((c) => ({
                            code: c.code,
                            label: `${c.code} ${c.name} ${c.symbol}`,
                            name: c.name,
                            icon: (
                              <div
                                className={`currency-flag mr-2 currency-flag-${c.code.toLowerCase()}`}
                              />
                            ),
                            value: c.code,
                          }))}
                          classNamePrefix='RS-Addon'
                          customComponents={{
                            Option: customOption,
                            Value: customSingleValue,
                            SingleValue: customSingleValue,
                          }}
                          isDisabled={readOnly}
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              {!!forGenerator && (
                <Col md={6} sm={12}>
                  <FormGroup className='mb-0'>
                    <Label className='col-form-label pt-0'>
                      Country
                      <span className='text-danger font-size-16 mx-1'>*</span>
                    </Label>
                    <ControlledSelect
                      options={
                        countries
                          ? countries.map((c) => ({
                              ...c,
                              label: c.name,
                              value: c.id,
                              flag: `/${c.svg}`,
                            }))
                          : []
                      }
                      control={control}
                      name='country_id'
                      error={errors.country_id}
                      isDisabled={readOnly}
                    />
                  </FormGroup>
                </Col>
              )}
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label className='col-form-label pt-0'>
                    Markup
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <ControlledCurrencyInput
                    control={control}
                    name={'markup'}
                    error={errors.markup}
                    isDisabled={readOnly}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className='p-0 m-0'>
              <Col md={6} sm={12}>
                <Row>
                  <Col md={12} className='mb-3'>
                    <Row className='m-0 align-items-center d-flex justify-content-between  '>
                      <Label className='font-size-24'>Month 1 Payroll</Label>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col md={6} sm={12}>
                <Row>
                  <Col md={12} className='mb-3'>
                    <Row className='m-0 align-items-center d-flex justify-content-between  '>
                      <Label className='font-size-24'>
                        General Monthly Payroll
                      </Label>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            {array.map((f, i) => (
              <>
                <Row id={`ww-${f.id}`} key={`ww-${f.id}`} className='p-0 m-0'>
                  <Col md={6}>
                    <FormGroup>
                      <ControlledInputLabel
                        required
                        control={control}
                        value={array[i]?.first_month_payroll?.name}
                        onChange={(v) =>
                          onChangeName(i, 'first_month_payroll', v)
                        }
                        name={`payroll.${i}.first_month_payroll.name`}
                        error={
                          errors.payroll?.length > 0 &&
                          errors.payroll[i]?.first_month_payroll?.name
                        }
                      />
                      <InputGroup style={{ zIndex: 6 }}>
                        <CurrencyInput
                          className={classnames({
                            'form-control': true,
                            'border-danger':
                              !array[i]?.first_month_payroll?.value &&
                              formState.submitCount > 0,
                          })}
                          value={array[i]?.first_month_payroll?.value}
                          onValueChange={(v) =>
                            onChangeValue(i, 'first_month_payroll', v)
                          }
                          disabled={readOnly}
                          // currency input props
                          allowDecimals={true}
                          decimalsLimit={2}
                          decimalSeparator='.'
                          groupSeparator=','
                        />
                        <InputGroupAddon addonType='append'>
                          {array[i]?.first_month_payroll?.name ===
                          'Management fees'
                            ? 'USD'
                            : localCurrency}
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <ControlledInputLabel
                        required
                        control={control}
                        value={array[i]?.monthly_payroll?.name}
                        onChange={(v) => onChangeName(i, 'monthly_payroll', v)}
                        name={`payroll.${i}.monthly_payroll.name`}
                        error={
                          errors.payroll?.length > 0 &&
                          errors.payroll[i]?.monthly_payroll?.name
                        }
                      />
                      <InputGroup style={{ zIndex: 6 }}>
                        <CurrencyInput
                          className={classnames({
                            'form-control': true,
                            'border-danger':
                              !array[i]?.monthly_payroll?.value &&
                              formState.submitCount > 0,
                          })}
                          value={array[i]?.monthly_payroll?.value}
                          onValueChange={(v) =>
                            onChangeValue(i, 'monthly_payroll', v)
                          }
                          disabled={readOnly}
                          // currency input props
                          allowDecimals={true}
                          decimalsLimit={2}
                          decimalSeparator='.'
                          groupSeparator=','
                        />
                        <InputGroupAddon addonType='append'>
                          {array[i]?.first_month_payroll?.name ===
                          'Management fees'
                            ? 'USD'
                            : localCurrency}
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                {i === 1 && !readOnly ? (
                  <Col>
                    <Row className='m-0 p-0 mb-2 align-items-center d-flex justify-content-end'>
                      <a
                        className='text-primary font-size-14'
                        onClick={onAppend}
                      >
                        Add field
                      </a>
                    </Row>
                  </Col>
                ) : null}
                {i <= 1 || readOnly
                  ? null
                  : array[i]?.first_month_payroll?.name !==
                      'Management fees' && (
                      <Col>
                        <Row className='m-0 p-0 mb-2 align-items-center d-flex justify-content-end'>
                          <a
                            className='text-danger font-size-14'
                            onClick={() => {
                              const newArray = [...array]
                              newArray.splice(i, 1)
                              setArray(newArray)
                              setTimeout(() => {
                                setValue('payroll', newArray)
                              }, 500)
                            }}
                          >
                            Remove
                          </a>
                        </Row>
                      </Col>
                    )}
              </>
            ))}
            <Row className='p-0 m-0 border-top py-4'>
              <Col>
                <span className='font-weight-bold font-size-14'>Total:</span>{' '}
                <span className='font-size-14 text-muted'>
                  {array.reduce(
                    (prev, curr) =>
                      Number(prev || 0) +
                      Number(curr.first_month_payroll?.value || 0),
                    0,
                  )}
                </span>
              </Col>
              <Col>
                <span className='font-weight-bold font-size-14'>Total:</span>{' '}
                <span className='font-size-14 text-muted'>
                  {array.reduce(
                    (prev, curr) =>
                      Number(prev || 0) +
                      Number(curr.monthly_payroll?.value || 0),
                    0,
                  )}
                </span>
              </Col>
            </Row>
            <Row className='p-0 m-0'>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label className='col-form-label pt-0'>
                    Deposit
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <InputGroup style={{ zIndex: 6 }}>
                    <ControlledCurrencyInput
                      control={control}
                      name={'deposit_amount'}
                      error={errors.deposit_amount}
                      isDisabled={readOnly}
                    />
                    <InputGroupAddon addonType='append'>
                      {currency}
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label className='col-form-label pt-0'>
                    Setup fee
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <InputGroup style={{ zIndex: 6 }}>
                    <ControlledCurrencyInput
                      control={control}
                      name={'setup_fee'}
                      error={errors.setup_fee}
                      isDisabled={readOnly}
                    />
                    <InputGroupAddon addonType='append'>
                      {currency}
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col sm={12} md={12}>
                <FormGroup id='scope-contract-info'>
                  <Label>
                    Additional Text
                    <span className='text-danger font-size-16 mx-1' />
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <ReactQuill
                    formats={[
                      'font',
                      'size',
                      'bold',
                      'italic',
                      'underline',
                      'strike',
                      'blockquote',
                      'indent',
                      'image',
                      'video',
                    ]}
                    readOnly={readOnly}
                    id='scopeEdit'
                    modules={{
                      toolbar: null,
                    }}
                    value={scope}
                    onChange={setScope}
                    placeholder='Additional text'
                    style={{ whiteSpace: 'pre-line' }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className='justify-content-end p-0 m-0 p-4'>
              {readOnly ? null : (
                <Button
                  type='submit'
                  disabled={readOnly || loading}
                  loading={loading}
                >
                  {initialData ? 'Update' : 'Create Quote'}
                </Button>
              )}
            </Row>
          </fieldset>
        </Form>
      </CardBody>
    </div>
  )
}

QuotationForm.propTypes = {
  initialData: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  forGenerator: PropTypes.bool,
}

export default QuotationForm
