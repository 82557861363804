import { Bank, IdentificationBadge, Paperclip } from '@phosphor-icons/react'
import cx from 'classnames'
import React from 'react'
import { Controller } from 'react-hook-form'

import CardButtons from '../../../../components/ui/card-button/card-buttons'
import InputFeedback from '../../../../components/ui/input-feedback'
import {
  CONTRACT_CATEGORY,
  CONTRACT_DOC_TYPE,
  contractTypes,
  DE_PAYMENT_PROVIDER,
  EMPLOYEE_CONTRACT_TYPE,
} from '../../../../helpers/enum'
import {
  Fixed,
  Milestone,
  PayAsYouGo,
  RpIcon,
  RpTemplate,
  YourTemplate,
} from '../../../../helpers/SvgIcons'
import styles from './type-select.module.css'

export const contractTypeCards = [
  {
    label: 'Fixed',
    description: 'Pay a fixed rate monthly, bi-weekly or weekly',
    icon: (active) => <Fixed isActive={active} />,
    value: contractTypes.FIXED,
  },
  {
    label: 'Pay as you go',
    description: 'Pay per day or per hour based on timesheets',
    icon: (active) => <PayAsYouGo isActive={active} />,
    value: contractTypes.PAYG,
  },
  {
    label: 'Milestones',
    description: 'Pay as milestones are completed',
    icon: (active) => <Milestone isActive={active} />,
    value: contractTypes.MILESTONES,
  },
]

export const contractCategories = [
  {
    label: 'A Contractor',
    description:
      'Onboard, manage and pay an individual or a company on a fixed, pay as you go or milestone contract.',
    icon: (active) => <PayAsYouGo isActive={active} />,
    value: CONTRACT_CATEGORY.CONTRACTOR,
  },
  {
    label: 'An Employee',
    description:
      'Onboard, manage, offer benefits and pay a full-time or a part-time employee in 150+ countries',
    icon: (active) => <Fixed isActive={active} />,
    value: CONTRACT_CATEGORY.EMPLOYEE,
  },
]

export const defaultContractTemplatesTypes = [
  {
    label: 'Use RemotePass’s Template',
    description: 'Use RemotePass’s legally compliant contract template',
    icon: (active) => <RpTemplate isActive={active} />,
    value: CONTRACT_DOC_TYPE.RP_TEMPLATE,
  },
  {
    label: 'Upload My Contract',
    description: 'Click here to upload your signed contract *PDF only',
    icon: (active) => <YourTemplate isActive={active} />,
    value: CONTRACT_DOC_TYPE.CUSTOM,
  },
]

export const customContractTemplatesType = {
  label: 'Use your templates',
  description: 'Click here to select one of your existing templates',
  icon: (active) => <YourTemplate isActive={active} />,
  value: CONTRACT_DOC_TYPE.CUSTOM_TEMPLATE,
}

export const employeeContractOptions = ({ isEorEnabled, isDeEnabled }) => [
  {
    label: 'EOR',
    description:
      'RemotePass to hire an employee on your behalf in their country of residence and pay local taxes',
    icon: (active) => <RpIcon isActive={active} />,
    value: EMPLOYEE_CONTRACT_TYPE.EOR,
    id: 'eor',
    isDisabled: isEorEnabled
      ? null
      : {
          title: 'Unlock access to full time contracts',
          description:
            'Full-time contract is a premium service where RemotePass acts as the legal employer on your behalf',
          chatMessage:
            'Hi, I want to unlock access to full time contracts on my account',
        },
  },
  {
    label: 'Direct Employee',
    description:
      'Onboard an employee under your entity and manage them through RemotePass',
    icon: (active) => (
      <IdentificationBadge
        weight='duotone'
        width='100%'
        height='100%'
        color={active ? 'var(--primary)' : '#BDBDBD'}
      />
    ),
    value: EMPLOYEE_CONTRACT_TYPE.DIRECT_EMPLOYEE,
    id: 'direct-employee-option-id',
    isDisabled: isDeEnabled
      ? null
      : {
          title: 'Unlock access to direct employee contracts',
          chatMessage:
            'Hi, I want to unlock access to direct employee contracts on my account',
        },
    isNew: true,
  },
]

export const deExternalPaymentOptions = [
  {
    label: 'Use RemotePass as payroll provider',
    description: 'Manage & Pay employee on RemotePass',
    value: DE_PAYMENT_PROVIDER.RP_PROVIDER,
    icon: (active) => <RpIcon isActive={active} />,
  },
  {
    label: 'Use external payroll provider',
    description:
      'Manage employee on RemotePass, generate payroll report and process using your bank',
    value: DE_PAYMENT_PROVIDER.EXTERNAL_PROVIDER,
    icon: (active) => (
      <Bank
        weight='duotone'
        width='100%'
        height='100%'
        color={active ? 'var(--primary)' : '#BDBDBD'}
      />
    ),
  },
]

export const deContractOptions = [
  {
    label: 'Use RemotePass’s Template',
    description: 'Use RemotePass’s legally compliant contract template',
    icon: (active) => <RpIcon isActive={active} />,
    value: CONTRACT_DOC_TYPE.RP_TEMPLATE,
  },
  {
    label: 'Upload My Contract',
    description: 'Click here to upload your signed contract (*PDF only)',
    icon: (active) => (
      <Paperclip
        weight='duotone'
        width='100%'
        height='100%'
        color={active ? 'var(--primary)' : '#BDBDBD'}
      />
    ),
    value: CONTRACT_DOC_TYPE.CUSTOM,
  },
]

export default function ControlledTypeSelect({
  control,
  name,
  error,
  required,
  types = [],
  cols = 3,
  horizontal,
  transform,
}) {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field }) => {
        const { value, onChange } = field

        return (
          <>
            <CardButtons
              value={transform?.input ? transform.input(value) : value}
              onChange={(e) =>
                typeof transform?.output === 'function'
                  ? onChange(transform.output(e))
                  : onChange(e?.value)
              }
              options={types}
              className={cx('d-flex', styles.cardButtons)}
              style={{
                '--cols': isNaN(Number(cols)) ? 2 : +cols,
                '--gap': 'var(--size-16)',
              }}
              horizontal={horizontal}
            />

            {!error ? null : (
              <InputFeedback className='mt-1'>
                {typeof error?.message === 'string' ? error.message : error}
              </InputFeedback>
            )}
          </>
        )
      }}
    />
  )
}
