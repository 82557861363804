import React from 'react'
import { Controller } from 'react-hook-form'

import { getCustomStyles } from '../Common/react-select-custom-styles'
import CustomSelect from '../Forms/CustomSelect/CustomSelect'

export const otherSelectOption = { value: 'other', label: 'Other' }

const ControlledSelect = ({
  name,
  options = [],
  otherOption,
  control,
  error,
  controlOnChange,
  controlValue,
  rules,
  disabled,
  isDisabled,
  transform,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value, onBlur, onChange, ref } }) => {
        const allOptions = otherOption
          ? [...options, otherSelectOption]
          : options

        const handleChange =
          typeof controlOnChange === 'function'
            ? controlOnChange
            : (s) => {
                if (props?.isMulti) {
                  const newVal = s.map((i) => i.value)
                  onChange(newVal)
                } else {
                  onChange(s?.value)
                }
              }
        const newValue =
          typeof controlValue === 'function'
            ? controlValue(value)
            : props?.isMulti
            ? value?.map((vl) => allOptions.find((op) => op.value === vl))
            : allOptions.find((e) => e.value === value) || null

        return (
          <CustomSelect
            name={name}
            options={allOptions}
            value={
              typeof transform?.input === 'function'
                ? transform.input(newValue)
                : newValue
            }
            onChange={(e) =>
              typeof transform?.output === 'function'
                ? handleChange(transform.output(e))
                : handleChange(e)
            }
            onMenuClose={onBlur}
            required
            hasError={!!error}
            error={error}
            aria-invalid={!!error}
            aria-errormessage={name + '-error-msg'}
            classNamePrefix=''
            styles={getCustomStyles({ hasError: !!error })}
            isDisabled={disabled || isDisabled}
            selectRef={ref}
            {...props}
          />
        )
      }}
    />
  )
}

export default ControlledSelect
