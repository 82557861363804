// export * from './sharedConstants'

export const InvoiceState = {
  DRAFT: 'DRAFT',
  WAITING_APPROVAL: 'WAITING_APPROVAL',
  PAID: 'PAID',
  UNPAID: 'UNPAID',
  DISPUTED: 'DISPUTED',
  VOIDED: 'VOIDED',
  PENDING: 'PENDING',
  FAILED: 'FAILED',
}

export const InvoiceTransition = {
  PAY: 'PAY',
  UNPAY: 'UNPAY',
  VOID: 'VOID',
  UNVOID: 'UNVOID',
  SEND_TO_CUSTOMER: 'SEND_TO_CUSTOMER',
  REVIEW: 'REVIEW',
  DISPUTE: 'DISPUTE',
}

export const FormFieldType = {
  TEXT: 'text',
  TEXTAREA: 'textarea',
  DATE: 'date',
  MONEY: 'money',
  CURRENCY: 'currency',
  MONEY_CONVERTED: 'moneyConverted',
  SELECT: 'select',
  CUSTOM_SELECT: 'customSelect',
  COUNTRY: 'country',
  RADIO: 'radio',
  UPLOAD: 'upload',
  CUSTOM: 'custom',
  CHECKBOX: 'checkbox',
  // Just an empty div for spacing purposes
  EMPTY: 'empty',
  PASSWORD: 'password',
  ACCORDION: 'accordion',
}

export const ExpenseStatus = {
  DRAFT: 'DRAFT',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
  SUBMITTED: 'SUBMITTED',
}

export const ExpenseReviewState = {
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
  UNREVIEWED: 'UNREVIEWED',
}

export const EmploymentPaymentCadences = {
  unknown: 'Unknown / unspecified',
  monthly: 'Monthly',
  bimonthly: 'Bimonthly',
  weekly: 'Weekly',
  fortnightly: 'Fortnightly',
}

export const EmploymentAnnualBonusTypes = {
  unknown: 'Unknown / unspecified',
  none: 'None',
  vacation: 'Vacation',
  '13th-month': '13th Month',
  '13th-and-14th-month': '13th and 14th Month',
}

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const paymentTypes = [
  'commission',
  'bonus',
  'annual_bonus',
  'sign_on_bonus',
  'spot_bonus',
  'referral_bonus',
  'retention_bonus',
  'holiday_bonus',
  'profit_sharing_bonus',
  'other',
]

export const expenseCategories = [
  'BENEFITS',
  'CAR',
  'EQUIPMENT',
  'HOME_OFFICE',
  'LEARNING_AND_DEVELOPMENT',
  'FOOD',
  'OFFICE_SUPPLIES',
  'RENT',
  'TRAVEL',
  'OTHER',
]

export const EngagementState = {
  DRAFT: 'DRAFT',
  ASSISTED_SETUP: 'ASSISTED_SETUP',
  IN_PROGRESS: 'IN_PROGRESS',
  WAITING_FOR_SIGNATURE: 'WAITING_FOR_SIGNATURE',
  ENGAGED: 'ENGAGED',
  TERMINATED: 'TERMINATED',
}

export const EngagementStatePillColors = {
  DRAFT: 'gray',
  WAITING_FOR_SIGNATURE: 'violet',
  ASSISTED_SETUP: 'violet',
  IN_PROGRESS: 'violet',
  ENGAGED: 'green',
  TERMINATED: 'white',
}

export const EmploymentStatus = {
  DRAFT: 'draft',
  WAITING_FOR_SIGNATURE: 'waiting_for_signature',
  IN_PROGRESS: 'assisted_setup',
  ENGAGED: 'engaged',
  TERMINATED: 'terminated',
}

export const ContractState = {
  DRAFT: 'draft',
  IN_PROGRESS: 'in_progress',
  WAITING_FOR_SIGNATURE: 'waiting_for_signature',
  ENGAGED: 'engaged',
  TERMINATED: 'terminated',
}

export const DEFAULT_DAYS_OF_NOTICE = 14
export const DEFAULT_DAYS_TO_CURE = 5

export const EmploymentEvent = {
  SUBMIT: 'submit',
  ENGAGE: 'engage',
  TERMINATE: 'terminate',
  REENGAGE: 'reengage',
  DISENGAGE: 'disengage',
  DRAFT: 'draft',
}

export const humanizedEmploymentState = (state) => {
  switch (state) {
    case EmploymentStatus.DRAFT:
    case EngagementState.DRAFT:
      return 'Draft'
    case EmploymentStatus.IN_PROGRESS:
    case EngagementState.IN_PROGRESS:
    case EngagementState.ASSISTED_SETUP:
    case ContractState.IN_PROGRESS:
      return 'In progress'
    case EngagementState.WAITING_FOR_SIGNATURE:
    case ContractState.WAITING_FOR_SIGNATURE:
      return 'Signing'
    case EngagementState.ENGAGED:
    case EmploymentStatus.ENGAGED:
      return 'Engaged'
    case EngagementState.TERMINATED:
    case EmploymentStatus.TERMINATED:
      return 'Terminated'
    default:
      return 'Not a valid state'
  }
}

export const ContractBillingType = {
  FIXED: 'FIXED',
  PAY_AS_YOU_GO: 'PAY_AS_YOU_GO',
}

export const PaygBillingType = {
  FIXED_RATE: 'FIXED_RATE',
  PAY_PER_TASK: 'PAY_PER_TASK',
  COMMISSION_BASED: 'COMMISSION_BASED',
}

export const usaStates = [
  { code: 'AK', name: 'Alaska' },
  { code: 'AL', name: 'Alabama' },
  { code: 'AR', name: 'Arkansas' },
  { code: 'AS', name: 'American Samoa' },
  { code: 'AZ', name: 'Arizona' },
  { code: 'CA', name: 'California' },
  { code: 'CO', name: 'Colorado' },
  { code: 'CT', name: 'Connecticut' },
  { code: 'DC', name: 'District of Columbia' },
  { code: 'DE', name: 'Delaware' },
  { code: 'FL', name: 'Florida' },
  { code: 'GA', name: 'Georgia' },
  { code: 'GU', name: 'Guam' },
  { code: 'HI', name: 'Hawaii' },
  { code: 'IA', name: 'Iowa' },
  { code: 'ID', name: 'Idaho' },
  { code: 'IL', name: 'Illinois' },
  { code: 'IN', name: 'Indiana' },
  { code: 'KS', name: 'Kansas' },
  { code: 'KY', name: 'Kentucky' },
  { code: 'LA', name: 'Louisiana' },
  { code: 'MA', name: 'Massachusetts' },
  { code: 'MD', name: 'Maryland' },
  { code: 'ME', name: 'Maine' },
  { code: 'MI', name: 'Michigan' },
  { code: 'MN', name: 'Minnesota' },
  { code: 'MO', name: 'Missouri' },
  { code: 'MS', name: 'Mississippi' },
  { code: 'MT', name: 'Montana' },
  { code: 'NC', name: 'North Carolina' },
  { code: 'ND', name: 'North Dakota' },
  { code: 'NE', name: 'Nebraska' },
  { code: 'NH', name: 'New Hampshire' },
  { code: 'NJ', name: 'New Jersey' },
  { code: 'NM', name: 'New Mexico' },
  { code: 'NV', name: 'Nevada' },
  { code: 'NY', name: 'New York' },
  { code: 'OH', name: 'Ohio' },
  { code: 'OK', name: 'Oklahoma' },
  { code: 'OR', name: 'Oregon' },
  { code: 'PA', name: 'Pennsylvania' },
  { code: 'PR', name: 'Puerto Rico' },
  { code: 'RI', name: 'Rhode Island' },
  { code: 'SC', name: 'South Carolina' },
  { code: 'SD', name: 'South Dakota' },
  { code: 'TN', name: 'Tennessee' },
  { code: 'TX', name: 'Texas' },
  { code: 'UT', name: 'Utah' },
  { code: 'VA', name: 'Virginia' },
  { code: 'VI', name: 'Virgin Islands' },
  { code: 'VT', name: 'Vermont' },
  { code: 'WA', name: 'Washington' },
  { code: 'WI', name: 'Wisconsin' },
  { code: 'WV', name: 'West Virginia' },
  { code: 'WY', name: 'Wyoming' },
]

export const ContractorKind = {
  INDIVIDUAL: 'INDIVIDUAL',
  COMPANY: 'COMPANY',
}

export const companyKindOptions = [
  'DEFAULT_NON_PROFIT',
  'DEFAULT_PARTNERSHIP',
  'DEFAULT_PRIVATE_COMPANY',
  'DEFAULT_PUBLIC_COMPANY',
  'DEFAULT_SOLE_TRADER',
  'UK_PARTNERSHIP',
  'UK_PRIVATE_LIMITED_COMPANY',
  'UK_PUBLIC_LIMITED_COMPANY',
  'UK_SOLE_PROPIETORSHIP',
  'UK_UNLIMITED_COMPANY',
  'US_CORPORATION',
  'US_LLC',
  'US_PARTNERSHIP',
  'US_SOLE_PROPIETORSHIP',
  'OTHER',
]

export const AccountAspect = {
  ADMIN: 'admin',
  NONE: 'none',
  COLLEAGUE: 'colleague',
  CONTRACTOR: 'contractor',
  COMPANY: 'company',
}

export const EngagementType = {
  CONTRACT: 'CONTRACT',
  EMPLOYMENT: 'EMPLOYMENT',
}

export const PaymentMethod = {
  TRANSFERWISE: 'PaymentMethodTransferwiseEmail',
  BANK_ACCOUNT: 'PaymentMethodBankAccount',
  STRIPE_ACH: 'PaymentMethodStripeAch',
  STRIPE_SEPA: 'PaymentMethodStripeSepa',
}

export const InvoiceKind = {
  CONTRACTOR: 'CONTRACTOR',
  OYSTER: 'OYSTER',
}

export const FlashMessageType = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
}

export const PaymentMethodState = {
  VERIFIED: 'VERIFIED',
  IN_VERIFICATION: 'IN_VERIFICATION',
  AUTHORIZED_FOR_DIRECT_DEBIT: 'AUTHORIZED_FOR_DIRECT_DEBIT',
}

export const TaxComplianceState = {
  PROVIDED: 'PROVIDED',
  EXPECTED: 'EXPECTED',
}

export const TaxComplianceKind = {
  US_W9: 'US W9',
  US_W8BEN: 'US W8BEN',
  US_W8BENE: 'US W8BENE',
}

export const TransactionStatus = {
  INITIALIZED: 'INITIALIZED',
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  ERROR: 'ERROR',
}

export const EngagementPaymentDays = {
  unknown: 'Unknown / unspecified',
  WORKED_MONTH_LAST_DAY: 'Last day of worked month',
  ...[...Array(30).keys()].reduce((result, key) => {
    result[`SUCCEEDING_MONTH_DAY_${key + 1}`] = `Day ${
      key + 1
    } of succeeding month`
    return result
  }, {}),
}

export const OysterFeeDiscountMonths = [
  { name: 'Not Applicable', value: 0 },
  { name: '3 months', value: 3 },
  { name: '6 months', value: 6 },
  { name: '1 year', value: 12 },
  { name: '2 years', value: 24 },
  { name: '3 years', value: 36 },
  { name: 'Indefinite', value: 1200 },
]

export const HealthBenefitPlan = {
  STANDARD: 'standard',
  PREMIUM: 'premium',
}

export const HealthBenefitType = {
  IMG: 'IMG',
  NONE: 'NONE',
  SAFETY_WINGS: 'SAFETY_WINGS',
  SUN_LIFE: 'SUN_LIFE',
}

export const IconPillColor = {
  ORANGE: 'orange',
}

export const ButtonType = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  DANGER: 'danger',
  CANCEL: 'cancel',
}

export const TerminationState = {
  CANCELLATION_REQUESTED: 'CANCELLATION_REQUESTED',
  CANCELLED: 'CANCELLED',
  APPROVED: 'APPROVED',
  INITIATED: 'INITIATED',
  TERMINATED: 'TERMINATED',
}

export const AddressType = {
  LIVING: 'living_address',
  MAILING: 'mailing_address',
  CORPORATE: 'corporate_address',
  BILLING: 'billing_address',
}

export const AllowanceStatuses = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
}
