import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { Card, Container, FormGroup, TabContent, TabPane } from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import { ControlledVerificationCode } from '../../../components/controlled-verification-code'
import ModalHeader from '../../../components/ModalHeader'
import StepContainer from '../../../components/Steps/StepContainer'
import Loader from '../../../components/ui/loader'
import { useFetch } from '../../../helpers/hooks'
import { cardActivity, resetCardPinCode } from '../../../services/api'
import { CARD_TYPE_ENUM } from '../utils/card-types-status'
import { PinSetSuccessModal } from './activate-physical-card'
import './pin_code.css'

const step = { id: 1, title: 'Reset card pin' }

function getPhysicalCard(cards) {
  if (!cards || !Array.isArray(cards)) return {}

  return cards.find((card) => card.card_type === CARD_TYPE_ENUM.PHYSICAL)
}

export default function ResetPin() {
  const history = useHistory()

  const { data: cards, isLoading: cardsLoading } = useFetch({
    action: cardActivity,
    autoFetch: true,
    onComplete: (data) => {
      const physicalCard = getPhysicalCard(data?.cards)

      const physicalCardActive = physicalCard?.status?.is_active

      if (!physicalCardActive) {
        history.push('/cards')
      }
    },
  })

  return (
    <Container fluid>
      <ModalHeader action={() => history.push('/cards')} />

      <div className='d-flex flex-column' style={{ marginBottom: '9rem' }}>
        <TabContent activeTab={step.id}>
          {cardsLoading ? (
            <Card style={{ maxWidth: 820, margin: '80px auto 0px auto' }}>
              <Loader minHeight='63vh' />
            </Card>
          ) : (
            <ResetPinForm card={getPhysicalCard(cards?.cards)} />
          )}
        </TabContent>
      </div>
    </Container>
  )
}

function getSchema(length, label) {
  return yup
    .string()
    .length(length, `${label} must be ${length} characters`)
    .matches(/^\d+$/, `${label} must be numeric`)
    .required(`${label} is required`)
}

const schema = yup.object().shape({
  old_pin_code: getSchema(4, 'PIN Code'),
  new_pin_code: getSchema(4, 'PIN Code'),
  confirm_pin_code: yup
    .string()
    .oneOf([yup.ref('new_pin_code'), null], 'Confirmation PIN Code must match')
    .required('Confirm PIN Code is required'),
})

function ResetPinForm({ card }) {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  })

  const pinConfirmation = watch('confirm_pin_code')

  const { startFetch: resetPin, isLoading } = useFetch({
    action: resetCardPinCode,
    onComplete: () => {
      toastr.clear()
      setIsSuccessModalOpen(true)
    },
    onError: (resp) => {
      toastr.error(resp?.error?.messages.join(', ') || 'Something went wrong')
    },
  })

  const nextIsLoadingAndDisabled = isLoading

  function onSubmit(data) {
    const body = { ...data, card_id: card.id }
    delete body.confirm_pin_code

    resetPin(body)
  }

  function onError(error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }

  const submitStep = handleSubmit(onSubmit, onError)

  return (
    <>
      <TabPane tabId={step.id}>
        <StepContainer
          total={1}
          index={step.id}
          title={step.title}
          loading={nextIsLoadingAndDisabled}
          disableNext={nextIsLoadingAndDisabled}
          onNext={submitStep}
          noBack
          nextText='Reset PIN'
        >
          <div
            className='align-items-center d-flex flex-column gap-32 py-4 text-center'
            style={{ minHeight: '50vh' }}
          >
            <FormGroup className='align-items-center d-flex flex-column mb-0 pin-code'>
              <ControlledVerificationCode
                control={control}
                label='Old PIN code'
                labelClassName='font-size-16'
                name='old_pin_code'
                id='old_pin_code'
                length={4}
                error={errors?.old_pin_code}
              />
            </FormGroup>
            <FormGroup className='align-items-center d-flex flex-column mb-0 pin-code'>
              <ControlledVerificationCode
                control={control}
                label='New PIN code'
                labelClassName='font-size-16'
                name='new_pin_code'
                id='new_pin_code'
                length={4}
                error={errors?.new_pin_code}
                transform={{
                  output: (newValue) => {
                    if (pinConfirmation && newValue === pinConfirmation) {
                      setError('confirm_pin_code', { message: '' })
                    }
                    return newValue
                  },
                }}
              />
            </FormGroup>
            <FormGroup className='align-items-center d-flex flex-column mb-0 pin-code'>
              <ControlledVerificationCode
                control={control}
                label='Confirm PIN code'
                labelClassName='font-size-16'
                name='confirm_pin_code'
                id='confirm_pin_code'
                length={4}
                error={errors?.confirm_pin_code}
              />
            </FormGroup>
          </div>
        </StepContainer>
      </TabPane>

      <PinSetSuccessModal isOpen={isSuccessModalOpen} />
    </>
  )
}
