import { LockKeyOpen } from '@phosphor-icons/react'
import cx from 'classnames'
import React from 'react'
import { Popover, PopoverBody } from 'reactstrap'

import BadgeX from '../../Table/BadgeX'
import styles from './card-button.module.scss'

export default function CardButton({
  label,
  description,
  icon,
  isActive,
  onClick,
  id,
  isDisabled = false,
  horizontal = false,
  isNew,
}) {
  const [showAccessModal, setShowAccessModal] = React.useState(false)

  return (
    <button
      className={cx(
        'd-flex p-4 rounded w-100 border-2 border-transparent position-relative text-left gap-12',
        styles.cardButton,
        {
          [`${styles.isActive} text-primary cursor-default`]: isActive,
          'text-secondary': !isActive,
          'flex-column align-items-start': horizontal,
          'text-slate-800': isDisabled,
          'bg-transparent': !isDisabled,
        },
      )}
      id={id}
      type='button'
      onClick={isDisabled ? () => setShowAccessModal(true) : onClick}
    >
      {!isDisabled ? null : (
        <Popover
          placement='top'
          isOpen={showAccessModal}
          toggle={() => setShowAccessModal(false)}
          target={id}
          trigger='legacy'
          style={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 16px 24px' }}
          className='border-0 rounded-0'
          innerClassName='border border-gray-b rounded'
        >
          <PopoverBody className='p-0'>
            <div className='d-flex p-3 gap-16 align-items-start justify-content-start'>
              <LockKeyOpen
                size={20}
                weight='duotone'
                color='var(--primary)'
                className='flex-shrink-0'
              />

              <div>
                <h3 className='font-size-16 text-gray-h'>
                  {isDisabled?.title || 'Unlock Access'}
                </h3>

                {!isDisabled?.description ? null : (
                  <p className='text-gray-600 mt-2 mb-0 font-size-12'>
                    {isDisabled.description}
                  </p>
                )}
              </div>
            </div>

            <div className='p-3 border-top border-gray-b'>
              <button
                type='button'
                className='btn btn-primary rounded btn-sm btn-block py-2'
                style={{ color: 'white' }}
                onClick={() =>
                  window.Intercom(
                    'showNewMessage',
                    isDisabled?.chatMessage || 'I want to unlock access',
                  )
                }
              >
                Proceed
              </button>
            </div>
          </PopoverBody>
        </Popover>
      )}

      {!isNew ? null : (
        <BadgeX
          className={cx('uppercase position-absolute', {
            'bg-gray-500': !!isDisabled,
          })}
          style={{ right: '1.5rem', top: '1.5rem' }}
          pill
          size='sm'
          color={!isDisabled ? 'primary' : 'secondary'}
        >
          New
        </BadgeX>
      )}

      {!icon ? null : (
        <span
          className='flex-shrink-0 align-items-center d-flex flex-shrink-0'
          style={{
            '--size': '36px',
            width: 'var(--size)',
            height: 'var(--size)',
          }}
        >
          {icon}
        </span>
      )}

      <div
        className={cx({
          'text-primary': isActive,
          'text-dark': !isActive && !isDisabled,
          'text-slate-500': isDisabled,
        })}
      >
        {!label ? null : <h5 className='rp-font-bold text-current'>{label}</h5>}

        {!description ? null : (
          <p
            className={cx('font-size-12 mb-0', { 'text-secondary': !isActive })}
          >
            {description}
          </p>
        )}
      </div>
    </button>
  )
}
