import React, { useCallback, useEffect, useState } from 'react'
import { useFetch } from '../../../../helpers/hooks'
import {
  Badge,
  Card,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  Table,
} from 'reactstrap'
import {
  getAdminDashboard,
  getAllPaymentList,
  refreshAdminDashboard,
} from '../../../../services/api'
import moment from 'moment'
import { StyledH5, StyledH6, StyledP } from '../../../../components/Typo'
import Paginations from 'react-js-pagination'
import classnames from 'classnames'
import styled from 'styled-components'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import SearchBar from '../../../../components/SearchBar'
import axios from 'axios'
import ContractRef from '../../components/ContractRef'
import MiniWidget from '../../../Activity/mini-widget'
import upcoming from '../../../../assets/images/upcoming_payment.svg'
import Walleticon from '../../../../assets/images/Walleticon.svg'
import Moneyicon from '../../../../assets/images/paid_this_month.svg'
import BadgeX from '../../../../components/Table/BadgeX'
import UserFlagging from '../../../../components/userFlag'

const getStatusColor = (status) => {
  switch (status) {
    case 'Unpaid':
      return 'danger'
    case 'Paid':
      return 'success'
    case 'Processing':
      return 'warning'
    default:
      return 'primary'
  }
}

const reports = [
  {
    icon: upcoming,
    link: '/admin/upcoming-payments',
    title: 'Upcoming Payments',
    value: {
      value: 0,
      currency: 'USD',
    },
    badgeValue: 0,
    color: 'success',
    desc: 'Contracts',
    info: 'List of payments in the current payment cycle, including due and overdue payments',
  },
  {
    icon: Walleticon,
    link: '/admin/due-payments',
    title: ' Payments due',
    value: {
      value: 0,
      currency: 'USD',
    },
    overDue: 0,
    overDueMonth: 0,
    badgeValue: 0,
    info: 'List of payments due within 5 days and overdue payments',
    color: 'success',
    desc: 'Contracts',
  },
  {
    icon: Moneyicon,
    link: '/admin/transactions',
    title: 'Paid this month',
    value: {
      value: 0,
      currency: 'USD',
    },
    badgeValue: 0,
    color: 'success',
    desc: 'Contracts',
  },
  {
    icon: Moneyicon,
    link: '/admin/transactions',
    title: 'Processed this month',
    value: {
      value: 0,
      currency: 'USD',
    },
    badgeValue: 0,
    color: 'success',
    desc: 'Contracts',
  },
]

const PaymentCardAdmin = (props) => {
  const { payment } = props

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: payment?.currency?.code || 'USD',
  })

  return (
    <div
      className=' py-0   mb-3'
      style={{
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 1px 0px #DFE1E6',
        borderRadius: 4,
        border: '1px solid #E7E8F2',
        position: 'relative',
      }}
    >
      <Row className='p-3 m-0 border-bottom'>
        <Col xs={8} className='p-0 m-0'>
          <StyledH5 className={'text-dark font-weight-normal   font-size-14'}>
            {payment.contract_name}
          </StyledH5>
          <StyledH6 className='text-primary  border-secondary font-size-12'>
            {payment.contract_ref}
          </StyledH6>
        </Col>
        <Col
          xs={4}
          className='align-items-center justify-content-end d-flex p-0 m-0'
        >
          <Badge
            className={`font-size-14  rounded bg-soft-${getStatusColor(
              payment?.status?.name,
            )}`}
            color='white'
            pill
          >
            <StyledP
              style={{ padding: 4 }}
              min={'12px'}
              max={'14px'}
              className={`mb-0 text-${getStatusColor(payment?.status?.name)}`}
            >
              {payment?.status?.name.includes('Pending')
                ? 'Pending'
                : payment?.status?.name}
            </StyledP>
          </Badge>
        </Col>
      </Row>
      <div className='p-3'>
        <div className='d-flex mb-3 align-items-center justify-content-between align-items-center'>
          <h6 className={'text-dark font-weight-normal mb-0   font-size-14'}>
            Contractor Name
          </h6>
          <h6 className='font-weight-normal mb-0 text-dark   font-size-14'>
            {' '}
            {payment.contractor?.first_name} {payment?.contractor?.last_name}
          </h6>
        </div>
        <div className='d-flex mb-3 align-items-center justify-content-between align-items-center'>
          <h6 className={'text-dark font-weight-normal mb-0   font-size-14'}>
            Company
          </h6>
          <h6 className='text-dark   font-size-14 font-weight-normal mb-0'>
            {' '}
            {payment?.company?.name}
          </h6>
        </div>

        <div className='d-flex mb-3 align-items-center justify-content-between align-items-center'>
          <h6 className={'text-dark font-weight-normal mb-0   font-size-14'}>
            Amount
          </h6>
          <h6 className='text-dark   font-size-14 font-weight-normal mb-0'>
            {formatter.format(payment.total)}
          </h6>
        </div>

        <div className='d-flex mb-3 justify-content-between align-items-center'>
          <h6 className='  text-dark font-weight-normal mb-0   font-size-14'>
            Due Date
          </h6>
          <h6 className='text-dark font-weight-normal mb-0   font-size-14'>
            {' '}
            {moment(payment.due_date).format('MM/DD/YYYY')}
          </h6>
        </div>
      </div>
    </div>
  )
}

let interval
const AllPayments = () => {
  const defaultState = { label: 'Select Status', value: -1 }
  const defaultCompany = { label: 'Select Company', value: null }

  const [expanded, setExpanded] = useState(-1)
  const [activePage, setActivePage] = useState(1)
  const [activeTab, setActiveTab] = useState(0)
  const [query, setQuery] = useState('')
  const [state, setState] = useState(defaultState)
  const [company, setCompany] = useState(defaultCompany)
  const [paymentReports, setPaymentReports] = useState(reports)
  const [queue, setQueue] = useState(null)
  const [isFirst, setIsFirst] = useState(false)

  const payments = useFetch({
    action: getAllPaymentList,
    withAdminAccess: true,
  })

  const dashboard = useFetch({
    action: getAdminDashboard,
    withAdminAccess: true,
    onComplete: (data) => {
      setIsFirst(true)
      const newReport = [...reports]
      newReport[0] = {
        ...newReport[0],
        value: { ...newReport[0].value, value: data.upcoming_payment },
        overDueMonth: data.upcoming_payment_this_month,
      }
      newReport[1] = {
        ...newReport[1],
        value: { ...newReport[1].value, value: data.payment_due },
        overDue: data.payment_over_due,
        overDueMonth: data.payment_due_this_month,
      }
      newReport[2] = {
        ...newReport[2],
        value: { ...newReport[2].value, value: data.paid_this_month },
      }
      newReport[3] = {
        ...newReport[3],
        value: { ...newReport[3].value, value: data.processed_this_month },
      }
      setPaymentReports(newReport)
    },
  })

  const fetchDashboard = useCallback(
    (cancel) => {
      if (cancel) cancel()
      if (dashboard.data?.queued === 0) {
        setQueue(null)
        clearInterval(interval)
      } else {
        if (!queue) {
          setQueue(dashboard.data?.queued)
        }
        // eslint-disable-next-line no-return-assign
        dashboard.startFetch(
          null,
          false,
          new axios.CancelToken((c) => (cancel = c)),
        )
      }
    },
    [dashboard.data, queue],
  )

  useEffect(() => {
    let cancel
    interval = setInterval(() => fetchDashboard(cancel), 2000)
    return () => {
      clearInterval(interval)
    }
  }, [dashboard.data, queue])

  const refresh = useFetch({
    action: refreshAdminDashboard,
    withAdminAccess: true,
    onComplete: (data) => {
      dashboard.startFetch()
    },
  })

  const handlePageChange = (page) => {
    setActivePage(page)
  }

  const status = [
    { label: 'Upcoming', value: '0' },
    { label: 'Due', value: '1' },
    { label: 'Overdue', value: '2' },
    { label: 'Paid', value: '3' },
    { label: 'Processing', value: '4' },
  ]

  useEffect(() => {
    let cancel
    const body = {
      page: activePage,
      tab: status[activeTab].label.toLowerCase(),
    }
    if (company?.value) {
      body.company_id = company.value
    }
    if (state?.value !== -1) {
      body.status_id = state?.value
    }
    if (query) {
      body.search = query
    }
    // eslint-disable-next-line no-return-assign
    payments.startFetch(
      body,
      !query && state?.value === -1 && !company?.value,
      new axios.CancelToken((c) => (cancel = c)),
    )
    return () => {
      cancel()
    }
  }, [activePage, activeTab, state, company, query])

  return (
    <div className='page-content'>
      {payments.isLoading ? (
        <Container style={{ minHeight: '30rem' }}>
          <Col style={{ minHeight: '30rem' }}>
            <Row
              style={{ minHeight: '30rem' }}
              className='justify-content-center align-items-center'
            >
              <Spinner type='grow' className='mr-2' color='primary' />
            </Row>
          </Col>
        </Container>
      ) : (
        <Container fluid className={'p-0 m-0'}>
          <Row className='align-items-center p-0 mx-0 mb-3 mb-md-4 justify-content-between'>
            <StyledH5 min='22px' max='32px' className='mb-0'>
              All payments
            </StyledH5>
            {isFirst && (
              <>
                {dashboard.data?.queued === 0 ? (
                  <button
                    className='btn btn-primary'
                    onClick={() => refresh.startFetch()}
                  >
                    {refresh.isLoading && (
                      <i className='bx bx-loader bx-spin mr-2' />
                    )}
                    Refresh
                  </button>
                ) : (
                  <>
                    {
                      <BadgeX
                        name={
                          queue ? (
                            `${queue - dashboard.data?.queued} / ${queue}`
                          ) : (
                            <i className='bx bx-loader bx-spin mx-2' />
                          )
                        }
                        status={'secondary'}
                        textStatus={'secondary'}
                      />
                    }
                  </>
                )}
              </>
            )}
          </Row>
          <MiniWidget reports={paymentReports} simple isAdmin />
          <Row className={'m-0 p-0 mt-3'}>
            <Col className={'p-0 m-0'}>
              <Card className={'p-0 m-0'}>
                <Container>
                  <Row className='p-3' style={{ gap: '0.75rem' }}>
                    <Col xs={12} className='px-0 mb-2 d-none d-md-block'>
                      <StyledNav tabs>
                        {status.map((e, i) => (
                          <NavItem key={`tab-${i}`} className='bg-white'>
                            <NavLink
                              style={{ cursor: 'pointer' }}
                              className={classnames({
                                active: activeTab === i,
                                'bg-white': true,
                              })}
                              onClick={() => {
                                setActiveTab(i)
                              }}
                            >
                              <p className='font-size-14 mb-0'>{e.label}</p>
                            </NavLink>
                          </NavItem>
                        ))}
                      </StyledNav>
                    </Col>

                    <Col md={4} xs={12} className='px-0 d-block d-md-none'>
                      <CustomSelect
                        name={'test'}
                        value={status[activeTab]}
                        defaultValue={{ label: 'Upcoming', value: '0' }}
                        options={status}
                        onChange={(v) => {
                          setActiveTab(v.value)
                        }}
                      />
                    </Col>

                    <Col md={4} xs={12} className='px-0'>
                      <CustomSelect
                        options={
                          Array.isArray(payments?.data?.companies)
                            ? [
                                defaultCompany,
                                ...(payments?.data?.companies?.map((e) => ({
                                  label: e.name,
                                  value: e.id,
                                })) ?? []),
                              ]
                            : [defaultCompany]
                        }
                        value={company}
                        onChange={setCompany}
                      />
                    </Col>

                    <Col md={3} xs={12} className='px-0'>
                      <button
                        className='btn btn-primary'
                        onClick={() => {
                          setActivePage(1)
                          setQuery(null)
                          setCompany(defaultCompany)
                          setState(defaultState)
                        }}
                      >
                        Clear Filter
                      </button>
                    </Col>

                    <Col xs={12} className='px-0'>
                      <SearchBar
                        query={query}
                        placeholder='search by contract ID, Payment ID, or contractor name'
                        onQueryChanged={setQuery}
                        className='mr-md-2'
                      />
                    </Col>
                  </Row>
                </Container>

                <div className={'p-3 m-0 d-block d-md-none'}>
                  {payments.data?.payments?.map((payment, key) => (
                    <PaymentCardAdmin payment={payment} key={key} inde={key} />
                  ))}
                </div>
                <div className='table-responsive p-0 m-0 d-none d-md-block'>
                  <Table className='table table-centered table-nowrap text-muted'>
                    <thead className='thead-light'>
                      <tr>
                        <th className='border-top-0 text-muted'>{''}</th>
                        <th className='border-top-0 text-muted'>Contract ID</th>
                        <th className='border-top-0 text-muted'>Payment ID</th>
                        <th className='border-top-0 text-muted'>
                          Contractor name
                        </th>
                        <th className='border-top-0 text-muted'>
                          Contract Name
                        </th>
                        <th className='border-top-0 text-muted'>Company</th>
                        <th className='border-top-0 text-muted'>Amount</th>
                        <th className='border-top-0 text-muted'>Due date</th>
                        <th className='border-top-0 text-muted'>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payments.data?.payments?.map((payment, key) => {
                        const formatter = new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: payment?.currency?.code || 'USD',
                        })
                        return (
                          <React.Fragment key={'_payment_' + key}>
                            <tr>
                              <th>
                                {payment?.works?.length !== 0 && (
                                  <a
                                    onClick={() => {
                                      if (expanded === key) {
                                        setExpanded(-1)
                                      } else {
                                        setExpanded(key)
                                      }
                                    }}
                                  >
                                    <i className='bx bx-plus' />
                                  </a>
                                )}
                              </th>
                              <td>
                                <ContractRef
                                  contractId={payment?.contract_ref}
                                />
                              </td>
                              <td>
                                <span
                                  className='text-primary font-size-14 '
                                  style={{ fontWeight: '400' }}
                                >
                                  {payment?.payment_ref}
                                </span>
                              </td>
                              <td>
                                <div
                                  className='d-flex'
                                  style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    width: '120px',
                                  }}
                                >
                                  {payment.contractor?.is_flagged ? (
                                    <UserFlagging
                                      user={payment?.contractor}
                                      onCompleteAction={() => {
                                        payments.startFetch(
                                          {
                                            page: activePage,
                                            tab: status[
                                              activeTab
                                            ].label.toLowerCase(),
                                          },
                                          false,
                                        )
                                      }}
                                    />
                                  ) : null}
                                  {payment.contractor?.first_name}{' '}
                                  {payment?.contractor?.last_name}
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    width: '180px',
                                  }}
                                >
                                  {payment.contract_name}
                                </div>
                              </td>
                              <td>{payment?.company?.name}</td>
                              <td className='text-right'>
                                {formatter.format(payment.total)}
                              </td>
                              <td>
                                {moment(payment.due_date).format('MM/DD/YYYY')}
                              </td>
                              <td>
                                <Badge
                                  className={`py-1 font-size-12 rounded bg-soft-${getStatusColor(
                                    payment.status?.name,
                                  )} my-2`}
                                  pill
                                >
                                  <p
                                    className={`mb-0 font-size-14 text-${getStatusColor(
                                      payment.status?.name,
                                    )}`}
                                  >
                                    {payment.status?.name}
                                  </p>
                                </Badge>
                              </td>
                            </tr>
                            {expanded === key && (
                              <>
                                {payment?.works?.map((t) => {
                                  const formatter = new Intl.NumberFormat(
                                    'en-US',
                                    {
                                      style: 'currency',
                                      currency: t?.currency || 'USD',
                                    },
                                  )
                                  return (
                                    <tr
                                      className='font-weight-light font-size-12 bg-soft-primary'
                                      key={'_trans' + t?.id}
                                    >
                                      <th>{''}</th>
                                      <th>{''}</th>
                                      <th>{''}</th>
                                      <th className='font-weight-normal font-size-12'>
                                        {t?.details}
                                      </th>
                                      <th className='font-weight-normal font-size-12'>
                                        {t?.name}
                                      </th>
                                      <th className='font-weight-normal font-size-12 text-right'>
                                        {formatter.format(t?.amount)}
                                      </th>
                                      <th>{''}</th>
                                      <th>{''}</th>
                                      <th>
                                        {t?.status ? (
                                          <Badge
                                            className={`py-1 font-size-12 rounded bg-soft-${getStatusColor(
                                              t?.status,
                                            )} my-2`}
                                            pill
                                          >
                                            <p
                                              className={`mb-0 font-size-14 text-${getStatusColor(
                                                t?.status,
                                              )}`}
                                            >
                                              {t?.status}
                                            </p>
                                          </Badge>
                                        ) : (
                                          ''
                                        )}{' '}
                                      </th>
                                    </tr>
                                  )
                                })}
                              </>
                            )}
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
                <Col>
                  <Row className='px-3 justify-content-end'>
                    <Paginations
                      itemClass='page-item'
                      linkClass='page-link'
                      activePage={activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={payments.paginator?.total ?? 0}
                      onChange={handlePageChange}
                    />
                  </Row>
                </Col>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  )
}

const StyledNav = styled(Nav)`
  border-bottom: 0 !important;

  .nav-item {
    margin-right: 2rem;

    .nav-link {
      padding: 6px 0;
      background-color: #f4f5f7;
      color: #777f9e;
      font-weight: 400;
      &:hover {
        border-top-color: #f4f5f7 !important;
        border-right-color: #f4f5f7 !important;
        border-left-color: #f4f5f7 !important;

        color: #777f9e !important;
      }
    }
    .active {
      border: 0;
      font-weight: 600;
      border-bottom: 2px solid #114ef7;
      p {
        color: #114ef7 !important;
      }
    }
  }
`

export default AllPayments
