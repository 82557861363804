import qs from 'query-string'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, withRouter } from 'react-router-dom'
import { Col, Row, Spinner } from 'reactstrap'

import { userTypes } from '../../helpers/enum'
import { useFetch, usePermissions } from '../../helpers/hooks'
import { getClientInfo, getContractorInfo } from '../../services/api'
import {
  logout,
  saveRoute,
  updateDeepLink,
} from '../../store/auth/register/actions'
import { updateUserProfileInfo } from '../../store/profile/actions'
import { getIntercomData } from '../../utils/analytics'

const AuthMiddleware = (props) => {
  const user = useSelector((state) => state.Account?.user)
  const loggedIn = useSelector((state) => state.Account?.loggedIn)
  const userProfile = useSelector((state) => state.userProfile.userProfile)
  const isEmployee = userProfile?.contractor_type === 'employee'

  const { hasAccess } = usePermissions()
  const dispatch = useDispatch()
  const Component = props.component
  const Layout = props.layout
  const { history, permissions } = props

  const newProps = { ...props }
  delete newProps?.layout
  delete newProps?.component

  const userInfo = useFetch(
    {
      initResult: null,
      action: user?.type === 'client' ? getClientInfo : getContractorInfo,
      body: null,
      successAction: updateUserProfileInfo,
      onComplete: (data) => {
        const intercomData = getIntercomData(data)
        window.analytics.identify(data?.id, intercomData)
      },
      onError: (_, status) => {
        if (status === 401) {
          history.push('/logout')
        }
      },
      autoFetch: loggedIn && props.location.pathname !== '/logout',
    },
    [props.location],
  )

  let idleTime = 0
  let idleInterval
  useEffect(() => {
    // Increment the idle time counter every minute.
    idleInterval = setInterval(timerIncrement, 60000) // 1 minute

    window.addEventListener('mousemove', function (e) {
      idleTime = 0
    })
    window.addEventListener('keypress', function (e) {
      idleTime = 0
    })
    window.addEventListener('mousedown', function (e) {
      idleTime = 0
    })

    function timerIncrement() {
      if (loggedIn) idleTime = idleTime + 1
      if (idleTime > 29) {
        // 30 minutes
        if (props.location.pathname === '/withdraw') {
          dispatch(saveRoute('/activity'))
        } else {
          dispatch(saveRoute(props.location.pathname))
        }
        dispatch(logout())
        clearInterval(idleInterval)
      }
    }

    return () => {
      clearInterval(idleInterval)
    }
  }, [loggedIn])

  return (
    <Route
      {...newProps}
      render={(props) => {
        if (userInfo.isLoading && !userProfile) {
          return (
            <Col style={{ minHeight: '100vh' }}>
              <Row
                style={{ minHeight: '100vh' }}
                className='justify-content-center align-items-center'
              >
                <Spinner type='grow' className='mr-2' color='primary' />
              </Row>
            </Col>
          )
        }
        // here you can apply condition
        if (
          user &&
          !loggedIn &&
          user?.['2fa'] &&
          props.location.pathname !== '/two-factor-authentication' &&
          props.location.pathname !== '/logout'
        ) {
          return (
            <Redirect
              to={{
                pathname: '/two-factor-authentication',
                state: { from: props.location },
              }}
            />
          )
        }
        if (
          user &&
          !loggedIn &&
          user?.length > 1 &&
          props.location.pathname !== '/select-profile' &&
          props.location.pathname !== '/logout'
        ) {
          return (
            <Redirect
              to={{
                pathname: '/select-profile',
                state: { from: props.location },
              }}
            />
          )
        }
        if (!loggedIn) {
          const stateFrom = history.location
          if (stateFrom) {
            dispatch(
              updateDeepLink(`${stateFrom?.pathname}${stateFrom?.search}`),
            )
          }
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          )
        }
        if (permissions && userProfile && !hasAccess(permissions)) {
          return <Redirect to={{ pathname: '/no-access' }} />
        }
        if (
          user?.new &&
          !['/switch', '/complete-profile'].includes(props.location.pathname)
        ) {
          return (
            <Redirect
              to={{
                pathname: '/complete-profile',
                state: { from: props.location },
              }}
            />
          )
        }
        if (
          !user?.must_select_type &&
          user?.must_complete_entity &&
          !['/switch', '/complete-profile'].includes(props.location.pathname)
        ) {
          return (
            <Redirect
              to={{
                pathname: '/complete-profile',
                state: { from: props.location },
              }}
            />
          )
        }
        if (
          user?.must_select_type &&
          !['/account-type', '/complete-company'].includes(
            props.location.pathname,
          )
        ) {
          return (
            <Redirect
              to={{
                pathname: '/account-type',
                state: { from: props.location },
              }}
            />
          )
        }
        if (
          props.location.pathname === '/insurance' &&
          (user?.type === userTypes.COMPANY || isEmployee)
        ) {
          return (
            <Redirect
              to={{ pathname: '/activity', state: { from: props.location } }}
            />
          )
        }
        const params = qs.parse(props.location.search)
        if (!!params.type && user?.type !== params.type) {
          dispatch(
            updateDeepLink(
              `${props.location.pathname}?${qs.stringify({ id: params?.id })}`,
            ),
          )
          return (
            <Redirect
              to={{
                pathname: '/switch',
                state: {
                  entity_id: params.entity_id,
                  notSwitch: userProfile.company?.id !== params.entity_id,
                },
              }}
            />
          )
        }
        if (
          !!params.entity_id &&
          userProfile &&
          userProfile?.company?.id !== params.entity_id
        ) {
          dispatch(
            updateDeepLink(
              `${props.location.pathname}?${qs.stringify({ id: params?.id })}`,
            ),
          )
          return (
            <Redirect
              to={{
                pathname: '/switch',
                state: { entity_id: params.entity_id, notSwitch: true },
              }}
            />
          )
        }

        return (
          <>
            <Layout>
              <Component {...props} />
            </Layout>
          </>
        )
      }}
    />
  )
}

export default withRouter(AuthMiddleware)
