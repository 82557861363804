import React, { useState } from 'react'
import {
  Button,
  Card,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from 'reactstrap'
import toastr from 'toastr'

import { useFetch } from '../../../../helpers/hooks'
import {
  getLocalCurrencies,
  getMarkup,
  updateMarkup,
} from '../../../../services/api'
import ActionsDropdown from '../../../../components/ActionsDropdown'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import ADMIN_PERMISSIONS from '../../../../config/admin-permissions'
import useHasPermission from '../../../../helpers/hooks/admin/has-permission'

const CurrencyMarkup = () => {
  const [showModal, setShowModal] = useState(false)
  const list = useFetch({
    action: getMarkup,
    withAdminAccess: true,
    autoFetch: true,
    onError: toastr.error,
  })

  const hasEditPermission = useHasPermission(
    ADMIN_PERMISSIONS.MANAGE_CURRENCY_MARKUP,
  )

  return (
    <div className='page-content'>
      <div
        className='d-flex justify-content-between'
        style={{ marginBottom: '2rem' }}
      >
        <h1 className='mb-0 rp-font-gilroyB'>Currency Markup</h1>
        {list.isLoading || !hasEditPermission ? null : (
          <Button onClick={() => setShowModal(true)} color='primary'>
            Add Markup
          </Button>
        )}
      </div>

      <Card>
        <Col className='p-0 m-0'>
          {list.isLoading ? (
            <div
              className='d-flex justify-content-center align-items-center'
              style={{ minHeight: 145 }}
            >
              <Spinner type='grow' color='primary' />
            </div>
          ) : (
            <>
              <div className='table-responsive'>
                <Table className='table-centered table-nowrap text-muted mb-5'>
                  <thead className='thead-light'>
                    <tr>
                      <th className='border-top-0 text-muted'>From</th>
                      <th className='border-top-0 text-muted'>To</th>
                      <th className='border-top-0 text-muted'>Current rate</th>
                      <th className='border-top-0 text-muted'>New rate</th>
                      <th className='border-top-0 text-muted'>Markup</th>
                      {!hasEditPermission ? null : (
                        <th className='border-top-0' />
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {list.data?.map((item) => (
                      <MarkupLine
                        key={`${item.from}-${item.to}`}
                        item={item}
                        onUpdate={() => {
                          list.startFetch(null, false)
                        }}
                        hasEditPermission={hasEditPermission}
                      />
                    ))}
                  </tbody>
                </Table>
              </div>
              <MarkupModal
                isOpen={showModal}
                toggle={() => setShowModal(false)}
                onUpdate={() => {
                  list.startFetch(null, false)
                }}
              />
            </>
          )}
        </Col>
      </Card>
    </div>
  )
}

const MarkupLine = ({ item, onUpdate, hasEditPermission }) => {
  const [showUpdate, setShowUpdate] = useState(false)
  const [showMenu, setShowMenu] = useState(false)

  const options = [
    {
      component: () => <div>Update</div>,
      onClick: () => {
        setShowUpdate(true)
      },
    },
  ]

  return (
    <>
      <tr>
        <td>{item.from}</td>
        <td>{item.to}</td>
        <td>{item.current_rate}</td>
        <td>{item.new_rate}</td>
        <td>{item.value} %</td>
        {!hasEditPermission ? null : (
          <td>
            <ActionsDropdown
              options={options}
              isOpen={showMenu}
              toggle={() => setShowMenu((t) => !t)}
            />
          </td>
        )}
      </tr>
      <MarkupModal
        isOpen={showUpdate}
        toggle={() => setShowUpdate(false)}
        item={item}
        onUpdate={onUpdate}
      />
    </>
  )
}

const MarkupModal = ({ isOpen, toggle, item, onUpdate }) => {
  const [value, setValue] = useState(item?.value)
  const [from, setFrom] = useState(null)
  const [to, setTo] = useState(null)
  const currencies = useFetch({
    action: getLocalCurrencies,
    autoFetch: true,
    onError: toastr.error,
  })
  const update = useFetch({
    action: updateMarkup,
    withAdminAccess: true,
    onComplete: () => {
      toggle()
      onUpdate()
    },
    onError: toastr.error,
  })
  const onConfirmUpdate = () => {
    update.startFetch({
      from: item?.from || from?.code,
      to: item?.to || to?.code,
      value,
    })
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Update Markup</ModalHeader>
      <ModalBody>
        {!item && (
          <>
            <Col>
              <FormGroup>
                <Label>From</Label>
                <CustomSelect
                  onChange={setFrom}
                  value={from}
                  options={currencies.data?.map((e) => ({
                    value: e.id,
                    label: `${e.code} - ${e.name}`,
                    code: e.code,
                  }))}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>To</Label>
                <CustomSelect
                  onChange={setTo}
                  value={to}
                  options={currencies.data?.map((e) => ({
                    value: e.id,
                    label: `${e.code} - ${e.name}`,
                    code: e.code,
                  }))}
                />
              </FormGroup>
            </Col>
          </>
        )}
        <Col>
          <FormGroup>
            <Label>Markup value</Label>
            <Input
              value={value ? `${value}%` : ''}
              onChange={(e) => {
                setValue(e.target.value.replace('%', ''))
              }}
            />
          </FormGroup>
        </Col>
      </ModalBody>
      <ModalFooter>
        <Row>
          <button className='btn btn-primary' onClick={onConfirmUpdate}>
            {update.isLoading && <i className='bx bx-loader bx-spin mx-2' />}
            Update
          </button>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export default CurrencyMarkup
