import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'
import styled from 'styled-components'

import { StyledH2 } from '../../components/Typo'
import Button from '../../components/ui/button'
import { usePermissions } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'

export const StyledMiniWidget = styled.div`
  background-color: #fff;
  box-shadow: 0px 1px 0px #dfe1e6;
  border-radius: 4px;
  height: 100%;
`
export const StylePart = styled.div`
  padding: 20px;
  align-items: center;
  display: flex;
  @media (max-width: 1280px) {
    padding: 16px 16px;
  }
`
const MiniWidget = (props) => {
  const { simple, withdraw, pay, isAdmin } = props
  const { t } = useTranslation()
  const { hasAccess } = usePermissions()

  const canManagePayments = useSelector(
    (state) => state.userProfile?.userProfile?.is_payroll_approver,
  )

  return (
    <Row className='mx-n2' style={{ gap: '1rem 0' }}>
      {props.reports.map((report, key) => {
        let formatter = null
        if (report.value?.currency) {
          formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: report.value?.currency || 'USD',
          })
        }
        return (
          <Col key={key} lg={4} className='px-2'>
            <StyledMiniWidget>
              <StylePart
                className='border-bottom justify-content-between align-items-center d-flex gap-6'
                style={{ minHeight: 83 }}
              >
                <div className='justify-content-between align-items-center d-flex gap-12'>
                  <StyledImg alt='icon' src={report.icon} />

                  <h5 className='mb-0 text-gray-700'>
                    {t(report.title)}
                    {!!report.info && (
                      <>
                        <i
                          id={`info-repo-${key}`}
                          className='bx bx-info-circle font-size-16 ml-1'
                        />
                        <UncontrolledTooltip
                          placement='top'
                          target={`info-repo-${key}`}
                        >
                          {report.info}
                        </UncontrolledTooltip>
                      </>
                    )}
                  </h5>
                </div>

                {!((withdraw && key === 0) || (pay && key === 1)) &&
                  !!report.value?.value && (
                    <>
                      {canManagePayments && pay && key === 2 ? (
                        <Button
                          tag={Link}
                          onClick={report.track}
                          to={report.link}
                        >
                          Review
                        </Button>
                      ) : (
                        <Link
                          onClick={report.track}
                          to={report.link}
                          className='text-primary ml-2 font-size-14 d-flex align-items-center'
                        >
                          Details{' '}
                          <i className='bx text-primary bx-chevron-right font-size-6 align-middle ml-9' />
                        </Link>
                      )}
                    </>
                  )}
                {withdraw && key === 0 && report.value?.value !== 0 && (
                  <Button tag={Link} onClick={report.track} to='/withdraw'>
                    Withdraw
                  </Button>
                )}
                {pay &&
                  key === 1 &&
                  report.value?.value !== 0 &&
                  hasAccess(permissions.ViewUnpaidPayments) && (
                    <>
                      {hasAccess(permissions.PrepareTransactions) ? (
                        <Button tag={Link} onClick={report.track} to='/payment'>
                          Pay
                        </Button>
                      ) : (
                        <Link
                          onClick={report.track}
                          to='/payment'
                          className='text-primary ml-2 font-size-14 d-flex align-items-center'
                        >
                          Details{' '}
                          <i className='bx bx-chevron-right text-primary font-size-6 align-middle ml-9' />
                        </Link>
                      )}
                    </>
                  )}
              </StylePart>

              <StylePart>
                <div>
                  <StyledH2
                    style={{ fontWeight: '600' }}
                    max='24px'
                    min='22px'
                    className='text-gray-h mb-0'
                  >
                    {formatter
                      ? formatter.format(report?.value?.value)
                      : report?.value?.amount}
                  </StyledH2>
                  {!!report?.overDue && (
                    <StyledH2
                      style={{ fontWeight: '600' }}
                      max={isAdmin ? '16px' : '18px'}
                      min={isAdmin ? '16px' : '18px'}
                      className='text-danger mb-0'
                    >
                      {formatter
                        ? formatter.format(report?.overDue)
                        : report?.overDue}{' '}
                      (Overdue)
                    </StyledH2>
                  )}
                  {!!report?.overDueMonth && (
                    <StyledH2
                      style={{ fontWeight: '600' }}
                      max={'16px'}
                      min={'16px'}
                      className='text-secondary   mb-0'
                    >
                      {formatter
                        ? formatter.format(report?.overDueMonth)
                        : report?.overDueMonth}{' '}
                      (This month)
                    </StyledH2>
                  )}
                </div>
                {!simple && (
                  <div className='d-flex'>
                    <div>
                      <div
                        style={{ color: '#0F1035' }}
                        className={
                          'badge badge-soft-' +
                          report.color +
                          ' font-size-12 ml-2'
                        }
                      >
                        <p className='mb-0 font-size-14'>{report.badgeValue}</p>
                      </div>
                    </div>
                    <span
                      style={{ color: '#0F1035' }}
                      className='ml-2 text-truncate'
                    >
                      {report.desc}
                    </span>
                  </div>
                )}
              </StylePart>
            </StyledMiniWidget>
          </Col>
        )
      })}
    </Row>
  )
}

export const StyledImg = styled.img`
  width: 32px;

  @media (max-width: 425px) {
    width: 24px;
  }
`

export default MiniWidget
