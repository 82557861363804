import axios from 'axios'

import { contractTypes, userTypes } from '../helpers/enum'
import isNill from '../utils/is-nill'
import { format } from 'date-fns'

const host = process.env.REACT_APP_API_HOST
const path = '/api'
const dateFormat = 'yyyy-MM'

const api = axios.create({
  baseURL: `${host}${path}`,
  headers: { 'Content-Type': 'application/json' },
})

const hostV2 = process.env.REACT_APP_API_HOST_V1
const apiV2 = axios.create({
  baseURL: hostV2,
  headers: { 'Content-Type': 'application/json' },
})

const getConfig = (token, extra = {}) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    ...extra,
  }
}

export const signup = (data, type) =>
  api.post(
    type === userTypes.CONTRACTOR ? 'contractor/signup' : 'client/signup',
    { ...data },
  )

export const signupEmployee = (token, data) => {
  const formData = new FormData()
  Object.keys(data).forEach((k) => {
    if (!isNill(data[k])) {
      formData.append(k, data[k])
    }
  })
  return api.post('contractor/update', formData, getConfig(token))
}

export const login = (data) => {
  return api.post('/login', data)
}
export const userLogin = (token, data) => {
  return api.post('/login', data, getConfig(token))
}

export const logoutUser = (token) => api.get('/logout', getConfig(token))

export const resetPassword = (_, data) => api.post('/password/reset', data)

export const updatePassword = (_, data) => api.post('/password/update', data)

export const verifyOTP = (otp, email, type) =>
  api.post(
    type === userTypes.CONTRACTOR ? 'contractor/verify' : 'client/verify',
    { email, otp },
  )
export const checkRegisterToken = (token) =>
  api.get('auth/token/check', getConfig(token))
export const verifyClientOTP = (data) => api.post('client/verify', data)
export const verifyContractorOTP = (data) => api.post('contractor/verify', data)

export const verifyReCaptchaV3 = (token) => {
  return api.post('recaptcha/verify', { response: token })
}

export const completeRegistration = (type, allData, token) => {
  const { nextStep, ...data } = allData

  if (data.token && nextStep !== 'update-profile') {
    return api.post(
      type === userTypes.CONTRACTOR
        ? 'contractor/google/signup'
        : 'client/google/signup',
      data,
      getConfig(token),
    )
  } else {
    const formData = new FormData()
    Object.keys(data).forEach((k) => {
      if (!isNill(data[k])) {
        formData.append(k, data[k])
      }
    })
    return api.post(
      type === userTypes.CONTRACTOR ? 'contractor/update' : 'client/update',
      formData,
      getConfig(token, { 'Content-Type': 'multipart/form-data' }),
    )
  }
}

export const googleSignUp = (_, data) => {
  const { token, sourcePath, sourceData } = data
  return api.post('client/google/signup/verify', {
    token,
    source_path: sourcePath,
    source_data: sourceData,
  })
}

export const getStaticData = (token) => {
  return api.post(
    'static/list',
    {
      tables: [
        'job_titles',
        'countries',
        'frequencies',
        'job_descriptions',
        'rates',
        'contract_statuses',
        'contract_types',
        'company_types',
        'currencies',
        'company_currencies',
        'roles',
        'occurrences',
        'document_types',
        'adjustment_types',
        'template_tags',
        'attribute_types',
        'expense_categories',
        'payment_statuses',
        'document_request_types',
        'equipment_types',
        'contractor_types',
        'other_countries',
        'payment_classifications',
        'payment_providers',
        'time_off_types',
        'seniorities',
      ],
    },
    getConfig(token),
  )
}

export const getStaticDataByTable = (token, data) => {
  const table = data?.table
  const tables = Array.isArray(table) ? table : [table]

  return api.post('static/list', { tables }, getConfig(token))
}

export const impersonateUser = (token, data) => {
  return api.post('admin/impersonate/enter', data, getConfig(token))
}
export const leaveImpersonation = (token, data) => {
  return api.post('admin/impersonate/leave', data, getConfig(token))
}
export const getStates = (data) => api.post('static/states', data)

export const getCities = (data) => api.post('static/cities', data)

export const getCompanyInfo = (token) => {
  return api.get('company/info', getConfig(token))
}

export const getClientInfo = (token) => {
  return api.get('client/info', getConfig(token))
}

export const getContractorInfo = (token) => {
  return api.get('contractor/info?settings', getConfig(token))
}
export const getFullTimeContractFields = (token, data) => {
  const { country, ...rest } = data
  return api.post(`contract/fulltime/${country}/fields`, rest, getConfig(token))
}
export const getAdminFullTimeContractFields = (token, data) => {
  const { country } = data
  return api.get(`admin/contract/fulltime/${country}/fields`, getConfig(token))
}

export const getEmployeesListByPartner = (token) => {
  return api.get('contract/fulltime/partner/employees', getConfig(token))
}

export const addDocumentRequest = (token, data) => {
  return api.post('contract/document/request/add', data, getConfig(token))
}
export const adminAddDocumentRequest = (token, data) => {
  return api.post(
    'admin/contract/fulltime/document/request/add',
    data,
    getConfig(token),
  )
}
export const getEmployeeDocuments = (token) => {
  return api.get('contract/fulltime/requests', getConfig(token))
}
export const getEmployeeDocumentsAdmin = (token) => {
  return api.get('admin/contract/fulltime/requests', getConfig(token))
}

export const uploadEmployeeDocument = (token, data) => {
  const formData = new FormData()
  Object.keys(data).forEach((k) => {
    if (!isNill(data[k])) {
      formData.append(k, data[k])
    }
  })

  return api.post(
    'admin/contract/fulltime/document/upload',
    formData,
    getConfig(token, { 'Content-Type': 'multipart/form-data' }),
  )
}

export const downloadEmployeeDocument = (token, data) => {
  const { request_id: requestId } = data
  return api.post(
    'contract/fulltime/document/download',
    { request_id: requestId },
    getConfig(token, { responseType: 'blob' }),
  )
}
export const downloadEmployeeDocumentAdmin = (token, data) => {
  const { request_id: requestId } = data
  return api.post(
    'admin/contract/fulltime/document/download',
    { request_id: requestId },
    getConfig(token, { responseType: 'blob' }),
  )
}

export const updateCompanyInfo = (token, data) => {
  const formData = new FormData()
  Object.keys(data).forEach((k) => {
    if (!isNill(data[k])) {
      formData.append(k, data[k])
    }
  })
  return api.post(
    'company/update',
    formData,
    getConfig(token, { 'Content-Type': 'multipart/form-data' }),
  )
}

export const updateMemberProfile = (token, data) => {
  const formData = new FormData()
  Object.keys(data).forEach((k) => {
    if (!isNill(data[k])) {
      formData.append(k, data[k])
    }
  })
  return api.post(
    'client/update',
    formData,
    getConfig(token, { 'Content-Type': 'multipart/form-data' }),
  )
}

export const updateContractorProfile = (token, data) => {
  const formData = new FormData()
  Object.keys(data).forEach((k) => {
    if (!isNill(data[k])) {
      formData.append(k, data[k])
    }
  })
  return api.post(
    'contractor/update',
    formData,
    getConfig(token, { 'Content-Type': 'multipart/form-data' }),
  )
}

export const getUsers = (token, data) => {
  return api.post('company/users', data, getConfig(token))
}

export const requestKYC = (token) => {
  return api.get('veriff/create', getConfig(token))
}

export const approveKYB = (token, data) => {
  return api.post('admin/company/kyb/approve', data, getConfig(token))
}
export const rejectKYB = (token, data) => {
  return api.post('admin/company/kyb/reject', data, getConfig(token))
}

export const sendUserInvitation = (token, data) => {
  return api.post('client/invite/send', data, getConfig(token))
}

export const verifyInvitationToken = (token) => {
  return api.post('client/invite/verify', {}, getConfig(token))
}

export const confirmInvitation = (data) =>
  api.post('client/invite/confirm', data)

export const createContract = (token, data, type) => {
  switch (type) {
    case contractTypes.FIXED:
      return api.post('contract/fixed/create', data, getConfig(token))
    case contractTypes.PAYG:
      return api.post('contract/payg/create', data, getConfig(token))
    case contractTypes.MILESTONES:
      return api.post('contract/milestone/create', data, getConfig(token))
    case contractTypes.FULL_TIME:
      return api.post('contract/fulltime/create', data, getConfig(token))
    default:
      return () => {}
  }
}
export const createContractV2 = (token, data) => {
  switch (data?.type) {
    case contractTypes.FIXED:
      return api.post('contract/fixed/create', data, getConfig(token))
    case contractTypes.PAYG:
      return api.post('contract/payg/create', data, getConfig(token))
    case contractTypes.MILESTONES:
      return api.post('contract/milestone/create', data, getConfig(token))
    case contractTypes.FULL_TIME:
      return api.post('contract/fulltime/create', data, getConfig(token))
    default:
      return () => {}
  }
}

export const createDirectEmploymentContract = (token, data) => {
  return api.post('direct_employees/contracts', data, getConfig(token))
}

export const uploadAnnexe = (token, data) => {
  const formData = new FormData()
  Object.keys(data).forEach((k) => {
    if (!isNill(data[k])) {
      formData.append(k, data[k])
    }
  })
  return api.post('contract/annex/upload', formData, getConfig(token))
}
export const removeAnnexe = (token, data) => {
  return api.post('contract/annex/delete', data, getConfig(token))
}
export const createFulltimeContract = (token, data) => {
  return api.post('contract/fulltime/create', data, getConfig(token))
}

export const uploadFTContractExtraFile = (token, data) => {
  const formData = new FormData()
  Object.keys(data).forEach((k) => {
    if (!isNill(data[k])) {
      formData.append(k, data[k])
    }
  })

  return api.post(
    'contract/fulltime/file/upload',
    formData,
    getConfig(token, { 'Content-Type': 'multipart/form-data' }),
  )
}
export const uploadAdminFTContractExtraFile = (token, data) => {
  const formData = new FormData()
  Object.keys(data).forEach((k) => {
    if (!isNill(data[k])) {
      formData.append(k, data[k])
    }
  })

  return api.post(
    'admin/contract/fulltime/file/upload',
    formData,
    getConfig(token, { 'Content-Type': 'multipart/form-data' }),
  )
}

export const downloadFTContractExtraFile = (token, data) => {
  return api.post(
    'admin/contract/fulltime/file/download',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}

export const updateAdminFulltimeQuotation = (token, data) => {
  return api.post(
    '/admin/contract/fulltime/quote/update',
    data,
    getConfig(token),
  )
}

export const updateFulltimeQuotation = (token, data) => {
  return api.post('contract/fulltime/quote/update', data, getConfig(token))
}

export const generateAdminFulltimeQuotation = (token, data) => {
  return api.post(
    '/admin/contract/fulltime/quote/generate',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}

export const generateFulltimeQuotation = (token, data) => {
  return api.post(
    'contract/fulltime/quote/generate',
    data,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}

export const uploadContractFile = (token, data, extra) => {
  const formData = new FormData()
  Object.keys(data).forEach((k) => {
    if (!isNill(data[k])) {
      formData.append(k, data[k])
    }
  })
  return api.post(
    'contract/upload',
    formData,
    getConfig(token, { ...extra, 'Content-Type': 'multipart/form-data' }),
  )
}
export const getContractList = (token, { status, tag }) => {
  const qp = [
    Array.isArray(status) && status.length > 0 ? `statuses=${status}` : null,
    Array.isArray(tag) && tag.length ? `tags=${tag}` : null,
  ]
    .filter(Boolean)
    .join('&')

  return api.get(`contract/list${qp ? `?${qp}` : ''}`, getConfig(token))
}
export const addContractTag = (token, data) => {
  return api.post('contract/tag/add', data, getConfig(token))
}
export const deleteContractTag = (token, data) => {
  return api.post('contract/tag/delete', data, getConfig(token))
}

export const getContractDetail = (token, { id }) => {
  return api.get(`contract/${id}/details`, getConfig(token))
}
export const getTagsList = (token) => {
  return api.get('contract/tag/list', getConfig(token))
}
export const inviteContractPart = (token, data) => {
  return api.post('contract/invite', data, getConfig(token))
}
export const inviteFullTimeEmployee = (token, data) => {
  return api.post('contract/fulltime/employee/invite', data, getConfig(token))
}
export const signContract = (token, data) => {
  return api.post('contract/signature', data, getConfig(token))
}

export const getNGBanks = (token) => {
  return api.get('accounts/bank/NG/banks', getConfig(token))
}

export const getBankFields = (token, data) => {
  return api.post('accounts/bank/fields', data, getConfig(token))
}

export const addPayPalAccount = (token, data) => {
  return api.post('contractor/paypal/add', data, getConfig(token))
}

export const addPayoneerAccount = (token, data) => {
  return api.post('contractor/payoneer/add', data, getConfig(token))
}

export const deletePayPalAccount = (token, data) => {
  return api.get(`contractor/paypal/${data?.id}/delete`, getConfig(token))
}

export const deletePayoneerAccount = (token, data) => {
  return api.get(`contractor/payoneer/${data?.id}/delete`, getConfig(token))
}

export const deleteCoinbaseAccount = (token, data) => {
  return api.get(`contractor/coinbase/${data?.id}/delete`, getConfig(token))
}

export const getInvoicesList = (token, data) => {
  return api.post('invoice/list', data, getConfig(token))
}
export const getAdminInvoicesList = (token, data) => {
  return api.post('admin/contract/invoice/list', data, getConfig(token))
}
export const getUnpaidInvoicesList = (token) => {
  return api.get('invoice/list/unpaid', getConfig(token))
}
export const getInvoiceDetail = (token, id) => {
  return api.get(`invoice/${id}/details`, getConfig(token))
}
export const getOngoingContract = (token) => {
  return api.get('cron/contracts/ongoing', getConfig(token))
}
export const newPaymentMethod = (token) => {
  return api.get('payment/method/new', getConfig(token))
}
export const generateInvoice = (token, data) => {
  return api.post('cron/contracts/invoices', data, getConfig(token))
}
export const addPaymentMethod = (token, data) => {
  return api.post('payment/method/add', data, getConfig(token))
}
export const addContractorPaymentMethod = (token, data) => {
  return api.post('contractor/payment/method/add', data, getConfig(token))
}
export const getPaymentMethods = (token) => {
  return api.get('payment/method/list', getConfig(token))
}

export const getContractorPaymentMethods = (token) => {
  return api.get('contractor/payment/method/list', getConfig(token))
}

export const revertAdminEorClientInvoice = (token, data) => {
  return api.post('admin/eor/invoice/client/revert', data, getConfig(token))
}
export const getContractsInvoicesList = (token, data, extra) => {
  return api.post('admin/eor/invoice/list', data, getConfig(token, extra))
}
export const getGeneratedInvoicesList = (token, data, extra) => {
  return api.post(
    'admin/eor/invoice/generate/list',
    data,
    getConfig(token, extra),
  )
}
export const addAdminEorPartnerInvoice = (token, data) => {
  return api.post(
    'admin/eor/invoice/partner/add',
    data,
    getConfig(token, { 'Content-Type': 'multipart/form-data' }),
  )
}
export const updateAdminEorPartnerInvoice = (token, data) => {
  return api.post(
    'admin/eor/invoice/partner/update',
    data,
    getConfig(token, { 'Content-Type': 'multipart/form-data' }),
  )
}

export const getParntersInvoicesList = (token, data, extra) => {
  return api.post(
    'admin/eor/invoice/partner/list',
    data,
    getConfig(token, extra),
  )
}
export const uploadPartnerInvoiceBreakdown = (token, data) => {
  return api.post(
    'admin/eor/invoice/breakdown/upload',
    data,
    getConfig(token, { 'Content-Type': 'multipart/form-data' }),
  )
}
export const getPartnerInvoiceEmployees = (token, data) => {
  return api.post('admin/eor/invoice/partner/employees', data, getConfig(token))
}

export const dowloadEorInvoice = (token, data) => {
  return api.get(
    `admin/eor/invoice/download/${data?.id}`,
    getConfig(token, { responseType: 'blob' }),
  )
}

export const downloadGeneratedInvoice = (token, data) => {
  return api.post(
    'admin/eor/invoice/generate/download',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}
export const payPartnerInvoice = (token, data) => {
  return api.post(
    'admin/eor/invoice/partner/payment/confirm',
    data,
    getConfig(token),
  )
}
export const approveParnterInvoice = (token, data) => {
  return api.post('admin/eor/invoice/partner/approve', data, getConfig(token))
}
export const revertParnterInvoice = (token, data) => {
  return api.post('admin/eor/invoice/partner/revert', data, getConfig(token))
}
export const revertParnterInvoiceBreakdown = (token, data) => {
  return api.post(
    'admin/eor/invoice/partner/breakdown/revert',
    data,
    getConfig(token),
  )
}

export const previewContractInvoice = (token, data) => {
  return api.post(
    'admin/eor/invoice/preview' + `?page=${data?.page || 1}`,
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}
export const submitContractInvoice = (token, data) => {
  return api.post(
    'admin/eor/invoice/submit',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}

export const submitGeneratedInvoice = (token, data) => {
  return api.post(
    'admin/eor/invoice/generate',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}
export const getPartnerInvoiceDetails = (token, data) => {
  return api.post('admin/eor/invoice/partner/details', data, getConfig(token))
}

export const getTransactionList = (token, data, extra) => {
  return api.post(
    'admin/transaction/list' + `?page=${data?.page || 1}`,
    data,
    getConfig(token, extra),
  )
}

export const getAllPaymentList = (token, data, extra) => {
  return api.post(
    'admin/payment/list' + `?page=${data?.page || 1}`,
    data,
    getConfig(token, extra),
  )
}

export const getAdminUpcomingPaymentList = (token, data, extra) => {
  return api.post(
    'admin/payment/company/list/upcoming' + `?page=${data?.page || 1}`,
    data,
    getConfig(token, extra),
  )
}

export const getUnpaidPaymentList = (token, data, extra) => {
  return api.post(
    'admin/payment/company/list/unpaid' + `?page=${data?.page || 1}`,
    data,
    getConfig(token, extra),
  )
}

export const getPaymentListByCompany = (token, data, extra) => {
  return api.post(
    'admin/payment/company/list' + `?page=${data?.page || 1}`,
    data,
    getConfig(token, extra),
  )
}

export const getPaymentHistory = (token, data, extra) => {
  return api.post('transaction/list', data, getConfig(token, { ...extra }))
}

export const getUnpaidList = (token) => {
  return api.get('payment/unpaid?grouped=1', getConfig(token))
}

export const getPaymentsForReview = (token) => {
  return api.get('payment/approval/pending?grouped=1', getConfig(token))
}

export const approvePayments = (token, data) => {
  return api.post('payment/approve', data, getConfig(token))
}
export const declinePayments = (token, data) => {
  return api.post('payment/decline', data, getConfig(token))
}

export const getUpcomingPaymentList = (token) => {
  return api.get('payment/upcoming?grouped=1', getConfig(token))
}

export const payWithCC = (token, data) => {
  return api.post('payment/create/cc', data, getConfig(token))
}

export const payWithTransfer = (token, data) => {
  return api.post('payment/create/transfer', data, getConfig(token))
}

export const downloadInvoice = (ref, token, invoiceToken) => {
  return api.get(
    `invoice/download/${ref}/${invoiceToken}`,
    getConfig(token, { responseType: 'blob' }),
  )
}

export const downloadReceipt = (ref, token, invoiceToken) => {
  return api.get(
    `transaction/receipt/${ref}/${invoiceToken}`,
    getConfig(token, { responseType: 'blob' }),
  )
}

export const downloadWithdrawalReceipt = (ref, token, invoiceToken) => {
  return api.get(
    `transaction/withdrawal/receipt/${ref}/${invoiceToken}`,
    getConfig(token, { responseType: 'blob' }),
  )
}
export const downloadProviderReceipt = (token, data) => {
  return api.post(
    `admin/transfer/receipt`,
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}
export const downloadContract = (ref, token, contractToken, isAdmin) => {
  return api.get(
    isAdmin
      ? `admin/contract/download/${ref}/${contractToken}`
      : `contract/download/${ref}/${contractToken}`,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}
export const downloadAdminQuote = (token, data) => {
  return api.post(
    'admin/contract/fulltime/quote/download',
    data,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}

export const downloadQuote = (token, data) => {
  return api.post(
    'contract/fulltime/quote/download',
    data,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}
export const downloadContractPdf = (token, data) => {
  return api.get(
    `admin/contract/download/${data?.ref}/${data?.contractToken}`,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}
export const downloadEmployementContractPdf = (token, data) => {
  return api.post(
    'admin/contract/fulltime/employment/download',
    data,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}
export const showContract = (token, data) => {
  return api.get(
    `contract/show/${data?.ref}/${data?.contractToken}`,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}

export const getDocs = (token) => {
  return api.get('contractor/documents', getConfig(token))
}
export const getDocumentsUS = (token) =>
  api.get('contractor/document/residence/form', getConfig(token))
export const getTaxConditions = (token) =>
  api.get('contractor/document/residence/conditions', getConfig(token))
export const submitWForm = (token, data) =>
  api.post('contractor/document/residence/generate', data, getConfig(token))

export const addMilestone = (token, data) => {
  return api.post('contract/milestone/add', data, getConfig(token))
}

export const submitMilestone = (token, data) => {
  return api.post('contract/milestone/submit', data, getConfig(token))
}

export const submitWork = (token, data) => {
  return api.post('contract/payg/submit', data, getConfig(token))
}

export const addWork = (token, data) => {
  return api.post('contract/payg/add', data, getConfig(token))
}

export const approveMilestone = (token, data) => {
  return api.post('contract/milestone/approve', data, getConfig(token))
}

export const declineMilestone = (token, data) => {
  return api.post('contract/milestone/decline', data, getConfig(token))
}

export const approveWork = (token, data) => {
  return api.post('contract/payg/approve', data, getConfig(token))
}

export const declineWork = (token, data) => {
  return api.post('contract/payg/decline', data, getConfig(token))
}

export const createTransactionCC = (token, data) => {
  return api.post('transaction/cc/create', data, getConfig(token))
}
export const createTransactionSepa = (token, data) => {
  return api.post('transaction/sepa/create', data, getConfig(token))
}

export const createTransactionTransfer = (token, data) => {
  return api.post('transaction/transfer/create', data, getConfig(token))
}

export const createTransactionACH = (token, data) => {
  return api.post('/transaction/ach/create', data, getConfig(token))
}

export const getContractPayment = (token, { id }) => {
  return api.get(`contract/${id}/payments`, getConfig(token))
}
export const getAdminContractPayment = (token, { id }) => {
  return api.post(
    'admin/contract/payment/list',
    { contract_id: id },
    getConfig(token),
  )
}

export const getOverview = (token) => {
  return api.get('contract/overview', getConfig(token))
}

export const getEarnings = (token) => {
  return api.get('contractor/earnings', getConfig(token))
}

export const getPayments = (token) => {
  return api.get('client/payments', getConfig(token))
}
export const getRecentContracts = (token) => {
  return api.get('widgets/recent_contracts', getConfig(token))
}
export const getUpcomingPayments = (token) => {
  return api.get('widgets/upcoming_payments', getConfig(token))
}
export const getOutstandingPayments = (token) => {
  return api.get('widgets/outstanding_payments', getConfig(token))
}
export const getCurrentMonthPayments = (token) => {
  return api.get('widgets/current_month_payments', getConfig(token))
}
export const getPendingApprovalPayments = (token) => {
  return api.get('widgets/pending_approval_payments', getConfig(token))
}
export const getBirthdays = (token) => {
  return api.get('widgets/upcoming_birthdays', getConfig(token))
}
export const getContractorsByCountry = (token) => {
  return api.get('client/contractors', getConfig(token))
}

export const getUpcomingBirthdays = (token) => {
  return api.get('client/birthdays', getConfig(token))
}

export const getActivity = (token) => {
  return api.get('contract/activity', getConfig(token))
}

export const getTransactionRef = (token, data) => {
  return api.post('transaction/prepare?grouped=1', data, getConfig(token))
}

export const confirmTransaction = (token, data) => {
  return api.post('admin/transaction/confirm', data, getConfig(token))
}
export const revertTransaction = (token, data) => {
  return api.post('admin/transaction/withdraw/revert', data, getConfig(token))
}
export const manualProcessTransaction = (token, data) => {
  return api.post('admin/transfer/process/manual', data, getConfig(token))
}
export const revertPaymentTransaction = (token, data) => {
  return api.post('admin/transaction/payment/revert', data, getConfig(token))
}
export const reviewTransaction = (token, data) => {
  return api.post('admin/transaction/compliance/status', data, getConfig(token))
}
export const archiveTransaction = (token, data) => {
  return api.post('admin/transaction/archive', data, getConfig(token))
}
export const createZendeskTicket = (token, data) => {
  return api.post(
    'admin/transaction/withdraw/ticket/send',
    data,
    getConfig(token),
  )
}
export const setPreferedProvider = (token, data) => {
  return api.post('admin/transaction/processor/update', data, getConfig(token))
}
export const unprocessWithdraw = (token, data) => {
  return api.post(
    'admin/transaction/withdraw/unprocess',
    data,
    getConfig(token),
  )
}

export const prepareTransaction = (token, data) => {
  return api.post('transaction/transfer/prepare', data, getConfig(token))
}
export const transferTransaction = (token, data) => {
  return api.post('admin/transfer/confirm', data, getConfig(token))
}

export const getTransferList = (token, data, extra) => {
  return api.post(
    'admin/transfer/list' + `?page=${data?.page || 1}`,
    data,
    getConfig(token, extra),
  )
}

export const getNotificationList = (token) => {
  return api.get('notifications', getConfig(token))
}

export const getContractorWallet = (token) => {
  return api.get('contractor/wallet', getConfig(token))
}

export const prepareWithdraw = (token, data) => {
  return api.post('contractor/withdraw/prepare', data, getConfig(token))
}

export const getPayoneerLink = (token, data) => {
  return api.post('contractor/payoneer/link', data, getConfig(token))
}

export const savePayoneerAccount = (token, data) => {
  return api.post('contractor/payoneer/save', data, getConfig(token))
}

export const deletePayoneerMethod = (token, data) => {
  if (!data?.id) return () => {}

  return api.get('contractor/payoneer/deactivate/' + data?.id, getConfig(token))
}

export const activatePayoneerAccount = (token, data) => {
  return api.post('contractor/payoneer/activate', data, getConfig(token))
}

export const createWithdraw = (token, data) => {
  return api.post('contractor/withdraw/create', data, getConfig(token))
}

export const getContractors = (token) => {
  return api.get('company/contractors', getConfig(token))
}

export const deleteCC = (token, data) => {
  return api.get(`payment/method/${data?.id}/delete`, getConfig(token))
}

export const deleteContractorCC = (token, data) => {
  return api.get(
    `contractor/payment/method/${data?.id}/delete`,
    getConfig(token),
  )
}

export const getMethods = (token, data) => {
  return api.post('transaction/methods', data, getConfig(token))
}

export const markNotificationAsRead = (token, data) => {
  return api.post('notification/global/read', data, getConfig(token))
}

export const markAllNotificationAsRead = (token, data) => {
  return api.post('notifications/read', data, getConfig(token))
}

export const cancelInvitation = (token, data) => {
  return api.post('contract/cancel/invitation', data, getConfig(token))
}

export const downloadDoc = (token, data) => {
  return api.get(
    `company/document/${data?.id}/image`,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}

export const getContractDocs = (token, data) => {
  return api.get(`contract/${data?.id}/documents`, getConfig(token))
}

export const downloadContractDoc = (token, data) => {
  return api.get(
    `contract/${data?.id}/document/${data?.token}`,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}

export const getContractorDocs = (token, data) => {
  return api.get(`company/documents/${data?.token}`, getConfig(token))
}

export const changeUserStatus = (token, data) => {
  return api.post('client/status', data, getConfig(token))
}
export const terminateContract = (token, data) => {
  return api.post('/contract/terminate', data, getConfig(token))
}

export const changePassword = (token, data) => {
  return api.post('password/change', data, getConfig(token))
}
export const confirmStripe = (token, data) => {
  return api.post('/transaction/cc/verify', data, getConfig(token))
}

export const confirmStripeForInsurance = (token, data) => {
  return api.post('/insurance/transaction/cc/verify', data, getConfig(token))
}

export const updateProrata = (token, data) => {
  return api.post('contract/fixed/prorata', data, getConfig(token))
}

export const getUsersListAdmin = (token, data, extra) => {
  return api.post('admin/users/list', data, getConfig(token, extra))
}
export const getBalancesAdmin = (token) => {
  return api.get('admin/company/balance/list', getConfig(token))
}
export const getAdminCompanies = (token) => {
  return api.get('admin/company/clients', getConfig(token))
}
export const deleteBalanceAdmin = (token, data) => {
  return api.post('admin/company/balance/delete', data, getConfig(token))
}
export const createBalanceAdmin = (token, data) => {
  return api.post('admin/company/balance/add', data, getConfig(token))
}
export const getCompaniesListAdmin = (token, data, extra) => {
  return api.post(
    'admin/company/list' + `?page=${data?.page}`,
    data,
    getConfig(token, extra),
  )
}

export const updateCompanyAdmin = (token, data, extra) => {
  return api.post('admin/company/update', data, getConfig(token, extra))
}
export const updateCompanyPerks = (token, data) => {
  const { id, ...rest } = data
  return api.post(`admin/company/${id}/perks`, rest, getConfig(token))
}

export const getContractsListAdmin = (token, data, extra) => {
  return api.post(
    'admin/contract/adminlist' + `?page=${data?.page || 1}`,
    data,
    getConfig(token, extra),
  )
}

export const getContractDetailAdmin = (token, data) => {
  return api.get(`admin/contract/${data.id}/details`, getConfig(token))
}

export const deleteContractorDocument = (token, data) => {
  return api.get(
    `contractor/document/residence/delete/${data?.token}`,
    getConfig(token),
  )
}

export const connectPaypal = (token, data) => {
  return api.post('contractor/paypal/connect', data, getConfig(token))
}

export const verifyPaypal = (token, data) => {
  return api.post('contractor/paypal/verify', data, getConfig(token))
}
export const connectCoinbase = (token, data) => {
  return api.post('contractor/coinbase/connect', data, getConfig(token))
}

export const verifyCoinbase = (token, data) => {
  return api.post('contractor/coinbase/verify', data, getConfig(token))
}

export const whitelistEmail = (token, data) => {
  return api.post('admin/contractor/email/whitelist', data, getConfig(token))
}

export const uploadContractorDoc = (token, data) => {
  const formData = new FormData()
  Object.keys(data).forEach((k) => {
    if (!isNill(data[k])) {
      formData.append(k, data[k])
    }
  })
  return api.post(
    'contractor/document/residence/upload',
    formData,
    getConfig(token, { 'Content-Type': 'multipart/form-data' }),
  )
}

export const getDocsList = (token) => {
  return api.get('contractor/document/residence/list', getConfig(token))
}

export const downloadContractorDoc = (token, data) => {
  return api.get(
    `contractor/document/residence/download/${data?.token}`,
    getConfig(token, { responseType: 'blob' }),
  )
}

export const changeUserRole = (token, data) => {
  return api.post('client/role', data, getConfig(token))
}

export const updateContract = (token, data) => {
  return api.post('contract/update', data, getConfig(token))
}
export const updateFTContract = (token, data) => {
  return api.post('admin/contract/fulltime/update', data, getConfig(token))
}
export const terminateEORContract = (token, data) => {
  return api.post('admin/contract/fulltime/terminate', data, getConfig(token))
}

export const uploadFTContract = (token, data) => {
  const formData = new FormData()
  Object.keys(data).forEach((k) => {
    if (!isNill(data[k])) {
      formData.append(k, data[k])
    }
  })
  return api.post(
    'admin/contract/fulltime/update',
    formData,
    getConfig(token, { 'Content-Type': 'multipart/form-data' }),
  )
}

export const amendContract = (token, data) => {
  return api.post('contract/amendment/add', data, getConfig(token))
}
export const getOccurrences = (token, data) => {
  return api.post('static/occurrences', data, getConfig(token))
}
export const connectQuickbooks = (token, data) => {
  return data
    ? api.get(
        `integration/qb/connect?code=${data?.code}&realmId=${data?.realmId}`,
        getConfig(token),
      )
    : api.get('integration/qb/connect', getConfig(token))
}
export const getQuickbooksConnection = (token) => {
  return api.get('integration/qb/complete', getConfig(token))
}
export const getFreshbooksConnection = (token) => {
  return api.get('integration/fresh/complete', getConfig(token))
}
export const getZohoConnection = (token, data) => {
  return api.post('integration/zoho/complete', data, getConfig(token))
}
export const disconnectQuickbooks = (token) => {
  return api.post('integrations/qb/disconnect', {}, getConfig(token))
}

export const getQuickbooksToken = (token, data) => {
  return api.post('integration/qb/connect', data, getConfig(token))
}

export const getQuickbooksAccounts = (token, data) => {
  return api.post('integrations/qb/accounts', data, getConfig(token))
}

export const selectQuickbooksAccount = (token, data) => {
  return api.post('integrations/qb/accounts/select', data, getConfig(token))
}
export const updateUserPermissions = (token, data) => {
  return api.post('client/permission', data, getConfig(token))
}
export const getSignatoryList = (token) => {
  return api.get('contract/signatory/list', getConfig(token))
}

export const assignContractToSignatory = (token, data) => {
  return api.post('contract/signatory/assign', data, getConfig(token))
}

export const cancelSignatoryInvitation = (token, data) => {
  return api.post('contract/signatory/cancel', data, getConfig(token))
}

export const archiveContract = (token, data) => {
  return api.post('/contract/archive', data, getConfig(token))
}
export const archiveAdminContract = (token, data) => {
  return api.post('admin/contract/archive', data, getConfig(token))
}

export const getArchivedContracts = (token) => {
  return api.get('contract/list/archived', getConfig(token))
}

export const deleteBankAccount = (token, data) => {
  return api.get(`contractor/bank/${data?.id}/delete`, getConfig(token))
}

export const checkTokenUpdatePass = (token, data) => {
  return api.post('client/invite/verify', data, getConfig(token))
}

export const getTransactionDetails = (token, data) => {
  return api.get(`transaction/${data?.id}/details`, getConfig(token))
}

export const autoWithdraw = (token, data) => {
  return api.post('admin/withdraw/action', data, getConfig(token))
}
export const multipleAutoWithdraw = (token, data) => {
  return api.post('admin/withdraw/action/bulk', data, getConfig(token))
}
export const archiveUser = (token, data) => {
  return api.post('admin/users/archive', data, getConfig(token))
}
export const deactivateUser = (token, data) => {
  return api.post('admin/users/deactivate', data, getConfig(token))
}
export const flagUser = (token, data) => {
  return api.post('admin/users/flag', data, getConfig(token))
}
export const unflagUser = (token, data) => {
  return api.post('admin/users/unflag', data, getConfig(token))
}
export const disableAdmin2fa = (token, data) => {
  return api.post('admin/users/2fa/disable', data, getConfig(token))
}
export const adminChangeEmail = (token, data) => {
  return api.post('admin/users/email/change', data, getConfig(token))
}
export const getAdminUserDetails = (token, data) => {
  return api.get(`admin/user/info/${data.id}`, getConfig(token))
}
export const getAdminCompanyDetails = (token, data) => {
  return api.get(`admin/company/details/${data.id}`, getConfig(token))
}
export const verifyIdwiseJourney = (token, data) => {
  return api.post('contractor/idwise/verify', data, getConfig(token))
}
export const createIdwiseSession = (token, data) => {
  return api.post('contractor/idwise/create', data, getConfig(token))
}

export const submitKYCVerification = (token, data) => {
  return api.post(
    'contractor/kyc/submit',
    data,
    getConfig(token, { 'Content-Type': 'multipart/form-data' }),
  )
}
export const getKYCSubmissions = (token, data) => {
  return api.post('admin/contractor/kyc/list', data, getConfig(token))
}
export const getBankAccounts = (token, data) => {
  const sp = searchParamsFromObject({ page: data?.page })

  return api.post('admin/bankaccounts/list' + sp, data, getConfig(token))
}
export const bankNameVerification = (token, data) => {
  return api.post('admin/bankaccounts/namematches', data, getConfig(token))
}
export const screenBankAccount = (token, data) => {
  return api.post('admin/bankaccounts/screening', data, getConfig(token))
}
export const screenCompany = (token, data) => {
  return api.post('admin/company/client/screening', data, getConfig(token))
}
export const screenContractorEntity = (token, data) => {
  return api.post('admin/company/contractor/screening', data, getConfig(token))
}
export const archiveBankAccount = (token, data) => {
  return api.post('admin/bankaccounts/archive', data, getConfig(token))
}
export const getUserKYCSubmission = (token, data) => {
  return api.post('admin/contractor/kyc/details', data, getConfig(token))
}
export const approveKYCSubmission = (token, data) => {
  return api.post('admin/contractor/kyc/approve', data, getConfig(token))
}
export const rejectKYCSubmission = (token, data) => {
  return api.post('admin/contractor/kyc/reject', data, getConfig(token))
}
export const screenUserKYCSubmission = (token, data) => {
  return api.post('admin/contractor/kyc/screening', data, getConfig(token))
}

export const resendInvite = (token, data) => {
  return api.post('client/invite/resend', data, getConfig(token))
}

export const getWithdrawList = (token) => {
  return api.get('admin/withdraw/list', getConfig(token))
}
export const getOCR = (token, data) => {
  return api.post(
    'https://vision.googleapis.com/v1/images:annotate?key=AIzaSyCJxpoDnrnRUiX12trB-mDkNtNnk0ZQ0iE',
    data,
  )
}

export const cancelContract = (token, data) => {
  return api.post('contract/cancel', data, getConfig(token))
}
export const adjustPayment = (token, data) => {
  return api.post('payment/adjustment/add', data, getConfig(token))
}
export const deleteWork = (token, data) => {
  return api.post('contract/work/delete', data, getConfig(token))
}
export const switchApi = (token) => {
  return api.post('/profile/switch', {}, getConfig(token))
}
export const cancelAmendContract = (token, data) => {
  return api.post('contract/amendment/cancel', data, getConfig(token))
}
export const getTrxDetails = (token, data) => {
  return api.get(`contractor/withdraw/details/${data?.id}`, getConfig(token))
}
export const exportTransactions = (token, data) => {
  return api.post(
    'transaction/export',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}

export const exportQuote = (token, data) => {
  return api.post(
    'eor/quote/export',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}
export const adminExportQuote = (token, data) => {
  return api.post(
    'admin/eor/quote/export',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}

export const exportStatement = (token, data) => {
  return api.post(
    'transaction/statement',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}
export const googleLogin = (token, data) => {
  return api.post('google/login', data, getConfig(token))
}
export const withdrawCurrencies = (token, data) => {
  return api.get(
    `withdraw/currencies/${data.currency || 'USD'}`,
    getConfig(token),
  )
}
export const getAlfardanBanks = (token, data) => {
  return api.get(`withdraw/${data?.country}/banks`, getConfig(token))
}

export const createWithdrawAccount = (token, data) => {
  return api.post('accounts/bank/create', data, getConfig(token))
}
export const exportAlfardanTrx = (token) => {
  return api.get(
    'admin/alfardan/export',
    getConfig(token, { responseType: 'blob' }),
  )
}
export const exportRMPTrx = (token) => {
  return api.get(
    'admin/remotepass/export',
    getConfig(token, { responseType: 'blob' }),
  )
}
export const exportTrx = (token) => {
  return api.get(
    'admin/transactions/export',
    getConfig(token, { responseType: 'blob' }),
  )
}
export const exportTWTrx = (token) => {
  return api.get(
    'admin/transferwise/export',
    getConfig(token, { responseType: 'blob' }),
  )
}
export const getInvoicesMonths = (token) => {
  return api.get('/invoice/months', getConfig(token))
}

export const getInvoicesYears = (token) => {
  return api.get('invoice/years', getConfig(token))
}

export const getTransactionsMonths = (token) => {
  return api.get('/transaction/months', getConfig(token))
}
export const getAdminTemplate = (token, data) => {
  return api.post('admin/template/edit', data, getConfig(token))
}
export const getAdminTemplateList = (token) => {
  return api.get('admin/template/list', getConfig(token))
}
export const getContractTemplate = (token, data) => {
  return api.post('template/edit', data, getConfig(token))
}
export const getAdminContractTemplate = (token, data) => {
  return api.post('admin/tpl/edit', data, getConfig(token))
}
export const getTemplateList = (token) => {
  return api.get('template/list', getConfig(token))
}
export const updateContractTemplateAdmin = (token, data) => {
  return api.post('admin/tpl/update', data, getConfig(token))
}
export const updateTemplateAdmin = (token, data) => {
  return api.post('admin/template/update', data, getConfig(token))
}
export const deleteTemplateAdmin = (token, data) => {
  return api.post('admin/template/delete', data, getConfig(token))
}
export const updateTemplateAdminStatus = (token, data) => {
  return api.post('admin/template/update/status', data, getConfig(token))
}
export const createTemplateAdmin = (token, data) => {
  return api.post('admin/template/create', data, getConfig(token))
}
export const updateContractTemplate = (token, data) => {
  return api.post('template/update', data, getConfig(token))
}
export const segmentIdentify = (data) => {
  return api.post('https://api.segment.io/v1/identify', data, {
    headers: {
      Authorization: `Basic ${process.env.REACT_APP_SEGMENT_WRITE_KEY}`,
    },
  })
}
export const segmentTrack = (data) => {
  return api.post('https://api.segment.io/v1/track', data, {
    headers: {
      Authorization: `Basic ${process.env.REACT_APP_SEGMENT_WRITE_KEY}`,
    },
  })
}
export const segmentBatch = (data) => {
  return api.post('https://api.segment.io/v1/batch', data, {
    headers: {
      Authorization: `Basic ${process.env.REACT_APP_SEGMENT_WRITE_KEY}`,
    },
  })
}
export const getDocuSignUrl = (token, data) => {
  return api.get(`contract/${data?.ref}/docusign`, getConfig(token))
}
export const getCustomFields = (token) => {
  return api.get('company/attribute/list', getConfig(token))
}

export const getContractAttribute = (token, data) => {
  return api.post('contract/payg/attributes', data, getConfig(token))
}

export const addAttribute = (token, data) => {
  return api.post('company/attribute/add', data, getConfig(token))
}
export const removeAttribute = (token, data) => {
  return api.post('company/attribute/delete', data, getConfig(token))
}
export const updateAttribute = (token, data) => {
  return api.post('company/attribute/update', data, getConfig(token))
}
export const getTimesOff = (token, data) => {
  return api.get(`contract/timeoff/list/${data?.contractId}`, getConfig(token))
}
export const getAdminTimesOff = (token, data) => {
  return api.post(
    'admin/contract/timeoff/list',
    { contract_id: data?.contractId },
    getConfig(token),
  )
}
export const getCycles = (token, data) => {
  return api.post('contract/timeoff/cycles', data, getConfig(token))
}
export const addTimeOff = (token, data) => {
  return api.post('contract/timeoff/add', data, getConfig(token))
}
export const approveTimeOff = (token, data) => {
  return api.post('contract/timeoff/approve', data, getConfig(token))
}
export const rejectTimeOff = (token, data) => {
  return api.post('contract/timeoff/reject', data, getConfig(token))
}
export const deleteTimeOff = (token, data) => {
  return api.post('contract/timeoff/delete', data, getConfig(token))
}
export const getExpenses = (token, data) => {
  return api.post('contract/expense/list', data, getConfig(token))
}
export const getAdminExpenses = (token, data) => {
  return api.post('admin/contract/expense/list', data, getConfig(token))
}
export const addExpense = (token, data) => {
  return api.post('contract/expense/add', data, getConfig(token))
}
export const uploadExpense = (token, data) => {
  const formData = new FormData()
  formData.append('photo', data?.photo)
  return api.post('contract/expense/upload', formData, getConfig(token))
}
export const revertExpense = (token, data) => {
  return api.post('contract/expense/revert', data, getConfig(token))
}
export const approveExpense = (token, data) => {
  return api.post('contract/expense/approve', data, getConfig(token))
}
export const rejectExpense = (token, data) => {
  return api.post('contract/expense/reject', data, getConfig(token))
}
export const deleteExpense = (token, data) => {
  return api.post('contract/expense/delete', data, getConfig(token))
}
export const createEntity = (token, data) => {
  return api.post('entity/create', data, getConfig(token))
}
export const switchEntity = (token, data) => {
  return api.post('entity/switch', data, getConfig(token))
}
export const getExpensesMonths = (token, data) => {
  return api.post('contract/expense/months', data, getConfig(token))
}
export const getAdminExpensesMonths = (token, data) => {
  return api.post('admin/contract/expense/months', data, getConfig(token))
}
export const updateContractorType = (token, data) => {
  return api.post('contractor/type', data, getConfig(token))
}
export const request2fa = (token, data) => {
  return api.post('2fa/request', data, getConfig(token))
}
export const enable2fa = (token, data) => {
  return api.post('2fa/enable', data, getConfig(token))
}
export const disable2fa = (token, data) => {
  return api.post('2fa/disable', data, getConfig(token))
}
export const check2fa = (token, data) => {
  return api.post('checkpoint', data, getConfig(token))
}
export const sendTfaEmailCode = (token, data) => {
  return api.post('2fa/email/send', data, getConfig(token))
}
export const verifyTfaEmail = (token, data) => {
  return api.post('2fa/email/verify', data, getConfig(token))
}

export const downloadAllInvoices = (token, data) => {
  return api.post(
    'invoice/download/multi',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}
export const exportWithdraw = (token, data) => {
  return api.post(
    'admin/withdraws/export',
    data,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}
// export Transactions
export const adminExportTransactions = (token, data) => {
  return api.post(
    'admin/export/transactions',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}
// export Contractors balances
export const adminExportContractorBalances = (token, data) => {
  return api.post(
    'admin/export/contractor/wallets',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}
// export Balances per contractor
export const adminExportContractorBalance = (token, data) => {
  return api.post(
    'admin/export/contractor/wallets/activities',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}
// export Client payments balances
export const adminExportClientPaymentBalances = (token, data) => {
  return api.post(
    'admin/export/client/payments/balances',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}
// export Client payments breakdown
export const adminExportClientPaymentBreakdowns = (token, data) => {
  return api.post(
    'admin/export/client/payments/breakdown',
    data,
    getConfig(token, { responseType: 'blob' }),
  )
}

export const archiveCompany = (token, data) => {
  return api.post('admin/company/archive', data, getConfig(token))
}

export const approveCompany = (token, data) => {
  return api.post('admin/company/approve', data, getConfig(token))
}
export const unArchiveCompany = (token, data) => {
  return api.post('admin/company/unarchive', data, getConfig(token))
}
export const cancelPayment = (token, data) => {
  return api.post('payment/cancel', data, getConfig(token))
}
export const revertCancelledPayment = (token, data) => {
  return api.post('payment/revert', data, getConfig(token))
}

export const parseInvoiceV2 = (token, data) => {
  return apiV2.post('parserai/api/parse/invoice', data, getConfigV2(token))
}

export const parseExpenseV2 = (token, data) => {
  return apiV2.post('parserai/api/parse/expense', data, getConfigV2(token))
}

export const getTokenInvoice = (token) => {
  return api.get('invoiceparser/token/generate', getConfig(token))
}
export const getAdminTokenInvoice = (token) => {
  return api.get('admin/invoiceparser/token/generate', getConfig(token))
}
export const resendContractInvite = (token, data) => {
  return api.post('contract/invite/resend', data, getConfig(token))
}
export const uploadYourInvoice = (token, data) => {
  const formData = new FormData()
  Object.keys(data).forEach((k) => {
    if (!isNill(data[k])) {
      formData.append(k, data[k])
    }
  })
  return api.post(
    'invoice/upload',
    formData,
    getConfig(token, { 'Content-Type': 'multipart/form-data' }),
  )
}

export const uploadOfferLetter = (token, data) => {
  const formData = new FormData()
  formData.append('file', data?.file)
  return api.post(
    'contract/fulltime/offerletter/upload',
    formData,
    getConfig(token, { 'Content-Type': 'multipart/form-data' }),
  )
}
export const uploadOfferLetterAdmin = (token, data) => {
  const formData = new FormData()
  formData.append('file', data?.file)
  return api.post(
    'admin/contract/fulltime/offerletter/upload',
    formData,
    getConfig(token, { 'Content-Type': 'multipart/form-data' }),
  )
}
export const getToDoList = (token) => {
  return api.get('todo', getConfig(token))
}
export const getClientToDoList = (token) => {
  return api.get('widgets/todo', getConfig(token))
}
export const getCalendarData = (token, data) => {
  return api.post('calendar', data, getConfig(token))
}

export const cancelFullTimeInvitation = (token, data) => {
  return api.post(
    'contract/fulltime/employee/invitation/cancel',
    data,
    getConfig(token),
  )
}

export const submitFullTimeExpense = (token, data) => {
  return api.post('/contract/fulltime/expense/submit', data, getConfig(token))
}

export const getFullTimeExpenses = (token, data) => {
  return api.post('contract/fulltime/expense/list', data, getConfig(token))
}

export const getFullTimeExpensesMoths = (token) => {
  return api.get('contract/fulltime/expense/months', getConfig(token))
}

export const getFullTimeTimeOffs = (token) => {
  return api.get('contract/fulltime/timeoff/list', getConfig(token))
}

export const getFullTimeContractDetails = (token) => {
  return api.get('contract/fulltime/employee/details', getConfig(token))
}

export const submitFullTimeTimeOff = (token, data) => {
  return api.post('contract/fulltime/timeoff/submit', data, getConfig(token))
}

export const uploadPayslip = (token, data) => {
  const formData = new FormData()
  Object.keys(data).forEach((k) => {
    if (!isNill(data[k])) {
      formData.append(k, data[k])
    }
  })
  return api.post(
    'contract/payslips/upload',
    formData,
    getConfig(token, { 'Content-Type': 'multipart/form-data' }),
  )
}

export const addPayslip = (token, data) => {
  return api.post('contract/payslips/add', data, getConfig(token))
}

export const getPayslipsList = (token, data) => {
  return api.post('contract/payslips/list', data, getConfig(token))
}

export const downloadPayslip = (token, data) => {
  return api.post(
    'contract/payslips/download',
    data,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}
export const cancelEORRequest = (token, data) => {
  return api.post('admin/contract/eor/cancel', data, getConfig(token))
}
export const getFullTimeReqList = (token, data, extra) => {
  return api.post('admin/contract/eor/list', data, getConfig(token, extra))
}

export const sendFullTimeRequestt = (token, data, extra) => {
  const formData = new FormData()
  Object.keys(data).forEach((k) => {
    if (!isNill(data[k])) {
      formData.append(k, data[k])
    }
  })
  return api.post(
    'admin/contract/fulltime/request/send',
    formData,
    getConfig(token, { ...extra, 'Content-Type': 'multipart/form-data' }),
  )
}

export const inviteClientFulltime = (token, data) => {
  return api.post(
    'admin/contract/fulltime/client/invite',
    data,
    getConfig(token),
  )
}

export const CancelFulltimeRequest = (token, data) => {
  return api.post(
    'admin/contract/fulltime/request/archive',
    data,
    getConfig(token),
  )
}

export const CreateNewPartner = (token, data) => {
  return api.post(
    'admin/contract/fulltime/partner/create',
    data,
    getConfig(token),
  )
}
export const AssignPartnerToFT = (token, data) => {
  return api.post(
    'admin/contract/fulltime/partner/assign',
    data,
    getConfig(token),
  )
}
export const GetPartners = (token) => {
  return api.get('admin/contract/fulltime/partner/list', getConfig(token))
}
export const setupSepa = (token) => {
  return api.get('payment/method/sepa/setup', getConfig(token))
}
export const getPlaidToken = (token) => {
  return api.get('payment/method/ach/generate', getConfig(token))
}
export const setupAch = (token, data) => {
  return api.post('payment/method/ach/setup', data, getConfig(token))
}
export const getEnabledMethods = (token) => {
  return api.get('payment/method/list/enabled', getConfig(token))
}
export const addEquipment = (token, data) => {
  return api.post('contract/equipment/add', data, getConfig(token))
}
export const signEquipment = (token, data) => {
  return api.post('contract/equipment/signature', data, getConfig(token))
}

export const updateEquipment = (token, data) => {
  return api.post('contract/equipment/update', data, getConfig(token))
}
export const editAgreement = (token, data) => {
  return api.post('contract/equipment/agreement/edit', data, getConfig(token))
}
export const equipmentList = (token, data) => {
  return api.post('contract/equipment/list', data, getConfig(token))
}

export const returnEquipment = (token, data) => {
  return api.post('contract/equipment/return', data, getConfig(token))
}

export const deleteEquipment = (token, data) => {
  return api.post('contract/equipment/delete', data, getConfig(token))
}

export const getAgreement = (token, data) => {
  return api.post(
    'contract/equipment/agreement',
    data,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}
export const getContractorAgreement = (token, data) => {
  return api.get(
    `contract/equipment/show/${data?.id}`,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}

export const downloadAgreement = (token, data) => {
  return api.get(
    `contract/equipment/download/${data?.id}`,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}

export const downloadOfferLetter = (token, data) => {
  return api.post(
    'admin/offerletter/download',
    data,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}
export const downloadFullTimeOfferLetter = (token, data) => {
  return api.post(
    'contract/fulltime/offerletter/download',
    data,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}
export const downloadFullTimeOfferLetterAdmin = (token, data) => {
  return api.post(
    'admin/contract/fulltime/offerletter/download',
    data,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}
export const uploadEquipmentFile = (token, data) => {
  const formData = new FormData()
  formData.append('photo', data?.photo)
  return api.post('contract/equipment/upload', formData, getConfig(token))
}
export const requestDoc = (token, data) => {
  return api.post(
    'contract/document/client/request/submit',
    data,
    getConfig(token),
  )
}

export const removeRequestDoc = (token, data) => {
  return api.post(
    'contract/document/client/request/delete',
    data,
    getConfig(token),
  )
}

export const downloadFile = (token, link) => {
  return api.get(
    link,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}
export const getLocalCurrencies = (token) => {
  return api.get('static/currencies', getConfig(token))
}
export const checkContractInvitation = (token, data) => {
  return api.get(
    `contract/invitation?token=${data?.token}&contract_id=${data?.contract_id}`,
    getConfig(token),
  )
}
export const customizeCompany = (token, data) => {
  const formData = new FormData()
  Object.keys(data).forEach((k) => {
    if (!isNill(data[k])) {
      formData.append(k, data[k])
    }
  })
  return api.post('company/personalization/update', formData, getConfig(token))
}
export const checkDomain = (token, data) => {
  return api.post('domain', data, getConfig(token))
}

export const processWithdraw = (token, data) => {
  return api.post('admin/transfer/process', data, getConfig(token))
}
export const multipleProcessWithdraw = (token, data) => {
  return api.post('admin/transfer/process/bulk', data, getConfig(token))
}
export const invitationHistory = (token, data) => {
  return api.post('admin/notifications/history', data, getConfig(token))
}
export const getCalculatorResult = (token, data) => {
  return api.post('eor/quote', data, getConfig(token))
}
export const updateCalculatorTexts = (token, data) => {
  return api.post('eor/taxes', data, getConfig(token))
}
export const getEORTaxes = (token, data) => {
  return api.get(`eor/taxes/${data.countryCode}`, getConfig(token))
}

export const getAdminCalculatorResult = (token, data) => {
  return api.post('admin/eor/quote', data, getConfig(token))
}
export const updateAdminCalculatorTexts = (token, data) => {
  return api.post('admin/eor/taxes', data, getConfig(token))
}
export const getAdminEORTaxes = (token, data) => {
  return api.get(`admin/eor/taxes/${data.countryCode}`, getConfig(token))
}

const CURRENCY_CONVERTER_API_KEY = 'dd44ba8883984d0f9593e4d5a25e6295'

const CACHED_RESULTS = new Map()

export const getCurrencyConversionFactor = async (fromCurrency, toCurrency) => {
  try {
    if (fromCurrency === toCurrency) return Promise.resolve(1)
    const memoKey = `${fromCurrency}-${toCurrency}`
    if (CACHED_RESULTS.has(memoKey)) return CACHED_RESULTS.get(memoKey)

    const query = fromCurrency + '_' + toCurrency
    const url =
      'https://api.currconv.com/api/v7/convert?q=' +
      query +
      '&compact=ultra&apiKey=' +
      CURRENCY_CONVERTER_API_KEY

    if (process.env.REACT_APP_ENV === 'test') {
      const result = Math.random()
      CACHED_RESULTS.set(memoKey, result)
      return Promise.resolve(result)
    } else {
      return api.get(url).then((res) => {
        const result = res.data[query]
        CACHED_RESULTS.set(memoKey, result)
        return result
      })
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
  }
}
export const getIntegration = (token) => {
  return api.get('integration/list', getConfig(token))
}
export const connectXero = (token, data) => {
  let url = 'integration/xero/connect?'
  if (data?.code) {
    url = `${url}code=${data?.code}`
  }
  if (data?.organization_id) {
    url = `${url}organization_id=${data?.organization_id}&token=${data?.token}`
  }
  return api.get(url, getConfig(token))
}
export const connectZoho = (token, data) => {
  let url = 'integration/zoho/connect?'
  if (data?.code) {
    url = `${url}code=${data?.code}`
  }
  if (data?.organization_id && data?.token) {
    url = `${url}organization_id=${data?.organization_id}&token=${data?.token}`
  }

  return api.get(url, getConfig(token))
}
export const connectFreshBooks = (token, data) => {
  return data?.code
    ? api.get(`integration/fresh/connect?code=${data?.code}`, getConfig(token))
    : api.get('integration/fresh/connect', getConfig(token))
}
export const disconnectXero = (token, data) => {
  return api.post('integration/disconnect', data, getConfig(token))
}

export const setupXero = (token, data) => {
  return api.post('integration/xero/update', data, getConfig(token))
}

export const setupZoho = (token, data) => {
  return api.post('integration/zoho/update', data, getConfig(token))
}

export const setupQuickbooks = (token, data) => {
  return api.post('integration/qb/update', data, getConfig(token))
}
export const setupFreshbooks = (token, data) => {
  return api.post('integration/fresh/update', data, getConfig(token))
}

export const saveDataToHibob = (token, data) => {
  return api.post('integration/hibob/save', data, getConfig(token))
}
export const saveDataToBamboo = (token, data) => {
  return api.post('integration/bamboohr/save', data, getConfig(token))
}
export const saveDataToSapling = (token, data) => {
  return api.post('integration/sapling/save', data, getConfig(token))
}

export const updateDataToHibob = (token, data) => {
  return api.post('integration/hibob/update', data, getConfig(token))
}

export const updateDataToSapling = (token, data) => {
  return api.post('integration/sapling/update', data, getConfig(token))
}
export const updateDataToBamboo = (token, data) => {
  return api.post('integration/bamboohr/update', data, getConfig(token))
}

export const getHibobEditData = (token) => {
  return api.get('integration/hibob/edit', getConfig(token))
}

export const getSaplingEditData = (token) => {
  return api.get('integration/sapling/edit', getConfig(token))
}

export const getbambooEditData = (token) => {
  return api.get('integration/bamboohr/edit', getConfig(token))
}

export const getHibobContractors = (token) => {
  return api.get('integration/hibob/contractors', getConfig(token))
}
export const getBambooContractors = (token) => {
  return api.get('integration/bamboohr/contractors', getConfig(token))
}
export const getSaplingContractors = (token) => {
  return api.get('integration/sapling/contractors', getConfig(token))
}

export const saveHibobContractors = (token, data) => {
  return api.post('integration/hibob/send', data, getConfig(token))
}

export const saveBambooContractors = (token, data) => {
  return api.post('integration/bamboohr/send', data, getConfig(token))
}

export const saveSaplingContractors = (token, data) => {
  return api.post('integration/sapling/send', data, getConfig(token))
}

export const getDefaultXeroIntegrationData = (token, data) => {
  return api.post('integration/xero/complete', data, getConfig(token))
}

export const getDefaultZohoIntegrationData = (token, data) => {
  return api.post('integration/zoho/complete', data, getConfig(token))
}

export const getXeroIntegrationData = (token) => {
  return api.get('integration/xero/edit', getConfig(token))
}

export const getZohoIntegrationData = (token) => {
  return api.get('integration/zoho/edit', getConfig(token))
}

export const getXeroHistory = (token) => {
  return api.get('integration/xero/invoices', getConfig(token))
}

export const getZohoHistory = (token) => {
  return api.get('integration/zoho/invoices', getConfig(token))
}

export const syncAllInvoices = (token) => {
  return api.get('integration/xero/synchronize', getConfig(token))
}

export const syncAllZohoInvoices = (token) => {
  return api.get('integration/zoho/synchronize', getConfig(token))
}

export const getQBIntegrationData = (token) => {
  return api.get('integration/qb/edit', getConfig(token))
}

export const getFreshIntegrationData = (token) => {
  return api.get('integration/fresh/edit', getConfig(token))
}

export const getQBHistory = (token) => {
  return api.get('integration/qb/invoices', getConfig(token))
}

export const getFreshHistory = (token) => {
  return api.get('integration/fresh/invoices', getConfig(token))
}

export const qbSyncAllInvoices = (token) => {
  return api.get('integration/qb/synchronize', getConfig(token))
}

export const freshSyncAllInvoices = (token) => {
  return api.get('integration/fresh/synchronize', getConfig(token))
}

export const getXeroOrganizations = (token) => {
  return api.get('integration/xero/organisations', getConfig(token))
}

export const getZohoOrganizations = (token) => {
  return api.get('integration/zoho/organizations', getConfig(token))
}

export const connectNetSuite = (token, data) => {
  return api.post('integration/netsuite/connect', data, getConfig(token))
}

export const editNetSuite = (token) => {
  return api.get('integration/netsuite/edit', getConfig(token))
}

export const updateNetSuite = (token, data) => {
  return api.post('integration/netsuite/update', data, getConfig(token))
}

export const completeNetSuite = (token) => {
  return api.get('integration/netsuite/complete', getConfig(token))
}

export const synchronizeNetSuite = (token) => {
  return api.get('integration/netsuite/synchronize', getConfig(token))
}

export const getNetSuiteInvoices = (token) => {
  return api.get('integration/netsuite/invoices', getConfig(token))
}

export const inviteUsers = (token, data) => {
  return api.post('client/invite/bulksend', data, getConfig(token))
}

export const approveAccount = (token, data) => {
  return api.post('admin/client/approve', data, getConfig(token))
}
export const addCashPoint = (token, data) => {
  return api.post('contractor/cash/add', data, getConfig(token))
}

export const uploadWork = (token, data) => {
  const formData = new FormData()
  formData.append('file', data?.file)
  formData.append('type', data?.type)
  return api.post('contract/upload', formData, getConfig(token))
}

export const downloadWork = (token, data) => {
  return api.post(
    'contract/work/download',
    data,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}

export const getInsuranceOverview = (token) => {
  return api.get('insurance/overview', getConfig(token))
}

export const getInsurancePlans = (token, data) => {
  return api.post('insurance/plans', data, getConfig(token))
}

export const createInsuranceRequest = (token, data) => {
  return api.post('insurance/create', data, getConfig(token))
}

export const prepareInsuranceTrx = (token, data) => {
  return api.post('insurance/transaction/prepare', data, getConfig(token))
}

export const createInsuranceTrx = (token, data) => {
  return api.post('insurance/transaction/create', data, getConfig(token))
}

export const getAdminInsuranceList = (token, data) => {
  return api.post('admin/insurance/list', data, getConfig(token))
}

export const activateInsurance = (token, data) => {
  return api.post('admin/insurance/activate', data, getConfig(token))
}

export const cancelInsurance = (token, data) => {
  return api.post('admin/insurance/cancel', data, getConfig(token))
}
export const deactivateInsurance = (token, data) => {
  return api.post('admin/insurance/deactivate', data, getConfig(token))
}
export const updateInsuranceRequest = (token, data) => {
  return api.post('admin/insurance/update', data, getConfig(token))
}

export const getAdminInsurancePaymentList = (token, data) => {
  return api.post('admin/insurance/payment/list', data, getConfig(token))
}
export const deactivateInsuranceContractorOld = (token) => {
  return api.get('insurance/deactivate', getConfig(token))
}
export const deactivateInsuranceContractor = (token, data) => {
  return apiV2.post(
    '/insurance/api/v1/members/cancel_by_policy',
    data,
    getConfigV2(token),
  )
}

export const activateAllowanceInsuranceClient = (token, data) => {
  return api.post(
    `contract/${data?.contract_id}/allowance/create_for_insurance`,
    data,
    getConfig(token),
  )
}

export const deactivateAllowanceInsuranceClient = (token, data) => {
  return api.post(
    `contract/${data?.contract_id}/allowance/${data?.allowance_id}/deactivate`,
    data,
    getConfig(token),
  )
}

export const markContractorKycVerified = (token, data) => {
  return api.post('admin/contractor/verify/kyc', data, getConfig(token))
}

export const markContractorKycUnverified = (token, data) => {
  return api.post('admin/contractor/kyc/unverify', data, getConfig(token))
}

export const screenContractor = (token, data) => {
  return api.post(
    'admin/contractor/kyc/contractor_screening',
    data,
    getConfig(token),
  )
}

export const getRecurringExpensesList = (token, data) => {
  return api.post('contract/expense/recurring/list', data, getConfig(token))
}

export const getAdminRecurringExpensesList = (token, data) => {
  return api.post(
    'admin/contract/expense/recurring/list',
    data,
    getConfig(token),
  )
}

export const addRecurringExpense = (token, data) => {
  return api.post('contract/expense/recurring/add', data, getConfig(token))
}

export const updateRecurringExpense = (token, data) => {
  return api.post('contract/expense/recurring/update', data, getConfig(token))
}

export const deleteRecurringExpense = (token, data) => {
  return api.post('contract/expense/recurring/delete', data, getConfig(token))
}

export const getAdminDashboard = (token) => {
  return api.get('admin/dashboard', getConfig(token))
}
export const refreshAdminDashboard = (token) => {
  return api.get('admin/dashboard/refresh', getConfig(token))
}
export const convertAmount = (token, data) => {
  return api.post('/admin/convert', data, getConfig(token))
}
export const simulateFee = (token, data) => {
  return api.post('/admin/contractor/withdraw/simulate', data, getConfig(token))
}
export const cancelTimeOff = (token, data) => {
  return api.post('/contract/timeoff/cancel', data, getConfig(token))
}
export const exportTransaction = (token, data) => {
  return api.post(
    '/transaction/export',
    data,
    getConfig(token, {
      responseType: 'blob',
    }),
  )
}

export const getMarkup = (token) => {
  return api.get('/admin/currency/markups', getConfig(token))
}

export const updateMarkup = (token, data) => {
  return api.post('/admin/currency/markup/update', data, getConfig(token))
}

export const updateContractorMethods = (token, data) => {
  return api.post('/admin/contractor/withdraw/methods', data, getConfig(token))
}

export const getContractorWithdrawMethods = (token, data) => {
  return api.get(
    'contractor/withdraw/methods/' + data.currency,
    getConfig(token),
  )
}

export const getContractorWithdrawAccounts = (token, data) => {
  return api.get(
    'contractor/withdraw/accounts' +
      (data?.source ? `?source=${data?.source}` : ''),
    getConfig(token),
  )
}

export const getBankAccountDetails = (token, data) => {
  return api.post('/contractor/bank/details', data, getConfig(token))
}

export const getBankDetails = (token, { id }) => {
  return api.get(`admin/bankaccounts/${id}/details`, getConfig(token))
}

export const getDependentDetails = (token, data) => {
  return api.post('admin/insurance/details', data, getConfig(token))
}
export const logoutAdmin = (token) => {
  return api.get('admin/logout', getConfig(token))
}

export const addComment = (token, data) => {
  return api.post('/admin/comments/add', data, getConfig(token))
}

export const archiveComment = (token, data) => {
  return api.post('/admin/comments/archive', data, getConfig(token))
}

export const getAdminPermissions = (token) => {
  return api.get('/admin/permissions/list', getConfig(token))
}

export const getAdminTeamList = (token) => {
  return api.get('admin/team/list', getConfig(token))
}

export const getAdminTeamRoles = (token) => {
  return api.get('admin/team/role/permissions', getConfig(token))
}

export const changeAdminUserStatus = (token, data) => {
  return api.post('admin/team/update', data, getConfig(token))
}

export const changeAdminUserRole = (token, data) => {
  return api.post('admin/team/role/update', data, getConfig(token))
}

export const updateAdminPermissions = (token, data) => {
  return api.post('admin/team/role/permissions/update', data, getConfig(token))
}

export const addNewAdmin = (token, data) => {
  return api.post('admin/team/create', data, getConfig(token))
}

export const getPaymentAccount = (token) => {
  return api.get('admin/company/payment/accounts', getConfig(token))
}

export const getIPGeolocation = (_, data) => {
  const sp = searchParamsFromObject({
    ...data,
    api_key: process.env.REACT_APP_IP_GEOLOCATION,
  })

  const url = `https://ipgeolocation.abstractapi.com/v1/${sp}`

  return api.get(url)
}

export const validatePhoneNumber = (token, data) => {
  return api.post('user/phone/validate', data, getConfig(token))
}

function searchParamsFromObject(data, addQuestionMark = true) {
  const params = new URLSearchParams()
  Object.entries(data).forEach(([key, value]) => {
    if (!value) return
    params.append(key, value)
  })

  let sp = params.toString()

  if (sp && addQuestionMark) {
    sp = ['?', sp].join('')
  }

  return sp ?? ''
}

function searchParamsFromObjectArray(data, addQuestionMark = true) {
  const params = new URLSearchParams()

  Object.entries(data).forEach(([key, value]) => {
    if (!(Array.isArray(value) && value?.length > 0)) return
    value.forEach((v) => params.append(`${key}[]`, v))
  })

  let sp = params?.toString()

  if (sp && addQuestionMark) {
    sp = ['?', sp].join('')
  }

  if (sp && !addQuestionMark) {
    sp = ['&', sp].join('')
  }

  return sp ?? ''
}

export const getAllProviderRules = (token, data) => {
  const sp = searchParamsFromObject(data)

  return api.get('admin/payment_provider_rules' + sp, getConfig(token))
}
export const addProviderRule = (token, data) => {
  return api.post('admin/payment_provider_rules', data, getConfig(token))
}
export const editProviderRule = (token, data) => {
  const { id, ...payload } = data
  return api.post(
    'admin/payment_provider_rules/' + id,
    { ...payload, _method: 'put' },
    getConfig(token),
  )
}
export const deleteProviderRule = (token, data) => {
  return api.post(
    'admin/payment_provider_rules/' + data?.id,
    { _method: 'delete' },
    getConfig(token),
  )
}

export const getAllBeneficiaries = (token, data) => {
  return api.get(
    'admin/bankaccounts/beneficiaries' +
      (data?.page ? '?page=' + data?.page : ''),
    getConfig(token),
  )
}

export const addBeneficiary = (token, data) => {
  return api.post(
    'admin/bankaccounts/beneficiaries/add',
    data,
    getConfig(token),
  )
}

export const sendPaymentReminder = (token, data) => {
  return api.post('admin/transaction/reminder/payment', data, getConfig(token))
}

export const getPaymentReminderHistory = (token, data) => {
  return api.post('admin/transaction/reminder/history', data, getConfig(token))
}

export const uploadCardKycDocument = (token, data) => {
  const { progressFuncs, ...restOfData } = data

  const formData = new FormData()
  Object.keys(restOfData).forEach((k) => {
    if (!isNill(restOfData[k])) {
      formData.append(k, restOfData[k])
    }
  })

  return apiV2.post(
    '/cards/api/v1/kyc/documents',
    formData,
    getConfigV2(
      token,
      {},
      { ...progressFuncs, 'Content-Type': 'multipart/form-data' },
    ),
  )
}
export const submitKycInfos = (token, data) => {
  return apiV2.post('/cards/api/v1/kyc', data, getConfigV2(token))
}
export const finalizeKycProcess = (token, data) => {
  return apiV2.post('/cards/api/v1/kyc/finalize', data, getConfigV2(token))
}
export const getCardKycDetails = (token, data) => {
  return apiV2.get(
    '/cards/api/v1/admin/kyc/details?rp_user_id=' + data?.rp_user_id,
    getConfigV2(token),
  )
}

export const cardActivity = (token, extra) => {
  return apiV2.get(
    '/cards/api/v1/cards?with_suspended=true',
    getConfigV2(token, extra),
  )
}
export const getCardDetails = (token, body) => {
  const { cardId, rpUserId } = body

  return apiV2.get(
    `/cards/api/v1/cards/${cardId}`,
    getConfigV2(token, { rp_user_id: rpUserId }),
  )
}
export const cardSecurityCodes = (token, extra) => {
  return apiV2.get(
    `/cards/api/v1/cards/${extra?.id}?mask_number=disabled`,
    getConfigV2(token, extra),
  )
}
export const cardTransactions = (token, data) => {
  const { page, limit } = data
  const sp = searchParamsFromObject({ page, limit })

  return apiV2.get(`/cards/api/v1/cards/transactions${sp}`, getConfigV2(token))
}
export const getCardEnumerations = (token) => {
  return apiV2.get('/cards/api/v1/enumerations', getConfigV2(token))
}

export const cardsKycList = (token, data, extra) => {
  const sp = searchParamsFromObject({ ...data, limit: 50 })

  return apiV2.get(
    '/cards/api/v1/admin/kyc' + sp,
    getConfigV2(token, {}, extra),
  )
}

export const adminCardsTransactions = (token, data, extra) => {
  const sp = searchParamsFromObject({ ...data, limit: 50 })

  return apiV2.get(
    '/cards/api/v1/admin/cards/transactions' + sp,
    getConfigV2(token, {}, extra),
  )
}
export const adminCardsList = (token, data, extra) => {
  const sp = searchParamsFromObject({ ...data, limit: 50 })

  return apiV2.get(
    '/cards/api/v1/admin/cards' + sp,
    getConfigV2(token, {}, extra),
  )
}
export const adminPhysicalCardsList = (token, data, extra) => {
  const sp = searchParamsFromObject({ ...data, limit: 50 })

  return apiV2.get(
    '/cards/api/v1/admin/cards/orders/physical' + sp,
    getConfigV2(token, {}, extra),
  )
}

export const adminDownloadPhysicalRequestDetails = (token, data) => {
  return apiV2.get(
    `/cards/api/v1/admin/cards/orders/physical/${data?.id}/download/pdf`,
    getConfigV2(token, {}, { responseType: 'blob' }),
  )
}

export const adminCardsUnpaidFees = (token, data, extra) => {
  const sp = searchParamsFromObject({ ...data, limit: 50 })

  return apiV2.get(
    '/cards/api/v1/admin/cards/unpaid_fees' + sp,
    getConfigV2(token, {}, extra),
  )
}
export const updateAdminCardsUnpaidFees = (token, data) => {
  const { itemId, ...payload } = data

  return apiV2.post(
    `/cards/api/v1/admin/cards/unpaid_fees/${itemId}/update`,
    payload,
    getConfigV2(token),
  )
}
export const adminCardsConfig = (token, data) => {
  const sp = searchParamsFromObject({ ...data, perPage: 300 })

  return api.get('admin/card_configurations' + sp, getConfig(token))
}

export const adminCardsConfigPut = (token, data) => {
  const { id, ...payload } = data
  return api.post(
    'admin/card_configurations/' + id,
    { ...payload, _method: 'put' },
    getConfig(token),
  )
}

export const requestNewCard = (token, data) => {
  return apiV2.post('/cards/api/v1/cards/order', data, getConfigV2(token))
}
export const resendCardActivationCode = (token, data) => {
  const { card_id: cardId, ...payload } = data
  return apiV2.post(
    `/cards/api/v1/cards/${cardId}/activation-code/send`,
    payload,
    getConfigV2(token),
  )
}
export const activatePhysicalCard = (token, data) => {
  const { card_id: cardId, ...payload } = data
  return apiV2.post(
    `/cards/api/v1/cards/${cardId}/activate`,
    payload,
    getConfigV2(token),
  )
}
export const resetCardPinCode = (token, data) => {
  const { card_id: cardId, ...payload } = data
  return apiV2.post(
    `/cards/api/v1/cards/${cardId}/pins`,
    payload,
    getConfigV2(token),
  )
}
export const adminPhysicalCardMarkIssued = (token, data) => {
  const { card_order_id: cardId, ...payload } = data
  return apiV2.post(
    `/cards/api/v1/admin/cards/orders/physical/${cardId}/issue`,
    payload,
    getConfigV2(token),
  )
}
export const adminPhysicalCardMarkShipped = (token, data) => {
  const { card_order_id: cardId, ...payload } = data
  return apiV2.post(
    `/cards/api/v1/admin/cards/orders/physical/${cardId}/ship`,
    payload,
    getConfigV2(token),
  )
}

export const requestCardForExistingUser = (token, data) => {
  return apiV2.post('/cards/api/v1/cards/saved/order', data, getConfigV2(token))
}

export const adminUpdateCardKyc = (token, data) => {
  return apiV2.post('/cards/api/v1/admin/kyc/update', data, getConfigV2(token))
}
export const adminAcceptCardKyc = (token, data) => {
  return apiV2.post('/cards/api/v1/admin/kyc/accept', data, getConfigV2(token))
}
export const adminRejectCardKyc = (token, data) => {
  return apiV2.post('/cards/api/v1/admin/kyc/reject', data, getConfigV2(token))
}
export const adminSubmitCardKyc = (token, data) => {
  return apiV2.post('/cards/api/v1/admin/kyc/submit', data, getConfigV2(token))
}

export const adminDownloadEddForm = (token, data) => {
  const { rp_user_id: rpUserId } = data

  return apiV2.get(
    '/cards/api/v1/admin/kyc/edd_form/download' + `?rp_user_id=${rpUserId}`,
    getConfigV2(token, {}, { responseType: 'blob' }),
  )
}

export const prepareTopup = (token, data) => {
  const { currency, amount } = data // aed
  const sp = searchParamsFromObject({ currency, amount })

  return api.get('cards/quotes/usd' + sp, getConfig(token))
}
export const confirmTopup = (token, data) => {
  const { cardId, ...body } = data

  return apiV2.post(
    `/cards/api/v1/cards/${cardId}/topup`,
    body,
    getConfigV2(token),
  )
}
export const confirmOffload = (token, data) => {
  return apiV2.post(
    `/cards/api/v1/cards/${data?.card_id}/offload`,
    { usd_amount: data.usd_amount },
    getConfigV2(token),
  )
}
export const updateCardStatus = (token, extra) => {
  return apiV2.post(
    `/cards/api/v1/cards/${extra?.id}/${extra.status}`,
    extra.status === 'block'
      ? { card_id: extra?.id, block_reason: extra?.block_reason }
      : {},
    getConfigV2(token, extra),
  )
}

// START Deprecated baas API
export const prepareConvert = (token, data) => {
  return api.post('contractor/baas/convert/prepare', data, getConfig(token))
}
export const confirmConvert = (token, data) => {
  return api.post('contractor/baas/convert/confirm', data, getConfig(token))
}
// END Deprecated baas API

export const referralLink = (token, data) => {
  return apiV2.get(
    `/referral/api/v1/participant/${data?.id}`,
    getConfigV2(token),
  )
}
export const requestNewLink = (token, data) => {
  return apiV2.post('/referral/api/v1/participant', data, getConfigV2(token))
}
export const getReferrals = (token, data) => {
  return apiV2.get(
    `/referral/api/v1/participant/${data?.id}/referral`,
    getConfigV2(token),
  )
}
export const sendReferralInvites = (token, data) => {
  return apiV2.post(
    `/referral/api/v1/participant/${data?.id}/invite`,
    data,
    getConfigV2(token),
  )
}

export const perksPreConditions = (token) => {
  return api.get('perks/pre_conditions', getConfig(token))
}
export const requestPerks = (token) => {
  return api.post('perks/activations/request', {}, getConfig(token))
}

export const getContractorBalancesAdmin = (token, data) => {
  return api.post('admin/wallet/adjustment/list', data, getConfig(token))
}
export const getClientBalancesAdmin = (token, data) => {
  return api.post('admin/client_balances/requests/list', data, getConfig(token))
}
export const getClientBalancesAdminNew = (token, data) => {
  return api.post('admin/client_balances/list', data, getConfig(token))
}
export const requestAWalletAdjustment = (token, data) => {
  return api.post('admin/wallet/adjustment/request', data, getConfig(token))
}
export const requestAClientBalanceAdjustment = (token, data) => {
  return api.post(
    'admin/client_balances/requests/create',
    data,
    getConfig(token),
  )
}
export const getWalletAdjustmentApprovers = (token) => {
  return api.get('admin/wallet/adjustment/approvers', getConfig(token))
}
export const getClientRequestApprovers = (token) => {
  return api.get('admin/client_balances/requests/approvers', getConfig(token))
}
export const approveWalletAdjustment = (token, data) => {
  return api.post('admin/wallet/adjustment/approve', data, getConfig(token))
}
export const approveClientAdjustment = (token, data) => {
  return api.post(
    'admin/client_balances/requests/approve',
    data,
    getConfig(token),
  )
}
export const rejectWalletAdjustment = (token, data) => {
  return api.post('admin/wallet/adjustment/reject', data, getConfig(token))
}
export const rejectClientAdjustment = (token, data) => {
  return api.post(
    'admin/client_balances/requests/reject',
    data,
    getConfig(token),
  )
}
export const assignAdjustmentApprover = (token, data) => {
  return api.post('admin/wallet/adjustment/assign', data, getConfig(token))
}
export const assignRequestApprover = (token, data) => {
  return api.post(
    'admin/client_balances/requests/assign',
    data,
    getConfig(token),
  )
}
export const cardsPreConditions = (token) => {
  return api.get('cards/pre_conditions', getConfig(token))
}
const getConfigV2 = (token, extraHeader, extra) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
      ...extraHeader,
    },
    ...extra,
  }
}

export const getPerks = (token) => {
  return apiV2.get('perks/api/v1/offers', getConfigV2(token))
}

export const getPerk = (token, data) => {
  return apiV2.get('perks/api/v1/offers/' + data?.id, getConfigV2(token))
}

export const applyPerkOffer = (token, data) => {
  const { offerId, ...body } = data

  return apiV2.post(
    `perks/api/v1/offers/${offerId}/applications`,
    body,
    getConfigV2(token),
  )
}

export const getOfferSecret = (token, data) => {
  const { offerId } = data

  return apiV2.get(`perks/api/v1/offers/${offerId}/secret`, getConfigV2(token))
}

export const getOfferCategories = (token) => {
  return apiV2.get(`perks/api/v1/categories`, getConfigV2(token))
}

export const getHeadcountPerCountry = (token) => {
  return api.get('charts/headcount_per_country', getConfig(token))
}

export const getHeadcountOnMap = (token) => {
  return api.get('charts/headcount_on_map', getConfig(token))
}

export const getHeadcountPerMonthChart = (token) => {
  return api.get('charts/headcount_per_month', getConfig(token))
}

export const getRetentionChart = (token) => {
  return api.get('charts/retention', getConfig(token))
}

export const getAverageContractCostPerMonthChart = (token) => {
  return api.get('charts/average_contract_cost_per_month', getConfig(token))
}
export const getSendingPerMonth = (token) => {
  return api.get('charts/spendings_per_month', getConfig(token))
}

export const getContractsReport = (token, data) => {
  const qp = searchParamsFromObjectArray({ status_ids: data?.status_ids })

  return api.get('reports/contracts/preview' + qp, getConfig(token))
}

export const exportContractsReport = (token, data) => {
  const qp = searchParamsFromObjectArray(
    { status_ids: data?.status_ids },
    false,
  )

  return api.get(
    'reports/contracts/export' + `?format=${data?.format || 'csv'}` + qp,
    getConfig(token),
  )
}

export const checkExportStatus = (token, data) => {
  return api.get('exports/' + data?.exportId, getConfig(token))
}

export const getInvoicesReport = (token, data) => {
  return api.get(
    'reports/invoices/preview' +
      `?month=${data?.month || format(new Date(), dateFormat)}&type=detailed`,
    getConfig(token),
  )
}

export const exportInvoicesReport = (token, data) => {
  return api.get(
    'reports/invoices/export' +
      `?format=${data?.format || 'csv'}&type=detailed&month=${
        data?.month || format(new Date(), dateFormat)
      }`,
    getConfig(token),
  )
}

export const getTransactionsReport = (token, data) => {
  return api.get(
    'reports/transactions/preview' +
      `?month=${data?.month || format(new Date(), dateFormat)}&type=detailed`,
    getConfig(token),
  )
}

export const exportTransactionsReport = (token, data) => {
  return api.get(
    'reports/transactions/export' +
      `?format=${data?.format || 'csv'}&type=detailed&month=${
        data?.month || format(new Date(), dateFormat)
      }`,
    getConfig(token),
  )
}

export const getExpensesReport = (token, data) => {
  return api.get(
    'reports/expenses/preview' +
      `?month=${data?.month || format(new Date(), dateFormat)}`,
    getConfig(token),
  )
}

export const exportExpensesReport = (token, data) => {
  return api.get(
    'reports/expenses/export' +
      `?format=${data?.format || 'csv'}&month=${
        data?.month || format(new Date(), dateFormat)
      }&type=detailed`,
    getConfig(token),
  )
}

export const getTeamsReport = (token, data) => {
  const qp = searchParamsFromObjectArray({
    contract_status_ids: data?.contract_status_ids,
  })

  return api.get('reports/team/preview' + qp, getConfig(token))
}

export const exportTeamsReport = (token, data) => {
  const qp = searchParamsFromObjectArray(
    { contract_status_ids: data?.contract_status_ids },
    false,
  )

  return api.get(
    'reports/team/export' + `?format=${data?.format || 'csv'}` + qp,
    getConfig(token),
  )
}

export const getForbiddenDomains = (token, data) => {
  return api.get('admin/forbidden-domains/list', getConfig(token))
}

export const updateForbiddenDomain = (token, data) => {
  return api.post(
    `admin/forbidden-domains/${data?.forbidden_domain_id}/edit`,
    data,
    getConfig(token),
  )
}

export const deleteForbiddenDomain = (token, data) => {
  return api.delete(
    `admin/forbidden-domains/${data?.forbidden_domain_id}/delete`,
    getConfig(token),
  )
}

export const createForbiddenDomain = (token, data) => {
  return api.post('admin/forbidden-domains/create', data, getConfig(token))
}

export const getContractorActiveInsuranceAllowances = (token, data) => {
  return api.get('allowance/insurance', getConfig(token))
}

export const getContractorContractAllowances = (token, data) => {
  return api.get(
    `/contract/${data?.contract_id}/allowance/list`,
    getConfig(token),
  )
}

// #region ############## Direct employee ##############
export const deTerminationCosts = (token, data) => {
  return api.get(
    'contract/termination_costs?contract_id=' + data?.contract_id,
    getConfig(token),
  )
}

export const deScheduledTerminate = (token, data) => {
  return api.post('contract/scheduled_terminate', data, getConfig(token))
}

export const deCancelScheduledTerminate = (token, data) => {
  return api.post('contract/cancel_scheduled_terminate', data, getConfig(token))
}

export const deSupportedCountries = (token) => {
  return api.get('direct_employees/countries', getConfig(token))
}

export const deGetCountryJurisdictions = (token, data) => {
  const id = data?.country_id
  return api.get(
    'direct_employees/jurisdictions/country/' + id,
    getConfig(token),
  )
}

// #endregion ############## Direct employee ##############

// #region ############## PAY IN ##############

// #region     ########## BREX ##########
export const getPayInAuthUrl = (token, data) => {
  const sp = searchParamsFromObject(data)

  return apiV2.get(
    '/payin/api/v1/brex/auth/url/authorize' + sp,
    getConfigV2(token),
  )
}

export const getPayInAuthCode = (token, data) => {
  const sp = searchParamsFromObject(data)
  return apiV2.post('/payin/api/v1/brex/auth/code' + sp, {}, getConfigV2(token))
}

export const getPayInBrexAccounts = (token, data) => {
  const sp = searchParamsFromObject(data)
  return apiV2.get(
    '/payin/api/v1/brex/transfer/accounts' + sp,
    getConfigV2(token),
  )
}

export const addPayInBrexAccount = (token, data) => {
  const { userId, companyId, ...body } = data
  const sp = searchParamsFromObject({ userId, companyId })

  return apiV2.post(
    '/payin/api/v1/brex/transfer/accounts' + sp,
    body,
    getConfigV2(token),
  )
}

export const getSavedBrexAccounts = (token, data) => {
  const sp = searchParamsFromObject(data)
  return apiV2.get(
    '/payin/api/v1/brex/transfer/accounts/saved' + sp,
    getConfigV2(token),
  )
}
export const deleteSavedBrexAccount = (token, data) => {
  const sp = searchParamsFromObject(data)
  return apiV2.delete(
    '/payin/api/v1/brex/transfer/accounts/saved' + sp,
    getConfigV2(token),
  )
}

export const savePayInPaymentIds = (token, data) => {
  return apiV2.post(
    '/payin/api/v1/brex/auth/payments',
    data,
    getConfigV2(token),
  )
}
export const getPayInPaymentIds = (token, data) => {
  const sp = searchParamsFromObject(data)

  return apiV2.get('/payin/api/v1/brex/auth/payments' + sp, getConfigV2(token))
}

export const createBrexTransfer = (token, data) => {
  const { userId, companyId, ...body } = data
  const sp = searchParamsFromObject({ userId, companyId })

  return apiV2.post(
    '/payin/api/v1/brex/transfer' + sp,
    body,
    getConfigV2(token),
  )
}
// #endregion     ########## BREX ##########

// #endregion ############## PAY IN ##############
