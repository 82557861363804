import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import toastr from 'toastr'

import Button from '../../../components/ui/button'
import { useFetch } from '../../../helpers/hooks'
import { deCancelScheduledTerminate } from '../../../services/api'

export default function DeCancelTermination({ contract, onConfirm, isAdmin }) {
  const [isOpen, setIsOpen] = useState()

  function toggle() {
    setIsOpen((open) => !open)
  }

  const { startFetch: cancelTermination, isLoading: cancelingTermination } =
    useFetch({
      action: deCancelScheduledTerminate,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error(data?.message || 'Error cancelling termination')
        } else {
          toastr.success('Termination cancelled')
          onConfirm?.()
          toggle()
        }
      },
      onError: (error) => {
        toastr.error(error?.message || 'Error cancelling termination')
      },
    })

  return (
    <div>
      <p className='mt-3 mb-1 font-size-12 text-muted'>
        This contract is scheduled for termination.
      </p>

      <Button
        disabled={isAdmin}
        onClick={() => {
          setIsOpen(true)
        }}
        color='light'
        outline
        block
      >
        Cancel terminate
      </Button>

      <Modal isOpen={isOpen} toggle={toggle} unmountOnClose centered>
        <ModalBody className='pt-5'>
          <div className='text-center rp-font-gilroyB text-gray-h'>
            <h5 className='text-gray-h'>Cancel Termination</h5>
            <p>
              Are you sure you want to cancel the termination of this contract?
            </p>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button
            color='light'
            outline
            onClick={toggle}
            disabled={cancelingTermination}
          >
            Never mind
          </Button>

          <Button
            color='danger'
            onClick={() => {
              cancelTermination({ contract_id: contract.id })
            }}
            disabled={cancelingTermination}
            loading={cancelingTermination}
          >
            Proceed to cancel termination
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
