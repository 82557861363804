import React, { useEffect, useMemo } from 'react'
import { Redirect, Route, withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useAdminPermission, useFetch } from '../../helpers/hooks'
import { getAdminPermissions as getAdminPermissionsAction } from '../../services/api'
import { updateUserAdminPermissions } from '../../store/profile/actions'
import { updatePageInfo } from '../../store/page/actions'

const AdminMiddleware = (props) => {
  const dispatch = useDispatch()
  const isAdmin = useSelector((state) => !!state.Login.loginToken)
  const isAdminPages = props.location.pathname.startsWith('/admin')

  useEffect(() => {
    if (isAdminPages) {
      window.analytics.identify(
        '',
        {},
        { Intercom: { hideDefaultLauncher: true } },
      )
    }
  }, [isAdminPages])

  const routePermissions = useMemo(() => props.permissions, [props.permissions])

  useFetch(
    {
      action: isAdmin ? getAdminPermissionsAction : null,
      withAdminAccess: true,
      autoFetch: true,
      onComplete: (data) => {
        if (isAdmin) {
          dispatch(updateUserAdminPermissions(data))
        }
      },
    },
    [isAdmin],
  )

  useEffect(() => {
    dispatch(updatePageInfo({ permissions: routePermissions }))
  }, [dispatch, props.permissions, routePermissions])

  const { hasAccess } = useAdminPermission()

  const Component = props.component
  const Layout = props.layout

  const newProps = { ...props }
  delete newProps?.layout
  delete newProps?.component

  return (
    <Route
      {...newProps}
      render={(routeRenderProps) => {
        if (routePermissions && !hasAccess(routePermissions)) {
          return <Redirect to={{ pathname: '/admin/all-payments' }} />
        }

        if (
          routeRenderProps.location.pathname?.startsWith('/admin') &&
          !isAdmin
        ) {
          return (
            <Redirect
              to={{
                pathname: '/admin/login',
                state: { from: routeRenderProps.location },
              }}
            />
          )
        }

        return (
          <Layout routePermissions={routePermissions}>
            <Component {...routeRenderProps} />
          </Layout>
        )
      }}
    />
  )
}

export default withRouter(AdminMiddleware)
