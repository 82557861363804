import React, { useState } from 'react'
import { Card, CardHeader, Col, Container, Row } from 'reactstrap'

import company from '../../assets/images/company.svg'
import companyhover from '../../assets/images/companyhover.svg'
import contractor from '../../assets/images/contractor2.svg'
import contractorhover from '../../assets/images/contractorhover.svg'

const UserTypeSelect = ({
  onUserTypeChanged,
  value,
  disable,
  withEmployee,
}) => {
  const [selected, setSelected] = useState(value)

  const options =
    disable && disable === 'contractor'
      ? [
          {
            title: 'Contractor',
            description: 'Get paid by my client',
            icon: contractor,
            hoverIcon: contractorhover,
          },
        ]
      : [
          {
            title: 'Contractor',
            description: 'Get paid by my client',
            icon: contractor,
            hoverIcon: contractorhover,
          },
          {
            title: 'Company',
            description: 'Pay my remote team',
            icon: company,
            hoverIcon: companyhover,
          },
        ]
  if (withEmployee) {
    options[2] = {
      title: 'Employee',
      description: 'Get paid by my employer',
      icon: contractor,
      hoverIcon: contractorhover,
    }
  }
  return (
    <Container fluid={true} className={'p-0 m-0'}>
      <Row className='justify-content-between p-0 m-0   '>
        <Col className='   m-0 p-0'>
          {options.map((e, i) => (
            <a
              key={`option-${e?.title}`}
              href='#'
              onClick={
                disable
                  ? null
                  : () => {
                      setSelected(i)
                      onUserTypeChanged(i)
                    }
              }
            >
              <Card
                style={{ boxShadow: 'none' }}
                outline
                color={i === selected ? 'primary' : 'secondary'}
                className={
                  i === selected
                    ? 'text-primary border-primary border mb-3 mb-md-4'
                    : 'mb-3 mb-md-4 text-secondary border border-light'
                }
              >
                <CardHeader className='bg-transparent p-0 m-0'>
                  <Row className='align-items-start  p-3 p-md-4 m-0'>
                    <Col
                      xs={12}
                      md={2}
                      className={' p-0 m-0 mb-2  pt-0 pt-md-2'}
                    >
                      <img
                        src={i === selected ? e.hoverIcon : e.icon}
                        style={{ width: 32 }}
                        alt={`${e.title} icon`}
                      />
                    </Col>
                    <Col xs={12} md={10} className={'  p-0 m-0'}>
                      <h5
                        className={
                          i === selected
                            ? '  text-primary  font-size-16  font-weight-bold'
                            : 'font-size-16 text-dark  font-weight-bold'
                        }
                        style={{ textJustify: 'center', alignItems: 'center' }}
                      >
                        {e.title}
                      </h5>
                      <p
                        className={
                          i === selected
                            ? 'text-primary  font-size-14  font-weight-light mb-0'
                            : 'font-size-14 text-secondary  font-weight-light mb-0'
                        }
                      >
                        {e.description}
                      </p>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
            </a>
          ))}
        </Col>
      </Row>
    </Container>
  )
}

export default UserTypeSelect
