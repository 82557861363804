import React from 'react'
import { useDispatch } from 'react-redux'

import { useFetch } from '../../../helpers/hooks'
import { getFullTimeContractDetails } from '../../../services/api'
import { updateContract } from '../../../store/contract/actions'
import EmployeeDocsList from './employee-docs-list'

export default function EmployeeDocuments() {
  const dispatch = useDispatch()

  const contractDetails = useFetch({
    action: getFullTimeContractDetails,
    autoFetch: true,
    onComplete: (data) => {
      dispatch(updateContract(data))
    },
  })

  const isContractOngoing = contractDetails.data?.status?.id === 4

  return (
    <div className='page-content'>
      <EmployeeDocsList isContractOngoing={isContractOngoing} />
    </div>
  )
}
