import React, { useState } from 'react'
import { useFetch } from '../../../../helpers/hooks'
import { exportWithdraw } from '../../../../services/api'
import ModalHeader from 'reactstrap/lib/ModalHeader'
import { Col, Row } from 'reactstrap'
import FormGroup from 'reactstrap/lib/FormGroup'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import Modal from 'reactstrap/lib/Modal'
import toastr from 'toastr'
import { useSelector } from 'react-redux'
import CustomDatePicker from '../../../../components/Forms/CustomDatePicker/CustomDatePicker'
import moment from 'moment'

const ExportTrxModal = ({ isOpen, toggle, title }) => {
  const [date, setDate] = useState(null)
  const providers = useSelector(
    (state) => state?.Layout?.staticData?.payment_providers,
  )
  const statuses = [
    { label: 'All', value: null },
    { label: 'Processed', value: 'processed' },
    { label: 'Confirmed', value: 'confirmed' },
  ]
  const [status, setStatus] = useState(statuses[0])
  const [provider, setProvider] = useState(providers?.[0])

  const exportFile = (data) => {
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute(
      'download',
      status?.label + '_' + provider?.label + '_transactions.xlsx',
    )
    document.body.appendChild(link)
    link.click()
    toggle()
  }
  const withdrawExport = useFetch({
    action: exportWithdraw,
    withAdminAccess: true,
    onComplete: exportFile,
    onError: (error) => {
      toastr.error(error)
    },
  })
  const handleWithdrawExport = () => {
    const body = {
      provider: provider?.value,
    }
    if (status?.value) {
      body.status = status?.value
    }
    if (date) {
      body.month = moment(date).format('YYYY-MM')
    }
    withdrawExport.startFetch(body)
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered unmountOnClose>
      <div className='modal-content'>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <Row className='p-4'>
          <Col>
            <FormGroup>
              <label>Provider</label>
              <CustomSelect
                options={
                  providers
                    ? providers.map((c) => ({
                        label: c.name,
                        value: c.code,
                      }))
                    : []
                }
                onChange={setProvider}
                value={provider}
              />
            </FormGroup>
            <FormGroup>
              <label>Month</label>
              <CustomDatePicker
                showMonthYearPicker
                placeholder='Select a month'
                dateFormat='MMM - yyyy'
                value={date}
                handleOnChange={setDate}
              />
            </FormGroup>
            <FormGroup>
              <label>Status</label>
              <CustomSelect
                options={statuses}
                onChange={setStatus}
                value={status}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className='modal-footer'>
        <Row>
          <button className='btn btn-outline-light mr-2' onClick={toggle}>
            Cancel
          </button>
          <button className='btn btn-primary' onClick={handleWithdrawExport}>
            {withdrawExport.isLoading && (
              <i className='bx bx-loader bx-spin font-size-16 align-middle mr-2'></i>
            )}
            Export
          </button>
        </Row>
      </div>
    </Modal>
  )
}

export default ExportTrxModal
