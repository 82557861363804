import { NewspaperClipping } from '@phosphor-icons/react'
import classnames from 'classnames'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'

import bkblue from '../../assets/images/bkblue.svg'
import warningIllustration from '../../assets/images/warning.png'
import Toggle from '../../components/Forms/Toggle/Toggle'
import BadgeX from '../../components/Table/BadgeX'
import { StyledH3 } from '../../components/Typo'
import Button from '../../components/ui/button'
import FEATURE_FLAGS from '../../config/feature-flags'
import { userTypes } from '../../helpers/enum'
import { useFetch, usePermissions } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import {
  getCompanyInfo,
  getPaymentMethods,
  updateCompanyInfo,
} from '../../services/api'
import { updateProfileCompany } from '../../store/profile/actions'
import { getItem, setItem } from '../../utils/ls'
import ContractorSettings from '../ContractorSettings'
import CompanyInfo from './CompanyInfo'
import CustomFields from './CustomFields'
import Customization from './Customization'
import Integrations from './Integrations'
import MemberAdmin from './MembersAdmin'
import PaymentInfo from './PaymentInfo'
import { ContractLogoManagement } from './components/contract-logo-management'
import ManageAvatar from './manage-avatar'

export default function CompanySetting({ location }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const user = useSelector((state) => state.Account?.user)
  const countries = useSelector((state) => state.Layout?.staticData?.countries)
  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company,
  )

  const formRef = useRef(null)
  const [activeTab, setActiveTab] = useState(1)
  const [loading, setLoading] = useState(false)
  const display = useSelector((state) => state.Account?.user?.type)
  const { hasAccess } = usePermissions()

  const paymentMethods = useFetch({
    action: getPaymentMethods,
    autoFetch: true,
  })

  function uploadPhoto(acceptedFiles) {
    const file = acceptedFiles[0]
    if (
      file &&
      ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'].includes(file.type)
    ) {
      const fileSize = file.size / 1024 / 1024

      if (fileSize > 25) {
        toastr.error('File size exceeds 25 MB')
        return
      }

      const body = { logo: file }
      setLoading(true)
      updateCompanyInfo(user?.token, body)
        .then((r) => {
          if (r.data.success) {
            setCompanyInfoData(r.data.data)
            dispatch(updateProfileCompany(r?.data?.data))
          } else {
            toastr.error(r.data.data?.error || r.data?.message)
          }
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    } else {
      toastr.error('File not supported')
    }
  }

  const {
    startFetch: fetchCompanyInfo,
    data: companyInfoData,
    setData: setCompanyInfoData,
  } = useFetch({
    action: getCompanyInfo,
    autoFetch: true,
    onComplete: () => {
      window.analytics.track('Viewed company settings', {
        email_id: user?.email,
      })
    },
  })

  const companyData = useMemo(() => {
    return [
      {
        id: 1,
        key: 'info',
        component: () => (
          <Card className='m-0 rp-rounded-top-0'>
            {!!countries?.length && !!companyInfoData && (
              <CompanyInfo
                ref={formRef}
                data={companyInfoData}
                countries={countries}
                onUpdate={() => fetchCompanyInfo(null, false)}
              />
            )}
          </Card>
        ),
        access: hasAccess(permissions.UpdateCompanies),
        icon: 'bx bxs-id-card',
        label: 'Company Info',
        route: '/settings/info',
      },
      {
        id: 2,
        key: 'payment',
        component: () => <PaymentInfo />,
        access:
          hasAccess(permissions.UpdateDefaultPaymentMethods) &&
          paymentMethods.data?.length > 0,
        icon: 'bx bx-money',
        label: 'Payment',
        route: '/settings/payment',
      },
      {
        id: 3,
        key: 'users',
        rendersTabPane: true,
        component: ({ tabId }) => (
          <TabPane tabId={tabId}>
            <MemberAdmin />
          </TabPane>
        ),
        access: hasAccess(permissions.UpdateCompanies),
        icon: 'bx bx-group',
        label: 'Users',
        route: '/settings/users',
      },
      {
        id: 4,
        key: 'payrollApproval',
        component: () => (
          <div
            className='px-1 py-1 py-md-3 bg-white rounded-bottom'
            style={{ minHeight: 'calc(422px - 72px)' }}
          >
            <PayrollApproval />
          </div>
        ),
        access:
          FEATURE_FLAGS.REMOTEPASS_PAYROLL_APPROVAL_PROCESS &&
          hasAccess(permissions.UpdateCompanies),
        icon: 'bx bx-group',
        label: 'Payroll approval',
        route: '/settings/payment-approval',
      },
      {
        id: 5,
        key: 'customFields',
        component: () => <CustomFields />,
        access: true,
        icon: 'fas fa-puzzle-piece',
        label: 'Custom Fields',
        route: '/settings/customFields',
      },
      {
        id: 6,
        key: 'integrations',
        component: () => <Integrations />,
        access: true,
        icon: 'fas fa-puzzle-piece',
        label: 'Integrations',
        route: '/settings/integrations',
      },
      {
        id: 7,
        key: 'customization',
        component: () => (
          <Customization data={companyInfoData?.personalization} />
        ),
        access: false,
        icon: 'fas fa-puzzle-piece',
        label: 'Customization',
        route: '/settings/customization',
      },
    ]
  }, [
    companyInfoData,
    countries,
    fetchCompanyInfo,
    hasAccess,
    paymentMethods.data?.length,
  ])

  const tabsData = useMemo(
    () => companyData?.filter((e) => e.access),
    [companyData],
  )

  useEffect(() => {
    const foundId = tabsData.find((tab) => {
      return location.pathname === tab.route
    })?.id

    if (!foundId) {
      setActiveTab(1)
    } else if (foundId !== activeTab) {
      setActiveTab(foundId)
    }
  }, [activeTab, location.pathname, tabsData])

  return display === userTypes.COMPANY ? (
    <StyledContainer className='page-content'>
      <Row className='p-0 m-0'>
        <StyledGap>
          <StyledImgGap bk={bkblue} />
        </StyledGap>
        <Col md={3} className='p-0 m-0 pr-md-3'>
          <StyledCol className='mb-4'>
            <ManageAvatar
              src={companyInfoData?.logo ?? company?.logo}
              name={company?.name}
              onUploadPhoto={uploadPhoto}
              isUploading={loading}
            />

            <StyledH3
              min='22px'
              max='24px'
              mid='1.25em'
              style={{ fontWeight: 600 }}
              className='px-2 text-center text-gray-h rp-font-gilroyB'
              translate='no'
            >
              {company?.name}
            </StyledH3>
            {!company?.type?.name ? null : (
              <p className='text-gray-600 font-size-14 px-2 text-center'>
                {company?.type?.name}
              </p>
            )}

            <BadgeX status='secondary' className='mb-3'>
              Entity
            </BadgeX>

            <Card
              className='border-top card font-size-14 mb-0 mt-2 p-4 text-gray-h'
              style={{ lineHeight: '24px' }}
            >
              Company information will be displayed as is in your contracts and
              invoices.
            </Card>
          </StyledCol>

          <ContractLogoSetting />
        </Col>
        <Col md={9} className='m-0 p-0'>
          {paymentMethods.isLoading ? (
            <Col style={{ minHeight: '30rem' }}>
              <Row
                style={{ minHeight: '30rem' }}
                className='justify-content-center align-items-center m-0 p-0'
              >
                <Spinner type='grow' className='mr-2' color='primary' />
              </Row>
            </Col>
          ) : (
            <div className='checkout-tabs'>
              <Row className='m-0 p-0'>
                <Col
                  className='m-0 p-0'
                  style={{ boxShadow: '0px 1px 0px #DFE1E6', borderRadius: 4 }}
                >
                  <StyledNav className='rounded-top' tabs>
                    {tabsData?.map((nav) => {
                      return (
                        <NavItem key={`companySettingsNav:${nav.id}`}>
                          <NavLink
                            className={classnames('font-size-14', {
                              active: activeTab === nav.id,
                            })}
                            style={{ whiteSpace: 'nowrap' }}
                            onClick={() => {
                              history.push(nav.route)
                            }}
                          >
                            {nav.label}
                          </NavLink>
                        </NavItem>
                      )
                    })}
                  </StyledNav>

                  <TabContent activeTab={activeTab}>
                    {tabsData?.map((nav) => {
                      if (nav?.rendersTabPane) {
                        return (
                          <nav.component key={`tab:${nav.id}`} tabId={nav.id} />
                        )
                      }

                      return (
                        <TabPane key={`tab:${nav.id}`} tabId={nav.id}>
                          <nav.component />
                        </TabPane>
                      )
                    })}
                  </TabContent>
                </Col>
              </Row>
            </div>
          )}
        </Col>
      </Row>
    </StyledContainer>
  ) : (
    <ContractorSettings />
  )
}

function useUpdateCompanyInfo() {
  const dispatch = useDispatch()
  const updateCompany = useFetch({
    action: updateCompanyInfo,
    onComplete: (data) => {
      dispatch(updateProfileCompany(data))
    },
  })

  return updateCompany
}

export function usePayrollApprovalEnabled() {
  const payrollApprovalEnabled = useSelector(
    (state) =>
      state.userProfile?.userProfile?.company?.is_payroll_approval_enabled?.toString() ===
      '1',
  )

  return payrollApprovalEnabled
}

function PayrollApproval() {
  const [payrollModalOpen, setPayrollModalOpen] = useState(false)
  const payrollApprovalEnabled = usePayrollApprovalEnabled()

  const { startFetch: updateCompany, isLoading } = useUpdateCompanyInfo()

  function handleToggleApproval() {
    setPayrollModalOpen(true)
  }

  return (
    <div className='d-flex flex-column p-3'>
      <h2 className='rp-font-gilroyB text-gray-h'>Payroll Approval</h2>

      <p className='text-gray-600' style={{ lineHeight: '20px' }}>
        Enable payroll approval flow for a better control. Assign other team
        members to review and approve every item relevant their teams before you
        process payroll. Admin role have the right to approve items by default.{' '}
        <a
          href='https://help.remotepass.com/en/articles/6999169-how-to-setup-payroll-approval'
          target='_blank'
          rel='noreferrer'
          className='white-space-nowrap'
        >
          Learn more
        </a>
      </p>

      <PayrollApprovalModal
        isOpen={payrollModalOpen}
        toggle={() => setPayrollModalOpen((o) => !o)}
        newValue={!payrollApprovalEnabled}
        isLoading={isLoading}
        onConfirm={({ sendEmail }) => {
          const body = {
            is_payroll_approval_enabled: !payrollApprovalEnabled ? 1 : 0,
          }
          if (sendEmail) {
            body.send_payroll_approval_enabled_notification = true
          }
          updateCompany(body)
        }}
      />

      <label
        className='align-items-center d-flex hover:bg-light mb-0 py-1 pl-1 pr-2 rounded-pill cursor-pointer text-gray-h'
        style={{ alignSelf: 'flex-start' }}
      >
        <Toggle
          change={(e) => handleToggleApproval(e.target.checked)}
          check={payrollApprovalEnabled}
          name='enablePayrollApproval'
          disabled={isLoading}
        />
        <span>
          {payrollApprovalEnabled ? 'Disable' : 'Enable'} Payroll Approval
        </span>
      </label>
    </div>
  )
}

function PayrollApprovalModal({
  isOpen,
  toggle,
  onConfirm,
  newValue,
  isLoading,
}) {
  const [sendEmail, setSendEmail] = useState(false)

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalBody className='text-center'>
        <img
          src={warningIllustration}
          className='my-4'
          style={{ width: 120 }}
        />

        <h4 className='h5 text-gray-h rp-font-gilroyB rp-font-bold mb-4'>
          Are you sure you want to {newValue ? 'enable' : 'disable'} Payroll
          Approval?
        </h4>

        {!newValue ? null : (
          <>
            <p className='text-zinc-700 mx-auto' style={{ maxWidth: '54ch' }}>
              By enabling Payroll Approval, all payment items will need to be
              approved before you can process payroll.
            </p>

            <FormGroup className='mt-2 mb-4 mx-1 d-inline-block'>
              <Label
                htmlFor='send-email'
                className='font-size-14 text-slate-700 mb-0 d-flex align-items-center rp-checkbox-label rp-font-normal gap-10 rounded-sm'
              >
                <Input
                  type='checkbox'
                  name='send-email'
                  id='send-email'
                  className='rp-checkbox-input'
                  value={sendEmail}
                  onChange={(e) => setSendEmail(e.target.checked)}
                />

                <span>Notify users by email</span>
              </Label>
            </FormGroup>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color='light' outline onClick={toggle} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          onClick={() => onConfirm({ sendEmail })}
          loading={isLoading}
          disabled={isLoading}
        >
          {newValue ? 'Enable' : 'Disable'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export const StyledNav = styled(Nav)`
  flex-wrap: nowrap !important;
  overflow-x: auto;
  overflow-y: hidden;
  border-bottom: 1px solid #e7e8f2;

  .nav-item {
    padding-right: 16px;

    .nav-link {
      font-weight: 400;
      color: var(--gray-600);
      margin: 16px 0;
      padding: 16px 0;
      border: 0;
      background-color: transparent;
      border-bottom: 2px solid transparent;

      &:hover {
        border-right-color: #fff !important;
        border-left-color: #fff !important;
        border-top-color: #fff !important;
      }
    }

    .active {
      font-weight: bold;
      color: var(--primary);
      border-bottom-color: var(--primary);
    }
  }

  @media (min-width: 768px) {
    background-color: #fff;

    .nav-item {
      padding-right: 20px;
      padding-left: 20px;

      .nav-link {
        margin: 0;
        padding: 24px 0;
      }
    }
  }
`

const StyledContainer = styled.div`
  margin-top: 32px;

  @media (max-width: 768px) {
  }
`

const StyledImgGap = styled.img`
  width: 100%;
  background-color: #fff;
  height: 210px;
  background-image: ${(props) => (props.bk ? `url(${props.bk})` : null)};
  background-repeat: repeat;
`
const StyledGap = styled.div`
  position: absolute;
  top: 47px;
  left: 0;
  right: 0;
  background-color: #114ef7;
  width: 100%;

  @media (max-width: 768px) {
    height: 10%;
    top: 22px;
  }
`

const StyledCol = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 1px 0px #dfe1e6;
  border-radius: 4px;
  height: fit-content;
  z-index: 2;
`

function ContractLogoSetting() {
  const dispatch = useDispatch()
  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company,
  )

  const enabled = company?.official_logo_enabled?.toString() === '1'

  const { startFetch: updateCompany, isLoading: updatingContractLogo } =
    useFetch({
      action: updateCompanyInfo,
      onComplete: (data) => {
        if (data?.success !== false) {
          dispatch(updateProfileCompany(data))
        }
      },
    })

  function handleToggleLogos(e) {
    const { checked } = e.target ?? {}

    updateCompany({ official_logo_enabled: checked ? 1 : 0 })
  }

  return (
    <Card className='rp-shadow-2'>
      <ContractLogoNotice />

      <CardBody className='pt-3 d-flex flex-column gap-12'>
        <label
          className='d-inline-flex align-items-center flex-wrap gap-8 mb-0 cursor-pointer'
          style={{ alignSelf: 'flex-start' }}
        >
          <Toggle
            check={enabled}
            change={handleToggleLogos}
            disabled={updatingContractLogo}
            marginRight={null}
          />
          <span className='rp-font-medium'>Add logo to documents</span>
        </label>

        <ContractLogoManagement enabled={enabled} />
      </CardBody>
    </Card>
  )
}

const CONTRACT_LOGO_NOTICE_KEY = 'contract-logo-notice-shown'
function ContractLogoNotice() {
  const shown = JSON.parse(getItem(CONTRACT_LOGO_NOTICE_KEY) || 'false')
  const [isShown, setIsShown] = useState(shown)

  function hideNotice() {
    setItem(CONTRACT_LOGO_NOTICE_KEY, true)
    setIsShown(true)
  }

  if (isShown) {
    return null
  }

  return (
    <CardBody className='pt-3 border-bottom border-gray-b text-center d-flex flex-column gap-12 align-items-center'>
      <div className='d-flex flex-column align-items-center'>
        <BadgeX
          color='primary'
          className='rounded-pill mb-n2 px-2 py-1.5 z-1000 position-relative uppercase'
          style={{ boxShadow: '0 0 0 3px white' }}
          size='md'
        >
          New
        </BadgeX>
        <NewspaperClipping
          size={64}
          weight='duotone'
          color='var(--primary)'
          className='p-3 rounded-circle bg-primary-20'
        />
      </div>

      <p className='mb-0 mx-auto text-gray-600' style={{ maxWidth: 210 }}>
        You can now add your logo to your future documents in RemotePass
      </p>

      <button
        className='rp-btn-nostyle text-black rp-font-bold hover:bg-slate-50'
        onClick={hideNotice}
      >
        Dismiss
      </button>
    </CardBody>
  )
}
