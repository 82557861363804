import cx from 'classnames'
import React from 'react'
import { Table } from 'reactstrap'

const TableComp = ({ children, className }) => {
  return (
    <Table
      className={cx(
        'mb-0 table table-nowrap text-muted position-relative',
        className,
      )}
    >
      {children}
    </Table>
  )
}

export default TableComp
