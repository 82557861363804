// Somehow react is not detected
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const StyledH1 = styled.h1`
  font-size: ${(props) => props.max};

  @media (max-width: 1280px) {
    font-size: ${(props) => props.mid || props.max};
  }
  @media (max-width: 425px) {
    font-size: ${(props) => (props.min ? props.min : props.max)};
  }
`

export const StyledH2 = styled.h2`
  font-size: ${(props) => props.max};
  @media (max-width: 1280px) {
    font-size: ${(props) => props.mid || props.max};
  }
  @media (max-width: 425px) {
    font-size: ${(props) => (props.min ? props.min : props.max)};
  }
`
export const StyledH3 = styled.h3`
  font-size: ${(props) => props.max};

  @media (max-width: 1280px) {
    font-size: ${(props) => props.mid || props.max};
  }
  @media (max-width: 425px) {
    font-size: ${(props) => (props.min ? props.min : props.max)};
  }
`
export const StyledH4 = styled.h4`
  font-size: ${(props) => props.max};

  @media (max-width: 1280px) {
    font-size: ${(props) => props.mid || props.max};
  }
  @media (max-width: 425px) {
    font-size: ${(props) => (props.min ? props.min : props.max)};
  }
`
export const StyledH5 = styled.h5`
  font-size: ${(props) => props.max};

  @media (max-width: 1280px) {
    font-size: ${(props) => props.mid || props.max};
  }
  @media (max-width: 425px) {
    font-size: ${(props) => props.min || props.max};
  }
`
export const StyledH6 = styled.h6`
  font-size: ${(props) => props.max};

  @media (max-width: 1280px) {
    font-size: ${(props) => props.mid || props.max};
  }
  @media (max-width: 425px) {
    font-size: ${(props) => (props.min ? props.min : props.max)};
  }
`

export const StyledP = styled.p`
  font-size: ${(props) => props.max};

  @media (max-width: 1280px) {
    font-size: ${(props) => props.mid || props.max};
  }
  @media (max-width: 425px) {
    font-size: ${(props) => (props.min ? props.min : props.max)};
  }
`

export const StyledLink = styled(Link)`
  font-size: ${(props) => props.max};
  @media (max-width: 1280px) {
    font-size: ${(props) => props.mid || props.max};
  }
  @media (max-width: 425px) {
    font-size: ${(props) => (props.min ? props.min : props.max)};
  }
`

export const StyledA = styled.a`
  font-size: ${(props) => (props.max ? props.max : '16px')};

  @media (max-width: 1280px) {
    font-size: ${(props) => props.mid || props.max};
  }
  @media (max-width: 425px) {
    font-size: ${(props) => (props.min ? props.min : props.max)};
  }
`
