import cx from 'classnames'
import React, { useMemo } from 'react'
import PhoneInput from 'react-phone-input-2'

import '../phoneInput/phoneInput.css'

const CustomPhoneInput = (props) => {
  const {
    value,
    onChange,
    name,
    label,
    id,
    enableAreaCodes,
    hasError,
    required,
  } = props
  const inputId = useMemo(() => id ?? name ?? 'phone', [id, name])

  return (
    <div className='position-relative'>
      {!label ? null : (
        <label htmlFor={inputId}>
          {label}
          {required ? (
            <span className='text-danger font-size-16 ml-1'>*</span>
          ) : null}
        </label>
      )}
      <PhoneInput
        enableAreaCodes={enableAreaCodes}
        inputClass={cx({ 'border-danger': hasError })}
        value={value}
        country='ae'
        onChange={onChange}
        {...props}
        inputProps={{ name: name ?? 'phone', id: inputId }}
      />
    </div>
  )
}

export default CustomPhoneInput
