import { slide as Menu } from 'react-burger-menu'
import React from 'react'
import SidebarContent from './VerticalLayout/SidebarContent'
import { connect, useDispatch, useSelector } from 'react-redux'
import { showRightSidebarAction, toggleLeftmenu } from '../store/layout/actions'
import { withTranslation } from 'react-i18next'
import WiseButton from './WiseButton'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import logoDark from '../assets/images/identity/logo_main.png'
import { Col, Row } from 'reactstrap'
import burgerCloseIcon from '../../src/assets/images/burgerCloseIcon.svg'
import reg from '../assets/images/reg.svg'
import { StyledH5 } from './Typo'
import permissions from '../helpers/permissions'
import { userTypes } from '../helpers/enum'
import companySetting from '../assets/images/companysettings.svg'
import { usePermissions } from '../helpers/hooks'
import logout from '../assets/images/logout.svg'
import EntitiesMenu from './EntitiesMenu'
import { saveRoute } from '../store/actions'

const BurgerMenu = (props) => {
  const { leftMenu, toggleLeftmenu: toggleLeftMenu } = props

  const closeBurger = () => {
    toggleLeftMenu(false)
  }

  const user = useSelector((state) => state.Account?.user)
  const { history } = props
  const { hasAccess } = usePermissions()
  const dispatch = useDispatch()
  const styles = {
    bmMenuWrap: { top: 0 },
    bmOverlay: { top: 0, bottom: 0, zIndex: 1020 },
  }

  return (
    <Menu false isOpen={leftMenu} onClose={closeBurger} styles={styles}>
      <div className='bg-white h-100 d-flex flex-column'>
        <Row
          style={{ height: 56 }}
          className='p-0 m-0 d-flex px-3 justify-content-between align-items-center border-bottom'
        >
          <Link to='/' className=''>
            <img src={logoDark} alt='' height='22' />
          </Link>
          <WiseButton
            text={''}
            minWidth='32'
            minHeight='32px'
            bk='white'
            padding='0'
            icon={
              <img
                src={burgerCloseIcon}
                style={{ width: 14 }}
                alt='Close Icon'
              />
            }
            textColor='dark'
            onClick={closeBurger}
          />
        </Row>
        <div className='d-flex flex-column justify-content-between'>
          <SidebarContent closeBurger={closeBurger} />

          <div className='border-bottom' />

          <div
            className='d-flex flex-column p-3 justify-content-end flex-column bg-white'
            style={{ gap: '1rem' }}
          >
            {(hasAccess(permissions.ViewCompaniesInfo) ||
              hasAccess(permissions.UpdateDefaultPaymentMethods) ||
              hasAccess(permissions.InviteUsers) ||
              user?.type === userTypes.CONTRACTOR) && (
              <div className='d-flex align-items-center justify-content-between'>
                <button
                  onClick={() => {
                    history.push('/settings')
                    closeBurger()
                  }}
                  className='d-flex align-items-center bg-transparent border-0 flex-grow-1'
                  style={{ gap: '1rem' }}
                >
                  <img
                    src={
                      user?.type === userTypes.COMPANY ? companySetting : reg
                    }
                    style={{ width: 24 }}
                    alt={'company setting icon'}
                  />
                  <StyledH5 className='font-size-16 py-2 mb-0'>
                    {user?.type === userTypes.COMPANY
                      ? 'Company Settings'
                      : 'Profile Settings'}
                  </StyledH5>
                </button>
                {user?.type === userTypes.CONTRACTOR && (
                  <button
                    onClick={() => {
                      history.push('/logout')
                      dispatch(saveRoute(null))
                      closeBurger()
                    }}
                    className='bg-transparent border-0 p-1 rounded hover:bg-light'
                  >
                    <img src={logout} style={{ width: 24 }} alt='logout icon' />
                  </button>
                )}
              </div>
            )}
            {user?.type === userTypes.COMPANY && (
              <div className='d-flex align-items-center justify-content-between'>
                <button
                  onClick={() => {
                    history.push('/profile-settings')
                    closeBurger()
                  }}
                  className='d-flex align-items-center bg-transparent border-0 flex-grow-1'
                  style={{ gap: '1rem' }}
                >
                  <img src={reg} style={{ width: 24 }} alt='profile icon' />
                  <StyledH5 className='font-size-16 py-2 mb-0'>
                    Profile Settings
                  </StyledH5>
                </button>
                <button
                  onClick={() => {
                    history.push('/logout')
                    dispatch(saveRoute(null))
                    closeBurger()
                  }}
                  className='bg-transparent border-0 p-1 rounded hover:bg-light'
                >
                  <img src={logout} style={{ width: 24 }} alt='logout icon' />
                </button>
              </div>
            )}
          </div>
        </div>
        {user?.type === userTypes.CONTRACTOR &&
          hasAccess(permissions.switchAccount) &&
          user[0] &&
          user[1] && (
            <button
              className='dropdown-item mt-auto mb-3 d-flex align-items-center '
              onClick={() => {
                history.push('/switch')
                closeBurger()
              }}
            >
              <i className='bx bx-transfer font-size-24 align-middle mr-3 text-dark' />
              <Row>
                <Col>
                  <StyledH5 className='font-size-16 text-dark mb-0'>
                    Switch account
                  </StyledH5>
                  <span className='text-muted font-size-12'>{`To ${
                    user?.type === userTypes.CONTRACTOR
                      ? 'client'
                      : 'contractor'
                  } account`}</span>
                </Col>
              </Row>
            </button>
          )}
        {user?.type === userTypes.COMPANY && (
          <div
            className='mx-3 mt-auto mb-3 rounded-pill'
            style={{ backgroundColor: '#ebecf0' }}
          >
            <EntitiesMenu CloseMenu={closeBurger} />
          </div>
        )}
      </div>
    </Menu>
  )
}

const mapStateToProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout
  return { layoutType, showRightSidebar, leftMenu }
}

export default connect(mapStateToProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(withRouter(BurgerMenu)))
