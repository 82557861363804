import React, { useCallback, useEffect, useState } from 'react'
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from 'reactstrap'
import classnames from 'classnames'
import AccountMappingTab from './accountMappingTab'
import { useFetch } from '../../../../helpers/hooks'
import { getXeroIntegrationData, setupXero } from '../../../../services/api'
import toastr from 'toastr'
import VendorsSettingTab from './vendorsSettingsTab'
import { StyledH5 } from '../../../../components/Typo'
import {
  submitAccountMapping,
  updatePreData,
  updateRequest,
} from '../../../../store/xero/actions'
import { useDispatch, useSelector } from 'react-redux'
import XeroLogs from './Logs'
import styled from 'styled-components'
import axios from 'axios'

const UpdateXeroIntegration = () => {
  const [activeTab, setActiveTab] = useState(0)
  const [submitted, setSubmitted] = useState(false)

  const dispatch = useDispatch()

  const onComplete = (data) => {
    const accountMapping = {}
    data.categories.forEach((c) => {
      accountMapping[c.category_id] = c.xero_id
    })
    dispatch(submitAccountMapping(accountMapping))
  }

  const xero = useFetch({
    action: getXeroIntegrationData,
    successAction: updatePreData,
    autoFetch: true,
    onError: toastr.error,
    onComplete: onComplete,
  })

  const refresh = useCallback(
    (cancel) => {
      if (cancel) cancel()
      if (xero.data?.contractors_queued > 0) {
        // eslint-disable-next-line no-return-assign,no-unused-vars
        xero.startFetch(null, false, new axios.CancelToken((c) => (cancel = c)))
      }
    },
    [xero.data],
  )

  useEffect(() => {
    let cancel
    const interval = setInterval(() => refresh(cancel), 800)
    return () => {
      clearInterval(interval)
    }
  }, [xero.data])

  const request = useSelector((state) => state.xero.request)
  const update = useFetch({
    action: setupXero,
    onError: toastr.error,
    onComplete: () => {
      setTimeout(() => {
        xero.startFetch(null, false)
      }, 800)
      dispatch(updateRequest(null))
    },
  })

  useEffect(() => {
    if (request && submitted) {
      update.startFetch(request)
      setSubmitted(false)
    }
  }, [request, submitted])

  useEffect(() => {
    return () => {
      dispatch(updateRequest(null))
    }
  }, [])

  const onUpdate = () => {
    setSubmitted(true)
  }

  const tabs = [
    {
      label: 'Accounts',
      component: (
        <AccountMappingTab
          data={xero.data}
          loading={update.isLoading}
          onSubmitted={onUpdate}
        />
      ),
    },
    {
      label: 'Team',
      component: (
        <VendorsSettingTab
          data={xero.data}
          loading={update.isLoading}
          onSubmitted={onUpdate}
        />
      ),
    },
    {
      label: 'Invoices',
      component: (
        <XeroLogs
          onSubmitted={onUpdate}
          onUpdate={() => xero.startFetch(null, true)}
        />
      ),
    },
  ]
  return (
    <div className='page-content'>
      {xero.isLoading ? (
        <Container style={{ minHeight: '100vh' }}>
          <Col style={{ minHeight: '100vh' }}>
            <Row
              style={{ minHeight: '100vh' }}
              className='justify-content-center align-items-center'
            >
              <Spinner type='grow' color='primary' />
            </Row>
          </Col>
        </Container>
      ) : (
        <>
          <div className={'mb-0 mb-md-2 d-flex'}>
            <StyledH5 min={'22px'} mid={'28px'} max={'32px'}>
              Xero
            </StyledH5>
          </div>

          <StyledNav tabs className='nav-tabs-custom'>
            {tabs.map((nav, i) => (
              <NavItem key={`xeroIntegrationNav:${i}`}>
                <NavLink
                  className={classnames({ active: activeTab === i })}
                  onClick={() => {
                    setActiveTab(i)
                  }}
                >
                  <p className=' font-size-14 mb-0'>{nav.label}</p>
                </NavLink>
              </NavItem>
            ))}
          </StyledNav>
          <TabContent activeTab={activeTab} className='mt-3'>
            {tabs.map((t, i) => (
              <TabPane key={`tab:${i}`} tabId={i}>
                {t.component}
              </TabPane>
            ))}
          </TabContent>
        </>
      )}
    </div>
  )
}

UpdateXeroIntegration.propTypes = {}

export const StyledNav = styled(Nav)`
  flex-wrap: nowrap !important;
  overflow-x: auto;
  overflow-y: hidden;

  .nav-item {
    margin: 0 42px 0 0;

    .nav-link {
      white-space: nowrap;
      padding: 16px 0;

      font-weight: 400;
      font-size: 14px;

      &::after {
        height: 3px;
        background-color: var(--primary);
      }
    }

    .active {
      color: var(--primary) !important;
      font-weight: 600;
      background-color: #f4f5f7;
    }
  }

  @media (max-width: 768px) {
    .nav-item {
      margin: 0 24px 0 0 !important;
    }
  }
`

export default UpdateXeroIntegration
