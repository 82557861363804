import cx from 'classnames'
import React from 'react'
import { Col, Row } from 'reactstrap'

import { StyledH4, StyledP } from '../Typo'

const NoContent = ({
  title,
  actionTitle,
  onAction,
  withAction,
  subtitle,
  customBottom,
  actionIcon = 'fas fa-plus',
  advanced = true,
  image,
  headline,
}) => {
  return (
    <>
      <h4 className='mb-3 font-size-18'>{title}</h4>

      <Col xl={12} className='justify-content-center pb-4'>
        {advanced && (
          <>
            <Row className='justify-content-center d-none d-md-flex'>
              <img src={image} width='400rem' height='350rem' alt={''} />
            </Row>
            <Row className='justify-content-center d-flex d-md-none'>
              <img src={image} width='300rem' height='300rem' alt={''} />
            </Row>
            <Row className='justify-content-center'>
              <StyledH4
                min='22px'
                max='32px'
                className='text-gray-h rp-font-gilroyB text-center mb-0'
              >
                {headline === '' ? '' : headline || 'Welcome to RemotePass'}
              </StyledH4>
            </Row>
          </>
        )}
        <Row className={cx('justify-content-center', { 'mt-3': advanced })}>
          <StyledP
            min={'14px'}
            max={'16px'}
            style={{ width: '70%' }}
            className='text-secondary  text-center'
          >
            {subtitle || 'Manage contracts, compliance and make payments'}
          </StyledP>
        </Row>
        {withAction && (
          <>
            {customBottom ? (
              <Row className={'d-flex justify-content-center mb-5'}>
                {customBottom}
              </Row>
            ) : (
              <Row className='justify-content-center mb-5'>
                <button onClick={onAction} className='btn btn-primary'>
                  {actionIcon !== null && (
                    <i style={{ marginRight: '10px' }} className={actionIcon} />
                  )}
                  {actionTitle}
                </button>
              </Row>
            )}
          </>
        )}
      </Col>
    </>
  )
}

export default NoContent
