const FEATURE_FLAGS = {
  LOCK_PERSONAL_INFO: true,
  NEW_PAYONEER_INTEGRATION: true,

  SENTRY_LOGGING: !!JSON.parse(process.env.REACT_APP_SENTRY_LOGGING ?? false),

  REMOTEPASS_CARDS: !!JSON.parse(
    process.env.REACT_APP_REMOTEPASS_CARDS ?? false,
  ),
  REMOTEPASS_PHYSICAL_CARDS: !!JSON.parse(
    process.env.REACT_APP_REMOTEPASS_PHYSICAL_CARDS ?? false,
  ),
  REMOTEPASS_PERKS: !!JSON.parse(
    process.env.REACT_APP_REMOTEPASS_PERKS ?? false,
  ),
  REMOTEPASS_REFERRALS: !!JSON.parse(
    process.env.REACT_APP_REMOTEPASS_REFERRALS ?? false,
  ),
  REMOTEPASS_REFERRALS_REGISTRATION_ON_SIGNUP: !!JSON.parse(
    process.env.REACT_APP_REMOTEPASS_REFERRALS_REGISTRATION_ON_SIGNUP ?? false,
  ),

  REMOTEPASS_PAYROLL_APPROVAL_PROCESS: !!JSON.parse(
    process.env.REACT_APP_PAYROLL_APPROVAL_PROCESS ?? false,
  ),

  PAYMENT_METHOD_BREX: !!JSON.parse(
    process.env.REACT_APP_PAYMENT_METHOD_BREX ?? false,
  ),

  REMOTEPASS_REPORTS_PAGE: !!JSON.parse(
    process.env.REACT_APP_REPORTS_PAGE ?? false,
  ),

  CONTRACT_CREATION_V2: !!JSON.parse(
    process.env.REACT_APP_CONTRACT_CREATION_V2 ?? false,
  ),

  COMPANY_ARCHIVE_POPUP: !!JSON.parse(
    process.env.REACT_APP_COMPANY_ARCHIVE_POPUP ?? false,
  ),
  NEW_INSURANCE_CANCELATION: !!JSON.parse(
    process.env.REACT_APP_NEW_INSURANCE_CANCELATION ?? false,
  ),
  INSURANCE_BANNER: !!JSON.parse(
    process.env.REACT_APP_INSURANCE_BANNER ?? false,
  ),
}

export default FEATURE_FLAGS
