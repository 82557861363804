import React from 'react'
import { Button, Col, Row, Spinner } from 'reactstrap'

import paymentConfirmation from '../../assets/images/payment_confirmation.svg'
import { BANK_TRANSFER } from '../../core/config/payment-methods'
import { useResize } from '../../helpers/hooks'

const ConfirmationPage = ({
  onComplete,
  loading,
  confirmed,
  method,
  onDownload,
}) => {
  const isMobile = useResize()

  // bank like methods (wise, mercury)
  const isBankTransferLike =
    method?.id === BANK_TRANSFER.id || !!method?.payment_account_id

  return loading ? (
    <Col style={{ minHeight: '50vh' }}>
      <Row
        style={{ minHeight: '50vh' }}
        className='justify-content-center align-items-center'
      >
        <Spinner type='grow' className='mr-2' color='primary' />
      </Row>
    </Col>
  ) : (
    <Row className='justify-content-center'>
      <Col className='p-0'>
        <Row
          className='justify-content-center align-items-center'
          style={{ minHeight: '75vh' }}
        >
          <Col>
            <Row
              className='justify-content-center'
              style={{ fontSize: '10rem' }}
            >
              {confirmed ? (
                <img
                  height={isMobile ? 200 : 400}
                  src={paymentConfirmation}
                  alt=''
                />
              ) : (
                <i className='bx bx-x-circle text-danger' />
              )}
            </Row>
            {confirmed && (
              <Row className='justify-content-center text-center font-size-16'>
                <Col>
                  {isBankTransferLike ? (
                    <p className='text-muted'>
                      Your payment transaction status is processing. Once your
                      payment is fully processed, you will be notified and the
                      funds will be immediately released to your contractors.
                    </p>
                  ) : (
                    <p className='text-muted'>
                      Your payment transaction status is pending. Once your
                      payment is received, you will be notified and the funds
                      will be immediately released to your contractors.
                    </p>
                  )}
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <Row className='justify-content-center align-items-center'>
          <Button
            onClick={onComplete}
            className='my-2 font-weight-bold'
            color='primary'
          >
            Go to Transactions
          </Button>

          {!onDownload ? null : (
            <Button
              onClick={onDownload}
              className='my-2 bg-soft-primary text-primary border-0 font-weight-bold ml-2'
            >
              Download Statement{' '}
              <i
                className='bx bx-download font-size-18 ml-2'
                style={{
                  marginBottom: -5,
                }}
              />
            </Button>
          )}
        </Row>
      </Col>
    </Row>
  )
}

export default ConfirmationPage
