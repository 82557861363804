import cx from 'classnames'
import React from 'react'
import { Button } from 'reactstrap'
import styled from 'styled-components'

const StyledButton = styled(Button)`
  height: ${(props) => props.height || '40px'};
  width: ${(props) => props.width || 'auto'};
  border: ${(props) => (props.$border ? '1px solid  #E7E8F2' : 0)};
  padding: ${(props) => (props.padding ? props.padding : null)};

  @media (max-width: 754px) {
    height: ${(props) => props.$minHeight || '40px'};
    width: ${(props) => props.$minWidth || 'auto'};
  }
`

const WiseButton = (props) => {
  const {
    height,
    width,
    bk,
    onClick,
    padding,
    type,
    disabled,
    className = 'd-flex align-items-center justify-content-center font-size-14',
    classN,
  } = props
  const {
    icon,
    text,
    textColor,
    minWidth,
    minHeight,
    classNameAdd,
    loading,
    border,
    bold: _,
    ...btnProps
  } = props
  return (
    <StyledButton
      onClick={onClick}
      color={`${bk || 'primary'}`}
      className={cx(classN || className, classNameAdd)}
      type={type || 'button'}
      height={height}
      width={width}
      padding={padding}
      disabled={disabled}
      $border={border}
      $minWidth={minWidth}
      $minHeight={minHeight}
      {...btnProps}
    >
      <div className='d-flex justify-content-center align-items-center'>
        {loading && <i className='bx bx-loader bx-spin font-size-14 mx-2' />}

        <div
          style={{ fontWeight: '500', whiteSpace: 'nowrap' }}
          className={`d-flex align-items-center mb-0 text-${
            textColor || 'white'
          }`}
        >
          {icon} {text}
        </div>
      </div>
    </StyledButton>
  )
}

export default WiseButton
