import React from 'react'
import { Col, Row } from 'reactstrap'
import moment from 'moment'
import { useSelector } from 'react-redux'
import WiseButton from '../../../components/WiseButton'
import filedownload from '../../../assets/images/download.svg'
import { UploadArea } from '../InvoicesList'
import { useFetch } from '../../../helpers/hooks'
import { uploadYourInvoice } from '../../../services/api'
import toastr from 'toastr'
import { userTypes } from '../../../helpers/enum'
import classnames from 'classnames'

const InvoiceCard = (props) => {
  const user = useSelector((state) => state?.Account?.user)
  const { invoice, handleDownloadInvoice } = props
  const partner = user?.type === 'client' ? invoice.contractor : invoice.client

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: invoice?.currency?.code || 'USD',
  })

  const upload = useFetch({
    action: uploadYourInvoice,
    onError: (err) => toastr.error(err),
  })
  return (
    <div
      className='mb-3'
      style={{
        background: '#FFFFFF',
        border: '1px solid #E7E8F2',
        borderRadius: 4,
      }}
    >
      <div className='d-flex justify-content-between align-items-center border-bottom p-3 m-0 p-0'>
        <p
          className='text-dark mb-0 font-weight-bold'
          style={{ fontWeight: 'bold' }}
        >
          Invoice {invoice.invoice_ref}
        </p>
      </div>

      <div className='d-flex justify-content-between align-items-center pt-3 px-3'>
        <p className='text-dark font-size-14  mb-0 '>
          {partner?.first_name} {partner?.last_name}
        </p>
        <p className='text-dark font-size-14 mb-0'>
          {moment(invoice.invoice_date * 1000).format('MM/DD/YYYY HH:mm')}
        </p>
      </div>

      <div className='d-flex justify-content-between align-items-center pt-3 px-3'>
        <p className='text-dark font-size-14 mb-0'>Total</p>
        <p className='text-dark font-size-14 mb-0'>
          {formatter.format(invoice?.total)}
        </p>
      </div>
      <Row className='m-0  p-3'>
        <Col
          className={classnames('m-0 p-0', {
            'pr-1': user?.type === userTypes.CONTRACTOR,
          })}
        >
          <WiseButton
            textColor={'dark'}
            onClick={() => handleDownloadInvoice(invoice)}
            bk={'white'}
            border
            minWidth={'100%'}
            text={'Invoice'}
            icon={
              <img
                src={filedownload}
                style={{ width: '1em', marginRight: 8 }}
                alt='trash icon'
              />
            }
          />
        </Col>

        {user?.type === userTypes.CONTRACTOR && (
          <Col className='m-0 p-0 pl-1'>
            <UploadArea
              onFileChanges={async (files) => {
                if (files[0]) {
                  if (files[0].size > 2000000) {
                    toastr.error('The file may not be greater than 2MB')
                  } else {
                    upload.startFetch({
                      invoice_id: invoice?.id,
                      file: files[0],
                    })
                  }
                }
              }}
            >
              <WiseButton
                textColor={'dark'}
                bk={'white'}
                border
                minWidth={'100%'}
                className='btn btn-block'
                text={'Replace'}
                icon={
                  upload.isLoading ? (
                    <i className='bx bx-loader bx-spin font-size-16 align-middle mx-1' />
                  ) : (
                    <i className='bx bx-upload font-size-16 mx-1' />
                  )
                }
              />
            </UploadArea>
          </Col>
        )}
      </Row>
    </div>
  )
}

export default InvoiceCard
