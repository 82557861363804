import cx from 'classnames'
import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import Button from '../../../../components/ui/button'
import { useResize } from '../../../../helpers/hooks'

const FormsModal = ({
  isOpen,
  toggle,
  onConfirm = () => {},
  children,
  title,
  isLoading,
}) => {
  const isMobile = useResize()
  return (
    <Modal
      isOpen={isOpen}
      autoFocus={true}
      scrollable={false}
      toggle={toggle}
      unmountOnClose={true}
      size='lg'
    >
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter className={cx({ 'fixed-bottom': isMobile })}>
        <Button color='light' outline onClick={toggle}>
          Cancel
        </Button>
        <Button disabled={isLoading} loading={isLoading} onClick={onConfirm}>
          Save Changes
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default FormsModal
