import cx from 'classnames'
import React from 'react'
import CurrencyInput from 'react-currency-input-field'
import { Controller } from 'react-hook-form'

import { getInputErrorMessage } from '../Forms/get-input-error-message'
import InputFeedback from '../ui/input-feedback'

const ControlledCurrencyInput = ({
  control,
  name,
  defaultValue,
  error,
  isDisabled,
  id,
  label,
  transform,
  className,
  ...prop
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value, onBlur, onChange, name } }) => (
        <>
          {!label ? null : <label htmlFor={id}>{label}</label>}

          <CurrencyInput
            className={cx(
              'form-control',
              { 'border-danger': !!error },
              className,
            )}
            name={name}
            value={transform?.input ? transform.input(value) : value}
            onValueChange={(e) =>
              transform?.output ? onChange(transform.output(e)) : onChange(e)
            }
            onBlur={onBlur}
            disabled={isDisabled}
            id={id}
            // currency input props
            allowDecimals={true}
            decimalsLimit={2}
            decimalSeparator='.'
            groupSeparator=','
            {...prop}
          />

          {!error ? null : (
            <InputFeedback className='mt-1'>
              {getInputErrorMessage(error)}
            </InputFeedback>
          )}
        </>
      )}
    />
  )
}

export default ControlledCurrencyInput
