export const userTypes = {
  CONTRACTOR: 'contractor',
  COMPANY: 'client',
}

export const clientRole = {
  ACCOUNTANT: 'Accountant',
  MANAGER: 'Manager',
  ADMIN: 'Admin',
  HR: 'HR',
}

export const contractTypes = {
  FIXED: 'FIXED',
  PAYG: 'PAYG',
  MILESTONES: 'MILESTONES',
  FULL_TIME: 'FULL_TIME',
}
export const contractorTypes = {
  INDIVIDUAL: 'individual',
  ENTITY: 'entity',
}

export const CONTRACT_STATUS = {
  ONGOING: { label: 'Ongoing', value: 4 },
}

export const CONTRACT_CATEGORY = {
  CONTRACTOR: 'CONTRACTOR',
  EMPLOYEE: 'EMPLOYEE',
}

export const EMPLOYEE_CONTRACT_TYPE = {
  EOR: 'EOR',
  DIRECT_EMPLOYEE: 'DIRECT_EMPLOYEE',
}

export const CONTRACT_DOC_TYPE = {
  RP_TEMPLATE: 'RP_TEMPLATE',
  CUSTOM: 'CUSTOM',
  CUSTOM_TEMPLATE: 'CUSTOM_TEMPLATE',
}

export const DE_PAYMENT_PROVIDER = {
  RP_PROVIDER: 'RP_PROVIDER',
  EXTERNAL_PROVIDER: 'EXTERNAL_PROVIDER',
}
