import React, { useEffect, useRef } from 'react'

const Editable = ({
  editable,
  content,
  quillEditorContainer,
  onChangeActive,
  isActive,
  withIcon,
}) => {
  // The element that will displat my editable's content when the editor is not active
  const contentEl = useRef()
  // The element that will contain the quill editor element when I'm the active Editable
  const quillEditorParent = useRef()

  // Set the contents of my contentEl when it changes
  useEffect(() => {
    contentEl.current.innerHTML = content
  }, [content])

  /**
   * When prop isActive is true, detach the quill editor element
   * from the temporary container of App, moving it to my own container for it.
   *
   * Also, toggle the visibility of contentEl and quillEditorParent depending
   * on if I'm active or not.
   */
  useEffect(() => {
    if (isActive) {
      quillEditorParent.current.appendChild(quillEditorContainer.current)
    }

    quillEditorParent.current.style.display = isActive ? 'inline' : 'none'
    contentEl.current.style.display = isActive ? 'none' : 'inline'
  }, [quillEditorParent, quillEditorContainer, isActive])

  /**
   * If I'm the active Editable, listen for user hitting the escape button
   * and deactivate me.
   */
  useEffect(() => {
    if (isActive) {
      const onKeyUp = (event) => {
        if (event.code === 'Escape') {
          activate(false)
        }
      }

      document.addEventListener('keyup', onKeyUp)
      return () => document.removeEventListener('keyup', onKeyUp)
    }
  }, [isActive])

  /**
   * Message App that I want to be the active editable.
   */
  const activate = (active) => {
    onChangeActive(editable, active)
  }

  return (
    <div className='editable' id={editable.id} onClick={() => activate(true)}>
      {withIcon && (
        <a>
          <i
            className='bx bx-edit-alt text-primary font-size-16 border border-primary p-1 rounded-circle'
            style={{ position: 'absolute', top: 12, right: 12 }}
          />
        </a>
      )}
      <div ref={quillEditorParent} />
      <div ref={contentEl} />
    </div>
  )
}

export default Editable
