import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useSelector } from 'react-redux'
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import Input from 'reactstrap/lib/Input'
import styled from 'styled-components'
import toastr from 'toastr'

import { StyledH5 } from '../../../components/Typo'
import { useFetch, useResize } from '../../../helpers/hooks'
import { getOCR, uploadContractorDoc } from '../../../services/api'
import uploadFiles from './../../../assets/images/uploadFiles.svg'

const UploadModal = ({ isOpen, toggle, doc, update }) => {
  const [title, setTitle] = useState(null)
  const [file, setFile] = useState(null)
  const user = useSelector((state) => state?.Account?.user)
  const upload = useFetch({
    action: uploadContractorDoc,
    checkSuccess: true,
    onComplete: (_, body) => {
      toggle()
      update()
      window.analytics.track('Uploaded document', {
        contractor_id: user?.id,
        document_name: body?.title,
      })
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  const ocr = useFetch({
    action: getOCR,
  })
  const { acceptedFiles, getRootProps, getInputProps, inputRef } = useDropzone({
    acceptedFiles: 'image/*,application/pdf',
  })
  const files = acceptedFiles.map((file) => (
    <p className='mb-0' key={file.path}>
      {file.path}
    </p>
  ))

  useEffect(() => {
    setTitle({ value: doc?.type_id ? doc?.title : '' })
    removeAll()
  }, [isOpen])
  const removeAll = () => {
    if (inputRef && inputRef.current) {
      acceptedFiles.length = 0
      acceptedFiles.splice(0, acceptedFiles.length)
      inputRef.current.value = ''
    }
  }
  useEffect(() => {
    if (!!acceptedFiles && acceptedFiles?.length > 0) {
      if (acceptedFiles[0].size > 2000000) {
        toastr.error('The file may not be greater than 2MB')
        removeAll()
      } else {
        setFile(acceptedFiles[0])
      }
    }
  }, [acceptedFiles])
  const handleUploadClick = () => {
    if (!title?.value) {
      toastr.error('Please enter the document title')
      return
    }
    if (!file) {
      toastr.error('Please pick a file first')
      return
    }
    if (acceptedFiles[0].size > 2000000) {
      toastr.error('The file may not be greater than 2MB')
      return
    }
    upload.startFetch({
      file,
      title: title?.value,
      type_id: doc?.type_id,
    })
  }

  const isMobile = useResize()
  return (
    <Modal
      className={'uploadModal'}
      unmountOnClose
      backdrop={!upload.isLoading}
      centered
      isOpen={isOpen}
      role='dialog'
      autoFocus
      toggle={toggle}
    >
      <div className='modal-content'>
        <ModalHeader toggle={toggle}>Upload Document</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Input
                placeholder='Title'
                value={title?.value}
                disabled={!!doc?.type_id}
                onChange={(e) => {
                  setTitle({ ...title, value: e.target.value, error: false })
                }}
              />
              <section className='p-0 mt-3'>
                <div className='dropzone border-primary'>
                  <div
                    className='dz-message needsclick bg-soft-primary'
                    {...getRootProps()}
                  >
                    <input
                      {...getInputProps()}
                      accept='image/*,application/pdf'
                    />

                    {!!acceptedFiles && acceptedFiles?.length > 0 ? (
                      <div
                        className='d-flex justify-content-between align-items-center font-size-14'
                        style={{ minHeight: 149 }}
                      >
                        {files}
                        <button
                          className='rp-btn-nostyle text-danger p-1'
                          onClick={removeAll}
                        >
                          <i className='bx bx-trash' />
                        </button>
                      </div>
                    ) : (
                      <>
                        <div className='py-4'>
                          <img src={uploadFiles} alt='' />
                        </div>
                        <Row>
                          <Col className='justify-content-center'>
                            <StyledH5 min={'14px'} max={'16px'}>
                              Drop files here or click to upload.
                            </StyledH5>
                            <p className='text-muted font-size-12'>
                              Max file size 2MB
                            </p>
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                </div>
              </section>
            </Col>
          </Row>
        </ModalBody>
        <StyledModalFooter className={isMobile ? 'fixed-bottom' : ''}>
          <StyledBtnCont>
            <Button
              disabled={upload.isLoading || ocr.isLoading}
              type='button'
              color='white'
              className={'border-light mr-md-3'}
              onClick={toggle}
            >
              Cancel
            </Button>

            <Button
              disabled={upload.isLoading || ocr.isLoading}
              type='button'
              color='primary'
              onClick={handleUploadClick}
            >
              {(upload.isLoading || ocr.isLoading) && (
                <i className='bx bx-loader bx-spin font-size-16 align-middle mr-2'></i>
              )}
              Upload
            </Button>
          </StyledBtnCont>
        </StyledModalFooter>
      </div>
    </Modal>
  )
}

const StyledBtnCont = styled.div`
  @media (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`
const StyledModalFooter = styled(ModalFooter)`
  @media (max-width: 767px) {
    margin-top: 33vh;
  }
`

export default UploadModal
