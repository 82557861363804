import React, { createContext, useContext, useState } from 'react'

export const formDataContext = createContext({})

const { Provider } = formDataContext

export const dataKeys = {
  jurisdiction: 'jurisdiction',
  jurisdictionId: 'jurisdictionId',
}

export function FormDataProvider({ children }) {
  const [formData, setFormData] = useState({})

  function setItem(name, value) {
    if (!Object.values(dataKeys).includes(name)) {
      throw new Error(`Invalid key: ${name}`)
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }))
    }
  }

  return (
    <Provider value={{ formData, setItem, setFormData }}>{children}</Provider>
  )
}

export function useFormData() {
  const { formData, setFormData, setItem } = useContext(formDataContext)

  return { formData, setFormData, setItem }
}
