import React, { useState } from 'react'
import { declineMilestone, declineWork } from '../../../services/api'
import { FormGroup, Input, Label, Modal } from 'reactstrap'
import { useSelector } from 'react-redux'
import toastr from 'toastr'

const DeclineModal = ({ isOpen, toggle, onDeclined, isMilestone, e, data }) => {
  const [comment, setComment] = useState(null)
  const user = useSelector((state) => state?.Account?.user)

  const onDecline = () => {
    if (isMilestone) {
      declineMilestone(user?.token, { work_id: e?.id, comment })
        .then((r) => {
          if (r.data.success) {
            toggle()
            onDeclined()
          } else {
            toastr.error(r.data?.data?.comment[0] || r.data?.message)
          }
        })
        .catch((e) => {
          console.log('e', e)
        })
    } else {
      declineWork(user?.token, { work_id: e?.id, comment })
        .then((r) => {
          if (r.data.success) {
            window.analytics.track('Declined work', {
              'contract-id': data.ref,
              'contract-type': data.type,
              'work-id': e?.id,
              'work-name': e?.details,
              'work-value': e?.qty,
              currency: data.currency?.code,
            })
            toggle()
            onDeclined()
          }
          console.log(r)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <div className='modal-content'>
        <div className='modal-header border-0 pb-0 '>
          <Label>Reason</Label>
          <button
            type='button'
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={toggle}
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <div className='modal-body'>
          <FormGroup>
            <Input
              name={'name'}
              placeholder='Reason'
              type='text'
              className='form-control'
              onChange={(e) => setComment(e.target.value)}
              id='basicpill-pancard-input5'
            />
          </FormGroup>
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-outline-light'
            data-dismiss='modal'
            onClick={toggle}
          >
            Cancel
          </button>
          <button type='button' className='btn btn-danger' onClick={onDecline}>
            Decline
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default DeclineModal
