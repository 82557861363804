import cx from 'classnames'
import React from 'react'
import { useDropzone } from 'react-dropzone'

export default function DropzoneInput({
  children,
  onDropAccepted,
  onDropRejected,
  accept,
  required,
  invalid,
  name,
  id,
  className,
  inputClassName,
  rootRef,
  inputRef,
  disabled,
  multiple = false,
  style = {},
  unstyled = false,
  maxFiles,
}) {
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    multiple,
    onDropAccepted,
    onDropRejected,
    disabled,
    maxFiles,
  })

  return (
    <div
      {...getRootProps({
        className: cx(
          !unstyled && 'rounded p-4 cursor-pointer',
          !unstyled && {
            'text-primary bg-soft-primary border-primary':
              !invalid && !disabled,
            'bg-soft-danger border-danger': invalid,
            'text-secondary opacity-50 bg-soft-secondary border-secondary cursor-default':
              disabled,
          },
          className,
        ),
        ref: rootRef,
      })}
      style={{ border: !unstyled && `2px dashed`, ...style }}
    >
      <input
        {...getInputProps({
          ref: inputRef,
        })}
        required={required}
        className={inputClassName}
        name={name}
        id={id ?? name}
        disabled={disabled}
      />
      {children}
    </div>
  )
}
