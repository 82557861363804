import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router'
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

import Toggle from '../../components/Forms/Toggle/Toggle'
import { StyledH3 } from '../../components/Typo'
import Button from '../../components/ui/button'
import Loader from '../../components/ui/loader'
import FEATURE_FLAGS from '../../config/feature-flags'
import { useFetch } from '../../helpers/hooks'
import {
  connectPaypal,
  updateContractorProfile,
  verifyCoinbase,
  verifyPaypal,
} from '../../services/api'
import KYCModal from '../Contract/components/KYCModal'
import BankAccounts from '../withdrawProcess/BankAccounts'
import CashPointAccount from '../withdrawProcess/CashPointAccount'
import CoinbaseAccounts from '../withdrawProcess/CoinbaseAccounts'
import PayoneerAccount from '../withdrawProcess/payoneer-account'
import PaypalAccounts from '../withdrawProcess/PaypalAccounts'
import coinbaseIllustration from './../../assets/images/coinbase_icon.svg'
import infoIllustration from './../../assets/images/info.png'
import payoneerIllustration from './../../assets/images/payoneer-logo.png'
import warningIllustration from './../../assets/images/warning.png'
import CashPointCard from './components/CashPointCard'
import CoinbaseMethodCard from './components/CoinbaseMethodCard'
import PayoneerMethodCard from './components/payoneer-method-card'
import PaypalMethodCard from './components/PaypalMethodCard'
import WithdrawMethodCard from './components/WithdrawMethodCard'

const METHODS = {
  BANK: { label: 'Bank', value: 0 },
  PAYPAL: { label: 'PayPal', value: 1 },
  CASH_POINT: { label: 'Cash Point', value: 2 },
  PAYONEER_EMAIL: { label: 'Payoneer Email', value: 3 },
  COINBASE: { label: 'Coinbase', value: 4 },
  PAYONEER: { label: 'Payoneer', value: 5 },
}

const modalTitle = Object.values(METHODS).reduce((acc, entry) => {
  acc[entry.value] = entry.label
  return acc
}, {})

const WithdrawalMethod = ({
  profile,
  data,
  paypal,
  onUpdate,
  loading,
  cashPoints,
  payoneer,
  coinbase,
}) => {
  const user = useSelector((state) => state?.Account?.user)
  const [method, setMethod] = useState(0)
  const [menu, setMenu] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showWarnAutoWithdrawChange, setShowWarnAutoWithdrawChange] =
    useState(null)
  const [selected, setSelected] = useState({
    id: profile?.prefered_withdraw_method_id,
    type: profile?.prefered_withdraw_method_type,
  })
  const [isAutoWithdraw, setIsAutoWithdraw] = useState(
    profile?.auto_withdraw === 1,
  )
  const [showKycModal, setShowKycModal] = useState(false)
  const isEmployee = useSelector(
    (state) => state.userProfile?.userProfile?.contractor_type === 'employee',
  )
  const userProfile = useSelector((state) => state?.userProfile?.userProfile)

  const location = useLocation()

  const { type } = useParams()
  const code = new URLSearchParams(location.search).get('code')

  const isLebanon = profile?.Country_of_Tax_Residence?.name === 'Lebanon'
  const [showAutoWithdrawInfoModal, setShowAutoWithdrawInfoModal] =
    useState(false)
  const [showAutoWithdrawWarning, setShowAutoWithdrawWarning] = useState(false)

  const history = useHistory()

  const savedMethods = useMemo(() => {
    return [
      ...data.map((e) => ({ ...e, type: METHODS.BANK.value })),
      ...paypal.map((e) => ({
        ...e,
        type: METHODS.PAYPAL.value,
      })),
      ...cashPoints.map((e) => ({
        ...e,
        type: METHODS.CASH_POINT.value,
      })),
      ...payoneer.map((e) => ({ ...e, type: METHODS.PAYONEER.value })),
      ...coinbase.map((e) => ({ ...e, type: METHODS.COINBASE.value })),
    ]
  }, [data, paypal, cashPoints, payoneer, coinbase])

  const updateProfile = useFetch({
    action: updateContractorProfile,
    onComplete: onUpdate,
    onerror: (err) => {
      toastr.error(err)
      onUpdate()
    },
  })

  useEffect(() => {
    setSelected({
      id: profile?.prefered_withdraw_method_id,
      type: profile?.prefered_withdraw_method_type,
    })
    setIsAutoWithdraw(profile?.auto_withdraw === 1)
  }, [profile])

  useEffect(() => {
    window.analytics.track('Viewed withdraw methods', {
      email_id: user?.email,
    })
  }, [])

  const handleAutoWithdrawCheck = (value) => {
    function updateAutoWithdraw(value) {
      updateProfile.startFetch({ auto_withdraw: value ? 1 : 0 })
      window.analytics.track(
        value ? 'Enabled auto-withdraw' : 'Disabled auto-withdraw',
        { email_id: user?.email },
      )
    }

    if (value) {
      if (!selected.id) {
        toastr.error('To set auto withdraw you need a default withdraw method')
      } else if (!['bank', 'payoneer'].includes(selected.type)) {
        toastr.error(
          'The default withdraw method must be a bank or payoneer account',
        )
      } else if (selected.id) {
        updateAutoWithdraw(value)
      }
    } else {
      setShowAutoWithdrawWarning(true)
      updateAutoWithdraw(value)
    }
  }

  const connect = useFetch({
    action: connectPaypal,
    onComplete: (data) => {
      if (data?.url) {
        window.location.replace(data?.url)
      }
    },
  })

  useFetch({
    action: verifyPaypal,
    autoFetch: type === 'paypal',
    body: { code },
    onComplete: () => {
      onUpdate()
      onAccountAdded()
      history.replace(
        location?.pathname.includes('settings')
          ? '/settings/paypal'
          : '/withdrawal-process/paypal',
      )
    },
    onError: (err) => {
      toastr.error(err)
      history.replace(
        location?.pathname.includes('settings')
          ? '/settings/paypal'
          : '/withdrawal-process/paypal',
      )
    },
  })
  useFetch({
    action: verifyCoinbase,
    autoFetch: type === 'coinbase' && !!code,
    body: { code, type: 'settings' },
    onComplete: () => {
      onUpdate()
      history.replace(
        location?.pathname.includes('settings')
          ? '/settings/coinbase'
          : '/withdrawal-process/coinbase',
      )
    },
    onError: (err) => {
      toastr.error(err)
      history.replace(
        location?.pathname.includes('settings')
          ? '/settings/coinbase'
          : '/withdrawal-process/coinbase',
      )
    },
  })

  const onAccountAdded = () => {
    setShowAutoWithdrawInfoModal(true)
  }

  function handleSetCashPointAsDefault() {
    const acc = showWarnAutoWithdrawChange
    updateProfile.startFetch({
      prefered_withdraw_method_id: acc?.id,
      prefered_withdraw_method_type: 'cashPoint',
    })
    setShowAutoWithdrawInfoModal(true)
    setShowWarnAutoWithdrawChange(null)
    setSelected({ id: acc.id, type: 'cashPoint' })
  }

  const { kyc_doc_missing: kycDocMissing, kyc_verified: kycVerified } =
    userProfile

  const needsKyc = kycVerified !== 1 && !kycDocMissing

  function handleMenuToggle() {
    if (needsKyc) {
      setShowKycModal(true)
    } else {
      setMenu((menu) => !menu)
    }
  }

  return (
    <div>
      {loading ? (
        <Loader minHeight='100vh' />
      ) : (
        <Row className='m-0 p-0'>
          <Col xs={12} md={6} className='m-md-0 p-md-0 px-md-3'>
            <StyledH3
              min='16px'
              max='24px'
              mid='18px'
              style={{ fontFamily: 'gilroyB' }}
              className='my-md-4 my-3 text-dark text-weight-bold'
            >
              Saved Methods
            </StyledH3>
            <div className='d-flex flex-column' style={{ gap: '1.5rem' }}>
              {React.Children.toArray(
                savedMethods.map((acc, i) => {
                  switch (acc.type) {
                    case METHODS.BANK.value: {
                      return (
                        <WithdrawMethodCard
                          deactivated={!acc?.active}
                          withTag
                          withRemove
                          onUpdate={onUpdate}
                          card={acc}
                          index={i}
                          selected={acc.id === selected?.id}
                          onSelect={() => {
                            updateProfile.startFetch({
                              prefered_withdraw_method_id: acc?.id,
                              prefered_withdraw_method_type: 'bank',
                            })
                            setShowAutoWithdrawInfoModal(true)
                            setSelected({ id: acc.id, type: 'bank' })
                          }}
                        />
                      )
                    }

                    case METHODS.PAYPAL.value: {
                      return (
                        <PaypalMethodCard
                          isPayoneer={acc.type === 3}
                          withRemove
                          withTag
                          onUpdate={onUpdate}
                          key={`acc${i}`}
                          account={acc}
                          index={i}
                          selected={
                            JSON.stringify({ id: acc.id, type: 'paypal' }) ===
                            JSON.stringify(selected)
                          }
                          onSelect={() => {
                            updateProfile.startFetch({
                              prefered_withdraw_method_id: acc?.id,
                              prefered_withdraw_method_type: 'paypal',
                            })
                            setShowAutoWithdrawInfoModal(true)
                            setSelected({ id: acc.id, type: 'paypal' })
                          }}
                        />
                      )
                    }

                    case METHODS.PAYONEER.value: {
                      if (!FEATURE_FLAGS.NEW_PAYONEER_INTEGRATION) {
                        return (
                          <PaypalMethodCard
                            isPayoneer
                            withRemove
                            withTag
                            onUpdate={onUpdate}
                            key={`acc${i}`}
                            account={acc}
                            index={i}
                            selected={
                              JSON.stringify({ id: acc.id, type: 'paypal' }) ===
                              JSON.stringify(selected)
                            }
                            onSelect={() => {
                              updateProfile.startFetch({
                                prefered_withdraw_method_id: acc?.id,
                                prefered_withdraw_method_type: 'paypal',
                              })
                              setShowAutoWithdrawInfoModal(true)
                              setSelected({ id: acc.id, type: 'paypal' })
                            }}
                          />
                        )
                      }
                      return (
                        <PayoneerMethodCard
                          key={`acc${i}`}
                          account={acc}
                          onUpdate={onUpdate}
                          selected={
                            JSON.stringify({ id: acc.id, type: 'payoneer' }) ===
                            JSON.stringify(selected)
                          }
                          onSelect={() => {
                            updateProfile.startFetch({
                              prefered_withdraw_method_id: acc.id,
                              prefered_withdraw_method_type: 'payoneer',
                            })
                            setShowAutoWithdrawInfoModal(true)
                            setSelected({ id: acc.id, type: 'payoneer' })
                          }}
                        />
                      )
                    }

                    case METHODS.COINBASE.value: {
                      return (
                        <CoinbaseMethodCard
                          withRemove
                          withTag
                          onUpdate={onUpdate}
                          key={`acc${i}`}
                          account={acc}
                          index={i}
                          selected={
                            JSON.stringify({ id: acc.id, type: 'paypal' }) ===
                            JSON.stringify(selected)
                          }
                          onSelect={() => {
                            updateProfile.startFetch({
                              prefered_withdraw_method_id: acc?.id,
                              prefered_withdraw_method_type: 'paypal',
                            })
                            setShowAutoWithdrawInfoModal(true)
                            setSelected({ id: acc.id, type: 'paypal' })
                          }}
                        />
                      )
                    }

                    case METHODS.CASH_POINT.value: {
                      return (
                        <CashPointCard
                          withTag
                          withRemove
                          onUpdate={onUpdate}
                          card={acc}
                          index={i}
                          selected={
                            JSON.stringify({
                              id: acc.id,
                              type: 'cashPoint',
                            }) === JSON.stringify(selected)
                          }
                          onSelect={() => setShowWarnAutoWithdrawChange(acc)}
                        />
                      )
                    }

                    default: {
                      return 'Method not supported ' + acc.type
                    }
                  }
                }),
              )}
            </div>

            <div className='d-flex justify-content-center'>
              <Dropdown
                className='my-4 d-flex justify-content-center'
                isOpen={menu}
                toggle={handleMenuToggle}
              >
                <DropdownToggle
                  id='withdraw-method-dropdown m-4'
                  tag={Button}
                  icon={<i className='bx bx-plus font-size-16 align-middle' />}
                >
                  Add new account
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => {
                      setMethod(METHODS.BANK.value)
                      setShowModal(true)
                    }}
                  >
                    <i className='bx bxs-bank font-size-16 align-middle mr-2' />
                    {METHODS.BANK.label}
                  </DropdownItem>

                  {!isEmployee && (
                    <DropdownItem
                      onClick={() => {
                        setMethod(METHODS.PAYPAL.value)
                        connect.startFetch({ type: 'settings' })
                      }}
                    >
                      <i className='bx bxl-paypal font-size-17 align-middle mr-2' />
                      {METHODS.PAYPAL.label}
                    </DropdownItem>
                  )}

                  {isLebanon && (
                    <DropdownItem
                      onClick={() => {
                        setMethod(METHODS.CASH_POINT.value)
                        setShowModal(true)
                      }}
                    >
                      <i className='bx bxs-bank font-size-17 align-middle mr-2' />
                      {METHODS.CASH_POINT.label}
                    </DropdownItem>
                  )}

                  {payoneer.length > 0 ||
                  !FEATURE_FLAGS.NEW_PAYONEER_INTEGRATION ? null : (
                    <DropdownItem
                      onClick={() => {
                        setMethod(METHODS.PAYONEER.value)
                        setShowModal(true)
                      }}
                      className='d-flex align-items-center'
                    >
                      <img
                        className='mr-2'
                        style={{ width: 16 }}
                        src={payoneerIllustration}
                      />
                      {METHODS.PAYONEER.label}
                    </DropdownItem>
                  )}

                  {FEATURE_FLAGS.NEW_PAYONEER_INTEGRATION ? null : (
                    <DropdownItem
                      onClick={() => {
                        setMethod(METHODS.PAYONEER_EMAIL.value)
                        setShowModal(true)
                      }}
                      className='d-flex align-items-center'
                    >
                      <img
                        className='mr-2'
                        style={{ width: 16 }}
                        src={payoneerIllustration}
                      />
                      {METHODS.PAYONEER.label}
                    </DropdownItem>
                  )}

                  {userProfile?.withdraw_method_ids?.includes('9') && (
                    <DropdownItem
                      onClick={() => {
                        setMethod(METHODS.COINBASE.value)
                        setShowModal(true)
                      }}
                    >
                      <img
                        className='mr-2'
                        style={{ width: 16, height: 16 }}
                        src={coinbaseIllustration}
                      />
                      {METHODS.COINBASE.label}
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
            </div>
          </Col>

          <Col xs={12} md={6} className='px-3 py-4'>
            <StyledH3
              min='16px'
              max='24px'
              mid='18px'
              style={{ fontFamily: 'gilroyB' }}
              className='mb-3 text-dark text-weight-bold'
            >
              Auto-withdraw
            </StyledH3>
            <p
              className='font-size-14 text-secondary mb-4'
              style={{ fontWeight: '400' }}
            >
              By activating the auto-withdraw option, your funds will be
              automatically transferred to your default payment account every
              time you receive a new payment.
            </p>
            <label className='d-flex cursor-pointer'>
              <Toggle
                check={isAutoWithdraw}
                change={(e) => handleAutoWithdrawCheck(e.target.checked)}
              />

              <span
                className='font-size-14  text-secondary  mb-md-4 mb-3'
                style={{ fontWeight: '400' }}
              >
                Auto-withdraw to default account
              </span>
            </label>
          </Col>
        </Row>
      )}

      <Modal
        className='newAccountModal'
        isOpen={showModal}
        scrollable
        centered
        role='dialog'
        size={method === METHODS.COINBASE.value ? 'sm' : undefined}
        toggle={
          method === METHODS.PAYONEER.value
            ? null
            : () => {
                setShowModal(false)
              }
        }
      >
        <ModalHeader
          toggle={() => {
            setShowModal(false)
          }}
        >
          Add {modalTitle[method]} account
        </ModalHeader>
        <ModalBody className={`${method === 0 ? 'pb-0' : 'p-0'}`}>
          {method === METHODS.BANK.value && (
            <BankAccounts
              noCards
              isEdit
              currency={profile?.currency?.code}
              onAdd={(_, res) => {
                onUpdate()
                onAccountAdded()
                setShowModal(false)
                window.analytics.track('Added withdraw methods', {
                  withdraw_account_name: res.accountHolderName,
                  withdraw_method: 'bank Transfer',
                  currency: res.currency,
                  country: res.country,
                  is_default: false,
                })
              }}
              onCancel={() => setShowModal(false)}
            />
          )}
          {method === METHODS.PAYPAL.value && (
            <PaypalAccounts
              isEdit
              noCards
              onAdd={() => {
                onUpdate()
                onAccountAdded()
                setShowModal(false)
                window.analytics.track('Added withdraw methods', {
                  withdraw_method: 'Paypal',
                  is_default: false,
                })
              }}
              onCancel={() => setShowModal(false)}
            />
          )}
          {method === METHODS.CASH_POINT.value && (
            <CashPointAccount
              onCancel={() => setShowModal(false)}
              onAdd={() => {
                onUpdate()
                onAccountAdded()
                setShowModal(false)
              }}
            />
          )}
          {method === METHODS.PAYONEER_EMAIL.value && (
            <PaypalAccounts
              isPayoneer
              isEdit
              noCards
              onAdd={() => {
                onUpdate()
                onAccountAdded()
                setShowModal(false)
                window.analytics.track('Added withdraw methods', {
                  withdraw_method: 'Payoneer',
                  is_default: false,
                })
              }}
              onCancel={() => setShowModal(false)}
            />
          )}

          {method === METHODS.PAYONEER.value && <PayoneerAccount />}

          {method === METHODS.COINBASE.value && (
            <CoinbaseAccounts
              isEdit
              noCards
              onAdd={() => {
                onUpdate()
                onAccountAdded()
                setShowModal(false)
                window.analytics.track('Added withdraw methods', {
                  withdraw_method: 'Coinbase',
                  is_default: false,
                })
              }}
              onCancel={() => setShowModal(false)}
            />
          )}
        </ModalBody>
      </Modal>

      <Modal
        isOpen={showAutoWithdrawInfoModal}
        role='dialog'
        centered
        toggle={() => setShowAutoWithdrawInfoModal(false)}
      >
        <ModalBody className='justify-content-center p-3'>
          <div className='d-flex justify-content-center py-4'>
            <img
              style={{ height: 100, width: 100 }}
              src={infoIllustration}
              alt=''
            />
          </div>
          <h1 className='text-center font-size-16' style={{ fontWeight: 700 }}>
            The default account was added successfully.
          </h1>
          <p className='text-center'>
            Your funds will be automatically sent your default account every
            time you receive a payment.
          </p>
        </ModalBody>
        <ModalFooter className='justify-content-center'>
          <button
            onClick={() => {
              setShowAutoWithdrawInfoModal(false)
            }}
            className='btn btn-primary'
          >
            Ok
          </button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={showAutoWithdrawWarning}
        centered
        toggle={() => setShowAutoWithdrawWarning(false)}
      >
        <ModalBody className='justify-content-center p-3'>
          <div className='d-flex justify-content-center py-4'>
            <img
              style={{ height: 100, width: 100 }}
              src={warningIllustration}
              alt=''
            />
          </div>
          <h1
            className='text-center font-size-16'
            style={{ fontWeight: '700' }}
          >
            Auto-withdraw is now disabled
          </h1>
          <p className='text-center'>
            You will need to withdraw your funds manually. Note that you must
            withdraw your funds within 30 days of receipt.
          </p>
        </ModalBody>
        <ModalFooter className='justify-content-center'>
          <button
            onClick={() => setShowAutoWithdrawWarning(false)}
            className='btn btn-primary'
          >
            Ok
          </button>
        </ModalFooter>
      </Modal>

      <KYCModal
        isOpen={showKycModal}
        toggle={() => setShowKycModal(false)}
        proceed={() => {
          history.push('/settings#kyc')
          setShowKycModal(false)
        }}
        message='You need to complete your KYC to add a new account.'
      />

      <WarnAutoWithdrawChange
        isOpen={!!showWarnAutoWithdrawChange}
        toggle={() => setShowWarnAutoWithdrawChange(null)}
        onConfirm={handleSetCashPointAsDefault}
      />
    </div>
  )
}

function WarnAutoWithdrawChange({ isOpen, toggle, onConfirm }) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Auto-withdraw will be disabled</ModalHeader>

      <ModalBody>
        By changing your default account, auto-withdraw will be disabled. You
        will need to withdraw your funds manually. Note that you must withdraw
        your funds within 30 days of receipt.
      </ModalBody>

      <ModalFooter>
        <Button onClick={toggle} color='light' outline>
          Cancel
        </Button>
        <Button onClick={onConfirm}>Proceed</Button>
      </ModalFooter>
    </Modal>
  )
}

export default WithdrawalMethod
