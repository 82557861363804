import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Col, Row, Container } from 'reactstrap'

import ModalHeader from '../../components/ModalHeader'
import { contractorTypes } from '../../helpers/enum'
import { useFetch } from '../../helpers/hooks'
import { updateContractorType } from '../../services/api'
import { loginUserSuccessful } from '../../store/auth/register/actions'
import { updateUserProfileInfo } from '../../store/profile/actions'
import CompanyInfo from '../CompanySetting/CompanyInfo'

const CompleteCompany = () => {
  const countries = useSelector((state) => state?.Layout?.staticData?.countries)
  const dispatch = useDispatch()
  const history = useHistory()
  const update = useFetch({
    action: updateContractorType,
    onComplete: (data, body) => {
      dispatch(loginUserSuccessful(data))
      dispatch(updateUserProfileInfo({ contractor_type: body?.type }))
      history.push('/activity')
    },
  })

  const location = history.location
  const back = location?.state?.back ?? '/settings'

  const onComplete = () => {
    update.startFetch({ type: contractorTypes.ENTITY })
  }

  return (
    <div className='blur-container h-100'>
      <Container fluid className='bg-white' style={{ minHeight: '100vh' }}>
        <ModalHeader action={() => history.push(back)} />
        <div className='bg-white'>
          <Row className='justify-content-center'>
            <h3 className='mb-4 mt-4 text-center'>
              Complete your company info
            </h3>
          </Row>
          <Row className='justify-content-center'>
            <Col className='p-0' lg={6}>
              {!!countries?.length && (
                <CompanyInfo
                  isCompletion
                  onComplete={onComplete}
                  countries={countries}
                />
              )}
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default CompleteCompany
