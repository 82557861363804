import React, { useState } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import { StyledH6 } from '../../../components/Typo'
import { deleteContractTag } from '../../../services/api'
import { useSelector } from 'react-redux'
import toastr from 'toastr'

const TagsDropdown = ({
  isOpen,
  toggle,
  options = [],
  elementTags,
  contract,
  handleDelete,
  addTagData,
}) => {
  const user = useSelector((state) => state?.Account?.user)
  const [tagValue, setTagValue] = useState(null)
  const [creating, setCreating] = useState(false)

  const deleteTag = (data) => {
    deleteContractTag(user?.token, {
      contract_id: contract.id,
      tag_id: data.id,
    })
      .then((r) => {
        if (r.data.success) {
          handleDelete(data)
        }
      })
      .catch((e) => {
        toastr.error(e)
      })
  }

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={toggle}
      style={{ maxHeight: '0', marginTop: '8px' }}
      className='dropdown d-inline-block mb-3 mb-md-3'
    >
      <DropdownToggle tag={'div'}>
        <div className='d-flex'>
          {elementTags?.map((tag, i) => {
            return (
              <div
                key={i}
                className={`d-flex ${
                  i === 0 ? 'ml-0 ml-md-0' : 'ml-1 ml-md-1'
                }`}
                style={{
                  padding: '4px 6px',
                  backgroundColor: tag?.color,
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
              >
                <StyledH6
                  style={{
                    margin: 'auto',
                    fontSize: '12px',
                    fontFamily: 'inter',
                    color: '#1C1C1C',
                    fontWeight: '400',
                  }}
                >
                  {tag.name}
                </StyledH6>
                {isOpen && (
                  <i
                    className='mdi mdi-close pl-1 '
                    onClick={() => deleteTag(tag)}
                    style={{
                      cursor: 'pointer',
                      zIndex: '10000',
                      fontSize: '10px',
                    }}
                  ></i>
                )}
              </div>
            )
          })}
        </div>
        {elementTags?.length === 0 && (
          <div
            style={{
              padding: '4px 6px',
              backgroundColor: 'rgba(119, 127, 158, 0.15)',
              width: '4.4rem',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            <StyledH6
              style={{
                margin: 'auto',
                fontSize: '12px',
                fontFamily: 'inter',
                color: '#1C1C1C',
                fontWeight: '400',
              }}
            >
              + Add Tag
            </StyledH6>
          </div>
        )}
      </DropdownToggle>
      <DropdownMenu
        className='dropdown-menu'
        flip={false}
        style={{
          width: '361px',
          zIndex: '1020',
          marginTop: '29px',
          border: '1px solid #E7E8F2',
          borderRadius: '4px',
        }}
      >
        <div style={{ paddingLeft: '16px', paddingTop: '3px' }}>
          <StyledH6
            style={{
              fontSize: '12px',
              fontWeight: '400',
              lineHeight: '15px',
              color: '#424652',
              fontFamily: 'inter',
            }}
          >
            Select an option or create one
          </StyledH6>
        </div>
        {options.map((e, i) => {
          const Component = e.component
          return (
            <DropdownItem
              key={`drop-${i}`}
              onClick={e.onClick}
              style={{ paddingLeft: '16px', paddingRight: '16px' }}
            >
              <Component />
            </DropdownItem>
          )
        })}
        {elementTags?.length < 2 && (
          <div
            className='d-flex align-items-center'
            style={{
              paddingLeft: '16px',
              paddingTop: '0.35rem',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (!creating) {
                setCreating(true)
              }
            }}
          >
            {!creating ? (
              <div
                style={{
                  padding: '0.2rem',
                  paddingTop: '0.1rem',
                  paddingBottom: '0.1rem',
                  backgroundColor: 'rgba(119, 127, 158, 0.15)',
                  borderRadius: '4px',
                }}
              >
                <StyledH6
                  style={{
                    margin: 'auto',
                    fontSize: '12px',
                    fontWeight: '400',
                    lineHeight: '15px',
                    fontFamily: 'inter',
                  }}
                >
                  + Create New Tag
                </StyledH6>
              </div>
            ) : (
              <div className='d-flex align-items-center'>
                <StyledH6
                  style={{
                    fontSize: '12px',
                    fontWeight: '400',
                    lineHeight: '15px',
                    fontFamily: 'inter',
                    marginTop: '8px',
                  }}
                >
                  Create:
                </StyledH6>
                <input
                  type='text'
                  id='tag_input'
                  maxLength='17'
                  onKeyUp={(event) => {
                    if (event.key === 'Enter') {
                      addTagData(tagValue)
                      event.target.value = ''
                      setCreating(false)
                    }
                  }}
                  onChange={(event) => {
                    setTagValue(event.target.value)
                  }}
                  // autoFocus={creating}
                  style={{
                    width: '145px',
                    border: 'none',
                    backgroundColor: 'rgba(119, 127, 158, 0.15)',
                    outline: 'none',
                    marginLeft: '4px',
                    maxWidth: '200px',
                  }}
                  placeholder='Enter a tag and hit enter'
                />
              </div>
            )}
          </div>
        )}
      </DropdownMenu>
    </Dropdown>
  )
}

export default TagsDropdown
