import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  CardBody,
  Col,
  Row,
  Spinner,
  Table,
  UncontrolledTooltip,
} from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'

import archiveHover from '../../assets/images/archive.svg'
import download from '../../assets/images/archiveBlack.svg'
import unarchive from '../../assets/images/unarchive.svg'
import unarchiveHover from '../../assets/images/unarchiveHover.svg'
import Avatar from '../../components/Avatar'
import ConfirmationModal from '../../components/Common/ConfirmationModal'
import Toggle from '../../components/Forms/Toggle/Toggle'
import RolesModal from '../../components/RolesModal'
import Button from '../../components/ui/button'
import { useFetch, useResize } from '../../helpers/hooks'
import {
  changeUserRole,
  changeUserStatus,
  getUsers,
  resendInvite,
  updateUserPermissions,
} from '../../services/api'
import AddUserModal from './AddUserModal'

const UserCard = ({
  myUser,
  index,
  handleCanSign,
  handleStatusChange,
  user,
  archived,
  handleRoleChange,
  handleArchive,
}) => {
  return (
    <div className='mb-3 rounded border'>
      <div className='border-bottom p-3'>
        <CompanyUser myUser={myUser} archived={archived} />
      </div>

      <div className='d-flex justify-content-between align-items-center pt-3 px-3'>
        <p className='text-dark font-size-14 mb-0'>Role</p>
        {!archived && (
          <div style={{ width: '65%' }}>
            <div className='form-control d-flex flex-row justify-content-between'>
              {myUser?.role?.name}
              {!(myUser.id === user?.id || myUser?.role === 'Owner') && (
                <a className='text-primary' onClick={() => handleRoleChange()}>
                  <i className='bx bx-edit-alt' />
                </a>
              )}
            </div>
          </div>
        )}
      </div>

      <div className='d-flex justify-content-between align-items-center pt-3 px-3'>
        <p className='text-dark font-size-14 mb-0'>Can Sign</p>
        <Toggle
          id={`SwitchCanSign${index}`}
          check={myUser?.can_sign}
          change={() => {
            handleCanSign(myUser?.id, myUser?.can_sign)
          }}
        />
      </div>
      <div className='d-flex justify-content-between align-items-center pt-3 px-3'>
        <p className='text-dark font-size-14 mb-0'>Status</p>
        <Toggle
          id={`SwitchStatus${index}`}
          check={myUser.status === 'active'}
          disabled={myUser.id === user?.id || myUser?.role === 'Owner'}
          change={() => {
            handleStatusChange(
              myUser?.id,
              myUser.status === 'active' ? 'inactive' : 'active',
            )
          }}
        />
      </div>

      <div className='p-3'>
        <Button
          block
          color='light'
          outline
          onClick={() => handleArchive(myUser?.id, index)}
          icon={
            <img
              id={`deletetooltip-${index}`}
              src={archived ? unarchive : download}
              alt={archived ? 'Unarchive icon' : 'Archive icon'}
            />
          }
        >
          {archived ? 'Unarchive' : 'Archive'}
        </Button>
      </div>
    </div>
  )
}

const Users = ({ archived, onUpdate, show, hide }) => {
  const isMobile = useResize()
  const user = useSelector((state) => state?.Account?.user)
  const [editing, setEditing] = useState(null)
  const [archiving, setArchiving] = useState(-1)
  const [loading, setLoading] = useState(false)

  const changeStatus = useFetch({
    autoFetch: false,
    action: changeUserStatus,
    onComplete: (_, body) => {
      updateUsersList(false)
      setArchiving(-1)
      onUpdate()
      if (body.status === 'archived') {
        window.analytics.track('Archived a user', {
          archived_user_email_id: body?.user_id,
          archived_user_first_name: body?.user?.first_name,
          archived_user_last_name: body?.user?.last_name,
        })
      } else if (body.status !== 'unarchive') {
        window.analytics.track('Changed user status', {
          changed_user_id: body?.user_id,
          new_status: body.status,
        })
      }
    },
  })

  const handleArchive = (id, key, user) => {
    setArchiving(key)
    if (archived) {
      handleStatusChange(id, 'unarchive')
    } else {
      changeStatus.startFetch({
        user_id: id,
        status: 'archived',
        user,
      })
    }
  }

  const handleStatusChange = (id, status, user) => {
    changeStatus.startFetch({
      user_id: id,
      status,
      user,
    })
  }

  const [data, setData] = useState({ list: [], roles: [] })
  useEffect(() => {
    updateUsersList()
  }, [])

  const updateUsersList = (isLoading = true) => {
    setLoading(isLoading)
    const data = archived ? { status: 'archived' } : null
    try {
      getUsers(user?.token, data)
        .then((r) => {
          setLoading(false)
          if (r.data?.success) {
            window.analytics.track('Viewed Users', { 'email-id': user?.email })
            setData(r.data?.data)
          }
        })
        .catch((e) => {
          setLoading(false)
        })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  const changeRole = useFetch({
    action: changeUserRole,
    onError: (err) => {
      toastr.error(err)
      updateUsersList(false)
    },
    onComplete: () => updateUsersList(false),
  })
  const changeCanSign = useFetch({
    action: updateUserPermissions,
    onComplete: () => updateUsersList(false),
  })
  const resend = useFetch({ action: resendInvite })

  const handleCanSign = (id, status) => {
    changeCanSign.startFetch({
      user_id: id,
      can_sign: status ? 0 : 1,
    })
  }

  return (
    <CardBody className='pt-0 px-3'>
      {loading ? (
        <Col style={{ minHeight: '0' }}>
          <Row
            style={{ minHeight: '50vh' }}
            className='justify-content-center align-items-center'
          >
            <Spinner type='grow' className='mr-2' color='primary' />
          </Row>
        </Col>
      ) : isMobile ? (
        <Col className='px-0 pt-0 p-md-3' style={{ minHeight: '60vh' }}>
          {data?.list?.map((myUser, key) => (
            <UserCard
              myUser={myUser}
              archived={archived}
              index={key}
              key={'_order_' + myUser?.id}
              handleCanSign={handleCanSign}
              handleStatusChange={handleStatusChange}
              user={user}
              handleRoleChange={() => setEditing(myUser)}
              handleArchive={handleArchive}
            />
          ))}
        </Col>
      ) : (
        <div className='table-responsive'>
          <Table className='table table-centered table-nowrap'>
            <thead className='thead-light'>
              <tr
                className='rp-border font-size-14'
                style={{ borderTopStyle: 'hidden', borderWidth: '0 0 1px 0' }}
              >
                <th style={{ fontWeight: '500' }}>Name</th>

                {!archived ? null : (
                  <th style={{ fontWeight: '500' }}>Email</th>
                )}

                {archived ? null : (
                  <>
                    <th style={{ fontWeight: '500' }}>Role</th>
                    <th style={{ fontWeight: '500' }} className='text-center'>
                      Can Sign
                    </th>
                    <th style={{ fontWeight: '500' }} className=' text-center'>
                      Status
                    </th>
                  </>
                )}
                <th className='border-top-0 text-center'>
                  {archived ? 'Unarchive' : 'Archive'}
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.list?.map((myUser, key) => {
                return (
                  <tr key={'_order_' + myUser?.id}>
                    <td>
                      <CompanyUser myUser={myUser} archived={archived} />
                    </td>

                    {!archived ? null : <td>{myUser.email}</td>}

                    {archived ? null : (
                      <>
                        <td>
                          <div className='form-control d-flex flex-row justify-content-between'>
                            {myUser?.role?.name}
                            {!(
                              myUser.id === user?.id || myUser?.role === 'Owner'
                            ) && (
                              <a
                                className='text-primary'
                                onClick={() => setEditing(myUser)}
                              >
                                <i className='bx bx-edit-alt' />
                              </a>
                            )}
                          </div>
                        </td>
                        <td className='text-center'>
                          <Toggle
                            id={`SwitchCanSign${key}`}
                            check={myUser?.can_sign}
                            change={() => {
                              handleCanSign(myUser?.id, myUser?.can_sign)
                            }}
                            marginRight={null}
                          />
                        </td>
                        <td className='text-center'>
                          {myUser.status === 'invited' ? (
                            <Link
                              className='text-primary font-size-14'
                              style={{ fontWeight: '400' }}
                              to='#'
                              onClick={() => {
                                resend.startFetch({ email: myUser?.email })
                              }}
                            >
                              {resend.isLoading ? (
                                <i className='bx bx-loader bx-spin font-size-16 text-primary' />
                              ) : (
                                'Resend Invitation'
                              )}
                            </Link>
                          ) : (
                            <Toggle
                              id={`SwitchStatus${key}`}
                              check={myUser.status === 'active'}
                              disabled={
                                myUser.id === user?.id ||
                                myUser?.role === 'Owner'
                              }
                              change={() => {
                                handleStatusChange(
                                  myUser?.id,
                                  myUser.status === 'active'
                                    ? 'inactive'
                                    : 'active',
                                )
                              }}
                              marginRight={null}
                            />
                          )}
                        </td>
                      </>
                    )}
                    <td>
                      <Col>
                        <Row className='justify-content-center'>
                          {!(myUser.id === user?.id) && (
                            <>
                              {archiving === key ? (
                                <i className='bx bx-loader bx-spin font-size-16 text-primary' />
                              ) : (
                                <Link
                                  to='#'
                                  onClick={() => handleArchive(myUser?.id, key)}
                                >
                                  {archived ? (
                                    <StyledSvg
                                      icon={unarchive}
                                      hoverIcon={unarchiveHover}
                                      id={`deletetooltip-${key}`}
                                      alt={'archive icon'}
                                    />
                                  ) : (
                                    <StyledSvg
                                      icon={download}
                                      hoverIcon={archiveHover}
                                      id={`deletetooltip-${key}`}
                                      alt={'archive icon'}
                                    />
                                  )}
                                  <UncontrolledTooltip
                                    placement='top'
                                    target={`deletetooltip-${key}`}
                                  >
                                    {archived ? 'UnArchive' : 'Archive'}
                                  </UncontrolledTooltip>
                                </Link>
                              )}
                            </>
                          )}
                        </Row>
                      </Col>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      )}
      <AddUserModal
        isOpen={show}
        onComplete={() => updateUsersList(false)}
        toggle={hide}
      />

      <RolesModal
        hide={() => setEditing(null)}
        show={!!editing}
        onChange={(roleId) => {
          changeRole.startFetch({
            user_id: editing?.id,
            role_id: roleId?.value,
          })
          setEditing(null)
        }}
        user={editing}
      />

      <ConfirmationModal />
    </CardBody>
  )
}

function CompanyUser({ myUser, archived }) {
  return (
    <div className='align-items-center d-flex flex-nowrap gap-12'>
      <Avatar
        name={`${myUser.first_name || 'Invitation pending'} ${
          myUser.last_name || ''
        }`}
        photo={myUser.photo}
      />
      <div>
        <h4
          className='font-size-14 mb-1 rp-font-bold rp-font-gilroyB'
          translate='no'
        >
          {myUser.first_name || 'Invitation pending'} {myUser.last_name}
        </h4>
        {archived ? null : <p className='font-size-12 mb-0'>{myUser.email}</p>}
      </div>
    </div>
  )
}

const StyledSvg = styled.div`
  width: 1rem;
  height: 1rem;
  background-image: ${(props) => ` url( ${props.icon} )`};
  &:hover {
    background-image: ${(props) => ` url( ${props.hoverIcon} )`};
  }
`

export default Users
