import React from 'react'
import Col from 'reactstrap/lib/Col'
import Row from 'reactstrap/lib/Row'
import Modal from 'reactstrap/lib/Modal'

import kycImage from '../../../assets/images/verification-img.png'

const KYCModal = ({
  isOpen,
  toggle,
  proceed,
  isCentered = true,
  message = 'Your client has requested to verify your identity before you can sign the contract.',
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={isCentered}>
      <div className='modal-content'>
        <div className='modal-body'>
          <Row className='justify-content-center text-center'>
            <Col md={10}>
              <h2>KYC Verification</h2>
              {!message ? null : <h6 className='text-muted'>{message}</h6>}
              <Row className='justify-content-center'>
                <Col sm='6' xs='8'>
                  <div>
                    <img src={kycImage} alt='' className='img-fluid' />
                  </div>
                </Col>
              </Row>

              <h6 className='text-muted'>
                It only takes 2 minutes to complete the process
              </h6>
            </Col>
          </Row>
        </div>
        <div className='modal-footer'>
          <button type='button' className='btn btn-link' onClick={toggle}>
            Cancel
          </button>
          <button
            type='button'
            className='btn btn-primary'
            data-dismiss='modal'
            onClick={proceed}
          >
            Proceed
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default KYCModal
