import React from 'react'
import cx from 'classnames'

const StyledTd = (props) => {
  const { children, colSpan, myStyle, className = '' } = props
  return (
    <td
      style={myStyle}
      colSpan={colSpan}
      className={cx('font-size-14 py-3 px-4', className)}
    >
      {children}
    </td>
  )
}

export default StyledTd
