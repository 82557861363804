import React from 'react'
import ReactDOM from 'react-dom'

import * as serviceWorker from './serviceWorker'
import Maintenance from './pages/maintenance'
import WrapperApp from './wrapper-app'

const isInMaintenance =
  JSON.parse(process.env.REACT_APP_MAINTENANCE ?? 'false') &&
  !document.cookie.includes('test-migration')

const app = isInMaintenance ? <Maintenance /> : <WrapperApp />

ReactDOM.render(app, document.getElementById('app'))
serviceWorker.unregister()
