import React, { useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import {
  Button,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap'

const TerminationModal = ({
  isOpen,
  toggle,
  onConfirm,
  notice,
  loading,
  withAmount = true,
  currency,
}) => {
  const [amount, setAmount] = useState(null)

  return (
    <Modal isOpen={isOpen} role='dialog' toggle={toggle}>
      <div className='modal-content'>
        <ModalHeader toggle={toggle}>Terminate Contract</ModalHeader>
        <ModalBody>
          {loading ? (
            <Row className='justify-content-center align-items-center'>
              <Spinner type='grow' className='mr-2' color='primary' />
            </Row>
          ) : (
            <Col className='p-0'>
              <p>
                {`This contract has a ${notice} day notice period, are you sure you want to terminate it now?  This action is not reversible.`}
              </p>
              {withAmount && (
                <FormGroup>
                  <p>
                    The current payment cycle will be cancelled, please enter
                    the amount of the last payment:
                  </p>
                  <InputGroup>
                    <CurrencyInput
                      name='amount'
                      type='text'
                      onKeyPress={(e) => {
                        if (!/[0-9.]/.test(e.key)) {
                          e.preventDefault()
                        }
                      }}
                      required
                      placeholder='Amount'
                      id='contract-currency'
                      className='form-control'
                      onValueChange={(e) => {
                        setAmount(e)
                      }}
                      // currency input props
                      allowDecimals={true}
                      decimalsLimit={2}
                      decimalSeparator='.'
                      groupSeparator=','
                    />
                    <InputGroupAddon
                      style={{ width: '25%', zIndex: 2 }}
                      addonType='append'
                    >
                      <InputGroupText style={{ width: '100%' }}>
                        <div style={{ textAlign: 'center' }}>
                          {currency?.code}
                        </div>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              )}
            </Col>
          )}
        </ModalBody>
        <ModalFooter>
          <Button type='button' color='light' outline onClick={toggle}>
            Cancel
          </Button>
          <Button
            type='button'
            color='danger'
            onClick={() => {
              onConfirm(amount)
            }}
          >
            Terminate
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

export default TerminationModal
