import { StyledH5 } from '../../../../components/Typo'
import React, { forwardRef, useEffect } from 'react'
import { Col, FormGroup, Label, Row } from 'reactstrap'
import ControlledInput from '../../../../components/ControlledInput'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { AvForm } from 'availity-reactstrap-validation'
import { useDispatch, useSelector } from 'react-redux'
import { HiBobRequest } from '../../../../store/hiBob/actions'
import { SaplingRequest } from '../../../../store/sapling/actions'
import { bambooRequest } from '../../../../store/bamboo/actions'

const Tab1 = forwardRef(function Tab1({ platform, onComplete, mode }, ref) {
  const HiRequest = useSelector((state) => state.hiBob.request)
  const saplingRequest = useSelector((state) => state.sapling.saplingRequest)
  const bambooReq = useSelector((state) => state.bamboo.request)
  const dispatch = useDispatch()

  const getLabel = () => {
    switch (platform) {
      case 'sapling':
        return 'Sapling'
      case 'bamboo':
        return 'Bamboo'
      default:
        return 'HiBob'
    }
  }

  const getInputName = () => {
    switch (platform) {
      case 'sapling':
        return { url: 'sapling_url', key: 'sapling_apikey' }
      case 'bamboo':
        return { url: 'bamboohr_subdomain', key: 'bamboohr_apikey' }
      default:
        return { url: 'hibob_url', key: 'hibob_secret' }
    }
  }

  const schema = yup.object().shape({
    [getInputName().url]: yup.string().required(),
    [getInputName().key]: yup.string().required(),
  })

  const {
    handleSubmit,
    control,
    reset,
    watch,

    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {},
  })

  const url = watch(getInputName().url)
  const secret = watch(getInputName().key)

  useEffect(() => {
    if (mode === 'edit' && url?.length > 0 && secret?.length > 0) {
      if (platform === 'sapling') {
        dispatch(
          SaplingRequest({
            ...saplingRequest,
            sapling_url: url,
            sapling_apikey: secret,
          }),
        )
      } else if (platform === 'bamboo') {
        dispatch(
          bambooRequest({
            ...bambooReq,
            bamboohr_subdomain: url,
            bamboohr_apikey: secret,
          }),
        )
      } else {
        dispatch(
          HiBobRequest({ ...HiRequest, hibob_url: url, hibob_secret: secret }),
        )
      }
    }
  }, [url, secret])

  useEffect(() => {
    if (platform === 'sapling') {
      reset(saplingRequest)
    } else if (platform === 'bamboo') {
      reset(bambooReq)
    } else {
      reset(HiRequest)
    }
  }, [HiRequest, saplingRequest])

  const onSubmit = (data) => {
    onComplete(data)
  }

  return (
    <div className={`${mode === 'edit' ? 'border-bottom  ' : ''}`}>
      <div
        className={`${mode === 'edit' ? 'px-4 pt-4 ' : 'p-4  border-bottom'}`}
      >
        <StyledH5 className='font-size-16 mb-0  font-weight-bold'>
          Automatically add employees and contractors to your {getLabel()}{' '}
          account.
        </StyledH5>
      </div>
      <div className={'p-4 '}>
        <AvForm
          ref={ref}
          autoComplete='off'
          className='form-horizontal m-0'
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row>
            <Col md={6} className={'mb-2'}>
              <FormGroup className='d-inline p-0 m-0  '>
                <Label
                  style={{ whiteSpace: 'nowrap' }}
                  htmlFor='billing-name'
                  md='3'
                  className='col-form-label p-0 m-0 mb-2 d-block font-weight-bold  font-size-14'
                >
                  {getLabel()} {platform === 'bamboo' ? 'Subdomain' : 'URL'}
                  <span className='text-danger font-size-16 mx-1'>*</span>
                </Label>
                <div
                  // style={{ paddingRight: mode === 'edit' ? '42%' : '7%' }}
                  className={'mb-3'}
                >
                  {platform === 'bamboo' ? (
                    <span className={'font-size-14 text-secondary'}>
                      Your {getLabel()} subdomain using to connect your{' '}
                      {getLabel()} account.
                      <a
                        style={{ borderBottom: '1px solid blue' }}
                        href={
                          'http://help.remotepass.com/en/articles/5591011-how-to-integrate-bamboohr-with-remotepass'
                        }
                        className={'text-primary pl-1'}
                      >
                        Read More
                      </a>
                    </span>
                  ) : (
                    <span className={'font-size-14 text-secondary'}>
                      Your {getLabel()} URL is provided to you in your{' '}
                      {getLabel()} HireAPI configuration.
                      <span
                        style={{ borderBottom: '1px solid blue d-none' }}
                        className={'text-primary pl-1'}
                      >
                        Read More
                      </span>
                    </span>
                  )}
                </div>
                <Row className='p-0 m-0 align-items-center'>
                  <Col className='p-0 m-0'>
                    <ControlledInput
                      control={control}
                      placeholder={platform === 'bamboo' && 'subdomain'}
                      name={`${getInputName().url}`}
                      error={errors[getInputName().url]}
                    />
                  </Col>
                  <div className='text-muted ml-1'>.bamboohr.com</div>
                </Row>
              </FormGroup>
            </Col>
            <Col md={6} className={'mb-2'}>
              <FormGroup className='d-inline p-0 m-0  '>
                <Label
                  style={{ whiteSpace: 'nowrap' }}
                  htmlFor='billing-name'
                  md='3'
                  className='d-block col-form-label p-0 m-0 mb-2 font-size-14 font-weight-bold '
                >
                  {getLabel()} {platform === 'bamboo' ? 'API' : 'Secret'} Key
                  <span className='text-danger font-size-16 mx-1'>*</span>
                </Label>
                <div
                  // style={{ paddingRight: mode === 'edit' ? '42%' : '7%' }}
                  className={'mb-3'}
                >
                  {platform === 'bamboo' ? (
                    <span className={'font-size-14  text-secondary'}>
                      Your {getLabel()} API Key is provided to you in your{' '}
                      {getLabel()} Account
                      <a
                        style={{ borderBottom: '1px solid blue' }}
                        href={
                          'http://help.remotepass.com/en/articles/5591011-how-to-integrate-bamboohr-with-remotepass'
                        }
                        className={'text-primary pl-1'}
                      >
                        Read More{' '}
                      </a>
                    </span>
                  ) : (
                    <span className={'font-size-14  text-secondary'}>
                      Your {getLabel()} Secret Key is provided to you in your{' '}
                      {getLabel()} HireAPI configuration
                      <span
                        style={{ borderBottom: '1px solid blue' }}
                        className={'text-primary pl-1 d-none'}
                      >
                        Read More{' '}
                      </span>
                    </span>
                  )}
                </div>
                <ControlledInput
                  control={control}
                  name={`${getInputName().key}`}
                  error={errors[getInputName().key]}
                />
              </FormGroup>
            </Col>
          </Row>
        </AvForm>
      </div>
    </div>
  )
})

export default Tab1
