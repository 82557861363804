import { yupResolver } from '@hookform/resolvers/yup'
import { AvForm } from 'availity-reactstrap-validation'
import classNames from 'classnames'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { slide as Menu } from 'react-burger-menu'
import CurrencyInput from 'react-currency-input-field'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { components } from 'react-select'
import {
  Button,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from 'reactstrap'
import styled from 'styled-components'
import * as yup from 'yup'

import ControlledCurrencyInput from '../../../components/ControlledCurrencyInput'
import ControlledSelect from '../../../components/ControlledSelect'
import TextInput from '../../../components/Forms/TextInput'
import { StyledH5 } from '../../../components/Typo'
import { useFetch, useResize } from '../../../helpers/hooks'
import {
  getAdminEORTaxes,
  getEORTaxes,
  getLocalCurrencies,
  updateAdminCalculatorTexts,
  updateCalculatorTexts,
} from '../../../services/api'
import {
  getOptionFromList,
  mapCountryToOption,
  mapCurrencyToOption,
} from '../../../utils/map-to-option'
import Quote from '../components/Quote'

const HalfCol = styled.div`
  --basis: 100%;
  flex-basis: var(--basis);

  @media (min-width: 992px) {
    --basis: calc(50% - 1.5rem / 2);
  }
`

const quoteSchema = yup.object().shape({
  country_id: yup.number().required(),
  amount: yup.number().required(),
  currency_id: yup.number().required(),
})

const FullTimeForm = forwardRef(function FullTimeForm(
  { edit, onComplete, quote, quoteDetails, readOnly },
  ref,
) {
  const countries = useSelector((state) => state?.Layout?.staticData?.countries)
  const [show, setShow] = useState(false)
  const [updateQuote, setUpdateQuote] = useState(false)
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(quoteSchema),
    defaultValues: quote || { currency_id: 1 },
  })

  const onSubmit = (v) => {
    onComplete(v)
  }

  const currencies = useFetch({
    action: getLocalCurrencies,
    autoFetch: true,
    initResult: [],
  })
  const currencyOptions = currencies?.data?.map((c) =>
    mapCurrencyToOption(c, 'id'),
  )

  useEffect(() => {
    // When I select a country, I want to set the currency
    // to the default currency of the country
    const selectedCountry = getOptionFromList(
      countries,
      watch('country_id'),
      'id',
    )

    const currencyItem = getOptionFromList(
      currencies?.data,
      selectedCountry?.currency,
      'code',
    )

    if (currencyItem?.id) {
      setValue('currency_id', currencyItem.id)
    }
  }, [watch('country_id')])

  const selectedCountry =
    countries?.length > 0
      ? getOptionFromList(countries, watch('country_id'), 'id')
      : null
  const localCountryCode = countries?.length > 0 ? selectedCountry?.iso2 : null

  const currId = watch('currency_id') ?? quote?.currency_id
  const customerCurrencyCode =
    currencies.data?.length > 0
      ? getOptionFromList(currencies?.data, currId, 'id')?.code
      : null

  return (
    <>
      {edit && localCountryCode && (
        <FieldsBurger
          countryCode={localCountryCode}
          onSubmitCompleted={() => setUpdateQuote(!updateQuote)}
          hide={() => setShow(false)}
          show={show}
        />
      )}
      <div>
        <AvForm
          ref={ref}
          autoComplete='off'
          className='form-horizontal'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='d-flex flex-wrap' style={{ gap: '1.5rem' }}>
            <HalfCol>
              <FormGroup className='d-inline p-0 m-0'>
                <Label
                  style={{ fontFamily: 'gilroyB', fontWeight: '600' }}
                  htmlFor='billing-name'
                  className='col-form-label p-0 m-0 mb-2 font-size-14'
                >
                  Country
                  <span className='text-danger font-size-16 mx-1'>*</span>
                </Label>
                <ControlledSelect
                  error={errors.country_id}
                  control={control}
                  name='country_id'
                  isDisabled={readOnly}
                  options={(countries ?? []).map((c) => mapCountryToOption(c))}
                />
              </FormGroup>
            </HalfCol>
            <HalfCol>
              <FormGroup className='d-inline p-0 m-0'>
                <Label
                  style={{ fontFamily: 'gilroyB', fontWeight: '600' }}
                  className='col-form-label p-0 m-0 mb-2 font-size-14'
                >
                  Yearly salary
                  <span className='text-danger font-size-16 mx-1'>*</span>
                </Label>
                <InputGroup style={{ zIndex: 6 }}>
                  <ControlledCurrencyInput
                    control={control}
                    name='amount'
                    error={errors.amount}
                    isDisabled={readOnly}
                  />
                  <InputGroupAddon
                    style={{ width: '125px' }}
                    addonType='append'
                  >
                    <InputGroupText
                      className={classNames({
                        'p-0': true,
                        'border-danger': !!errors.currency_id,
                      })}
                      style={{ width: '100%', border: 0 }}
                    >
                      <div className='w-100'>
                        <ControlledSelect
                          error={errors.currency_id}
                          control={control}
                          name='currency_id'
                          isDisabled={readOnly}
                          options={currencyOptions}
                          classNamePrefix='RS-Addon'
                          customComponents={{
                            Option: customOption,
                            Value: customSingleValue,
                            SingleValue: customSingleValue,
                          }}
                        />
                      </div>
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </HalfCol>
            {!!localCountryCode && !!customerCurrencyCode && !!quote && (
              <div style={{ flexBasis: '100%' }}>
                <Quote
                  quoteResults={(res) =>
                    quoteDetails ? quoteDetails(res) : null
                  }
                  updateQuote={updateQuote}
                  selectedCountry={selectedCountry}
                  localCountryCode={localCountryCode}
                  salaryAmountInCustomerCurrency={watch('amount')}
                  noOfHires={1}
                  customerCurrencyCode={customerCurrencyCode}
                />
              </div>
            )}
          </div>
        </AvForm>
      </div>

      {!!edit && !!quote && !readOnly && (
        <div className={'d-flex justify-content-end text-center '}>
          <Button
            onClick={() => setShow(true)}
            color='primary'
            className='px-5'
          >
            Edit
          </Button>
        </div>
      )}
    </>
  )
})

const { Option } = components
export const customOption = (props) => (
  <Option {...props}>
    <div className='input-select'>
      <div className='input-select__single-value d-flex flex-row align-items-center'>
        {props.data.flag && (
          <img
            src={props.data.flag}
            style={{
              width: '1rem',
              height: '1rem',
              borderRadius: '0.5rem',
              marginRight: 10,
              objectFit: 'cover',
            }}
            alt={props.data.code}
          />
        )}
        {props.data.icon}
        <span className='font-weight-light text-dark font-weight-bold'>
          {props.data.code}
        </span>
        <span
          className={'font-weight-light text-secondary ml-2'}
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {props.data.name}
        </span>
      </div>
    </div>
  </Option>
)

export const customSingleValue = (props) => (
  <div className='input-select'>
    <div className='input-select__single-value d-flex flex-row align-items-center'>
      {props.data.flag && (
        <img
          src={props.data.flag}
          style={{
            width: '1rem',
            height: '1rem',
            borderRadius: '0.5rem',
            marginRight: 10,
            objectFit: 'cover',
          }}
          alt={props.data.code}
        />
      )}
      {props.data.icon}
      <span
        className={'font-weight-light text-secondary'}
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {props.data.code}
      </span>
    </div>
  </div>
)

export const FieldsBurger = ({
  hide,
  show,
  countryCode,
  onSubmitCompleted,
}) => {
  const isMobile = useResize()
  const formRef = useRef()

  const [data, setData] = useState(null)
  const location = useLocation()
  const isAdmin = location.pathname.startsWith('/admin')

  const updateData = useFetch({
    action: isAdmin ? updateAdminCalculatorTexts : updateCalculatorTexts,
    withAdminAccess: isAdmin,
    onComplete: (data) => {
      onSubmitCompleted()
      hide()
    },
  })

  const getFieldsToChange = useFetch({
    action: isAdmin ? getAdminEORTaxes : getEORTaxes,
    withAdminAccess: isAdmin,
    onComplete: (res) => {
      setData(res)
    },
  })

  useEffect(() => {
    getFieldsToChange.startFetch({ countryCode })
  }, [countryCode])

  const handleSubmit = (v) => {
    updateData.startFetch({ data, code: countryCode })
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        zIndex: 9999,
      }}
    >
      <Menu
        onClose={hide}
        className={'bg-white'}
        isOpen={show}
        width={isMobile ? '100%' : '30%'}
        right
      >
        <div className={'p-3'}>
          <StyledH5 min={'20px'} max={'30px'}>
            RemotePass fee
          </StyledH5>
          <AvForm
            ref={formRef}
            className='form-horizontal'
            onValidSubmit={handleSubmit}
          >
            <FormGroup className='mb-md-4 mb-0 p-0 m-0 d-block' row>
              <div>
                {data?.fees?.value && (
                  <CurrencyInput
                    name={'amount'}
                    type='text'
                    onKeyPress={(e) => {
                      if (!/[0-9.]/.test(e.key)) {
                        e.preventDefault()
                      }
                    }}
                    required
                    placeholder='Amount'
                    id='basicpill-pancard-input7'
                    className='form-control'
                    defaultValue={data?.fees?.value}
                    onValueChange={(value) => {
                      const newData = data
                      newData.fees.value = value
                      setData(newData)
                    }}
                    // currency input props
                    allowDecimals={true}
                    decimalsLimit={2}
                    decimalSeparator='.'
                    groupSeparator=','
                  />
                )}
              </div>
            </FormGroup>

            <StyledH5 min={'20px'} max={'30px'}>
              Employee tax
            </StyledH5>
            {data?.employeeTaxData?.map((f, i) => (
              <div key={`f${i}`}>
                <FormGroup className='mb-md-4 mb-0 p-0 m-0 d-block' row>
                  <TextInput
                    onChange={(e) => {
                      const newData = data
                      newData.employeeTaxData[i].name = e.target.value
                      setData(newData)
                    }}
                    label={f?.name}
                    name={f?.name}
                    type='text'
                    className='form-control'
                    id={f?.name}
                    value={f?.name || 'N/A'}
                  />
                </FormGroup>
              </div>
            ))}
            <StyledH5 min={'20px'} max={'30px'}>
              Employer tax
            </StyledH5>
            {data?.taxData?.map((f, i) => (
              <div key={`f${i}`}>
                <FormGroup className='mb-md-4 mb-0 p-0 m-0 d-block' row>
                  <TextInput
                    onChange={(e) => {
                      const newData = data
                      newData.taxData[i].name = e.target.value
                      setData(newData)
                    }}
                    label={f?.name}
                    name={f?.name}
                    type='text'
                    className='form-control'
                    id={f?.name}
                    value={f?.name || 'N/A'}
                  />
                </FormGroup>
              </div>
            ))}
          </AvForm>

          <Button
            type='button'
            color='primary'
            onClick={() => {
              formRef.current.submit()
            }}
          >
            Save changes
          </Button>
        </div>
      </Menu>
    </div>
  )
}

export default FullTimeForm
