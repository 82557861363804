import cx from 'classnames'
import React from 'react'

import { ReactComponent as CardPendingIllustration } from '../../../assets/images/card-pending.svg'
import EmptyContent from './empty-content'

export default function PendingCard({
  className,
  cardLabel = 'RemotePass Card',
  wrapperProps = {},
  illustrationStyles,
}) {
  const wProps = { minHeight: 'calc(100vh - 300px)', ...wrapperProps }
  return (
    <EmptyContent className={cx('py-4 px-4 px-md-0', className)} {...wProps}>
      <CardPendingIllustration className='px-4' style={illustrationStyles} />

      <div className='text-center'>
        <h2 className='rp-font-gilroyB text-gray-h'>Your card is on the way</h2>
        <p className='mb-0 text-gray-600' style={{ maxWidth: '48ch' }}>
          You requested a <b>{cardLabel}</b>. You will get a notification when
          your card is approved.
        </p>
      </div>
    </EmptyContent>
  )
}
export function PendingPhysicalCard() {
  const wProps = {
    shadow: false,
    minHeight: '10rem',
    styles: { marginBottom: 0, gap: '1rem' },
  }
  return (
    <EmptyContent className='px-3 rp-max-w-100 flex-grow-1' {...wProps}>
      <CardPendingIllustration className='px-3' style={{ height: 110 }} />

      <div className='text-center'>
        <h4 className='rp-font-gilroyB text-gray-h'>
          Your Physical card is on its way
        </h4>

        <p
          className='mb-0 text-gray-600 uppercase'
          style={{ maxWidth: '48ch' }}
        >
          Your card should arrive within 15 business days.
        </p>
      </div>
    </EmptyContent>
  )
}
