import { slide as Menu } from 'react-burger-menu'
import React from 'react'
import { withTranslation } from 'react-i18next'
import WiseButton from './WiseButton'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import logoDark from '../assets/images/identity/logo_main.png'
import { Row } from 'reactstrap'
import burgerCloseIcon from '../../src/assets/images/burgerCloseIcon.svg'

import SidebarContent from '../pages/AdminPanel/components/VerticalLayout/SidebarContent'

const BurgerMenuPanel = (props) => {
  const { open, hide } = props

  return (
    <Menu false isOpen={open} onClose={hide} className='bg-white'>
      <Row
        style={{ height: 56 }}
        className='p-0 m-0 d-flex px-3 bg-soft-primary justify-content-between align-items-center border-bottom'
      >
        <Link to='/' className=''>
          <img src={logoDark} alt='' height='22' />
        </Link>
        <WiseButton
          text={''}
          minWidth={'32'}
          minHeight={'32px'}
          bk={'white'}
          padding={'0'}
          icon={
            <img
              src={burgerCloseIcon}
              style={{ width: 14 }}
              alt={'Close Icon'}
            />
          }
          textColor={'dark'}
          onClick={hide}
        />
      </Row>
      <div
        style={{ height: '80vh' }}
        className=' d-flex flex-column justify-content-between '
      >
        <SidebarContent />
      </div>
    </Menu>
  )
}

export default withTranslation()(withRouter(BurgerMenuPanel))
