import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import toastr from 'toastr'

import noTransactions from '../../assets/images/no-transactions.svg'
import NoContent from '../../components/NoContent'
import Button from '../../components/ui/button'
import Loader from '../../components/ui/loader'
import { PageTitle } from '../../components/ui/page-heading'
import { usePermissions } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import { getUnpaidList, getUpcomingPaymentList } from '../../services/api'
import { updateToPayList } from '../../store/payment/actions'
import { getPaymentIds } from '../payInvoices'
import PaymentList from './paymentList'

const Payment = ({ history, match }) => {
  const user = useSelector((state) => state.Account?.user)
  const profile = useSelector((state) => state.userProfile.userProfile)
  const { hasAccess } = usePermissions()
  const currency = profile?.company?.currency || profile?.currency
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [trigger, setTrigger] = useState(false)
  const [totalToPay, setTotalToPay] = useState(0)
  const updatePaymentList = () => {
    if (match.params?.id) {
      getUpcomingPaymentList(user?.token)
        .then((r) => {
          if (r.data.success) {
            setData(
              r.data.data.map((e) => ({
                ...e,
                selected: e.id === match.params?.id,
              })),
            )
          } else {
            toastr.error(r.data.message)
          }
          setLoading(false)
        })
        .catch((e) => {
          setLoading(false)
        })
    } else {
      getUnpaidList(user?.token)
        .then((r) => {
          if (r.data.success) {
            setData(r.data.data.map((e) => ({ ...e, selected: true })))
          } else {
            toastr.error(r.data.message)
          }
          setLoading(false)
        })
        .catch((e) => {
          setLoading(false)
        })
    }
  }

  const dispatch = useDispatch()

  useEffect(() => {
    const selectedElements = data.filter((e) => e.selected)

    if (selectedElements?.length > 1) {
      setTotalToPay(
        selectedElements?.reduce((prev, current) =>
          prev?.total_trans_amount
            ? prev?.total_trans_amount + current?.total_trans_amount
            : prev + current?.total_trans_amount,
        ),
      )
    } else if (selectedElements?.length === 1) {
      setTotalToPay(selectedElements[0]?.total_trans_amount)
    } else {
      setTotalToPay(0)
    }

    const ids = getPaymentIds(selectedElements)
    dispatch(updateToPayList(ids))

    if (trigger) {
      history.push('/pay-invoices')
    }
    return () => {
      setTrigger(false)
    }
  }, [data])

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency?.code || 'USD',
  })

  useEffect(() => {
    updatePaymentList()
  }, [])

  const AEDPayments = data.filter((e) => e?.currency?.code === 'AED')

  return (
    <div className='page-content'>
      {loading ? (
        <Loader minHeight='30rem' />
      ) : (
        <Container className='p-0 m-0' fluid>
          {data?.length === 0 ? (
            <Card className='p-0 m-0'>
              <CardBody>
                <NoContent
                  headline='Payments due'
                  subtitle='Payments due will be shown here'
                  image={noTransactions}
                />
              </CardBody>
            </Card>
          ) : (
            <Row className='p-0 m-0'>
              <Col className='p-0 m-0'>
                <div id='accordion'>
                  <Row className='p-0 m-0 mb-3 mb-md-4'>
                    <Col xs={12} md={9} className='p-0 m-0'>
                      <PageTitle className='mb-3 mb-md-0'>
                        Payments due
                      </PageTitle>
                    </Col>

                    {user?.type === 'client' &&
                      hasAccess(permissions.PrepareTransactions) && (
                        <Col
                          xs={12}
                          md={3}
                          className='p-0 m-0 justify-content-end align-items-end'
                        >
                          <Button
                            block
                            disabled={totalToPay === 0}
                            onClick={() => {
                              history.push('/pay-invoices')
                            }}
                          >
                            {`Pay ${
                              totalToPay === 0
                                ? ''
                                : formatter.format(totalToPay)
                            }`}
                          </Button>

                          {currency?.code === 'AED' &&
                            AEDPayments.length > 0 &&
                            AEDPayments.length < data.length && (
                              <div className='py-2'>
                                <a
                                  className='text-primary'
                                  onClick={() => {
                                    setTrigger(true)
                                    setData(
                                      data.map((inv) => ({
                                        ...inv,
                                        selected: inv?.currency?.code === 'AED',
                                      })),
                                    )
                                  }}
                                >
                                  Process AED payments separately
                                </a>
                              </div>
                            )}
                        </Col>
                      )}
                  </Row>
                  <Card>
                    <PaymentList
                      data={data}
                      onInvoiceSelected={(i) => {
                        setData(
                          data.map((inv, idx) => ({
                            ...inv,
                            selected: i === idx ? !inv.selected : inv.selected,
                          })),
                        )
                      }}
                    />
                  </Card>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      )}
    </div>
  )
}

export default Payment
