import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Alert, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import toastr from 'toastr'

import { useSelectedCard } from '..'
import ActionsDropdown from '../../../components/ActionsDropdown'
import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import Button from '../../../components/ui/button'
import { useFetch } from '../../../helpers/hooks'
import {
  cardSecurityCodes,
  getCardEnumerations,
  updateCardStatus,
} from '../../../services/api'
import { getErrors } from '../../../utils/get-errors'
import { BxIcon } from '../../AdminPanel/pages/Transactions'
import { CARD_TYPE_ENUM, isCardActive } from '../utils/card-types-status'
import { handleErrorOnComplete } from '../utils/handle-error-on-complete'
import { CreditCard } from './credit-card'

const CARD_STATUS_ACTIONS = {
  LOCK: 'freeze',
  UNLOCK: 'unfreeze',
  TERMINATE: 'block',
}

let cancelCardDetailsShow = false

export default function ActiveCreditCardSection({
  cardData,
  setAllCardData,
  refetchCards,
}) {
  const [confirmationModalProps, setConfirmationModalProps] = useState(false)
  const [blockReason, setBlockReason] = useState('stolen')
  const [actionsOpen, setActionsOpen] = useState(false)

  const { selectedCard } = useSelectedCard()

  const history = useHistory()
  const userProfile = useSelector((state) => state.userProfile?.userProfile)

  const isActiveCardPhysical = selectedCard === CARD_TYPE_ENUM.PHYSICAL

  const { startFetch: fetchCardSecurityCodes, isLoading: loadingUnmaskedData } =
    useFetch({
      action: cardSecurityCodes,
      onComplete: handleCardDetailsComplete,
      onError: (err) => {
        toastr.error(getErrors(err))
      },
    })

  const { startFetch: updateCard, isLoading: updatingCardStatus } = useFetch({
    action: updateCardStatus,
    onComplete: (data) => {
      document.body.classList.remove('modal-open')

      handleErrorOnComplete({
        data,
        successMessage: 'Card status updated successfully',
        errorMessage: 'Error updating card status',
        onSuccess: () => {
          handleToggleConfirmationModal()
          refetchCards()
        },
      })
    },
    onError: () => {
      toastr.error('Error updating card status, please retry again')
      handleToggleConfirmationModal()
    },
  })

  function handleToggleFreezeCard() {
    updateCard({
      id: cardData?.id,
      status: isCardActive(cardData?.cardStatus)
        ? CARD_STATUS_ACTIONS.LOCK
        : CARD_STATUS_ACTIONS.UNLOCK,
      rp_user_id: userProfile?.id,
    })
  }

  function handleTerminateCard() {
    updateCard({
      id: cardData?.id,
      status: CARD_STATUS_ACTIONS.TERMINATE,
      rp_user_id: userProfile?.id,
      block_reason: blockReason,
    })
  }

  function handleCardDetailsComplete(data) {
    if (cancelCardDetailsShow) {
      return
    }

    const newCardData = {
      unmaskedCardNumber: data?.number,
      cvv: data?.cvv,
      validUntil: data?.date?.expiry,
      showDetails: true,
    }

    const cardType = data?.card_type?.type

    setAllCardData((prev) => {
      const newData = { ...prev }
      newData[cardType] = { ...prev[cardType], ...newCardData }

      return newData
    })
  }

  function handleShowData() {
    cancelCardDetailsShow = false
    if (cardData?.unmaskedCardNumber) {
      setAllCardData((prev) => ({
        ...prev,
        [selectedCard]: { ...prev[selectedCard], showDetails: true },
      }))
    } else {
      fetchCardSecurityCodes({
        id: cardData?.id,
        rp_user_id: userProfile?.id,
        masked_number: cardData?.number,
      })
    }
  }

  function handleHideData() {
    cancelCardDetailsShow = true

    setAllCardData((prev) => ({
      ...prev,
      [selectedCard]: { ...prev[selectedCard], showDetails: false },
    }))
  }

  function toggleCardDetails() {
    const showDetails = cardData?.showDetails
    if (showDetails) {
      handleHideData()
    } else {
      handleShowData()
    }
  }

  function handleToggleConfirmationModal() {
    setConfirmationModalProps((prev) => ({ ...prev, isOpen: false }))
    setTimeout(() => {
      setConfirmationModalProps(null)
    }, 200)
  }

  const cardActionOptions = [
    {
      id: 2,
      onClick: () => {
        const actionText = isCardActive(cardData?.cardStatus)
          ? 'freeze'
          : 'unfreeze'

        const lastFourDigits = cardData.maskedCardNumber.slice(-4)

        setConfirmationModalProps({
          isOpen: true,
          toggle: handleToggleConfirmationModal,
          title: `${actionText} card`,
          content: (
            <p
              className='font-size-16 text-slate-600'
              style={{ maxWidth: '32ch' }}
            >
              Are you sure you want to <b>{actionText}</b> this card (**** ****
              **** {lastFourDigits})?
            </p>
          ),
          onConfirm: handleToggleFreezeCard,
          confirmText: `Yes, ${actionText} this card`,
          confirmColor: 'danger',
          cancelColor: 'light',
        })
      },
      content: (
        <>
          <BxIcon name='bx bxs-lock' className='font-size-16' />
          <span>
            {isCardActive(cardData?.cardStatus) ? 'Freeze' : 'Unfreeze'}
          </span>
        </>
      ),
    },
    {
      id: 3,
      onClick: () => {
        const lastFourDigits = cardData.maskedCardNumber.slice(-4)

        setConfirmationModalProps({
          isOpen: true,
          toggle: handleToggleConfirmationModal,
          title: 'Terminate card',
          Component: () => (
            <TerminateModalContent
              lastFourDigits={lastFourDigits}
              setBlockReason={setBlockReason}
            />
          ),
          onConfirm: handleTerminateCard,
          confirmText: `Yes, terminate this card`,
          confirmColor: 'danger',
          cancelColor: 'light',
        })
      },
      content: (
        <>
          <BxIcon name='bx bxs-x-circle' className='font-size-16' />
          <span>Terminate</span>
        </>
      ),
    },
    isActiveCardPhysical &&
      isCardActive(cardData?.cardStatus) && {
        id: 4,
        onClick: () => {
          history.push('/cards/reset-pin')
          window.scroll(0, 0)
        },
        content: (
          <>
            <span className='d-flex'>{pinLockIcon}</span>
            <span>Reset PIN</span>
          </>
        ),
      },
  ].filter(Boolean)

  return (
    <>
      <CreditCard
        {...cardData}
        loading={loadingUnmaskedData}
        onMouseEnter={
          selectedCard === CARD_TYPE_ENUM.VIRTUAL ? handleShowData : null
        }
        onMouseLeave={
          selectedCard === CARD_TYPE_ENUM.VIRTUAL ? handleHideData : null
        }
        onToggleCardDetails={toggleCardDetails}
      />

      <div
        className='d-flex align-items-center justify-content-end p-4 w-100 mt-auto border-top'
        style={{ gap: '1rem' }}
      >
        <div className='font-size-16 rp-font-bold rp-font-gilroyB'>
          {cardData?.line1}
        </div>

        <ActionsDropdown
          options={cardActionOptions}
          isOpen={actionsOpen}
          toggle={() => setActionsOpen((o) => !o)}
          CustomToggle={(props) => {
            const btnProps = {
              'aria-expanded': props['aria-expanded'],
              'aria-haspopup': props['aria-haspopup'],
              onClick: props.onClick,
            }
            return (
              <button
                {...btnProps}
                ref={props.innerRef}
                className='btn btn-outline-light d-flex align-items-center'
                style={{ gap: '0.5rem' }}
              >
                <i
                  className='bx bx-dots-vertical-rounded font-size-18'
                  style={{ marginLeft: -4 }}
                />
                <span>Settings</span>
              </button>
            )
          }}
        />
      </div>

      <ConfirmationModal
        {...confirmationModalProps}
        loading={updatingCardStatus}
      />
    </>
  )
}

function ConfirmationModal({
  isOpen,
  toggle,
  title,
  content,
  confirmText,
  confirmColor = 'primary',
  onConfirm,
  cancelText,
  cancelColor = 'primary',
  onCancel,
  loading,
  centered = true,
  Component,
}) {
  const body = Component ? <Component /> : content

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered={centered}
      className='native-dialog-sm'
    >
      {!title ? null : (
        <ModalHeader
          className='border-0'
          cssModule={{
            'modal-title':
              'align-items-center d-flex flex-column modal-title w-100 gap-8',
          }}
        >
          <i className='bg-soft-danger bx bxs-error font-size-28 p-2 rounded-circle text-danger' />
          <span className='text-muted rp-capitalize'>{title}</span>
        </ModalHeader>
      )}
      {!body ? null : <ModalBody>{body}</ModalBody>}
      <ModalFooter className='border-0'>
        <Button
          loading={loading}
          disabled={loading}
          type='button'
          color={cancelColor}
          outline
          onClick={onCancel ?? toggle}
        >
          {cancelText ?? 'Cancel'}
        </Button>
        <Button
          loading={loading}
          disabled={loading}
          type='button'
          color={confirmColor}
          onClick={onConfirm}
        >
          {confirmText ?? 'Cancel'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

function TerminateModalContent({ lastFourDigits, setBlockReason }) {
  const { data: enumerations, isLoading } = useFetch({
    action: getCardEnumerations,
    autoFetch: true,
  })

  const options = isLoading
    ? []
    : enumerations?.card_termination_reasons?.map((p) => {
        return { label: p.toUpperCase(), value: p }
      }) || []

  return (
    <div>
      <Alert color='orange' className='font-size-14'>
        <p className='mb-0' style={{ maxWidth: '34ch' }}>
          Are you sure you want to <b>terminate</b> this card (**** **** ****{' '}
          {lastFourDigits})?
          <br />
          This action <b>cannot be reversed</b>.
        </p>
      </Alert>
      {isLoading || !enumerations ? (
        <div>
          <label>Reason</label>
          <div style={{ height: 42.55 }} className='bg-light rounded' />
        </div>
      ) : (
        <ReasonDropDown onChange={setBlockReason} options={options} />
      )}
    </div>
  )
}

function ReasonDropDown({ onChange, options }) {
  const [reason, setReason] = useState(options?.[0])

  return (
    <CustomSelect
      label='Reason'
      value={reason}
      onChange={(value) => {
        setReason(value)
        onChange(value?.value)
      }}
      options={options}
    />
  )
}

const pinLockIcon = (
  <svg
    width='16'
    height='14'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12 6H10V4C10 3.46957 9.78929 2.96086 9.41421 2.58579C9.03914 2.21071 8.53043 2 8 2C7.46957 2 6.96086 2.21071 6.58579 2.58579C6.21071 2.96086 6 3.46957 6 4V6H4V4C4 2.93913 4.42143 1.92172 5.17157 1.17157C5.92172 0.421427 6.93913 0 8 0C9.06087 0 10.0783 0.421427 10.8284 1.17157C11.5786 1.92172 12 2.93913 12 4V6Z'
      fill='currentColor'
    />
    <path
      d='M14 7H2C1.73478 7 1.48043 7.10536 1.29289 7.29289C1.10536 7.48043 1 7.73478 1 8V15C1 15.2652 1.10536 15.5196 1.29289 15.7071C1.48043 15.8946 1.73478 16 2 16H14C14.2652 16 14.5196 15.8946 14.7071 15.7071C14.8946 15.5196 15 15.2652 15 15V8C15 7.73478 14.8946 7.48043 14.7071 7.29289C14.5196 7.10536 14.2652 7 14 7Z'
      fill='currentColor'
    />
    <path
      d='M4.10232 12.8571L4.14928 11.8105L3.2694 12.3785L3 11.9072L3.93426 11.4286L3 10.9499L3.2694 10.4787L4.14928 11.0466L4.10232 10H4.63866L4.5917 11.0466L5.47158 10.4787L5.74098 10.9499L4.80672 11.4286L5.74098 11.9072L5.47158 12.3785L4.5917 11.8105L4.63866 12.8571H4.10232Z'
      fill='white'
    />
    <path
      d='M7.73183 12.8571L7.77879 11.8105L6.89891 12.3785L6.62951 11.9072L7.56377 11.4286L6.62951 10.9499L6.89891 10.4787L7.77879 11.0466L7.73183 10H8.26817L8.22121 11.0466L9.10109 10.4787L9.37049 10.9499L8.43623 11.4286L9.37049 11.9072L9.10109 12.3785L8.22121 11.8105L8.26817 12.8571H7.73183Z'
      fill='white'
    />
    <path
      d='M11.3613 12.8571L11.4083 11.8105L10.5284 12.3785L10.259 11.9072L11.1933 11.4286L10.259 10.9499L10.5284 10.4787L11.4083 11.0466L11.3613 10H11.8977L11.8507 11.0466L12.7306 10.4787L13 10.9499L12.0657 11.4286L13 11.9072L12.7306 12.3785L11.8507 11.8105L11.8977 12.8571H11.3613Z'
      fill='white'
    />
  </svg>
)
