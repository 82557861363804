import { format } from 'date-fns'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Alert, Col, Row } from 'reactstrap'

import KycPending from '../../assets/images/kyc-pending.svg'
import KycVerification from '../../assets/images/kyc-verification.svg'
import KycVerified from '../../assets/images/kyc-verified.svg'
import shield from '../../assets/images/shield.svg'
import Button from '../../components/ui/button'
import Loader from '../../components/ui/loader'
import { useFetch } from '../../helpers/hooks'
import { getContractorInfo, requestKYC } from '../../services/api'
import { updateContractRef } from '../../store/profile/actions'
import capitalizeFirstLetter from '../../utils/capitalize-first-letter'

const NEEDS_INFO_STATUS = 'needs-info'
const KYC_PROVIDERS = { VERIFF: 'veriff', IDWISE: 'idwise' }

export default function Kyc() {
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector((state) => state?.Account?.user)
  const userProfile = useSelector((state) => state?.userProfile?.userProfile)
  const contractRef = useSelector((state) => state?.userProfile?.contractRef)

  const location = history.location
  const needsReverification = location.state?.needsReverification
  const missingDocs = !!needsReverification

  const {
    data: contractorInfo,
    isLoading: infoLoading,
    startFetch: getInfo,
  } = useFetch({
    action: getContractorInfo,
    initResult: {},
  })

  useEffect(() => {
    window.analytics.track('Viewed KYC', {
      email_id: user?.email,
    })

    getInfo()
  }, [])

  useEffect(() => {
    if (
      contractorInfo?.kyc_verified === 1 &&
      !!contractRef &&
      contractRef?.type === 'kyc'
    ) {
      history.push(`/contract/detail?id=${contractRef?.ref}`)
      dispatch(updateContractRef(null))
    }
  }, [contractRef, contractorInfo?.kyc_verified, dispatch, history])

  const kycStatus = useMemo(() => {
    const firstName = userProfile?.first_name
    const lastName = userProfile?.last_name
    const birthDate = userProfile?.birth_date

    const needsInfo = !firstName || !lastName || !birthDate

    const kycStatus = needsInfo ? NEEDS_INFO_STATUS : contractorInfo.kyc_status

    return kycStatus
  }, [
    contractorInfo.kyc_status,
    userProfile?.birth_date,
    userProfile?.first_name,
    userProfile?.last_name,
  ])

  return (
    <Row className='p-4'>
      <Col>
        {infoLoading ? (
          <Loader minHeight='30vh' />
        ) : (
          <div className='text-center py-3' style={{ minHeight: 330 }}>
            <img
              src={getKycProfile(kycStatus).illustration.src}
              alt={getKycProfile(kycStatus).illustration.alt}
              className='d-block mx-auto'
              style={{ height: '200px', width: '50%' }}
            />
            <h2 style={{ fontFamily: 'gilroyB', color: '#424652' }}>
              {getKycProfile(kycStatus).title}
            </h2>

            <VerificationDescription
              description={getKycProfile(kycStatus).description}
              provider={contractorInfo?.kyc_provider}
            />

            {kycStatus !== 'pending' ? null : (
              <Alert
                color='info'
                style={{ marginTop: '-1rem', maxWidth: '66ch' }}
                className='mx-auto'
              >
                Make sure you’ve entered your name exactly as it is shown on
                your passport or ID card. After you’ve been verified, you will
                not be able to edit it.
              </Alert>
            )}

            {kycStatus !== 'failed' ? null : (
              <Alert
                color='warning'
                style={{ marginTop: '-1rem', maxWidth: '66ch' }}
                className='mx-auto'
              >
                You’re last verification failed, please try again.
              </Alert>
            )}

            <RenderKYCAction
              status={kycStatus}
              missingDocs={missingDocs}
              kycProvider={contractorInfo?.kyc_provider}
              verifiedAt={contractorInfo?.kyc_verified_at}
            />
          </div>
        )}
      </Col>
    </Row>
  )
}

function VerificationDescription({ description, provider = '' }) {
  if (!description) return null

  if (typeof description === 'string') {
    return (
      <p
        className='text-muted w-75 font-size-16 mx-auto'
        style={{ marginBottom: '1.825rem' }}
      >
        {description}
      </p>
    )
  }
  if (typeof description === 'function') {
    return (
      <p
        className='text-muted w-75 font-size-16 mx-auto'
        style={{ marginBottom: '1.825rem' }}
      >
        {description({ provider })}
      </p>
    )
  }

  return React.cloneElement(description, {
    className: 'text-muted w-75 font-size-16 mx-auto',
    style: { marginBottom: '1.825rem' },
  })
}

function RenderKYCAction({ status, kycProvider, verifiedAt, missingDocs }) {
  switch (status) {
    case NEEDS_INFO_STATUS: {
      return (
        <Link to='/settings#profile' className='btn btn-primary'>
          Complete your profile
        </Link>
      )
    }

    case 'verified': {
      return (
        <>
          <VerifiedLine verifiedAt={verifiedAt} />

          {!missingDocs ? null : (
            <>
              <br />

              <p className='text-muted'>
                Your verification has expired. Please, re-verify.
              </p>

              <VerificationAction kycProvider={kycProvider} />
            </>
          )}
        </>
      )
    }

    case 'submitted': {
      return null
    }

    default: {
      return <VerificationAction kycProvider={kycProvider} />
    }
  }
}

export function VerificationAction({
  kycProvider,
  children = 'Get Verified Now',
  className = 'mx-auto',
}) {
  const [loading, setLoading] = useState(false)
  const user = useSelector((state) => state?.Account?.user)

  const handleRequestKYC = useCallback(() => {
    setLoading(true)
    window.analytics.track('Clicked KYC button', {
      email_id: user?.email,
    })

    requestKYC(user?.token)
      .then((r) => {
        window.location.replace(r.data.data?.url)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [setLoading, user?.email, user?.token])

  return kycProvider === KYC_PROVIDERS.IDWISE ? (
    <Link to='/idwise-verification' className='btn btn-primary'>
      {children}
    </Link>
  ) : (
    <Button
      onClick={handleRequestKYC}
      loading={loading}
      disabled={loading}
      className={className}
    >
      {children}
    </Button>
  )
}

function VerifiedLine({ verifiedAt }) {
  return (
    <div className='d-flex justify-content-center align-items-center'>
      <img
        src={shield}
        alt='shield icon'
        style={{ width: '0.825rem', marginRight: 8 }}
      />
      <p style={{ color: '#22C799' }} className='font-size-14 mb-0'>
        Identity verified
        {!verifiedAt
          ? null
          : ` on ${format(new Date(verifiedAt * 1000), 'MM/dd/yyyy hh:mm')}`}
      </p>
    </div>
  )
}

function getKycProfile(status) {
  switch (status) {
    case NEEDS_INFO_STATUS: {
      return {
        illustration: {
          src: KycVerification,
          alt: 'kyc verification illustration',
        },
        title: 'Identity unverified',
        description: (
          <p>
            Your profile info are not complete. Please add{' '}
            <strong>your personal information</strong> before starting the
            verification.
          </p>
        ),
      }
    }

    case 'failed':
    case 'pending': {
      return {
        illustration: {
          src: KycVerification,
          alt: 'kyc verification illustration',
        },
        title: 'Identity unverified',
        description: ({ provider }) =>
          `Identity verification only takes a few minutes. Have your ID handy and you will be taken to our ID partner ${capitalizeFirstLetter(
            provider,
          )}.`,
      }
    }
    case 'submitted': {
      return {
        illustration: {
          src: KycPending,
          alt: 'kyc verification pending illustration',
        },
        title: 'Verification in progress',
        description: '',
      }
    }
    case 'verified': {
      return {
        illustration: {
          src: KycVerified,
          alt: 'kyc verified illustration',
        },
        title: 'Verification completed',
        description: '',
      }
    }

    default: {
      return {
        illustration: { src: '', alt: '' },
        title: '',
        description: '',
      }
    }
  }
}
