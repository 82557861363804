import React, { useEffect, useMemo, useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Container, Row, TabContent, TabPane } from 'reactstrap'
import Col from 'reactstrap/lib/Col'
import toastr from 'toastr'

import ModalHeader from '../../../components/ModalHeader'
import NoContent from '../../../components/NoContent'
import Steps from '../../../components/Steps'
import StepContainer from '../../../components/Steps/StepContainer'
import WiseButton from '../../../components/WiseButton'
import Wootric from '../../../components/Wootric/Wootric'
import { contractTypes } from '../../../helpers/enum'
import { useFetch, useResize } from '../../../helpers/hooks'
import {
  createContract,
  createFulltimeContract,
  getTemplateList,
} from '../../../services/api'
import { setContractToDuplicate } from '../../../store/contract/actions'
import ComplianceForm from '../components/Forms/ComplianceForm'
import InfoForm from '../components/Forms/InfoForm'
import MilestonesForm from '../components/Forms/MilestonesForm'
import PaymentForm from '../components/Forms/PaymentForm'
import FullTimeDetailsForm from '../RequestContract/FullTimeDetailsForm'
import FullTimeForm from '../RequestContract/FullTimeForm'
import confirmPayImage from './../../../assets/images/payment_confirmation.svg'

const CreateContract = () => {
  const [activeTab, setActiveTab] = useState(0)
  const user = useSelector((state) => state?.Account?.user)
  const toDuplicate = useSelector((state) => state?.Contract?.toDuplicate)
  const ref = useRef()
  const quoteRef = useRef()
  const fullTimeRef = useRef()
  const detailsFormRef = useRef()
  const payRef = useRef()
  const compRef = useRef()
  const isMobile = useResize()
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [data, setData] = useState(null)
  const [tempData, setTempData] = useState({})
  const [milestones, setMilestones] = useState([])
  const [quote, setQuote] = useState(null)
  const history = useHistory()

  const templates = useFetch({
    action: getTemplateList,
    autoFetch: true,
  })

  const [fullTimeData, setFullTimeData] = useState(null)

  const dispatch = useDispatch()
  const handleContractCreation = (
    e,
    values,
    file,
    notice,
    contractMedia,
    // taxResidenceId,
    template,
  ) => {
    if (contractMedia === -1) {
      toastr.error('Contract is missing', 'error')
      return
    }
    // if (!taxResidenceId) {
    //   toastr.error('Please select the Contractor Tax country', 'error')
    //   return
    // }
    if (contractMedia === 1 && !file) {
      toastr.error('Please upload your Contract file', 'error')
      return
    }
    if (contractMedia === 2 && !template) {
      toastr.error('Please select one of your templates', 'error')
      return
    }
    setLoading(true)

    const formData = {
      ...data,
      ...values,
      kyc: true,
      notice_period: notice,
      extra: 'extra',
      client_can_submit: true,
      milestones,
      template_id: template?.value,
      file,
      // tax_residence_id: taxResidenceId,
    }

    delete formData.contractType
    delete formData.currency

    if (data?.contractType?.value === contractTypes.FULL_TIME) {
      formData.currency_id = 1
    }

    createContract(user?.token, formData, data?.contractType?.value)
      .then((r) => {
        setLoading(false)
        if (r.data.success) {
          toastr.success('Contract created successfully')
          setCompleted(true)
          window.analytics.track('Contract_created', {
            contract_id: r.data.data?.ref,
            contract_name: r.data.data?.name,
            type: user?.type,
            contractor_label: formData?.contractor_name,
          })
          history.push(`/contract/detail?id=${r.data.data?.ref}`)
        } else {
          toastr.error(r.data?.message || 'An error occurred', 'error')
        }
      })
      .catch((e) => {
        setLoading(false)
        toastr.error('An error occurred', 'error')
      })

    setData({ ...data, ...values })
  }

  const send = useFetch({
    action: createFulltimeContract,
    onComplete: (data) => {
      if (data?.id) {
        history.push(`/contract/detail?id=${data?.ref}`)
      } else {
        let errors = ''
        if (data?.message === 'Validation Error.') {
          errors = Object.values(data?.data).reduce((prev, curr) => {
            return curr + '<br/>' + prev
          }, '')
        }
        toastr.error(errors, data?.message)
      }
    },
    onError: (err) => {
      toastr.success(err)
    },
  })

  useEffect(() => {
    return () => {
      dispatch(setContractToDuplicate(null))
    }
  }, [])

  const stepsData = useMemo(() => {
    return getSteps(data?.contractType?.value)
  }, [data?.contractType?.value])

  function getSteps(contractType) {
    if (contractType === contractTypes.FULL_TIME) {
      return ['Info', 'Simulation', 'Details']
    } else {
      return ['Info', 'Payment', 'Compliance']
    }
  }

  return (
    <div className='page-content min-vh-100 p-0 m-0'>
      <Container fluid>
        <ModalHeader
          action={
            history.location.state?.duplicating
              ? () => history.push(history.location.state?.backRoute)
              : null
          }
        >
          {!isMobile && <Steps activeTab={activeTab} data={stepsData} />}
        </ModalHeader>

        <Row className='justify-content-center'>
          <Col md={8}>
            <TabContent activeTab={activeTab} style={{ marginBottom: '10rem' }}>
              <TabPane tabId={0}>
                <StepContainer
                  title='Contract Details'
                  index={0}
                  total={
                    data?.contractType?.value === contractTypes.FULL_TIME
                      ? 4
                      : 3
                  }
                  onNext={() => {
                    if (data?.contractType?.value === contractTypes.FULL_TIME) {
                      quoteRef.current.submit()
                    } else {
                      ref.current.submit()
                    }
                  }}
                  loading={send.isLoading}
                  onBack={() => setActiveTab(activeTab - 1)}
                >
                  <InfoForm
                    ref={ref}
                    withContractType
                    onContractTypeChanged={(contractType) => {
                      setData({ contractType })
                    }}
                    onSubmit={(e) => {
                      setActiveTab(activeTab + 1)
                      setData({ ...data, ...e })
                    }}
                    duplicating={!!toDuplicate}
                    data={toDuplicate}
                  />
                  {data?.contractType?.value === contractTypes.FULL_TIME && (
                    <FullTimeForm
                      onComplete={(q) => {
                        setQuote(q)
                        setActiveTab(activeTab + 1)
                      }}
                      ref={quoteRef}
                      data={toDuplicate}
                    />
                  )}
                </StepContainer>
              </TabPane>
              {activeTab === 1 && (
                <TabPane tabId={1}>
                  <StepContainer
                    minHeight='50vh'
                    title={
                      data?.contractType?.value === contractTypes.FULL_TIME
                        ? 'Simulation'
                        : 'Payment schedule'
                    }
                    index={1}
                    total={
                      data?.contractType?.value === contractTypes.FULL_TIME
                        ? 4
                        : 3
                    }
                    onNext={() => {
                      if (
                        data?.contractType?.value === contractTypes.FULL_TIME
                      ) {
                        fullTimeRef.current.submit()
                      } else {
                        payRef.current.submit()
                      }
                    }}
                    onBack={() => setActiveTab(activeTab - 1)}
                  >
                    {data?.contractType?.value === contractTypes.MILESTONES ? (
                      <MilestonesForm
                        ref={payRef}
                        onSubmit={(e, m) => {
                          setMilestones(m)
                          setData({ ...data, ...e })
                          setActiveTab(activeTab + 1)
                        }}
                      />
                    ) : data?.contractType?.value ===
                      contractTypes.FULL_TIME ? (
                      <FullTimeForm
                        ref={fullTimeRef}
                        onComplete={(v) => {
                          setFullTimeData(v)
                          setActiveTab(activeTab + 1)
                        }}
                        quote={quote}
                      />
                    ) : (
                      <PaymentForm
                        ref={payRef}
                        data={data || toDuplicate}
                        duplicatingData={toDuplicate}
                        setTempData={(e) =>
                          setTempData((prev) => ({ ...prev, ...e }))
                        }
                        tempData={tempData}
                        onSubmit={(e) => {
                          setData({ ...data, ...e })
                          setActiveTab(activeTab + 1)
                        }}
                      />
                    )}
                  </StepContainer>
                </TabPane>
              )}
              <TabPane tabId={2}>
                <StepContainer
                  title={
                    data?.contractType?.value === contractTypes.FULL_TIME
                      ? 'Contract Details'
                      : 'Compliance Details'
                  }
                  index={2}
                  total={
                    data?.contractType?.value === contractTypes.FULL_TIME
                      ? 4
                      : 3
                  }
                  onNext={() => {
                    if (data?.contractType?.value === contractTypes.FULL_TIME) {
                      detailsFormRef.current.submit()
                    } else {
                      compRef.current.submit()
                    }
                  }}
                  loading={loading || send.isLoading}
                  onBack={() => setActiveTab(activeTab - 1)}
                  nextText='Create'
                >
                  {data?.contractType?.value === contractTypes.FULL_TIME ? (
                    <FullTimeDetailsForm
                      onComplete={(res) => {
                        const body = { ...fullTimeData, ...res }
                        send.startFetch(body)
                      }}
                      ref={detailsFormRef}
                      initialData={data}
                      quote={quote}
                    />
                  ) : (
                    <ComplianceForm
                      ref={compRef}
                      onSubmit={handleContractCreation}
                      templates={templates.data}
                      type={data?.contractType?.value}
                      data={toDuplicate}
                    />
                  )}
                </StepContainer>
              </TabPane>
              <TabPane tabId={3}>
                <NoContent
                  headline='Request has been submitted'
                  subtitle='New contract request has been submitted sucessfully.'
                  image={confirmPayImage}
                  withAction={true}
                  customBottom={
                    <WiseButton
                      onClick={() => history.goBack()}
                      text='Back to Dashboard'
                      minHeight='48px'
                      minWidth='100%'
                    />
                  }
                />
              </TabPane>
            </TabContent>
            {completed && <Wootric />}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default withTranslation()(CreateContract)
