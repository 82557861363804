import React, { useMemo } from 'react'
import { Card } from 'reactstrap'

import DataTable from '../../../../components/ui/data-table'
import Loader from '../../../../components/ui/loader'
import NoContent from '../../../../components/ui/no-content'
import Pagination from '../../../../components/ui/pagination'
import { useFetch } from '../../../../helpers/hooks'
import { adminCardsTransactions } from '../../../../services/api'
import { usdFormatter } from '../../../../utils/formatters/currency'
import { trxIcon } from '../../../remotepass-cards/components/latest-transactions'
import { dateCell } from './cell'
import useFilter from './use-filters'

function getBodyFromFilters(filters) {
  return { ...filters, page: filters.page - 1 }
}

export default function AdminCardsTransactions() {
  const [filters, handleFiltersChange] = useFilter({ page: 1 })

  const { isLoading, data } = useFetch(
    {
      action: adminCardsTransactions,
      withAdminAccess: true,
      autoFetch: true,
      body: getBodyFromFilters(filters),
    },
    [filters],
  )

  const items = data?.items ?? []

  const columns = useMemo(
    () => [
      { Header: 'Date', accessor: 'created_at', Cell: dateCell },
      {
        Header: 'Type',
        accessor: 'authorization.transaction_type',
        Cell: ({ cellData }) => {
          const icon = trxIcon[cellData]
          return (
            <div className='d-flex align-items-center gap-4'>
              <i
                className={`${icon} bg-slate-100 rounded-circle text-success`}
                style={{ padding: '0.125rem' }}
              />
              {cellData}
            </div>
          )
        },
      },
      { Header: 'Merchant name', accessor: 'merchant_details.name' },
      {
        Header: 'Card number',
        accessor: 'card.last_4_digits',
        Cell: ({ cellData }) => '**** **** **** ' + cellData,
      },
      { Header: 'Channel', accessor: 'payment_channel.channel_name' },
      { Header: 'Amount', accessor: 'authorization.transaction_amount' },
      { Header: 'Currency', accessor: 'authorization.transaction_currency' },
      {
        Header: 'USD value',
        Cell: ({ rowData }) =>
          usdFormatter.format(rowData.authorization.total_amount),
      },
      { Header: 'First name', accessor: 'user.name.first' },
      { Header: 'Last name', accessor: 'user.name.last' },
      { Header: 'Email', accessor: 'user.email' },
    ],
    [],
  )

  return (
    <div className='page-content'>
      <div style={{ marginBottom: '2rem' }}>
        <h2 className='h1 mb-0 rp-font-gilroyB'>Card Transactions</h2>
      </div>
      <Card>
        {isLoading ? (
          <Loader minHeight='max(50vh, 550px)' />
        ) : items?.length <= 0 ? (
          <NoContent
            className='text-muted font-size-16'
            minHeight='max(50vh, 550px)'
          >
            No items found
          </NoContent>
        ) : (
          <>
            <DataTable responsive data={items ?? []} columns={columns} />

            <div className='d-flex justify-content-end px-3'>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={data?.limit ?? 10}
                totalItemsCount={data?.total_items_count ?? 0}
                onChange={(v) => handleFiltersChange('page', v)}
              />
            </div>
          </>
        )}
      </Card>
    </div>
  )
}
