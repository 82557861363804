import React, { useState } from 'react'
import { Card } from 'reactstrap'
import toastr from 'toastr'

import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import { useFetch } from '../../../../helpers/hooks'
import { getContractorBalancesAdmin } from '../../../../services/api'
import BalanceAdjustmentModal from './balance-adjustment-modal'
import BalancesList from './balances-list'

const adjustmentStatuses = [
  { value: 'pending', label: 'Pending' },
  { value: 'approved', label: 'Approved' },
  { value: 'rejected', label: 'Rejected' },
]

export default function BalancesContractors() {
  const [filters, setFilters] = useState({
    status: adjustmentStatuses[0].value,
    page: 1,
  })

  const {
    data: balances,
    paginator: balancesPaginator,
    startFetch: refreshContractorsBalances,
    isLoading: gettingBalances,
  } = useFetch(
    {
      action: getContractorBalancesAdmin,
      withAdminAccess: true,
      autoFetch: true,
      body: filters,
      onError: () => {
        toastr.error('Something went wrong. Please try again later.')
      },
    },
    [filters],
  )

  function refreshBalances() {
    refreshContractorsBalances(filters)
  }

  function handleChange(value, name) {
    setFilters((prev) => ({ ...prev, [name]: value }))
  }

  return (
    <div className='page-content'>
      <div
        className='d-flex justify-content-between align-items-center'
        style={{ marginBottom: '2rem' }}
      >
        <h1 className='mb-0 rp-font-gilroyB'>Balance Contractors</h1>

        <BalanceAdjustmentModal refreshData={refreshBalances} />
      </div>

      <Card className='p-3'>
        <BalanceFilters filters={filters} changeFilters={handleChange} />

        <BalancesList
          balances={balances}
          paginator={balancesPaginator}
          loading={gettingBalances}
          refreshData={refreshBalances}
          page={filters.page}
          setPage={(page) => handleChange(page, 'page')}
        />
      </Card>
    </div>
  )
}

function BalanceFilters({ filters, changeFilters }) {
  return (
    <div className='mb-3 w-25'>
      <CustomSelect
        value={adjustmentStatuses.find(
          (status) => status.value === filters.status,
        )}
        options={adjustmentStatuses}
        onChange={(option) => changeFilters(option?.value, 'status')}
      />
    </div>
  )
}
