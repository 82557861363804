import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, Col, Container, Row, Spinner } from 'reactstrap'
import toastr from 'toastr'

import noTransactions from '../../assets/images/no-transactions.svg'
import NoContent from '../../components/NoContent'
import { StyledH3 } from '../../components/Typo'
import WiseButton from '../../components/WiseButton'
import { getUpcomingPaymentList } from '../../services/api'
import { updateToPayList } from '../../store/payment/actions'
import { getCurrencyFormatter } from '../../utils/formatters/currency'
import { getPaymentIds } from '../payInvoices'
import PaymentList from '../Payment/paymentList'

const UpcomingPayment = ({ history, match }) => {
  const user = useSelector((state) => state.Account?.user)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const profile = useSelector((state) => state.userProfile.userProfile)
  const currency = profile?.company?.currency || profile?.currency
  const [totalToPay, setTotalToPay] = useState(0)
  const updateInvoiceList = () => {
    getUpcomingPaymentList(user?.token)
      .then((r) => {
        if (r.data.success) {
          if (match.params?.id) {
            setData(
              r.data.data.map((e) => ({
                ...e,
                selected: e.id === match.params?.id,
              })),
            )
          } else {
            setData(r.data.data.map((e) => ({ ...e, selected: false })))
          }
        } else {
          toastr.error(r.data.message)
        }
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
      })
  }

  const dispatch = useDispatch()

  useEffect(() => {
    updateInvoiceList()
  }, [])

  useEffect(() => {
    const selectedElements = data.filter((e) => e.selected)
    if (selectedElements?.length > 1) {
      setTotalToPay(
        selectedElements?.reduce((prev, current) =>
          prev?.total_trans_amount
            ? prev?.total_trans_amount + current?.total_trans_amount
            : prev + current?.total_trans_amount,
        ),
      )
    } else if (selectedElements?.length === 1) {
      setTotalToPay(selectedElements[0]?.total_trans_amount)
    } else {
      setTotalToPay(0)
    }

    const ids = getPaymentIds(selectedElements)
    dispatch(updateToPayList(ids))
  }, [data])

  const formatter = getCurrencyFormatter(currency?.code)

  return (
    <div className='page-content'>
      {loading ? (
        <Container style={{ minHeight: '30rem' }}>
          <Col style={{ minHeight: '30rem' }}>
            <Row
              style={{ minHeight: '30rem' }}
              className='justify-content-center align-items-center'
            >
              <Spinner type='grow' className='mr-2' color='primary' />
            </Row>
          </Col>
        </Container>
      ) : (
        <Container fluid className={'p-0 m-0'}>
          <Row className='p-0 m-0 mb-3 mb-md-4'>
            <Col xs={12} md={6}>
              <StyledH3
                min={'22px'}
                max={'32px'}
                style={{ fontFamily: 'gilroyB' }}
                className={'text-dark   mb-3 mb-md-4'}
              >
                Upcoming Payments
              </StyledH3>
            </Col>
            <Col xs={12} md={6} className='p-0 m-0 d-flex justify-content-end'>
              {user?.type === 'client' && (
                <WiseButton
                  onClick={() => {
                    history.push('/pay-invoices')
                  }}
                  height={'48px'}
                  minHeight={'48px'}
                  width={'auto'}
                  minWidth={'100%'}
                  disabled={totalToPay === 0}
                  textColor={'white'}
                  bk={totalToPay === 0 ? 'secondary' : 'primary'}
                  bold
                  text={`Pay ${
                    totalToPay === 0 ? '' : formatter.format(totalToPay)
                  }`}
                />
              )}
            </Col>
          </Row>

          {data?.length === 0 ? (
            <Card>
              <CardBody>
                <NoContent
                  headline=''
                  subtitle="You don't have any upcoming payments"
                  image={noTransactions}
                />
              </CardBody>
            </Card>
          ) : (
            <Row className={'p-0 m-0'}>
              <Col className={'p-0 m-0'}>
                <Card className='mb-1  p-0 m-0  '>
                  <CardBody className='p-0 m-0'>
                    <PaymentList
                      data={data}
                      currency={currency}
                      onInvoiceSelected={(i) => {
                        setData(
                          data.map((inv, idx) => ({
                            ...inv,
                            selected: i === idx ? !inv.selected : inv.selected,
                          })),
                        )
                      }}
                      onAdvanceSet={(e) => {
                        const ids = getPaymentIds([e])
                        dispatch(updateToPayList(ids))
                        history.push('/pay-invoices/' + e.advance)
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      )}
    </div>
  )
}

export default UpcomingPayment
