import React, { useState } from 'react'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import cx from 'classnames'

const CustomDropDown = ({
  title,
  options,
  containerClassName = '',
  isDisabled = false,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const toggle = () => setIsDropdownOpen(!isDropdownOpen)

  return (
    <Dropdown
      isOpen={isDropdownOpen}
      disabled={isDisabled}
      toggle={toggle}
      className={cx(
        'dropdown d-inline-block',
        containerClassName,
        isDisabled ? 'disabled' : '',
      )}
    >
      <DropdownToggle tag={'div'}>
        <div
          style={{
            borderWidth: '1px',
            borderColor: '#325AE7',
            borderRadius: '6px',
            width: '100%',
          }}
        >
          {title}
        </div>
      </DropdownToggle>
      <DropdownMenu right>
        {options.map((e, i) => {
          return (
            <DropdownItem key={`drop-${i}`} onClick={e.onClick}>
              {e.label}
            </DropdownItem>
          )
        })}
      </DropdownMenu>
    </Dropdown>
  )
}
export default CustomDropDown
