import cx from 'classnames'
import React from 'react'

import styles from './shimmer.module.css'

export default function Shimmer({
  rounded = true,
  duration = '1s',
  width = 100,
  height = 40,
  className,
}) {
  return (
    <div
      className={cx(
        'position-relative overflow-hidden',
        { rounded },
        className,
      )}
      style={{
        width,
        height,
        backgroundColor: '#f6f7f8',
        '--duration': duration,
      }}
    >
      <div className={styles.shimmer} />
    </div>
  )
}
