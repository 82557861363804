import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap'

import { userTypes } from '../../helpers/enum'
import { useFetch, usePermissions, useResize } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import { switchEntity } from '../../services/api'
import { updateProfileCompany } from '../../store/profile/actions'
import BadgeX from '../Table/BadgeX'
import { StyledH5 } from '../Typo'

const EntitiesMenu = (props) => {
  const { CloseMenu } = props
  const [menu, setMenu] = useState(false)
  const profile = useSelector((state) => state?.userProfile?.userProfile)
  const history = useHistory()
  const isMobile = useResize()
  const user = useSelector((state) => state.Account?.user)
  const { hasAccess } = usePermissions()

  return (
    <Dropdown
      isOpen={menu}
      toggle={() => setMenu(!menu)}
      className='w-100 p-0 m-0'
    >
      <DropdownToggle
        className='btn header-item w-100 d-flex align-items-center rounded-pill entity-bg'
        id='page-header-user-dropdown'
        tag='button'
        style={{
          height: 'auto !important',
          padding: '0.5rem 0.625rem',
        }}
      >
        <div>
          {profile?.company?.logo ? (
            <img
              className='rounded-circle'
              style={{
                height: '2.5rem',
                width: '2.5rem',
              }}
              src={profile?.company?.logo}
            />
          ) : (
            <div
              className='rounded-circle'
              style={{
                height: '2.5rem',
                width: '2.5rem',
              }}
            >
              <span className='avatar-title rounded-circle'>
                {profile?.company?.name?.charAt(0)}
              </span>
            </div>
          )}
        </div>
        <div className='align-items-start d-flex flex-column mx-2 flex-grow-1'>
          {isMobile ? (
            <>
              <span
                style={{ maxWidth: '17ch' }}
                className='text-dark text-truncate font-size-15'
              >
                {user?.first_name + ' ' + user?.last_name}
              </span>
              <span
                style={{ fontWeight: 600, maxWidth: '20ch' }}
                className='text-secondary text-truncate font-size-14'
              >
                {user?.type === userTypes.CONTRACTOR
                  ? 'Contractor'
                  : profile?.company?.dba ?? profile?.company?.name ?? ''}
              </span>
            </>
          ) : (
            <>
              <BadgeX status='secondary'>Entity</BadgeX>
              <span
                style={{ fontWeight: 600, maxWidth: '13ch' }}
                className='text-dark font-size-14 text-truncate'
                translate='no'
              >
                {profile?.company?.dba ?? profile?.company?.name}
              </span>
            </>
          )}
        </div>
        <i className='bx bx-dots-horizontal-rounded font-size-18' />
      </DropdownToggle>
      <DropdownMenu right className='w-100 mt-2'>
        {profile?.entities?.map((e) => (
          <EntityItem
            key={`${e?.entity_id}`}
            e={e}
            closeBurgerMenu={CloseMenu}
          />
        ))}
        {hasAccess(permissions.addEntity) && (
          <div
            onClick={() => {
              CloseMenu()
              history.push('/switch')
            }}
          >
            {isMobile && (
              <Link className='dropdown-item py-2 d-flex align-items-center'>
                <i className='bx bx-transfer font-size-24 align-middle mr-3 text-dark' />
                <Row>
                  <Col>
                    <StyledH5 className='font-size-16 text-dark mb-0'>
                      Switch account
                    </StyledH5>
                    <span className='text-muted font-size-12'>{`To ${
                      user?.type === userTypes.CONTRACTOR
                        ? 'client'
                        : 'contractor'
                    } account`}</span>
                  </Col>
                </Row>
              </Link>
            )}
          </div>
        )}
        {hasAccess(permissions.addEntity) && (
          <>
            <div className='dropdown-divider' />
            <div>
              <a
                onClick={() => {
                  history.push('/entity/add')
                  window.scrollTo(0, 0)
                }}
                className='dropdown-item py-2 d-flex align-items-center'
              >
                <i className='bx bx-plus font-size-24 align-middle mr-3 text-dark' />
                <Row>
                  <Col>
                    <StyledH5 className='font-size-16 text-dark mb-0'>
                      Add Entity
                    </StyledH5>
                  </Col>
                </Row>
              </a>
            </div>
          </>
        )}
      </DropdownMenu>
    </Dropdown>
  )
}

const EntityItem = ({ e, closeBurgerMenu }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const switchE = useFetch({
    action: switchEntity,
    onComplete: (data) => {
      if (closeBurgerMenu) {
        closeBurgerMenu()
      }
      dispatch(updateProfileCompany(data))
      history.push('/activity')
    },
  })
  return (
    <DropdownItem
      onClick={() => switchE.startFetch({ entity_id: e?.entity_id })}
      tag='a'
      className='d-flex align-items-center text-dark'
    >
      {e?.logo ? (
        <img className='rounded-circle avatar-xs mr-3' src={e?.logo} />
      ) : (
        <div className='avatar-xs mr-3 flex-shrink-0'>
          <span
            className={`${
              e?.type === 'client' ? 'avatar-title-green' : 'avatar-title'
            } rounded-circle`}
          >
            {e?.name?.charAt(0)}
          </span>
        </div>
      )}
      <StyledH5
        className='font-size-16 pt-2'
        style={{ whiteSpace: 'break-spaces' }}
        translate='no'
      >
        {e?.name}
      </StyledH5>
    </DropdownItem>
  )
}
export default EntitiesMenu
