import cx from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Card, Col, Container, Row } from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

import Button from '../../components/ui/button'
import Loader from '../../components/ui/loader'
import NoContent from '../../components/ui/no-content'
import { useFetch } from '../../helpers/hooks'
import { getContractorWallet } from '../../services/api'
import { updateWithdrawAmount } from '../../store/withdraw/actions'
import { getCurrencyFormatter } from '../../utils/formatters/currency'
import KYCModal from '../Contract/components/KYCModal'
import { FlagIcon } from '../../components/ui/flag-icon'

function withdrawAmountValidations(amount, max, currency) {
  const errors = []

  const formatter = getCurrencyFormatter(currency)

  if (currency === 'AED' && amount < 32) {
    errors.push(`Minimum withdrawal amount is ${formatter.format(32)}`)
  } else if (amount < 5) {
    errors.push(`Minimum withdrawal amount is ${formatter.format(5)}`)
  } else if (amount > max) {
    errors.push(`Maximum withdrawal amount is ${formatter.format(max)}`)
  }

  return errors
}

const Withdrawal = (props) => {
  const [editing, setEditing] = useState(false)
  const [showKycModal, setShowKycModal] = useState(false)
  const [withdrawBalance, setWithdrawBalance] = useState(null)

  const dispatch = useDispatch()
  const unpaidInsurances = useSelector(
    (state) => state?.userProfile?.userProfile?.unpaid_insurances,
  )
  const userEmail = useSelector((state) => state?.Account?.user?.email)

  function setSelectedBalance(newBalanceSelected) {
    setWithdrawBalance({
      ...newBalanceSelected,
      balanceToWithdraw: newBalanceSelected.balance,
    })
  }
  const { data: balancesData, isLoading: loadingBalances } = useFetch({
    action: getContractorWallet,
    autoFetch: true,
    onComplete: (data) => {
      const firstBalance = data?.balances[0]
      setSelectedBalance(firstBalance)
    },
  })

  useEffect(() => {
    window.analytics.track('Clicked withdraw', { email_id: userEmail })

    // Run effect only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleWithdrawClick = () => {
    const value = withdrawBalance?.balanceToWithdraw
    if (!value) {
      toastr.error('Please enter a valid amount')
      return
    }

    const currency = withdrawBalance.currency
    const amount = Number(String(value).replaceAll(',', ''))
    const max = Number(withdrawBalance.balance)

    const errors = withdrawAmountValidations(amount, max, currency.code)

    if (errors.length > 0) {
      toastr.error(errors.join(', '))
      return
    }

    dispatch(updateWithdrawAmount({ amount, currency }))
    props.history.push('/withdrawal-process')
  }

  const [amountError, setAmountError] = useState('')

  const checkWithdrawAmount = useCallback(
    function checkWithdrawAmount(value) {
      if (!withdrawBalance) {
        return false
      }

      const amount = Number(String(value).replaceAll(',', ''))
      const max = Number(withdrawBalance.balance)

      const errors = withdrawAmountValidations(
        amount,
        max,
        withdrawBalance.currency.code,
      )

      if (errors.length > 0) {
        setAmountError(errors.join(', '))
        return false
      }

      setAmountError('')
      return true
    },
    [withdrawBalance],
  )

  function handleChangeCurrency(newValue) {
    checkWithdrawAmount(newValue)
    setWithdrawBalance((prev) => ({ ...prev, balanceToWithdraw: newValue }))
  }

  function handleCurrencyBlur() {
    const amountValid = checkWithdrawAmount(withdrawBalance.balanceToWithdraw)
    if (!amountValid) {
      return
    }
    setEditing(false)
  }

  function handleKeyDownCurrency(k) {
    if (k.keyCode === 13) {
      handleCurrencyBlur()
    }
  }

  function handleBalanceClick(selectedBalance) {
    const { balance, currency } = selectedBalance
    window.analytics.track('Selected balance', {
      balance_amount: balance,
      balance_currency: currency.code,
    })

    setSelectedBalance(selectedBalance)
  }

  function handleTriggerEdit() {
    setEditing(true)
    setWithdrawBalance((prev) => ({ ...prev, balanceToWithdraw: '0' }))
  }

  const withdrawAmountValid = useMemo(() => {
    return checkWithdrawAmount(withdrawBalance?.balanceToWithdraw)
  }, [checkWithdrawAmount, withdrawBalance?.balanceToWithdraw])

  return (
    <div className='page-content'>
      <h1 className='rp-font-gilroyB font-size-32 mb-3 mb-md-4 text-gray-h'>
        Withdrawal
      </h1>

      <Card>
        <Container fluid className='py-4'>
          {loadingBalances ? (
            <Loader minHeight='133px' />
          ) : balancesData?.balances?.length > 0 ? (
            <Row style={{ gap: '1rem 0' }}>
              <Col md={6}>
                <div className='rp-font-gilroyB mb-1'>Amount</div>
                <div>
                  {!withdrawBalance ? null : editing ? (
                    <EditBalance
                      value={withdrawBalance.balanceToWithdraw}
                      prefix={withdrawBalance.currency.symbol}
                      onChange={handleChangeCurrency}
                      onBlur={handleCurrencyBlur}
                      onKeyDown={handleKeyDownCurrency}
                      error={amountError}
                    />
                  ) : (
                    <SelectedBalance
                      balance={withdrawBalance ?? {}}
                      canEdit={withdrawBalance.partial_withdraw}
                      onEditClick={handleTriggerEdit}
                    />
                  )}

                  <div className='mt-2' />

                  {balancesData?.warnings?.length <= 0 ? null : (
                    <div className='d-flex flex-column gap-6'>
                      {balancesData?.warnings.map((warning, index) => {
                        return (
                          <Alert
                            color='info'
                            className='mb-0 align-items-center d-flex gap-8'
                            key={index}
                          >
                            <i
                              className='bx bx-info-circle font-size-22'
                              style={{ marginLeft: -6 }}
                            />
                            <p
                              className='mb-0'
                              dangerouslySetInnerHTML={{ __html: warning }}
                            />
                          </Alert>
                        )
                      })}
                    </div>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className='rp-font-gilroyB mb-1'>Choose Balance</div>
                <div className='d-flex flex-column gap-8'>
                  {balancesData?.balances
                    ?.filter((e) => e.balance > 0)
                    ?.map((balance) => {
                      const isSelected = balance.id === withdrawBalance?.id

                      return (
                        <BalanceItem
                          key={balance.id}
                          balance={balance}
                          onClick={handleBalanceClick}
                          isSelected={isSelected}
                        />
                      )
                    })}
                </div>
              </Col>
            </Row>
          ) : (
            <NoContent minHeight='133px' className='text-muted'>
              No Balances Found.
            </NoContent>
          )}
        </Container>

        <div className='d-flex border-top justify-content-start py-3 px-4'>
          <Button
            disabled={!!unpaidInsurances || !withdrawAmountValid}
            onClick={handleWithdrawClick}
            type='button'
          >
            Confirm & Withdraw
          </Button>
        </div>
      </Card>

      <KYCModal
        isOpen={showKycModal}
        toggle={() => setShowKycModal(false)}
        proceed={() => {
          props.history.push('/settings#kyc')
        }}
      />
    </div>
  )
}

function BalanceItem({ balance, isSelected, onClick }) {
  const { currency, balance: amount } = balance
  const formatter = getCurrencyFormatter(currency.code)

  return (
    <BalanceButton
      className={cx(
        'd-flex justify-content-between align-items-center bg-transparent p-3 rounded border transition-colors',
        {
          'border-2 border-primary text-primary cursor-default balance-selected':
            isSelected,
          'hover:bg-soft-light': !isSelected,
        },
      )}
      tabIndex={isSelected ? -1 : undefined}
      type='button'
      onClick={() => onClick(balance)}
    >
      <div className='d-flex align-items-center gap-8'>
        <FlagIcon code={currency.code} className='border' />
        <div>
          {currency.code}{' '}
          <span className='font-size-12 text-muted'>({currency.name})</span>
        </div>
      </div>
      <div>{formatter.format(amount)}</div>
    </BalanceButton>
  )
}

function EditBalance({ value, onChange, onBlur, onKeyDown, prefix, error }) {
  return (
    <>
      <CurrencyInput
        value={value}
        onValueChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        placeholder='Amount'
        min={5}
        autoFocus
        prefix={prefix}
        className='font-size-20 form-control px-3 rp-font-gilroyB'
        style={{ height: 50 }}
        // currency input props
        allowDecimals={true}
        decimalsLimit={2}
        decimalSeparator='.'
        groupSeparator=','
      />
      {!error ? null : <div className='text-danger mt-1'>{error}</div>}
    </>
  )
}

function SelectedBalance({ balance, canEdit, onEditClick }) {
  const { currency, balanceToWithdraw } = balance
  const formatter = getCurrencyFormatter(currency.code)

  return (
    <div
      className='d-flex rp-font-gilroyB justify-content-between align-items-center rounded border px-3'
      style={{ height: 50 }}
    >
      <span className='font-size-20 mb-0'>
        {formatter.format(balanceToWithdraw)}
      </span>

      {!canEdit ? null : (
        <button
          className='rp-btn-nostyle text-primary'
          type='button'
          onClick={onEditClick}
        >
          Edit
        </button>
      )}
    </div>
  )
}

const BalanceButton = styled.button`
  :not(.balance-selected) {
    transition-property: box-shadow;

    &:focus-visible {
      box-shadow: 0 0 0 2px #fff, 0 0 0 4px rgba(51, 65, 85, 0.52);
    }
  }
`

export default Withdrawal
