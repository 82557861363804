import React, { useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import { Popover, PopoverBody } from 'reactstrap'
import _uniqueId from 'lodash/uniqueId'
import { AvForm, AvInput } from 'availity-reactstrap-validation'
import { format, formatDistanceToNow } from 'date-fns'
import toastr from 'toastr'

import styles from './user-comments.module.scss'
import { useFetch } from '../../helpers/hooks'
import {
  addComment as addCommentAction,
  archiveComment as archiveCommentAction,
} from '../../services/api'

export default function UserComments({
  comments = [],
  userId,
  onCompleteAction,
  prefillComment,
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [commentText, setCommentText] = useState(prefillComment ?? '')
  const [allComments, setAllComments] = useState(comments)
  const [id] = useState(_uniqueId('comment-'))

  useEffect(() => {
    setCommentText(prefillComment)
  }, [prefillComment])

  useEffect(() => {
    setAllComments(comments)
  }, [comments])

  const { startFetch: addComment, isLoading } = useFetch({
    action: addCommentAction,
    withAdminAccess: true,
    onComplete: (data) => {
      setAllComments(data.comments)
      setCommentText('')

      if (typeof onCompleteAction === 'function') {
        onCompleteAction()
      }
    },
    onError: () => {
      toastr.error('Error adding your comment')
    },
  })

  const { startFetch: archiveComment, isLoading: isArchivingComment } =
    useFetch({
      action: archiveCommentAction,
      withAdminAccess: true,
      onComplete: (data) => {
        setAllComments(data.comments)

        if (typeof onCompleteAction === 'function') {
          onCompleteAction()
        }
      },
      onError: () => {
        toastr.error('Error archiving the comment')
      },
    })

  function toggle() {
    setIsOpen((isOpen) => !isOpen)
  }

  function handleShowComments() {
    toggle()
  }

  function handleSubmit(_, __, values) {
    const body = {
      id: userId,
      model: 'user',
      comment: values.comment,
    }
    addComment(body)
  }

  function handleArchiveComment(id) {
    archiveComment({ comment_id: id })
  }

  const count = useMemo(() => allComments.length, [allComments.length])

  return (
    <>
      <button
        onClick={handleShowComments}
        title='Show comments'
        className={cx(
          styles.commentBtn,
          'px-1 rounded border-0 bg-transparent text-muted',
        )}
        type='button'
        id={id}
      >
        <i className='bx bx-comment-detail' />
        {count <= 0 ? null : <span className='font-size-12'> {count}</span>}
      </button>
      <Popover
        flip
        target={id}
        toggle={toggle}
        isOpen={isOpen}
        placement='bottom'
        className={styles.popperClassName}
      >
        <PopoverBody
          className={cx(
            styles.popperBody,
            'px-0 d-flex flex-column position-relative',
          )}
        >
          {count <= 0 ? null : (
            <div className={cx(styles.commentsWrapper, 'd-flex flex-column')}>
              {allComments.map((comment) => {
                return (
                  <Comment
                    key={comment.id}
                    {...comment}
                    archiveComment={handleArchiveComment}
                    isArchivingComment={isArchivingComment}
                  />
                )
              })}
            </div>
          )}
          <button
            className='d-flex p-0 position-absolute rp-btn-nostyle rounded text-secondary hover:bg-soft-secondary'
            style={{ top: 2, right: 2 }}
            onClick={toggle}
          >
            <i className='bx bx-x font-size-17' />
          </button>
          <AvForm onSubmit={handleSubmit} className='px-2'>
            <AvInput
              placeholder={count <= 0 ? 'Comment ...' : 'Reply ...'}
              name='comment'
              className={cx(styles.commentInput, 'border-0')}
              autoComplete='off'
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
              disabled={isLoading}
            />
          </AvForm>
        </PopoverBody>
      </Popover>
    </>
  )
}

function Comment({
  created_by: createdBy,
  created_at: createdAt,
  comment,
  archiveComment,
  id,
  isArchivingComment,
}) {
  return (
    <div className={styles.commentBody}>
      <div className={cx(styles.commentHeader, 'd-flex align-items-baseline')}>
        <h3
          className={cx('font-size-14 font-weight-bolder mb-0')}
          style={{ whiteSpace: 'nowrap' }}
        >
          {createdBy}
        </h3>
        <p
          className='mb-0 text-muted text-capitalize text-truncate'
          title={format(new Date(createdAt), 'dd/MM/yyyy hh:mm:ss')}
        >
          {formatDistanceToNow(new Date(createdAt), { addSuffix: true })}
        </p>
        <button
          type='button'
          onClick={() => archiveComment(id)}
          className={cx(
            styles.commentBtn,
            styles.commentArchive,
            'px-2 py-1 ml-auto rounded border border-light bg-white font-size-14 text-muted',
          )}
          title='Archive comments'
          disabled={isArchivingComment}
        >
          <i
            className={`bx ${
              isArchivingComment ? 'bx-loader-alt bx-spin' : 'bx-box'
            }`}
          />
        </button>
      </div>
      <p className='mb-0 font-size-14'>{comment}</p>
    </div>
  )
}
