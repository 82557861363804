import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFetch, useResize } from '../../../helpers/hooks'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { Col, FormGroup, Label, Row } from 'reactstrap'
import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import { components } from 'react-select'
import { StyledH5, StyledH6 } from '../../../components/Typo'
import { submitWForm } from '../../../services/api'
import toastr from 'toastr'
import parse from 'html-react-parser'
import moment from 'moment'
import CustomDatePicker from '../../../components/Forms/CustomDatePicker/CustomDatePicker'

const GenerateTaxForm = ({ fields, onFinish }) => {
  const user = useSelector((state) => state.Account?.user)
  const profile = useSelector((state) => state?.userProfile?.userProfile)
  const staticData = useSelector((state) => state.Layout.staticData)
  const isMobile = useResize()
  const ref = useRef(null)
  const [citizenchip, setCitizenchip] = useState(null)
  const [country, setCountry] = useState(null)
  const [mailingCountry, setMailingCountry] = useState(null)
  const [federalTax, setFederalTax] = useState(null)
  const [taxPayId, setTaxPayId] = useState(null)
  const [limitedCompany, setLimitedCompany] = useState(null)
  const [submitting, setSubmitting] = useState(null)
  const [date, setDate] = useState(moment(new Date()).format('MM/DD/YYYY'))
  const [birthDate, setBirthDate] = useState(
    new Date(moment(profile?.birth_date)),
  )
  const submitForm = useFetch({
    action: submitWForm,
    onError: (err) => {
      toastr.error(err)
    },
    onComplete: () => {
      onFinish()
      setSubmitting(false)
    },
  })
  const otherCountries = staticData?.other_countries
    ? [...(staticData?.other_countries ?? [])]
    : []

  const mapCountry = (e) => {
    return { name: e.name, key: e.iso2, flag: `/${e.svg}`, value: e.name }
  }

  const nationalities = [
    ...(staticData?.countries ?? []),
    ...otherCountries,
  ].map(mapCountry)
  function handleValidSubmit(event, values) {
    setSubmitting(true)
    if (values) {
      submitForm.startFetch({
        ...values,
        permanent_country: country,
        mailing_country: mailingCountry,
        country_of_citizenship: citizenchip,
        federal_tax_classification: federalTax?.value,
        taxpayer_identification: taxPayId?.value,
        limited_liability_company: limitedCompany,
        date_of_signature: moment(new Date(date)).format('MM/DD/YYYY'),
        date_of_birth: moment(new Date(birthDate)).format('MM/DD/YYYY'),
      })
    }
  }
  return (
    <AvForm
      ref={ref}
      onValidSubmit={(e, v) => {
        handleValidSubmit(e, v)
      }}
    >
      <div
        style={{
          minHeight: '70vh',
          marginBottom: isMobile ? '5rem' : undefined,
        }}
        className='p-0'
      >
        <Row className={'modal-header p-0 px-4 pb-1'}>
          <StyledH5 style={{ marginLeft: isMobile ? '8%' : '3.5%' }}>
            {fields.title}
          </StyledH5>
        </Row>
        <Row className={'p-0 m-0 pt-3'}>
          {fields?.fields.map((f, i) => {
            return f.type === 'date' && f.code === 'date_of_birth' ? (
              <Col md={6} key={`grp${i}`}>
                <FormGroup>
                  <Label htmlFor='name'>
                    {f?.label}
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <CustomDatePicker
                    value={birthDate}
                    handleOnChange={setBirthDate}
                    maxDate={
                      new Date(moment().subtract(18, 'years').toISOString())
                    }
                    dateFormat='MM/dd/yyyy'
                  />
                </FormGroup>
              </Col>
            ) : f.type === 'countries' ? (
              <Col md={6} key={`grp${i}`}>
                <FormGroup>
                  <Label htmlFor='name'>
                    {f.label}
                    {f?.required && (
                      <span className='text-danger font-size-16 mx-1'>*</span>
                    )}
                  </Label>
                  <CustomSelect
                    name={f.code}
                    isSearchable
                    onChange={(e) => {
                      if (f.code === 'country_of_citizenship') {
                        setCitizenchip(e.name)
                      }
                      if (f.code === 'permanent_country') {
                        setCountry(e.name)
                      }
                      if (f.code === 'mailing_country') {
                        setMailingCountry(e.name)
                      }
                    }}
                    options={
                      f.code === 'country_of_citizenship'
                        ? nationalities
                        : staticData?.countries?.map((e) => ({
                            name: e.name,
                            key: e.iso2,
                            flag: `/${e.svg}`,
                            value: e.name,
                          }))
                    }
                    customComponents={{
                      Option: customOption,
                      Value: customSingleValue,
                      SingleValue: customSingleValue,
                    }}
                  />
                </FormGroup>
              </Col>
            ) : f.options ? (
              f?.code === 'federal_tax_classification' ? (
                <Row className={'p-0 m-0 w-100'}>
                  {' '}
                  <Col md={federalTax?.subData ? 6 : 12} key={`grp${i}`}>
                    <FormGroup>
                      <Label htmlFor='name'>
                        {f.label}
                        {f?.required && (
                          <span className='text-danger font-size-16 mx-1'>
                            *
                          </span>
                        )}
                      </Label>
                      <CustomSelect
                        name={f.code}
                        hasError={!federalTax && submitting && f?.required}
                        onChange={(e) => {
                          setFederalTax(e)
                        }}
                        options={f?.options?.map((e) => ({
                          value: e.value,
                          label: e.label,
                          subData: e?.options,
                        }))}
                        // customComponents={{
                        // Option: customOption,
                        // Value: customSingleValue,
                        // SingleValue: customSingleValue
                        // }}
                      />
                    </FormGroup>
                  </Col>
                  {federalTax?.subData && (
                    <Col md={6} key={`grp${i}`}>
                      <FormGroup>
                        <Label htmlFor='name'>
                          {federalTax.label}
                          {federalTax?.required && (
                            <span className='text-danger font-size-16 mx-1'>
                              *
                            </span>
                          )}
                        </Label>
                        <CustomSelect
                          name={federalTax.value}
                          options={federalTax?.subData}
                          onChange={(e) => setLimitedCompany(e.value)}
                          // customComponents={{
                          // Option: customOption,
                          // Value: customSingleValue,
                          // SingleValue: customSingleValue
                          // }}
                        />
                      </FormGroup>
                    </Col>
                  )}{' '}
                </Row>
              ) : (
                <Col md={6} key={`grp${i}`}>
                  <FormGroup>
                    <Label htmlFor='name'>
                      {f.label}
                      {f?.required && (
                        <span className='text-danger font-size-16 mx-1'>*</span>
                      )}
                    </Label>
                    <CustomSelect
                      name={f.code}
                      onChange={(e) => {
                        setTaxPayId(e)
                      }}
                      options={f?.options}
                      hasError={!taxPayId && submitting && f?.required}
                      // customComponents={{
                      // Option: customOption,
                      // Value: customSingleValue,
                      // SingleValue: customSingleValue
                      // }}
                    />
                  </FormGroup>
                </Col>
              )
            ) : (
              <Col md={6} key={`grp${i}`}>
                <FormGroup>
                  <Label htmlFor='name'>
                    {f.label}
                    {f?.required && (
                      <span className='text-danger font-size-16 mx-1'>*</span>
                    )}
                  </Label>
                  <AvField
                    name={f?.code}
                    type={f.type}
                    className='form-control'
                    id={f.key}
                    placeholder={f.name}
                    key={f?.key}
                    validate={{
                      required: { value: f?.required },
                      maxLength: f?.length
                        ? { value: 9, errorMessage: 'number length must be 9' }
                        : { value: 300 },
                    }}
                    autocomplete='off'
                  ></AvField>
                </FormGroup>
              </Col>
            )
          })}
        </Row>
      </div>
      <Row className='modal-header modal-footer'>
        <div className='px-4'>
          <StyledH6
            style={{
              color: '#777f9e',
              alignSelf: 'flex-start',
              fontSize: '11px',
              lineHeight: '21px',
              fontWeight: '400',
            }}
          >
            {parse(fields.text)}
          </StyledH6>
        </div>
      </Row>
      <Row className='m-0 p-0 pt-2'>
        <Col md={6} key={'grp full name'}>
          <FormGroup>
            <Label htmlFor='name'>
              Name<span className='text-danger font-size-16 mx-1'>*</span>
            </Label>
            <AvField
              name='name_of_signer'
              type='text'
              validate={{
                required: { value: true },
              }}
              defaultValue={`${user.first_name} ${user.last_name}`}
              className='form-control'
              placeholder='Full name'
              autocomplete='off'
            ></AvField>
          </FormGroup>
        </Col>
        <Col md={6} key={'grp date sign'}>
          <FormGroup>
            <Label htmlFor='name'>
              Date<span className='text-danger font-size-16 mx-1'>*</span>
            </Label>
            <CustomDatePicker
              value={date}
              handleOnChange={setDate}
              dateFormat='MM/dd/yyyy'
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className='modal-footer'>
        <Col className='mr-3'>
          <Row className='justify-content-end'>
            <button
              className={`btn btn-primary waves-effect waves-light px-4 w-${
                isMobile ? '50' : '25'
              }`}
              type='submit'
              onClick={handleValidSubmit}
            >
              {false && (
                <i className='bx bx-loader bx-spin font-size-16 align-middle mr-2'></i>
              )}
              Agree & Sign
            </button>
          </Row>
        </Col>
      </Row>
    </AvForm>
  )
}
const { Option } = components

const customOption = (props) => (
  <Option {...props}>
    <div className='input-select'>
      <div className='input-select__single-value d-flex flex-row align-items-center'>
        {props.data.flag && (
          <img
            src={props.data.flag}
            style={{
              width: '1rem',
              height: '1rem',
              borderRadius: '0.5rem',
              marginRight: 10,
              objectFit: 'cover',
            }}
            alt={props.data.code}
          />
        )}
        {props.data.icon}
        <span
          className='font-weight-light text-dark font-weight-bold'
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {props.data.code}
        </span>
        <span
          className={'font-weight-light text-secondary ml-2'}
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {props.data.name}
        </span>
      </div>
    </div>
  </Option>
)

const customSingleValue = (props) => (
  <div className='input-select'>
    <div className='input-select__single-value d-flex flex-row align-items-center'>
      {props.data.flag && (
        <img
          src={props.data.flag}
          style={{
            width: '1rem',
            height: '1rem',
            borderRadius: '0.5rem',
            marginRight: 10,
            objectFit: 'cover',
          }}
          alt={props.data.code}
        />
      )}
      {props.data.icon}
      <span
        className={'font-weight-light text-secondary'}
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {props.data.name}
      </span>
    </div>
  </div>
)
export default GenerateTaxForm
