import cx from 'classnames'
import React from 'react'
import { Badge } from 'reactstrap'

const BadgeX = ({
  status,
  textStatus = status,
  name,
  children,
  className,
  style,
  size = 'lg',
  ...props
}) => {
  return (
    <Badge
      className={cx(
        'py-1 align-items-center',
        {
          [`badge-soft-${status}`]: status,
          [`text-${textStatus}`]: textStatus,
          'font-size-10': size === 'sm',
          'font-size-12': size === 'md',
          'font-size-14': size === 'lg',
        },
        className,
      )}
      style={style}
      {...props}
    >
      {name ?? children}
    </Badge>
  )
}

export default BadgeX
