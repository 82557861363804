import axios from 'axios'
import classnames from 'classnames'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import {
  Card,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'

import { StyledH5 } from '../../../../components/Typo'
import { useFetch } from '../../../../helpers/hooks'
import {
  getHibobEditData,
  saveHibobContractors,
  updateDataToHibob,
} from '../../../../services/api'
import {
  HiBobRequest,
  HiBobUpdateContractors,
} from '../../../../store/hiBob/actions'
import Tab1 from '../HibobIntegration/Tab1'
import Tab4 from '../HibobIntegration/Tab4'
import TabX from '../HibobIntegration/TabX'

const UpdateHibobIntegration = () => {
  const [activeTab, setActiveTab] = useState(0)
  const tab1Ref = useRef()

  const dispatch = useDispatch()
  const HiRequest = useSelector((state) => state.hiBob.request)
  const history = useHistory()

  useEffect(() => {
    if (!HiRequest?.session_id) {
      history.goBack()
    }
  }, [])

  const UpdateData = useFetch({
    action: updateDataToHibob,
    onComplete: (data) => {
      if (data?.user_id) {
        toastr.success('Settings updated successfully.')
      }
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  const callGetHibobContractors = useFetch({
    action: getHibobEditData,
    onComplete: (data) => {
      if (data?.settings) {
        const newContractType = HiRequest?.contract_types?.map((type) =>
          data?.settings?.settings?.contract_types.includes(type.name)
            ? type
            : {
                ...type,
                status: false,
              },
        )
        const newFields = HiRequest?.fields?.map((type) =>
          data?.settings?.settings?.fields.includes(type.name)
            ? {
                ...type,
                status: true,
              }
            : type,
        )
        dispatch(
          HiBobRequest({
            ...HiRequest,
            ...data?.settings?.auth,
            contract_types: newContractType,
            fields: newFields,
          }),
        )
      }
      dispatch(HiBobUpdateContractors(data?.contractors))
      // setAllSetting(data?.settings)
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  const refresh = useCallback(
    (cancel) => {
      if (cancel) cancel()
      if (callGetHibobContractors.data?.contractors_queued > 0) {
        callGetHibobContractors.startFetch(
          null,
          false,
          new axios.CancelToken((c) => (cancel = c)),
        )
      }
    },
    [callGetHibobContractors.data],
  )

  useEffect(() => {
    if (activeTab === 1) {
      let cancel
      const interval = setInterval(() => refresh(cancel), 800)
      return () => {
        clearInterval(interval)
      }
    }
  }, [callGetHibobContractors.data, activeTab])

  const saveContractors = useFetch({
    action: saveHibobContractors,
    onComplete: (data) => {
      toastr.success('Settings updated successfully.')
      callGetHibobContractors.startFetch()
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  useEffect(() => {
    callGetHibobContractors.startFetch()
  }, [])

  // useEffect(() => {
  //   console.log('allSetting', allSetting)
  //
  //
  // }, [allSetting])

  const tabs = [
    {
      label: 'Settings',
      component: (
        <Card className={''}>
          {(saveContractors.isLoading || callGetHibobContractors.isLoading) && (
            <LoadingTab />
          )}
          <Tab1
            mode={'edit'}
            ref={tab1Ref}
            onComplete={(data) => {
              dispatch(HiBobRequest({ ...HiRequest, ...data }))
              const contractTypes = HiRequest?.contract_types
                ?.filter((type) => type.status === true)
                .map((type) => type.name)
              const fields = HiRequest?.fields
                ?.filter((type) => type.status === true)
                .map((type) => type.name)
              UpdateData.startFetch({
                ...HiRequest,
                ...data,
                contract_types: contractTypes,
                fields,
              })
            }}
          />
          <TabX
            mode={'edit'}
            context={'tab3'}
            onComplete={(data) => {
              dispatch(HiBobRequest({ ...HiRequest, fields: data }))
            }}
          />
          <TabX
            mode={'edit'}
            context={'tab2'}
            onComplete={(data) => {
              dispatch(HiBobRequest({ ...HiRequest, contract_types: data }))
            }}
          />
          <Row className='justify-content-end p-0 m-0 m-4 '>
            <button
              onClick={() => {
                tab1Ref.current.submit()
              }}
              className='btn btn-primary'
            >
              {UpdateData.isLoading && <i className='bx bx-loader bx-spin' />}{' '}
              Update
            </button>
          </Row>
        </Card>
      ),
    },
    {
      label: 'Team',
      component: (
        <Card className={'p-3'}>
          {(saveContractors.isLoading || callGetHibobContractors.isLoading) && (
            <LoadingTab />
          )}
          <Tab4
            mode={'edit'}
            createContractor={(ContractorsToSubmit) => {
              saveContractors.startFetch({
                contractors: [ContractorsToSubmit[0]?.contractor_id],
              })
            }}
          />
        </Card>
      ),
    },
  ]
  return (
    <div className='page-content'>
      <>
        <div className={'mb-0 mb-md-2 d-flex'}>
          <StyledH5 min={'22px'} mid={'28px'} max={'32px'}>
            Hibob
          </StyledH5>
        </div>

        <StyledNav tabs className='nav-tabs-custom'>
          {tabs.map((nav, i) => (
            <NavItem key={`xeroIntegrationNav:${i}`}>
              <NavLink
                className={classnames({ active: activeTab === i })}
                onClick={() => {
                  setActiveTab(i)
                }}
              >
                <p className=' font-size-14 mb-0'>{nav.label}</p>
              </NavLink>
            </NavItem>
          ))}
        </StyledNav>
        <TabContent activeTab={activeTab} className='mt-3'>
          {tabs.map((t, i) => (
            <TabPane className={'position-relative'} key={`tab:${i}`} tabId={i}>
              {t.component}
            </TabPane>
          ))}
        </TabContent>
      </>
    </div>
  )
}

UpdateHibobIntegration.propTypes = {}

export const StyledNav = styled(Nav)`
  flex-wrap: nowrap !important;
  overflow-x: auto;
  overflow-y: hidden;

  .nav-item {
    margin: 0 42px 0 0;

    .nav-link {
      white-space: nowrap;
      padding: 16px 0;

      font-weight: 400;
      font-size: 14px;

      &::after {
        height: 3px;
        background-color: var(--primary);
      }
    }

    .active {
      color: var(--primary) !important;
      font-weight: 600;
      background-color: #f4f5f7;
    }
  }

  @media (max-width: 768px) {
    .nav-item {
      margin: 0 24px 0 0 !important;
    }
  }
`

export const LoadingTab = () => {
  return (
    <div
      style={{
        backgroundColor: 'white',
        opacity: 0.8,
        position: 'absolute',
        zIndex: 9,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        paddingTop: 250,
      }}
      className={'d-flex justify-content-center  '}
    >
      <Spinner type='grow' className='mr-2' color='primary' />
    </div>
  )
}

export default UpdateHibobIntegration
