import cx from 'classnames'
import React from 'react'
import { Controller } from 'react-hook-form'
import { Input, Label } from 'reactstrap'

import { getInputErrorMessage } from '../Forms/get-input-error-message'
import InputFeedback from '../ui/input-feedback'
import styles from './radio-list.module.scss'

export default function ControlledRadioList({
  options,
  name,
  control,
  error,
  required,
  ...props
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onBlur, onChange } }) => {
        function handleChange(event) {
          const newValue = event.target.value
          onChange(newValue)
        }

        return (
          <RadioList
            options={options}
            onChange={handleChange}
            value={value}
            onBlur={onBlur}
            error={error}
            name={name}
            {...props}
          />
        )
      }}
    />
  )
}

function RadioList({
  options,
  name,
  onChange,
  onBlur,
  error,
  value: _checkValue,
  otherOption,
  label,
  labelClassName,
  horizontal,
  isFancy,
  ...props
}) {
  return (
    <div>
      {!label ? null : <label className={labelClassName}>{label}</label>}

      <div
        className={cx(
          'd-flex',
          isFancy ? 'gap-16' : horizontal ? 'gap-6' : 'flex-column',
        )}
      >
        {options.map(({ value, label, icon: Icon }, i) => {
          const isChecked = value === _checkValue
          return (
            <RadioItemLabel
              key={`drop-${value ?? i}`}
              isFancy={isFancy}
              isChecked={isChecked}
            >
              <Input
                checked={value === _checkValue}
                type='radio'
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                style={{ marginTop: '0.25rem' }}
                invalid={!!error}
                className={isFancy ? 'sr-only' : ''}
                {...props}
              />
              {!Icon ? null : (
                <span
                  style={{
                    '--size': '1.325rem',
                    width: 'var(--size)',
                    height: 'var(--size)',
                    color: isChecked ? null : 'var(--gray-500)',
                  }}
                  className='align-items-center d-flex justify-content-center'
                >
                  <Icon isActive={isChecked} />
                </span>
              )}

              <span>{label}</span>
            </RadioItemLabel>
          )
        })}

        {!otherOption ? null : (
          <RadioItemLabel
            key='drop-other'
            isFancy={isFancy}
            isChecked={_checkValue === 'other'}
          >
            <Input
              checked={_checkValue === 'other'}
              type='radio'
              name={name}
              value='other'
              onChange={onChange}
              onBlur={onBlur}
              style={{ marginTop: '0.25rem' }}
              invalid={!!error}
              className={isFancy ? 'sr-only' : ''}
              {...props}
            />{' '}
            <span>Other</span>
          </RadioItemLabel>
        )}
      </div>

      {!error ? null : (
        <InputFeedback className='mt-1'>
          {getInputErrorMessage(error)}
        </InputFeedback>
      )}
    </div>
  )
}

function RadioItemLabel({ className, children, isFancy, isChecked }) {
  return (
    <Label
      key='drop-other'
      className={cx(
        'cursor-pointer font-size-14 hover:bg-slate-50 rounded mb-0 d-flex gap-4',
        className,
        isFancy
          ? 'px-3 py-3 justify-content-center align-items-center'
          : 'py-1 pr-2',
        { [styles.active]: isChecked && isFancy },
        styles.rlOptionLabel,
      )}
      style={{
        paddingLeft: isFancy ? null : '1.625rem',
        flex: isFancy ? 1 : null,
      }}
    >
      {children}
    </Label>
  )
}
