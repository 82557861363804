import cx from 'classnames'
import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Col, Collapse, UncontrolledTooltip } from 'reactstrap'
import styled from 'styled-components'

import Avatar from '../../components/Avatar'
import { userTypes } from '../../helpers/enum'
import { Check, ErrorIcon, HourGlass } from '../../helpers/SvgIcons'
import { StyledH3, StyledH4 } from '../Typo'
import Shimmer from '../ui/shimmer'

const StyledCol = styled(Col)`
  background: ${(props) => (props.noStyle ? '#fff' : 'transparent')};
  box-shadow: 0 1px 0 #dfe1e6;
  border-radius: 4px;
`
const StyledTitleCont = styled.div`
  padding: 18px 24px;
  gap: 10px;
  border: 0;
  border-bottom: 1px solid #f4f5f7;
  width: 100%;
  text-align: left;
  background-color: white;
`
const Item = styled.div`
  padding: 16px 24px 8px;
`
const ActionCenter = ({ data, isLoading }) => {
  return (
    <StyledCol className='p-0 m-0 mb-4' noStyle>
      {data?.length > 0 ? (
        <StyledH3
          max={'24px'}
          min={'18px'}
          className='font-weight-bold px-4 py-4 mb-0'
        >
          Things to do
        </StyledH3>
      ) : null}
      {isLoading ? (
        <div className='p-3' style={{ '--s-height': '64px' }}>
          <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
          <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
          <Shimmer width='100%' height='var(--s-height)' className='mb-2' />
        </div>
      ) : (
        data.map((item, index) => <ActionItem item={item} key={index} />)
      )}
    </StyledCol>
  )
}

export const completeContractorEntity = 'contractor_entity_details'

const ActionItem = ({ item }) => {
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(false)
  const isCompleted = !!(item.completed && item.onboarding)
  const inProgress = !!item.in_progress
  const disabled = !!item.disabled

  const handleHeaderClick = () => {
    if (item?.list?.length) {
      setIsOpen(!isOpen)
    } else {
      switch (item?.type) {
        case 'complete_profile':
          history.push('/settings#profile')
          break
        case 'bank_account':
          history.push('/settings#accounts')
          break
        case 'kyc':
          history.push('/settings#kyc')
          break
        case 'first_contract':
          history.push('/contract/create')
          break
        case 'add_users':
          history.push('/settings/users')
          break
        case 'pending_documents':
          history.push('/documents')
          break
        case 'pending_insurance_payments':
          history.push('/insurance')
          break
        case 'company_details': {
          if (!disabled) history.push('/registration-document')
          break
        }
        case completeContractorEntity: {
          history.push('/complete-company', {
            back: '/activity',
            action: completeContractorEntity,
          })
          break
        }
        case 'need_assistance': {
          const url = item?.link
          window.open(url, '_blank', 'noopener')
          break
        }
        case 'pending_payroll_approval': {
          history.push('/review-payments')
          break
        }
      }
    }
  }

  const IconComp = useMemo(
    () =>
      !isCompleted && !inProgress ? ErrorIcon : inProgress ? HourGlass : Check,
    [inProgress, isCompleted],
  )
  const iconId = useMemo(() => `${item?.type}_icon`, [item?.type])

  const needsAssistance = item.type === 'need_assistance'

  return (
    <>
      <StyledTitleCont
        as={isCompleted || inProgress || disabled ? 'div' : 'button'}
        disabled={disabled}
        onClick={
          isCompleted || inProgress || disabled ? null : handleHeaderClick
        }
        className={cx('d-flex justify-content-between align-items-center', {
          'hover:bg-soft-light': !isCompleted && !inProgress && !disabled,
          'bg-light': disabled,
        })}
      >
        <div
          className='d-flex justify-content-between align-items-center'
          style={{ gap: 10, flex: 1 }}
        >
          <div
            className={cx(
              'p-2 rounded-circle d-flex align-items-center justify-content-center',
              {
                'bg-soft-primary': !isCompleted && !inProgress,
                'bg-soft-warning': inProgress,
                'bg-soft-success': isCompleted,
              },
            )}
            id={iconId}
            style={{
              '--size-icon-wrapper': '40px',
              width: 'var(--size-icon-wrapper)',
              height: 'var(--size-icon-wrapper)',
            }}
          >
            <IconComp
              style={{
                '--size': '20px',
                width: 'var(--size)',
                height: 'var(--size)',
              }}
              className={cx({
                'text-primary': !isCompleted && !inProgress,
                'text-warning': inProgress,
                'text-success': isCompleted,
              })}
            />
          </div>

          {!inProgress || isCompleted ? null : (
            <UncontrolledTooltip target={iconId}>
              This item is in progress.
            </UncontrolledTooltip>
          )}

          <StyledH4
            max='16px'
            className={cx('mb-0 rp-font-gilroyB', {
              'text-muted': isCompleted || inProgress,
              'text-dark': !isCompleted && !inProgress,
            })}
            style={{ flex: 1 }}
          >
            {item?.text}
          </StyledH4>
        </div>

        {needsAssistance ? (
          <span className='text-primary'>Book a demo</span>
        ) : !inProgress || isCompleted ? (
          <i
            className={cx('bx text-muted', {
              'bx-chevron-up': item?.list?.length > 0 && isOpen,
              'bx-chevron-down': item?.list?.length > 0 && !isOpen,
              'bx-chevron-right': !item?.list || item?.list?.length <= 0,
            })}
            style={{ fontSize: 25 }}
          />
        ) : null}
      </StyledTitleCont>

      <Collapse isOpen={isOpen}>
        <div style={{ paddingBottom: 8, borderBottom: '1px solid #f4f5f7' }}>
          {React.Children.toArray(
            item?.list?.map((element, index) => (
              <ItemList element={element} type={item?.type} key={index} />
            )),
          )}
        </div>
      </Collapse>
    </>
  )
}

const ItemList = ({ element, type }) => {
  const history = useHistory()
  const user = useSelector((state) => state?.Account?.user)
  const partner =
    user?.type === userTypes.CONTRACTOR ? element?.client : element?.contractor
  const handleReviewClick = () => {
    switch (type) {
      case 'pending_contracts':
        history.push(`/contract/detail?id=${element?.reference}`)
        break
      case 'pending_expenses':
        history.push(`/contract/detail?id=${element?.reference}&tab=expenses`)
        break
      case 'pending_time_off':
        history.push(`/contract/detail?id=${element?.reference}&tab=timeOff`)
        break
      case 'pending_works':
        history.push(`/contract/detail?id=${element?.reference}&tab=works`)
        break
      case 'pending_invoices':
        history.push(`/contract/detail?id=${element?.reference}&tab=works`)
        break
      case 'pending_equipments':
        history.push(`/contract/detail?id=${element?.reference}&tab=equipment`)
        break
      case 'pending_documents':
        history.push('/documents')
        break
    }
  }
  return (
    <Item className='d-flex justify-content-between align-items-center font-size-14'>
      <div
        className='d-flex justify-content-start align-items-center'
        style={{ gap: 10, flex: 1 }}
      >
        <Avatar
          name={`${partner?.first_name} ${partner?.last_name}`}
          photo={partner?.photo}
          flag={partner?.flag}
        />
        <span className={'ml-2 font-weight-bold'}>
          {partner?.first_name} {partner?.last_name}
        </span>
      </div>
      <a
        onClick={handleReviewClick}
        className='text-primary ml-2 font-size-14 d-flex align-items-center'
      >
        Review
      </a>
    </Item>
  )
}

export default ActionCenter
