import React, { useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import Select from 'react-select'
import {
  Container,
  FormGroup,
  Label,
  Spinner,
  TabContent,
  TabPane,
} from 'reactstrap'
import toastr from 'toastr'

import customStyles from '../../../components/Common/react-select-custom-styles'
import ModalHeader from '../../../components/ModalHeader'
import Steps from '../../../components/Steps'
import StepContainer from '../../../components/Steps/StepContainer'
import { useFetch, useResize } from '../../../helpers/hooks'
import {
  cardActivity,
  confirmConvert as confirmConvertAction,
  prepareConvert,
} from '../../../services/api'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import WithdrawReview from '../../withdrawProcess/withdrawReview'
import CustomBalanceControl from '../components/custom-balance-control'
import { CustomBalanceOption } from '../components/custom-balance-option'
import FieldAmount from '../components/field-amount'
import { mapBalancesToOptions } from '../utils/balance-utils'

const steps = [
  { label: 'Balance selection', value: 0 },
  { label: 'Review & Convert', value: 1 },
]

export default function CardConvert() {
  const amountRef = useRef()
  const isMobile = useResize()
  const history = useHistory()

  const [activeStep, setActiveStep] = useState(steps[0].value)
  const [convertData, setConvertData] = useState({})

  const { data: cards, isLoading: balancesLoading } = useFetch({
    action: cardActivity,
    autoFetch: true,
  })

  const balances = useMemo(() => {
    return mapBalancesToOptions(
      cards?.balances.map((b) => ({
        ...b,
        currency: { code: b.curSymbol, id: b.currency_id },
      })) ?? [],
    )
  }, [cards])

  const {
    startFetch: getReviewData,
    data: reviewData,
    isLoading: reviewDataLoading,
  } = useFetch({ action: prepareConvert })

  const { startFetch: confirmConvert, isLoading: confirmingConvert } = useFetch(
    {
      action: confirmConvertAction,
      onComplete: (data) => {
        if (
          Object.prototype.hasOwnProperty.call(data, 'success') &&
          data.success === false
        ) {
          toastr.error('Something went wrong, retry later')
        } else {
          toastr.success('Convertion successful')
          history.push('/cards')
        }
      },
    },
  )

  function handleFirstStepNext() {
    const MIN_CONVERT_AMOUNT = 5
    const amount = convertData.amount
    if (convertData?.balance?.balance === 0) {
      toastr.error('You have no balance to topup')
    } else if (amount < MIN_CONVERT_AMOUNT) {
      const formatter = getCurrencyFormatter(convertData.balance?.curSymbol)
      const balanceFormatted = formatter.format(MIN_CONVERT_AMOUNT)
      toastr.error(`Minimum amount is ${balanceFormatted}`)
      amountRef.current.focus()
    } else {
      setActiveStep((s) => s + 1)
      getReviewData({
        amount,
        from_currency_id: convertData.balance?.currency?.id,
        to_currency_id: convertData.toBalance?.currency?.id,
      })
    }
  }

  function handleDataChange(key, value) {
    const balance = convertData?.balance ?? {}

    if (key === 'amount' && value > balance?.balance) {
      const formatter = getCurrencyFormatter(balance?.currency?.code)
      const balanceFormatted = formatter.format(balance?.balance)
      toastr.error(`Sorry, you can't select more than ${balanceFormatted}`)
    } else {
      setConvertData((prevData) => ({ ...prevData, [key]: value }))

      if (key === 'balance') {
        setConvertData((prevData) => ({
          ...prevData,
          amount: '',
          toBalance: null,
        }))
      }
    }
  }

  function handleConfirmConvertion() {
    const amount = convertData.amount
    confirmConvert({
      amount,
      from_currency_id: convertData.balance?.currency?.id,
      to_currency_id: convertData.toBalance?.currency?.id,
    })
  }

  return (
    <div>
      <Container fluid>
        <ModalHeader action={() => history.push('/cards')}>
          {!isMobile && (
            <Steps
              activeTab={activeStep}
              data={steps.map((step) => step.label)}
              noLastAction
            />
          )}
        </ModalHeader>
      </Container>

      <Container style={{ maxWidth: 552, margin: 'auto' }}>
        <TabContent activeTab={activeStep}>
          <TabPane tabId={steps[0].value}>
            <StepContainer
              index={steps[0].value}
              total={steps.length}
              title={steps[0].label}
              onNext={handleFirstStepNext}
              onBack={() => setActiveStep((step) => step - 1)}
              nextText='Next'
              disableNext={!convertData.balance && !convertData.amount}
              minHeight='320px'
            >
              {balancesLoading ? (
                <div
                  className='d-flex align-items-center justify-content-center flex-grow-1'
                  style={{ height: '100%', minHeight: '310px' }}
                >
                  <Spinner type='grow' color='primary' />
                </div>
              ) : (
                <>
                  <FormGroup>
                    <Label>Source:</Label>
                    <Select
                      options={mapBalancesToOptions(balances, {
                        disabledEmptyBalance: true,
                      })}
                      styles={customStyles}
                      value={convertData.balance}
                      onChange={(v) => handleDataChange('balance', v)}
                      components={{
                        IndicatorSeparator: null,
                        Option: CustomBalanceOption,
                        Control: CustomBalanceControl,
                      }}
                    />
                  </FormGroup>
                  {!convertData.balance ? null : (
                    <>
                      <FieldAmount
                        label='Amount:'
                        name='convert-amount'
                        value={convertData.amount}
                        onValueChange={(v) => handleDataChange('amount', v)}
                        prefix={convertData.balance?.curSymbol}
                        inputRef={amountRef}
                      />

                      <FormGroup>
                        <Label>Destination:</Label>
                        <Select
                          options={mapBalancesToOptions(balances).filter(
                            (o) =>
                              o.currency_id !==
                              convertData.balance?.currency_id,
                          )}
                          styles={customStyles}
                          value={convertData.toBalance}
                          onChange={(v) => handleDataChange('toBalance', v)}
                          components={{
                            IndicatorSeparator: null,
                            Option: CustomBalanceOption,
                            Control: CustomBalanceControl,
                          }}
                        />
                      </FormGroup>
                    </>
                  )}
                </>
              )}
            </StepContainer>
          </TabPane>
          <TabPane tabId={steps[1].value}>
            <StepContainer
              index={steps[1].value}
              total={steps.length}
              title={steps[1].label}
              onNext={handleConfirmConvertion}
              onBack={() => setActiveStep((step) => step - 1)}
              loading={confirmingConvert}
              nextText='Review & Convert'
              isFlat
              minHeight='320px'
            >
              <WithdrawReview
                bankInfo={reviewData}
                loading={reviewDataLoading}
                onBack={() => setActiveStep((step) => step - 1)}
                loadingMinHeight='310px'
              />
            </StepContainer>
          </TabPane>
        </TabContent>
      </Container>
    </div>
  )
}
