import cx from 'classnames'
import MetisMenu from 'metismenujs'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Link, withRouter } from 'react-router-dom'
import { Badge } from 'reactstrap'
import styled from 'styled-components'

import FEATURE_FLAGS from '../../config/feature-flags'
import { userTypes } from '../../helpers/enum'
import { usePermissions, useResizeForSideBar } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import {
  ActivityIcon,
  CardSidebarIcon,
  ContractIcon,
  DocIcon,
  InactiveContractIcon,
  InactiveDocIcon,
  InactiveInsurance,
  InactiveInvoicesIcon,
  Insurance,
  InvoicesIcon,
  PerksSidebarIcon,
  ReferralSidebarIcon,
  TransactionsIcon,
} from '../../helpers/SvgIcons'
import { RequestDocumentModal } from '../../pages/Fulltime/employee-documents/employee-docs-list'
import { hasReportsRequiredRole } from '../../pages/reports'
import {
  SB_INSURANCE,
  SB_PERKS,
  SB_REFERS,
} from '../../utils/cookies/cookie-names'
import { setNewBadge, showNewBadge } from '../../utils/cookies/sidebar'
import EntitiesMenu from '../EntitiesMenu'
import BadgeX from '../Table/BadgeX'
import WiseButton from '../WiseButton'

const links = (
  isEmployee,
  isCompany,
  isContractor,
  isIndividual,
  profile,
  hasAccess,
  t,
) => {
  const showReports =
    FEATURE_FLAGS.REMOTEPASS_REPORTS_PAGE &&
    isCompany &&
    hasReportsRequiredRole(profile?.role_name)

  return [
    {
      show: true,
      label: isEmployee ? 'Overview' : isCompany ? 'Activity' : 'Activity',
      to: '/activity',
      highlighted: ['/payment', '/upcoming-payment'],
      getIcon: (isCurrentPath) => <ActivityIcon isActive={isCurrentPath} />,
    },
    {
      show:
        (hasAccess(permissions.ViewContracts) || isContractor) && !isEmployee,
      label: 'Contracts',
      to: '/contracts',
      highlighted: ['/contract'],
      getIcon: (isCurrentPath) =>
        isCurrentPath ? (
          <ContractIcon style={{ marginRight: 20 }} />
        ) : (
          <InactiveContractIcon />
        ),
    },
    {
      show:
        (hasAccess(permissions.ViewInvoices) || isContractor) && !isEmployee,
      label: 'Invoices',
      to: '/invoices',
      getIcon: (isCurrentPath) =>
        isCurrentPath ? <InvoicesIcon /> : <InactiveInvoicesIcon />,
    },
    {
      show: showReports,
      label: 'Reports',
      to: '/reports',
      getIcon: (isCurrentPath) => <ActivityIcon isActive={isCurrentPath} />,
    },
    {
      show: isEmployee,
      label: t('Expenses'),
      to: '/expenses',
      getIcon: (isCurrentPath) =>
        isCurrentPath ? (
          <ContractIcon style={{ marginRight: 20 }} />
        ) : (
          <InactiveContractIcon />
        ),
    },
    {
      show: isEmployee,
      label: t('Time Off'),
      to: '/timeoff',
      getIcon: (isCurrentPath) =>
        isCurrentPath ? <InvoicesIcon /> : <InactiveInvoicesIcon />,
    },
    {
      show:
        (hasAccess(permissions.ViewCompaniesDocuments) && !isCompany) ||
        isContractor,
      label: isEmployee ? 'Payslips' : 'Documents',
      to: '/documents',
      getIcon: (isCurrentPath) =>
        isCurrentPath ? <DocIcon /> : <InactiveDocIcon />,
    },
    {
      show: isEmployee,
      label: 'My documents',
      to: '/my-documents',
      getIcon: (isCurrentPath) => (
        <i
          className={cx('bx bxs-file font-size-20', {
            'text-primary': isCurrentPath,
          })}
          style={{ marginRight: 8, marginLeft: -8 }}
        />
      ),
    },
    {
      show:
        (hasAccess(permissions.ViewTransactions) || isContractor) &&
        !isEmployee,
      label: 'Transactions',
      to: '/transactions',
      getIcon: (isCurrentPath) => <TransactionsIcon isActive={isCurrentPath} />,
    },
    {
      show: isContractor && !isEmployee,
      label: 'Insurance',
      to: '/insurance',
      getIcon: (isCurrentPath) =>
        isCurrentPath ? <Insurance /> : <InactiveInsurance />,
      newBadgeCookie: SB_INSURANCE,
    },
    {
      show: FEATURE_FLAGS.REMOTEPASS_CARDS && isContractor && isIndividual,
      label: 'Cards',
      to: '/cards',
      getIcon: (isCurrentPath) => <CardSidebarIcon isActive={isCurrentPath} />,
      betaBadge: true,
    },
    {
      show: FEATURE_FLAGS.REMOTEPASS_PERKS && isCompany,
      label: 'Perks',
      to: '/perks',
      getIcon: (isCurrentPath) => <PerksSidebarIcon isActive={isCurrentPath} />,
      newBadgeCookie: SB_PERKS,
    },
    {
      show: FEATURE_FLAGS.REMOTEPASS_REFERRALS,
      label: 'Refer & Earn',
      to: '/referrals',
      getIcon: (isCurrentPath) => (
        <ReferralSidebarIcon isActive={isCurrentPath} />
      ),
      newBadgeCookie: SB_REFERS,
    },
  ]
}

const SidebarContent = (props) => {
  const { closeBurger } = props
  const user = useSelector((state) => state.Account?.user)
  const { type: display } = user
  const profile = useSelector((state) => state?.userProfile?.userProfile)

  const isEmployee = profile?.contractor_type === 'employee'
  const isIndividual = profile?.contractor_type === 'individual'
  const isCompany = display === userTypes.COMPANY
  const isContractor = display === userTypes.CONTRACTOR

  const { hasAccess } = usePermissions()
  const isMobile = useResizeForSideBar()

  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      // eslint-disable-next-line no-new
      new MetisMenu('#side-menu')
      let matchingMenuItem = null
      const ul = document.getElementById('side-menu')
      const items = ul.getElementsByTagName('a')
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  function activateParentDropdown(item) {
    item.classList.add('active')
    const parent = item.parentElement

    if (parent) {
      parent.classList.add('mm-active')
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add('mm-show')

        const parent3 = parent2.parentElement

        if (parent3) {
          parent3.classList.add('mm-active') // li
          parent3.childNodes[0].classList.add('mm-active') // a
          const parent4 = parent3.parentElement
          if (parent4) {
            parent4.classList.add('mm-active')
          }
        }
      }
      return false
    }
    return false
  }

  const pathname = props.history.location.pathname
  const history = useHistory()
  const [showRequestDoc, setShowRequestDoc] = useState(false)

  function toggleRequestModal() {
    setShowRequestDoc(false)
  }

  return (
    <>
      <div
        id='sidebar-menu'
        className='d-flex flex-column m-0 pb-0'
        style={!isMobile ? { height: 'calc(100vh - 70px)' } : null}
      >
        <div className='mb-3 mx-auto d-flex'>
          <WiseButton
            classNameAdd='mx-4'
            style={{ maxWidth: 192, width: '100%' }}
            onClick={() => {
              if (isEmployee) {
                setShowRequestDoc(true)
              } else {
                history.push('/contract/create')
                window.analytics.track('Clicked add new contract', {
                  email_id: user?.email,
                })
              }
            }}
            disabled={!(hasAccess(permissions.CreateContracts) || isEmployee)}
            text={isEmployee ? 'Request Document' : 'New Contract'}
            icon={
              isEmployee ? null : (
                <i
                  style={{ marginRight: '18px' }}
                  className='fas fa-plus font-size-12'
                />
              )
            }
          />
        </div>

        <ul
          className='metismenu app-sidebar-navlist list-unstyled'
          id='side-menu'
        >
          {links(
            isEmployee,
            isCompany,
            isContractor,
            isIndividual,
            profile,
            hasAccess,
            props.t,
          ).map(
            (
              {
                label,
                to,
                getIcon,
                highlighted,
                show,
                newBadgeCookie,
                betaBadge,
              },
              index,
            ) => {
              const isCurrentPath =
                pathname.startsWith(to) ||
                (highlighted &&
                  highlighted.filter((p) => pathname.startsWith(p)).length > 0)
              const icon = getIcon(isCurrentPath)

              if (!show) return null

              const showNewItemBadge =
                newBadgeCookie && showNewBadge(newBadgeCookie, user?.id)

              return (
                <li key={index}>
                  <StyledLink
                    $active={isCurrentPath}
                    onClick={() => {
                      if (closeBurger) closeBurger()

                      if (showNewItemBadge) {
                        setNewBadge(newBadgeCookie, user?.id)
                      }
                    }}
                    to={to}
                    className='waves-effect font-size-16'
                  >
                    {icon}
                    <span>{label}</span>
                    {!showNewItemBadge && !betaBadge ? null : betaBadge ? (
                      <BadgeX className='bg-primary ml-2 text-white' size='sm'>
                        Beta
                      </BadgeX>
                    ) : showNewItemBadge ? (
                      <Badge className='py-1 font-size-4 rounded bg-primary ml-2'>
                        <span className='mb-0 font-size-6 text-white'>New</span>
                      </Badge>
                    ) : null}
                  </StyledLink>
                </li>
              )
            },
          )}
        </ul>

        {!isMobile && isCompany && (
          <div className='p-3 w-100 pl-4 mt-auto'>
            <EntitiesMenu />
          </div>
        )}
      </div>

      <RequestDocumentModal
        isOpen={showRequestDoc}
        toggle={toggleRequestModal}
      />
    </>
  )
}

const StyledLink = styled(Link).attrs((p) => ({
  className: p.className + (p.$active ? ' link-active' : ''),
}))`
  opacity: ${(props) => (props.$active ? 1 : 0.7)};
  font-weight: ${(props) => (props.$active ? 600 : 500)};

  &:hover {
    opacity: ${(props) => (props.$active ? 1 : 0.85)};
  }

  > svg {
    margin-right: 12px !important;

    @media (min-width: 1200px) {
      margin-right: 20px !important;
    }
  }

  @media (max-width: 754px) {
    padding: 12px 16px !important;
    font-size: 14px;
  }
`

export default withRouter(withTranslation()(SidebarContent))
