import { PlusCircle, TrashSimple } from '@phosphor-icons/react'
import cx from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import {
  FormProvider,
  useController,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import {
  Container,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import DropzoneInput from '../../../components/Common/dropzone-input'
import ControlledCheckbox from '../../../components/controlled-checkbox'
import ControlledRadioList from '../../../components/controlled-radio-list'
import ControlledCurrencyInput from '../../../components/ControlledCurrencyInput'
import ControlledDatePicker, {
  formatDate,
} from '../../../components/ControlledDatePicker'
import ControlledInput from '../../../components/ControlledInput'
import ControlledSelect from '../../../components/ControlledSelect'
import { getInputErrorMessage } from '../../../components/Forms/get-input-error-message'
import ModalHeader from '../../../components/ModalHeader'
import Steps from '../../../components/Steps'
import StepContainer from '../../../components/Steps/StepContainer'
import Alert, { PrimaryAlert } from '../../../components/ui/alert'
import Button from '../../../components/ui/button'
import { FlagIcon } from '../../../components/ui/flag-icon'
import InputFeedback from '../../../components/ui/input-feedback'
import Loader from '../../../components/ui/loader'
import {
  CONTRACT_CATEGORY,
  CONTRACT_DOC_TYPE,
  contractTypes,
  DE_PAYMENT_PROVIDER,
  EMPLOYEE_CONTRACT_TYPE,
} from '../../../helpers/enum'
import { useFetch } from '../../../helpers/hooks'
import {
  definiteValue,
  employmentTermOptions,
  employmentTypeOptions,
  qualificationOptions,
} from '../../../helpers/lists'
import {
  createContractV2,
  createDirectEmploymentContract,
  createFulltimeContract,
  deGetCountryJurisdictions,
  deSupportedCountries,
  getCalculatorResult,
  getOccurrences,
  getTemplateList,
  updateProrata,
  uploadContractFile,
} from '../../../services/api'
import { track } from '../../../utils/analytics'
import { getCookie, setCookie } from '../../../utils/cookies'
import { DE_ONBOARDING_BANNER_COOKIE } from '../../../utils/cookies/cookie-names'
import {
  getOptionFromList,
  mapCountryToOption,
  mapCurrencyToOption,
} from '../../../utils/map-to-option'
import { getCurrencyFromTaxCountry } from '../components/Forms/PaymentForm'
import {
  customOption,
  customSingleValue,
} from '../components/Forms/select-components'
import QuoteView from '../components/Quote/QuoteView'
import allowanceStyles from './allowance-block.module.scss'
import { FormCol } from './components/form-col'
import LabelContent from './components/label-content'
import NoticePeriodField from './components/notice-period-field'
import OnboardToDeModal from './components/onboard-to-de-modal'
import ProbationPeriodField from './components/probation-period-field'
import { YearlySalaryField } from './components/yearly-salary-field'
import ScopeOfWorkField from './fields/scope-of-work'
import ControlledTypeSelect, {
  contractCategories,
  contractTypeCards,
  customContractTemplatesType,
  deContractOptions,
  deExternalPaymentOptions,
  defaultContractTemplatesTypes,
  employeeContractOptions,
} from './fields/type-select'
import { formatDataBody } from './utils/format-data-body'
import { mapFrequencyToOption } from './utils/map-frequency-to-option'
import { USD_CODE, USD_ID } from './utils/usd-id'
import {
  dataKeys,
  FormDataProvider,
  useFormData,
} from './utils/use-form-data-context'
import useTriggerField from './utils/use-trigger-field'
import validateSchema from './utils/validate-schema'

function constructWhen(attr, getCondition) {
  return (getSchema, getNotContractorSchema) => {
    return [
      attr,
      {
        is: (data) => getCondition(data),
        then: getSchema,
        otherwise: getNotContractorSchema,
      },
    ]
  }
}

const isContractor = (contractType) =>
  contractType === CONTRACT_CATEGORY.CONTRACTOR
const whenContractor = constructWhen('contract_type', isContractor)

const isNotMilestone = ([contractType, type]) =>
  contractType === CONTRACT_CATEGORY.CONTRACTOR &&
  type !== contractTypes.MILESTONES
const whenNotMilestone = constructWhen(
  ['contract_type', 'type'],
  isNotMilestone,
)

const isMilestone = (type) => type === contractTypes.MILESTONES
const whenMilestone = constructWhen('type', isMilestone)

const whenPayG = constructWhen('type', (type) => {
  return type === contractTypes.PAYG
})
const whenEmployee = constructWhen('contract_type', (contractType) => {
  return contractType === CONTRACT_CATEGORY.EMPLOYEE
})
const whenEor = constructWhen('employee_type', (employeeType) => {
  return employeeType === EMPLOYEE_CONTRACT_TYPE.EOR
})
const isDe = (employeeType) => {
  return employeeType === EMPLOYEE_CONTRACT_TYPE.DIRECT_EMPLOYEE
}
const whenDe = constructWhen('employee_type', isDe)

function isDefinite(term) {
  return term === definiteValue
}
const whenDeEndDate = constructWhen('employment_term', isDefinite)

const fieldKeys = {
  type: 'type',
  startDate: 'start_date',
  endDate: 'end_date',
  currencyId: 'currency_id',
  frequencyId: 'frequency_id',
  occurrenceId: 'occurrence_id',
  firstPaymentDate: 'first_payment_date',
  proRata: 'first_payment_prorata',
  proRataAmount: 'prorata_amount',
  amount: 'amount',
  milestones: 'milestones',
}
const allowancesKey = 'allowances'

const steps = {
  contractType: {
    id: 0,
    label: 'Contract type',
    stepTitle: 'I would like to onboard',
    schema: yup.object().shape({
      contract_type: yup.string().required('Please select a contract type'),
    }),
  },
  info: {
    id: 1,
    label: 'Info',
    stepTitle: 'Contract Details',
    schema: yup.object().shape({
      type: yup.string().when(
        ...whenContractor(
          (schema) =>
            schema
              .typeError('Please select a type')
              .required('Please select a type'),
          (schema) => schema.nullable(),
        ),
      ),

      employee_type: yup.string().when(
        ...whenEmployee(
          (schema) =>
            schema
              .typeError('Please select a type')
              .required('Please select a type'),
          (schema) => schema.nullable(),
        ),
      ),

      // Contractor
      tax_residence_id: yup
        .string()
        .when(
          ...whenContractor((schema) =>
            schema.required('Contractor Tax Country is required'),
          ),
        ),

      scope: yup
        .string()
        .when(
          ...whenContractor((schema) =>
            schema.required('Scope of work is required'),
          ),
        )
        .when(
          ...whenDe((schema) => schema.required('Scope of work is required')),
        ),
      name: yup
        .string()
        .when(
          ...whenContractor((schema) => schema.required('Role is required')),
        )
        .when(...whenDe((schema) => schema.required('Title is required'))),

      // Employee | DE & EOR
      country_id: yup
        .string()
        .when(
          ...whenEmployee((schema) => schema.required('Country is required')),
        ),

      // Employee | EOR
      amount: yup
        .number()
        .typeError('Please enter a valid number for the amount')
        .when(
          ...whenEor((schema) =>
            schema.required('Please enter a valid amount'),
          ),
        ),

      currency_id: yup
        .string()
        .when(
          ...whenEor((schema) => schema.required('The currency is required')),
        ),

      // Employee | DE
      jurisdiction_id: yup
        .string()
        .when(
          ...whenDe((schema) => schema.required('Jurisdiction is required')),
        ),
      employment_term: yup
        .string()
        .when(
          ...whenDe((schema) => schema.required('Employment term is required')),
        ),
      employment_type: yup
        .string()
        .when(
          ...whenDe((schema) => schema.required('Employment type is required')),
        ),
      seniority: yup
        .string()
        .when(...whenDe((schema) => schema.required('Seniority is required'))),
      nationality_id: yup
        .string()
        .when(
          ...whenDe((schema) => schema.required('Nationality is required')),
        ),
    }),
  },
  simulation: {
    id: 2,
    label: 'Simulation',
    stepTitle: 'Simulation',
  },
  details: {
    id: 3,
    label: 'Details',
    stepTitle: 'Contract Details',
    schema: yup.object().shape({
      employee_first_name: yup.string().required('First name is required'),
      employee_last_name: yup.string().required('Last name is required'),
      employee_email: yup
        .string()
        .email('Please enter a valid email')
        .required('Email is required'),
      employee_country_id: yup.string().required('Nationality is required'),
      working_from_country_id: yup
        .string()
        .required('Country of employment is required'),
      qualification: yup.string().required('Qualification is required'),
      employment_type: yup.string().required('Employment type is required'),
      employment_term: yup.string().required('Employment term is required'),
      job_title: yup.string().required('Job title is required'),
      job_description: yup.string().required('Job description is required'),
      start_date: yup
        .date()
        .typeError('Start date is required')
        .required('Start date is required'),
    }),
  },
  payment: {
    id: 4,
    label: 'Payment',
    stepTitle: 'Payment',
    schema: yup.object().shape({
      start_date: yup
        .date()
        .when(
          ...whenContractor((schema) =>
            schema
              .typeError('Start date is required')
              .required('Start date is required'),
          ),
        )
        .when(
          ...whenDe((schema) =>
            schema
              .typeError('Start date is required')
              .required('Start date is required'),
          ),
        ),
      end_date: yup
        .date()
        .when(
          ...whenDeEndDate((schema) =>
            schema
              .typeError('End date is required')
              .required('End date is required'),
          ),
        ),
      amount: yup
        .number()
        .when(
          ...whenNotMilestone((schema) =>
            schema.required('Amount is required'),
          ),
        )
        .when(...whenDe((schema) => schema.required('Amount is required'))),

      milestones: yup.array().when(
        ...whenMilestone((schema) =>
          schema.of(
            yup.object().shape({
              name: yup.string().typeError('Milestone name is required'),
              amount: yup.number().typeError('Milestone amount is required'),
            }),
          ),
        ),
      ),

      currency_id: yup
        .string()
        .when(
          ...whenContractor((schema) =>
            schema.required('Currency is required'),
          ),
        )
        .when(...whenDe((schema) => schema.required('Currency is required'))),

      rate_id: yup
        .string()
        .when(
          ...whenPayG((schema) =>
            schema.required('Unit (of work) is required'),
          ),
        ),

      frequency_id: yup
        .string()
        .when(
          ...whenNotMilestone((schema) =>
            schema.required('Frequency is required'),
          ),
        ),

      occurrence_id: yup.string().when(['contract_type', 'frequency_id'], {
        is: (contractType, frequencyId) => {
          return contractType === CONTRACT_CATEGORY.CONTRACTOR && !!frequencyId
        },
        then: (schema) =>
          schema
            .typeError('Occurrence is required')
            .required('Occurrence is required'),
        otherwise: (schema) => schema.nullable(),
      }),

      first_payment_date: yup
        .date()
        .when(['contract_type', 'occurrence_id', 'employee_type'], {
          is: (contractType, occurrenceId, employeeType) => {
            return (
              isDe(employeeType) ||
              (contractType === CONTRACT_CATEGORY.CONTRACTOR && !!occurrenceId)
            )
          },
          then: (schema) =>
            schema
              .typeError('Date for the first payment is required')
              .required('Date for the first payment is required'),
          otherwise: (schema) => schema.nullable(),
        }),

      [fieldKeys.proRata]: yup
        .boolean()
        .when(
          ['contract_type', 'type', 'employee_type', 'first_payment_date'],
          {
            is: (contractType, type, employeeType, firstPaymentDate) => {
              if (isDe(employeeType)) {
                return !!firstPaymentDate
              }
              return (
                contractType === CONTRACT_CATEGORY.CONTRACTOR &&
                [contractTypes.FIXED, contractTypes.FULL_TIME].includes(type)
              )
            },
            then: (schema) =>
              schema
                .typeError('Pro rata is required')
                .required('Pro rata is required'),
            otherwise: (schema) => schema.nullable(),
          },
        ),

      [fieldKeys.proRataAmount]: yup
        .number()
        .when(
          [
            'contract_type',
            'type',
            'first_payment_date',
            fieldKeys.proRata,
            'employee_type',
          ],
          {
            is: (
              contractType,
              type,
              firstPaymentDate,
              proRata,
              employeeType,
            ) => {
              if (isDe(employeeType)) {
                return !!firstPaymentDate && proRata === true
              }
              return (
                contractType === CONTRACT_CATEGORY.CONTRACTOR &&
                [contractTypes.FIXED, contractTypes.FULL_TIME].includes(type) &&
                !!firstPaymentDate &&
                proRata === true
              )
            },
            then: (schema) =>
              schema
                .typeError('Amount is required')
                .required('Amount is required'),
            otherwise: (schema) => schema.nullable(),
          },
        ),

      is_external_payroll_provider: yup
        .string()
        .when(
          ...whenDe((schema) =>
            schema
              .oneOf(Object.values(DE_PAYMENT_PROVIDER))
              .required('Please select a payroll provider'),
          ),
        ),

      [allowancesKey]: yup.array().when(
        ...whenDe((schema) =>
          schema
            .min(0)
            .of(
              yup.object().shape({
                name: yup.string().required('Allowance name is required'),
                amount: yup.number().required('Allowance amount is required'),
              }),
            )
            .required('Allowances are required'),
        ),
      ),
    }),
  },
  compliance: {
    id: 5,
    label: 'Compliance',
    stepTitle: 'Compliance',
    schema: yup.object().shape({
      contract_doc_type: yup
        .string()
        .when(
          ...whenContractor((schema) =>
            schema.required('Contract document type is required'),
          ),
        ),
      file: yup.mixed().when(['contract_doc_type', 'de_contract_doc_type'], {
        is: (contractDocType, deContractDocType) => {
          return (
            contractDocType === CONTRACT_DOC_TYPE.CUSTOM ||
            deContractDocType === CONTRACT_DOC_TYPE.CUSTOM
          )
        },
        then: (schema) => schema.required('File is required'),
        otherwise: (schema) => schema.nullable(),
      }),

      de_contract_doc_type: yup
        .string()
        .when(
          ...whenDe((schema) =>
            schema
              .oneOf(Object.values(CONTRACT_DOC_TYPE))
              .required('Contract document type is required'),
          ),
        ),

      paid_vacation_days: yup
        .number()
        .when(
          ...whenDe((schema) =>
            schema.required('Paid vacation days is required'),
          ),
        ),
      paid_vacation_accrual_date: yup
        .date()
        .when(
          ...whenDe((schema) =>
            schema.required('Paid vacation accrual date is required'),
          ),
        ),
      probation_period: yup
        .number()
        .typeError('Probation period is required')
        .when(
          ...whenDe((schema) =>
            schema.required('Probation period is required'),
          ),
        ),
      notice_period: yup
        .number()
        .typeError('Notice period is required')
        .when(
          ...whenDe((schema) => schema.required('Notice period is required')),
        ),
    }),
  },
}

function getSteps(contractType, employeeType) {
  if (
    contractType === CONTRACT_CATEGORY.EMPLOYEE &&
    employeeType === EMPLOYEE_CONTRACT_TYPE.EOR
  ) {
    return [steps.contractType, steps.info, steps.simulation, steps.details]
  } else {
    return [steps.contractType, steps.info, steps.payment, steps.compliance]
  }
}

function getIndex(steps, id) {
  return steps.findIndex((s) => s.id === id)
}

function getNext(steps, current) {
  const index = getIndex(steps, current)
  return steps[index + 1]?.id
}

function getPrevious(steps, current) {
  const index = getIndex(steps, current)
  return steps[index - 1]?.id
}

const contractCreationFormId = 'contract-creation-form'

export default function CreateContractV2() {
  const [activeTab, setActiveTab] = useState(steps.contractType.id)

  const user = useSelector((state) => state.Account?.user)

  const { contractor_types: contractorTypes } = useSelector(
    (state) => state.Layout?.staticData ?? {},
  )

  const history = useHistory()

  const formMethods = useForm({
    defaultValues: {
      start_date: formatDate(new Date()),
      notice_period: 15,
      contractor_label_id: 1,
      first_payment_prorata: false,
      milestones: [],

      // EOR default values
      insurance: false,
      work_visa: false,
      trial_period: 'No probation',

      // DE default values
      is_bonus_clause_enabled: false,
      is_annual_plane_ticket_enabled: false,
    },
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    getValues,
    clearErrors,
    setFocus,
    watch,
    trigger,
  } = formMethods

  const { contract_type: contractType, employee_type: employeeType } = useWatch(
    { control },
  )

  function handleReturn() {
    if (
      history.location.state?.duplicating &&
      history.location.state?.backRoute
    ) {
      history.push(history.location.state.backRoute)
    }

    history.push('/activity')
  }

  const stepsData = useMemo(() => {
    return getSteps(contractType, employeeType)
  }, [contractType, employeeType])

  function validateStep(schema, goNext) {
    if (!schema) {
      goNext?.()
    } else {
      validateSchema(schema, getValues(), {
        setFocus,
        setError,
        clearErrors,
        onValidSchema: goNext,
      })
    }
  }

  useTriggerField({ watch, trigger })

  function handleNext(id) {
    window.scrollTo(0, 0)

    function goNext() {
      setActiveTab(getNext(stepsData, id))
    }

    const currentStep = stepsData.find((s) => s.id === id)

    validateStep(currentStep.schema, goNext)
  }

  function handleBack(id) {
    window.scrollTo(0, 0)
    clearErrors()
    setActiveTab(getPrevious(stepsData, id))
  }

  function handleContractCreationError(data) {
    const additionalErrors =
      typeof data === 'object'
        ? Object.values(data?.data ?? {})
            .map((errorArray) => errorArray.join(', '))
            .join('<br />')
        : ''

    toastr.error(
      additionalErrors,
      typeof data === 'string'
        ? data
        : data?.message || data?.error || 'Error creating contract',
      { allowHtml: true },
    )
  }

  function handleContractCreated(data, body) {
    if (data?.success === false) {
      handleContractCreationError(data)
    } else {
      track('Contract_created', {
        contract_id: data?.ref,
        contract_name: data?.name,
        type: user?.type,
        contractor_label: body?.contractor_name,
      })

      toastr.success('Contract created')

      history.push(
        data?.ref ? `/contract/detail?id=${data?.ref}` : '/contracts',
      )
    }
  }

  const { startFetch: createContract, isLoading: creatingContract } = useFetch({
    action: createContractV2,
    onComplete: (data, body) => handleContractCreated(data, body),
    onError: handleContractCreationError,
  })

  const { startFetch: createEorContract, isLoading: creatingEorContract } =
    useFetch({
      action: createFulltimeContract,
      onComplete: (data, body) => handleContractCreated(data, body),
      onError: handleContractCreationError,
    })

  const {
    startFetch: createDirectEmployeeContract,
    isLoading: creatingDirectEmployeeContract,
  } = useFetch({
    action: createDirectEmploymentContract,
    onComplete: (data, body) => handleContractCreated(data, body),
    onError: handleContractCreationError,
  })

  const loading =
    creatingDirectEmployeeContract || creatingContract || creatingEorContract

  function handleCreateContract(data) {
    const formattedBody = formatDataBody(data, { contractorTypes })

    const currentStep = stepsData.find((s) => s.id === activeTab)

    validateStep(currentStep?.schema, () => {
      if (data.contract_type === CONTRACT_CATEGORY.EMPLOYEE) {
        if (data.employee_type === EMPLOYEE_CONTRACT_TYPE.DIRECT_EMPLOYEE) {
          createDirectEmployeeContract(formattedBody)
        } else if (data.employee_type === EMPLOYEE_CONTRACT_TYPE.EOR) {
          createEorContract(formattedBody)
        }
      } else {
        if (formattedBody?.type) {
          createContract(formattedBody)
        }
      }
    })
  }

  function handleError(errors) {
    // console.log(errors)
    toastr.error('There was a validation error!')
  }

  return (
    <Container fluid>
      <ModalHeader action={handleReturn}>
        <Steps
          activeTab={getIndex(stepsData, activeTab)}
          data={stepsData.map((s) => s.label)}
          className='d-none d-md-flex'
        />
      </ModalHeader>
      <FormProvider {...formMethods}>
        <FormDataProvider>
          <form
            onSubmit={handleSubmit(handleCreateContract, handleError)}
            id={contractCreationFormId}
          >
            <TabContent activeTab={activeTab} className='tab-content-sm-margin'>
              <TabPane tabId={steps.contractType.id}>
                <StepContainer
                  title={steps.contractType.stepTitle}
                  index={getIndex(stepsData, steps.contractType.id)}
                  total={stepsData.length}
                  onNext={() => handleNext(steps.contractType.id)}
                  onBack={() => handleBack(steps.contractType.id)}
                >
                  <ControlledTypeSelect
                    control={control}
                    name='contract_type'
                    error={errors?.contract_type}
                    types={contractCategories}
                    required
                    cols={2}
                    horizontal
                    transform={{
                      output: (newValue) => {
                        setValue('employee_type', null)
                        setValue('type', null)
                        return newValue?.value
                      },
                    }}
                  />
                </StepContainer>
              </TabPane>

              <TabPane tabId={steps.info.id}>
                <StepContainer
                  title={steps.info.stepTitle}
                  index={getIndex(stepsData, steps.info.id)}
                  total={stepsData.length}
                  onNext={() => handleNext(steps.info.id)}
                  onBack={() => handleBack(steps.info.id)}
                  minHeight='540px'
                >
                  <ContractTypeDetailsStep control={control} />
                </StepContainer>
              </TabPane>

              <TabPane tabId={steps.payment.id}>
                <StepContainer
                  title={steps.payment.stepTitle}
                  index={getIndex(stepsData, steps.payment.id)}
                  total={stepsData.length}
                  onNext={() => handleNext(steps.payment.id)}
                  onBack={() => handleBack(steps.payment.id)}
                  minHeight='445px'
                >
                  <PaymentStep control={control} />
                </StepContainer>
              </TabPane>

              <TabPane tabId={steps.simulation.id}>
                <StepContainer
                  title={steps.simulation.stepTitle}
                  index={getIndex(stepsData, steps.simulation.id)}
                  total={stepsData.length}
                  onBack={() => handleBack(steps.simulation.id)}
                  onNext={() => handleNext(steps.simulation.id)}
                  loading={loading}
                  minHeight='742px'
                >
                  <SimulationView />
                </StepContainer>
              </TabPane>

              <TabPane tabId={steps.details.id}>
                <StepContainer
                  title={steps.details.stepTitle}
                  index={getIndex(stepsData, steps.details.id)}
                  total={stepsData.length}
                  onBack={() => handleBack(steps.details.id)}
                  loading={loading}
                  nextText='Create'
                  nextFormId={contractCreationFormId}
                  nextType='submit'
                >
                  <EORContractDetails control={control} />
                </StepContainer>
              </TabPane>

              <TabPane tabId={steps.compliance.id}>
                <StepContainer
                  title={steps.compliance.stepTitle}
                  index={getIndex(stepsData, steps.compliance.id)}
                  total={stepsData.length}
                  onBack={() => handleBack(steps.compliance.id)}
                  loading={loading}
                  nextText='Create'
                  nextFormId={contractCreationFormId}
                  nextType='submit'
                  minHeight='534px'
                >
                  <ComplianceStep control={control} />
                </StepContainer>
              </TabPane>
            </TabContent>
          </form>
        </FormDataProvider>
      </FormProvider>
    </Container>
  )
}

function ContractTypeDetailsStep({ control }) {
  const [showDeOnboardingInfo, setShowDeOnboardingInfo] = useState(false)

  const {
    countries,
    currencies,
    job_titles: titles,
  } = useSelector((state) => state.Layout.staticData ?? {})

  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company,
  )

  const isEorEnabled = company?.eor === 1
  const isDeEnabled = company?.is_direct_employee_enabled

  const {
    name: watchingJobTitle,
    contract_type: contractType,
    employee_type: employeeType,
  } = useWatch({ control })

  const {
    setValue,
    formState: { errors },
  } = useFormContext()

  if (contractType === CONTRACT_CATEGORY.EMPLOYEE) {
    return (
      <>
        <ControlledTypeSelect
          control={control}
          name='employee_type'
          error={errors?.employee_type}
          types={employeeContractOptions({ isDeEnabled, isEorEnabled })}
          transform={{
            output: (newValue) => {
              const deOnboardInfoAlreadySeen = getCookie(
                DE_ONBOARDING_BANNER_COOKIE,
              )

              if (
                newValue?.value === EMPLOYEE_CONTRACT_TYPE.DIRECT_EMPLOYEE &&
                !deOnboardInfoAlreadySeen
              ) {
                setShowDeOnboardingInfo(true)
              }

              return newValue?.value
            },
          }}
          required
          cols={2}
          horizontal
        />

        <div className='mt-3'>
          {employeeType === EMPLOYEE_CONTRACT_TYPE.EOR ? (
            <EORForm control={control} />
          ) : employeeType === EMPLOYEE_CONTRACT_TYPE.DIRECT_EMPLOYEE ? (
            <>
              <DEForm control={control} />

              <OnboardToDeModal
                isOpen={showDeOnboardingInfo}
                toggle={() => {
                  setShowDeOnboardingInfo(false)
                  setCookie(DE_ONBOARDING_BANNER_COOKIE, true)
                }}
              />
            </>
          ) : null}
        </div>
      </>
    )
  } else if (contractType === CONTRACT_CATEGORY.CONTRACTOR) {
    return (
      <>
        <ControlledTypeSelect
          control={control}
          name='type'
          error={errors?.type}
          types={contractTypeCards}
          required
        />

        <div className='mt-3'>
          <ControlledSelect
            wrapperClassName='mb-3'
            control={control}
            label={
              <LabelContent required>Contractor Tax Country:</LabelContent>
            }
            name='tax_residence_id'
            error={errors?.tax_residence_id}
            options={countries?.map((c) => mapCountryToOption(c))}
            transform={{
              output: (value) => {
                const newCurrency = getCurrencyFromTaxCountry(
                  currencies,
                  value?.currency,
                )

                setValue('currency_id', newCurrency?.id ?? USD_ID)

                return value
              },
            }}
          />

          <div className='mb-3'>
            <ControlledInput
              control={control}
              label={<LabelContent required>Role:</LabelContent>}
              name='name'
              error={errors?.name}
              placeholder='i.e. Website Design, Content generation'
              autoComplete={titles}
              watchState={watchingJobTitle}
            />
          </div>

          <div>
            <ScopeOfWorkField
              label={<LabelContent required>Scope Of Work:</LabelContent>}
              placeholder='Describe the deliverables'
              name='scope'
              error={errors?.scope}
              control={control}
            />
          </div>
        </div>
      </>
    )
  }

  return null
}

function EORForm({ control }) {
  const { countries, currencies } = useSelector(
    (state) => state.Layout.staticData ?? {},
  )
  const currencyOptions = currencies?.map((c) => mapCurrencyToOption(c, 'id'))

  const {
    setValue,
    formState: { errors },
  } = useFormContext()

  return (
    <Row className='mx-n2'>
      <FormCol>
        <ControlledSelect
          label={<LabelContent required>Country:</LabelContent>}
          control={control}
          name='country_id'
          error={errors.country_id}
          options={(countries ?? []).map((c) => mapCountryToOption(c))}
          transform={{
            output: (value) => {
              const newCurrency = getCurrencyFromTaxCountry(
                currencies,
                value?.currency,
              )

              setValue('working_from_country_id', value?.value)
              setValue('currency_id', newCurrency?.id ?? USD_ID)

              return value
            },
          }}
        />
      </FormCol>

      <FormCol>
        <YearlySalaryField
          control={control}
          currencyOptions={currencyOptions}
          errors={errors}
          label={<LabelContent required>Yearly Salary:</LabelContent>}
        />
      </FormCol>
    </Row>
  )
}

function EORContractDetails({ control }) {
  const {
    countries,
    currencies,
    other_countries: otherCountries,
    job_titles: titles,
  } = useSelector((state) => state.Layout.staticData ?? {})

  const { job_title: watchingJobTitle, currency_id: currencyId } = useWatch({
    control,
  })

  const {
    formState: { errors },
  } = useFormContext()

  const countryOptions = countries?.map((c) => mapCountryToOption(c, 'id'))
  const nationalities = [...(countries ?? []), ...(otherCountries ?? [])].map(
    (c) => mapCountryToOption(c, 'id'),
  )

  const currencyOptions = currencies?.map((c) => mapCurrencyToOption(c, 'id'))

  const currencyCode =
    getOptionFromList(currencies, currencyId, 'id')?.code ?? USD_CODE

  return (
    <>
      <Row className='px-2'>
        <FormCol md={12}>
          <h5 className='rp-font-gilroyB text-gray-h'>Employee Info</h5>
        </FormCol>

        <FormCol>
          <ControlledInput
            control={control}
            label={<LabelContent required>First Name:</LabelContent>}
            name='employee_first_name'
            error={errors?.employee_first_name}
            placeholder='First Name'
          />
        </FormCol>

        <FormCol>
          <ControlledInput
            control={control}
            label={<LabelContent required>Last Name:</LabelContent>}
            name='employee_last_name'
            error={errors?.employee_last_name}
            placeholder='Last Name'
          />
        </FormCol>

        <FormCol>
          <ControlledInput
            control={control}
            label={<LabelContent>Middle Name:</LabelContent>}
            name='employee_middle_name'
            error={errors?.employee_middle_name}
            placeholder='Middle Name'
          />
        </FormCol>

        <FormCol>
          <ControlledInput
            control={control}
            label={<LabelContent required>Personal Email:</LabelContent>}
            name='employee_email'
            error={errors?.employee_email}
            placeholder='Personal Email'
            type='email'
          />
        </FormCol>

        <FormCol>
          <ControlledSelect
            control={control}
            name='employee_country_id'
            error={errors?.employee_country_id}
            label={<LabelContent required>Nationality:</LabelContent>}
            options={nationalities}
          />
        </FormCol>

        <FormCol>
          <ControlledSelect
            control={control}
            name='working_from_country_id'
            error={errors?.working_from_country_id}
            label={<LabelContent required>Country Of Employment:</LabelContent>}
            options={countryOptions}
          />
        </FormCol>

        <FormCol>
          <ControlledCheckbox
            control={control}
            name='work_visa'
            error={errors?.work_visa}
            id='work_visa'
            label='Will the hire require a work visa?'
          />
        </FormCol>
      </Row>

      <hr className='mx-n4' />

      <Row className='px-2'>
        <FormCol md={12}>
          <h5 className='rp-font-gilroyB text-gray-h'>Job Info</h5>
        </FormCol>

        <FormCol>
          <ControlledSelect
            control={control}
            name='qualification'
            error={errors?.qualification}
            label={<LabelContent required>Qualification:</LabelContent>}
            options={qualificationOptions}
          />
        </FormCol>

        <FormCol>
          <ControlledSelect
            control={control}
            name='employment_type'
            error={errors?.employment_type}
            label={<LabelContent required>Employment Type:</LabelContent>}
            options={employmentTypeOptions}
          />
        </FormCol>

        <FormCol>
          <ControlledSelect
            control={control}
            name='employment_term'
            error={errors?.employment_term}
            label={<LabelContent required>Employment Term:</LabelContent>}
            options={employmentTermOptions}
          />
        </FormCol>

        <FormCol>
          <ControlledInput
            control={control}
            label={<LabelContent>Probation Period:</LabelContent>}
            name='trial_period'
            error={errors?.trial_period}
            placeholder='Probation Period'
          />
        </FormCol>

        <FormCol md={12}>
          <ControlledInput
            control={control}
            label={<LabelContent required>Job title:</LabelContent>}
            name='job_title'
            error={errors?.job_title}
            placeholder='i.e. Website Design, Content generation'
            autoComplete={titles}
            watchState={watchingJobTitle}
          />
        </FormCol>

        <FormCol md={12}>
          <ScopeOfWorkField
            label={<LabelContent required>Job description:</LabelContent>}
            placeholder='Job description'
            name='job_description'
            error={errors?.job_description}
            control={control}
          />
        </FormCol>

        <FormCol md={12}>
          <label htmlFor='offer_letter'>
            <LabelContent>Offer Letter:</LabelContent>
          </label>

          <UploadCustomFile
            control={control}
            name='offer_letter'
            error={errors?.offer_letter}
          />

          {!errors?.offer_letter?.message ? null : (
            <InputFeedback>{errors.offer_letter.message}</InputFeedback>
          )}
        </FormCol>
      </Row>

      <hr className='mx-n4' />

      <Row className='px-2'>
        <FormCol md={12}>
          <h5 className='rp-font-gilroyB text-gray-h'>Compensation</h5>
        </FormCol>

        <FormCol>
          <YearlySalaryField
            control={control}
            currencyOptions={currencyOptions}
            label={<LabelContent required>Gross Salary:</LabelContent>}
            errors={errors}
          />
        </FormCol>

        <FormCol>
          <ControlledDatePicker
            control={control}
            name='start_date'
            error={errors?.start_date}
            label={<LabelContent>Start Date:</LabelContent>}
          />
        </FormCol>

        <FormCol md={12}>
          <ControlledCheckbox
            control={control}
            name='insurance'
            id='health_insurance'
            label={
              <LabelContent>
                Would you like to offer premium health insurance?
              </LabelContent>
            }
          />
        </FormCol>

        <FormCol md={12} noMargin>
          <legend className='rp-font-gilroyB font-size-14 text-muted my-3'>
            Bonus (Optional)
          </legend>
        </FormCol>

        <FormCol>
          <label>
            <LabelContent>Bonus Amount:</LabelContent>
          </label>

          <InputGroup>
            <InputCurrencyAddon code={currencyCode} />

            <ControlledCurrencyInput control={control} name='bonus_amount' />
          </InputGroup>

          {!errors?.bonus_amount?.message ? null : (
            <InputFeedback>{errors.bonus_amount.message}</InputFeedback>
          )}
        </FormCol>

        <FormCol md={12} noMargin>
          <ControlledInput
            control={control}
            type='textarea'
            name='bonus_details'
            error={errors?.bonus_details}
            id='bonus_details'
            maxLength='100'
            label={<LabelContent>Bonus Details:</LabelContent>}
          />
        </FormCol>
      </Row>
    </>
  )
}

function SimulationView({ control }) {
  const { currencies, countries } = useSelector(
    (state) => state?.Layout?.staticData ?? {},
  )

  const {
    currency_id: currencyId,
    country_id: countryId,
    amount,
  } = useWatch({ control })

  const countryCode =
    countries?.length > 0
      ? getOptionFromList(countries, countryId, 'id')?.iso2
      : null
  const customerCurrencyCode =
    currencies?.length > 0
      ? getOptionFromList(currencies, currencyId, 'id')?.code
      : null

  const {
    data: eorQuote,
    isLoading: gettingQuote,
    error,
  } = useFetch(
    {
      action: getCalculatorResult,
      autoFetch: currencyId && countryId && amount,
      body: {
        country_code: countryCode,
        annual_salary: Number(amount),
        currency: customerCurrencyCode,
      },
    },
    [currencyId, countryId, amount],
  )

  return (
    <>
      <EORForm control={control} />

      <div style={{ height: '0.5rem' }} />

      {(error || eorQuote?.original?.data?.error) && !gettingQuote ? (
        <Alert color='primary'>
          {typeof error === 'string' && !!error
            ? error
            : eorQuote?.original?.data?.error}
        </Alert>
      ) : gettingQuote ? (
        <Loader minHeight='577px' />
      ) : (
        <QuoteView quote={eorQuote} />
      )}
    </>
  )
}

function DEForm({ control }) {
  const {
    job_titles: titles = [],
    countries = [],
    other_countries: otherCountries = [],
    currencies = [],
    seniorities = [],
  } = useSelector((state) => state.Layout?.staticData ?? {})

  const { setItem } = useFormData()

  const { country_id: countryId, name: watchingJobTitle } = useWatch({
    control,
  })

  const {
    formState: { errors },
    setValue,
  } = useFormContext()

  const { data: supportedCountries, isLoading: gettingSupportedCountries } =
    useFetch({
      action: deSupportedCountries,
      autoFetch: true,
    })

  const supportedCountryOptions = supportedCountries?.map((c) =>
    mapCountryToOption(c, 'id'),
  )

  const nationalities = [...countries, ...otherCountries].map((c) =>
    mapCountryToOption(c, 'id'),
  )

  const { data: jurisdictions, isLoading: loadingJurisdictions } = useFetch(
    {
      action: deGetCountryJurisdictions,
      body: { country_id: countryId },
      autoFetch: !!countryId,
    },
    [countryId],
  )

  return (
    <>
      <FormSectionTitle title='Contract' />

      <Row className='mx-n3'>
        <FormCol>
          <ControlledSelect
            control={control}
            name='country_id'
            error={errors?.country_id}
            label={<LabelContent required>Employment Country:</LabelContent>}
            options={supportedCountryOptions}
            disabled={gettingSupportedCountries}
            isLoading={gettingSupportedCountries}
            transform={{
              output: (value) => {
                const newCurrency = getCurrencyFromTaxCountry(
                  currencies,
                  value?.currency,
                )

                setValue('currency_id', newCurrency?.id ?? USD_ID)

                return value
              },
            }}
          />
        </FormCol>

        <FormCol>
          <ControlledSelect
            control={control}
            name='jurisdiction_id'
            error={errors?.jurisdiction_id}
            label={<LabelContent required>Jurisdiction:</LabelContent>}
            options={jurisdictions?.map((j) => ({
              ...j,
              label: j?.name,
              value: j?.id,
            }))}
            disabled={
              loadingJurisdictions || !jurisdictions?.length || !countryId
            }
            isLoading={loadingJurisdictions}
            transform={{
              output: (value) => {
                setItem(dataKeys.jurisdictionId, value?.currencies)
                setItem(dataKeys.jurisdiction, value)

                const defaultNoticePeriod = value?.notice_period
                const defaultPaidVacationDays = value?.paid_vacation_days
                const defaultProbationPeriod = value?.probation_period

                setValue('notice_period', defaultNoticePeriod)
                setValue('paid_vacation_days', defaultPaidVacationDays)
                setValue('probation_period', defaultProbationPeriod)

                return value
              },
            }}
          />
        </FormCol>

        <FormCol>
          <ControlledRadioList
            control={control}
            name='employment_term'
            error={errors?.employment_term}
            horizontal
            label={<LabelContent required>Employment Term:</LabelContent>}
            options={employmentTermOptions}
            isFancy
          />
        </FormCol>

        <FormCol>
          <ControlledRadioList
            control={control}
            name='employment_type'
            error={errors?.employment_type}
            horizontal
            label={<LabelContent required>Contract Type:</LabelContent>}
            options={employmentTypeOptions}
            isFancy
          />
        </FormCol>
      </Row>

      <FormSectionTitle title='Employee' />

      <Row className='mx-n3'>
        <FormCol>
          <ControlledSelect
            control={control}
            name='seniority'
            error={errors?.seniority}
            label={<LabelContent required>Seniority:</LabelContent>}
            options={seniorities?.map((s) => ({
              label: typeof s === 'string' ? s : s?.name,
              value: typeof s === 'string' ? s : s?.id,
            }))}
          />
        </FormCol>

        <FormCol>
          <ControlledSelect
            control={control}
            name='nationality_id'
            error={errors?.nationality_id}
            label={<LabelContent required>Nationality:</LabelContent>}
            options={nationalities}
          />
        </FormCol>

        <FormCol>
          <ControlledInput
            label={<LabelContent required>Title:</LabelContent>}
            control={control}
            name='name'
            error={errors?.name}
            placeholder='i.e. Website Design, Content generation'
            autoComplete={titles}
            watchState={watchingJobTitle}
          />
        </FormCol>

        <FormCol>
          <ControlledInput
            control={control}
            name='department'
            error={errors?.department}
            label={<LabelContent>Department</LabelContent>}
            placeholder='i.e. Marketing, Sales, Tech, etc.'
          />
        </FormCol>

        <FormCol md={12} noMargin>
          <ScopeOfWorkField
            label={<LabelContent required>Scope Of Work:</LabelContent>}
            placeholder='Describe the deliverables'
            name='scope'
            error={errors?.scope}
            control={control}
          />
        </FormCol>
      </Row>
    </>
  )
}

function FormSectionTitle({ title, className }) {
  return (
    <div className={cx('pt-3 border-top', className)}>
      {!title ? null : (
        <h5 className='rp-font-gilroyB text-gray-h mb-3'>{title}</h5>
      )}
    </div>
  )
}

function PaymentStep({ control, showRecurringExpensesMessage }) {
  const { [fieldKeys.type]: type, employee_type: employeeType } = useWatch({
    control,
  })

  if (type === contractTypes.MILESTONES) {
    return <MilestoneForm control={control} />
  }

  if (employeeType === EMPLOYEE_CONTRACT_TYPE.DIRECT_EMPLOYEE) {
    return <DePaymentStep control={control} />
  }

  return (
    <DefaultContractForm
      control={control}
      showRecurringExpensesMessage={showRecurringExpensesMessage}
    />
  )
}

const deDefaultFrequencyId = 4

function DePaymentStep({ control }) {
  const { currencies } = useSelector((state) => state.Layout.staticData ?? {})

  const { formData } = useFormData()

  const {
    currency_id: currencyId,
    start_date: startDate,
    occurrence_id: occurrenceId,
    first_payment_date: firstPaymentDate,
    [fieldKeys.proRata]: proRata,
    [fieldKeys.endDate]: endDate,
    [fieldKeys.amount]: amount,
  } = useWatch({ control })

  const {
    setValue,
    formState: { errors },
    watch,
  } = useFormContext()

  const currencyOptions = currencies?.map((c) => mapCurrencyToOption(c, 'id'))
  const selectedCurrency = getOptionFromList(currencyOptions, currencyId, 'id')

  useProRataUpdate({
    amount,
    endDate,
    firstPaymentDate,
    frequencyId: deDefaultFrequencyId,
    occurrenceId,
    proRata,
    startDate,
    onComplete: (data) => {
      setValue(fieldKeys.proRataAmount, data?.amount)
    },
  })

  const isDeDefinite = isDefinite(watch('employment_term'))

  return (
    <>
      <Row className='mx-n3'>
        <FormCol md={12}>
          <ControlledTypeSelect
            control={control}
            name='is_external_payroll_provider'
            error={errors?.is_external_payroll_provider}
            types={deExternalPaymentOptions}
            cols={deExternalPaymentOptions.length}
            horizontal
          />
        </FormCol>

        <FormCol>
          <ControlledDatePicker
            control={control}
            name='start_date'
            error={errors?.start_date}
            label={<LabelContent required>Start Date:</LabelContent>}
          />
        </FormCol>

        <FormCol>
          <ControlledDatePicker
            control={control}
            name='end_date'
            error={errors?.end_date}
            placeholder={isDeDefinite ? 'End date' : '(Optional)'}
            label={
              <LabelContent required={isDeDefinite}>End Date:</LabelContent>
            }
          />
        </FormCol>

        <FormCol>
          <YearlySalaryField
            control={control}
            currencyOptions={formData[dataKeys.jurisdictionId]?.map((c) =>
              mapCurrencyToOption(c, 'id'),
            )}
            errors={errors}
            label={<LabelContent required>Base salary:</LabelContent>}
          />
        </FormCol>

        <FormCol>
          <ControlledDatePicker
            label={<LabelContent required>First payment date:</LabelContent>}
            control={control}
            name='first_payment_date'
            error={errors?.first_payment_date}
            placeholder='First payment date'
            minDate={new Date()}
          />
        </FormCol>

        {!firstPaymentDate ? null : (
          <FormCol md={12}>
            <ControlledCheckbox
              control={control}
              name={fieldKeys.proRata}
              error={errors?.[fieldKeys.proRata]}
              id='proRata'
              label={<ProRataLabel />}
              className='d-inline-block'
            />
          </FormCol>
        )}

        {!proRata || !firstPaymentDate ? null : (
          <FormCol>
            <ControlledCurrencyInput
              label={
                <LabelContent required>
                  Amount of the first payment:
                </LabelContent>
              }
              control={control}
              prefix={
                selectedCurrency?.symbol ? `${selectedCurrency?.symbol} ` : ''
              }
              name={fieldKeys.proRataAmount}
              error={errors?.[fieldKeys.proRataAmount]}
              id={fieldKeys.proRataAmount}
              placeholder='Amount'
            />
          </FormCol>
        )}

        <FormCol md={12}>
          <DeAllowancesField control={control} />
        </FormCol>
      </Row>

      <FormSectionTitle className='mt-2' />

      <Row className='mx-n3'>
        <FormCol md={12}>
          <div className='bg-slate-50 p-3 rounded border border-slate-200'>
            <h5>Privileges</h5>

            <Row className='mx-n3'>
              <FormCol noMargin>
                <ControlledCheckbox
                  control={control}
                  label='Add bonus clause'
                  name='is_bonus_clause_enabled'
                  error={errors?.is_bonus_clause_enabled}
                  id='is_bonus_clause_enabled'
                  className='border border-slate-200 bg-white rounded py-2 pr-2 pl-5'
                />
              </FormCol>
              <FormCol noMargin>
                <ControlledCheckbox
                  control={control}
                  label='Annual plane ticket'
                  name='is_annual_plane_ticket_enabled'
                  error={errors?.is_annual_plane_ticket_enabled}
                  id='is_annual_plane_ticket_enabled'
                  className='border border-slate-200 bg-white rounded py-2 pr-2 pl-5'
                />
              </FormCol>
            </Row>
          </div>
        </FormCol>
      </Row>
    </>
  )
}

function DeAllowancesField({ control }) {
  const { currencies } = useSelector((state) => state.Layout?.staticData ?? {})

  const { currency_id: currencyId } = useWatch({ control })

  const { fields, append, remove } = useFieldArray({
    control,
    name: allowancesKey,
  })

  const {
    formState: { errors },
  } = useFormContext()

  const currencyOptions = currencies?.map((c) => mapCurrencyToOption(c, 'id'))
  const selectedCurrency = getOptionFromList(currencyOptions, currencyId, 'id')

  return (
    <>
      <div className='d-flex flex-column gap-14'>
        {fields.map((item, index) => (
          <div
            key={item.id}
            className='d-flex gap-10 bg-slate-50 p-2 border border-slate-100 rounded'
          >
            <div className='w-75'>
              <ControlledInput
                control={control}
                name={`${allowancesKey}.${index}.name`}
                error={errors?.[allowancesKey]?.[index]?.name}
                placeholder='e.g. Housing, Transportation'
              />
            </div>

            <InputGroup>
              <ControlledCurrencyInput
                control={control}
                name={`${allowancesKey}.${index}.amount`}
                error={errors?.[allowancesKey]?.[index]?.amount}
              />

              <InputCurrencyAddon
                code={selectedCurrency?.symbol}
                addonType='append'
              />
            </InputGroup>

            <div
              className='d-flex align-items-center border-left pl-2 flex-shrink-0'
              style={{ minWidth: 30 }}
            >
              <button
                type='button'
                onClick={() => remove(index)}
                className='rp-btn-nostyle text-danger hover:bg-red-30 bg-slate-50 d-flex p-1.5'
                aria-label='Delete'
              >
                <TrashSimple size='20' />
              </button>
            </div>
          </div>
        ))}
      </div>

      {fields.length <= 0 ? null : <div style={{ height: 12 }} />}

      <Button
        onClick={() => append({ name: '', amount: 0 })}
        outline
        icon={<PlusCircle size='24' />}
        block
        className={cx('!border-dashed', allowanceStyles.addAllowanceBtn)}
        alignLeft
      >
        Add Allowance
      </Button>
    </>
  )
}

function MilestoneForm({ control, errors }) {
  const { currencies } = useSelector((state) => state.Layout?.staticData ?? {})

  const { [fieldKeys.currencyId]: currencyId } = useWatch({
    control,
  })

  const currencyOptions = currencies?.map((c) => mapCurrencyToOption(c, 'id'))
  const selectedCurrency = getOptionFromList(currencyOptions, currencyId, 'id')

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldKeys.milestones,
  })

  return (
    <div className='d-flex flex-column align-items-start gap-24'>
      <div style={{ maxWidth: 320 }} className='w-100'>
        <ControlledSelect
          name='currency_id'
          control={control}
          error={errors?.currency_id}
          options={currencyOptions}
        />
      </div>

      <div className='w-100 d-flex flex-column gap-14'>
        {fields.map((item, index) => {
          return (
            <div key={item.id} className='d-flex align-items-center gap-24'>
              <div className='flex-grow-1'>
                <ControlledInput
                  control={control}
                  name={`${fieldKeys.milestones}.${index}.name`}
                  placeholder='Milestone name'
                />
              </div>

              <ControlledCurrencyInput
                control={control}
                name={`${fieldKeys.milestones}.${index}.amount`}
                prefix={
                  selectedCurrency?.symbol ? `${selectedCurrency?.symbol} ` : ''
                }
                className='w-25'
              />

              <div style={{ minWidth: 30 }}>
                <button
                  type='button'
                  onClick={() => remove(index)}
                  className='rp-btn-nostyle text-danger bg-slate-50 d-flex p-1.5'
                  aria-label='Delete'
                >
                  <i className='bx bx-trash font-size-20' />
                </button>
              </div>
            </div>
          )
        })}
      </div>

      <Button onClick={() => append({ name: '', amount: 0 })} size='sm' outline>
        Add milestone
      </Button>
    </div>
  )
}

function useProRataUpdate({
  amount,
  endDate,
  firstPaymentDate,
  frequencyId,
  occurrenceId,
  proRata,
  startDate,
  onComplete,
}) {
  const { startFetch: getDefaultProrata } = useFetch({
    action: updateProrata,
    onComplete,
  })

  // Recalculate prorata amount when data changes => amount, start date, frequency, occurrence, end date or first payment date
  useEffect(() => {
    if (proRata && !!firstPaymentDate) {
      getDefaultProrata({
        amount,
        first_payment_date: firstPaymentDate,
        frequency_id: frequencyId,
        start_date: startDate,
        occurrence_id: occurrenceId,
        end_date: endDate ?? null,
      })
    }
  }, [
    amount,
    endDate,
    firstPaymentDate,
    frequencyId,
    // getDefaultProrata,
    occurrenceId,
    proRata,
    startDate,
  ])
}

function DefaultContractForm({ control, showRecurringExpensesMessage }) {
  const { currencies, rates, frequencies } = useSelector(
    (state) => state.Layout?.staticData ?? {},
  )

  const {
    setValue,
    formState: { errors },
  } = useFormContext()

  const {
    [fieldKeys.type]: type,
    [fieldKeys.startDate]: startDate,
    [fieldKeys.endDate]: endDate,
    [fieldKeys.currencyId]: currencyId,
    [fieldKeys.frequencyId]: frequencyId,
    [fieldKeys.occurrenceId]: occurrenceId,
    [fieldKeys.firstPaymentDate]: firstPaymentDate,
    [fieldKeys.proRata]: proRata,
    [fieldKeys.amount]: amount,
  } = useWatch({ control })

  const currencyOptions = currencies?.map((c) => mapCurrencyToOption(c, 'id'))
  const selectedCurrency = getOptionFromList(currencyOptions, currencyId, 'id')

  const { data: occurrencesData } = useFetch(
    {
      action: getOccurrences,
      autoFetch: !!startDate,
      body: { start_date: startDate },
    },
    [startDate],
  )

  useProRataUpdate({
    amount,
    endDate,
    firstPaymentDate,
    frequencyId,
    occurrenceId,
    proRata,
    startDate,
    onComplete: (data) => {
      setValue(fieldKeys.proRataAmount, data?.amount)
    },
  })

  const amountCurrencyError = [
    errors?.amount?.message,
    errors?.currency_id?.message,
  ]
    .filter(Boolean)
    .join(', ')

  return (
    <Row className='mx-0 py-2'>
      {!showRecurringExpensesMessage ? null : (
        <FormCol md={12} noMargin>
          <Alert color='info'>
            If the currency is changed, this contract’s active recurring
            expenses will be deactivated.
          </Alert>
        </FormCol>
      )}

      <FormCol>
        <ControlledDatePicker
          control={control}
          name='start_date'
          error={errors?.start_date}
          label={<LabelContent required>Start Date:</LabelContent>}
        />
      </FormCol>

      <FormCol>
        <ControlledDatePicker
          control={control}
          name='end_date'
          error={errors?.end_date}
          placeholder='(Optional)'
          label={<LabelContent>End Date:</LabelContent>}
        />
      </FormCol>

      <FormCol>
        <label htmlFor='amount'>
          <LabelContent required>Amount:</LabelContent>
        </label>

        <InputGroup>
          <ControlledCurrencyInput
            control={control}
            name='amount'
            id='amount'
            placeholder='Amount'
          />

          <ControlledSelect
            className='input-group-text p-0 border-0 rounded-left-0'
            leftRounded
            placeholder='Currency'
            name='currency_id'
            control={control}
            error={errors.currency_id}
            classNamePrefix='RS-Addon'
            options={currencyOptions}
            customComponents={{
              Option: customOption,
              Value: customSingleValue,
              SingleValue: customSingleValue,
            }}
          />
        </InputGroup>

        {!amountCurrencyError ? null : (
          <InputFeedback className='mt-1'>{amountCurrencyError}</InputFeedback>
        )}
      </FormCol>

      {type !== contractTypes.PAYG ? null : (
        <FormCol>
          <ControlledSelect
            label={<LabelContent required>Unit (of work):</LabelContent>}
            name='rate_id'
            error={errors?.rate_id}
            control={control}
            isSearchable={false}
            options={rates?.map((e) => ({ value: e.id, label: e.name }))}
          />
        </FormCol>
      )}

      <FormCol>
        <ControlledSelect
          label={<LabelContent required>Frequency of payments:</LabelContent>}
          control={control}
          name='frequency_id'
          error={errors?.frequency_id}
          isSearchable={false}
          options={frequencies?.map(mapFrequencyToOption)}
          transform={{
            output: (value) => {
              setValue(fieldKeys.occurrenceId, null)
              setValue(fieldKeys.firstPaymentDate, null)
              return value
            },
          }}
        />
      </FormCol>

      {!frequencyId ? null : (
        <FormCol>
          <ControlledSelect
            label={
              <LabelContent required>
                When would you process payments?
              </LabelContent>
            }
            control={control}
            name='occurrence_id'
            error={errors?.occurrence_id}
            isSearchable={false}
            options={occurrencesData
              ?.filter((o) => o.frequency_id === frequencyId)
              ?.map((e) => ({ value: e.id, label: e.name }))}
            transform={{
              output: (value) => {
                setValue(fieldKeys.firstPaymentDate, null)
                return value
              },
            }}
          />
        </FormCol>
      )}

      {!occurrenceId ? null : (
        <FormCol>
          <ControlledSelect
            label={
              <LabelContent required>Date for the first payment:</LabelContent>
            }
            control={control}
            name='first_payment_date'
            error={errors?.first_payment_date}
            isSearchable={false}
            options={occurrencesData?.find((o) => o.id === occurrenceId)?.dates}
          />
        </FormCol>
      )}

      {!firstPaymentDate ||
      ![contractTypes.FIXED, contractTypes.FULL_TIME].includes(type) ? null : (
        <FormCol md={12}>
          <ControlledCheckbox
            control={control}
            name={fieldKeys.proRata}
            error={errors?.[fieldKeys.proRata]}
            id='proRata'
            label={<ProRataLabel />}
            className='d-inline-block'
          />
        </FormCol>
      )}

      {!proRata ||
      ![contractTypes.FIXED, contractTypes.FULL_TIME].includes(type) ||
      !firstPaymentDate ? null : (
        <FormCol noMargin>
          <ControlledCurrencyInput
            label={
              <LabelContent required>Amount of the first payment:</LabelContent>
            }
            control={control}
            prefix={
              selectedCurrency?.symbol ? `${selectedCurrency?.symbol} ` : ''
            }
            name={fieldKeys.proRataAmount}
            error={errors?.[fieldKeys.proRataAmount]}
            id={fieldKeys.proRataAmount}
            placeholder='Amount'
          />
        </FormCol>
      )}
    </Row>
  )
}

function ComplianceStep({ control }) {
  const { contractor_types: contractorTypes } = useSelector(
    (state) => state.Layout?.staticData ?? {},
  )

  const { data: templates } = useFetch({
    action: getTemplateList,
    autoFetch: true,
  })

  const { contract_doc_type: contractDocType, employee_type: employeeType } =
    useWatch({ control })

  const {
    setValue,
    formState: { errors },
  } = useFormContext()

  const types = useMemo(() => {
    return [
      ...defaultContractTemplatesTypes,
      templates?.length > 0 ? customContractTemplatesType : null,
    ].filter(Boolean)
  }, [templates?.length])

  if (employeeType === EMPLOYEE_CONTRACT_TYPE.DIRECT_EMPLOYEE) {
    return <DeComplianceStep control={control} />
  }

  return (
    <Row className='mx-n2'>
      <FormCol md={12}>
        <ControlledTypeSelect
          control={control}
          name='contract_doc_type'
          error={errors?.contract_doc_type}
          types={types}
          cols={types.length}
          horizontal
        />
      </FormCol>

      {contractDocType === CONTRACT_DOC_TYPE.RP_TEMPLATE ||
      !contractDocType ? null : (
        <FormCol md={12}>
          {contractDocType === CONTRACT_DOC_TYPE.CUSTOM ? (
            <UploadCustomFile
              control={control}
              name='file'
              error={errors?.file}
              onFileSet={() => {
                // template_id & file are mutually exclusive
                setValue('template_id', null)
              }}
            />
          ) : contractDocType === CONTRACT_DOC_TYPE.CUSTOM_TEMPLATE ? (
            <ControlledSelect
              label='Select a template:'
              control={control}
              name='template_id'
              options={templates?.map((t) => ({
                value: t?.id,
                label: t?.name,
              }))}
              transform={{
                output: (value) => {
                  // template_id & file are mutually exclusive
                  setValue('file', null)
                  return value
                },
              }}
            />
          ) : null}
        </FormCol>
      )}

      <FormCol>
        <label htmlFor='notice_period'>
          <LabelContent required>
            Notice period (days) to terminate the contract:
          </LabelContent>
        </label>
        <InputGroup>
          <ControlledCurrencyInput
            control={control}
            name='notice_period'
            id='notice_period'
          />

          <InputGroupAddon addonType='append'>
            <InputGroupText className='bg-transparent text-muted'>
              Days
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      </FormCol>

      <FormCol>
        <ControlledSelect
          control={control}
          name='contractor_label_id'
          label={<ContractorLabel />}
          options={contractorTypes?.map((e) => ({
            value: e.id,
            label: e.name,
          }))}
        />
      </FormCol>
    </Row>
  )
}

function DeComplianceStep({ control }) {
  const { de_contract_doc_type: deContractDocType } = useWatch({ control })

  const { formData } = useFormData()

  const jurisdiction = formData?.[dataKeys.jurisdiction]

  const {
    formState: { errors },
  } = useFormContext()

  const types = useMemo(() => {
    return deContractOptions
  }, [])

  return (
    <>
      <Row className='mx-n3'>
        <FormCol md={12}>
          <ControlledTypeSelect
            control={control}
            name='de_contract_doc_type'
            error={errors?.de_contract_doc_type}
            types={types}
            cols={types.length}
            horizontal
          />
        </FormCol>

        <FormCol md={12}>
          {deContractDocType !== CONTRACT_DOC_TYPE.CUSTOM ? null : (
            <UploadCustomFile
              control={control}
              name='file'
              error={errors?.file}
            />
          )}
        </FormCol>

        <FormCol md={12}>
          <ControlledCheckbox
            control={control}
            name='is_offer_letter_included'
            id='is_offer_letter_included'
            label={<LabelContent>Add an offer letter</LabelContent>}
            className='border border-slate-200 bg-slate-50 rounded py-2 pr-2 pl-5'
          />
        </FormCol>

        <FormCol md={12} noMargin>
          <PrimaryAlert>
            The employments template and the offer letter can be edited and
            customized on the next step.
          </PrimaryAlert>
        </FormCol>
      </Row>

      <FormSectionTitle title='Terms' className='mt-3' />

      <Row className='mx-n3'>
        <FormCol>
          <label>
            <LabelContent required>Vacation days:</LabelContent>
          </label>

          <InputGroup className='w-100 flex-nowrap'>
            <ControlledInput
              className='rounded-right-0'
              control={control}
              name='paid_vacation_days'
              type='number'
              min={0}
              showError={false}
            />

            <InputGroupAddon addonType='append'>
              <InputGroupText>Working Days</InputGroupText>
            </InputGroupAddon>
          </InputGroup>

          {!errors?.paid_vacation_days?.message ? null : (
            <InputFeedback>{errors.paid_vacation_days.message}</InputFeedback>
          )}
        </FormCol>

        <FormCol>
          <ControlledDatePicker
            control={control}
            name='paid_vacation_accrual_date'
            error={errors?.paid_vacation_accrual_date}
            label={
              <LabelContent required>Vacation Accrual start Date:</LabelContent>
            }
            placeholder='Vacation Accrual start Date'
          />
        </FormCol>

        <FormCol>
          <ProbationPeriodField
            control={control}
            errors={errors}
            jurisdiction={jurisdiction}
          />
        </FormCol>

        <FormCol>
          <NoticePeriodField
            control={control}
            errors={errors}
            jurisdiction={jurisdiction}
          />
        </FormCol>

        {!jurisdiction?.notice_vacation_days ? null : (
          <FormCol md={12} noMargin>
            <PrimaryAlert>{jurisdiction?.notice_vacation_days}</PrimaryAlert>
          </FormCol>
        )}
      </Row>
    </>
  )
}

function ProRataLabel() {
  return (
    <LabelContent
      tooltipId='proRataInfo'
      tooltipText='Pro-rata is calculated based on the days worked in the 1st cycle [days
          worked * (amount / days in a 1st cycle)]'
    >
      Prorata on the first payment
    </LabelContent>
  )
}

function ContractorLabel() {
  return (
    <LabelContent
      tooltipId='contractorLabel'
      tooltipText='By default this label is set to ‘Contractor’. You can change it to;
        Consultant, Freelancer, Expert, Service provider…etc. If you change this
        label, the change will be reflected on the Agreement as well as the
        Contract details. *Avoid using Employee as a label, if you are using our
        contract template.'
    >
      Contractor Label
    </LabelContent>
  )
}

function UploadCustomFile({ control, name, onFileSet, error }) {
  const [contractFile, setContractFile] = useState(null)

  const {
    field: { value },
  } = useController({ control, name })
  const { setValue } = useFormContext()

  const { startFetch: uploadContractDoc, isLoading: uploadingContractDoc } =
    useFetch({
      action: uploadContractFile,
      onComplete: (data) => {
        setValue(name, data.path)
      },
      onError: (error) => {
        toastr.error(
          typeof error === 'string'
            ? error
            : typeof error?.message === 'string'
            ? error.message
            : 'Something went wrong while uploading the contract document',
        )
      },
    })

  function handleUploadCustomContract(files) {
    const file = files[0]
    setContractFile(file)
    onFileSet?.()
    uploadContractDoc({ file })
  }

  function handleRemoveFile(e) {
    e.preventDefault()
    e.stopPropagation()
    setContractFile(null)
    setValue(name, null)
  }

  return (
    <div>
      <DropzoneInput
        name={name}
        onDropAccepted={handleUploadCustomContract}
        className='d-flex flex-column justify-content-center align-items-center'
        style={{ minHeight: 180 }}
      >
        {contractFile || value ? (
          <div className='align-items-center d-flex gap-12 w-100'>
            {!uploadingContractDoc ? (
              <i className='bx bxs-file-pdf' style={{ fontSize: 32 }} />
            ) : (
              <Loader type='border' minHeight={null} />
            )}
            <div className='h5 rp-font-gilroyB mb-0'>
              {contractFile?.name ?? value}
            </div>

            <button
              className='rp-btn-nostyle text-danger font-size-22 d-flex p-1 ml-auto'
              type='button'
              onClick={handleRemoveFile}
            >
              <i className='bx bx-trash' />
            </button>
          </div>
        ) : (
          <>
            <i className='bx bxs-file-pdf pb-3' style={{ fontSize: 40 }} />
            <h4 className='rp-font-gilroyB text-current d-md-none'>
              Click to upload files.
            </h4>
            <h4 className='rp-font-gilroyB text-current d-none d-md-block'>
              Drop files here or click to upload.
            </h4>
            <p className='text-muted font-size-12 mb-0'>
              *PDF only. Max file size 2MB
            </p>
          </>
        )}
      </DropzoneInput>

      {!error ? null : (
        <InputFeedback className='mt-1'>
          {getInputErrorMessage(error)}
        </InputFeedback>
      )}
    </div>
  )
}

function InputCurrencyAddon({ code, addonType = 'prepend' }) {
  return (
    <InputGroupAddon addonType={addonType}>
      <InputGroupText style={{ minWidth: 80 }}>
        <FlagIcon className='mr-2' code={code} />
        {code}
      </InputGroupText>
    </InputGroupAddon>
  )
}
