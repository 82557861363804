import cx from 'classnames'
import React from 'react'

export default function UploadPreview({
  preview,
  className = 'h-100 w-100',
  style,
}) {
  const classList = cx('rounded border', className)

  return preview?.type === 'application/pdf' ? (
    <iframe
      src={preview?.data + '#toolbar=0&navpanes=0&scrollbar=0'}
      className={classList}
      style={style}
    />
  ) : (
    <div style={style} className='overflow-auto'>
      <img alt='' src={preview?.data} className={classList} />
    </div>
  )
}
