import { ShieldPlus } from '@phosphor-icons/react'
import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { Col, Container, Spinner, Badge, Alert } from 'reactstrap'
import Button from 'reactstrap/lib/Button'
import { useSelector } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Link, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import toastr from 'toastr'

import Walleticon from '../../assets/images/Walleticon.svg'
import ActiveContracts from '../../assets/images/active_contracts.svg'
import ContractorInsurance from '../../assets/images/contractor-insurance.svg'
import Moneyicon from '../../assets/images/upcoming_payment.svg'
import ActionCenter from '../../components/ActionCenter'
import { useFetch } from '../../helpers/hooks'
import { contractorTypes, userTypes } from '../../helpers/enum'
import { getCurrencyFormatter } from '../../utils/formatters/currency'
import { StyledH4, StyledLink } from '../../components/Typo'
import { getEarnings, getOverview, getToDoList } from '../../services/api'
import { getCookie, setCookie } from '../../utils/cookies'
import { INSURANCE_BANNER_COOKIE } from '../../utils/cookies/cookie-names'
import ColumnWithDataLabels from '../AllCharts/apex/ColumnWithDataLabels'
import ContractList from '../Contract/ContractList/ContractList'
import styles from './insurance-banner.module.scss'
import MiniWidget from './mini-widget'
import PhysicalCardBanner, { RedIndicator } from './physical-card-banner'
import FEATURE_FLAGS from '../../config/feature-flags'

const StyledCol = styled(Col)`
  background: ${(props) => (props.doStyle ? '#FFFFFF' : 'transparent')};
  box-shadow: 0px 1px 0px #dfe1e6;
  border-radius: 4px;
`
const StyledTitleCont = styled.div`
  padding: 32px 24px;
  @media (max-width: 425px) {
    padding: 16px;
  }
`
const StyledRow = styled.div`
  padding: 32px 24px;
  @media (max-width: 425px) {
    padding: 16px;
  }
`

const StyledPrice = styled.h3`
  font-size: 24px;
  font-weight: 700;
  margin: 16px 0;
  letter-spacing: 1px;
`

const CustomColumn = styled.div`
  &.blurred {
    filter: blur(4px);
    -webkit-filter: blur(4px);
    pointer-events: none;
  }
`
const Dimmer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  z-index: 999;
`

const ContractorOverview = (props) => {
  const [reports, setReports] = useState([])
  const [loading, setLoading] = useState(true)
  const [contracts, setContracts] = useState([])
  const [earnings, setEarnings] = useState({
    currency: {},
    months: [],
    labels: [],
    values: [],
    current: {},
    last: {},
    isEmpty: null,
  })

  const user = useSelector((state) => state.Account?.user)
  const userProfile = useSelector((state) => state.userProfile?.userProfile)
  const tagSession = () => {
    if (window.smartlook) {
      window.smartlook('identify', user?.id, {
        name: `${user?.first_name} ${user?.last_name}`,
        email: user?.email,
      })
    }
  }

  const todo = useFetch({
    action: getToDoList,
    initResult: [],
    onError: (err) => toastr.error(err),
    autoFetch: false,
  })

  useEffect(() => {
    todo.startFetch()
    tagSession()
    try {
      getEarnings(user?.token)
        .then((r) => {
          const xAxis = []
          const xAxisLong = []
          const values = []
          if (r.data.success) {
            r.data.data.months.map((element, i) => {
              xAxis[i] = element.month.short
              xAxisLong[i] = element.month.long
              values[i] = element.total
              return element
            })
            setEarnings({
              currency: r.data.data.currency,
              months: xAxis,
              labels: xAxisLong,
              values,
              current: r.data.data.current,
              last: r.data.data.last,
              isEmpty: r.data.data.is_empty,
            })
          }
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.warn(e)
          setLoading(false)
        })
    } catch (e) {
      setLoading(false)
    }
    try {
      getOverview(user?.token)
        .then((r) => {
          setLoading(false)
          if (r.data.success) {
            setReports([
              {
                icon: Walleticon,
                link: '/history',
                title: 'Balance',
                value: r.data.data.balance,
                badgeValue: '6',
                color: 'success',
                desc: 'Contracts',
                track: () => {
                  window.analytics.track('Clicked withdraw', {
                    email_id: user?.email,
                  })
                },
              },
              {
                icon: ActiveContracts,
                link: '/contracts',
                title: 'Active contracts',
                value: r.data.data.active_contracts,
                badgeValue: '6',
                color: 'success',
                desc: 'Contracts',
                track: () => {
                  window.analytics.track('Clicked view active contracts', {
                    email_id: user?.email,
                  })
                },
              },
              {
                icon: Moneyicon,
                link: '/payment',
                title: 'Upcoming Payments',
                value: r.data.data.upcoming_payment,
                badgeValue: '--',
                color: 'success',
                desc: 'Contracts',
                info: 'List of payments in the current cycle for active contracts. For Pay as you Go and Milestone contracts, a payment is created only when your submitted work is approved by the client',
                track: () => {
                  window.analytics.track('Clicked upcoming payment', {
                    email_id: user?.email,
                  })
                },
              },
            ])
            setContracts(r.data.data.contracts)
          }
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.warn(e)
          setLoading(false)
        })
    } catch (e) {
      setLoading(false)
    }
  }, [])

  const formatter = getCurrencyFormatter(earnings?.currency?.code)

  const insuranceBannerCookie = JSON.parse(
    getCookie(INSURANCE_BANNER_COOKIE) ?? 'false',
  )

  const shouldShowInsuranceBanner =
    user?.type === userTypes.CONTRACTOR &&
    userProfile?.can_add_insurance &&
    !insuranceBannerCookie &&
    FEATURE_FLAGS.INSURANCE_BANNER

  const [insuranceBanner, setInsuranceBanner] = useState(
    shouldShowInsuranceBanner,
  )

  const shouldShowAllowanceInsuranceBanner =
    props?.userInfo?.type === userTypes.CONTRACTOR &&
    props?.userInfo?.has_insurance_allowance &&
    props?.userInfo?.can_add_insurance &&
    props?.userInfo?.contractor_type === contractorTypes.INDIVIDUAL

  function handleToggle() {
    setInsuranceBanner((isShown) => {
      if (isShown) {
        const twoWeeks = 15
        setCookie(INSURANCE_BANNER_COOKIE, true, { expires: twoWeeks })
      }

      return !isShown
    })
  }

  return (
    <>
      <div className='page-content'>
        {loading || todo.isLoading ? (
          <div
            style={{ height: 'calc(100vh - 215px)' }}
            className='d-flex justify-content-center align-items-center'
          >
            <Spinner type='grow' color='primary' />
          </div>
        ) : (
          <Container fluid className='p-0 m-0'>
            <Alert
              color='light'
              className={cx(
                'mb-4 d-flex justify-content-between align-items-center bg-white',
                styles.banner,
              )}
              isOpen={shouldShowAllowanceInsuranceBanner}
            >
              <div className='d-flex align-items-start align-items-md-center flex-column flex-md-row gap-16'>
                <div className='align-items-center d-flex bg-primary-20 rounded-circle p-3'>
                  <ShieldPlus size={32} className='text-primary' />
                </div>

                <div>
                  <div className='mb-1 d-flex gap-12'>
                    <h3 className='rp-font-gilroyB font-size-24 mb-0'>
                      Get health insurance
                    </h3>

                    <Badge
                      className='p-2 mb-2 font-size-10 rounded-pill'
                      color='primary'
                    >
                      NEW
                    </Badge>
                  </div>
                  <p className='mb-0 font-size-14'>
                    We see that you have an allowance on your balance.
                    <br />
                    Are you interested in purchasing health insurance from us?
                  </p>
                </div>
              </div>
              <div className='d-flex gap-24'>
                <div>
                  <div className='pb-3 pt-1'>
                    <span className='text-body rp-font-medium'>
                      One step left.{' '}
                    </span>
                    <span className='text-muted'>About 30 seconds.</span>
                  </div>
                  <div
                    className='progress'
                    style={{
                      height: '4px',
                    }}
                  >
                    <div
                      className='progress-bar bg-success rounded'
                      role='progressbar'
                      style={{
                        width: '48%',
                      }}
                    ></div>
                    <div
                      className='progress-bar bg-white rounded'
                      role='progressbar'
                      style={{
                        width: '4%',
                      }}
                    ></div>
                    <div
                      className='progress-bar bg-light rounded'
                      role='progressbar'
                      style={{
                        width: '48%',
                      }}
                    ></div>
                  </div>
                </div>
                <div>
                  <RedIndicator />
                  <Button
                    tag={Link}
                    to='/insurance'
                    color='primary'
                    className='align-self-center'
                  >
                    <span className='d-flex align-items-center'>
                      Continue{' '}
                      <i className='bx bx-right-arrow-alt font-size-24' />
                    </span>
                  </Button>
                </div>
              </div>
            </Alert>
            {!shouldShowInsuranceBanner ? null : (
              <Alert
                color='light'
                className={cx(
                  'mb-4 d-flex justify-content-between align-items-center bg-white',
                  styles.banner,
                )}
                closeClassName='p-2'
                toggle={handleToggle}
                isOpen={insuranceBanner}
              >
                <div
                  className='d-flex align-items-center'
                  style={{ gap: '1rem' }}
                >
                  <img
                    src={ContractorInsurance}
                    style={{
                      '--size': '38px',
                      height: 'var(--size)',
                      width: 'var(--size)',
                    }}
                    alt=''
                  />
                  <p
                    className={cx(
                      'mb-0 rp-font-gilroyB text-capitalize',
                      styles.bannerText,
                    )}
                  >
                    Get Premium Health Coverage and Pay In Monthly Installments!
                  </p>
                </div>
                <Button
                  size='sm'
                  className='rp-candy-pink-btn'
                  iconRight={
                    <i className='bx bx-right-arrow-alt font-size-18' />
                  }
                  tag={Link}
                  to='/insurance'
                >
                  Learn More
                </Button>
              </Alert>
            )}
            <PhysicalCardBanner />
            <StyledH1 className='text-dark mb-1 mb-md-3'>
              Hi, <span translate='no'>{user?.first_name}</span>
            </StyledH1>
            <StyledH2 className='text-secondary'>
              Here is a recap of your activity
            </StyledH2>
            {todo?.data?.length > 0 && <ActionCenter data={todo.data} />}

            <Col className='p-0 m-0'>
              <div style={{ marginBottom: 32 }}>
                <MiniWidget t={props.t} reports={reports} simple withdraw />
              </div>
            </Col>
            <StyledCol
              className={`p-0 m-0 mb-4 ${
                earnings?.isEmpty ? 'empty-graph' : ''
              }`}
              doStyle
            >
              <StyledTitleCont className='d-flex justify-content-between align-items-center'>
                <StyledH4
                  min='16px'
                  max='24px'
                  className='mb-0 text-dark'
                  style={{ fontFamily: 'GilroyB' }}
                >
                  Earnings Per Month
                </StyledH4>
              </StyledTitleCont>
              <StyledRow className='row position-relative'>
                {earnings?.isEmpty && <Dimmer>No data available</Dimmer>}
                <CustomColumn
                  className={`col-lg-4 ${earnings?.isEmpty ? 'blurred' : ''}`}
                >
                  <h5>This Month</h5>
                  <div className='d-flex align-items-center'>
                    <StyledPrice>
                      {formatter && formatter.format(earnings?.current?.total)}
                    </StyledPrice>
                    <span
                      className={`font-size-12 ml-2 rounded badge badge-white badge-pill bg-soft-${
                        earnings?.current?.growth < 0 ? 'danger' : 'success'
                      }`}
                    >
                      <p
                        className={`font-size-14 mb-0 text-${
                          earnings?.current?.growth < 0 ? 'danger' : 'success'
                        }`}
                      >
                        {earnings && earnings?.current?.growth}%
                      </p>
                    </span>
                  </div>
                  <StyledLink
                    max='14px'
                    min='12px'
                    to='/transactions'
                    className='text-primary d-flex align-items-center'
                  >
                    View details{' '}
                    <i className='bx text-primary bx-chevron-right font-size-6 align-middle ml-9' />
                  </StyledLink>
                  <hr
                    style={{
                      borderColor: '#E7E8F2',
                      marginTop: '20px',
                      marginBottom: '20px',
                    }}
                  />
                  <h5>Last Month</h5>
                  <div className='d-flex align-items-center'>
                    <StyledPrice>
                      {formatter && formatter.format(earnings?.last?.total)}
                    </StyledPrice>
                    <span
                      className={`font-size-12 ml-2 rounded badge badge-white badge-pill bg-soft-${
                        earnings?.last.growth < 0 ? 'danger' : 'success'
                      }`}
                    >
                      <p
                        className={`font-size-14 mb-0 text-${
                          earnings?.last?.growth < 0 ? 'danger' : 'success'
                        }`}
                      >
                        {earnings && earnings?.last?.growth}%
                      </p>
                    </span>
                  </div>
                  <StyledLink
                    max='14px'
                    min='12px'
                    to='/transactions'
                    className='text-primary d-flex align-items-center'
                  >
                    View details{' '}
                    <i className='bx text-primary bx-chevron-right font-size-6 align-middle ml-9' />
                  </StyledLink>
                </CustomColumn>
                <CustomColumn
                  className={`col-lg-8 ${earnings?.isEmpty ? 'blurred' : ''}`}
                >
                  <ColumnWithDataLabels
                    values={earnings?.values}
                    months={earnings?.months}
                    labels={earnings?.labels}
                    currency={earnings?.currency}
                  />
                </CustomColumn>
              </StyledRow>
            </StyledCol>
            {user?.type === userTypes.COMPANY && (
              <StyledCol className='p-0 m-0'>
                {!!contracts && !!contracts.length && (
                  <StyledTitleCont
                    style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
                    className='d-flex bg-white justify-content-between align-items-center'
                  >
                    <StyledH4
                      min='16px'
                      max='24px'
                      className='mb-0 text-dark'
                      style={{ fontFamily: 'GilroyB' }}
                    >
                      Recent Contracts
                    </StyledH4>
                    <StyledLink
                      max='14px'
                      min='12px'
                      to='/contracts'
                      className='text-primary ml-2 d-flex align-items-center'
                    >
                      View all{' '}
                      <i className='bx text-primary bx-chevron-right font-size-6 align-middle ml-9' />
                    </StyledLink>
                  </StyledTitleCont>
                )}

                <ContractList Orders={contracts} />
              </StyledCol>
            )}
          </Container>
        )}
      </div>
    </>
  )
}

const StyledH1 = styled.h1`
  font-family: 'gilroyB';
  margin-bottom: 14px;
  font-size: 32px;
  @media (max-width: 425px) {
    font-size: 22px;
  }
`
const StyledH2 = styled.h1`
  margin-bottom: 32px;
  font-weight: 400;
  font-size: 16px;
  @media (max-width: 425px) {
    font-size: 14px;
    margin-bottom: 16px;
  }
`

export default withRouter(withTranslation()(ContractorOverview))
