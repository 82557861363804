import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Row, Spinner } from 'reactstrap'

import { useFetch } from '../../../helpers/hooks'
import {
  getAdminCalculatorResult,
  getCalculatorResult,
} from '../../../services/api'
import { countryCodeToName } from '../utils/localizationHelpers'
import QuoteView from './Quote/QuoteView'

const CantQuoteFor = ({ countryCode }) => (
  <div className='p-8 mt-12 text-sm font-light text-gray-500 border border-gray-200 rounded-lg shadow w-172'>
    Sorry, we can’t automatically generate a quote for{' '}
    {countryCodeToName(countryCode)}. Please drop a message to us (via the
    widget on bottom right) and we can help you!
  </div>
)

CantQuoteFor.propTypes = { countryCode: PropTypes.string.isRequired }

export const Quote = ({
  updateQuote,
  quoteResults,
  localCountryCode,
  customerCurrencyCode,
  salaryAmountInCustomerCurrency,
  noOfHires,
}) => {
  const [quote, setQuote] = useState(null)
  const location = useLocation()
  const isAdmin = location.pathname.startsWith('/admin')

  const getcurrencyCalculations = useFetch({
    action: isAdmin ? getAdminCalculatorResult : getCalculatorResult,
    withAdminAccess: isAdmin,
    onComplete: (data) => {
      setQuote(data)

      quoteResults(data)
    },
  })

  useEffect(() => {
    getcurrencyCalculations.startFetch({
      country_code: localCountryCode,
      annual_salary: Number(salaryAmountInCustomerCurrency),
      currency: customerCurrencyCode,
    })
  }, [
    localCountryCode,
    customerCurrencyCode,
    salaryAmountInCustomerCurrency,
    noOfHires,
    updateQuote,
  ])

  return (
    <>
      {getcurrencyCalculations.isLoading ? (
        <Row className='w-100 justify-content-center p-5'>
          <Spinner type='border' color='primary' />
        </Row>
      ) : getcurrencyCalculations?.error ? (
        <div className='bg-soft-danger rounded text-danger py-3 border border-danger p-3'>
          <p className={'mb-0 d-flex '}>
            <i className='bx bx-info-circle text-danger  mr-2 font-weight-light font-size-20 ml-1' />
            {getcurrencyCalculations?.error}
          </p>
        </div>
      ) : quote?.original?.data?.error ? (
        <div className='bg-soft-primary rounded text-primary py-3 border border-primary p-3'>
          <p className={'mb-0 d-flex '}>
            <i className='bx bx-info-circle text-primary  mr-2 font-weight-light font-size-20 ml-1' />
            {quote?.original?.data?.error}
          </p>
        </div>
      ) : (
        <QuoteView quote={quote} />
      )}
    </>
  )
}

Quote.propTypes = {
  localCountryCode: PropTypes.string.isRequired,
  customerCurrencyCode: PropTypes.string.isRequired,
  salaryAmountInCustomerCurrency: PropTypes.number.isRequired,
  noOfHires: PropTypes.number.isRequired,
}

export default Quote
