import React from 'react'
import 'react-toggle/style.css'
import ToggleC from 'react-toggle'
import cx from 'classnames'

import './Toggle.css'

const Toggle = ({
  check,
  change,
  id,
  disabled,
  className,
  'aria-label': ariaLabel,
  icons = false,
  name,
  style,
  marginRight = 'mr-2',
}) => {
  return (
    <ToggleC
      id={id}
      disabled={disabled}
      className={cx(
        'custom-classname',
        { [marginRight]: !!marginRight },
        className,
      )}
      icons={icons}
      checked={check}
      onChange={change}
      aria-label={ariaLabel}
      name={name}
      style={style}
    />
  )
}

export default Toggle
