import React, { useState } from 'react'
import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Card,
} from 'reactstrap'
import { StyledH1, StyledH2 } from '../../../components/Typo'
import Button from '../../../components/ui/button'
import { useFetch, useResize } from '../../../helpers/hooks'
import { activateAllowanceInsuranceClient } from '../../../services/api'
import toastr from 'toastr'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'

const InsurancePlans = ({
  features,
  title,
  price,
  desc,
  currency,
  plan,
  ctaText,
  contractUser,
  contract,
  onAddAllowanceUpdate,
}) => {
  const isMobile = useResize()

  const formatter = getCurrencyFormatter(currency?.code)

  const [openConfirmAllowanceModal, setOpenConfirmAllowanceModal] =
    useState(false)
  const onSelectPlan = () => {
    setOpenConfirmAllowanceModal(true)
  }

  return (
    <Card className='mt-3 border border-1'>
      <Row className='p-4'>
        <Col>
          <StyledH1
            style={{
              fontFamily: 'gilroyB',
              fontWeight: 'bold',
              marginBottom: 24,
            }}
            min='16px'
            max='24px'
          >
            {title}
          </StyledH1>
          <StyledH2
            style={{
              fontFamily: 'gilroyB',
              fontWeight: 'bold',
              marginBottom: 12,
            }}
            min='16px'
            max='24px'
          >
            {formatter.format(price)}
            <span className='text-secondary'>/mo</span>
          </StyledH2>
          <h3
            style={{ marginBottom: 32, fontWeight: '400' }}
            className='text-secondary font-size-12'
          >
            {desc}
          </h3>
          <Row>
            {features.map((e, i) => (
              <Col
                key={`feature-${i}`}
                style={{ minWidth: isMobile ? '100%' : '20vw' }}
              >
                <Row className='p-0 m-0 align-items-center mb-4'>
                  {e?.included ? (
                    <div
                      className='bg-soft-success rounded-circle align-items-center justify-content-center mr-2'
                      style={{
                        height: 24,
                        width: 24,
                        paddingLeft: 2,
                        paddingTop: 2,
                      }}
                    >
                      <i className='bx bx-check text-success font-size-20 text-center' />
                    </div>
                  ) : (
                    <div
                      className='bg-soft-danger rounded-circle align-items-center justify-content-center mr-2'
                      style={{ height: 24, width: 24, paddingLeft: 3 }}
                    >
                      <i
                        className='mdi mdi-window-close text-danger font-size-18 text-center '
                        style={{ lineHeight: '24px' }}
                      />
                    </div>
                  )}
                  <h1 className='font-size-14 font-weight-bold my-0'>
                    {e.name}
                  </h1>
                  <i
                    id={`feature-${plan?.id}-${i}`}
                    className='bx bx-help-circle mx-2 text-secondary font-size-18'
                  />
                  <UncontrolledTooltip
                    placement='top'
                    target={`feature-${plan?.id}-${i}`}
                  >
                    {e.details}
                  </UncontrolledTooltip>
                </Row>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row className='p-0 m-0 justify-content-end'>
        <div className='w-100 p-4 d-flex'>
          <button
            onClick={onSelectPlan}
            className='flex-grow-1 btn btn-primary justify-content-center'
          >
            {ctaText}
          </button>
        </div>
      </Row>

      <ConfirmAllowanceModal
        isOpen={openConfirmAllowanceModal}
        toggle={() => setOpenConfirmAllowanceModal(false)}
        plan={plan}
        contractUser={contractUser}
        contract={contract}
        onUpdate={onAddAllowanceUpdate}
      />
    </Card>
  )
}

const ConfirmAllowanceModal = ({
  isOpen,
  toggle,
  plan,
  contractUser,
  contract,
  onUpdate,
}) => {
  const buyAllowanceInsuranceReq = useFetch({
    action: activateAllowanceInsuranceClient,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data?.message || 'Something went wrong')
      } else {
        onUpdate?.startFetch({ contract_id: contract?.id })
        toggle()
      }
    },
    onError: (error) => {
      toastr.error(
        typeof error === 'string'
          ? error
          : typeof error?.message === 'string'
          ? error.message
          : 'An error occurred while adding allowance.',
      )
    },
  })

  const buyAllowanceInsurance = () => {
    let occurrenceId = 17 // 1st of every month
    if (new Date().getDate() < 15) {
      occurrenceId = 31 // 15th of every month
    }
    if (contract?.id && plan?.id) {
      buyAllowanceInsuranceReq.startFetch({
        contract_id: contract?.id,
        plan_id: plan?.id,
        occurrence_id: occurrenceId,
      })
    }
  }

  return (
    <Modal
      unmountOnClose
      centered
      isOpen={isOpen}
      toggle={toggle}
      keyboard
      backdrop
    >
      <ModalHeader toggle={toggle}>Confirm allowance</ModalHeader>
      <ModalBody className='text-center'>
        <Card className='p-4 mb-4 border border-1'>
          <div className='d-flex justify-content-between'>
            <span className='text-muted font-size-14'>Option</span>
            <span className='rp-font-bold font-size-16 text-primary'>
              The Allowance {plan.name}
            </span>
          </div>
        </Card>

        <Card className='p-4 mb-0 border border-1'>
          <div className='d-flex justify-content-between'>
            <div className='d-flex flex-column align-items-start'>
              <i className='bx bx-user-circle font-size-24' />
              <span className='font-size-14'>Contractor</span>
              <span className='font-size-14 rp-font-semibold'>
                {contractUser?.full_name}
              </span>
            </div>
            <div className='d-flex flex-column align-items-start'>
              <i className='bx bx-dollar-circle font-size-24' />
              <span className='font-size-14'>Cost</span>
              <span className='font-size-14 rp-font-semibold'>
                {plan.currency.symbol} {plan.amount}
              </span>
            </div>
            <div className='d-flex flex-column align-items-start'>
              <i className='bx bx-calendar font-size-24' />
              <span className='font-size-14'>Occurrence</span>
              <span className='font-size-14 rp-font-semibold'>Every 15th</span>
            </div>
          </div>

          <hr />

          <div className='font-size-14 text-left'>
            Contractors will have the right to use this allowance for whatever
            purpose they wish. This includes, but is not limited to, housing,
            transportation, health insurance or more.
          </div>
        </Card>
      </ModalBody>

      <ModalFooter>
        <Button
          onClick={buyAllowanceInsurance}
          color='primary'
          type='button'
          className='w-100'
          disabled={buyAllowanceInsuranceReq.isLoading}
        >
          {buyAllowanceInsuranceReq.isLoading ? (
            <i className='bx bx-loader bx-spin align-middle mr-2' />
          ) : (
            <span className='d-flex align-items-center'>
              <i className='bx bx-check font-size-24' /> Add Allowance
            </span>
          )}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default InsurancePlans
