import React from 'react'
import { Col, Form, FormGroup, Label, Row } from 'reactstrap'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import ControlledInput from '../../../components/ControlledInput'

const schema = yup.object().shape({
  account_id: yup.string().required(),
  consumer_key: yup.string().required(),
  consumer_secret: yup.string().required(),
  token_id: yup.string().required(),
  token_secret: yup.string().required(),
})
const ConnectNetSuiteForm = ({ onSubmitted }) => {
  const {
    handleSubmit,
    control,

    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {},
  })
  return (
    <div className='p-4'>
      <Form onSubmit={handleSubmit(onSubmitted)}>
        <Row>
          <Col md={12} sm={12} className='mb-3'>
            <FormGroup
              style={{ whiteSpace: 'nowrap' }}
              className='d-inline p-0 m-0 '
            >
              <Label className='col-form-label p-0 m-0 mb-2 font-size-14 '>
                Account ID
                <span className='text-danger font-size-16 mx-1'>*</span>
              </Label>
              <ControlledInput
                control={control}
                error={errors.account_id}
                name='account_id'
              />
            </FormGroup>
          </Col>
          <Col md={12} sm={12} className='mb-3'>
            <FormGroup
              style={{ whiteSpace: 'nowrap' }}
              className='d-inline p-0 m-0 '
            >
              <Label className='col-form-label p-0 m-0 mb-2 font-size-14 '>
                Consumer Key
                <span className='text-danger font-size-16 mx-1'>*</span>
              </Label>
              <ControlledInput
                control={control}
                error={errors.consumer_key}
                name='consumer_key'
              />
            </FormGroup>
          </Col>
          <Col md={12} sm={12} className='mb-3'>
            <FormGroup
              style={{ whiteSpace: 'nowrap' }}
              className='d-inline p-0 m-0 '
            >
              <Label className='col-form-label p-0 m-0 mb-2 font-size-14 '>
                Consumer Secret
                <span className='text-danger font-size-16 mx-1'>*</span>
              </Label>
              <ControlledInput
                control={control}
                error={errors.consumer_secret}
                name='consumer_secret'
              />
            </FormGroup>
          </Col>
          <Col md={12} sm={12} className='mb-3'>
            <FormGroup
              style={{ whiteSpace: 'nowrap' }}
              className='d-inline p-0 m-0 '
            >
              <Label className='col-form-label p-0 m-0 mb-2 font-size-14 '>
                Token ID<span className='text-danger font-size-16 mx-1'>*</span>
              </Label>
              <ControlledInput
                control={control}
                error={errors.token_id}
                name='token_id'
              />
            </FormGroup>
          </Col>
          <Col md={12} sm={12} className='mb-3'>
            <FormGroup
              style={{ whiteSpace: 'nowrap' }}
              className='d-inline p-0 m-0 '
            >
              <Label className='col-form-label p-0 m-0 mb-2 font-size-14 '>
                Token Secret
                <span className='text-danger font-size-16 mx-1'>*</span>
              </Label>
              <ControlledInput
                control={control}
                error={errors.token_secret}
                name='token_secret'
              />
            </FormGroup>
          </Col>
        </Row>
        <button
          className='d-none'
          id='submit-netsuite-account-connect'
          type='submit'
        />
      </Form>
    </div>
  )
}

ConnectNetSuiteForm.propTypes = {
  onSubmitted: BarProp.func,
}

export default ConnectNetSuiteForm
