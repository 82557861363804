import React from 'react'
import { Col, Container, Row, Spinner } from 'reactstrap'

import NoContent from '../../components/NoContent'
import { StyledH3 } from '../../components/Typo'
import { useFetch } from '../../helpers/hooks'
import { getContractors } from '../../services/api'
import { DocsList } from './index'
import emptyDocImage from './../../assets/images/empty_docs.svg'

const ClientDocs = () => {
  const docs = useFetch({
    autoFetch: true,
    initResult: [],
    action: getContractors,
  })

  return (
    <div className='page-content'>
      <Col xs={12} md={12} className={'p-0 m-0'}>
        <StyledH3
          min={'22px'}
          max={'32px'}
          style={{ fontFamily: 'gilroyB' }}
          className={'text-dark   mb-3 mb-md-4'}
        >
          Documents
        </StyledH3>
      </Col>

      {docs.isLoading ? (
        <Col style={{ minHeight: '30rem' }}>
          <Row
            style={{ minHeight: '30rem' }}
            className='justify-content-center align-items-center'
          >
            <Spinner type='grow' className='mr-2' color='primary' />
          </Row>
        </Col>
      ) : (
        <Container fluid className='m-0 p-0'>
          {docs.data?.length === 0 ? (
            <NoContent
              title=''
              headline='No documents added'
              subtitle={
                'All compliance documents will be shown here when added by contractors'
              }
              image={emptyDocImage}
            />
          ) : (
            <Row className='p-0 m-0'>
              <Col xs='12' className='p-0 m-0'>
                <Row className='mb-2'>
                  <Col sm='4'></Col>
                  <Col sm='8'></Col>
                </Row>
                <DocsList docs={docs.data} />
              </Col>
            </Row>
          )}
        </Container>
      )}
    </div>
  )
}

export default ClientDocs
