import * as yup from 'yup'

const ADDRESS_1_MAX_LENGTH = 35
const ADDRESS_2_MAX_LENGTH = 35
const STATE_MAX_LENGTH = 20

export const commonAddressSchema = yup.object().shape({
  address_1: yup
    .string()
    .max(
      ADDRESS_1_MAX_LENGTH,
      `The address line 1 should be less than ${ADDRESS_1_MAX_LENGTH} characters`,
    )
    .required(),
  address_2: yup
    .string()
    .max(
      ADDRESS_2_MAX_LENGTH,
      `The address line 2 should be less than ${ADDRESS_2_MAX_LENGTH} characters`,
    )
    .required(),
  city: yup.string().required('The city field is required'),
  state: yup
    .string()
    .max(
      STATE_MAX_LENGTH,
      `The state should be less than ${STATE_MAX_LENGTH} characters`,
    )
    .required('The state field is required'),

  country: yup.object().required('The country field is required'),
  zipcode: yup.string().required('The zipcode field is required'),
})
