import cx from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'

import Trash from '../../../assets/images/Trash.svg'
import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import Toggle from '../../../components/Forms/Toggle/Toggle'
import StyledTd from '../../../components/Table/StyledTd'
import StyledTh from '../../../components/Table/StyledTh'
import TableComp from '../../../components/Table/TableComp'
import { StyledH5 } from '../../../components/Typo'
import { useFetch, useResize } from '../../../helpers/hooks'
import {
  addRecurringExpense,
  deleteRecurringExpense,
  updateRecurringExpense,
} from '../../../services/api'
import ExpenseModal from '../components/ExpensesModal'
import useRecurringExpenses from '../hooks/use-recurring-expenses'

const RecurringExpenses = ({ isAdmin, isVisible }) => {
  const contract = useSelector((state) => state.Contract?.details)
  const [show, setShow] = useState(false)

  const list = useRecurringExpenses({ isAdmin, contract })

  const create = useFetch({
    action: addRecurringExpense,
    onError: toastr.error,
    onComplete: () => list.startFetch({ contract_id: contract?.id }),
  })

  useEffect(() => {
    if (isVisible) {
      list.startFetch({ contract_id: contract?.id })
    }
  }, [isVisible])

  const isMobile = useResize()

  return (
    <div
      className={cx('py-4', {
        'border-bottom': !list.data?.length || isMobile,
      })}
    >
      <div
        className='d-flex flex-wrap justify-content-between px-4'
        style={{ gap: '1rem' }}
      >
        <div>
          <StyledH5 className='font-size-16 font-weight-bold mb-3'>
            Recurring expenses
          </StyledH5>
          <p className='text-muted mb-0'>
            Set up and manage recurring expenses for this contract
          </p>
        </div>
        {!isAdmin && (
          <div className={cx({ 'w-100': isMobile })}>
            <button
              onClick={() => setShow(true)}
              className={`btn btn-primary ${isMobile ? 'btn-block' : ''}`}
            >
              Add new
            </button>
          </div>
        )}
      </div>
      {!!list.data?.length && !isMobile && (
        <TableComp className='mt-3 mt-md-4'>
          <thead className='thead-light'>
            <tr
              style={{
                borderTop: 'hidden',
                borderBottom: '1px solid #E7E8F2',
              }}
            >
              <StyledTh>Expense</StyledTh>
              <StyledTh>Category</StyledTh>
              <StyledTh>Start Date</StyledTh>
              <StyledTh>Occurrence</StyledTh>
              <StyledTh>Amount</StyledTh>
              <StyledTh>Status</StyledTh>
            </tr>
          </thead>
          <tbody>
            {list.data?.map((item, key) => {
              return (
                <>
                  <RecurringExpLine
                    key={'_order_' + key}
                    item={item}
                    isAdmin={isAdmin}
                    onUpdate={() =>
                      list.startFetch({ contract_id: contract?.id })
                    }
                  />
                </>
              )
            })}
          </tbody>
        </TableComp>
      )}
      {!!list.data?.length && isMobile && (
        <div className='px-4 w-100 mt-4'>
          {list.data?.map((item) => {
            return (
              <RecurringExpLine
                key={item.id}
                item={item}
                onUpdate={() => list.startFetch({ contract_id: contract?.id })}
              />
            )
          })}
        </div>
      )}
      {show && (
        <ExpenseModal
          isRecurring
          show={show}
          hide={() => {
            setShow(false)
          }}
          onSubmit={(v) => {
            create.startFetch({
              ...v,
              contract_id: contract?.id,
            })
            setShow(false)
          }}
        />
      )}
    </div>
  )
}

const RecurringExpLine = ({ item, onUpdate, isAdmin }) => {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const isMobile = useResize()
  const remove = useFetch({
    action: deleteRecurringExpense,
    onError: toastr.error,
    onComplete: onUpdate,
  })
  const update = useFetch({
    action: updateRecurringExpense,
    onError: toastr.error,
    onComplete: () => {
      setShowConfirmation(false)
      onUpdate()
    },
  })
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: item?.currency?.code || 'USD',
  })
  const onChangeStatus = () => {
    setShowConfirmation(true)
  }
  return (
    <>
      {isMobile ? (
        <StyledCard className={'mb-3'}>
          <div className='p-3 border-bottom d-flex justify-content-between align-items-center w-100'>
            <div className='w-100'>
              <Row className='p-0 m-0 justify-content-between align-items-center'>
                <label className='align-items-center d-flex mb-0 cursor-pointer'>
                  <Toggle
                    disabled={isAdmin}
                    check={item?.status === 'active'}
                    change={onChangeStatus}
                    item
                  />
                  <span>
                    Expense is{' '}
                    {item?.status === 'active' ? 'active' : 'inactive'}
                  </span>
                </label>
                {item.can_delete && (
                  <div>
                    <a
                      onClick={() =>
                        remove.startFetch({
                          recurring_expense_id: item?.id,
                        })
                      }
                    >
                      {remove.isLoading ? (
                        <i className='bx bx-loader bx-spin text-danger' />
                      ) : (
                        <img src={Trash} alt={''} />
                      )}
                    </a>
                  </div>
                )}
              </Row>
            </div>
          </div>
          <div className='p-3'>
            <div
              className={
                ' mb-3 d-flex justify-content-between align-items-center'
              }
            >
              <p className={'mb-0 font-size-14 font-weight-light'}>Expense</p>
              <p className={'mb-0 font-size-14 font-weight-light'}>
                {item?.name}
              </p>
            </div>
            <div
              className={
                ' mb-3 d-flex justify-content-between align-items-center'
              }
            >
              <p className={'mb-0 font-size-14 font-weight-light'}>
                Occurrence
              </p>
              <p className={'mb-0 font-size-14 font-weight-light'}>
                {item?.occurrence?.name}
              </p>
            </div>
            <div
              className={
                ' mb-3 d-flex justify-content-between align-items-center'
              }
            >
              <p className={'mb-0 font-size-14 font-weight-light'}>
                Start Date
              </p>
              <p className={'mb-0 font-size-14 font-weight-light'}>
                {moment(item?.start_date * 1000).format('DD/MM/YYYY')}
              </p>
            </div>
            <div
              className={
                ' mb-3 d-flex justify-content-between align-items-center'
              }
            >
              <p className={'mb-0 font-size-14 font-weight-light'}>Amount</p>
              <p className={'mb-0 font-size-14 font-weight-light'}>
                {formatter.format(item?.amount)}
              </p>
            </div>
            <div
              className={'   d-flex justify-content-between align-items-center'}
            >
              <p className={'mb-0 font-size-14 font-weight-light'}>Category</p>
              <p className={'mb-0 font-size-14 font-weight-light'}>
                {item?.category?.name}
              </p>
            </div>
          </div>
        </StyledCard>
      ) : (
        <tr style={{ borderBottom: '1px solid #E7E8F2' }}>
          <StyledTd>{item?.name}</StyledTd>
          <StyledTd>{item?.category?.name}</StyledTd>
          <StyledTd>
            {moment(item?.start_date * 1000).format('DD/MM/YYYY')}
          </StyledTd>
          <StyledTd>{item?.occurrence?.name}</StyledTd>
          <StyledTd>{formatter.format(item?.amount)}</StyledTd>
          <StyledTd>
            <div className='w-100'>
              <Col>
                <Row>
                  <Toggle
                    disabled={isAdmin || item?.can_enable === false}
                    check={item?.status === 'active'}
                    change={onChangeStatus}
                  />
                </Row>
              </Col>
            </div>
          </StyledTd>
          <StyledTd>
            {item.can_delete && (
              <a
                onClick={() =>
                  remove.startFetch({
                    recurring_expense_id: item?.id,
                  })
                }
              >
                {remove.isLoading ? (
                  <i className='bx bx-loader bx-spin text-danger' />
                ) : (
                  <img src={Trash} alt={''} />
                )}
              </a>
            )}
          </StyledTd>
        </tr>
      )}
      <ConfirmationModal
        isOpen={showConfirmation}
        loading={update.isLoading}
        onConfirm={() => {
          update.startFetch({
            recurring_expense_id: item?.id,
            status: item?.status === 'active' ? 'inactive' : 'active',
          })
        }}
        toggle={() => setShowConfirmation(false)}
        message={
          item?.status === 'active' ? (
            <>
              <p>Are you sure you want to disable this recurring expense?</p>
              <p>
                {' '}
                If you disable, future expenses will not be created and current
                unpaid or processing expenses will not be impacted.
              </p>
            </>
          ) : (
            <>
              <p>Are you sure you want to enable this recurring expense?</p>
              <p>
                {' '}
                If you enable, future expenses will be created automatically
                according to the schedule set
              </p>
            </>
          )
        }
        title={'Recurring Expense'}
        caption={item?.status === 'active' ? 'Disable' : 'Enable'}
        buttonColor={item?.status === 'active' ? 'danger' : 'primary'}
      />
    </>
  )
}
RecurringExpenses.propTypes = {
  data: PropTypes.object,
}

const StyledCard = styled.div`
  background-color: #ffffff;
  border: 1px solid #e7e8f2;
  box-sizing: border-box;
  border-radius: 4px;
`

export default RecurringExpenses
