import React, { forwardRef, useState } from 'react'
import { Button, Col, FormGroup, Row } from 'reactstrap'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import CurrencyInput from 'react-currency-input-field'
import toastr from 'toastr'

// eslint-disable-next-line react/display-name
const MilestonesForm = forwardRef(({ onSubmit }, ref) => {
  const staticData = useSelector((state) => state.Layout.staticData)
  const [milestones, setMilestones] = useState([{ currency_id: 1 }])
  const [currency, setCurrency] = useState({
    label: staticData?.currencies[0].code,
    value: staticData?.currencies[0].id,
    symbol: staticData?.currencies[0].symbol,
  })
  return (
    <Row>
      <Col>
        <AvForm
          ref={ref}
          onValidSubmit={(e, v) => {
            const testAmount =
              milestones?.filter((ml) => ml?.amount).length ===
              milestones?.length
            if (testAmount) {
              onSubmit({ ...v, currency_id: currency?.value }, milestones)
            } else {
              toastr.error('Amount field is required')
            }
          }}
        >
          <Row className='mb-3'>
            <Col md={4}>
              <CustomSelect
                type='select'
                name={'currency_id'}
                required
                value={currency}
                onChange={setCurrency}
                options={staticData?.currencies.map((e, i) => ({
                  label: e.code,
                  value: e.id,
                  symbol: e.symbol,
                }))}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {milestones.map((e, i) => (
                <Row
                  key={`milestone-${i}`}
                  className='justify-content-center flex-nowrap'
                >
                  <Col xs={7}>
                    <FormGroup>
                      <AvField
                        name={`name-${i}`}
                        placeholder='Milestone Name'
                        type='text'
                        required
                        defaultValue={e.name}
                        className='form-control'
                        onChange={(e) => {
                          const newMilestones = [...milestones]
                          newMilestones[i].name = e.target.value
                        }}
                        id='basicpill-pancard-input5'
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={3} className='p-0'>
                    <FormGroup>
                      <CurrencyInput
                        name={`amount-${i}`}
                        type='text'
                        onKeyPress={(e) => {
                          if (!/[0-9.]/.test(e.key)) {
                            e.preventDefault()
                          }
                        }}
                        required
                        placeholder='Amount'
                        id='basicpill-pancard-input7'
                        className='form-control'
                        defaultValue={e.amount}
                        onValueChange={(value) => {
                          const newMilestones = [...milestones]
                          newMilestones[i].amount = value
                        }}
                        // currency input props
                        allowDecimals={true}
                        decimalsLimit={2}
                        decimalSeparator='.'
                        groupSeparator=','
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={2} className='p-0 px-2 pt-2'>
                    {milestones.length > 1 && (
                      <Link
                        to='#'
                        onClick={() => {
                          setMilestones(
                            milestones.filter((_, index) => i !== index),
                          )
                        }}
                      >
                        <i
                          className='bx bx-trash text-danger font-size-24 mr-1'
                          id='deletetooltip'
                        />
                      </Link>
                    )}
                  </Col>
                </Row>
              ))}
              <Row className='mb-2'>
                <Col>
                  <Row>
                    <Col lg='4'>
                      <Button
                        color='primary'
                        outline
                        onClick={() => {
                          setMilestones([...milestones, {}])
                        }}
                      >
                        Add milestone
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </AvForm>
      </Col>
    </Row>
  )
})

export default MilestonesForm
