import cx from 'classnames'
import React from 'react'
import { Alert } from 'reactstrap'

export default function UsdSwiftMessage({ showMessage, className }) {
  return (
    <Alert
      color='info'
      className={cx(
        'mb-0 p-2 font-size-12 bg-soft-primary border border-soft-primary text-primary',
        className,
      )}
      isOpen={showMessage}
    >
      <UsdSwiftMessageContent />
    </Alert>
  )
}

export function UsdSwiftMessageContent() {
  return (
    <>
      USD Transfers to USD accounts outside of the United States might incur
      additional fees deducted by intermediary banks over which{' '}
      <strong>RemotePass has no control or visibility</strong>.{' '}
      <a
        href='https://help.remotepass.com/en/articles/6436265-usd-transfers-to-a-usd-denominated-bank-account-outside-usa'
        target='_blank'
        rel='noreferrer'
        className='alert-link'
      >
        More info
      </a>
    </>
  )
}
