import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TableComp from '../../../../components/Table/TableComp'
import StyledTh from '../../../../components/Table/StyledTh'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { updateContractor } from '../../../../store/xero/actions'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'
import { StyledH5, StyledH6 } from '../../../../components/Typo'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import MappingAccountModal from '../MappingAccountModal'
import XeroStatus from '../XeroStatus'
import { useResize } from '../../../../helpers/hooks'
import NoContent from '../../../../components/NoContent'
import classnames from 'classnames'
import { qbUpdateContractor } from '../../../../store/qb/actions'
import { freshUpdateContractor } from '../../../../store/fresh/actions'
import { updateZohoContractor } from '../../../../store/zoho/actions'
import { updateNetSuiteContractor } from '../../../../store/ns/actions'
import welcomeImage from './../../../../assets/images/welcome.svg'

const ContactsList = ({ defaultValues, history, platform = 'Xero' }) => {
  const contractors = useSelector((state) => {
    switch (platform) {
      case 'Xero':
        return state.xero?.contractors
      case 'Quickbooks':
        return state.qb?.contractors
      case 'Freshbooks':
        return state.fresh?.contractors
      case 'Zoho':
        return state.zoho?.contractors
      case 'NetSuite':
        return state.ns?.contractors
    }
  })
  const isMobile = useResize()

  return (
    <div>
      {!contractors?.length ? (
        <NoContent
          image={welcomeImage}
          subtitle={'Manage contracts'}
          title={''}
          actionTitle='New Contract'
          onAction={() => {
            history.push('/contract/create')
          }}
        />
      ) : (
        <>
          <TableComp stopResponsive={!isMobile}>
            <thead className='thead-light'>
              <tr>
                <StyledTh>Contractor</StyledTh>
                <StyledTh>Vendor</StyledTh>
                <StyledTh>Accounts</StyledTh>
                {!!defaultValues && <StyledTh>Status</StyledTh>}
              </tr>
            </thead>
            <tbody>
              {contractors?.map((e, i) => (
                <ContractorItem
                  key={`milestone-${i}`}
                  item={e}
                  isTable
                  withStatus={!!defaultValues}
                  platform={platform}
                />
              ))}
            </tbody>
          </TableComp>
        </>
      )}
    </div>
  )
}

const ContractorItem = ({ item, isTable, withStatus, platform }) => {
  const [showAccountModal, setShowAccountModal] = useState(false)
  const data = useSelector((state) => {
    switch (platform) {
      case 'Xero':
        return state.xero.preData
      case 'Quickbooks':
        return state.qb.preData
      case 'Freshbooks':
        return state.fresh.preData
      case 'Zoho':
        return state.zoho.preData
      case 'NetSuite':
        return state.ns.preData
    }
  })
  const dispatch = useDispatch()
  const onVendorChange = (element) => {
    switch (platform) {
      case 'Xero':
        dispatch(
          updateContractor({
            ...item,
            xero: element,
          }),
        )
        break
      case 'Quickbooks':
        dispatch(
          qbUpdateContractor({
            ...item,
            xero: element,
          }),
        )
        break
      case 'Freshbooks':
        dispatch(
          freshUpdateContractor({
            ...item,
            xero: { label: element.label, value: element.value.toString() },
          }),
        )
        break
      case 'Zoho':
        dispatch(
          updateZohoContractor({
            ...item,
            xero: element,
          }),
        )
        break
      case 'NetSuite':
        dispatch(
          updateNetSuiteContractor({
            ...item,
            xero: element,
          }),
        )
        break
    }
  }
  const contactOptions =
    data?.contacts?.map((c) => ({ label: c.name, value: c.account_id })) || []

  return isTable ? (
    <>
      <tr>
        <td className='py-3 px-4'>
          <Col xs={6} className='p-0 m-0'>
            <StyledH5 style={{ fontFamily: 'inter' }} min={'14px'} max={'14px'}>
              {item?.name}
            </StyledH5>
            <StyledH6 min={'10px'} max={'12px'} className='text-muted'>
              {item.email}
            </StyledH6>
          </Col>
        </td>
        <td className='py-3 px-4' style={{ minWidth: 238 }}>
          <CustomSelect
            placeholder='Create new'
            menuPosition={'fixed'}
            options={[{ label: 'Create New', value: null }, ...contactOptions]}
            onChange={onVendorChange}
            value={
              item.xero ||
              contactOptions.find(
                (e) =>
                  item.xero_id === e.value ||
                  item.qb_id === e.value ||
                  item.fresh_id === e.value.toString() ||
                  item.zoho_id === e.value.toString() ||
                  item.netsuite_id === e.value.toString(),
              ) || { label: 'Create New', value: null }
            }
          />
        </td>
        <td className='py-3 px-4'>
          <a className='text-primary' onClick={() => setShowAccountModal(true)}>
            Update
          </a>
        </td>
        {withStatus && (
          <td className='py-3 px-4 text-center'>
            {item.queued ? (
              <i
                className={classnames({
                  'fas fa-sync-alt mr-2': true,
                  'bx-spin': true,
                })}
              />
            ) : (
              <Col>
                <Row>
                  <XeroStatus status={item.status} />
                  <div style={{ position: 'relative' }}>
                    {!!item.error && (
                      <>
                        <i
                          className='bx bx-info-circle mx-2'
                          id={`xero-sync-contract-${item?.contractor_id}`}
                        />
                        <UncontrolledTooltip
                          placement='top'
                          target={`xero-sync-contract-${item?.contractor_id}`}
                        >
                          {item.error}
                        </UncontrolledTooltip>
                      </>
                    )}
                  </div>
                </Row>
              </Col>
            )}
          </td>
        )}
      </tr>
      {showAccountModal && (
        <MappingAccountModal
          toggle={() => setShowAccountModal(false)}
          isOpen={showAccountModal}
          contractor={item}
          platform={platform}
        />
      )}
    </>
  ) : (
    <StyledCard className='p-3'>
      <Row className='justify-content-between p-0 m-0'>
        <Col className='p-0 m-0'>
          <h5>{item?.name}</h5>
          <p className='text-muted mb-0'>{item.email}</p>
        </Col>
        <div>
          <XeroStatus status={item.status} />
        </div>
      </Row>
    </StyledCard>
  )
}

ContactsList.propTypes = {
  defaultValues: PropTypes.object,
}

const StyledCard = styled.div`
  margin-bottom: 24px;
  background: #ffffff;
  box-shadow: 0px 1px 0px #dfe1e6;
  border-radius: 4px;
`

export default ContactsList
