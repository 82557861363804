import React from 'react'
import CompanyInfo from '../CompanySetting/CompanyInfo'
import { Card, Col, Row, Container } from 'reactstrap'
import { useResize } from '../../helpers/hooks'
import ModalHeader from '../../components/ModalHeader'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'

const Entity = () => {
  const isMobile = useResize()
  const history = useHistory()
  const countries = useSelector((state) => state?.Layout?.staticData?.countries)
  return (
    <div className='page-content min-vh-100 p-0 m-0'>
      <Container fluid>
        <ModalHeader />
        <Row className='justify-content-center'>
          <Col lg={isMobile ? undefined : 6}>
            <Row className='justify-content-center my-3'>
              <h1 className='font-weight-bold'>Create Entity</h1>
            </Row>
            <Card>
              {!!countries?.length && (
                <CompanyInfo
                  isCompletion
                  onComplete={() => {
                    history.goBack()
                  }}
                  isCreation
                  countries={countries}
                />
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Entity
