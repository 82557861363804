import classnames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import {
  Badge,
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'

import bkblue from '../../assets/images/bkblue.svg'
import ChangePassword from '../../components/CommonForBoth/ChangePassword'
import { StyledH3 } from '../../components/Typo'
import WiseButton from '../../components/WiseButton'
import { updateMemberProfile } from '../../services/api'
import { updateUserInfo } from '../../store/auth/register/actions'
import capitalizeFirstLetter from '../../utils/capitalize-first-letter'
import ManageAvatar from '../CompanySetting/manage-avatar'
import Notifications from './Notifications'
import ProfileInfo from './ProfileInfo'

const ProfileSettings = () => {
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState(0)
  const [loading, setLoading] = useState(false)
  const formRef = useRef(null)
  const user = useSelector((state) => state?.Account?.user)
  const [, setData] = useState({})

  const { hash } = useLocation()

  useEffect(() => {
    switch (hash) {
      case '#security': {
        setActiveTab(1)
        break
      }
    }
  }, [hash])

  const updatePhoto = (dataInfo) => {
    setLoading(true)
    try {
      updateMemberProfile(user?.token, dataInfo)
        .then((r) => {
          setLoading(false)
          if (r.data.success) {
            setData(r.data.data)
            dispatch(updateUserInfo(r.data?.data || {}))
          } else {
            toastr.error(r.data.data?.error || r.data?.message)
          }
        })
        .catch((e) => {
          setLoading(false)
        })
    } catch (e) {
      setLoading(false)
    }
  }

  return (
    <StyledContainer className='page-content position-relative m-0'>
      <StyledGap>
        <StyledImgGap bk={bkblue} />
      </StyledGap>
      <Row className='p-0 m-0'>
        <Col md={3} className='pr-md-3 p-0 m-0'>
          <StyledCol className='p-0 m-0 d-flex flex-column align-items-center'>
            <ManageAvatar
              src={user?.photo}
              name={user?.first_name}
              onUploadPhoto={(acceptedFiles) => {
                const file = acceptedFiles[0]
                if (file.size > 2000000) {
                  toastr.error('The file may not be greater than 2MB')
                } else {
                  const dataInfo = { photo: acceptedFiles[0] }
                  updatePhoto(dataInfo)
                }
              }}
              isUploading={loading}
              avatarTitleClassName={
                user?.type === 'client' ? 'avatar-title-green' : 'avatar-title'
              }
            />
            <StyledH3
              min={'22px'}
              mid={'1.25em'}
              max={'24px'}
              style={{ fontWeight: 600 }}
              className='px-2 text-center text-dark'
              translate='no'
            >
              {user?.first_name} {user?.last_name}
            </StyledH3>
            <Badge
              className='font-size-12 rounded bg-soft-self-badge mb-3'
              pill
            >
              <p style={{ padding: 2 }} className='mb-0 font-size-14'>
                {capitalizeFirstLetter(user?.type)}
              </p>
            </Badge>
            <div className='border-top  my-md-4 mb-3 mt-4  w-100' />
            <div className='px-3 px-md-4 mb-3 mb-md-4 w-100'>
              <Link to='/logout'>
                <WiseButton
                  text='Log out'
                  width='100%'
                  minWidth='100%'
                  bk='white'
                  textColor='dark'
                />
              </Link>
            </div>
          </StyledCol>
        </Col>
        <Col md={9}>
          <div className='checkout-tabs'>
            <Row>
              <Col className='p-0 m-0'>
                <Card className='overflow-hidden'>
                  <StyledNav tabs>
                    {['General Info', 'Security', 'Notification'].map(
                      (label, i) => (
                        <NavItem key={`prNav-${i}`}>
                          <NavLink
                            className={classnames('font-size-14', {
                              active: activeTab === i,
                            })}
                            onClick={() => setActiveTab(i)}
                          >
                            {label}
                          </NavLink>
                        </NavItem>
                      ),
                    )}
                  </StyledNav>

                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={0}>
                      <ProfileInfo
                        ref={formRef}
                        onStart={() => setLoading(true)}
                        onFinish={() => setLoading(false)}
                      />
                    </TabPane>
                  </TabContent>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={1}>
                      <ChangePassword />
                    </TabPane>
                  </TabContent>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={2}>
                      {activeTab === 2 && <Notifications />}
                    </TabPane>
                  </TabContent>
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </StyledContainer>
  )
}

const StyledNav = styled(Nav)`
  flex-wrap: nowrap !important;
  overflow-x: auto;
  overflow-y: hidden;

  .nav-item {
    padding-right: 20px;
    padding-left: 20px;

    .nav-link {
      font-weight: 400;
      color: #777f9e;
      padding: 24px 0;

      &:hover {
        border-right-color: #fff !important;
        border-left-color: #fff !important;
        border-top-color: #fff !important;
      }
    }

    .active {
      color: var(--primary);
      font-weight: 600;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 3px solid var(--primary);
    }
  }

  @media (max-width: 768px) {
    background-color: #f4f5f7;

    width: 100%;

    .nav-item {
      background-color: #f4f5f7;
      padding-right: 0;
      padding-left: 0;
      margin-right: 21px;

      .nav-link {
        font-weight: 400;
        color: #777f9e;
        width: max-content;
        background-color: #f4f5f7;
        padding: 0 0 8px 0;
        border-bottom: 2px solid #f4f5f7;
        margin: 16px 0;

        &:hover {
          border-right-color: #f4f5f7 !important;
          border-left-color: #f4f5f7 !important;
          border-top-color: #f4f5f7 !important;
        }
      }
      .active {
        border: 0;
        padding: 0 0 8px 0;

        margin: 16px 0;
        color: var(--primary);
        font-weight: bold;
        border-bottom: 2px solid var(--primary);
      }
    }
  }
`

const StyledContainer = styled.div`
  margin-top: 32px !important;
`

const StyledImgGap = styled.img`
  width: 100%;
  background-color: #fff;
  height: 210px;
  background-image: ${(props) => (props.bk ? `url(${props.bk})` : null)};
  background-repeat: repeat;
`
const StyledGap = styled.div`
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  background-color: #114ef7;
  width: 100%;
  height: 19%;
  @media (max-width: 768px) {
    height: 10%;
    top: 22px;
  }
`

const StyledCol = styled.div`
  margin: 0;
  background: #ffffff;
  box-shadow: 0px 1px 0px #dfe1e6;
  border-radius: 4px;
  height: fit-content;
  z-index: 2;
`

export default ProfileSettings
