import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap'

const TagSelector = ({ onSelect, placeholders }) => {
  const [col1, setCol1] = useState([0])
  const defaultPlaceholders = useSelector(
    (state) => state?.Layout?.staticData?.template_tags,
  )

  const formattedPlaceholders = placeholders?.map((group) => {
    return { ...group, tags: group?.list }
  })

  return (
    <div id='accordion'>
      {(formattedPlaceholders ?? defaultPlaceholders)?.map((group, key) => (
        <Card key={`group-${group?.title}`} className='m-0 p-0'>
          <CardHeader
            className='border px-4 py-3 bg-white text-dark cursor-pointer hover:bg-primary-10 d-flex justify-content-between align-items-center'
            id={`heading-${key}`}
            onClick={() => {
              if (col1.includes(key)) {
                setCol1((prev) => prev.filter((e) => e !== key))
              } else {
                setCol1((prev) => [...prev, key])
              }
            }}
          >
            <h6 className='m-0'>{group?.title}</h6>
            <i
              className={`bx bxs-${
                col1.includes(key) ? 'up' : 'down'
              }-arrow font-size-10`}
            />
          </CardHeader>

          <Collapse isOpen={col1.includes(key)}>
            <Card className='m-0'>
              <CardBody>
                {group?.tags.map((tag, key) => (
                  <label
                    key={`tags-${tag}`}
                    onClick={() => onSelect(tag, key)}
                    style={{ marginRight: '5px' }}
                    className='tag'
                  >
                    {tag}
                  </label>
                ))}
              </CardBody>
            </Card>
          </Collapse>
        </Card>
      ))}
    </div>
  )
}

export default TagSelector
