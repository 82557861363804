import React from 'react'
import * as yup from 'yup'

import { FormContentRenderer } from '../components/form-content-renderer'

const EDD_NAME_MAX_LENGTH = 50

export const eddFields = [
  {
    label: 'Full name as shown on the ID document',
    name: 'edd_id_full_name',
    type: 'text',
    rules: {
      required: 'This field is required',
      max: EDD_NAME_MAX_LENGTH,
      maxMessage: `This field should be at most ${EDD_NAME_MAX_LENGTH} characters`,
    },
  },
  {
    label: 'Contact Number',
    name: 'edd_contactNumber',
    type: 'tel',
    rules: { required: 'This field is required' },
    disabled: true,
  },
  {
    label: 'Source of Wealth',
    description: ['Check all that apply'],
    name: 'edd_sourceOfWealth',
    rules: { required: 'This field is required' },
    type: 'select',
    isMulti: true,
    otherOption: true,
    disabled: true,
    options: [
      { label: 'Employment', value: 'employment' },
      { label: 'Investment', value: 'investment' },
      { label: 'Business', value: 'business' },
      { label: 'Ownership', value: 'ownership' },
      { label: 'Inheritance/Gift', value: 'inheritance' },
    ],
  },
  {
    label: 'Current Position',
    name: 'edd_employment',
    type: 'text',
    rules: { required: 'This field is required' },
  },
  {
    label: 'Current client/employer',
    name: 'edd_companyName',
    type: 'text',
    disabled: true,
    rules: { required: 'This field is required' },
  },
  {
    label: 'Client/employer sector',
    description: ['Mark only one option'],
    name: 'edd_companyNature',
    type: 'radio',
    rules: { required: 'This field is required' },
    otherOption: true,
    options: [
      { label: 'Money Changing and remittance services', value: 'money' },
      { label: 'Casinos and casino junkets', value: 'casinos' },
      { label: 'Dealing with virtual and crypto Currencies', value: 'crypto' },
      { label: 'Banking and finance', value: 'banking' },
      { label: 'Pawnbrokers', value: 'pawnbrokers' },
      { label: 'Payment service providers', value: 'payment' },
      { label: 'Government related organizations', value: 'government' },
      { label: 'Arms, defense military related', value: 'arms' },
      { label: 'Dealing with precious metals and stones', value: 'precious' },
      {
        label: 'Non-profit organizations such as charities and foundations',
        value: 'non-profit',
      },
      { label: 'Non-Government Organizations (NGOs)', value: 'non-government' },
      {
        label:
          'Art/antique dealers, auction houses or dealers and traders in high value goods',
        value: 'art',
      },
      { label: 'Medical and healthcare Services', value: 'medical' },
      { label: 'Pharmaceutical', value: 'pharmaceutical' },
      { label: 'Education services', value: 'education' },
      { label: 'Technology and cybersecurity services', value: 'technology' },
    ],
  },
  {
    label:
      'Are you a PEP (Politically Exposed Person) or a close associate of a PEP?',
    description: [
      'PEP refers to a person who is holding a prominent public function or role such as the head of state or government, government ministers, senior civil or public servants, senior judicial or military officials, senior executives of state-owned corporations, senior political party officials, members of the legislature and senior management of international organizations.',
      'Mark only one option',
    ],
    name: 'edd_pep',
    type: 'radio',
    rules: { required: 'This field is required' },
    options: [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ],
  },
]

export function addEddValidation(baseSchema) {
  let newSchema = baseSchema ?? yup.object()

  eddFields.forEach((field) => {
    function getSelectYupValidation(field) {
      const selectSchema = yup.string().required(field.rules.required)

      return field?.isMulti
        ? yup.array(selectSchema).required(field.rules.required)
        : selectSchema
    }

    if (field?.rules?.required) {
      const fieldSchema =
        field.type === 'select'
          ? getSelectYupValidation(field)
          : field.rules?.max
          ? yup
              .string()
              .max(field.rules.max, field.rules.maxMessage)
              .required(field.rules.required)
          : yup.string().required(field.rules.required)

      newSchema = newSchema.concat(
        yup.object().shape({ [field.name]: fieldSchema }),
      )
    }
  })

  return newSchema
}

function fullName(user) {
  return [user?.first_name, user?.middle_name, user?.last_name]
    .filter(Boolean)
    .join(' ')
}

const defaultSourceOfWealth = 'employment'
const defaultCompanyNature = 'technology'

export function getDefaultEddValues(user) {
  return {
    edd_id_full_name: fullName(user),
    edd_contactNumber: '+' + user?.phone,
    edd_sourceOfWealth: [defaultSourceOfWealth],
    edd_companyNature: defaultCompanyNature,
  }
}

const formFields = [{ title: '', fields: eddFields }]

export default function CardEdd({ control, errors }) {
  return (
    <FormContentRenderer
      formFields={formFields}
      isLoading={false}
      cols={false}
      control={control}
      errors={errors}
    />
  )
}
