import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useSelector } from 'react-redux'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'
import toastr from 'toastr'

import Avatar from '../../../components/Avatar'
import BadgeX from '../../../components/Table/BadgeX'
import { StyledH5, StyledH6 } from '../../../components/Typo'
import WiseButton from '../../../components/WiseButton'
import { CONTRACT_TYPES } from '../../../core/config/contract-types'
import { contractorTypes, userTypes } from '../../../helpers/enum'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import { resendContractInvite } from '../../../services/api'
import SelectSignatoryDropdown from './SelectSignatoryDropdown'
import InvitationHistoryModal from './components/InvitationHistoryModal'

const CardContact = ({ label, isKyc, isContractor, user, contract }) => {
  const isDeContract = contract?.type === CONTRACT_TYPES.DIRECT_EMPLOYEE

  return (
    <Row className='p-3 p-md-4 m-0 align-items-center d-flex flex-nowrap'>
      <Avatar
        isLarge
        name={user?.first_name || user?.email}
        photo={user?.photo}
        label={label}
      />
      <Col className='align-items-center p-0 m-0 ml-3 ml-md-4'>
        <StyledH5 min='14px' mid='14px' max='16px' className='text-dark'>
          {user?.first_name && user?.last_name
            ? user?.full_name ?? `${user?.first_name} ${user?.last_name}`
            : 'Invitation pending'}
          {isContractor && (
            <>
              <i
                id='kyc_v'
                className={`bx ${
                  isKyc
                    ? 'bxs-check-shield text-primary'
                    : 'bxs-shield-x text-secondary'
                } position-absolute ml-1 font-size-20`}
              />
              <UncontrolledTooltip placement='top' target='kyc_v'>
                {isKyc
                  ? 'This contractor has completed the KYC verification process'
                  : 'This contractor has not completed the KYC verification process'}
              </UncontrolledTooltip>
            </>
          )}
        </StyledH5>
        <Row className='ml-0 d-flex flex-column '>
          <StyledH6
            min='12px'
            mid='13px'
            max='14px'
            className='text-secondary mb-3 font-weight-light p-0'
          >
            {user?.email}
          </StyledH6>
          <div className='d-flex gap-8'>
            <BadgeX status='secondary'>{label}</BadgeX>

            {!isDeContract && isContractor && user?.type && (
              <BadgeX status='secondary'>
                {user?.type === contractorTypes.ENTITY
                  ? 'Entity'
                  : 'Individual'}
              </BadgeX>
            )}
          </div>
        </Row>
      </Col>
    </Row>
  )
}

export const ActionButton = ({
  isAdmin,
  onInvite,
  onSign,
  onCancel,
  isLoading,
  type,
  data,
  clientSigned,
  contractorSigned,
  employeeInvitation,
  onUpdateContract,
  onEmployeeInvite,
}) => {
  const user = useSelector((state) => state?.Account?.user)
  const { hasAccess } = usePermissions()
  const resend = useFetch({
    action: resendContractInvite,
    onComplete: () => {
      toastr.success('Invitation send successfully')
    },
  })

  const [showInvitationHistory, setShowInvitationHistory] = useState(false)
  const getOptions = () => {
    if (type === 'client') {
      if (!data?.contractor) {
        return {
          label: `Invite ${data?.contractor_name || 'Contractor'}`,
          color: 'primary',
          action: onInvite,
          disabled: !hasAccess(permissions.InviteToContracts),
        }
      } else if (
        !clientSigned &&
        user?.type === 'client' &&
        data?.signatory?.id === user?.id
      ) {
        return {
          label: 'Sign',
          color: 'primary',
          action: onSign,
          disabled: !hasAccess(permissions.SignContracts),
        }
      } else if (!clientSigned && user?.type === 'client') {
        return {
          label: 'Sign',
          color: 'primary',
          type: 'dropdown',
        }
      } else if (!clientSigned && user?.type !== 'client') {
        return data?.can_cancel_invitation && data?.type !== 'Full Time'
          ? {
              label: 'Cancel Invitation',
              color: 'danger-cancel',
              action: onCancel,
              disabled: !hasAccess(permissions.CancelInvitations),
            }
          : {
              label: data.created_by === 'client' ? 'Created' : 'Invited',
              color: 'success-disabled',
              action: () => {},
              disabled: true,
            }
      } else if (clientSigned) {
        return {
          label: 'Signed',
          color: 'success-disabled',
          action: () => {},
          disabled: true,
        }
      }
    }
    if (type === 'contractor') {
      if (!data?.client) {
        return {
          label: 'Invite Client',
          color: 'primary',
          action: onInvite,
          disabled: false,
        }
      } else if (!contractorSigned && user?.type === 'contractor') {
        return {
          label: 'Sign',
          color: 'primary',
          action: onSign,
          disabled: false,
        }
      } else if (!contractorSigned && user?.type !== 'contractor') {
        return data?.can_cancel_invitation && data?.type !== 'Full Time'
          ? {
              label: 'Cancel Invitation',
              color: 'danger-cancel',
              action: onCancel,
              disabled: !hasAccess(permissions.CancelInvitations),
            }
          : {
              label: data.created_by === 'contractor' ? 'Created' : 'Invited',
              invited: true,
              color: 'success-disabled',
              action: () => {},
              disabled: true,
            }
      } else if (
        data?.type === 'Full Time' &&
        data?.status?.id === 4 &&
        !data?.employee &&
        user?.type === userTypes.CONTRACTOR
      ) {
        return {
          label: 'Invite Employee',
          color: 'primary',
          action: onEmployeeInvite,
          disabled: false,
        }
      } else if (contractorSigned) {
        return {
          label: 'Signed',
          color: 'success-disabled',
          action: () => {},
          disabled: true,
        }
      }
    }
    if (type === 'employee') {
      if (!data?.employee_invited) {
        return {
          label: 'Invite',
          color: 'primary',
          action: onInvite,
          disabled: false,
        }
      } else {
        return {
          label: 'Invited',
          color: 'success-disabled',
          action: () => {},
          disabled: true,
        }
      }
    }
  }
  const buttonOptions = getOptions()
  return (
    <div className='d-flex flex-column m-0 w-100'>
      <>
        {buttonOptions.type === 'dropdown' ? (
          <SelectSignatoryDropdown
            onSign={onSign}
            contract={data}
            onCancel={onUpdateContract}
            onAssigned={onUpdateContract}
            isAdmin={isAdmin}
          />
        ) : (
          <WiseButton
            bold
            minWidth='100%'
            width='100%'
            height='auto'
            minHeight='auto'
            text={buttonOptions.label}
            loading={!!isLoading}
            bk={buttonOptions.color}
            textColor='white'
            className='btn-block  '
            onClick={buttonOptions.action}
            disabled={
              buttonOptions.disabled ||
              isAdmin ||
              [5, 6, 7].includes(data?.status?.id)
            }
          />
        )}
        {(!!data.invitation_url || employeeInvitation) &&
          ['contractor', 'employee'].includes(type) &&
          (user?.type === userTypes.COMPANY || isAdmin) && (
            <CopyToClipboard
              text={data.invitation_url || data.employee_invitation_url}
              onCopy={() => toastr.info('Link copied')}
            >
              <div className='text-primary cursor-pointer font-size-12 mt-1'>
                Copy Invitation Link
              </div>
            </CopyToClipboard>
          )}
        {isAdmin &&
          ((!contractorSigned && type === 'contractor' && !!data?.client) ||
            (!clientSigned && type === 'client' && !!data?.contractor) ||
            employeeInvitation) && (
            <button
              className='rp-btn-nostyle text-primary font-size-12 mt-1 p-0 text-left'
              onClick={() => setShowInvitationHistory(true)}
            >
              Email history
            </button>
          )}

        {isAdmin &&
          ((!contractorSigned && type === 'contractor' && !!data?.client) ||
            (!clientSigned && type === 'client' && !!data?.contractor)) && (
            <button
              className='rp-btn-nostyle text-primary font-size-12 mt-1 p-0 text-left'
              onClick={() => {
                resend.startFetch(
                  type === 'client'
                    ? { contract_id: data?.id, destination: 'client' }
                    : { contract_id: data?.id },
                )
              }}
            >
              Resend Invitation
            </button>
          )}
      </>
      {showInvitationHistory && (
        <InvitationHistoryModal
          user={type === 'client' ? data?.client : data?.contractor}
          show={showInvitationHistory}
          hide={() => setShowInvitationHistory(false)}
        />
      )}
    </div>
  )
}

export default CardContact
