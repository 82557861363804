import React from 'react'
import { useHistory } from 'react-router'
import { Col, Row } from 'reactstrap'

import { HeaderLogo } from '../Common/header-logo'

const ModalHeader = ({ children, noExit, action }) => {
  const history = useHistory()

  const onClick = () => {
    history.replace('/')
  }

  return (
    <Row className='justify-content-between flex-nowrap p-3 border-bottom border-light sticky-top bg-white'>
      <Col
        xs={2}
        className='align-items-center d-flex pl-0 pr-2 '
        style={{ height: 40 }}
      >
        <HeaderLogo />
      </Col>

      <Col className='d-flex justify-content-center px-0'>{children}</Col>

      {!noExit && (
        <Col
          xs={2}
          className='d-flex align-items-center justify-content-end px-0'
        >
          <button
            type='button'
            className='rp-btn-nostyle d-flex py-1'
            onClick={action || onClick}
          >
            <i className='bx bx bx-x font-weight-light font-size-20' />
          </button>
        </Col>
      )}
    </Row>
  )
}

export default ModalHeader
