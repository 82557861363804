import moment from 'moment'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Col, Row } from 'reactstrap'

import { useFetch } from '../../../helpers/hooks'
import {
  connectFreshBooks,
  connectQuickbooks,
  connectXero,
  connectZoho,
  disconnectXero,
  updateDataToBamboo,
  updateDataToHibob,
  updateDataToSapling,
} from '../../../services/api'
import { bambooRequest } from '../../../store/bamboo/actions'
import { HiBobRequest } from '../../../store/hiBob/actions'
import { SaplingRequest } from '../../../store/sapling/actions'

import xeroLogo from '../../../assets/images/xero.png'
import quickbooksLogo from '../../../assets/images/quickbooks.png'
import saplingLogo from '../../../assets/images/sapling.png'
import bobLogo from '../../../assets/images/bob.png'
import bambooLogo from '../../../assets/images/bamboo.png'
import freshbooksLogo from '../../../assets/images/freshbooks.png'
import zohoInvoiceLogo from '../../../assets/images/zoho_invoice.png'
import netsuiteLogo from '../../../assets/images/netsuite-on.svg'

function IntegrationCard({ onClick, item, onUpdate }) {
  const history = useHistory()
  const internalIntegration = item?.auth_type === 'internal'
  const saplingRequest = useSelector((state) => state.sapling.saplingRequest)
  const HiRequest = useSelector((state) => state.hiBob.request)
  const request = useSelector((state) => state.bamboo.request)

  const dispatch = useDispatch()

  const getIcon = (e) => {
    switch (e?.code) {
      case 'xero':
        return (
          <img
            className={'my-0 mx-auto mb-4 rounded-circle'}
            src={xeroLogo}
            height={63}
            width={63}
            alt={''}
          />
        )
      case 'quickbooks':
        return (
          <img
            className={'my-0 mx-auto mb-4 rounded-circle'}
            src={quickbooksLogo}
            height={63}
            width={63}
            alt={''}
          />
        )
      case 'sapling':
        return (
          <img
            className={'my-0 mx-auto mb-4 rounded-circle'}
            src={saplingLogo}
            height={63}
            width={63}
            alt={''}
          />
        )
      case 'hibob':
        return (
          <img
            className={'my-0 mx-auto mb-4 rounded-circle'}
            src={bobLogo}
            height={63}
            width={63}
            alt={''}
          />
        )
      case 'bamboohr':
        return (
          <img
            className={'my-0 mx-auto mb-4 rounded-circle'}
            src={bambooLogo}
            height={63}
            width={63}
            alt={''}
          />
        )
      case 'fresh':
        return (
          <img
            className={'my-0 mx-auto mb-4 rounded-circle'}
            src={freshbooksLogo}
            height={63}
            width={63}
            alt={''}
          />
        )
      case 'zoho':
        return (
          <img
            className={'my-0 mx-auto mb-4 rounded-circle'}
            src={zohoInvoiceLogo}
            height={63}
            width={63}
            alt={''}
          />
        )
      case 'netsuite':
        return (
          <img
            className={'my-0 mx-auto mb-4 rounded-circle'}
            src={netsuiteLogo}
            height={63}
            width={63}
            alt={''}
          />
        )
      default:
        return null
    }
  }

  const getDescription = (e) => {
    switch (e?.code) {
      case 'xero':
        return `The Xero integration saves time, reduces errors and improves efficiency by automatically synchronizing your company invoices with your company's ${e?.organisation_name} account.`
      case 'quickbooks':
        return `The Quickbooks integration saves time, reduces errors and improves efficiency by automatically synchronizing your company invoices with your company's ${e?.organisation_name} account.`
      case 'zoho':
        return `The Zoho Invoices integration saves time, reduces errors and improves efficiency by automatically synchronizing your company invoices with your company's ${e?.organisation_name} account.`
      case 'hibob':
        return 'One-click integration to automatically create new employees or contractors on Bob with all relevant data.'
      case 'bamboohr':
        return 'One-click integration to automatically create new employees or contractors on Bamboo HR with all relevant data.'
      case 'sapling':
        return 'One-click integration to automatically create new employees or contractors on Sapling HR with all relevant data.'
      case 'fresh':
        return `The FreshBooks integration saves time, reduces errors and improves efficiency by automatically synchronizing your company invoices with your company's ${e?.organisation_name} account.`
      case 'netsuite':
        return `The NetSuite integration saves time, reduces errors and improves efficiency by automatically synchronizing your company invoices with your company's ${e?.organisation_name} account.`
      default:
        return null
    }
  }

  const getConnectFunction = (e) => {
    switch (e?.code) {
      case 'xero':
        return connectXero
      case 'quickbooks':
        return connectQuickbooks
      case 'zoho':
        return connectZoho
      case 'hibob':
        return updateDataToHibob
      case 'fresh':
        return connectFreshBooks
      case 'sapling':
        return updateDataToSapling
      case 'bamboohr':
        return updateDataToBamboo
      case 'netsuite':
        return null
      default:
        return null
    }
  }

  const handleReview = (e) => {
    if (e?.code === 'sapling') {
      dispatch(
        SaplingRequest({ ...saplingRequest, session_id: item?.session_id }),
      )
      history.push('/integrations/update-sapling')
    } else if (e?.code === 'hibob') {
      dispatch(HiBobRequest({ ...HiRequest, session_id: item?.session_id }))
      history.push('/integrations/update-hibob')
    } else if (e?.code === 'bamboohr') {
      dispatch(bambooRequest({ ...request, session_id: item?.session_id }))
      history.push('/integrations/update-bamboo')
    }
  }

  const updateData = useFetch(
    {
      action: getConnectFunction(item),
      onComplete: onUpdate,
    },
    [],
  )

  const handleEnable = () => {
    if (item.synched === 1 && item?.code !== 'netsuite') {
      updateData.startFetch({ session_id: item?.session_id, connected: 1 })
    } else {
      history.push(`/integrations/${item?.code}`)
    }
  }

  const connect = useFetch(
    {
      action: getConnectFunction(item),
      onComplete: (data) => {
        if (data?.redirect_url) {
          window.location.replace(data?.redirect_url)
        }
      },
    },
    [],
  )

  const disconnect = useFetch(
    {
      action: disconnectXero,
      onComplete: onUpdate,
    },
    [],
  )

  const handleSynced1 = (item) => {
    if (item.synched === 1) {
      history.push(
        `/integrations/update-${
          item?.code === 'fresh' ? 'freshbooks' : item?.code
        }`,
      )
    } else {
      history.push(
        `/integrations/${item?.code === 'fresh' ? 'freshbooks' : item?.code}`,
      )
    }
  }

  return (
    <Col className={'p-0 mb-3'} md={6}>
      <div
        style={{
          marginLeft: 12,
          marginRight: 12,
          borderRadius: 4,
          border: '1px solid #E7E8F2',
          height: '100%',
        }}
      >
        {!!item.connected && (
          <Row
            style={{ position: 'absolute', right: 30, top: 18 }}
            className='align-items-center p-0 m-0'
          >
            <span
              style={{
                height: 10,
                width: 10,
                backgroundColor: 'lime',
                borderRadius: '50%',
              }}
            />
          </Row>
        )}
        <a onClick={onClick} style={{ height: '100%' }}>
          <div className='rounded-sm m-0 p-0' style={{ height: '100%' }}>
            <Row className='p-0 m-0 p-4 flex-column' style={{ height: '100%' }}>
              {getIcon(item)}
              <Col
                className='p-0 m-0 align-content-between'
                style={{ height: '100%' }}
              >
                <div>
                  <Row className='align-items-center p-0 m-0 mb-1'>
                    <h4
                      style={{ fontSize: 'gilroyB' }}
                      className={'font-size-24'}
                    >
                      {item?.name}
                    </h4>
                  </Row>
                  <div>
                    <p className='text-muted font-size-14'>
                      {getDescription(item)}
                    </p>
                  </div>
                </div>
              </Col>
              <div>
                <Row
                  className='m-0 p-0 align-items-center'
                  style={{ height: '100%' }}
                >
                  {/* eslint-disable-next-line no-constant-condition */}

                  <Row className='m-0 p-0 w-100 text-center '>
                    {item.connected ? (
                      <>
                        <Col className='m-0 p-0 pr-1'>
                          <button
                            onClick={() => {
                              if (
                                internalIntegration &&
                                item?.code !== 'netsuite'
                              ) {
                                handleReview(item)
                              } else {
                                handleSynced1(item)
                              }
                            }}
                            className='btn btn-outline-primary btn-block font-weight-bold'
                          >
                            {item.synched === 1
                              ? 'Review'
                              : internalIntegration
                              ? 'Review'
                              : 'Complete'}
                          </button>
                        </Col>
                        <Col className='m-0 p-0 pl-1'>
                          <button
                            onClick={() => {
                              if (
                                internalIntegration &&
                                item?.code !== 'netsuite'
                              ) {
                                if (!updateData.isLoading) {
                                  updateData.startFetch({
                                    session_id: item?.session_id,
                                    connected: 0,
                                  })
                                }
                              } else {
                                if (!disconnect.isLoading) {
                                  disconnect.startFetch({
                                    session_id: item?.session_id,
                                  })
                                }
                              }
                            }}
                            className='btn btn-outline-danger btn-block font-weight-bold'
                          >
                            {(updateData?.isLoading ||
                              disconnect.isLoading) && (
                              <i className='bx bx-loader bx-spin font-size-16 align-middle mr-2' />
                            )}
                            {internalIntegration ? 'Disable' : 'Disconnect'}
                          </button>
                        </Col>
                      </>
                    ) : (
                      <Col className='m-0 p-0'>
                        <button
                          onClick={() => {
                            if (internalIntegration) {
                              handleEnable()
                            } else {
                              if (!connect.isLoading) {
                                connect.startFetch()
                              }
                            }
                          }}
                          className='btn btn-primary btn-block'
                        >
                          {(updateData?.isLoading ||
                            connect.isLoading ||
                            disconnect.isLoading) && (
                            <i className='bx bx-loader bx-spin mr-2' />
                          )}
                          {internalIntegration ? 'Enable' : 'Connect'}
                        </button>
                      </Col>
                    )}
                  </Row>
                  {item.synched ? (
                    <p className=' mt-3 m-0 text-muted font-size-12'>
                      Last sync:{' '}
                      {moment(item.last_sync_date * 1000).format(
                        'MM/DD/YYYY, h:mm a',
                      )}
                    </p>
                  ) : (
                    <p className=' mt-3 m-0  font-size-12 text-white'>
                      Last sync:{' '}
                    </p>
                  )}
                </Row>
              </div>
            </Row>
          </div>
        </a>
      </div>
    </Col>
  )
}

export default IntegrationCard
