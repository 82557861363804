import cx from 'classnames'
import moment from 'moment'
import qs from 'query-string'
import React, { useEffect, useRef, useState } from 'react'
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Media,
  Modal,
  Row,
  Spinner,
  Table,
} from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'
import * as yup from 'yup'

import contractTypeIcon from '../../../assets/images/contractTypeIcon.svg'
import nextPayment from '../../../assets/images/nextPayment.svg'
import startDate from '../../../assets/images/startDate.svg'
import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import ControlledInput from '../../../components/ControlledInput'
import ControlledSelect from '../../../components/ControlledSelect'
import { StyledH5, StyledH6, StyledP } from '../../../components/Typo'
import ControlledRadioList from '../../../components/controlled-radio-list'
import Button from '../../../components/ui/button'
import { FlagIcon } from '../../../components/ui/flag-icon'
import { CONTRACT_TYPES } from '../../../core/config/contract-types'
import { userTypes } from '../../../helpers/enum'
import { useFetch, usePermissions, useResize } from '../../../helpers/hooks'
import { employmentTermOptions } from '../../../helpers/lists'
import permissions from '../../../helpers/permissions'
import {
  amendContract,
  assignContractToSignatory,
  cancelAmendContract,
  cancelContract,
  cancelFullTimeInvitation,
  cancelInvitation,
  deGetCountryJurisdictions,
  deSupportedCountries,
  downloadFullTimeOfferLetter,
  downloadContract as downloadPdf,
  downloadQuote,
  getTemplateList,
  inviteFullTimeEmployee,
  showContract,
  signContract,
  terminateContract,
  updateContract,
} from '../../../services/api'
import { updateContractRef } from '../../../store/profile/actions'
import { track } from '../../../utils/analytics'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import {
  getOptionFromList,
  mapCountryToOption,
  mapCurrencyToOption,
} from '../../../utils/map-to-option'
import { AddEquipmentModal } from '../ContractPage/Equipments'
import { FormCol } from '../CreateContract/components/form-col'
import LabelContent from '../CreateContract/components/label-content'
import NoticePeriodField from '../CreateContract/components/notice-period-field'
import ProbationPeriodField from '../CreateContract/components/probation-period-field'
import { YearlySalaryField } from '../CreateContract/components/yearly-salary-field'
import {
  FormDataProvider,
  dataKeys,
  useFormData,
} from '../CreateContract/utils/use-form-data-context'
import useTriggerField from '../CreateContract/utils/use-trigger-field'
import validateSchema from '../CreateContract/utils/validate-schema'
import AddMilestoneModal from '../components/AddMilestoneModal'
import AmendModal from '../components/AmendModal'
import AnnexForm from '../components/Forms/AnnexForm'
import ComplianceForm from '../components/Forms/ComplianceForm'
import FormsModal from '../components/Forms/FormsModal'
import InfoForm from '../components/Forms/InfoForm'
import PaymentForm from '../components/Forms/PaymentForm'
import KYCModal from '../components/KYCModal'
import RequiredBankAccountModal from '../components/RequiredBankAccountModal'
import SubmitMilestoneModal from '../components/SubmitMilestoneModal'
import SubmitWorkModal from '../components/SubmitWorkModal'
import TerminationModal from '../components/TerminationModal'
import DeCancelTermination from '../components/de-cancel-termination'
import DeTerminationModal from '../components/de-termination-modal'
import CardContact, { ActionButton } from './card-contact'
import FulltimeDetailBlocks from './components/FulltimeDetailBlocks'
import ModalInvitation from './components/ModalInvitation'

export const getStatusColor = (status) => {
  switch (status?.id) {
    case 4:
      return 'success'
    case 5:
      return 'danger'
    case 6:
      return 'danger'
    case 7:
      return 'danger'
    default:
      return 'warning'
  }
}
const annexesCols = [
  { label: 'Name' },
  { label: 'Created At', style: { textAlign: 'right', paddingRight: '50px' } },
]
const editedAnnexesCols = [
  { label: 'Name' },
  { label: 'Created At', style: { textAlign: 'right', paddingRight: '50px' } },
  { label: 'Action', style: { textAlign: 'right', paddingRight: '50px' } },
]

const ContractDetail = ({
  id,
  data,
  updateContractDetail = () => {},
  loading,
  onShowTerminatedModal = () => {},
  onShowEndedModal = () => {},
  isAdmin,
  isEmployee,
}) => {
  const [reports, setReports] = useState([
    { title: 'Contract Type', iconClass: contractTypeIcon, description: '--' },
    { title: 'Start Date', iconClass: startDate, description: '--' },
    { title: 'Payment Schedule', iconClass: nextPayment, description: '--' },
  ])
  const history = useHistory()
  const location = history.location
  const isMobile = useResize()
  const templates = useFetch({
    action: getTemplateList,
    autoFetch: !isAdmin,
  })

  const [showInvitePopup, setShowInvitePopup] = useState(false)
  const [showInviteEmployee, setShowInviteEmployee] = useState(false)
  const [kycRequired, setKycRequired] = useState(false)
  const [docuSignLoading, setDocuSignLoading] = useState(true)
  const [showSignModal, setShowSignModal] = useState(false)
  const [bankAccountRequired, setBankAccountRequired] = useState(false)
  const [showNeedApprovalModal, setShowNeedApprovalModal] = useState(false)
  const [showNeedKyb, setShowNeedKyb] = useState(false)

  const [, setFullName] = useState(null)
  const user = useSelector((state) => state.Account?.user)
  const loginToken = useSelector((state) => state.Login?.loginToken)
  const userProfile = useSelector((state) => state.userProfile?.userProfile)
  const dispatch = useDispatch()

  const [addMilestone, setAddMilestone] = useState(false)
  const [submitMilestone, setSubmitMilestone] = useState(false)
  const [submitWork, setSubmitWork] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [showTerminateConfirmation, setShowTerminateConfirmation] =
    useState(false)
  const [deTerminationOpen, setDeTerminationOpen] = useState(false)
  const [showEquipmentModal, setShowEquipmentModal] = useState(false)
  const [showNeedToCompleteProfile, setShowNeedToCompleteProfile] =
    useState(false)
  const iframeRef = useRef(null)
  const [editPayment, setEditPayment] = useState(false)
  const [editInfo, setEditInfo] = useState(false)
  const [editCompliance, setEditCompliance] = useState(false)
  const [addAnnex, setAddAnnex] = useState(false)
  const [canEdit, setCanEdit] = useState(false)
  const [canAmend, setCanAmend] = useState(false)
  const [cancelConfirmation, setCancelConfirmation] = useState(false)
  const [cancelAmendConfirmation, setCancelAmendConfirmation] = useState(false)
  const [amendConfirmation, setAmendConfirmation] = useState(false)
  const [amendData, setAmendData] = useState(null)
  const [showFulltimePayment, setShowFulltimePayment] = useState(false)
  const editInfoRef = useRef(null)
  const editPaymentRef = useRef(null)
  const editComplianceRef = useRef(null)
  const annexRef = useRef(null)
  const [isUploading, setIsUploading] = useState(false)
  const [isDownloadOpen, setIsDownloadOpen] = useState(false)
  const [menu, setMenu] = useState(false)
  const [loadingSignature, setLoadingSignature] = useState(false)
  const [defaultAnnexes, setDefaultAnnexes] = useState(
    data?.annexes ? data.annexes : [],
  )
  const [newAnnexes, setNewAnnexes] = useState([])
  const [annexesToRemove, setAnnexesToRemove] = useState([])
  useEffect(() => {
    setDefaultAnnexes(data?.annexes)
  }, [data])
  const cancel = useFetch({
    action: cancelInvitation,
    onComplete: () => updateContractDetail(false),
  })
  const cancelEmployee = useFetch({
    action: cancelFullTimeInvitation,
    onComplete: () => updateContractDetail(false),
  })
  const terminate = useFetch({
    action: terminateContract,
    onComplete: () => {
      updateContractDetail(false)
      // window.analytics.track('Confirmed terminate', { contract_id: data?.id })
    },
  })
  const show = useFetch({
    action: showContract,
    onComplete: (data) => {
      // const file = window.URL.createObjectURL(data)
      // setPdf(`${process.env.REACT_APP_API_HOST}/api/contract/show/${data?.ref}/${data?.contractToken}`)
    },
  })

  const update = useFetch({
    action: updateContract,
    onComplete: () => {
      setNewAnnexes([])
      setAnnexesToRemove([])
      updateContractDetail(false)

      setCanAmend(false)
      setCanEdit(false)
    },
  })
  const amend = useFetch({
    action: amendContract,
    onComplete: () => {
      updateContractDetail(false)
      setAmendData(null)
    },
  })

  const cancelAmend = useFetch({
    action: cancelAmendContract,
    onComplete: () => {
      updateContractDetail()
      setCancelAmendConfirmation(false)
    },
  })

  const { hasAccess } = usePermissions()

  useEffect(() => {
    updateContractDetail()
    setDefaultAnnexes(data?.annexes)
  }, [])

  const cancelFetch = useFetch({
    action: cancelContract,
    onComplete: () => {
      updateContractDetail()
    },
  })

  const inviteEmployee = useFetch({
    action: inviteFullTimeEmployee,
    onComplete: () => updateContractDetail(false),
    onError: (err) => {
      toastr.error(err)
    },
  })
  const handleCancelContractClick = () => {
    window.analytics.track('Clicked cancel', { contract_id: data?.id })
    cancelFetch.startFetch({
      contract_id: data?.id,
    })
  }

  const cancelInvite = () => {
    cancel.startFetch({ contract_id: data?.id })
  }
  const cancelFullTimeInvite = () => {
    cancelEmployee.startFetch({ contract_id: data?.id })
  }

  const handleTerminateContract = (amount) => {
    track('Clicked terminate', { contract_id: data?.id })

    if (data.type === CONTRACT_TYPES.FIXED && !amount) {
      toastr.error('Please enter the last payment amount')
      return
    }
    setShowTerminateConfirmation(false)
    const body = {
      contract_id: data.id,
      termination_date: moment(new Date()).format('YYYY-MM-DD'),
    }
    if (amount) {
      body.amount = amount
    }
    terminate.startFetch(body)
  }
  const handleDeleteAnnex = (anx) => {
    if (anx?.id) {
      const tmp1 = [...defaultAnnexes]
      const index = tmp1.findIndex((e) => e.id === anx.id)
      tmp1.splice(index, 1)
      setDefaultAnnexes(tmp1)
      setAnnexesToRemove([...annexesToRemove, anx?.id])
      setAmendData({
        ...amendData,
        contract_id: data?.id,
        annexes_removed: [...annexesToRemove, anx?.id],
        annexes: newAnnexes,
      })
    } else {
      const tmp2 = [...newAnnexes]
      const index = tmp2.findIndex((e) => e.path === anx.path)
      tmp2.splice(index, 1)
      setNewAnnexes(tmp2)
      setAmendData({
        ...amendData,
        contract_id: data?.id,
        annexes: tmp2,
      })
    }
  }
  const downloadContract = (ref, token) => {
    show.startFetch({ ref, contractToken: token })
  }

  useEffect(() => {
    const newData = { ...data }
    if (newData.ref && newData?.file && !isAdmin) {
      downloadContract(newData?.ref, newData?.file)
    }
    const getStatusCard = () => {
      switch (data?.status?.id) {
        case 6:
          return {
            title: 'Cancelled',
            color: 'danger',
            iconClass: nextPayment,
            description: '--',
          }
        case 7:
          return {
            title: 'Terminated',
            description: terminationDate,
            iconClass: nextPayment,
            color: 'danger',
          }
        case 5:
          return {
            title: 'Ended',
            description: endDate,
            iconClass: nextPayment,
            color: 'danger',
          }
        default:
          return {
            title: 'Next Payment',
            description: newData?.next_payment,
            color: 'primary',
            iconClass: nextPayment,
          }
      }
    }
    setReports([
      {
        title: 'Contract Type',
        iconClass: contractTypeIcon,
        description: newData?.type,
      },
      {
        title: 'Start Date',
        iconClass: startDate,
        description:
          newData?.type !== 'Milestones' ? newData?.start_date : '--',
      },
      getStatusCard(),
    ])
  }, [data])

  useEffect(() => {
    if (
      !showSignModal &&
      data?.ref &&
      data?.contractor?.first_name &&
      data?.client?.first_name
    ) {
      setDocuSignLoading(true)
    }
  }, [showSignModal])

  const handleDownloadContract = () => {
    track('Clicked on download contract', { 'contract-id': data.ref })

    setDownloading(true)
    downloadPdf(
      data?.ref,
      isAdmin ? loginToken : user?.token,
      data?.file,
      isAdmin,
    )
      .then((r) => {
        setDownloading(false)
        const url = window.URL.createObjectURL(new Blob([r.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${data?.ref}.pdf`) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
      .catch((e) => {
        setDownloading(false)
      })
  }
  const handleDownloadOfferLetter = () => {
    track('Clicked on download contract', { 'contract-id': data.ref })

    setDownloading(true)
    downloadFullTimeOfferLetter({ contract_id: data?.id }, user?.token)
      .then((r) => {
        setDownloading(false)
        const url = window.URL.createObjectURL(new Blob([r.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${data?.ref}-offer-letter.pdf`) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
      .catch((e) => {
        setDownloading(false)
      })
  }

  const [previewing, setPreviewing] = useState(false)

  const handlePreviewContract = () => {
    setPreviewing(true)
    downloadPdf(
      data?.ref,
      isAdmin ? loginToken : user?.token,
      data?.file,
      isAdmin,
    )
      .then((r) => {
        const file = new Blob([r.data], { type: 'application/pdf' })
        const url = URL.createObjectURL(file)
        window.open(url)
      })
      .finally(() => {
        setPreviewing(false)
      })
  }

  const handleDownloadQuote = () => {
    setDownloading(true)
    downloadQuote(user?.token, { contract_id: data?.id })
      .then((r) => {
        setDownloading(false)
        const url = window.URL.createObjectURL(new Blob([r.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `quotation-${data?.ref}.pdf`) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
      .catch((e) => {
        setDownloading(false)
      })
  }

  const terminationDate = data?.termination_date
  const endDate = data?.status?.id === 5 && data?.end_date

  const formatter = getCurrencyFormatter(
    amendData?.currency_id?.code || data?.currency?.code,
  )

  function createMarkup(text) {
    return { __html: text }
  }

  const handleSignClick = () => {
    setLoadingSignature(true)
    if (!data.signatory && user?.type === userTypes.COMPANY) {
      assignContractToSignatory(user?.token, {
        contract_id: data?.id,
        signatory_id: user?.id,
      })
        .then((r) => {
          if (r.data.success) {
            handleSignContract()
          }
        })
        .catch(() => {
          setLoadingSignature(false)
          toastr.error('Error while signing the contract')
        })
    } else {
      handleSignContract()
    }
  }

  const handleSignContract = () => {
    signContract(user?.token, {
      name: `${userProfile?.first_name || ''} ${
        userProfile?.middle_name ? userProfile?.middle_name + ' ' : ''
      }${userProfile?.last_name || ''}`,
      contract_id: data?.id,
    }).then((r) => {
      setLoadingSignature(false)
      if (r.data.success) {
        if (
          data?.type === CONTRACT_TYPES.FULL_TIME &&
          user?.type === userTypes?.COMPANY &&
          hasAccess(permissions.PrepareTransactions)
        ) {
          setShowFulltimePayment(true)
        }

        setShowSignModal(false)
      }
      updateContractDetail(false)
    })
  }

  const [deChanges, setDeChanges] = useState()

  const handleSaveAmendChanges = () => {
    if (deChanges) {
      const body = {
        contract_id: deChanges?.id,
        amount: deChanges?.amount,
        first_payment_date: deChanges?.first_payment_date,
        start_date: deChanges?.start_date,
        occurrence_id: deChanges?.occurrence_id ?? deChanges?.occurrence?.id,
        frequency_id:
          deChanges?.frequency_id ?? deChanges?.occurrence?.frequency_id,
        currency_id: deChanges?.currency_id ?? deChanges?.currency?.id,
        seniority: deChanges?.seniority,
        // is_offer_letter_included: true,
        employment_term: deChanges?.employment_term,
        jurisdiction_id: deChanges?.jurisdiction_id,
        name: deChanges?.name || deChanges?.title,
        department: deChanges?.department,
        probation_period: deChanges?.probation_period,
        notice_period: deChanges?.notice_period,
      }

      if (canEdit) {
        update.startFetch(body)
      } else if (canAmend) {
        console.log('TODO: amend de contract')
        // amend.startFetch(body)
      }
    } else {
      const body = {
        ...amendData,
        frequency_id: amendData?.frequency_id?.value,
        currency_id: amendData?.currency_id?.value,
        occurrence_id: amendData?.occurrence_id?.value,
        rate_id: amendData?.rate_id?.value,
      }
      delete body.currency
      if (canEdit) {
        update.startFetch(body)
      } else if (canAmend) {
        amend.startFetch(body)
      }
      setCanAmend(false)
      setCanEdit(false)
    }
  }

  const handleCancelAmendChanges = () => {
    setDeChanges(null)
    setAmendData(null)
    setCanAmend(false)
    setCanEdit(false)
    setDefaultAnnexes(data?.annexes)
    setNewAnnexes([])
    setAnnexesToRemove([])
    updateContractDetail(false)
  }
  const updateTabLink = (tab) => {
    let query = qs.parse(location.search)
    query = { ...query, tab }
    const searchString = qs.stringify(query)

    history.push({ search: searchString })
  }

  const handleKybProceed = () => {
    setShowNeedKyb(false)
    if (['rejected', 'pending'].includes(userProfile?.kyb_status)) {
      const redirect = encodeURI(location.pathname + location.search)
      const url = '/registration-document?redirect=' + redirect
      history.push(url)
    }
  }

  const showTerminateButton =
    data?.can_terminate &&
    (data?.type === CONTRACT_TYPES.FULL_TIME
      ? data?.status?.name?.toLowerCase() !== 'ongoing'
      : data?.type === CONTRACT_TYPES.DIRECT_EMPLOYEE
      ? data?.is_scheduled_terminated === 0
      : true)

  const showCancelTermination =
    data?.type === CONTRACT_TYPES.DIRECT_EMPLOYEE &&
    data?.is_scheduled_terminated === 1

  return loading ? (
    <Col style={{ minHeight: '30rem' }}>
      <Row
        style={{ minHeight: '30rem' }}
        className='  justify-content-center align-items-center m-0 p-0'
      >
        <Spinner type='grow' className='mr-2' color='primary' />
      </Row>
    </Col>
  ) : (
    <>
      <Row className='p-0 m-0'>
        <Col xs={12} md={12} xl={isEmployee ? 12 : 9} className='p-0 m-0 '>
          <Row className='p-0 m-0 '>
            {reports.map((report, key) => (
              <Col
                md='12'
                lg={4}
                className={`pr-md-0 m-0 p-0 ${
                  key === 2 ? 'pr-xl-4' : 'pr-lg-4'
                }`}
                key={'_col_' + key}
              >
                <Card
                  className='mini-stats-wid mb-3 mb-md-4'
                  style={{
                    backgroundColor: '#FFFFFF',
                    boxShadow: '0px 1px 0px #DFE1E6',
                    borderRadius: 4,
                  }}
                >
                  <CardBody className='p-0 m-0'>
                    <Media>
                      <Row className='m-0 p-0 w-100'>
                        <Col
                          md={12}
                          className='d-flex justify-content-center p-3 p-md-4 p-card-col m-0 border-bottom'
                        >
                          <span>
                            {key === 2 ? (
                              <img
                                src={report.iconClass}
                                alt={report.title}
                                style={{ marginRight: 12 }}
                                className='card-icon'
                              />
                            ) : (
                              <img
                                src={report.iconClass}
                                alt={report.title}
                                style={{ marginRight: 12 }}
                                className='card-icon'
                              />
                            )}
                          </span>
                          <Media body className='align-items-center d-flex'>
                            {key === 2 ? (
                              <StyledH6
                                min='14px'
                                mid='14px'
                                max='16px'
                                className={`font-weight-bold mb-0 text-${report.color}`}
                              >
                                {report.title}
                              </StyledH6>
                            ) : (
                              <StyledH6
                                min='14px'
                                mid='14px'
                                max='16px'
                                className='font-weight-bold text-dark mb-0'
                              >
                                {report.title}
                              </StyledH6>
                            )}
                          </Media>
                        </Col>
                        <Col
                          md={12}
                          className='d-flex p-3 p-md-4 p-card-col m-0'
                        >
                          {key === 2 ? (
                            <StyledH6
                              min='22px'
                              max='19px'
                              className={`mb-0 text-truncate text-${report.color}`}
                            >
                              {report.description}
                            </StyledH6>
                          ) : (
                            <StyledH6
                              min='22px'
                              max='19px'
                              className='mb-0 text-dark'
                            >
                              {report.description}
                            </StyledH6>
                          )}
                        </Col>
                      </Row>
                    </Media>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <Row className='p-0 m-0 mr-xl-4'>
            <Col className='p-0 m-0' style={{ backgroundColor: 'transparent' }}>
              {!!data?.client && (
                <Row
                  style={{
                    backgroundColor: '#FFFFFF',
                    boxShadow: '0px 1px 0px #DFE1E6',
                    borderRadius: 4,
                  }}
                  className='mb-md-4 mb-3 p-0 m-0 align-items-center'
                >
                  <Col md={9} className='p-0 m-0'>
                    <Row className='p-0 m-0 justify-content-between align-items-center'>
                      <CardContact
                        label='Client'
                        user={data?.signatory || data?.client}
                        key={'_client_' + data?.client?.id}
                      />
                      {data?.status?.id === 3 &&
                        data?.type === CONTRACT_TYPES.FULL_TIME && (
                          <Dropdown
                            isOpen={isDownloadOpen}
                            toggle={() => setIsDownloadOpen((t) => !t)}
                            className='dropdown d-inline-block'
                          >
                            <DropdownToggle
                              tag='button'
                              className='rp-btn-nostyle text-primary mx-2'
                            >
                              {!downloading ? null : (
                                <i className='bx bx-loader bx-spin mr-1' />
                              )}
                              Download
                            </DropdownToggle>
                            <DropdownMenu
                              className='dropdown-menu'
                              flip={false}
                            >
                              <DropdownItem onClick={handleDownloadContract}>
                                SOW
                              </DropdownItem>
                              <DropdownItem onClick={handleDownloadQuote}>
                                Quotation
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        )}
                    </Row>
                  </Col>
                  <div className='w-100 divider border-top d-block d-md-none' />
                  <Col
                    md={3}
                    className=' d-flex justify-content-end pr-md-4 p-3  p-md-0'
                  >
                    <ActionButton
                      type='client'
                      isAdmin={isAdmin}
                      data={data}
                      clientSigned={data?.client_signed}
                      contractorSigned={!!data?.contractor_signed}
                      onCancel={cancelInvite}
                      onInvite={() => {
                        if (
                          ['submitted', 'pending', 'rejected'].includes(
                            userProfile?.kyb_status,
                          )
                        ) {
                          setShowNeedKyb(true)
                        } else if (!userProfile?.approved) {
                          setShowNeedApprovalModal(true)
                        } else if (terminationDate) {
                          onShowTerminatedModal()
                        } else if (endDate) {
                          onShowEndedModal()
                        } else {
                          setShowInvitePopup(true)
                        }
                      }}
                      onSign={() => {
                        if (terminationDate) {
                          onShowTerminatedModal()
                        } else if (endDate) {
                          onShowEndedModal()
                        } else if (
                          ['submitted', 'pending', 'rejected'].includes(
                            userProfile?.kyb_status,
                          )
                        ) {
                          setShowNeedKyb(true)
                        } else if (data?.can_sign) {
                          setShowSignModal(true)
                        } else {
                          setShowNeedToCompleteProfile(true)
                        }
                      }}
                      onUpdateContract={() => updateContractDetail(false)}
                    />
                  </Col>
                </Row>
              )}
              {!!data?.contractor && (
                <Row
                  style={{
                    backgroundColor: '#FFFFFF',
                    boxShadow: '0px 1px 0px #DFE1E6',
                    borderRadius: 4,
                  }}
                  className='align-items-center p-0 m-0 mb-md-4 mb-3'
                >
                  <Col md={9} className='p-0 m-0'>
                    <CardContact
                      label={`${data?.contractor_name || 'Contractor'}`}
                      user={data?.contractor}
                      key={'_contractor_12' + data?.contractor?.id}
                      isContractor
                      contract={data}
                      isKyc={data?.contractor?.kyc_level_2 === 'Verified'}
                    />
                  </Col>
                  <div className='w-100 divider border-top d-block d-md-none' />
                  <Col
                    md={3}
                    className='  d-flex justify-content-end pr-md-4 p-3   p-md-0'
                  >
                    <ActionButton
                      isAdmin={isAdmin}
                      type='contractor'
                      data={data}
                      clientSigned={data?.client_signed}
                      contractorSigned={!!data?.contractor_signed}
                      onCancel={cancelInvite}
                      onInvite={() => {
                        if (terminationDate) {
                          onShowTerminatedModal()
                        } else if (endDate) {
                          onShowEndedModal()
                        } else {
                          setShowInvitePopup(true)
                        }
                      }}
                      onEmployeeInvite={() => {
                        setShowInviteEmployee(true)
                      }}
                      onSign={() => {
                        if (terminationDate) {
                          onShowTerminatedModal()
                        } else if (endDate) {
                          onShowEndedModal()
                        } else if (
                          data?.kyc === 1 &&
                          userProfile?.kyc_verified !== 1
                        ) {
                          setKycRequired(true)
                        } else if (data.can_sign) {
                          setShowSignModal(true)
                        } else {
                          setShowNeedToCompleteProfile(true)
                        }
                      }}
                      onUpdateContract={() => updateContractDetail(false)}
                      isLoading={cancel.isLoading}
                    />
                  </Col>
                </Row>
              )}
              {!!data?.employee &&
                data?.status?.id === 4 &&
                !!data?.employee_signed &&
                (user?.type === userTypes.CONTRACTOR ||
                  !!data?.employee_invited) && (
                  <Row
                    style={{
                      backgroundColor: '#FFFFFF',
                      boxShadow: '0px 1px 0px #DFE1E6',
                      borderRadius: 4,
                    }}
                    className='align-items-center p-0 m-0 mb-md-4 mb-3'
                  >
                    <Col md={9} className='p-0 m-0'>
                      <CardContact
                        label='Employee'
                        user={data?.employee}
                        key={'_employee_12' + data?.employee?.id}
                      />
                    </Col>
                    <div className='w-100 divider border-top d-block d-md-none' />
                    <Col
                      md={3}
                      className='d-flex justify-content-end pr-md-4 p-3 p-md-0'
                    >
                      <ActionButton
                        isAdmin={isAdmin}
                        type='employee'
                        data={data}
                        clientSigned={data?.client_signed}
                        contractorSigned={!!data?.contractor_signed}
                        employeeInvitation={!!data?.employee_invitation_url}
                        onCancel={cancelFullTimeInvite}
                        onInvite={() =>
                          inviteEmployee.startFetch({
                            email: data?.employee?.email,
                            contract_id: data?.id,
                          })
                        }
                        onUpdateContract={() => updateContractDetail(false)}
                        isLoading={
                          cancelEmployee.isLoading || inviteEmployee.isLoading
                        }
                      />
                    </Col>
                  </Row>
                )}
            </Col>
            <KYCModal
              toggle={() => setKycRequired(false)}
              isOpen={kycRequired}
              proceed={() => {
                setKycRequired(false)
                dispatch(updateContractRef({ ref: data?.ref, type: 'kyc' }))
                history.push('/settings#kyc')
              }}
            />
            <RequiredBankAccountModal
              toggle={() => setBankAccountRequired(false)}
              isOpen={bankAccountRequired}
              proceed={() => {
                setBankAccountRequired(false)
                history.push('/settings#accounts')
              }}
            />
            <ConfirmationModal
              toggle={() => setShowNeedApprovalModal(false)}
              isOpen={showNeedApprovalModal}
              title='Need to approve your account'
              onConfirm={() => {
                window.Intercom('show')
                setShowNeedApprovalModal(false)
              }}
              message='Your account is not approved yet please contact support to get approved.'
              caption='OK'
            />
            <ConfirmationModal
              toggle={() => setShowNeedKyb(false)}
              isOpen={showNeedKyb}
              onConfirm={handleKybProceed}
              title='Company details confirmation'
              {...getKybModalText(userProfile?.kyb_status)}
            />
          </Row>
          <Row className='p-0 m-0 mb-3 pr-xl-4'>
            <Col className='p-0 m-0'>
              {data?.type === CONTRACT_TYPES.FULL_TIME ? (
                <FulltimeDetailBlocks />
              ) : data?.type === CONTRACT_TYPES.DIRECT_EMPLOYEE ? (
                <DeDetails
                  contract={data}
                  editing={canEdit || canAmend}
                  setDeChanges={setDeChanges}
                />
              ) : (
                <StyledCard className='p-3 p-md-4 m-0'>
                  <CardBody className='p-0 m-0'>
                    <>
                      <StyledDiv>
                        <Media>
                          <Media className='overflow-hidden' body>
                            <Col className='p-0 m-0'>
                              <Row className='justify-content-between align-items-start p-0 m-0'>
                                <StyledH6 className='text-dark font-size-16 mb-3 mb-md-4  font-weight-bold'>
                                  Overview
                                </StyledH6>

                                {(canEdit || canAmend) && (
                                  <button
                                    className='btn btn-link text-primary p-0 font-size-14'
                                    onClick={() => setEditInfo(true)}
                                  >
                                    Edit
                                  </button>
                                )}
                              </Row>
                            </Col>
                            <div className='mb-3 mb-md-4'>
                              <StyledH5 className='font-size-14'>
                                Tax Residence
                              </StyledH5>
                              <Col>
                                <Row>
                                  <StyledH6 className='font-size-14 text-secondary font-weight-light'>
                                    {amendData?.taxResidence?.label ||
                                      data?.tax_residence?.name}
                                  </StyledH6>
                                  <img
                                    src={
                                      amendData?.taxResidence?.flag ||
                                      (!!data?.tax_residence?.iso2 &&
                                        `/flags/${data?.tax_residence.iso2?.toLowerCase()}.svg`)
                                    }
                                    className='mx-1'
                                    alt=''
                                    style={{
                                      width: '1rem',
                                      height: '1rem',
                                      borderRadius: '0.5rem',
                                      marginRight: 10,
                                      objectFit: 'cover',
                                    }}
                                  />
                                </Row>
                              </Col>
                            </div>
                            <StyledH5 className='text-dark font-size-14 '>
                              Role
                            </StyledH5>
                            <StyledP className='text-secondary font-size-14'>
                              {amendData?.name || data?.name}
                            </StyledP>
                          </Media>
                        </Media>

                        <StyledH5 className='text-dark font-size-14'>
                          Scope of Work
                        </StyledH5>
                        <div
                          className='text-secondary font-size-14'
                          dangerouslySetInnerHTML={createMarkup(
                            amendData?.scope || data?.scope,
                          )}
                        />
                      </StyledDiv>
                      {!data?.milestones && (
                        <div>
                          <div className='my-3 my-md-4 border-top w-100 bg-white divider d-none d-md-block' />
                        </div>
                      )}
                      <Row>
                        <Col>
                          <StyledDiv>
                            <Media>
                              <Media className='overflow-hidden' body>
                                <Col>
                                  <Row className='justify-content-between'>
                                    <StyledH6 className='text-dark font-size-16 align-items-center font-weight-bold'>
                                      Payment schedule
                                    </StyledH6>
                                    {(canEdit || canAmend) &&
                                      !data?.milestones && (
                                        <button
                                          className='btn btn-link text-primary p-0'
                                          onClick={() => setEditPayment(true)}
                                        >
                                          Edit
                                        </button>
                                      )}
                                  </Row>
                                </Col>
                                <Row className='mt-md-4 mt-3 p-0 m-0'>
                                  {!data?.milestones && (
                                    <>
                                      {(!!amendData?.frequency ||
                                        !!data?.frequency) && (
                                        <Col
                                          className='pl-0 mb-3 mb-md-0'
                                          xs={12}
                                          md={4}
                                        >
                                          <div className='mb-3 mb-md-4'>
                                            <StyledH5 className='font-size-14'>
                                              Frequency
                                            </StyledH5>
                                            <StyledH6 className='font-size-14 text-secondary font-weight-light'>
                                              {amendData?.frequency_id?.label ||
                                                data?.frequency?.name}
                                            </StyledH6>
                                          </div>
                                        </Col>
                                      )}
                                      {(!!amendData?.start_date ||
                                        !!data?.start_date) && (
                                        <Col
                                          className='pl-0 mb-3 mb-md-0'
                                          xs={12}
                                          md={4}
                                        >
                                          <StyledH5 className='font-size-14'>
                                            Start Date
                                          </StyledH5>
                                          <StyledH6 className='font-size-14 text-secondary font-weight-light'>
                                            {moment(
                                              amendData?.start_date ||
                                                data?.start_date,
                                              amendData?.start_date
                                                ? 'YYYY-MM-DD'
                                                : 'MMM DD, YYYY',
                                            ).format('DD/MM/YYYY')}
                                          </StyledH6>
                                        </Col>
                                      )}
                                      {(!!amendData?.end_date ||
                                        !!data?.end_date) && (
                                        <Col
                                          className='pl-0 mb-3 mb-md-0'
                                          xs={12}
                                          md={4}
                                        >
                                          <div className='mb-3 mb-md-4'>
                                            <StyledH5 className='font-size-14'>
                                              End Date
                                            </StyledH5>
                                            <StyledH6 className='font-size-14 text-secondary font-weight-light'>
                                              {moment(
                                                amendData?.end_date ||
                                                  data?.end_date,
                                                amendData?.end_date
                                                  ? 'YYYY-MM-DD'
                                                  : 'MMM DD, YYYY',
                                              ).format('DD/MM/YYYY')}
                                            </StyledH6>
                                          </div>
                                        </Col>
                                      )}
                                      {(!!amendData?.amount ||
                                        !!data?.amount) && (
                                        <Col
                                          className='pl-0 mb-3 mb-md-0'
                                          xs={12}
                                          md={4}
                                        >
                                          <StyledH5 className='font-size-14'>
                                            Amount
                                          </StyledH5>
                                          <StyledH6 className='font-size-14 text-secondary font-weight-light'>
                                            {formatter.format(
                                              amendData?.amount || data?.amount,
                                            )}{' '}
                                            {amendData?.rate_id?.label ||
                                              data?.rate_id?.name ||
                                              ''}
                                          </StyledH6>
                                        </Col>
                                      )}
                                      {(!!data?.first_payment_date ||
                                        !!data?.first_payment_date) && (
                                        <Col className='pl-0' xs={12} md={4}>
                                          <div className='mb-3 mb-md-4'>
                                            <StyledH5 className='font-size-14'>
                                              First Payment
                                            </StyledH5>
                                            <StyledH6 className='font-size-14 text-secondary font-weight-light'>
                                              {moment(
                                                amendData?.first_payment_date ||
                                                  data?.first_payment_date,
                                                'YYYY-MM-DD',
                                              ).format('DD/MM/YYYY')}
                                            </StyledH6>
                                          </div>
                                        </Col>
                                      )}

                                      {!!(
                                        amendData?.occurrence_id ||
                                        data?.occurrence
                                      ) && (
                                        <Col className='pl-0  ' xs={12} md={4}>
                                          <StyledH5 className='font-size-14'>
                                            Occurrence
                                          </StyledH5>
                                          <StyledH6 className='font-size-14 text-secondary font-weight-light'>
                                            {amendData?.occurrence_id?.label ||
                                              data?.occurrence?.name}
                                          </StyledH6>
                                        </Col>
                                      )}
                                      {((!!amendData?.prorata_amount &&
                                        amendData?.first_payment_prorata) ||
                                        (!!data?.first_payment_prorata &&
                                          !amendData)) && (
                                        <Col className='pl-0  ' xs={12} md={4}>
                                          <StyledH5 className='font-size-14'>
                                            Prorata on first payment
                                          </StyledH5>
                                          <StyledH6 className='font-size-14 text-secondary font-weight-light'>
                                            {formatter.format(
                                              amendData?.prorata_amount ||
                                                data?.first_payment_amount,
                                            )}
                                          </StyledH6>
                                        </Col>
                                      )}
                                    </>
                                  )}
                                </Row>
                              </Media>
                            </Media>
                          </StyledDiv>
                        </Col>

                        {addMilestone && (
                          <AddMilestoneModal
                            contract={data}
                            isOpen={addMilestone}
                            toggle={() => setAddMilestone(false)}
                            onMilestoneAdded={() => updateContractDetail(false)}
                          />
                        )}
                        <SubmitMilestoneModal
                          isOpen={submitMilestone}
                          toggle={() => setSubmitMilestone(false)}
                        />
                        {submitWork && (
                          <SubmitWorkModal
                            contract={data}
                            isOpen={submitWork}
                            toggle={() => setSubmitWork(false)}
                          />
                        )}
                      </Row>
                      <div>
                        <div className='my-3 my-md-4 border-top w-100 bg-white divider d-none d-md-block' />
                      </div>

                      <Row>
                        <Col>
                          <StyledDiv>
                            <Media>
                              <Media className='overflow-hidden' body>
                                <Col className='p-0'>
                                  <Col>
                                    <Row className='justify-content-between'>
                                      <StyledH6 className='text-dark font-size-16 font-weight-bold'>
                                        Compliance
                                      </StyledH6>
                                      {(canEdit || canAmend) && (
                                        <button
                                          className='btn btn-link text-primary p-0'
                                          onClick={() =>
                                            setEditCompliance(true)
                                          }
                                        >
                                          Edit
                                        </button>
                                      )}
                                    </Row>
                                  </Col>
                                  <Row className='  mt-md-4 mt-3 p-0 m-0'>
                                    <Col
                                      className='pl-0 mb-3 mb-md-0'
                                      xs={12}
                                      md={4}
                                    >
                                      <div className='mb-3 mb-md-4'>
                                        <StyledH5 className='font-size-14'>
                                          Notice Period
                                        </StyledH5>
                                        <StyledH6 className='font-size-14 text-secondary font-weight-light'>
                                          {amendData?.notice_period ||
                                            data?.notice_period}
                                        </StyledH6>
                                      </div>
                                    </Col>
                                    {/* <Col
                                      className='pl-0 mb-3 mb-md-0'
                                      xs={12}
                                      md={4}
                                    >
                                      <div className='mb-3 mb-md-4'>
                                        <StyledH5 className='font-size-14'>
                                          Tax Residence
                                        </StyledH5>
                                        <Col>
                                          <Row>
                                            <StyledH6 className='font-size-14 text-secondary font-weight-light'>
                                              {amendData?.taxResidence?.label ||
                                                data?.tax_residence?.name}
                                            </StyledH6>
                                            <img
                                              src={
                                                amendData?.taxResidence?.flag ||
                                                (!!data?.tax_residence?.iso2 &&
                                                  `/flags/${data?.tax_residence.iso2?.toLowerCase()}.svg`)
                                              }
                                              className='mx-1'
                                              style={{
                                                width: '1rem',
                                                height: '1rem',
                                                borderRadius: '0.5rem',
                                                marginRight: 10,
                                                objectFit: 'cover',
                                              }}
                                            />
                                          </Row>
                                        </Col>
                                      </div>
                                    </Col> */}
                                    <Col className='pl-0  ' xs={12} md={4}>
                                      <>
                                        <StyledH5 className='font-size-14'>
                                          {amendData?.template_id
                                            ? 'You are using your saved contract template ' +
                                              amendData?.template?.label
                                            : !!amendData?.file ||
                                              data?.template_type === 'uploaded'
                                            ? 'You are using an uploaded contract'
                                            : amendData?.rw_file
                                            ? "You are using RemotePass's template for " +
                                              amendData?.taxResidence?.label
                                            : data?.template_type === 'custom'
                                            ? 'You are using your saved contract template ' +
                                              data?.template_name
                                            : data?.template_type === 'uploaded'
                                            ? 'You are using an uploaded contract'
                                            : data?.template_type === 'default'
                                            ? "You are using RemotePass's template for " +
                                              data?.tax_residence?.name
                                            : ''}
                                        </StyledH5>
                                      </>
                                    </Col>
                                  </Row>
                                </Col>
                              </Media>
                            </Media>
                          </StyledDiv>
                        </Col>
                      </Row>
                      {(canEdit || canAmend || data?.annexes?.length > 0) && (
                        <div>
                          <div className='my-3 my-md-4 border-top w-100 bg-white divider d-none d-md-block' />
                        </div>
                      )}
                      {(canEdit || canAmend || data?.annexes?.length > 0) && (
                        <Row>
                          <Col>
                            <StyledDiv>
                              <Media>
                                <Media className='overflow-hidden' body>
                                  <Col className='p-0'>
                                    <Col>
                                      <Row className='justify-content-between'>
                                        <StyledH6 className='text-dark font-size-16 font-weight-bold'>
                                          Annexes
                                        </StyledH6>
                                        {(canEdit || canAmend) && !addAnnex && (
                                          <button
                                            className='btn btn-link text-primary p-0'
                                            onClick={() => setAddAnnex(true)}
                                          >
                                            Add
                                          </button>
                                        )}
                                      </Row>
                                      <Row className='justify-content-center p-0 pt-4'>
                                        {canEdit || canAmend ? (
                                          <Table className='table'>
                                            {(data?.annexes.length > 0 ||
                                              newAnnexes.length > 0) && (
                                              <thead>
                                                <tr>
                                                  {editedAnnexesCols.map(
                                                    (
                                                      {
                                                        className,
                                                        label,
                                                        style,
                                                      },
                                                      index,
                                                    ) => {
                                                      return (
                                                        <th
                                                          className={className}
                                                          style={style}
                                                          key={index}
                                                        >
                                                          {label}
                                                        </th>
                                                      )
                                                    },
                                                  )}
                                                </tr>
                                              </thead>
                                            )}
                                            <tbody>
                                              <EditedAnnexes
                                                annexes={
                                                  [
                                                    ...(defaultAnnexes ?? []),
                                                    ...newAnnexes,
                                                  ] ?? []
                                                }
                                                deleteAnnex={handleDeleteAnnex}
                                                canEdit={canEdit}
                                              />
                                            </tbody>
                                          </Table>
                                        ) : (
                                          <Table className='table '>
                                            <thead>
                                              <tr>
                                                {annexesCols.map(
                                                  (
                                                    { className, label, style },
                                                    index,
                                                  ) => {
                                                    return (
                                                      <th
                                                        className={className}
                                                        style={style}
                                                        key={index}
                                                      >
                                                        {label}
                                                      </th>
                                                    )
                                                  },
                                                )}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <Annexes
                                                annexes={data?.annexes ?? []}
                                              />
                                            </tbody>
                                          </Table>
                                        )}
                                      </Row>
                                    </Col>
                                  </Col>
                                </Media>
                              </Media>
                            </StyledDiv>
                          </Col>
                        </Row>
                      )}
                    </>
                  </CardBody>
                </StyledCard>
              )}
            </Col>
          </Row>
        </Col>
        {!isEmployee && (
          <Col xs={12} md={12} xl={3} className='p-0 m-0'>
            <Card
              style={{
                backgroundColor: '#FFFFFF',
                boxShadow: '0px 1px 0px #DFE1E6',
                borderRadius: 4,
                height: 'fit-content',
              }}
            >
              <CardBody className='p-0 m-0'>
                <div className='flex-row p-3 border-bottom mb-0 p-md-4'>
                  <StyledH5
                    style={{ whiteSpace: 'nowrap' }}
                    min='16px'
                    max='22px'
                    className=' text-dark font-weight-bold'
                  >
                    Contract Timeline
                  </StyledH5>
                  {data?.amended ? (
                    <Badge
                      className='rounded font-size-14 bg-soft-warning text-warning'
                      color='white'
                      style={{ padding: '4px 6px' }}
                      pill
                    >
                      Pending amendment
                    </Badge>
                  ) : (
                    <Badge
                      className={`ml-0 rounded font-size-12  bg-soft-${getStatusColor(
                        data?.status,
                      )} text-${getStatusColor(data?.status)}`}
                      color='white'
                      pill
                      style={{ padding: '4px 6px' }}
                    >
                      {data?.status?.name}
                    </Badge>
                  )}
                </div>

                <div className='p-3 p-md-4'>
                  <ul className='verti-timeline list-unstyled'>
                    {data?.timeline?.map((status, key) => (
                      <li key={key} className='event-list'>
                        <div
                          className='event-timeline-dot font-size-24 rounded-circle border bg-white d-flex justify-content-center align-items-center'
                          style={{ marginLeft: '-5px', width: 24, height: 24 }}
                        >
                          <i
                            className={
                              status.completed === 1
                                ? ` font-size-10 fas fa-check mt-0 ${
                                    [
                                      'Contract Cancelled',
                                      'Contract Terminated',
                                    ].includes(status.name)
                                      ? 'text-danger'
                                      : 'text-success'
                                  } `
                                : 'fas fa-times text-danger mt-0 font-size-10'
                            }
                          />
                        </div>
                        <div className='media'>
                          <div className='media-body'>
                            <div>
                              <p
                                style={{ fontWeight: 'bold' }}
                                className='mb-1 font-size-14'
                              >
                                {status?.name}
                              </p>
                              {!!status?.user && (
                                <>
                                  <span className='mb-1 text-secondary font-size-14'>
                                    by {status.user}
                                  </span>
                                  <br />
                                </>
                              )}
                              <p className='mb-1 text-secondary font-size-14'>
                                {status.date
                                  ? moment(status.date * 1000).format(
                                      'DD/MM/YYYY HH:mm',
                                    )
                                  : ' '}
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </CardBody>
              <div className='p-3 p-md-4 border-top'>
                {data?.offer_letter ? (
                  <Dropdown isOpen={menu} toggle={() => setMenu((t) => !t)}>
                    <DropdownToggle
                      className='btn btn-block h-100 m-0 p-0 header-item waves-effect text-primary font-weight-bold'
                      id='withdraw-method-dropdown'
                      tag='button'
                    >
                      <button
                        style={{ height: 48 }}
                        disabled={!hasAccess(permissions.DownloadContracts)}
                        className='btn btn-block btn-primary'
                      >
                        {downloading && (
                          <i className='bx bx-loader bx-spin font-size-16 align-middle mr-2'></i>
                        )}
                        Download
                      </button>
                    </DropdownToggle>
                    <DropdownMenu className='w-100'>
                      <DropdownItem tag='a' onClick={handleDownloadContract}>
                        Contract Agreement
                      </DropdownItem>

                      <DropdownItem tag='a' onClick={handleDownloadOfferLetter}>
                        Offer Letter
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                ) : (
                  <button
                    style={{ height: 48 }}
                    disabled={!hasAccess(permissions.DownloadContracts)}
                    onClick={handleDownloadContract}
                    className='btn btn-block btn-primary'
                  >
                    {downloading && (
                      <i className='bx bx-loader bx-spin font-size-16 align-middle mr-2'></i>
                    )}
                    Download
                  </button>
                )}

                <Button
                  className={cx('mt-3', { 'text-muted': previewing })}
                  onClick={handlePreviewContract}
                  color='light'
                  outline
                  block
                  disabled={previewing}
                  loading={previewing}
                >
                  {previewing ? 'Previewing ..' : 'Preview'}
                </Button>

                {!showTerminateButton ? null : (
                  <Button
                    style={{ height: 48 }}
                    disabled={
                      isAdmin ||
                      terminate.isLoading ||
                      !hasAccess(permissions.TerminateContracts)
                    }
                    onClick={() => {
                      if (data?.type === CONTRACT_TYPES.DIRECT_EMPLOYEE) {
                        setDeTerminationOpen(true)
                      } else {
                        setShowTerminateConfirmation(true)
                      }
                    }}
                    className={cx('mt-3', {
                      'text-muted': terminate.isLoading,
                    })}
                    loading={terminate.isLoading}
                    color='light'
                    outline
                    block
                  >
                    Terminate
                  </Button>
                )}

                {!showCancelTermination ? null : (
                  <DeCancelTermination
                    contract={data}
                    isAdmin={isAdmin}
                    onConfirm={() => updateContractDetail(false)}
                  />
                )}

                {data?.can_cancel && data?.type !== CONTRACT_TYPES.FULL_TIME && (
                  <>
                    <Button
                      style={{ height: 48 }}
                      disabled={
                        isAdmin ||
                        cancelFetch.isLoading ||
                        !hasAccess(permissions.cancelContracts)
                      }
                      loading={cancelFetch.isLoading}
                      onClick={() => setCancelConfirmation(true)}
                      className={cx('mt-3', {
                        'text-muted': cancelFetch.isLoading,
                      })}
                      color='light'
                      block
                      outline
                    >
                      Cancel Contract
                    </Button>
                    <ConfirmationModal
                      isOpen={cancelConfirmation}
                      toggle={() => {
                        setCancelConfirmation(false)
                      }}
                      message='Are you sure you want to cancel this contract? This action is irreversible and the contractor will be notified by email'
                      onConfirm={handleCancelContractClick}
                    />
                  </>
                )}

                {data?.can_amend &&
                  !data?.amended &&
                  !canAmend &&
                  data?.type !== CONTRACT_TYPES.FULL_TIME && (
                    <>
                      <button
                        style={{ height: 48 }}
                        onClick={() => setAmendConfirmation(true)}
                        disabled={
                          isAdmin || !hasAccess(permissions.AmendContracts)
                        }
                        className='btn btn-block bg-white mt-3 border-light'
                      >
                        {amend.isLoading && (
                          <i className='bx bx-loader bx-spin font-size-16 align-middle mr-2' />
                        )}
                        Amend
                      </button>
                      <AmendModal
                        isOpen={amendConfirmation}
                        toggle={() => {
                          setAmendConfirmation(false)
                        }}
                        onProceed={() => {
                          setCanAmend(true)
                          setAmendConfirmation(false)
                        }}
                      />
                    </>
                  )}
                {data?.can_edit && data?.type !== CONTRACT_TYPES.FULL_TIME && (
                  <Button
                    style={{ height: 48 }}
                    onClick={() => {
                      setCanEdit(true)
                    }}
                    disabled={
                      isAdmin ||
                      update.isLoading ||
                      !hasAccess(permissions.AmendContracts) ||
                      canEdit
                    }
                    loading={update.isLoading}
                    className='mt-3'
                    color='light'
                    block
                    outline={!canEdit}
                  >
                    {canEdit ? 'Editing' : 'Edit Contract'}
                  </Button>
                )}
                {data?.can_edit &&
                  data?.template_type !== 'uploaded' &&
                  data?.type !== CONTRACT_TYPES.FULL_TIME && (
                    <Button
                      style={{ height: 48 }}
                      onClick={() => {
                        history.push({
                          pathname: '/template-editor',
                          state: { contract_id: data?.id },
                        })
                      }}
                      disabled={
                        isAdmin ||
                        !hasAccess(permissions.AmendContracts) ||
                        canEdit
                      }
                      color='light'
                      block
                      outline={!canEdit}
                      className='mt-3'
                    >
                      {canEdit ? 'Editing' : 'Edit Template'}
                    </Button>
                  )}
                {!!data?.amended && user?.type === userTypes.COMPANY && (
                  <>
                    <button
                      style={{ height: 48 }}
                      onClick={() => setCancelAmendConfirmation(true)}
                      disabled={
                        isAdmin || !hasAccess(permissions.cancelAmendment)
                      }
                      className='btn btn-block bg-white mt-3 border-light'
                    >
                      {cancelAmend.isLoading && (
                        <i className='bx bx-loader bx-spin font-size-16 align-middle mr-2' />
                      )}
                      Cancel Amendment
                    </button>
                    <ConfirmationModal
                      isOpen={cancelAmendConfirmation}
                      loading={cancelAmend.isLoading}
                      toggle={() => {
                        setCancelAmendConfirmation(false)
                      }}
                      message='Are you sure you want to cancel this contract Amendment? This action is irreversible'
                      onConfirm={() => {
                        cancelAmend.startFetch({ contract_id: data?.id })
                      }}
                    />
                  </>
                )}
                {data?.status?.id === 4 &&
                  data?.type !== CONTRACT_TYPES.FULL_TIME &&
                  user?.type === userTypes.COMPANY &&
                  hasAccess(permissions.addEquipment) && (
                    <button
                      style={{ height: 48 }}
                      disabled={isAdmin}
                      onClick={() => setShowEquipmentModal(true)}
                      className='btn btn-block bg-white mt-3 border-light'
                    >
                      Add Equipment
                    </button>
                  )}

                {!!data?.resign && false && (
                  <button
                    style={{ height: 48 }}
                    onClick={() => setShowSignModal(true)}
                    className='btn btn-block bg-white mt-3 border-light'
                  >
                    Resign with DocuSign
                  </button>
                )}

                <TerminationModal
                  notice={data?.notice_period}
                  isOpen={showTerminateConfirmation}
                  toggle={() => setShowTerminateConfirmation(false)}
                  onConfirm={handleTerminateContract}
                  withAmount={data?.type === CONTRACT_TYPES.FIXED}
                  currency={data?.currency}
                />

                {!deTerminationOpen ? null : (
                  <DeTerminationModal
                    isOpen={deTerminationOpen}
                    contract={data}
                    toggle={() => setDeTerminationOpen((open) => !open)}
                    onConfirm={() => updateContractDetail(false)}
                  />
                )}
              </div>
              <ConfirmationModal
                title='Incomplete Profile'
                message='You need to complete your profile before signing this contract'
                isOpen={showNeedToCompleteProfile}
                toggle={() => {
                  setShowNeedToCompleteProfile(false)
                }}
                caption='Complete profile now'
                onConfirm={() => {
                  dispatch(updateContractRef({ ref: data?.ref }))
                  history.push('/settings')
                }}
              />
            </Card>
          </Col>
        )}
      </Row>
      {(canAmend || canEdit) && (
        <div id='footer-amend'>
          <Container fluid={true}>
            <Row
              className='bg-soft-primary p-4 justify-content-center gap-16'
              style={{ backdropFilter: 'blur(2px)' }}
            >
              <Button
                disabled={amend.isLoading || update.isLoading}
                onClick={handleCancelAmendChanges}
                color='danger'
                outline
              >
                Cancel
              </Button>
              <Button
                disabled={
                  (!amendData && !deChanges) ||
                  amend.isLoading ||
                  update.isLoading
                }
                loading={amend.isLoading || update.isLoading}
                onClick={handleSaveAmendChanges}
              >
                Apply changes
              </Button>
            </Row>
          </Container>
        </div>
      )}

      {editPayment && (
        <FormsModal
          title='Edit Payment'
          isOpen={editPayment}
          toggle={() => {
            setEditPayment(false)
          }}
          onConfirm={() => {
            editPaymentRef.current.submit()
          }}
        >
          <PaymentForm
            ref={editPaymentRef}
            data={data}
            isAdmin={isAdmin}
            amendData={amendData}
            isAmend={canAmend || canEdit}
            onSubmit={(v) => {
              const body = { ...amendData, contract_id: data?.id, ...v }
              if (!v.end_date) {
                delete body?.end_date
                delete data?.end_date
              }
              if (
                body.amount &&
                body.currency_id &&
                body.first_payment_date &&
                body.frequency_id &&
                body.occurrence_id &&
                body.start_date &&
                body.start_date !== 'Invalid date'
              ) {
                setAmendData(body)
                setEditPayment(false)
              } else {
                toastr.error('Missing required field')
              }
            }}
            isUpdate
          />
        </FormsModal>
      )}
      <FormsModal
        title='Edit Info'
        isOpen={editInfo}
        toggle={() => {
          setEditInfo(false)
        }}
        onConfirm={() => {
          editInfoRef.current.submit()
        }}
      >
        <InfoForm
          ref={editInfoRef}
          data={data}
          amendData={amendData}
          onSubmit={(v) => {
            setAmendData({ ...amendData, contract_id: data?.id, ...v })
            setEditInfo(false)
          }}
        />
      </FormsModal>
      <FormsModal
        title='Edit Compliance'
        isOpen={editCompliance}
        toggle={() => {
          setEditCompliance(false)
        }}
        onConfirm={() => editComplianceRef.current.submit()}
        isLoading={isUploading}
      >
        <ComplianceForm
          ref={editComplianceRef}
          data={data}
          amendData={amendData}
          isAmend={canAmend}
          onUploadStatusChanged={setIsUploading}
          onSubmit={(e, v, file, notice, contractMedia, template) => {
            if (contractMedia === -1) {
              toastr.error('Contract is missing', 'error')
              return
            }
            if (contractMedia === 2 && !template) {
              toastr.error('Please select one of your templates', 'error')
              return
            }
            const body = {
              ...amendData,
              contract_id: data?.id,
              ...v,
              notice_period: notice,
              client_can_submit: true,
              file,
              template_id: template?.value,
              template,
            }
            if (contractMedia === 0) {
              body.rw_file = true
            }
            setAmendData(body)
            setEditCompliance(false)
          }}
          templates={templates.data}
          uploaded={data?.uploaded}
        />
      </FormsModal>
      <FormsModal
        title='Add Annex'
        isOpen={addAnnex}
        toggle={() => {
          setAddAnnex(false)
        }}
        onConfirm={() => annexRef.current.submit()}
        isLoading={isUploading}
      >
        <AnnexForm
          ref={annexRef}
          newAnnexes={newAnnexes}
          data={defaultAnnexes}
          toggle={() => setAddAnnex(false)}
          onUpdateContract={() => updateContractDetail(false)}
          onUploadStatusChanged={setIsUploading}
          onSubmit={(name, path) => {
            if (!name) {
              toastr.error('Name is missing', 'error')
              return
            }
            if (!path) {
              toastr.error('File is missing', 'error')
              return
            }
            const annexesToAdd = [...newAnnexes]
            annexesToAdd.push({ name, path })
            setNewAnnexes(annexesToAdd)
            const body = {
              ...amendData,
              contract_id: data?.id,
              annexes: annexesToAdd,
            }
            setAmendData(body)
            setAddAnnex(false)
          }}
          handleDeleteAnnex={handleDeleteAnnex}
        />
      </FormsModal>
      <ModalInvitation
        show={showInvitePopup}
        type={user?.type === userTypes.CONTRACTOR ? 'client' : 'contractor'}
        data={data}
        hide={() => setShowInvitePopup(false)}
        message={`Invite your ${
          user?.type === 'client'
            ? data?.contractor_name?.toLowerCase()
            : 'client'
        }`}
        onUpdate={() => {
          updateContractDetail(false)
          setShowInvitePopup(false)
        }}
      />
      <ModalInvitation
        show={showInviteEmployee}
        type='employee'
        data={data}
        hide={() => setShowInviteEmployee(false)}
        message='Invite your employee'
        onUpdate={() => {
          updateContractDetail(false)
          setShowInviteEmployee(false)
        }}
      />
      <Modal
        size='lg'
        centered
        isOpen={showSignModal}
        scrollable
        toggle={() => {
          setShowSignModal(false)
        }}
      >
        <div className='modal-header p-3 p-md-4'>
          <StyledH5
            className='modal-title mt-0 font-size-16'
            id='myLargeModalLabel'
          >
            Contract #<span className='text-secondary'> {data?.ref}</span>
          </StyledH5>
          <Row className='align-items-center p-0 m-0'>
            <button
              disabled={!hasAccess(permissions.DownloadContracts)}
              onClick={handleDownloadContract}
              className='btn btn-outline-light'
            >
              {downloading && (
                <i className='bx bx-loader bx-spin font-size-16 align-middle mr-2' />
              )}
              Download
            </button>
            <button
              onClick={() => {
                setShowSignModal(false)
              }}
              type='button'
              className='close'
              data-dismiss='modal'
              aria-label='Close'
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </Row>
        </div>
        <div
          style={{ height: isMobile ? '20vh' : '60vh' }}
          className='modal-body p-0'
        >
          {docuSignLoading && (
            <Row
              style={{ height: '60vh' }}
              className='justify-content-center align-items-center'
            >
              <Spinner type='grow' className='mr-2' color='primary' />
            </Row>
          )}
          <iframe
            id='signatureFrame'
            src={`${process.env.REACT_APP_API_HOST}/api/contract/show/${data?.ref}/${data?.file}`}
            title='Contract view'
            width='100%'
            height='98%'
            ref={iframeRef}
            onLoad={(e) => {
              setDocuSignLoading(false)
              try {
                if (
                  window.frames.signatureFrame.contentWindow.location.href.startsWith(
                    `${process.env.REACT_APP_API_HOST}/docu-sign`,
                  )
                ) {
                  const event = new URLSearchParams(
                    window.frames.signatureFrame.contentWindow.location.search,
                  ).get('event')
                  if (event === 'signing_complete') {
                    handleSignClick()
                  }
                  setShowSignModal(false)
                }
              } catch (err) {
                // eslint-disable-next-line no-console
                console.log(err)
              }
            }}
            style={{ backgroundColor: '#fff', border: 'none' }}
          />
        </div>
        <div className='modal-footer p-3 p-md-4'>
          <Container>
            <Row className='p-0 align-items-end  d-flex'>
              {user?.type === 'contractor' && (
                <Col className='p-0 m-0 pr-md-3 mb-3 mb-md-0' sm={12} md={8}>
                  <label className='font-size-14'>Your Full Name</label>
                  <Input
                    placeholder='Full name'
                    onChange={(e) => {
                      setFullName(e.target.value)
                    }}
                    disable={user?.type === 'client'}
                    value={`${userProfile?.first_name || ''} ${
                      userProfile?.middle_name
                        ? userProfile?.middle_name + ' '
                        : ''
                    }${userProfile?.last_name || ''}`}
                    disabled
                  />
                  <h6 className='text-muted d-none'>
                    Enter your full name [contractor]
                  </h6>
                </Col>
              )}
              {user?.type === 'client' && (
                <Col className='p-0 m-0  pr-md-3 mb-3 mb-md-0' sm={12} md={8}>
                  <label className='font-size-14'>Your Full Name</label>
                  <Input
                    placeholder='Full name'
                    disable={user?.type === 'contractor'}
                    value={`${userProfile?.first_name || ''} ${
                      userProfile?.middle_name
                        ? userProfile?.middle_name + ' '
                        : ''
                    }${userProfile?.last_name || ''}`}
                    onChange={(e) => {
                      setFullName(e.target.value)
                    }}
                  />
                  <h6 className='text-muted d-none'>
                    Enter your full name [client]
                  </h6>
                </Col>
              )}
              <Col className='p-0 m-0' sm={12} md={4}>
                <Button
                  onClick={() => {
                    window.analytics.track('Clicked sign contract', {
                      contract_id: data?.ref,
                    })
                    handleSignClick()
                  }}
                  type='button'
                  loading={loadingSignature}
                  disabled={loadingSignature}
                  block
                >
                  Agree & Sign
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </Modal>

      {showFulltimePayment && (
        <ConfirmationModal
          isOpen={showFulltimePayment}
          message='Next step, process the deposit payment to activate the employee onboarding process.'
          onConfirm={() => {
            history.push(`/contract/detail?id=${data?.ref}&tab=payments`)
            setShowFulltimePayment(false)
          }}
          title='Deposit Payment'
          caption='Pay'
          toggle={() => setShowFulltimePayment(false)}
        />
      )}

      {showEquipmentModal && (
        <AddEquipmentModal
          contractId={data?.id}
          isOpen={showEquipmentModal}
          toggle={() => {
            setShowEquipmentModal(false)
          }}
          onEquipmentAdded={() => {
            updateContractDetail()
            updateTabLink('equipment')
          }}
        />
      )}
    </>
  )
}

const StyledCard = styled(Card)`
  @media (max-width: 768px) {
    padding: 0 !important;
    background-color: #f4f5f7;
    box-shadow: none;
  }
`
const StyledDiv = styled.div`
  @media (max-width: 768px) {
    padding: 16px;
    background-color: #ffffff;
    box-shadow: 0px 1px 0px #dfe1e6;
    border-radius: 4px;
    margin-bottom: 16px;
  }
`
function Annexes({ annexes, onUpdateContract }) {
  if (!annexes || !Array.isArray(annexes)) return

  return annexes.map((annex) => {
    return <AnnexLine key={annex.name} annex={annex} />
  })
}
function AnnexLine({ annex }) {
  return (
    <tr key={'_order_' + annex.name}>
      <td style={{ whiteSpace: 'pre-line' }}>{annex.name}</td>

      <td style={{ textAlign: 'right' }}>
        {moment(annex.created_at * 1000).format('MM/DD/YYYY hh:mm')}
      </td>
    </tr>
  )
}
function EditedAnnexes({ annexes, deleteAnnex, canEdit }) {
  if (!annexes || !Array.isArray(annexes)) return

  return annexes.map((annex) => {
    return (
      <EditedAnnexLine
        key={annex.name}
        annex={annex}
        deleteAnnex={deleteAnnex}
        canEdit={canEdit}
      />
    )
  })
}
function EditedAnnexLine({ annex, deleteAnnex, canEdit }) {
  return (
    <tr key={'_order_' + annex.name}>
      <td style={{ whiteSpace: 'pre-line' }}>{annex.name}</td>
      <td style={{ textAlign: 'right' }}>
        {moment().format('MM/DD/YYYY hh:mm')}
      </td>
      <td style={{ textAlign: 'right' }}>
        {canEdit ? (
          <i
            className='bx bx-trash text-danger font-size-18'
            style={{ paddingRight: '50px', cursor: 'pointer' }}
            onClick={() => deleteAnnex(annex)}
          />
        ) : !annex.id ? (
          <i
            className='bx bx-trash text-danger font-size-18'
            style={{ paddingRight: '50px', cursor: 'pointer' }}
            onClick={() => deleteAnnex(annex)}
          />
        ) : null}
      </td>
    </tr>
  )
}

function getKybModalText(status) {
  switch (status) {
    case 'pending': {
      return {
        content: (
          <div className='font-size-16 py-3 text-center text-secondary'>
            You need to confirm your company details.
          </div>
        ),
        caption: 'Proceed',
      }
    }
    case 'rejected': {
      return {
        content: (
          <div className='font-size-16 py-3 text-center text-secondary'>
            Your previous submission was rejected. Please confirm your company
            details.
          </div>
        ),
        caption: 'Proceed',
      }
    }
    case 'submitted': {
      return {
        content: (
          <div className='font-size-16 py-3 text-center text-secondary'>
            Your account is still pending verification.
          </div>
        ),
        caption: 'Ok',
      }
    }
    default: {
      return { content: '', caption: '' }
    }
  }
}

function SectionTitle({ children, editing, onClickEdit }) {
  return (
    <div className='font-size-16 mb-1 rp-font-gilroyB text-gray-h'>
      <div className='d-flex justify-content-between align-items-baseline'>
        {children}

        {!editing ? null : (
          <Button color='link' className='px-1 py-0' onClick={onClickEdit}>
            Edit
          </Button>
        )}
      </div>

      <hr />
    </div>
  )
}

function ContractInfoItem({ title, value, countryCode, className, style }) {
  return (
    <div className={cx('d-flex flex-column gap-6', className)} style={style}>
      {!title ? null : (
        <div className='text-size-14 rp-font-gilroyB text-gray-600'>
          {title}
        </div>
      )}
      <div className='align-items-center d-flex gap-8 text-gray-h text-size-14 rp-capitalize text-nowrap'>
        {!countryCode ? null : <FlagIcon code={countryCode} size='16px' />}
        {value ?? '-'}
      </div>
    </div>
  )
}

const overviewSchema = yup.object().shape({
  name: yup
    .string()
    .typeError('Title is required')
    .required('Title is required'),
  country_id: yup
    .string()
    .typeError('Country is required')
    .required('Country is required'),
  jurisdiction_id: yup
    .string()
    .typeError('Jurisdiction is required')
    .required('Jurisdiction is required'),
  employment_term: yup
    .string()
    .typeError('Term is required')
    .required('Term is required'),
  seniority: yup
    .string()
    .typeError('Seniority is required')
    .required('Seniority is required'),
})

const compensationSchema = yup.object().shape({
  amount: yup
    .number()
    .typeError('Salary is required')
    .required('Salary is required'),
  currency_id: yup
    .string()
    .typeError('Currency is required')
    .required('Currency is required'),
  probation_period: yup
    .number()
    .typeError('Probation period is required')
    .required('Probation period is required'),
  notice_period: yup
    .number()
    .typeError('Notice period is required')
    .required('Notice period is required'),
})

const deDetailsSections = {
  overview: {
    key: 'overview',
    title: 'Overview',
    schema: overviewSchema,
  },
  compensation: {
    key: 'compensation',
    title: 'Compensation',
    schema: compensationSchema,
  },
}

function DeDetails({ contract, editing, setDeChanges }) {
  const [editFormSection, setEditFormSection] = useState(null)
  const [prevData, setPrevData] = useState(null)

  const { currencies = [] } = useSelector(
    (state) => state.Layout?.staticData ?? {},
  )

  const currencyCode = contract?.jurisdiction_country?.currency

  const currencyOptions = currencies?.map((c) => mapCurrencyToOption(c, 'code'))
  const currencyId = getOptionFromList(
    currencyOptions,
    currencyCode,
    'code',
  )?.id

  const formMethods = useForm({
    defaultValues: {
      ...contract,
      country_id: contract?.jurisdiction_country?.id,
      jurisdiction_id: contract?.jurisdiction?.id,
      currency_id: currencyId,
    },
  })

  const { watch, reset } = formMethods

  const contractData = editing ? watch() : contract

  const formatter = getCurrencyFormatter(
    contractData?.jurisdiction_country?.currency,
  )

  const overviewInfos = [
    {
      title: 'Country',
      value: contractData?.jurisdiction_country?.name,
      countryCode: contractData?.jurisdiction_country?.currency,
    },
    { title: 'Term', value: contractData?.employment_term },
    { title: 'Title', value: contractData?.name },
    { title: 'Seniority', value: contractData?.seniority },
    { title: 'Department', value: contractData?.department },
  ]

  const compensationInfos = [
    {
      title: 'Salary',
      value: formatter.format(contractData?.amount),
      countryCode: contractData?.jurisdiction_country?.currency,
    },
    { title: 'Salary date', value: contractData?.next_payment },
    {
      title: 'Probation period',
      value: `${contractData?.probation_period} Days`,
    },
    { title: 'Notice', value: `${contractData?.notice_period} Days` },
  ]

  function closeModal() {
    setEditFormSection(null)
    reset(prevData)
  }

  function openModal(section) {
    setPrevData(watch())
    setEditFormSection(section)
  }

  return (
    <>
      <Card className='p-4 mb-md-4 mb-3 rp-shadow-2'>
        <SectionTitle
          editing={editing}
          onClickEdit={() => {
            openModal(deDetailsSections.overview.key)
          }}
        >
          {deDetailsSections.overview.title}
        </SectionTitle>

        <div className='d-flex flex-wrap gap-16'>
          {overviewInfos.map((info) => {
            return (
              <ContractInfoItem
                key={info.title}
                title={info.title}
                value={info.value}
                countryCode={info.countryCode}
                style={{ flex: 1 }}
              />
            )
          })}
        </div>
      </Card>

      <Card className='p-4 mb-md-4 mb-3 rp-shadow-2'>
        <SectionTitle
          editing={editing}
          onClickEdit={() => {
            openModal(deDetailsSections.compensation.key)
          }}
        >
          {deDetailsSections.compensation.title}
        </SectionTitle>

        <div
          className='gap-16'
          style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)' }}
        >
          {compensationInfos.map((info) => {
            return (
              <ContractInfoItem
                key={info.title}
                title={info.title}
                value={info.value}
                countryCode={info.countryCode}
              />
            )
          })}
        </div>
      </Card>

      <FormProvider {...formMethods}>
        <FormDataProvider>
          <DeEditingForms
            editFormSection={editFormSection}
            contract={contractData}
            closeModal={closeModal}
            applyChanges={(newData) => {
              setEditFormSection(null)
              setDeChanges(newData)
            }}
          />
        </FormDataProvider>
      </FormProvider>
    </>
  )
}

function DeEditingForms({
  editFormSection,
  closeModal,
  contract,
  applyChanges,
}) {
  const { setItem } = useFormData()

  const {
    control,
    formState: { errors },
    setValue,
    watch,
    setError,
    setFocus,
    clearErrors,
    trigger,
  } = useFormContext()

  useTriggerField({ watch, trigger })

  function handleConfirm() {
    const schema = deDetailsSections[editFormSection].schema

    if (!schema) return

    const data = watch()

    validateSchema(schema, data, {
      setFocus,
      setError,
      clearErrors,
      onValidSchema: () => {
        applyChanges(data)
      },
    })
  }

  const { data: supportedCountries, isLoading: gettingSupportedCountries } =
    useFetch({
      action: deSupportedCountries,
      autoFetch: true,
    })

  const { country_id: countryId } = useWatch({
    control,
  })

  const { data: jurisdictions, isLoading: loadingJurisdictions } = useFetch(
    {
      action: deGetCountryJurisdictions,
      body: { country_id: countryId },
      autoFetch: !!countryId,
      onComplete: (data) => {
        const foundJurisdiction = data?.find(
          (j) => j.id === contract?.jurisdiction_id,
        )

        if (contract?.jurisdiction_id && foundJurisdiction?.id) {
          setValue('jurisdiction_id', contract.jurisdiction_id)

          setItem(dataKeys.jurisdictionId, foundJurisdiction?.currencies)
          setItem(dataKeys.jurisdiction, foundJurisdiction)
        }
      },
    },
    [countryId],
  )

  const supportedCountryOptions = supportedCountries?.map((c) =>
    mapCountryToOption(c, 'id'),
  )

  return (
    <FormsModal
      title={`Edit ${editFormSection}`}
      isOpen={!!editFormSection}
      toggle={closeModal}
      onConfirm={handleConfirm}
    >
      {editFormSection === deDetailsSections.overview.key ? (
        <OverviewForm
          control={control}
          errors={errors}
          data={{
            supportedCountryOptions,
            gettingSupportedCountries,
            jurisdictions,
            loadingJurisdictions,
          }}
        />
      ) : editFormSection === deDetailsSections.compensation.key ? (
        <CompensationForm control={control} errors={errors} />
      ) : null}
    </FormsModal>
  )
}

function OverviewForm({ control, errors, data }) {
  const {
    supportedCountryOptions,
    gettingSupportedCountries,
    jurisdictions,
    loadingJurisdictions,
  } = data

  const { seniorities = [], job_titles: titles = [] } = useSelector(
    (state) => state.Layout?.staticData ?? {},
  )

  const { country_id: countryId, name: watchingJobTitle } = useWatch({
    control,
  })

  return (
    <Row className='mx-n3'>
      <FormCol>
        <ControlledInput
          label={<LabelContent required>Title:</LabelContent>}
          control={control}
          name='name'
          error={errors?.name}
          placeholder='i.e. Website Design, Content generation'
          autoComplete={titles}
          watchState={watchingJobTitle}
        />
      </FormCol>

      <FormCol />

      <FormCol>
        <ControlledSelect
          control={control}
          name='country_id'
          error={errors?.country_id}
          label={<LabelContent required>Employment Country:</LabelContent>}
          options={supportedCountryOptions}
          disabled={gettingSupportedCountries}
          isLoading={gettingSupportedCountries}
        />
      </FormCol>

      <FormCol>
        <ControlledSelect
          control={control}
          name='jurisdiction_id'
          error={errors?.jurisdiction_id}
          label={<LabelContent required>Jurisdiction:</LabelContent>}
          options={jurisdictions?.map((j) => ({
            ...j,
            label: j?.name,
            value: j?.id,
          }))}
          disabled={
            loadingJurisdictions || !jurisdictions?.length || !countryId
          }
          isLoading={loadingJurisdictions}
        />
      </FormCol>

      <FormCol>
        <ControlledRadioList
          control={control}
          name='employment_term'
          error={errors?.employment_term}
          horizontal
          label={<LabelContent required>Employment Term:</LabelContent>}
          options={employmentTermOptions}
          isFancy
        />
      </FormCol>

      <FormCol />

      <FormCol>
        <ControlledSelect
          control={control}
          name='seniority'
          error={errors?.seniority}
          label={<LabelContent required>Seniority:</LabelContent>}
          options={seniorities?.map((s) => ({
            label: typeof s === 'string' ? s : s?.name,
            value: typeof s === 'string' ? s : s?.id,
          }))}
        />
      </FormCol>

      <FormCol>
        <ControlledInput
          control={control}
          name='department'
          error={errors?.department}
          label={<LabelContent>Department</LabelContent>}
          placeholder='i.e. Marketing, Sales, Tech, etc.'
        />
      </FormCol>
    </Row>
  )
}

function CompensationForm({ control, errors }) {
  const { formData } = useFormData()
  const jurisdiction = formData?.[dataKeys.jurisdiction]

  return (
    <Row className='mx-n3'>
      <FormCol>
        <YearlySalaryField
          control={control}
          currencyOptions={formData[dataKeys.jurisdictionId]?.map((c) =>
            mapCurrencyToOption(c, 'id'),
          )}
          errors={errors}
          label={<LabelContent required>Base salary:</LabelContent>}
        />
      </FormCol>

      <FormCol>
        <ProbationPeriodField
          control={control}
          errors={errors}
          jurisdiction={jurisdiction}
        />
      </FormCol>

      <FormCol>
        <NoticePeriodField
          control={control}
          errors={errors}
          jurisdiction={jurisdiction}
        />
      </FormCol>
    </Row>
  )
}

export default ContractDetail
