import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Col, Row, Spinner } from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'

import filedownload from '../../../assets/images/download.svg'
import fileIcon from '../../../assets/images/fileIcon.svg'
import trash from '../../../assets/images/Trash.svg'
import NoContent from '../../../components/NoContent'
import StyledTd from '../../../components/Table/StyledTd'
import StyledTh from '../../../components/Table/StyledTh'
import TableComp from '../../../components/Table/TableComp'
import TableH from '../../../components/Table/TableH'
import WiseButton from '../../../components/WiseButton'
import { userTypes } from '../../../helpers/enum'
import { useFetch, useResize } from '../../../helpers/hooks'
import {
  deleteContractorDocument,
  downloadPayslip,
  getFullTimeContractDetails,
  getPayslipsList,
} from '../../../services/api'
import { updateContract } from '../../../store/contract/actions'
import AddPayslipModal from './addPayslipModal'
import emptyDocs from '../../../assets/images/empty_docs.svg'

const Payslips = ({ isShorted }) => {
  const contract = useSelector((state) => state?.Contract?.details)
  const user = useSelector((state) => state?.Account?.user)
  const isEmployee = useSelector(
    (state) => state.userProfile?.userProfile?.contractor_type === 'employee',
  )
  const [show, setShow] = useState(false)
  const list = useFetch(
    {
      action: getPayslipsList,
      body: isEmployee ? null : { contract_id: contract?.id },
      initResult: [],
      autoFetch: true,
    },
    [show],
  )

  const dispatch = useDispatch()
  useFetch({
    action: getFullTimeContractDetails,
    autoFetch: true,
    onComplete: (data) => {
      dispatch(updateContract(data))
    },
  })

  return (
    <>
      {list.isLoading ? (
        <Col style={{ minHeight: isShorted ? '20vh' : '50vh' }}>
          <Row
            style={{ minHeight: isShorted ? '20vh' : '50vh' }}
            className='justify-content-center align-items-center'
          >
            <Spinner type='grow' className='mr-2' color='primary' />
          </Row>
        </Col>
      ) : list.data.length === 0 ? (
        <NoContent
          headline={'No payslips added'}
          subtitle={
            'Your payslips will be shown here when added by the employer'
          }
          image={emptyDocs}
          withAction={user?.type === userTypes.CONTRACTOR && !isEmployee}
          actionTitle={'Add a New Payslip'}
          onAction={() => setShow(!show)}
          advanced={!!isEmployee}
        />
      ) : (
        <Card
          style={{
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 1px 0px #DFE1E6',
            borderRadius: 4,
            overflow: 'visible',
          }}
          className='  p-0 m-0 mb-5'
        >
          <div className='overflow-visible'>
            <TableH
              title={'Your Payslips'}
              leftSide={
                user?.type === userTypes.CONTRACTOR && !isEmployee ? (
                  <WiseButton
                    onClick={() => setShow(!show)}
                    text={'Add a New Payslip'}
                    bold
                    minHeight={'48px'}
                    minWidth={'100%'}
                  />
                ) : null
              }
            />

            <PayslipList data={list.data} loading={list.isLoading} />
          </div>
        </Card>
      )}
      <AddPayslipModal show={show} hide={() => setShow(false)} />
    </>
  )
}

export const PayslipList = ({ data, loading, isShorted }) => {
  const isMobile = useResize()

  return (
    <TableComp>
      {!isMobile && (
        <thead className='thead-light'>
          <tr>
            <StyledTh>Name</StyledTh>
            <StyledTh className='text-right'>Actions</StyledTh>
          </tr>
        </thead>
      )}
      {data?.map((e, i) => (
        <PayslipCard key={`payslip-${i}`} item={e} />
      ))}
    </TableComp>
  )
}

const PayslipCard = ({ item, update = () => {}, select }) => {
  const date = moment(new Date(item?.year, item?.month))
  const isEmployee = useSelector(
    (state) => state.userProfile?.userProfile?.contractor_type === 'employee',
  )
  const isMobile = useResize()

  const download = useFetch({
    action: downloadPayslip,
    onComplete: (data) => {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${date.format('MMM - YYYY')}.pdf`) // or any other extension
      document.body.appendChild(link)
      link.click()
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const deleteDoc = useFetch({
    action: deleteContractorDocument,
    onComplete: update,
    onError: (err) => {
      toastr.error(err)
    },
  })
  const handleDownload = () => {
    download.startFetch({
      payslip_id: item.id,
    })
  }
  const handleDelete = () => {
    deleteDoc.startFetch({
      payslip_id: item.id,
    })
  }
  return !isMobile ? (
    <tr>
      <StyledTd>{date.format('MMM - YYYY')}</StyledTd>
      <StyledTd>
        <div className='d-none d-md-flex align-items-center justify-content-end'>
          {!isEmployee && false && (
            <>
              {deleteDoc.isLoading ? (
                <i className='bx bx-loader bx-spin font-size-16 align-middle mr-2' />
              ) : (
                <>
                  <a onClick={handleDelete}>
                    <img src={trash} style={{ height: 20 }} alt={''} />
                  </a>
                </>
              )}
              <div className='mx-2' />
            </>
          )}
          {download?.isLoading ? (
            <i className='bx bx-loader bx-spin font-size-16 align-middle mr-2' />
          ) : (
            <>
              <a onClick={handleDownload}>
                <img src={filedownload} style={{ height: 20 }} alt={''} />
              </a>
            </>
          )}
        </div>
      </StyledTd>
    </tr>
  ) : (
    <StyledCard>
      <Col className='p-0 m-0'>
        <Row className='d-flex justify-content-start p-0 m-0 mb-3 mb-md-0 justify-content-center justify-content-md-start align-items-center'>
          <img src={fileIcon} style={{ width: 24 }} alt='file icon' />
          <StyledP className='mb-0 ml-2'>{date.format('MMM - YYYY')}</StyledP>
        </Row>
      </Col>
      <Col className='w-100 justify-content-end d-flex p-0'>
        <div className='d-flex d-md-none w-100 justify-content-md-between justify-content-center'>
          <WiseButton
            textColor={'dark'}
            onClick={handleDownload}
            bk={'white'}
            border
            loading={download?.isLoading}
            minWidth={'45%'}
            text={'Download'}
            icon={
              <img
                src={filedownload}
                style={{ width: '0.8em', marginRight: 8 }}
                alt='download icon'
              />
            }
          />
          {!isEmployee && false && (
            <WiseButton
              textColor={'danger'}
              onClick={handleDelete}
              bk={'white'}
              border
              loading={deleteDoc?.isLoading}
              minWidth={'45%'}
              text={'Remove'}
              icon={
                <img
                  src={trash}
                  style={{ width: '0.8em', marginRight: 8 }}
                  alt='download icon'
                />
              }
            />
          )}
        </div>
      </Col>
    </StyledCard>
  )
}
const StyledCard = styled.div`
  padding: 28px;
  align-items: center;
  display: flex;
  margin-bottom: 16px;
  background: #ffffff;
  border: 1px solid #e7e8f2;
  box-sizing: border-box;
  border-radius: 4px;
  @media (max-width: 425px) {
    display: block;
    padding: 16px;
  }
`
const StyledP = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 130.02%;
  color: #4a4e5b;
`

export default Payslips
