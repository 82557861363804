import copy from 'copy-to-clipboard'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share'
import { Label } from 'reactstrap'
import toastr from 'toastr'

import Button from '../../../components/ui/button'
import Shimmer from '../../../components/ui/shimmer'
import { useFetch, useResize } from '../../../helpers/hooks'
import { referralLink, requestNewLink } from '../../../services/api'
import { getErrors } from '../../../utils/get-errors'

function isEmail(email) {
  return /[\w\d-]+@[\w\d-]+[\w\d-]+/.test(email)
}
function isInList(email, tags) {
  return tags.includes(email)
}
const SharingSection = ({
  showPreview,
  emails,
  setEmails,
  loading,
  sendInvitations,
}) => {
  const user = useSelector((state) => state.Account?.user)
  const isMobile = useResize()
  const [sharingLink, setSharingLink] = useState(null)

  const { startFetch: requestSharingLink } = useFetch({
    action: requestNewLink,
    onComplete: (data) => {
      setSharingLink(data?.shareUrl)
    },
    onError: (err) => {
      toastr.error(getErrors(err))
    },
  })
  useFetch({
    action: referralLink,
    autoFetch: true,
    body: { id: user?.id },
    onComplete: (data) => {
      setSharingLink(data?.shareUrl)
    },
    onError: (err) => {
      if (err?.error === 'Not Found') {
        requestSharingLink({
          id: user?.id,
          email: user?.email,
          firstName: user?.first_name,
          lastName: user?.last_name,
          type: user?.type,
        })
      } else {
        toastr.error(getErrors(err))
      }
    },
  })

  function copyToClipboard(value, name) {
    copy(value)
    toastr.info('Link copied!')
  }

  return (
    <div
      className='d-flex card mb-0 rp-shadow-2 p-2'
      style={{ width: isMobile ? '100%' : '59%' }}
    >
      {sharingLink ? (
        <div className='p-2'>
          <p className='m-0 font-size-24 rp-font-gilroyB font-weight-600'>
            Refer RemotePass to your network today!
          </p>
          <p className='mt-3 font-size-13 rp-font-gilroyB font-weight-500'>
            Copy the link and Share!
          </p>
          <div className='d-flex p-1 align-items-center justify-content-between border'>
            <div className='font-size-14 text-muted'>{sharingLink}</div>
            <i
              className='bx bxs-copy'
              style={{ cursor: 'pointer' }}
              onClick={() => copyToClipboard(sharingLink, 'Link')}
            />
          </div>
          <div className='text-muted pt-2'>SHARE LINK ON</div>
          <div className='py-2' style={{ gap: '1.5rem' }}>
            <TwitterShareButton
              title={
                '@RemotePassCom has revolutionized our remote payroll and onboarding. They’re giving one month free for new companies — try it out today! 👉'
              }
              url={sharingLink}
              // hashtags={['remotepass', 'payroll']}
            >
              <Button
                color='rgba(231, 232, 242, 0.5)'
                className='px-4 py-2'
                style={{ backgroundColor: 'rgba(231, 232, 242, 0.5)' }}
              >
                <i
                  className='bx bxl-twitter font-size-24'
                  style={{ color: 'rgba(66, 103, 178, 1)' }}
                ></i>
              </Button>
            </TwitterShareButton>
            <FacebookShareButton
              url={sharingLink}
              // hashtag={'#remotepass'}
              className='ml-2'
            >
              <Button
                color='rgba(231, 232, 242, 0.5)'
                className='px-4 py-2'
                style={{ backgroundColor: 'rgba(231, 232, 242, 0.5)' }}
              >
                <i
                  className='bx bxl-facebook font-size-24'
                  style={{ color: 'rgba(66, 103, 178, 1)' }}
                ></i>
              </Button>
            </FacebookShareButton>
            <LinkedinShareButton url={sharingLink} className='ml-2'>
              <Button
                color='rgba(231, 232, 242, 0.5)'
                className='px-4 py-2'
                style={{ backgroundColor: 'rgba(231, 232, 242, 0.5)' }}
              >
                <i
                  className='bx bxl-linkedin-square font-size-24'
                  style={{ color: 'rgba(66, 103, 178, 1)' }}
                ></i>
              </Button>
            </LinkedinShareButton>
          </div>
          <div
            className='w-10'
            style={{
              background: '#E7E8F2',
              height: '1px',
            }}
          ></div>
          <div className='d-flex flex-column py-2'>
            <Label>Invite by email</Label>
            <div className='App'>
              <TagsInput tags={emails} setTags={setEmails} />
            </div>
          </div>
          <div
            className='d-flex align-items-center justify-content-end pt-1'
            style={{ gap: '1.5rem' }}
          >
            <Button
              outline
              color='secondary'
              onClick={() => showPreview(true)}
              disabled={loading || emails.length <= 0}
            >
              Preview email
            </Button>
            <Button
              loading={loading}
              onClick={sendInvitations}
              disabled={loading || emails.length <= 0}
            >
              Send email
            </Button>
          </div>
        </div>
      ) : (
        <Shimmer width='100%' height='100%' />
      )}
    </div>
  )
}
function TagsInput({ tags, setTags }) {
  const [emValue, setEmailValue] = useState('')
  const [error, setError] = useState(null)

  function isValid(email) {
    let error = null

    if (isInList(email, tags)) {
      error = `${email} has already been added.`
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`
    }

    if (error) {
      setError(error)

      return false
    }

    return true
  }
  function handleKeyDown(evt) {
    if ([',', ' '].includes(evt.key)) {
      evt.preventDefault()

      const value = emValue.trim()

      if (value && isValid(value)) {
        setTags([...tags, emValue])
        setEmailValue('')
      }
    }
  }
  function handleChange(evt) {
    setEmailValue(evt.target.value)
    setError(null)
  }
  function removeTag(index) {
    setTags(tags.filter((el, i) => i !== index))
  }

  return (
    <div>
      <div className='tags-input-container'>
        {tags.map((tag, index) => (
          <div className='email-tag-item' key={index}>
            <span className='text'>{tag}</span>
            <span className='close' onClick={() => removeTag(index)}>
              x
            </span>
          </div>
        ))}
        <input
          value={emValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          type='text'
          className='email-tags-input'
          placeholder='Enter email addresses separated by comma'
        />
      </div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  )
}
export default SharingSection
