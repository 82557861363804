import { AvForm } from 'availity-reactstrap-validation'
import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Col, FormGroup, Input, InputGroup, Label, Row } from 'reactstrap'
import toastr from 'toastr'

import { useFetch, useResize } from '../../../../helpers/hooks'
import { UploadFile } from '../../../../helpers/SvgIcons'
import { uploadWork } from '../../../../services/api'

const AnnexForm = forwardRef(function AnnexForm(
  { onSubmit, data, newAnnexes, onUploadStatusChanged },
  ref,
) {
  const [, setAnnexes] = useState([...data, ...newAnnexes])
  const [name, setName] = useState(null)
  const [path, setPath] = useState(null)

  const isMobile = useResize()
  const upload = useFetch({
    action: uploadWork,
    onComplete: (data) => {
      setPath(data?.path)
    },
    onError: (error) => {
      toastr.error(error)
    },
  })
  useEffect(() => {
    onUploadStatusChanged(upload.isLoading)
  }, [upload.isLoading])
  const { acceptedFiles, getRootProps, getInputProps, inputRef } = useDropzone({
    acceptedFiles: 'application/pdf',
  })
  const files = acceptedFiles.map((file) => <p key={file.path}>{file.path}</p>)
  const removeAll = () => {
    if (inputRef && inputRef.current) {
      acceptedFiles.length = 0
      acceptedFiles.splice(0, acceptedFiles.length)
      inputRef.current.value = ''
    }
  }
  useEffect(
    useCallback(() => {
      if (acceptedFiles?.length > 0) {
        if (acceptedFiles[0].size > 2000000) {
          toastr.error('The file may not be greater than 2MB')
          removeAll()
        } else {
          upload.startFetch({
            file: acceptedFiles[0],
            type: 'annex',
          })
        }
      }
    }, [acceptedFiles]),
    [acceptedFiles],
  )
  useEffect(() => {
    setAnnexes([...data, ...newAnnexes])
  }, [newAnnexes])
  return (
    <AvForm
      ref={ref}
      onValidSubmit={() => {
        onSubmit(name, path)
      }}
    >
      <div>
        <Row>
          <Col md={12} xs={12}>
            <FormGroup>
              <Label for='basicpill-pancard-input6'>Name</Label>
              <InputGroup>
                <Input
                  type='text'
                  id='name'
                  className='form-control'
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value)
                  }}
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col>
            <section className='p-0'>
              <div className='dropzone border-primary'>
                <div
                  className='dz-message needsclick bg-soft-primary'
                  {...getRootProps()}
                  onClick={upload.error ? () => {} : getRootProps().onClick}
                >
                  <input {...getInputProps()} accept='application/pdf' />

                  {!!acceptedFiles && acceptedFiles?.length > 0 ? (
                    <Row className='font-size-14 justify-content-between px-3'>
                      {acceptedFiles?.length ? files : 'file uploaded'}
                      <div>
                        {upload.isLoading && (
                          <i className='bx bx-loader bx-spin text-primary mx-2'></i>
                        )}

                        <a onClick={removeAll}>
                          <i className='bx bx-trash text-danger' />
                        </a>
                      </div>
                    </Row>
                  ) : (
                    <>
                      <div className='py-4'>
                        <UploadFile />
                      </div>
                      <Row>
                        <Col className='justify-content-center'>
                          {isMobile ? (
                            <h4>Click to upload files.</h4>
                          ) : (
                            <h3>Drop files here or click to upload.</h3>
                          )}
                          <p className='text-muted font-size-12'>
                            *PDF only. Max file size 2MB
                          </p>
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
              </div>
            </section>
          </Col>
        </Row>
      </div>
    </AvForm>
  )
})

export default AnnexForm
