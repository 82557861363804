import React from 'react'

const RadioGroup = ({ options, defaultValue, onChange }) => {
  return (
    <div className='d-flex flex-column gap-10'>
      {React.Children.toArray(
        options.map((e, i) => (
          <RadioItem
            key={i}
            option={e}
            isDefault={e?.value === defaultValue?.value}
            onSelect={(event) => {
              if (event.target.checked) {
                onChange(e)
              }
            }}
          />
        )),
      )}
    </div>
  )
}

const RadioItem = ({ option, isDefault, onSelect }) => {
  return (
    <div className='form-check hover:bg-light rounded pl-4'>
      <input
        className='form-check-input cursor-pointer'
        onClick={onSelect}
        type='radio'
        name='exampleRadios'
        id={`exampleRadios${option?.value}`}
        value={option?.value}
        defaultChecked={isDefault}
      />
      <label
        className='form-check-label py-1 w-100'
        htmlFor={`exampleRadios${option?.value}`}
      >
        <span>{option.label}</span>
        {!option?.description ? null : (
          <p className='text-muted mb-0'>{option?.description}</p>
        )}
      </label>
    </div>
  )
}
export default RadioGroup
