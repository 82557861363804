import React, { useEffect, useMemo, useState } from 'react'
import { Col, Row } from 'reactstrap'

import { CardItem } from '../../components/CardSelector'
import FEATURE_FLAGS from '../../config/feature-flags'
import {
  ACH,
  BANK_TRANSFER,
  BREX,
  CREDIT_CARD,
  SEPA_DEBIT,
} from '../../core/config/payment-methods'
import { getCurrencyFormatter } from '../../utils/formatters/currency'

export const initialMethods = [
  {
    label: 'Credit / Debit Card',
    description: 'Fee depends on the cash out currency',
    id: CREDIT_CARD.id,
    type: 'card',
  },
  {
    label: BANK_TRANSFER.name,
    description: '2.5% Fee',
    id: BANK_TRANSFER.id,
    type: 'bank_transfer',
  },
  {
    label: SEPA_DEBIT.name,
    description: '2.5% Fee',
    id: SEPA_DEBIT.id,
    type: 'sepa_debit',
  },
  {
    label: ACH.name,
    description: '2.5% Fee',
    id: ACH.id,
  },
  !FEATURE_FLAGS.PAYMENT_METHOD_BREX
    ? null
    : { label: BREX.name, description: '', id: BREX.id },
]?.filter(Boolean)

function formatFee(formatter, amount) {
  if (amount === 0) {
    return 'No fees'
  } else if (amount) {
    return `${formatter.format(amount)} Fee`.trim()
  }

  return ''
}

const PaymentMethods = ({
  onSelected,
  data,
  bankMethods,
  currency,
  isEditing,
  noACH,
  isInsurance,
  requestAccess,
  isItemSelected,
}) => {
  const [options, setOptions] = useState(isInsurance ? data : initialMethods)

  const formatter = useMemo(() => {
    return getCurrencyFormatter(currency?.code)
  }, [currency?.code])

  useEffect(() => {
    if (data && !isInsurance) {
      setOptions((options) => {
        function formatItem(item) {
          const dataItem = data?.find((f) => f?.id === item?.id)

          const element = {
            ...dataItem,
            ...item,
            description: formatFee(formatter, dataItem?.fee),
          }
          return element
        }

        const formattedOptions = options.map((item) => formatItem(item))

        return formattedOptions.filter(
          (t) =>
            !!t?.id &&
            (!noACH || t?.id !== ACH.id) &&
            (!isEditing || t?.id !== BANK_TRANSFER.id),
        )
      })
    }
  }, [data, formatter, isEditing, isInsurance, noACH])

  return (
    <Row className='mx-n2'>
      {options
        .reduce(function (result, _, index, array) {
          if (index % 2 === 0) {
            result.push(array.slice(index, index + 2))
          }
          return result
        }, [])
        .map((e, i) => (
          <React.Fragment key={i}>
            {e?.map((item, index) => (
              <Col key={`${item.id}-${index}`} md={6} className='px-2'>
                <CardItem
                  selected={isItemSelected(item)}
                  key={item.value}
                  isEditing={isEditing}
                  item={item}
                  onSelect={() => onSelected(item)}
                  requestAccess={() => requestAccess(item)}
                />
              </Col>
            ))}
          </React.Fragment>
        ))}

      {bankMethods?.map((item) => {
        const formattedItem = {
          ...item,
          description: formatFee(formatter, item?.fee),
        }

        return (
          <Col key={item.id} md={6} className='px-2'>
            <CardItem
              key={item.value}
              isEditing={isEditing}
              item={formattedItem}
              onSelect={() => onSelected(item)}
              requestAccess={() => requestAccess(item)}
            />
          </Col>
        )
      })}
    </Row>
  )
}

export default PaymentMethods
