import { Clock, ClockCountdown } from '@phosphor-icons/react'
import React from 'react'

export const qualificationOptions = [
  { label: 'Employee', value: 'Employee' },
  { label: 'Manager', value: 'Manager' },
]

export const employmentTypeOptions = [
  { label: 'Full time', value: 'full-time' },
  { label: 'Part-time', value: 'part-time' },
]

export const definiteValue = 'definite'
export const employmentTermOptions = [
  {
    label: 'Definite',
    value: definiteValue,
    icon: () => <Clock width='100%' height='100%' />,
  },
  {
    label: 'Indefinite',
    value: 'indefinite',
    icon: () => <ClockCountdown width='100%' height='100%' />,
  },
]

export const genderOptions = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
]
