import classnames from 'classnames'
import React, { useState } from 'react'
import {
  Card,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap'
import styled from 'styled-components'

import CustomSelect from '../../components/Forms/CustomSelect/CustomSelect'
import Button from '../../components/ui/button'
import Users from './Users'

const memberStatusOptions = [
  { label: 'Active Users', value: '1' },
  { label: 'Archived Users', value: '2' },
]

const MemberAdmin = () => {
  const [activeTab, setActiveTab] = useState('1')
  const [flag, setFlag] = useState(false)
  const [show, setShow] = useState(false)

  return (
    <div>
      <Row className='bg-white m-0 d-block d-md-none'>
        <div className='p-3 border-bottom'>
          <CustomSelect
            name='Active'
            value={activeTab.label}
            defaultValue={memberStatusOptions[0]}
            onChange={(v) => setActiveTab(v.value)}
            options={memberStatusOptions}
          />
        </div>
        <div className='p-3'>
          <Button
            onClick={() => setShow(true)}
            icon={<i className='bx bx-plus font-size-20' />}
          >
            New User
          </Button>
        </div>
      </Row>

      <Row className='bg-white d-none d-md-flex m-0 py-3 px-4 justify-content-between align-items-center'>
        <StyledNav>
          {memberStatusOptions.map((item, index) => {
            return (
              <NavItem key={index}>
                <NavLink
                  className={classnames('font-size-14 cursor-pointer', {
                    active: activeTab === item.value,
                  })}
                  onClick={() => {
                    setActiveTab(item.value)
                  }}
                >
                  {item.label}
                </NavLink>
              </NavItem>
            )
          })}
        </StyledNav>
        <Button
          className='py-2'
          icon={<i className='bx bx-plus font-size-20' />}
          onClick={() => setShow(true)}
        >
          New User
        </Button>
      </Row>

      <TabContent activeTab={activeTab}>
        {activeTab === '1' && (
          <TabPane tabId='1'>
            <Card className='p-0 m-0'>
              <Users
                show={show}
                hide={() => setShow(false)}
                flag={flag}
                onUpdate={() => setFlag(!flag)}
              />
            </Card>
          </TabPane>
        )}
        {activeTab === '2' && (
          <TabPane tabId='2'>
            <Card className='p-0 m-0'>
              <Users
                show={show}
                hide={() => setShow(false)}
                archived
                flag={flag}
                onUpdate={() => setFlag(!flag)}
              />
            </Card>
          </TabPane>
        )}
      </TabContent>
    </div>
  )
}

const StyledNav = styled(Nav)`
  flex-wrap: nowrap !important;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 1.5rem;

  .nav-item {
    .nav-link {
      color: #777f9e;
      padding: 0.5rem 0;
      border-bottom: 2px solid #fff;

      &:hover {
        border-right-color: #f4f5f7 !important;
        border-left-color: #f4f5f7 !important;
        border-top-color: #f4f5f7 !important;
      }
    }
    .active {
      color: var(--primary);
      font-weight: bold;
      border: 0;
      border-bottom: 2px solid var(--primary);
    }
  }
`

export default MemberAdmin
