import React, { useState } from 'react'
import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'

// Redux
import { connect, useDispatch } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

// availity-reactstrap-validation
import { AvForm } from 'availity-reactstrap-validation'

// action
import { requestForgetPassword, userForgetPassword } from '../../store/actions'

import TextInput from '../../components/Forms/TextInput'
import { StyledH5, StyledH6 } from '../../components/Typo'
import Button from '../../components/ui/button'
import { useFetch } from '../../helpers/hooks'
import { resetPassword } from '../../services/api'
import logoMain from './../../assets/images/identity/logo_main.png'

const ForgetPasswordPage = (props) => {
  const dispatch = useDispatch()
  const [sent, setSent] = useState(false)
  const resetPass = useFetch({
    action: resetPassword,
    onComplete: (data, body) => {
      setSent(true)
      dispatch(requestForgetPassword(body?.email))
      // props.history.push('/update-password')
      window.analytics.track('Forgot password', {
        email_id: body?.email,
      })
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  return (
    <React.Fragment>
      <StyledDiv className='d-flex justify-content-center align-items-center'>
        <img src={logoMain} height={24} />{' '}
      </StyledDiv>
      <div className='account-pages '>
        <Container className={'p-0 '}>
          <Row className='justify-content-center p-0 m-0'>
            <Row className='justify-content-center mt-4 mt-md-5  mb-3 mb-md-4   mx-0 '>
              <StyledH5
                min={'22px'}
                max={'32px'}
                className={'mb-2 mb-md-3 text-center w-100'}
              >
                Can’t remember your password?
              </StyledH5>
              <StyledH6
                style={{ lineHeight: '24px' }}
                min={'14px'}
                max={'14px'}
                className={
                  'px-3 px-md-4 mb-0 w-100  text-center text-secondary font-weight-light'
                }
              >
                Not a problem! Just enter your email address and we’ll send you
                a link to reset it.
              </StyledH6>
            </Row>
            <Col md={8} lg={6} xl={5} className={'p-3 p-md-0 m-0'}>
              <Card
                style={{
                  background: '#FFFFFF',
                  boxShadow: '0px 1px 0px #DFE1E6',
                  borderRadius: 4,
                }}
                className='overflow-hidden p-3 p-md-4'
              >
                <CardBody className='p-0 m-0'>
                  <div className='p-0'>
                    {props.forgetError && props.forgetError ? (
                      <Alert color='danger' style={{ marginTop: '13px' }}>
                        {props.forgetError}
                      </Alert>
                    ) : null}
                    {props.forgetSuccessMsg ? (
                      <Alert color='success' style={{ marginTop: '13px' }}>
                        {props.forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <AvForm
                      className='form-horizontal '
                      onValidSubmit={(e, v) => {
                        resetPass.startFetch(v)
                      }}
                    >
                      <div className='form-group mb-4'>
                        <TextInput
                          name='email'
                          label='Email'
                          placeholder='Enter email'
                          type='email'
                          required
                        />
                      </div>
                      {sent && (
                        <Row className='justify-content-center  m-0 p-0 mb-3'>
                          <div
                            className={'p-3 w-100'}
                            style={{
                              backgroundColor: '#F2F5FC',
                              border: '1px solid  #114EF7',
                              borderRadius: 4,
                            }}
                          >
                            <p
                              className={
                                '   font-size-14 font-weight-normal text-primary mb-0'
                              }
                            >
                              <i className=' bx bx-info-circle text-primary font-size-18 font-weight-semibold mr-2'></i>
                              If your email address exists in our database,
                              you’ll receive an email with instructions to reset
                              your password.
                            </p>
                          </div>
                        </Row>
                      )}
                      <Row className='form-group p-0 m-0'>
                        <Button
                          block
                          type='submit'
                          loading={resetPass.isLoading}
                          disabled={resetPass.isLoading}
                          className='font-size-14'
                        >
                          {sent ? 'Resend' : 'Send reset link'}
                        </Button>
                      </Row>
                    </AvForm>
                    <div className='mt-3 text-center d-flex justify-content-start align-items-center'>
                      <Link
                        to='/login'
                        className='text-primary mb-0 font-size-14 font-weight-medium'
                      >
                        Go back to the login page
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className='mt-5 text-center'>
                {/* <p> */}
                {/*                    Go back to{' '} */}
                {/*  <Link */}
                {/*    to="login" */}
                {/*    className="font-weight-medium text-primary" */}
                {/*  > */}
                {/*                        Login */}
                {/*  </Link>{' '} */}
                {/* </p> */}
                <p className={'text-secondary font-weight-light font-size-14'}>
                  © {new Date().getFullYear()} RemotePass.{' '}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
const StyledDiv = styled.div`
  height: 80px;
  background-color: #ffffff;
  box-shadow: 0px 0px 8px #eaeaea, inset 0px -1px 0px #eaeaea;
  @media (max-width: 768px) {
    height: 56px;
  }
`

const mapStatetoProps = (state) => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword
  return { forgetError, forgetSuccessMsg }
}

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ForgetPasswordPage),
)
