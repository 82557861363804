import React, { useCallback, useState } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'

import Button from '../../../../components/ui/button'

const formId = 'unprocess-form'

export default function UnprocessModal({
  isOpen,
  transaction,
  isLoading,
  toggle,
  onConfirm,
}) {
  const [reason, setReason] = useState('')

  const handleConfirm = useCallback(
    (e) => {
      e.preventDefault()
      onConfirm(transaction.transaction_id, reason)
    },
    [onConfirm, reason, transaction.transaction_id],
  )

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader className='d-flex justify-content-between' toggle={toggle}>
        Unprocess Withdrawal
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleConfirm} id={formId}>
          <FormGroup>
            <Label for='unprocess-reason'>Reason:</Label>
            <Input
              id='unprocess-reason'
              name='unprocess-reason'
              type='textarea'
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              required
            />
          </FormGroup>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          type='button'
          color='light'
          outline
          disabled={isLoading}
          onClick={toggle}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          formId={formId}
          disabled={isLoading}
          loading={isLoading}
          color={isLoading ? 'light' : 'primary'}
        >
          {isLoading ? 'Unprocessing ...' : 'Unprocess Withdrawal'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
