import React, { useEffect, useState } from 'react'
import {
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap'
import styled from 'styled-components'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import DatePicker from 'react-datepicker'
import {
  getContractAttribute,
  submitWork,
  uploadWork,
} from '../../../services/api'
import { useSelector } from 'react-redux'
import moment from 'moment'
import toastr from 'toastr'
import { StyledH5 } from '../../../components/Typo'
import WiseButton from '../../../components/WiseButton'
import { useFetch, useResize } from '../../../helpers/hooks'
import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import CustomDatePicker from '../../../components/Forms/CustomDatePicker/CustomDatePicker'
import { useDropzone } from 'react-dropzone'
import expenseImage from './../../../assets/images/expense.svg'

const submissionDates = [
  {
    value: 'Work for ' + moment(new Date()).format('MMMM YYYY'),
    label: moment(new Date()).format('MMMM YYYY'),
  },
  {
    value:
      'Work for ' + moment(new Date()).add(-1, 'months').format('MMMM YYYY'),
    label: moment(new Date()).add(-1, 'months').format('MMMM YYYY'),
  },
]
const SubmitWorkModal = ({ toggle, isOpen, contract, onWorkSubmitted }) => {
  const [date, setDate] = useState(new Date())
  const [submissionDate, setSubmissionDate] = useState(null)
  const [value, setValue] = useState(null)
  const [extraData, setExtraData] = useState([])
  const [loading, setLoading] = useState(false)
  const [addAttachment, setAddAttachment] = useState(false)
  const getRate = (id) => {
    switch (id) {
      case 1:
        return 'Hours'
      case 2:
        return 'Days'
      case 3:
        return 'Months'
      case 4:
        return 'Minutes'
      case 5:
        return 'Task'
    }
  }
  const fields = useFetch({
    action: getContractAttribute,
    autoFetch: true,
    initResult: [],
    body: { contract_id: contract.id },
  })
  const user = useSelector((state) => state.Account?.user)

  const { acceptedFiles, getRootProps, getInputProps, inputRef } = useDropzone({
    acceptedFiles: 'image/jpeg,image/png,image/gif',
  })
  const isMobile = useResize()
  const removeAll = () => {
    if (inputRef && inputRef.current) {
      acceptedFiles.length = 0
      acceptedFiles.splice(0, acceptedFiles.length)
      inputRef.current.value = ''
    }
  }
  const upload = useFetch({
    action: uploadWork,
  })
  const files = acceptedFiles.map((file) =>
    upload.isLoading ? (
      <i className='bx bx-loader bx-spin text-primary font-size-20 ml-3' />
    ) : (
      <p key={file.path}>{file.path}</p>
    ),
  )

  useEffect(() => {
    if (acceptedFiles?.length > 0) {
      upload.startFetch({ file: acceptedFiles[0], type: 'work' })
    }
  }, [acceptedFiles])

  return (
    <Modal
      className='submitWorkModal '
      isOpen={isOpen}
      toggle={toggle}
      centered
    >
      <AvForm
        onValidSubmit={(e, v) => {
          if (!contract?.monthly_rate && (!value || !date)) {
            toastr.error('You need to fill all fields')
            return
          }
          for (let i = 0; i < fields.data?.length; i++) {
            const f = fields.data[i]
            const value = extraData.find((e) => e?.id === f?.id)?.value
            if (f?.required && !value) {
              toastr.error(`${f?.name} is required`)
              return
            }
          }
          setLoading(true)
          submitWork(user?.token, {
            ...v,
            details: contract?.monthly_rate ? submissionDate : v.details,
            submitted_at: moment(date).format('YYYY-MM-DD'),
            contract_id: contract.id,
            qty: contract?.monthly_rate ? 1 : value,
            attributes: extraData?.filter((e) => !!e),
            file: upload.data?.path,
          })
            .then((r) => {
              window.analytics.track('Added work', {
                contract_id: contract.id,
                contract_type: contract.type,
                work_name: v?.details,
                work_id: r?.data?.data?.id,
                work_value: v?.qty,
                currency: contract.currency?.code,
              })

              setLoading(false)
              if (r.data.success) {
                toggle()
                onWorkSubmitted()
              }
            })
            .catch((e) => {
              setLoading(false)
            })
        }}
      >
        <div className='modal-content'>
          {loading ? (
            <Col style={{ minHeight: '10rem' }}>
              <Row
                style={{ minHeight: '10rem' }}
                className='justify-content-center align-items-center'
              >
                <Spinner type='grow' className='mr-2' color='primary' />
              </Row>
            </Col>
          ) : (
            <>
              <ModalHeader toggle={toggle}>Submit work</ModalHeader>
              <StyledModalBody className='modal-body'>
                <Row className='justify-content-center'>
                  <Col>
                    {!contract?.monthly_rate && (
                      <FormGroup>
                        <Label>Date</Label>
                        <DatePicker
                          name='end-date'
                          className='form-control'
                          selected={date}
                          onChange={setDate}
                          minDate={
                            new Date(
                              moment(
                                contract?.start_date,
                                'MMM DD, YYYY',
                              ).toISOString(),
                            )
                          }
                          maxDate={new Date()}
                          placeholderText='Date'
                        />
                      </FormGroup>
                    )}

                    {!contract?.monthly_rate && (
                      <FormGroup>
                        <Label>Name</Label>
                        <AvField
                          name='details'
                          type='text'
                          required={!contract?.monthly_rate}
                          placeholder='Name'
                          id='basicpill-pancard-input7'
                          className='form-control'
                        />
                      </FormGroup>
                    )}
                    {!contract?.monthly_rate && (
                      <FormGroup>
                        <Label>Value</Label>
                        <InputGroup>
                          <input
                            className='form-control'
                            required={!contract?.monthly_rate}
                            placeholder='Value'
                            name='qty'
                            type='text'
                            value={value}
                            onKeyPress={(e) => {
                              if (!/[0-9.]/.test(e.key)) {
                                e.preventDefault()
                              }
                            }}
                            onChange={(e) => {
                              setValue(e.target.value)
                            }}
                          />
                          <InputGroupAddon addonType='append'>
                            <InputGroupText>
                              {getRate(contract?.rate_id?.id) ??
                                contract?.rate_id?.name}
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    )}
                    {contract.monthly_rate === 1 && (
                      <FormGroup>
                        <Label>Month</Label>
                        <CustomSelect
                          options={submissionDates}
                          onChange={(v) => {
                            setSubmissionDate(v.value)
                          }}
                        />
                      </FormGroup>
                    )}
                    {fields.data?.map((e, i) => {
                      if (e.type === 'text') {
                        return (
                          <FormGroup>
                            <Label>{e.name}</Label>
                            <AvField
                              name={`attr-${e.name}`}
                              type='text'
                              required={!!e.required}
                              placeholder={e.name}
                              className='form-control'
                              onChange={(event) => {
                                const newData = [...extraData]
                                newData[i] = {
                                  id: e.id,
                                  value: event.target.value,
                                }
                                setExtraData(newData)
                              }}
                            />
                          </FormGroup>
                        )
                      }
                      if (e.type === 'selection') {
                        return (
                          <FormGroup>
                            <Label>{e.name}</Label>
                            <CustomSelect
                              options={e.options.map((o) => ({
                                label: o.name,
                                value: o.name,
                              }))}
                              onChange={(v) => {
                                const newData = [...extraData]
                                newData[i] = { id: e.id, value: v.value }
                                setExtraData(newData)
                              }}
                            />
                          </FormGroup>
                        )
                      }
                      if (e.type === 'date') {
                        return (
                          <FormGroup>
                            <Label>{e.name}</Label>
                            <CustomDatePicker
                              handleOnChange={(v) => {
                                const newData = [...extraData]
                                newData[i] = {
                                  id: e.id,
                                  value: moment(v).format('YYYY-MM-DD'),
                                }
                                setExtraData(newData)
                              }}
                              value={extraData[i]?.value}
                            />
                          </FormGroup>
                        )
                      }
                      return null
                    })}
                  </Col>
                </Row>
                <Row className='justify-content-center mt-3'>
                  <Col>
                    <div className='mb-3'>
                      <a
                        onClick={() => setAddAttachment(true)}
                        className='font-weight-bold text-primary'
                      >
                        Add attachment <i className='fas fa-paperclip' />
                      </a>
                    </div>
                    {addAttachment && (
                      <section className='p-0'>
                        <div className='dropzone border-primary'>
                          <div
                            className='dz-message needsclick bg-soft-primary'
                            {...getRootProps()}
                          >
                            <input
                              {...getInputProps()}
                              accept='application/pdf'
                            />

                            {!!acceptedFiles && acceptedFiles?.length > 0 ? (
                              <Row className='font-size-14 justify-content-between'>
                                {files}
                                <i
                                  onClick={removeAll}
                                  className='bx bx-trash text-danger'
                                />
                              </Row>
                            ) : (
                              <>
                                <div className='py-4'>
                                  <img src={expenseImage} alt={''} />
                                </div>
                                <Row>
                                  <Col className='justify-content-center'>
                                    {isMobile ? (
                                      <h4>Click to upload files.</h4>
                                    ) : (
                                      <StyledH5 max={'16px'}>
                                        Drop here or click to upload file{' '}
                                      </StyledH5>
                                    )}
                                  </Col>
                                </Row>
                              </>
                            )}
                          </div>
                        </div>
                      </section>
                    )}
                  </Col>
                </Row>
              </StyledModalBody>
              <div className='modal-footer d-flex justify-content-between justify-content-md-end'>
                <WiseButton
                  bk={'white'}
                  textColor={'dark'}
                  border
                  type='button'
                  className='btn btn-outline-light'
                  data-dismiss='modal'
                  width={'30%'}
                  minWidth={'45%'}
                  text={'Cancel'}
                  onClick={toggle}
                />

                <WiseButton
                  width={'30%'}
                  minWidth={'45%'}
                  text={'Add'}
                  type='submit'
                  className='btn btn-primary'
                />
              </div>
            </>
          )}
        </div>
      </AvForm>
    </Modal>
  )
}

const StyledModalBody = styled.div`
  @media (max-width: 754px) {
    height: 84vh;
  }
`
export default SubmitWorkModal
