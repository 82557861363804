import classnames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import { Link, useHistory, useLocation } from 'react-router-dom'
import {
  Badge,
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'

import bkBlue from '../../assets/images/bkblue.svg'
import ChangePassword from '../../components/CommonForBoth/ChangePassword'
import BadgeX from '../../components/Table/BadgeX'
import { StyledH3 } from '../../components/Typo'
import WiseButton from '../../components/WiseButton'
import { contractorTypes } from '../../helpers/enum'
import { useFetch } from '../../helpers/hooks'
import {
  getCompanyInfo,
  getContractorInfo,
  getContractorPaymentMethods,
  savePayoneerAccount,
  updateContractorProfile,
} from '../../services/api'
import { updateUserInfo } from '../../store/auth/register/actions'
import CompanyInfo from '../CompanySetting/CompanyInfo'
import ManageAvatar from '../CompanySetting/manage-avatar'
import WithdrawalMethod from '../Withdrawal/WithdrawalMethod'
import Kyc from './KYC'
import ProfileInfoNew from './ProfileInfoNew'

const ContractorSettings = (props) => {
  const [activeTab, setActiveTab] = useState('1')
  const formRef = useRef(null)
  const companyFormRef = useRef(null)
  const user = useSelector((state) => state?.Account?.user)
  const isEmployee =
    useSelector((state) => state?.userProfile?.userProfile?.contractor_type) ===
    'employee'
  const countries = useSelector((state) => state?.Layout?.staticData?.countries)
  const profile = useSelector((state) => state?.userProfile?.userProfile)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const history = useHistory()
  const location = useLocation()
  const payeeId = new URLSearchParams(location.search).get('payee_id')

  const { startFetch: savePayoneer } = useFetch({ action: savePayoneerAccount })

  useEffect(() => {
    if (payeeId) {
      setActiveTab('2')
      history.replace('/settings')
      savePayoneer({ payee_id: payeeId })
      toastr.success('You Payoneer account was added successfully', 'Success')
    }

    // Disabling exhaustive-deps because `startFetch` is not memoized
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payeeId, history])

  useEffect(() => {
    switch (props.location.hash) {
      case '#kyc':
        setActiveTab('3')
        break
      case '#profile':
        setActiveTab('1')
        break
      case '#accounts':
        setActiveTab('2')
        break
      case '#security':
        setActiveTab('4')
        break
    }
    if (
      props.location?.pathname?.includes('settings/paypal') ||
      props.location?.pathname?.includes('settings/coinbase')
    ) {
      setActiveTab('2')
    }
  }, [props.location])

  useEffect(() => {
    update()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const paymentMethods = useFetch({
    action: getContractorPaymentMethods,
    autoFetch: true,
    initialResult: [],
  })

  const info = useFetch(
    {
      action: getCompanyInfo,
      autoFetch: profile?.contractor_type === contractorTypes.ENTITY,
      onError: (err) => toastr.error(err),
    },
    profile,
  )

  const updateContractor = useFetch({
    autoFetch: false,
    checkSuccess: true,
    action: updateContractorProfile,
    onComplete: (data) => {
      setLoading(false)
      setData(data)
      dispatch(updateUserInfo(data || {}))
    },
    onError: (err) => {
      setLoading(false)
      toastr.error(err)
    },
  })
  const updatePhoto = (dataInfo) => {
    setLoading(true)
    updateContractor.startFetch(dataInfo)
  }

  const update = (isLoading = true) => {
    setLoading(isLoading)
    getContractorInfo(user?.token)
      .then((r) => {
        setLoading(false)
        if (r.data.success) {
          window.analytics.track('Viewed profile', { 'email-id': user?.email })
          setData(r.data.data)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <StyledContainer className='page-content position-relative'>
      <Row className='p-0 m-0'>
        <StyledGap>
          <StyledImgGap bk={bkBlue} />
        </StyledGap>
        <StyledCol
          xs={12}
          md={3}
          className='p-0 m-0 d-flex flex-column align-items-center'
        >
          <ManageAvatar
            src={user?.photo}
            name={user?.first_name}
            onUploadPhoto={(acceptedFiles) => {
              const file = acceptedFiles[0]
              if (file.size > 2000000) {
                toastr.error('The file may not be greater than 2MB')
              } else {
                const dataInfo = { photo: acceptedFiles[0] }
                updatePhoto(dataInfo)
              }
            }}
            isUploading={updateContractor.isLoading}
            avatarTitleClassName={
              user?.type === 'client' ? 'avatar-title-green' : 'avatar-title'
            }
          />

          <StyledH3
            min='22px'
            max='1.75em'
            mid='1.25em'
            style={{ fontWeight: 600 }}
            className='px-2 text-center text-dark'
            translate='no'
          >
            {user?.first_name} {user?.last_name}
          </StyledH3>
          <Row className='justify-content-center'>
            <Badge
              className='font-size-12 rounded bg-soft-self-badge mb-3'
              pill
            >
              <p style={{ padding: 2 }} className='mb-0  font-size-14  '>
                {isEmployee ? 'Employee' : 'Contractor'}
              </p>
            </Badge>
            <div className='p-1' />
            {!isEmployee && (
              <Badge
                className='font-size-12 rounded bg-soft-self-badge mb-3'
                pill
              >
                <p style={{ padding: 2 }} className='mb-0  font-size-14  '>
                  {profile?.contractor_type === contractorTypes.ENTITY
                    ? 'Entity'
                    : 'Individual'}
                </p>
              </Badge>
            )}
          </Row>

          {isEmployee ? null : !data ? (
            <BadgeX
              status='light'
              textStatus='secondary'
              className='d-flex gap-6'
            >
              <Spinner size='sm' /> Checking Verification
            </BadgeX>
          ) : data?.kyc_verified === 1 ? (
            <BadgeX className='d-flex gap-6' status='success'>
              <i className='bx bxs-check-shield font-size-16' />
              <p className='mb-0'>Identity verified</p>
            </BadgeX>
          ) : data?.kyc_status === 'submitted' ? (
            <BadgeX className='d-flex gap-6' status='warning'>
              <i className='bx bxs-hourglass font-size-16' />
              <p className='mb-0'>Verification in progress</p>
            </BadgeX>
          ) : (
            <BadgeX className='d-flex gap-6' status='danger'>
              <i className='bx bx-shield-x font-size-16' />
              <p className='mb-0 font-size-14'>Identity not verified</p>
            </BadgeX>
          )}
          <div className='border-top my-md-4 mb-3 mt-4  w-100' />
          <div className='px-4 mb-3 mb-md-4 w-100'>
            <Link to='/logout'>
              <WiseButton
                text='Log out'
                width={'100%'}
                border
                bk={'white'}
                textColor={'dark'}
                height={'48px'}
                minWidth={'100%'}
              />
            </Link>
          </div>
        </StyledCol>

        <Col xs={12} md={9} className='pl-md-4 p-0'>
          <Card className='overflow-visible' style={{ marginBottom: 150 }}>
            <StyledNav tabs>
              <NavItem>
                <NavLink
                  className={classnames('font-size-14', {
                    active: activeTab === '1',
                  })}
                  onClick={() => setActiveTab('1')}
                >
                  General Info
                </NavLink>
              </NavItem>
              {profile?.contractor_type === contractorTypes.ENTITY && (
                <NavItem>
                  <NavLink
                    className={classnames('font-size-14', {
                      active: activeTab === '5',
                    })}
                    onClick={() => setActiveTab('5')}
                  >
                    Company Info
                  </NavLink>
                </NavItem>
              )}

              <NavItem>
                <NavLink
                  className={classnames('font-size-14', {
                    active: activeTab === '2',
                  })}
                  onClick={() => setActiveTab('2')}
                >
                  Withdrawal Methods
                </NavLink>
              </NavItem>

              {!isEmployee && (
                <NavItem>
                  <NavLink
                    className={classnames('font-size-14', {
                      active: activeTab === '3',
                    })}
                    onClick={() => setActiveTab('3')}
                  >
                    Verification
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <NavLink
                  className={classnames('font-size-14', {
                    active: activeTab === '4',
                  })}
                  onClick={() => setActiveTab('4')}
                >
                  Security
                </NavLink>
              </NavItem>
            </StyledNav>

            <TabContent activeTab={activeTab}>
              <TabPane tabId='1'>
                {!!data && !!countries?.length ? (
                  <ProfileInfoNew
                    ref={formRef}
                    data={data}
                    countries={countries}
                  />
                ) : (
                  <CardBody style={{ minHeight: '50vh' }} className='p-0 m-0' />
                )}
              </TabPane>
              {profile?.contractor_type === contractorTypes.ENTITY && (
                <TabPane tabId='5'>
                  {!!info?.data && !!countries?.length && (
                    <CompanyInfo
                      onComplete={() => {}}
                      ref={companyFormRef}
                      countries={countries}
                      data={info?.data}
                    />
                  )}
                </TabPane>
              )}
              <TabPane
                tabId='2'
                id='v-pills-payment'
                role='tabpanel'
                aria-labelledby='v-pills-payment-tab'
              >
                <CardBody className='p-0 m-0'>
                  <WithdrawalMethod
                    data={data ? data['bank-accounts'] || [] : []}
                    paypal={data?.paypal_accounts || []}
                    payoneer={data?.payoneer_accounts || []}
                    cashPoints={data?.cash_points || []}
                    coinbase={data?.coinbase_accounts || []}
                    profile={data}
                    onUpdate={() => update(false)}
                    loading={loading}
                  />
                </CardBody>
              </TabPane>
              <TabPane tabId='3' id='v-pills-confirm' role='tabpanel'>
                <Card className='shadow-none mb-0'>
                  {activeTab === '3' && <Kyc />}
                </Card>
              </TabPane>
              <TabPane tabId='4' id='v-pills-confirm' role='tabpanel'>
                <ChangePassword />
              </TabPane>
              {paymentMethods.data?.length > 0 && (
                <TabPane
                  tabId='6'
                  id='v-pills-confirm'
                  role='tabpanel'
                ></TabPane>
              )}
            </TabContent>
          </Card>
        </Col>
      </Row>
    </StyledContainer>
  )
}

const StyledCol = styled(Col)`
  background: #ffffff;
  box-shadow: 0px 1px 0px #dfe1e6;
  border-radius: 4px;
  height: fit-content;
  z-index: 2;
`
const StyledImgGap = styled.img`
  width: 100%;
  background-color: #fff;
  height: 13rem;
  background-image: ${(props) => (props.bk ? `url(${props.bk})` : null)};
  background-repeat: repeat;
`
const StyledGap = styled.div`
  position: absolute;
  top: 35px;
  left: 0;
  right: 0;
  background-color: #114ef7;
  width: 100%;
  height: 19%;
  max-height: 13rem;
  @media (max-width: 768px) {
    height: 10%;
    top: 22px;
  }
`

const StyledNav = styled(Nav)`
  .nav-item {
    padding-right: 20px;
    padding-left: 20px;

    .nav-link {
      font-weight: 400;
      color: #777f9e;
      padding: 24px 0;

      &:hover {
        border-right-color: #fff !important;
        border-left-color: #fff !important;
        border-top-color: #fff !important;
      }
    }

    .active {
      color: var(--primary);
      font-weight: 600;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 3px solid var(--primary);
    }
  }

  @media (max-width: 768px) {
    background-color: #f4f5f7;

    width: 100%;

    .nav-item {
      background-color: #f4f5f7;
      padding-right: 0;
      padding-left: 0;
      margin-right: 21px;

      .nav-link {
        font-weight: 400;
        color: #777f9e;
        width: max-content;
        background-color: #f4f5f7;
        padding: 0 0 8px 0;
        border-bottom: 2px solid #f4f5f7;
        margin: 16px 0;

        &:hover {
          border-right-color: #f4f5f7 !important;
          border-left-color: #f4f5f7 !important;
          border-top-color: #f4f5f7 !important;
        }
      }
      .active {
        border: 0;
        padding: 0 0 8px 0;

        margin: 16px 0;
        color: var(--primary);
        font-weight: bold;
        border-bottom: 2px solid var(--primary);
      }
    }
  }

  @media (max-width: 1280px) {
    flex-wrap: nowrap !important;
    overflow-x: auto;
    overflow-y: hidden;
    .nav-item {
      padding-right: 20px;
      padding-left: 20px;
      .nav-link {
        padding: 16px 0;
      }
    }
  }
`
const StyledContainer = styled.div`
  margin-top: 2rem;
`

export default withRouter(ContractorSettings)
