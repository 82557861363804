import React, { useMemo, useState } from 'react'
import { LinkItUrl } from 'react-linkify-it'
import { useSelector } from 'react-redux'
import {
  Card,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
} from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'

import { loadingElement } from '..'
import { ReactComponent as PerksIllustration } from '../../../assets/images/perks-none.svg'
import Button from '../../../components/ui/button'
import { useFetch } from '../../../helpers/hooks'
import {
  applyPerkOffer,
  getOfferCategories,
  getOfferSecret,
  getPerk,
  getPerks,
} from '../../../services/api'
import Heading from '../components/heading'
import ModalBase from '../components/modal-base'
import ModalQuestions from '../components/modal-questions'
import PerkCard, { PERK_STATUS } from '../components/perk-card'
import PerksStatusPage from '../components/perks-status-page'

function notifyUser() {
  toastr.success(
    'It usually takes up to 2 business days to review new requests. You will receive an email with an update.',
  )
}

export default function PerksList() {
  const [isFiltersOpen, setFiltersOpen] = useState(false)
  const [categoryFilter, setCategoryFilter] = useState([])
  const [showPerkDetails, setShowPerkDetails] = useState(null)
  const [showQuestionModal, setShowQuestionModal] = useState(null)
  const [showSecretModal, setShowSecretModal] = useState(null)
  const [search, setSearch] = useState('')

  const user = useSelector((state) => state.Account?.user)

  function handleFiltersToggle() {
    setFiltersOpen((open) => !open)
  }
  function handleClearFilters() {
    setCategoryFilter([])
  }
  function handleFiltersChange(e) {
    const { value, checked, name } = e.target

    if (checked) {
      setCategoryFilter((prev) => [...prev, { id: value, name }])
    } else {
      setCategoryFilter((prev) =>
        prev.filter(({ id: itemValue }) => itemValue !== value),
      )
    }
  }

  const { data: perksCategories } = useFetch({
    action: getOfferCategories,
    autoFetch: true,
  })

  const categories = (perksCategories ?? []).map((category) => ({
    name: category.name,
    id: String(category.id),
  }))

  const {
    data,
    isLoading,
    startFetch: refreshPerks,
  } = useFetch({ action: getPerks, autoFetch: true })

  function handleGetPerkProcess(data, body) {
    const { eligibility_process: process, eligibility_questions: questions } =
      data
    if (process && questions?.length > 0) {
      setShowQuestionModal(questions)
    } else {
      handlePerkRequest(null, body)
    }
  }

  const { isLoading: applyingOffer, startFetch: applyOffer } = useFetch({
    action: applyPerkOffer,
    checkStatus: true,
    onComplete: (_, body) => {
      if (!body?.eligibility_answers) {
        getPerkSecret({ offerId: body.offerId })
      } else {
        notifyUser()
        setShowQuestionModal(null)
      }
      document.body.classList.remove('modal-open')
      refreshPerks(null, false)
    },
    onError() {
      toastr.error('Error redeeming perk', 'Error')
      setShowQuestionModal(null)
    },
  })

  function handlePerkRequest(values, extra) {
    const body = { userId: user?.id, offerId: extra?.id ?? showPerkDetails?.id }

    if (values) {
      body.eligibility_answers = Object.entries(values).reduce(
        (prev, [key, value]) => {
          return [...prev, { question_id: key, answer_content: value }]
        },
        [],
      )
    }

    applyOffer(body)
  }

  const { startFetch: getPerkDetails, isLoading: gettingPerkDetails } =
    useFetch({
      action: getPerk,
      onComplete(data, body) {
        handleGetPerkProcess(data, body)
      },
      onError() {
        toastr.error('Error getting perk details', 'Error')
      },
    })

  const { startFetch: getPerkSecret, isLoading: gettingSecret } = useFetch({
    action: getOfferSecret,
    onComplete(data) {
      setShowSecretModal(data?.offer)
    },
    onError() {
      toastr.error('Error getting perk code', 'Error')
    },
  })

  const loadingActionBtn = useMemo(() => {
    return gettingPerkDetails || applyingOffer || gettingSecret
  }, [applyingOffer, gettingPerkDetails, gettingSecret])

  function handlePerkClick(perk) {
    setShowPerkDetails(perk)
    if (perk.offer_status === PERK_STATUS.accepted) {
      getPerkSecret({ offerId: perk.id })
    } else if (perk.offer_status === PERK_STATUS.pending) {
      notifyUser()
    } else {
      getPerkDetails({ id: perk.id })
    }
  }

  const filteredPerks = useMemo(
    () =>
      data?.filter((perk) => {
        const hasSearch = search.length > 0
        const hasCategory = categoryFilter && categoryFilter.length > 0
        if (!hasSearch && !hasCategory) return true

        const meetsSearch = !hasSearch
          ? true
          : perk?.product_name?.toLowerCase().includes(search?.toLowerCase())

        const meetsCategory = !hasCategory
          ? true
          : categoryFilter
              .map(({ name }) => name)
              .includes(perk.product_category)

        return meetsSearch && meetsCategory
      }),
    [categoryFilter, data, search],
  )

  if (isLoading) {
    return (
      <div>
        <Heading className='mb-3'>Perks</Heading>
        {loadingElement}
      </div>
    )
  }

  if (!data || data.length <= 0) {
    return (
      <PerksStatusPage
        illustration={PerksIllustration}
        heading='You don’t have any perks yet'
        // body='Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
      />
    )
  }

  return (
    <>
      <Heading className='mb-3'>Perks</Heading>

      <Card className='p-4'>
        <PerksFiltering className='mb-3'>
          <PerkSearch search={search} setSearch={setSearch} />
          <PerkFilters
            isOpen={isFiltersOpen}
            toggle={handleFiltersToggle}
            value={categoryFilter}
            onChange={handleFiltersChange}
            categories={categories}
            clearFilters={handleClearFilters}
          />
        </PerksFiltering>

        <PerksListing
          onClickPerk={handlePerkClick}
          perks={filteredPerks}
          gettingPerk={loadingActionBtn}
          clickedPerk={showPerkDetails}
        />
      </Card>

      <ModalQuestions
        fields={showQuestionModal?.map((q) => ({
          name: q.id,
          label: q?.content,
        }))}
        isOpen={!!showQuestionModal}
        toggle={() => setShowQuestionModal(null)}
        onSubmit={handlePerkRequest}
        isSubmitting={applyingOffer}
      />

      <ModalShowSecret
        text={showSecretModal?.secret}
        offerDesc={showPerkDetails?.description}
        isOpen={!!showSecretModal}
        toggle={() => setShowSecretModal(null)}
      />
    </>
  )
}

function PerksListing({ onClickPerk, gettingPerk, clickedPerk, perks }) {
  if (!perks || perks.length <= 0) {
    return <div className='px-1 text-muted'>No perks found</div>
  }

  return (
    <PerksLayout>
      {perks.map((perk) => {
        return (
          <PerkCard
            key={perk.id}
            perk={perk}
            onClickPerk={onClickPerk}
            gettingPerk={gettingPerk}
            clickedPerk={clickedPerk}
          />
        )
      })}
    </PerksLayout>
  )
}

const PerksLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media (min-width: 576px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const PerksFiltering = styled.div`
  display: flex;
  flex-direction: column;

  .search-input {
    border-radius: 0 0 0.25rem 0.25rem;
  }

  .dropdown-toggle {
    gap: 0.5rem;
    border-radius: 0.25rem 0.25rem 0 0;
  }

  @media (min-width: 768px) {
    flex-direction: row;

    .dropdown {
      flex-basis: 200px;
    }

    .dropdown-toggle {
      border-radius: 0 0.25rem 0.25rem 0;
    }

    .search-input {
      border-right: 0;
      border-radius: 0.25rem 0 0 0.25rem;
    }
  }

  @media (max-width: 767px) {
    .search {
      order: 2;
    }
    .dropdown {
      order: 1;
    }
    .search-input {
      border-top: 0;
    }
  }
`

function PerkSearch({ search, setSearch }) {
  return (
    <div className='search position-relative flex-grow-1'>
      <i
        className='bx bx-search position-absolute font-size-16 text-muted'
        style={{
          paddingTop: '0.8rem',
          paddingLeft: '0.8rem',
          touchAction: 'none',
          pointerEvents: 'none',
        }}
      />
      <Input
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className='border-light search-input'
        style={{ paddingLeft: '2.5rem' }}
        placeholder='Search'
      />
    </div>
  )
}

function PerkFilters({
  isOpen,
  toggle,
  categories,
  value,
  onChange,
  clearFilters,
}) {
  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle
        className='dropdown-toggle d-flex justify-content-between align-items-center w-100 px-3 bg-transparent border rp-font-light text-muted'
        color='light'
      >
        <span>Filters</span>
        <i className='bx bx-caret-down' />
      </DropdownToggle>
      <DropdownMenu className='w-100' container='div.parks-page-content'>
        <div className='d-flex align-items-center justify-content-between w-100 px-3'>
          <span>Categories</span>
          <button
            className='rp-btn-nostyle text-muted font-size-12'
            onClick={clearFilters}
          >
            Reset
          </button>
        </div>

        <hr className='my-2' />

        <div className='px-2'>
          {categories?.map((category, i) => {
            return (
              <Label
                key={`drop-${i}`}
                className='cursor-pointer font-size-14 d-block hover:bg-light py-1 rounded mb-1'
                style={{ paddingLeft: '1.75rem' }}
              >
                <Input
                  checked={value.map(({ id }) => id).includes(category.id)}
                  type='checkbox'
                  name={category.name}
                  value={category.id}
                  onChange={onChange}
                  style={{ marginTop: '0.25rem' }}
                />{' '}
                <span>{category.name}</span>
              </Label>
            )
          })}
        </div>
      </DropdownMenu>
    </Dropdown>
  )
}

function ModalShowSecret({ toggle, isOpen, offerDesc, text }) {
  const modalProps = { toggle, isOpen, heading: 'How to use the perk?' }

  return (
    <ModalBase
      {...modalProps}
      actions={
        <Button type='button' onClick={toggle}>
          Got it
        </Button>
      }
    >
      <p className='text-gray-h rp-font-bold font-size-14'>{offerDesc}</p>
      <LinkItUrl>
        <p
          className='text-muted font-size-14 mb-1'
          style={{ lineHeight: 1.7, wordBreak: 'break-word' }}
        >
          {text}
        </p>
      </LinkItUrl>
    </ModalBase>
  )
}
