import React from 'react'
import { Col, Container, Row, Spinner } from 'reactstrap'
import { useLocation, useHistory } from 'react-router'
import { useFetch } from '../../helpers/hooks'
import { checkContractInvitation } from '../../services/api'
import toastr from 'toastr'

const InvitationLoader = () => {
  // eslint-disable-next-line camelcase
  const location = useLocation()
  const history = useHistory()
  const token = new URLSearchParams(location.search).get('token')
  const contractId = new URLSearchParams(location.search).get('contract_id')
  useFetch({
    action: checkContractInvitation,
    autoFetch: true,
    body: {
      token,
      contract_id: contractId,
    },
    onError: (err) => {
      toastr.error(err)
      history.push('/')
    },
    onComplete: (data) => {
      history.push(data?.url)
    },
  })

  return (
    <Container style={{ minHeight: '100vh' }}>
      <Col style={{ minHeight: '100vh' }}>
        <Row
          style={{ minHeight: '100vh' }}
          className='justify-content-center align-items-center'
        >
          <Spinner type='grow' className='mr-2' color='primary' />
        </Row>
      </Col>
    </Container>
  )
}

export default InvitationLoader
