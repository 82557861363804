import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Spinner, TabContent, TabPane } from 'reactstrap'
import { useFetch } from '../../../helpers/hooks'
import {
  connectQuickbooks,
  getQuickbooksConnection,
  setupQuickbooks,
} from '../../../services/api'
import toastr from 'toastr'
import ModalHeader from '../../../components/ModalHeader'
import Steps from '../../../components/Steps'
import StepContainer from '../../../components/Steps/StepContainer'
import AccountMappingStep from './XeroIntegration/accountMappingStep'
import VendorsSettingStep from './XeroIntegration/vendorsSettingsStep'
import FinalStep from './XeroIntegration/finalStep'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import {
  initQB,
  qbUpdatePreData,
  qbUpdateRequest,
} from '../../../store/qb/actions'

const QuickbookLoading = () => {
  const [activePage, setActivePage] = useState(0)

  const location = useLocation()
  const history = useHistory()

  const dispatch = useDispatch()
  const code = new URLSearchParams(location.search).get('code')
  const realmId = new URLSearchParams(location.search).get('realmId')

  const quickbooksConnection = useFetch({
    action: getQuickbooksConnection,
    autoFetch: !code || !realmId,
    successAction: qbUpdatePreData,
  })

  const getToken = useFetch({
    action: connectQuickbooks,
    body: {
      code,
      realmId,
    },
    autoFetch: !!code && !!realmId,
    onComplete: (data) => {
      if (data?.synched) {
        history.push('/settings/integrations')
      } else {
        quickbooksConnection.startFetch()
      }
    },
    onError: (err) => {
      toastr.error(err)
      history.push('/settings/integrations')
    },
  })

  const request = useSelector((state) => state.qb.request)

  const update = useFetch(
    {
      action: setupQuickbooks,
      onError: toastr.error,
      onComplete: () => {
        dispatch(qbUpdateRequest(null))
        setActivePage(activePage + 1)
      },
    },
    [request],
  )

  useEffect(() => {
    if (request) update.startFetch(request)
  }, [request])

  useEffect(() => {
    return () => {
      dispatch(initQB())
    }
  }, [])

  const steps = ['Account Mapping', 'Vendor Mapping', 'Completed']
  return (
    <div className='page-content min-vh-100 p-0 m-0'>
      {quickbooksConnection.isLoading ? (
        <Container style={{ minHeight: '100vh' }}>
          <Col style={{ minHeight: '100vh' }}>
            <Row
              style={{ minHeight: '100vh' }}
              className='justify-content-center align-items-center'
            >
              <Spinner type='grow' color='primary' />
            </Row>
          </Col>
        </Container>
      ) : (
        <Container fluid>
          <ModalHeader
            action={() => {
              history.push('/settings/integrations')
            }}
          >
            <Steps activeTab={activePage} data={steps} />
          </ModalHeader>
          <Row className='justify-content-center'>
            <Col md={6}>
              <TabContent
                activeTab={activePage}
                className='twitter-bs-wizard-tab-content'
              >
                <TabPane tabId={0}>
                  <StepContainer
                    title='Account Mapping'
                    index={1}
                    total={steps.length}
                    onNext={() => {
                      document
                        .getElementById('submit-xero-account-mapping-step')
                        .click()
                    }}
                    loading={false}
                    isFlat
                    onBack={() => setActivePage(activePage - 1)}
                  >
                    <AccountMappingStep
                      platform={'Quickbooks'}
                      onSubmitted={() => setActivePage(activePage + 1)}
                      loading={getToken.isLoading}
                    />
                  </StepContainer>
                </TabPane>
                <TabPane tabId={1}>
                  <VendorsSettingStep
                    platform={'Quickbooks'}
                    onBack={() => setActivePage(activePage - 1)}
                    loading={getToken.isLoading}
                    onSubmitted={(values) => {
                      dispatch(qbUpdateRequest(values))
                    }}
                  />
                </TabPane>
                <TabPane tabId={2}>
                  <FinalStep platform={'Quickbooks'} />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  )
}

export default QuickbookLoading
