import { yupResolver } from '@hookform/resolvers/yup'
import { flattenDeep } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import {
  Alert,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Progress,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import FileImage from '../../../assets/images/filesKyc.svg'
import DropzoneInput from '../../../components/Common/dropzone-input'
import UploadPreview from '../../../components/Common/upload-preview'
import ControlledInput from '../../../components/ControlledInput'
import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import ModalHeader from '../../../components/ModalHeader'
import Steps from '../../../components/Steps'
import StepContainer from '../../../components/Steps/StepContainer'
import Button from '../../../components/ui/button'
import Loader from '../../../components/ui/loader'
import { useFetch } from '../../../helpers/hooks'
import {
  cardActivity,
  cardsPreConditions,
  getCardEnumerations,
  getContractList,
  submitKycInfos,
  uploadCardKycDocument,
} from '../../../services/api'
import {
  documentTypes,
  kycRejectionReasons,
} from '../../AdminPanel/pages/cards/cards-kyc'
import CardEdd, {
  addEddValidation,
  getDefaultEddValues,
} from '../organisms/card-edd'
import { KYC_STATUSES } from '../components/cards-kyc-banner'
import { FormContentRenderer } from '../components/form-content-renderer'
import {
  Divider,
  mapCountryToOptionCards,
  UI_STATE,
} from '../components/request-new-card'
import { formatRequestData, isDataValid } from '../utils/format-request-data'
import {
  addInfoFormValidation,
  getDefaultInfoValues,
  infoFormFields,
} from '../utils/info-form-fields'
import useUpdateAddress from '../utils/use-update-address'

const fields = {
  id_type: {
    name: 'id_type',
    label: 'ID',
    fullCol: true,
    required: true,
    rules: { required: 'This field is required' },
  },
  id_number: {
    name: 'id_number',
    label: 'ID Number',
    required: true,
  },
  address1: {
    name: 'address_1',
    label: 'Address line 1',
    required: true,
  },
  address2: {
    name: 'address_2',
    label: 'Address line 2',
    required: true,
  },
  city: {
    name: 'city',
    label: 'City',
    required: true,
  },
  state: {
    name: 'state',
    label: 'State',
    required: true,
  },
  country: {
    name: 'country',
    label: 'Country',
    required: true,
  },
  zipCode: {
    name: 'zipcode',
    label: 'Zip Code',
    required: true,
  },
  approvalAddress: {
    name: 'approvalAddress',
    label: 'Proof of address',
    required: true,
  },
  issuingCountry: {
    name: 'issuingCountry',
    label: 'Issuing country:',
    required: true,
  },
  frontId: { name: 'frontId', label: 'Front:', required: true },
  backId: { name: 'backId', label: 'Back:', required: true },
  selfie: { name: 'selfie', label: 'Selfie:', required: true },
  proofOfAddress: {
    name: 'proofOfAddress',
    label: 'Proof of address:',
    required: true,
  },
  residentialAddress: {
    name: 'residentialAddress',
    label: 'Residential address:',
    required: true,
  },
}

const poaSchema = yup.object().shape({
  address_1: yup.string().required(),
  address_2: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  country: yup.object().required(),
  zipcode: yup.string().required(),
})

let step1Schema = poaSchema
let allDataSchema = step1Schema

const stepIds = {
  residential_address: 'residential_address',
  id_verification: 'id_verification',
  edd: 'edd',
}

export function getReasons(reason) {
  return {
    reason,

    isOtherReason: reason === kycRejectionReasons.OTHER,
    isInvalidId: reason === kycRejectionReasons.INVALID_ID,
    isInvalidPoa: reason === kycRejectionReasons.INVALID_POA,
  }
}

function getConditions({ reason, isEdd }) {
  const { isOtherReason, isInvalidId, isInvalidPoa } = getReasons(reason)
  const shouldAddEdd = isEdd && (isOtherReason || isInvalidId)

  return { isOtherReason, isInvalidId, isInvalidPoa, shouldAddEdd }
}

function getSteps({ shouldAddEdd, isInvalidPoa }) {
  return [
    {
      id: stepIds.residential_address,
      label: isInvalidPoa ? 'Residential address' : 'Info',
      title: isInvalidPoa ? 'Residential address' : 'Info',
    },
    shouldAddEdd
      ? { id: stepIds.edd, label: 'EDD', title: 'Enhanced Due Diligence' }
      : null,
  ]
    .filter(Boolean)
    .map((step, index) => ({ ...step, value: index }))
}
export default function CardsKyc() {
  const history = useHistory()
  const user = useSelector((state) => state?.userProfile?.userProfile)
  const [activeStep, setActiveStep] = useState(0)

  const enumerations = useFetch({
    action: getCardEnumerations,
    autoFetch: true,
  })

  const { data: preConditions, isLoading: loadingPreConditions } = useFetch({
    action: cardsPreConditions,
    autoFetch: true,
  })

  const { data: cards, isLoading: loadingCards } = useFetch({
    action: cardActivity,
    autoFetch: true,
    body: { rp_user_id: user?.id },
  })

  const loadingData =
    enumerations?.loading || loadingPreConditions || loadingCards

  const isEdd = preConditions?.card_configurations?.is_edd

  const steps = useMemo(() => {
    const kycRejectionReason = cards?.kyc?.rejection_reason

    const { shouldAddEdd, isInvalidPoa } = getConditions({
      reason: kycRejectionReason,
      isEdd,
    })

    return getSteps({ shouldAddEdd, isInvalidPoa })
  }, [cards, isEdd])

  useEffect(() => {
    if (!cards?.kyc?.status) {
      return
    }

    const shouldRedirect = ![KYC_STATUSES.REJECTED].includes(cards?.kyc?.status)

    if (shouldRedirect) {
      history.push('/cards')
    }
  }, [cards?.kyc?.status, history])

  return (
    <Container fluid>
      <ModalHeader action={() => history.push('/cards')}>
        {steps?.length <= 1 ? null : (
          <Steps
            className='d-none d-md-flex'
            activeTab={activeStep}
            data={steps.map((step) => step.label)}
            noLastAction
          />
        )}
      </ModalHeader>

      <Form className='d-flex flex-column' style={{ marginBottom: '9rem' }}>
        <TabContent
          activeTab={activeStep}
          className='twitter-bs-wizard-tab-content'
        >
          {loadingData || !cards ? (
            <Card style={{ maxWidth: 820, margin: '80px auto 0px auto' }}>
              <Loader minHeight='70vh' />
            </Card>
          ) : (
            <CardKycForm
              activeStep={activeStep}
              enumerations={enumerations}
              preConditions={preConditions}
              kyc={cards.kyc}
              setActiveStep={setActiveStep}
              steps={steps}
            />
          )}
        </TabContent>
      </Form>
    </Container>
  )
}

const allFields = [{ title: 'Personal information:', fields: infoFormFields }]

export function showYupErrors(inner) {
  const err = inner.reduce((prev, { message, path, type }) => {
    return [...prev, { message, path, type }]
  }, [])

  const messages = err.map(({ path, message }) => [
    `<strong>${path}</strong>`,
    message,
  ])
  const joinedMessages = flattenDeep(messages).join('<br/>')

  toastr.clear()
  toastr.error(joinedMessages, '', { allowHtml: true })
}

function CardKycForm({
  enumerations,
  preConditions,
  kyc,
  setActiveStep,
  steps,
}) {
  const [uiState, setUiState] = useState(UI_STATE.IDLE)

  const history = useHistory()
  const user = useSelector((state) => state?.userProfile?.userProfile)

  const { shouldAddEdd, isInvalidPoa } = getConditions({
    reason: kyc?.rejection_reason,
    isEdd: preConditions?.card_configurations?.is_edd,
  })

  if (!isInvalidPoa) {
    step1Schema = addInfoFormValidation(step1Schema, infoFormFields)
  }
  if (shouldAddEdd) {
    allDataSchema = addEddValidation(allDataSchema)
  } else {
    allDataSchema = step1Schema
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(allDataSchema),
    defaultValues: {
      ...getDefaultInfoValues(user),

      ...getDefaultEddValues(user),
    },
  })

  const { data: contracts } = useFetch(
    {
      action: getContractList,
      autoFetch: preConditions?.card_configurations?.is_edd,
      body: { status: [4] },
    },
    [preConditions?.card_configurations?.is_edd],
  )

  useEffect(() => {
    const nationalityCode = user?.Country_of_Citizenship?.iso3
    const nationalityFound = enumerations?.data?.nationalities?.find(
      (n) => n.code === nationalityCode,
    )
    const defaultNationality = {
      value: nationalityFound?.nationality_name,
      label: nationalityFound?.description,
    }

    const countryCode = user?.country?.iso3
    const countryFound = enumerations?.data?.countries?.find(
      (n) => n.iso3 === countryCode,
    )
    const defaultCountry = mapCountryToOptionCards(countryFound)

    setValue('nationality', defaultNationality)
    setValue('country', defaultCountry)

    if (preConditions?.card_configurations?.is_edd) {
      const firstContract = contracts?.[0]
      const companyName = firstContract?.company_name
      const contractName = firstContract?.name

      setValue('edd_companyName', companyName)
      setValue('edd_employment', contractName)
    }
  }, [
    enumerations?.data?.nationalities,
    enumerations?.data?.countries,
    user?.Country_of_Citizenship?.iso3,
    user?.country?.iso3,
    preConditions?.card_configurations?.is_edd,
    setValue,
    contracts,
  ])

  const { updateContractorAddress } = useUpdateAddress()

  const { startFetch: submitKyc, isLoading: submittingKyc } = useFetch({
    action: submitKycInfos,
    onComplete: (_, body) => {
      // Update address on request success
      const residentialAddress = body?.user_residential_address
      updateContractorAddress(residentialAddress)

      history.push('/cards')
    },
    onError: (err) => {
      const messages = err?.error?.messages.join('<br/>')
      toastr.error(messages, '', { allowHtml: true })
    },
  })

  function isDocumentUploaded() {
    const test = uiState === UI_STATE.UPLOADED
    if (!test) {
      toastr.clear()
      toastr.error('Please upload your proof of address')
    }
    return test
  }

  function goNext() {
    if (isDocumentUploaded()) {
      setActiveStep((step) => step + 1)
      window.scrollTo(0, 0)
    }
  }

  async function onSubmitFirstStep(e) {
    e?.preventDefault()

    step1Schema
      .validate(getValues(), { abortEarly: false })
      .then(() => goNext())
      .catch(({ inner }) => showYupErrors(inner))
  }

  async function onKycSubmit(data) {
    if (!isDocumentUploaded()) {
      return
    }

    const validData = isDataValid(data)

    if (!validData) {
      return
    }

    const body = formatRequestData(data, {
      isEdd: shouldAddEdd,
      isUpdate: true,
    })
    submitKyc(body)
  }

  function handleError(err) {
    const keys = Object.keys(err)
    const messages = keys.map((k) => [`<strong>${k}</strong>`, err[k].message])
    const joinedMessages = flattenDeep(messages).join('<br/>')

    toastr.error(joinedMessages, '', { allowHtml: true })
  }

  const submitAllSteps = handleSubmit(onKycSubmit, handleError)

  function getStep(id) {
    return steps.find((s) => s.id === id) ?? {}
  }

  function goBack() {
    setActiveStep((step) => step - 1)
  }

  return (
    <>
      <TabPane tabId={getStep(stepIds.residential_address).value}>
        <StepContainer
          total={shouldAddEdd ? 2 : 1}
          index={getStep(stepIds.residential_address).value}
          title={getStep(stepIds.residential_address).title}
          loading={submittingKyc}
          disableNext={submittingKyc}
          nextText={steps.length > 1 ? undefined : 'Submit'}
          onNext={steps.length > 1 ? onSubmitFirstStep : submitAllSteps}
        >
          {isInvalidPoa ? null : (
            <>
              <FormContentRenderer
                fieldData={enumerations?.data ?? {}}
                formFields={allFields}
                control={control}
                errors={errors}
                preConditions={preConditions}
              />

              <Divider />
            </>
          )}

          <UserInfosForm
            errors={errors}
            control={control}
            uiState={uiState}
            setUiState={setUiState}
            enumerations={enumerations}
          />
        </StepContainer>
      </TabPane>

      {!shouldAddEdd ? null : (
        <TabPane tabId={getStep(stepIds.edd)?.value}>
          <StepContainer
            total={shouldAddEdd ? 2 : 1}
            index={getStep(stepIds.edd)?.value}
            title={getStep(stepIds.edd)?.title}
            nextText='Submit'
            onNext={submitAllSteps}
            onBack={goBack}
            disableNext={submittingKyc}
            loading={submittingKyc}
          >
            <CardEdd control={control} errors={errors} />
          </StepContainer>
        </TabPane>
      )}
    </>
  )
}

function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export function UserInfosForm({
  errors,
  control,
  uiState,
  setUiState,
  enumerations,
}) {
  const [preview, setPreview] = useState(null)
  const [attachment, setAttachment] = useState(null)
  const [progress, setProgress] = useState(0)
  const userProfile = useSelector((state) => state?.userProfile?.userProfile)

  const { startFetch: uploadDocument } = useFetch({
    action: uploadCardKycDocument,
    onComplete: () => {
      setUiState(UI_STATE.UPLOADED)
      setProgress(0)
    },
    onError: (err) => {
      toastr.error(
        err?.error?.messages?.[0] ?? 'Something went wrong while uploading',
      )
      setUiState(UI_STATE.IDLE)
      setProgress(0)
    },
  })
  async function handleAcceptedFiles(acceptedFiles) {
    setUiState(UI_STATE.UPLOADING)
    const rawFile = acceptedFiles[0]
    setAttachment(rawFile)
    const base64File = await toBase64(rawFile)
    setPreview({ data: base64File, type: rawFile.type })
    uploadDocument({
      file: rawFile,
      document_type: documentTypes.proof_of_address,
      progressFuncs: {
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 50
          setProgress(progress)
        },
        onDownloadProgress: (progressEvent) => {
          const progress =
            50 + (progressEvent.loaded / progressEvent.total) * 50
          setProgress(progress)
        },
      },
    })
  }

  return (
    <>
      <div className='pt-3'>
        <h5
          className='rp-font-gilroyB d-inline-block mb-3'
          style={{ color: '#424652' }}
        >
          Residential address
        </h5>

        <Row>
          <Col xs={12} md={6} className='pr-md-2'>
            <FormGroup>
              <Label htmlFor={fields.address1.name}>
                {fields.address1.label}
                <span className='text-danger font-size-16 ml-1'>*</span>
              </Label>
              <ControlledInput
                name={fields.address1.name}
                defaultValue={userProfile.address}
                id={fields.address1.name}
                error={errors.address_1}
                placeholder={fields.address1.label}
                control={control}
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={6} className='pl-md-2'>
            <FormGroup>
              <Label htmlFor={fields.address2.name}>
                {fields.address2.label}
                <span className='text-danger font-size-16 ml-1'>*</span>
              </Label>
              <ControlledInput
                name={fields.address2.name}
                id={fields.address2.name}
                error={errors.address_2}
                placeholder={fields.address2.label}
                control={control}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} className='pr-md-2'>
            <FormGroup>
              <Label htmlFor={fields.city.name}>
                {fields.city.label}
                <span className='text-danger font-size-16 ml-1'>*</span>
              </Label>
              <ControlledInput
                name={fields.city.name}
                id={fields.city.name}
                error={errors.city}
                placeholder={fields.city.label}
                defaultValue={userProfile.city}
                control={control}
                disabled
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={6} className='pl-md-2'>
            <FormGroup>
              <Label htmlFor={fields.state.name}>
                {fields.state.label}
                <span className='text-danger font-size-16 ml-1'>*</span>
              </Label>
              <ControlledInput
                name={fields.state.name}
                id={fields.state.name}
                error={errors.state}
                defaultValue={userProfile.state?.name}
                placeholder={fields.state.label}
                control={control}
                disabled={!!userProfile.state?.name}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} className='pr-md-2'>
            <FormGroup>
              <Label htmlFor={fields.country.name}>
                {fields.country.label}
                <span className='text-danger font-size-16 ml-1'>*</span>
              </Label>
              <ControlledCountrySelect
                name={fields.country.name}
                error={errors.country}
                control={control}
                countries={enumerations?.countries || []}
                disabled
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={6} className='pl-md-2'>
            <FormGroup>
              <Label htmlFor={fields.zipCode.name}>
                {fields.zipCode.label}
                <span className='text-danger font-size-16 ml-1'>*</span>
              </Label>
              <ControlledInput
                name={fields.zipCode.name}
                id={fields.zipCode.name}
                error={errors.zipcode}
                placeholder={fields.zipCode.label}
                defaultValue={userProfile?.zip_code ?? ''}
                control={control}
                disabled={!!userProfile?.zip_code}
              />
            </FormGroup>
          </Col>
        </Row>

        <Label>
          {fields.approvalAddress.label}
          <span className='text-danger font-size-16 ml-1'>*</span>
        </Label>

        {poaMessage}

        {uiState === UI_STATE.UPLOADED ? (
          <>
            <UploadPreview
              preview={preview}
              style={{ minHeight: 345, maxHeight: 345 }}
            />

            <div className='d-flex gap-8 align-items-center'>
              <p className='font-size-10 mb-0 text-secondary'>
                {attachment?.name}
              </p>

              <Button
                size='sm'
                color='secondary'
                outline
                onClick={() => setUiState(UI_STATE.IDLE)}
              >
                Clear
              </Button>
            </div>
          </>
        ) : (
          <DropzoneInput
            className='d-flex justify-content-center align-items-center flex-column text-center w-full rp-font-gilroyB'
            style={{ minHeight: 245 }}
            onDropAccepted={handleAcceptedFiles}
          >
            {uiState === UI_STATE.UPLOADING || uiState === UI_STATE.IDLE ? (
              <>
                <div className='p-3 position-relative'>
                  <img
                    src={FileImage}
                    alt='invoice'
                    style={{ width: 48, height: 48 }}
                  />
                  {uiState !== UI_STATE.UPLOADING ? null : (
                    <Spinner
                      color='primary'
                      className='position-absolute'
                      style={{
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                      }}
                    />
                  )}
                </div>
                {uiState !== UI_STATE.UPLOADING ? null : (
                  <Progress className='my-2 w-100' value={progress}>
                    {Math.round(Number(progress))}%
                  </Progress>
                )}
              </>
            ) : null}
            {uiState === UI_STATE.IDLE ? (
              <>
                <p className='mb-1 h5 text-body'>
                  Drop files here or click to upload{' '}
                </p>
                <p className='mb-0 text-size-14 text-secondary'>
                  The image types are restricted to .jpg , .png , .pdf and each
                  file can be up to 8MB in size.
                </p>
              </>
            ) : null}
          </DropzoneInput>
        )}
      </div>
    </>
  )
}

function ControlledCountrySelect({
  control,
  defaultValue,
  error,
  name,
  disabled,
  rules,
  countries,
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, value, name } }) => {
        return (
          <div>
            <CustomSelect
              name={name}
              onChange={onChange}
              hasError={!!error}
              aria-invalid={!!error}
              isDisabled={disabled}
              aria-errormessage={name + '-error-msg'}
              placeholder='Select a country'
              value={value}
              options={countries?.map((c) => mapCountryToOptionCards(c))}
            />
            {error && error.message ? (
              <p className='font-size-12 text-danger'>{error.message}</p>
            ) : null}
          </div>
        )
      }}
    />
  )
}

const poaMessage = (
  <Alert color='light' className='alert-orange py-3'>
    <h6 className='text-current'>
      Before uploading your proof of address please make sure that:
    </h6>
    <ul className='mb-0 pl-4'>
      <li>POA is scanned with full width of the document</li>
      <li>POA is dated within 3 months</li>
      <li>POA contains the full name and address of the user clearly</li>
      <li>
        POA contains the issuing company’s logo or the authorities/government
        agencies’ name
      </li>
      <li>Identify the type of POA (Bank or Credit card statement)</li>
    </ul>
  </Alert>
)
