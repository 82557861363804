import { Crop, Eye, PencilSimple, Plus } from '@phosphor-icons/react'
import cx from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import toastr from 'toastr'

import DropzoneInput from '../../../components/Common/dropzone-input'
import Button from '../../../components/ui/button'
import { useFetch } from '../../../helpers/hooks'
import { updateCompanyInfo } from '../../../services/api'
import { updateProfileCompany } from '../../../store/profile/actions'
import style from './contract-logo-management.module.scss'

export function ContractLogoManagement({ enabled }) {
  const [file, setFile] = useState(null)
  const dropzoneRef = useRef(null)

  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company,
  )

  function openDropzone() {
    if (!dropzoneRef.current || !dropzoneRef.current.click) {
      return
    }
    dropzoneRef.current.click()
  }

  if (company?.cropped_official_logo && enabled) {
    return (
      <>
        <DropzoneWrapper
          name='contract-logo-2'
          dropzoneRef={dropzoneRef}
          setFile={setFile}
          className={cx(
            'p-4 cursor-pointer text-center bg-primary-10 mx-3 mt-3 mb-2 rp-rounded-md position-relative d-flex align-items-center justify-content-center',
            style.areaBtn,
          )}
          style={{
            minHeight: 155,
            border: '2px dashed #EEEEE1',
            '--icon-size': '36px',
          }}
        >
          <img
            src={company?.cropped_official_logo}
            style={{
              width: 118,
              height: 'auto',
              maxHeight: 103,
              objectFit: 'contain',
            }}
          />

          <PencilSimple
            className='p-2 border bg-white rounded-circle position-absolute'
            style={{
              top: 'calc(calc(-1 * var(--icon-size)) / 2)',
              right: 'calc(calc(-1 * var(--icon-size)) / 2)',
              width: 'var(--icon-size)',
              height: 'var(--icon-size)',
            }}
          />
        </DropzoneWrapper>

        <LogoUploadModal
          isOpen={!!file}
          file={file}
          toggle={() => setFile(null)}
          openImagePicker={openDropzone}
        />
      </>
    )
  }

  if (!enabled) {
    return null
  }

  return (
    <>
      <DropzoneWrapper
        className={cx(
          'p-4 cursor-pointer text-center d-flex align-items-center justify-content-center flex-column gap-16 bg-primary-10 border border-primary-100 rp-rounded-md !border-dashed',
          style.areaBtn,
        )}
        style={{ minHeight: 200 }}
        name='contract-logo'
        dropzoneRef={dropzoneRef}
        setFile={setFile}
      >
        <Plus
          size={80}
          className='p-4 bg-primary-20 rounded-circle'
          color='var(--primary)'
        />

        <div>
          <p className='rp-font-medium font-size-16 mb-1'>Add your logo</p>
          <p className='rp-font-normal text-gray-600 mb-0'>
            Click or drag file
          </p>
        </div>
      </DropzoneWrapper>

      <ul className='pl-3 text-slate-700 mb-0'>
        <li>Max size 5MB</li>
        <li>File supported PNG</li>
        <li>Aspect ratio recommended is 1:1</li>
      </ul>

      <LogoUploadModal
        isOpen={!!file}
        file={file}
        toggle={() => setFile(null)}
        openImagePicker={openDropzone}
      />
    </>
  )
}

const panelName = { crop: 'Crop', preview: 'Preview' }
export function LogoUploadModal({ isOpen, toggle, file, openImagePicker }) {
  const dispatch = useDispatch()

  const [activePanel, setActivePanel] = useState(panelName.crop)
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()

  const imageRef = useRef(null)
  const previewCanvasRef = useRef(null)

  function handleToggle() {
    setActivePanel(panelName.crop)
    setCrop(undefined)
    toggle?.()
  }

  const { startFetch: updateCompany, isLoading: updatingContractLogo } =
    useFetch({
      action: updateCompanyInfo,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error(data?.message || 'Failed to update contract logo')
        } else {
          dispatch(updateProfileCompany(data))
          toastr.success('Contract logo updated successfully')
          handleToggle()
        }
      },
    })

  function handleDeleteImage() {
    updateCompany({ reset_official_logo: true })
  }

  async function saveOfficialLogo() {
    const croppedLogo = await getImageFromCanvas(
      previewCanvasRef.current,
      file.file?.name,
    )

    const body = {
      official_logo: file.file,
      cropped_official_logo: croppedLogo,
    }

    updateCompany(body)
  }

  function onImageLoad(e) {
    const { width, height } = e.currentTarget

    setCrop(centerAspectCrop(width, height, width / height))
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imageRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(imageRef.current, previewCanvasRef.current, completedCrop)
      }
    },
    100,
    [completedCrop],
  )

  return (
    <Modal isOpen={isOpen} toggle={handleToggle}>
      <ModalHeader toggle={handleToggle}>Edit logo</ModalHeader>

      <ModalBody className='p-0 d-flex flex-column' style={{ minHeight: 510 }}>
        <Tabs>
          {[
            { name: panelName.crop, icon: Crop },
            { name: panelName.preview, icon: Eye },
          ].map(({ name, icon: Icon }) => {
            return (
              <Tab
                key={name}
                active={activePanel === name}
                onClick={() => {
                  setActivePanel(name)
                }}
              >
                <Icon size={20} />
                {name}
              </Tab>
            )
          })}
        </Tabs>

        <div
          className={cx('flex-column flex-grow-1', {
            'd-none': activePanel !== panelName.crop,
            'd-flex': activePanel === panelName.crop,
          })}
        >
          <div
            className='d-flex align-items-center justify-content-center flex-grow-1'
            style={{ backgroundColor: '#EEEFF1' }}
          >
            {!file ? null : (
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                ruleOfThirds
                onComplete={(c) => setCompletedCrop(c)}
              >
                <img
                  ref={imageRef}
                  alt='Crop me'
                  src={file.src}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            )}
          </div>
        </div>

        <div
          className={cx('p-4 flex-grow-1', {
            'd-none': activePanel !== panelName.preview,
          })}
          style={{ backgroundColor: '#EEEFF1' }}
        >
          <div
            className='bg-white px-5 py-4 text-slate-600'
            style={{ borderRadius: '8px 8px 0 0' }}
          >
            <h6 className='text-right text-muted mb-4'>
              Contract id: #[Contract ID]
            </h6>

            {!completedCrop ? null : (
              <div className='text-center mb-4'>
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    objectFit: 'contain',
                    width: completedCrop.width,
                    height: completedCrop.height,
                    maxWidth: 120,
                    maxHeight: 38,
                  }}
                />
              </div>
            )}

            <h3 className='mb-4 font-size-16 rp-font-bold text-center'>
              Agreement
            </h3>

            <p style={{ lineHeight: 1.5 }}>
              This agreement (this “Agreement”) is entered into between:
            </p>
            <p style={{ lineHeight: 1.5 }}>
              Company LLC Limited Liability Company (LLC), with its principal
              place of business located at [Company address] and with a
              registration number [Registration number] and email address:
              example@company.com, (the “Client” and together with the
              Contractor the “Parties” or a “Party”);
            </p>
          </div>
        </div>
      </ModalBody>

      <ModalFooter className='flex-nowrap'>
        <Button
          color='link'
          className='text-dark rp-font-bold mr-auto'
          onClick={handleDeleteImage}
          disabled={updatingContractLogo}
        >
          Delete
        </Button>

        <Button
          outline
          color='light'
          onClick={() => {
            handleToggle?.()
            openImagePicker?.()
          }}
          disabled={updatingContractLogo}
        >
          <span className='d-none d-md-block'>Upload new image</span>
          <span className='d-md-none'>New image</span>
        </Button>
        <Button
          onClick={saveOfficialLogo}
          disabled={updatingContractLogo}
          loading={updatingContractLogo}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  )
}

function Tabs({ children, center = true }) {
  return (
    <div className={cx('d-flex gap-8', { 'justify-content-center': center })}>
      {children}
    </div>
  )
}

function Tab({ children, active, onClick }) {
  const isButton = !!onClick
  const Component = isButton ? 'button' : 'div'

  return (
    <Component
      className={cx('p-3 font-size-14 d-flex gap-12 justify-content-center', {
        'text-primary border-primary': active,
        'text-gray-600 border-transparent': !active,
        'border-none bg-transparent': isButton,
      })}
      style={{ borderBottom: '2px solid' }}
      onClick={onClick}
    >
      {children}
    </Component>
  )
}

async function getImageFromCanvas(canvasImage, originalName) {
  const blob = await new Promise((resolve) => canvasImage.toBlob(resolve))

  const [nameWithoutExtension, extension] = originalName.split('.')
  const croppedName = `${nameWithoutExtension}-cropped.${extension}`

  const file = new File([blob], croppedName)

  return file
}

function DropzoneWrapper({
  setFile,
  dropzoneRef,
  className,
  style,
  name,
  children,
}) {
  return (
    <DropzoneInput
      unstyled
      className={className}
      style={style}
      name={name}
      onDropAccepted={(files) => {
        const file = files[0]
        const reader = new FileReader()

        reader.addEventListener('load', () => {
          const src = reader.result?.toString() || ''
          setFile({ file, src })
        })

        reader.readAsDataURL(file)
      }}
      maxFiles={1}
      inputRef={dropzoneRef}
      accept={['image/png', 'image/jpg', 'image/jpeg', 'image/gif']}
    >
      {children}
    </DropzoneInput>
  )
}

export function useDebounceEffect(fn, waitTime, deps) {
  useEffect(() => {
    const t = setTimeout(() => {
      fn.apply(undefined, deps)
    }, waitTime)

    return () => {
      clearTimeout(t)
    }
  }, deps)
}

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

const TO_RADIANS = Math.PI / 180
export async function canvasPreview(
  image,
  canvas,
  crop,
  scale = 1,
  rotate = 0,
) {
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    throw new Error('No 2d context')
  }

  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

  ctx.scale(pixelRatio, pixelRatio)
  ctx.imageSmoothingQuality = 'high'

  const cropX = crop.x * scaleX
  const cropY = crop.y * scaleY

  const rotateRads = rotate * TO_RADIANS
  const centerX = image.naturalWidth / 2
  const centerY = image.naturalHeight / 2

  ctx.save()

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY)
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY)
  // 3) Rotate around the origin
  ctx.rotate(rotateRads)
  // 2) Scale the image
  ctx.scale(scale, scale)
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY)
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
  )

  ctx.restore()
}
