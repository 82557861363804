import React, { useMemo } from 'react'
import { useWatch } from 'react-hook-form'
import { FormGroup } from 'reactstrap'

import ControlledInput from '../../../components/ControlledInput'
import { FormField } from './request-new-card'

export const otherSuffix = '_other'

export function FormContentRenderer({
  formFields,
  isLoading,
  control,
  errors,
  fieldData,
  cols = true,
  preConditions,
}) {
  return formFields.map(({ title, fields }, index) => {
    return (
      <React.Fragment key={index}>
        <div className='pt-3'>
          {!title ? null : (
            <h5
              className='rp-font-gilroyB d-inline-block mb-3'
              style={{ color: '#424652' }}
            >
              {title}
            </h5>
          )}

          <fieldset
            className='d-flex flex-column flex-md-row flex-wrap'
            style={{ gap: '0 1rem' }}
            disabled={isLoading}
          >
            {fields.map((field, index) => {
              return (
                <div
                  style={{
                    flexBasis:
                      field?.fullCol || !cols
                        ? '100%'
                        : 'calc(50% - calc(1rem / 2))',
                  }}
                  key={index}
                >
                  <FormField
                    {...field}
                    control={control}
                    error={errors[field.name]}
                    data={fieldData}
                    preConditions={preConditions}
                  />

                  {!field?.otherOption ? null : (
                    <OtherField
                      control={control}
                      name={`${field.name}${otherSuffix}`}
                      error={`${errors[field.name]}${otherSuffix}`}
                    />
                  )}
                </div>
              )
            })}
          </fieldset>
        </div>

        {index === formFields.length - 1 ? null : (
          <hr
            style={{
              marginRight: '-1.5rem',
              marginLeft: '-1.5rem',
              borderTopColor: '#eff2f7',
            }}
          />
        )}
      </React.Fragment>
    )
  })
}

function OtherField({ control, name, error }) {
  const fieldName = useMemo(() => {
    const index = name.indexOf(otherSuffix)
    const fn = name.slice(0, index)
    return fn
  }, [name])

  const fieldValue = useWatch({
    control,
    name: fieldName,
  })

  if (!fieldValue?.includes('other')) {
    return null
  }

  return (
    <FormGroup className='mt-n5'>
      <ControlledInput
        style={{ height: 26 }}
        control={control}
        name={name}
        error={error}
        className='my-1 px-2 py-1'
        placeholder='Other answer'
      />
    </FormGroup>
  )
}
