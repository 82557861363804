export function getCurrencyFormatter(
  currency = 'USD',
  locale = 'en-US',
  options = {},
) {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    ...options,
  })
}

export const usdFormatter = getCurrencyFormatter()
