import React from 'react'
import { Controller } from 'react-hook-form'

import DropzoneInput from '../Common/dropzone-input'

export default function ControlledDropzoneInput({
  control,
  name,
  children,
  error,
  ...props
}) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { name, onChange } }) => {
        return (
          <DropzoneInput
            name={name}
            onDropAccepted={onChange}
            invalid={error}
            {...props}
          >
            {children}
          </DropzoneInput>
        )
      }}
    />
  )
}
