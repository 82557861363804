import React from 'react'

const StyledTh = (props) => {
  const { children, textRight, className = '' } = props
  return (
    <th
      style={{
        borderTop: 0,
        fontWeight: '500',
        textAlign: textRight ? 'right' : 'left',
      }}
      className={`font-size-14 px-4 px-3 ${className}`}
    >
      {children}
    </th>
  )
}

export default StyledTh
