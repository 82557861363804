import React, { useEffect, useState } from 'react'
import { Modal } from 'reactstrap'
import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import { useSelector } from 'react-redux'
import FormGroup from 'reactstrap/lib/FormGroup'
import Label from 'reactstrap/lib/Label'
import Input from 'reactstrap/lib/Input'
import toastr from 'toastr'
const SaveChangesModal = ({
  isOpen,
  toggle,
  onSaveChanges,
  isAdmin,
  templateId,
  defaultName,
}) => {
  const countries = useSelector((state) =>
    state?.Layout?.staticData?.countries?.map((e) => ({
      label: e?.name,
      value: e?.id,
    })),
  )
  const [name, setName] = useState(defaultName)
  const [country, setCountry] = useState(null)
  useEffect(() => {
    setName(defaultName)
  }, [defaultName])

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title mt-0'>Save Contract</h5>
          <button
            type='button'
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={toggle}
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <div className='modal-body'>
          {isAdmin ? (
            <p>Are you sure you want to save this template.</p>
          ) : (
            <>
              {name === defaultName ? (
                <p>Are you sure you want to overwrite this template.</p>
              ) : (
                <p>Create new template</p>
              )}
            </>
          )}
          {isAdmin ? (
            templateId === 0 ? (
              <CustomSelect
                options={countries}
                value={country}
                onChange={setCountry}
              />
            ) : null
          ) : (
            <FormGroup>
              <Label>Template Name</Label>
              <Input
                placeholder='Template Name'
                value={name}
                onChange={(e) => {
                  setName(e.target.value)
                }}
              />
            </FormGroup>
          )}
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className='btn btn-outline-light'
            data-dismiss='modal'
            onClick={toggle}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => {
              if (name || isAdmin) {
                onSaveChanges(name, country?.value)
              } else {
                toastr.error('Please enter template name')
              }
            }}
          >
            Save changes
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default SaveChangesModal
