import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm } from 'react-hook-form'
import {
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import { addDays, format } from 'date-fns'
import ControlledCurrencyInput from '../../../components/ControlledCurrencyInput'
import ControlledDatePicker from '../../../components/ControlledDatePicker'
import ControlledSelect from '../../../components/ControlledSelect'
import Steps from '../../../components/Steps'
import Button from '../../../components/ui/button'
import { FlagIcon } from '../../../components/ui/flag-icon'
import { useFetch } from '../../../helpers/hooks'
import { deScheduledTerminate, deTerminationCosts } from '../../../services/api'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import styles from './de-termination-modal.module.css'
import { getInputErrorMessage } from '../../../components/Forms/get-input-error-message'
import InputFeedback from '../../../components/ui/input-feedback'

const formSchema = yup.object().shape({
  termination_reason: yup.string().required('Termination reason is required'),
  termination_date: yup.date().required('Termination date is required'),
  last_payment_amount: yup.number().required('Last payment amount is required'),
  payment_date: yup.date().required('Payment date is required'),
})

const dateFormat = 'yyyy-MM-dd'

const terminationFormId = 'termination-form'

export default function DeTerminationModal({
  isOpen,
  toggle,
  contract,
  onConfirm,
}) {
  const noticePeriod = contract?.notice_period
  const ptoDays = contract?.paid_vacation_days

  const defaultTerminationDate = format(
    addDays(new Date(), noticePeriod + ptoDays),
    dateFormat,
  )

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { termination_date: defaultTerminationDate },
    resolver: yupResolver(formSchema),
  })

  const { data: terminationCosts } = useFetch(
    {
      action: deTerminationCosts,
      autoFetch: isOpen && !!contract?.id,
      body: { contract_id: contract?.id },
    },
    [isOpen, contract?.id],
  )

  const { startFetch: scheduleTermination, isLoading: schedulingTermination } =
    useFetch({
      action: deScheduledTerminate,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.error(data?.message || 'Something went wrong')
        } else {
          toastr.success('Contract termination scheduled successfully')
          toggle()
          onConfirm?.()
        }
      },
      onError: (error) => {
        toastr.error(error?.message || 'Something went wrong')
      },
    })

  function onSubmit(data) {
    scheduleTermination({ ...data, contract_id: contract?.id })
  }

  const contractFormatter = getCurrencyFormatter(contract?.currency?.code)

  const noticeCost = contractFormatter.format(terminationCosts?.notice)
  const ptoCost = contractFormatter.format(terminationCosts?.pto)
  const severanceCost = contractFormatter.format(terminationCosts?.tenure)

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={styles.modalMedium}
      unmountOnClose
    >
      <ModalHeader cssModule={{ 'modal-title': 'w-100' }}>
        <Steps data={['Review', 'Confirm']} activeTab={0} />
      </ModalHeader>

      <ModalBody>
        <p className='mb-3 text-gray-h'>
          This contract has a 30 day notice period, are you sure you want to
          terminate it now? This action is not reversible. The current payment
          cycle will be cancelled.
        </p>

        <form
          className='mb-3'
          id={terminationFormId}
          onSubmit={handleSubmit(onSubmit)}
        >
          <ControlledSelect
            control={control}
            name='termination_reason'
            label='Select a Reason'
            options={[
              { label: 'Voluntary Employee', value: 'voluntary_employee' },
            ]}
            wrapperClassName='mb-3'
            error={errors?.termination_reason?.message}
          />

          <ControlledDatePicker
            control={control}
            name='termination_date'
            label='Last date'
            placeholder='Select last date'
            dateFormat={dateFormat}
            wrapperClassName='mb-3'
            minDate={new Date()}
            error={errors?.termination_date?.message}
          />

          <FormGroup>
            <label htmlFor='last_payment_amount'>
              Enter the amount of the last payment
            </label>

            <InputGroup>
              <InputGroupAddon addonType='prepend'>
                <InputGroupText style={{ minWidth: 80 }}>
                  <FlagIcon className='mr-2' code={contract?.currency?.code} />
                  {contract?.currency?.code}
                </InputGroupText>
              </InputGroupAddon>

              <ControlledCurrencyInput
                control={control}
                name='last_payment_amount'
                id='last_payment_amount'
                prefix={`${contract?.currency?.symbol} `}
              />
            </InputGroup>

            {!errors?.last_payment_amount ? null : (
              <InputFeedback className='mt-1'>
                {getInputErrorMessage(errors?.last_payment_amount)}
              </InputFeedback>
            )}
          </FormGroup>

          <ControlledDatePicker
            control={control}
            name='payment_date'
            label='Payment Date'
            placeholder='Select payment date'
            dateFormat={dateFormat}
            wrapperClassName='mb-3'
            minDate={new Date()}
            error={errors?.payment_date?.message}
          />
        </form>

        <div className='p-3 rounded bg-slate-100'>
          <div className='text-gray-h rp-font-bold rp-font-gilroyB mb-2'>
            End of Service Benefits summary
          </div>
          <ul className='pl-4 mb-0 text-slate-500'>
            <li>
              {ptoCost} : {ptoDays} days remaining PTO
            </li>
            <li>
              {noticeCost} : {noticePeriod}-day notice period
            </li>
            <li>
              {severanceCost} : Severance based on 1.5 days per year worked
            </li>
          </ul>
        </div>
      </ModalBody>

      <ModalFooter>
        <Button
          color='light'
          outline
          onClick={toggle}
          disabled={schedulingTermination}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          formId={terminationFormId}
          disabled={schedulingTermination}
          loading={schedulingTermination}
        >
          Proceed
        </Button>
      </ModalFooter>
    </Modal>
  )
}
