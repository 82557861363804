import { format, sub } from 'date-fns'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { components } from 'react-select'
import {
  Alert,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap'
import toastr from 'toastr'

import customStyles from '../../components/Common/react-select-custom-styles'
import CustomDatePicker from '../../components/Forms/CustomDatePicker/CustomDatePicker'
import CustomSelect from '../../components/Forms/CustomSelect/CustomSelect'
import Button from '../../components/ui/button'
import UsdSwiftMessage from '../../components/usd-swift-message'
import { useFetch } from '../../helpers/hooks'
import {
  createWithdrawAccount,
  getBankFields,
  withdrawCurrencies,
} from '../../services/api'
import deepen from '../../utils/deepen'
import { mapCurrencyToOption } from '../../utils/map-to-option'
import WithdrawMethodCard from '../Withdrawal/components/WithdrawMethodCard'
import './bank-accounts.css'
import useWithdrawAccounts from './use-contractor-withdraw-accounts'

function Messages({ showUsMessage }) {
  return (
    <div className='mb-4 mx-2 d-flex flex-column' style={{ gap: '0.5rem' }}>
      <Alert
        color='info'
        className='mb-0 p-2 font-size-12 bg-soft-primary border border-soft-primary text-primary'
      >
        <strong>Important:</strong> The name on the bank account must be the
        same as your name or entity name on the signed contract. If different,
        the transfer will be rejected.
      </Alert>
      <UsdSwiftMessage showMessage={showUsMessage} />
    </div>
  )
}

const BankAccounts = ({
  onNext = () => {},
  currency,
  isEdit,
  isEditing,
  onAdd = () => {},
  noCards,
  onCancel = () => {},
}) => {
  const [checkedCard, setCheckedCard] = useState(-1)
  const [showNewBankAccountModal, setShowNewBankAccountModal] = useState(false)

  const [editing, setEditing] = useState(!!noCards)
  useEffect(() => {
    setEditing(!!noCards)
  }, [isEditing, noCards])

  const selectedCurrency = useSelector(
    (state) => state.Withdraw?.withdrawAmount?.currency,
  )

  const { data: currencies } = useFetch(
    {
      action: withdrawCurrencies,
      autoFetch: editing || showNewBankAccountModal,
      body: { currency: selectedCurrency?.code },
    },
    [editing, showNewBankAccountModal],
  )

  const { getAccountsList, accounts, loading } = useWithdrawAccounts(
    'bank',
    selectedCurrency?.code,
  )

  function handleClose() {
    setShowNewBankAccountModal(false)
    onCancel?.()
  }

  return (
    <div>
      {!editing ? (
        <Row className='justify-content-center p-0 m-0'>
          {loading ? (
            <Col className={'p-0 m-0'} style={{ minHeight: '15rem' }}>
              <Row
                style={{ minHeight: '15rem' }}
                className='justify-content-center align-items-center p-0 m-0'
              >
                <Spinner type='grow' className='mr-2' color='primary' />
              </Row>
            </Col>
          ) : (
            <Col
              md={isEdit ? 9 : undefined}
              className='p-0 d-flex flex-column'
              style={{ gap: '1rem' }}
            >
              {accounts?.map((e, i) => (
                <WithdrawMethodCard
                  onClick={() => {
                    setCheckedCard(i)
                    onNext(e)
                  }}
                  checked={checkedCard === i}
                  key={`acc${i}`}
                  card={e}
                  index={i}
                />
              ))}
              <button
                onClick={() => setShowNewBankAccountModal(true)}
                className='rp-btn-nostyle align-self-start text-primary rp-font-bold rp-font-gilroyB'
                type='button'
              >
                + Add New Bank Account
              </button>
            </Col>
          )}
        </Row>
      ) : (
        <NewAddBankForm
          currency={currency}
          currencies={currencies?.map((c) => mapCurrencyToOption(c))}
          toggle={onCancel}
          updateList={(data, res) => {
            onAdd(data, res)
            getAccountsList()
          }}
        />
      )}
      <Modal
        isOpen={showNewBankAccountModal}
        scrollable
        centered
        className='newAccountModal'
        toggle={handleClose}
      >
        <ModalHeader toggle={handleClose}>Add Bank account</ModalHeader>
        <ModalBody className='pb-0'>
          <NewAddBankForm
            currency={currency}
            currencies={currencies?.map((c) => mapCurrencyToOption(c))}
            toggle={handleClose}
            updateList={(data, res) => {
              onAdd(data, res)
              setShowNewBankAccountModal(false)
              getAccountsList()
            }}
          />
        </ModalBody>
      </Modal>
    </div>
  )
}

function mapValuesAllowed({ key, name }) {
  return { label: name, value: key }
}

const dateFormat = 'yyyy-MM-dd'
function formatDate(date) {
  return format(new Date(date ?? null), dateFormat)
}

export function NewAddBankForm({ currency, currencies, toggle, updateList }) {
  const user = useSelector((state) => state.Account?.user)
  const [selectedCurrency, setSelectedCurrency] = useState(null)
  const [loadingButton, setLoadingButton] = useState(false)

  // Fields to show to user
  const [fields, setFields] = useState(null)
  // Sometimes, there are two (or more) sets of fields,
  // depending on a type of this field
  const [fieldType, setFieldType] = useState(null)
  // The data filled by the user
  const [fieldData, setFieldData] = useState({})

  function getFields(data) {
    if (!currency || !selectedCurrency?.value) return
    const body = {
      source: currency,
      target: selectedCurrency?.value,
      targetAmount: 300, // arbitrary value
    }

    if (data) {
      body.details = { ...data }
    }

    setLoadingButton(true)
    getBankFields(user?.token, body)
      .then((r) => {
        let newData = r?.data?.data

        const newDataTypes = Object.values(newData).map((e) => e.type)
        const isInNewTypes = newDataTypes.includes(fieldType)
        const newType =
          newDataTypes.length > 1
            ? isInNewTypes
              ? fieldType
              : newDataTypes[0]
            : newDataTypes[0]
        setFieldType(newType)

        // Convert data format from `[{}, {}]` to `{key: {}, key2: {}}`
        newData = !Array.isArray(newData)
          ? {}
          : newData.reduce((prev, curr) => {
              return { ...prev, [curr?.type]: curr }
            }, {})

        setFields(newData)
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e)
      })
      .finally(() => {
        setLoadingButton(false)
      })
  }

  const fieldsToRender = useMemo(() => {
    if (!fields || !fieldType) return []

    return fields[fieldType]?.fields
  }, [fieldType, fields])

  const fieldTypeOptions = useMemo(() => {
    if (!fields) return []

    const fieldTypes = Object.values(fields).map(({ title, type }) => ({
      label: title,
      value: type,
    }))

    if (fieldTypes.length <= 1) return []

    return [
      { label: 'Select', value: null, disabled: true, isSelected: true },
      ...fieldTypes,
    ]
  }, [fields])

  function handleFieldChange(event, field) {
    let name = null
    let value = null

    if (field.type === 'date') {
      name = field.key
      value = event
    } else {
      name = event.target.name
      value = event.target.value
    }

    const newFieldData = { ...fieldData, [name]: value }

    setFieldData(newFieldData)

    if (field?.refreshRequirementsOnChange) {
      getFields(newFieldData)
    }
  }

  useEffect(() => {
    getFields()
    setFieldData({})
  }, [selectedCurrency])

  function handleSubmit(event) {
    event.preventDefault()

    // Filter unwanted data
    const filteredData = {}
    const keys = fieldsToRender.map((f) => f.key)
    keys.forEach((key) => {
      filteredData[key] = fieldData[key]
    })

    /* [Start] We need to send the bankName field if we have it */
    if (keys.includes('bankCode')) {
      const banks = fieldsToRender.find(
        (e) => e.key === 'bankCode',
      )?.valuesAllowed
      if (banks?.length > 0) {
        const theBank = banks.find((e) => e.key === fieldData.bankCode)
        filteredData.bankName = theBank?.name
      }
    }
    if (keys.includes('branchCode')) {
      const branches = fieldsToRender.find(
        (e) => e.key === 'branchCode',
      )?.valuesAllowed
      if (branches?.length > 0) {
        const theBranch = branches.find((e) => e.key === fieldData.branchCode)
        filteredData.branchName = theBranch?.name
      }
    }
    if (keys.includes('swiftCode')) {
      const field = fieldsToRender.find((e) => e.key === 'swiftCode')
      if (field.type === 'select') {
        const banks = field?.valuesAllowed
        const theBank = banks.find((e) => e.key === fieldData.swiftCode)
        filteredData.bankName = theBank?.name
      }
    }
    /* [END] We need to send the bankName field if we have it */

    const deepFieldData = deepen(filteredData)

    const data = {
      currency: selectedCurrency?.value,
      accountHolderName: `${user?.first_name} ${user?.last_name}`,
      type: fieldType,
      legalType: deepFieldData?.legalType,
      ...deepFieldData,
    }

    setLoadingButton(true)
    createWithdrawAccount(user?.token, data)
      .then((r) => {
        if (r.data.success) {
          updateList(data, r.data.data)
          toggle()
        } else {
          toastr.error(r.data.data?.error || r.data?.error || r.data?.message)
        }
      })
      .catch((e) => {
        toastr.error('An error occurred')
        // eslint-disable-next-line no-console
        console.log(e)
      })
      .finally(() => {
        setLoadingButton(false)
      })
  }

  const showUsMessage =
    selectedCurrency?.value === 'USD' && fieldType === 'swift_code'

  return (
    <Container fluid className='px-0'>
      <Messages showUsMessage={showUsMessage} />

      <form onSubmit={handleSubmit}>
        <div
          className='mb-md-0'
          style={{ minHeight: '50vh', marginBottom: 'calc(74px * 2)' }}
        >
          <Row className='mx-n3'>
            <Col md={6}>
              <FormGroup>
                <Label htmlFor='currency'>Select the desired currency</Label>
                <CustomSelect
                  name='currency'
                  inputId='currency'
                  value={selectedCurrency}
                  onChange={setSelectedCurrency}
                  options={currencies}
                  classNamePrefix='RS-Control'
                  styles={customStyles}
                  customComponents={{
                    Option: customOption,
                  }}
                />
              </FormGroup>
            </Col>
            {fieldTypeOptions?.length <= 0 ? null : (
              <Col md={6}>
                <FormGroup>
                  <Label htmlFor='currency'>Type</Label>
                  <NativeSelect
                    value={fieldType}
                    onChange={(e) => setFieldType(e.target.value)}
                    options={fieldTypeOptions}
                  />
                </FormGroup>
              </Col>
            )}
            {fieldsToRender?.map((field) => {
              return (
                <Col md={6} key={field.key}>
                  <FormGroup>
                    <Label htmlFor={field.key}>{field.name}</Label>
                    {field.type === 'text' ? (
                      <Input
                        name={field.key}
                        id={field.key}
                        placeholder={field.name}
                        value={fieldData[field.name]}
                        onChange={(e) => handleFieldChange(e, field)}
                        maxLength={field?.maxLength}
                        minLength={field?.minLength}
                      />
                    ) : ['select', 'radio'].includes(field.type) ? (
                      <NativeSelect
                        name={field.key}
                        id={field.key}
                        placeholder={field.name}
                        value={fieldData[field.name]}
                        onChange={(e) => handleFieldChange(e, field)}
                        options={[
                          {
                            label: 'Select',
                            value: null,
                            disabled: true,
                            isSelected: true,
                          },
                          ...(field.valuesAllowed?.map(mapValuesAllowed) ?? []),
                        ]}
                      />
                    ) : field.type === 'date' ? (
                      <CustomDatePicker
                        value={formatDate(fieldData[field.name])}
                        name={field.key}
                        handleOnChange={(v) => {
                          handleFieldChange(formatDate(v), field)
                        }}
                        placeholder={dateFormat}
                        minDate={sub(new Date(), { years: 118 })}
                        maxDate={sub(new Date(), { years: 18 })}
                      />
                    ) : process.env.NODE_ENV !== 'production' ? (
                      `${field.type} is not supported`
                    ) : null}
                  </FormGroup>
                </Col>
              )
            })}
          </Row>
        </div>

        <ModalFooter className='mx-n3 custom-modal-footer bg-white'>
          <Button
            color='light'
            outline
            type='button'
            onClick={toggle}
            disabled={loadingButton}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            disabled={loadingButton}
            loading={loadingButton}
          >
            Add account
          </Button>
        </ModalFooter>
      </form>
    </Container>
  )
}

function NativeSelect({
  options,
  name,
  id,
  onChange,
  value,
  placeholder,
  defaultValue,
}) {
  const selectProps = { name, id, onChange, value, placeholder, defaultValue }
  return (
    <select
      className='form-select form-control'
      style={{ height: 42 }}
      {...selectProps}
    >
      {options?.map((option) => {
        return (
          <option
            value={option.value}
            key={option.value}
            disabled={option.disabled}
            selected={option.isSelected}
          >
            {option.label}
          </option>
        )
      })}
    </select>
  )
}

const { Option } = components

const customOption = (props) => (
  <Option {...props}>
    <div className='input-select'>
      <div className='input-select__single-value d-flex flex-row align-items-center'>
        {props.data.flag && (
          <img
            src={props.data.flag}
            style={{
              width: '1rem',
              height: '1rem',
              borderRadius: '0.5rem',
              marginRight: 10,
              objectFit: 'cover',
            }}
            alt={props.data.code}
          />
        )}
        {props.data.icon}
        <span
          className='font-weight-light text-dark font-weight-bold'
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {props.data.code}
        </span>
        <span
          className={'font-weight-light text-secondary ml-2'}
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {props.data.name}
        </span>
      </div>
    </div>
  </Option>
)

export default BankAccounts
