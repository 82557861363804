import React, { useState } from 'react'
import ReactInputVerificationCode from 'react-input-verification-code'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'

import { TFA_TYPES } from '../../components/CommonForBoth/TwoFactorAuthentication'
import { StyledH5, StyledH6 } from '../../components/Typo'
import Button from '../../components/ui/button'
import { CheckItem } from '../../components/ui/check-item'
import { useFetch } from '../../helpers/hooks'
import useTdGuid from '../../helpers/hooks/use-td-guid'
import useUserAgent from '../../helpers/hooks/use-user-agent'
import { check2fa } from '../../services/api'
import { loginUserSuccessful } from '../../store/auth/register/actions'
import { isOtpCodeValid } from '../../utils/is-otp-code-valid'
import logoMain from './../../assets/images/identity/logo_main.png'

const twoFaRememberMeLabel = (
  <span className='d-flex align-items-center' style={{ gap: '0.25rem' }}>
    <span>Remember this device for 30 days</span>{' '}
    <i className='bx bxs-error-circle font-size-16' id='notice-icon' />
    <UncontrolledTooltip target='notice-icon'>
      For your convenience, keep this checked. On shared devices, additional
      precautions are recommended.
    </UncontrolledTooltip>
  </span>
)

const TwoFactorAuthenticator = () => {
  const user = useSelector((state) => state?.Account?.user)
  const history = useHistory()
  const [code, setCode] = useState('')
  const [rememberMeChecked, setRememberMeChecked] = useState(false)

  const { setGuid } = useTdGuid()

  const check = useFetch({
    action: check2fa,
    successAction: loginUserSuccessful,
    onComplete: (data) => {
      let deviceGuid = null
      if (Array.isArray(data)) {
        deviceGuid = data[0]?.trusted_device?.guid
      } else {
        deviceGuid = data?.trusted_device?.guid
      }

      if (deviceGuid) {
        setGuid(deviceGuid)
      }

      setTimeout(() => {
        history.push('/activity')
      }, 200)
    },
    onError: (err) => {
      setCode('')
      toastr.error(err)
    },
  })

  const userAgent = useUserAgent()

  const handleValidSubmit = (newCode) => {
    const validCode = isOtpCodeValid(newCode) ? newCode : code
    let data = { user_id: user?.id, otp: validCode }

    if (rememberMeChecked) {
      data = {
        ...data,
        remember_me: rememberMeChecked ? 1 : 0,
        user_agent: userAgent,
      }
    }

    check.startFetch(data)
  }
  function handleCompleted(code) {
    if (isOtpCodeValid(code)) {
      handleValidSubmit(code)
    }
  }

  return (
    <>
      <StyledDiv className='d-flex justify-content-center align-items-center'>
        <img src={logoMain} height={24} alt='' />
      </StyledDiv>

      <div className='blur-container'>
        <StyledH5
          min='22px'
          max='32px'
          className='text-center mb-0 pt-4 pt-md-5 pb-3 pb-md-4'
        >
          Two-Factor Authentication
        </StyledH5>

        <Container className='px-3 pb-3 pt-0'>
          <Row className='d-flex justify-content-center p-0 m-0'>
            <Col md={9} lg={7} xl={6} className='p-0 m-0'>
              <Card style={{ boxShadow: '0px 1px 0px #DFE1E6' }}>
                <CardBody className='pt-3'>
                  <StyledH6
                    style={{ lineHeight: '24px' }}
                    min='14px'
                    max='14px'
                    className='mb-4 text-center font-weight-light text-secondary'
                  >
                    {user?.type_2fa === TFA_TYPES.google.value ? (
                      <>
                        Open the{' '}
                        <strong className='rp-font-bold'>
                          Google Authenticator
                        </strong>{' '}
                        app on your phone and enter the generated code.
                      </>
                    ) : user?.type_2fa === TFA_TYPES.email.value ? (
                      <>Enter the code from you received via Email.</>
                    ) : null}
                  </StyledH6>
                  <div>
                    <div className='verificationCode'>
                      <ReactInputVerificationCode
                        type='number'
                        length={6}
                        value={code}
                        onChange={setCode}
                        onCompleted={handleCompleted}
                      />
                    </div>

                    <div className='mt-4'>
                      <Button
                        loading={check.isLoading}
                        disabled={check.isLoading || code?.includes('·')}
                        type='button'
                        onClick={handleValidSubmit}
                        block
                      >
                        Confirm
                      </Button>
                    </div>
                    {!userAgent ? null : (
                      <div className='mt-4'>
                        <CheckItem
                          label={twoFaRememberMeLabel}
                          checked={rememberMeChecked}
                          onChange={(e) =>
                            setRememberMeChecked(e.target.checked)
                          }
                        />
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>

              <div className='text-center mt-2'>
                <Link style={{ color: '#315CE7' }} to='/logout'>
                  Logout
                </Link>
              </div>

              <div className='mt-5 text-center'>
                <p className={'text-secondary '}>
                  {new Date().getFullYear()} © RemotePass.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
const StyledDiv = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 0px 8px #eaeaea, inset 0px -1px 0px #eaeaea;
  height: 56px;
  @media (min-width: 767px) {
    height: 80px;
  }
`
export default TwoFactorAuthenticator
