import React, { useState } from 'react'
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap'
import { CirclePicker } from 'react-color'
import Input from 'reactstrap/lib/Input'
import Dropzone from 'react-dropzone'
import toastr from 'toastr'
import WiseButton from '../../components/WiseButton'
import { useFetch } from '../../helpers/hooks'
import { customizeCompany } from '../../services/api'
import { storage } from '../../helpers/config'
import logoPlaceholder from './../../assets/images/logo_placeholder2.png'

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
const Customization = ({ data }) => {
  const [color, setColor] = useState(data?.main_color)
  const [domain, setDomain] = useState(data?.domain)
  const [logo, setLogo] = useState(null)

  const update = useFetch({
    action: customizeCompany,
    onComplete: () => {
      document.getElementById('root').style.setProperty('--primary', color)
    },
  })

  return (
    <Card className='m-0 p-0'>
      <CardBody className='m-0 p-0'>
        <Col>
          <Row className='p-3 w-100 pb-0 mb-0'>
            {/* <StyledLogo style={{ position: 'relative', margin: '40px 0 18px 0' }}> */}

            <div>
              {
                <div className='upload-btn' style={{}}>
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      toBase64(acceptedFiles[0]).then((r) => {
                        setLogo({ base64: r, file: acceptedFiles[0] })
                      })
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        style={{
                          zIndex: 99999999999,
                        }}
                      >
                        <div {...getRootProps()}>
                          <input
                            {...getInputProps()}
                            onChange={async (e) => {
                              if (
                                e.target.files[0] &&
                                [
                                  'image/png',
                                  'image/jpg',
                                  'image/jpeg',
                                  'image/gif',
                                ].includes(e.target.files[0].type)
                              ) {
                                const FileSize =
                                  e.target.files[0].size / 1024 / 1024
                                if (FileSize > 25) {
                                  toastr.error('File size exceeds 25 MB')
                                  return
                                }
                                toBase64(e.target.files[0]).then((r) => {
                                  setLogo({
                                    base64: r,
                                    file: e.target.files[0],
                                  })
                                })
                              } else {
                                toastr.error('File not supported')
                              }
                            }}
                          />
                          <Row className='align-items-center m-0 p-0'>
                            <img
                              width={120}
                              src={
                                logo?.base64 ||
                                (!!data?.logo && `${storage}${data.logo}`) ||
                                logoPlaceholder
                              }
                              alt=''
                              className='bg-soft-primary'
                            />
                            <a className='mx-3 text-primary'>Upload Logo</a>
                          </Row>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
              }
            </div>
            {/* </StyledLogo> */}
          </Row>
          <Row className='p-3 w-100 pb-0 mb-0'>
            <FormGroup className='mb-0'>
              <Label>Custom Domain</Label>
              <InputGroup>
                <Input
                  value={domain}
                  onChange={(e) => {
                    setDomain(e.target.value)
                  }}
                />
                <InputGroupAddon addonType='append'>
                  <InputGroupText>remotepass.com</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Row>
          <Row className='p-3 w-100 pb-0 mb-0'>
            <FormGroup>
              <Label>Primary color</Label>
              <Input placeholder='#000000' value={color} />
            </FormGroup>
            <CirclePicker
              className='mx-4 mt-3'
              circleSize={20}
              color={color || undefined}
              onChange={(c) => {
                setColor(c.hex)
              }}
            />
          </Row>
          <div className='w-100 divider border-top my-md-4 my-3' />
        </Col>
        <Row className='px-md-4 pb-3 pb-md-4 px-3 m-0 justify-content-end w-100'>
          <WiseButton
            type='submit'
            onClick={() => {
              update.startFetch({
                domain,
                main_color: color,
                logo: logo?.file,
              })
            }}
            width={'146px'}
            loading={update.isLoading}
            text={'Save'}
            minWidth={'100%'}
            bold
          />
        </Row>
      </CardBody>
    </Card>
  )
}

export default Customization
