import { yupResolver } from '@hookform/resolvers/yup'
import classnames from 'classnames'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import ControlledCurrencyInput from '../../../../components/ControlledCurrencyInput'
import ControlledSelect from '../../../../components/ControlledSelect'
import Button from '../../../../components/ui/button'
import { useFetch } from '../../../../helpers/hooks'
import { getLocalCurrencies, simulateFee } from '../../../../services/api'
import { mapCurrencyToOption } from '../../../../utils/map-to-option'
import {
  customOption,
  customSingleValue,
} from '../../../Contract/RequestContract/FullTimeForm'
import SimulatorReview from '../components/SimulatorReview'

const FeeSimulator = () => {
  const staticData = useSelector((state) => state.Layout.staticData)
  const schema = yup.object().shape({
    amount: yup.number().required(),
    base_currency_id: yup.number().required(),
    account_currency_id: yup.number().required(),
  })
  const {
    handleSubmit,
    control,

    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(schema),
  })
  const currencies = useFetch({
    action: getLocalCurrencies,
    autoFetch: true,
    initResult: [],
  })
  const simulate = useFetch({
    action: simulateFee,
    withAdminAccess: true,
    onError: (error) => {
      toastr.error(error)
    },
  })
  const onSubmit = (values) => {
    simulate.startFetch(values)
  }
  return (
    <div className='page-content'>
      <h1 className='rp-font-gilroyB' style={{ marginBottom: '2rem' }}>
        Fee Simulator
      </h1>

      <Card className='mt-3'>
        <Form
          autoComplete='off'
          className='py-3 py-md-4'
          onSubmit={handleSubmit(onSubmit)}
        >
          <Container fluid>
            <Row>
              <Col md={6} sm={12}>
                <FormGroup className='mb-0'>
                  <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                    Amount
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <InputGroup style={{ zIndex: 96 }}>
                    <ControlledCurrencyInput
                      control={control}
                      name='amount'
                      error={errors.amount}
                    />
                    <InputGroupAddon
                      style={{ width: '125px' }}
                      addonType='append'
                    >
                      <InputGroupText
                        className={classnames({
                          'p-0': true,
                          'border-danger': !!errors.base_currency_id,
                        })}
                        style={{ width: '100%', border: 0 }}
                      >
                        <div className='w-100'>
                          <ControlledSelect
                            error={errors.base_currency_id}
                            control={control}
                            name='base_currency_id'
                            options={staticData?.currencies?.map((c) =>
                              mapCurrencyToOption(c, 'id'),
                            )}
                            classNamePrefix='RS-Addon'
                            customComponents={{
                              Option: customOption,
                              Value: customSingleValue,
                              SingleValue: customSingleValue,
                            }}
                          />
                        </div>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md={3} sm={12}>
                <FormGroup className='mb-0'>
                  <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                    Withdraw currency
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <ControlledSelect
                    error={errors.account_currency_id}
                    control={control}
                    name='account_currency_id'
                    options={currencies?.data?.map((c) =>
                      mapCurrencyToOption(c, 'id'),
                    )}
                    classNamePrefix='RS-Addon'
                    customComponents={{
                      Option: customOption,
                      Value: customSingleValue,
                      SingleValue: customSingleValue,
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={3} sm={12} className='d-flex align-items-end'>
                <Button
                  type='submit'
                  loading={simulate.isLoading}
                  disabled={simulate.isLoading}
                >
                  Simulate
                </Button>
              </Col>
            </Row>
          </Container>
        </Form>
        {simulate?.data && !simulate.isLoading && (
          <SimulatorReview data={simulate.data} />
        )}
      </Card>
    </div>
  )
}

export default FeeSimulator
