import { format } from 'date-fns'
import {
  NotePencil,
  CalendarBlank,
  ClockClockwise,
  CurrencyCircleDollar,
} from '@phosphor-icons/react'
import React, { useState } from 'react'
import {
  Card,
  CardBody,
  Col,
  Row,
  ModalBody,
  Modal,
  ModalFooter,
  ModalHeader,
  Carousel,
  CarouselItem,
  Badge,
} from 'reactstrap'
import Button from '../../../components/ui/button'
import InsurancePlans from './InsurancePlans'
import NoContent from '../../../components/NoContent'
import { StyledH1 } from '../../../components/Typo'
import remoteHealth from '../../../assets/images/remote-health-safety-wing.png'
import healthIllustration from '../../../assets/images/health-illustration.svg'
import welcomeImage from '../../../assets/images/welcome.svg'
import { useFetch } from '../../../helpers/hooks'
import {
  getContractorContractAllowances,
  deactivateAllowanceInsuranceClient,
  getInsurancePlans,
} from '../../../services/api'
import { getAgeBracket } from '../../../utils/insurance-age-bracket'
import { userTypes } from '../../../helpers/enum'
import { AllowanceStatuses } from '../utils/constants'
import BadgeX from '../../../components/Table/BadgeX'
import Loader from '../../../components/ui/loader'

export const Allowance = ({
  contractUser,
  userInfo,
  contract,
  showOnboardingModal,
}) => {
  const [openBoostModal, setOpenBoostModal] = useState(true)
  const [
    openConfirmAllowanceDeactivationModal,
    setOpenConfirmAllowanceDeactivationModal,
  ] = useState(false)

  const insurancePlans = useFetch({
    body: {
      ages: getAgeBracket(contractUser?.birth_date),
      country_id: contractUser?.country?.id,
    },
    autoFetch: contractUser?.birth_date && contractUser?.country,
    action: getInsurancePlans,
  })

  const contractorContractAllowances = useFetch({
    action: getContractorContractAllowances,
    body: { contract_id: contract?.id },
    autoFetch: true,
  })

  const getStatusColor = (status) => {
    switch (status) {
      case 'active':
        return 'success'
      case 'inactive':
        return 'danger'
      default:
        return 'primary'
    }
  }

  const dateFormat = 'do MMM yyyy'

  const showAllowances =
    Array.isArray(contractorContractAllowances?.data) &&
    contractorContractAllowances?.data?.length &&
    contractorContractAllowances?.data.some(
      (a) => a?.status === AllowanceStatuses.ACTIVE,
    )

  return userInfo?.type === userTypes.COMPANY ? (
    <>
      {insurancePlans.isLoading || contractorContractAllowances?.isLoading ? (
        <Card style={{ minHeight: '80vh' }} className='bg-white rounded p-3'>
          <Loader minHeight='60vh' />
        </Card>
      ) : showAllowances ? (
        <Card className='p-0 m-0'>
          <CardBody className='p-4 m-0 rounded'>
            <Row className='p-0 m-0'>
              <h1 className='rp-font-gilroyB font-size-32'>Allowance</h1>
            </Row>
            <hr />
            <Row>
              <Col className='text-muted rp-font-semibold font-size-14'>
                <NotePencil fontSize={22} /> Status
              </Col>
              <Col className='text-muted rp-font-semibold font-size-14'>
                <CalendarBlank fontSize={22} /> Start date
              </Col>
              <Col className='text-muted rp-font-semibold font-size-14'>
                <ClockClockwise fontSize={22} /> Occurrence
              </Col>
              <Col className='text-muted rp-font-semibold font-size-14'>
                <CurrencyCircleDollar fontSize={22} /> Cost
              </Col>
              <Col></Col>
            </Row>

            {contractorContractAllowances?.data?.map((allowance, key) => {
              return (
                <Row key={key} className='align-items-center py-2'>
                  <Col>
                    <BadgeX
                      status={getStatusColor(allowance.status)}
                      name={allowance.status}
                      textStatus={getStatusColor(allowance.status)}
                      className='text-capitalize'
                    >
                      {allowance?.status}
                    </BadgeX>
                  </Col>
                  <Col>
                    <span className='rp-font-black'>
                      {format(new Date(allowance?.created_at), dateFormat)}
                    </span>
                  </Col>
                  <Col>
                    <span className='rp-font-black'>
                      {allowance?.occurrence?.name}
                    </span>
                  </Col>
                  <Col>
                    <span className='rp-font-black'>
                      {allowance?.currency?.symbol} {allowance?.amount}
                    </span>
                  </Col>
                  <Col>
                    {allowance.status === AllowanceStatuses.ACTIVE && (
                      <Button
                        size='md'
                        color='danger'
                        onClick={() => {
                          setOpenConfirmAllowanceDeactivationModal(allowance)
                        }}
                        disabled={
                          allowance.status === AllowanceStatuses.INACTIVE
                        }
                      >
                        Deactivate
                      </Button>
                    )}
                  </Col>
                </Row>
              )
            })}
          </CardBody>
        </Card>
      ) : (
        <>
          {insurancePlans.data?.country_not_supported ? (
            <Card className='bg-white rounded p-3'>
              <NoContent
                image={welcomeImage}
                subtitle={' '}
                headline={`${insurancePlans?.data?.country_name} is not supported yet`}
                onAction={() => {}}
              />
            </Card>
          ) : Array.isArray(insurancePlans.data) &&
            insurancePlans.data?.length ? (
            <Card className='p-0 m-0'>
              <CardBody className='p-4 m-0 rounded'>
                <Row className='p-0 m-0 justify-content-between align-items-center'>
                  <h1 className='rp-font-gilroyB font-size-32'>Allowance</h1>
                  <img
                    height={48}
                    src={remoteHealth}
                    alt='Remote Health Logo'
                  />
                </Row>
                <Row className='mb-5'>
                  {insurancePlans.data?.map((e, i) => (
                    <Col
                      key={`insurance-plan-${i}`}
                      md={4}
                      sm={12}
                      className=''
                    >
                      <InsurancePlans
                        plan={e}
                        title={e.name}
                        desc={e.description}
                        features={e.conditions}
                        price={e.amount}
                        currency={e.currency}
                        contractUser={contractUser}
                        contract={contract}
                        ctaText='Add Allowance'
                        onAddAllowanceUpdate={contractorContractAllowances}
                      />
                    </Col>
                  ))}
                </Row>
              </CardBody>
            </Card>
          ) : null}
        </>
      )}

      {showOnboardingModal && (
        <BoostModal
          isOpen={openBoostModal}
          toggle={() => setOpenBoostModal(false)}
        />
      )}
      {openConfirmAllowanceDeactivationModal !== null && (
        <ConfirmAllowanceDeactivationModal
          isOpen={!!openConfirmAllowanceDeactivationModal}
          toggle={() => setOpenConfirmAllowanceDeactivationModal(null)}
          allowance={openConfirmAllowanceDeactivationModal}
          contract={contract}
          onUpdate={contractorContractAllowances}
        />
      )}
    </>
  ) : (
    <></>
  )
}

const BoostModal = ({ isOpen, toggle }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const slides = [
    <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={2}
    >
      <ModalBody
        style={{
          minHeight: '700px',
        }}
      >
        <img className='w-100' src={healthIllustration} />
        <Badge className='p-2 mb-2 font-size-10' color='primary' pill>
          New
        </Badge>
        <StyledH1
          className='mb-2'
          style={{
            fontFamily: 'gilroyB',
            fontWeight: 'bold',
            marginBottom: 24,
          }}
        >
          Boost Talent, Productivity & Satisfaction!
        </StyledH1>
        <div className='text-muted'>
          Contractors now have the option to purchase health insurance on our
          platform themselves. This can help with attracting top talent,
          increase productivity and job satisfaction. and ultimately save costs
          in the long run.
        </div>
      </ModalBody>
    </CarouselItem>,
    <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={1}
    >
      <ModalBody
        style={{
          minHeight: '700px',
        }}
      >
        <div className='m-3'>
          <StyledH1
            style={{
              fontFamily: 'gilroyB',
              fontWeight: 'bold',
            }}
            className='my-4'
          >
            It has never been simpler!
          </StyledH1>
          <Card className='p-4 mb-4 border border-1'>
            <div className='d-flex justify-content-between'>
              <i className='bx bx-palette font-size-28 text-primary mr-4' />
              <div>
                <p className='rp-font-bold font-size-16 mb-2'>
                  Multiple Options
                </p>
                <span className='font-size-14 text-muted'>
                  We offer multiple options for different kinds of health
                  insurance coverage.
                </span>
              </div>
            </div>
          </Card>
          <Card className='p-4 mb-4 border border-1'>
            <div className='d-flex justify-content-between'>
              <i className='bx bx-palette font-size-28 text-primary mr-4' />
              <div>
                <p className='rp-font-bold font-size-16 mb-2'>
                  Automated & Fixed
                </p>
                <span className='font-size-14 text-muted'>
                  Transfer of a monthly allowance to the contractor can be
                  automated.
                </span>
              </div>
            </div>
          </Card>
          <Card className='p-4 mb-4 border border-1'>
            <div className='d-flex justify-content-between'>
              <i className='bx bx-palette font-size-28 text-primary mr-4' />
              <div>
                <p className='rp-font-bold font-size-16 mb-2'>Full Control</p>
                <span className='font-size-14 text-muted'>
                  The Contractor can freely chose to opt into a health insurance
                  plan themselves should they wish to or use this allowance for
                  any other purpose.
                </span>
              </div>
            </div>
          </Card>
          {/* TODO: "Need help?" should be a link... to what? */}
          <p className='pb-4 position-absolute bottom-0 font-size-16 text-primary rp-font-bold'>
            Need help?
          </p>
        </div>
      </ModalBody>
    </CarouselItem>,
  ]

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === slides.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? slides.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  return (
    <Modal
      unmountOnClose
      centered
      isOpen={isOpen}
      toggle={toggle}
      keyboard
      backdrop
    >
      <button
        className='position-absolute right-0 z-1000 btn font-size-24 p-0 mr-2 rp-font-normal'
        onClick={toggle}
        type='button'
      >
        &times;
      </button>
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        interval={false}
      >
        {slides}
        <ModalFooter className='justify-content-between'>
          <Button
            disabled={activeIndex === 0}
            color='light'
            type='button'
            outline
            onClick={() => {
              previous()
            }}
          >
            Back
          </Button>
          <Button
            color='primary'
            type='button'
            onClick={() => {
              if (activeIndex === 1) {
                toggle()
              } else {
                next()
              }
            }}
          >
            {activeIndex === 1 ? 'Browse Plans' : 'Next'}
          </Button>
        </ModalFooter>
      </Carousel>
    </Modal>
  )
}

const ConfirmAllowanceDeactivationModal = ({
  isOpen,
  toggle,
  allowance,
  contract,
  onUpdate,
}) => {
  const deactivateAllowanceInsurance = useFetch({
    action: deactivateAllowanceInsuranceClient,
    body: { contract_id: contract?.id, allowance_id: allowance?.id },
    onComplete: (data) => {
      onUpdate?.startFetch()
    },
  })

  return (
    <Modal
      unmountOnClose
      centered
      isOpen={isOpen}
      toggle={toggle}
      keyboard
      backdrop
    >
      <ModalHeader toggle={toggle}>Confirm deactivation</ModalHeader>
      <ModalBody className='text-center'>
        <i className='bx bx-error-alt font-size-24 text-danger' />
        <div className='m-3'>
          Are you sure you want to deactivate this allowance?
        </div>
      </ModalBody>

      <ModalFooter>
        <Button color='light' type='button' outline onClick={toggle}>
          Cancel
        </Button>
        <Button
          color='danger'
          type='button'
          onClick={() => {
            deactivateAllowanceInsurance.startFetch()
            toggle()
          }}
        >
          Deactivate
        </Button>
      </ModalFooter>
    </Modal>
  )
}
