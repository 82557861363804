import cx from 'classnames'
import copy from 'copy-to-clipboard'
import React, { useState } from 'react'
import { Spinner } from 'reactstrap'
import styled from 'styled-components'

import { ReactComponent as RPSymbol } from '../../../assets/images/identity/rp-symbol-main.svg'
import masterCardLogo from '../../../assets/images/master-card.svg'
import vbg from '../../../assets/images/virtual-card-bg.png'
import Button from '../../../components/ui/button'
import { CARD_STATUSES, CARD_TYPE_ENUM } from '../utils/card-types-status'

const CardType = styled.div`
  height: 38px;
  width: 54px;

  background-image: url(${masterCardLogo});
  background-size: contain;
  background-repeat: no-repeat;

  top: 4px;
  position: relative;
`

const CCWrapper = styled.div`
  width: 303px;
  height: 191px;

  &.card-active {
    /* background-image: url(${vbg});
    background-size: cover; */
    background-color: #0f1035;
  }

  .copy-instructions {
    opacity: 0.6;
  }

  .btn-outline-light {
    color: white;

    &:hover {
      background-color: transparent !important;
    }
  }

  &.card-active.card-virtual .card-detail-item {
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background-color: rgb(54, 56, 119, 0.55);
    }
  }
`

function formatCCNumber(str) {
  if (typeof str !== 'string') return ''
  return str.replaceAll('-', '').match(/.{1,4}/g)
}
function formatExpiry(str) {
  if (typeof str !== 'string') return []
  return str.replaceAll('-', '').match(/.{1,4}/g)
}

const defaultInstructions = (
  <>
    <i className='bx bxs-copy' /> Click on numbers to copy
  </>
)
let timeOutId = null

export function CreditCard({
  onMouseEnter,
  onMouseLeave,
  loading,
  cardType,
  cardStatus,
  unmaskedCardNumber,
  maskedCardNumber,
  validUntil,
  cvv,
  showDetails,
  onToggleCardDetails,
  line1,
}) {
  const [instructionMessage, setInstructionMessage] =
    useState(defaultInstructions)

  function copyToClipboard(value, name) {
    if (!cardActive || !cardIsVirtual) {
      return
    }

    copy(value)
    setInstructionMessage(
      <>
        <i className='bx bxs-copy-alt' /> {name ?? 'Detail'} copied!
      </>,
    )
    if (timeOutId) clearTimeout(timeOutId)
    timeOutId = setTimeout(
      () => setInstructionMessage(defaultInstructions),
      5000,
    )
  }

  function handleOnMouseLeave(e) {
    setInstructionMessage(defaultInstructions)
    if (typeof onMouseLeave === 'function') {
      onMouseLeave(e)
    }
  }
  const cardActive = cardStatus?.endsWith(CARD_STATUSES.ACTIVE)
  const cardBlocked = cardStatus === CARD_STATUSES.TEMP_BLOCK
  const cardIsVirtual = cardType === CARD_TYPE_ENUM.VIRTUAL

  return (
    <CCWrapper
      className={cx(
        'd-flex flex-column justify-content-between rounded-lg text-white p-3 position-relative overflow-hidden',
        {
          'bg-secondary': cardBlocked,
          'card-active': cardActive,
          'card-virtual': cardIsVirtual,
        },
      )}
      style={{ backgroundColor: cardActive ? '#0F1035' : undefined }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      {!loading ? null : (
        <div
          className='d-flex position-absolute'
          style={{ inset: 0, backgroundColor: 'rgba(15, 16, 53,0.2)' }}
        >
          <Spinner
            size='sm'
            className='position-absolute'
            style={{ right: '1.25rem', top: '2.125rem' }}
          />
        </div>
      )}

      <div className='d-flex justify-content-between'>
        <RPSymbol
          className={cardActive ? 'text-primary' : 'text-white'}
          style={{ height: '28px', width: 'auto' }}
        />

        <div className='d-flex flex-column align-items-end'>
          <div className='font-size-10 px-1'>
            {cardIsVirtual ? 'Virtual Card' : 'Physical Card'}
          </div>

          <div className={cx('copy-instructions', { 'd-none': !cardActive })}>
            {instructionMessage && showDetails ? instructionMessage : null}
            &nbsp;
          </div>
        </div>
      </div>

      <div
        className='d-flex flex-column align-items-start'
        style={{ textShadow: '0 1px 4px rgba(0, 0, 0, 0.35)', gap: '0.2rem' }}
      >
        <div
          className='d-flex align-items-center rp-font-mono'
          style={{ gap: '1rem', minHeight: 23 }}
        >
          <div
            className='d-flex font-size-16 card-detail-item px-1'
            style={{ gap: '1ch' }}
            onClick={() => {
              copyToClipboard(
                formatCCNumber(unmaskedCardNumber).join(' '),
                'Number',
              )
            }}
          >
            {(unmaskedCardNumber && showDetails
              ? formatCCNumber(unmaskedCardNumber) ?? []
              : formatCCNumber(maskedCardNumber) ?? []
            )?.map((item, index) => (
              <span key={index}>{item}</span>
            ))}
          </div>
          {!cardIsVirtual ? null : (
            <Button
              outline={!showDetails}
              color='light'
              size='sm'
              className='rounded-pill d-lg-none'
              onClick={onToggleCardDetails}
              style={{ padding: '0.125rem 0.5rem' }}
            >
              {showDetails ? 'Hide' : 'Show'}
            </Button>
          )}
        </div>
        <div className='d-flex px-1' style={{ gap: '1rem' }}>
          <CardDetail
            label={
              <>
                VALID
                <br />
                THRU
              </>
            }
            value={formatExpiry(
              showDetails && validUntil ? validUntil : 'YYYYMM',
            ).join('/')}
            onClick={() => {
              copyToClipboard(formatExpiry(validUntil)?.join('/'), 'Expiry')
            }}
          />
          <CardDetail
            label={
              <>
                SECURITY
                <br />
                CODE
              </>
            }
            value={showDetails && cvv ? cvv : 'XXX'}
            onClick={() => {
              copyToClipboard(cvv, 'CVV')
            }}
          />
        </div>
      </div>

      <div className='d-flex justify-content-between align-items-end'>
        {/* <p className='mb-0 font-size-10 px-1'>Limited Use</p> */}
        <div className='font-size-14 rp-font-gilroyB px-1'>{line1}</div>
        <CardType />
      </div>
    </CCWrapper>
  )
}

function CardDetail({ label, value, onClick }) {
  return (
    <div className='d-flex align-items-end' style={{ gap: '0.125rem' }}>
      <div style={{ lineHeight: 1.2, fontSize: 6 }}>{label}</div>
      <div
        className='rp-font-mono font-size-14 card-detail-item'
        style={{ lineHeight: 1, padding: '1px 4px' }}
        onClick={onClick}
      >
        {value}
      </div>
    </div>
  )
}
