import React from 'react'

import Loader from './ui/loader'

export default function WizardReviewStep({
  loading,
  loadingMinHeight = '40vh',
  infos = [],
}) {
  if (loading) {
    return <Loader minHeight={loadingMinHeight} />
  }

  return (
    <div>
      {infos.map(({ title, info }, idx) => {
        return (
          <>
            <div key={title ?? idx}>
              {!title ? null : (
                <h3 className='rp-font-gilroyB rp-font-bold font-size-18 text-gray-h'>
                  {title}
                </h3>
              )}

              {info.map(({ label, value }, index) => {
                return <InfoDisplay key={index} label={label} value={value} />
              })}
            </div>

            {idx === infos.length - 1 ? null : (
              <hr className='border-top border-light' />
            )}
          </>
        )
      })}
    </div>
  )
}

function InfoDisplay({ value, label }) {
  return (
    <div className='d-flex justify-content-between font-size-14 py-2'>
      <p className='mb-0 rp-capitalize rp-font-gilroyB text-gray-h'>{label}</p>
      <p className='mb-0 text-muted'>{value}</p>
    </div>
  )
}
