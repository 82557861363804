import axios from 'axios'
import classnames from 'classnames'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { slide as Menu } from 'react-burger-menu'
import Paginations from 'react-js-pagination'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import {
  Badge,
  Button as BsButton,
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  Table,
} from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'

import archiveIconHover from '../../../../assets/images/archive.svg'
import archiveIcon from '../../../../assets/images/archiveBlack.svg'
import unArchiveIcon from '../../../../assets/images/unarchive.svg'
import ConfirmationModal from '../../../../components/Common/ConfirmationModal'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import Toggle from '../../../../components/Forms/Toggle/Toggle'
import SearchBar from '../../../../components/SearchBar'
import { StyledH5, StyledH6 } from '../../../../components/Typo'
import Button from '../../../../components/ui/button'
import UserComments from '../../../../components/user-comments/user-comments'
import UserFlagging from '../../../../components/userFlag'
import WiseButton from '../../../../components/WiseButton'
import ADMIN_PERMISSIONS from '../../../../config/admin-permissions'
import { useFetch, useResize } from '../../../../helpers/hooks'
import useHasPermission from '../../../../helpers/hooks/admin/has-permission'
import {
  archiveBankAccount,
  archiveUser,
  bankNameVerification,
  deactivateUser as deactivateUserAction,
  deleteBalanceAdmin,
  disableAdmin2fa,
  flagUser,
  getAdminUserDetails,
  getBalancesAdmin,
  getBankAccounts,
  getKYCSubmissions,
  getUsersListAdmin,
  impersonateUser,
  markContractorKycUnverified,
  markContractorKycVerified,
  screenBankAccount,
  screenUserKYCSubmission,
  unflagUser,
  updateContractorMethods,
  whitelistEmail,
} from '../../../../services/api'
import { loginUserSuccessful } from '../../../../store/actions'
import { StyledIcon } from '../../../Contract/ContractList/ContractList'
import UserActions from './components/user-actions'
import DeactivateUserModal from './deactivate-user-modal'

const kycCols = [
  { label: 'Submission ID', className: 'sticky-col first-kycUser-col' },
  { label: 'Name on RemotePass', className: 'sticky-col second-kycUser-col' },
  { label: 'Name On ID', className: 'sticky-col third-kycUser-col' },
  { label: 'ID Type' },
  { label: 'Created At' },
  { label: 'Approved At' },
  { label: 'Approved By' },
  { label: 'Validation' },
  { label: 'Screening' },
]

const bankAccountsCols = [
  { label: 'Bank holder', className: 'sticky-col first-bank-col' },
  { label: 'Name on RemotePass', className: 'sticky-col second-bank-col' },
  { label: 'Bank country' },
  { label: 'Bank info' },
  { label: 'Created At' },
  { label: 'Approved At' },
  { label: 'Approved By' },
  { label: 'Name matches?' },
  { label: 'Screening', needsEditPermission: true },
  { label: '', needsEditPermission: true },
]
const tabs = [
  { label: 'Contractors', value: 0 },
  { label: 'Clients', value: 1 },
  { label: 'Archived', value: 2 },
  // { label: 'Balances', value: 3 },
  { label: 'KYC Verification', value: 4 },
  { label: 'Bank Accounts', value: 5 },
]

const kycStatuses = [
  { label: 'Pending', data: { label: 'Pending', value: 'pending' } },
  { label: 'Approved', data: { label: 'Approved', value: 'approved' } },
  { label: 'Rejected', data: { label: 'Rejected', value: 'rejected' } },
]
const baStatuses = [
  { label: 'Pending', data: { label: 'Pending', value: 'pending' } },
  { label: 'On Hold', data: { label: 'On Hold', value: 'onhold' } },
  { label: 'Approved', data: { label: 'Approved', value: 'approved' } },
  { label: 'Rejected', data: { label: 'Rejected', value: 'rejected' } },
]
const matchesOptions = [
  { value: 1, label: 'Yes' },
  { value: 0, label: 'No' },
]
const defaultState = { label: 'Select', value: -1 }
const status = [
  defaultState,
  { label: 'Has Balance', value: 1 },
  { label: 'Has no balance', value: 0 },
]
const defaultCompany = { label: 'Select Company', value: null }

const UsersAdmin = ({ location }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [activePage, setActivePage] = useState(1)
  const [activeTab, setActiveTab] = useState(0)
  const [show, setShow] = useState(false)
  const [selectedUserId, setselectedUserId] = useState(null)
  const [query, setQuery] = useState(null)
  const [kycQuery, setKycQuery] = useState(null)
  const [bankQuery, setBankQuery] = useState(null)
  const [state, setState] = useState(defaultState)
  const [company, setCompany] = useState(defaultCompany)
  const [kycStatus, setKycStatus] = useState(kycStatuses[0].data)
  const [baStatus, setBaStatus] = useState(baStatuses[0].data)
  const [archiving, setArchiving] = useState(-1)
  const [showBalance, setShowBalance] = useState(-1)
  const [showBankAccounts, setShowBankAccounts] = useState(-1)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [balanceToDelete, setBalanceToDelete] = useState(null)
  const [showDetails, setShowDetails] = useState(false)
  const [selectedBankDetails, setSelectedBankDetails] = useState(null)
  const [banksPage, setBankPage] = useState(1)
  const [showDeactivateUser, setShowDeactivateUser] = useState(false)

  const staticData = useSelector((state) => state.Layout.staticData)

  useEffect(() => {
    if (!location.hash) {
      return () => {}
    }

    const tabFromHash = tabs.find((tab) => {
      const label = '#' + tab.label.replace(' ', '-').toLowerCase()
      return label === location.hash
    })?.value

    setActiveTab(tabFromHash)
  }, [location.hash])

  const allUsers = useFetch({
    action: getUsersListAdmin,
    withAdminAccess: true,
  })
  const tabBalances = useFetch({
    action: getBalancesAdmin,
    withAdminAccess: true,
    autoFetch: true,
  })
  const deleteBalance = useFetch({
    action: deleteBalanceAdmin,
    withAdminAccess: true,
    onComplete: () => {
      tabBalances.startFetch()
      setConfirmDelete(false)
    },
  })

  const kycSubmissions = useFetch({
    action: getKYCSubmissions,
    withAdminAccess: true,
  })
  const bankAccounts = useFetch({
    action: getBankAccounts,
    withAdminAccess: true,
    autoFetch: true,
    body: { status: 'pending' },
  })
  const handlePageChange = (page) => {
    setActivePage(page)
  }
  const handleBanksPage = (page) => {
    setBankPage(page)
  }
  const applyFilter = (cancelToken, noLoading) => {
    if (activeTab === 4) {
      kycSubmissions.startFetch(
        { status: kycStatus.value, search: kycQuery },
        noLoading,
        cancelToken,
      )
    } else if (activeTab === 5) {
      bankAccounts.startFetch(
        { status: baStatus.value, page: banksPage, search: bankQuery },
        noLoading,
        cancelToken,
      )
    } else {
      const body = {
        page: activePage,
        tab: tabs.map((t) => t.label)[activeTab]?.toLowerCase(),
      }
      if (query) {
        body.search = query
      }
      if (company?.value && activeTab === 1) {
        body.company_id = company?.value
      }
      if (state?.value !== -1 && activeTab === 0) {
        body.has_balance = state?.value
      }
      if (activeTab === 2) {
        body.archived = 1
      } else {
        body.archived = 0
      }
      allUsers.startFetch(
        body,
        !query && !company?.value && state?.value === -1 && !noLoading,
        cancelToken,
      )
    }
  }

  const archive = useFetch({
    action: archiveUser,
    withAdminAccess: true,
    onComplete: () => {
      setArchiving(-1)
      applyFilter(null, true)
    },
  })

  useEffect(() => {
    if (activeTab !== 3) {
      let cancel
      // eslint-disable-next-line no-return-assign
      applyFilter(new axios.CancelToken((c) => (cancel = c)))
      return () => {
        if (cancel) cancel()
      }
    }
  }, [
    activeTab,
    activePage,
    query,
    state,
    company,
    kycStatus,
    baStatus,
    banksPage,
    kycQuery,
    bankQuery,
  ])

  const clearKycFilter = () => {
    setKycQuery('')
    setKycStatus(kycStatuses[0].data)
  }
  const clearBankFilter = () => {
    setBankQuery('')
    setBaStatus(baStatuses[0].data)
  }
  const updateWithdraw = useFetch({
    action: updateContractorMethods,
    withAdminAccess: true,
    onError: toastr.error,
    onComplete: () => {
      applyFilter(null, true)
    },
  })

  const whitelist = useFetch({
    action: whitelistEmail,
    withAdminAccess: true,
    onError: toastr.error,
    onComplete: () => {
      applyFilter(null, true)
    },
  })
  const impersonate = useFetch({
    action: impersonateUser,
    withAdminAccess: true,
    onError: toastr.error,
    onComplete: (data) => {
      if (data[0].admin_token) {
        dispatch(loginUserSuccessful(data[0]))
        history.replace('/')
      }
    },
  })
  const unflagMyUser = useFetch({
    action: unflagUser,
    withAdminAccess: true,
    onComplete: () => {
      applyFilter(null, true)
    },
    onError: () => {
      toastr.error('Error unflagging user')
    },
  })
  const flagMyUser = useFetch({
    action: flagUser,
    withAdminAccess: true,
    onComplete: () => {
      applyFilter(null, true)
    },
    onError: () => {
      toastr.error('Error unflagging user')
    },
  })
  const disableAdminTFA = useFetch({
    action: disableAdmin2fa,
    withAdminAccess: true,
    onComplete: () => {
      applyFilter(null, true)
    },
    onError: () => {
      toastr.error('Error disabling 2FA')
    },
  })
  const handleImpersonation = (usr) => {
    impersonate.startFetch({ user_id: usr?.id })
  }

  const { startFetch: deactivateUser, isLoading: isDeactivationLoading } =
    useFetch({
      action: deactivateUserAction,
      withAdminAccess: true,
      onComplete: () => {
        setShowDeactivateUser(null)
        applyFilter(null, true)
      },
    })

  function handleDeactivateUser(sendNotifEmail) {
    if (!showDeactivateUser?.id) return

    deactivateUser({
      user_id: showDeactivateUser?.id,
      notify: sendNotifEmail,
    })
  }

  const isPageLoading = useMemo(() => {
    if ([0, 1, 2].includes(activeTab)) {
      return allUsers.isLoading
    } else if (activeTab === 3) {
      return tabBalances.isLoading
    }
    return false
  }, [activeTab, allUsers.isLoading, tabBalances.isLoading])

  const hasEditPermission = useHasPermission(ADMIN_PERMISSIONS.MANAGE_USERS)
  const hasImpersonationPermission = useHasPermission(
    ADMIN_PERMISSIONS.USER_IMPERSONATION,
  )
  const hasUpdateFAPermission = useHasPermission(
    ADMIN_PERMISSIONS.UPDATE_ADMIN_2FA,
  )

  return (
    <div className='page-content'>
      <ConfirmationModal
        toggle={() => setConfirmDelete(false)}
        isOpen={confirmDelete}
        caption='Proceed'
        title={'Delete confirmation'}
        message={'Are you sure you want to delete this balance?'}
        onConfirm={() => {
          deleteBalance.startFetch({ balance_id: balanceToDelete?.id })
        }}
      />
      <BurgerDetails
        id={selectedUserId}
        hide={() => setShow(false)}
        show={show}
      />
      <DetailsModal
        details={selectedBankDetails}
        hide={() => setShowDetails(false)}
        show={showDetails}
      />

      <DeactivateUserModal
        isOpen={!!showDeactivateUser}
        toggle={() => setShowDeactivateUser(null)}
        user={showDeactivateUser}
        isLoading={isDeactivationLoading}
        onConfirm={handleDeactivateUser}
      />
      <Container fluid className={'p-0 m-0'}>
        <StyledH5 min='22px' max='32px' style={{ marginBottom: '2rem' }}>
          Users
        </StyledH5>
        <Card className={'p-0 m-0'}>
          {/* MOBILE VIEW TABS: [NOT MAINTAINED] */}
          <Row
            style={{ borderTopLeftRadius: 4, borderTopRightRadius: 4 }}
            className='d-block d-md-none p-3 p-0 m-0 justify-content-between align-items-center border-bottom bg-white'
          >
            <CustomSelect
              name='selectInput'
              value={tabs.find((p) => p.value === activeTab)}
              defaultValue={tabs[0]}
              onChange={(v) => setActiveTab(v.value)}
              options={tabs}
            />
          </Row>

          {/* MD VIEW TABS */}
          <Row className='d-none d-md-flex justify-content-between align-items-center m-0 p-3 pt-2'>
            <StyledNav tabs>
              {tabs.map((e, i) => (
                <NavItem key={`user-tab-${i}`} className='bg-white'>
                  <NavLink
                    className={classnames(
                      { active: activeTab === e.value },
                      'font-size-14 px-1',
                    )}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setActiveTab(e.value)}
                  >
                    {e.label}
                  </NavLink>
                </NavItem>
              ))}
            </StyledNav>
          </Row>

          {/* MD VIEW TABS */}
          <Row className='p-3'>
            {activeTab === 0 && (
              <Col md={4} xs={12}>
                <CustomSelect
                  options={status}
                  value={state}
                  onChange={setState}
                />
              </Col>
            )}

            {activeTab === 1 && (
              <Col md={4} xs={12}>
                <CustomSelect
                  options={
                    Array.isArray(allUsers?.data?.companies)
                      ? [
                          defaultCompany,
                          ...(allUsers?.data?.companies?.map((e) => ({
                            label: e.name,
                            value: e.id,
                          })) ?? []),
                        ]
                      : [defaultCompany]
                  }
                  value={company}
                  onChange={setCompany}
                  isSearchable
                  isClearable
                />
              </Col>
            )}

            {![3, 4, 5].includes(activeTab) && (
              <Col md={12} className='mt-2'>
                <SearchBar
                  query={query}
                  onQueryChanged={setQuery}
                  placeholder='search by name, email'
                  className='mr-md-2'
                />
              </Col>
            )}
          </Row>
          {activeTab === 4 && (
            <Row className='p-3'>
              <Col md={4} xs={12}>
                <CustomSelect
                  options={kycStatuses.map((e) => e.data)}
                  value={kycStatus}
                  onChange={setKycStatus}
                />
              </Col>
              {/* <div className='d-sm-flex p-1' /> */}
              <Col md={5} xs={12}>
                <SearchBar
                  query={kycQuery}
                  placeholder={'Search by name or email'}
                  onQueryChanged={setKycQuery}
                  className='mr-md-2'
                />
              </Col>
              <Col md={3} xs={12} className='p-0'>
                <Button onClick={clearKycFilter}>Clear Filter</Button>
              </Col>
            </Row>
          )}
          {activeTab === 5 && (
            <Row className='p-3'>
              <Col md={4} xs={12}>
                <CustomSelect
                  options={baStatuses.map((e) => e.data)}
                  value={baStatus}
                  onChange={setBaStatus}
                />
              </Col>
              <Col md={5} xs={12}>
                <SearchBar
                  query={bankQuery}
                  placeholder={'Search by name or email'}
                  onQueryChanged={setBankQuery}
                  className='mr-md-2'
                />
              </Col>
              <Col md={3} xs={12} className='p-0'>
                <Button onClick={clearBankFilter}>Clear Filter</Button>
              </Col>
            </Row>
          )}
          {/* MOBILE VIEW TABLE: [NOT MAINTAINED] */}
          <div className='d-block d-md-none p-3'>
            {allUsers.data &&
              allUsers.data?.users?.map((myUser, key) => (
                <React.Fragment key={myUser.id}>
                  <UserBalancesModal
                    show={showBalance === key}
                    hide={() => setShowBalance(-1)}
                    balances={myUser?.balances}
                  />
                  <UserAccountsModal
                    show={showBankAccounts === key}
                    hide={() => setShowBankAccounts(-1)}
                    accounts={myUser?.bank_accounts || []}
                  />
                  <UserCardAdmin
                    myUser={myUser}
                    index={key}
                    key={key}
                    onArchive={() => {
                      setArchiving(key)
                      archive.startFetch({
                        user_id: myUser.id,
                        status: activeTab === 2 ? 0 : 1,
                      })
                    }}
                    archiveValue={activeTab === 2 ? 0 : 1}
                    archiving={archiving}
                    onViewBalances={() => setShowBalance(key)}
                    onViewAccounts={() => setShowBankAccounts(key)}
                  />
                </React.Fragment>
              ))}
          </div>

          {activeTab === 4 ? (
            kycSubmissions.isLoading || !kycSubmissions.completed ? (
              <div
                className='d-flex justify-content-center align-items-center'
                style={{ minHeight: 105 }}
              >
                <Spinner type='grow' color='primary' />
              </div>
            ) : kycSubmissions?.data?.length <= 0 ? (
              <p
                className='my-2 px-3 text-muted font-size-16'
                style={{ minHeight: 'calc(105px - 16px)' }}
              >
                No KYC submissions found using the selected filters.
              </p>
            ) : (
              <>
                <div className='table-responsive d-none d-md-block px-1'>
                  <Table className='table table-centered table-nowrap'>
                    <thead className='thead-light'>
                      <tr>
                        {kycCols.map(
                          ({
                            className,
                            label,
                            tabs,
                            nonTabs,
                            needsEditPermission,
                          }) => {
                            const showCol = Array.isArray(tabs)
                              ? tabs.includes(activeTab)
                              : Array.isArray(nonTabs)
                              ? !nonTabs.includes(activeTab)
                              : needsEditPermission
                              ? hasEditPermission
                              : true

                            return showCol ? (
                              <th className={className}>{label}</th>
                            ) : null
                          },
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      <KYCVerification
                        submissions={kycSubmissions?.data ?? []}
                        onCompleteAction={() => applyFilter(null, true)}
                        setselectedUserId={setselectedUserId}
                        setShow={setShow}
                        hasEditPermission={hasEditPermission}
                      />
                    </tbody>
                  </Table>
                </div>
              </>
            )
          ) : activeTab === 5 ? (
            bankAccounts.isLoading ? (
              <div
                className='d-flex justify-content-center align-items-center'
                style={{ minHeight: 105 }}
              >
                <Spinner type='grow' color='primary' />
              </div>
            ) : bankAccounts?.data?.length <= 0 ? (
              <p
                className='my-2 px-3 text-muted font-size-16'
                style={{ minHeight: 'calc(105px - 16px)' }}
              >
                No Bank accounts
              </p>
            ) : (
              <>
                <div className='table-responsive d-none d-md-block px-1'>
                  <Table className='table table-centered table-nowrap'>
                    <thead className='thead-light'>
                      <tr>
                        {bankAccountsCols.map(
                          ({
                            className,
                            label,
                            tabs,
                            nonTabs,
                            needsEditPermission,
                          }) => {
                            const showBCol = Array.isArray(tabs)
                              ? tabs.includes(activeTab)
                              : Array.isArray(nonTabs)
                              ? !nonTabs.includes(activeTab)
                              : needsEditPermission
                              ? hasEditPermission
                              : true

                            return showBCol ? (
                              <th className={className}>{label}</th>
                            ) : null
                          },
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      <BankAccounts
                        banks={bankAccounts?.data ?? []}
                        applyFilter={applyFilter}
                        setShow={setShowDetails}
                        setSelectedBankDetails={setSelectedBankDetails}
                        setShowUser={setShow}
                        setSelectedUserId={setselectedUserId}
                        hasEditPermission={hasEditPermission}
                      />
                    </tbody>
                  </Table>
                </div>
              </>
            )
          ) : isPageLoading ? (
            <div
              className='d-flex justify-content-center align-items-center'
              style={{ minHeight: 105 }}
            >
              <Spinner type='grow' color='primary' />
            </div>
          ) : (
            <div className='table-responsive d-none d-md-block pb-5'>
              <Table className='table-centered table-nowrap text-muted'>
                <thead>
                  <tr>
                    <th className='sticky-col first-user-col'>ID</th>
                    {activeTab !== 3 && (
                      <th className='sticky-col second-user-col'>First Name</th>
                    )}
                    {activeTab !== 3 && (
                      <th className='sticky-col third-user-col'>Last Name</th>
                    )}
                    {[1, 2, 3].includes(activeTab) && <th>Company</th>}
                    {activeTab !== 3 && (
                      <th className='sticky-col fourth-user-col'>Email</th>
                    )}
                    {[1, 2].includes(activeTab) && <th>Type</th>}
                    {activeTab === 0 && <th>All Contracts</th>}
                    {activeTab !== 3 && <th>Phone</th>}
                    {activeTab !== 3 && <th>Status</th>}
                    {[0, 1, 2].includes(activeTab) && hasUpdateFAPermission && (
                      <th>2FA</th>
                    )}
                    {[0, 2].includes(activeTab) && <th>KYC Verified</th>}
                    {[0, 2].includes(activeTab) && <th>Coinbase</th>}
                    {[0, 2].includes(activeTab) && <th>Coinbase email</th>}
                    {[0, 2].includes(activeTab) && <th>Paypal email</th>}
                    {[0, 2].includes(activeTab) && <th>Payoneer email</th>}
                    {[0, 1, 2].includes(activeTab) && <th>Flag</th>}
                    {[0, 2].includes(activeTab) && <th>Tax residence</th>}
                    {[0, 1, 2].includes(activeTab) && <th>Created at</th>}
                    {[0, 2].includes(activeTab) && <th>Has bank account</th>}
                    {[0, 2].includes(activeTab) && <th>Has balance</th>}
                    {activeTab === 3 && <th>TRX ID</th>}
                    {activeTab === 3 && <th>Amount</th>}
                    {activeTab === 3 && <th>Currency</th>}
                    {activeTab === 3 && <th>Status</th>}
                    {!hasEditPermission ? null : (
                      <th>{activeTab === 2 ? 'Unarchive' : 'Archive'}</th>
                    )}
                    {[0, 1, 2].includes(activeTab) &&
                      hasImpersonationPermission && <th>Impersonate</th>}
                    {![0, 1].includes(activeTab) ||
                    !hasEditPermission ? null : (
                      <th>Deactivation</th>
                    )}
                    {![0, 1].includes(activeTab) ||
                    !hasEditPermission ? null : (
                      <th>Actions</th>
                    )}
                  </tr>
                </thead>

                {activeTab === 3 ? (
                  <tbody>
                    {tabBalances.data
                      ? tabBalances.data?.map((balance, key) => (
                          <>
                            <tr key={'_order_' + key}>
                              <td>{balance?.company_name}</td>
                              <td>{balance?.transaction_ref}</td>
                              <td>{balance?.amount}</td>
                              <td>{balance?.currency?.code}</td>
                              <td>
                                <Badge
                                  className={`font-size-14 rounded ${
                                    balance?.processed
                                      ? 'bg-soft-success text-success'
                                      : 'bg-soft-warning text-warning'
                                  }`}
                                  pill
                                  style={{ padding: 6 }}
                                >
                                  {balance?.processed ? 'Processed' : 'Pending'}
                                </Badge>
                              </td>
                              <td>
                                {!balance.processed && (
                                  <WiseButton
                                    textColor={'white'}
                                    onClick={() => {
                                      setBalanceToDelete(balance)
                                      setConfirmDelete(true)
                                    }}
                                    bk={'danger'}
                                    border
                                    width={'50%'}
                                    minWidth={'50%'}
                                    text={'Delete'}
                                  />
                                )}
                              </td>
                            </tr>
                          </>
                        ))
                      : null}
                  </tbody>
                ) : (
                  <tbody>
                    {allUsers.data
                      ? allUsers.data?.users?.map((myUser, key) => {
                          return (
                            <tr key={'_order_' + key}>
                              <UserBalancesModal
                                show={showBalance === key}
                                hide={() => setShowBalance(-1)}
                                balances={myUser?.balances}
                              />
                              <UserAccountsModal
                                show={showBankAccounts === key}
                                hide={() => setShowBankAccounts(-1)}
                                accounts={myUser?.bank_accounts || []}
                              />
                              <td className='sticky-col first-user-col'>
                                <Link
                                  to={'#'}
                                  onClick={() => {
                                    setselectedUserId(myUser?.id)
                                    setShow(true)
                                  }}
                                  className='d-inline-block position-relative text-truncate'
                                  style={{
                                    top: 2,
                                  }}
                                  title={myUser.first_name}
                                >
                                  {myUser.id}
                                </Link>
                              </td>
                              <td className='second-user-col sticky-col'>
                                {![0, 1].includes(activeTab) ||
                                !myUser.is_flagged ? null : (
                                  <span
                                    className='position-absolute d-inline-flex align-items-center font-size-16'
                                    style={{
                                      top: '50%',
                                      transform: 'translate(-25px, -50%)',
                                      gap: '0.25rem',
                                    }}
                                  >
                                    <UserFlagging
                                      user={myUser}
                                      onCompleteAction={() => {
                                        applyFilter(null, true)
                                      }}
                                    />
                                  </span>
                                )}
                                <Link
                                  to={'#'}
                                  onClick={() => {
                                    setselectedUserId(myUser?.id)
                                    setShow(true)
                                  }}
                                  className='d-inline-block position-relative text-truncate'
                                  style={{
                                    maxWidth: 'calc(100% - 26px)',
                                    top: 2,
                                  }}
                                  title={myUser.first_name}
                                >
                                  {myUser.first_name}
                                </Link>

                                {![0, 1].includes(activeTab) ||
                                !myUser.first_name ? null : (
                                  <span
                                    className='position-absolute d-inline-flex align-items-center font-size-16'
                                    style={{
                                      top: '50%',
                                      transform: 'translate(5px, -50%)',
                                      gap: '0.25rem',
                                    }}
                                  >
                                    <UserComments
                                      comments={myUser.comments}
                                      userId={myUser.id}
                                      onCompleteAction={() => {
                                        applyFilter(null, true)
                                      }}
                                    />
                                  </span>
                                )}
                              </td>
                              <td
                                className='sticky-col third-user-col text-truncate'
                                title={myUser.last_name}
                              >
                                {myUser.last_name}
                              </td>
                              {[1, 2].includes(activeTab) && (
                                <td
                                  className='text-truncate'
                                  style={{ maxWidth: 150 }}
                                  title={myUser.company?.name}
                                >
                                  {myUser.company?.name}
                                </td>
                              )}
                              <td className='sticky-col fourth-user-col'>
                                {myUser.email}
                              </td>
                              {[1, 2].includes(activeTab) && (
                                <td>
                                  {myUser.type}
                                  <span className={'text-secondary'}>
                                    &nbsp;
                                    {myUser.role !== 'Contractor' &&
                                      `(${myUser.role})`}
                                  </span>
                                </td>
                              )}
                              {activeTab === 0 ? (
                                <td>
                                  <div
                                    className='d-flex flex-column overflow-auto'
                                    style={{ maxHeight: '11ch' }}
                                  >
                                    {myUser.contracts.map(({ ref, id }) => {
                                      return (
                                        <Link
                                          key={id}
                                          to={`/admin/contract-detail?id=${ref}`}
                                        >
                                          {ref}
                                        </Link>
                                      )
                                    })}
                                  </div>
                                </td>
                              ) : null}
                              <td>{myUser.phone}</td>
                              <td>
                                <Toggle
                                  id={`SwitchStatus${key}`}
                                  disabled
                                  check={myUser.status === 'active'}
                                />
                              </td>
                              {hasUpdateFAPermission && (
                                <td>
                                  <Toggle
                                    id={`2fa${key}`}
                                    check={!!myUser.is_2fa_enabled}
                                    change={(v) => {
                                      disableAdminTFA.startFetch({
                                        user_id: myUser.id,
                                      })
                                    }}
                                    disabled={
                                      !myUser.is_2fa_enabled ||
                                      disableAdminTFA.isLoading
                                    }
                                  />
                                </td>
                              )}
                              {activeTab !== 1 && (
                                <td>
                                  {myUser?.type === 'Client' ? (
                                    'N/B'
                                  ) : (
                                    <VerificationBadge
                                      user={myUser}
                                      hasEditPermission={hasEditPermission}
                                      refreshData={applyFilter}
                                    />
                                  )}
                                </td>
                              )}

                              {activeTab !== 1 && (
                                <td>
                                  <Toggle
                                    id={`hasCoinbase${key}`}
                                    check={myUser.userable_id?.withdraw_method_ids?.includes(
                                      '9',
                                    )}
                                    change={(v) => {
                                      const methods =
                                        myUser.userable_id?.withdraw_method_ids?.split(
                                          ',',
                                        )
                                      updateWithdraw.startFetch({
                                        user_id: myUser?.id,
                                        withdraw_method_ids:
                                          !myUser.userable_id?.withdraw_method_ids?.includes(
                                            '9',
                                          )
                                            ? [...methods, 9]?.map((e) =>
                                                Number(e),
                                              )
                                            : methods
                                                .filter((e) => Number(e) !== 9)
                                                ?.map((e) => Number(e)),
                                      })
                                    }}
                                    disabled={!hasEditPermission}
                                  />
                                </td>
                              )}
                              {activeTab !== 1 && (
                                <td>
                                  <Toggle
                                    id={`cb_whitelist${key}`}
                                    check={!!myUser.userable_id?.cb_whitelist}
                                    change={(v) => {
                                      whitelist.startFetch({
                                        user_id: myUser?.id,
                                        cb_whitelist: v.target.checked ? 1 : 0,
                                      })
                                    }}
                                    disabled={!hasEditPermission}
                                  />
                                </td>
                              )}
                              {activeTab !== 1 && (
                                <td>
                                  <Toggle
                                    id={`paypal_whitelist${key}`}
                                    check={
                                      !!myUser.userable_id?.paypal_whitelist
                                    }
                                    change={(v) => {
                                      whitelist.startFetch({
                                        user_id: myUser?.id,
                                        paypal_whitelist: v.target.checked
                                          ? 1
                                          : 0,
                                      })
                                    }}
                                    disabled={!hasEditPermission}
                                  />
                                </td>
                              )}
                              {activeTab !== 1 && (
                                <td>
                                  <Toggle
                                    id={`payoneer_whitelist${key}`}
                                    check={
                                      !!myUser.userable_id?.payoneer_whitelist
                                    }
                                    change={(v) => {
                                      whitelist.startFetch({
                                        user_id: myUser?.id,
                                        payoneer_whitelist: v.target.checked
                                          ? 1
                                          : 0,
                                      })
                                    }}
                                    disabled={!hasEditPermission}
                                  />
                                </td>
                              )}
                              <td>
                                <Toggle
                                  id={`flag${key}`}
                                  check={!!myUser.is_flagged}
                                  change={(v) => {
                                    if (myUser.is_flagged) {
                                      unflagMyUser.startFetch({
                                        user_id: myUser.id,
                                      })
                                    } else {
                                      flagMyUser.startFetch({
                                        user_id: myUser.id,
                                      })
                                    }
                                  }}
                                  disabled={
                                    !hasEditPermission ||
                                    flagMyUser.isLoading ||
                                    unflagMyUser.isLoading
                                  }
                                />
                              </td>

                              {![0, 2].includes(activeTab) ? null : (
                                <td>
                                  {myUser.Country_of_Tax_Residence
                                    ? staticData?.countries?.find(
                                        (e) =>
                                          e.id ===
                                          myUser.Country_of_Tax_Residence,
                                      ).name
                                    : myUser?.tax_country?.name || 'N/B'}
                                </td>
                              )}

                              <td>
                                {moment(myUser.userable_id.created_at).format(
                                  'MM/DD/YYYY hh:mm',
                                )}
                              </td>

                              {activeTab !== 1 && (
                                <>
                                  <td>
                                    {myUser?.bank_accounts?.length > 0
                                      ? 'Yes'
                                      : 'No'}
                                  </td>
                                  <td>{myUser?.has_balance ? 'Yes' : 'No'}</td>
                                </>
                              )}

                              {!hasEditPermission ? null : (
                                <td className='text-center'>
                                  {archiving === key ? (
                                    <i className='bx bx-loader bx-spin font-size-16 text-primary' />
                                  ) : (
                                    <button
                                      type='button'
                                      className='d-inline-flex p-1 rp-btn-nostyle text-dark hover:bg-soft-secondary'
                                      onClick={() => {
                                        setArchiving(key)
                                        archive.startFetch({
                                          user_id: myUser?.id,
                                          status: activeTab === 2 ? 0 : 1,
                                        })
                                      }}
                                    >
                                      {activeTab === 2 ? (
                                        <i className='bx bx-archive-out font-size-18'></i>
                                      ) : (
                                        <i className='bx bx-archive-in font-size-18'></i>
                                      )}
                                    </button>
                                  )}
                                </td>
                              )}
                              {[0, 1, 2].includes(activeTab) &&
                                hasImpersonationPermission && (
                                  <td className='text-center'>
                                    <button
                                      type='button'
                                      className='d-inline-flex rp-btn-nostyle text-primary hover:bg-soft-primary'
                                      onClick={() =>
                                        handleImpersonation(myUser)
                                      }
                                    >
                                      <i className='bx bx-log-in font-size-18 ml-n1' />
                                    </button>
                                  </td>
                                )}
                              {![0, 1].includes(activeTab) ||
                              !hasEditPermission ? null : (
                                <td>
                                  {myUser.status !== 'active' ? null : (
                                    <Button
                                      type='button'
                                      outline
                                      size='sm'
                                      color='warning'
                                      onClick={() =>
                                        setShowDeactivateUser(myUser)
                                      }
                                    >
                                      Deactivate
                                    </Button>
                                  )}
                                </td>
                              )}
                              {![0, 1].includes(activeTab) ||
                              !hasEditPermission ? null : (
                                <td>
                                  <UserActions
                                    user={myUser}
                                    refreshData={() => applyFilter(null, true)}
                                  />
                                </td>
                              )}
                            </tr>
                          )
                        })
                      : null}
                  </tbody>
                )}
              </Table>
            </div>
          )}

          {activeTab !== 3 && (
            <Col>
              <Row className='px-3 justify-content-end'>
                <Paginations
                  itemClass='page-item'
                  linkClass='page-link'
                  activePage={activeTab === 5 ? banksPage : activePage}
                  itemsCountPerPage={
                    (activeTab === 4
                      ? kycSubmissions
                      : activeTab === 5
                      ? bankAccounts
                      : allUsers
                    )?.paginator?.per_page ?? 10
                  }
                  totalItemsCount={
                    (activeTab === 4
                      ? kycSubmissions
                      : activeTab === 5
                      ? bankAccounts
                      : allUsers
                    ).paginator?.total ?? 20
                  }
                  pageRangeDisplayed={5}
                  onChange={
                    activeTab === 5 ? handleBanksPage : handlePageChange
                  }
                />
              </Row>
            </Col>
          )}
        </Card>
      </Container>
    </div>
  )
}

const UserCardAdmin = ({
  index,
  myUser,
  archiving,
  archiveValue,
  onArchive,
  onViewBalances,
  onViewAccounts,
}) => {
  const staticData = useSelector((state) => state.Layout.staticData)

  return (
    <div
      className=' py-0   mb-3'
      style={{
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 1px 0px #DFE1E6',
        borderRadius: 4,
        border: '1px solid #E7E8F2',
        position: 'relative',
      }}
    >
      <Row className='p-3 m-0 border-bottom'>
        <Col
          xs={12}
          className='p-0 m-0 d-flex justify-content-between align-items-center'
        >
          {!myUser.first_name ? (
            <div></div>
          ) : (
            <StyledH5
              className={'text-dark font-weight-normal  mb-0  font-size-14'}
            >
              {' '}
              {`${myUser.first_name} ${myUser.last_name}`}{' '}
            </StyledH5>
          )}
          <div>
            <Toggle
              id={`SwitchStatus${index}`}
              disabled
              check={myUser.status === 'active'}
            />
          </div>
        </Col>
      </Row>
      <div className='p-3'>
        <div className='d-flex mb-3 align-items-center justify-content-between align-items-center'>
          <h6 className={'text-dark font-weight-normal mb-0   font-size-14'}>
            Email
          </h6>
          <h6 className='text-dark   font-size-14 font-weight-normal mb-0'>
            {' '}
            {myUser.email}
          </h6>
        </div>
        <div className='d-flex mb-3 align-items-center justify-content-between align-items-center'>
          <h6 className={'text-dark font-weight-normal mb-0   font-size-14'}>
            Type
          </h6>
          <h6 className='text-dark   font-size-14 font-weight-normal mb-0'>
            {myUser.type}{' '}
            <span className={'text-secondary'}>
              &nbsp;{myUser.role !== 'Contractor' && `(${myUser.role})`}
            </span>
          </h6>
        </div>
        <div className='d-flex mb-3 align-items-center justify-content-between align-items-center'>
          <h6 className={'text-dark font-weight-normal mb-0   font-size-14'}>
            Phone
          </h6>
          <h6 className='text-dark font-size-14 font-weight-normal mb-0'>
            {myUser.phone}
          </h6>
        </div>
        <div className='d-flex mb-3 align-items-center justify-content-between align-items-center'>
          <h6 className={'text-dark font-weight-normal mb-0   font-size-14'}>
            KYC verified
          </h6>
          {myUser?.type === 'Client' ? (
            'N/B'
          ) : (
            <Badge
              className={`font-size-12 py-1 rounded badge-soft-${
                myUser?.userable_id?.kyc_verified ? 'success' : 'danger'
              }`}
              pill
            >
              {myUser?.userable_id?.kyc_verified
                ? 'KYC Verified'
                : 'KYC Not Verified'}
            </Badge>
          )}
        </div>

        <div className='d-flex mb-3 justify-content-between align-items-center'>
          <h6 className='  text-dark font-weight-normal mb-0   font-size-14'>
            Tax residence{' '}
          </h6>
          <h6 className='text-dark font-weight-normal mb-0   font-size-14'>
            {myUser.Country_of_Tax_Residence
              ? staticData?.countries?.find(
                  (e) => e.id === myUser.Country_of_Tax_Residence,
                ).name
              : myUser?.tax_country?.name || 'N/B'}
          </h6>
        </div>
        <div className='d-flex mb-0 align-items-center justify-content-between align-items-center'>
          <h6 className={'text-dark font-weight-normal mb-0   font-size-14'}>
            {' '}
            Created at{' '}
          </h6>
          <h6 className='font-weight-normal mb-0 text-dark   font-size-14'>
            {' '}
            {moment(myUser.userable_id.created_at).format('MM/DD/YYYY hh:mm')}
          </h6>
        </div>

        <div className='py-3 w-100'>
          {archiving === index ? (
            <div className='d-flex justify-content-center align-items-center'>
              <i className='bx bx-loader bx-spin font-size-16 text-primary'></i>
            </div>
          ) : (
            <WiseButton
              textColor={'dark'}
              onClick={onArchive}
              bk={'white'}
              border
              width={'100%'}
              minWidth={'100%'}
              text={archiveValue === 0 ? 'UnArchive' : 'Archive'}
              icon={
                archiveValue === 0 ? (
                  <img
                    src={unArchiveIcon}
                    alt={'archive icon'}
                    style={{ width: 16, marginRight: 8 }}
                  />
                ) : (
                  <img
                    src={archiveIcon}
                    alt={'archive icon'}
                    style={{ width: 16, marginRight: 8 }}
                  />
                )
              }
            />
          )}
        </div>
        {myUser?.has_balance && (
          <div className='w-100'>
            <WiseButton
              textColor={'dark'}
              onClick={onViewBalances}
              bk={'white'}
              border
              width={'100%'}
              minWidth={'100%'}
              text={'View Balances'}
            />
          </div>
        )}
        {myUser?.bank_accounts?.length > 0 && (
          <div className='w-100'>
            <WiseButton
              textColor={'dark'}
              onClick={onViewAccounts}
              bk={'white'}
              border
              width={'100%'}
              minWidth={'100%'}
              text={'Bank Accounts'}
            />
          </div>
        )}
      </div>
    </div>
  )
}

const UserBalancesModal = ({ show, hide, balances }) => {
  return (
    <Modal
      isOpen={show}
      toggle={() => hide()}
      centered={true}
      className={'expensesModal'}
      unmountOnClose
    >
      <ModalHeader toggle={() => hide()}>Balances</ModalHeader>
      <ModalBody>
        <div
          className=' py-0 mb-3'
          style={{
            backgroundColor: '#FFFFFF',
            borderRadius: 4,
            position: 'relative',
          }}
        >
          {balances?.map((b, i) => {
            const formatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: b?.currency?.code || 'USD',
            })
            return (
              <div
                key={`balance-${i}`}
                className='d-flex mb-3 align-items-center justify-content-between align-items-center'
              >
                <h6
                  className={'text-dark font-weight-normal mb-0   font-size-14'}
                >
                  {b?.currency?.name}
                </h6>
                <h6 className='text-dark   font-size-14 font-weight-normal mb-0'>
                  {' '}
                  {formatter.format(b?.balance)}
                </h6>
              </div>
            )
          })}
        </div>
      </ModalBody>
      <ModalFooter>
        <BsButton color='outline-light' onClick={() => hide()}>
          Close
        </BsButton>
      </ModalFooter>
    </Modal>
  )
}

const UserAccountsModal = ({ show, hide, accounts }) => {
  if (!accounts || accounts.lenght <= 0) return null

  return (
    <Modal
      size='lg'
      isOpen={show}
      toggle={() => hide()}
      centered={true}
      unmountOnClose
    >
      <ModalHeader toggle={() => hide()}>Bank Accounts</ModalHeader>
      <ModalBody className='p-0'>
        <div
          className='py-0 mb-3'
          style={{
            backgroundColor: '#FFFFFF',
            borderRadius: 4,
            position: 'relative',
          }}
        >
          <div className='table-responsive d-none d-md-block'>
            <Table className='table table-centered table-nowrap'>
              <thead className='thead-light'>
                <tr>
                  <th className='border-top-0'>Provider</th>
                  <th className='border-top-0'>Currency</th>
                  <th className='border-top-0'>Bank Code</th>
                  <th className='border-top-0'>Bank Name</th>
                </tr>
              </thead>
              <tbody>
                {accounts?.map((account, index) => {
                  return (
                    <tr key={index + '_account_' + account.id}>
                      <td className='text-dark font-weight-normal mb-0 font-size-14'>
                        {account?.provider}
                      </td>
                      <td className='text-dark font-weight-normal mb-0 font-size-14'>
                        {account?.currency}
                      </td>
                      {Object.keys(account?.details)?.map((acc, index) =>
                        !!account?.details[acc] &&
                        typeof account?.details[acc] === 'object' ? (
                          <React.Fragment key={index}>
                            {Object.keys(account?.details[acc])?.map((e) => (
                              <td
                                key={`det-${acc}-${e}`}
                                className='text-dark font-size-14 font-weight-normal mb-0'
                              >
                                {e}: {account?.details[acc][e]}
                              </td>
                            ))}
                          </React.Fragment>
                        ) : (
                          <td
                            key={`det-${acc}`}
                            className='text-dark font-size-14 font-weight-normal mb-0'
                          >
                            {acc}: {account?.details[acc]}
                          </td>
                        ),
                      )}
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <BsButton color='outline-light' onClick={() => hide()}>
          Close
        </BsButton>
      </ModalFooter>
    </Modal>
  )
}

export const StyledNav = styled(Nav)`
  border-bottom: 0;

  .nav-item {
    margin-right: 1rem;

    .nav-link {
      padding: 6px 2px;
      color: #777f9e;
      font-weight: 400;

      &:hover {
        color: #114ef7;
        background-color: #f4f7ff;
        border-color: transparent transparent currentColor transparent;
      }
    }

    .active {
      font-weight: 600;
      border: 0;
      border-bottom: 2px solid currentColor;
      color: #114ef7;

      &:hover {
        color: #114ef7;
        background-color: transparent;
      }
    }
  }
`

export const BurgerDetails = ({ hide, show, id, selectedBankId }) => {
  const isMobile = useResize()
  const [expanded, setExpanded] = useState(-1)

  const getUserDetails = useFetch(
    {
      action: getAdminUserDetails,
      withAdminAccess: true,
      autoFetch: !!id,
      body: { id },
    },
    [id],
  )

  useEffect(() => {
    document.body.classList.toggle('modal-open', show)
  }, [show])

  return (
    <div
      style={{ position: 'absolute', top: 0, bottom: 0, left: 0, zIndex: 1100 }}
    >
      <Menu
        onClose={hide}
        className='bg-white'
        isOpen={show}
        width={isMobile ? '100%' : '30%'}
        right
      >
        {getUserDetails.isLoading ? (
          <Row
            style={{ minHeight: '30rem' }}
            className='d-flex p-0 m-0  justify-content-center align-items-center'
          >
            <Spinner type='grow' className='mr-2' color='primary' />
          </Row>
        ) : (
          <Row className={'p-0 m-0'}>
            <Col md={12} className={'p-0 m-0'}>
              <Card>
                <CardBody className='m-0  p-4'>
                  {getUserDetails.data?.map((item, i) => {
                    return (
                      <div key={i}>
                        <StyledH6 min={'20px'} max={'30px'}>
                          {item?.title}
                        </StyledH6>
                        {item?.title === 'Bank accounts'
                          ? item?.value?.map((bankAccount, index) => {
                              const selected =
                                !!selectedBankId &&
                                bankAccount.find(
                                  (attr) => attr?.title === 'Account ID',
                                )?.value === selectedBankId

                              return (
                                <div key={`b${index}`}>
                                  <Link
                                    to={'#'}
                                    color='primary'
                                    onClick={() => {
                                      setExpanded(
                                        expanded === index ? -1 : index,
                                      )
                                    }}
                                    style={{ marginBottom: '1rem' }}
                                  >
                                    <div
                                      style={{
                                        border: '1px solid #E7E8F2',
                                        borderRadius: 4,
                                        color: '#777F9E',
                                      }}
                                      className={
                                        'd-flex align-items-center  mb-2 p-2 justify-content-between'
                                      }
                                    >
                                      <div className={'d-flex '}>
                                        <h5 className='mb-0 mr-3 text-primary'>
                                          <span>{bankAccount?.[1]?.value}</span>
                                          {!selected ? null : (
                                            <span className='badge badge-pill badge-secondary ml-2'>
                                              Used in selected transaction
                                            </span>
                                          )}
                                        </h5>
                                        <h5
                                          className='mb-0 col-form-label p-0 m-0
                                      font-size-14'
                                        >
                                          {' '}
                                          {bankAccount?.[0]?.value}
                                        </h5>
                                      </div>
                                      <i
                                        style={{ opacity: 0.2 }}
                                        className={`bx bx-${
                                          expanded === index ? 'up' : 'down'
                                        }-arrow-circle 
                                       font-size-24 align-middle mr-1 text-secondary`}
                                      />
                                    </div>
                                  </Link>
                                  <Collapse isOpen={expanded === index}>
                                    <Card>
                                      <CardBody>
                                        {bankAccount.map((e, index) => {
                                          return (
                                            <div
                                              key={`b${index}`}
                                              className='d-flex align-items-center p-2 justify-content-between'
                                            >
                                              <h5 className='col-form-label p-0 m-0 mb-0 font-size-14'>
                                                {e?.title || e?.name}
                                              </h5>
                                              <p
                                                className='p-0 mb-0 text-right pl-4'
                                                style={{ color: '#777F9E' }}
                                              >
                                                {e.value || 'N/A'}
                                              </p>
                                            </div>
                                          )
                                        })}
                                      </CardBody>
                                    </Card>
                                  </Collapse>
                                </div>
                              )
                            })
                          : item?.value?.map((v, x) => {
                              return (
                                <div key={v?.title}>
                                  <h5 className='col-form-label p-0 m-0 mb-2 font-size-14'>
                                    {v?.title}
                                  </h5>
                                  {v?.is_image ? (
                                    <img
                                      src={v?.value}
                                      style={{ width: '100%' }}
                                      alt=''
                                    />
                                  ) : (
                                    <p
                                      className={'p-2'}
                                      style={{
                                        border: '1px solid #E7E8F2',
                                        borderRadius: 4,
                                        color: '#777F9E',
                                      }}
                                    >
                                      {v?.value || 'N/A'}
                                    </p>
                                  )}
                                </div>
                              )
                            })}
                      </div>
                    )
                  })}
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Menu>
    </div>
  )
}

const idTypeLabel = {
  1: 'ID Card',
  2: 'Passport',
  'ID Card': 'ID Card',
  Passport: 'Passport',
}

const submissionStatus = {
  pending: 'text-warning',
  onhold: 'text-warning',
  completed: 'text-success',
  approved: 'text-success',
  rejected: 'text-danger',
}
function KYCVerification({
  submissions,
  onCompleteAction,
  setselectedUserId,
  setShow,
  hasEditPermission,
}) {
  if (!submissions || !Array.isArray(submissions)) return

  return submissions.map((submission) => {
    return (
      <tr key={'_order_' + submission.id}>
        <td className='first-kycUser-col sticky-col'>{submission.id}</td>
        <td className='sticky-col second-kycUser-co'>
          <button
            className='rp-btn-nostyle text-primary'
            onClick={() => {
              setselectedUserId(submission.contractor_id)
              setShow(true)
            }}
          >
            {submission.contractor_name}
          </button>
        </td>
        <td className='sticky-col third-kycUser-col'>{submission.name}</td>
        <td className='text-capitalize'>{idTypeLabel[submission.type]}</td>
        <td>
          {moment(submission.created_at * 1000).format('MM/DD/YYYY hh:mm')}
        </td>
        <td>
          {submission?.approved_at
            ? moment(submission.approved_at * 1000).format('MM/DD/YYYY hh:mm')
            : ''}
        </td>
        <td>{submission?.approved_by}</td>
        <td
          className={`text-capitalize ${submissionStatus[submission.status]}`}
          style={{ fontWeight: 'bold' }}
        >
          {submission.status !== 'pending' || !hasEditPermission ? (
            submission.status
          ) : (
            <Link
              to={`/admin/kyc-verification/${submission.id}`}
              style={{ color: 'currentColor' }}
            >
              <BsButton
                type='button'
                outline
                size='sm'
                color='primary'
                style={{ borderRadius: 4 }}
              >
                Review Submission
              </BsButton>
            </Link>
          )}
        </td>
        <td>
          {submission.status !== 'completed' || !hasEditPermission ? (
            submission.screening_status
          ) : (
            <ScreeningColumn
              screeningStatus={submission.screening_status}
              screeningId={submission.id}
              onCompleteAction={onCompleteAction}
            />
          )}
        </td>
      </tr>
    )
  })
}
function ScreeningColumn({
  screeningStatus,
  screeningId,
  bank,
  onCompleteAction,
}) {
  const {
    startFetch: screenSubmission,
    isLoading,
    completed,
  } = useFetch({
    action: bank ? screenBankAccount : screenUserKYCSubmission,
    withAdminAccess: true,
    onComplete: onCompleteAction,
  })

  function handleScreening() {
    screenSubmission(
      bank ? { bank_account_id: screeningId } : { request_id: screeningId },
    )
  }

  if (isLoading) {
    return (
      <span className='text-secondary' style={{ fontWeight: 'bold' }}>
        Screening ...
      </span>
    )
  }

  if (screeningStatus === 'pending') {
    return (
      <BsButton
        type='button'
        outline
        size='sm'
        color={isLoading ? 'light' : 'primary'}
        style={{ borderRadius: 4 }}
        onClick={handleScreening}
        disabled={isLoading && !completed}
      >
        {isLoading ? 'Requesting ...' : 'Screen now'}
      </BsButton>
    )
  }

  return (
    <span
      className={`text-capitalize ${submissionStatus[screeningStatus]}`}
      style={{ fontWeight: 'bold' }}
    >
      {screeningStatus}
    </span>
  )
}

function BankAccounts({
  banks,
  applyFilter,
  setShow,
  setSelectedBankDetails,
  setSelectedUserId,
  setShowUser,
  hasEditPermission,
}) {
  if (!banks || !Array.isArray(banks)) return

  return banks.map((bank) => {
    return (
      <BankLine
        key={bank.id}
        bank={bank}
        setShow={setShow}
        setSelectedBankDetails={setSelectedBankDetails}
        applyFilter={applyFilter}
        setSelectedUserId={setSelectedUserId}
        setShowUser={setShowUser}
        hasEditPermission={hasEditPermission}
      />
    )
  })
}

function BankLine({
  bank,
  setShow,
  setSelectedBankDetails,
  applyFilter,
  setSelectedUserId,
  setShowUser,
  hasEditPermission,
}) {
  const [value, setValue] = useState(null)
  const { startFetch: verifyName, isLoading } = useFetch({
    action: bankNameVerification,
    withAdminAccess: true,
    onComplete: () => {
      applyFilter(null, false)
    },
  })
  const archiveBank = useFetch({
    action: archiveBankAccount,
    withAdminAccess: true,
    onComplete: () => {
      applyFilter(null, false)
    },
  })
  const change = (e) => {
    setValue(e.value)
    verifyName({
      bank_account_id: bank?.id,
      status: e.value,
    })
  }
  return (
    <tr key={'_order_' + bank.id}>
      <td
        className='sticky-col first-bank-col'
        style={{ whiteSpace: 'pre-line' }}
      >
        {bank.holder_name}
      </td>
      <td
        className='sticky-col second-bank-col'
        style={{ whiteSpace: 'pre-line' }}
      >
        <div>
          <div className='d-flex flex-column align-items-start justify-content-center'>
            <div>
              <Link
                to={'#'}
                onClick={() => {
                  setSelectedUserId(bank.contractor_id)
                  setShowUser(true)
                }}
                tag='a'
              >
                {bank.contractor_name}
              </Link>
              <span>
                <i
                  className={`bx ${
                    bank.kyc_status === 1
                      ? 'bxs-check-shield text-success'
                      : 'bxs-shield-x text-danger'
                  } font-size-16 ml-1 position-absolute`}
                  title={`kyc ${bank.kyc_status ? 'verified' : 'unverified'}`}
                />
              </span>
            </div>

            {bank?.has_entity ? (
              <div>
                <span className='font-size-11'>
                  {bank.entity}
                  <i
                    className={`bx ${
                      bank.kyb_status === 'approved'
                        ? 'bxs-check-shield text-success'
                        : bank.kyb_status === 'rejected'
                        ? 'bxs-shield-x text-danger'
                        : 'bxs-notification-off text-secondary'
                    } font-size-16 ml-1 position-absolute`}
                    title={`kyb ${bank.kyb_status}`}
                  />
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </td>
      <td>{bank.country_name}</td>
      <td>
        <button
          onClick={() => {
            setSelectedBankDetails(bank.details)
            setShow(true)
          }}
          className='rp-btn-nostyle text-primary'
        >
          View details
        </button>
      </td>
      <td>{moment(bank.created_at * 1000).format('MM/DD/YYYY hh:mm')}</td>
      <td>
        {bank?.approved_at
          ? moment(bank.approved_at * 1000).format('MM/DD/YYYY hh:mm')
          : ''}
      </td>
      <td>{bank?.approved_by}</td>
      <td>
        {isLoading ? (
          <div className='d-flex align-items-center justify-content-center'>
            <Spinner
              type='border'
              color='primary'
              style={{
                '--size': '22px',
                width: 'var(--size)',
                height: 'var(--size)',
              }}
            />
          </div>
        ) : !bank.name_matches && hasEditPermission ? (
          <div style={{ width: '100px' }}>
            <Select
              options={matchesOptions}
              menuPlacement='auto'
              menuPosition='fixed'
              value={value}
              onChange={change}
            />
          </div>
        ) : (
          <span
            className={`text-capitalize ${
              bank.name_matches ? 'text-success' : 'text-danger'
            }`}
            style={{ fontWeight: 'bold' }}
          >
            {bank.name_matches ? 'Verified' : 'Not verified'}
          </span>
        )}
      </td>
      {!hasEditPermission ? null : (
        <td>
          {bank.name_matches ? (
            <ScreeningColumn
              screeningStatus={bank.screening_status}
              screeningId={bank.id}
              bank
              onCompleteAction={() => applyFilter(null, true)}
            />
          ) : (
            'Bank name match matches?'
          )}
        </td>
      )}
      {!hasEditPermission ? null : (
        <td>
          {archiveBank.isLoading ? (
            <i className='bx bx-loader bx-spin font-size-16 text-primary'></i>
          ) : (
            <button
              className='rp-btn-nostyle'
              onClick={() => {
                archiveBank.startFetch({
                  bank_account_id: bank.id,
                  status: 1,
                })
              }}
            >
              <StyledIcon
                icon={archiveIcon}
                hoverIcon={archiveIconHover}
                alt={'archive icon'}
                style={{ width: 16 }}
              />
            </button>
          )}
        </td>
      )}
    </tr>
  )
}
export const DetailsModal = ({ hide, show, details }) => {
  const isMobile = useResize()

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        zIndex: 9999,
      }}
    >
      <Menu
        onClose={hide}
        className={'bg-white'}
        isOpen={show}
        width={isMobile ? '100%' : '30%'}
        right
      >
        <Row className={'p-0 m-0'}>
          <Col md={12} className={'p-0 m-0'}>
            <Card>
              <CardBody className='m-0  p-4'>
                <StyledH5 min={'20px'} max={'30px'}>
                  Bank Informations
                </StyledH5>
                {details?.map((item, i) => {
                  return (
                    <div key={i}>
                      <div key={item?.title || item?.name}>
                        <h5 className='col-form-label p-0 m-0 mb-2 font-size-14'>
                          {item?.title || item?.name}
                        </h5>
                        <p
                          className={'p-2'}
                          style={{
                            border: '1px solid #E7E8F2',
                            borderRadius: 4,
                            color: '#777F9E',
                          }}
                        >
                          {item?.value || 'N/A'}
                        </p>
                      </div>
                    </div>
                  )
                })}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Menu>
    </div>
  )
}
function VerificationBadge({ user, hasEditPermission, refreshData }) {
  const [showVerifyModal, setShowVerifyModal] = useState(false)

  const verifyKyc = useFetch({
    action: markContractorKycVerified,
    withAdminAccess: true,
    onComplete: () => {
      refreshData(null, true)
    },
  })
  const unverifyKyc = useFetch({
    action: markContractorKycUnverified,
    withAdminAccess: true,
    onComplete: () => {
      setShowVerifyModal(false)
      refreshData(null, true)
    },
  })

  function unverify() {
    unverifyKyc.startFetch({ user_id: user?.id })
  }

  function toggleVerify(user, isVerified) {
    if (isVerified) {
      setShowVerifyModal(true)
    } else {
      verifyKyc.startFetch({ user_id: user?.id })
    }
  }

  const loading = unverifyKyc.isLoading || verifyKyc.isLoading

  return (
    <div className='d-flex align-items-center'>
      <Badge
        className={`font-size-12 p-1 rounded badge-soft-${
          user?.userable_id?.kyc_verified ? 'success' : 'danger'
        }`}
      >
        {user?.userable_id?.kyc_verified ? 'KYC Verified' : 'KYC Not Verified'}
      </Badge>
      {!hasEditPermission ? null : (
        <button
          type='button'
          className='d-inline-flex align-items-center border-0 bg-transparent p-0 ml-2 font-size-14'
          style={{ cursor: 'pointer' }}
          title='Unverify'
          onClick={() => {
            toggleVerify(user, user?.userable_id?.kyc_verified)
          }}
        >
          {loading ? (
            <i className='bx bx-loader bx-spin text-secondary' />
          ) : user?.userable_id?.kyc_verified ? (
            <i className='bx bxs-x-circle text-danger' />
          ) : (
            <i className='bx bxs-check-circle text-primary' />
          )}
        </button>
      )}

      {!hasEditPermission ? null : (
        <Modal isOpen={showVerifyModal} centered>
          <ModalBody>
            <p className='mb-0 p-3 font-size-16'>
              Are you sure you want to unverify this user?
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              type='button'
              color='light'
              outline
              onClick={() => setShowVerifyModal(false)}
              disabled={unverifyKyc.isLoading}
            >
              Cancel
            </Button>
            <Button
              type='button'
              color='danger'
              onClick={unverify}
              disabled={unverifyKyc.isLoading}
              loading={unverifyKyc.isLoading}
            >
              Unverify
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  )
}

export default UsersAdmin
