import cx from 'classnames'
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Card, Nav, NavItem, NavLink } from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'

import Button from '../../components/ui/button'
import Loader from '../../components/ui/loader'
import FEATURE_FLAGS from '../../config/feature-flags'
import { useFetch } from '../../helpers/hooks'
import {
  cardActivity,
  cardsPreConditions,
  cardTransactions,
  getCardDetails,
} from '../../services/api'
import { getErrors } from '../../utils/get-errors'
import { riskAssessmentEnum } from '../AdminPanel/pages/cards/cards-kyc'
import ActiveCreditCardSection from './components/active-credit-card-section'
import AddNewCard from './components/add-new-card'
import BalanceDisplay from './components/balance-display'
import CardsKycBanner, { KYC_STATUSES } from './components/cards-kyc-banner'
import EmptyContent from './components/empty-content'
import LatestTransactions from './components/latest-transactions'
import PendingCard from './components/pending-card'
import RequestNewCard from './components/request-new-card'
import {
  CardsSection,
  CardsSectionHeader,
  SectionHeading,
} from './components/section-heading'
import TerminatedRequestCard from './components/terminated-request-card'
import TransactionDetails from './components/transactions-details'
import UnderReviewCard from './components/under-review-card'
import PhysicalCardStates, {
  isPhysicalCardPending,
} from './organisms/physical-card-states'
import CARD_TYPES_OPTIONS, {
  CARD_STATUSES,
  CARD_TYPE_ENUM,
  getCardType,
} from './utils/card-types-status'
import { formatCard, getActiveCard } from './utils/cards-data-formatting'

const SelectedCardContext = createContext({})
export const useSelectedCard = () => useContext(SelectedCardContext)

export default function RemotepassCards() {
  const [selectedCard, setSelectedCard] = useState(CARD_TYPE_ENUM.VIRTUAL)

  return (
    <div className='page-content'>
      <h1 className='rp-font-gilroyB font-size-32 text-gray-h mb-3 mb-md-4'>
        Cards
      </h1>
      <SelectedCardContext.Provider value={{ selectedCard, setSelectedCard }}>
        <CardStates />
      </SelectedCardContext.Provider>
    </div>
  )
}

function CardStates() {
  const user = useSelector((state) => state.Account?.user)

  const { data: preConditions, isLoading: preConditionsLoading } = useFetch({
    action: cardsPreConditions,
    autoFetch: true,
  })

  const {
    data: cards,
    isLoading: cardsLoading,
    startFetch: refetchCards,
    error: cardsError,
  } = useFetch({
    action: cardActivity,
    autoFetch: true,
    body: { rp_user_id: user?.id },
    onError: (err) => {
      const errorMessage = getErrors(err)

      toastr.error(errorMessage ?? 'Something went wrong')
    },
  })

  function handleRefetchCards() {
    refetchCards({ rp_user_id: user?.id })
  }

  const hasCardOrder = !!cards?.card_order || cards?.cards?.length > 0

  const isUnderReview =
    hasCardOrder &&
    cards?.risk_assessment?.status !== riskAssessmentEnum.CLEARED

  const isPagePending =
    cards?.cards?.length === 1 &&
    cards?.card_order &&
    cards?.card_order?.status !== 'issued' &&
    cards?.card_order?.card_type !== CARD_TYPE_ENUM.PHYSICAL

  if (cardsError) {
    return (
      <EmptyContent>
        <h3 className='h2 mb-0' style={{ color: 'var(--gray-h)' }}>
          Whoops!
        </h3>
        <p className='mb-0'>There was an error</p>
      </EmptyContent>
    )
  }

  if (cardsLoading || preConditionsLoading) {
    return <Loader minHeight={566} />
  }

  if (isPagePending) {
    return <PendingCard />
  }

  if (isUnderReview) {
    return <UnderReviewCard />
  }

  if (hasCardOrder) {
    return (
      <CardsDetails
        cards={cards}
        refetchCards={handleRefetchCards}
        preConditions={preConditions}
      />
    )
  }

  return (
    <RequestNewCard
      afterRequestNewCard={refetchCards}
      preConditions={preConditions}
    />
  )
}

function CardsDetails({ cards, preConditions, refetchCards }) {
  const { selectedCard } = useSelectedCard()
  const [allCardData, setAllCardData] = useState({})

  const acknowledgedAt = cards?.kyc?.acknowledged_at
  const showKycBanner = !acknowledgedAt

  const allCardsTerminated =
    allCardData?.[CARD_TYPE_ENUM.PHYSICAL]?.cardStatus ===
      CARD_STATUSES.TERMINATED &&
    allCardData?.[CARD_TYPE_ENUM.VIRTUAL]?.cardStatus ===
      CARD_STATUSES.TERMINATED

  const alreadyHasPhysicalCard = cards?.cards?.some(
    (card) => card?.card_type === CARD_TYPE_ENUM.PHYSICAL,
  )
  const physicalCardPending = isPhysicalCardPending({
    status: cards?.card_order?.status,
    type: cards?.card_order?.card_type,
  })
  const hideCardBanner = alreadyHasPhysicalCard || physicalCardPending

  useEffect(() => {
    const physicalCards = []
    const virtualCards = []

    cards?.cards.forEach((card) => {
      if (card.card_type === CARD_TYPE_ENUM.VIRTUAL) {
        virtualCards.push(formatCard(card))
      } else {
        physicalCards.push(formatCard(card))
      }
    })

    setAllCardData({
      [CARD_TYPE_ENUM.PHYSICAL]: getActiveCard(physicalCards),
      [CARD_TYPE_ENUM.VIRTUAL]: getActiveCard(virtualCards),
    })

    return () => {}
  }, [cards?.cards])

  return (
    <div className='d-flex flex-column gap-24'>
      {showKycBanner ? (
        <CardsKycBanner
          kycStatus={cards?.kyc}
          refetchCards={refetchCards}
          preConditions={preConditions}
        />
      ) : null}

      <CreditCardAndBalance
        allCardsTerminated={allCardsTerminated}
        activeCardData={allCardData[selectedCard]}
        alreadyHasPhysicalCard={alreadyHasPhysicalCard}
        cardOrder={cards?.card_order}
        refetchCards={refetchCards}
        setAllCardData={setAllCardData}
      />

      <PhysicalCardBanner
        hide={hideCardBanner}
        cardsKycStatus={cards?.kyc?.status}
        preConditions={preConditions}
      />

      <CardsLatestTransactions />
    </div>
  )
}

function CreditCardAndBalance({
  allCardsTerminated,
  activeCardData,
  alreadyHasPhysicalCard,
  cardOrder,
  refetchCards,
  setAllCardData,
}) {
  return (
    <CardsBalancesContainer className='d-flex flex-wrap mb-0 gap-24 flex-column flex-md-row'>
      <CardsSection>
        <CardsSectionHeader>
          <SectionHeading className='mb-0'>My cards</SectionHeading>

          <CardTypeSelector />
        </CardsSectionHeader>

        <CreditCardContent
          activeCardData={activeCardData}
          alreadyHasPhysicalCard={alreadyHasPhysicalCard}
          cardOrder={cardOrder}
          refetchCards={refetchCards}
          setAllCardData={setAllCardData}
        />
      </CardsSection>
      <CardsSection>
        <CardsSectionHeader>
          <SectionHeading className='mb-0'>Current balance</SectionHeading>
        </CardsSectionHeader>

        <BalanceContent
          activeCardData={activeCardData}
          allCardsTerminated={allCardsTerminated}
        />
      </CardsSection>
    </CardsBalancesContainer>
  )
}

function CreditCardContent({
  activeCardData,
  alreadyHasPhysicalCard,
  cardOrder,
  refetchCards,
  setAllCardData,
}) {
  const { selectedCard } = useSelectedCard()

  const isActiveCardPhysical = selectedCard === CARD_TYPE_ENUM.PHYSICAL
  const activeCardStatus = activeCardData?.cardStatus

  const cardOrderStatus = cardOrder?.status
  const cardOrderType = cardOrder?.card_type

  const physicalCardPending = isPhysicalCardPending({
    status: cardOrderStatus,
    type: cardOrderType,
  })

  const cardOrderIsPhysical =
    !!cardOrder && cardOrder?.card_type === CARD_TYPE_ENUM.PHYSICAL

  const showPhysicalCardState =
    isActiveCardPhysical &&
    (physicalCardPending ||
      !activeCardStatus ||
      (cardOrderIsPhysical &&
        ![
          CARD_STATUSES.ACTIVE,
          CARD_STATUSES.TEMP_BLOCK,
          CARD_STATUSES.TERMINATED,
        ].includes(activeCardStatus)))

  const isCardStatusTerminated = activeCardStatus === CARD_STATUSES.TERMINATED

  const isVirtualCardTerminated = !activeCardData?.id || isCardStatusTerminated
  const isPhysicalCardTerminated =
    alreadyHasPhysicalCard && isCardStatusTerminated

  const isCardTerminated = isActiveCardPhysical
    ? isPhysicalCardTerminated
    : isVirtualCardTerminated

  if (showPhysicalCardState) {
    return (
      <PhysicalCardStates
        cardOrder={cardOrder}
        alreadyHasPhysicalCard={alreadyHasPhysicalCard}
      />
    )
  }

  if (isCardTerminated) {
    return (
      <TerminatedRequestCard
        afterRequestNewCard={refetchCards}
        cardType={selectedCard}
      />
    )
  }

  if (activeCardStatus === CARD_STATUSES.PENDING) {
    return 'Pending card'
  }

  return (
    <ActiveCreditCardSection
      cardData={activeCardData}
      setAllCardData={setAllCardData}
      refetchCards={refetchCards}
    />
  )
}

function BalanceContent({ activeCardData, allCardsTerminated }) {
  const userProfile = useSelector((state) => state.userProfile?.userProfile)
  const rpUserId = useSelector((state) => state.Account?.user?.id)

  const cardId = useMemo(() => activeCardData?.id ?? null, [activeCardData?.id])

  const { data: cardDetails, isLoading: loadingCardDetails } = useFetch(
    {
      action: getCardDetails,
      body: { cardId, rpUserId },
      autoFetch: !!cardId,
      onError: (err) => {
        // eslint-disable-next-line no-console
        console.log('err', err)
      },
    },
    [cardId],
  )

  if (loadingCardDetails) {
    return <Loader className='w-100 flex-grow-1' />
  }

  return (
    <>
      <div className='d-flex flex-column gap-24 px-4 w-100'>
        <BalanceDisplay balance={cardDetails?.funds?.available} />

        <div>
          <h3 className='h6 mb-1 rp-font-bold text-gray-600'>
            Billing address
          </h3>
          <p className='mb-0 text-gray-600'>
            {userProfile?.address}
            <br />
            {[userProfile?.city, userProfile?.country?.name]
              .filter(Boolean)
              .join(', ')}
          </p>
        </div>
      </div>

      <div className='d-flex align-items-center justify-content-end p-4 gap-8 w-100 border-top mt-auto'>
        <Button
          tag={Link}
          to={{ pathname: '/cards/offload', state: { card: cardDetails } }}
          color='light'
          outline
        >
          Offload
        </Button>
        <Button
          tag={Link}
          to={
            allCardsTerminated
              ? null
              : { pathname: '/cards/topup', state: { card: cardDetails } }
          }
          disabled={allCardsTerminated}
        >
          Top Up
        </Button>
      </div>
    </>
  )
}

function CardTypeSelector() {
  const { selectedCard, setSelectedCard } = useSelectedCard()

  return (
    <CardStyledNav pills className='border rounded-pill gap-4'>
      {CARD_TYPES_OPTIONS.map((tab) => {
        const active = tab.value === selectedCard

        return (
          <NavItem key={`card-${tab.value}`}>
            <NavLink
              tag='button'
              className={cx(
                'rounded-pill hover:bg-light',
                { active },
                active ? 'bg-primary' : 'bg-white text-secondary',
              )}
              onClick={() => {
                setSelectedCard(tab.value)
              }}
            >
              {tab?.labelShort}
            </NavLink>
          </NavItem>
        )
      })}
    </CardStyledNav>
  )
}

function CardsLatestTransactions() {
  const [trxDetailsOpen, setTrxDetailsOpen] = useState(false)
  const [selectedTrx, setSelectedTrx] = useState([])

  const { data: transactions, isLoading } = useFetch({
    action: cardTransactions,
    body: { page: '0', limit: '5' },
    autoFetch: true,
    onError: (err) => {
      toastr.error(getErrors(err))
    },
  })

  return (
    <>
      <Card className='mb-0 p-4 p-md-0 rp-shadow-2'>
        {transactions?.items?.length <= 0 || isLoading ? null : (
          <div
            className='p-md-3 pb-3 d-flex justify-content-between align-items-baseline flex-wrap'
            style={{ gap: '0.75rem' }}
          >
            <SectionHeading className='mb-0'>
              Latest Transactions
            </SectionHeading>

            <Button
              tag={Link}
              color='light'
              outline
              className='font-size-14'
              to='/cards/transactions'
            >
              See All
            </Button>
          </div>
        )}

        <LatestTransactions
          data={transactions?.items}
          isLoading={isLoading}
          setSelectedTrx={setSelectedTrx}
          setShow={setTrxDetailsOpen}
          withArrow
        />
      </Card>

      <TransactionDetails
        hide={() => setTrxDetailsOpen(false)}
        show={trxDetailsOpen}
        setShow={setTrxDetailsOpen}
        selectedTrx={selectedTrx}
      />
    </>
  )
}

function PhysicalCardBanner({ hide, cardsKycStatus, preConditions }) {
  const history = useHistory()

  const isPhysicalCardSupported =
    preConditions?.card_configurations?.is_physical_card_supported &&
    preConditions?.nationality_card_ordering_enabled

  function handleRequestCard() {
    const isKycApproved = [KYC_STATUSES.APPROVED, KYC_STATUSES.DONE].includes(
      cardsKycStatus,
    )

    if (!FEATURE_FLAGS.REMOTEPASS_PHYSICAL_CARDS) {
      toastr.info('Physical cards are <strong>coming soon</strong>', null, {
        allowHtml: true,
      })
    } else {
      if (!isPhysicalCardSupported) {
        toastr.error(
          'Physical cards are not supported <strong>in your country</strong>',
          null,
          { allowHtml: true },
        )
        return
      }

      if (!isKycApproved) {
        toastr.error(
          'You need to complete your KYC before you can request a physical card.',
        )
        return
      }

      history.push('/cards/request-physical')
      window.scrollTo(0, 0)
    }
  }

  if (hide) return null

  return (
    <AddNewCard
      cardLabel={getCardType(CARD_TYPE_ENUM.PHYSICAL)?.label}
      requestedCard={CARD_TYPE_ENUM.PHYSICAL}
      action={
        <Button
          type='button'
          onClick={isPhysicalCardSupported ? handleRequestCard : () => {}}
          disabled={!isPhysicalCardSupported}
          color={isPhysicalCardSupported ? 'primary' : 'secondary'}
          style={{ cursor: isPhysicalCardSupported ? null : 'not-allowed' }}
        >
          {isPhysicalCardSupported ? 'Request physical card' : 'Coming soon'}
        </Button>
      }
    />
  )
}

const CardsBalancesContainer = styled.div`
  > div {
    flex-grow: 1;
  }

  @media (min-width: 768px) {
    > div {
      flex-basis: calc(50% - calc(1.5rem / 2));
    }
  }
`

const CardStyledNav = styled(Nav)`
  padding: 0.25rem;

  .nav-item {
    .nav-link {
      border: 0;
      font-weight: 500;
      padding: 0.125rem 0.5rem;

      &.active {
        cursor: default;
      }

      :focus {
        box-shadow: 0 0 0 0.15rem rgba(81, 115, 235, 0.5);
      }
    }
  }
`
