import React, { useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap'
import TableH from '../../../components/Table/TableH'
import TableComp from '../../../components/Table/TableComp'
import StyledTh from '../../../components/Table/StyledTh'
import StyledTd from '../../../components/Table/StyledTd'
import styled from 'styled-components'
import NoContent from '../../../components/NoContent'
import { useSelector } from 'react-redux'
import { userTypes } from '../../../helpers/enum'
import { useFetch, useResize } from '../../../helpers/hooks'
import {
  downloadFile,
  removeRequestDoc,
  requestDoc,
} from '../../../services/api'
import toastr from 'toastr'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import ControlledInput from '../../../components/ControlledInput'
import WiseButton from '../../../components/WiseButton'
import ActionsDropdown from '../../../components/ActionsDropdown'
import emptyDocImage from './../../../assets/images/empty_docs.svg'

const Documents = ({
  docs,
  isContract,
  contractId,
  isAdmin,
  loading,
  onUpdate,
}) => {
  const [open, setOpen] = useState(false)
  const user = useSelector((state) => state?.Account?.user)

  return (
    <>
      {loading ? (
        <Row
          style={{ minHeight: '80vh' }}
          className='justify-content-center align-items-center'
        >
          <Spinner type='grow' className='mr-2' color='primary' />
        </Row>
      ) : docs?.length > 0 ? (
        <Row className='p-0 m-0'>
          <Col xs='12' className='p-0 m-0'>
            <Card className='p-0 m-0'>
              <CardBody className='p-0 m-0'>
                <TableH
                  title='Documents'
                  leftSide={
                    user?.type === userTypes.COMPANY ? (
                      <WiseButton
                        onClick={() => setOpen(true)}
                        text={'Request Document'}
                        bold
                        minHeight={'48px'}
                        minWidth={'100%'}
                      />
                    ) : null
                  }
                />
                <DocsList
                  isAdmin={isAdmin}
                  docs={docs}
                  isContract={isContract}
                  contractId={contractId}
                  onUpdate={onUpdate}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        <NoContent
          headline={'No documents added'}
          subtitle={'Submitted compliance documents will be shown here'}
          image={emptyDocImage}
          withAction={user?.type === userTypes.COMPANY}
          customBottom={
            <WiseButton
              onClick={() => setOpen(true)}
              text={'Request Document'}
              bold
              minHeight={'48px'}
              minWidth={'100%'}
            />
          }
          advanced={false}
        />
      )}

      <RequestFormModal
        show={open}
        hide={() => setOpen(false)}
        contractId={contractId}
        onUpdate={onUpdate}
      />
    </>
  )
}

const DocsList = ({ isAdmin, docs, onUpdate }) => {
  return (
    <StyledTableCont>
      <div className={!isAdmin && 'table-with-dropdown'}>
        <div className='table-responsive'>
          <TableComp>
            <thead className='thead-light'>
              <tr>
                <StyledTh>Name</StyledTh>
                <StyledTh className='text-right' />
              </tr>
            </thead>
            <tbody className='w-100'>
              {React.Children.toArray(
                docs.map((doc, index) => (
                  <DocLine
                    doc={doc}
                    isAdmin={isAdmin}
                    onUpdate={onUpdate}
                    key={index}
                  />
                )),
              )}
            </tbody>
          </TableComp>
        </div>
      </div>
    </StyledTableCont>
  )
}

const DocLine = ({ doc, isAdmin, onUpdate }) => {
  const isMobile = useResize()
  const [showActions, setShowActions] = useState(false)
  const downloadDoc = useFetch({
    action: downloadFile,
    onComplete: (data) => {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${doc?.name}.${data?.type?.split('/')[1]}`)
      document.body.appendChild(link)
      link.click()
    },
  })
  const deleteDoc = useFetch({
    action: removeRequestDoc,
    onComplete: () => {
      onUpdate()
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  const handleDownload = () => {
    downloadDoc.startFetch(doc?.link)
  }
  const handleDelete = () => {
    deleteDoc.startFetch({
      request_id: doc?.id,
    })
  }
  const actions = []
  if (doc?.can_delete) {
    actions[0] = {
      component: () => 'Remove',
      onClick: handleDelete,
    }
  }
  if (doc?.link) {
    actions[1] = {
      component: () => 'Download',
      onClick: handleDownload,
    }
  }
  return (
    <tr>
      <StyledTd>
        {isMobile ? doc?.name?.slice(0, 30) + '...' : doc?.name}
      </StyledTd>
      {!isAdmin && (
        <StyledTd className='justify-end text-right'>
          {(!!doc?.can_delete || !!doc?.link) && (
            <ActionsDropdown
              toggle={() => setShowActions((t) => !t)}
              isOpen={showActions}
              options={actions}
            />
          )}
        </StyledTd>
      )}
    </tr>
  )
}

const schema = yup.object().shape({
  title: yup.string().required(),
})

const RequestFormModal = ({ show, hide, contractId, onUpdate }) => {
  const {
    handleSubmit,
    control,

    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {},
  })

  const add = useFetch({
    action: requestDoc,
    onComplete: () => {
      hide()
      onUpdate(false)
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  const onSubmit = (v) => {
    add.startFetch({ ...v, contract_id: contractId })
  }

  return (
    <div>
      <Modal
        isOpen={show}
        toggle={hide}
        centered={true}
        className={'expensesModal'}
      >
        <Form
          autoComplete='off'
          className='form-horizontal m-0 '
          onSubmit={handleSubmit(onSubmit)}
        >
          <ModalHeader toggle={hide}>Request Document</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label className='col-form-label pt-0'>Document Name</Label>
              <ControlledInput
                control={control}
                name='title'
                error={errors.title}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color='outline-light' onClick={hide}>
              Cancel
            </Button>
            <Button color='primary' type='submit' disabled={add.isLoading}>
              {add.isLoading && <i className='bx bx-loader bx-spin' />} Submit
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  )
}

const StyledTableCont = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 0px #dfe1e6;
  border-radius: 4px;
`

export default Documents
