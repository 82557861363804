import React from 'react'
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap'

import Button from '../ui/button'

const ConfirmationModal = ({
  isOpen,
  toggle,
  onClosed = () => {},
  title,
  message,
  onConfirm,
  loading,
  confirmLoading,
  content,
  caption = 'Ok',
  negativeCaption = 'Cancel',
  buttonColor = 'primary',
  withCancel = true,
  centered = true,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      centered={centered}
      toggle={toggle}
      onClosed={onClosed}
    >
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        {loading ? (
          <Row className='justify-content-center align-items-center'>
            <Spinner type='grow' color='primary' />
          </Row>
        ) : (
          content ?? <p>{message}</p>
        )}
      </ModalBody>
      <ModalFooter>
        {!withCancel ? null : (
          <Button
            type='button'
            color='light'
            outline
            onClick={toggle}
            disabled={loading || confirmLoading}
          >
            {negativeCaption}
          </Button>
        )}
        <Button
          type='button'
          color={buttonColor}
          onClick={onConfirm}
          disabled={loading || confirmLoading}
          loading={loading || confirmLoading}
        >
          {caption}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ConfirmationModal
