import { useEffect } from 'react'

export default function useTriggerField({ watch, trigger }) {
  useEffect(() => {
    const subscription = watch((_, { name }) => {
      trigger(name)
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [trigger, watch])
}
