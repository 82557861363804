import React from 'react'

import { connect } from 'react-redux'

import { Link } from 'react-router-dom'

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from '../../store/actions'

// Import menuDropdown
import LanguageDropdown from '../CommonForBoth/TopbarDropdown/LanguageDropdown'
import NotificationDropdown from '../CommonForBoth/TopbarDropdown/NotificationDropdown'
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu'

import logoLight from '../../assets/images/logo-light.png'

// i18n
import { withTranslation } from 'react-i18next'

const Header = (props) => {
  return (
    <React.Fragment>
      <header id='page-topbar'>
        <div className='navbar-header'>
          <div className='d-flex'>
            <div className='navbar-brand-box'>
              {/* <Link to='/' className='logo logo-dark'>
                <span className='logo-sm'>
                  <img src={logoDark} alt='' height='22' />
                </span>
                <span className='logo-lg'>
                  <img src={logoDark} alt='' height='25' />
                </span>
              </Link> */}

              <Link to='/' className='logo logo-light'>
                <span className='logo-sm'>
                  <img src={logoLight} alt='' height='22' />
                </span>
                <span className='logo-lg'>
                  <img src={logoLight} alt='' height='25' />
                </span>
              </Link>
            </div>

            <button
              type='button'
              className='btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light'
              data-toggle='collapse'
              onClick={() => {
                props.toggleLeftmenu(!props.leftMenu)
              }}
              data-target='#topnav-menu-content'
            >
              <i className='fa fa-fw fa-bars'></i>
            </button>
          </div>

          <div className='d-flex'>
            <LanguageDropdown />
            <NotificationDropdown />

            <ProfileMenu />

            <div className='dropdown d-inline-block'>
              <button
                onClick={() => {
                  props.showRightSidebarAction(!props.showRightSidebar)
                }}
                type='button'
                className='btn header-item noti-icon right-bar-toggle waves-effect'
              >
                <i className='bx bx-cog bx-spin'></i>
              </button>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout
  return { layoutType, showRightSidebar, leftMenu }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header))
