import React from 'react'
import { InputGroup } from 'reactstrap'
import ControlledCurrencyInput from '../../../../components/ControlledCurrencyInput'
import ControlledSelect from '../../../../components/ControlledSelect'
import InputFeedback from '../../../../components/ui/input-feedback'
import {
  customOption,
  customSingleValue,
} from '../../components/Forms/select-components'

export function YearlySalaryField({
  control,
  label,
  placeholder,
  name = 'amount',
  errors,
  currencyOptions,
}) {
  const error = [errors?.[name]?.message, errors?.currency_id?.message]
    .filter(Boolean)
    .join(', ')

  return (
    <>
      {!label ? null : <label htmlFor={name}>{label}</label>}

      <InputGroup>
        <ControlledCurrencyInput
          control={control}
          name={name}
          id={name}
          placeholder={placeholder}
        />

        <ControlledSelect
          className='input-group-text p-0 border-0 rounded-left-0'
          leftRounded
          placeholder='Currency'
          name='currency_id'
          control={control}
          classNamePrefix='RS-Addon'
          options={currencyOptions}
          customComponents={{
            Option: customOption,
            Value: customSingleValue,
            SingleValue: customSingleValue,
          }}
        />
      </InputGroup>

      {!error ? null : <InputFeedback className='mt-1'>{error}</InputFeedback>}
    </>
  )
}
