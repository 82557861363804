import React from 'react'
import { Modal } from 'reactstrap'
import { useSelector } from 'react-redux'

const NeedSignModal = ({
  isOpen,
  toggle,
  onSignNow,
  clientSignature,
  contractorSignature,
}) => {
  const user = useSelector((state) => state.Account?.user)
  const getMessage = () => {
    if (
      user?.id !== clientSignature?.id &&
      user?.id !== contractorSignature?.id
    ) {
      return 'This contract is pending your signature.  You will be able to submit work after the contract is signed.'
    }
    if (!contractorSignature) {
      return 'This contract is pending contractor signature.  You will be able to submit work after the contract is signed by the contractor.'
    }
    if (!clientSignature) {
      return 'This contract is pending client signature.  You will be able to submit work after the contract is signed by the client.'
    }
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title mt-0'>Contract has not signed yet</h5>
          <button
            type='button'
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={toggle}
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <div className='modal-body'>
          <p>{getMessage()}</p>
        </div>
        <div className='modal-footer'>
          {/* <button */}
          {/*    type="button" */}
          {/*    className="btn btn-primary" */}
          {/*    onClick={onSignNow} */}
          {/* > */}
          {/*    Yes */}
          {/* </button> */}
          <button
            type='button'
            className='btn btn-secondary'
            data-dismiss='modal'
            onClick={toggle}
          >
            Ok
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default NeedSignModal
