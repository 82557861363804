import React from 'react'
import ReactApexChart from 'react-apexcharts'

const ColumnWithDataLabels = (props) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: props?.currency?.code || 'USD',
  })
  function kFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
      : Math.sign(num) * Math.abs(num)
  }
  const series = [
    {
      name: 'Profit',
      data: props.values,
    },
  ]
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'center', // top, center, bottom
        },
        columnWidth: '50%',
        colors: {
          backgroundBarRadius: 5,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },

    colors: ['#777F9E'],
    grid: {
      borderColor: '#E7E8F2',
    },
    xaxis: {
      categories: props.months,
      position: 'bottom',
      labels: {
        offsetY: 0,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tickAmount: 100,
      crosshairs: {
        show: false,
      },
      tooltip: {
        enabled: false,
        offsetY: -35,
      },
    },
    fill: {
      opacity: 1,
      type: 'solid',
      colors: ['#E8E9EB', '#114EF7'],
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        formatter: function (val) {
          return props?.currency?.symbol + kFormatter(val)
        },
      },
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div style="display:block !important;">' +
          '<span style="font-size: 12px; color: #0F1035; display: block; padding: 8px 16px; border-bottom: 1px solid #E7E8F2;">' +
          props.labels[dataPointIndex] +
          '</span>' +
          '<div style="font-size: 12px; color: #0F1035; display: block; padding: 8px 16px;"><span style="width: 10px; height: 10px; border-radius: 50%; background-color:#3250E7; display: inline-block; margin-right: 8px;"></span>' +
          formatter.format(series[seriesIndex][dataPointIndex]) +
          '</div>' +
          '</div>'
        )
      },
      x: {
        show: true,
        format: 'MMMM',
        formatter: undefined,
      },
      y: {
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      z: {
        formatter: undefined,
        title: 'Size: ',
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'darken',
          value: 0.35,
        },
      },
    },
  }
  return (
    <ReactApexChart
      options={options}
      series={series}
      type='bar'
      height={props?.height ?? 350}
    />
  )
}
export default ColumnWithDataLabels
