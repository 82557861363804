import cx from 'classnames'
import React, { useState } from 'react'
import { slide as Menu } from 'react-burger-menu'
import Paginations from 'react-js-pagination'
import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  Spinner,
  Table,
} from 'reactstrap'

import ActionsDropdown from '../../../../components/ActionsDropdown'
import Avatar from '../../../../components/Avatar'
import ConfirmationModal from '../../../../components/Common/ConfirmationModal'
import SearchBar from '../../../../components/SearchBar'
import BadgeX from '../../../../components/Table/BadgeX'
import { StyledH6 } from '../../../../components/Typo'
import Button from '../../../../components/ui/button'
import UserFlagging from '../../../../components/userFlag'
import ADMIN_PERMISSIONS from '../../../../config/admin-permissions'
import { useFetch, useResize } from '../../../../helpers/hooks'
import useHasPermission from '../../../../helpers/hooks/admin/has-permission'
import {
  activateInsurance,
  cancelInsurance,
  deactivateInsurance,
  getAdminInsuranceList,
  getDependentDetails,
  updateInsuranceRequest,
} from '../../../../services/api'
import { StyledNav } from '../../../Contract/ContractList'
import ApproveModal from '../../../Insurance/components/ApproveModal'
import { BurgerDetails } from '../Users/users'
import BeneficiariesModal from './beneficiaries-modal'
import RequestModal from './RequestModal'

const tabs = [
  { key: 'pending', label: 'Pending Requests' },
  { key: 'canceled', label: 'Cancelled Requests' },
  { key: 'active', label: 'Active' },
  { key: 'inactive', label: 'Deactivated' },
]

const InsuranceAdmin = () => {
  const [activeTab, setActiveTab] = useState(0)
  const [activePage, setActivePage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')

  const insurance = useFetch(
    {
      action: getAdminInsuranceList,
      withAdminAccess: true,
      autoFetch: true,
      body: {
        status: tabs[activeTab].key,
        search: searchQuery,
        page: activePage,
      },
      initResult: null,
    },
    [activeTab, searchQuery, activePage],
  )

  const [selectedUserId, setselectedUserId] = useState(null)
  const [show, setShow] = useState(false)
  const [selectedInsuranceId, setSelectedInsuranceId] = useState(null)
  const [showInsurance, setShowInsurance] = useState(false)
  const hasEditPermission = useHasPermission(
    ADMIN_PERMISSIONS.MANAGE_INSURANCE_REQUESTS,
  )

  function handleSearch(query) {
    setActivePage(1)
    setSearchQuery(query)
  }

  return (
    <div className='page-content'>
      <BurgerDetails
        id={selectedUserId}
        hide={() => setShow(false)}
        show={show}
      />
      <DependentsDetails
        id={selectedInsuranceId}
        hide={() => setShowInsurance(false)}
        show={showInsurance}
      />
      <Container fluid className='p-0 m-0'>
        <h1 className='rp-font-gilroyB' style={{ marginBottom: '2rem' }}>
          Insurances
        </h1>

        <Card>
          <div
            className='d-md-flex d-none flex-column p-3'
            style={{ gap: '1rem' }}
          >
            <StyledNav tabs>
              {tabs.map((tab, i) => (
                <NavItem key={`tab-${i}`}>
                  <NavLink
                    tag='button'
                    style={{ cursor: 'pointer' }}
                    className={cx(
                      {
                        active: activeTab === i,
                      },
                      'bg-white',
                    )}
                    onClick={() => {
                      setActiveTab(i)
                    }}
                  >
                    <span className='font-size-14'>{tab?.label}</span>
                  </NavLink>
                </NavItem>
              ))}
            </StyledNav>

            <div>
              <SearchBar
                query={searchQuery}
                onQueryChanged={handleSearch}
                placeholder='Search by name'
                className='mr-md-2'
              />
            </div>
          </div>
          {insurance.isLoading ? (
            <div
              className='d-flex justify-content-center align-items-center flex-grow-1'
              style={{ minHeight: '50vh' }}
            >
              <Spinner type='grow' className='mr-2' color='primary' />
            </div>
          ) : (
            <div className='table-responsive' style={{ minHeight: '50vh' }}>
              <Table className='table table-centered table-nowrap text-muted'>
                <thead className='thead-light'>
                  <tr className='text-muted'>
                    <th className='border-top-0 text-muted'>Contractor</th>
                    <th className='border-top-0 text-muted'>Client</th>
                    <th className='border-top-0 text-muted'>Plan</th>
                    <th className='border-top-0 text-muted'>Created At</th>
                    <th className='border-top-0 text-muted'>Dependents</th>
                    {activeTab === 2 && (
                      <th className='border-top-0 text-muted'>Next Billing</th>
                    )}
                    <th className='border-top-0 text-muted'>Total</th>
                    <th className='border-top-0 text-muted'>Status</th>
                    {![0, 2].includes(activeTab) ||
                    !hasEditPermission ? null : (
                      <th className='border-top-0 text-muted'>Actions</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {insurance.data?.map((e, i) => (
                    <InsuranceLine
                      onUserClick={(id) => {
                        setselectedUserId(id)
                        setShow(true)
                      }}
                      onDetailsClick={(id) => {
                        setSelectedInsuranceId(id)
                        setShowInsurance(true)
                      }}
                      activeTab={activeTab}
                      key={`ins-${i}`}
                      item={e}
                      hasEditPermission={hasEditPermission}
                      onUpdate={() =>
                        insurance.startFetch(
                          { status: tabs[activeTab].key },
                          false,
                        )
                      }
                    />
                  ))}
                </tbody>
              </Table>
            </div>
          )}

          <div className='d-flex justify-content-end px-3'>
            <Paginations
              itemClass='page-item'
              linkClass='page-link'
              activePage={activePage}
              itemsCountPerPage={insurance?.paginator?.per_page}
              totalItemsCount={insurance?.paginator?.total}
              onChange={setActivePage}
            />
          </div>
        </Card>
      </Container>
    </div>
  )
}

const InsuranceLine = ({
  item,
  onUpdate,
  activeTab,
  onUserClick,
  onDetailsClick,
  hasEditPermission,
}) => {
  const cancel = useFetch({
    action: cancelInsurance,
    withAdminAccess: true,
    onComplete: onUpdate,
  })
  const update = useFetch({
    action: updateInsuranceRequest,
    withAdminAccess: true,
    onComplete: onUpdate,
  })
  const approve = useFetch({
    action: activateInsurance,
    withAdminAccess: true,
    onComplete: onUpdate,
  })
  const deactivate = useFetch({
    action: deactivateInsurance,
    withAdminAccess: true,
    onComplete: onUpdate,
  })
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    maximumFractionDigits: 0,
    currency: item.currency?.code,
  })

  const [confirmCancel, setConfirmCancel] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [confirmApprove, setConfirmApprove] = useState(false)
  const [confirmDeactivate, setConfirmDeactivate] = useState(false)
  const [showBeneficiaries, setShowBeneficiaries] = useState(null)

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'warning'
      case 'canceled':
        return 'danger'
      case 'active':
        return 'success'
      default:
        return 'primary'
    }
  }

  return (
    <>
      <tr>
        <td>
          <button
            className='rp-btn-nostyle text-primary'
            onClick={() => {
              onUserClick(item?.user?.id)
            }}
          >
            <Row className='align-items-center flex-nowrap m-0 p-0'>
              <Avatar
                name={item?.user?.first_name}
                photo={item?.user?.img}
                flag={item?.user?.flag}
              />
              <div
                style={{ fontWeight: '600', marginLeft: 10 }}
                className='d-flex text-primary'
              >
                {item.user.is_flagged ? (
                  <UserFlagging
                    user={item?.user}
                    onCompleteAction={() => {
                      onUpdate()
                    }}
                  />
                ) : null}
                {item?.user?.first_name} {item?.user?.last_name}
              </div>
            </Row>
          </button>
        </td>
        <td
          className='text-truncate'
          style={{ maxWidth: 200 }}
          title={item.clients}
        >
          {item.clients}
        </td>
        <td>{item?.plan?.name}</td>
        <td>{item?.created_at}</td>
        <td>{item?.beneficiaries}</td>
        {activeTab === 2 && <td>{item?.next_billing}</td>}
        <td>{formatter.format(item?.total)}</td>
        <td>
          <BadgeX
            status={getStatusColor(item.status)}
            name={item.status}
            textStatus={getStatusColor(item.status)}
          />
        </td>

        <td>
          <div className='d-flex align-items-center' style={{ gap: '0.5rem' }}>
            <Button
              size='sm'
              outline
              onClick={() => {
                onDetailsClick(item?.id)
              }}
            >
              Details
            </Button>

            {activeTab !== 0 || !hasEditPermission ? null : (
              <InsuranceActions
                setConfirmApprove={setConfirmApprove}
                setConfirmCancel={setConfirmCancel}
                setEditModal={setEditModal}
              />
            )}
          </div>
        </td>
        {/* {activeTab !== 2 || !hasEditPermission ? null : (
          <td>
            <Row className='p-0 m-0 flex-nowrap'>
              <button
                onClick={() => setConfirmDeactivate(true)}
                className='btn btn-danger mr-2'
              >
                {deactivate.isLoading && (
                  <i className='bx bx-loader bx-spin mx-2' />
                )}
                Deactivate
              </button>
            </Row>
          </td>
        )} */}
      </tr>
      {confirmApprove && (
        <ApproveModal
          toggle={() => setConfirmApprove(false)}
          isOpen={confirmApprove}
          title={'Activate Insurance'}
          message={'Are you sure you want to activate this insurance'}
          onSubmit={(v) => {
            setConfirmApprove(false)
            approve.startFetch({ insurance_id: item.id, ...v })
          }}
        />
      )}
      {confirmCancel && (
        <ConfirmationModal
          toggle={() => setConfirmCancel(false)}
          isOpen={confirmCancel}
          title={'Cancel Insurance'}
          message={'Are you sure you want to cancel this insurance'}
          onConfirm={() => {
            setConfirmCancel(false)
            cancel.startFetch({ insurance_id: item.id })
          }}
        />
      )}
      {confirmDeactivate && (
        <ConfirmationModal
          toggle={() => setConfirmDeactivate(false)}
          isOpen={confirmDeactivate}
          title={'Deactivate Insurance'}
          message={'Are you sure you want to deactivate this insurance'}
          onConfirm={() => {
            setConfirmDeactivate(false)
            deactivate.startFetch({ insurance_id: item.id })
          }}
        />
      )}
      {editModal && (
        <RequestModal
          req={item}
          isOpen={editModal}
          toggle={() => setEditModal(false)}
          onConfirm={(v) => {
            setEditModal(false)
            update.startFetch({ ...v, insurance_id: item?.id })
          }}
          dependents={item?.dependents}
          planeName={item?.plan.name}
        />
      )}

      {!showBeneficiaries ? null : (
        <BeneficiariesModal
          dependents={showBeneficiaries}
          isOpen={!!showBeneficiaries}
          toggle={() => setShowBeneficiaries(null)}
          currency={item?.currency?.code}
          item={item}
        />
      )}
    </>
  )
}
function InsuranceActions({
  setConfirmApprove,
  setConfirmCancel,
  setEditModal,
}) {
  const [isOpen, setIsOpen] = useState(false)

  const insuranceOptions = [
    {
      id: 1,
      onClick: () => setConfirmApprove(true),
      content: (
        <>
          <BxIcon name='bx bx-check' className='font-size-14 text-muted' />
          <span>Approve</span>
        </>
      ),
    },
    {
      id: 2,
      onClick: () => setConfirmCancel(true),
      content: (
        <>
          <BxIcon name='bx bx-history' className='font-size-14 text-muted' />
          <span>Cancel</span>
        </>
      ),
    },
  ]

  return (
    <ActionsDropdown
      options={insuranceOptions}
      isOpen={isOpen}
      toggle={() => setIsOpen((t) => !t)}
    />
  )
}

export function BxIcon({ loading, name, className }) {
  return (
    <i
      className={cx(
        `${loading ? 'bx bx-loader-circle bx-spin' : name}`,
        className,
      )}
    />
  )
}
export const DependentsDetails = ({ hide, show, id }) => {
  const isMobile = useResize()

  const getInsuranceDetails = useFetch(
    {
      action: getDependentDetails,
      withAdminAccess: true,
      autoFetch: !!id,
      body: { insurance_id: id },
    },
    [id],
  )

  return (
    <div
      style={{ position: 'absolute', top: 0, bottom: 0, left: 0, zIndex: 1100 }}
    >
      <Menu
        onClose={hide}
        className={'bg-white'}
        isOpen={show}
        width={isMobile ? '100%' : '30%'}
        right
      >
        {getInsuranceDetails.isLoading ? (
          <Row
            style={{ minHeight: '30rem' }}
            className='d-flex p-0 m-0  justify-content-center align-items-center'
          >
            <Spinner type='grow' className='mr-2' color='primary' />
          </Row>
        ) : (
          <Row className={'p-0 m-0'}>
            <Col md={12} className={'p-0 m-0'}>
              <Card>
                <CardBody className='m-0  p-4'>
                  {getInsuranceDetails.data?.map((item, i) => {
                    return (
                      <div key={i}>
                        <StyledH6 min={'20px'} max={'30px'}>
                          {item?.title}
                        </StyledH6>
                        {item?.details?.map((v, x) => {
                          return (
                            <div key={v?.title}>
                              <h5 className='col-form-label p-0 m-0 mb-2 font-size-14'>
                                {v?.title}
                              </h5>
                              <p
                                className={'p-2'}
                                style={{
                                  border: '1px solid #E7E8F2',
                                  borderRadius: 4,
                                  color: '#777F9E',
                                }}
                              >
                                {v?.value || 'N/A'}
                              </p>
                            </div>
                          )
                        })}
                      </div>
                    )
                  })}
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Menu>
    </div>
  )
}
export default InsuranceAdmin
