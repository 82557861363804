import { AvField, AvForm } from 'availity-reactstrap-validation'
import React, { useMemo, useState } from 'react'
import Pagination from 'react-js-pagination'
import { useSelector } from 'react-redux'
import {
  Card,
  Col,
  Container,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap'
import toastr from 'toastr'

import ActionsDropdown from '../../../../components/ActionsDropdown'
import customStyles from '../../../../components/Common/react-select-custom-styles'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import Button from '../../../../components/ui/button'
import DataTable from '../../../../components/ui/data-table'
import ADMIN_PERMISSIONS from '../../../../config/admin-permissions'
import { useFetch } from '../../../../helpers/hooks'
import useHasPermission from '../../../../helpers/hooks/admin/has-permission'
import {
  addProviderRule as addProviderRuleAction,
  deleteProviderRule as deleteProviderRuleAction,
  editProviderRule as editProviderRuleAction,
  getAllProviderRules,
} from '../../../../services/api'
import {
  mapCountryToOption,
  mapCurrencyToOption,
} from '../../../../utils/map-to-option'
import { BxIcon } from '../Insurance'
import AvFieldSelect from './av-field-select'

function mapFiltersToBody(filters) {
  const body = {
    page: filters?.page,
    payment_provider_id: filters?.payment_provider_id?.value,
    source_currency: filters?.source_currency?.value,
    target_currency: filters?.target_currency?.value,
    country_code: filters?.country_code?.value,
  }

  return body
}

export default function ProviderRules() {
  const [filters, setFilters] = useState({ page: 1 })
  const [showEditModal, setShowEditModal] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(null)
  function closeEditModal() {
    setShowEditModal(null)
  }
  function closeDeleteModal() {
    setShowDeleteModal(null)
  }

  function handleFiltersChange(key, value, options = {}) {
    const { action } = options

    setFilters((f) => {
      const changes = {}

      const isPageChange = key === 'page'
      if (isPageChange) {
        changes.page = value
      } else {
        const isClearingFilter = action === 'clear'
        changes.page = isClearingFilter ? 1 : f.page
        changes[key] = value
      }

      return { ...f, ...changes }
    })
  }

  const {
    data: providerRules,
    isLoading,
    startFetch: refreshRulesList,
    paginator,
  } = useFetch(
    {
      action: getAllProviderRules,
      withAdminAccess: true,
      autoFetch: true,
      body: { ...mapFiltersToBody(filters) },
    },
    [filters],
  )

  const { startFetch: editProviderRule, isLoading: isEditing } = useFetch({
    action: editProviderRuleAction,
    withAdminAccess: true,
    onComplete: () => {
      refreshRulesList()
      closeEditModal(null)
    },
  })
  const { startFetch: deleteProviderRule, isLoading: isDeleting } = useFetch({
    action: deleteProviderRuleAction,
    withAdminAccess: true,
    onComplete: () => {
      refreshRulesList()
      closeDeleteModal(null)
    },
  })

  const hasCreateProviderRulePermission = useHasPermission(
    ADMIN_PERMISSIONS.CREATE_PROVIDER_RULES,
  )
  const hasUpdateProviderRulePermission = useHasPermission(
    ADMIN_PERMISSIONS.UPDATE_PROVIDER_RULES,
  )
  const hasDeleteProviderRulePermission = useHasPermission(
    ADMIN_PERMISSIONS.DELETE_PROVIDER_RULES,
  )

  const columns = useMemo(() => {
    return [
      { Header: 'Provider', accessor: 'payment_provider_name' },
      { Header: 'Source', accessor: 'source_currency' },
      { Header: 'Target', accessor: 'target_currency' },
      { Header: 'Country', accessor: 'country_code' },
      { Header: 'Swift', accessor: 'swift_code' },
      {
        Header: 'Actions',
        Cell: ({ rowData }) => {
          const options = [
            hasUpdateProviderRulePermission && {
              content: (
                <>
                  <BxIcon name='bx bx-edit-alt' className='font-size-14' />
                  <span>Edit</span>
                </>
              ),
              onClick: () => setShowEditModal(rowData),
            },
            hasDeleteProviderRulePermission && {
              content: (
                <>
                  <BxIcon name='bx bx-trash-alt' className='font-size-14' />
                  <span>Delete</span>
                </>
              ),
              onClick: () => setShowDeleteModal(rowData),
              className: 'text-danger',
            },
          ].filter(Boolean)

          if (options.length <= 0) return null

          return <ActionsDropdown options={options} />
        },
      },
    ]
  }, [hasDeleteProviderRulePermission, hasUpdateProviderRulePermission])

  return (
    <div className='page-content'>
      <div
        className='d-flex justify-content-between align-items-center'
        style={{ marginBottom: '2rem' }}
      >
        <h1 className='mb-0 rp-font-gilroyB'>Provider Rules</h1>
        {isLoading || !hasCreateProviderRulePermission ? null : (
          <AddRuleModal refreshData={refreshRulesList} />
        )}
      </div>

      <Card className='p-3'>
        <Filters filters={filters} onChange={handleFiltersChange} />

        {isLoading ? (
          <div
            style={{ minHeight: '65vh' }}
            className='d-flex justify-content-center align-items-center'
          >
            <Spinner type='grow' color='primary' />
          </div>
        ) : (
          <>
            <DataTable
              columns={columns}
              data={providerRules}
              className='sticky-header'
              headClassName='border-bottom-0 text-body'
            />

            <Pagination
              itemClass='page-item'
              linkClass='page-link'
              innerClass='pagination mb-0 mt-3 justify-content-end'
              itemsCountPerPage={paginator?.per_page}
              totalItemsCount={paginator?.total ?? 100}
              activePage={filters?.page}
              onChange={(v) => handleFiltersChange('page', v)}
            />
          </>
        )}

        <EditRuleModal
          item={showEditModal}
          closeModal={closeEditModal}
          onSubmit={editProviderRule}
          isLoading={isEditing}
        />
        <DeleteRuleModal
          item={showDeleteModal}
          closeModal={closeDeleteModal}
          onSubmit={deleteProviderRule}
          isLoading={isDeleting}
        />
      </Card>
    </div>
  )
}

function Filters({ filters, onChange }) {
  const currencies = useSelector(
    (state) => state?.Layout?.staticData?.currencies ?? [],
  )
  const countries = useSelector(
    (state) => state.Layout?.staticData?.countries ?? [],
  )
  const paymentProviders = useSelector(
    (state) => state.Layout?.staticData?.payment_providers ?? [],
  )

  const filtersFields = [
    {
      name: 'payment_provider_id',
      label: 'Provider',
      options: paymentProviders.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    },
    {
      name: 'source_currency',
      label: 'Source currency',
      options: currencies.map(mapCurrencyToOption),
    },
    {
      name: 'target_currency',
      label: 'Target currency',
      options: currencies.map(mapCurrencyToOption),
    },
    {
      name: 'country_code',
      label: 'Country',
      options: countries.map((c) => mapCountryToOption(c, 'iso2')),
    },
  ]

  return (
    <Container fluid className='px-1 pb-3'>
      <Row className='mx-n2'>
        {filtersFields.map(({ name, label, options }) => {
          return (
            <Col xs={12} md={6} lg={3} className='px-2' key={name}>
              <FormGroup>
                <Label htmlFor={name}>{label}</Label>
                <CustomSelect
                  styles={customStyles}
                  value={filters[name]}
                  inputId={name}
                  onChange={(option, actionType) => {
                    onChange(name, option, { action: actionType.action })
                  }}
                  options={options}
                  isClearable
                />
              </FormGroup>
            </Col>
          )
        })}
      </Row>
    </Container>
  )
}

function DeleteRuleModal({ item, closeModal, onSubmit, isLoading }) {
  function handleSubmit(_, values) {
    onSubmit({ ...values, id: item?.id })
  }

  return (
    <DefaultFormModel
      isOpen={!!item}
      toggle={closeModal}
      isLoading={isLoading}
      confirmText='Delete rule'
      submitId='delete-provider-rule'
      modalTitle='Delete Provider Rule'
    >
      <AvForm id='delete-provider-rule' onSubmit={handleSubmit} />
      <p className='font-size-18 my-4 text-center text-secondary'>
        Are you sure you want to delete this rule?
      </p>
    </DefaultFormModel>
  )
}

function EditRuleModal({ item, closeModal, onSubmit, isLoading }) {
  function handleSubmit(_, values) {
    onSubmit({ ...values, id: item?.id })
  }

  return (
    <DefaultFormModel
      isOpen={!!item}
      toggle={closeModal}
      isLoading={isLoading}
      confirmText='Edit rule'
      submitId='edit-provider-rule'
      modalTitle='Edit Provider Rule'
    >
      <ProviderRuleForm
        formId='edit-provider-rule'
        onSubmit={handleSubmit}
        defaultValues={item}
      />
    </DefaultFormModel>
  )
}

function AddRuleModal({ refreshData }) {
  const [showModal, setShowModal] = useState(false)

  function toggle() {
    setShowModal((o) => !o)
  }

  const { startFetch: addProviderRule, isLoading } = useFetch({
    action: addProviderRuleAction,
    withAdminAccess: true,
    checkSuccess: true,
    onComplete: () => {
      refreshData?.()
      toggle()
    },
    onError: (data) => {
      if (typeof data === 'string') {
        toastr.error(data)
      } else {
        const header = data?.message
        const messages = Object.entries(data?.errors ?? {}).reduce(
          (prev, curr) => {
            const [key, value] = curr
            return [...prev, `<b>${key}</b>: ${value}`]
          },
          [],
        )
        toastr.error(messages.join('<br />'), header, { allowHtml: true })
      }
    },
  })

  function handleSubmit(_, values) {
    addProviderRule(values)
  }

  return (
    <>
      <Button size='sm' onClick={() => setShowModal(true)}>
        Add Provider Rule
      </Button>

      <DefaultFormModel
        isOpen={showModal}
        toggle={toggle}
        isLoading={isLoading}
        submitId='provider-rule'
        modalTitle='Add Provider Rule'
      >
        <ProviderRuleForm formId='provider-rule' onSubmit={handleSubmit} />
      </DefaultFormModel>
    </>
  )
}

function ProviderRuleForm({ onSubmit, formId, defaultValues }) {
  const currencies = useSelector(
    (state) => state?.Layout?.staticData?.currencies ?? [],
  )
  const countries = useSelector(
    (state) => state.Layout?.staticData?.countries ?? [],
  )
  const paymentProviders = useSelector(
    (state) => state.Layout?.staticData?.payment_providers ?? [],
  )

  return (
    <AvForm id={formId} onValidSubmit={onSubmit} model={defaultValues}>
      <AvFieldSelect
        name='payment_provider_id'
        label='Payment Provider'
        options={paymentProviders.map(({ id, name }) => ({
          label: name,
          value: id,
        }))}
      />
      <AvFieldSelect
        name='source_currency'
        label='Source Currency'
        options={currencies.map(mapCurrencyToOption)}
      />
      <AvFieldSelect
        name='target_currency'
        label='Target Currency'
        options={currencies.map(mapCurrencyToOption)}
      />
      <AvFieldSelect
        name='country_code'
        label='Country'
        options={countries.map((c) => mapCountryToOption(c, 'iso2'))}
      />
      <AvField name='swift_code' label='Swift Code' />
    </AvForm>
  )
}

function DefaultFormModel({
  children,
  isOpen,
  toggle,
  isLoading,
  submitId,
  modalTitle,
  confirmText = 'Add a rule',
}) {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <Button color='light' outline onClick={toggle} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          type='submit'
          formId={submitId}
          disabled={isLoading}
          loading={isLoading}
        >
          {confirmText}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
