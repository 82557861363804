export const BANK_TRANSFER = { name: 'Bank Transfer', id: 3 }
export const CASH_POINT = { name: 'CashPoint', id: 6 }
export const PAYONEER = { name: 'Payoneer', id: 7 }
export const COINBASE = { name: 'Coinbase', id: 9 }
export const PAYPAL = { name: 'PayPal', id: 2 }
export const WALLET = { name: 'Wallet', id: 8 }
export const CREDIT_CARD = { name: 'Credit Card', id: 1 }
export const SEPA_DEBIT = { name: 'SEPA Direct Debit', id: 4 }
export const ACH = { name: 'ACH', id: 5 }
export const MERCURY = { name: 'Mercury', id: 13 }
export const WISE_USD = { name: 'Wise', id: 12 }
export const WISE_EUR = { name: 'Wise', id: 14 }
export const WISE_GBP = { name: 'Wise', id: 15 }
export const WISE_AUD = { name: 'Wise', id: 16 }
export const BREX = { name: 'Brex', id: 15, type: 'brex' }

export const PAYMENT_METHODS_BY_ID = {
  [BANK_TRANSFER.id]: BANK_TRANSFER,
  [CASH_POINT.id]: CASH_POINT,
  [PAYONEER.id]: PAYONEER,
  [COINBASE.id]: COINBASE,
  [PAYPAL.id]: PAYPAL,
  [WALLET.id]: WALLET,
}

export const WITHDRAW_METHODS_BY_ID = {
  [BANK_TRANSFER.id]: BANK_TRANSFER,
  [CASH_POINT.id]: CASH_POINT,
  [PAYONEER.id]: PAYONEER,
  [COINBASE.id]: COINBASE,
  [PAYPAL.id]: PAYPAL,
}

export const WITHDRAW_METHODS = {
  BANK_TRANSFER,
  CASH_POINT,
  PAYONEER,
  COINBASE,
  PAYPAL,
}
