import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  Form,
  FormGroup,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import * as yup from 'yup'

import ControlledCurrencyInput from '../../../../components/ControlledCurrencyInput'
import ControlledInput from '../../../../components/ControlledInput'
import ControlledSelect from '../../../../components/ControlledSelect'
import Button from '../../../../components/ui/button'
import { useFetch } from '../../../../helpers/hooks'
import { requestAClientBalanceAdjustment } from '../../../../services/api'
import {
  getOptionFromList,
  mapCurrencyToOption,
} from '../../../../utils/map-to-option'
import { FlagIcon } from '../../../../components/ui/flag-icon'
import toastr from 'toastr'

const adjustmentMethods = [
  { value: 'addition', label: 'Balance addition' },
  { value: 'deduction', label: 'Balance deduction' },
]

const addAdjustmentFormId = 'addAdjustmentFormId'

const schema = yup.object().shape({
  amount: yup
    .number()
    .positive('The amount should be a positive number')
    .required('Amount is required'),
  currency_id: yup.string().required('Currency is required'),
  type: yup.string().required('Adjustment method is required'),
  transaction_id: yup.string().required('Transaction Id is required'),
  note: yup.string().required('Note is required'),
})
function handleErrorRequest(resp) {
  toastr.error(resp?.message ?? 'Something went wrong')
}
export default function ClientBalanceAdjustmentModal({ refreshData }) {
  const [isOpen, setIsOpen] = useState(false)

  const { startFetch: requestAdjustment, isLoading: adjusting } = useFetch({
    action: requestAClientBalanceAdjustment,
    withAdminAccess: true,
    onComplete: () => {
      refreshData()
      toggle()
    },
    onError: handleErrorRequest,
  })

  const currencies = useSelector(
    (state) => state?.Layout?.staticData?.currencies,
  )

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  })

  function toggle() {
    setIsOpen((open) => {
      if (open) {
        reset()
      }

      return !open
    })
  }

  const selectedCurrency = getOptionFromList(
    currencies,
    watch('currency_id'),
    'id',
  )

  function onSubmit(values) {
    requestAdjustment(values)
  }

  return (
    <>
      <Button onClick={toggle}>Add request</Button>

      <Modal isOpen={isOpen} toggle={toggle} unmountOnClose>
        <ModalHeader toggle={toggle}>Add request</ModalHeader>

        <ModalBody>
          <Form id={addAdjustmentFormId} onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <ControlledInput
                control={control}
                label='Transaction Id'
                name='transaction_id'
                error={errors?.transaction_id?.message}
                id='transaction_id'
                labelFor='transaction_id'
              />
            </FormGroup>

            <FormGroup>
              <ControlledSelect
                control={control}
                label='Type'
                name='type'
                error={errors?.type?.message}
                options={adjustmentMethods}
              />
            </FormGroup>

            <FormGroup>
              <ControlledSelect
                control={control}
                label='Currency'
                name='currency_id'
                error={errors?.currency_id?.message}
                options={currencies?.map((c) => mapCurrencyToOption(c, 'id'))}
              />
            </FormGroup>

            <FormGroup>
              <Label for='amount'>Amount</Label>
              <InputGroup>
                {!selectedCurrency?.code ? null : (
                  <InputGroupText
                    className='border-right-0 d-flex gap-4'
                    style={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                  >
                    <FlagIcon code={selectedCurrency.code} />{' '}
                    {selectedCurrency.code}
                  </InputGroupText>
                )}

                <ControlledCurrencyInput
                  control={control}
                  name='amount'
                  id='amount'
                  labelFor='amount'
                  // currency input props
                  autoComplete='off'
                  allowDecimals={true}
                  decimalsLimit={2}
                  decimalSeparator='.'
                  groupSeparator=','
                />
              </InputGroup>

              <InvalidFormFeedback error={errors?.amount?.message} />
            </FormGroup>

            <FormGroup>
              <ControlledInput
                type='textarea'
                control={control}
                label='Note'
                name='note'
                error={errors?.note?.message}
                id='note'
                labelFor='note'
              />
            </FormGroup>
          </Form>
        </ModalBody>

        <ModalFooter>
          <Button color='light' outline onClick={toggle} disabled={adjusting}>
            Cancel
          </Button>

          <Button
            formId={addAdjustmentFormId}
            type='submit'
            disabled={adjusting}
            loading={adjusting}
          >
            Add request
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export function InvalidFormFeedback({ error }) {
  if (!error) return null

  return <small className='form-text text-danger'>{error}</small>
}
