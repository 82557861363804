import React, { useEffect } from 'react'
import { Col, Container, Row, Spinner } from 'reactstrap'
import { useSelector } from 'react-redux'

const DocuSignWrapper = () => {
  const user = useSelector((state) => state?.Account?.user)
  useEffect(() => {
    window.analytics.identify(
      user?.id,
      { someTrait: 'x' },
      {
        Intercom: { hideDefaultLauncher: true },
      },
    )
    return () => {
      window.analytics.identify(
        user?.id,
        { someTrait: 'x' },
        {
          Intercom: { hideDefaultLauncher: false },
        },
      )
    }
  }, [])
  return (
    <Container style={{ minHeight: '100vh' }}>
      <Col style={{ minHeight: '100vh' }}>
        <Row
          style={{ minHeight: '100vh' }}
          className='justify-content-center align-items-center'
        >
          <Spinner type='grow' className='mr-2' color='primary' />
        </Row>
      </Col>
    </Container>
  )
}

export default DocuSignWrapper
