import React from 'react'
import { Link } from 'react-router-dom'

import { HeaderLogo } from '../../components/Common/header-logo'

export default function NotFoundPage() {
  return (
    <div style={{ minHeight: '100vh' }} className='d-flex flex-column'>
      <header className='bg-white p-3'>
        <Link to='/'>
          <HeaderLogo />
        </Link>
      </header>

      <div className='flex-grow-1 d-flex flex-column justify-content-center align-items-center'>
        <h1 className='font-size-28 mb-0 rp-font-gilroyB text-gray-h'>
          Page not found
        </h1>

        <Link to='/activity' className='mt-1'>
          Go back home
        </Link>
      </div>
    </div>
  )
}
