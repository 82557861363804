import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import { format } from 'date-fns'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import UploadPreview from '../../../components/Common/upload-preview'
import ControlledCurrencyInput from '../../../components/ControlledCurrencyInput'
import ControlledDatePicker from '../../../components/ControlledDatePicker'
import ControlledInput from '../../../components/ControlledInput'
import ControlledSelect from '../../../components/ControlledSelect'
import { StyledH5 } from '../../../components/Typo'
import Button from '../../../components/ui/button'
import { useFetch, useResize } from '../../../helpers/hooks'
import {
  addExpense,
  getLocalCurrencies,
  getOccurrences,
  parseExpenseV2,
  submitFullTimeExpense,
  uploadExpense,
} from '../../../services/api'
import {
  customOption,
  customSingleValue,
} from '../RequestContract/FullTimeForm'
import expenseImage from './../../../assets/images/expense.svg'

function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

function getFieldsData(entities, currencies) {
  const fieldsData = []

  entities.forEach((entity) => {
    if (entity?.type === 'supplier_name') {
      const supplierName = entity?.mentionText || entity?.normalizedValue?.text

      fieldsData.push({ key: 'expense', value: supplierName ?? '' })
    } else if (entity?.type === 'total_amount') {
      const amount = entity?.normalizedValue?.text

      fieldsData.push({ key: 'amount', value: Math.abs(Number(amount)) })
    } else if (entity?.type === 'receipt_date') {
      const dateObj = entity?.normalizedValue?.dateValue

      const dateFormat = 'yyyy-MM-dd'
      const formattedDate = format(
        new Date(dateObj.year, dateObj.month - 1, dateObj.day),
        dateFormat,
      )

      fieldsData.push({ key: 'date', value: formattedDate })
    } else if (entity?.type === 'currency') {
      const currency = entity?.normalizedValue?.text
      const currencyId = currencies?.find((e) => e.code === currency)?.id

      fieldsData.push({ key: 'currency_id', value: currencyId })
    }
  })

  return fieldsData
}

const ExpenseModal = ({ show, hide, isRecurring, onSubmit }) => {
  const contract = useSelector((state) => state?.Contract?.details)
  const isEmployee = useSelector(
    (state) => state.userProfile?.userProfile?.contractor_type === 'employee',
  )
  const staticData = useSelector((state) => state?.Layout?.staticData)

  const isMobile = useResize()
  const [preview, setPreview] = useState(null)

  const { startFetch: parseExpense, isLoading: isParsingExpense } = useFetch({
    action: parseExpenseV2,
    onComplete: handleParsedExpense,
  })

  const { acceptedFiles, getRootProps, getInputProps, inputRef } = useDropzone({
    acceptedFiles: 'application/pdf,image/jpeg,image/png,image/gif',
    onDrop(acceptedFiles) {
      const firstFile = acceptedFiles[0]
      if (firstFile.size > 2000000) {
        toastr.error('The file may not be greater than 2MB')
      } else {
        toBase64(firstFile).then((file) => {
          setPreview({ data: file, type: firstFile.type })
          const fileData = file.split(',')[1]
          parseExpense({ file: fileData })
          upload.startFetch({ photo: firstFile })
        })
      }
    },
  })
  const removeAll = (e) => {
    e.stopPropagation()
    if (inputRef && inputRef.current) {
      acceptedFiles.length = 0
      acceptedFiles.splice(0, acceptedFiles.length)
      inputRef.current.value = ''
      e.currentTarget.blur()
    }
  }

  const currencies = useFetch({ action: getLocalCurrencies, autoFetch: true })

  function handleParsedExpense(data) {
    const fieldsData = getFieldsData(data?.entities, currencies?.data)

    const dirtyFieldsKeys = Object.keys(dirtyFields)
    fieldsData.forEach(({ key, value }) => {
      if (!dirtyFieldsKeys.includes(key)) {
        setValue(key, value)
      }
    })
  }

  const upload = useFetch({
    action: uploadExpense,
    onError: (err) => toastr?.error(err),
  })
  const add = useFetch({
    action: isEmployee ? submitFullTimeExpense : addExpense,
    onComplete: (data) => {
      if (data?.success === false) {
        const errMessage = Object.entries(data?.data).reduce(
          (acc, [field, msgs]) => {
            return acc + field + '<br/>' + msgs.join('<br/>') + '<br/>'
          },
          '',
        )

        toastr.error(errMessage, '', { allowHtml: true })
      } else {
        hide?.()
      }
    },
  })

  const dataSchema = yup.object().shape({
    expense: yup.string().max(25).required('Expense is required'),
    amount: yup.string().required('Amount is required'),
    category_id: yup.string().required('Category is required'),
  })

  if (isRecurring) {
    const recurringSchema = yup.object().shape({
      start_date: yup.string().required('Start date is required'),
      occurrence_id: yup.number().required('Occurrence is required'),
    })
    dataSchema.concat(recurringSchema)
  } else {
    const nonRecurringSchema = yup.object().shape({
      currency_id: yup.number().required('Currency is required'),
      date: yup.string().required('Expense date is required'),
    })
    dataSchema.concat(nonRecurringSchema)
  }

  const {
    handleSubmit,
    control,
    watch,
    setValue,

    formState: { dirtyFields, errors },
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(dataSchema),
    shouldUnregister: true,
    defaultValues: { category_id: 15, date: moment() },
  })

  const occurrences = useFetch({ action: getOccurrences })

  useEffect(() => {
    setValue('occurrence_id', null)
    occurrences.startFetch({
      start_date: moment(new Date()).format('YYYY-MM-DD'),
    })
  }, [])

  useEffect(() => {
    setValue('start_date', null, { shouldValidate: true })
  }, [watch('occurrence_id')])

  const submit = (data) => {
    const formattedAmount = data?.amount?.replaceAll(',', '')
    isRecurring
      ? onSubmit({ ...data, amount: formattedAmount, name: data?.expense })
      : add.startFetch({
          contract_id: contract?.id,
          name: data?.expense,
          date: moment(data?.date).format('YYYY-MM-DD'),
          category_id: data?.category_id,
          amount: formattedAmount,
          currency_id: data?.currency_id,
          photo: upload?.data?.path,
        })
  }

  return (
    <div>
      <Modal
        isOpen={show}
        toggle={hide}
        centered={true}
        size={!isRecurring && 'lg'}
      >
        <ModalHeader toggle={hide}>
          {isRecurring ? 'Set up a recurring expense' : 'Add expense'}
        </ModalHeader>
        <form
          autoComplete='off'
          className='form-horizontal m-0 '
          onSubmit={handleSubmit(submit)}
        >
          <ModalBody>
            <Row>
              {!isRecurring && (
                <Col md={6} sm={12}>
                  <div className='dropzone border-primary h-100'>
                    <div
                      className='dz-message needsclick bg-soft-primary h-100'
                      {...getRootProps()}
                    >
                      <input
                        {...getInputProps()}
                        accept='image/x-png,image/gif,image/jpeg,application/pdf'
                      />

                      <div className='d-flex align-items-center justify-content-center cursor-pointer h-100'>
                        {!!acceptedFiles &&
                        acceptedFiles?.length > 0 &&
                        acceptedFiles[0].size <= 2000000 ? (
                          <>
                            {isParsingExpense ? (
                              <Spinner
                                style={{ width: '5rem', height: '5rem' }}
                                color='primary'
                              />
                            ) : (
                              <>
                                <UploadPreview preview={preview} />

                                <button
                                  onClick={removeAll}
                                  title='Remove file'
                                  type='button'
                                  className='rp-btn-nostyle text-danger position-absolute d-flex p-1'
                                  style={{ top: 12, right: 32 }}
                                >
                                  <i className='bx bx-trash' />
                                </button>
                              </>
                            )}
                          </>
                        ) : (
                          <div>
                            <img className='pb-4' src={expenseImage} alt='' />

                            {isMobile ? (
                              <h4>Click to upload files.</h4>
                            ) : (
                              <StyledH5 max='16px'>
                                Drop here or click to upload receipt
                              </StyledH5>
                            )}
                            <p className='text-muted font-size-12 mb-0'>
                              Max file size 2MB
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              )}
              {isMobile && <div className='p-2' />}
              <Col md={isRecurring ? 12 : 6} sm={12}>
                <Row className='justify-content-center mb-3'>
                  <Col>
                    <label htmlFor='expense'>
                      Expense
                      <span className='text-danger font-size-16 ml-1'>*</span>
                    </label>
                    <ControlledInput
                      control={control}
                      placeholder=''
                      name='expense'
                      id='expense'
                      error={errors.expense}
                      disabled={isParsingExpense}
                    />
                  </Col>
                </Row>
                {!isRecurring && (
                  <Row className='justify-content-center mb-3'>
                    <Col>
                      <FormGroup className='mb-0'>
                        <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                          Date
                          <span className='text-danger font-size-16 mx-1'>
                            *
                          </span>
                        </Label>
                        <ControlledDatePicker
                          control={control}
                          name='date'
                          error={errors.date}
                          disabled={isParsingExpense}
                        />
                        {!!errors.date && (
                          <span className='font-size-11 text-danger'>
                            {errors.date?.message}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                )}
                <Row className='justify-content-center mb-3'>
                  <Col>
                    <FormGroup
                      style={{ whiteSpace: 'nowrap' }}
                      className='d-inline p-0 m-0 mb-2'
                    >
                      <Label
                        htmlFor='billing-name'
                        md='3'
                        className='col-form-label p-0 m-0 mb-2 font-size-14  '
                      >
                        Category
                      </Label>
                      <ControlledSelect
                        name='category_id'
                        error={errors.category_id}
                        control={control}
                        options={staticData?.expense_categories?.map((e) => ({
                          label: e?.name,
                          value: e?.id,
                        }))}
                        isDisabled={isParsingExpense}
                      />
                      {!!errors.category_id && (
                        <span className='font-size-11 text-danger'>
                          {errors.category_id?.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row className='justify-content-center mb-3'>
                  <Col className='pb-0'>
                    <FormGroup className='d-inline p-0 m-0'>
                      <Label
                        style={{ fontFamily: 'gilroyB', fontWeight: '600' }}
                        className='col-form-label p-0 m-0 mb-2 font-size-14'
                      >
                        Amount
                        <span className='text-danger font-size-16 mx-1'>*</span>
                      </Label>
                      <InputGroup style={{ zIndex: 6 }}>
                        <ControlledCurrencyInput
                          maxLength={isRecurring && 7}
                          control={control}
                          name='amount'
                          error={errors.amount}
                          disabled={isParsingExpense}
                        />
                        {isRecurring ? (
                          <InputGroupAddon addonType='append'>
                            <InputGroupText>
                              {contract?.currency?.code}
                            </InputGroupText>
                          </InputGroupAddon>
                        ) : (
                          <InputGroupAddon addonType='append'>
                            <InputGroupText
                              className={classNames({
                                'p-0': true,
                                'border-danger': !!errors.currency_id,
                              })}
                              style={{ width: '126px', border: 0 }}
                            >
                              <div className='w-100'>
                                <ControlledSelect
                                  error={errors.currency_id}
                                  control={control}
                                  name='currency_id'
                                  options={currencies?.data?.map((c) => ({
                                    code: c.code,
                                    label: `${c.code} ${c.name} ${c.symbol}`,
                                    name: c.name,
                                    icon: (
                                      <div
                                        className={`currency-flag mr-2 flex-shrink-0 currency-flag-${c.code.toLowerCase()}`}
                                      />
                                    ),
                                    value: c.id,
                                  }))}
                                  isDisabled={isParsingExpense}
                                  classNamePrefix='RS-Addon'
                                  customComponents={{
                                    Option: customOption,
                                    Value: customSingleValue,
                                    SingleValue: customSingleValue,
                                  }}
                                />
                              </div>
                            </InputGroupText>
                          </InputGroupAddon>
                        )}
                      </InputGroup>
                      {!!errors.amount && (
                        <span className='font-size-11 text-danger'>
                          {errors.amount?.message}
                        </span>
                      )}{' '}
                      {!!errors.currency_id && (
                        <span className='font-size-11 text-danger'>
                          {errors.currency_id?.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                {isRecurring && (
                  <Row className='justify-content-center mb-3'>
                    <Col>
                      <FormGroup className='mb-0'>
                        <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                          Occurrence
                          <span className='text-danger font-size-16 mx-1'>
                            *
                          </span>
                        </Label>
                        <ControlledSelect
                          control={control}
                          name='occurrence_id'
                          error={errors.occurrence_id}
                          options={occurrences.data
                            ?.filter((o) => o.frequency_id === 4)
                            ?.map((e) => ({
                              value: e.id,
                              label: e.name,
                            }))}
                        />
                        {!!errors.occurrence_id && (
                          <span className='font-size-11 text-danger'>
                            {errors.occurrence_id?.message}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                )}
                {isRecurring && !!watch('occurrence_id') && (
                  <Row className='justify-content-center mb-3'>
                    <Col>
                      <FormGroup className='mb-0'>
                        <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                          Start Date
                          <span className='text-danger font-size-16 mx-1'>
                            *
                          </span>
                          <span className='text-danger font-size-16 mx-1' />
                        </Label>
                        <ControlledSelect
                          control={control}
                          name='start_date'
                          error={errors.start_date}
                          options={
                            occurrences.data?.find(
                              (o) => o.id === watch('occurrence_id'),
                            )?.dates
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </ModalBody>
          {isMobile && <div style={{ height: 80 }} />}
          <ModalFooter className={isMobile && 'fixed-bottom bg-white'}>
            <Button
              color='light'
              outline
              onClick={hide}
              disabled={add.isLoading || isParsingExpense}
            >
              Cancel
            </Button>
            <Button
              type='submit'
              loading={add.isLoading}
              disabled={add.isLoading || isParsingExpense}
            >
              {isRecurring ? 'Save' : 'Submit'}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  )
}

export default ExpenseModal
