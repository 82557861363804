import classnames from 'classnames'
import moment from 'moment'
import qs from 'query-string'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { animated, useSpring } from 'react-spring'
import {
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'

import trash from '../../../assets/images/Trash.svg'
import emptyMilestoneImage from '../../../assets/images/milestones-empty.png'
import noInvoicesImage from '../../../assets/images/noinvoices.svg'
import viewIcon from '../../../assets/images/visibility.png'
import ActionsDropdown from '../../../components/ActionsDropdown'
import Avatar from '../../../components/Avatar'
import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import NoContent from '../../../components/NoContent'
import BadgeX from '../../../components/Table/BadgeX'
import StyledTh from '../../../components/Table/StyledTh'
import TableComp from '../../../components/Table/TableComp'
import TableH from '../../../components/Table/TableH'
import { StyledH5, StyledH6 } from '../../../components/Typo'
import WiseButton from '../../../components/WiseButton'
import { CONTRACT_TYPES } from '../../../core/config/contract-types'
import { storage } from '../../../helpers/config'
import {
  CONTRACT_STATUS,
  contractorTypes,
  userTypes,
} from '../../../helpers/enum'
import { useFetch, usePermissions, useResize } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  approveMilestone as approveSubmittedMilestone,
  approveWork as approveSubmittedWork,
  deleteWork,
  downloadWork,
  equipmentList,
  getClientInfo,
  getContractDetail,
  getContractorInfo,
} from '../../../services/api'
import { updateDeepLink } from '../../../store/auth/register/actions'
import { updateContract } from '../../../store/contract/actions'
import openFileV2 from '../../../utils/file/open-v2'
import Payslips from '../../Fulltime/Payslips'
import ContractDetail from '../ContractDetail/index'
import AddMilestoneModal from '../components/AddMilestoneModal'
import DeclineModal from '../components/DeclineModal'
import SubmitMilestoneModal from '../components/SubmitMilestoneModal'
import SubmitWorkModal from '../components/SubmitWorkModal'
import NeedSignModal from '../components/needSignModal'
import { Allowance } from './Allowance'
import Documents from './Documents'
import Equipments from './Equipments'
import Expenses, { ImageModal } from './Expenses'
import PaymentHistory from './Payments'
import Settings from './Settings'
import TimesOff from './TimesOff'
import DeTimeOffTab from './de-time-off-tab'
import './style.css'

const getRate = (id, number) => {
  switch (id) {
    case 1:
      return 'Hours'
    case 2:
      return 'Days'
    case 3:
      if (number > 1) {
        return 'Months'
      } else return 'Month'
    case 4:
      return 'Minutes'
  }
}
const ContractPage = (props) => {
  const history = useHistory()
  const [activeTab, setActiveTab] = useState(
    props.location.hash === '#work' ? '8' : '5',
  )

  const searchParams = new URLSearchParams(props.location.search)
  const id = searchParams.get('id')
  const tab = searchParams.get('tab')

  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const user = useSelector((state) => state.Account?.user)
  const [addMilestone, setAddMilestone] = useState(false)
  const [submitMilestone, setSubmitMilestone] = useState(false)
  const [submitWork, setSubmitWork] = useState(false)
  const [milestoneToSubmit, setMilestoneToSubmit] = useState(null)
  const [needSign, setNeedSign] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showEndedModal, setEndedModal] = useState(false)
  const [terminationDate, setTerminationDate] = useState(null)
  const [endedDate, setEndedDate] = useState(null)
  const [clientSignature, setClientSignature] = useState(null)
  const [contractorSignature, setContractorSignature] = useState(null)
  const [showSwitchPopup, setShowSwitchPopup] = useState(false)
  const [menu, setMenu] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const isMobile = useResize()
  const isProvider = useSelector(
    (state) => state.userProfile?.userProfile?.contractor_type === 'provider',
  )
  const userIsPartner = useSelector(
    (state) => state.userProfile?.userProfile?.is_partner,
  )

  const getEquipmentList = useFetch({
    action: equipmentList,
    onComplete: () => {
      setFirstLoad(false)
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const userInfo = useFetch({
    action:
      user?.type === userTypes.COMPANY ? getClientInfo : getContractorInfo,
    autoFetch: true,
  })

  useEffect(() => {
    if (data?.id) {
      getEquipmentList.startFetch({ contract_id: data?.id }, firstLoad)
    }
  }, [data?.id, data?.equipments])

  const dispatch = useDispatch()
  const { hasAccess } = usePermissions()

  const updateTabLink = (tab) => {
    let query = qs.parse(props.location.search)
    const prevTab = query?.tab

    if ((!tab && !prevTab) || tab === prevTab) {
      return
    }

    if (tab) {
      query = { ...query, tab }
    }

    const searchString = qs.stringify(query)

    history.push({ search: searchString })
  }

  useEffect(() => {
    switch (tab) {
      case 'payments':
        setActiveTab('6')
        break
      case 'documents':
        setActiveTab('7')
        break
      case 'works':
        setActiveTab('8')
        break
      case 'expenses':
        setActiveTab('9')
        break
      case 'timeOff':
        setActiveTab('10')
        break
      case 'settings':
        setActiveTab('11')
        break
      case 'equipment':
        setActiveTab('13')
        break
      case 'allowance':
        setActiveTab('14')
        break

      default:
        setActiveTab('5')
        break
    }
  }, [tab])
  useEffect(() => {
    let eventName = ''
    switch (activeTab) {
      case '5':
        updateTabLink(null)
        eventName = 'Viewed contract details'
        break
      case '6':
        updateTabLink('payments')
        eventName = 'Viewed payment tab'
        break
      case '7':
        updateTabLink('documents')
        eventName = 'Viewed document tab'
        break
      case '8':
        updateTabLink('works')
        eventName = 'Viewed work submission'
        break
      case '9':
        updateTabLink('expenses')
        eventName = 'Viewed Expenses'
        break
      case '10':
        updateTabLink('timeOff')
        eventName = 'Viewed timeOff'
        break
      case '11':
        updateTabLink('settings')
        eventName = 'Viewed settings'
        break
      case '12':
        updateTabLink('payslips')
        eventName = 'Viewed payslips'
        break
      case '13':
        updateTabLink('equipment')
        eventName = 'Viewed equipments'
        break
      case '14':
        updateTabLink('allowance')
        eventName = 'Viewed allowance'
        break
    }
    window.analytics.track(eventName, {
      contract_id: data.contract_id,
      contract_type: data.type,
      contract_status: data?.status?.name,
    })
  }, [activeTab])

  const updateContractDetail = (withLoading = true) => {
    setLoading(withLoading)

    if (!id) {
      history.push('/contracts')
      return
    }

    getContractDetail(user?.token, { id })
      .then((r) => {
        window.analytics.track('Viewed a contract', {
          'contract-id': r.data.data.contract_id,
          'contract-status': r.data.data.status?.name,
        })
        if (r.data.success) {
          if (r.data?.data?.switch_required) {
            setShowSwitchPopup(true)
          } else {
            setLoading(false)
            setData(r.data.data)
            dispatch(updateContract(r.data.data))
            setTerminationDate(r.data?.data?.termination_date)
            setEndedDate(
              r.data?.data?.status?.id === 5 && r.data.data?.end_date,
            )
            if (r.data?.data?.client) {
              setClientSignature(
                r.data?.data?.signatures?.find(
                  (e) => e?.user_id === r.data?.data?.client?.id,
                ),
              )
            }
            if (r.data?.data?.contractor) {
              setContractorSignature(
                r.data?.data?.signatures?.find(
                  (e) => e?.user_id === r.data?.data?.contractor?.id,
                ),
              )
            }
          }
        } else if (r.data.message === 'Unauthorised') {
          props.history.push('/no-access')
        }
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    updateContractDetail()
    return () => {
      dispatch(updateContract(null))
    }
  }, [])

  const switchTabs = (tab) => {
    const search = qs.stringify({ ...qs.parse(props.location.search), tab })
    history.push(`${props.location.pathname}?${search}`)
  }

  const contractUser =
    user?.type === userTypes.CONTRACTOR
      ? data.client
      : data?.employee || data.contractor

  const showInsuranceAllowanceTab =
    user?.type === userTypes.COMPANY &&
    data?.type === CONTRACT_TYPES.FIXED &&
    data?.status?.id === CONTRACT_STATUS.ONGOING.value &&
    data?.contractor?.type === contractorTypes.INDIVIDUAL

  const showAvatar = useMemo(() => activeTab !== '5', [activeTab])

  const [avatarStyles] = useSpring(() => {
    return {
      opacity: showAvatar || isMobile ? 1 : 0,
      scale: showAvatar || isMobile ? 1 : 0.3,
      x: 0,
      config: { duration: 200 },
    }
  }, [showAvatar])

  const [contractRefStyles] = useSpring(() => {
    return {
      x: showAvatar || isMobile ? '0rem' : '-5.6rem',
      config: { duration: 200 },
    }
  }, [showAvatar])

  return (
    <div className='page-content'>
      <div
        style={{ minHeight: 72 }}
        className='d-flex align-items-center gap-16 mb-3'
      >
        {!data?.id ? null : contractUser && user?.type === userTypes.COMPANY ? (
          <>
            <animated.div
              style={{ ...avatarStyles, transformOrigin: 'left center' }}
            >
              <Avatar
                label={
                  user?.type === userTypes.CONTRACTOR ? 'Client' : 'Contractor'
                }
                isLarge
                flag={contractUser?.flag}
                name={contractUser?.first_name || contractUser?.email}
                photo={contractUser?.photo}
              />
            </animated.div>
            <animated.div style={{ ...contractRefStyles }}>
              <h3 className='font-size-24 font-weight-bold'>
                {contractUser?.first_name} {contractUser?.last_name}
              </h3>
              <h4 className='font-size-16 font-weight-bold mb-0'>
                Contract{' '}
                <span className='text-muted font-weight-light'>
                  #{data?.ref}
                </span>
              </h4>
            </animated.div>
          </>
        ) : (
          <>
            <StyledH5 min='22px' mid='28px' max='32px' className='mb-0'>
              Contract
            </StyledH5>
            <StyledH6
              min='22px'
              mid='28px'
              max='32px'
              className='text-secondary font-weight-normal rp-font-gilroyB mb-0'
            >
              #{data?.ref}
            </StyledH6>
          </>
        )}
      </div>

      <Container fluid className='p-0 m-0'>
        {showSwitchPopup ? (
          <ConfirmationModal
            isOpen={showSwitchPopup}
            toggle={() => setShowSwitchPopup(false)}
            title='Switch Account'
            onConfirm={() => {
              const stateFrom = history.location
              if (stateFrom) {
                dispatch(
                  updateDeepLink(`${stateFrom?.pathname}${stateFrom?.search}`),
                )
              }
              history.push('/switch')
            }}
            caption='Switch'
            message={`You need to switch to ${
              user?.type === userTypes.COMPANY ? 'Contractor' : 'Client'
            } account to see this contract`}
          />
        ) : (
          <Col className='m-0 p-0'>
            <Row className='justify-content-between align-items-center p-0  m-0'>
              <StyledNav tabs className='nav-tabs-custom pb-3'>
                <NavItem>
                  <NavLink
                    style={{ cursor: 'pointer' }}
                    className={classnames({
                      active: activeTab === '5',
                      'contract-link': true,
                    })}
                    onClick={() => {
                      setActiveTab('5')
                      updateContractDetail(false)
                      switchTabs()
                    }}
                  >
                    Contract Details
                  </NavLink>
                </NavItem>
                {user?.type === userTypes.COMPANY &&
                  hasAccess(permissions.manageContractSettings) &&
                  data?.type !== CONTRACT_TYPES.FULL_TIME && (
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: activeTab === '11',
                          'contract-link': true,
                        })}
                        onClick={() => {
                          setActiveTab('11')
                          updateContractDetail(false)
                        }}
                      >
                        Settings
                      </NavLink>
                    </NavItem>
                  )}
                {(hasAccess(permissions.ViewContractsPayments) ||
                  user?.type === userTypes.CONTRACTOR) && (
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: activeTab === '6',
                        'contract-link': true,
                      })}
                      onClick={() => {
                        setActiveTab('6')
                        updateContractDetail(false)
                      }}
                    >
                      Payments
                    </NavLink>
                  </NavItem>
                )}
                {(hasAccess(permissions.ViewContractsDocuments) ||
                  user?.type === userTypes.CONTRACTOR) &&
                  data?.type !== CONTRACT_TYPES.FULL_TIME && (
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: activeTab === '7',
                          'contract-link': true,
                        })}
                        onClick={() => {
                          setActiveTab('7')
                          updateContractDetail(false)
                        }}
                      >
                        Documents
                      </NavLink>
                    </NavItem>
                  )}
                {[CONTRACT_TYPES.MILESTONES, CONTRACT_TYPES.FULL_TIME].includes(
                  data.type,
                ) && (
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: activeTab === '8',
                        'contract-link': true,
                      })}
                      onClick={() => {
                        setActiveTab('8')
                        switchTabs(CONTRACT_TYPES.MILESTONES.toLowerCase())
                      }}
                    >
                      {data.type === CONTRACT_TYPES.FULL_TIME || userIsPartner
                        ? 'Invoices'
                        : CONTRACT_TYPES.MILESTONES}
                    </NavLink>
                  </NavItem>
                )}
                {data.type === CONTRACT_TYPES.PAY_AS_YOU_GO && (
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: activeTab === '8',
                        'contract-link': true,
                      })}
                      onClick={() => {
                        setActiveTab('8')
                        updateContractDetail(false)
                      }}
                    >
                      Submitted Work
                    </NavLink>
                  </NavItem>
                )}
                {[4, 5, 6, 7].includes(data?.status?.id) &&
                  !isProvider &&
                  (hasAccess(permissions.viewExpenses) ||
                    user?.type === userTypes.CONTRACTOR) && (
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: activeTab === '9',
                          'contract-link': true,
                        })}
                        onClick={() => {
                          setActiveTab('9')
                          history.push(
                            `${props.location.pathname}${props.location.search}&tab=expenses`,
                          )
                          updateContractDetail(false)
                        }}
                      >
                        Expenses
                      </NavLink>
                    </NavItem>
                  )}
                {[
                  CONTRACT_TYPES.FIXED,
                  CONTRACT_TYPES.FULL_TIME,
                  CONTRACT_TYPES.DIRECT_EMPLOYEE,
                ].includes(data?.type) &&
                  [4, 7].includes(data?.status?.id) &&
                  (hasAccess(permissions.viewTimeOffs) ||
                    user?.type === userTypes.CONTRACTOR) && (
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: activeTab === '10',
                          'contract-link': true,
                        })}
                        onClick={() => {
                          setActiveTab('10')
                        }}
                      >
                        Time off
                      </NavLink>
                    </NavItem>
                  )}
                {[CONTRACT_TYPES.FULL_TIME].includes(data?.type) &&
                  data?.status?.id === 4 &&
                  user?.type === userTypes.CONTRACTOR && (
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: activeTab === '12',
                          'contract-link': true,
                        })}
                        onClick={() => {
                          setActiveTab('12')
                          updateContractDetail(false)
                        }}
                      >
                        Payslips
                      </NavLink>
                    </NavItem>
                  )}
                {data?.type !== CONTRACT_TYPES.FULL_TIME &&
                  data?.status?.id === 4 &&
                  data?.equipments > 0 && (
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          active: activeTab === '13',
                          'contract-link': true,
                        })}
                        onClick={() => {
                          setActiveTab('13')
                          updateContractDetail(false)
                        }}
                      >
                        Equipments
                      </NavLink>
                    </NavItem>
                  )}
                {showInsuranceAllowanceTab && (
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: activeTab === '14',
                        'contract-link': true,
                      })}
                      onClick={() => {
                        setActiveTab('14')
                      }}
                    >
                      Allowance
                    </NavLink>
                  </NavItem>
                )}
              </StyledNav>
            </Row>
          </Col>
        )}

        <TabContent activeTab={activeTab} className='p-0'>
          <TabPane tabId='5' className='p-0 m-0'>
            <ContractDetail
              history={props.history}
              id={id}
              data={data}
              updateContractDetail={updateContractDetail}
              loading={loading}
              onShowTerminatedModal={() => {
                setShowConfirmationModal(true)
              }}
              onShowEndedModal={() => {
                setEndedModal(true)
              }}
            />
          </TabPane>
          <TabPane tabId='9' className='p-0'>
            <Expenses contractLoading={loading} />
          </TabPane>
          <TabPane tabId='10' className='p-0'>
            {CONTRACT_TYPES.DIRECT_EMPLOYEE === data?.type ? (
              <DeTimeOffTab contractData={data} />
            ) : (
              <TimesOff />
            )}
          </TabPane>
          {user?.type === userTypes.COMPANY &&
            hasAccess(permissions.manageContractSettings) && (
              <TabPane tabId='11' className='p-0'>
                <Settings
                  onUpdate={updateContractDetail}
                  isVisible={activeTab === '11'}
                />
              </TabPane>
            )}
          {activeTab === '12' && (
            <TabPane tabId='12' className='p-0'>
              <Payslips />
            </TabPane>
          )}
          <TabPane tabId='6' className='p-0'>
            <PaymentHistory contract={data} contractLoading={loading} />
          </TabPane>
          <TabPane tabId='7' className='p-0'>
            <Documents
              docs={data.documents}
              isContract
              contractId={data?.id}
              loading={loading}
              onUpdate={updateContractDetail}
            />
          </TabPane>
          {data.type === CONTRACT_TYPES.PAY_AS_YOU_GO && (
            <TabPane tabId='8' className='p-0'>
              {submitWork && (
                <SubmitWorkModal
                  contract={data}
                  isOpen={submitWork}
                  toggle={() => setSubmitWork(false)}
                  onWorkSubmitted={updateContractDetail}
                />
              )}
              {data.works?.length > 0 ? (
                <Card
                  style={{
                    backgroundColor: '#FFFFFF',
                    boxShadow: '0px 1px 0px #DFE1E6',
                    borderRadius: 4,
                    overflow: 'visible',
                  }}
                  className='p-0 m-0'
                >
                  <div className='overflow-visible'>
                    <TableH
                      title='Work'
                      leftSide={
                        <WiseButton
                          minWidth='100%'
                          width='auto'
                          minHeight='48px'
                          bold
                          text='Add New Work'
                          onClick={() => {
                            if (terminationDate) {
                              setShowConfirmationModal(true)
                            } else if (endedDate) {
                              setEndedModal(true)
                            } else if (
                              user?.type === 'client' ||
                              data?.can_submit
                            ) {
                              setSubmitWork(true)
                            } else {
                              setNeedSign(true)
                            }
                          }}
                          disabled={
                            !(
                              user?.type === userTypes.CONTRACTOR ||
                              hasAccess(permissions.SubmitWorks) ||
                              data?.can_approve
                            ) ||
                            [5, 6, 7].includes(data?.status?.id) ||
                            data?.amended
                          }
                        />
                      }
                    />
                    {isMobile ? (
                      <div className='px-3'>
                        {data.works?.map((e, i) => (
                          <WorkCardMobile
                            key={`work:${i}`}
                            e={e}
                            updateContractDetail={updateContractDetail}
                            data={data}
                          />
                        ))}
                      </div>
                    ) : (
                      <div className='table-with-dropdown'>
                        <TableComp>
                          <thead className='thead-light'>
                            <tr>
                              <StyledTh>Date</StyledTh>
                              <StyledTh>Rate</StyledTh>
                              <StyledTh>Status</StyledTh>
                              <StyledTh></StyledTh>
                            </tr>
                          </thead>
                          <tbody>
                            {data.works?.map((e, i) => (
                              <WorkCard
                                key={`work:${i}`}
                                e={e}
                                updateContractDetail={updateContractDetail}
                                data={data}
                              />
                            ))}
                          </tbody>
                        </TableComp>
                      </div>
                    )}
                  </div>
                </Card>
              ) : (
                <NoContent
                  headline='No work submitted'
                  subtitle='All work submissions will be shown here and will be subject to approval by the client.'
                  image={noInvoicesImage}
                  withAction={
                    user?.type === userTypes.CONTRACTOR ||
                    hasAccess(permissions.SubmitWorks) ||
                    data?.can_approve ||
                    ([5, 6, 7].includes(data?.status?.id) && !data?.amended)
                  }
                  actionTitle='Submit Work'
                  onAction={() => {
                    if (terminationDate) {
                      setShowConfirmationModal(true)
                    } else if (endedDate) {
                      setEndedModal(true)
                    } else if (user?.type === 'client' || data?.can_submit) {
                      setSubmitWork(true)
                    } else {
                      setNeedSign(true)
                    }
                  }}
                  advanced={false}
                />
              )}
            </TabPane>
          )}
          {[CONTRACT_TYPES.FULL_TIME, CONTRACT_TYPES.MILESTONES].includes(
            data.type,
          ) && (
            <TabPane tabId='8' className='p-0 m-0'>
              {!addMilestone ? null : (
                <AddMilestoneModal
                  contract={data}
                  isOpen={addMilestone}
                  toggle={() => setAddMilestone(false)}
                  onMilestoneAdded={updateContractDetail}
                />
              )}

              <SubmitMilestoneModal
                isOpen={submitMilestone}
                toggle={() => setSubmitMilestone(false)}
                milestone={milestoneToSubmit}
                onMilestoneSubmitted={updateContractDetail}
                data={data}
              />
              {data?.milestones?.length > 0 ? (
                <Card
                  style={{
                    backgroundColor: '#FFFFFF',
                    boxShadow: '0px 1px 0px #DFE1E6',
                    borderRadius: 4,
                    overflow: 'visible',
                  }}
                  className='p-0 m-0'
                >
                  <div>
                    <TableH
                      title={
                        data?.type === CONTRACT_TYPES.FULL_TIME || userIsPartner
                          ? 'Invoices'
                          : CONTRACT_TYPES.MILESTONES
                      }
                      leftSide={
                        user?.type === userTypes.CONTRACTOR ||
                        (hasAccess(permissions.SubmitMilestones) &&
                          data?.can_approve &&
                          data?.type !== CONTRACT_TYPES.FULL_TIME) ||
                        [5, 6, 7].includes(data?.status?.id) ? (
                          userIsPartner ? (
                            <WiseButton
                              text='Add Invoice'
                              bold
                              minHeight='48px'
                              minWidth='100%'
                              onClick={() => {
                                setAddMilestone(true)
                              }}
                            />
                          ) : (
                            <Dropdown
                              isOpen={menu}
                              toggle={() => {
                                if (terminationDate) {
                                  setShowConfirmationModal(true)
                                } else if (endedDate) {
                                  setEndedModal(true)
                                } else {
                                  if (data?.type === CONTRACT_TYPES.FULL_TIME) {
                                    history.push({
                                      pathname: '/scan-invoice',
                                      state: { contractId: data?.id, ...data },
                                    })
                                  } else if (
                                    user?.type === userTypes.CONTRACTOR
                                  ) {
                                    setMenu(!menu)
                                  } else {
                                    setAddMilestone(true)
                                  }
                                }
                              }}
                            >
                              <DropdownToggle
                                className='btn h-100 m-0 p-0 header-item waves-effect text-primary font-weight-bold'
                                id='withdraw-method-dropdown'
                                tag='button'
                              >
                                <WiseButton
                                  text={
                                    data?.type === CONTRACT_TYPES.FULL_TIME
                                      ? 'Add Invoice'
                                      : 'Add milestone'
                                  }
                                  bold
                                  minHeight='48px'
                                  minWidth='100%'
                                />
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  tag='a'
                                  onClick={() => {
                                    setAddMilestone(true)
                                  }}
                                >
                                  Add it Manually
                                </DropdownItem>

                                <DropdownItem
                                  tag='a'
                                  onClick={() => {
                                    history.push({
                                      pathname: '/scan-invoice',
                                      state: { contractId: data?.id, ...data },
                                    })
                                  }}
                                >
                                  Upload Invoice
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          )
                        ) : null
                      }
                    />
                    {isMobile ? (
                      data?.milestones?.map((e, i) => {
                        return (
                          <MilestoneMobileCard
                            key={`milestone:${i}`}
                            e={e}
                            i={i}
                            onSubmit={() => {
                              if (user?.type === 'client' || data.can_submit) {
                                setMilestoneToSubmit(e)
                                setSubmitMilestone(true)
                              } else if (terminationDate) {
                                setShowConfirmationModal(true)
                              } else if (endedDate) {
                                setEndedModal(true)
                              } else {
                                setNeedSign(true)
                              }
                            }}
                            data={data}
                            updateContractDetail={updateContractDetail}
                          />
                        )
                      })
                    ) : (
                      <div className='table-with-dropdown'>
                        <TableComp>
                          <thead className='thead-light'>
                            <tr>
                              <StyledTh>Name</StyledTh>
                              <StyledTh>Amount</StyledTh>
                              <StyledTh className='px-2'>Status</StyledTh>
                              <StyledTh></StyledTh>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.milestones?.map((e, i) => {
                              return (
                                <MilestoneCard
                                  key={`milestone:${i}`}
                                  e={e}
                                  i={i}
                                  onSubmit={() => {
                                    if (
                                      user?.type === 'client' ||
                                      data.can_submit
                                    ) {
                                      setMilestoneToSubmit(e)
                                      setSubmitMilestone(true)
                                    } else if (terminationDate) {
                                      setShowConfirmationModal(true)
                                    } else if (endedDate) {
                                      setEndedModal(true)
                                    } else {
                                      setNeedSign(true)
                                    }
                                  }}
                                  data={data}
                                  updateContractDetail={updateContractDetail}
                                />
                              )
                            })}
                          </tbody>
                        </TableComp>
                      </div>
                    )}
                  </div>
                </Card>
              ) : (
                <NoContent
                  customBottom={
                    <Dropdown
                      isOpen={menu}
                      toggle={() => {
                        if (terminationDate) {
                          setShowConfirmationModal(true)
                        } else if (endedDate) {
                          setEndedModal(true)
                        } else {
                          if (data?.type === CONTRACT_TYPES.FULL_TIME) {
                            history.push({
                              pathname: '/scan-invoice',
                              state: { contractId: data?.id, ...data },
                            })
                          } else if (user?.type === userTypes.CONTRACTOR) {
                            setMenu(!menu)
                          } else {
                            setAddMilestone(true)
                          }
                        }
                      }}
                    >
                      <DropdownToggle
                        className='btn h-100 m-0 p-0    header-item waves-effect text-primary font-weight-bold'
                        id='withdraw-method-dropdown'
                        tag='button'
                      >
                        <WiseButton
                          disabled={
                            !(
                              user?.type === userTypes.CONTRACTOR ||
                              (hasAccess(permissions.SubmitMilestones) &&
                                data?.can_approve)
                            ) || [5, 6, 7].includes(data?.status?.id)
                          }
                          text={
                            data?.type === CONTRACT_TYPES.FULL_TIME
                              ? 'Add Invoice'
                              : 'Add milestone'
                          }
                          bold
                          minHeight='48px'
                          minWidth='100%'
                        />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          tag='a'
                          onClick={() => {
                            setAddMilestone(true)
                          }}
                        >
                          Add it Manually
                        </DropdownItem>

                        <DropdownItem
                          tag='a'
                          onClick={() => {
                            history.push({
                              pathname: '/scan-invoice',
                              state: { contractId: data?.id, ...data },
                            })
                          }}
                        >
                          Upload Invoice
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  }
                  headline={
                    data.type === CONTRACT_TYPES.FULL_TIME
                      ? 'No Invoice added'
                      : 'No Milestone added'
                  }
                  subtitle='All work submissions will be shown here and will be subject to approval by the client.'
                  image={
                    data.type === CONTRACT_TYPES.FULL_TIME
                      ? noInvoicesImage
                      : emptyMilestoneImage
                  }
                  withAction={
                    user?.type === userTypes.CONTRACTOR ||
                    (hasAccess(permissions.SubmitMilestones) &&
                      data?.can_approve &&
                      data?.type !== CONTRACT_TYPES.FULL_TIME) ||
                    [5, 6, 7].includes(data?.status?.id)
                  }
                  actionTitle={
                    data.type === CONTRACT_TYPES.FULL_TIME
                      ? 'Add Invoice'
                      : 'Add Milestone'
                  }
                  onAction={() => {
                    setAddMilestone(true)
                  }}
                  advanced={false}
                />
              )}
            </TabPane>
          )}
          {data?.type !== CONTRACT_TYPES.FULL_TIME &&
            data?.status?.id === 4 &&
            data?.equipments > 0 && (
              <TabPane tabId='13' className='p-0'>
                <Equipments
                  loadingData={getEquipmentList.isLoading || loading}
                  data={getEquipmentList?.data}
                  contractId={data?.id}
                  currency={data?.currency}
                  isAdmin
                  fetchEquipmentList={() =>
                    getEquipmentList.startFetch(
                      { contract_id: data?.id },
                      firstLoad,
                    )
                  }
                />
              </TabPane>
            )}
          {showInsuranceAllowanceTab ? (
            <TabPane tabId='14' className='p-0'>
              <Allowance
                contractUser={contractUser}
                userInfo={userInfo?.data}
                contract={data}
                showOnboardingModal={activeTab === '14'}
              ></Allowance>
            </TabPane>
          ) : null}
        </TabContent>
        <ConfirmationModal
          toggle={() => setShowConfirmationModal(false)}
          isOpen={showConfirmationModal}
          title='Contract Terminated'
          message="You can't perform this action, this contract is terminated"
          onConfirm={() => {
            setShowConfirmationModal(false)
          }}
        />
        <ConfirmationModal
          toggle={() => setEndedModal(false)}
          isOpen={showEndedModal}
          title='Contract Ended'
          message="You can't perform this action, this contract is ended"
          onConfirm={() => {
            setEndedModal(false)
          }}
        />
        <NeedSignModal
          isOpen={needSign}
          toggle={() => setNeedSign(false)}
          onSignNow={() => {
            setActiveTab('5')
            setNeedSign(false)
          }}
          clientSignature={clientSignature}
          contractorSignature={contractorSignature}
        />
      </Container>
    </div>
  )
}

const getPaymentStatusColor = (status) => {
  switch (status) {
    case 'Paid':
      return 'success'
    case 'Approved':
      return 'success'
    case 'Unpaid':
      return 'danger'
    case 'Declined':
      return 'danger'
    case 'Processing':
      return 'warning'
    case 'Pending approval':
      return 'warning'
    default:
      return 'primary'
  }
}

const WorkCardMobile = ({ e, updateContractDetail, data, isAdmin }) => {
  const user = useSelector((state) => state.Account?.user)
  const { hasAccess } = usePermissions()
  const declineWork = () => {
    setDeclineModal(true)
  }
  const approveWork = () => {
    approveSubmittedWork(user?.token, {
      work_id: e.id,
    }).then((r) => {
      if (r.data.success) {
        window.analytics.track('Approved work', {
          'contract-id': data.ref,
          'contract-type': data.type,
          'work-id': e?.id,
          'work-name': e?.name,
          'work-value': e?.qty,
          currency: data.currency?.code,
        })
        updateContractDetail()
      }
    })
  }
  const handleRemoveWorkClick = (e) => {
    deleteLine.startFetch({
      work_id: e?.id,
    })
  }
  const deleteLine = useFetch({
    action: deleteWork,
    onComplete: () => {
      updateContractDetail()
      window.analytics.track('Deleted work', {
        contract_id: data?.id,
        contract_type: data?.type,
        work_id: e?.id,
        work_name: e?.name,
        work_value: e?.value,
        currency: data?.currency?.code,
      })
    },
  })

  const [declineModal, setDeclineModal] = useState(false)

  return (
    <div
      className='mb-3'
      style={{
        background: '#FFFFFF',
        border: '1px solid #E7E8F2',
        borderRadius: 4,
      }}
    >
      <DeclineModal
        data={data}
        isOpen={declineModal}
        e={e}
        onDeclined={() => {
          updateContractDetail()
        }}
        toggle={() => setDeclineModal(false)}
      />
      <div className='d-flex justify-content-between border-bottom p-3 m-0 p-0'>
        <div>
          {user?.type === userTypes.COMPANY &&
          e.status?.name === 'Pending approval' ? null : (
            <BadgeX
              status={getPaymentStatusColor(
                e.payment_status === 'Unpaid'
                  ? e.status?.name
                  : e.payment_status,
              )}
              textStatus={getPaymentStatusColor(
                e.payment_status === 'Unpaid'
                  ? e.status?.name
                  : e.payment_status,
              )}
              name={
                e.payment_status === 'Unpaid'
                  ? e.status?.name
                  : e.payment_status
              }
            />
          )}
        </div>
        {e.status?.id === 2 && user?.type === 'client' && !isAdmin && (
          <>
            {e.created_by?.type === userTypes.COMPANY ? (
              <button
                className='text-primary btn btn-link font-weight-bold waves-effect waves-light py-0 px-0'
                onClick={approveWork}
                disabled={
                  !(hasAccess(permissions.ApproveWorks) || data?.can_approve)
                }
              >
                <i className='bx bx-check text-primary' /> Mark as approved
              </button>
            ) : (
              <Row className='px-3'>
                <WiseButton
                  bk='danger'
                  textColor='white'
                  text='Decline'
                  onClick={declineWork}
                  disabled={
                    !(hasAccess(permissions.DeclineWorks) || data?.can_approve)
                  }
                />
                <div className='mx-2' />
                <WiseButton
                  bk='success'
                  textColor='white'
                  text='Approve'
                  onClick={approveWork}
                  disabled={
                    !(hasAccess(permissions.ApproveWorks) || data?.can_approve)
                  }
                />
              </Row>
            )}
          </>
        )}
      </div>

      <div className='d-flex justify-content-between align-items-center pt-3 px-3'>
        <p className='text-dark font-size-14 mb-0'> Date</p>
        <div className='p-0 m-0  '>
          <StyledH5 style={{ fontFamily: 'inter' }} min='14px' max='14px'>
            {e.submitted_at}
            <StyledH5 style={{ fontFamily: 'inter' }} min='14px' max='14px'>
              {e.name}
            </StyledH5>
            {e.attributes?.map((a) => (
              <div key={`attr-${a?.name}`} className='py-1 font-size-13'>
                <span className='text-muted'>{a?.name}: </span>
                <span>{a?.value}</span>
                <br />
              </div>
            ))}
            <StyledH6 min='12px' max='12px' className='text-secondary mt-1'>
              by:{e.created_by?.first_name} {e.created_by?.last_name}
            </StyledH6>
          </StyledH5>
        </div>
      </div>

      <div className='d-flex justify-content-between align-items-center pt-3 px-3'>
        <p className='text-dark font-size-14 mb-0'>Rate</p>
        <p className='text-dark font-size-14 mb-0'>
          {e.qty} {getRate(data?.rate_id?.id, e.qty)}
        </p>
      </div>

      {e.can_delete && (
        <div className='d-flex justify-content-between align-items-center p-3'>
          <p className='text-dark font-size-14 mb-0'>Actions</p>
          {e.can_delete && (
            <a className='ml-3' onClick={() => handleRemoveWorkClick(e)}>
              {deleteLine.isLoading ? (
                <i className='bx bx-loader bx-spin text-danger font-size-18 align-middle mr-2' />
              ) : (
                <img src={trash} style={{ width: '1rem' }} alt='delete icon' />
              )}
            </a>
          )}
        </div>
      )}
    </div>
  )
}

const MilestoneMobileCard = ({
  e,
  i,
  updateContractDetail,
  onSubmit,
  isAdmin,
  data,
}) => {
  const user = useSelector((state) => state.Account?.user)
  const [showImg, setShowImg] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const { hasAccess } = usePermissions()
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: data?.currency?.code,
  })
  const declineMilestone = () => {
    setDeclineModal(true)
  }
  const approveMilestone = () => {
    approveSubmittedMilestone(user?.token, {
      work_id: e.id,
    })
      .then((r) => {
        if (r.data.success) {
          updateContractDetail()
        }
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.warn(e)
      })
  }
  const handleRemoveWorkClick = (e) => {
    deleteLine.startFetch({
      work_id: e?.id,
    })
  }
  const deleteLine = useFetch({
    action: deleteWork,
    onComplete: () => {
      updateContractDetail()
      window.analytics.track('Deleted work', {
        contract_id: data?.id,
        contract_type: data?.type,
        work_id: e?.id,
        work_name: e?.name,
        work_value: e?.value,
        currency: data?.currency?.code,
      })
    },
  })
  const [declineModal, setDeclineModal] = useState(false)

  return (
    <div
      className='mb-3 mx-3'
      style={{
        background: '#FFFFFF',
        border: '1px solid #E7E8F2',
        borderRadius: 4,
      }}
    >
      <DeclineModal
        isOpen={declineModal}
        toggle={() => setDeclineModal(false)}
        onDeclined={updateContractDetail}
        isMilestone
        e={e}
      />
      <div className='d-flex justify-content-between  border-bottom p-3 m-0 p-0'>
        <div className='p-0 m-0 d-flex'>
          {e.status?.name !== 'Request approval' &&
            !(user?.type === 'client' && e.status.id === 2) && (
              <div className='mx-2'>
                <BadgeX
                  name={
                    e.payment_status === 'Unpaid'
                      ? e.status?.name
                      : e.payment_status
                  }
                  status={getPaymentStatusColor(
                    e.payment_status === 'Unpaid'
                      ? e.status?.name
                      : e.payment_status,
                  )}
                  textStatus={
                    e.payment_status === 'Unpaid'
                      ? e.status?.name
                      : e.payment_status
                  }
                />
              </div>
            )}
          {!isAdmin && user?.type === 'client' && e.status.id === 2 && (
            <Row className='mx-0'>
              <button
                className='btn btn-danger mx-2 py-1 font-size-11'
                onClick={declineMilestone}
                disabled={
                  !(
                    hasAccess(permissions.DeclineMilestones) ||
                    data?.can_approve
                  )
                }
              >
                Decline
              </button>
              <button
                className='btn btn-success py-1 font-size-11'
                onClick={approveMilestone}
                disabled={
                  !(
                    hasAccess(permissions.ApproveMilestones) ||
                    data?.can_approve
                  )
                }
              >
                Approve
              </button>
            </Row>
          )}
          {user?.type === 'client' && !isAdmin && e.status?.id === 1 && (
            <button
              style={{ fontFamily: 'inter' }}
              className='text-primary font-size-14 btn btn-link   waves-effect waves-light pr-0 p-0 m-0'
              onClick={approveMilestone}
              disabled={
                !(hasAccess(permissions.ApproveMilestones) || data?.can_approve)
              }
            >
              <i className='bx bx-check text-primary ' /> Mark as completed
            </button>
          )}
          {user?.type !== 'client' && !isAdmin && e.status?.id === 1 && (
            <button
              className='text-primary btn btn-link   waves-effect waves-light pr-0 p-0 m-0'
              onClick={onSubmit}
              style={{ fontFamily: 'inter' }}
            >
              Submit
            </button>
          )}
        </div>
      </div>

      <div className='d-flex justify-content-between align-items-center pt-3 px-3'>
        <p className='text-dark font-size-14 mb-0'> Name</p>
        <div className='p-0 m-0  '>
          <StyledH5
            style={{ fontFamily: 'inter', textAlign: 'right' }}
            min='14px'
            max='14px'
          >
            {e.details}
          </StyledH5>
          <StyledH6 min='10px' max='12px' className='text-secondary'>
            Added by {e.created_by?.first_name} {e.created_by?.last_name} on{' '}
            {e.submitted_at}
          </StyledH6>
        </div>
      </div>

      <div className='d-flex justify-content-between align-items-center pt-3 px-3 mb-3'>
        <p className='text-dark font-size-14 mb-0'>Amount</p>
        <StyledH5
          style={{ fontFamily: 'inter', textAlign: 'right' }}
          min='14px'
          max='14px'
          className='mb-0'
        >
          {formatter.format(e.amount)}
        </StyledH5>
      </div>

      {e.can_delete && (
        <div className='d-flex justify-content-between align-items-center p-3'>
          <p className='text-dark font-size-14 mb-0'>Actions</p>
          <Row className='p-0 m-0'>
            {e.can_delete && (
              <a
                className='mx-2 pt-1 pb-2'
                onClick={() => setShowConfirmation(true)}
              >
                {deleteLine.isLoading ? (
                  <i className='bx bx-loader bx-spin text-danger font-size-14' />
                ) : (
                  <img
                    src={trash}
                    style={{ width: '16px' }}
                    alt='delete icon'
                  />
                )}
              </a>
            )}
            {!!e?.attachement?.pdf && (
              <a
                className='text-primary align-items-center py-1'
                onClick={() => setShowImg(true)}
              >
                <i />
                <img
                  src={viewIcon}
                  alt='archive icon'
                  style={{ width: 16, marginRight: 8 }}
                />
              </a>
            )}
          </Row>
        </div>
      )}
      <ImageModal
        title='Invoice preview'
        show={showImg}
        hide={() => setShowImg(false)}
        photo={`${storage}${e?.attachement?.thumbnail}`}
        downloadLink={`${storage}${e?.attachement?.pdf}`}
      />
      <ConfirmationModal
        title={`Remove Milestone ${i + 1}`}
        message='Are you sure you want to remove this milestone'
        caption='Remove'
        buttonColor='danger'
        onConfirm={() => {
          handleRemoveWorkClick(e)
          setShowConfirmation(false)
        }}
        isOpen={showConfirmation}
        toggle={() => {
          setShowConfirmation(false)
        }}
      />
    </div>
  )
}

export const WorkCard = ({ e, updateContractDetail, data, isAdmin }) => {
  const user = useSelector((state) => state.Account?.user)
  const { hasAccess } = usePermissions()
  const declineWork = () => {
    setDeclineModal(true)
  }
  const approveWork = () => {
    approveSubmittedWork(user?.token, {
      work_id: e.id,
    }).then((r) => {
      if (r.data.success) {
        window.analytics.track('Approved work', {
          'contract-id': data.ref,
          'contract-type': data.type,
          'work-id': e?.id,
          'work-name': e?.name,
          'work-value': e?.qty,
          currency: data.currency?.code,
        })
        updateContractDetail()
      }
    })
  }
  const handleRemoveWorkClick = (e) => {
    deleteLine.startFetch({
      work_id: e?.id,
    })
  }
  const handleDownloadWorkClick = (e) => {
    download.startFetch({
      work_id: e?.id,
    })
  }
  const download = useFetch({
    action: downloadWork,
    onComplete: (data) => {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `work-${e?.id}.pdf`) // or any other extension
      document.body.appendChild(link)
      link.click()
    },
  })

  const deleteLine = useFetch({
    action: deleteWork,
    onComplete: () => {
      updateContractDetail()
      window.analytics.track('Deleted work', {
        contract_id: data?.id,
        contract_type: data?.type,
        work_id: e?.id,
        work_name: e?.name,
        work_value: e?.value,
        currency: data?.currency?.code,
      })
    },
  })

  const [declineModal, setDeclineModal] = useState(false)
  const [showActions, setShowActions] = useState(false)
  const actions = []
  if (e.can_delete) {
    actions[0] = {
      onClick: () => handleRemoveWorkClick(e),
      // eslint-disable-next-line react/display-name
      component: () => <p className='m-0 p-0'>Delete</p>,
    }
  }
  if (e.file) {
    actions[1] = {
      onClick: () => handleDownloadWorkClick(e),
      // eslint-disable-next-line react/display-name
      component: () => <p className='m-0 p-0'>Download</p>,
    }
  }

  return (
    <>
      <DeclineModal
        data={data}
        isOpen={declineModal}
        e={e}
        onDeclined={() => {
          updateContractDetail()
        }}
        toggle={() => setDeclineModal(false)}
      />
      <tr>
        <td className='py-3 px-4' style={{ whiteSpace: 'normal' }}>
          <Col xs={12} className='p-0 m-0  '>
            <StyledH5 style={{ fontFamily: 'inter' }} min='14px' max='14px'>
              {e.name}
            </StyledH5>
            {e.attributes?.map((a) => (
              <div key={`attr-${a?.name}`} className='py-1 font-size-13 d-flex'>
                <span className='text-muted'>{a?.name}: </span>
                <span style={{ marginLeft: '2px' }}> {a?.value}</span>
                <br />
              </div>
            ))}
            <StyledH6 min='12px' max='12px' className='text-secondary mt-1'>
              Submitted on {moment(e.submitted_at).format('DD/MM/YYYY')} by{' '}
              {e.created_by?.first_name} {e.created_by?.last_name}
            </StyledH6>
            {e.status.id === 4 && e?.reason && (
              <StyledH6 min='12px' max='12px' className='text-secondary mt-1'>
                Reason for decline: {e?.reason}
              </StyledH6>
            )}
          </Col>
        </td>
        <td className='py-3 px-4'>
          <StyledH5
            style={{ fontFamily: 'inter' }}
            min='14px'
            max='14px'
            className='mb-0'
          >
            {e.qty} {getRate(data?.rate_id?.id, e.qty)}
          </StyledH5>
        </td>
        <td className='py-3 px-3' style={{ minWidth: '200px' }}>
          <Col xs={12} className='p-0 m-0'>
            <Row className='p-0 m-0'>
              <div className='mx-2'>
                {user?.type === userTypes.COMPANY &&
                e.status?.name === 'Pending approval' ? null : (
                  <BadgeX
                    status={getPaymentStatusColor(
                      e.payment_status === 'Unpaid'
                        ? e.status?.name
                        : e.payment_status,
                    )}
                    textStatus={getPaymentStatusColor(
                      e.payment_status === 'Unpaid'
                        ? e.status?.name
                        : e.payment_status,
                    )}
                    name={
                      e.payment_status === 'Unpaid'
                        ? e.status?.name
                        : e.payment_status
                    }
                  />
                )}
              </div>
              {e.status?.id === 2 && user?.type === 'client' && !isAdmin && (
                <>
                  {e.created_by?.type === userTypes.COMPANY ? (
                    <button
                      className='text-primary btn btn-link font-weight-bold waves-effect waves-light p-0'
                      onClick={approveWork}
                      disabled={
                        !(
                          hasAccess(permissions.ApproveWorks) ||
                          data?.can_approve
                        )
                      }
                    >
                      <i className='bx bx-check text-primary' /> Mark as
                      approved
                    </button>
                  ) : (
                    <Row className='m-0 p-0'>
                      <button
                        className='btn btn-danger mr-2 py-1 font-size-11'
                        onClick={declineWork}
                        disabled={
                          !(
                            hasAccess(permissions.DeclineWorks) ||
                            data?.can_approve
                          )
                        }
                      >
                        Decline
                      </button>
                      <button
                        className='btn btn-success py-1 font-size-11'
                        onClick={approveWork}
                        disabled={
                          !(
                            hasAccess(permissions.ApproveWorks) ||
                            data?.can_approve
                          )
                        }
                      >
                        Approve
                      </button>
                    </Row>
                  )}
                </>
              )}
            </Row>
          </Col>
        </td>
        <td className='py-3 px-4 text-center position-relative'>
          {!!actions?.length && (
            <ActionsDropdown
              options={actions}
              isOpen={showActions}
              toggle={() => setShowActions((t) => !t)}
            />
          )}
        </td>
      </tr>
    </>
  )
}

export const MilestoneCard = ({
  e,
  i,
  updateContractDetail,
  onSubmit,
  isAdmin,
  data,
}) => {
  const user = useSelector((state) => state.Account?.user)
  const [showImg, setShowImg] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const { hasAccess } = usePermissions()
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: data?.currency?.code,
  })
  const declineMilestone = () => {
    setDeclineModal(true)
  }
  const approveMilestone = () => {
    approveSubmittedMilestone(user?.token, {
      work_id: e.id,
    })
      .then((r) => {
        if (r.data.success) {
          updateContractDetail()
        }
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.warn(e)
      })
  }
  const handleRemoveWorkClick = (e) => {
    deleteLine.startFetch({ work_id: e?.id })
  }
  const handleDownloadWorkClick = (e) => {
    downloadLine.startFetch({ work_id: e?.id })
  }
  const deleteLine = useFetch({
    action: deleteWork,
    onComplete: () => {
      updateContractDetail()
      window.analytics.track('Deleted work', {
        contract_id: data?.id,
        contract_type: data?.type,
        work_id: e?.id,
        work_name: e?.name,
        work_value: e?.value,
        currency: data?.currency?.code,
      })
    },
  })
  const downloadLine = useFetch({
    action: downloadWork,
    onComplete: (data) => {
      const url = window.URL.createObjectURL(new Blob([data]))
      downloadFile(url, `work-${e?.id}.pdf`)
    },
  })

  const downloadFile = (url, name) => {
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', name) // or any other extension
    document.body.appendChild(link)
    link.click()
  }
  const [declineModal, setDeclineModal] = useState(false)
  const [showActions, setShowActions] = useState(false)
  const actions = []

  if (e.can_delete) {
    actions[0] = {
      onClick: () => setShowConfirmation(true),
      component: () => (
        <Row className='p-0 m-0 align-items-center'>
          <p className='m-0'>Delete</p>
        </Row>
      ),
    }
  }
  if (e.file) {
    actions[1] = {
      onClick: () => handleDownloadWorkClick(e),
      // eslint-disable-next-line react/display-name
      component: () => (
        <Row className='p-0 m-0 align-items-center'>
          <p className='m-0'>Download</p>
        </Row>
      ),
    }
  }
  if (e?.attachement?.pdf) {
    actions[2] = {
      onClick: () => openFileV2(e.attachement.pdf, { isDataUrl: true }),
      // eslint-disable-next-line react/display-name
      component: () => (
        <Row className='p-0 m-0 align-items-center'>
          <p className='m-0'>Download</p>
        </Row>
      ),
    }
  }

  return (
    <>
      <DeclineModal
        isOpen={declineModal}
        toggle={() => setDeclineModal(false)}
        onDeclined={updateContractDetail}
        isMilestone
        e={e}
      />
      <tr>
        <td className='py-3 px-4'>
          <Col xs={12} className='p-0 m-0'>
            <StyledH5
              style={{ fontFamily: 'inter', whiteSpace: 'pre-line' }}
              min='14px'
              max='14px'
            >
              {e.details}
            </StyledH5>
            <StyledH6 min='10px' max='12px' className='text-secondary'>
              Added by {e.created_by?.first_name} {e.created_by?.last_name} on{' '}
              {e.submitted_at}
            </StyledH6>
            {e?.status?.id === 4 && e?.reason && (
              <StyledH6 min='10px' max='12px' className='text-secondary'>
                Reason for decline: {e?.reason}
              </StyledH6>
            )}
          </Col>
        </td>
        <td className='py-3 px-4'>
          <StyledH5
            style={{ fontFamily: 'inter', minWidth: 110 }}
            min='14px'
            max='14px'
            className='mb-0'
          >
            {formatter.format(e.amount)}
          </StyledH5>
        </td>
        <td className='py-3 px-4'>
          <div className='p-0 m-0 d-flex'>
            {e.status?.name !== 'Request approval' &&
              !(user?.type === 'client' && e.status.id === 2) && (
                <div className='mx-2'>
                  <BadgeX
                    name={
                      e.payment_status === 'Unpaid'
                        ? e.status?.name
                        : e.payment_status
                    }
                    status={getPaymentStatusColor(
                      e.payment_status === 'Unpaid'
                        ? e.status?.name
                        : e.payment_status,
                    )}
                    textStatus={
                      e.payment_status === 'Unpaid'
                        ? e.status?.name
                        : e.payment_status
                    }
                  />
                </div>
              )}
            {!isAdmin && user?.type === 'client' && e.status.id === 2 && (
              <Row className='mx-0'>
                <button
                  className='btn btn-danger mx-2 py-1 font-size-11'
                  onClick={declineMilestone}
                  disabled={
                    !(
                      hasAccess(permissions.DeclineMilestones) ||
                      data?.can_approve
                    )
                  }
                >
                  Decline
                </button>
                <button
                  className='btn btn-success py-1 font-size-11'
                  onClick={approveMilestone}
                  disabled={
                    !(
                      hasAccess(permissions.ApproveMilestones) ||
                      data?.can_approve
                    )
                  }
                >
                  Approve
                </button>
              </Row>
            )}
            {user?.type === 'client' && !isAdmin && e.status?.id === 1 && (
              <button
                style={{ fontFamily: 'inter' }}
                className='text-primary font-size-14 btn btn-link   waves-effect waves-light pr-0 p-0 m-0'
                onClick={approveMilestone}
                disabled={
                  !(
                    hasAccess(permissions.ApproveMilestones) ||
                    data?.can_approve
                  )
                }
              >
                <i className='bx bx-check text-primary ' /> Mark as completed
              </button>
            )}
            {user?.type !== 'client' && !isAdmin && e.status?.id === 1 && (
              <button
                className='text-primary btn btn-link   waves-effect waves-light pr-0 p-0 m-0'
                onClick={onSubmit}
                style={{ fontFamily: 'inter' }}
              >
                Submit
              </button>
            )}
          </div>
        </td>
        <td className='position-relative text-center'>
          {!!actions?.length && (
            <ActionsDropdown
              toggle={() => setShowActions((t) => !t)}
              isOpen={showActions}
              options={actions}
            />
          )}
        </td>
      </tr>
      <ImageModal
        title='Invoice preview'
        show={showImg}
        hide={() => setShowImg(false)}
        photo={`${e.type === 'deposit' ? '' : storage}${
          e?.attachement?.thumbnail
        }`}
        downloadLink={`${e.type === 'deposit' ? '' : storage}${
          e?.attachement?.pdf
        }`}
      />
      <ConfirmationModal
        title={`Remove Milestone ${i + 1}`}
        message='Are you sure you want to remove this milestone'
        caption='Remove'
        buttonColor='danger'
        onConfirm={() => {
          handleRemoveWorkClick(e)
          setShowConfirmation(false)
        }}
        isOpen={showConfirmation}
        toggle={() => {
          setShowConfirmation(false)
        }}
      />
    </>
  )
}

export const StyledNav = styled(Nav)`
  flex-wrap: nowrap !important;
  overflow-x: auto;
  overflow-y: hidden;

  .nav-item {
    margin: 0 42px 0 0;

    .nav-link {
      white-space: nowrap;
      padding: 16px 0;

      font-weight: 400;
      font-size: 14px;

      &::after {
        height: 3px;
        background-color: var(--primary);
      }
    }

    .active {
      color: var(--primary) !important;
      font-weight: 600;
      background-color: #f4f5f7;
    }
  }

  @media (max-width: 768px) {
    .nav-item {
      margin: 0 24px 0 0 !important;
    }
  }
`

export default ContractPage
