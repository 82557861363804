import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader as ModalHeaderRS,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap'
import toastr from 'toastr'

import CustomSelect from '../../components/Forms/CustomSelect/CustomSelect'
import ModalHeader from '../../components/ModalHeader'
import Steps from '../../components/Steps'
import StepContainer from '../../components/Steps/StepContainer'
import Button from '../../components/ui/button'
import Loader from '../../components/ui/loader'
import Wootric from '../../components/Wootric/Wootric'
import {
  ACH,
  BANK_TRANSFER,
  BREX,
  CREDIT_CARD,
  SEPA_DEBIT,
} from '../../core/config/payment-methods'
import { useFetch, useResize } from '../../helpers/hooks'
import {
  confirmStripe,
  createBrexTransfer,
  createTransactionACH,
  createTransactionCC,
  createTransactionSepa,
  createTransactionTransfer,
  downloadReceipt,
  getMethods,
  getPayInPaymentIds,
  getPaymentMethods,
  getPlaidToken,
  getTransactionRef,
  savePayInPaymentIds,
} from '../../services/api'
import {
  endConfirmation,
  startConfirmation,
  updateConfirmationStatus,
  updateToPayList,
} from '../../store/payment/actions'
import { tag } from '../../utils/analytics'
import { EVENTS } from '../../utils/analytics/events'
import { mapCountryToOption } from '../../utils/map-to-option'
import ConfirmationPage from './ConfirmationPage'
import CreditCardsForSteps from './creditCardsForSteps'
import PaymentReview from './PaymentReview'

export function getPaymentIds(selectedList) {
  if (
    !selectedList ||
    !Array.isArray(selectedList) ||
    selectedList.length === 0
  ) {
    return []
  }

  const allPayments = selectedList
    ? [].concat(
        ...(selectedList?.map((e) =>
          e?.payments ? [...(e?.payments ?? [])] : [],
        ) ?? []),
      )
    : []

  if (allPayments.length > 0) {
    return [].concat(
      ...(allPayments?.map((e) => [
        ...(e?.works?.map((w) => w?.payment_item_id) ?? []),
      ]) ?? []),
    )
  } else if (selectedList) {
    return [].concat(
      ...(selectedList?.map((e) => [
        ...(e?.works?.map((w) => w?.payment_item_id) ?? []),
      ]) ?? []),
    )
  }

  return []
}

const PayInvoices = ({ history, match, location }) => {
  const searchParams = new URLSearchParams(location.search)
  const isConfirmation = searchParams.get('confirmation')
  const quoteId = searchParams.get('quote_id')
  const paymentIntent = searchParams.get('payment_intent')
  const paymentIntentClientSecret = searchParams.get(
    'payment_intent_client_secret',
  )
  const state = searchParams.get('state')

  const [activeTab, setActiveTab] = useState(isConfirmation ? 2 : 0)
  const [method, setMethod] = useState(null)
  const [selectedCard, setSelectedCard] = useState(null)
  const [loading, setLoading] = useState(false)
  const [trxData, setTrxData] = useState(null)

  const dispatch = useDispatch()

  const user = useSelector((state) => state.Account?.user)
  const userProfile = useSelector((state) => state.userProfile?.userProfile)
  const paymentState = useSelector((state) => state.Payment)
  const confirming = useSelector((state) => state?.Payment?.confirming)
  const hasPreferredBank = useSelector(
    (state) => state.userProfile?.userProfile?.company?.has_preferred_bank,
  )

  // The payment ids are stored in the redux store.
  // But, if the user tries to use Brex, we save the ids in the savePayInPaymentIds endpoint
  // and then we get them with getPayInPaymentIds.
  // everything is saved in the state paymentIds
  const statePaymentIds = useSelector((state) => state?.Payment?.toPay)
  const [paymentIds, setPaymentIds] = useState(statePaymentIds)

  if (
    (!statePaymentIds || statePaymentIds?.length <= 0) &&
    !isConfirmation &&
    !state
  ) {
    history.push('payment')
  }

  const { isLoading: gettingPaymentIds } = useFetch({
    action: getPayInPaymentIds,
    autoFetch: !!state,
    body: { key: state },
    onError: () => {
      history.push('payment')
    },
    onComplete: (data) => {
      const foundIds = data?.values?.split(',') ?? statePaymentIds

      if ((!foundIds || foundIds?.length <= 0) && !isConfirmation) {
        history.push('payment')
      }

      setPaymentIds(foundIds)
      paymentMethods.startFetch({ payment_item_ids: foundIds.map(Number) })
    },
  })

  const { startFetch: saveIds } = useFetch({ action: savePayInPaymentIds })
  function savePaymentIds(url) {
    const searchString = url?.split('?')?.[1]
    const searchParams = new URLSearchParams(searchString)
    const state = searchParams.get('state')

    saveIds({ key: state, values: paymentIds.join(',') })
  }

  const [bankTransferCountrySelection, setBankTransferCountrySelection] =
    useState(false)

  const paymentMethods = useFetch({
    autoFetch: !isConfirmation && !state,
    initResult: [],
    action: getMethods,
    body: { payment_item_ids: (paymentIds ?? [])?.map(Number) },
    onError: () => {
      toastr.error('An error occurred')
    },
  })

  const prepareTRX = useFetch({
    autoFetch: false,
    initResult: null,
    action: getTransactionRef,
  })

  const isMobile = useResize()

  const createAchTrx = useFetch({
    action: createTransactionACH,
    onComplete: (data) => {
      stopLoadingAndTagEvent()
      setTrxData(data)
      onTransactionCreated(data?.ref)
    },
    onError: () => {
      setLoading(false)
    },
  })

  useEffect(() => {
    if (
      isConfirmation &&
      !paymentState?.confirming &&
      !paymentState?.confirmed
    ) {
      dispatch(startConfirmation())
      setLoading(true)
      confirmStripe(user?.token, {
        payment_intent: paymentIntent,
        payment_intent_client_secret: paymentIntentClientSecret,
        quote_id: quoteId,
      })
        .then((r) => {
          dispatch(endConfirmation())
          if (r.data.success) {
            if (r.data.data.status === 'succeeded') {
              setTrxData(r.data?.data)
              dispatch(updateConfirmationStatus(true))
              window.analytics.track('Pay step 4 - Payment Confirmation', {
                pay_amount: paymentMethods?.data?.methods[0]?.total,
                pay_currency: paymentMethods?.data?.currency?.code,
                pay_method: method?.label,
                trx_id: r.data.data?.ref,
              })
            }
          } else {
            toastr.error(
              r.data.data?.error ||
                r.data?.error ||
                r.data?.message ||
                'An error occurred',
            )
          }
          setLoading(false)
        })
        .catch(() => {
          dispatch(endConfirmation())
          setLoading(false)
        })
    }
  }, [])

  const onTransactionCreated = (ref) => {
    setActiveTab(activeTab + 1)
    window.analytics.track('Pay step 4 - Payment Confirmation', {
      pay_amount: paymentMethods.data?.methods[0]?.total,
      pay_currency: paymentMethods.data?.currency?.code,
      method: method?.label,
      transaction_id: ref,
    })
  }

  const { startFetch: brexTransfer } = useFetch({
    action: createBrexTransfer,
    onError: () => {
      setLoading(false)
    },
    onComplete: (data) => {
      stopLoadingAndTagEvent()
      setTrxData(data)
      onTransactionCreated(data?.ref)
    },
  })

  function stopLoadingAndTagEvent() {
    setLoading(false)

    const eventData = {
      date: new Date().toISOString(),
      email: userProfile.email,
      utm_source: user?.campaign_data?.source_data?.utm_source ?? '',
    }
    tag(EVENTS.TRANSACTION_COMPLETED, eventData)
  }

  const processPayment = () => {
    setLoading(true)
    if ([CREDIT_CARD.id, SEPA_DEBIT.id].includes(method?.id)) {
      const createTrxFunc =
        method?.id === SEPA_DEBIT.id
          ? createTransactionSepa
          : method?.id === CREDIT_CARD.id
          ? createTransactionCC
          : () => {}

      createTrxFunc(user?.token, {
        quote_id: prepareTRX.data?.quote_id,
        payment_method_id: selectedCard?.id,
      })
        .then((r) => {
          if (r.data.success) {
            setTrxData(r.data.data)
            if (r.data.data?.url) {
              window.location.replace(r.data.data?.url)
            } else {
              onTransactionCreated(r.data.data?.ref)
            }
          } else {
            toastr.error(r.data.message)
          }
        })
        .catch(() => {
          toastr.error('An error occurred')
        })
        .finally(() => {
          stopLoadingAndTagEvent()
        })
    } else if (method?.id === ACH.id) {
      createAchTrx.startFetch({
        quote_id: prepareTRX.data?.quote_id,
        payment_method_id: selectedCard?.id,
      })
    } else if (method?.id === BREX.id) {
      brexTransfer({
        quoteId: prepareTRX.data?.quote_id?.toString(),
        userId: user?.id,
        companyId: userProfile?.company?.id,
      })
    } else {
      createTransactionTransfer(user?.token, {
        quote_id: prepareTRX.data?.quote_id,
      })
        .then((r) => {
          if (r.data.success) {
            setTrxData(r.data.data)
            onTransactionCreated(r.data.data?.ref)
          } else {
            toastr.error(r.data.message)
          }
        })
        .catch(() => {
          toastr.error('An error occurred')
        })
        .finally(() => {
          stopLoadingAndTagEvent()
        })
    }

    window.analytics.track('Pay step 3 - Review', {
      pay_amount: paymentMethods.data?.methods[0]?.total,
      pay_currency: paymentMethods.data?.currency?.code,
      pay_method: method?.label,
    })
  }

  const getToken = useFetch({
    action: getPlaidToken,
    autoFetch: true,
    onError() {
      toastr.error('Something went wrong')
    },
  })

  const accounts = useFetch({
    action: getPaymentMethods,
    autoFetch: true,
    initResult: [],
    onError() {
      toastr.error('Something went wrong')
    },
  })

  const handleMethodSelection = useCallback(
    (item) => {
      if (Array.isArray(paymentMethods.data?.methods)) {
        window.analytics.track('Pay step 1 - Method', {
          pay_amount: paymentMethods.data?.methods[0]?.total,
          pay_currency: paymentMethods.data?.currency?.code,
          pay_method: method?.name,
        })
        setMethod(item)

        // bank like methods (wise, mercury)
        const isBankTransferLike =
          item?.id === BANK_TRANSFER.id || !!item?.payment_account_id

        if (isBankTransferLike) {
          const body = {
            grouped: 1,
            payment_method_id: item?.id,
            payment_item_ids: paymentIds,
          }

          // bank like methods (wise, mercury)
          if (item?.payment_account_id) {
            body.payment_method_id = BANK_TRANSFER.id
            body.payment_account_id = item?.payment_account_id
          }

          // if the company has a has_preferred_bank and bank transfer is selected
          // we need to select country first
          if (item?.id === BANK_TRANSFER.id && !hasPreferredBank) {
            setBankTransferCountrySelection(true)
          } else {
            prepareTRX.startFetch(body)
            setActiveTab(activeTab + 1)
          }
        }
      }
    },
    [
      paymentIds,
      activeTab,
      method?.name,
      paymentMethods.data?.currency?.code,
      paymentMethods.data?.methods,
      prepareTRX,
      hasPreferredBank,
    ],
  )

  function handleNextBankTransfer(country) {
    const body = {
      grouped: 1,
      payment_method_id: method?.id,
      payment_item_ids: paymentIds,
      country_id: country?.id,
    }

    prepareTRX.startFetch(body)
    setActiveTab(activeTab + 1)
    setBankTransferCountrySelection(false)
  }

  const onDownload = () => {
    downloadReceipt(trxData?.ref, user?.token, trxData?.token).then((r) => {
      const url = window.URL.createObjectURL(new Blob([r?.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${trxData?.ref}.pdf`) // or any other extension
      document.body.appendChild(link)
      link.click()
    })
  }

  // bank like methods (wise, mercury)
  const isBankTransferLike =
    method?.id === BANK_TRANSFER.id || !!method?.payment_account_id

  if (gettingPaymentIds) {
    return (
      <div className='page-content min-vh-100 p-0 m-0'>
        <Container fluid>
          <ModalHeader />

          <Loader minHeight='max(60vh, 670px)' />
        </Container>
      </div>
    )
  }

  return (
    <div className='page-content min-vh-100 p-0 m-0'>
      <Container fluid>
        <ModalHeader action={() => history.replace('/payment')}>
          {!isMobile && (
            <Steps
              activeTab={activeTab}
              data={['Payment methods', 'Review', 'Pay']}
              noLastAction
            />
          )}
        </ModalHeader>

        <Row className='justify-content-center'>
          <Col sm={12} md={7} lg={7} xl={6}>
            <TabContent
              activeTab={activeTab}
              className='twitter-bs-wizard-tab-content'
            >
              <TabPane tabId={0}>
                <StepContainer
                  index={0}
                  title='Payment Methods'
                  total={3}
                  onNext={() => {
                    prepareTRX.startFetch({
                      grouped: 1,
                      payment_method_id: method?.id,
                      payment_item_ids: paymentIds,
                    })
                    setActiveTab(activeTab + 1)
                  }}
                  onBack={() => setActiveTab(activeTab - 1)}
                  nextText='Next'
                  disableNext={!method}
                >
                  <Container className='p-0 m-0' style={{ minHeight: '30vh' }}>
                    {getToken.data?.link_token && (
                      <>
                        <CreditCardsForSteps
                          onNext={(card) => {
                            setSelectedCard(card)
                            window.analytics.track('Pay step 2 - Account', {
                              pay_amount:
                                paymentMethods.data?.methods[0]?.total,
                              pay_currency: paymentMethods.data?.currency?.code,
                              pay_method: method?.name,
                              pay_account: card?.id,
                            })
                          }}
                          onAccountAdded={(account) => {
                            window.analytics.track(
                              'Pay step 2 - Added account',
                              {
                                pay_amount:
                                  paymentMethods.data?.methods[0]?.total,
                                pay_currency:
                                  paymentMethods.data?.currency?.code,
                                pay_method: method?.name,
                                pay_account: account?.id,
                              },
                            )

                            if (!account?.id) {
                              const pathname = history.location.pathname

                              dispatch(updateToPayList(paymentIds))
                              history.push(pathname)
                            }

                            accounts.startFetch(null, false)
                            setSelectedCard(account)
                          }}
                          type={method?.type}
                          plaidToken={getToken.data?.link_token}
                          onBrexAuthInitiated={(data) => {
                            savePaymentIds(data.url)
                          }}
                          cards={accounts.data}
                          cardsLoading={accounts.isLoading}
                          onMethodSelected={handleMethodSelection}
                          hasMethodSelected={!!method || !!selectedCard}
                          methods={paymentMethods.data}
                          loading={
                            paymentMethods.isLoading ||
                            getToken.isLoading ||
                            accounts.isLoading
                          }
                        />

                        <BankTransferCountryModal
                          isOpen={bankTransferCountrySelection}
                          toggle={() => setBankTransferCountrySelection(false)}
                          onConfirm={(country) => {
                            handleNextBankTransfer(country)
                          }}
                          onCancel={() =>
                            setBankTransferCountrySelection(false)
                          }
                        />
                      </>
                    )}
                  </Container>
                </StepContainer>
              </TabPane>
              <TabPane tabId={1}>
                <StepContainer
                  index={1}
                  total={3}
                  title={
                    isBankTransferLike ? 'Review and confirm' : 'Review & Pay'
                  }
                  onNext={processPayment}
                  onBack={() => {
                    setSelectedCard(null)
                    setMethod(null)
                    setActiveTab(activeTab - 1)
                  }}
                  nextText={
                    isBankTransferLike ? 'I’ll make the transfer' : 'Confirm'
                  }
                  loading={loading}
                  disableNext={prepareTRX.isLoading || prepareTRX.error}
                  isFlat
                >
                  <PaymentReview
                    onBack={() => {
                      setSelectedCard(null)
                      setMethod(null)
                      setActiveTab(activeTab - 1)
                    }}
                    data={prepareTRX.data}
                    dataError={prepareTRX.error}
                    advance={match.params.id}
                    loading={prepareTRX.isLoading}
                    card={selectedCard}
                    method={method}
                    bankInfo={isBankTransferLike}
                  />
                </StepContainer>
              </TabPane>
              <TabPane tabId={2}>
                {loading ? (
                  <Loader minHeight='30rem' />
                ) : (
                  <ConfirmationPage
                    onComplete={() => {
                      history.push('/transactions')
                    }}
                    method={method}
                    loading={loading || confirming}
                    confirmed={!isConfirmation || paymentState?.confirmed}
                    onDownload={trxData?.ref ? onDownload : null}
                  />
                )}
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>

      {activeTab === 3 && (!isConfirmation || paymentState.confirmed) && (
        <Wootric />
      )}
    </div>
  )
}

function BankTransferCountryModal({ isOpen, toggle, onConfirm, onCancel }) {
  const countries = useSelector((state) => state.Layout?.staticData?.countries)
  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company,
  )

  const [country, setCountry] = useState(() => {
    const country = countries?.find(
      (c) => c?.id === company?.transfer_country_id,
    )
    return mapCountryToOption(country ?? {}, 'id')
  })

  const countryOptions = countries?.map((c) => mapCountryToOption(c, 'id'))

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeaderRS toggle={toggle}>Select country</ModalHeaderRS>
      <ModalBody>
        <label>Select where you will be sending the funds from</label>
        <CustomSelect
          options={countryOptions}
          value={country}
          onChange={setCountry}
          placeholder='Select your country'
        />
      </ModalBody>
      <ModalFooter>
        <Button color='light' outline onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={() => onConfirm(country)}>Confirm</Button>
      </ModalFooter>
    </Modal>
  )
}

export default PayInvoices
