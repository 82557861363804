import cx from 'classnames'
import React from 'react'
import Dropzone from 'react-dropzone'
import styled from 'styled-components'

const StyledLogo = styled.div`
  width: 124px;
  height: 124px;

  img {
    width: 124px;
    height: 124px;
  }

  @media (max-width: 1280px) {
    width: 100px;
    height: 100px;
    img {
      width: 100px;
      height: 100px;
    }
  }
`

export default function ManageAvatar({
  src,
  name,
  onUploadPhoto,
  isUploading,
  avatarTitleClassName = 'avatar-title',
}) {
  return (
    <StyledLogo style={{ position: 'relative', margin: '40px 0 18px 0' }}>
      {src ? (
        <img src={src} alt='' className='rounded-circle' />
      ) : (
        <span
          className={cx(
            'rounded-circle font-weight-bolder',
            avatarTitleClassName,
          )}
          style={{ fontSize: 40, marginBottom: 6 }}
        >
          {name?.charAt(0)}
        </span>
      )}
      <div
        className='upload-btn'
        style={{
          position: 'absolute',
          width: 32,
          top: 0,
          right: 0,
        }}
      >
        <Dropzone onDrop={onUploadPhoto}>
          {({ getRootProps, getInputProps }) => (
            <div style={{ zIndex: 99999999999 }}>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className='avatar-xs mr-3'>
                  <span
                    className='avatar-title rounded-circle bg-primary text-white  border'
                    style={{
                      backgroundColor: 'ref',
                    }}
                  >
                    {isUploading ? (
                      <i className='bx bx-loader bx-spin font-size-16 align-middle    ' />
                    ) : (
                      <i className='bx bx-camera font-size-16 text-white'></i>
                    )}
                  </span>
                </div>
              </div>
            </div>
          )}
        </Dropzone>
      </div>
    </StyledLogo>
  )
}
