import { CalendarBlank, X } from '@phosphor-icons/react'
import cx from 'classnames'
import React, { useRef } from 'react'
import DatePicker from 'react-datepicker'

import InputFeedback from '../../ui/input-feedback'
import { getInputErrorMessage } from '../get-input-error-message'
import './datePicker.css'

const CustomDatePicker = (props) => {
  const {
    label,
    value,
    handleOnChange,
    placeholder,
    minDate,
    maxDate,
    showMonthYearPicker,
    disabled,
    excludeDates,
    hasError,
    datePickerClassName,
    wrapperClassName,
    inputClassName,
    name = 'pick-date',
    handleClear,
    clearable,
    error,
    ...otherProps
  } = props

  const ref = useRef(null)

  return (
    <div className={wrapperClassName}>
      {!!label && <label>{label}</label>}

      <div className='position-relative cursor-pointer'>
        <DatePicker
          ref={ref}
          name={name}
          className={cx('form-control', datePickerClassName)}
          selected={value ? new Date(value) : null}
          onChange={handleOnChange}
          style={{ width: '100%' }}
          disabled={disabled}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode='select'
          customInput={
            <CustomInput
              hasError={hasError}
              className={inputClassName}
              disabled={disabled}
            />
          }
          minDate={minDate}
          maxDate={maxDate}
          popperClassName='custom-popper'
          popperPlacement='bottom-start'
          popperModifiers={[
            {
              name: 'offset',
              enabled: true,
              options: {
                offset: [5, 10],
              },
            },
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                escapeWithReference: false,
                boundariesElement: 'viewport',
              },
            },
          ]}
          excludeDates={excludeDates}
          placeholderText={placeholder}
          showMonthYearPicker={showMonthYearPicker}
          clearable={clearable}
          {...otherProps}
        />

        <div
          className={cx('d-flex position-absolute', {
            'hover:bg-gray-100': clearable && value && handleClear,
          })}
          style={{ top: '50%', right: 12, transform: 'translateY(-50%)' }}
          onClick={() => {
            if (clearable && value) {
              handleClear?.()
            } else {
              ref.current.setOpen(true)
            }
          }}
        >
          {clearable && value ? (
            <X size={22} className='p-0.5' />
          ) : (
            <CalendarBlank size={18} />
          )}
        </div>
      </div>

      {!error ? null : (
        <InputFeedback className='mt-1'>
          {getInputErrorMessage(error)}
        </InputFeedback>
      )}
    </div>
  )
}

export const CustomInput = React.forwardRef(function CustomInput(
  { value, onClick, placeholder, hasError, className, disabled },
  ref,
) {
  return (
    <div
      ref={ref}
      className={cx(
        {
          'form-control': true,
          'border-danger': hasError,
          'bg-light': disabled,
        },
        className,
      )}
      onClick={onClick}
      tabIndex={disabled ? undefined : 0}
    >
      {value || placeholder}
    </div>
  )
})

export default CustomDatePicker
