import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Card, CardBody, Col, Media, Row } from 'reactstrap'
import { StyledH5, StyledH6, StyledP } from '../../../../components/Typo'

function createMarkup(text) {
  return { __html: text }
}

const FulltimeDetailBlocks = () => {
  const contract = useSelector((state) => state.Contract?.details)
  const salaryFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: contract?.salary_currency?.code || 'USD',
  })
  return (
    <>
      <StyledCard className='p-3 p-md-4 m-0 mb-3'>
        <CardBody className='p-0 m-0'>
          <StyledDiv>
            <Media>
              <Media className='overflow-hidden' body>
                <Col className='p-0 m-0'>
                  <Row className='justify-content-between align-items-start p-0 m-0'>
                    <StyledH6 className='text-dark font-size-16 mb-3 mb-md-4  font-weight-bold'>
                      Employee info
                    </StyledH6>
                  </Row>
                </Col>
              </Media>
            </Media>
            <Row>
              <Col md={4} sm={12}>
                <StyledH5 className='text-dark font-size-14'>
                  Nationality
                </StyledH5>
                <Row className='p-0 m-0'>
                  <StyledP className='text-secondary font-size-14'>
                    {contract?.employee?.country?.name}
                  </StyledP>
                  <img
                    src={
                      !!contract?.employee?.country?.iso2 &&
                      `/flags/${contract?.employee?.country.iso2?.toLowerCase()}.svg`
                    }
                    className='mx-1'
                    style={{
                      width: '1rem',
                      height: '1rem',
                      borderRadius: '0.5rem',
                      marginRight: 10,
                      objectFit: 'cover',
                    }}
                    alt={''}
                  />
                </Row>
              </Col>
              <Col md={4} sm={12}>
                <StyledH5 className='text-dark font-size-14'>
                  Country of employment
                </StyledH5>
                <Row className='p-0 m-0'>
                  <StyledP className='text-secondary font-size-14'>
                    {contract?.employee?.working_from_country?.name}
                  </StyledP>
                  <img
                    src={
                      !!contract?.employee?.working_from_country?.iso2 &&
                      `/flags/${contract?.employee?.working_from_country.iso2?.toLowerCase()}.svg`
                    }
                    className='mx-1'
                    style={{
                      width: '1rem',
                      height: '1rem',
                      borderRadius: '0.5rem',
                      marginRight: 10,
                      objectFit: 'cover',
                    }}
                    alt={''}
                  />
                </Row>
              </Col>
              <Col md={4} sm={12}>
                <StyledH5 className='text-dark font-size-14'>Visa</StyledH5>
                <p className='text-secondary font-size-14'>
                  {contract?.work_visa ? 'Yes' : 'No'}
                </p>
              </Col>
            </Row>
          </StyledDiv>
        </CardBody>
      </StyledCard>
      <StyledCard className='p-3 p-md-4 m-0 mb-3'>
        <CardBody className='p-0 m-0'>
          <StyledDiv>
            <Media>
              <Media className='overflow-hidden' body>
                <Row>
                  <Col md={12} sm={12}>
                    <Row className='justify-content-between align-items-start p-0 m-0'>
                      <StyledH6 className='text-dark font-size-16 mb-3 mb-md-4  font-weight-bold'>
                        Job info
                      </StyledH6>
                    </Row>
                  </Col>
                  <Col md={6} sm={12}>
                    <StyledH5 className='text-dark font-size-14 '>
                      Type of hire
                    </StyledH5>
                    <StyledP className='text-secondary font-size-14'>
                      {contract?.employment_type}
                    </StyledP>
                  </Col>
                  <Col sm={12} md={6}>
                    <StyledH5 className='text-dark font-size-14 '>
                      Job Title
                    </StyledH5>
                    <StyledP className='text-secondary font-size-14'>
                      {contract?.name}
                    </StyledP>
                  </Col>
                </Row>
              </Media>
            </Media>

            {!!contract?.scope && (
              <>
                <StyledH5 className='text-dark font-size-14'>
                  Job Description
                </StyledH5>
                <div
                  className='text-secondary font-size-14'
                  dangerouslySetInnerHTML={createMarkup(contract?.scope)}
                />
              </>
            )}
          </StyledDiv>
        </CardBody>
      </StyledCard>
      <StyledCard className='p-3 p-md-4 m-0 mb-3'>
        <CardBody className='p-0 m-0'>
          <StyledDiv>
            <Media>
              <Media className='overflow-hidden' body>
                <Col className='p-0 m-0'>
                  <Row className='justify-content-between align-items-start p-0 m-0'>
                    <StyledH6 className='text-dark font-size-16 mb-3 mb-md-4 font-weight-bold'>
                      Compensation
                    </StyledH6>
                  </Row>
                </Col>
              </Media>
            </Media>

            <Row>
              <Col md={4} sm={12}>
                <StyledH5 className='text-dark font-size-14 '>
                  Gross Yearly Salary
                </StyledH5>
                <p className='text-secondary font-size-14'>
                  {salaryFormatter.format(contract?.amount)}
                </p>
              </Col>
              <Col md={4} sm={12}>
                <StyledH5 className='text-dark font-size-14'>
                  Start Date
                </StyledH5>
                <div
                  className='text-secondary font-size-14'
                  dangerouslySetInnerHTML={createMarkup(contract?.start_date)}
                />
              </Col>
              <Col md={4} sm={12}>
                <StyledH5 className='text-dark font-size-14'>Type</StyledH5>
                <StyledP className='text-secondary font-size-14'>
                  {contract?.employment_term}
                </StyledP>
              </Col>
              {parseInt(contract?.bonus_amount) <= 0 ? null : (
                <>
                  <Col md={4} sm={12}>
                    <StyledH5 className='text-dark font-size-14'>
                      Bonus Amount
                    </StyledH5>
                    <StyledP className='text-secondary font-size-14'>
                      {salaryFormatter.format(contract?.bonus_amount)}
                    </StyledP>
                  </Col>
                  <Col md={8} sm={12}>
                    <StyledH5 className='text-dark font-size-14'>
                      Bonus Details
                    </StyledH5>
                    <StyledP className='text-secondary font-size-14'>
                      {contract?.bonus_details ?? 'N/A'}
                    </StyledP>
                  </Col>
                </>
              )}
            </Row>
          </StyledDiv>
        </CardBody>
      </StyledCard>
    </>
  )
}

const StyledDiv = styled.div`
  @media (max-width: 768px) {
    padding: 16px;
    background-color: #ffffff;
    box-shadow: 0px 1px 0px #dfe1e6;
    border-radius: 4px;
    margin-bottom: 16px;
  }
`
const StyledCard = styled(Card)`
  @media (max-width: 768px) {
    padding: 0 !important;
    background-color: #f4f5f7;
    box-shadow: none;
  }
`
export default FulltimeDetailBlocks
