import React from 'react'
import { slide as Menu } from 'react-burger-menu'

import { useResize } from '../../../helpers/hooks'
import triggerCsChat from '../../../utils/trigger-cs-chat'

export default function TransactionDetails({ show, selectedTrx, setShow }) {
  const isMobile = useResize()

  function isMenuOpen(state) {
    if (state.isOpen) {
      document.body.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
    }

    if (typeof setShow !== 'function') {
      return
    }
    return setShow(state.isOpen)
  }

  return (
    <div
      style={{ position: 'absolute', top: 0, bottom: 0, left: 0, zIndex: 1100 }}
    >
      <Menu
        className='bg-white py-4'
        isOpen={show}
        onStateChange={isMenuOpen}
        right
        width={isMobile ? '100%' : 'max(30%, 375px)'}
        customCrossIcon={false}
        styles={{
          bmMenu: { height: 'unset', overflow: 'unset' },
          bmMenuWrap: { overflow: 'auto' },
        }}
      >
        <div className='d-flex flex-wrap gap-4 justify-content-between px-3'>
          <span className='text-secondary font-size-16'>
            <span className='user-select-none'>#</span>
            {selectedTrx?.transaction_ref}
          </span>
          <button
            className='align-items-center d-flex justify-content-center p-0 rp-btn-nostyle'
            onClick={() => setShow(false)}
          >
            <i className='bx bx-x font-size-22' />
          </button>
        </div>

        <hr />

        {selectedTrx?.items?.map((item) => {
          return (
            <React.Fragment key={item?.title || item?.name}>
              <div
                className='d-flex flex-column px-3 py-2'
                style={{ gap: '1rem' }}
              >
                <h5
                  style={{ color: 'var(--slate-700)' }}
                  className='mb-1 font-size-16 rp-font-gilroyB'
                >
                  {item?.title || item?.name}
                </h5>
                {item?.data.map((sit) => {
                  return (
                    <div key={sit?.title || sit?.name}>
                      <h6 className='mb-1 text-secondary font-size-14 rp-font-normal'>
                        {sit?.title || sit?.name}
                      </h6>

                      {typeof sit?.value === 'string' &&
                      sit?.value?.startsWith('http') ? (
                        <a
                          className='font-size-16 rp-font-medium'
                          href={sit?.value}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {sit?.value}
                        </a>
                      ) : (
                        <p
                          className='font-size-16 mb-0 rp-font-medium rp-capitalize'
                          style={{ color: 'var(--slate-700)' }}
                        >
                          {sit?.value || 'N/A'}
                        </p>
                      )}
                    </div>
                  )
                })}
              </div>

              <hr />
            </React.Fragment>
          )
        })}

        <div className='px-3'>
          <h5
            style={{ color: 'var(--slate-700)' }}
            className='font-size-16 rp-font-gilroyB'
          >
            Notice a problem?
          </h5>
          <button
            className='align-items-center d-flex rp-btn-nostyle rp-font-bold rp-font-gilroyB text-primary'
            onClick={() => {
              triggerCsChat(
                `I would like to inquire about this transaction:\nID: ${selectedTrx?.transaction_ref} `,
              )
            }}
          >
            Dispute charge
          </button>
        </div>
      </Menu>
    </div>
  )
}
