import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap'
import { AvForm } from 'availity-reactstrap-validation'
import { updatePassword } from '../../services/api'
import { useSelector } from 'react-redux'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import styled from 'styled-components'
import TextInput from '../../components/Forms/TextInput'
import WiseButton from '../../components/WiseButton'
import { useFetch } from '../../helpers/hooks'
import { PasswordChecklistComp } from '../../components/CommonForBoth/ChangePassword'
import logoMain from './../../assets/images/identity/logo_main.png'

const UpdatePassword = (props) => {
  const email = useSelector((state) => state.ForgetPassword?.email)
  const token = new URLSearchParams(props.location.search).get('token')

  const [isValid, setIsValid] = useState('')
  const [password, setPassword] = useState('')
  console.log(email)
  const updatePass = useFetch({
    action: updatePassword,
    onComplete: () => {
      props.history.push('/login')
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  return (
    <React.Fragment>
      <div className='account-pages m-0 p-0 '>
        <StyledDiv className='d-flex justify-content-center align-items-center'>
          <img src={logoMain} height={24} />
        </StyledDiv>
        <Container className='p-0  mt-5'>
          <Row className='justify-content-center p-0 m-0'>
            <Col md={8} lg={6} xl={5} className={'p-3 p-md-0 m-0'}>
              <Card className='overflow-hidden p-0 m-0 '>
                <CardBody className='p-3 p-md-4 m-0 '>
                  <div>
                    {props.forgetError && props.forgetError ? (
                      <Alert color='danger' style={{ marginTop: '13px' }}>
                        {props.forgetError}
                      </Alert>
                    ) : null}
                    {props.forgetSuccessMsg ? (
                      <Alert color='success' style={{ marginTop: '13px' }}>
                        {props.forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <AvForm
                      className='form-horizontal '
                      onValidSubmit={(e, v) => {
                        if (isValid) {
                          updatePass.startFetch({ ...v, token })
                        } else {
                          toastr.error(
                            'Weak password easy to guess, password should respect checklist condition',
                          )
                        }
                      }}
                    >
                      <div className='form-group'>
                        <Row className='verificationCode m-0 justify-content-center   pt-3    pt-md-4'>
                          {/* <ReactInputVerificationCode */}
                          {/*  type='number' */}
                          {/*  length={4} */}
                          {/*  inputStyle={{ */}
                          {/*    padding: '12px', */}
                          {/*    margin: '12px' */}
                          {/*  }} */}
                          {/*  onChange={(e) => { */}
                          {/*    setCode(e) */}
                          {/*  }} */}
                          {/* /> */}
                        </Row>
                      </div>
                      <div className='form-group '>
                        <TextInput
                          name='password'
                          label='New Password'
                          placeholder='Enter new password'
                          type='password'
                          required
                          autocomplete='autocomplete_off_hack_xfr4!k'
                          id={'passWord'}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <PasswordChecklistComp
                          password={password}
                          TooltipId={'passWord'}
                          onChange={(v) => setIsValid(v)}
                        />
                      </div>
                      <Row className='form-group mb-0'>
                        <Col className='text-right'>
                          <WiseButton
                            loading={updatePass.isLoading}
                            width={'100%'}
                            minWidth={'100%'}
                            minHeight={'48px'}
                            height={'48px'}
                            text={'Reset'}
                            type='submit'
                          />
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className='my-3 my-md-4 text-center'>
                <div className='my-3 my-md-4 d-flex justify-content-center align-items-center'>
                  <Link
                    to='/login'
                    className='text-primary   mb-0 font-size-14 font-weight-medium'
                  >
                    Go back to the login page
                  </Link>
                </div>
                <p>© {new Date().getFullYear()} RemotePass. </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const StyledDiv = styled.div`
  height: 80px;
  background-color: #ffffff;
  box-shadow: 0px 0px 8px #eaeaea, inset 0px -1px 0px #eaeaea;
  @media (max-width: 768px) {
    height: 56px;
  }
`

export default UpdatePassword
