import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Col, Container, Label, Row } from 'reactstrap'
import FormGroup from 'reactstrap/es/FormGroup'
import toastr from 'toastr'

import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import { useFetch, useResize } from '../../../helpers/hooks'
import { RpTemplate, UploadFile, YourTemplate } from '../../../helpers/SvgIcons'
import { uploadContractFile } from '../../../services/api'

const ContractTypeSelect = ({
  onContractChanged,
  value,
  onFileUploaded,
  templates = [],
  onTemplateSelected = () => {},
  template,
  uploaded,
  onUploadStatusChanged = () => {},
}) => {
  const [selected, setSelected] = useState(value)
  const isMobile = useResize()

  const { acceptedFiles, getRootProps, getInputProps, inputRef } = useDropzone({
    acceptedFiles: 'application/pdf',
  })
  const files = acceptedFiles.map((file) => <p key={file.path}>{file.path}</p>)

  const templateOptions = templates?.map((t) => ({
    value: t?.id,
    label: t?.name,
  }))

  const options = [
    {
      title: 'Use RemotePass’s Template',
      description: "Use RemotePass's legally compliant contract template",
      icon: <RpTemplate />,
      activeIcon: <RpTemplate isActive />,
    },
    {
      title: 'Upload My Contract',
      description: 'Click here to upload your signed contract\n *PDF only',
      icon: <YourTemplate />,
      activeIcon: <YourTemplate isActive />,
    },
  ]
  if (templates?.length > 0) {
    options.push({
      title: 'Use your templates',
      description: 'Click here to select one of your existing templates',
      icon: <YourTemplate />,
      activeIcon: <YourTemplate isActive />,
    })
  }

  const upload = useFetch({
    action: uploadContractFile,
    onComplete: (data) => {
      onFileUploaded(data.path)
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  useEffect(() => {
    onUploadStatusChanged(upload.isLoading)
  }, [onUploadStatusChanged, upload.isLoading])

  const removeAll = useCallback(() => {
    if (inputRef && inputRef.current) {
      acceptedFiles.length = 0
      acceptedFiles.splice(0, acceptedFiles.length)
      inputRef.current.value = ''
    }
  }, [acceptedFiles, inputRef])

  useEffect(() => {
    let cancel
    if (!!acceptedFiles && acceptedFiles?.length > 0) {
      if (acceptedFiles[0].size > 2000000) {
        toastr.error('The file may not be greater than 2MB')
        removeAll()
      } else {
        upload.startFetch(
          { file: acceptedFiles[0] },
          true,
          new axios.CancelToken((c) => (cancel = c)),
        )
      }
    }
    return () => {
      if (cancel) cancel()
    }
  }, [acceptedFiles, removeAll])

  return (
    <Container fluid={true}>
      <Col className='p-0'>
        <Row className='p-0'>
          {options.map((e, i) => (
            <Col
              md={isMobile ? 12 : undefined}
              className={`pb-3 ${i !== 0 && !isMobile ? 'pl-2' : ''}`}
              key={e.title}
            >
              <a
                onClick={() => {
                  if (i === 0) {
                    removeAll()
                  }
                  setSelected(i)
                  onContractChanged(i)
                }}
              >
                <div
                  className={
                    i === selected
                      ? 'text-primary border p-3 m-0 border border-primary rounded'
                      : 'text-secondary border border-light p-3 m-0 rounded'
                  }
                  style={{
                    minHeight: options?.length > 2 ? '11rem' : '9.5rem',
                  }}
                >
                  <Col className='p-0'>
                    <div className='mb-4'>
                      {i === selected ? e.activeIcon : e.icon}
                    </div>
                    <div>
                      <Col className='p-0'>
                        <h5
                          className={
                            i === selected
                              ? 'my-0 text-primary mb-2 bold'
                              : 'my-0 mb-2 bold'
                          }
                          style={{
                            textJustify: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {e.title}
                        </h5>
                        <p
                          className={`font-size-12 mb-0 ${
                            i === selected ? 'text-primary' : 'text-secondary'
                          }`}
                        >
                          {e.description}
                        </p>
                      </Col>
                    </div>
                  </Col>
                </div>
              </a>
            </Col>
          ))}
        </Row>
      </Col>
      {selected === 1 && (
        <Row className='justify-content-center'>
          <Col>
            <section className='p-0'>
              <div className='dropzone border-primary'>
                <div
                  className='dz-message needsclick bg-soft-primary'
                  {...getRootProps()}
                  onClick={upload.error ? () => {} : getRootProps().onClick}
                >
                  <input {...getInputProps()} accept='application/pdf' />

                  {(!!acceptedFiles && acceptedFiles?.length > 0) ||
                  uploaded ? (
                    <Row className='font-size-14 justify-content-between px-3'>
                      {acceptedFiles?.length ? files : 'file uploaded'}
                      <div>
                        {!!upload.error && (
                          <a
                            className='text-danger mx-2'
                            onClick={() => {
                              if (acceptedFiles[0].size > 2000000) {
                                toastr.error(
                                  'The file may not be greater than 2MB',
                                )
                              } else {
                                upload.startFetch({ file: acceptedFiles[0] })
                              }
                            }}
                          >
                            Try again
                          </a>
                        )}
                        {upload.isLoading && (
                          <i className='bx bx-loader bx-spin text-primary mx-2'></i>
                        )}
                        {uploaded ? (
                          <a
                            className='text-primary'
                            onClick={
                              upload.error ? getRootProps().onClick : undefined
                            }
                          >
                            Replace
                          </a>
                        ) : (
                          <a
                            onClick={
                              upload.error ? getRootProps().onClick : removeAll
                            }
                          >
                            <i className='bx bx-trash text-danger' />
                          </a>
                        )}
                      </div>
                    </Row>
                  ) : (
                    <>
                      <div className='py-4'>
                        <UploadFile />
                      </div>
                      <Row>
                        <Col className='justify-content-center'>
                          {isMobile ? (
                            <h4>Click to upload files.</h4>
                          ) : (
                            <h3>Drop files here or click to upload.</h3>
                          )}
                          <p className='text-muted font-size-12'>
                            *PDF only. Max file size 2MB
                          </p>
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
              </div>
            </section>
          </Col>
        </Row>
      )}
      {selected === 2 && (
        <Row>
          <Col>
            <FormGroup>
              <Label>Templates</Label>
              <CustomSelect
                options={templateOptions}
                onChange={onTemplateSelected}
                value={template}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
    </Container>
  )
}

export default ContractTypeSelect
