import cx from 'classnames'
import styled from 'styled-components'

export const FlagIcon = styled.div.attrs((props) => ({
  className: cx(
    props?.className,
    props?.code
      ? `currency-flag currency-flag-${props.code.toLowerCase()}`
      : undefined,
  ),
}))`
  --size: ${(props) => (props.size ? props.size : '16px')};
  width: var(--size) !important;
  height: var(--size) !important;
  border-radius: 999px !important;
`
