import React from 'react'

export default function AddOnCard({ icon, desc, title }) {
  return (
    <div
      className='d-flex rounded p-4 p-sm-3 p-lg-4'
      style={{ height: '100%', border: '1px solid #e7e8f2', gap: '1rem' }}
    >
      {icon}
      <div>
        <h4
          className='font-size-16 rp-font-gilroyB'
          style={{ fontWeight: 600 }}
        >
          {title}
        </h4>
        <p
          className='text-muted font-size-14 mb-0'
          style={{ lineHeight: '24px' }}
        >
          {desc}
        </p>
      </div>
    </div>
  )
}
