import React, { useMemo, useState } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  FormText,
  Col,
  Row,
} from 'reactstrap'
import toastr from 'toastr'

import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import Toggle from '../../../../components/Forms/Toggle/Toggle'
import Button from '../../../../components/ui/button'
import { getCurrencyFormatter } from '../../../../utils/formatters/currency'

const revertReasons = [
  { label: 'Name mismatch', value: 'name_mismatch' },
  { label: 'Contractor request', value: 'contractor_request' },
  { label: 'Incorrect bank details', value: 'incorrect_bank_details' },
  {
    label: 'Incorrect bank details (Egypt)',
    value: 'incorrect_bank_details_eg',
  },
  { label: 'Other (with email)', value: 'custom' },
  { label: 'Other (no email)', value: 'other' },
]

export const RevertTrxModal = ({
  isOpen,
  toggle,
  onConfirm,
  trx,
  isLoading,
  isWithdraw,
}) => {
  const [customReason, setCustomReason] = useState('')
  const [reason, setReason] = useState(revertReasons[0])
  const [archiveBank, setArchiveBank] = useState(false)

  const formatter = useMemo(() => {
    return getCurrencyFormatter(trx?.currency?.code, undefined, {
      maximumSignificantDigits: trx?.provider === 'coinbase' ? 6 : undefined,
    })
  }, [trx?.currency?.code, trx?.provider])

  const info = useMemo(() => {
    return [
      { label: 'Transaction ref:', value: trx?.transaction_ref },
      { label: 'Amount:', value: formatter.format(trx?.total) },
      {
        label: isWithdraw ? 'Contractor:' : 'Cilent:',
        value: isWithdraw
          ? `${trx?.contractor?.first_name} ${trx?.contractor?.last_name}`
          : `${trx?.client?.first_name} ${trx?.client?.last_name}`,
      },
    ]
  }, [formatter, trx?.total, trx?.transaction_ref])

  function handleConfirm() {
    if (reason.value === 'custom' && !customReason) {
      toastr.error('Please enter a reason', 'Error')
      return
    }
    onConfirm(reason.value, customReason, archiveBank ? 1 : 0)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Revert Transaction</ModalHeader>

      <ModalBody>
        {info.map(({ label, value }, index) => {
          return (
            <div className='mb-2' key={index}>
              <span className='rp-font-bold rp-font-gilroyB text-dark'>
                {label}
              </span>{' '}
              {value}
            </div>
          )
        })}
        <p>Are you sure you want to revert this transaction?</p>
        {isWithdraw && (
          <div className='w-50'>
            <CustomSelect
              label={'Reason'}
              value={reason}
              onChange={setReason}
              options={revertReasons}
            />
          </div>
        )}
        {isWithdraw && (
          <Col>
            <Row className='py-3'>
              <Toggle
                id={`rvt`}
                check={archiveBank}
                change={() => {
                  setArchiveBank(!archiveBank)
                }}
              />
              <label>Archive bank account</label>
            </Row>
          </Col>
        )}

        {reason.value !== 'custom' ? null : (
          <div className='mt-2'>
            <FormGroup>
              <Label className='font-size-14'>Custom reason:</Label>
              <Input
                type='textarea'
                name='custom_reason'
                value={customReason}
                onChange={(e) => setCustomReason(e.target.value)}
              />
              <FormText>This reason will be sent to the contractor.</FormText>
            </FormGroup>
          </div>
        )}
      </ModalBody>

      <ModalFooter>
        <Button
          type='button'
          color='light'
          outline
          onClick={toggle}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          type='button'
          onClick={handleConfirm}
          disabled={isLoading}
          loading={isLoading}
        >
          Revert
        </Button>
      </ModalFooter>
    </Modal>
  )
}
