import React, { useState } from 'react'
import { StyledH5 } from '../../../../components/Typo'
import { Card, Col, Row } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ControlledDatePicker from '../../../../components/ControlledDatePicker'
import ContactsList from '../ContactsList'
import Toggle from '../../../../components/Forms/Toggle/Toggle'
import styled from 'styled-components'

const schema = yup.object().shape({
  date: yup.string().required(),
  invoices_auto_sync: yup.bool(),
  contractors_auto_sync: yup.bool(),
})
const VendorsSettingStep = ({
  onSubmitted,
  loading,
  onBack,
  platform = 'Xero',
}) => {
  const [syncInvoice, setSyncInvoice] = useState(false)
  const [syncContractors, setSyncContractors] = useState(false)

  const {
    control,
    handleSubmit,

    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {},
  })

  const onSubmit = (value) => {
    onSubmitted({
      ...value,
      invoices_auto_sync: syncInvoice,
      contractors_auto_sync: syncContractors,
    })
  }

  return (
    <Col className='py-3'>
      <Row className={'justify-content-center align-items-center p-4'}>
        <h1 className='font-weight-bold'> Vendors Settings</h1>
      </Row>
      <form
        id='vendorMappingForm'
        autoComplete='off'
        className='form-horizontal m-0'
        onSubmit={(event) => {
          if (event.target.id === 'vendorMappingForm') {
            handleSubmit(onSubmit)(event)
          }
        }}
      >
        <StyledCard className='p-4 m-0 mb-3'>
          <div>
            <StyledH5 className='font-size-16 font-weight-bold'>
              When to start syncing your invoices
            </StyledH5>
          </div>
          <div className='p-0 m-0 w-100'>
            <ControlledDatePicker
              maxDate={new Date()}
              control={control}
              error={errors.date}
              name='date'
              placeholder={'Start date'}
            />
          </div>
        </StyledCard>
        <StyledCard className='p-4 m-0 mb-3'>
          <Row className='p-0 m-0 align-items-center'>
            <div className='mr-3'>
              <Toggle
                change={() => setSyncInvoice((e) => !e)}
                check={syncInvoice}
              />
            </div>
            <div>
              <StyledH5 className='font-size-16 font-weight-bold'>
                Invoices auto sync
              </StyledH5>
              <p className='text-muted p-0 m-0'>
                Automatically synchronize with {platform} every time I run
                payroll
              </p>
            </div>
          </Row>
        </StyledCard>
        <StyledCard className='p-4 m-0 mb-3'>
          <Row className='p-0 m-0 align-items-center'>
            <div className='mr-3'>
              <Toggle
                change={() => setSyncContractors((e) => !e)}
                check={syncContractors}
              />
            </div>
            <div>
              <StyledH5 className='font-size-16 font-weight-bold'>
                Contractors auto sync
              </StyledH5>
              <p className='text-muted p-0 m-0'>
                Automatically sync all new contractors with the integration.
              </p>
            </div>
          </Row>
        </StyledCard>
        <StyledCard className='m-0 mb-3'>
          <ContactsList platform={platform} />
          <div className={'modal-footer'}>
            <Col>
              <Row className='justify-content-between'>
                <div>
                  <button
                    type={'button'}
                    onClick={onBack}
                    className='btn btn-outline-light'
                  >
                    Back
                  </button>
                </div>
                <div>
                  <button
                    disabled={loading}
                    form='vendorMappingForm'
                    id='vendor-settings-submit'
                    type='submit'
                    className='btn btn-primary'
                  >
                    {loading && (
                      <i className='bx bx-loader bx-spin font-size-16 align-middle mx-2' />
                    )}
                    Continue
                  </button>
                </div>
              </Row>
            </Col>
          </div>
        </StyledCard>
      </form>
    </Col>
  )
}
const StyledCard = styled(Card)`
  background: #ffffff;
  box-shadow: 0px 1px 0px #dfe1e6;
  border-radius: 4px;
`

VendorsSettingStep.propTypes = {}

export default VendorsSettingStep
