import React, { useState } from 'react'
import {
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  Row,
} from 'reactstrap'

// i18n
import { withTranslation } from 'react-i18next'
import logout from '../../../assets/images/logout.svg'
import companyset from '../../../assets/images/companysettings.svg'
import reg from '../../../assets/images/reg.svg'
// Redux
import { connect, useSelector } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

// users
import { userTypes } from '../../../helpers/enum'
import { storage } from '../../../helpers/config'
import { usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import { StyledH5 } from '../../Typo'
import ModalHeader from '../../ModalHeader'

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [show, setShow] = useState(false)
  const user = useSelector((state) => state.Account?.user)

  const isEmployee =
    useSelector((state) => state?.userProfile?.userProfile?.contractor_type) ===
    'employee'

  const { hasAccess } = usePermissions()

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className='h-100'>
        <DropdownToggle
          className='btn header-item waves-effect p-0 h-100'
          id='page-header-user-dropdown'
          tag='button'
        >
          <Row className='mr-0 mr-md-3 ml-0 d-flex align-items-center justify-content-end'>
            <div className='pr-md-4 pl-md-4'>
              {user?.photo ? (
                <img
                  className='rounded-circle header-profile-user'
                  src={user?.photo}
                  alt=''
                />
              ) : (
                <div className='rounded-circle header-profile-user'>
                  <span
                    className={`${
                      user?.type === 'client'
                        ? 'avatar-title-green'
                        : 'avatar-title'
                    } rounded-circle`}
                  >
                    {user?.first_name?.charAt(0)}
                  </span>
                </div>
              )}
            </div>
            <Col
              className='justify-content-start'
              style={{ marginRight: '4rem' }}
            >
              <Row>
                <span
                  style={{ fontWeight: 600 }}
                  className='text-dark font-size-16'
                  translate='no'
                >
                  {user?.first_name} {user?.last_name}
                </span>
              </Row>
              {!props.isMobile && (
                <Row>
                  <span
                    style={{ fontWeight: 500 }}
                    className='text-secondary font-size-14'
                  >
                    {user?.type === 'client'
                      ? 'Client'
                      : isEmployee
                      ? 'Employee'
                      : 'Contractor'}
                  </span>
                </Row>
              )}
            </Col>
            <i className='bx bxs-down-arrow font-size-10' />
          </Row>
        </DropdownToggle>
        <DropdownMenu right className='w-100 mt-2'>
          {user?.type === userTypes.COMPANY && (
            <DropdownItem
              tag={Link}
              to='/profile-settings'
              className='d-flex align-items-center text-dark py-2'
              style={{ gap: '1rem' }}
            >
              <img src={reg} style={{ width: 24 }} alt='' />
              <StyledH5
                className='font-size-16 py-2 rp-font-gilroyB'
                as='span'
                style={{ lineHeight: 1 }}
              >
                Profile Settings
              </StyledH5>
            </DropdownItem>
          )}
          {(hasAccess(permissions.manageCompanySettings) ||
            user?.type === userTypes.CONTRACTOR) &&
            !isEmployee && (
              <DropdownItem
                tag={Link}
                to='/settings'
                className='text-dark d-flex align-items-center py-2'
                style={{ gap: '1rem' }}
              >
                <img src={companyset} style={{ width: 24 }} alt='' />
                <StyledH5
                  className='font-size-16 py-2 rp-font-gilroyB'
                  as='span'
                  style={{ lineHeight: 1 }}
                >
                  {user?.type === userTypes.COMPANY
                    ? 'Company Settings'
                    : 'Profile Settings'}
                </StyledH5>
              </DropdownItem>
            )}
          {isEmployee && (
            <DropdownItem
              tag={Link}
              to={'/settings'}
              className='text-dark d-flex align-items-center py-2'
              style={{ gap: '1rem' }}
            >
              <img src={companyset} style={{ width: 24 }} alt='' />
              <StyledH5
                className='font-size-16 py-2 rp-font-gilroyB'
                as='span'
                style={{ lineHeight: 1 }}
              >
                {props.t('Profile Settings')}
              </StyledH5>
            </DropdownItem>
          )}
          <div className='dropdown-divider' />
          {!isEmployee &&
            hasAccess(permissions.switchAccount) &&
            user[0] &&
            user[1] && (
              <DropdownItem
                tag='button'
                type='button'
                onClick={() => props.history.push('/switch')}
                className='dropdown-item py-2 d-flex align-items-center py-2'
                style={{ gap: '1rem' }}
              >
                <i className='bx bx-transfer font-size-24 align-middle text-dark' />
                <div className='d-flex flex-column'>
                  <StyledH5
                    className='font-size-16 text-dark mb-0 rp-font-gilroyB'
                    as='span'
                    style={{ lineHeight: 1 }}
                  >
                    Switch account
                  </StyledH5>
                  <span className='text-muted font-size-12'>{`To ${
                    user?.type === userTypes.CONTRACTOR
                      ? 'client'
                      : 'contractor'
                  } account`}</span>
                </div>
              </DropdownItem>
            )}
          <DropdownItem
            tag={Link}
            to='/logout'
            className='dropdown-item py-2 d-flex align-items-center py-2'
            style={{ gap: '1rem' }}
          >
            <img src={logout} style={{ width: 24 }} alt='' />
            <StyledH5
              className='font-size-16 text-danger rp-font-gilroyB'
              as='span'
              style={{ lineHeight: 1 }}
            >
              Logout
            </StyledH5>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <SwitchModal
        user={user}
        history={props.history}
        show={show}
        hide={() => setShow(false)}
      />
    </React.Fragment>
  )
}

const SwitchModal = (props) => {
  const { show, hide, history, user } = props
  return (
    <Modal
      style={{
        maxWidth: '100%',
        margin: 0,
        backgroundColor: 'white',
      }}
      isOpen={show}
      toggle={hide}
      className='p-0 switch-modal h-100'
    >
      <Container fluid>
        <ModalHeader action={hide} />
        <ModalBody>
          <div
            style={{
              borderRadius: 10,
              minWidth: '33%',
              maxWidth: '100%',
            }}
          >
            <h3 className='mb-4 mt-4 text-center'>
              Which account would you like to use?
            </h3>
            <div
              onClick={hide}
              style={{ borderRadius: 10 }}
              className={
                'border d-flex align-items-center justify-content-between p-3 mb-3'
              }
            >
              <Row className='p-0 m-0'>
                {!user?.photo ? (
                  <div className='avatar-sm mx-auto'>
                    <span
                      className={`${
                        user?.type === 'client'
                          ? 'avatar-title-green'
                          : 'avatar-title'
                      }  rounded-circle  text-white font-size-16`}
                    >
                      {user?.first_name
                        ? user?.first_name?.charAt(0)
                        : user?.email?.charAt(0)}
                    </span>
                  </div>
                ) : (
                  <div>
                    <img
                      className='rounded-circle avatar-sm'
                      src={`${storage}${user?.photo}`}
                      alt='user-avatar'
                    />
                  </div>
                )}
                <Col>
                  <h5 className='font-size-15 text-dark'>
                    {user?.first_name && user?.last_name}
                  </h5>
                  <Row className='ml-0 flex-nowrap'>
                    <p className='text-muted mb-0'>{`your ${user?.type} account`}</p>
                  </Row>
                </Col>
              </Row>
              <i className='bx bx-chevron-right font-size-24 align-middle mr-1  text-primary ' />
            </div>

            <div
              onClick={() => history.push('/switch')}
              style={{ borderRadius: 10 }}
              className={
                'border d-flex align-items-center justify-content-between p-3 mb-3'
              }
            >
              <Row className='p-0 m-0'>
                {!user?.photo ? (
                  <div className='avatar-sm mx-auto'>
                    <span
                      className={`rounded-circle   text-white font-size-16 ${
                        user?.type === 'client'
                          ? 'avatar-title-green'
                          : 'avatar-title'
                      }`}
                    >
                      {user?.first_name
                        ? user?.first_name?.charAt(0)
                        : user?.email?.charAt(0)}
                    </span>
                  </div>
                ) : (
                  <div>
                    <img
                      className='rounded-circle avatar-sm'
                      src={`${storage}${user?.photo}`}
                      alt='user-avatar'
                    />
                  </div>
                )}
                <Col>
                  <h5 className='font-size-15 text-dark'>
                    {user?.first_name && user?.last_name}
                  </h5>
                  <Row className='ml-0 flex-nowrap'>
                    <p className='text-muted mb-0'>{`your ${
                      user?.type === userTypes.CONTRACTOR
                        ? 'client'
                        : 'contractor'
                    } account`}</p>
                  </Row>
                </Col>
              </Row>
              <i className='bx bx-chevron-right font-size-24 align-middle mr-1  text-primary ' />
            </div>
          </div>
        </ModalBody>
      </Container>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  const { error, success } = state.Profile
  return { error, success }
}
export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(ProfileMenu)),
)
