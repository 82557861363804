import React, { useMemo, useState } from 'react'
import { Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Select from 'react-select'
import { useSelector } from 'react-redux'

import Button from '../../../../components/ui/button'
import { getOptionFromList } from '../../../../utils/map-to-option'

function getInfos(transaction) {
  const infos = [
    { title: 'Transaction ref', value: transaction.transaction_ref },
    {
      title: 'Contractor',
      value: [transaction?.client?.first_name, transaction?.client?.last_name]
        .filter(Boolean)
        .join(' '),
    },
    { title: 'Current Provider', value: transaction?.provider },
  ]

  return infos
}

export default function PreferedProviderModal({
  isOpen,
  transaction,
  isLoading,
  toggle,
  onConfirm,
}) {
  const paymentProviders = useSelector(
    (state) => state.Layout?.staticData?.payment_providers ?? [],
  )

  const paymentProviderOptions = useMemo(() => {
    return paymentProviders.map(({ code, name }) => ({
      label: name,
      value: code,
    }))
  }, [paymentProviders])

  const [newProvider, setNewProvider] = useState(
    getOptionFromList(paymentProviderOptions, transaction?.provider),
  )

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader className='d-flex justify-content-between' toggle={toggle}>
        Prefered Provider
      </ModalHeader>
      <ModalBody>
        {/* Transaction Info */}
        <div className='font-size-16'>
          {getInfos(transaction).map(({ title, value }, index) => {
            return (
              <div key={index}>
                <span>{title}: </span>
                <span className='font-weight-bold text-dark text-capitalize'>
                  {value}
                </span>
              </div>
            )
          })}
        </div>

        <hr className='' />

        {/* Providers List */}
        <Label for='new-provider'>New prefered provider:</Label>
        <Select
          name='new-provider'
          components={{ IndicatorSeparator: () => null }}
          isSearchable={false}
          placeholder='Select new prefered provider'
          options={paymentProviderOptions}
          value={newProvider}
          onChange={setNewProvider}
        />
      </ModalBody>
      <ModalFooter className='justify-content-between'>
        <Button
          type='button'
          outline
          color='light'
          disabled={isLoading}
          onClick={toggle}
        >
          Cancel
        </Button>
        <Button
          type='button'
          disabled={isLoading}
          loading={isLoading}
          color={isLoading ? 'light' : 'primary'}
          onClick={() => onConfirm(newProvider.value)}
        >
          {isLoading ? 'Setting provider ...' : 'Set prefered provider'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
