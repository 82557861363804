import React, { useState } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import cx from 'classnames'

const NoOpComponent = () => null

export default function ActionsDropdown({
  CustomToggle,
  options = [],
  direction = 'down',
  containerClassName = '',
  toggleClassName = '',
  menuClassName = '',
}) {
  const [isOpen, setIsOpen] = useState(false)
  function toggle() {
    setIsOpen((o) => !o)
  }

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={toggle}
      direction={direction}
      className={cx('d-inline-block', containerClassName)}
    >
      <DropdownToggle
        tag={CustomToggle ?? 'button'}
        className={cx('border-0 d-flex p-1 rounded bg-light', toggleClassName)}
      >
        <i className='bx bx-dots-vertical-rounded font-size-24 text-dark' />
      </DropdownToggle>
      <DropdownMenu
        className={cx('dropdown-menu-right rp-border rp-shadow', menuClassName)}
        flip={false}
      >
        {options.filter(Boolean).map((option, i) => {
          const Component = option?.component ?? NoOpComponent

          return (
            <DropdownItem
              key={`drop-${option?.id ?? i}`}
              onClick={option.onClick}
              tag={option?.tag ?? 'button'}
              disabled={option?.disabled}
              className={cx(
                'align-items-center d-flex px-3 gap-8',
                option?.className,
              )}
              toggle={option?.toggle}
            >
              {option?.content ?? <Component />}
            </DropdownItem>
          )
        })}
      </DropdownMenu>
    </Dropdown>
  )
}
