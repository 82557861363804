import classnames from 'classnames'
import React, { useState } from 'react'
import Paginations from 'react-js-pagination'
import { Link } from 'react-router-dom'
import {
  Badge,
  Card,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  Table,
} from 'reactstrap'
import styled from 'styled-components'

import Avatar from '../../../../components/Avatar'
import { StyledP } from '../../../../components/Typo'
import UserFlagging from '../../../../components/userFlag'
import { useFetch } from '../../../../helpers/hooks'
import { getFullTimeReqList } from '../../../../services/api'
import ContractRef from '../../components/ContractRef'
import SearchBar from '../../../../components/SearchBar'

const tabs = ['Pending', 'Ongoing', 'Cancelled']

const getRequestStatus = (activeTab) => {
  switch (activeTab) {
    case '1':
      return 'pending'
    case '2':
      return 'ongoing'
    case '3':
      return 'cancelled'
    default:
      return 'pending'
  }
}
const defaultFilters = {
  activeTab: '1',
  page: 1,
  contract_ref: '',
  employee_name: '',
}
function getBodyFromFilters(filters) {
  return {
    ...filters,
    status: getRequestStatus(filters.activeTab),
  }
}
const FullTimeRequests = () => {
  const [pageFilters, setPageFilters] = useState(defaultFilters)

  const requests = useFetch(
    {
      action: getFullTimeReqList,
      withAdminAccess: true,
      autoFetch: true,
      body: getBodyFromFilters(pageFilters),
    },
    [pageFilters],
  )
  function handleChange(changeValue, options) {
    const name = options?.name ?? changeValue.target.name
    const value = options?.name ? changeValue : changeValue.target.value

    setPageFilters((prev) => ({
      ...prev,
      [name]: value,
      page: name === 'page' ? value : 1,
    }))
  }

  // const countries = useSelector((state) => state?.Layout?.staticData?.countries)

  return (
    <div className='page-content'>
      <Container fluid className={'p-0 m-0'}>
        <h1 style={{ marginBottom: '2rem' }} className='rp-font-gilroyB'>
          Full time contracts
        </h1>

        <Card>
          <div className='p-3'>
            <StyledNav tabs>
              {tabs.map((e, i) => (
                <NavItem key={`tab-${i}`}>
                  <NavLink
                    style={{ cursor: 'pointer' }}
                    className={classnames({
                      active: pageFilters.activeTab === `${i + 1}`,
                      'bg-white': true,
                    })}
                    onClick={() => {
                      handleChange(`${i + 1}`, { name: 'activeTab' })
                    }}
                  >
                    <p className='  font-size-14 mb-0'>{e}</p>
                  </NavLink>
                </NavItem>
              ))}
            </StyledNav>
          </div>
          <div className='p-3'>
            <Row className='p-3'>
              <Col md={6} className='pl-md-0'>
                <SearchBar
                  query={pageFilters.contract_ref}
                  onQueryChanged={(e) =>
                    handleChange(e, { name: 'contract_ref' })
                  }
                  placeholder='Search by contract ref'
                  className='mr-md-2'
                />
              </Col>
              <Col md={6} className='pl-md-0'>
                <SearchBar
                  query={pageFilters.employee_name}
                  onQueryChanged={(e) =>
                    handleChange(e, { name: 'employee_name' })
                  }
                  placeholder='Search by employee name'
                  className='mr-md-2'
                />
              </Col>
            </Row>
          </div>

          {requests.isLoading ? (
            <div
              style={{ minHeight: '50vh' }}
              className='d-flex justify-content-center align-items-center'
            >
              <Spinner type='grow' color='primary' />
            </div>
          ) : (
            <>
              <div className='table-responsive'>
                <Table className='table table-centered table-nowrap text-muted'>
                  <thead className='thead-light'>
                    <tr className='text-muted'>
                      <th className='border-top-0 text-muted'>Contract ID</th>
                      <th className='border-top-0 text-muted'>Job Title</th>
                      <th className='border-top-0 text-muted'>Client</th>
                      <th className='border-top-0 text-muted'>Provider</th>
                      <th className='border-top-0 text-muted'>Employee</th>
                      <th className='border-top-0 text-muted'>Salary</th>
                      <th className='border-top-0 text-muted'>Company Name</th>
                      <th className='border-top-0 text-muted'>Country</th>
                      <th className='border-top-0 text-muted'>Start Date</th>
                      <th className='border-top-0 text-muted'>Status</th>
                      <th className='border-top-0 text-muted' />
                    </tr>
                  </thead>
                  <tbody>
                    {requests?.data?.contracts?.map((contract, i) => (
                      <ContractLine
                        key={`temp${i}`}
                        contract={contract}
                        onUpdate={() =>
                          requests.startFetch(
                            getBodyFromFilters(pageFilters),
                            false,
                          )
                        }
                      />
                    ))}
                  </tbody>
                </Table>
              </div>

              <div className='d-flex justify-content-end pt-3 px-3'>
                <Paginations
                  itemClass='page-item'
                  linkClass='page-link'
                  activePage={pageFilters.page}
                  itemsCountPerPage={requests.paginator?.per_page}
                  totalItemsCount={requests.paginator?.total}
                  onChange={(e) => handleChange(e, { name: 'page' })}
                />
              </div>
            </>
          )}
        </Card>
      </Container>
    </div>
  )
}

const ContractLine = ({ contract, onUpdate }) => {
  return (
    <tr>
      <td>
        <ContractRef contractId={contract.ref} />
      </td>
      <td>{contract?.name}</td>
      <td>
        <Col>
          <Row className='align-items-center flex-nowrap'>
            <Avatar
              name={contract?.client?.first_name || contract?.client?.email}
              photo={contract?.client?.photo}
              flag={contract?.client?.flag}
            />

            <div
              className='d-flex font-size-14   '
              style={{
                color: '#4A4E5B',
                fontWeight: '600',
                marginLeft: '10px',
              }}
            >
              {contract.client.is_flagged ? (
                <UserFlagging
                  user={contract?.client}
                  onCompleteAction={() => {
                    onUpdate()
                  }}
                />
              ) : null}
              {contract?.client?.first_name || contract?.client?.first_name
                ? `${contract?.client?.first_name || ''} ${
                    contract?.client?.last_name || ''
                  }`
                : contract?.client?.email}
            </div>
          </Row>
        </Col>
      </td>
      <td>
        <Col>
          <Row className='align-items-center flex-nowrap'>
            <Avatar
              name={contract?.provider?.first_name || contract?.provider?.email}
              photo={contract?.provider?.photo}
              flag={contract?.provider?.flag}
            />

            <div
              className='font-size-14   '
              style={{
                color: '#4A4E5B',
                fontWeight: '600',
                marginLeft: '10px',
              }}
            >
              {contract?.provider?.first_name || contract?.provider?.first_name
                ? `${contract?.provider?.first_name || ''} ${
                    contract?.provider?.last_name || ''
                  }`
                : contract?.provider?.email}
            </div>
          </Row>
        </Col>
      </td>
      <td>
        <Col>
          <Row className='align-items-center flex-nowrap'>
            <Avatar
              name={
                contract?.contractor?.first_name || contract?.contractor?.email
              }
              photo={contract?.contractor?.photo}
              flag={contract?.contractor?.flag}
            />

            <div
              className='font-size-14   '
              style={{
                color: '#4A4E5B',
                fontWeight: '600',
                marginLeft: '10px',
              }}
            >
              {contract?.contractor?.first_name ||
              contract?.contractor?.first_name
                ? `${contract?.contractor?.first_name || ''} ${
                    contract?.contractor?.last_name || ''
                  }`
                : contract?.contractor?.email}
            </div>
          </Row>
        </Col>
      </td>
      <td>{contract?.salary}</td>
      <td>{contract?.company}</td>
      <td>{contract?.country}</td>
      <td>{contract?.start_date}</td>
      <td>
        <Badge
          className={`font-size-14  rounded bg-soft-${
            contract?.status?.id === 4 ? 'success' : 'warning'
          }`}
          color='white'
          pill
        >
          <StyledP
            style={{ padding: 4 }}
            min={'12px'}
            max={'14px'}
            className={`mb-0 text-${
              contract?.status?.id === 4 ? 'success' : 'warning'
            }`}
          >
            {contract?.status?.name}
          </StyledP>
        </Badge>
      </td>
      <td>
        <Link target='_blank' to={`/admin-fulltime-details/${contract.id}`}>
          Review
        </Link>
      </td>
    </tr>
  )
}

const StyledNav = styled(Nav)`
  border-bottom: 0 !important;

  .nav-item {
    margin-right: 2rem;

    .nav-link {
      padding: 6px 0;
      background-color: #f4f5f7;
      color: #777f9e;
      font-weight: 400;

      &:hover {
        border-top-color: #f4f5f7 !important;
        border-right-color: #f4f5f7 !important;
        border-left-color: #f4f5f7 !important;

        color: #777f9e !important;
      }
    }

    .active {
      border: 0;
      font-weight: 600;
      border-bottom: 2px solid #114ef7;

      p {
        color: #114ef7 !important;
      }
    }
  }
`

export default FullTimeRequests
