import React from 'react'
import { slide as Menu } from 'react-burger-menu'
import BadgeX from '../../../../components/Table/BadgeX'

import { useResize } from '../../../../helpers/hooks'

const dataFields = [
  {
    title: 'Client',
    value: [
      { title: 'First Name', dataKey: 'client.first_name' },
      { title: 'Middle Name', dataKey: 'client.middle_name' },
      { title: 'Last Name', dataKey: 'client.last_name' },
      { title: 'Email', dataKey: 'client.email' },
      { title: 'Company Name', dataKey: 'company_name' },
      { title: 'Contracts', dataKey: 'contracts' },
    ],
  },
  {
    title: 'Details',
    value: [
      { title: 'TRX Ref', dataKey: 'transaction_ref' },
      {
        title: 'Amount',
        custom: (item) => {
          const formatter = getCurrencyFormatter({
            currency: item?.currency?.code,
            sigDigits: item?.provider === 'coinbase' ? 6 : undefined,
          })

          return formatter.format(item.amount)
        },
      },
      {
        title: 'TRX Status',
        custom: (item) => {
          const status =
            item.status.toLowerCase() === 'confirmed' ? 'success' : 'secondary'
          return (
            <BadgeX
              status={status}
              textStatus={status}
              name={item.status}
              className='text-capitalize px-1'
            />
          )
        },
      },
      { title: 'TRX Type', dataKey: 'type.name' },
      { title: 'Provider', dataKey: 'provider' },
      { title: 'TRX Method', dataKey: 'method.name' },
      { title: 'TRX Created at', dataKey: 'created_at' },
      { title: 'TRX Confirmed at', dataKey: 'confirmed_at' },
      { title: 'TRX Confirmed by', dataKey: 'confirmed_by' },
    ],
  },
  {
    title: 'Bank',
    value: [
      { title: 'Account Holder', dataKey: 'account_holder' },
      { title: 'Bank', dataKey: 'bank_account_details.currency' },
      { title: 'Bank Email', dataKey: 'bank_account_details.email' },
    ],
  },
]

function getCurrencyFormatter({ currency = 'USD', sigDigits }) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    maximumSignificantDigits: sigDigits,
  })

  return formatter
}

export default function TransactionDetailsSider({ transaction, hide, isOpen }) {
  const isMobile = useResize()

  if (!transaction) return null

  return (
    <div
      style={{ position: 'absolute', top: 0, bottom: 0, left: 0, zIndex: 1100 }}
    >
      <Menu
        onClose={hide}
        className='bg-white'
        isOpen={isOpen}
        width={isMobile ? '100%' : '30%'}
        right
      >
        <div
          className='d-flex flex-column rp-font-gilroyB p-3'
          style={{ gap: '0.5rem' }}
        >
          <Details data={transaction} fields={dataFields} />
        </div>
      </Menu>
    </div>
  )
}

function Details({ fields, data, level = 2 }) {
  return fields.map((dataField, index) => {
    if (Array.isArray(dataField?.value)) {
      return (
        <React.Fragment key={`${dataField.title}-${index}`}>
          <div className={`h${Math.min(level, 6)} mt-2`}>{dataField.title}</div>
          <div className='d-flex flex-column' style={{ gap: '0.5rem' }}>
            <Details fields={dataField.value} data={data} level={level + 1} />
          </div>
        </React.Fragment>
      )
    }

    return (
      <SingleDetail
        {...dataField}
        key={`${dataField.dataKey}-${index}`}
        data={data}
      />
    )
  })
}

function SingleDetail({ dataKey, custom, title, data }) {
  const value =
    typeof custom === 'function'
      ? custom(data)
      : dataKey.split('.').reduce((accu, prev) => {
          return accu[prev]
        }, data)

  return (
    <div>
      <div className='font-size-14 col-form-label p-0 m-0 mb-2'>{title}: </div>
      <div
        className='font-size-16'
        style={{
          padding: '0.325rem 0.5rem',
          border: '1px solid rgba(231, 232, 242, 1)',
          borderRadius: 4,
          color: 'rgba(119, 127, 158, 1)',
        }}
      >
        <DetailValue value={value} />
      </div>
    </div>
  )
}

function DetailValue({ value }) {
  if (!value) return 'N/A'

  if (Array.isArray(value)) {
    return value.map((v, i) => (
      <>
        {i !== 0 ? ', ' : null}
        <span key={`${v}-${i}`}>{v}</span>
      </>
    ))
  }

  return value
}
