import React from 'react'
import ReactApexChart from 'react-apexcharts'

import kFormatter from '../../../utils/formatters/k-formatter'

function defaultTooltipFormatter(args) {
  const { series, seriesIndex, dataPointIndex } = args

  return series[seriesIndex][dataPointIndex]
}

function defaultCustomTooltip(args, params) {
  const { seriesIndex, dataPointIndex } = args
  const { multipleTooltip, labels, series, fillColors, formatTooltipValue } =
    params
  if (multipleTooltip) {
    return (
      '<div>' +
      '<span style="font-size: 12px; color: #0F1035; display: block; padding: 8px 16px; font-weight:700; border-bottom: 1px solid #E7E8F2;">' +
      labels[dataPointIndex] +
      '</span>' +
      series
        .map((elm, index) => {
          return (
            '<div style="font-size: 12px; color: #0F1035; padding: 4px 16px;"><span style="width: 10px; height: 10px; border-radius: 50%; background-color:' +
            fillColors[index] +
            '; display: inline-block; margin-right: 8px;" class="hover:bg-light"></span>' +
            formatTooltipValue(args, index) +
            '</div>'
          )
        })
        .join('') +
      '</div>'
    )
  } else {
    return (
      '<div style="display:block !important;">' +
      '<span style="font-size: 12px; color: #0F1035; display: block; padding: 8px 16px; font-weight:700; border-bottom: 1px solid #E7E8F2;">' +
      labels[dataPointIndex] +
      '</span>' +
      '<div style="font-size: 12px; color: #0F1035; display: block; padding: 8px 16px;"><span style="width: 10px; height: 10px; border-radius: 50%; background-color:' +
      fillColors[seriesIndex] +
      '; display: inline-block; margin-right: 8px;" class="hover:bg-light"></span>' +
      formatTooltipValue(args) +
      '</div>' +
      '</div>'
    )
  }
}

export default function Bar({
  height,
  xAxisCategories,
  labels,
  series,
  multipleTooltip,
  fillColors = ['#E8E9EB', '#114EF7'],
  showLegend = false,
  options: { stacked } = { stacked: false },
  formatTooltipValue = defaultTooltipFormatter,
  yAxisFormatter = (val) => kFormatter(val),
  customTooltip = (args) =>
    defaultCustomTooltip(args, {
      multipleTooltip,
      series,
      labels,
      fillColors,
      formatTooltipValue,
    }),
  className,
}) {
  return (
    <ReactApexChart
      className={className}
      options={getOptions({
        xAxisCategories,
        yAxisFormatter,
        customTooltip,
        fillColors,
        showLegend,
        stacked,
      })}
      series={series}
      type='bar'
      height={height ?? 350}
    />
  )
}

function getOptions({
  xAxisCategories,
  yAxisFormatter,
  customTooltip,
  fillColors,
  showLegend,
  stacked = false,
}) {
  return {
    chart: {
      stacked,
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        dataLabels: { position: 'center' }, // top, center, bottom
        columnWidth: '50%',
        colors: { backgroundBarRadius: 5 },
      },
    },
    dataLabels: { enabled: false },
    colors: ['#777F9E'],
    grid: { borderColor: '#E7E8F2' },
    xaxis: {
      categories: xAxisCategories,
      position: 'bottom',
      labels: { offsetY: 0 },
      axisBorder: { show: false },
      axisTicks: { show: false },
      tickAmount: 100,
      crosshairs: { show: false },
      tooltip: { enabled: false },
    },
    fill: { opacity: 1, type: 'solid', colors: fillColors },
    yaxis: {
      axisBorder: { show: false },
      axisTicks: { show: false },
      labels: { show: true, formatter: yAxisFormatter },
    },
    tooltip: {
      enabled: true,
      custom: customTooltip,
      x: { show: true, format: 'MMMM', formatter: undefined },
      y: {
        formatter: undefined,
        title: { formatter: (seriesName) => seriesName },
      },
      z: { formatter: undefined, title: 'Size: ' },
    },
    states: {
      normal: { filter: { type: 'none', value: 0 } },
      hover: { filter: { type: 'none', value: 0 } },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: { type: 'darken', value: 0.35 },
      },
    },
    legend: { show: showLegend },
  }
}
