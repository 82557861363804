import React from 'react'

export default function Maintenance() {
  if (window.location.pathname !== '/') {
    window.location.replace('/')
  }

  return (
    <div className='d-flex flex-column' style={{ minHeight: '100vh' }}>
      <header
        className='p-4 mx-auto'
        style={{ maxWidth: '42rem', width: '100%' }}
      >
        <img
          src='./logo_main.png'
          alt='RemotePass'
          style={{ height: '1.5rem' }}
        />
      </header>

      <main
        className='p-4 d-flex flex-column justify-content-center flex-grow-1 mx-auto'
        style={{ maxWidth: '42rem', width: '100%', gap: '1.25rem' }}
      >
        <img
          src='./down-illustration.png'
          alt=''
          style={{
            filter: 'drop-shadow(1px 1px 2px rgba(0,0,0,0.2))',
            width: '7rem',
          }}
        />
        <h2
          className='mb-0'
          style={{ fontSize: '1.875rem', lineHeight: '2.25rem' }}
        >
          We are down for maintenance
        </h2>
        <div
          className='font-size-16 text-secondary'
          style={{ lineHeight: '1.25rem' }}
        >
          <p className='mb-1'>
            We are performing a database migration to make your experience
            faster.
          </p>
          <p className='mb-0'>
            We expect to be back in a few hours. Check back soon.
          </p>
        </div>
      </main>

      <footer className='p-5'>{/* @ 2022 RemotePass */}</footer>
    </div>
  )
}
