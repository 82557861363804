import cx from 'classnames'
import React, { useEffect } from 'react'
// MetisMenu
import MetisMenu from 'metismenujs'
import { Link, withRouter } from 'react-router-dom'
// i18n
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useAdminPermission } from '../../../../helpers/hooks'
import { InactiveInsurance } from '../../../../helpers/SvgIcons'
import { adminPanelRoutes } from '../../../../routes/allRoutes'

const links = [
  { label: 'All Payments', to: '/admin/all-payments', icon: 'bx bx-money' },
  {
    label: 'Transactions',
    to: '/admin/transactions',
    icon: 'fas fa-clipboard-list',
  },
  {
    to: '/#',
    icon: 'bx bx-transfer',
    label: 'Transfers',
    submenu: [
      { label: 'Pending Transfers', to: '/admin/pending-transfers' },
      { label: 'Confirmed Transfers', to: '/admin/confirmed-transfers' },
    ],
  },
  {
    to: '/#!',
    icon: 'bx bx-money-withdraw',
    label: 'Balances',
    submenu: [
      { label: 'Contractors', to: '/admin/balance-contractors' },
      { label: 'Clients', to: '/admin/balance-clients' },
    ],
  },
  { to: '/admin/contracts', icon: 'fas fa-file-contract', label: 'Contracts' },
  { to: '/admin/companies', icon: 'fas fa-briefcase', label: 'Companies' },
  {
    to: '/admin/users',
    icon: 'bx bx-group',
    label: 'Users',
  },
  {
    to: '/#!',
    icon: 'bx bxs-credit-card',
    label: 'Cards',
    submenu: [
      { to: '/admin/cards-kyc', label: 'KYC Cards' },
      { to: '/admin/cards-unpaid-fees', label: 'Unpaid fees' },
      { to: '/admin/cards-physical', label: 'Physical Requests' },
      { to: '/admin/cards-all', label: 'All Cards' },
      { to: '/admin/cards-transactions', label: 'Transactions Card' },
      { to: '/admin/cards-support', label: 'Supported countries' },
    ],
  },
  { to: '/admin/withdraw', icon: 'bx bx-wallet', label: 'Auto withdraw' },
  { to: '/admin/templates', icon: 'fas fa-file-contract', label: 'Templates' },
  {
    to: '/#!',
    icon: 'fas fa-file-contract',
    label: 'EOR',
    submenu: [
      { to: '/admin/requests', label: 'Contracts' },
      { to: '/admin/eor-documents', label: 'Document requests' },
      { to: '/admin/partners', label: 'Partners' },
      { to: '/admin/contracts-invoices', label: 'Invoices' },
      { to: '/admin/partner-invoices', label: 'Partner Invoices' },
      { to: '/admin/EORCalculator', label: 'Calculator' },
      { to: '/admin/quote-generator', label: 'Quote generator' },
      { to: '/admin/generated-invoices', label: 'Invoice generator' },
    ],
  },
  {
    to: '/#!',
    customIcon: <InactiveInsurance />,
    label: 'Insurance',
    submenu: [
      { to: '/admin/insurance', label: 'Plans' },
      { to: '/admin/insurance-payments', label: 'Payments' },
    ],
  },
  {
    to: '/admin/currency-markup',
    icon: 'bx bx-dollar-circle',
    label: 'Currency Markup',
  },
  {
    to: '/#!',
    icon: 'bx bxs-user-detail',
    label: 'Admin users',
    submenu: [
      { to: '/admin/admin-list', label: 'All Admins' },
      { to: '/admin/admin-roles', label: 'Admin Roles' },
    ],
  },
  {
    to: '/admin/beneficiaries',
    icon: 'bx bx-question-mark',
    label: 'Bank Beneficiaries',
  },
  {
    to: '/admin/provider-rules',
    icon: 'bx bxs-analyse',
    label: 'Providers Rules',
  },
  {
    to: '/admin/forbidden-domains',
    icon: 'bx bx-unlink',
    label: 'Forbidden Domains',
  },
]

function getAdminPermissions(path) {
  return adminPanelRoutes.find((route) => route.path === path)?.permissions
}

const SidebarContent = (props) => {
  const { hasAccess } = useAdminPermission()

  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      // eslint-disable-next-line no-new
      new MetisMenu('#side-menu')
      let matchingMenuItem = null
      const ul = document.getElementById('side-menu')
      const items = ul.getElementsByTagName('a')
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  function activateParentDropdown(item) {
    item.classList.add('active')
    const parent = item.parentElement

    if (parent) {
      parent.classList.add('mm-active')
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add('mm-show')

        const parent3 = parent2.parentElement

        if (parent3) {
          parent3.classList.add('mm-active') // li
          parent3.childNodes[0].classList.add('mm-active') // a
          const parent4 = parent3.parentElement
          if (parent4) {
            parent4.classList.add('mm-active')
          }
        }
      }
      return false
    }
    return false
  }

  return (
    <React.Fragment>
      <div id='sidebar-menu' className='admin-sidebar'>
        <ul
          className='metismenu app-sidebar-navlist list-unstyled'
          id='side-menu'
        >
          {links.map(({ customIcon, icon, label, submenu, to }, index) => {
            const linkIcon = customIcon ?? <i className={icon} />

            const submenuPerms = submenu
              ?.map((sub) => getAdminPermissions(sub.to))
              ?.map(hasAccess)
              ?.some(Boolean)

            const perms = getAdminPermissions(to)
            const hasPerms =
              to && !perms ? true : !submenu ? hasAccess(perms) : submenuPerms

            if (!hasPerms) {
              return null
            }

            return (
              <StyledLi key={index}>
                <Link
                  to={to}
                  className={cx({ 'has-arrow': !!submenu }, 'waves-effect')}
                >
                  {linkIcon}
                  <span>{label}</span>
                </Link>

                {!submenu ? null : (
                  <ul className='sub-menu' aria-expanded='false'>
                    {submenu.map(({ label, to }, subIndex) => {
                      const perms = getAdminPermissions(to)
                      const hasPerms = !perms ? true : hasAccess(perms)

                      if (!hasPerms) {
                        return null
                      }

                      return (
                        <li key={subIndex}>
                          <Link to={to}>{label}</Link>
                        </li>
                      )
                    })}
                  </ul>
                )}
              </StyledLi>
            )
          })}
        </ul>
      </div>
    </React.Fragment>
  )
}

const StyledLi = styled.li`
  padding-top: 4px;

  a {
    font-size: 14px !important;
    opacity: 0.7;
    font-weight: 400;

    &:hover:not(.active) {
      opacity: 0.85;
    }
  }
  .active {
    .bx {
      color: #114ef7 !important;
    }
    color: #0f1035;
    font-weight: 600;
    opacity: 1 !important;
  }

  @media (max-width: 754px) {
    padding: 0 0 20px 16px !important;
    font-size: 12px;
  }
`

export default withRouter(withTranslation()(SidebarContent))
