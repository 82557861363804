import { AvField, AvForm } from 'availity-reactstrap-validation'
import React, { useCallback, useMemo, useState } from 'react'
import {
  Card,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import toastr from 'toastr'

import BadgeX from '../../../../components/Table/BadgeX'
import Button from '../../../../components/ui/button'
import DataTable from '../../../../components/ui/data-table'
import Loader from '../../../../components/ui/loader'
import NoContent from '../../../../components/ui/no-content'
import Pagination from '../../../../components/ui/pagination'
import { useFetch } from '../../../../helpers/hooks'
import {
  adminCardsUnpaidFees,
  getCardEnumerations,
  updateAdminCardsUnpaidFees,
} from '../../../../services/api'
import { getCardStatus } from './cards-all'
import { dateCell } from './cell'
import { FilterField } from './filter-field'
import useFilter from './use-filters'

const statusKey = 'fee_status'
function getBodyFromFilters(filters) {
  const { [statusKey]: status, ...rest } = filters
  return {
    ...rest,
    status: status?.value,
    page: filters.page - 1,
  }
}

const cardFeesStatusColor = {
  pending: 'danger',
  processed: 'success',
}

export default function AdminCardsUnpaidFees() {
  const [filters, handleFiltersChange] = useFilter({ page: 1 }, { statusKey })

  const {
    isLoading,
    data,
    startFetch: refetchCardsUnpaidFees,
  } = useFetch(
    {
      action: adminCardsUnpaidFees,
      withAdminAccess: true,
      autoFetch: true,
      body: getBodyFromFilters(filters),
    },
    [filters],
  )

  const refetchData = useCallback(
    function refetchData() {
      refetchCardsUnpaidFees(getBodyFromFilters(filters))
    },
    [filters, refetchCardsUnpaidFees],
  )

  const { data: enumerations, isLoading: loadingEnumerations } = useFetch({
    action: getCardEnumerations,
    autoFetch: true,
    withAdminAccess: true,
  })

  const cardStatusOptions = useMemo(() => {
    return loadingEnumerations || !enumerations
      ? []
      : enumerations.filterable_unpaid_card_fees_statuses?.map(getCardStatus)
  }, [enumerations, loadingEnumerations])

  const items = data?.items ?? []

  const columns = useMemo(
    () => [
      { Header: 'First name', accessor: 'user.name.first' },
      { Header: 'Last name', accessor: 'user.name.last' },
      { Header: 'Email', accessor: 'user.email' },
      { Header: 'Updated at', accessor: 'updated_at', Cell: dateCell },
      { Header: 'Card ID', accessor: 'card.id' },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ cellData }) => (
          <BadgeX
            className='rp-capitalize'
            status={cardFeesStatusColor[cellData]}
          >
            {cellData}
          </BadgeX>
        ),
      },
      {
        Header: 'Actions',
        Cell: ({ rowData }) => (
          <ActionsCell
            rowData={rowData}
            refreshData={refetchData}
            statusOptions={cardStatusOptions}
          />
        ),
      },
    ],
    [cardStatusOptions, refetchData],
  )

  return (
    <div className='page-content'>
      <div style={{ marginBottom: '2rem' }}>
        <h2 className='h1 mb-0 rp-font-gilroyB'>Cards: Unpaid fees</h2>
      </div>
      <Card>
        <div className='py-3'>
          <Filters
            cardStatusOptions={cardStatusOptions}
            filters={filters}
            onChange={handleFiltersChange}
          />
        </div>

        {isLoading ? (
          <Loader minHeight='max(50vh, 550px)' />
        ) : items?.length <= 0 ? (
          <NoContent
            className='text-muted font-size-16'
            minHeight='max(50vh, 550px)'
          >
            No items found
          </NoContent>
        ) : (
          <>
            <DataTable responsive data={items ?? []} columns={columns} />

            <div className='d-flex justify-content-end px-3'>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={data?.limit ?? 10}
                totalItemsCount={data?.total_items_count ?? 0}
                onChange={(v) => handleFiltersChange('page', v)}
              />
            </div>
          </>
        )}
      </Card>
    </div>
  )
}

function ActionsCell({ rowData, refreshData, statusOptions }) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  function toggle() {
    setIsModalOpen((o) => !o)
  }

  return (
    <>
      <Button size='sm' onClick={toggle}>
        Update status
      </Button>

      <UpdateStatusModal
        isOpen={isModalOpen}
        toggle={toggle}
        item={rowData}
        refreshData={refreshData}
        statusOptions={statusOptions}
      />
    </>
  )
}

const updateStatusFormId = 'mark-issued-form'
function UpdateStatusModal({
  isOpen,
  toggle,
  item,
  refreshData,
  statusOptions,
}) {
  const { startFetch: updateStatus, isLoading } = useFetch({
    action: updateAdminCardsUnpaidFees,
    withAdminAccess: true,
    onComplete: () => {
      toggle()
      refreshData()
    },
    onError: (error) => {
      toastr.error(error.error?.messages?.join(', '), 'Something went wrong')
    },
  })

  function handleSubmit(_, data) {
    const body = { status: data?.new_status, itemId: item?.id }
    updateStatus(body)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Update status</ModalHeader>
      <ModalBody>
        <AvForm
          id={updateStatusFormId}
          onValidSubmit={handleSubmit}
          model={{ new_status: '' }}
        >
          <AvField
            name='old_status'
            label='Old status'
            value={item.status}
            disabled
            className='rp-capitalize'
          />

          <AvField name='new_status' type='select' label='New status'>
            <option value='' disabled>
              Select new status
            </option>
            {statusOptions
              .filter((o) => o.value !== item.status)
              .map((option) => {
                return (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                )
              })}
          </AvField>
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <Button
          formId={updateStatusFormId}
          type='submit'
          loading={isLoading}
          disabled={isLoading}
        >
          Update status
        </Button>
      </ModalFooter>
    </Modal>
  )
}

function Filters({ filters, onChange, cardStatusOptions }) {
  const filtersFields = [
    {
      name: statusKey,
      label: 'Fee Status',
      options: cardStatusOptions,
      type: 'select',
      isClearable: true,
    },
    // { name: 'email', label: 'Email', type: 'email' },
    // { name: 'name', label: 'Name', type: 'text' },
  ]

  return (
    <Container fluid className='px-3'>
      <Row className='mx-n2'>
        {filtersFields.map((field) => {
          return (
            <FilterField
              {...field}
              key={field.name}
              value={filters[field.name]}
              onChange={onChange}
            />
          )
        })}
      </Row>
    </Container>
  )
}
