import cx from 'classnames'
import React from 'react'

export function PageTitle({
  children,
  containerClassName,
  className,
  subtitle,
  subtitleClassName,
}) {
  return (
    <div className={containerClassName}>
      <h1
        className={cx(
          'rp-font-gilroyB font-size-32 text-gray-h mb-0',
          className,
        )}
      >
        {children}
      </h1>
      {subtitle && (
        <p className={cx('text-secondary font-size-16', subtitleClassName)}>
          {subtitle}
        </p>
      )}
    </div>
  )
}

export function PageAction({ children, className }) {
  return (
    <div
      className={cx(
        'col-6 gap-6 d-flex flex-row-reverse align-items-baseline',
        className,
      )}
    >
      {children}
    </div>
  )
}

export function PageHeading({ children, className }) {
  return <div className={cx('mb-3 mb-md-4', className)}>{children}</div>
}
