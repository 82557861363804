import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useSelector } from 'react-redux'
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import toastr from 'toastr'

import CustomDatePicker from '../../../components/Forms/CustomDatePicker/CustomDatePicker'
import { StyledH5 } from '../../../components/Typo'
import { useFetch, useResize } from '../../../helpers/hooks'
import { addPayslip, uploadPayslip } from '../../../services/api'
import expenseImage from './../../../assets/images/expense.svg'

const AddPayslipModal = ({ show, hide }) => {
  const [date, setDate] = useState(null)
  const { acceptedFiles, getRootProps, getInputProps, inputRef } = useDropzone({
    acceptedFiles: 'image/jpeg,image/png,image/gif,application/pdf',
  })
  const contract = useSelector((state) => state?.Contract?.details)

  const isMobile = useResize()
  const removeAll = () => {
    if (inputRef && inputRef.current) {
      acceptedFiles.length = 0
      acceptedFiles.splice(0, acceptedFiles.length)
      inputRef.current.value = ''
    }
  }
  const upload = useFetch({
    action: uploadPayslip,
  })
  const add = useFetch({
    action: addPayslip,
    onComplete: hide,
    onError: (err) => {
      toastr.error(err)
    },
  })
  const files = acceptedFiles.map((file) =>
    upload.isLoading ? (
      <i className='bx bx-loader bx-spin text-primary font-size-20 ml-3' />
    ) : (
      <p key={file.path}>{file.path}</p>
    ),
  )
  const submit = () => {
    if (!upload.data?.path) {
      toastr.error('Payslip file is required')
      return
    }
    add.startFetch({
      contract_id: contract?.id,
      month: date.getMonth(),
      year: date.getFullYear(),
      document: upload?.data?.path,
    })
  }
  useEffect(() => {
    if (acceptedFiles?.length > 0) {
      upload.startFetch({ document: acceptedFiles[0] })
    }
  }, [acceptedFiles])
  return (
    <div>
      <Modal
        isOpen={show}
        toggle={hide}
        centered={true}
        className={'expensesModal'}
      >
        <ModalHeader toggle={hide}>Add expense</ModalHeader>
        <ModalBody>
          <Row className='justify-content-center mb-3'>
            <Col>
              <section className='p-0'>
                <div className='dropzone border-primary'>
                  <div
                    className='dz-message needsclick bg-soft-primary'
                    {...getRootProps()}
                  >
                    <input
                      {...getInputProps()}
                      accept='image/x-png,image/gif,image/jpeg, application/pdf'
                    />

                    {!!acceptedFiles && acceptedFiles?.length > 0 ? (
                      <Row className='font-size-14 justify-content-between'>
                        {files}
                        <i
                          onClick={removeAll}
                          className='bx bx-trash text-danger'
                        />
                      </Row>
                    ) : (
                      <>
                        <div className='py-4'>
                          <img src={expenseImage} alt='' />
                        </div>
                        <Row>
                          <Col className='justify-content-center'>
                            {isMobile ? (
                              <h4>Click to upload files.</h4>
                            ) : (
                              <StyledH5 max={'16px'}>
                                Drop here or click to upload payslip{' '}
                              </StyledH5>
                            )}
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                </div>
              </section>
            </Col>
          </Row>

          <FormGroup>
            <Label className='col-form-label pt-0'>Date</Label>
            <CustomDatePicker
              value={date}
              handleOnChange={setDate}
              placeholder={'Month'}
              dateFormat='MMM - yyyy'
              showMonthYearPicker
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color='outline-light' onClick={hide}>
            Cancel
          </Button>
          <Button color='primary' onClick={submit}>
            {add.isLoading && <i className='bx bx-loader bx-spin' />} Submit
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default AddPayslipModal
