import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Badge,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'
import styled from 'styled-components'

import download from '../../assets/images/download.svg'
import Avatar from '../../components/Avatar'
import BadgeX from '../../components/Table/BadgeX'
import StyledTd from '../../components/Table/StyledTd'
import StyledTh from '../../components/Table/StyledTh'
import TableComp from '../../components/Table/TableComp'
import WiseButton from '../../components/WiseButton'
import { useFetch, usePermissions, useResize } from '../../helpers/hooks'
import permissions from '../../helpers/permissions'
import {
  downloadContractDoc,
  downloadDoc,
  getContractDocs,
  getContractorDocs,
} from '../../services/api'
import Payslips from '../Fulltime/Payslips'
import ClientDocs from './ClientDocs'
import ContractorDoc from './ContractorDocs'

const Documents = () => {
  const user = useSelector((state) => state.Account?.user)
  const isEmployee = useSelector(
    (state) => state.userProfile?.userProfile?.contractor_type === 'employee',
  )
  return user?.type === 'client' ? (
    <ClientDocs />
  ) : isEmployee ? (
    <div className='page-content'>
      <Payslips />
    </div>
  ) : (
    <ContractorDoc />
  )
}

export const DocsList = ({ docs, isContract, contractId, isAdmin }) => {
  const user = useSelector((state) => state.Account?.user)
  useEffect(() => {
    window.analytics.track('Viewed documents', {
      email_id: user?.email,
    })
  }, [])

  const isMobile = useResize()
  const { hasAccess } = usePermissions()
  const getDownloadPermission = () => {
    if (isAdmin) {
      return false
    }
    if (isContract) {
      return hasAccess(permissions.DownloadContracsDocuments)
    } else {
      return hasAccess(permissions.DownloadCompaniesDocuments)
    }
  }
  return (
    <StyledTableCont>
      {isMobile ? (
        <div>
          <Col
            className='p-3'
            style={{
              backgroundColor: '#FFFFFF',
              minHeight: '100vh',
              boxShadow: '0px 1px 0px #DFE1E6',
              borderRadius: 4,
            }}
          >
            {docs?.map((doc, key) => (
              <DocCardClient
                isContract={isContract}
                contractId={contractId}
                isAdmin={isAdmin}
                index={key}
                key={'_order_' + key}
                doc={doc}
              />
            ))}
          </Col>
        </div>
      ) : (
        <div className='table-responsive'>
          <TableComp>
            <thead className='thead-light'>
              <tr>
                <StyledTh>Name</StyledTh>
                <StyledTh>Verification</StyledTh>
                {getDownloadPermission() && <StyledTh>Download</StyledTh>}
              </tr>
            </thead>
            <tbody>
              {docs?.map((doc, key) => (
                <tr key={'_order_' + key}>
                  <StyledTd>
                    <Col>
                      <Row className='align-items-center flex-nowrap'>
                        <Avatar
                          name={doc.first_name}
                          photo={doc.img}
                          flag={doc?.flag}
                        />
                        <div
                          className='font-size-14 ml-2 rp-font-bold text-gray-h'
                          translate='no'
                        >
                          {doc.first_name} {doc.last_name}
                        </div>
                      </Row>
                    </Col>
                  </StyledTd>
                  <StyledTd>
                    <BadgeX
                      status={
                        doc.kyc_level_2 === 'Verified' ? 'success' : 'danger'
                      }
                      textStatus={
                        doc.kyc_level_2 === 'Verified' ? 'success' : 'danger'
                      }
                      name={doc.kyc_level_2}
                    />
                  </StyledTd>
                  {!isAdmin && (
                    <StyledTd>
                      {doc?.documents?.length > 0 && (
                        <DownloadDropDown
                          contractor={doc}
                          docs={doc.documents}
                          isContract={isContract}
                          contractId={contractId}
                        />
                      )}
                    </StyledTd>
                  )}
                </tr>
              ))}

              <div className='p-lg-5'></div>
              <div className='p-lg-5'></div>
            </tbody>
          </TableComp>
        </div>
      )}
    </StyledTableCont>
  )
}

const StyledTableCont = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 0px #dfe1e6;
  border-radius: 4px;
`

const DownloadDropDown = ({ docs, isContract, contractId, contractor }) => {
  const getType = (type) => {
    switch (type) {
      case 'application/pdf':
        return 'pdf'
      case 'image/jpeg':
        return 'jpeg'
      case 'image/png':
        return 'png'
      default:
        return ''
    }
  }
  const [isOpen, setIsOpen] = useState(false)
  const user = useSelector((state) => state.Account?.user)
  const handleDownloadDocs = (doc) => {
    window.analytics.track('Clicked download document', {
      contractor_id: contractor?.id,
    })
    const callBack = (r) => {
      const url = window.URL.createObjectURL(new Blob([r.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${doc?.name}.${getType(r?.data?.type)}`) // or any other extension
      document.body.appendChild(link)
      link.click()
    }
    if (isContract) {
      downloadContractDoc(user?.token, {
        token: doc.id,
        id: contractId,
      })
        .then(callBack)
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e)
        })
    } else {
      downloadDoc(user?.token, {
        id: doc.id,
      })
        .then(callBack)
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e)
        })
    }
  }
  const documents = useFetch({
    autoFetch: docs?.length > 0,
    initResult: [],
    action: isContract ? getContractDocs : getContractorDocs,
    body: { ...docs[0], id: contractId },
  })

  if (documents.isLoading) {
    return <span className='px-2 py-1'>Loading ...</span>
  }

  if (documents.data.length <= 0) {
    return <span className='py-1'>No documents</span>
  }

  return (
    <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
      <DropdownToggle
        color='light'
        className='bg-transparent border-0 px-2 py-1'
      >
        <div className='d-flex align-items-center' style={{ gap: '0.5rem' }}>
          <i className='bx bx-download font-size-16' />
          Documents
          <i className='bx bxs-chevron-down font-size-16' />
        </div>
      </DropdownToggle>
      <DropdownMenu>
        {!!documents.data &&
          documents.data?.map((doc, i) => (
            <DropdownItem
              key={`doc:${i}`}
              onClick={() => handleDownloadDocs(doc)}
              href='#'
            >
              {doc?.name}
            </DropdownItem>
          ))}
      </DropdownMenu>
    </Dropdown>
  )
}

const DownloadModal = (props) => {
  const { modal, hide, docs, isContract, contractId, contractor } = props

  const getType = (type) => {
    switch (type) {
      case 'application/pdf':
        return 'pdf'
      case 'image/jpeg':
        return 'jpeg'
      case 'image/png':
        return 'png'
      default:
        return ''
    }
  }
  const user = useSelector((state) => state.Account?.user)
  const handleDownloadDocs = (doc) => {
    window.analytics.track('Clicked download document', {
      contractor_id: contractor?.id,
    })
    const callBack = (r) => {
      const url = window.URL.createObjectURL(new Blob([r.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${doc?.name}.${getType(r?.data?.type)}`) // or any other extension
      document.body.appendChild(link)
      link.click()
    }
    if (isContract) {
      downloadContractDoc(user?.token, {
        token: doc.id,
        id: contractId,
      })
        .then(callBack)
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e)
        })
    } else {
      downloadDoc(user?.token, {
        id: doc.id,
      })
        .then(callBack)
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e)
        })
    }
  }
  const documents = useFetch({
    autoFetch: docs?.length > 0,
    initResult: [],
    action: isContract ? getContractDocs : getContractorDocs,
    body: { ...docs[0], id: contractId },
  })

  return (
    <div>
      <Modal isOpen={modal} toggle={hide}>
        <ModalHeader toggle={hide}>Select Document</ModalHeader>
        <ModalBody className={'p-0 m-0'}>
          {!!documents.data &&
            documents.data?.map((doc, i) => (
              <div
                className={'p-3 border-bottom d-flex'}
                key={`doc:${i}`}
                onClick={() => handleDownloadDocs(doc)}
              >
                <img
                  src={download}
                  style={{ width: '1rem', marginRight: 10 }}
                  alt=''
                />
                <p className={'mb-0 font-weight-light font-size-14 text-dark'}>
                  {doc?.name}
                </p>
              </div>
            ))}
        </ModalBody>
      </Modal>
    </div>
  )
}

const DocCardClient = (props) => {
  const { doc, isContract, contractId, isAdmin } = props
  const [modal, setModal] = useState(false)
  return (
    <div
      className='mb-3'
      style={{
        background: '#FFFFFF',
        border: '1px solid #E7E8F2',
        borderRadius: 4,
      }}
    >
      <div className='  border-bottom p-3 m-0 p-0'>
        <p
          className='text-dark mb-0 font-size-14  font-weight-bold'
          style={{ fontWeight: 'bold' }}
          translate='no'
        >
          {doc.first_name} {doc.last_name}
        </p>
        <p className='text-dark font-size-14  mb-0 '>{doc?.id_number}</p>
      </div>

      <div className='d-flex justify-content-between align-items-center pt-3 px-3'>
        <p className='text-dark font-size-14 mb-0'> Active contracts</p>
        <p className='text-dark font-size-14 mb-0'> {doc?.contracts}</p>
      </div>
      <div className='d-flex justify-content-between align-items-center p-3'>
        <p className='text-dark font-size-14 mb-0'>Verification</p>
        <Badge
          className={`font-size-14 rounded bg-soft-${
            doc.kyc_level_2 === 'Verified' ? 'success' : 'danger'
          }`}
          pill
        >
          <p
            style={{ paddingTop: 2, paddingBottom: 2 }}
            className={`mb-0  text-${
              doc.kyc_level_2 === 'Verified' ? 'success' : 'danger'
            }`}
          >
            {doc.kyc_level_1}
          </p>
        </Badge>
      </div>

      {!isAdmin && doc?.documents?.length > 0 && (
        <div className='m-0  p-0 d-flex  px-3 pb-3'>
          <DownloadModal
            docs={doc.documents}
            isContract={isContract}
            contractId={contractId}
            contractor={doc}
            modal={modal}
            hide={() => setModal(false)}
          />
          <WiseButton
            icon={
              <img
                src={download}
                style={{ width: '1rem', marginRight: 10 }}
                alt=''
              />
            }
            bk={'white'}
            textColor={'dark'}
            border
            text={'Documents'}
            minWidth={'100%'}
            onClick={() => setModal(true)}
          />
        </div>
      )}
    </div>
  )
}

export default withTranslation()(Documents)
