import React from 'react'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import RadioGroup from '../RadioGroup'

const RolesModal = ({ show, hide, onChange, user }) => {
  const staticData = useSelector((state) => state?.Layout?.staticData)
  return (
    <Modal
      isOpen={show}
      role='dialog'
      centered
      className='exampleModal'
      tabindex='-1'
      toggle={hide}
    >
      <div className='modal-content'>
        <ModalHeader toggle={hide}>Select role</ModalHeader>
        <ModalBody>
          <RadioGroup
            defaultValue={{
              value: user?.role?.id,
              label: user?.role?.name,
            }}
            options={staticData?.roles?.map((e) => ({
              value: e.id,
              label: e.name,
              description: e?.description,
            }))}
            onChange={onChange}
          />
        </ModalBody>
      </div>
    </Modal>
  )
}

export default RolesModal
