import cx from 'classnames'
import React, { useState } from 'react'
import styled from 'styled-components'

import bankAccount from '../../../assets/images/bankAccount.svg'
import bankAccountChecked from '../../../assets/images/bankAccount_selected.svg'
import { useFetch, useResize } from '../../../helpers/hooks'
import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import { deleteBankAccount } from '../../../services/api'
import { track } from '../../../utils/analytics'
import EVENTS from '../../../utils/events'
import BankDetailsModal from './BankDetailsModal'

const WithdrawMethodCard = ({
  deactivated,
  withTag,
  withRemove,
  card,
  onUpdate,
  selected,
  onSelect,
  onClick = () => {},
  checked,
}) => {
  const [showDetails, setShowDetails] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const isMobile = useResize()

  let accountNumber = ''
  let address = ''
  try {
    accountNumber =
      JSON.parse(card?.details ?? '{}')?.iban ||
      JSON.parse(card?.details ?? '{}')?.accountNumber ||
      JSON.parse(card?.details ?? '{}')?.IBAN
    address = JSON.parse(card?.details ?? '{}')?.address
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
  }
  const deleteCard = useFetch({
    action: deleteBankAccount,
    onComplete: () => {
      onUpdate()
      track(EVENTS.REMOVED_WITHDRAW_METHODS, {
        withdraw_account_name: card.holder_name,
        withdraw_method: 'Bank Transfer',
        currency: card?.currency,
        country: address?.country,
        is_default: false,
      })
    },
  })

  return (
    <button
      className={cx(`position-relative p-4 text-left rounded cursor-default`, {
        'border-primary': checked,
        'bg-soft-secondary': deactivated,
        'bg-white': !deactivated,
      })}
      style={{ border: '1px solid #e7e8f2' }}
      type='button'
      disabled={deactivated}
      onClick={() => onClick(card)}
    >
      {withRemove && !deactivated && (
        <StyledTrash>
          {deleteCard.isLoading ? (
            <i className='bx bx-loader bx-spin text-danger font-size-14 align-middle ml-2' />
          ) : (
            <button
              type='button'
              className='rp-btn-nostyle d-flex p-1 text-muted font-size-16 hover:bg-slate-50'
              onClick={(e) => {
                e.stopPropagation()
                setShowConfirm(true)
              }}
            >
              <i className='bx bxs-trash-alt' />
            </button>
          )}
        </StyledTrash>
      )}
      <div
        className='d-flex flex-column flex-md-row align-items-start align-items-md-center'
        style={{ gap: '1.5rem' }}
      >
        <div
          className='position-relative'
          style={{ top: isMobile ? undefined : -4 }}
        >
          <StyledBank
            className={`${isMobile ? 'mb-md-2' : 'mb-md-0'}`}
            src={checked ? bankAccountChecked : bankAccount}
            alt={'Wallet icon'}
          />
          <div
            className={`currency-flag currency-flag-${card?.currency.toLowerCase()}`}
            style={{ position: 'absolute', bottom: '-8px', right: '-8px' }}
          />
        </div>

        <div
          className='d-flex flex-column align-items-start'
          style={{ gap: '0.25rem' }}
        >
          <h5
            className={cx('mb-0 font-size-16 rp-font-gilroy-B rp-font-bold', {
              'text-primary': checked,
            })}
          >
            {'' + card.holder_name}
          </h5>
          <div className='d-flex' style={{ gap: '0.25rem' }}>
            <div
              className={`${
                checked ? 'text-primary' : 'text-secondary'
              } font-size-14`}
            >
              {card?.currency} account ending in{' '}
              {accountNumber?.slice(
                accountNumber?.length - 4,
                accountNumber?.length,
              )}
            </div>

            <button
              className={`rp-btn-nostyle d-flex p-0 hover:bg-slate-50 ${
                checked ? 'text-primary' : 'text-secondary'
              }`}
              type='button'
              onClick={(e) => {
                e.stopPropagation()
                setShowDetails(true)
              }}
              title='Show details'
            >
              <i className='bx bx-show font-size-20' />
            </button>
          </div>

          {withTag && (
            <button
              onClick={(e) => {
                e.stopPropagation()
                onSelect?.(e)
              }}
              className={`btn rounded-sm font-size-10 ${
                deactivated
                  ? 'btn-dark'
                  : selected
                  ? 'btn-primary'
                  : 'btn-outline-light'
              } py-0 px-1 font-weight-normal`}
              disabled={deactivated}
            >
              {deactivated
                ? 'Deactivated'
                : selected
                ? 'Default'
                : 'Make default'}
            </button>
          )}
        </div>
      </div>

      {showDetails && (
        <BankDetailsModal
          isOpen={showDetails}
          toggle={() => setShowDetails(false)}
          accountId={card?.id}
        />
      )}

      {showConfirm && (
        <ConfirmationModal
          toggle={() => setShowConfirm(false)}
          isOpen={showConfirm}
          title={'Delete Bank Account'}
          message={'Are you sure you want to delete this account?'}
          onConfirm={() => {
            setShowConfirm(false)
            deleteCard.startFetch({ id: card?.id })
          }}
        />
      )}
    </button>
  )
}

const StyledTrash = styled.div`
  z-index: 9;
  position: absolute;
  top: 12px;
  right: 12px;
`
const StyledBank = styled.img`
  width: 1.8rem;
`
export default WithdrawMethodCard
