import { AvField } from 'availity-reactstrap-validation'
import React, { useState } from 'react'

import WiseButton from '../WiseButton'
import eye from '../../assets/images/eye.svg'

const TextInput = (props) => {
  const {
    disabled,
    value,
    name,
    id,
    label,
    required,
    type,
    placeholder,
    validate,
    autocomplete,
    onChange,
    onKeyUp,
    onBlur,
    translate,
  } = props
  const [typo, setTypo] = useState(type)

  return (
    <div>
      <p
        style={{
          marginBottom: '8px',
          fontFamily: 'GilroyB',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '14px',
          color: '#0F1035',
        }}
      >
        {label}
        {required ? (
          <span className='text-danger font-size-16 ml-1'>*</span>
        ) : null}
      </p>
      <div style={{ position: 'relative' }}>
        <AvField
          disabled={disabled}
          autoComplete={autocomplete}
          validate={validate}
          required={required}
          type={typo || 'text'}
          placeholder={placeholder || ''}
          name={name}
          value={value}
          id={id}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
          translate={translate}
        />
        {type === 'password' && (
          <div
            style={{
              position: 'absolute',
              top: '0',
              right: 4,
              width: '32px',
            }}
          >
            <WiseButton
              onClick={() => {
                typo === 'password' ? setTypo('text') : setTypo('password')
              }}
              textColor={'black'}
              minWidth={'32px'}
              width={'32px'}
              padding={'0'}
              icon={
                <img src={eye} style={{ width: '12px' }} alt={'eye icon'} />
              }
              bk={'white'}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default TextInput
