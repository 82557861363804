import cx from 'classnames'
import React from 'react'

export default function SimpleMessage({
  minHeight = '20vh',
  children,
  className,
  style,
}) {
  return (
    <p
      className={cx(
        'align-items-center d-flex font-size-16 justify-content-center mb-0 p-4 rp-font-gilroyB text-center text-muted',
        className,
      )}
      style={{ minHeight, ...style }}
    >
      {children}
    </p>
  )
}
