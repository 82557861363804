import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Spinner,
} from 'reactstrap'
import Row from 'reactstrap/es/Row'
import Alert from 'reactstrap/lib/Alert'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import * as yup from 'yup'

import ControlledDatePicker from '../../components/ControlledDatePicker'
import ControlledInput from '../../components/ControlledInput'
import ControlledPhoneInput from '../../components/ControlledPhoneInput'
import ControlledSelect from '../../components/ControlledSelect'
import CustomSelect from '../../components/Forms/CustomSelect/CustomSelect'
import Button from '../../components/ui/button'
import FEATURE_FLAGS from '../../config/feature-flags'
import { contractorTypes } from '../../helpers/enum'
import { useFetch } from '../../helpers/hooks'
import { nameRegExp } from '../../helpers/info-latin-regex'
import {
  getContractorInfo,
  getNotificationList,
  updateContractorProfile,
  updateContractorType,
  validatePhoneNumber,
} from '../../services/api'
import {
  loginUserSuccessful,
  updateUserInfo,
} from '../../store/auth/register/actions'
import {
  updateContractRef,
  updateUserNotifications,
  updateUserProfileInfo,
} from '../../store/profile/actions'
import { mapCurrencyToOption } from '../../utils/map-to-option'

const schema = (countries) =>
  yup.object().shape({
    country_id: yup.string().required(),
    state_id: yup.string().when('country_id', (value) => {
      if (countries?.find((c) => '' + c.id === value)?.states?.length) {
        return yup.string().required()
      }
    }),
    city: yup.string().required(),
    currency_id: yup.string().required(),
    address: yup
      .string()
      .required()
      .matches(
        /(^[-0-9A-Za-z.,/ ]+$)/,
        'Your address must contain only letters and numbers',
      ),
    phone: yup.string().required(),
    birth_date: yup.string().required(),
    document_type: yup.string().required(),
    document_number: yup.string().required(),
    Country_of_Citizenship: yup.string().required(),
    Country_of_Tax_Residence: yup.string().required(),
    first_name: yup
      .string()
      .required()
      .matches(nameRegExp, 'Your first name must contain only letters'),
    last_name: yup
      .string()
      .required()
      .matches(nameRegExp, 'Your last name must contain only letters'),
    middle_name: yup
      .string()
      .nullable()
      .matches(nameRegExp, 'Your middle name must contain only letters'),
    Tax_ID: yup.string().nullable(),
  })

const types = [
  { value: contractorTypes.ENTITY, label: 'Entity' },
  {
    value: contractorTypes.INDIVIDUAL,
    label: 'Individual',
  },
]

export const switchingContractorToEntity = 'switching-contractor-to-entity'

const ProfileInfoNew = React.forwardRef(function ProfileInfoNew(
  { onStart = () => {}, isCompletion, onCompleted, countries = [], data },
  ref,
) {
  const history = useHistory()
  const staticData = useSelector((state) => state?.Layout?.staticData)
  const contractRef = useSelector((state) => state?.userProfile?.contractRef)

  const isEmployee =
    useSelector((state) => state?.userProfile?.userProfile?.contractor_type) ===
    'employee'

  const { kyc_status: kycStatus, kyc_verified: kycVerified } = useSelector(
    (state) => state?.userProfile?.userProfile,
  )

  const isVerified =
    FEATURE_FLAGS.LOCK_PERSONAL_INFO &&
    kycStatus === 'verified' &&
    kycVerified === 1

  const defaultValues = {
    country_id: data?.country?.id,
    state_id: data?.state?.id,
    city: data?.city,
    currency_id: data?.currency?.id,
    address: data?.address,
    phone: data?.phone,
    birth_date: data?.birth_date,
    document_type: data?.document?.type?.id,
    document_number: data?.document?.number,
    Country_of_Citizenship: data?.Country_of_Citizenship?.id,
    Country_of_Tax_Residence: data?.Country_of_Tax_Residence?.id,
    first_name: data?.first_name,
    last_name: data?.last_name,
    middle_name: data?.middle_name,
    Tax_ID: data?.Tax_ID,
    zip_code: data?.zip_code,
  }

  const {
    handleSubmit,
    control,
    watch,

    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(schema(countries)),
    defaultValues,
  })

  const [loading, setLoading] = useState(true)
  const [type, setType] = useState(null)
  const dispatch = useDispatch()
  const notifications = useFetch({
    autoFetch: true,
    initResult: null,
    action: getNotificationList,
    onComplete: (data) => {
      dispatch(updateUserNotifications(data))
    },
  })
  const updateContractor = useFetch({
    autoFetch: false,
    initResult: null,
    action: updateContractorProfile,
    onComplete: (data) => {
      window.analytics.track('Updated profile settings', {
        'email-id': user?.email,
      })
      if (data?.success === false) {
        toastr.error(data?.message, 'Error')
        return
      }
      toastr.success('Profile update successfully')
      dispatch(updateUserInfo(data || {}))
      dispatch(updateUserProfileInfo(data || {}))
      notifications.startFetch()
      if (isCompletion) {
        onCompleted()
      }
      if (contractRef) {
        if (isVerified) {
          history.push(`/contract/detail?id=${contractRef?.ref}`)
          dispatch(updateContractRef(null))
        } else {
          history.push('/settings#kyc')
        }
      }
    },
  })

  const user = useSelector((state) => state?.Account?.user)

  useEffect(() => {
    try {
      getContractorInfo(user?.token)
        .then((r) => {
          setLoading(false)
          if (r.data.success) {
            setType(
              types.find((e) => e.value === r.data?.data?.contractor_type),
            )
          }
        })
        .catch(() => setLoading(false))
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }, [user?.token])

  const update = useFetch({
    action: updateContractorType,
    onComplete: (data, body) => {
      dispatch(loginUserSuccessful(data))
      dispatch(updateUserProfileInfo({ contractor_type: body?.type }))
    },
  })
  const onTypeChanged = (newType) => {
    setType(newType)
    if (newType.value === contractorTypes.ENTITY) {
      history.push('/complete-company', {
        action: switchingContractorToEntity,
      })
    } else {
      update.startFetch({ type: newType?.value })
    }
  }

  const onSubmit = async (v) => {
    const skipPhoneValidation = !!JSON.parse(
      process.env.REACT_APP_SKIP_PHONE_VALIDATION ?? true,
    )

    if (!skipPhoneValidation) {
      const { data } = await validatePhoneNumber(user?.token, {
        phone: v.phone,
      })
      const { valid: isPhoneValid } = data?.data ?? {}

      if (isPhoneValid !== null && !isPhoneValid) {
        toastr.error('Make sure to enter a valid phone number.')
        return
      }
    }

    if (!type && !isEmployee) {
      toastr.error('Contractor type is required')
      return
    }

    onStart()
    updateContractor.startFetch(v)
  }
  const otherCountries = staticData?.other_countries
    ? [...(staticData?.other_countries ?? [])]
    : []

  const mapCountry = (e) => {
    return { value: e.id, label: e.name, flag: `/${e.svg}` }
  }

  const nationalities = [
    ...(staticData?.countries ?? []),
    ...(otherCountries ?? []),
  ].map(mapCountry)
  const allCountries = staticData?.countries?.map(mapCountry)

  return (
    <div>
      {loading ? (
        <Container style={{ minHeight: '30rem' }}>
          <Col style={{ minHeight: '30rem' }}>
            <Row
              style={{ minHeight: '30rem' }}
              className='justify-content-center align-items-center'
            >
              <Spinner type='grow' className='mr-2' color='primary' />
            </Row>
          </Col>
        </Container>
      ) : (
        <CardBody className='p-0 pt-3 pt-md-4'>
          <Form
            autocomplete='off'
            className=' '
            ref={ref}
            onSubmit={handleSubmit(onSubmit)}
          >
            {isEmployee ? null : (
              <>
                <Col md={6} sm={12} className='mb-3'>
                  <FormGroup className='mb-4' disabled={isVerified}>
                    <Label className='col-form-label p-0 m-0 mb-2 font-size-14 '>
                      Contractor Type
                    </Label>
                    <CustomSelect
                      value={type}
                      onChange={onTypeChanged}
                      options={types}
                      isDisabled={isVerified}
                    />
                  </FormGroup>
                </Col>
                <Col sm={12} className='mb-3'>
                  <Alert color='info'>
                    Spell your name exactly as it’s shown on your passport or ID
                    card. After you’ve been verified, you can’t edit some of the
                    fields
                  </Alert>
                </Col>
              </>
            )}
            <Row className='p-0 m-0'>
              <Col md={6} sm={12} className='mb-3'>
                <FormGroup
                  style={{ whiteSpace: 'nowrap' }}
                  className='d-inline p-0 m-0'
                  disabled={isVerified}
                >
                  <Label className='col-form-label p-0 m-0 mb-2 font-size-14 '>
                    First Name
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <ControlledInput
                    control={control}
                    error={errors.first_name}
                    name='first_name'
                    disabled={isVerified}
                  />
                </FormGroup>
              </Col>
              <Col md={6} sm={12} className='mb-3'>
                <FormGroup
                  className='d-inline p-0 m-0'
                  row={!isCompletion}
                  disabled={isVerified}
                >
                  <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                    Middle name (Optional)
                  </Label>
                  <ControlledInput
                    control={control}
                    name='middle_name'
                    error={errors.middle_name}
                    disabled={isVerified}
                  />
                </FormGroup>
              </Col>
              <Col md={6} sm={12} className='mb-3'>
                <FormGroup
                  style={{ whiteSpace: 'nowrap' }}
                  className='d-inline p-0 m-0'
                  disabled={isVerified}
                >
                  <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                    Last Name
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <ControlledInput
                    control={control}
                    error={errors.last_name}
                    name='last_name'
                    disabled={isVerified}
                  />
                </FormGroup>
              </Col>

              <Col md={6} sm={12} className='mb-3'>
                <FormGroup className='d-inline p-0 m-0'>
                  <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                    Nationality
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <ControlledSelect
                    options={nationalities}
                    control={control}
                    name='Country_of_Citizenship'
                    error={errors.Country_of_Citizenship}
                  />
                </FormGroup>
              </Col>

              <Col md={6} sm={12} className='mb-3'>
                <FormGroup
                  style={{ whiteSpace: 'nowrap' }}
                  className='d-inline p-0 m-0 '
                >
                  <ControlledPhoneInput
                    control={control}
                    error={errors.phone}
                    name='phone'
                    label={
                      <>
                        Phone Number{' '}
                        <span className='text-danger font-size-16 mx-1'>*</span>
                      </>
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={6} sm={12} className='mb-3'>
                <FormGroup className='d-inline p-0 m-0'>
                  <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                    Tax ID (optional)
                  </Label>
                  <ControlledInput
                    control={control}
                    name='Tax_ID'
                    error={errors.Tax_ID}
                  />
                </FormGroup>
              </Col>
              <Col md={6} sm={12} className='mb-3'>
                <FormGroup
                  style={{ whiteSpace: 'nowrap' }}
                  className='d-inline p-0 m-0 '
                >
                  <Label className='col-form-label p-0 m-0 mb-2 font-size-14 '>
                    Tax Residence
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <ControlledSelect
                    options={allCountries}
                    control={control}
                    error={errors.Country_of_Tax_Residence}
                    name='Country_of_Tax_Residence'
                  />
                </FormGroup>
              </Col>
              <Col md={6} sm={12} className='mb-3'>
                <FormGroup
                  className='d-inline p-0 m-0'
                  style={{ zIndex: 5, position: 'relative' }}
                >
                  <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                    Currency
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <ControlledSelect
                    options={staticData?.currencies?.map((e) =>
                      mapCurrencyToOption(e, 'id'),
                    )}
                    control={control}
                    name='currency_id'
                    error={errors.currency_id}
                  />
                </FormGroup>
              </Col>
              <Col md={6} sm={12} className='mb-3'>
                <FormGroup
                  style={{ whiteSpace: 'nowrap' }}
                  className='d-inline p-0 m-0 '
                >
                  <Label
                    className='col-form-label p-0 m-0 mb-2 font-size-14'
                    htmlFor='address'
                  >
                    Address
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <ControlledInput
                    control={control}
                    error={errors.address}
                    name='address'
                    id='address'
                  />
                </FormGroup>
              </Col>
              <Col md={6} sm={12} className='mb-3'>
                <FormGroup className='d-inline p-0 m-0'>
                  <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                    ID / Passport
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <InputGroup style={{ flexWrap: 'nowrap' }}>
                    <ControlledInput
                      placeholder='ID / Passport'
                      name='document_number'
                      control={control}
                      error={errors.document_number}
                      showError={false}
                    />
                    <InputGroupAddon style={{ zIndex: 1 }} addonType='append'>
                      <InputGroupText
                        className={classNames({
                          'p-0': true,
                          'border-danger': !!errors.document_type,
                        })}
                        style={{ width: '126px', border: 0 }}
                      >
                        <div className='w-100'>
                          <ControlledSelect
                            isSearchable={false}
                            options={staticData?.document_types?.map((e) => ({
                              value: e.id,
                              label: e.name,
                            }))}
                            classNamePrefix='RS-Addon'
                            control={control}
                            name='document_type'
                            error={errors.document_type}
                          />
                        </div>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>

                  {errors?.document_number && (
                    <span className='text-danger font-size-12'>
                      {errors?.document_number.message}
                    </span>
                  )}
                </FormGroup>
              </Col>

              <Col md={6} sm={12} className='mb-3'>
                <FormGroup className='d-inline p-0 m-0'>
                  <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                    Country
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <ControlledSelect
                    error={errors.country_id}
                    control={control}
                    name='country_id'
                    options={
                      countries
                        ? countries.map((c) => ({
                            ...c,
                            label: c.name,
                            value: c.id,
                            flag: `/${c.svg}`,
                          }))
                        : []
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={6} sm={12} className='mb-3'>
                <FormGroup className='mb-0'>
                  <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                    Birth Date
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <ControlledDatePicker
                    control={control}
                    name='birth_date'
                    error={errors.birth_date}
                    maxDate={
                      new Date(moment().subtract(18, 'years').toISOString())
                    }
                    disabled={isVerified}
                  />
                </FormGroup>
              </Col>
              {!!countries?.find((e) => e.id === watch('country_id'))?.states
                ?.length && (
                <Col md={6} sm={12} className='mb-3'>
                  <FormGroup className='d-inline p-0 m-0' row={!isCompletion}>
                    <Label
                      htmlFor='billing-name'
                      md='3'
                      className='col-form-label p-0 m-0 mb-2 font-size-14'
                    >
                      State
                      <span className='text-danger font-size-16 mx-1'>*</span>
                    </Label>
                    <ControlledSelect
                      error={errors.state_id}
                      control={control}
                      name='state_id'
                      options={
                        watch('country_id')
                          ? countries
                              ?.find((e) => e.id === watch('country_id'))
                              ?.states?.map((s) => ({
                                label: s.name,
                                value: s.id,
                              }))
                          : []
                      }
                    />
                  </FormGroup>
                </Col>
              )}
              <Col md={6} sm={12} className='mb-3'>
                <FormGroup className='d-inline p-0 m-0' row={!isCompletion}>
                  <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                    City
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <ControlledInput
                    control={control}
                    name='city'
                    error={errors.city}
                  />
                </FormGroup>
              </Col>
              <Col md={6} sm={12} className='mb-3'>
                <FormGroup
                  style={{ whiteSpace: 'nowrap' }}
                  className='d-inline p-0 m-0 '
                >
                  <Label className='col-form-label p-0 m-0 mb-2 font-size-14 '>
                    Zip Code
                  </Label>
                  <ControlledInput
                    control={control}
                    error={errors.zip_code}
                    name='zip_code'
                  />
                </FormGroup>
              </Col>
            </Row>
            {isCompletion ? (
              <Row className='px-md-4 pb-3 pb-md-4 px-3 m-0 justify-content-start w-100'>
                <button className='btn btn-primary btn-block' type='submit'>
                  {updateContractor.isLoading && (
                    <i className='bx bx-loader bx-spin font-size-16 align-middle mr-2' />
                  )}
                  Next
                </button>
              </Row>
            ) : (
              <>
                <div className='w-100 divider border-top my-md-4 my-3' />
                <Row className={'p-0 m-0'}>
                  <Row className='px-md-4 pb-3 pb-md-4 px-3 m-0 justify-content-start w-100'>
                    <Button
                      type='submit'
                      loading={updateContractor.isLoading}
                      disabled={updateContractor.isLoading}
                      style={{ width: 146 }}
                    >
                      Save
                    </Button>
                  </Row>
                </Row>
              </>
            )}
          </Form>
        </CardBody>
      )}
    </div>
  )
})

// const customStyles = (hasError) => ({
//   menu: () => ({
//     position: 'absolute',
//     backgroundColor: 'white',
//     zIndex: 99999,
//     width: '100%',
//     borderRadius: '3px',
//     borderColor: '#dddddd',
//     borderWidth: '1px',
//     borderStyle: 'solid',
//   }),
//   dropdownIndicator: () => ({
//     paddingRight: '10px',
//     paddingLeft: '10px',
//   }),
//   indicatorSeparator: () => ({
//     width: 0,
//   }),
//   control: () => ({
//     display: 'flex',
//     flexDirection: 'row',
//     borderColor: hasError ? 'var(--red) !important' : undefined,
//   }),
// })

export default ProfileInfoNew
