import { countries } from 'countries-list'
import countriesAndCurrencies from '../data/currencies'
import { CALLING_CODES } from '../data/phoneCallingCodes'
import { DEMONYMS } from '../data/countryDemonyms'
import { sortBy, join } from 'lodash'

export const countryCodeToName = (countryCode) =>
  countries[countryCode] && countries[countryCode].name

export const countryCodeToDemonym = (countryCode) =>
  DEMONYMS[countryCode] || countryCodeToName(countryCode)

export const regionNameForCountry = (countryCode) => {
  if (countryCode === 'US') return 'State code'
  if (['IE', 'GB', 'EE'].includes(countryCode)) return 'County'
  if (countryCode === 'CA') return 'Province or territory code'
  if (countryCode === 'ES') return 'Community/Province'
  return 'Region/State/Province'
}

export const zipNameForCountry = (countryCode) => {
  if (countryCode === 'US') return 'ZIP'
  if (['AU', 'GB'].includes(countryCode)) return 'Postcode'
  if (['CA', 'ES', 'EE'].includes(countryCode)) return 'Postal code'
  return 'Postcode / ZIP'
}

export const bankingType = (countryCode) => {
  if (countryCode === 'GR') return 'iban'
  else if (countryCode === 'CA') return 'canadian-style'
  else throw new Error(`Don't know bankingType for ${countryCode}`)
}

export const currenciesByCountryCode = countriesAndCurrencies.reduce(
  (acc, country) => {
    acc[country.code] = country.currency
    return acc
  },
  {},
)

export const currenciesByCurrencyCode = countriesAndCurrencies.reduce(
  (acc, country) => {
    acc[country.currency.code] = country.currency
    return acc
  },
  {},
)

export const currencyCodeToSymbol = (code) =>
  (code && currenciesByCurrencyCode[code]?.symbol) || code

export const countryCodeToCurrencyCode = (countryCode) =>
  currenciesByCountryCode[countryCode]?.code

export const currencies2 = countriesAndCurrencies.reduce((memo, c) => {
  if (memo[c.currency.code] === undefined) memo[c.currency.code] = c.currency
  if (memo[c.currency.code].countryCodes === undefined) {
    memo[c.currency.code].countryCodes = []
  }
  memo[c.currency.code].countryCodes.push(c.code)
  return memo
}, {})

export const currencyInputSelectOptions = () => {
  if (currencyInputSelectOptions.memo) return currencyInputSelectOptions.memo
  const gbp = currencies2.GBP
  const usd = currencies2.USD
  const eur = currencies2.EUR

  currencyInputSelectOptions.memo = [
    {
      label: '',
      options: [
        {
          name: gbp.name,
          label: gbp.code + ' ' + gbp.name,
          currencyCode: gbp.code,
          countryCode: 'GB',
        },
        {
          name: usd.name,
          currencyCode: usd.code,
          label: usd.code + ' ' + usd.name,
          countryCode: 'US',
        },
        {
          name: eur.name,
          currencyCode: eur.code,
          label: eur.code + ' ' + eur.name,
          countryCode: 'EU',
        },
      ],
    },
    {
      label: '',
      options: sortBy(Object.values(currencies2), 'code')
        .filter((c) => c.name)
        .map((c) => {
          return {
            name: c.name,
            currencyCode: c.code,
            label:
              c.code +
              ' ' +
              c.name +
              ' ' +
              join(
                c.countryCodes.map((code) => countryCodeToName(code)),
                ' ',
              ),
            countryCode: c.code === 'EUR' ? 'EU' : c.countryCodes[0],
          }
        }),
    },
  ]
  return currencyInputSelectOptions.memo
}

export const COUNTRY_TAG_LIST = Object.keys(countries)
  .map((code) => {
    return {
      id: code,
      name: countries[code].name,
      label: countries[code].name,
      value: code,
    }
  })
  .sort(function (a, b) {
    return a.name.localeCompare(b.name)
  })

const sortedCountryData = Object.keys(countries)
  .map(function (shortCode) {
    const countryData = countries[shortCode]
    countryData.shortCode = shortCode
    return countryData
  })
  .sort(function (a, b) {
    return a.name.localeCompare(b.name)
  })

const COUNTRIES_SANCTIONED_BY_US = ['CU', 'IR', 'KP', 'SY']

export const countrySelectOptions = (
  priorityCountries = true,
  sanctionedCountries = true,
) => {
  const result = [
    ...sortedCountryData.filter(
      (c) =>
        sanctionedCountries ||
        !COUNTRIES_SANCTIONED_BY_US.includes(c.shortCode),
    ),
  ]
  if (priorityCountries) {
    result.unshift({ name: '----', shortCode: '', reactKey: 'spacer' })
    result.unshift({ ...countries.US, reactKey: 'priority-US' })
    result.unshift({ ...countries.GB, reactKey: 'priority-GB' })
  }
  result.unshift({ name: '----', shortCode: '', reactKey: 'spacer2' })
  countrySelectOptions.memo = result
  return result
}

export const countrySelectOptionsReactSelect = (
  priorityCountries = true,
  sanctionedCountries = true,
  countryCodes = null,
) => {
  const result = []
  const gb = countries.GB
  const us = countries.US

  const memoKey = `${priorityCountries} ${sanctionedCountries}`
  const memoizedResult = countrySelectOptionsReactSelect.memo.get(memoKey)
  if (memoizedResult) return memoizedResult

  if (priorityCountries) {
    const priorities = []
    if (!countryCodes || countryCodes.includes('US')) {
      priorities.push({
        label: us.name,
        code: us.shortCode,
      })
    }
    if (!countryCodes || countryCodes.includes('GB')) {
      priorities.push({
        label: gb.name,
        code: gb.shortCode,
      })
    }
    if (priorities.length > 0) {
      result.push({
        label: '',
        options: priorities,
      })
    }
  }

  result.push({
    label: '',
    options: sortedCountryData
      .filter(
        (c) =>
          sanctionedCountries ||
          !COUNTRIES_SANCTIONED_BY_US.includes(c.shortCode),
      )
      .filter((c) => !countryCodes || countryCodes.includes(c.shortCode))
      .map((c) => {
        return {
          label: c.name,
          code: c.shortCode,
        }
      }),
  })

  countrySelectOptionsReactSelect.memo.set(memoKey, result)
  return result
}
countrySelectOptionsReactSelect.memo = new Map()

export const phoneInputSelectOptions = () => {
  if (phoneInputSelectOptions.memo) return phoneInputSelectOptions.memo

  return [
    {
      label: '',
      options: [{ ...countries.GB }, { ...countries.US }],
    },
    {
      label: '',
      options: sortedCountryData.map((c) => {
        c.callingCode = CALLING_CODES[c.shortCode]
        c.label = c.name
        return c
      }),
    },
  ]
}

export default {
  countryCodeToName,
  currencyCodeToSymbol,
  currenciesByCountryCode,
  currenciesByCurrencyCode,
  countryCodeToCurrencyCode,
  regionNameForCountry,
  zipNameForCountry,
  countryCodeToDemonym,
  bankingType,
  countrySelectOptions,
  phoneInputSelectOptions,
  COUNTRY_TAG_LIST,
}
