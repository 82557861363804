import { yupResolver } from '@hookform/resolvers/yup'
import cx from 'classnames'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Alert,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import { completeContractorEntity } from '../../components/ActionCenter'
import ControlledDropzoneInput from '../../components/controlled-dropzone-input'
import ControlledInput from '../../components/ControlledInput'
import ControlledSelect from '../../components/ControlledSelect'
import Button from '../../components/ui/button'
import WiseButton from '../../components/WiseButton'
import { contractorTypes, userTypes } from '../../helpers/enum'
import { useFetch } from '../../helpers/hooks'
import {
  createEntity,
  getCompanyInfo,
  getNotificationList,
  updateCompanyInfo,
} from '../../services/api'
import {
  updateContractRef,
  updateProfileCompany,
  updateUserNotifications,
} from '../../store/profile/actions'
import { mapCurrencyToOption } from '../../utils/map-to-option'
import { switchingContractorToEntity } from '../ContractorSettings/ProfileInfoNew'

const schema = (countries, requireDoc, isOnboarding, addNbEmployees) => {
  let baseSchema = yup.object().shape({
    country_id: yup.string().required(),
    state_id: yup.string().when('country_id', (value) => {
      if (countries?.find((c) => '' + c.id === value)?.states?.length) {
        return yup.string().required()
      }
    }),
    city: yup.string().required(),
    type_id: yup.string().required(),
    currency_id: yup.string().required(),
    name: yup.string().required(),
    registration_no: yup.string().required(),
    vat: yup.string().nullable(),
    address: yup.string().required(),
    registration_document: requireDoc
      ? yup.mixed().required()
      : yup.mixed().notRequired(),
  })

  // if (addNbEmployees) {
  //   baseSchema = baseSchema.shape({
  //     employeeNumber: yup.string().required(),
  //   })
  // }

  if (isOnboarding) {
    baseSchema = baseSchema.shape({
      dba: yup.string().nullable(),
    })
  }

  return baseSchema
}

function getDefaultValues(data) {
  return {
    ...data,
    country_id: data?.country?.id,
    state_id: data?.state?.id,
    type_id: data?.type?.id,
    currency_id: data?.currency?.id || 1,
    registration_document: null,
    employeeNumber: data?.nb_employees,
  }
}

const CompanyInfo = React.forwardRef(function CompanyInfo(
  {
    isCompletion,
    onComplete = () => {},
    isCreation,
    countries,
    data,
    setLoading = () => {},
    isOnboarding,
    onUpdate = () => {},
  },
  ref,
) {
  const [isCompanyActive, setIsCompanyActive] = React.useState(
    data?.approved === 1,
  )

  const history = useHistory()
  const locationState = history.location.state

  const completeEntityDetails =
    locationState?.action === completeContractorEntity
  const switchContractorToEntity =
    locationState?.action === switchingContractorToEntity

  const defaultValues = getDefaultValues(data)
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
    reset,
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(
      schema(
        countries,
        isCompletion || completeEntityDetails,
        isOnboarding,
        switchContractorToEntity,
      ),
    ),
    defaultValues,
  })

  const user = useSelector((state) => state.Account?.user)
  const staticData = useSelector((state) => state.Layout?.staticData)
  const contractRef = useSelector((state) => state?.userProfile?.contractRef)
  const contractorType = useSelector(
    (state) => state?.userProfile?.userProfile?.contractor_type,
  )
  const isEntity = contractorType === contractorTypes.ENTITY

  const dispatch = useDispatch()
  const notifications = useFetch({
    autoFetch: !!user?.id,
    initResult: null,
    action: getNotificationList,
    onComplete: (data) => {
      dispatch(updateUserNotifications(data))
    },
  })

  const create = useFetch({
    action: createEntity,
    onComplete,
    onError: (err) => {
      toastr.error(err)
    },
  })
  const edit = useFetch({
    action: updateCompanyInfo,
    onError: (err) => toastr.error(err),
    onComplete: (data) => {
      if (isOnboarding) {
        window.analytics.track('onboarding_add_company_info', {
          email_id: user?.email,
        })
        onComplete()
      } else {
        if (data?.success === false) {
          toastr.error(data?.message || 'Error updating company info')
        } else {
          toastr.success('Updated Successfully')
          window.analytics.track('Updated company settings', {
            email_id: user?.email,
          })
          onUpdate()
          dispatch(updateProfileCompany(data))
          if (isCompletion) {
            onComplete()
          }
          notifications.startFetch()
          if (contractRef) {
            history.push(`/contract/detail?id=${contractRef?.ref}`)
            dispatch(updateContractRef(null))
          }
        }
      }
    },
  })

  const onSubmit = ({ registration_document: registrationDocument, ...v }) => {
    let values = { ...v, logo: typeof v?.logo === 'string' ? null : v?.logo }
    delete values.official_logo
    delete values.cropped_official_logo

    if (isCompletion && registrationDocument?.[0]) {
      values.registration_document = registrationDocument[0]
    }

    if (completeEntityDetails) {
      values = {
        registration_document: registrationDocument[0],
        currency_id: v.currency_id,
      }
    } else if (isCompanyActive) {
      values = { currency_id: v.currency_id }
    }

    if (!isCreation) {
      const body = { ...values }
      if (!isEntity) {
        body.nb_employees = values?.employeeNumber
      } else {
        delete body.employeeNumber
        delete body.nb_employees
      }
      edit.startFetch(body)
    } else {
      create.startFetch(values)
    }
  }

  const onError = (errors) => {
    setLoading(false)

    const errEntries = Object.entries(errors)
    if (errEntries?.length > 2) {
      toastr.error('Please fill all required fields')
    } else {
      let errorStr = ''
      errEntries.forEach(([key, value]) => {
        const msg = value?.message
        errorStr += `<strong>${key}</strong><p>${msg}</p>`
      })

      toastr.error(errorStr, 'Errors', { allowHtml: true })
    }
  }

  useFetch({
    action: getCompanyInfo,
    autoFetch: completeEntityDetails,
    onComplete: (data) => {
      reset(getDefaultValues(data))
      setIsCompanyActive(data?.approved === 1)
    },
  })

  return (
    <CardBody className='px-0 pb-0 pt-3 pt-md-4'>
      <Container>
        <Row>
          <Col>
            <Alert color='info' className='mb-0'>
              You cannot change these details after your account is verified.
            </Alert>
          </Col>
        </Row>
      </Container>

      <Form
        id='company-info-form'
        ref={ref}
        autoComplete='off'
        className='pt-3 pt-md-4'
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <Container>
          <Row>
            <Col md={isOnboarding ? 12 : 6} sm={12} className='mb-3'>
              <FormGroup
                className='m-0'
                row={!isCompletion}
                disabled={isCompanyActive}
              >
                <Label
                  style={{ whiteSpace: 'nowrap' }}
                  htmlFor='company-name'
                  className='p-0 mb-2 font-size-14'
                >
                  Company Legal Name
                  <span className='text-danger font-size-16 ml-1'>*</span>
                </Label>
                <ControlledInput
                  control={control}
                  name='name'
                  id='company-name'
                  error={errors.name}
                  disabled={isCompanyActive}
                  translate='no'
                />
              </FormGroup>
            </Col>
            <Col md={isOnboarding ? 12 : 6} sm={12} className='mb-3'>
              <FormGroup
                style={{ whiteSpace: 'nowrap' }}
                className='d-inline m-0'
                row={!isCompletion}
                disabled={isCompanyActive}
              >
                <Label className='p-0 mb-2 font-size-14'>
                  Company Type
                  <span className='text-danger font-size-16 ml-1'>*</span>
                </Label>
                <ControlledSelect
                  name='type_id'
                  error={errors.type_id}
                  control={control}
                  options={staticData?.company_types?.map((e) => ({
                    value: e.id,
                    label: e.name,
                  }))}
                  isDisabled={isCompanyActive}
                />
              </FormGroup>
            </Col>
            {user?.type === userTypes.COMPANY && !isOnboarding && (
              <Col md={isOnboarding ? 12 : 6} sm={12} className='mb-3'>
                <FormGroup
                  style={{ whiteSpace: 'nowrap' }}
                  className='m-0'
                  row={!isCompletion}
                  disabled={isCompanyActive}
                >
                  <Label htmlFor='dba' className='p-0 mb-2 font-size-14'>
                    Doing Business As (Optional)
                  </Label>
                  <ControlledInput
                    control={control}
                    name='dba'
                    id='dba'
                    error={errors.dba}
                    disabled={isCompanyActive}
                    translate='no'
                  />
                </FormGroup>
              </Col>
            )}
            <Col md={isOnboarding ? 12 : 6} sm={12} className='mb-3'>
              <FormGroup
                style={{ whiteSpace: 'nowrap' }}
                className='m-0'
                row={!isCompletion}
                disabled={isCompanyActive}
              >
                <Label
                  htmlFor='registration_no'
                  className='p-0 mb-2 font-size-14'
                >
                  Registration Number
                  <span className='text-danger font-size-16 ml-1'>*</span>
                </Label>
                <ControlledInput
                  control={control}
                  name='registration_no'
                  id='registration_no'
                  error={errors.registration_no}
                  disabled={isCompanyActive}
                />
              </FormGroup>
            </Col>

            {!isEntity && user?.type === userTypes.COMPANY && (
              <Col md={isOnboarding ? 12 : 6} sm={12} className='mb-3'>
                <FormGroup
                  style={{ whiteSpace: 'nowrap' }}
                  className='m-0'
                  row={!isCompletion}
                  disabled={isCompanyActive}
                >
                  <Label
                    htmlFor='employeeNumber'
                    className='p-0 mb-2 font-size-14'
                  >
                    Number of employees
                    <span className='text-danger font-size-16 ml-1'>*</span>
                  </Label>
                  <ControlledInput
                    control={control}
                    name='employeeNumber'
                    id='employeeNumber'
                    error={errors.employeeNumber}
                    disabled={isCompanyActive}
                  />
                </FormGroup>
              </Col>
            )}
            {/* <Col md={isOnboarding ? 12 : 6} sm={12} className='mb-3'>
              <FormGroup
                style={{ whiteSpace: 'nowrap' }}
                className='m-0'
                row={!isCompletion}
                disabled={isCompanyActive}
              >
                <Label htmlFor='vat' className='p-0 mb-2 font-size-14'>
                  VAT NO (Optional)
                </Label>
                <ControlledInput
                  control={control}
                  name='vat'
                  id='vat'
                  error={errors.vat}
                  disabled={isCompanyActive}
                />
              </FormGroup>
            </Col> */}
            <Col md={isOnboarding ? 12 : 6} sm={12} className='mb-3'>
              <FormGroup className='d-inline m-0' row={!isCompletion}>
                <Label className='p-0 mb-2 font-size-14'>
                  Currency
                  <span className='text-danger font-size-16 ml-1'>*</span>
                </Label>
                <ControlledSelect
                  error={errors.currency_id}
                  control={control}
                  name='currency_id'
                  options={
                    staticData?.company_currencies
                      ? staticData.company_currencies.map((c) =>
                          mapCurrencyToOption(c, 'id'),
                        )
                      : []
                  }
                />
              </FormGroup>
            </Col>
            <Col md={isOnboarding ? 12 : 6} sm={12} className='mb-3'>
              <FormGroup
                className='m-0'
                row={!isCompletion}
                disabled={isCompanyActive}
              >
                <Label htmlFor='address' className='p-0 mb-2 font-size-14'>
                  Address
                  <span className='text-danger font-size-16 ml-1'>*</span>
                </Label>

                <ControlledInput
                  control={control}
                  name='address'
                  id='address'
                  error={errors.address}
                  disabled={isCompanyActive}
                  translate='no'
                />
              </FormGroup>
            </Col>
            <Col md={isOnboarding ? 12 : 6} sm={12} className='mb-3'>
              <FormGroup
                className='d-inline m-0'
                row={!isCompletion}
                disabled={isCompanyActive}
              >
                <Label className='p-0 mb-2 font-size-14'>
                  Country
                  <span className='text-danger font-size-16 ml-1'>*</span>
                </Label>
                <ControlledSelect
                  error={errors.country_id}
                  control={control}
                  name='country_id'
                  options={
                    countries
                      ? countries.map((c) => ({
                          ...c,
                          label: c.name,
                          value: c.id,
                          flag: `/${c.svg}`,
                        }))
                      : []
                  }
                  isDisabled={isCompanyActive}
                />
              </FormGroup>
            </Col>
            {!!countries?.find((e) => e.id === watch('country_id'))?.states
              ?.length && (
              <Col md={isOnboarding ? 12 : 6} sm={12} className='mb-3'>
                <FormGroup
                  className='d-inline m-0'
                  row={!isCompletion}
                  disabled={isCompanyActive}
                >
                  <Label className='p-0 mb-2 font-size-14'>
                    State
                    <span className='text-danger font-size-16 ml-1'>*</span>
                  </Label>
                  <ControlledSelect
                    error={errors.state_id}
                    control={control}
                    name='state_id'
                    options={
                      watch('country_id')
                        ? countries
                            ?.find((e) => e.id === watch('country_id'))
                            ?.states?.map((s) => ({
                              label: s.name,
                              value: s.id,
                            }))
                        : []
                    }
                    isDisabled={isCompanyActive}
                  />
                </FormGroup>
              </Col>
            )}
            <Col md={isOnboarding ? 12 : 6} sm={12} className='mb-3'>
              <FormGroup
                className='m-0'
                row={!isCompletion}
                disabled={isCompanyActive}
              >
                <Label htmlFor='city' className='p-0 mb-2 font-size-14'>
                  City
                  <span className='text-danger font-size-16 ml-1'>*</span>
                </Label>
                <ControlledInput
                  control={control}
                  name='city'
                  id='city'
                  error={errors.city}
                  disabled={isCompanyActive}
                  translate='no'
                />
              </FormGroup>
            </Col>
            {/* <Col md={isOnboarding ? 12 : 6} sm={12} className='mb-3'>
              <FormGroup
                className='m-0'
                row={!isCompletion}
                disabled={isCompanyActive}
              >
                <Label htmlFor='zip_code' className='p-0 mb-2 font-size-14'>
                  Zip Code
                </Label>

                <ControlledInput
                  control={control}
                  name='zip_code'
                  id='zip_code'
                  error={errors.zip_code}
                  disabled={isCompanyActive}
                />
              </FormGroup>
            </Col> */}
            {!isCompletion ? null : (
              <Col sm={12} className='mb-3'>
                <FormGroup className='m-0' row={!isCompletion}>
                  <Label
                    htmlFor='registration_document'
                    className='p-0 mb-2 font-size-14'
                  >
                    Registration Document
                    <span className='text-danger font-size-16 ml-1'>*</span>
                  </Label>
                  <ControlledDropzoneInput
                    control={control}
                    name='registration_document'
                    error={errors.registration_document}
                    accept='application/pdf'
                  >
                    {watch('registration_document')
                      ? watch('registration_document')[0].name
                      : getValues('registration_document')?.[0]
                      ? getValues('registration_document')?.[0]?.name
                      : 'Accepts PDF'}
                  </ControlledDropzoneInput>
                </FormGroup>
              </Col>
            )}
          </Row>
        </Container>

        {isCompletion ? (
          <Container>
            <Row
              className={cx('pb-4 pt-2 m-0', {
                'd-none': isOnboarding,
              })}
            >
              <Button
                type='submit'
                block={!isCreation}
                loading={edit.isLoading || create.isLoading}
                disabled={edit.isLoading || create.isLoading}
              >
                {isCreation
                  ? 'Create'
                  : completeEntityDetails || isCompletion
                  ? 'Update'
                  : 'Next'}
              </Button>
            </Row>
          </Container>
        ) : (
          <>
            <div
              className={`w-100 divider border-top my-md-4 my-3 ${
                isOnboarding && 'd-none'
              }`}
            />

            <Row
              className={`px-md-4 pb-3 pb-md-4 px-3 m-0 justify-content-start w-100 ${
                isOnboarding && 'd-none'
              }`}
            >
              <WiseButton
                id='submit-company-info-form'
                type='submit'
                width='146px'
                loading={edit.isLoading || create.isLoading}
                disabled={edit.isLoading || create.isLoading}
                text='Save'
                minWidth='100%'
              />
            </Row>
          </>
        )}
      </Form>
    </CardBody>
  )
})

export default CompanyInfo
