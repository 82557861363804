import React, { useState } from 'react'
import { Col, Container, Row, Spinner } from 'reactstrap'
import UploadModal from './components/UploadModal'
import { useFetch } from '../../helpers/hooks'
import {
  deleteContractorDocument,
  downloadContractorDoc,
  getDocsList,
} from '../../services/api'
import { useSelector } from 'react-redux'
import toastr from 'toastr'
import WiseButton from '../../components/WiseButton'
import downloadIcon from '../../assets/images/file-download.svg'
import fileIcon from '../../assets/images/fileIcon.svg'
import trash from '../../assets/images/Trashblack.svg'
import fileDownload from '../../assets/images/download.svg'
import styled from 'styled-components'
import { StyledH3, StyledH4 } from '../../components/Typo'
import { useHistory } from 'react-router'
import editImage from '../../assets/images/edit_white.png'
import secondEditImage from '../../assets/images/edit_white2.png'

const ContractorDoc = () => {
  const history = useHistory()
  const country = useSelector(
    (state) => state.userProfile?.userProfile?.Country_of_Tax_Residence,
  )
  const [isOpen, setIsOpen] = useState(false)
  const [selectedDoc, setSelectedDoc] = useState(null)
  const docsList = useFetch({
    action: getDocsList,
    autoFetch: true,
    onError: (err) => {
      toastr.error(err)
    },
  })
  return (
    <div className='page-content  '>
      <Row className='  d-flex justify-content-between align-items-center p-0 m-0 mb-4'>
        <Col xs={12} md={12} className={'p-0 m-0'}>
          <StyledH3
            min={'22px'}
            max={'32px'}
            style={{ fontFamily: 'gilroyB' }}
            className={'text-dark   mb-3'}
          >
            Documents
          </StyledH3>
          <StyledH4
            style={{ fontWeight: '400' }}
            min={'12px'}
            max={'16px'}
            className='text-secondary mb-3 '
          >{`Based on your tax country ${
            country?.name || ''
          }, below the documents you are required to provide to your
                        client.`}</StyledH4>
        </Col>
        <Col
          xs={12}
          md={6}
          className='m-0 p-0 d-flex justify-content-end'
        ></Col>
      </Row>

      {docsList.isLoading ? (
        <Container style={{ minHeight: '30rem' }}>
          <Col style={{ minHeight: '30rem' }}>
            <Row
              style={{ minHeight: '30rem' }}
              className='justify-content-center align-items-center'
            >
              <Spinner type='grow' className='mr-2' color='primary' />
            </Row>
          </Col>
        </Container>
      ) : (
        <Container fluid className={'p-0 m-0'}>
          {/* <Col> */}
          {/*  <p>{`Based on your tax country ${country?.name || ''}, below the documents you are required to provide to your */}
          {/*                client.`}</p> */}
          {/* </Col> */}

          {docsList.data?.map((e, i) => (
            <DocCard
              key={`doc${i}`}
              item={e}
              update={() => docsList.startFetch({}, false)}
              select={() => {
                if (e.is_form) {
                  history.push('/document/create')
                } else {
                  setSelectedDoc(e)
                  setIsOpen(true)
                }
              }}
            />
          ))}
          <DocCard
            item={{
              title: 'Any relevant documents (optional) ',
              subtitle: 'Upload a scanned copy of any relevant documents',
            }}
            update={() => {
              docsList.startFetch({}, false)
            }}
            select={() => {
              setSelectedDoc({
                title: 'Any relevant documents (optional) ',
                subtitle: 'Upload a scanned copy of any relevant documents',
              })
              setIsOpen(true)
            }}
          />

          <UploadModal
            update={() => docsList.startFetch({}, false)}
            isOpen={isOpen}
            toggle={() => setIsOpen(false)}
            doc={selectedDoc}
          />
        </Container>
      )}
    </div>
  )
}

const DocCard = ({ item, update = () => {}, select }) => {
  const download = useFetch({
    action: downloadContractorDoc,
    onComplete: (data) => {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${item.title}.${getType(data?.type)}`) // or any other extension
      document.body.appendChild(link)
      link.click()
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  const getType = (type) => {
    switch (type) {
      case 'application/pdf':
        return 'pdf'
      case 'image/jpeg':
      case 'image/jpg':
        return 'jpg'
      case 'image/png':
        return 'png'
      default:
        return ''
    }
  }
  const deleteDoc = useFetch({
    action: deleteContractorDocument,
    onComplete: update,
    onError: (err) => {
      toastr.error(err)
    },
  })
  const handleDownload = () => {
    download.startFetch({ token: item.token })
  }
  const handleDelete = () => {
    deleteDoc.startFetch({ token: item.token })
  }

  return (
    <StyledCard className=''>
      <div className='d-flex justify-content-start p-md-3 pb-3 p-md-0 justify-content-md-start align-items-center'>
        <img src={fileIcon} style={{ width: 24 }} alt='file icon' />
      </div>
      <Col className='col-md-7 col-12 m-0 p-0'>
        <h6
          style={{ fontFamily: 'gilroyB' }}
          className='text-dark font-size-14 font-weight-bold mb-0'
        >
          {item.title} {item?.required && !item?.token ? '(required)' : ''}
        </h6>
        {item?.token ? null : (
          <p className='text-secondary font-size-14 mb-0 mt-2'>
            {item.is_form
              ? 'Fill form to generate the document'
              : 'Upload a scanned copy'}
          </p>
        )}
      </Col>
      <Col className='w-100 justify-content-end d-flex p-0 m-0'>
        <div className='d-none d-md-flex'>
          {item?.token ? (
            download?.isLoading || deleteDoc.isLoading ? (
              <i className='bx bx-loader bx-spin font-size-16 align-middle mr-2'></i>
            ) : (
              <>
                {item.is_form ? null : (
                  <a onClick={handleDelete}>
                    <img
                      src={trash}
                      style={{ height: 18, marginRight: 24 }}
                      alt='trash icon'
                    />
                  </a>
                )}
                <a onClick={handleDownload}>
                  <img
                    src={fileDownload}
                    style={{ height: 16 }}
                    alt='trash icon'
                  />
                </a>
              </>
            )
          ) : (
            <WiseButton
              text={item?.is_form ? 'Fill form' : 'Upload Document'}
              icon={
                <img
                  src={item?.is_form ? secondEditImage : downloadIcon}
                  style={{ marginRight: 8, width: 16 }}
                  alt=''
                />
              }
              onClick={select}
            />
          )}
        </div>
        <div className='d-flex d-md-none w-100'>
          {item?.token ? (
            download?.isLoading || deleteDoc.isLoading ? (
              <div className='d-flex justify-content-center align-items-center'>
                <i className='bx bx-loader bx-spin font-size-16 align-middle mr-2'></i>
              </div>
            ) : (
              <div className='w-100 d-flex justify-content-between'>
                <WiseButton
                  textColor={'dark'}
                  onClick={handleDelete}
                  bk={'white'}
                  border
                  minWidth={'45%'}
                  text={'Delete'}
                  icon={
                    <img
                      src={trash}
                      style={{ width: 20, marginRight: 8 }}
                      alt='trash icon'
                    />
                  }
                />
                <WiseButton
                  textColor={'dark'}
                  onClick={handleDownload}
                  bk={'white'}
                  border
                  minWidth={'45%'}
                  text={'Download'}
                  icon={
                    <img
                      src={fileDownload}
                      style={{ width: '1em', marginRight: 8 }}
                      alt='download icon'
                    />
                  }
                />
              </div>
            )
          ) : (
            <WiseButton
              text={item?.is_form ? 'Fill form' : 'Upload Document'}
              minWidth={'100%'}
              icon={
                <img
                  src={item?.is_form ? editImage : downloadIcon}
                  style={{ marginRight: 8, width: 16 }}
                  alt=''
                />
              }
              onClick={select}
            />
          )}
        </div>
      </Col>
    </StyledCard>
  )
}

const StyledCard = styled.div`
  padding: 16px;
  align-items: center;
  display: flex;
  margin-bottom: 16px;
  background-color: #fff;
  box-shadow: 0px 1px 0px #dfe1e6;
  border-radius: 4px;
  @media (max-width: 425px) {
    display: block;
  }
`

export default ContractorDoc
