import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Col, Container, Row, TabContent, TabPane } from 'reactstrap'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

import CardSelector from '../../components/CardSelector'
import ModalHeader from '../../components/ModalHeader'
import Steps from '../../components/Steps'
import StepContainer from '../../components/Steps/StepContainer'
import TransactionError from '../../components/transaction-error'
import Loader from '../../components/ui/loader'
import Wootric from '../../components/Wootric/Wootric'
import FEATURE_FLAGS from '../../config/feature-flags'
import { WITHDRAW_METHODS } from '../../core/config/payment-methods'
import { useFetch } from '../../helpers/hooks'
import {
  createWithdraw,
  getContractorWithdrawMethods,
  prepareWithdraw,
} from '../../services/api'
import { updateWithdrawAmount } from '../../store/withdraw/actions'
import BankAccounts from './BankAccounts'
import CashPointAccount from './CashPointAccount'
import CoinbaseAccounts from './CoinbaseAccounts'
import ConfirmationPage from './ConfirmationPage'
import PayoneerWithdrawAccounts from './payoneer-withdraw-accounts'
import PaypalAccounts from './PaypalAccounts'
import WithdrawReview from './withdrawReview'

const WithdrawProcess = ({ history }) => {
  const { type } = useParams()

  const [activeTab, setActiveTab] = useState(type ? 1 : 0)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [account, setAccount] = useState(null)
  const preWithdraw = useFetch({
    checkSuccess: true,
    action: prepareWithdraw,
    onComplete: () => {
      if (preWithdraw.error) {
        toastr.error(preWithdraw.error)
      }
    },
  })

  const withdrawAmount = useSelector((state) => state?.Withdraw?.withdrawAmount)
  const withdrawCurrency = withdrawAmount?.currency.code

  const { data: methods, isLoading: gettingMethods } = useFetch(
    {
      action: getContractorWithdrawMethods,
      autoFetch: !!withdrawCurrency,
      body: { currency: withdrawCurrency },
    },
    [withdrawCurrency],
  )

  const withdraw = useFetch({
    autoFetch: false,
    initResult: null,
    action: createWithdraw,
    onComplete: (data) => {
      if (withdraw.error) {
        toastr.error(withdraw.error)
      } else {
        window.analytics.track('Withdraw step 4 - Withdraw Confirmation', {
          balance_amount: withdrawAmount?.amount,
          balance_currency: withdrawCurrency,
          amount_selected: withdrawAmount?.amount,
          withdraw_account: account?.id,
          withdraw_method: paymentMethod?.label,
          transaction_id: withdraw.data?.ref,
        })
        dispatch(updateWithdrawAmount(null))
        setActiveTab(activeTab + 1)
      }
    },
  })

  const dispatch = useDispatch()

  useEffect(() => {
    if (!withdrawAmount) history.push('/withdraw')

    return () => {
      dispatch(updateWithdrawAmount(null))
    }
    // We want to run this check only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelectMethod = (method) => {
    setPaymentMethod(method)
    setAccount(null)
    window.analytics.track('Withdraw step 1 - Method', {
      balance_amount: withdrawAmount?.amount,
      balance_currency: withdrawCurrency,
      amount_selected: withdrawAmount?.amount,
      withdraw_method: method?.label,
    })
  }

  const handleSelectBankAccount = (account) => {
    setAccount(account)
    window.analytics.track('Withdraw step 2 - Account', {
      balance_amount: withdrawAmount?.amount,
      balance_currency: withdrawCurrency,
      amount_selected: withdrawAmount?.amount,
      withdraw_account: account?.id,
      withdraw_method: paymentMethod?.label,
    })
  }

  const handleBankAccountAdd = (account) => {
    window.analytics.track('Withdraw step 2 - Added account', {
      balance_amount: withdrawAmount?.amount,
      balance_currency: withdrawCurrency,
      amount_selected: withdrawAmount?.amount,
      withdraw_account: account?.id,
      withdraw_method: paymentMethod?.label,
    })
  }

  const handleAccountSelect = (account) => {
    setAccount(account)
    window.analytics.track('Withdraw step 2 - Account', {
      balance_amount: withdrawAmount?.amount,
      balance_currency: withdrawCurrency,
      amount_selected: withdrawAmount?.amount,
      withdraw_account: account?.id,
      withdraw_method: paymentMethod?.label,
    })
  }

  const handleCashPointAccountSelect = (account) => {
    setAccount(account)
  }

  const handleConfirmWithdraw = () => {
    window.analytics.track('Withdraw step 3 - Review', {
      balance_amount: withdrawAmount?.amount,
      balance_currency: withdrawCurrency,
      amount_selected: withdrawAmount?.amount,
      withdraw_account: account?.id,
      withdraw_method: paymentMethod?.label,
    })
    withdraw.startFetch({
      quote_id: preWithdraw.data?.quote_id,
    })
  }

  const handleStepTow = () => {
    const paymentMethodId = paymentMethod?.id

    preWithdraw.startFetch({
      amount: withdrawAmount?.amount,
      currency_id: withdrawAmount?.currency?.id,
      payment_method_id: paymentMethodId,
      account_id: account?.id,
    })
    setActiveTab(activeTab + 1)
  }

  const stepMinHeight = '60vh'

  return (
    <div className='page-content min-vh-100 p-0 m-0'>
      <Container fluid>
        <ModalHeader
          action={() => {
            history.replace('/withdraw')
          }}
        >
          <Steps
            activeTab={activeTab}
            data={['Withdrawal methods', 'Account', 'Review', 'Withdraw']}
            noLastAction
            className='d-none d-md-flex'
          />
        </ModalHeader>
        <Row className='justify-content-center'>
          <Col lg={activeTab === 0 ? 6 : 5}>
            <TabContent
              activeTab={activeTab}
              className='twitter-bs-wizard-tab-content'
            >
              <TabPane tabId={0}>
                <StepContainer
                  index={0}
                  total={4}
                  title='Withdrawal methods'
                  onNext={() => setActiveTab(activeTab + 1)}
                  onBack={() => setActiveTab(activeTab - 1)}
                  nextText='Next'
                  disableNext={!paymentMethod}
                >
                  {gettingMethods ? (
                    <Loader minHeight={254} />
                  ) : (
                    <CardSelector
                      options={methods ?? []}
                      value={paymentMethod}
                      onSelect={handleSelectMethod}
                    />
                  )}
                </StepContainer>
              </TabPane>
              <TabPane tabId={1}>
                <StepContainer
                  title='Select an Account'
                  index={1}
                  total={4}
                  onNext={handleStepTow}
                  onBack={() => setActiveTab(activeTab - 1)}
                  disableNext={!account}
                  minHeight='50vh'
                >
                  {activeTab === 1 && (
                    <>
                      {paymentMethod?.id ===
                        WITHDRAW_METHODS.BANK_TRANSFER.id && (
                        <BankAccounts
                          onNext={handleSelectBankAccount}
                          onAdd={handleBankAccountAdd}
                          currency={withdrawCurrency}
                        />
                      )}
                      {FEATURE_FLAGS.NEW_PAYONEER_INTEGRATION ? (
                        <>
                          {paymentMethod?.id === WITHDRAW_METHODS.PAYPAL.id && (
                            <PaypalAccounts
                              isPayoneer={paymentMethod?.id === 3}
                              onNext={handleAccountSelect}
                            />
                          )}
                          {paymentMethod?.id ===
                            WITHDRAW_METHODS.PAYONEER.id && (
                            <PayoneerWithdrawAccounts
                              onNext={handleAccountSelect}
                            />
                          )}
                        </>
                      ) : (
                        [
                          WITHDRAW_METHODS.PAYPAL.id,
                          WITHDRAW_METHODS.PAYONEER.id,
                        ].includes(paymentMethod?.id) && (
                          <PaypalAccounts
                            isPayoneer={paymentMethod?.id === 3}
                            onNext={handleAccountSelect}
                          />
                        )
                      )}

                      {paymentMethod?.id === WITHDRAW_METHODS.COINBASE.id && (
                        <CoinbaseAccounts onNext={handleAccountSelect} />
                      )}

                      {paymentMethod?.id === WITHDRAW_METHODS.CASH_POINT.id && (
                        <CashPointAccount
                          editing={true}
                          onNext={handleCashPointAccountSelect}
                        />
                      )}
                    </>
                  )}
                </StepContainer>
              </TabPane>
              <TabPane tabId={2}>
                <StepContainer
                  nextText='Confirm & Withdraw'
                  disableNext={preWithdraw.isLoading || preWithdraw.error}
                  title='Review & Withdraw'
                  index={2}
                  total={4}
                  onNext={handleConfirmWithdraw}
                  onBack={() => setActiveTab(activeTab - 1)}
                  minHeight={stepMinHeight}
                  isFlat
                  loading={withdraw.isLoading}
                >
                  {preWithdraw.error ? (
                    <TransactionError
                      errorText={preWithdraw.error}
                      minHeight='max(55vh, 380px)'
                      genericErrorText='An error occurred while withdrawing your funds.'
                    />
                  ) : (
                    <WithdrawReview
                      onNext={handleConfirmWithdraw}
                      bankInfo={preWithdraw.data}
                      loading={preWithdraw.isLoading}
                      actionLoading={withdraw.isLoading}
                      method={paymentMethod}
                      onBack={() => {
                        setActiveTab(activeTab - 1)
                      }}
                    />
                  )}
                </StepContainer>
              </TabPane>
              <TabPane tabId={3}>
                <ConfirmationPage
                  onComplete={() => {
                    history.push('/')
                  }}
                  paymentMethod={paymentMethod}
                  bankInfo={preWithdraw.data}
                  data={withdraw.data}
                />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
      {activeTab === 3 && <Wootric />}
    </div>
  )
}

export default WithdrawProcess
