import React, { forwardRef } from 'react'
import cx from 'classnames'
import Input from 'reactstrap/lib/Input'

const SearchBar = forwardRef(function SearchBar(
  { onQueryChanged, withFilter, placeholder, query, rightRounded, className },
  ref,
) {
  return (
    <div className={cx('search-box', className)}>
      <div className='position-relative'>
        <Input
          ref={ref}
          type='text'
          className={
            rightRounded
              ? 'right-rounded'
              : withFilter
              ? 'form-control with-filter'
              : 'form-control'
          }
          placeholder={placeholder || 'Search...'}
          value={query ?? ''}
          onChange={(e) => {
            onQueryChanged(e.target.value)
          }}
        />
        <i
          className='bx bx-search search-icon align-items-center'
          style={{ pointerEvents: 'none' }}
        />
      </div>
    </div>
  )
})

export default SearchBar
