import moment from 'moment'
import React, { useEffect, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { useSelector } from 'react-redux'
import {
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap'
import Row from 'reactstrap/lib/Row'
import toastr from 'toastr'

import CustomDatePicker from '../../../components/Forms/CustomDatePicker/CustomDatePicker'
import Button from '../../../components/ui/button'
import { userTypes } from '../../../helpers/enum'
import { useFetch } from '../../../helpers/hooks'
import {
  addTimeOff,
  getCycles,
  submitFullTimeTimeOff,
} from '../../../services/api'

const TimeOffModal = (props) => {
  const { show, hide, data, toExclude } = props
  const [amount, setAmount] = useState({})
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [deduct, setDeduct] = useState(!!data?.cycles?.find((c) => c.amount))
  const user = useSelector((state) => state?.Account?.user)
  const contract = useSelector((state) => state?.Contract?.details)
  const isEmployee = useSelector(
    (state) => state.userProfile?.userProfile?.contractor_type === 'employee',
  )

  const submitBtnDisabled = !startDate || !endDate

  const cycles = useFetch({
    action: getCycles,
    initResult: data ? { cycles: data?.cycles } : [],
  })
  useEffect(() => {
    if (data) {
      setStartDate(moment(data?.from, 'YYYY-MM-DD'))
      setEndDate(moment(data?.to, 'YYYY-MM-DD'))
    }
  }, [data])
  useEffect(() => {
    if (startDate && endDate) {
      cycles.startFetch({
        contract_id: contract?.id,
        from: moment(startDate).format('YYYY-MM-DD'),
        to: moment(endDate).format('YYYY-MM-DD'),
      })
    }
  }, [startDate, endDate])

  const add = useFetch({
    action: isEmployee ? submitFullTimeTimeOff : addTimeOff,
    onComplete: () => hide(),
  })
  const submit = () => {
    const notSetAmount = cycles.data?.cycles?.find((e) => !amount[e?.id])
    if (deduct && notSetAmount) {
      toastr.error('Please enter amount for all cycles')
      return
    }
    if (data) {
      hide(
        deduct
          ? cycles.data?.cycles?.map((cycle) => ({
              id: cycle?.id,
              amount: amount[cycle?.id],
            }))
          : [],
      )
    } else {
      add.startFetch({
        contract_id: contract?.id,
        from: moment(startDate).format('YYYY-MM-DD'),
        to: moment(endDate).format('YYYY-MM-DD'),
        cycles: deduct
          ? cycles.data?.cycles?.map((cycle) => ({
              id: cycle?.id,
              amount: amount[cycle?.id],
            }))
          : [],
      })
    }
  }

  return (
    <Modal isOpen={show} toggle={hide} centered unmountOnClose>
      <ModalHeader toggle={hide}>Add Time Off</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label className='col-form-label pt-0'>
            Start Date<span className='text-danger font-size-16 ml-1'>*</span>
          </Label>
          <CustomDatePicker
            handleOnChange={setStartDate}
            value={startDate}
            disabled={!!data}
            excludeDates={toExclude}
            selectsStart
            startDate={startDate}
            endDate={endDate}
          />
        </FormGroup>
        <FormGroup>
          <Label className='col-form-label pt-0'>
            End Date<span className='text-danger font-size-16 ml-1'>*</span>
          </Label>
          <CustomDatePicker
            handleOnChange={setEndDate}
            value={endDate}
            disabled={!!data}
            excludeDates={toExclude}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={toExclude?.find((e) => e > startDate)}
          />
        </FormGroup>

        {user?.type === userTypes.COMPANY && !!startDate && !!endDate && (
          <>
            <FormGroup className='pt-2' style={{ zIndex: 9 }}>
              <Col>
                <Row
                  className='flex-row position-relative'
                  style={{ zIndex: 0 }}
                >
                  <div
                    className='custom-control custom-switch align-items-center'
                    dir='ltr'
                  >
                    <Input
                      name='deduct'
                      type='checkbox'
                      className='custom-control-input'
                      onChange={(e) => {
                        setDeduct(e.target.checked)
                      }}
                      value={deduct}
                      id='deductToggle'
                      defaultChecked={false}
                    />
                    <label
                      className='custom-control-label align-items-center d-flex'
                      htmlFor='deductToggle'
                      style={{ gap: '0.25rem' }}
                    >
                      <span>Deduct time off amount </span>
                      <i
                        id='deduct'
                        className='bx bx-info-circle'
                        style={{ marginTop: 1 }}
                      />

                      <UncontrolledTooltip placement='top' target='deduct'>
                        The amount of the unpaid time-off will be deducted from
                        the relative payment cycle
                      </UncontrolledTooltip>
                    </label>
                  </div>
                </Row>
              </Col>
            </FormGroup>
            {deduct &&
              cycles.data?.cycles?.map((cycle) => (
                <FormGroup key={cycle?.id}>
                  <Label className='col-form-label pt-0'>
                    {`Amount to deduct from payment cycle (${moment(
                      cycle?.from,
                      'YYYY-MM-DD',
                    ).format('ll')} - ${moment(cycle?.to, 'YYYY-MM-DD').format(
                      'll',
                    )})`}
                    <span className='text-danger font-size-16 ml-1'>*</span>
                  </Label>
                  <InputGroup>
                    <CurrencyInput
                      name='amount'
                      className='form-control'
                      placeholder='Amount'
                      type='text'
                      onKeyPress={(e) => {
                        if (!/[0-9.]/.test(e.key)) {
                          e.preventDefault()
                        }
                      }}
                      defaultValue={cycle?.amount}
                      autocomplete='off'
                      onValueChange={(value) => {
                        const newAmount = { ...amount }
                        newAmount[cycle?.id] = value
                        setAmount(newAmount)
                      }}
                      // currency input props
                      allowDecimals={true}
                      decimalsLimit={2}
                      decimalSeparator='.'
                      groupSeparator=','
                    />
                    <InputGroupAddon addonType='append'>
                      <InputGroupText>
                        {contract?.currency?.symbol}
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              ))}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color='light'
          outline
          disabled={add.isLoading}
          onClick={() => hide()}
        >
          Cancel
        </Button>
        <Button
          color='primary'
          loading={add.isLoading}
          disabled={submitBtnDisabled || add.isLoading}
          onClick={submit}
        >
          {data
            ? 'Approve'
            : user?.type === userTypes.CONTRACTOR
            ? 'Submit'
            : 'Submit and Approve'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default TimeOffModal
