import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import {
  Card,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import toastr from 'toastr'

import DropzoneInput from '../../../components/Common/dropzone-input'
import UploadPreview from '../../../components/Common/upload-preview'
import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import Button from '../../../components/ui/button'
import DataTable from '../../../components/ui/data-table'
import Loader from '../../../components/ui/loader'
import SimpleMessage from '../../../components/ui/simple-message'
import { useFetch } from '../../../helpers/hooks'
import {
  addDocumentRequest,
  adminAddDocumentRequest,
  downloadEmployeeDocument,
  downloadEmployeeDocumentAdmin,
  getContractDetailAdmin,
  getEmployeeDocuments,
  getEmployeeDocumentsAdmin,
  uploadEmployeeDocument,
} from '../../../services/api'
import openFileV2 from '../../../utils/file/open-v2'
import { getFullName } from '../../../utils/get-full-name'
import ContractRef from '../../AdminPanel/components/ContractRef'
import { toBase64 } from '../../AdminPanel/pages/Partners/eor-partner-invoice-modal'

export default function EmployeeDocsList({ isAdmin, isContractOngoing }) {
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false)
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  const {
    data: employeeDocuments,
    isLoading,
    startFetch: refreshData,
  } = useFetch({
    action: isAdmin ? getEmployeeDocumentsAdmin : getEmployeeDocuments,
    autoFetch: true,
    withAdminAccess: isAdmin,
  })

  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const contractRef = searchParams.get('id')

  const filteredEmployeeDocuments = useMemo(
    () =>
      !contractRef
        ? employeeDocuments
        : employeeDocuments?.filter((doc) => doc.contract_ref === contractRef),
    [employeeDocuments, contractRef],
  )

  const { isLoading: downloadingDocument, startFetch: downloadDocument } =
    useFetch({
      action: isAdmin
        ? downloadEmployeeDocumentAdmin
        : downloadEmployeeDocument,
      autoFetch: true,
      withAdminAccess: isAdmin,
      onComplete: (data, body) => {
        openFileV2(data, {
          download: true,
          name: `${body?.title}-${body?.request_id}.pdf`,
        })
      },
    })

  function toggleRequestModal() {
    setIsRequestModalOpen((open) => !open)
  }
  function toggleUploadModal() {
    setIsUploadModalOpen((open) => !open)
  }

  function handleUploadEorDocument(rowData) {
    setIsUploadModalOpen(true)
    setSelectedItem(rowData)
  }

  const handleDownload = useCallback(
    function handleDownload(rowData) {
      downloadDocument({ request_id: rowData.id, title: rowData.title })
    },
    [downloadDocument],
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Contract',
        accessor: 'contract_ref',
        Cell: ({ cellData }) => <ContractRef contractId={cellData} isAdmin />,
      },
      {
        Header: 'Full Name',
        accessor: 'user',
        Cell: ({ cellData }) => getFullName(cellData),
      },
      { Header: 'Title', accessor: 'title' },
      {
        Header: 'Actions',
        accessor: 'file',
        Cell: ({ cellData: file, rowData }) => {
          if (!file) {
            if (isAdmin) {
              return (
                <Button
                  type='button'
                  color='info'
                  outline
                  size='sm'
                  onClick={() => handleUploadEorDocument(rowData)}
                >
                  Upload document
                </Button>
              )
            } else {
              return 'Document is being prepared'
            }
          } else {
            return (
              <Button
                type='button'
                outline
                size='sm'
                disabled={downloadingDocument}
                onClick={() => handleDownload(rowData)}
              >
                Download
              </Button>
            )
          }
        },
      },
    ],
    [downloadingDocument, handleDownload, isAdmin],
  )

  return (
    <Card className='p-4'>
      <div className='d-flex flex-wrap gap-4 justify-content-between align-items-start mb-3'>
        <h3 className='rp-font-gilroyB'>Employee Documents</h3>

        {!contractRef && !isContractOngoing ? null : (
          <Button type='button' size='sm' onClick={toggleRequestModal}>
            {isAdmin ? 'Create request' : 'Request document'}
          </Button>
        )}
      </div>

      {isLoading ? (
        <Loader minHeight='min(276px, 30vh)' />
      ) : !filteredEmployeeDocuments ||
        filteredEmployeeDocuments?.length <= 0 ? (
        <SimpleMessage minHeight='min(276px, 30vh)'>
          Your documents will be shown here.
        </SimpleMessage>
      ) : (
        <DataTable data={filteredEmployeeDocuments} columns={columns} />
      )}

      <RequestDocumentModal
        isOpen={isRequestModalOpen}
        toggle={toggleRequestModal}
        afterSuccess={refreshData}
        isAdmin={isAdmin}
        contractRef={contractRef}
      />

      <UploadDocumentModal
        isOpen={isUploadModalOpen}
        toggle={toggleUploadModal}
        item={selectedItem}
        afterSuccess={refreshData}
      />
    </Card>
  )
}

const employeeDocumentOtherTypeValue = 3

export function RequestDocumentModal({
  isOpen,
  toggle,
  afterSuccess,
  isAdmin,
  contractRef,
}) {
  const [documentType, setDocumentType] = useState(null)
  const [documentName, setDocumentName] = useState('')

  const { startFetch: addDocument, isLoading: requestingDocument } = useFetch({
    action: isAdmin ? adminAddDocumentRequest : addDocumentRequest,
    withAdminAccess: isAdmin,
    onComplete: () => {
      toggle?.()
      afterSuccess?.()
      toastr.success('Document request sent successfully')
    },
    onError: (resp) => {
      toastr.error(resp?.message || 'Something went wrong')
    },
  })

  const { data: contractDetails } = useFetch(
    {
      autoFetch: !!contractRef,
      action: getContractDetailAdmin,
      withAdminAccess: true,
      body: { id: contractRef },
    },
    [contractRef],
  )

  const requestTypes =
    useSelector((state) =>
      state?.Layout?.staticData?.document_request_types.map((e) => {
        return {
          label: e.name,
          value: e.id,
        }
      }),
    ) || []

  function handleConfirm() {
    const body = {
      title:
        documentType?.value === employeeDocumentOtherTypeValue
          ? documentName
          : documentType?.label,
    }
    if (isAdmin) {
      body.employee_id = contractDetails?.employee?.id
    }

    addDocument(body)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>Request document from the employee</ModalHeader>

      <ModalBody>
        <FormGroup>
          <Label htmlFor='document-type'>Document name</Label>
          <CustomSelect
            inputId='document-type'
            options={requestTypes}
            value={documentType}
            onChange={setDocumentType}
          />
        </FormGroup>

        {documentType?.value === employeeDocumentOtherTypeValue ? (
          <FormGroup>
            <Label htmlFor='document-name'>Document name</Label>
            <Input
              type='text'
              id='document-name'
              name='document-name'
              placeholder='Document name'
              value={documentName}
              onChange={(e) => setDocumentName(e.target.value)}
            />
          </FormGroup>
        ) : null}
      </ModalBody>

      <ModalFooter>
        <Button
          type='button'
          color='light'
          outline
          onClick={toggle}
          disabled={requestingDocument}
        >
          Cancel
        </Button>
        <Button
          type='button'
          onClick={handleConfirm}
          loading={requestingDocument}
          disabled={requestingDocument}
        >
          Request document
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export function UploadDocumentModal({ isOpen, toggle, item, afterSuccess }) {
  const [document, setDocument] = useState(null)
  const [sendEmail, setSendEmail] = useState(false)

  const { startFetch: uploadRequestedDocument, isLoading: uploadingDocument } =
    useFetch({
      action: uploadEmployeeDocument,
      withAdminAccess: true,
      onComplete: () => {
        toggle()
        setDocument(null)
        afterSuccess()
      },
    })

  async function handleDrop([file]) {
    const base64File = await toBase64(file)

    setDocument({ file, data: base64File })
  }

  function handleUpload() {
    uploadRequestedDocument({
      request_id: item?.id,
      file: document.file,
      send_email: sendEmail ? 1 : 0,
    })
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Upload document</ModalHeader>
      <ModalBody>
        <FormGroup className='mb-3 mx-1'>
          <Label
            htmlFor='send-email'
            className='font-size-14 mb-0 d-flex align-items-center rp-checkbox-label rp-font-normal gap-10 rounded-sm'
            style={{ color: 'var(--slate-700)' }}
          >
            <Input
              type='checkbox'
              name='send-email'
              id='send-email'
              className='rp-checkbox-input'
              value={sendEmail}
              onChange={(e) => setSendEmail(e.target.checked)}
            />

            <span>Send email to employee</span>
          </Label>
        </FormGroup>

        <DropzoneInput onDropAccepted={handleDrop}>
          <div
            className='d-flex flex-column gap-12 justify-content-center align-items-center rp-font-gilroyB'
            style={{ minHeight: '250px' }}
          >
            {document ? (
              <>
                <div className='d-flex justify-content-between align-items-center flex-grow w-100'>
                  <div>{document.file.name}</div>
                  <Button
                    type='button'
                    size='sm'
                    color='secondary'
                    outline
                    onClick={(e) => {
                      e.stopPropagation()
                      setDocument(null)
                    }}
                  >
                    Clear
                  </Button>
                </div>
                <UploadPreview
                  preview={{ data: document.data, type: document.file.type }}
                  style={{ minHeight: 345, maxHeight: 345 }}
                />
              </>
            ) : (
              <>
                <i className='bx bxs-file-doc' style={{ fontSize: '52px' }} />
                <span className='font-size-16'>
                  Click or drop your document (pdf) here to upload
                </span>
              </>
            )}
          </div>
        </DropzoneInput>
      </ModalBody>
      <ModalFooter>
        <Button
          type='button'
          color='light'
          outline
          onClick={toggle}
          disabled={uploadingDocument}
        >
          Cancel
        </Button>
        <Button
          type='button'
          onClick={handleUpload}
          loading={uploadingDocument}
          disabled={uploadingDocument}
        >
          Submit document
        </Button>
      </ModalFooter>
    </Modal>
  )
}
