import moment from 'moment'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Col, Container, Row, Spinner, Table } from 'reactstrap'
// import { Link, useHistory } from 'react-router-dom'
// import { Badge, Card, Col, Container, Row, Spinner, Table } from 'reactstrap'
// import toastr from 'toastr'

import CustomDatePicker from '../../../../components/Forms/CustomDatePicker/CustomDatePicker'
import Button from '../../../../components/ui/button'
import WiseButton from '../../../../components/WiseButton'
// import ADMIN_PERMISSIONS from '../../../../config/admin-permissions'
import { useFetch } from '../../../../helpers/hooks'
// import useHasPermission from '../../../../helpers/hooks/admin/has-permission'
import {
  downloadGeneratedInvoice,
  getGeneratedInvoicesList,
} from '../../../../services/api'
import openFile from '../../../../utils/file/open'
// import EorPartnerInvoiceModal from '../Partners/eor-partner-invoice-modal'

// const getStatusColor = (status) => {
//   switch (status) {
//     case 'Unpaid':
//       return 'danger'
//     case 'Paid':
//       return 'success'
//     case 'Processing':
//       return 'warning'
//     case 'Ongoing':
//       return 'primary'

//     default:
//       return 'secondary'
//   }
// }

function GeneratedInvoices() {
  // const [activePage, setActivePage] = useState(1)
  const [date, setDate] = useState(moment().format('YYYY-MM'))
  const history = useHistory()

  const invoices = useFetch(
    {
      action: getGeneratedInvoicesList,
      withAdminAccess: true,
      autoFetch: true,
      onError: console.error,
      body: { month: date },
    },
    [date],
  )

  // function refreshData() {
  //   invoices.startFetch({ month: date })
  // }

  // const hasEditPermission = useHasPermission(
  //   ADMIN_PERMISSIONS.MANAGE_INVOICE_GENERATOR,
  // )

  return (
    <div className='page-content'>
      <Container fluid className='p-0 m-0'>
        <h1 className='rp-font-gilroyB' style={{ marginBottom: '2rem' }}>
          Generated Invoices
        </h1>
        <Row className='m-0 p-0 mt-3'>
          <Col className='p-0 m-0'>
            <Card>
              {invoices.isLoading ? (
                <Container style={{ minHeight: '30rem' }}>
                  <Col style={{ minHeight: '30rem' }}>
                    <Row
                      style={{ minHeight: '30rem' }}
                      className='justify-content-center align-items-center'
                    >
                      <Spinner type='grow' className='mr-2' color='primary' />
                    </Row>
                  </Col>
                </Container>
              ) : (
                <>
                  <Container fluid>
                    <Row className='p-3' style={{ gap: '0.75rem' }}>
                      <Col md={4} xs={12} className='p-0'>
                        <CustomDatePicker
                          showMonthYearPicker
                          placeholder='Filter by month'
                          dateFormat='MMM - yyyy'
                          value={date}
                          handleOnChange={(v) => {
                            setDate(moment(v).format('YYYY-MM'))
                          }}
                        />
                      </Col>
                      <Col md={3} xs={12} className='p-0'>
                        <button
                          className='btn btn-primary'
                          onClick={() => {
                            setDate(moment().format('YYYY-MM'))
                          }}
                        >
                          Clear Filter
                        </button>
                      </Col>
                      <div className='col md-5 d-flex align-items-center justify-content-end flex-1'>
                        <WiseButton
                          minHeight={'48px'}
                          minWidth={'100%'}
                          width={'192px'}
                          bold
                          onClick={() => {
                            history.push('/admin/invoice-generation')
                          }}
                          text='New Invoice'
                          icon={
                            <i
                              style={{ marginRight: '18px' }}
                              className='fas fa-plus font-size-12'
                            />
                          }
                        />
                      </div>
                    </Row>
                  </Container>

                  <Table
                    responsive
                    className='table-centered table-nowrap text-muted'
                  >
                    <thead>
                      <tr>
                        <th className='border-top-0'>Ref</th>
                        <th className='border-top-0'>Partner name</th>
                        <th className='border-top-0'>Created at</th>
                        <th className='border-top-0'>total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoices?.data?.map((raw, key) => {
                        return <GeneratedInvoiceLine invoice={raw} key={key} />
                      })}
                    </tbody>
                  </Table>

                  <div className='d-flex justify-content-end p-4'>
                    {/* <Paginations
                      itemClass='page-item'
                      linkClass='page-link'
                      // pagination class is required, because innerClass overrides it
                      innerClass='pagination mb-0'
                      activePage={activePage}
                      itemsCountPerPage={invoices.paginator?.per_page}
                      totalItemsCount={invoices.paginator?.total}
                      onChange={setActivePage}
                    /> */}
                  </div>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

function GeneratedInvoiceLine({ invoice, key }) {
  const [downloading, setDownloading] = useState(null)
  const { startFetch: downloadInvoice, isLoading: downloadingInvoice } =
    useFetch({
      action: downloadGeneratedInvoice,
      withAdminAccess: true,
      onComplete: (data, body) => {
        openFile(data, `generated-invoice-${body?.invoice_id}.pdf`)
        setDownloading(null)
      },
    })
  function handlePreviewInvoice(invoiceId) {
    return function () {
      downloadInvoice({ invoice_id: invoiceId })
      setDownloading(invoiceId)
    }
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: invoice?.currency?.code || 'USD',
  })

  const isDownloading = downloadingInvoice && downloading === invoice.id
  return (
    <React.Fragment key={'_invoice_' + key}>
      <tr>
        <td>{invoice?.ref}</td>
        <td>{invoice?.partner_name}</td>
        <td>{moment.unix(invoice?.created_at).format('MM-DD-YYYY')}</td>
        <td>{formatter.format(invoice?.total)}</td>
        <td>
          <Button
            size='sm'
            outline
            color='secondary'
            onClick={handlePreviewInvoice(invoice.id)}
            disabled={isDownloading}
            loading={isDownloading}
          >
            Download
          </Button>
        </td>
      </tr>
    </React.Fragment>
  )
}

// function EorPartnerMissingInvoices({ contract, onUpdate }) {
//   const [invoiceData, setInvoiceData] = useState(null)

//   function toggle() {
//     setInvoiceData(null)
//   }

//   const { startFetch: updateAdminInvoice, isLoading: updatingInvoice } =
//     useFetch({
//       action: updateAdminEorPartnerInvoice,
//       withAdminAccess: true,
//       checkSuccess: true,
//       onComplete: () => {
//         toggle()
//         onUpdate()
//         toastr.success('Invoice added successfully')
//       },
//       onError: (error) => {
//         toastr.error(error, 'error')
//       },
//     })

//   function confirm(formData) {
//     formData.append('partner_invoice_id', contract?.partner_invoice_id)
//     updateAdminInvoice(formData)
//   }

//   return (
//     <>
//       <Button size='xs' onClick={() => setInvoiceData(contract)}>
//         Add invoice
//       </Button>
//       <EorPartnerInvoiceModal
//         isEditting
//         isOpen={!!invoiceData}
//         toggle={() => setInvoiceData(null)}
//         onConfirm={confirm}
//         isConfirmLoading={updatingInvoice}
//       />
//     </>
//   )
// }

export const CLIENT_INVOICE_STATUSES = {
  SUBMITTED: 'Submitted',
  APPROVED: 'Approved',
  DECLINED: 'Declined',
  PENDING_APPROVAL: 'Pending approval',
}

// const clientInvoiceStatus = Object.values(CLIENT_INVOICE_STATUSES)

// const paymentStatus = {
//   Unpaid: 'warning',
//   Processing: 'info',
//   Paid: 'success',
//   NA: 'secondary',
// }

// const invoiceStatus = {
//   // partner_invoice_status
//   'Pending submission': 'warning',
//   'Request approval': 'info',
//   Approved: 'success',
//   Rejected: 'danger',

//   // client_invoice_status
//   'Pending approval': 'info',
//   Declined: 'danger',
// }

// function ClientInvoiceStatusColumn({ rawContract, hasEditPermission }) {
//   if (!rawContract.partner_invoice_id) {
//     return <span className='text-muted'>N/A</span>
//   }

//   const statusElement = (
//     <div className={`text-${invoiceStatus[rawContract.client_invoice_status]}`}>
//       {rawContract.client_invoice_status}
//     </div>
//   )
//   if (!hasEditPermission) {
//     return statusElement
//   }

//   const resubmitting =
//     rawContract.client_invoice_status === CLIENT_INVOICE_STATUSES.DECLINED

//   if (
//     clientInvoiceStatus.includes(rawContract?.client_invoice_status) &&
//     !resubmitting
//   ) {
//     return statusElement
//   }

//   return (
//     <>
//       {!resubmitting ? null : statusElement}
//       <Link
//         to={{
//           pathname: '/admin/invoice-generator',
//           state: { ...rawContract, resubmitting },
//         }}
//       >
//         <Badge
//           className={`py-1 font-size-14 rounded bg-${
//             resubmitting ? 'warning' : 'primary'
//           } text-white`}
//         >
//           {resubmitting ? 'Re-submit' : 'Add Invoice'}
//         </Badge>
//       </Link>
//     </>
//   )
// }

export default GeneratedInvoices
