import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ButtonDropdown from 'reactstrap/lib/ButtonDropdown'
import Col from 'reactstrap/lib/Col'
import DropdownItem from 'reactstrap/lib/DropdownItem'
import DropdownMenu from 'reactstrap/lib/DropdownMenu'
import DropdownToggle from 'reactstrap/lib/DropdownToggle'
import Row from 'reactstrap/lib/Row'
import WiseButton from '../../../components/WiseButton'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  assignContractToSignatory,
  cancelSignatoryInvitation,
  getSignatoryList,
} from '../../../services/api'

const SelectSignatoryDropdown = ({ onAssigned, onCancel, onSign, isAdmin }) => {
  const user = useSelector((state) => state.Account?.user)
  const contract = useSelector((state) => state.Contract?.details)
  const { hasAccess } = usePermissions()
  const [isOpen, setIsOpen] = useState(false)
  const signatories = useFetch({
    action: getSignatoryList,
    autoFetch: true,
  })

  const assign = useFetch({
    action: assignContractToSignatory,
    onComplete: (data) => {
      onAssigned()
    },
  })
  const cancel = useFetch({
    action: cancelSignatoryInvitation,
    onComplete: () => {
      onCancel()
    },
  })
  const handleSignatorySelection = (signatory) => {
    if (signatory?.email === user?.email) {
      onSign()
    } else {
      assign.startFetch({
        contract_id: contract?.id,
        signatory_id: signatory?.id,
      })
    }
  }
  const handleCancelInvitation = () => {
    cancel.startFetch({
      contract_id: contract?.id,
    })
  }

  const disabled =
    !hasAccess(permissions.assignSignatory) ||
    [5, 6, 7, 1].includes(contract?.status?.id) ||
    isAdmin

  return contract?.signatory ? (
    <div className={'w-100'}>
      <Col className='p-0 m-0 w-100 mt-3 d-flex justify-content-end'>
        <WiseButton
          bold
          minWidth={'100%'}
          width={'100%'}
          bk={'white'}
          className={'btn-danger-cancel'}
          onClick={handleCancelInvitation}
          disabled={
            !hasAccess(permissions.cancelPendingSignatory) || cancel.isLoading
          }
          loading={cancel.isLoading}
          text={'Cancel Invitation'}
        />
      </Col>
      <div className='text-muted font-size-12'>
        Assigned to {contract?.signatory?.first_name}{' '}
        {contract?.signatory?.last_name}
      </div>
    </div>
  ) : (
    <ButtonDropdown
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      disabled={disabled}
    >
      <DropdownToggle
        caret
        color={disabled ? 'secondary' : 'primary'}
        disabled={
          !hasAccess(permissions.assignSignatory) ||
          [1, 5, 6, 7].includes(contract?.status?.id)
        }
      >
        <Row className='justify-content-center align-items-center flex-nowrap px-2'>
          <span>
            {assign.isLoading && <i className='bx bx-loader bx-spin mr-2' />}
          </span>
          {contract?.status?.id === 1 ? 'Pending Quote' : 'Select Signatory'}{' '}
          &nbsp;{' '}
        </Row>
      </DropdownToggle>
      <DropdownMenu>
        {signatories.data?.map((e, i) => (
          <DropdownItem
            onClick={() => {
              handleSignatorySelection(e)
            }}
            key={`signatory:${i}`}
          >{`${e.first_name || e.email || ''} ${e.last_name || ''} ${
            user?.email === e.email ? '(Me)' : ''
          }`}</DropdownItem>
        ))}
      </DropdownMenu>
    </ButtonDropdown>
  )
}

export default SelectSignatoryDropdown
