import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { Badge, Col, Container, Row, Spinner } from 'reactstrap'
import styled from 'styled-components'

import FormWizard from '../../components/Forms/FormWizard'
import Steps from '../../components/Steps'
import { StyledH5 } from '../../components/Typo'
import { checkRegisterToken } from '../../services/api'
import { apiError } from '../../store/actions'
import logoMain from './../../assets/images/identity/logo_main.png'
import logoSymbol from './../../assets/images/identity/symbol_main.png'

const Register = (props) => {
  const [activeTab, setActiveTab] = useState(1)
  const [loading, setLoading] = useState(false)
  const tokenP = new URLSearchParams(props.location.search).get('token')
  const history = useHistory()

  useEffect(() => {
    if (tokenP) {
      setLoading(true)
      checkRegisterToken(tokenP)
        .then((r) => {
          if (r.data?.success) {
            if (!r?.data?.data.is_valid) {
              history.push('/activity')
            }
            setLoading(false)
          }
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log(e)
        })
    }
  }, [])

  const steps = tokenP
    ? ['Account type', '', 'Your Info']
    : ['Account type', 'Your Info', 'Company Info', 'Team members']

  return loading ? (
    <Container style={{ minHeight: '80vh' }}>
      <Col style={{ minHeight: '80vh' }}>
        <Row
          style={{ minHeight: '80vh' }}
          className='justify-content-center align-items-center'
        >
          <Spinner type='grow' className='mr-2' color='primary' />
        </Row>
      </Col>
    </Container>
  ) : (
    <div
      className={`account-pages ${tokenP ? 'blur-image' : 'blur-container'}`}
      style={{ minHeight: '100vh' }}
    >
      <StyledDiv className='d-flex justify-content-between align-items-center fixed-top'>
        <div className='p-3 flex-shrink-0'>
          <img
            src={logoMain}
            className='d-none d-md-block'
            height={21}
            width={138}
            alt=''
          />
          <img src={logoSymbol} className='d-md-none' height={24} alt='' />
        </div>
        <Col md={10} lg={8} className='d-none ml-md-4 d-md-block flex-shrink-1'>
          <Steps activeTab={activeTab === 1 ? 0 : activeTab - 1} data={steps} />
        </Col>
        <Col
          xs={2}
          className='d-block d-md-none d-lg-block p-0 m-0 justify-content-end d-flex px-3'
        >
          <Badge
            className='d-lg-none font-size-12 rounded bg-soft-primary'
            color='white'
            pill
          >
            <p
              style={{ padding: '3px 2px' }}
              className='font-size-12 mb-0 text-primary'
            >
              {activeTab} / {steps?.length}
            </p>
          </Badge>
        </Col>
      </StyledDiv>

      <StyledContainer fluid>
        {activeTab === 0 && (
          <Row className='justify-content-center mt-3 mt-md-4  mb-4  p-0 m-0 '>
            <StyledH5 min='22px' max='32px' className='mb-0'>
              Account type
            </StyledH5>
          </Row>
        )}
        {activeTab === 1 && (
          <Row className='justify-content-center mt-4 mt-md-5 mb-3 m-0'>
            <StyledH5 min='22px' max='32px' className='text-center mb-3'>
              Check your email
            </StyledH5>
          </Row>
        )}
        {activeTab === 2 && (
          <Row className='justify-content-center mt-4 mt-md-5 mb-3 m-0'>
            <StyledH5 min='22px' max='32px' className='text-center mb-3'>
              General info
            </StyledH5>
          </Row>
        )}
        {activeTab === 3 && (
          <Row className='justify-content-center mt-4 mt-md-5 mb-3 m-0'>
            <StyledH5 min='22px' max='32px' className='text-center mb-3'>
              {tokenP ? steps[activeTab - 1] : 'Company info'}
            </StyledH5>
          </Row>
        )}
        {activeTab === 4 && (
          <Row className='justify-content-center mt-4 mt-md-5 mb-3 m-0'>
            <StyledH5 min='22px' max='32px' className='text-center mb-3'>
              Invite users
            </StyledH5>
          </Row>
        )}

        <Row className='justify-content-center p-0 m-0'>
          <Col className='p-0 m-0'>
            <FormWizard
              returnTabValue={(value) => {
                if (value > 2) {
                  setActiveTab(!tokenP ? value - 1 : value)
                } else {
                  setActiveTab(1)
                }
              }}
            />
          </Col>
        </Row>
      </StyledContainer>
    </div>
  )
}

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

const StyledContainer = styled(Container)`
  padding-top: 80px !important;

  @media (min-width: 992px) {
    padding-top: 100px !important;
  }
`

const StyledDiv = styled.div`
  height: 80px;
  background-color: #ffffff;
  box-shadow: 0px 0px 8px #eaeaea, inset 0px -1px 0px #eaeaea;
  @media (max-width: 768px) {
    height: 56px;
  }
`

export default connect(mapStatetoProps, { apiError })(Register)
