import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import React from 'react'

export default function ModalBase({
  isOpen,
  toggle,
  heading,
  children,
  actions,
  size,
  centered = true,
}) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={centered} size={size}>
      {!heading ? null : <ModalHeader toggle={toggle}>{heading}</ModalHeader>}
      {!children ? null : <ModalBody>{children}</ModalBody>}
      {!actions ? null : <ModalFooter>{actions}</ModalFooter>}
    </Modal>
  )
}
