import { AvField, AvForm } from 'availity-reactstrap-validation'
import capitalize from 'lodash/capitalize'
import React from 'react'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'

import Button from '../../../../components/ui/button'
import { userTypes } from '../../../../helpers/enum'
import { useFetch } from '../../../../helpers/hooks'
import {
  inviteContractPart,
  inviteFullTimeEmployee,
  segmentBatch,
  segmentTrack,
} from '../../../../services/api'

const StyledButton = styled(Button)`
  @media (min-width: 755px) {
    align-self: end;
  }
`

const inviteFormId = 'invite-form'

const ModalInvitation = ({ show, hide, message, type, data, onUpdate }) => {
  const user = useSelector((state) => state.Account?.user)
  const invite = useFetch({
    action: type === 'employee' ? inviteFullTimeEmployee : inviteContractPart,
    onComplete: (r, { email }) => {
      const invitedUser = r.user
      const trackData = {
        userId: invitedUser?.id,
        event: 'Invited-to-Contract',
        properties: {
          contract_id: data?.ref,
          contractor_first_name: invitedUser?.first_name,
          contractor_last_name: invitedUser?.last_name,
          client_first_name: user?.first_name,
          client_last_name: user?.last_name,
          invitation_url: r?.invitation_url,
          contract_name: data?.name,
          email,
          type,
        },
      }
      if (invitedUser?.first_name) {
        segmentTrack(trackData).then()
      } else {
        segmentBatch({
          batch: [
            {
              type: 'identify',
              userId: invitedUser?.id,
              traits: {
                email,
              },
            },
            {
              type: 'track',
              ...trackData,
            },
          ],
        }).then()
      }

      window.analytics.track(
        user?.type === userTypes.COMPANY
          ? 'Invited contractor'
          : 'Invited client',
        user?.type === userTypes.COMPANY
          ? {
              contract_id: data?.ref,
              contractor_email: email,
            }
          : {
              contract_id: data?.ref,
              client_email: email,
            },
      )
      onUpdate()
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  return (
    <Modal
      className='custom-modal-style'
      isOpen={show}
      centered
      size='lg'
      toggle={hide}
    >
      <ModalHeader className='p-4' toggle={hide}>
        {message}
      </ModalHeader>
      <ModalBody className='d-flex flex-column p-4'>
        <p className='font-size-16 mb-4' style={{ color: 'var(--zinc-700)' }}>
          Enter the email address of the {data?.contractor_name?.toLowerCase()}.
          An invitation email will be sent to them.
        </p>

        <AvForm
          onValidSubmit={(_, { email, customText }) => {
            invite.startFetch({
              email,
              contract_id: data?.id,
              custom_text: customText,
            })
          }}
          className='d-flex flex-column'
          style={{ gap: '1rem' }}
          id={inviteFormId}
        >
          <AvField
            labelClass='font-size-15'
            type='email'
            name='email'
            label='Email'
            groupAttrs={{ className: 'mb-0' }}
            placeholder={capitalize(
              `${data?.contractor_name} email address ...`,
            )}
            autoComplete='off'
            required
            errorMessage='Please enter a valid email address'
          />

          <AvField
            labelClass='font-size-15'
            type='textarea'
            name='customText'
            defaultValue='Hello, welcome onboard. Join us on RemotePass our payroll and compliance platform, to review and sign your contract.'
            label={
              <>
                Message <span className='font-weight-normal'>(Optional)</span>
              </>
            }
            groupAttrs={{ className: 'mb-0' }}
            placeholder='Invite Message...'
            rows={6}
            autoComplete='off'
          />
        </AvForm>
      </ModalBody>
      <ModalFooter className='p-4'>
        <StyledButton
          className='flex-grow-1 flex-md-grow-0'
          style={{ minWidth: 146 }}
          type='button'
          color='light'
          outline
          disabled={invite.isLoading}
          onClick={hide}
        >
          Cancel
        </StyledButton>
        <StyledButton
          className='flex-grow-1 flex-md-grow-0'
          style={{ minWidth: 146 }}
          type='submit'
          form
          color='primary'
          loading={invite.isLoading}
          disabled={invite.isLoading}
          formId={inviteFormId}
        >
          Send invite
        </StyledButton>
      </ModalFooter>
    </Modal>
  )
}

export default ModalInvitation
