import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Card, CardBody, Col, Row, Spinner } from 'reactstrap'

import ActionsDropdown from '../../../components/ActionsDropdown'
import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import NoContent from '../../../components/NoContent'
import BadgeX from '../../../components/Table/BadgeX'
import StyledTd from '../../../components/Table/StyledTd'
import StyledTh from '../../../components/Table/StyledTh'
import TableComp from '../../../components/Table/TableComp'
import TableH from '../../../components/Table/TableH'
import Button from '../../../components/ui/button'
import WiseButton from '../../../components/WiseButton'
import { userTypes } from '../../../helpers/enum'
import { useFetch, usePermissions, useResize } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  approvePayments,
  cancelPayment,
  declinePayments,
  deleteWork,
  getAdminContractPayment,
  getContractPayment,
  revertCancelledPayment,
} from '../../../services/api'
import { updateToPayList } from '../../../store/payment/actions'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import { usePayrollApprovalEnabled } from '../../CompanySetting'
import { getPaymentIds } from '../../payInvoices'
import AdjustModal from '../components/AdjustModal'

const Payments = ({ contract, isAdmin, contractLoading }) => {
  const user = useSelector((state) => state.Account?.user)
  const [show, setShow] = useState(false)
  const [counter, setCounter] = useState(0)
  const [expanded, setExpanded] = useState(-1)
  const isMobile = useResize()
  const { hasAccess } = usePermissions()
  const payments = useFetch({
    action: isAdmin ? getAdminContractPayment : getContractPayment,
    onComplete: () => {
      setCounter(counter + 1)
    },
    withAdminAccess: !!isAdmin,
  })
  useEffect(() => {
    if (contract) {
      updatePayments(counter === 0)
    }
  }, [contract])

  const updatePayments = (loading = true) => {
    if (contract?.id) {
      payments.startFetch({ id: contract.id }, loading)
    }
  }

  return payments.isLoading || contractLoading ? (
    <Col style={{ minHeight: '30rem' }}>
      <Row
        style={{ minHeight: '30rem' }}
        className='justify-content-center align-items-center'
      >
        <Spinner type='grow' className='mr-2' color='primary' />
      </Row>
    </Col>
  ) : (
    <Row className='p-0 m-0'>
      <Col xs='12' className='p-0 m-0'>
        <Card className='p-0 m-0'>
          <CardBody
            className='p-0 m-0'
            style={{
              backgroundColor: '#FFFFFF',
              boxShadow: '0px 1px 0px #DFE1E6',
              borderRadius: 4,
            }}
          >
            <TableH
              title='Payments'
              leftSide={
                user?.type === userTypes.COMPANY &&
                !isAdmin &&
                contract.type !== 'Full Time' && (
                  <WiseButton
                    disabled={
                      [5, 6].includes(contract?.status?.id) ||
                      !!contract?.amended ||
                      !hasAccess(permissions.addAdjustments)
                    }
                    onClick={() => {
                      window.analytics.track('Clicked adjust', {
                        contract_id: contract?.id,
                        contract_type: contract?.type?.name,
                        contract_status: contract?.status?.name,
                      })
                      setShow(true)
                    }}
                    text='Adjust +-'
                    bold
                    minHeight='48px'
                    minWidth='100%'
                  />
                )
              }
            />
            {payments?.data?.length === 0 ? (
              <div
                className='align-items-center d-flex justify-content-center p-2'
                style={{ minHeight: '10rem' }}
              >
                <NoContent
                  subtitle='Past and upcoming payments will be shown here'
                  advanced={false}
                />
              </div>
            ) : (
              <div className='table-responsive pb-5'>
                {isMobile ? (
                  <div className='p-3' style={{ minHeight: '70vh' }}>
                    {React.Children.toArray(
                      payments?.data?.map((order, key) => (
                        <Line
                          key={key}
                          isAdmin={isAdmin}
                          item={order}
                          expanded={expanded === key}
                          onUpdate={() => updatePayments(false)}
                          onExpand={() => {
                            if (expanded === key) {
                              setExpanded(-1)
                            } else {
                              setExpanded(key)
                            }
                          }}
                        />
                      )),
                    )}
                  </div>
                ) : (
                  <TableComp>
                    <thead className='thead-light'>
                      <tr
                        style={{
                          borderTop: 'hidden',
                          borderBottom: '1px solid #E7E8F2',
                        }}
                      >
                        <StyledTh>{''}</StyledTh>
                        <StyledTh>Payment ID</StyledTh>
                        <StyledTh>Due Date</StyledTh>
                        <StyledTh>Amount</StyledTh>
                        <StyledTh>
                          {user?.type !== 'client' ||
                          !hasAccess(permissions.PrepareTransactions)
                            ? 'Status'
                            : ''}
                        </StyledTh>
                      </tr>
                    </thead>
                    <tbody>
                      {React.Children.toArray(
                        payments?.data?.map((order, key) => (
                          <Line
                            key={key}
                            isAdmin={isAdmin}
                            item={order}
                            expanded={expanded === key}
                            onUpdate={() => updatePayments(false)}
                            onExpand={() => {
                              if (expanded === key) {
                                setExpanded(-1)
                              } else {
                                setExpanded(key)
                              }
                            }}
                          />
                        )),
                      )}
                    </tbody>
                  </TableComp>
                )}
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
      {show && (
        <AdjustModal
          data={payments.data}
          contract={contract}
          show={show}
          hide={() => setShow(false)}
          updated={() => {
            updatePayments(false)
            setShow(false)
          }}
        />
      )}
    </Row>
  )
}

const getStatusColor = (status) => {
  switch (status) {
    case 'Processing':
      return 'warning'
    case 'Paid':
      return 'success'
    case 'Overdue':
      return 'danger'
    case 'Unpaid':
      return 'danger'
    case 'Cancelled':
      return 'secondary'
    default:
      return 'primary'
  }
}

const Line = ({ item, expanded, onExpand, onUpdate, isAdmin }) => {
  const user = useSelector((state) => state.Account?.user)
  const contract = useSelector((state) => state.Contract?.details)
  const formatter = getCurrencyFormatter(item?.currency?.code)
  const { hasAccess } = usePermissions()

  const deleteLine = useFetch({
    action: deleteWork,
    onComplete: (_, body) => {
      onUpdate()
      window.analytics.track('Deleted adjustment', {
        contract_id: contract?.id,
        contract_type: contract?.type,
        contract_status: contract?.status?.name,
        adjust_id: body?.work?.work_id,
        adjust_type: body?.work?.name,
        amount: body?.work?.amount,
        currency: contract?.currency?.code,
      })
    },
  })
  const handleRemoveWork = (work) => {
    deleteLine.startFetch({ work_id: work?.work_id, work })
  }

  const isMobile = useResize()

  const info = [
    { label: 'Amount', value: formatter.format(item?.amount) },
    { label: 'Due Date', value: item?.due_date },
  ]

  return (
    <>
      {isMobile ? (
        <div className='bg-white border mb-3 position-relative py-0 rounded rp-shadow-2'>
          <Row className='p-3 m-0 border-bottom'>
            <Col xs={8} className='p-0 m-0'>
              <p className='font-size-14 rp-font-bold mb-0'>
                #{item.payment_ref}
              </p>
            </Col>
            <Col
              xs={4}
              className='p-0 m-0 justify-content-end align-items-center d-flex'
            >
              {item.status === 'Unpaid' &&
              user?.type === 'client' &&
              hasAccess(permissions.PrepareTransactions) &&
              !isAdmin ? (
                item?.amount !== 0 && <></>
              ) : (
                <BadgeX
                  status={getStatusColor(item.status)}
                  textStatus={getStatusColor(item.status)}
                  name={item.status}
                />
              )}
            </Col>
          </Row>

          <div className='p-3 d-flex flex-column gap-12'>
            {info.map(({ label, value }, key) => {
              return (
                <div
                  key={key}
                  className='d-flex align-items-center justify-content-between align-items-center'
                >
                  <h6
                    className={'text-dark font-weight-normal mb-0 font-size-14'}
                  >
                    {label}
                  </h6>
                  <h6 className='mb-0 text-dark font-weight-normal font-size-14'>
                    {value}
                  </h6>
                </div>
              )
            })}
            <ActionSection
              item={item}
              isAdmin={isAdmin}
              onUpdate={onUpdate}
              showStatus={false}
            />
          </div>
        </div>
      ) : (
        <>
          <tr style={{ borderBottom: '1px solid var(--gray-b)' }}>
            <StyledTd>
              {item?.works?.length !== 0 && item.status !== 'Paid (off-cycle)' && (
                <button
                  className='rp-btn-nostyle p-1 d-flex text-current'
                  onClick={onExpand}
                >
                  {expanded ? (
                    <i className='bx bx-minus' />
                  ) : (
                    <i className='bx bx-plus' />
                  )}
                </button>
              )}
            </StyledTd>
            <StyledTd>
              <span className='font-size-14'>#{item.payment_ref}</span>
            </StyledTd>
            <StyledTd>{item.due_date}</StyledTd>
            <StyledTd>{formatter.format(item.amount)}</StyledTd>
            <StyledTd>
              <div className='d-flex justify-content-between align-items-center gap-10'>
                <ActionSection
                  item={item}
                  isAdmin={isAdmin}
                  onUpdate={onUpdate}
                />

                <ItemActionsDropdown
                  item={item}
                  onUpdate={onUpdate}
                  isAdmin={isAdmin}
                />
              </div>
            </StyledTd>
          </tr>

          {expanded && (
            <>
              {item?.works?.map((t, k) => (
                <tr
                  className='font-weight-light font-size-12 bg-gray-bg'
                  key={'_trans' + t?.id}
                >
                  <th className='p-0' style={{ height: 55 }}>
                    <div
                      className='h-100'
                      style={{ borderLeft: '3px solid var(--primary)' }}
                    />
                  </th>
                  <th
                    colSpan={2}
                    className='py-3 px-4 text-dark font-size-14'
                    style={{ fontWeight: '400' }}
                  >
                    <div
                      className='d-flex flex-column'
                      style={{ gap: '0.5rem' }}
                    >
                      <p className='rp-font-gilroyB mb-0'>{t?.name}</p>

                      <p
                        style={{ maxWidth: 224 }}
                        className='font-size-14 mb-0'
                      >
                        {t?.details}
                      </p>

                      {t.attributes?.map((a) => (
                        <div key={`attr-${a?.name}`}>
                          <span className='text-muted'>{a?.name}: </span>
                          <span>{a?.value}</span>
                          <br />
                        </div>
                      ))}
                    </div>
                  </th>

                  <th
                    className='py-3 px-4 text-dark font-size-14'
                    style={{ fontWeight: '400' }}
                  >
                    {formatter.format(t?.amount)}
                  </th>

                  <th
                    className='py-3 px-4 text-dark font-size-14'
                    style={{ fontWeight: '400' }}
                  >
                    {t.can_delete && user?.type === 'client' && (
                      <button
                        className='rp-btn-nostyle text-danger d-flex p-1 hover:bg-soft-danger font-size-18'
                        type='button'
                        onClick={() => handleRemoveWork(t, k)}
                        disabled={deleteLine.isLoading}
                      >
                        {deleteLine.isLoading ? (
                          <i className='bx bx-loader bx-spin' />
                        ) : (
                          <i className='bx bx-trash' />
                        )}
                      </button>
                    )}
                  </th>
                </tr>
              ))}
            </>
          )}
        </>
      )}
    </>
  )
}

function ActionSection({ item, isAdmin, onUpdate, showStatus = true }) {
  const [showConfirmation, setShowConfirmation] = useState(false)

  const { hasAccess } = usePermissions()

  const payrollApprovalEnabled = usePayrollApprovalEnabled()

  const canManagePayments = item?.can_approve

  const user = useSelector((state) => state.Account?.user)
  const dispatch = useDispatch()
  const history = useHistory()

  const { startFetch: cancelItem, isLoading: cancelingItem } = useFetch({
    action: cancelPayment,
    onComplete: onUpdate,
  })
  const { startFetch: _approvePayment, isLoading: approvingPayments } =
    useFetch({
      action: approvePayments,
      onComplete: onUpdate,
    })
  const { startFetch: _declinePayment, isLoading: decliningPayments } =
    useFetch({
      action: declinePayments,
      onComplete: onUpdate,
    })

  function handleClickPay() {
    const ids = getPaymentIds([item])
    dispatch(updateToPayList(ids))
    history.push('/pay-invoices')
  }

  const statusBadge = (
    <BadgeX
      status={getStatusColor(item.status)}
      textStatus={getStatusColor(item.status)}
      name={item.status}
    />
  )

  if (!!isAdmin || user?.type !== userTypes.COMPANY) {
    return statusBadge
  }

  const isPending = item?.approval_status === 'pending' // 'declined'
  const isDeclined = item?.approval_status === 'declined'

  const showApprovalProcess = payrollApprovalEnabled && item.status === 'Unpaid'

  const approvalProcessLoading = approvingPayments || decliningPayments

  if (showApprovalProcess) {
    if (isDeclined) {
      return <BadgeX status='danger'>Declined</BadgeX>
    } else if (isPending) {
      if (canManagePayments) {
        return (
          <div className='d-flex gap-10'>
            <Button
              size='sm'
              color='success'
              onClick={() => _approvePayment({ payment_ids: [item?.id] })}
              loading={approvingPayments}
              disabled={approvalProcessLoading}
              className='font-size-14 flex-grow-1 flex-md-grow-0'
            >
              Approve
            </Button>
            <Button
              size='sm'
              color='danger'
              outline
              onClick={() => _declinePayment({ payment_ids: [item?.id] })}
              loading={decliningPayments}
              disabled={approvalProcessLoading}
              className='font-size-14 flex-grow-1 flex-md-grow-0'
            >
              Decline
            </Button>
          </div>
        )
      } else {
        return <BadgeX status='secondary'>Pending approval</BadgeX>
      }
    }
  }

  const hasPermissions = hasAccess(permissions.PrepareTransactions)
  const showPayButton = hasPermissions && item.status === 'Unpaid'
  const showCancelButton =
    hasPermissions && item.status !== 'Cancelled' && item.status === 'Unpaid'

  if (!showCancelButton && !showPayButton && !showStatus) {
    return null
  }

  return (
    <>
      <div className='d-flex gap-10'>
        {showPayButton ? (
          <>
            {item?.amount === 0 ? null : (
              <Button
                size='sm'
                color='primary'
                outline
                onClick={handleClickPay}
                icon={<i className='bx bx-money font-size-18' />}
                className='font-size-14 flex-grow-1 flex-md-grow-0'
              >
                Pay
              </Button>
            )}
          </>
        ) : !showStatus ? null : (
          statusBadge
        )}

        {!showCancelButton ? null : (
          <Button
            size='sm'
            color='danger'
            outline
            onClick={() => {
              setShowConfirmation(true)
            }}
            icon={<i className='bx bxs-x-circle font-size-18' />}
            className='font-size-14 border-transparent bg-soft-danger hover:bg-danger flex-grow-1 flex-md-grow-0'
            loading={cancelingItem}
            disabled={cancelingItem}
          >
            Cancel
          </Button>
        )}
      </div>

      <ConfirmationModal
        toggle={() => setShowConfirmation((o) => !o)}
        isOpen={showConfirmation}
        title='Cancel Payment'
        caption='Cancel Payment'
        buttonColor='danger'
        onConfirm={() => {
          setShowConfirmation(false)
          cancelItem({ payment_id: item?.id })
        }}
        message='Are you sure you want to cancel this payment?'
        negativeCaption='Close'
      />
    </>
  )
}

function ItemActionsDropdown({ item, onUpdate, isAdmin }) {
  const [revertModalOpen, setRevertModalOpen] = useState(false)

  const user = useSelector((state) => state.Account?.user)
  const userCanRevert = !isAdmin && user?.type === userTypes.COMPANY

  const { startFetch: revertPayment, isLoading: revertingPayment } = useFetch({
    action: revertCancelledPayment,
    onComplete: () => {
      setRevertModalOpen(false)
      onUpdate?.()
    },
  })

  const hasActivities = item?.activities?.length > 0

  if (!hasActivities) return null

  const lastActivity = item?.activities[item?.activities.length - 1]

  return (
    <>
      <ActionsDropdown
        options={[
          {
            tag: 'div',
            className: 'text-muted rp-capitalize',
            content: getActivityPhrase(lastActivity),
            toggle: false,
          },
          item.status !== 'Cancelled' || !userCanRevert
            ? null
            : {
                content: 'Revert status',
                className: 'text-primary text-underline',
                onClick: () => setRevertModalOpen(true),
              },
        ].filter(Boolean)}
      />

      <ConfirmationModal
        toggle={() => setRevertModalOpen((o) => !o)}
        isOpen={revertModalOpen}
        title='Revert cancelled payment'
        caption='Revert cancelled payment'
        buttonColor='danger'
        onConfirm={() => {
          revertPayment({ payment_id: item?.id })
        }}
        message='Are you sure you want to revert this cancelled payment?'
        negativeCaption='Close'
        confirmLoading={revertingPayment}
      />
    </>
  )
}

function getActivityPhrase(activity) {
  if (!activity) return null

  const formattedDate = format(
    new Date(activity?.date * 1000),
    'dd/MM/yy HH:mm',
  )

  return (
    <>
      {`${activity?.status} by ${activity?.user}`}
      <br />
      {`on ${formattedDate}`}
    </>
  )
}

export default Payments
