import React from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// Simple bar
import SimpleBar from 'simplebar-react'

// i18n
import { withTranslation } from 'react-i18next'
import SidebarContent from './SidebarContent'

const Sidebar = (props) => {
  return (
    <React.Fragment>
      {props.type !== 'condensed' ? (
        <div className='vertical-menu'>
          <div data-simplebar className='h-100'>
            <SimpleBar>
              <SidebarContent />
            </SimpleBar>
          </div>
        </div>
      ) : (
        <></>
      )}
    </React.Fragment>
  )
}

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {},
)(withRouter(withTranslation()(Sidebar)))
