import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// Simple bar
import SimpleBar from 'simplebar-react'
// i18n
import { withTranslation } from 'react-i18next'

import SidebarContent from './SidebarContent'
import { useResizeForSideBar } from '../../../../helpers/hooks'

const Sidebar = (props) => {
  const isMobile2 = useResizeForSideBar()
  return (
    <>
      {!isMobile2 && (
        <div className='vertical-menu'>
          <div data-simplebar className='h-100'>
            {props.type !== 'condensed' ? (
              <SimpleBar style={{ maxHeight: '100%' }}>
                <SidebarContent routePermissions={props.routePermissions} />
              </SimpleBar>
            ) : (
              <SidebarContent routePermissions={props.routePermissions} />
            )}
          </div>
        </div>
      )}
    </>
  )
}

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {},
)(withRouter(withTranslation()(Sidebar)))
