import cx from 'classnames'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Popover, PopoverBody } from 'reactstrap'

import { StyledH5 } from '../../../components/Typo'
import { useResize } from '../../../helpers/hooks'
import unlockPopover from '../../../assets/images/unlock_popover.svg'

const ContractTypeSelect = ({ onContractTypeChanged, value, options }) => {
  const [selected, setSelected] = useState(value)
  const [showAccessModal, setShowAccessModal] = useState(false)
  const isMobile = useResize()
  const company = useSelector(
    (state) => state.userProfile?.userProfile?.company,
  )

  const isEorEnabled = (i) => i === 3 && company?.eor !== 1
  const thereIs4Type = options?.length === 4

  const colNumber = thereIs4Type ? '2' : '3'
  const colWidth = `calc(100% / ${colNumber})`
  const colGutter = `var(--gap-1rem) * (${colNumber} - 1) / ${colNumber}`
  const flexBasis = `calc(${colWidth} - ${colGutter})`

  return (
    <div
      className='d-flex flex-wrap mb-3'
      style={{ '--gap-1rem': '1rem', gap: 'var(--gap-1rem)' }}
    >
      {options.map((option, i) => {
        return (
          <button
            key={option.value}
            className={cx('d-flex p-4 rounded w-100 border text-left', {
              'align-items-center': thereIs4Type,
              'flex-column': !thereIs4Type && !isMobile,
              'text-primary border-primary': i === selected,
              'text-secondary border-light': i !== selected,
              'bg-soft-self-badge': isEorEnabled(i),
              'bg-transparent': !isEorEnabled(i),
            })}
            id={`pm_popover_${i}`}
            type='button'
            style={{
              gap: '0.75rem',
              flexBasis: isMobile ? '100%' : flexBasis,
            }}
            onClick={() => {
              if (!isEorEnabled(i)) {
                setSelected(i)
                onContractTypeChanged(i)
              } else {
                setShowAccessModal(true)
              }
            }}
          >
            {isEorEnabled(i) && (
              <Popover
                placement='top'
                isOpen={showAccessModal}
                toggle={() => setShowAccessModal(false)}
                target={`pm_popover_${i}`}
                trigger='legacy'
                style={{
                  boxShadow: 'rgba(149, 157, 165, 0.2) 0px 16px 24px',
                }}
                className='unbo-popover'
              >
                <PopoverBody className='p-0'>
                  <div>
                    <div className='d-flex p-2 align-items-start justify-content-start'>
                      <img
                        src={unlockPopover}
                        width={16}
                        height={16}
                        className='ml-2 mt-2'
                        alt=''
                      />
                      <div className='pl-2'>
                        <div className='px-2 pt-2'>
                          <StyledH5
                            className='font-size-16'
                            style={{ color: '#424652' }}
                          >
                            Unlock full-time contract
                          </StyledH5>
                        </div>

                        <div className='px-2 text-secondary font-size-12'>
                          <p
                            style={{ color: '#777F9E', marginBottom: '0.4rem' }}
                          >
                            Full-time contract is a premium service where
                            RemotePass acts as the legal employer on your behalf
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      className='px-3 py-3'
                      style={{ borderTop: '1px solid #E7E8F2' }}
                    >
                      <button
                        type='button'
                        className='btn btn-primary rounded btn-sm btn-block py-2'
                        style={{ color: 'white' }}
                        onClick={() =>
                          window.Intercom(
                            'showNewMessage',
                            'Hi, I want to unlock access to full time contracts on my account',
                          )
                        }
                      >
                        Proceed
                      </button>
                    </div>
                  </div>
                </PopoverBody>
              </Popover>
            )}
            <div>{i === selected ? option.activeIcon : option.icon}</div>
            <div
              className={cx({
                'text-primary': i === selected,
                'text-dark': i !== selected,
              })}
            >
              <h5 className='rp-font-bold' style={{ color: 'currentcolor' }}>
                {option.label}
              </h5>
              <p
                className={cx('font-size-12 mb-0', {
                  'text-secondary': i !== selected,
                })}
              >
                {option.description}
              </p>
            </div>
          </button>
        )
      })}
    </div>
  )
}

export default ContractTypeSelect
