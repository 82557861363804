import React, { useState } from 'react'
import { Badge, Card, Col, Container, Row, Spinner, Table } from 'reactstrap'
import toastr from 'toastr'

import { useFetch } from '../../../../helpers/hooks'
import {
  autoWithdraw,
  getWithdrawList,
  multipleAutoWithdraw,
} from '../../../../services/api'
import { StyledH5, StyledH6, StyledP } from '../../../../components/Typo'
import WiseButton from '../../../../components/WiseButton'
import ADMIN_PERMISSIONS from '../../../../config/admin-permissions'
import useHasPermission from '../../../../helpers/hooks/admin/has-permission'
import Toggle from '../../../../components/Forms/Toggle/Toggle'
import Button from '../../../../components/ui/button'
import UserFlagging from '../../../../components/userFlag'

const AutoWithdraw = () => {
  const [withdrawsData, setWithdrawsData] = useState([])
  const [selectedWithdraws, setSelectedWithdraws] = useState([])
  const [forWithdraw, setForWithdraw] = useState(-1)
  const withdraws = useFetch({
    autoFetch: true,
    initResult: null,
    action: getWithdrawList,
    onComplete: (data) => {
      setWithdrawsData(
        data.map((dt) => ({
          ...dt,
          selected: false,
        })),
      )
    },
    withAdminAccess: true,
    body: { transferred: 1 },
    onError: (err) => {
      toastr.error(err)
    },
  })
  const withdraw = useFetch({
    action: autoWithdraw,
    withAdminAccess: true,
    onComplete: () => {
      setForWithdraw(-1)
      setSelectedWithdraws([])
      withdraws.startFetch(null, false)
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const multipleWithdraw = useFetch({
    action: multipleAutoWithdraw,
    withAdminAccess: true,
    onComplete: () => {
      // setForWithdraw(-1)
      setSelectedWithdraws([])
      withdraws.startFetch(null, true)
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  const handleWithdraw = (b, i) => {
    setForWithdraw(i)
    withdraw.startFetch({
      user_id: b?.contractor?.id,
      amount: b?.balance,
      currency_id: b?.currency?.id,
      payment_method_id: b?.contractor?.default_account?.method_id,
      account_id: b?.contractor?.default_account?.id,
    })
  }
  const handleWithdrawChecked = (data) => {
    const newData = [...withdrawsData]
    const index = newData.findIndex((e) => e.id === data.id)
    newData[index].selected = !newData[index].selected
    setWithdrawsData(newData)
    const newWithdraws = [...selectedWithdraws]
    if (newWithdraws.filter((e) => e.id === data.id).length > 0) {
      const withdrawIndex = newWithdraws.findIndex((e) => e.id === data.id)
      newWithdraws.splice(withdrawIndex, 1)
      setSelectedWithdraws(newWithdraws)
    } else {
      newWithdraws.push({
        id: data?.id,
        user_id: data?.contractor?.id,
        amount: data?.balance,
        currency_id: data?.currency?.id,
        payment_method_id: data?.contractor?.default_account?.method_id,
        account_id: data?.contractor?.default_account?.id,
      })
      setSelectedWithdraws(newWithdraws)
    }
  }
  const handleMultipleWithdraws = () => {
    const body = {
      withdraws: selectedWithdraws,
    }
    multipleWithdraw.startFetch(body)
  }
  const hasEditPermission = useHasPermission(
    ADMIN_PERMISSIONS.MANAGE_AUTO_WITHDRAWS,
  )

  return (
    <div className='page-content'>
      <Container fluid className={'p-0 m-0'}>
        <Row className='justify-content-end mx-0 mb-3 mb-md-4'>
          <Col xs={12} md={6} className='px-0 mb-2 mb-md-0'>
            <StyledH5 min={'22px'} max={'32px'} className='mb-0'>
              Auto withdraw
            </StyledH5>
          </Col>
          <Col>
            <Row className='justify-content-md-end' style={{ gap: '0.75rem' }}>
              <Button
                onClick={() => {
                  handleMultipleWithdraws()
                }}
                disabled={selectedWithdraws.length === 0}
                color={selectedWithdraws.length === 0 ? 'secondary' : 'primary'}
                className='px-2 py-1 px-md-4 py-md-2'
              >
                Process Withdraws
              </Button>
              <Button
                onClick={() => {
                  withdraws.startFetch(null, true)
                }}
                className='px-2 py-1 px-md-4 py-md-2'
              >
                Refresh Withdraws
              </Button>
            </Row>
          </Col>
        </Row>

        <Row className={'p-0 m-0'}>
          <Col className={'p-0 m-0'}>
            <Card className={'p-0 m-0'}>
              {withdraws.isLoading ? (
                <div
                  className='d-flex justify-content-center align-items-center'
                  style={{ minHeight: 145 }}
                >
                  <Spinner type='grow' color='primary' />
                </div>
              ) : (
                <>
                  <div className='d-block d-md-none p-3'>
                    {withdrawsData?.map((order, key) => (
                      <WithdrawCardAdmin
                        order={order}
                        withdraw={withdraw}
                        forWithdraw={forWithdraw}
                        handleWithdraw={(order, index) =>
                          handleWithdraw(order, index)
                        }
                        index={key}
                        key={key}
                        hasEditPermission={hasEditPermission}
                      />
                    ))}
                  </div>
                  <div className='table-responsive mb-0 d-none d-md-block'>
                    <Table className='table table-centered table-nowrap text-muted'>
                      <thead className='thead-light'>
                        <tr>
                          <th className='border-top-0'>Contractor name</th>
                          <th className='border-top-0 text-right'>Balance</th>
                          <th className='border-top-0 text-right'>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {withdrawsData?.map((order, key) => {
                          const formatter = new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: order?.currency?.code || 'USD',
                          })
                          return (
                            <tr key={'_order_' + key}>
                              <td>
                                <div className='d-flex'>
                                  {order.contractor.is_flagged ? (
                                    <UserFlagging
                                      user={order?.contractor}
                                      onCompleteAction={() => {
                                        withdraws.startFetch(null, false)
                                      }}
                                    />
                                  ) : null}
                                  {order.contractor?.first_name}{' '}
                                  {order.contractor?.last_name}
                                </div>
                              </td>
                              <td className='text-right'>
                                {formatter.format(order.balance)}
                              </td>
                              <td className='text-right'>
                                {!hasEditPermission ||
                                order.queued === 1 ? null : (
                                  <Button
                                    size='sm'
                                    onClick={() => {
                                      handleWithdraw(order, key)
                                    }}
                                  >
                                    {(withdraw.isLoading &&
                                      forWithdraw === key) ||
                                    (selectedWithdraws.filter(
                                      (e) => e.id === order.id,
                                    ).length > 0 &&
                                      multipleWithdraw.isLoading) ? (
                                      <i className='bx bx-loader bx-spin' />
                                    ) : (
                                      'Withdraw'
                                    )}
                                  </Button>
                                )}
                              </td>
                              <td className='text-center'>
                                {order.failed === 1 ? (
                                  <Badge
                                    className={
                                      'font-size-14  rounded bg-soft-danger'
                                    }
                                    color='white'
                                    pill
                                  >
                                    <StyledP
                                      style={{ padding: 4 }}
                                      min={'12px'}
                                      max={'14px'}
                                      className='mb-0 text-danger'
                                    >
                                      failed
                                    </StyledP>
                                  </Badge>
                                ) : order.queued === 1 ? (
                                  <i className='bx bx-loader bx-spin' />
                                ) : (
                                  <Toggle
                                    id={`wdr${key}`}
                                    check={order.selected}
                                    change={() => {
                                      handleWithdrawChecked(order)
                                    }}
                                  />
                                )}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </div>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
const WithdrawCardAdmin = ({
  index,
  order,
  handleWithdraw,
  withdraw,
  forWithdraw,
  hasEditPermission,
}) => {
  return (
    <div
      className='py-0 mb-3 position-relative rounded bg-white'
      style={{
        boxShadow: '0px 1px 0px #DFE1E6',
        border: '1px solid #E7E8F2',
      }}
    >
      <Row className='p-3 m-0 border-bottom'>
        <Col xs={12} className='p-0 m-0 d-flex justify-content-between'>
          <StyledH5 className='text-dark font-weight-normal mb-0 font-size-14'>
            Contractor name
          </StyledH5>

          <StyledH6 className='text-primary mb-0 border-secondary font-size-12'>
            {order.contractor?.first_name} {order.contractor?.last_name}
          </StyledH6>
        </Col>
      </Row>
      <div className='p-3'>
        <div className='d-flex mb-3 align-items-center justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            Balance
          </h6>
          <h6 className='text-dark font-size-14 font-weight-normal mb-0'>
            {order.balance}
          </h6>
        </div>
        <div className='d-flex mb-0 align-items-center justify-content-between align-items-center'>
          {!hasEditPermission ? null : (
            <WiseButton
              text='Withdraw'
              minWidth='100%'
              onClick={() => {
                handleWithdraw(order, index)
              }}
              loading={withdraw.isLoading && forWithdraw === index}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default AutoWithdraw
