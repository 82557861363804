import React, { useEffect, useState } from 'react'
import { Badge, Col, Row, Table } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import NoContent from '../../../components/NoContent'
import { useHistory, withRouter } from 'react-router'
import moment from 'moment'
import { useFetch, usePermissions, useResize } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import { userTypes } from '../../../helpers/enum'
import {
  archiveAdminContract,
  archiveContract,
  getContractDetail,
} from '../../../services/api'
import styled from 'styled-components'
import archiveIcon from '../../../assets/images/archiveBlack.svg'
import unArchiveIcon from '../../../assets/images/unarchive.svg'
import { StyledH6, StyledP } from '../../../components/Typo'
import SearchBar from '../../../components/SearchBar'
import WiseButton from '../../../components/WiseButton'
import ContractRef from '../../AdminPanel/components/ContractRef'
import Avatar from '../../../components/Avatar'
import { setContractToDuplicate } from '../../../store/contract/actions'
import ActionsDropdown from '../../../components/ActionsDropdown'
import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import Container from 'reactstrap/lib/Container'
import welcomeImage from './../../../assets/images/welcome.svg'
import UserFlagging from '../../../components/userFlag'

const ContractList = (props) => {
  const user = useSelector((state) => state?.Account?.user)
  const { hasAccess } = usePermissions()
  const isMobile = useResize()
  const [data, setData] = useState(props.Orders || [])
  const [query, setQuery] = useState(null)
  const defaultState = { label: 'All', value: -1 }
  const [state, setState] = useState(defaultState)
  const status = useSelector(
    (state) => state?.Layout?.staticData?.contract_statuses,
  )
  const getStatusColor = (status) => {
    switch (status?.id) {
      case 4:
        return 'success'
      case 5:
        return 'danger'
      case 6:
        return 'danger'
      case 7:
        return 'danger'
      default:
        return 'warning'
    }
  }

  useEffect(() => {
    setData(props.Orders)
  }, [props.Orders])

  const handleSearch = (query) => {
    setQuery(query)
    if (props.onQuery && query && query !== '') {
      props.onQuery(query)
      return
    }
    if (!query || query === '') {
      setData(props.Orders)
      return
    }
    const newData = data.filter((e) => {
      const secondPart =
        user?.type === userTypes.COMPANY ? e?.contractor : e?.client
      return (
        e?.name?.toUpperCase()?.includes(query?.toUpperCase()) ||
        e?.ref?.toUpperCase()?.includes(query?.toUpperCase()) ||
        secondPart?.first_name?.toUpperCase()?.includes(query?.toUpperCase()) ||
        secondPart?.last_name?.toUpperCase()?.includes(query)
      )
    })
    if (newData.length) {
      setData(newData)
    }
  }
  const handleFilter = (elm) => {
    setState(elm)
    if (elm?.value === -1) {
      clearFilter()
    } else {
      setData(props?.Orders.filter((s) => s?.status?.id === elm.value))
    }
  }
  const clearFilter = () => {
    setData(props.Orders)
    setState(defaultState)
  }
  const getContractDetailUrl = (contract) => {
    if (
      !props.isAdmin &&
      (hasAccess(permissions.ViewContractsDetails) ||
        user?.type === userTypes.CONTRACTOR)
    ) {
      return `/contract/detail?id=${contract.ref}`
    } else if (props.isAdmin) {
      return `/admin/contract/detail?id=${contract.ref}`
    } else {
      return '#'
    }
  }

  return (props.Orders && props.Orders?.length > 0) || props.isAdmin ? (
    isMobile ? (
      <div>
        {props.withArchive &&
          (user?.type === userTypes.COMPANY || props.isAdmin) && (
            <Col className='px-3 pb-3'>
              <SearchBar
                query={query}
                className='mr-0 mr-md-2'
                onQueryChanged={handleSearch}
              />
            </Col>
          )}
        <Col
          className='p-3'
          style={{
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 1px 0px #DFE1E6',
            borderRadius: 4,
          }}
        >
          {data?.map((order, i) => (
            <ContractCard
              index={i}
              key={'_order_' + i}
              archiveValue={props.archiveValue}
              withArchive={props.withArchive}
              order={order}
              isAdmin={props.isAdmin}
              getContractDetailUrl={getContractDetailUrl}
              getStatusColor={getStatusColor}
              onUpdate={props.onUpdate}
            />
          ))}
        </Col>
      </div>
    ) : (
      <StyledTableCont>
        {props.withArchive &&
          (user?.type !== userTypes.CONTRACTOR || props.isAdmin) && (
            <Container fluid>
              <Row className='pb-3 px-3' style={{ gap: '0.75rem' }}>
                <Col className='px-0'>
                  <SearchBar
                    query={query}
                    placeholder={
                      props.isAdmin
                        ? 'Search by name, email, or contract ID'
                        : undefined
                    }
                    onQueryChanged={handleSearch}
                    className='mr-md-2'
                  />
                </Col>
                {props?.withCustomFilter && user?.type === userTypes.COMPANY && (
                  <Col md={4} xs={12} className='px-0'>
                    <CustomSelect
                      options={
                        Array.isArray(status)
                          ? [
                              defaultState,
                              ...(status?.map((e) => ({
                                label: e.name,
                                value: e.id,
                              })) ?? []),
                            ]
                          : [defaultState]
                      }
                      value={state}
                      onChange={handleFilter}
                    />
                  </Col>
                )}
              </Row>
            </Container>
          )}
        <div className={!props.isAdmin && 'table-with-dropdown'}>
          <Table
            responsive
            className='table-centered table-nowrap text-muted mb-0'
          >
            <thead>
              <tr
                style={{
                  borderTop: 'hidden',
                  borderBottom: '1px solid #E7E8F2',
                }}
              >
                <th
                  style={{ fontWeight: '500' }}
                  className='text-dark font-size-14 px-4'
                >
                  Contract ID
                </th>
                {props.isAdmin && (
                  <th
                    style={{ fontWeight: '500' }}
                    className='text-dark font-size-14'
                  >
                    Created on{' '}
                  </th>
                )}
                <th
                  style={{ fontWeight: '500' }}
                  className='text-dark font-size-14'
                >
                  Role
                </th>
                <th
                  style={{ fontWeight: '500' }}
                  className='text-dark font-size-14'
                >
                  Type
                </th>
                <th
                  style={{ fontWeight: '500' }}
                  className='text-dark font-size-14'
                >
                  Rate
                </th>
                {!!props.isAdmin && (
                  <th
                    style={{ fontWeight: '500' }}
                    className='text-dark font-size-14'
                  >
                    Company
                  </th>
                )}
                <th
                  style={{ fontWeight: '500' }}
                  className='text-dark font-size-14'
                >
                  {!!props.isAdmin || user?.type === 'client'
                    ? 'Name'
                    : 'Client'}
                </th>

                {!!props.isAdmin && (
                  <th
                    style={{ fontWeight: '500' }}
                    className='text-dark font-size-14'
                  >
                    Client
                  </th>
                )}
                <th
                  style={{ fontWeight: '500' }}
                  className='text-dark font-size-14'
                >
                  Status
                </th>
                {!props.isAdmin &&
                  props.withArchive &&
                  hasAccess(permissions.archiveContracts) && (
                    <th
                      style={{ fontWeight: '500' }}
                      className='text-dark font-size-14'
                    ></th>
                  )}
              </tr>
            </thead>
            <tbody>
              {data?.map((order) => {
                return (
                  <ContractLine
                    key={`contract-${order?.ref}`}
                    order={order}
                    isAdmin={props.isAdmin}
                    handleUserClick={props.handleUserClick}
                    archiveValue={props.archiveValue}
                    withArchive={props?.withArchive}
                    getStatusColor={getStatusColor}
                    getContractDetailUrl={getContractDetailUrl}
                    onUpdate={props.onUpdate}
                  />
                )
              })}
            </tbody>
          </Table>
        </div>
      </StyledTableCont>
    )
  ) : (
    <div>
      <NoContent
        image={welcomeImage}
        subtitle={
          user?.type === userTypes.COMPANY
            ? undefined
            : 'Manage contracts, invoices and get paid in +126 currencies'
        }
        title={''}
        withAction={hasAccess(permissions.CreateContracts)}
        actionTitle='New Contract'
        onAction={() => {
          props.history.push('/contract/create')
        }}
      />
    </div>
  )
}

const ContractCard = ({
  order,
  withArchive,
  archiveValue,
  getContractDetailUrl,
  getStatusColor,
  isAdmin,
  onUpdate,
}) => {
  const user = useSelector((state) => state?.Account?.user)
  const { hasAccess } = usePermissions()

  const secondPart =
    user?.type === userTypes.COMPANY ? order?.contractor : order?.client

  const archive = useFetch({
    action: isAdmin ? archiveAdminContract : archiveContract,
    withAdminAccess: isAdmin,
    onComplete: (_, body) => {
      onUpdate()
      window.analytics.track('Archived a contract', {
        contract_id: body?.contract_id,
        contract_status: body?.contract?.status?.name,
      })
    },
  })
  return (
    <div
      className=' py-0   mb-3'
      style={{
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 1px 0px #DFE1E6',
        borderRadius: 4,
        border: '1px solid #E7E8F2',
        position: 'relative',
      }}
    >
      <Row className='p-3 m-0 border-bottom'>
        <Col xs={8} className='p-0 m-0'>
          <p
            style={{ fontWeight: 'bold' }}
            className={'text-dark   font-size-14 mb-0'}
          >
            {order.name}
          </p>
          <Link
            style={{ textDecoration: 'none' }}
            className='text-primary border-bottom border-secondary font-size-12'
            to={getContractDetailUrl(order)}
          >
            {order.ref}
          </Link>
        </Col>
        <Col
          xs={4}
          className='align-items-center justify-content-end d-flex p-0 m-0'
        >
          <Badge
            className={`font-size-14  rounded bg-soft-${getStatusColor(
              order?.status,
            )}`}
            color='white'
            pill
          >
            <StyledP
              style={{ padding: 4 }}
              min={'12px'}
              max={'14px'}
              className={`mb-0 text-${getStatusColor(order?.status)}`}
            >
              {order?.status?.name.includes('Pending')
                ? 'Pending'
                : order?.status?.name}
            </StyledP>
          </Badge>
        </Col>
      </Row>
      <div className='p-3'>
        <div className='d-flex mb-3 align-items-center justify-content-between align-items-center'>
          <h6 className={'text-dark font-weight-normal mb-0   font-size-14'}>
            Created on
          </h6>
          <h6 className='font-weight-normal mb-0 text-dark   font-size-14'>
            {moment(order.created_at * 1000).format('MM/DD/YYYY HH:mm')}
          </h6>
        </div>
        <div className='d-flex mb-3 align-items-center justify-content-between align-items-center'>
          <h6 className={'text-dark font-weight-normal mb-0   font-size-14'}>
            Rate
          </h6>
          <h6 className='text-dark   font-size-14 font-weight-normal mb-0'>
            {order.total}
          </h6>
        </div>

        <div className='d-flex mb-3 justify-content-between align-items-center'>
          <h6 className='  text-dark font-weight-normal mb-0   font-size-14'>
            Type
          </h6>
          <h6 className='text-dark font-weight-normal mb-0   font-size-14'>
            {order.type}
          </h6>
        </div>

        <div className='d-flex mb-0 justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0  font-size-14'>
            Contractor
          </h6>
          {secondPart
            ? !secondPart?.photo
            : !isAdmin && (
                <div className='text-dark   font-size-14'>
                  <Link
                    to={`/contract/detail?id=${order.ref}`}
                    className='font-weight-bold  text-primary font-size-14 '
                  >
                    Invite
                  </Link>
                </div>
              )}
          <h6 className={'text-dark font-weight-normal mb-0  font-size-14'}>
            {secondPart?.first_name || secondPart?.first_name
              ? `${secondPart?.first_name || ''}
               ${secondPart?.last_name || ''}`
              : secondPart?.email}
          </h6>
        </div>
        {!!isAdmin && (
          <div className='d-flex mt-3 justify-cInvoicesontent-between align-items-center'>
            <h6 className='text-dark font-weight-normal mb-0  font-size-14'>
              Company
            </h6>
            <StyledH6
              className='font-size-14'
              style={{
                color: '#4A4E5B',
                fontWeight: '400',
                marginLeft: '10px',
              }}
            >
              {order?.company}
            </StyledH6>
          </div>
        )}
        {!!isAdmin && (
          <div className='d-flex mt-3 justify-content-between align-items-center'>
            <h6 className='text-dark font-weight-normal mb-0  font-size-14'>
              Client
            </h6>
            <StyledH6
              className='font-size-14   '
              style={{
                color: '#4A4E5B',
                fontWeight: '400',
                marginLeft: '10px',
              }}
            >
              {order?.client?.first_name || order?.client?.first_name
                ? `${order?.client?.first_name || ''} ${
                    order?.client?.last_name || ''
                  }`
                : order?.client?.email}
            </StyledH6>
          </div>
        )}
      </div>
      {withArchive &&
        hasAccess(permissions.archiveContracts) &&
        (order?.status?.id !== 4 || archiveValue === 0 || isAdmin) && (
          <div className='px-3 pb-3 w-100'>
            {archive.isLoading ? (
              <div className='d-flex justify-content-center align-items-center'>
                <i className='bx bx-loader bx-spin font-size-16 text-primary' />
              </div>
            ) : (
              <WiseButton
                textColor={'dark'}
                onClick={() => {
                  archive.startFetch({
                    contract_id: order.id,
                    status: archiveValue,
                  })
                }}
                bk={'white'}
                border
                width={'100%'}
                minWidth={'100%'}
                text={archiveValue === 0 ? 'UnArchive' : 'Archive'}
                icon={
                  archiveValue === 0 ? (
                    <img
                      src={unArchiveIcon}
                      alt={'archive icon'}
                      style={{ width: 16, marginRight: 8 }}
                    />
                  ) : (
                    <img
                      src={archiveIcon}
                      alt={'archive icon'}
                      style={{ width: 16, marginRight: 8 }}
                    />
                  )
                }
              />
            )}
          </div>
        )}
    </div>
  )
}

const ContractLine = ({
  order,
  isAdmin,
  handleUserClick,
  withArchive,
  archiveValue,
  getContractDetailUrl,
  getStatusColor,
  onUpdate,
}) => {
  const user = useSelector((state) => state?.Account?.user)
  const { hasAccess } = usePermissions()
  const dispatch = useDispatch()
  const history = useHistory()
  const [showActions, setShowActions] = useState(false)

  const toDuplicate = useFetch({
    action: getContractDetail,
    onComplete: (data) => {
      dispatch(setContractToDuplicate(data))
      history.push('/contract/create')
    },
  })

  const archive = useFetch({
    action: isAdmin ? archiveAdminContract : archiveContract,
    withAdminAccess: isAdmin,
    onComplete: (_, body) => {
      onUpdate()
      window.analytics.track('Archived a contract', {
        contract_id: body?.contract_id,
        contract_status: body?.contract?.status?.name,
      })
    },
  })

  const secondPart =
    !!isAdmin || user?.type === userTypes.COMPANY
      ? order?.contractor
      : order?.client
  const total = order.total.split('/')
  const canArchive =
    (order?.status?.id !== 4 || archiveValue === 0 || isAdmin) &&
    withArchive &&
    hasAccess(permissions.archiveContracts)
  const canDuplicate =
    order?.type !== 'Full Time' && hasAccess(permissions.archiveContracts)

  const actionsOptions = []

  if (canArchive) {
    actionsOptions[0] = {
      // eslint-disable-next-line react/display-name
      component: () =>
        archiveValue === 0 ? (
          <div className='row p-0 m-0'>
            <div />
            Unarchive
          </div>
        ) : (
          <div className='row p-0 m-0'>
            <div />
            Archive
          </div>
        ),
      onClick: () => {
        archive.startFetch({
          contract_id: order.id,
          status: archiveValue,
          contract: order,
        })
      },
    }
  }
  if (canDuplicate && user?.type !== userTypes.CONTRACTOR) {
    actionsOptions[1] = {
      component: () => 'Duplicate',
      onClick: () => {
        toDuplicate.startFetch({ id: order?.ref })
      },
    }
  }

  const contractUser =
    user?.type === userTypes.CONTRACTOR && !isAdmin
      ? order?.company_name || secondPart?.email
      : secondPart?.full_name ?? secondPart?.first_name
      ? secondPart?.full_name
      : secondPart?.email

  return (
    <tr style={{ borderBottom: '1px solid #E7E8F2' }}>
      <td className='p-4'>
        {isAdmin ? (
          <ContractRef contractId={order.ref} />
        ) : (
          <Link
            className='text-primary font-size-14 '
            style={{ fontWeight: '400' }}
            to={getContractDetailUrl(order)}
          >
            {order.ref}
          </Link>
        )}
      </td>
      {isAdmin && (
        <td className='text-dark font-size-14 ' style={{ fontWeight: '400' }}>
          {moment(order.created_at * 1000).format('MM/DD/YYYY HH:mm')}
        </td>
      )}
      <td
        className='text-dark font-size-14 text-truncate'
        style={{ fontWeight: '400', maxWidth: '24ch' }}
        title={order?.name || ''}
        translate='no'
      >
        {order?.name || ''}
      </td>
      <td className='text-dark font-size-14 ' style={{ fontWeight: '400' }}>
        {order.type}
      </td>
      <td>
        {
          <StyledH6
            style={{ fontWeight: '400' }}
            className={'text-dark font-size-14'}
          >
            {total[0]} <span className={'text-secondary'}>/{total[1]}</span>
          </StyledH6>
        }{' '}
      </td>
      {!!isAdmin && (
        <td className='text-dark font-size-14 ' style={{ fontWeight: '400' }}>
          {order.company}
        </td>
      )}
      <td>
        <Col>
          <Row className='align-items-center flex-nowrap overflow-hidden'>
            {secondPart ? (
              <Avatar
                name={
                  user?.type === userTypes.CONTRACTOR && !isAdmin
                    ? order.company_name || secondPart?.email
                    : secondPart?.first_name || secondPart?.email
                }
                photo={
                  user?.type === userTypes.CONTRACTOR && !isAdmin
                    ? order?.company_logo
                    : secondPart?.photo
                }
                flag={
                  (user?.type === userTypes.COMPANY || isAdmin) &&
                  secondPart?.flag
                }
              />
            ) : (
              !isAdmin && (
                <div className='py-2'>
                  <Link
                    to={`/contract/detail?id=${order.ref}`}
                    className='font-weight-bold'
                  >
                    Invite
                  </Link>
                </div>
              )
            )}
            <div className='font-size-14 ml-2 font-weight-bolder text-dark'>
              {isAdmin ? (
                <div className='d-flex'>
                  {order?.client?.is_flagged ? (
                    <UserFlagging
                      user={order?.client}
                      onCompleteAction={() => {
                        onUpdate()
                      }}
                    />
                  ) : null}
                  <button
                    className='rp-btn-nostyle text-primary px-0 text-truncate'
                    onClick={() => handleUserClick(order)}
                    style={{ fontWeight: 'inherit', maxWidth: '17ch' }}
                    translate='no'
                  >
                    {contractUser}
                  </button>
                </div>
              ) : (
                <div
                  className='text-truncate'
                  style={{ fontWeight: 'inherit', maxWidth: '17ch' }}
                  translate='no'
                >
                  {contractUser}
                </div>
              )}
            </div>
          </Row>
        </Col>
      </td>
      {!!isAdmin && (
        <td>
          <Col>
            <Row className='align-items-center flex-nowrap'>
              {!!order?.client && (
                <Avatar
                  photo={order?.client?.photo}
                  name={order?.client?.first_name || order?.client?.email}
                  flag={order?.client?.flag}
                />
              )}
              <div className='d-flex font-size-14 ml-2 font-weight-bolder text-dark'>
                {order?.client?.is_flagged ? (
                  <UserFlagging
                    user={order?.client}
                    onCompleteAction={() => {
                      onUpdate()
                    }}
                  />
                ) : null}
                {order?.client?.first_name || order?.client?.first_name
                  ? `${order?.client?.first_name || ''} ${
                      order?.client?.last_name || ''
                    }`
                  : order?.client?.email}
              </div>
            </Row>
          </Col>
        </td>
      )}
      <td>
        <div className='flex-row'>
          {order?.amended ? (
            <Badge
              className='font-size-12 rounded bg-soft-warning'
              color='white'
              pill
            >
              <p
                style={{ padding: '3px 2px' }}
                className='font-size-14  mb-0  text-warning'
              >
                Pending
              </p>
            </Badge>
          ) : (
            <Badge
              className={`font-size-12 rounded bg-soft-${getStatusColor(
                order?.status,
              )}`}
              color='white'
              pill
            >
              <p
                style={{ padding: '3px 2px' }}
                className={`font-size-14  mb-0   text-${getStatusColor(
                  order?.status,
                )}`}
              >
                {order?.status?.name.includes('Pending')
                  ? 'Pending'
                  : order?.status?.name}
              </p>
            </Badge>
          )}
        </div>
      </td>
      {!isAdmin && (
        <td className='position-relative'>
          {actionsOptions?.length > 0 && (
            <Row
              style={{ minWidth: '100px' }}
              className='justify-content-center'
            >
              {archive.isLoading || toDuplicate.isLoading ? (
                <i className='bx bx-loader bx-spin text-dark font-size-24' />
              ) : (
                <ActionsDropdown
                  toggle={() => setShowActions((t) => !t)}
                  isOpen={showActions}
                  options={actionsOptions}
                />
              )}
            </Row>
          )}
        </td>
      )}
    </tr>
  )
}

const StyledTableCont = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 0px #dfe1e6;
  border-radius: 4px;
`

export const StyledIcon = styled.span`
  --size: 16px;
  width: var(--size);
  height: var(--size);
  display: flex;
  margin-right: ${(props) => (props.noMargin ? '0' : '10px')};
  background: ${(props) => `url(${props.icon}) no-repeat`};

  &:hover {
    background: ${(props) => `url(${props.hoverIcon}) no-repeat 0 0`};
  }

  p {
    margin-left: 25px;

    &:hover {
      color: #3250e7;
    }
  }
`

export default withRouter(ContractList)
