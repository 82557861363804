import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import Checkbox from 'react-custom-checkbox'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  Col,
  Form,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import ControlledInput from '../../components/ControlledInput'
import { useFetch } from '../../helpers/hooks'
import { updateMemberProfile } from '../../services/api'
import { updateUserProfileInfo } from '../../store/profile/actions'

const schema = yup.object().shape({
  notification_email: yup.string().email().required(),
})
const Notifications = () => {
  const user = useSelector((state) => state.userProfile?.userProfile)
  const [showInput, setShowInput] = useState(!!user?.notification_email)
  const dispatch = useDispatch()
  const {
    control,
    handleSubmit,

    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: { notification_email: user?.notification_email },
  })
  const update = useFetch({
    action: updateMemberProfile,
    onError: toastr.error,
    onComplete: (data) => {
      dispatch(updateUserProfileInfo(data))
      toastr.success('Updated successfully')
    },
  })
  const onSubmit = (v) => {
    update.startFetch(v)
  }
  return (
    <Col className='mb-3 py-4' style={{ minHeight: '40vh' }}>
      <Label className='font-weight-bold font-size-14'>Email Preferences</Label>
      <p className='text-muted'>Your default account email: {user?.email}</p>
      <Row className='p-0 m-0 align-items-center'>
        <Checkbox
          icon={
            <div
              style={{
                display: 'flex',
                flex: 1,
                backgroundColor: '#114EF7',
                alignSelf: 'stretch',
                'align-items': 'center',
                'justify-content': 'center',
              }}
            >
              <i className='bx bx-check  font-size-20  text-white  ' />
            </div>
          }
          labelClassName='m-0 bg-success'
          borderWidth={2}
          name={'check'}
          checked={!!user?.notification_email}
          onChange={(v) => {
            if (!v) update.startFetch({ notification_email: '' })
            setShowInput(v)
          }}
          borderColor='#E7E8F2'
          size={20}
          style={{ overflow: 'hidden' }}
        />
        <p className='text-muted ml-2 mt-2'>Send notifications to this email</p>
        <i
          style={{ marginBottom: '8px' }}
          className='bx bx-info-circle text-muted mx-1'
          id={'emailNotTooltip'}
        />
        <UncontrolledTooltip placement='right' target={'emailNotTooltip'}>
          Receive notifications (Expenses, time-off, signatures etc.) on a
          different email
        </UncontrolledTooltip>
      </Row>
      {showInput && (
        <Col md={6} sm={12}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <ControlledInput
                name={'notification_email'}
                control={control}
                error={errors?.notification_email}
                placeholder={'Email'}
              />
            </FormGroup>
            <Row className='p-0 m-0 justify-content-end'>
              <button className='btn btn-primary' type='submit'>
                {update.isLoading && (
                  <i className='bx bx-loader bx-spin mx-2' />
                )}
                Save
              </button>
            </Row>
          </Form>
        </Col>
      )}
    </Col>
  )
}

export default Notifications
