import React from 'react'
import { Col, Modal, ModalHeader, Row } from 'reactstrap'
import AmendModalImage from '../../../assets/images/amendModal.png'

const AmendModal = ({ isOpen, toggle, onProceed }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} />
      <div className='modal-content'>
        <div className='modal-body p-3 p-md-4'>
          <Row className={'p-0 m-0'}>
            <Col className={'p-0 m-0'}>
              <Row className='justify-content-center p-3 p-md-4 m-0 p-0 '>
                <Col sm={4} className={'p-5 p-md-0 m-0'}>
                  <div>
                    <img src={AmendModalImage} alt='' className='img-fluid' />
                  </div>
                </Col>
              </Row>

              <p>Please note that when you amend an ongoing contract:</p>
              <ul>
                <li>Contract will need to be re-signed by both parties</li>
                <li>Unpaid payments will be removed</li>
                <li>New payment will be created using the new details</li>
              </ul>
              <p>Do you wish to continue?</p>
            </Col>
          </Row>
        </div>
        <div className='modal-footer p-3 p-md-4'>
          <button
            type='button'
            className='btn btn-outline-light'
            onClick={toggle}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn btn-primary'
            data-dismiss='modal'
            onClick={onProceed}
          >
            Proceed
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default AmendModal
