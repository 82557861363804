import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from 'reactstrap'
import styled from 'styled-components'

import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import { StyledH3 } from '../../../components/Typo'
import WiseButton from '../../../components/WiseButton'
import { userTypes } from '../../../helpers/enum'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import { getArchivedContracts, getContractList } from '../../../services/api'
import ClientContractList from './ClientContractList'
import CtList from './ContractList'

const ContractList = (props) => {
  const user = useSelector((state) => state.Account.user)
  const { hasAccess } = usePermissions()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [filtering, setFiltering] = useState(false)
  const [activeTab, setActiveTab] = useState('1')
  const [statusQueries, setStatusQueries] = useState([])
  const [tagQueries, setTagQueries] = useState([])

  const archived = useFetch({
    action: getArchivedContracts,
  })
  useEffect(() => {
    updateList(statusQueries, tagQueries)
  }, [statusQueries, tagQueries])

  const filterByStatus = (checked, value) => {
    if (checked) {
      setStatusQueries([...statusQueries, value])
    } else {
      const newStatusQueries = [...statusQueries]
      const statusIndex = newStatusQueries.findIndex((e) => e === value)
      newStatusQueries.splice(statusIndex, 1)
      setStatusQueries(newStatusQueries)
    }
  }
  const filterByTag = (checked, value) => {
    if (checked) {
      setTagQueries([...tagQueries, value])
    } else {
      const newTagQueries = [...tagQueries]
      const tagIndex = newTagQueries.findIndex((e) => e === value)
      newTagQueries.splice(tagIndex, 1)
      setTagQueries(newTagQueries)
    }
  }
  const clearFilters = () => {
    setTagQueries([])
    setStatusQueries([])
  }
  const updateList = (status, tag, del) => {
    if (del && del === 'delete') {
      setLoading(true)
      clearFilters()
    }
    if (del && del === 'clearF') {
      setFiltering(true)
      clearFilters()
      // setLoading(true)
    }
    if (status?.length > 0 || tag?.length > 0) {
      setFiltering(true)
      // setLoading(true)
    }
    getContractList(user?.token, { status, tag })
      .then((r) => {
        if (r.data.success) {
          window.analytics.track('Viewed contracts', {
            'email-id': user?.email,
          })
          setData(r.data.data)
        }
      })
      .finally(() => {
        setLoading(false)
        setFiltering(false)
      })
    archived.startFetch()
  }
  useEffect(() => {
    if (activeTab === '2') {
      window.analytics.track('Viewed archived contracts', {
        email_id: user?.email,
      })
    }
  }, [activeTab, user?.email])

  return (
    <div className='page-content' style={{ overflow: 'visible' }}>
      {loading ? (
        <Col style={{ minHeight: '30rem' }}>
          <Row
            style={{ minHeight: '30rem' }}
            className='justify-content-center align-items-center'
          >
            <Spinner type='grow' className='mr-2' color='primary' />
          </Row>
        </Col>
      ) : (
        <Container className='p-0 m-0' fluid>
          <Wrapper>
            {data?.length === 0 && archived.data?.length === 0 ? (
              user?.type === userTypes.COMPANY ? (
                <ClientContractList
                  Orders={data}
                  withCustomFilter
                  withArchive
                  onUpdate={updateList}
                  archiveValue={1}
                  filterByStatus={filterByStatus}
                  filterByTag={filterByTag}
                  tagQueries={tagQueries}
                  statusQueries={statusQueries}
                  filtering={filtering}
                  clearFilters={clearFilters}
                />
              ) : (
                <CtList Orders={data} />
              )
            ) : (
              <>
                <Col className={'p-0 m-0'}>
                  <Row className='mb-md-4 mb-0 d-flex justify-content-between align-items-center p-0 m-0 '>
                    <Col xs={12} md={6} className='p-0 m-0 mb-3 mb-md-0'>
                      <StyledH3
                        min={'22px'}
                        max={'32px'}
                        style={{ fontFamily: 'gilroyB' }}
                        className={' text-dark mb-0'}
                      >
                        Contracts
                      </StyledH3>
                    </Col>
                    <Col
                      xs={12}
                      md={6}
                      className='p-0 m-0 mb-3 mb-md-0 justify-content-end d-flex '
                    >
                      <WiseButton
                        onClick={() =>
                          props.history.push(
                            hasAccess(permissions.CreateContracts)
                              ? '/contract/create'
                              : '#',
                          )
                        }
                        minHeight={'48px'}
                        // minWidth={'100%'}
                        bold
                        text='New Contract'
                        icon={
                          <i
                            style={{ marginRight: '18px' }}
                            className='fas fa-plus  font-size-12'
                          />
                        }
                        disabled={!hasAccess(permissions.CreateContracts)}
                      />
                    </Col>
                  </Row>
                </Col>
                <Row
                  style={{ borderTopLeftRadius: 4, borderTopRightRadius: 4 }}
                  className='p-3 m-0 d-block d-md-none justify-content-between align-items-center border-bottom bg-white'
                >
                  <CustomSelect
                    name='Active'
                    isSearchable={false}
                    value={activeTab.label}
                    defaultValue={{ label: 'Active Contracts', value: '1' }}
                    onChange={(v) => setActiveTab(v.value)}
                    options={[
                      {
                        label: 'Active Contracts',
                        value: '1',
                      },
                      archived.data?.length > 0 && {
                        label: 'Archived Contracts',
                        value: '2',
                      },
                    ]}
                  />
                </Row>
                <Row className='m-0 p-0  d-md-flex  d-none justify-content-between align-items-center   pt-2 mb-4 '>
                  <StyledNav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames('font-size-14', {
                          active: activeTab === '1',
                        })}
                        onClick={() => {
                          setActiveTab('1')
                        }}
                        tag='button'
                        type='button'
                      >
                        Active Contracts ({data?.length})
                      </NavLink>
                    </NavItem>
                    {archived.data?.length > 0 && (
                      <NavItem>
                        <NavLink
                          className={classnames('font-size-14', {
                            active: activeTab === '2',
                          })}
                          onClick={() => {
                            setActiveTab('2')
                          }}
                          tag='button'
                          type='button'
                        >
                          Archived Contracts ({archived.data?.length})
                        </NavLink>
                      </NavItem>
                    )}
                  </StyledNav>
                </Row>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId='1'>
                    {user?.type === userTypes.COMPANY ? (
                      <ClientContractList
                        Orders={data}
                        withCustomFilter
                        withArchive
                        onUpdate={updateList}
                        archiveValue={1}
                        filterByStatus={filterByStatus}
                        filterByTag={filterByTag}
                        tagQueries={tagQueries}
                        statusQueries={statusQueries}
                        filtering={filtering}
                        clearFilters={clearFilters}
                      />
                    ) : (
                      <CtList
                        Orders={data}
                        withCustomFilter
                        withArchive
                        onUpdate={updateList}
                        archiveValue={1}
                      />
                    )}
                  </TabPane>
                  <TabPane tabId='2'>
                    {user?.type === userTypes.COMPANY ? (
                      <ClientContractList
                        Orders={archived.data}
                        withCustomFilter
                        withArchive
                        onUpdate={updateList}
                        archiveValue={0}
                        filterByStatus={filterByStatus}
                        filterByTag={filterByTag}
                        tagQueries={tagQueries}
                        statusQueries={statusQueries}
                        filtering={filtering}
                        clearFilters={clearFilters}
                      />
                    ) : (
                      <CtList
                        Orders={archived.data}
                        withCustomFilter
                        withArchive
                        onUpdate={updateList}
                        archiveValue={0}
                      />
                    )}
                  </TabPane>
                </TabContent>
              </>
            )}
          </Wrapper>
        </Container>
      )}
    </div>
  )
}
const Wrapper = ({ children }) => {
  return <div>{children}</div>
}

export const StyledNav = styled(Nav)`
  border-bottom: 0 !important;

  .nav-item {
    margin-right: 1rem;

    .nav-link {
      padding: 6px 4px;
      background-color: #f4f5f7;
      color: #777f9e;
      font-weight: 400;

      &:hover {
        border-top-color: #f4f5f7 !important;
        border-right-color: #f4f5f7 !important;
        border-left-color: #f4f5f7 !important;

        color: #777f9e !important;
      }
    }

    .active {
      border: 0;
      font-weight: 600;
      border-bottom: 2px solid var(--primary) !important;
      color: var(--primary) !important;
      &:hover {
        color: var(--primary) !important;
      }
    }
  }
`

export default withTranslation()(ContractList)
