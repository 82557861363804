import React, { useEffect, useState } from 'react'
import { Prompt, useHistory } from 'react-router'
import { Container, Spinner } from 'reactstrap'
import Col from 'reactstrap/lib/Col'
import Row from 'reactstrap/lib/Row'
import toastr from 'toastr'

import ModalHeader from '../../../components/ModalHeader'
import { useFetch } from '../../../helpers/hooks'
import {
  createTemplateAdmin,
  getAdminContractTemplate,
  getAdminTemplate,
  getContractTemplate,
  updateContractTemplate,
  updateContractTemplateAdmin,
  updateTemplateAdmin,
} from '../../../services/api'
import DiscardModal from '../ContractEditor/DiscardModal'
import SaveChangesModal from '../ContractEditor/SaveChangesModal'
import Editor from './Editor'
import './style.css'

const TemplateEditor = () => {
  const [data, setData] = useState(null)
  const [editing, setEditing] = useState(false)
  const history = useHistory()
  const location = history.location
  const contractId = location.state?.contract_id
  const isAdmin = location.state?.isAdmin

  const update = useFetch({
    action: isAdmin
      ? contractId
        ? updateContractTemplateAdmin
        : updateTemplateAdmin
      : updateContractTemplate,
    withAdminAccess: isAdmin,
    onComplete: () => {
      setTimeout(() => {
        history.goBack()
      }, 500)
    },
    onError: (err) => {
      toastr.error(err)
      setEditing(true)
    },
  })
  const create = useFetch({
    action: createTemplateAdmin,
    withAdminAccess: true,
    onComplete: () => {
      setTimeout(() => {
        history.goBack()
      }, 500)
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const getTemplate = useFetch({
    action: isAdmin
      ? contractId
        ? getAdminContractTemplate
        : getAdminTemplate
      : getContractTemplate,
    withAdminAccess: isAdmin,
    autoFetch: true,
    body: isAdmin
      ? contractId
        ? { contract_id: contractId }
        : { template_id: location.state?.template_id || 1 }
      : { contract_id: contractId },
  })
  const handleChange = (sections) => {
    setData(sections)
  }
  const saveChanges = (text, country) => {
    setEditing(false)
    if (location.state?.template_id === 0 && isAdmin) {
      create.startFetch({
        country_id: country,
        sections: data?.map((e, i) => ({ ...e, order: i + 1 })),
      })
    } else {
      update.startFetch(
        isAdmin
          ? contractId
            ? {
                contract_id: contractId,
                name: text,
                sections: data?.map((e, i) => ({ ...e, order: i + 1 })),
              }
            : {
                template_id: location.state?.template_id,
                sections: data?.map((e, i) => ({ ...e, order: i + 1 })),
              }
          : {
              contract_id: location.state?.contract_id,
              name: text,
              sections: data?.map((e, i) => ({ ...e, order: i + 1 })),
            },
      )
    }
    setSaveChangesModal(false)
  }
  const handleDiscard = () => {
    setEditing(false)
    setDiscardModal(false)
    setTimeout(() => {
      history.goBack()
    }, 500)
  }
  useEffect(() => {
    const handler = (event) => {
      if (!editing) return
      event.preventDefault()
      // Chrome requires returnValue to be set.
      event.returnValue = ''
    }
    window.addEventListener('beforeunload', handler)
    return () => {
      window.removeEventListener('beforeunload', handler)
    }
  }, [editing])

  const [saveChangesModal, setSaveChangesModal] = useState(false)
  const [discardModal, setDiscardModal] = useState(false)
  return (
    <div className='page-content min-vh-100 p-0 m-0'>
      <Container fluid>
        <ModalHeader noExit>
          <Col>
            <Row className='justify-content-end align-items-center '>
              <Col>
                <Row className='justify-content-center'>
                  <h5 className='m-0 mx-2'>{getTemplate.data?.name}</h5>
                </Row>
              </Col>
              <button
                className='btn btn-outline-light'
                onClick={() => setDiscardModal(true)}
              >
                Discard changes
              </button>
              <button
                className='btn btn-primary ml-2'
                onClick={() => setSaveChangesModal(true)}
              >
                Save changes
              </button>
            </Row>
          </Col>
        </ModalHeader>
        <Col className='p-0'>
          <Editor
            onChange={handleChange}
            agreement={getTemplate.data?.default}
            sections={getTemplate.data?.sections}
            placeholders={getTemplate.data?.placeholders}
            onEdit={() => setEditing(true)}
          />
        </Col>
      </Container>
      <SaveChangesModal
        isOpen={saveChangesModal}
        toggle={() => setSaveChangesModal(false)}
        onSaveChanges={saveChanges}
        defaultName={getTemplate.data?.name}
        isAdmin={isAdmin}
        templateId={location.state?.template_id}
      />
      <DiscardModal
        isOpen={discardModal}
        toggle={() => setDiscardModal(false)}
        onDiscard={handleDiscard}
      />
      {(update?.isLoading || getTemplate.isLoading || create.isLoading) && (
        <div
          className='page-content min-vh-100 min-vw-100 p-0 m-0 bg-soft-primary'
          style={{ position: 'fixed', top: 0, zIndex: 999999999 }}
        >
          <Col style={{ minHeight: '100vh' }}>
            <Row
              style={{ minHeight: '100vh' }}
              className='justify-content-center align-items-center'
            >
              <Spinner type='grow' className='mr-2' color='primary' />
            </Row>
          </Col>
        </div>
      )}
      <Prompt
        when={editing}
        message={(location) =>
          `Are you sure you want to go to ${location.pathname}`
        }
      />
    </div>
  )
}

export default TemplateEditor
