import React, { useEffect, useState } from 'react'
import {
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  Row,
  Spinner,
} from 'reactstrap'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import CurrencyInput from 'react-currency-input-field'
import { useDropzone } from 'react-dropzone'

import { addMilestone, uploadWork } from '../../../services/api'
import WiseButton from '../../../components/WiseButton'
import { StyledH5 } from '../../../components/Typo'
import { useFetch, useResize } from '../../../helpers/hooks'
import expencesSvg from './../../../assets/images/expense.svg'

const AddMilestoneModal = ({ isOpen, toggle, contract, onMilestoneAdded }) => {
  const user = useSelector((state) => state.Account.user)
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(null)
  const [addAttachment, setAddAttachment] = useState(false)
  const { acceptedFiles, getRootProps, getInputProps, inputRef } = useDropzone({
    acceptedFiles: 'image/jpeg,image/png,image/gif',
  })
  const isMobile = useResize()
  const removeAll = () => {
    if (inputRef && inputRef.current) {
      acceptedFiles.length = 0
      acceptedFiles.splice(0, acceptedFiles.length)
      inputRef.current.value = ''
    }
  }

  const upload = useFetch({ action: uploadWork })

  const files = acceptedFiles.map((file, index) =>
    upload.isLoading ? (
      <i
        className='bx bx-loader bx-spin text-primary font-size-20'
        key={index}
      />
    ) : (
      <p className='mb-0' key={file.path}>
        {file.path}
      </p>
    ),
  )

  useEffect(() => {
    if (acceptedFiles?.length > 0) {
      upload.startFetch({ file: acceptedFiles[0], type: 'work' })
    }
  }, [acceptedFiles])

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <AvForm
        onValidSubmit={(e, v) => {
          setLoading(true)
          addMilestone(user?.token, {
            ...v,
            contract_id: contract.id,
            amount: value,
            file: upload?.data?.path,
          })
            .then((r) => {
              setLoading(false)
              if (r.data.success) {
                toggle()
                onMilestoneAdded()
              }
            })
            .catch((e) => {
              setLoading(false)
            })
        }}
      >
        <div className='modal-content'>
          {loading ? (
            <Col style={{ minHeight: '10rem' }}>
              <Row
                style={{ minHeight: '10rem' }}
                className='justify-content-center align-items-center'
              >
                <Spinner type='grow' color='primary' />
              </Row>
            </Col>
          ) : (
            <>
              <div className='modal-header p-3 p-md-4'>
                <h5 className='modal-title mt-0'>
                  Add {contract?.type === 'Full Time' ? 'invoice' : 'milestone'}
                </h5>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                  onClick={toggle}
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body px-0'>
                <Row className='justify-content-center p-0 m-0'>
                  <Col className={'p-0 m-0 px-3'}>
                    <FormGroup>
                      <Label>Name</Label>
                      <AvField
                        name={'name'}
                        placeholder='Name'
                        type='text'
                        className='form-control'
                        required
                        id='basicpill-pancard-input5'
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>Amount</Label>
                      <InputGroup>
                        <CurrencyInput
                          placeholder='Value'
                          name='amount'
                          className='form-control'
                          type='text'
                          onKeyPress={(e) => {
                            if (!/[0-9.]/.test(e.key)) {
                              e.preventDefault()
                            }
                          }}
                          required
                          onValueChange={(value) => {
                            setValue(value)
                          }}
                          // currency input props
                          allowDecimals={true}
                          decimalsLimit={2}
                          decimalSeparator='.'
                          groupSeparator=','
                        />
                        <InputGroupAddon addonType='append'>
                          <InputGroupText>
                            {contract?.currency?.code}
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <div className='px-3'>
                  <button
                    onClick={() => setAddAttachment(true)}
                    className='rp-btn-nostyle text-primary'
                    type='button'
                  >
                    Add attachment <i className='fas fa-paperclip' />
                  </button>
                  {addAttachment && (
                    <div className='dropzone border-primary cursor-pointer mt-3'>
                      <div
                        className='dz-message needsclick bg-soft-primary'
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} accept='application/pdf' />

                        {!!acceptedFiles && acceptedFiles?.length > 0 ? (
                          <div className='d-flex justify-content-between align-items-baseline font-size-14'>
                            {files}
                            <button
                              className='rp-btn-nostyle text-danger'
                              onClick={removeAll}
                              type='button'
                            >
                              <i className='bx bx-trash font-size-16' />
                            </button>
                          </div>
                        ) : (
                          <>
                            <img src={expencesSvg} className='pb-4' alt='' />
                            {isMobile ? (
                              <h4 className='mb-0'>Click to upload files.</h4>
                            ) : (
                              <StyledH5 max='16px' className='mb-0'>
                                Drop here or click to upload file
                              </StyledH5>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <StyledFooter className='modal-footer p-3 p-md-4 justify-content-between justify-content-md-end'>
                <WiseButton
                  border
                  bk={'white'}
                  textColor={'dark'}
                  type='button'
                  text={' Cancel'}
                  bold
                  width={'auto'}
                  minWidth={'40%'}
                  onClick={toggle}
                />

                <WiseButton
                  text={'Add'}
                  bold
                  width={'auto'}
                  minWidth={'40%'}
                  type='submit'
                />
              </StyledFooter>
            </>
          )}
        </div>
      </AvForm>
    </Modal>
  )
}

const StyledFooter = styled(Col)`
  @media (max-width: 767px) {
    margin-top: 35vh;
  }
`

export default AddMilestoneModal
