import React from 'react'
import { useController } from 'react-hook-form'
import ReactInputVerificationCode from 'react-input-verification-code'
import { Label } from 'reactstrap'

export function ControlledVerificationCode({
  control,
  name,
  label,
  labelClassName,
  error,
  id,
  required,
  length,
  transform,
  ...props
}) {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue: '',
  })

  return (
    <>
      {!label ? null : (
        <Label for={id} className={labelClassName}>
          {label}
        </Label>
      )}

      <ReactInputVerificationCode
        length={length}
        onChange={(e) =>
          transform?.output ? onChange(transform.output(e)) : onChange(e)
        }
        value={transform?.input ? transform.input(value) : value}
        id={id}
        {...props}
      />

      {error && (
        <p className='mt-2 mb-0 font-size-12 text-danger'>{error.message}</p>
      )}
    </>
  )
}
