import { yupResolver } from '@hookform/resolvers/yup'
import { CalendarCheck, CalendarX, Info } from '@phosphor-icons/react'
import {
  add,
  differenceInBusinessDays,
  differenceInHours,
  eachDayOfInterval,
  isBefore,
  parse,
} from 'date-fns'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import {
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import ActionsDropdown from '../../../components/ActionsDropdown'
import ControlledDatePicker from '../../../components/ControlledDatePicker'
import ControlledSelect from '../../../components/ControlledSelect'
import BadgeX from '../../../components/Table/BadgeX'
import ControlledCheckbox from '../../../components/controlled-checkbox'
import Alert from '../../../components/ui/alert'
import Button from '../../../components/ui/button'
import DataTable from '../../../components/ui/data-table'
import Loader from '../../../components/ui/loader'
import { useFetch } from '../../../helpers/hooks'
import {
  addTimeOff,
  approveTimeOff,
  cancelTimeOff,
  deleteTimeOff,
  getAdminTimesOff,
  getTimesOff,
  rejectTimeOff,
} from '../../../services/api'
import { getCurrencyFormatter } from '../../../utils/formatters/currency'
import { getTimeOffStatusColor } from './TimesOff'
import { userTypes } from '../../../helpers/enum'

function parseDate(date) {
  return date ? parse(date, 'yyyy-MM-dd', new Date()) : null
}

export default function DeTimeOffTab({ contractData }) {
  const [isTimeOffModalOpen, setIsTimeOffModalOpen] = useState(false)
  const user = useSelector((state) => state.Account?.user)

  const location = useLocation()
  const isAdmin = location.pathname?.startsWith('/admin/')

  const {
    data: timesOff,
    isLoading: gettingTimeOffList,
    startFetch: refreshTimeOff,
  } = useFetch(
    {
      action: isAdmin ? getAdminTimesOff : getTimesOff,
      withAdminAccess: isAdmin,
      initResult: [],
      autoFetch: contractData?.id,
      body: { contractId: contractData?.id },
    },
    [contractData?.id],
  )

  const excludeDates = timesOff?.list
    ?.filter((t) => [2, 3].includes(t.status?.id))
    ?.reduce((prev, curr) => {
      const start = parseDate(curr?.from)
      const end = parseDate(curr?.to)

      let toBeExcluded = []
      try {
        toBeExcluded = eachDayOfInterval({ start, end })
      } catch (e) {
        console.log(e)
      }
      return [...prev, ...toBeExcluded]
    }, [])

  const formatter = getCurrencyFormatter(contractData?.currency?.code)

  const { startFetch: removeTimeOff, isLoading: removingTimeOff } = useFetch({
    action: deleteTimeOff,
    onComplete: () => refreshTimeOff(),
  })

  const { startFetch: approve, isLoading: approving } = useFetch({
    action: approveTimeOff,
    onComplete: () => refreshTimeOff(),
  })
  const { startFetch: decline, isLoading: declining } = useFetch({
    action: rejectTimeOff,
    onComplete: () => refreshTimeOff(),
  })
  const { startFetch: cancel, isLoading: canceling } = useFetch({
    action: cancelTimeOff,
    onComplete: () => refreshTimeOff(),
  })

  const columns = useMemo(
    () => [
      { accessor: 'type.name', Header: 'Type' },
      { accessor: 'from', Header: 'Start Date' },
      { accessor: 'to', Header: 'End Date' },
      { accessor: 'days', Header: 'Days' },
      {
        accessor: 'status.name',
        Header: 'Status',
        Cell: ({ cellData }) => (
          <BadgeX status={getTimeOffStatusColor(cellData)}>{cellData}</BadgeX>
        ),
      },
      {
        accessor: 'applied',
        Header: 'Deducted',
        Cell: ({ cellData, rowData }) =>
          cellData ? `Yes (${formatter.format(rowData?.total)})` : 'No',
      },
      {
        accessor: 'id',
        Header: 'Actions',
        style: { width: 300 },
        Cell: ({ rowData }) => {
          // id 2 is pending approval
          const canDelete = rowData?.status?.id === 2

          // only the one that's getting remove that should be loading
          if (removingTimeOff || approving || declining || canceling) {
            return <Spinner size='sm' type='border' color='secondary' />
          }

          const isClient = user?.type === userTypes.COMPANY
          const isPending = rowData?.status?.id === 2

          const options = [
            rowData?.can_cancel && {
              content: 'Cancel',
              onClick: () => {
                cancel({ time_off_id: rowData?.id })
              },
              className: 'text-danger',
            },
            canDelete && {
              content: 'Delete',
              onClick: () => {
                removeTimeOff({ time_off_id: rowData?.id })
              },
              className: 'text-danger',
            },
          ].filter(Boolean)

          return (
            <div className='d-flex align-items-center gap-8'>
              {options?.length <= 0 ? null : (
                <ActionsDropdown options={options} />
              )}

              {!isClient || !isPending ? null : (
                <>
                  <Button
                    size='sm'
                    color='success'
                    onClick={() => {
                      approve({ time_off_id: rowData?.id })
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    size='sm'
                    color='danger'
                    outline
                    onClick={() => {
                      decline({ time_off_id: rowData?.id })
                    }}
                  >
                    Decline
                  </Button>
                </>
              )}
            </div>
          )
        },
      },
    ],
    [
      approve,
      approving,
      cancel,
      canceling,
      decline,
      declining,
      formatter,
      removeTimeOff,
      removingTimeOff,
      user?.type,
    ],
  )

  function showTimeOffModal() {
    setIsTimeOffModalOpen(true)
  }

  if (gettingTimeOffList) {
    return <Loader />
  }

  return (
    <>
      {timesOff?.list?.length <= 0 ? (
        <NoTimeOff showTimeOffModal={showTimeOffModal} />
      ) : (
        <Card className='rp-shadow-2'>
          <CardHeader className='bg-transparent d-flex justify-content-between pt-4'>
            <h3 className='text-gray-h rp-font-gilroyB'>Time Off</h3>

            <div className='d-flex align-items-center gap-12'>
              <div className='bg-slate-50 d-flex gap-12 p-2 rounded'>
                <DaysInfo
                  label='Paid'
                  value={timesOff?.days_paid}
                  icon={<CalendarCheck size={20} />}
                />
                <div className='bg-slate-200' style={{ width: 1 }} />
                <DaysInfo
                  label='Unpaid'
                  value={timesOff?.days_unpaid}
                  icon={<CalendarX size={20} />}
                />
              </div>

              <Button
                icon={<i className='bx bx-plus' />}
                onClick={showTimeOffModal}
              >
                Add time off
              </Button>
            </div>
          </CardHeader>

          <CardBody>
            <DataTable columns={columns} data={timesOff?.list} />
          </CardBody>
        </Card>
      )}

      <TimeOffModal
        isOpen={isTimeOffModalOpen}
        toggle={() => setIsTimeOffModalOpen((open) => !open)}
        onSubmit={() => refreshTimeOff()}
        contract={contractData}
        excludeDates={excludeDates}
      />
    </>
  )
}

function DaysInfo({ label, value, icon }) {
  return (
    <div className='rp-font-gilroyB text-gray-h font-size-14 d-flex gap-8'>
      <div className='align-items-center d-flex gap-2 justify-content-center text-slate-500'>
        {!icon ? null : icon}
        <span>{label}</span>
      </div>
      <div className=''>{!value ? '--' : `${value} days`}</div>
    </div>
  )
}

function NoTimeOff({ showTimeOffModal }) {
  return (
    <div
      className='d-flex flex-column justify-content-center align-items-center gap-16'
      style={{ minHeight: 300 }}
    >
      <p className='text-muted font-size-16 mb-0'>
        Submitted time off requests will be shown here
      </p>

      <Button icon={<i className='bx bx-plus' />} onClick={showTimeOffModal}>
        Add time off
      </Button>
    </div>
  )
}

function TimeOffModal({ isOpen, toggle, contract, excludeDates, onSubmit }) {
  const { startFetch: submitTimeOffRequest, isLoading } = useFetch({
    action: addTimeOff,
    onComplete: (data) => {
      if (data?.success === false) {
        toastr.error(data?.message || 'Something went wrong')
        return
      }
      onSubmit?.()
      toggle?.()
    },
    onError: (error) => {
      toastr.error(error?.message || 'Something went wrong')
    },
  })

  const { time_off_types: timeOffTypes } = useSelector(
    (state) => state?.Layout?.staticData ?? {},
  )

  function handleSubmitRequest(data) {
    const body = { ...data, contract_id: contract?.id }

    submitTimeOffRequest(body)
  }

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      time_off_type_id: '',
      from: '',
      to: '',
      is_half_start_date: false,
      is_half_end_date: false,
    },
    resolver: yupResolver(
      yup.object().shape({
        time_off_type_id: yup
          .string()
          .typeError('Please select a type')
          .required('Please select a type'),
        from: yup
          .string()
          .typeError('The start date is required')
          .required('The start date is required'),
        to: yup
          .string()
          .typeError('The end date is required')
          .required('The end date is required'),
      }),
    ),
  })

  const from = watch('from')
  const to = watch('to')
  const isHalfStartDate = watch('is_half_start_date')
  const isHalfEndDate = watch('is_half_end_date')

  const formattedDistance = useMemo(() => {
    if (!from || !to) {
      return ''
    }

    let parsedFrom = parseDate(from)
    let parsedTo = add(parseDate(to), { days: 1 })

    if (isHalfStartDate) {
      // if the start date is half day, we need to add 12 hours to the start date
      // and 1 day is required to calculate the difference in business days
      // The differenceInBusinessDays calculates the difference between 2 dates and rounds it up
      // e.g. 1 day and 1 hour will be 2 days
      parsedFrom = add(parsedFrom, { hours: 12, days: isHalfEndDate ? 0 : 1 })
    }
    if (isHalfEndDate) {
      parsedTo = add(parsedTo, { hours: -12 })
    }

    const isValid = isBefore(parsedFrom, parsedTo)

    if (!parsedFrom || !parsedTo || !isValid) {
      return ''
    }

    const days = differenceInBusinessDays(parsedTo, parsedFrom)
    const rawHours = (differenceInHours(parsedTo, parsedFrom) % 24) / 24
    const hours = parseFloat(rawHours.toFixed(1))

    return days + hours + ' days'
  }, [from, isHalfEndDate, isHalfStartDate, to])

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Add time off</ModalHeader>
      <ModalBody>
        <form id='time-off-form' onSubmit={handleSubmit(handleSubmitRequest)}>
          <div className='d-flex flex-column gap-16'>
            <ControlledSelect
              control={control}
              name='time_off_type_id'
              error={errors?.time_off_type_id?.message}
              label='Purpose'
              options={(timeOffTypes ?? []).map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
            />

            <div>
              <ControlledDatePicker
                control={control}
                name='from'
                error={errors?.from?.message}
                label='Start date'
                dateFormat='yyyy-MM-dd'
                excludeDates={excludeDates}
                maxDate={parseDate(watch('to'))}
                placeholderText='Select start date'
                wrapperClassName='flex-grow-1'
                className='rp-rounded-bottom-0'
                clearable
              />

              <div className='bg-slate-100 rounded-bottom border border-top-0 border-gray-b d-flex justify-content-between align-items-center p-2'>
                <ControlledCheckbox
                  control={control}
                  label='Make start day half day'
                  name='is_half_start_date'
                  id='is_half_start_date'
                />

                <Info size={20} color='var(--slate-600)' />
              </div>
            </div>

            <div>
              <ControlledDatePicker
                control={control}
                name='to'
                error={errors?.to?.message}
                label='End date'
                dateFormat='yyyy-MM-dd'
                excludeDates={excludeDates}
                minDate={parseDate(watch('from'))}
                placeholderText='Select end date'
                wrapperClassName='flex-grow-1'
                className='rp-rounded-bottom-0'
                clearable
              />

              <div className='bg-slate-100 rounded-bottom border border-top-0 border-gray-b d-flex justify-content-between align-items-center p-2'>
                <ControlledCheckbox
                  control={control}
                  label='Make end day half day'
                  name='is_half_end_date'
                  id='is_half_end_date'
                />

                <Info size={20} color='var(--slate-600)' />
              </div>
            </div>

            <div>
              {!formattedDistance ? null : (
                <Alert
                  customIcon={<CalendarCheck size={24} weight='duotone' />}
                  className='py-3 bg-primary-10 text-slate-700 border-gray-200'
                  innerTag='div'
                  innerClassName='w-100'
                >
                  <div className='d-flex gap-8 font-size-14 justify-content-between'>
                    <p className='mb-1'>Selected duration</p>
                    <span className='text-primary'>{formattedDistance}</span>
                  </div>
                  <p className='mb-0 font-size-12 text-slate-500'>
                    Excluding weekends and public holidays
                  </p>
                </Alert>
              )}
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle} color='light' outline disabled={isLoading}>
          Cancel
        </Button>
        <Button disabled={isLoading} loading={isLoading} formId='time-off-form'>
          Submit request
        </Button>
      </ModalFooter>
    </Modal>
  )
}
