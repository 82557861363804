export const INSURANCE_BANNER_COOKIE = 'insurance_banner'
export const PHYSICAL_CARD_BANNER_COOKIE = 'physical_card_banner'
export const DE_ONBOARDING_BANNER_COOKIE = 'de_onboarding_banner'

export const TFA_REMINDER = 'tfa_reminder'

export const SB_INSURANCE = 'sb_insurance'
export const SB_CARDS = 'sb_cards'
export const SB_PERKS = 'sb_perks'
export const SB_REFERS = 'sb_refers'
