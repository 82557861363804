import { eachMonthOfInterval, format } from 'date-fns'
import React, { useEffect, useMemo, useState } from 'react'
import { Card, Modal, ModalBody, ModalHeader } from 'reactstrap'
import toastr from 'toastr'

import documentReady from '../../assets/images/document_success.svg'
import emptyTable from '../../assets/images/empty_icon.svg'
import { HeaderLogo } from '../../components/Common/header-logo'
import CustomDropDown from '../../components/Dropdowns/CustomDropDown'
import CustomSelect from '../../components/Forms/CustomSelect/CustomSelect'
import Button from '../../components/ui/button'
import DataTable from '../../components/ui/data-table'
import Loader from '../../components/ui/loader'
import { PageHeading, PageTitle } from '../../components/ui/page-heading'
import { useFetch } from '../../helpers/hooks'
import {
  checkExportStatus,
  exportContractsReport,
  exportExpensesReport,
  exportInvoicesReport,
  exportTeamsReport,
  exportTransactionsReport,
  getContractsReport,
  getExpensesReport,
  getInvoicesReport,
  getTeamsReport,
  getTransactionsReport,
} from '../../services/api'

const reportTypes = [
  { label: 'Contracts report', value: 'contract' },
  { label: 'Invoices report', value: 'invoice' },
  { label: 'Expenses report', value: 'expense' },
  { label: 'Transactions Report', value: 'trx' },
  { label: 'Team report', value: 'team' },
]
const contractTypes = [
  { label: 'Fixed', value: 'fixed' },
  { label: 'PAYG', value: 'payg' },
  { label: 'Milestone', value: 'milestone' },
  { label: 'EOR', value: 'eor' },
]
const teamContractStatuses = [
  { label: 'Ongoing', value: 4 },
  { label: 'Amended', value: 8 },
  { label: 'Ended', value: 5 },
  { label: 'Terminated', value: 7 },
]
const contractStatuses = [
  { label: 'Pending invite', value: 1 },
  { label: 'Pending contractor signature', value: 2 },
  { label: 'Pending client signature', value: 3 },
  { label: 'Ongoing', value: 4 },
  { label: 'Amended', value: 8 },
  { label: 'Ended', value: 5 },
  { label: 'Cancelled', value: 6 },
  { label: 'Terminated', value: 7 },
]
const dateFormatValue = 'yyyy-MM'
const dateFormatLabel = 'MMMM yyy'

export default function CustomReports() {
  const months = useMemo(() => {
    return eachMonthOfInterval({
      start: new Date(2020, 0),
      end: new Date(),
    })
      .reverse()
      .map((d) => ({
        value: format(d, dateFormatValue),
        label: format(d, dateFormatLabel),
      }))
  }, [])
  const [openExportModal, setOpenExportModal] = useState(null)

  const [filterContractType, setFilterContractType] = useState(null)
  const [teamContractStatusQueries, setTeamContractStatusQueries] = useState([])
  const [contractStatusQueries, setContractStatusQueries] = useState([])
  const [filterReportType, setFilterReportType] = useState(reportTypes[0])
  const [filterCountry, setFilterCountry] = useState(null)
  const [filterMonth, setFilterMonth] = useState(months[0])

  const exportReportsActions = [
    {
      label: 'Excel',
      onClick: () => setOpenExportModal('excel'),
    },
    { label: 'CSV', onClick: () => setOpenExportModal('csv') },
  ]

  const contractsTableData = useFetch({
    action: getContractsReport,
    autoFetch: true,
    body: { status_ids: contractStatusQueries.map((e) => e.value) },
  })

  const invoicesTableData = useFetch({
    action: getInvoicesReport,
    autoFetch: false,
  })

  const transactionsTableData = useFetch({
    action: getTransactionsReport,
    autoFetch: false,
  })

  const expensesTableData = useFetch({
    action: getExpensesReport,
    autoFetch: false,
  })

  const teamsTableData = useFetch({
    action: getTeamsReport,
    autoFetch: false,
    body: {
      contract_status_ids: teamContractStatusQueries.map((e) => e.value),
    },
  })

  useEffect(() => {
    setFilterContractType(null)
    setTeamContractStatusQueries([])
    setContractStatusQueries([])
    setFilterCountry(null)
    setFilterMonth(months[0])
  }, [filterReportType])

  useEffect(() => {
    switch (filterReportType.value) {
      case 'contract':
        contractsTableData.startFetch({
          status_ids: contractStatusQueries.map((e) => e.value),
        })
        break
      case 'invoice':
        invoicesTableData.startFetch({ month: filterMonth?.value })
        break
      case 'expense':
        expensesTableData.startFetch({ month: filterMonth?.value })
        break
      case 'trx':
        transactionsTableData.startFetch({ month: filterMonth?.value })
        break
      case 'team':
        teamsTableData.startFetch({
          contract_status_ids: teamContractStatusQueries.map((e) => e.value),
        })
        break
    }
  }, [filterMonth, teamContractStatusQueries, contractStatusQueries])

  const filteredDataTable = useMemo(() => {
    switch (filterReportType.value) {
      case 'contract':
        return contractsTableData.data?.list || []
      case 'invoice':
        return invoicesTableData.data?.list || []
      case 'expense':
        return expensesTableData.data?.list || []
      case 'trx':
        return transactionsTableData.data?.list || []
      case 'team':
        return teamsTableData.data?.list || []
    }
  }, [
    // filterCountry,
    // filterContractType,
    filterReportType.value,
    contractsTableData.data,
    invoicesTableData.data,
    transactionsTableData.data,
    expensesTableData.data,
    teamsTableData.data,
  ])

  const getTableHeader = (tableData) => {
    return tableData
      ? Object.entries(tableData?.columns).map(([key, value]) => {
          return { Header: value, accessor: key }
        })
      : []
  }

  const tableHeader = useMemo(() => {
    switch (filterReportType.value) {
      case 'contract':
        return getTableHeader(contractsTableData.data)
      case 'invoice':
        return getTableHeader(invoicesTableData.data)
      case 'expense':
        return getTableHeader(expensesTableData.data)
      case 'trx':
        return getTableHeader(transactionsTableData.data)
      case 'team':
        return getTableHeader(teamsTableData.data)
      default:
        return []
    }
  }, [
    filterReportType,
    contractsTableData.data,
    invoicesTableData.data,
    expensesTableData.data,
    transactionsTableData.data,
    teamsTableData.data,
  ])

  // eslint-disable-next-line no-unused-vars
  const info = [
    { label: 'Generated on', value: '31/01/2023' },
    { label: 'Generated by', value: 'Steve Balmer' },
    { label: 'Company', value: 'ACME Inc.' },
    { label: 'Contract type', value: 'All (Fixed, PAYG, Milestone, EOR)' },
    { label: 'Countries', value: 'All' },
    { label: 'Period', value: '01/01/2023 to 31/01/2023' },
  ]

  return (
    <div className='page-content'>
      <PageHeading className='row'>
        <PageTitle
          subtitle='Export detailed reports'
          containerClassName='col-6'
        >
          Generate report
        </PageTitle>
      </PageHeading>

      <div className='d-flex flex-md-row flex-column gap-12 mb-4'>
        <CustomSelect
          placeholder='Report type'
          defaultValue={reportTypes[0]}
          options={reportTypes}
          wrapperStyles={{ minWidth: 192 }}
          onChange={setFilterReportType}
        />
        <CustomSelect
          wrapperClassName={
            reportTypes[4].value === filterReportType?.value ? '' : 'd-none'
          }
          placeholder='Contract status'
          isMulti
          options={teamContractStatuses}
          wrapperStyles={{ minWidth: 192 }}
          onChange={setTeamContractStatusQueries}
          value={teamContractStatusQueries}
        />
        <CustomSelect
          wrapperClassName={
            reportTypes[0].value === filterReportType?.value ? '' : 'd-none'
          }
          placeholder='Contract status'
          isMulti
          options={contractStatuses}
          wrapperStyles={{ minWidth: 192 }}
          onChange={setContractStatusQueries}
          value={contractStatusQueries}
        />
        {/* TODO: Enable when we get response from backend */}
        <CustomSelect
          wrapperClassName='d-none'
          placeholder='Contract type'
          isDisabled={true}
          options={contractTypes}
          wrapperStyles={{ minWidth: 162 }}
          onChange={setFilterContractType}
          value={filterContractType}
        />
        <CustomSelect
          wrapperClassName={
            [reportTypes[0].value, reportTypes[4].value].includes(
              filterReportType?.value,
            )
              ? 'd-none'
              : ''
          }
          placeholder='Month'
          value={filterMonth}
          options={months}
          wrapperStyles={{ minWidth: 172 }}
          onChange={setFilterMonth}
        />
        {/* TODO: Enable when we get response from backend */}
        <CustomSelect
          wrapperClassName='d-none'
          placeholder='Country'
          isDisabled={true}
          wrapperStyles={{ minWidth: 172 }}
          value={filterCountry}
          onChange={setFilterCountry}
        />
      </div>

      <Card className='rp-shadow-2 px-4 py-4'>
        <div className='border-bottom pb-3 mb-4 d-flex justify-content-between'>
          <HeaderLogo />

          <CustomDropDown
            isDisabled={!filteredDataTable?.length}
            containerClassName='btn text-primary'
            title='Export report'
            options={exportReportsActions}
          />
        </div>

        <div className='d-flex flex-column gap-8 mb-4'>
          {/* TODO: fetch info from backend when it's ready */}
          {/* {info.map(({ label, value }, index) => {
            return (
              <p key={index} className='text-zinc-700 mb-0'>
                {label}: {value}
              </p>
            )
          })} */}
        </div>

        <div className='mb-4'>
          {contractsTableData.isLoading ||
          transactionsTableData.isLoading ||
          invoicesTableData.isLoading ||
          expensesTableData.isLoading ||
          teamsTableData.isLoading ? (
            <div
              style={{ minHeight: 229.2 }}
              className='d-flex justify-content-center align-items-center'
            >
              <Loader />
            </div>
          ) : filteredDataTable?.length ? (
            <DataTable
              responsive
              columns={tableHeader}
              data={filteredDataTable}
              headClassName='py-2'
            />
          ) : (
            <div className='text-center'>
              <img
                src={emptyTable}
                alt='Empty table image'
                style={{ width: 160, height: 178 }}
              />
              <p className='text-secondary pb-3'>
                Sorry, but no data to display.
              </p>
            </div>
          )}

          {!!openExportModal && (
            <ExportModal
              exportFileType={openExportModal}
              exportReportType={filterReportType.value}
              monthFilter={filterMonth?.value}
              isOpen={!!openExportModal}
              toggle={() => setOpenExportModal(null)}
              teamContractStatusQueries={teamContractStatusQueries}
              contractStatusQueries={contractStatusQueries}
            />
          )}
        </div>

        <div className='text-center'>
          <CustomDropDown
            isDisabled={!filteredDataTable?.length}
            color='link'
            size='sm'
            containerClassName='btn text-primary'
            title='Export to view all records'
            options={exportReportsActions}
          />
        </div>
      </Card>
    </div>
  )
}

function ExportModal({
  isOpen,
  toggle,
  exportFileType,
  monthFilter,
  exportReportType,
  teamContractStatusQueries = [],
  contractStatusQueries = [],
}) {
  const ExportStatuses = {
    FAILED: 'failed',
    READY: 'ready',
  }

  const checkDownloadStatus = useFetch(
    {
      action: checkExportStatus,
      autoFetch: false,
      onComplete: (data) => {
        if (data?.status === ExportStatuses.FAILED) {
          toastr.error('Failed to export')
          toggle()
        }
      },
      onError: (err) => {
        toastr.error(
          typeof err === 'string'
            ? err
            : err?.message || 'Something went wrong',
        )
      },
    },
    [],
  )

  const getRequiredAction = () => {
    switch (exportReportType) {
      case 'trx':
        return exportTransactionsReport
      case 'expense':
        return exportExpensesReport
      case 'invoice':
        return exportInvoicesReport
      case 'team':
        return exportTeamsReport
      default:
        return exportContractsReport
    }
  }

  const sendExportRequest = useFetch(
    {
      action: getRequiredAction(),
      autoFetch: true,
      body: {
        format: exportFileType,
        month: monthFilter,
        contract_status_ids: teamContractStatusQueries.map((e) => e.value),
        status_ids: contractStatusQueries.map((e) => e.value),
      },
      onError: (err) => {
        toastr.error(
          typeof err === 'string'
            ? err
            : err?.message || 'Something went wrong',
        )
        toggle()
      },
    },
    [],
  )

  useEffect(() => {
    const timeIntervalId = setInterval(() => {
      if (sendExportRequest.data?.id) {
        checkDownloadStatus.startFetch({
          exportId: sendExportRequest.data?.id,
        })
      }
    }, 5000)

    if (checkDownloadStatus.data?.url) {
      clearInterval(timeIntervalId)
    }

    return () => clearInterval(timeIntervalId)
  }, [sendExportRequest.data, checkDownloadStatus.data])

  const downloadFile = (url, name) => {
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <Modal
      unmountOnClose
      centered={true}
      isOpen={isOpen}
      toggle={toggle}
      keyboard={true}
      backdrop={true}
    >
      <ModalHeader toggle={toggle}></ModalHeader>
      <ModalBody>
        <div className='w-100 py-4 text-center'>
          {!checkDownloadStatus.isLoading &&
          checkDownloadStatus.data?.status === ExportStatuses.READY ? (
            <>
              <img
                src={documentReady}
                alt='Document ready image'
                style={{ width: 160, height: 178 }}
              />
              <p className='font-size-24 pb-3'>Your report is ready</p>
              <Button
                onClick={() => {
                  downloadFile(
                    checkDownloadStatus.data?.url,
                    sendExportRequest.data?.path,
                  )
                  toggle()
                }}
              >
                Download report
              </Button>
            </>
          ) : (
            <div
              style={{ minHeight: '35vh' }}
              className='d-flex flex-column justify-content-around'
            >
              <Loader />
              <p className='font-size-24'>We are preparing your report</p>
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}
