import React from 'react'
import { Col, Row, Spinner } from 'reactstrap'

const PaymentReview = ({ loading, data, method }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: data?.currency?.code || 'USD',
  })
  const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return loading ? (
    <Col style={{ minHeight: '30rem' }}>
      <Row
        style={{ minHeight: '30rem' }}
        className='justify-content-center align-items-center'
      >
        <Spinner type='grow' className='mr-2' color='primary' />
      </Row>
    </Col>
  ) : (
    <>
      <Col className='border-bottom'>
        <Row className='border-bottom px-4 py-3 mb-3'>
          <h6 className='font-weight-bold pb-0 mb-0'>Recap</h6>
        </Row>
        {data?.items?.map((item, i) => {
          return (
            <React.Fragment key={i}>
              <div className={'d-flex justify-content-between '}>
                <p key={i}>{item.details}</p>
                <p>{formatter.format(item?.trans_amount)} </p>
              </div>
            </React.Fragment>
          )
        })}
        <Row className='justify-content-between font-weight-light text-muted px-4'>
          <p>Fee</p>
          <p>{formatter.format(data?.fee)}</p>
        </Row>

        <Row className='justify-content-between font-size-16 px-4 mb-3'>
          <h6>Total</h6>
          <h6>{formatter.format(data?.total)}</h6>
        </Row>
      </Col>
      <Col className='p-0'>
        <Col>
          <Row className='justify-content-between py-2 border-bottom align-items-center px-4 py-3 mb-3'>
            <h6 className='pb-0 mb-0 font-weight-bold'>Payment Method</h6>
            <p className='text-dark font-weight-bold mb-0'>{method?.label}</p>
          </Row>
        </Col>

        <Col className='text-muted'>
          {data?.account?.map((e) => {
            const baseFormatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: e?.currency || 'USD',
            })
            return (
              <>
                <Row className='justify-content-between font-weight-light align-items-center px-4'>
                  <p>Balance({e.currency})</p>
                  <p>
                    {e.currency !== 'USD' && (
                      <span>({baseFormatter.format(e?.base_deduction)})</span>
                    )}{' '}
                    {usdFormatter.format(e?.usd_deduction)}
                  </p>
                </Row>
              </>
            )
          })}
        </Col>
      </Col>
    </>
  )
}

export default PaymentReview
