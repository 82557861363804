import { AvForm } from 'availity-reactstrap-validation'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CardBody, Col, FormGroup, Row } from 'reactstrap'
import toastr from 'toastr'

import CustomPhoneInput from '../../components/Forms/phoneInput/CustomPhoneInput'
import TextInput from '../../components/Forms/TextInput'
import WiseButton from '../../components/WiseButton'
import { storage } from '../../helpers/config'
import { areNamesLatin } from '../../helpers/info-latin-regex'
import { getClientInfo, updateMemberProfile } from '../../services/api'
import { updateUserInfo } from '../../store/auth/register/actions'

const ProfileInfo = React.forwardRef(function ProfileInfo(props, ref) {
  const [, setEditable] = useState(false)
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const user = useSelector((state) => state?.Account?.user)
  const dispatch = useDispatch()

  useEffect(() => {
    try {
      getClientInfo(user?.token).then((r) => {
        if (r.data.success) {
          window.analytics.track('Viewed profile', {
            email_id: user?.email,
          })
          setData({
            ...r.data.data,
            photo: r.data?.data?.photo
              ? `${storage}${r.data?.data?.photo}`
              : r.data?.data?.photo,
          })
        }
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }, [user?.email, user?.token])

  return (
    <div>
      <CardBody className='p-0'>
        <AvForm
          ref={ref}
          onValidSubmit={(e, v) => {
            const {
              first_name: firstName,
              last_name: lastName,
              middle_name: middleName,
            } = v

            const names = [firstName, lastName, middleName]
            const namesAreLatin = areNamesLatin(names)

            if (!namesAreLatin) {
              toastr.error('Your name should contain only latin letters')
            } else if (!data.phone || !v.first_name || !v.last_name) {
              toastr.error('Please enter all required fields')
            } else {
              setLoading(true)
              const dataInfo = {
                ...v,
                phone: data?.phone,
              }
              if (data?.photo && !data?.photo?.startsWith('http')) {
                dataInfo.photo = data?.photo
              }
              updateMemberProfile(user?.token, dataInfo)
                .then((r) => {
                  setLoading(false)
                  if (r.data.success) {
                    window.analytics.track('Updated profile', {
                      email_id: user?.email,
                    })
                    setData({
                      ...r.data.data,
                      photo: r.data?.data?.photo
                        ? `${storage}${r.data?.data?.photo}`
                        : r.data?.data?.photo,
                    })
                    dispatch(updateUserInfo(r.data?.data || {}))
                    toastr.success('General information submitted.')
                    setEditable(false)
                  }
                })
                .catch((e) => {
                  setLoading(false)
                })
            }
          }}
        >
          <Row className='p-0 m-0'>
            <Col xs={12} md={6} className='p-md-4 px-3 pt-3'>
              <FormGroup>
                <TextInput
                  type='text'
                  name='first_name'
                  value={data?.first_name}
                  label='First Name'
                  required
                  id='billing-first-name'
                  translate='no'
                />
              </FormGroup>
              <FormGroup>
                <TextInput
                  value={data?.last_name}
                  name='last_name'
                  type='text'
                  label='Last Name'
                  required
                  id='billing-last-name'
                  translate='no'
                />
              </FormGroup>
            </Col>
            <Col
              xs={12}
              md={6}
              className={'px-3 pt-0  pl-md-0 pr-md-4  pt-md-4'}
            >
              <FormGroup>
                <TextInput
                  type='text'
                  name='middle_name'
                  value={data?.middle_name}
                  label='Middle Name'
                  id='billing-middle-name'
                  translate='no'
                />
              </FormGroup>
              <FormGroup>
                <CustomPhoneInput
                  label='Phone Number'
                  required
                  // containerClass="form-control"
                  name='phone'
                  value={data?.phone || ''}
                  country='ae'
                  enableAreaCodes
                  onChange={(phone) => setData({ ...data, phone })}
                />
              </FormGroup>
            </Col>
          </Row>
          <div className='border-top divider w-100 ' />
          <Row className=' p-md-4 p-3  m-0'>
            <WiseButton
              bold
              minWidth='100%'
              text='save'
              onClick={() => ref.current.submit()}
              loading={loading}
            />
          </Row>
        </AvForm>
      </CardBody>
    </div>
  )
})

export default ProfileInfo
