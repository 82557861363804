import React from 'react'

import { FlagIcon } from '../components/ui/flag-icon'

export function mapCurrencyToOption(currency = {}, key = 'code') {
  const { code, name, symbol } = currency

  const label = [code, name ? `- ${name}` : null, symbol ? `(${symbol})` : null]
    .filter(Boolean)
    .join(' ')

  return {
    ...currency,
    label,
    value: currency[key],
    icon: !code ? null : (
      <FlagIcon code={code} className='mr-2 flex-shrink-0' />
    ),
  }
}

export function getOptionFromList(list, optionValue, valueKey = 'value') {
  if (!Array.isArray(list)) {
    return {}
  }

  return list.find((item) => optionValue === item[valueKey])
}

export function mapCountryToOption(country = {}, key = 'id') {
  return {
    ...country,
    label: country.name,
    value: country[key],
    flag: !country?.svg ? null : `/${country.svg}`,
  }
}
