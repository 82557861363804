import React, { useEffect, useState } from 'react'

import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Row,
  Spinner,
} from 'reactstrap'

// Redux
import { withRouter } from 'react-router-dom'

// availity-reactstrap-validation
import { AvForm } from 'availity-reactstrap-validation'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

// import images
import {
  checkRegisterToken,
  checkTokenUpdatePass,
  confirmInvitation,
  signupEmployee,
} from '../../services/api'
import { useFetch } from '../../helpers/hooks'
import styled from 'styled-components'
import WiseButton from '../../components/WiseButton'
import TextInput from '../../components/Forms/TextInput'
import { PasswordChecklistComp } from '../../components/CommonForBoth/ChangePassword'
import {
  OTPSuccessful,
  registerUserSuccessful,
  toggleLogin,
} from '../../store/auth/register/actions'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import logoMain from './../../assets/images/identity/logo_main.png'

const Invite = (props) => {
  // handleValidSubmit

  const dispatch = useDispatch()
  const history = useHistory()
  const [isValid, setIsValid] = useState('')
  const [password, setPassword] = useState('')
  const token = new URLSearchParams(props.location.search).get('token')
  const email = new URLSearchParams(props.location.search).get('email')
  const typeP = new URLSearchParams(props.location.search).get('type')

  useEffect(() => {
    if (token && typeP) {
      checkRegisterToken(token)
        .then((r) => {
          if (r.data?.success) {
            if (!r?.data?.data.is_valid) {
              history.push('/activity')
            }
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }, [])

  function handleValidSubmit(event, values) {
    if (values['confirm-password'] === values.password) {
      if (['employee', 'contractor'].includes(typeP)) {
        dispatch(OTPSuccessful({ token }))
        signUp.startFetch(
          { email, password: values.password },
          true,
          null,
          token,
        )
      } else {
        const body = {
          token: new URLSearchParams(props.location.search).get('token'),
          ...values,
        }
        delete body['confirm-password']
        confirmInvitation(body).then((r) => {
          if (r.data.success) {
            props.history.push('/login')
          } else {
            toastr.error(r.data.message, 'Error')
          }
        })
      }
    } else {
      toastr.error('invalid password', 'Error')
    }
  }

  const check = useFetch({
    action: checkTokenUpdatePass,
    autoFetch: !['employee', 'contractor'].includes(typeP),
    body: { token: new URLSearchParams(props.location.search).get('token') },
    onError: () => {
      props.history.push('/login')
    },
  })

  const signUp = useFetch({
    action: signupEmployee,
    autoFetch: false,
    onComplete: (data) => {
      dispatch(registerUserSuccessful(data))
      dispatch(toggleLogin(true))
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  return (
    <div>
      <StyledDiv className='d-flex justify-content-center align-items-center'>
        <img src={logoMain} height={24} alt={''} />
      </StyledDiv>
      {check.isLoading ? (
        <Container style={{ minHeight: '100vh' }}>
          <Col style={{ minHeight: '100vh' }} className={'p-0 m-0'}>
            <Row
              style={{ minHeight: '100vh' }}
              className='justify-content-center align-items-center'
            >
              <Spinner type='grow' className='mr-2' color='primary' />
            </Row>
          </Col>
        </Container>
      ) : (
        <div
          style={{ minHeight: '100vh' }}
          className='account-pages blur-image'
        >
          <Row
            style={{ minHeight: '100vh' }}
            className='align-items-center h-100 p-0 m-0'
          >
            <Col className='align-items-center p-0 m-0'>
              <Container>
                <Row className='justify-content-center p-0 m-0'>
                  <Col md={8} lg={6} xl={5} className={'p-0 m-0'}>
                    <Card
                      className='overflow-hidden p-0 m-0 '
                      style={{
                        backgroundColor: '#FFFFFF',
                        boxShadow: '0px 1px 0px #DFE1E6',
                        borderRadius: 4,
                      }}
                    >
                      <CardBody className='p-3 p-md-4 m-0'>
                        <div className='p-0'>
                          <AvForm
                            autocomplete='off'
                            className='form-horizontal'
                            onValidSubmit={(e, v) => {
                              if (isValid) {
                                handleValidSubmit(e, v)
                              } else {
                                toastr.error(
                                  'Weak password easy to guess, password should respect checklist condition',
                                )
                              }
                            }}
                          >
                            {props.error && props.error ? (
                              <Alert color='danger'>{props.error}</Alert>
                            ) : null}

                            <div className='form-group pb-3  mb-0'>
                              {!typeP && (
                                <>
                                  <FormGroup
                                    className='mb-md-4 mb-0 p-0 m-0 d-block'
                                    row
                                  >
                                    <TextInput
                                      label={'First name'}
                                      name='first_name'
                                      type='text'
                                      className='form-control'
                                      id='first-name'
                                      required
                                    />
                                  </FormGroup>
                                  <FormGroup
                                    className='mb-md-4 mb-0 p-0 m-0 d-block'
                                    row
                                  >
                                    <TextInput
                                      label={'Middle name'}
                                      name='middle_name'
                                      type='text'
                                      className='form-control'
                                      id='middle-name'
                                    />
                                  </FormGroup>
                                  <FormGroup
                                    className='mb-md-4 mb-0 p-0 m-0 d-block'
                                    row
                                  >
                                    <TextInput
                                      required
                                      name='last_name'
                                      type='text'
                                      className='form-control'
                                      label={'Last name'}
                                      id='last-name'
                                    />
                                  </FormGroup>
                                </>
                              )}
                              <TextInput
                                name='confirm-password'
                                label='Password'
                                type='password'
                                required
                                placeholder='Enter Password'
                                id={'passWord'}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                              <PasswordChecklistComp
                                password={password}
                                TooltipId={'passWord'}
                                onChange={(v) => setIsValid(v)}
                              />
                            </div>

                            <div className='form-group pb-3  mb-0'>
                              <TextInput
                                name='password'
                                label='Confirm Password'
                                type='password'
                                required
                                placeholder='Confirm Password'
                              />
                            </div>
                            <div className=' d-block'>
                              <WiseButton
                                height={'48px'}
                                minHeight={'48px'}
                                width={'100%'}
                                minWidth={'100%'}
                                text={'Create Account'}
                                className='btn btn-primary btn-block waves-effect waves-light'
                                type='submit'
                              />
                            </div>
                          </AvForm>
                        </div>
                      </CardBody>
                    </Card>
                    <div className='mt-5 text-center'>
                      <p className={'text-white'}>
                        © {new Date().getFullYear()} RemotePass
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </div>
      )}
    </div>
  )
}

const StyledDiv = styled.div`
  height: 80px;
  background-color: #ffffff;
  box-shadow: 0px 0px 8px #eaeaea, inset 0px -1px 0px #eaeaea;
  @media (max-width: 768px) {
    height: 56px;
  }
`

export default withRouter(Invite)
