import React from 'react'

const Avatar = ({ photo, name, flag, isLarge, label }) => {
  return (
    <div className={`${isLarge ? 'avatar-md' : 'avatar-xs'} position-relative`}>
      {photo ? (
        <img className='rounded-circle avatar-title' src={photo} alt='' />
      ) : (
        <span
          className={`${isLarge ? 'avatar-md font-size-24' : 'avatar-xs'} ${
            label === 'Client' ? 'avatar-title-green' : 'avatar-title'
          } rounded-circle`}
        >
          {name?.charAt(0)}
        </span>
      )}
      {!!flag && (
        <img
          src={`/${flag}`}
          style={
            isLarge
              ? {
                  width: '1.5rem',
                  height: '1.5rem',
                  borderRadius: '.75rem',
                  position: 'absolute',
                  bottom: -3,
                  right: -6,
                  objectFit: 'cover',
                }
              : {
                  width: '1rem',
                  height: '1rem',
                  borderRadius: '0.5rem',
                  position: 'absolute',
                  bottom: 0,
                  right: -4,
                  objectFit: 'cover',
                }
          }
          alt=''
        />
      )}
    </div>
  )
}
export default Avatar
