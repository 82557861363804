import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Container, Row, Spinner } from 'reactstrap'
import styled from 'styled-components'

import NoContent from '../../components/NoContent'
import { StyledH3, StyledH4, StyledH5, StyledH6 } from '../../components/Typo'
import { useFetch } from '../../helpers/hooks'
import { getFullTimeContractDetails } from '../../services/api'
import { updateContract } from '../../store/contract/actions'
import { PayslipList } from '../Fulltime/Payslips'
import emptyDocs from './../../assets/images/empty_docs.svg'

const EmployeeOverview = () => {
  const user = useSelector((state) => state.Account?.user)

  const dispatch = useDispatch()
  const employeeActivity = useFetch({
    action: getFullTimeContractDetails,
    autoFetch: true,
    onComplete: (data) => {
      dispatch(updateContract(data))
    },
  })

  const StyledTitleCont = styled.div`
    padding: 32px 24px;
    @media (max-width: 425px) {
      padding: 16px;
    }
  `

  const StyledCol = styled(Col)`
    background: ${(props) => (props.noStyle ? '#fff' : 'transparent')};
    box-shadow: 0 1px 0 #dfe1e6;
    border-radius: 4px;
  `
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: employeeActivity?.data?.salary_currency?.code || 'USD',
  })

  return (
    <div className='page-content'>
      {employeeActivity.isLoading ? (
        <Container className='p-0 m-0' style={{ minHeight: '30rem' }}>
          <Col className='p-0 m-0' style={{ minHeight: '30rem' }}>
            <Row
              style={{ minHeight: '30rem' }}
              className='p-0 m-0 justify-content-center align-items-center'
            >
              <Spinner type='grow' className='mr-2' color='primary' />
            </Row>
          </Col>
        </Container>
      ) : (
        <Container fluid className='p-0 m-0'>
          <h1
            style={{ fontFamily: 'gilroyB', marginBottom: 14 }}
            className='   text-dark font-size-32 '
          >
            Hi, <span translate='no'>{user?.first_name}</span>
          </h1>
          <h2
            style={{ marginBottom: 32, fontWeight: '400' }}
            className='text-secondary font-size-16'
          >
            Here is a recap of your employment{' '}
          </h2>
          <StyledCol className='p-0 m-0 mb-4 p-3 p-md-4' noStyle>
            <StyledH3
              min={'22px'}
              max={'32px'}
              style={{ fontFamily: 'gilroyB' }}
              className={'text-dark mb-3 mb-md-4'}
            >
              Employment details
            </StyledH3>
            <Row className={'mb-3'}>
              <Col className={'col-md-4 col-12'}>
                <StyledH5
                  className={'font-size-14 text-secondary font-weight-light'}
                >
                  Job title
                </StyledH5>
                <StyledH6 className={'font-size-14'}>
                  {employeeActivity?.data?.name}
                </StyledH6>
              </Col>
              <Col className={'col-md-4 col-12'}>
                <StyledH5
                  className={'font-size-14 text-secondary font-weight-light'}
                >
                  Company
                </StyledH5>
                <StyledH6 className={'font-size-14'}>
                  {employeeActivity?.data?.company_name}
                </StyledH6>
              </Col>
              <Col className={'col-md-4 col-12'}>
                <StyledH5
                  className={'font-size-14 text-secondary font-weight-light'}
                >
                  Status
                </StyledH5>
                <StyledH6 className={'font-size-14'}>
                  Full-time employee
                </StyledH6>
              </Col>
            </Row>
            <Row>
              <Col className={'col-md-4 col-12'}>
                <StyledH5
                  className={'font-size-14 text-secondary font-weight-light'}
                >
                  Start date
                </StyledH5>
                <StyledH6 className={'font-size-14'}>
                  {employeeActivity?.data?.start_date}
                </StyledH6>
              </Col>
              <Col className={'col-md-4 col-12'}>
                <StyledH5
                  className={'font-size-14 text-secondary font-weight-light'}
                >
                  Gross Salary
                </StyledH5>
                <StyledH6 className={'font-size-14'}>
                  {formatter.format(employeeActivity?.data?.amount)}
                </StyledH6>
              </Col>
              <Col className={'col-md-4 col-12'} />
            </Row>
          </StyledCol>
          <StyledCol className='m-0 p-md-0 pb-3' noStyle>
            <StyledTitleCont
              style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
              className='d-flex p-4 bg-white justify-content-between align-items-center pl-0'
            >
              <StyledH4
                min={'16px'}
                max={'24px'}
                className='mb-0   text-dark'
                style={{ fontFamily: 'GilroyB' }}
              >
                Recent Payslips
              </StyledH4>
              {/* <StyledLink max={'14px'} min={'12px'} to={'/documents'} */}
              {/*  className="text-primary ml-2  d-flex align-items-center"> */}
              {/*      View all  <i */}
              {/*    className="bx  text-primary    bx-chevron-right  font-size-6 align-middle ml-9"/> */}
              {/* </StyledLink> */}
            </StyledTitleCont>

            {!employeeActivity.data?.payslips?.length ? (
              <NoContent
                headline={'No payslips added'}
                subtitle={
                  'Your payslips will be shown here when added by the employer'
                }
                image={emptyDocs}
              />
            ) : (
              <PayslipList data={employeeActivity.data?.payslips} isShorted />
            )}
          </StyledCol>
          <div className='bg-white'></div>
        </Container>
      )}
    </div>
  )
}

export default EmployeeOverview
