import moment from 'moment'
import React, { useState } from 'react'
// Import images
// i18n
import { withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { withRouter } from 'react-router-dom'
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Modal,
  Row,
  UncontrolledTooltip,
} from 'reactstrap'
import Col from 'reactstrap/lib/Col'
import SimpleBar from 'simplebar-react'
import styled, { keyframes } from 'styled-components'

import bell from '../../../assets/images/bell.svg'
import { useFetch, useResize } from '../../../helpers/hooks'
import {
  getNotificationList,
  markAllNotificationAsRead,
} from '../../../services/api'
import { updateUserNotifications } from '../../../store/profile/actions'
import { StyledH5 } from '../../Typo'

const animation = keyframes`
  0% { transform: rotate(0); }
  20% { transform: rotate(20deg); }
  40% { transform: rotate(-20deg); }
  60% { transform: rotate(20deg); }
  80% { transform: rotate(-20deg); }
  100% { transform: rotate(0); }
`

// Keyframes must be consumed as an interpolated string
const AnimatedImg = styled.img`
  animation: 1s ${animation} ease-in-out infinite;
`

const NotificationDropdown = (props) => {
  const dispatch = useDispatch()

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const notificationData = useSelector(
    (state) => state?.userProfile?.notifications,
  )
  const user = useSelector((state) => state?.Account?.user)
  const notifications = useFetch({
    autoFetch: true,
    initResult: null,
    action: getNotificationList,
    onComplete: (data) => {
      dispatch(updateUserNotifications(data))
    },
  })

  const markAllAsRead = useFetch({
    action: markAllNotificationAsRead,
    onComplete: notifications.startFetch,
  })

  let unreadCount = 0
  try {
    unreadCount = Array.isArray(notificationData)
      ? notificationData?.filter((n) => !n.read_at)?.length
      : 0
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
  }

  const isMobile = useResize()

  const notificationButtonContent = (
    <>
      <div
        id='notifications-toggle'
        className='p-4 position-relative d-flex h-100'
      >
        {unreadCount > 0 ? (
          <AnimatedImg src={bell} alt='notification button' width={20} />
        ) : (
          <img src={bell} alt='notification button' width={20} />
        )}

        {unreadCount > 0 && (
          <span
            style={{ position: 'absolute', top: '20%', right: '20%' }}
            className='badge badge-danger badge-pill border'
          >
            {unreadCount}
          </span>
        )}
      </div>
      <UncontrolledTooltip target='notifications-toggle'>
        Notifications
      </UncontrolledTooltip>
    </>
  )

  return (
    <>
      {!isMobile ? (
        <Dropdown
          isOpen={menu}
          toggle={() => {
            window.analytics.track('Clicked notifications', {
              email_id: user?.email,
            })
            setMenu(!menu)
            markAllAsRead.startFetch(null)
          }}
          direction={'down'}
          setActiveFromChild
          className='d-inline-block'
        >
          <DropdownToggle
            className='bg-transparent border-0 p-0 hover:bg-light h-100'
            tag='button'
            type='button'
            id='page-header-notifications-dropdown'
            aria-label='Notifications'
          >
            {notificationButtonContent}
          </DropdownToggle>

          <DropdownMenu className='dropdown-menu dropdown-menu-lg notification-dropdown my-0'>
            <SimpleBar className='p-0 m-0' style={{ maxHeight: '70vh' }}>
              {notificationData?.length === 0 ||
              !Array.isArray(notificationData) ? (
                <div className='d-flex flex-column justify-content-center align-items-center py-5'>
                  <img
                    style={{ padding: '0 24px  0 24px' }}
                    height={'100%'}
                    width={'50%'}
                    src={bell}
                    alt=''
                  />
                  <StyledH5 className={'mt-4'}>No notifications</StyledH5>
                </div>
              ) : (
                notificationData?.map((n, i) => (
                  <NotificationItem
                    withBorder={i !== notificationData.length - 1}
                    key={`notification-${i}-${n?.created_at}`}
                    notification={n}
                    toggle={() => setMenu(false)}
                  />
                ))
              )}
            </SimpleBar>
          </DropdownMenu>
        </Dropdown>
      ) : (
        <>
          <button
            onClick={() => {
              markAllAsRead.startFetch(null)
              setMenu(true)
            }}
            className='bg-transparent border-0 p-0 hover:bg-light'
          >
            {notificationButtonContent}
          </button>
          <Modal
            isOpen={menu}
            toggle={() => {
              setMenu(false)
            }}
          >
            <div className='modal-content'>
              <div className='modal-header fixed-top bg-white'>
                <h6
                  style={{ fontFamily: 'gilroyB' }}
                  className='m-0 font-size-16 '
                >
                  Notifications
                </h6>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                  onClick={() => setMenu(false)}
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body p-0' style={{ marginTop: '3rem' }}>
                {notificationData?.length === 0 ||
                !Array.isArray(notificationData) ? (
                  <div
                    className={
                      'd-flex flex-column justify-content-center align-items-center'
                    }
                  >
                    <img
                      style={{ padding: '100px 24px  0 24px' }}
                      height={'100%'}
                      width={'50%'}
                      src={bell}
                      alt=''
                    />
                    <StyledH5 className={'mt-4'}>
                      No notifications right Now!
                    </StyledH5>
                  </div>
                ) : (
                  <SimpleBar>
                    {notificationData?.map((n, i) => (
                      <NotificationItem
                        withBorder={i !== notificationData.length - 1}
                        key={`notification-${i}-${n?.created_at}`}
                        notification={n}
                        toggle={() => {
                          setMenu(false)
                        }}
                      />
                    ))}
                  </SimpleBar>
                )}
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  )
}

const getLink = (notification) => {
  switch (notification?.data?.object_type) {
    case 'contract':
      return `/contract/detail?id=${notification?.data?.object_id}`
    case 'company-profile':
      return '/settings#profile'
    case 'contractor-profile':
      return '/settings#profile'
    case 'kyc':
      return '/settings#kyc'
  }
}

const NotificationItem = ({ notification, toggle, withBorder }) => {
  const history = useHistory()
  const handleNotificationClick = (notification) => {
    const link = getLink(notification)
    if (link) {
      history.push(link)
    }
    toggle()
  }
  return (
    <a
      onClick={() => handleNotificationClick(notification)}
      className={`text-reset notification-item ${
        !notification.read_at ? 'bg-primary' : ''
      }`}
    >
      <Col>
        <Row
          className={`media ${
            withBorder ? 'border-bottom' : ''
          } p-3 my-0 border-light ${
            !notification.read_at ? 'bg-soft-primary' : ''
          }`}
        >
          <Col className='p-0 m-0'>
            <Row
              style={{ marginBottom: 12 }}
              className='justify-content-between p-0  mx-0 align-items-start'
            >
              <Col md={8} className='p-0 m-0'>
                <h6 className='mt-0 mb-0  font-size-14 text-muted font-weight-bold'>
                  {notification?.data?.title}
                </h6>
              </Col>
              <Col md={4}>
                <Row className='justify-content-end'>
                  <h6 className='mb-0 text-secondary text-muted font-weight-normal font-size-12'>
                    {moment(notification.created_at).fromNow()}
                  </h6>
                </Row>
              </Col>
            </Row>
            <p className='mb-0 font-size-12 text-muted font-weight-light'>
              {notification?.data?.content}
            </p>
          </Col>
        </Row>
      </Col>
    </a>
  )
}

export default withRouter(withTranslation()(NotificationDropdown))
