import React from 'react'
import { Col, Row, Modal } from 'reactstrap'
import addCardImage from '../../../assets/images/add-card.png'

const RequiredBankAccountModal = ({ isOpen, toggle, proceed }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <div className='modal-content'>
        <div className='modal-body'>
          <Row className='justify-content-center text-center'>
            <Col md={10}>
              <h2>Add new account</h2>
              <h6 className='text-muted'>
                Add your bank account details to receive expense reimbursements
              </h6>
              <Row className='justify-content-center'>
                <Col sm='6' xs='8'>
                  <div className={'mb-4'}>
                    <img src={addCardImage} alt='' className='img-fluid' />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className='modal-footer'>
          <button type='button' className='btn btn-link' onClick={toggle}>
            Cancel
          </button>
          <button
            type='button'
            className='btn btn-primary'
            data-dismiss='modal'
            onClick={proceed}
          >
            Add Bank Account
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default RequiredBankAccountModal
