import React, { useMemo } from 'react'
import { Card, Container, Row } from 'reactstrap'

import BadgeX from '../../../../components/Table/BadgeX'
import DataTable from '../../../../components/ui/data-table'
import Loader from '../../../../components/ui/loader'
import NoContent from '../../../../components/ui/no-content'
import Pagination from '../../../../components/ui/pagination'
import { useFetch } from '../../../../helpers/hooks'
import { adminCardsList, getCardEnumerations } from '../../../../services/api'
import capitalizeFirstLetter from '../../../../utils/capitalize-first-letter'
import { usdFormatter } from '../../../../utils/formatters/currency'
import unSlugify from '../../../../utils/un-slugify'
import { dateCell } from './cell'
import { FilterField } from './filter-field'
import useFilter from './use-filters'

function getBodyFromFilters(filters) {
  return {
    ...filters,
    status: filters?.card_status?.value,
    page: filters.page - 1,
  }
}

export function getCardStatus(status) {
  return {
    label: capitalizeFirstLetter(unSlugify(status)),
    value: status,
  }
}

const cardStatusColor = {
  active: 'success',
  locked: 'warning',
  blocked: 'danger',
}

export default function AdminCardsAll() {
  const [filters, handleFiltersChange] = useFilter(
    { page: 1 },
    { statusKey: 'card_status' },
  )

  const { isLoading, data } = useFetch(
    {
      action: adminCardsList,
      withAdminAccess: true,
      autoFetch: true,
      body: getBodyFromFilters(filters),
    },
    [filters],
  )

  const { data: enumerations, isLoading: loadingEnumerations } = useFetch({
    action: getCardEnumerations,
    autoFetch: true,
    withAdminAccess: true,
  })

  const cardStatusOptions =
    loadingEnumerations || !enumerations
      ? []
      : enumerations.filterable_card_statuses.map(getCardStatus)

  const items = data?.items ?? []

  const columns = useMemo(
    () => [
      { Header: 'Requested on', accessor: 'date.requested', Cell: dateCell },
      { Header: 'First name', accessor: 'user.name.first' },
      { Header: 'Last name', accessor: 'user.name.last' },
      { Header: 'Email', accessor: 'user.email' },
      {
        Header: 'Phone',
        accessor: 'user.mobile',
        Cell: ({ cellData: p }) => '+' + p?.country_code + ' ' + p?.number,
      },
      {
        Header: 'Country of Residence',
        accessor: 'user.address.residential.country',
      },
      { Header: 'Card type', accessor: 'type', className: 'rp-capitalize' },
      {
        Header: 'Status',
        accessor: 'status.text',
        Cell: ({ cellData }) => (
          <BadgeX className='rp-capitalize' status={cardStatusColor[cellData]}>
            {cellData}
          </BadgeX>
        ),
      },
      {
        Header: 'Balance',
        accessor: 'balance.amount',
        Cell: ({ cellData }) => usdFormatter.format(cellData),
      },
      { Header: 'KYC Level', accessor: 'kyc_level' },
      {
        Header: 'Card number',
        accessor: 'number',
        Cell: ({ cellData }) =>
          Array.from(cellData.matchAll(/[0-9]{4}/g))
            .map((d) => d[0])
            .join(' '),
        className: 'tabular-nums',
      },
      { Header: 'Expiry date', accessor: 'date.expiry' },
      { Header: 'Issue date', accessor: 'date.issued' },
      { Header: 'Preferred name', accessor: 'user.name.preferred' },
    ],
    [],
  )

  return (
    <div className='page-content'>
      <div style={{ marginBottom: '2rem' }}>
        <h2 className='h1 mb-0 rp-font-gilroyB'>All Cards</h2>
      </div>
      <Card>
        <div className='py-3'>
          <Filters
            cardStatusOptions={cardStatusOptions}
            filters={filters}
            onChange={handleFiltersChange}
          />
        </div>

        {isLoading ? (
          <Loader minHeight='max(50vh, 550px)' />
        ) : items?.length <= 0 ? (
          <NoContent
            className='text-muted font-size-16'
            minHeight='max(50vh, 550px)'
          >
            No items found
          </NoContent>
        ) : (
          <>
            <DataTable responsive data={items ?? []} columns={columns} />

            <div className='d-flex justify-content-end px-3'>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={data?.limit ?? 10}
                totalItemsCount={data?.total_items_count ?? 0}
                onChange={(v) => handleFiltersChange('page', v)}
              />
            </div>
          </>
        )}
      </Card>
    </div>
  )
}

function Filters({ filters, onChange, cardStatusOptions }) {
  const filtersFields = [
    {
      name: 'card_status',
      label: 'Card Status',
      options: cardStatusOptions,
      type: 'select',
      isClearable: true,
    },
    { name: 'email', label: 'Email', type: 'email' },
    { name: 'name', label: 'Name', type: 'text' },
  ]

  return (
    <Container fluid className='px-3'>
      <Row className='mx-n2'>
        {filtersFields.map((field) => {
          return (
            <FilterField
              {...field}
              key={field.name}
              value={filters[field.name]}
              onChange={onChange}
            />
          )
        })}
      </Row>
    </Container>
  )
}
