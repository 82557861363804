import { format } from 'date-fns'
import React from 'react'
import { Controller } from 'react-hook-form'

import CustomDatePicker from '../Forms/CustomDatePicker/CustomDatePicker'

export function formatDate(date) {
  return format(date, 'yyyy-MM-dd') // "2022-09-10"
}

const ControlledDatePicker = ({
  name,
  control,
  error,
  label,
  clearable,
  ...prop
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onBlur, onChange } }) => (
        <CustomDatePicker
          label={label}
          value={value}
          handleOnChange={(v) => {
            onChange(formatDate(v))
          }}
          hasError={!!error}
          error={error}
          handleClear={() => {
            onChange(null)
          }}
          clearable={clearable}
          onMenuClose={onBlur}
          {...prop}
        />
      )}
    />
  )
}

export default ControlledDatePicker
