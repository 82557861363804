import React, { useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
} from 'reactstrap'
import { useFetch } from '../../../../../helpers/hooks'
import { getContractsListAdmin } from '../../../../../services/api'
import { StyledH5 } from '../../../../../components/Typo'
import Paginations from 'react-js-pagination'
import CustomSelect from '../../../../../components/Forms/CustomSelect/CustomSelect'
import { useSelector } from 'react-redux'
import CtList from '../../../../Contract/ContractList/ContractList'
import classnames from 'classnames'
import styled from 'styled-components'
import axios from 'axios'
import { BurgerDetails } from '../../Users/users'

const ContractsListAdmin = () => {
  const [activePage, setActivePage] = useState(1)
  const defaultState = { label: 'Select Status', value: -1 }
  const defaultCompany = { label: 'Select Company', value: null }
  const [state, setState] = useState(defaultState)
  const [company, setCompany] = useState({
    label: 'Select Company',
    value: null,
  })
  const [query, setQuery] = useState(null)
  const [activeTab, setActiveTab] = useState('1')
  const [tabOptions] = useState([
    {
      label: 'Active Contracts',
      value: '1',
    },
    {
      label: 'Archived Contracts',
      value: '2',
    },
  ])
  const contractsList = useFetch({
    action: getContractsListAdmin,
    withAdminAccess: true,
  })
  const status = useSelector(
    (state) => state?.Layout?.staticData?.contract_statuses,
  )
  const [selectedUserId, setselectedUserId] = useState(null)
  const [show, setShow] = useState(false)

  const handlePageChange = (page) => {
    setActivePage(page)
  }

  useEffect(() => {
    let cancel
    // eslint-disable-next-line no-return-assign
    applyFilter(new axios.CancelToken((c) => (cancel = c)))
    return () => {
      cancel()
    }
  }, [state, company, activePage, query])

  useEffect(() => {
    setActivePage(1)
    contractsList.startFetch({ archived: activeTab === '1' ? 0 : 1 })
  }, [activeTab])

  const applyFilter = (cancelToken) => {
    const body = { archived: activeTab === '1' ? 0 : 1 }
    if (company?.value) {
      body.company_id = company?.value
    }
    if (activePage !== 1) {
      body.page = activePage
    }
    if (state?.value !== -1) {
      body.status_id = state?.value
    }
    if (query) {
      body.search = query
    }
    contractsList.startFetch(
      body,
      !query && !company?.value && state?.value === -1,
      cancelToken,
    )
  }
  const updateList = () => {
    contractsList.startFetch(
      {
        page: activePage,
        archived: activeTab === '1' ? 0 : 1,
      },
      false,
    )
  }

  return (
    <div className='page-content'>
      <BurgerDetails
        id={selectedUserId}
        hide={() => setShow(false)}
        show={show}
      />
      <Container fluid className={'p-0 m-0'}>
        <Row className='mb-3 mb-md-4 p-0 m-0'>
          <Col xs={12} md={6} className={'p-0 m-0'}>
            <StyledH5 min={'22px'} max={'32px'}>
              Contracts
            </StyledH5>
          </Col>
        </Row>

        <Card className={'p-0 m-0'}>
          {contractsList.isLoading ? (
            <div
              style={{ minHeight: '30rem' }}
              className='d-flex justify-content-center align-items-center'
            >
              <Spinner type='grow' color='primary' />
            </div>
          ) : (
            <CardBody className={'p-0 m-0'}>
              <Row
                style={{ borderTopLeftRadius: 4, borderTopRightRadius: 4 }}
                className=' d-block  d-md-none   p-3 p-0 m-0   justify-content-between align-items-center  border-bottom     bg-white'
              >
                <CustomSelect
                  name={'selectInput'}
                  value={tabOptions[activeTab - 1]}
                  defaultValue={{
                    label: 'Active Contracts',
                    value: '1',
                  }}
                  onChange={(v) => setActiveTab(v.value)}
                  options={tabOptions}
                />
              </Row>

              <div className='d-none d-md-flex justify-content-between align-items-center m-0 p-3'>
                <StyledNav tabs>
                  <NavItem className='bg-white'>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: activeTab === '1',
                        'bg-white': true,
                      })}
                      onClick={() => {
                        setActiveTab('1')
                      }}
                    >
                      <p className='font-size-14 mb-0'>Active Contracts</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: activeTab === '2',
                        'bg-white': true,
                      })}
                      onClick={() => {
                        setActiveTab('2')
                      }}
                    >
                      <p className='font-size-14 mb-0'>Archived Contracts</p>
                    </NavLink>
                  </NavItem>
                </StyledNav>
              </div>

              <Container fluid>
                <Row className='p-3' style={{ gap: '0.75rem' }}>
                  <Col md={4} xs={12} className='p-0'>
                    <CustomSelect
                      options={
                        Array.isArray(status)
                          ? [
                              defaultState,
                              ...(status?.map((e) => ({
                                label: e.name,
                                value: e.id,
                              })) ?? []),
                            ]
                          : [defaultState]
                      }
                      value={state}
                      onChange={setState}
                    />
                  </Col>

                  <Col md={4} xs={12} className='p-0'>
                    <CustomSelect
                      options={
                        Array.isArray(contractsList?.data?.companies)
                          ? [
                              defaultCompany,
                              ...(contractsList?.data?.companies?.map((e) => ({
                                label: e.name,
                                value: e.id,
                              })) ?? []),
                            ]
                          : [defaultCompany]
                      }
                      value={company}
                      onChange={setCompany}
                    />
                  </Col>

                  <Col md={3} xs={12} className='p-0'>
                    <button
                      className='btn btn-primary'
                      onClick={() => {
                        setActivePage(1)
                        setQuery(null)
                        setState(defaultState)
                        setCompany(defaultCompany)
                      }}
                    >
                      Clear Filter
                    </button>
                  </Col>
                </Row>
              </Container>

              <CtList
                handleUserClick={(user) => {
                  setselectedUserId(user?.contractor?.id)
                  setShow(true)
                }}
                isAdmin
                Orders={contractsList?.data?.contracts}
                withArchive
                onUpdate={updateList}
                archiveValue={activeTab === '1' ? 1 : 0}
                onQuery={setQuery}
              />
              <Col>
                <Row className='p-3 justify-content-end'>
                  <Paginations
                    itemClass='page-item'
                    linkClass='page-link'
                    activePage={activePage}
                    itemsCountPerPage={50}
                    totalItemsCount={contractsList.paginator?.total}
                    onChange={handlePageChange}
                  />
                </Row>
              </Col>
            </CardBody>
          )}
        </Card>
      </Container>
    </div>
  )
}

const StyledNav = styled(Nav)`
  border-bottom: 0 !important;

  .nav-item {
    margin-right: 2rem;

    .nav-link {
      padding: 6px 0;
      background-color: #f4f5f7;
      color: #777f9e;
      font-weight: 400;
      &:hover {
        border-top-color: #f4f5f7 !important;
        border-right-color: #f4f5f7 !important;
        border-left-color: #f4f5f7 !important;

        color: #777f9e !important;
      }
    }
    .active {
      border: 0;
      font-weight: 600;
      border-bottom: 2px solid #114ef7;
      p {
        color: #114ef7 !important;
      }
    }
  }
`

export default ContractsListAdmin
