import { Info } from '@phosphor-icons/react'
import cx from 'classnames'
import React from 'react'
import { Alert as BsAlert } from 'reactstrap'

export default function Alert({
  color,
  children,
  innerTag: InnerTag = 'p',
  innerClassName,
  customIcon,
  className,
  style,
}) {
  const icon = customIcon ?? (
    <i className='bx bx-info-circle font-size-22' style={{ marginLeft: -6 }} />
  )

  return (
    <BsAlert
      color={color}
      className={cx('d-flex', className)}
      style={{ gap: '0.5rem', ...style }}
    >
      {icon}
      <InnerTag className={cx('mb-0', innerClassName)}>{children}</InnerTag>
    </BsAlert>
  )
}

export function PrimaryAlert({ children }) {
  return (
    <Alert
      color='primary'
      className='mb-0 font-size-14 p-4 bg-primary-20 border-slate-100 text-slate-800'
      style={{ gap: '0.75rem' }}
      customIcon={<Info size='22' className='flex-shrink-0 text-primary' />}
    >
      {children}
    </Alert>
  )
}
