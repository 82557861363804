import { AvForm } from 'availity-reactstrap-validation'
import React, { useEffect, useRef, useState } from 'react'
import GoogleLogin from 'react-google-login'
import ReCAPTCHA from 'react-google-recaptcha'
import { useDispatch, useSelector } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

import TextInput from '../../components/Forms/TextInput'
import { StyledH5 } from '../../components/Typo'
import Button from '../../components/ui/button'
import { useFetch } from '../../helpers/hooks'
import useTdGuid from '../../helpers/hooks/use-td-guid'
import useUserAgent from '../../helpers/hooks/use-user-agent'
import { googleLogin, userLogin as userLoginAction } from '../../services/api'
import {
  loginOriginalUserSuccessful,
  loginUserSuccessful,
  updateDeepLink,
  updateRecaptchaRequired,
  updateUserData,
} from '../../store/actions'
import googleGIcon from './../../assets/images/Google__G__Logo.svg'
import logoMain from './../../assets/images/identity/logo_main.png'
import logoWhite from './../../assets/images/identity/logo_white.png'
import './style.css'

const Login = (props) => {
  const dispatch = useDispatch()
  const link = useSelector((state) => state.Account?.link)
  const loggedIn = useSelector((state) => state.Account?.loggedIn)
  const ref = useRef(null)
  const isRecaptchaRequired = useSelector(
    (state) => state.Account?.isRecaptchaRequired,
  )
  const [captchaVerified, setCaptchaVerified] = useState(false)
  const onChange = (e) => {
    setCaptchaVerified(true)
  }

  useEffect(() => {
    if (loggedIn) {
      props.history.push('/')
    }
  }, [loggedIn, props.history])

  const handleLoginResponse = (data) => {
    dispatch(loginUserSuccessful(data))
    dispatch(loginOriginalUserSuccessful(data))
    if (link && link !== '/logout' && typeof link === 'string') {
      if (link === '/withdraw') {
        props.history.push('/activity')
      } else {
        props.history.push(link)
      }
      dispatch(updateDeepLink(null))
    } else {
      props.history.push('/activity')
    }
  }

  const userAgent = useUserAgent()
  const { guid } = useTdGuid()

  function handleError(data) {
    if (data === 'Invalid email or password') {
      dispatch(updateRecaptchaRequired(true))
      setCaptchaVerified(false)
    }
    toastr.error(data)
  }

  const { startFetch: userLogin, isLoading: logging } = useFetch({
    action: userLoginAction,
    onComplete: (data, values) => {
      if (data?.success === false) {
        handleError(data)
      } else {
        window.analytics.track('Signed in', { email_id: values?.email })
        handleLoginResponse(data)
      }
    },
    onError: handleError,
  })

  // handleValidSubmit
  function handleValidSubmit(_, values) {
    if (captchaVerified || !isRecaptchaRequired) {
      dispatch(updateRecaptchaRequired(false))
      let data = {
        email: values.email,
        password: values.password,
      }

      if (guid) {
        data = { ...data, user_agent: userAgent, guid }
      }

      userLogin(data)
    }
    // props.loginUser(values, props.history);
  }
  const loginGoogle = useFetch({
    action: googleLogin,
    onComplete: (data) => {
      handleLoginResponse(data)
    },
    onError: (err) => {
      toastr.error(err)
    },
    checkSuccess: true,
  })
  const handleGoogleLogin = (e) => {
    dispatch(updateUserData(e))
    loginGoogle.startFetch({
      token: e?.tokenId,
    })
  }

  const handleGoogleLoginError = (e) => {
    if (
      e?.error === 'idpiframe_initialization_failed' &&
      e?.details === 'Cookies are not enabled in current environment.'
    ) {
      return
    }

    toastr.error(e.error)
  }

  return (
    <React.Fragment>
      {!link?.startsWith('/contract/detail') && (
        <StyledDiv className='d-flex justify-content-center align-items-center'>
          <img
            src={link?.startsWith('/contract/detail') ? logoWhite : logoMain}
            height={24}
            alt=''
          />
        </StyledDiv>
      )}

      <div
        className={`account-pages ${
          link?.startsWith('/contract/detail') ? 'blur-image' : 'blur-container'
        } p-0 m-0`}
        style={{
          minHeight: link?.startsWith('/contract/detail') ? '100vh' : null,
        }}
      >
        <StyledH5
          min='22px'
          max='32px'
          className={`text-center mb-0 pt-4 pt-md-5 pb-3 pb-md-4 ${
            link?.startsWith('/contract/detail') ? 'text-white' : 'text-dark'
          }`}
        >
          Sign in
        </StyledH5>

        <Container className='px-3 pb-3 pt-0'>
          <Row className='justify-content-center p-0 m-0'>
            <Col md={8} lg={6} xl={5} className='p-0 m-0'>
              <Card
                style={{ boxShadow: '0px 1px 0px #DFE1E6' }}
                className='overflow-hidden p-3 m-0'
              >
                <CardBody className=' m-0 p-0'>
                  <AvForm
                    className='form-horizontal'
                    onValidSubmit={handleValidSubmit}
                  >
                    {props.error && props.error ? (
                      <Alert color='danger'>{props.error}</Alert>
                    ) : null}

                    <div className='form-group'>
                      <TextInput
                        name='email'
                        label='Email'
                        placeholder='Enter email'
                        autocomplete='email'
                        type='email'
                        required
                      />
                    </div>

                    <div className='form-group'>
                      <TextInput
                        name='password'
                        label='Current Password'
                        type='password'
                        placeholder='Current Password'
                        autocomplete='current-password'
                        required
                      />
                    </div>
                    <div className='mt-4'>
                      <Button
                        className='font-size-16 rp-font-gilroyB'
                        type='submit'
                        loading={logging}
                        disabled={logging}
                        block
                      >
                        Sign In
                      </Button>
                    </div>

                    {isRecaptchaRequired && (
                      <Row className='justify-content-center p-3'>
                        <ReCAPTCHA
                          ref={ref}
                          sitekey={process.env.REACT_APP_RE_CAPTCHA_SITE_KEY_V2}
                          onChange={onChange}
                        />
                      </Row>
                    )}
                    <div className='mt-4 text-center d-flex justify-content-between'>
                      <div />
                      <Link
                        to='/forgot-password'
                        className='text-primary font-size-14 font-weight-medium'
                      >
                        Forgot password?
                      </Link>
                    </div>

                    <span
                      style={{
                        position: 'absolute',
                        right: '46%',
                        fontFamily: 'gilroyB',
                        padding: '15px',
                      }}
                      className='bg-white text-muted'
                    >
                      Or
                    </span>
                    <div className='mt-4 border-top pt-4'>
                      <GoogleLogin
                        className='btn-block btn-outline-light font-weight-bold text-dark text-center google-btn border border-light rounded'
                        clientId='434551861221-untotaur31aeerf1t5hoj7jra3s7a5ru.apps.googleusercontent.com'
                        buttonText='Continue with Google'
                        render={(renderProps) => (
                          <button
                            className='btn btn-block btn-outline-light font-weight-bold text-dark text-center google-btn border border-light rounded'
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                          >
                            <img
                              height={16}
                              className='mr-3'
                              src={googleGIcon}
                              alt=''
                            />
                            Continue with Google
                          </button>
                        )}
                        onSuccess={handleGoogleLogin}
                        onFailure={handleGoogleLoginError}
                        cookiePolicy='single_host_origin'
                        uxMode='popup'
                      />
                    </div>
                  </AvForm>
                </CardBody>
              </Card>

              <div className='mt-5 text-center'>
                <p className={'text-secondary '}>
                  {new Date().getFullYear()} © RemotePass.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const StyledDiv = styled.div`
  height: 80px;
  background-color: #ffffff;
  box-shadow: 0px 0px 8px #eaeaea, inset 0px -1px 0px #eaeaea;
  @media (max-width: 768px) {
    height: 56px;
  }
`
export default withRouter(Login)
