import cx from 'classnames'
import React from 'react'

import { ReactComponent as CardUnderReviewIllustration } from '../../../assets/images/card-under-review.svg'
import EmptyContent from './empty-content'

export default function UnderReviewCard({
  className,
  wrapperProps = {},
  illustrationStyles,
}) {
  const props = { minHeight: 'calc(100vh - 300px)', ...wrapperProps }
  return (
    <EmptyContent className={cx('py-4 px-4 px-md-0', className)} {...props}>
      <CardUnderReviewIllustration
        className='px-4'
        style={illustrationStyles}
      />

      <div className='text-center'>
        <h2 className='rp-font-gilroyB text-gray-h'>
          Your card request is under review
        </h2>
        {/* <p className='mb-0 text-gray-600' style={{ maxWidth: '48ch' }}>
          Verification is in progress
        </p> */}
      </div>
    </EmptyContent>
  )
}
