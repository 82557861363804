import React from 'react'
import { Col, Row } from 'reactstrap'
import styled from 'styled-components'

import coinbaseSelected from '../../../assets/images/coinbase_icon.svg'
import coinbase from '../../../assets/images/coinbase_icon_inactive.svg'
import { useFetch, useResize } from '../../../helpers/hooks'
import { deleteCoinbaseAccount } from '../../../services/api'
import { track } from '../../../utils/analytics'
import EVENTS from '../../../utils/events'

const PaypalMethodCard = ({
  account,
  withTag,
  onUpdate,
  selected,
  onSelect,
  withRemove,
  onClick = () => {},
  checked,
}) => {
  const deleteCard = useFetch({
    action: deleteCoinbaseAccount,
    onComplete: () => {
      onUpdate()
      track(EVENTS.REMOVED_WITHDRAW_METHODS, {
        withdraw_method: 'Paypal',
        is_default: false,
      })
    },
  })
  const isMobile = useResize()

  return (
    <StyledCard
      className={checked ? 'border-primary' : ''}
      onClick={() => onClick(account)}
    >
      <StyledPaypalIcon>
        {withRemove && (
          <>
            {deleteCard.isLoading ? (
              <i className='bx bx-loader bx-spin text-danger font-size-14 align-middle ml-2' />
            ) : (
              <button
                type='button'
                className='rp-btn-nostyle d-flex p-1 text-muted font-size-16'
                onClick={() => {
                  deleteCard.startFetch({ id: account?.id })
                }}
              >
                <i className='bx bxs-trash-alt' />
              </button>
            )}
          </>
        )}
      </StyledPaypalIcon>
      <Row
        className={`${isMobile ? 'p-md-3 p-3' : 'p-md-0 p-0'} py-md-3 py-3 m-0`}
      >
        <Col xs={12} md={2} className='iconi p-0 m-0'>
          <div
            style={{ height: '100%', width: '100%' }}
            className={`d-flex align-items-center ${
              isMobile ? 'justify-content-start' : 'justify-content-center'
            }`}
          >
            <img
              className={'mb-3 mb-md-0'}
              src={checked ? coinbaseSelected : coinbase}
              alt=''
              style={{ width: '1.8rem' }}
            />
          </div>
        </Col>

        <Col xs={12} md={10} className='p-0 m-0'>
          <h5
            className={`${
              checked ? 'text-primary' : 'text-dark'
            } mb-2 font-size-16`}
            style={{ fontFamily: 'gilroyB' }}
          >
            Coinbase
          </h5>
          <div
            className={`${
              checked ? 'text-primary' : 'text-secondary'
            } font-size-14 mb-2 `}
          >
            {account.email} {account.currency ? ` - ${account.currency}` : ''}{' '}
          </div>
        </Col>
      </Row>
    </StyledCard>
  )
}

const StyledCard = styled.div`
  position: relative;
  background-color: #ffffff;
  border: 1px solid #e7e8f2;
  border-radius: 4px;
  @media (max-width: 768px) {
    margin-bottom: 16px;
  }
`

const StyledPaypalIcon = styled.div`
  z-index: 9;
  position: absolute;
  top: 12px;
  right: 12px;
`

export default PaypalMethodCard
