import { yupResolver } from '@hookform/resolvers/yup'
import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  Card,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Modal,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'
import * as yup from 'yup'

import ActionsDropdown from '../../../components/ActionsDropdown'
import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import ControlledCurrencyInput from '../../../components/ControlledCurrencyInput'
import ControlledInput from '../../../components/ControlledInput'
import ControlledSelect from '../../../components/ControlledSelect'
import NoContent from '../../../components/NoContent'
import Steps from '../../../components/Steps'
import BadgeX from '../../../components/Table/BadgeX'
import StyledTd from '../../../components/Table/StyledTd'
import StyledTh from '../../../components/Table/StyledTh'
import TableComp from '../../../components/Table/TableComp'
import TableH from '../../../components/Table/TableH'
import WiseButton from '../../../components/WiseButton'
import { userTypes } from '../../../helpers/enum'
import { useFetch, usePermissions, useResize } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  addEquipment,
  deleteEquipment,
  downloadAgreement,
  editAgreement,
  getAgreement,
  getContractorAgreement,
  returnEquipment,
  signEquipment,
  updateEquipment,
} from '../../../services/api'
import styles from '../components/Forms/payment-form.module.scss'
import {
  customOption,
  customSingleValue,
} from '../ContractList/ClientContractList'
import noPayImage from './../../../assets/images/nopayments.svg'
import './../ContractEditor/style.css'
import EquipmentEditor from './EquipmentEditor'
import { getStatusColor } from './Expenses'

const Equipments = ({
  loadingData,
  contractId,
  data,
  fetchEquipmentList,
  currency,
}) => {
  const [open, setOpen] = useState(false)
  const [showReturnModal, setShowReturnModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [equipmentToReturn, setEquipmentToReturn] = useState(null)
  const [itemToSign, setItemToSign] = useState(null)
  const [selectedItem, setSelectedItem] = useState(null)
  const [toRemove, setToRemove] = useState(null)
  const user = useSelector((state) => state.Account.user)
  const contract = useSelector((state) => state.Contract.details)

  const { hasAccess } = usePermissions()

  const returnEquipmentFunc = useFetch({
    action: returnEquipment,
    onComplete: (res) => {
      fetchEquipmentList()
      setShowReturnModal(false)
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  const deleteEquipmentFunc = useFetch({
    action: deleteEquipment,
    onComplete: (res) => {
      fetchEquipmentList()
      setShowConfirmationModal(false)
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const handleReturn = () => {
    returnEquipmentFunc.startFetch({ equipment_id: equipmentToReturn?.id })
  }

  const downloadAgreementFile = (e) => {
    downloadAgreement(user?.token, e)
      .then((r) => {
        const url = window.URL.createObjectURL(new Blob([r.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${contract?.ref}-${e?.type}.pdf`)
        document.body.appendChild(link)
        link.click()
      })
      .catch((e) => {
        toastr.error(e)
      })
  }

  const isMobile = useResize()

  return (
    <>
      {loadingData ? (
        <Row
          style={{ minHeight: '80vh' }}
          className='justify-content-center align-items-center'
        >
          <Spinner type='grow' className='mr-2' color='primary' />
        </Row>
      ) : (
        <Card
          style={{
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 1px 0px #DFE1E6',
            borderRadius: 4,
            overflow: 'visible',
          }}
          className='  p-0 m-0 mb-5'
        >
          <div className='overflow-visible'>
            <TableH
              title={'Equipments'}
              leftSide={
                user?.type === userTypes.COMPANY &&
                hasAccess(permissions.addEquipment) ? (
                  <WiseButton
                    onClick={() => setOpen(!open)}
                    text={'Add equipment'}
                    bold
                    minHeight={'48px'}
                    minWidth={'100%'}
                  />
                ) : null
              }
            />

            {data?.length === 0 ? (
              <NoContent
                headline='No Equipment'
                subtitle={
                  'All your equipment will be shown here after someone will add it...'
                }
                image={noPayImage}
                withAction={
                  user?.type === userTypes.COMPANY &&
                  hasAccess(permissions.addEquipment)
                }
                onAction={() => {
                  setOpen(true)
                }}
                advanced={false}
                actionTitle={'Add New Equipment'}
              />
            ) : isMobile ? (
              data?.map((e, i) => {
                return (
                  <EquipmentMobileCard
                    e={e}
                    key={`eq-${e?.id}`}
                    handleSetItemToSign={(e) => setItemToSign(e)}
                    handleOpen={() => setOpen(!open)}
                    handleDownload={() => downloadAgreementFile(e)}
                    handleReturn={() => {
                      setEquipmentToReturn(e)
                      setShowReturnModal(true)
                    }}
                    handleDelete={() => {
                      setToRemove(e)
                      setShowConfirmationModal(true)
                    }}
                    currency={currency}
                  />
                )
              })
            ) : (
              <div className='table-with-dropdown'>
                <TableComp>
                  <thead className='thead-light'>
                    <tr>
                      <StyledTh>Type</StyledTh>
                      <StyledTh>Value</StyledTh>
                      <StyledTh>Serial number</StyledTh>
                      <StyledTh>Description</StyledTh>
                      <StyledTh>Status</StyledTh>
                      <StyledTh></StyledTh>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((e, i) => {
                      let desc = e?.description.replace('<p>', '')
                      desc = desc.replace('</p>', '')
                      const formatter1 = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: e?.currency?.code || 'USD',
                      })

                      return (
                        <>
                          <tr key={`eq-${e?.id}`}>
                            <StyledTd>{e?.type}</StyledTd>
                            <StyledTd>
                              {e?.price ? formatter1.format(e?.price) : 'N/A'}
                            </StyledTd>
                            <StyledTd>{e?.serial_number || 'N/A'}</StyledTd>
                            <StyledTd>
                              <div
                                className={'requirement-description'}
                                dangerouslySetInnerHTML={{
                                  __html: `${
                                    desc?.length > 25
                                      ? desc?.substring(0, 25) + '...'
                                      : desc
                                  }`,
                                }}
                              />
                            </StyledTd>
                            <td className='py-3 px-4'>
                              {user?.type === userTypes.CONTRACTOR &&
                              e?.contractor_signed === 0 ? (
                                <a
                                  className='text-primary'
                                  onClick={() => {
                                    setItemToSign(e)
                                    setOpen(true)
                                  }}
                                >
                                  Review & Sign
                                </a>
                              ) : (
                                <BadgeX
                                  name={e.status?.name}
                                  status={getStatusColor(
                                    e?.status?.name?.includes('Pending')
                                      ? 'Pending'
                                      : e?.status?.name,
                                  )}
                                  textStatus={getStatusColor(
                                    e?.status?.name?.includes('Pending')
                                      ? 'Pending'
                                      : e?.status?.name,
                                  )}
                                />
                              )}
                            </td>
                            <td className='py-3 px-4 position-relative'>
                              <ActionDropDown
                                equipment={e}
                                handleDownload={() => downloadAgreementFile(e)}
                                handleReturn={() => {
                                  setEquipmentToReturn(e)
                                  setShowReturnModal(true)
                                }}
                                handleDelete={() => {
                                  setToRemove(e)
                                  setShowConfirmationModal(true)
                                }}
                              />
                            </td>
                          </tr>
                        </>
                      )
                    })}
                  </tbody>
                </TableComp>
              </div>
            )}
          </div>
          {!!open && (
            <AddEquipmentModal
              selectedItem={selectedItem}
              itemToSign={itemToSign}
              contractId={contractId}
              isOpen={open}
              toggle={() => {
                setItemToSign(null)
                setSelectedItem(null)
                setOpen(!open)
              }}
              onEquipmentAdded={fetchEquipmentList}
            />
          )}

          <ConfirmationModal
            onConfirm={() =>
              deleteEquipmentFunc.startFetch({ equipment_id: toRemove?.id })
            }
            toggle={() => setShowConfirmationModal(false)}
            isOpen={showConfirmationModal}
            title={'Delete equipment'}
            buttonColor={'danger'}
            caption={'Delete'}
            message={'Are you sure you want to delete this equipment'}
          />
          <ReturnEquipmentModal
            onConfirm={handleReturn}
            isOpen={showReturnModal}
            toggle={() => {
              setShowReturnModal(!showReturnModal)
            }}
            onEquipmentAdded={fetchEquipmentList}
          />
        </Card>
      )}
    </>
  )
}

const ActionDropDown = ({
  handleReturn,
  handleDelete,
  handleDownload,
  equipment,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const user = useSelector((state) => state.Account?.user)
  const { hasAccess } = usePermissions()

  const actionsOptions = []

  if (
    equipment?.status?.name.includes('Pending') &&
    user?.type === userTypes.COMPANY &&
    hasAccess(permissions.deleteEquipment)
  ) {
    actionsOptions[0] = {
      onClick: handleDelete,
      // eslint-disable-next-line react/display-name
      component: () => <p className='m-0'>Delete</p>,
    }
  }
  if (
    equipment?.status?.name === 'Signed' &&
    user?.type === userTypes.COMPANY
  ) {
    actionsOptions[1] = {
      onClick: handleReturn,
      // eslint-disable-next-line react/display-name
      component: () => <p className='m-0 p-0'>Mark as returned</p>,
    }
  }
  if (hasAccess(permissions.downloadEquipment)) {
    actionsOptions[2] = {
      onClick: handleDownload,
      // eslint-disable-next-line react/display-name
      component: () => <p className='m-0'>Download</p>,
    }
  }

  return (
    <ActionsDropdown
      isOpen={isOpen}
      toggle={() => setIsOpen((t) => !t)}
      options={actionsOptions}
    />
  )
}

const ReturnEquipmentModal = ({ isOpen, toggle, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <div className='modal-content'>
        <>
          <div className='modal-header p-3 p-md-4'>
            <h5 className='modal-title mt-0'></h5>
            <button
              type='button'
              className='close'
              data-dismiss='modal'
              aria-label='Close'
              onClick={toggle}
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>

          <div className='modal-body p-4'>
            <Col className='p-0 m-0'>
              <Row className='bg-soft-primary rounded text-primary border border-primary  m-0 mb-3 p-3 '>
                <p className='font-size-16 font-weight-bold'>Attention!</p>
                <p>
                  Please note that this action is irreversible. When you mark
                  equipment as returned, you claim to have received the
                  equipment back or allowed the user to keep it. This agreement
                  will be cancelled and no longer be valid
                </p>
              </Row>
              <p className='font-size-16 font-weight-bold text-dark'>
                Do you wish to continue?
              </p>
            </Col>
          </div>
          <StyledFooter className='modal-footer p-3 p-md-4 justify-content-between justify-content-md-end'>
            <WiseButton
              border
              bk={'white'}
              textColor={'dark'}
              type='button'
              text={' Cancel'}
              bold
              width={'auto'}
              minWidth={'40%'}
              onClick={toggle}
            />

            <WiseButton
              loading={false}
              disabled={false}
              text={'Proceed'}
              bold
              width={'auto'}
              minWidth={'40%'}
              onClick={onConfirm}
            />
          </StyledFooter>
        </>
      </div>
    </Modal>
  )
}

export const AddEquipmentModal = ({
  contractId,
  isOpen,
  toggle,
  onEquipmentAdded,
  selectedItem,
  itemToSign,
}) => {
  const [phase, setPhase] = useState(itemToSign ? 2 : 0)
  const [agreement, setAgreement] = useState(null)
  const [formData, setFormData] = useState(null)
  const [editable, setEditable] = useState(null)
  const [textAgreement, setTextAgreement] = useState(null)
  const staticData = useSelector((state) => state.Layout.staticData)
  const user = useSelector((state) => state?.Account?.user)
  const contract = useSelector((state) => state.Contract.details)
  const [currency, setCurrency] = useState(contract?.currency)
  const equipmentTypes =
    useSelector((state) => state.Layout.staticData?.equipment_types) || []

  useEffect(() => {
    if (itemToSign) {
      setFormData({
        ...itemToSign,
        contract_id: contractId,
        currency_id: currency?.id,
      })
      getHtmlAgreement.startFetch({
        ...itemToSign,
        contract_id: contractId,
        currency_id: currency?.id,
      })
    }
  }, [itemToSign])

  const createEquipment = useFetch({
    action: user?.type === userTypes.COMPANY ? addEquipment : signEquipment,
    onComplete: (res) => {
      if (selectedItem?.id) {
        onEquipmentAdded()
        toggle()
      } else {
        onEquipmentAdded()
        toggle()
      }
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const getHtmlAgreement = useFetch({
    action:
      user?.type === userTypes.COMPANY ? getAgreement : getContractorAgreement,
    onComplete: (res) => {
      const file = window.URL.createObjectURL(res)
      setAgreement(file)
      if (!itemToSign) {
        setPhase(phase + 1)
      }
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const getEditableAgreement = useFetch({
    action: editAgreement,
    onComplete: (res) => {
      setEditable(res?.part2)
      setPhase(phase + 1)
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const updateEq = useFetch({
    action: updateEquipment,
    onComplete: (res) => {
      onEquipmentAdded()
      toggle()
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const schema = yup.object().shape({
    contract_id: yup.number(),
    type: yup.string().required(),
    description: yup.string().required(),
    price: yup.number(),
    currency_id: yup.number(),
    serial_number: yup.string(),
    file: yup.string(),
  })

  const {
    handleSubmit,
    control,

    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: selectedItem || {},
  })

  const onSubmit = (body) => {
    if (selectedItem?.id) {
      updateEq.startFetch({
        ...body,
        equipment_id: selectedItem?.id,
        currency_id: currency?.id,
      })
    } else {
      setFormData({
        ...body,
        contract_id: contractId,
        currency_id: currency?.id,
      })
      getEditableAgreement.startFetch({
        ...body,
        contract_id: contractId,
        currency_id: currency?.id,
      })
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size={'lg'}>
      <div className='p-3 p-md-4 border-bottom border-light'>
        <Row className='justify-content-center'>
          <Row className='justify-content-center w-100'>
            <Col md={8}>
              {!itemToSign && (
                <Steps
                  activeTab={phase}
                  data={['Add Equipment', 'Review', 'Sign']}
                />
              )}
            </Col>
          </Row>
          <button
            type='button'
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={toggle}
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </Row>
      </div>
      <TabContent activeTab={phase} className='p-0'>
        <TabPane tabId={0}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='modal-content'>
              <>
                <div
                  className='modal-body p-0 m-0 py-4'
                  style={{ height: '60vh' }}
                >
                  <Row className='justify-content-center align-items-center p-0 m-0'>
                    <Col md={4} sm={12} className='mb-3'>
                      <FormGroup className='d-inline p-0 m-0'>
                        <Label
                          htmlFor='billing-name'
                          className='col-form-label p-0 m-0 mb-2 font-size-14'
                        >
                          Equipment type
                          <span className='text-danger font-size-16 mx-1'>
                            *
                          </span>
                        </Label>
                        <ControlledSelect
                          error={errors.type}
                          control={control}
                          name='type'
                          options={[
                            ...(equipmentTypes?.map((e) => ({
                              label: e?.name,
                              value: e?.name,
                            })) ?? []),
                            { label: 'Other', value: 'Other' },
                          ]}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6} sm={12} className='mb-3'>
                      <FormGroup className='d-inline p-0 m-0'>
                        <Label
                          style={{ whiteSpace: 'nowrap' }}
                          htmlFor='billing-name'
                          md='3'
                          className='col-form-label p-0 m-0 mb-2 font-size-14'
                        >
                          Value
                        </Label>

                        <InputGroup>
                          <ControlledCurrencyInput
                            control={control}
                            name='price'
                            error={errors.price}
                            placeholder='Enter Value (Optional)'
                            autocomplete='off'
                            allowDecimals={true}
                            decimalsLimit={2}
                            decimalSeparator='.'
                            groupSeparator=','
                          />
                          {/* <InputGroupAddon addonType="append">
                            <InputGroupText>{contract?.currency?.code}</InputGroupText>
                          </InputGroupAddon> */}
                          <ControlledSelect
                            className={cx(
                              'input-group-text p-0',
                              styles.selectAddonAppend,
                            )}
                            selectStyles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                paddingLeft: 0,
                              }),
                            }}
                            placeholder='Currency'
                            error={errors.currency_id}
                            control={control}
                            name='currency_id'
                            options={staticData?.currencies?.map((c) => {
                              return {
                                ...c,
                                label: `${c.code} ${c.name} ${c.symbol}`,
                                icon: (
                                  <div
                                    className={`currency-flag mr-1 currency-flag-${c.code.toLowerCase()}`}
                                  />
                                ),
                                value: c.id,
                              }
                            })}
                            value={{
                              ...currency,
                              icon: (
                                <div
                                  className={`currency-flag mr-1 currency-flag-${currency.code.toLowerCase()}`}
                                />
                              ),
                            }}
                            onChange={setCurrency}
                            classNamePrefix='RS-Addon'
                            customComponents={{
                              Option: customOption,
                              Value: customSingleValue,
                              SingleValue: customSingleValue,
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md={10} sm={12} className='mb-3'>
                      <FormGroup className='d-inline p-0 m-0'>
                        <Label
                          style={{ whiteSpace: 'nowrap' }}
                          htmlFor='billing-name'
                          md='3'
                          className='col-form-label p-0 m-0 mb-2 font-size-14'
                        >
                          Model
                          <span className='text-danger font-size-16 mx-1'>
                            *
                          </span>
                        </Label>
                        <ControlledInput
                          control={control}
                          name='description'
                          error={errors.description}
                          placeholder={'ex. Macbook Air 2020'}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={10} sm={12} className='mb-3'>
                      <FormGroup className='d-inline p-0 m-0'>
                        <Label
                          style={{ whiteSpace: 'nowrap' }}
                          htmlFor='billing-name'
                          md='3'
                          className='col-form-label p-0 m-0 mb-2 font-size-14'
                        >
                          Serial number
                        </Label>
                        <ControlledInput
                          control={control}
                          name='serial_number'
                          error={errors.serial_number}
                          placeholder='Enter serial number (Optional)'
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <StyledFooter className='modal-footer p-3 p-md-4 justify-content-between justify-content-md-end'>
                  <WiseButton
                    border
                    bk={'white'}
                    textColor={'dark'}
                    type='button'
                    text={' Cancel'}
                    bold
                    width={'auto'}
                    minWidth={'40%'}
                    onClick={toggle}
                  />

                  <WiseButton
                    loading={getEditableAgreement.isLoading}
                    disabled={getEditableAgreement.isLoading}
                    text={selectedItem?.id ? 'Update' : 'Continue'}
                    bold
                    width={'auto'}
                    minWidth={'40%'}
                    type='submit'
                  />
                </StyledFooter>
              </>
            </div>
          </form>
        </TabPane>
        {phase === 1 && (
          <TabPane tabId={1}>
            <div className='modal-content'>
              <div
                className='modal-body p-0 m-0'
                style={{ height: '60vh', overflow: 'scroll' }}
              >
                <EquipmentEditor
                  onEdit={() => {}}
                  onChange={(s) => {
                    setTextAgreement(s)
                  }}
                  sections={[{ details: editable }]}
                  agreement={getEditableAgreement.data?.part1}
                  signature={getEditableAgreement.data?.part3}
                />
              </div>
              <StyledFooter className='modal-footer p-3 p-md-4 justify-content-between justify-content-md-end'>
                <WiseButton
                  border
                  bk={'white'}
                  textColor={'dark'}
                  type='button'
                  text={' Back'}
                  bold
                  width={'auto'}
                  minWidth={'40%'}
                  onClick={() => setPhase(phase - 1)}
                />

                <WiseButton
                  loading={getHtmlAgreement.isLoading}
                  disabled={getHtmlAgreement.isLoading}
                  text={'Next'}
                  bold
                  width={'auto'}
                  minWidth={'40%'}
                  onClick={() => {
                    const body = {
                      ...formData,
                    }
                    if (textAgreement) body.custom = textAgreement
                    getHtmlAgreement.startFetch(body)
                  }}
                />
              </StyledFooter>
            </div>
          </TabPane>
        )}

        <TabPane tabId={2}>
          <SignaturePart
            createEquipment={(name) => {
              const body =
                user?.type === userTypes.COMPANY
                  ? {
                      ...formData,
                      signature_name: name,
                    }
                  : { equipment_id: formData?.id, name }
              if (textAgreement) body.custom = textAgreement
              createEquipment.startFetch(body)
            }}
            html={agreement}
            toggle={toggle}
            onBack={!itemToSign ? () => setPhase(phase - 1) : null}
          />
        </TabPane>
      </TabContent>
    </Modal>
  )
}

const SignaturePart = ({ html, data, createEquipment, toggle, onBack }) => {
  const [fullName, setFullName] = useState(null)
  const { hasAccess } = usePermissions()

  useEffect(() => {}, [])

  const handleSignClick = () => {
    createEquipment(fullName)
  }

  return (
    <>
      <div
        style={{ backgroundColor: '#F4F5F7' }}
        className='modal-body p-3 p-md-3'
      >
        {html ? (
          <iframe
            frameBorder='0'
            id='eqSignatureFrame'
            src={html}
            width='100%'
            style={{
              backgroundColor: '#fff',
              overflow: 'auto',
              minHeight: '60vh',
            }}
          />
        ) : (
          <Row
            style={{ minHeight: '60vh' }}
            className='justify-content-center align-items-center'
          >
            <Spinner type='grow' className='mr-2' color='primary' />
          </Row>
        )}
      </div>

      <div className='modal-footer p-3 p-md-4 justify-content-between justify-content-md-end align-items-center'>
        <Col className='p-0 m-0 pr-1'>
          <Input
            placeholder='Enter your full name'
            onChange={(e) => {
              setFullName(e.target.value)
            }}
            value={fullName}
          />
        </Col>
        {!!onBack && (
          <WiseButton
            border
            bk={'white'}
            textColor={'dark'}
            type='button'
            text={' Back'}
            bold
            width={'auto'}
            height={'auto'}
            onClick={onBack}
          />
        )}

        <WiseButton
          text={'Agree & Sign'}
          bold
          width={'auto'}
          height={'auto'}
          onClick={() => {
            if (fullName) {
              handleSignClick()
            } else {
              toastr.error('Please Enter your full name')
            }
          }}
          disabled={!hasAccess(permissions.signEquipment)}
        />
      </div>
    </>
  )
}

const EquipmentMobileCard = ({
  e,
  handleOpen,
  handleSetItemToSign,
  handleDownload,
  handleReturn,
  handleDelete,
  currency,
}) => {
  const user = useSelector((state) => state.Account.user)
  let desc = e?.description.replace('<p>', '')
  desc = desc.replace('</p>', '')
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency?.code || 'USD',
  })
  return (
    <div
      className='mb-3 mx-3'
      style={{
        background: '#FFFFFF',
        border: '1px solid #E7E8F2',
        borderRadius: 4,
      }}
    >
      <div className='d-flex justify-content-between  border-bottom p-3 m-0 p-0'>
        <div>
          {user?.type === userTypes.CONTRACTOR && e?.contractor_signed === 0 ? (
            <a
              className='text-primary'
              onClick={() => {
                handleSetItemToSign(e)
                handleOpen()
              }}
            >
              Review & Sign
            </a>
          ) : (
            <BadgeX
              name={e.status?.name}
              status={getStatusColor(
                e?.status?.name?.includes('Pending')
                  ? 'Pending'
                  : e?.status?.name,
              )}
              textStatus={getStatusColor(
                e?.status?.name?.includes('Pending')
                  ? 'Pending'
                  : e?.status?.name,
              )}
            />
          )}
        </div>
      </div>

      <div className='d-flex justify-content-between align-items-center pt-3 px-3'>
        <p className='text-dark font-size-14 mb-0'> Type</p>
        <p className='text-dark font-size-14 mb-0'> {e?.type}</p>
      </div>

      <div className='d-flex justify-content-between align-items-center pt-3 px-3'>
        <p className='text-dark font-size-14 mb-0'>Price</p>
        <p className='text-dark font-size-14 mb-0'>
          {e?.price ? formatter.format(e?.price) : 'N/A'}
        </p>
      </div>
      <div className='d-flex justify-content-between align-items-center pt-3 px-3'>
        <p className='text-dark font-size-14 mb-0'>Serial number</p>
        <p className='text-dark font-size-14 mb-0'>
          {e?.serial_number || 'N/A'}
        </p>
      </div>
      <div className='d-flex justify-content-between align-items-center pt-3 px-3'>
        <p className='text-dark font-size-14 mb-0'>Description</p>
        <p className='text-dark font-size-14 mb-0'>
          <div
            className={'requirement-description'}
            dangerouslySetInnerHTML={{
              __html: `${
                desc?.length > 25 ? desc?.substring(0, 25) + '...' : desc
              }`,
            }}
          />
        </p>
      </div>

      <div className='d-flex justify-content-between align-items-center p-3'>
        <p className='text-dark font-size-14 mb-0'>Actions</p>
        <div>
          <ActionDropDown
            equipment={e}
            handleDownload={handleDownload}
            handleReturn={handleReturn}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </div>
  )
}

const StyledFooter = styled(Col)`
  @media (max-width: 767px) {
    margin-top: 35vh;
  }
`

export default Equipments
