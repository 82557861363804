import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import { useResize } from '../../../helpers/hooks'
import XeroAccountMapping from './XeroAccountMapping'
import { StyledH5 } from '../../../components/Typo'
import Avatar from '../../../components/Avatar'
import { useDispatch } from 'react-redux'
import { updateContractor } from '../../../store/xero/actions'
import { qbUpdateContractor } from '../../../store/qb/actions'
import { freshUpdateContractor } from '../../../store/fresh/actions'
import { updateZohoContractor } from '../../../store/zoho/actions'
import { updateNetSuiteContractor } from '../../../store/ns/actions'

MappingAccountModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.bool.isRequired,
  contractor: PropTypes.object.isRequired,
}

function MappingAccountModal({
  isOpen,
  toggle,
  contractor,
  platform = 'Xero',
}) {
  const isMobile = useResize()

  const dispatch = useDispatch()
  const onSubmitted = (values) => {
    const payload = {
      ...contractor,
      categories: values,
    }
    switch (platform) {
      case 'Xero':
        dispatch(updateContractor(payload))
        break
      case 'Freshbooks':
        dispatch(freshUpdateContractor(payload))
        break
      case 'Quickbooks':
        dispatch(qbUpdateContractor(payload))
        break
      case 'Zoho':
        dispatch(updateZohoContractor(payload))
        break
      case 'NetSuite':
        dispatch(updateNetSuiteContractor(payload))
        break
    }
  }
  return (
    <Modal
      size='lg'
      centered
      isOpen={isOpen}
      role='dialog'
      tabindex='-1'
      toggle={toggle}
    >
      <ModalHeader toggle={toggle} />
      <ModalBody>
        <Row className='p-0 m-0 align-items-center mb-3'>
          <Avatar name={'Azeddine Ajebli'} />
          <Col className='p-0 m-0 ml-2'>
            <h6 className='mb-1'>{contractor?.name}</h6>
            <p className='text-muted mb-0'>{contractor?.email}</p>
          </Col>
        </Row>
        <div className='mb-3 my-md-4'>
          <StyledH5 className='font-size-16 font-weight-bold mb-3'>
            {platform} Accounts
          </StyledH5>
        </div>
        <Row className='align-items-center w-100 p-0 m-0'>
          <p className='text-muted'>
            Remotepass categories on the left will be posted to the {platform}{' '}
            accounts on the right
          </p>
        </Row>
        <XeroAccountMapping
          context={'modal'}
          onSubmitted={onSubmitted}
          contractor={contractor}
          platform={platform}
        />
      </ModalBody>
      <ModalFooter className={isMobile ? 'fixed-bottom' : ''}>
        <Button outline type='button' color='light' onClick={toggle}>
          Cancel
        </Button>
        <Button
          type='button'
          color='primary'
          onClick={() => {
            document.getElementById('submit-xero-account-mapping-modal').click()
            toggle()
          }}
        >
          Save changes
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default MappingAccountModal
