import React, { useState } from 'react'
import styled from 'styled-components'
import { Collapse } from 'reactstrap'
import { StyledH3 } from '../../../components/Typo'

const QuestionItem = ({ title, component, defaultStatus = false }) => {
  const [isOpen, setIsOpen] = useState(defaultStatus)
  const onHeaderClick = () => {
    setIsOpen((t) => !t)
  }
  return (
    <Card className='mb-3 bg-white rounded'>
      <button
        className='d-flex align-items-center justify-content-between text-left bg-transparent w-100 border-0'
        style={{ padding: '1.325rem' }}
        onClick={onHeaderClick}
      >
        <StyledH3
          style={{ maxWidth: '76%' }}
          className=' font-weight-bold m-0'
          min={'16px'}
          max={'24px'}
        >
          {title}
        </StyledH3>
        <i className='bx bx-chevron-down text-dark font-size-24' />
      </button>
      <Collapse isOpen={isOpen}>{component}</Collapse>
    </Card>
  )
}

const Card = styled.div`
  box-shadow: 0px 1px 0px #dfe1e6;
`

export default QuestionItem
