import cx from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { Controller } from 'react-hook-form'
import { Input } from 'reactstrap'
import styled from 'styled-components'

import { useOnClickOutside } from '../../helpers/hooks/use-outside-click'
import { getInputErrorMessage } from '../Forms/get-input-error-message'
import InputFeedback from '../ui/input-feedback'

const Suggestions = styled.div`
  z-index: 9;
  position: absolute;
  left: 0;
  max-height: 250px;
  overflow-y: auto;

  box-shadow: 5px 5px 24px -4px rgba(0, 0, 0, 0.07);
`

const ControlledInput = ({
  autoComplete,
  watchState,
  control,
  disabled,
  name,
  defaultValue,
  error: externalError,
  showError = true,
  label,
  labelClassName,
  labelFor,
  transform,
  containerClassName,
  ...otherProps
}) => {
  const inputContainerRef = useRef()
  const [suggestion, setSuggestions] = useState([])

  useOnClickOutside(inputContainerRef, () => {
    setSuggestions([])
  })

  useEffect(() => {
    const selectedValue =
      autoComplete?.filter((word) => word.title === watchState).length > 0

    if (watchState?.length <= 0) {
      setSuggestions([])
    } else {
      if (!selectedValue) {
        const regex = new RegExp(`^${watchState}`, 'i')
        const newSuggestions = autoComplete?.filter((word) =>
          regex.test(word?.title),
        )
        setSuggestions(newSuggestions?.length > 0 ? newSuggestions : [])
      }
    }
  }, [watchState, autoComplete])

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { onChange, value, onBlur, ref },
        fieldState: { error },
      }) => {
        const theError = externalError || error

        const showingError = theError && showError

        return (
          <>
            {!label ? null : (
              <label
                className={labelClassName}
                htmlFor={otherProps?.id ?? labelFor}
              >
                {label}
              </label>
            )}

            <div
              className={cx('position-relative w-100', containerClassName)}
              ref={inputContainerRef}
            >
              <Input
                className={cx('form-control', {
                  'border-danger': !!theError,
                })}
                disabled={disabled}
                onChange={(e) =>
                  transform?.output
                    ? onChange(transform.output(e))
                    : onChange(e)
                }
                value={transform?.input ? transform.input(value) : value}
                onBlur={onBlur}
                innerRef={ref}
                name={name}
                autoComplete={
                  typeof autoComplete === 'object' ? 'off' : autoComplete
                }
                {...otherProps}
              />

              {showingError ? (
                <InputFeedback className='mt-1'>
                  {getInputErrorMessage(theError)}
                </InputFeedback>
              ) : null}

              {autoComplete && suggestion.length > 0 && (
                <Suggestions
                  className='py-2 bg-white rounded border border-gray-b w-100'
                  style={{ top: showingError ? '100%' : 'calc(100% + 12px)' }}
                >
                  {suggestion?.slice(0, 10)?.map((item, i) => {
                    return (
                      <button
                        className='rp-btn-nostyle d-block rounded-0 w-100 text-left font-size-14 hover:bg-light px-3 py-2 cursor-pointer'
                        onClick={() => {
                          onChange(item.title)
                          setSuggestions([])
                        }}
                        key={i}
                      >
                        {item?.title}
                      </button>
                    )
                  })}
                </Suggestions>
              )}
            </div>
          </>
        )
      }}
    />
  )
}

export default ControlledInput
