import { AvField, AvForm } from 'availity-reactstrap-validation'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  Row,
  Spinner,
} from 'reactstrap'
import toastr from 'toastr'

import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import { StyledH5 } from '../../../../components/Typo'
import Button from '../../../../components/ui/button'
import ADMIN_PERMISSIONS from '../../../../config/admin-permissions'
import { useFetch } from '../../../../helpers/hooks'
import useHasPermission from '../../../../helpers/hooks/admin/has-permission'
import {
  AssignPartnerToFT,
  cancelEORRequest,
  downloadAdminQuote,
  downloadContractPdf,
  downloadEmployementContractPdf,
  getContractDetailAdmin,
  GetPartners,
  inviteClientFulltime,
  terminateEORContract,
  updateAdminFulltimeQuotation,
  updateFTContract,
  uploadFTContract,
} from '../../../../services/api'
import { updateFulltimeContract } from '../../../../store/contract/actions'
import { getStatusColor } from '../../../Contract/ContractDetail'
import FullTimeDetailsForm from '../../../Contract/RequestContract/FullTimeDetailsForm'
import QuotationForm from '../../../Contract/RequestContract/QuotationForm'

const RequestDetails = () => {
  const { contractId } = useParams()
  const [isDownloadOpen, setIsDownloadOpen] = useState(false)
  const contract = useFetch({
    action: getContractDetailAdmin,
    withAdminAccess: true,
    autoFetch: true,
    body: { id: contractId },
    onComplete: (data) => {
      setCollapseInfo(!data?.quote)
      setCollapseQuotation(!data?.quote)
      if (data.partner) {
        setPartner({ value: data?.partner?.id, label: data?.partner?.name })
      }
    },
  })

  const requestsPartners = useFetch({
    action: GetPartners,
    withAdminAccess: true,
    autoFetch: true,
  })
  const detailsFormRef = useRef(null)

  const [isOpenPreview, setIsOpenPreview] = useState(false)

  const [collapseQuotation, setCollapseQuotation] = useState(false)
  const [collapseInfo, setCollapseInfo] = useState(false)
  const [partner, setPartner] = useState(null)

  const history = useHistory()

  const dispatch = useDispatch()

  const updateQuotation = useFetch({
    action: updateAdminFulltimeQuotation,
    withAdminAccess: true,
    onComplete: () => {
      contract.startFetch({ id: contractId }, false)
      toastr.success('Quote updated successfully.')
    },
    checkSuccess: true,
    onError: (data) => {
      toastr.error('There was an error updating quote.' + data)
    },
  })
  const update = useFetch({
    action: updateFTContract,
    withAdminAccess: true,
    onComplete: () => {
      contract.startFetch({ id: contractId }, false)
    },
  })
  const onUpdateTemplate = () => {
    history.push({
      pathname: '/admin/template-editor',
      state: { contract_id: contract?.data?.id, isAdmin: true },
    })
  }
  const invite = useFetch({
    action: inviteClientFulltime,
    withAdminAccess: true,
    onComplete: () => {
      setIsOpenPreview(false)
      contract.startFetch({ id: contractId }, false)
      toastr.success('Client invited')
    },
    onError: toastr.error,
  })
  const onInviteClient = () => {
    invite.startFetch({
      contract_id: contract?.data?.id,
    })
  }
  useEffect(() => {
    return () => {
      dispatch(updateFulltimeContract(null))
    }
  }, [dispatch])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      onUploadContract(acceptedFiles[0])
    },
  })

  const upload = useFetch({
    action: uploadFTContract,
    withAdminAccess: true,
    checkSuccess: true,
    onError: (error) => {
      toastr.error(error)
    },
    onComplete: () => {
      contract.startFetch({ id: contractId }, false)
    },
  })

  const onUploadContract = useCallback(
    (file) => {
      upload.startFetch({
        contract_id: contract?.data?.id,
        employment_contract_file: file,
      })
    },
    [contract?.data?.id, upload],
  )

  const downloadContract = useFetch({
    action: downloadContractPdf,
    withAdminAccess: true,
    onComplete: (data) => {
      downloadFile(data, `SOW-${contract?.data?.ref}.pdf`)
    },
  })

  const downloadQuotation = useFetch({
    action: downloadAdminQuote,
    withAdminAccess: true,
    onComplete: (data) => {
      downloadFile(data, `Quotation-${contract?.data?.ref}.pdf`)
    },
  })

  const downloadEmploymentContract = useFetch({
    action: downloadEmployementContractPdf,
    withAdminAccess: true,
    onComplete: (data) => {
      downloadFile(data, `EmploymentContract-${contract?.data?.ref}.pdf`)
    },
  })

  const onDownloadMAS = () => {
    downloadContract.startFetch({
      contractToken: contract?.data?.file,
      ref: contract?.data?.ref,
    })
  }
  const onDownloadQuote = () => {
    downloadQuotation.startFetch({ contract_id: contract?.data?.id })
  }

  const onDownloadEmployementContract = () => {
    downloadEmploymentContract.startFetch({ contract_id: contractId })
  }

  const downloadFile = (data, name) => {
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
  }

  const cancelRequest = useFetch({
    action: cancelEORRequest,
    withAdminAccess: true,
    checkSuccess: true,
    onComplete: (data) => {
      history.push('/admin/requests')
    },
    onError: (data) => {
      console.log({ data })
    },
  })

  const { startFetch: terminateContract, isLoading: isTerminatingContract } =
    useFetch({
      action: terminateEORContract,
      withAdminAccess: true,
      onComplete: () => {
        history.push('/admin/requests')
      },
    })
  const assignPartner = useFetch({
    action: AssignPartnerToFT,
    withAdminAccess: true,
    onComplete: () => {
      contract.startFetch({ id: contractId }, false)
    },
    onError: (error) => {
      toastr.error(error)
    },
  })

  function handleCancelRequest() {
    const contractId = contract?.data?.id
    cancelRequest.startFetch({ contract_id: contractId })
  }
  function handleTerminateRequest(_, values) {
    const contractId = contract?.data?.id
    terminateContract({ contract_id: contractId, ...values })
  }
  const handleAssignPartner = (data) => {
    assignPartner.startFetch({
      contract_id: contractId,
      partner_id: data.value,
    })
  }

  const hasEditPermission = useHasPermission(
    ADMIN_PERMISSIONS.MANAGE_CONTRACT_DETAILS,
  )

  return (
    <div className='page-content'>
      <Container fluid className={'p-0 m-0'}>
        <Col className='m-0 p-0'>
          <Row className='m-0 p-0'>
            <h1 style={{ marginBottom: '2rem' }} className='rp-font-gilroyB'>
              Contract
              {!contract?.data?.ref ? null : (
                <span className='text-muted'> ref #{contract?.data?.ref}</span>
              )}
            </h1>
          </Row>

          {contract.isLoading ? (
            <div
              style={{ minHeight: '50vh' }}
              className='d-flex justify-content-center align-items-center'
            >
              <Spinner type='grow' color='primary' />
            </div>
          ) : (
            <Row className={'p-0 m-0'}>
              <Col md={4} className='p-0 m-0'>
                <div>
                  <Card>
                    <CardBody className='m-0  p-4'>
                      <h5 className='col-form-label p-0 m-0 mb-2 font-size-14'>
                        Requester name
                      </h5>
                      <p
                        className={'p-2'}
                        style={{
                          border: '1px solid #E7E8F2',
                          borderRadius: 4,
                          color: '#777F9E',
                        }}
                      >{`${contract?.data?.client?.first_name} ${
                        contract?.data?.client?.middle_name || ''
                      } ${contract?.data?.client?.last_name}`}</p>
                      <h5 className='col-form-label p-0 m-0 mb-2 font-size-14'>
                        Requester email
                      </h5>
                      <p
                        className={'p-2'}
                        style={{
                          border: '1px solid #E7E8F2',
                          borderRadius: 4,
                          color: '#777F9E',
                        }}
                      >
                        {contract?.data?.client?.email}
                      </p>
                      {contract?.data?.company_name && (
                        <>
                          <h5 className='col-form-label p-0 m-0 mb-2 font-size-14'>
                            Company name
                          </h5>
                          <p
                            className={'p-2'}
                            style={{
                              border: '1px solid #E7E8F2',
                              borderRadius: 4,
                              color: '#777F9E',
                            }}
                          >
                            {contract?.data?.company_name}
                          </p>
                        </>
                      )}
                      <h5 className='col-form-label p-0 m-0 mb-2 font-size-14'>
                        Phone number
                      </h5>
                      <p
                        className={'p-2'}
                        style={{
                          border: '1px solid #E7E8F2',
                          borderRadius: 4,
                          color: '#777F9E',
                        }}
                      >
                        {contract?.data?.client?.phone}
                      </p>
                      <h5 className='col-form-label p-0 m-0 mb-2 font-size-14'>
                        Partner
                      </h5>

                      <CustomSelect
                        name={'Parner'}
                        value={partner}
                        onChange={handleAssignPartner}
                        options={
                          requestsPartners?.data
                            ? requestsPartners.data?.map((c) => ({
                                value: c.id,
                                label: c.company_name,
                              }))
                            : []
                        }
                      />
                    </CardBody>
                  </Card>
                  {!hasEditPermission ? null : (
                    <Card
                      className='p-4 align-items-start'
                      style={{ gap: '1rem' }}
                    >
                      <h4 className='rp-font-gilroyB text-gray-h'>
                        Danger actions
                      </h4>
                      {contract?.data?.status?.id === 4 ? (
                        <AvForm
                          onValidSubmit={handleTerminateRequest}
                          className='w-100'
                        >
                          <AvField
                            label='Termination Date:'
                            name='termination_date'
                            type='date'
                            required
                            className='w-100'
                            style={{ maxWidth: 260 }}
                          />
                          <Button
                            type='submit'
                            outline
                            color='danger'
                            loading={isTerminatingContract}
                            disabled={isTerminatingContract}
                            size='sm'
                          >
                            Terminate contract
                          </Button>
                        </AvForm>
                      ) : [6, 7].includes(contract?.data?.status?.id) ? (
                        <div className='text-muted'>No Actions</div>
                      ) : (
                        <Button
                          type='button'
                          outline
                          onClick={handleCancelRequest}
                          color='danger'
                          loading={cancelRequest.isLoading}
                          disabled={cancelRequest.isLoading}
                        >
                          Cancel Request
                        </Button>
                      )}
                    </Card>
                  )}
                  <Card
                    style={{
                      backgroundColor: '#FFFFFF',
                      boxShadow: '0px 1px 0px #DFE1E6',
                      borderRadius: 4,
                      height: 'fit-content',
                    }}
                  >
                    <CardBody className='p-0 m-0'>
                      <div className='flex-row p-3 border-bottom mb-0 p-md-4'>
                        <StyledH5
                          style={{ whiteSpace: 'nowrap' }}
                          min={'16px'}
                          max={'22px'}
                          className=' text-dark font-weight-bold'
                        >
                          Contract Timeline
                        </StyledH5>
                        {contract?.data?.amended ? (
                          <Badge
                            className='rounded font-size-14 bg-soft-warning text-warning'
                            color='white'
                            style={{ padding: '4px 6px' }}
                            pill
                          >
                            Pending amendment
                          </Badge>
                        ) : (
                          <Badge
                            className={`ml-0 rounded font-size-12  bg-soft-${getStatusColor(
                              contract?.data?.status,
                            )} text-${getStatusColor(contract?.data?.status)}`}
                            color='white'
                            pill
                            style={{ padding: '4px 6px' }}
                          >
                            {contract?.data?.status?.name}
                          </Badge>
                        )}
                      </div>

                      <div className='p-3 p-md-4'>
                        <ul className='verti-timeline list-unstyled'>
                          {contract?.data?.timeline?.map((status, key) => (
                            <li key={key} className='event-list'>
                              <div
                                className='event-timeline-dot font-size-24 rounded-circle border bg-white d-flex justify-content-center align-items-center'
                                style={{
                                  marginLeft: '-5px',
                                  width: 24,
                                  height: 24,
                                }}
                              >
                                <i
                                  className={
                                    status.completed === 1
                                      ? ` font-size-10   fas fa-check mt-0 ${
                                          [
                                            'Contract Cancelled',
                                            'Contract Terminated',
                                          ].includes(status.name)
                                            ? 'text-danger'
                                            : 'text-success'
                                        } `
                                      : 'fas fa-times text-danger mt-0 font-size-10'
                                  }
                                />
                              </div>
                              <div className='media'>
                                <div className='media-body'>
                                  <div>
                                    <p
                                      style={{ fontWeight: 'bold' }}
                                      className='mb-1 font-size-14'
                                    >
                                      {status?.name}
                                    </p>
                                    {!!status?.user && (
                                      <>
                                        <span className='mb-1 text-secondary font-size-14'>
                                          by {status.user}
                                        </span>
                                        <br />
                                      </>
                                    )}
                                    <p className=' mb-1   text-secondary font-size-14'>
                                      {status.date
                                        ? moment(status.date * 1000).format(
                                            'DD/MM/YYYY HH:mm',
                                          )
                                        : ' '}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Col>
              <Col md={8}>
                <CardHeader
                  className='border py-3 bg-white rounded-top cursor-pointer hover:bg-light'
                  onClick={() => setCollapseInfo((t) => !t)}
                >
                  <div className='d-flex align-items-center justify-content-between'>
                    <Badge className='bg-soft-primary text-primary font-size-12 p-2'>
                      Step: 1
                    </Badge>
                    <i
                      className={`bx bxs-${
                        collapseInfo ? 'up' : 'down'
                      }-arrow text-dark font-size-10`}
                    />
                  </div>
                </CardHeader>
                <Collapse isOpen={collapseInfo}>
                  <Card className='p-4'>
                    <FullTimeDetailsForm
                      ref={detailsFormRef}
                      initialData={contract?.data}
                      readOnly={!hasEditPermission}
                      onComplete={(v) =>
                        update.startFetch({
                          ...v,
                          contract_id: contract?.data?.id,
                        })
                      }
                      quote={{
                        country_id:
                          contract?.data?.employee?.working_from_country?.id,
                        currency_id: contract?.data?.salary_currency?.id,
                        amount: contract?.data?.amount,
                      }}
                      loading={update.isLoading}
                    />
                    {!hasEditPermission ? null : (
                      <Row className='m-0 p-0 justify-content-end'>
                        <Button
                          onClick={() => {
                            detailsFormRef.current.submit()
                          }}
                          disabled={!hasEditPermission || update.isLoading}
                          loading={update.isLoading}
                        >
                          Update
                        </Button>
                      </Row>
                    )}
                  </Card>
                </Collapse>
                <CardHeader
                  className='border py-3 bg-white cursor-pointer hover:bg-light'
                  onClick={() => setCollapseQuotation((t) => !t)}
                >
                  <div className='d-flex align-items-center justify-content-between'>
                    <Badge className='bg-soft-primary text-primary font-size-12 p-2'>
                      Step: 2
                    </Badge>
                    <i
                      className={`bx bxs-${
                        collapseQuotation ? 'up' : 'down'
                      }-arrow text-dark font-size-10`}
                    />
                  </div>
                </CardHeader>
                <Collapse isOpen={collapseQuotation}>
                  <Card>
                    <QuotationForm
                      initialData={contract?.data?.quote}
                      readOnly={!hasEditPermission}
                      onSubmit={(v) => {
                        updateQuotation.startFetch({
                          ...v,
                          contract_id: contract?.data?.id,
                        })
                      }}
                      loading={updateQuotation.isLoading}
                      currency={contract?.data?.currency?.code}
                      amount={contract?.data?.amount}
                      salaryCurrency={contract?.data?.salary_currency?.code}
                    />
                  </Card>
                </Collapse>
                {!!contract?.data?.quote && (
                  <>
                    <CardHeader className='border py-3 bg-white'>
                      <Badge className='bg-soft-primary text-primary font-size-12 p-2'>
                        Step: 3
                      </Badge>
                    </CardHeader>
                    <Collapse isOpen={true}>
                      <Card className='p-4'>
                        <Container>
                          <Row style={{ gap: '1rem' }}>
                            {(!!contract?.data?.template ||
                              !!contract?.data?.quote) && (
                              <Col className='px-0'>
                                <Dropdown
                                  isOpen={isDownloadOpen}
                                  toggle={() => setIsDownloadOpen((t) => !t)}
                                >
                                  <DropdownToggle tag='div'>
                                    <Button
                                      color='secondary'
                                      block
                                      loading={downloadContract.isLoading}
                                      disabled={downloadContract.isLoading}
                                    >
                                      Download
                                    </Button>
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className='dropdown-menu'
                                    flip={false}
                                  >
                                    {!!contract?.data?.template && (
                                      <DropdownItem onClick={onDownloadMAS}>
                                        SOW
                                      </DropdownItem>
                                    )}
                                    {!!contract?.data?.quote && (
                                      <DropdownItem onClick={onDownloadQuote}>
                                        Quotation
                                      </DropdownItem>
                                    )}
                                  </DropdownMenu>
                                </Dropdown>
                              </Col>
                            )}
                            <Col className='px-0'>
                              <Button
                                block
                                onClick={onUpdateTemplate}
                                disabled={!hasEditPermission}
                              >
                                {contract?.data?.template ? 'Update' : 'Create'}{' '}
                                SOW
                              </Button>
                            </Col>
                          </Row>
                        </Container>
                      </Card>
                    </Collapse>

                    <CardHeader className='d-flex align-items-baseline border rounded-top py-3 bg-white'>
                      <Badge className='bg-soft-primary text-primary font-size-12 p-2'>
                        Step: 4
                      </Badge>
                      {!!contract?.data?.client_invited && (
                        <p className='text-muted m-0 ml-2'>
                          Invited on:{' '}
                          {moment(contract?.data?.client_invited_at).format(
                            'DD/MM/YYYY hh:mm',
                          )}
                        </p>
                      )}
                    </CardHeader>
                    <Collapse isOpen={true}>
                      <Card className='p-4 border rp-rounded-top-0'>
                        <Button
                          block
                          onClick={() => setIsOpenPreview(true)}
                          disabled={!hasEditPermission}
                        >
                          Review &{' '}
                          {contract?.data?.client_invited ? 'Resend' : 'Send'}
                        </Button>
                      </Card>
                    </Collapse>

                    <CardHeader className='border rounded-top py-3 bg-white'>
                      <Badge className='bg-soft-primary text-primary font-size-12 p-2'>
                        Step: 5
                      </Badge>
                    </CardHeader>

                    <Collapse isOpen={true}>
                      <Card className='p-4 border rp-rounded-top-0'>
                        <Container>
                          <Row style={{ gap: '1rem' }}>
                            {!!contract?.data?.employee_signed && (
                              <Col className='px-0'>
                                <Button
                                  type='button'
                                  color='secondary'
                                  block
                                  onClick={onDownloadEmployementContract}
                                  disabled={
                                    downloadEmploymentContract.isLoading
                                  }
                                  loading={downloadEmploymentContract.isLoading}
                                >
                                  Download employment contract
                                </Button>
                              </Col>
                            )}
                            <Col className='px-0'>
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <Button
                                  type='button'
                                  block
                                  disabled={
                                    !hasEditPermission || upload.isLoading
                                  }
                                  loading={upload.isLoading}
                                >
                                  Upload employment contract
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </Card>
                    </Collapse>
                  </>
                )}
              </Col>
            </Row>
          )}
        </Col>

        <Modal
          size='lg'
          centered
          isOpen={isOpenPreview}
          toggle={() => setIsOpenPreview(false)}
        >
          <div
            style={{ backgroundColor: '#F4F5F7' }}
            className='modal-body p-3 p-md-4'
          >
            <iframe
              frameBorder='0'
              id='signatureFrame'
              src={`${process.env.REACT_APP_API_HOST}/api/contract/show/${contract?.data?.ref}/${contract?.data?.file}`}
              title='Contract view'
              width='100%'
              style={{
                backgroundColor: '#fff',
                overflow: 'auto',
                minHeight: '80vh',
              }}
            />
            <button
              className='btn btn-primary btn-block mb-5'
              onClick={onInviteClient}
            >
              {invite.isLoading && (
                <i className='bx bx-loader bx-spin font-size-16 align-middle mr-2' />
              )}
              Review & Send
            </button>
          </div>
        </Modal>
      </Container>
    </div>
  )
}

export default RequestDetails
