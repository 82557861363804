import React from 'react'
import propTypes from 'prop-types'
import { Col, Label, Modal, Row, Spinner } from 'reactstrap'
import { useFetch } from '../../../helpers/hooks'
import { getBankAccountDetails } from '../../../services/api'

const BankDetailsModal = ({ isOpen, toggle, accountId }) => {
  const details = useFetch({
    action: getBankAccountDetails,
    body: { account_id: accountId },
    autoFetch: true,
  })
  return (
    <Modal isOpen={isOpen} scrollable centered toggle={toggle}>
      <div className='modal-header'>
        <h5 className='modal-title mt-0' id='myLargeModalLabel'>
          Bank account details
        </h5>
        <button
          onClick={toggle}
          type='button'
          className='close'
          data-dismiss='modal'
          aria-label='Close'
        >
          <span aria-hidden='true'>&times;</span>
        </button>
      </div>
      <div className='modal-body p-4 '>
        {details.isLoading ? (
          <Col style={{ minHeight: '10rem' }}>
            <Row
              style={{ minHeight: '10rem' }}
              className='justify-content-center align-items-center'
            >
              <Spinner type='grow' className='mr-2' color='primary' />
            </Row>
          </Col>
        ) : (
          <div>
            {details.data?.map((e, i) => (
              <Col key={`bankAccountInfo-${i}`} className='p-0'>
                <Label className='col-form-label pt-0 font-weight-light'>
                  {e.name}
                </Label>
                <div
                  className='form-control mb-2'
                  id='bankAccountInfo_Beneficiary'
                >
                  {e.value}
                </div>
              </Col>
            ))}
          </div>
        )}
      </div>
    </Modal>
  )
}

BankDetailsModal.propTypes = {
  isOpen: propTypes.bool,
  toggle: propTypes.func.isRequired,
  accountId: propTypes.number.isRequired,
}

export default BankDetailsModal
