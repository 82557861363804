import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Dropzone, { useDropzone } from 'react-dropzone'
import { useSelector } from 'react-redux'
import { Card, Col, Row, Spinner, Table } from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'

import ActionsDropdown from '../../components/ActionsDropdown'
import Avatar from '../../components/Avatar'
import CustomSelect from '../../components/Forms/CustomSelect/CustomSelect'
import NoContent from '../../components/NoContent'
import SearchBar from '../../components/SearchBar'
import { userTypes } from '../../helpers/enum'
import { useFetch, useResize } from '../../helpers/hooks'
import {
  downloadAllInvoices,
  downloadInvoice,
  uploadYourInvoice,
} from '../../services/api'
import InvoiceCard from './components/InvoiceCard'

import noInvoices from '../../assets/images/noinvoices.svg'
import Button from '../../components/ui/button'

const InvoicesList = ({
  Orders,
  dataLoading,
  selectable,
  onInvoiceSelected,
  currency,
  recent,
  months,
  month,
  onMonthChanged,
  isAdmin,
}) => {
  const user = useSelector((state) => state?.Account?.user)
  const [expanded, setExpanded] = useState(-1)
  const [data, setData] = useState(Orders || [])
  const [loading, setLoading] = useState(false)
  const isMobile = useResize()
  const [query, setQuery] = useState(null)

  const handleInvoiceChecked = (i) => {
    onInvoiceSelected(i)
  }
  const handleDownloadInvoice = (invoice) => {
    window.analytics.track('Clicked download invoice', {
      invoice_id: invoice?.invoice_ref,
    })

    downloadInvoice(invoice.invoice_ref, user?.token, invoice.token).then(
      (r) => {
        if (r.data?.data?.error || r.data?.message) {
          toastr.error(
            r.data?.data?.error || r.data?.message || 'An error occurred',
          )
          return
        }
        const url = window.URL.createObjectURL(new Blob([r.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${invoice?.invoice_ref}.pdf`) // or any other extension
        document.body.appendChild(link)
        link.click()
      },
    )
  }

  const downloadAll = (invoices) => {
    setLoading(true)

    const allRef = invoices.map((invoice, i) => invoice.invoice_ref)

    downloadAllInvoices(user?.token, { refs: allRef })
      .then((r) => {
        if (r.data?.data?.error || r.data?.message) {
          toastr.error(
            r.data?.data?.error || r.data?.message || 'An error occurred',
          )
          return
        }
        const url = window.URL.createObjectURL(new Blob([r.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'AllInvoices.zip')
        document.body.appendChild(link)
        link.click()
      })
      .catch(() => {
        toastr.error('Error while downloading invoices')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    window.analytics.track('Viewed invoices', { email_id: user?.email })
  }, [])

  useEffect(() => {
    setData(Orders)
  }, [Orders])

  const handleSearch = (query) => {
    setQuery(query)
    if (!query || query === '') {
      setData(Orders)
      return
    }

    const newData = data.filter((e) => {
      const secondPart =
        user?.type === userTypes.COMPANY ? e?.contractor : e?.client
      return (
        e?.invoice_ref?.toUpperCase()?.includes(query?.toUpperCase()) ||
        e?.contract_ref?.toUpperCase()?.includes(query?.toUpperCase()) ||
        secondPart?.first_name?.toUpperCase()?.includes(query?.toUpperCase()) ||
        secondPart?.last_name?.toUpperCase()?.includes(query)
      )
    })
    if (newData.length) {
      setData(newData)
    }
  }

  return !dataLoading && Orders?.length === 0 ? (
    <div>
      <NoContent
        headline='No Invoices'
        subtitle='All your invoices will be shown here after each payment'
        image={noInvoices}
      />
    </div>
  ) : (
    <Row className={'p-0 m-0'}>
      <Col className={'p-0 m-0'} xs='12'>
        {!selectable && !recent && (
          <Row className='mb-2'>
            <Col sm='4' />
            <Col sm='8'></Col>
          </Row>
        )}

        {recent && <h4 className='mb-3 font-size-18'>Recent invoices</h4>}

        {isMobile ? (
          <div>
            <Row className='p-3'>
              <Col className='px-0' xs={5}>
                <CustomSelect
                  options={months}
                  value={month}
                  onChange={onMonthChanged}
                  withSearch
                  searchable={false}
                  classNamePrefix='with-search'
                />
              </Col>
              <Col className='p-0' xs={7}>
                <SearchBar
                  query={query}
                  withFilter
                  onQueryChanged={handleSearch}
                  className='mr-md-2'
                />
              </Col>

              <Col xs={6} className='mb-2 p-1'>
                <button
                  className='btn btn-primary btn-block'
                  onClick={() => downloadAll(data)}
                >
                  {loading && (
                    <i className='bx bx-loader bx-spin text-white font-size-14 align-middle mr-2' />
                  )}
                  Download all invoices
                </button>
              </Col>
            </Row>

            {dataLoading ? (
              <div
                className='d-flex justify-content-center align-items-center'
                style={{ minHeight: '20rem' }}
              >
                <Spinner type='grow' color='primary' />
              </div>
            ) : (
              <Col
                className='p-3'
                style={{
                  backgroundColor: '#FFFFFF',
                  boxShadow: '0px 1px 0px #DFE1E6',
                  borderRadius: '4px',
                }}
              >
                {Orders.map((order, key) => (
                  <InvoiceCard
                    handleDownloadInvoice={handleDownloadInvoice}
                    key={'_order_' + key}
                    invoice={order}
                  />
                ))}
              </Col>
            )}
          </div>
        ) : (
          <Card>
            <StyledTableCont>
              {!isAdmin && (
                <Row className='mb-3 mx-0 p-4'>
                  <Col className='p-0' sm={3}>
                    <CustomSelect
                      options={months}
                      value={month}
                      onChange={onMonthChanged}
                      withSearch
                      searchable={false}
                      classNamePrefix='with-search'
                    />
                  </Col>
                  <Col className='p-0'>
                    <SearchBar
                      query={query}
                      withFilter
                      onQueryChanged={handleSearch}
                      className='mr-md-2'
                    />
                  </Col>

                  <div className='d-flex' style={{ gap: '0.5rem' }}>
                    {user?.type === userTypes.COMPANY && (
                      <Button
                        onClick={() => downloadAll(data)}
                        disabled={loading}
                        loading={loading}
                      >
                        Download invoices
                      </Button>
                    )}
                  </div>
                </Row>
              )}

              {dataLoading ? (
                <div
                  className='d-flex justify-content-center align-items-center'
                  style={{ minHeight: '20rem' }}
                >
                  <Spinner type='grow' color='primary' />
                </div>
              ) : (
                <div className='table-with-dropdown'>
                  <div className='table-responsive'>
                    <Table className='table table-centered table-nowrap  mb-0'>
                      <thead className='thead-light'>
                        <tr
                          style={{
                            borderTop: 'hidden',
                            borderBottom: '1px solid #E7E8F2',
                          }}
                        >
                          <th
                            style={{ fontWeight: '500' }}
                            className='text-dark font-size-14   px-4 py-3'
                          />
                          <th
                            style={{ fontWeight: '500', width: '200 px' }}
                            className='text-dark font-size-14   px-4 py-3'
                          >
                            Invoice
                          </th>
                          {isAdmin ? null : (
                            <th
                              style={{ fontWeight: '500' }}
                              className='text-dark font-size-14   px-4 py-3'
                            >
                              {user?.type === 'client'
                                ? 'Contractor'
                                : 'Client'}
                            </th>
                          )}
                          <th
                            style={{ fontWeight: '500' }}
                            className='text-dark font-size-14   px-4 py-3'
                          >
                            Total
                          </th>
                          <th
                            style={{ fontWeight: '500' }}
                            className='text-dark font-size-14   px-4 py-3'
                          >
                            Invoice Date
                          </th>
                          <th style={{ fontWeight: '500' }} />
                          {user?.type === userTypes.CONTRACTOR && (
                            <th
                              style={{ fontWeight: '500' }}
                              className='text-dark font-size-14   px-4 py-3'
                            />
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((invoice, key) => (
                          <InvoiceLine
                            invoice={invoice}
                            key={'_order_' + key}
                            isAdmin={isAdmin}
                            onCheck={() => {
                              handleInvoiceChecked(key)
                            }}
                            currency={currency}
                            selectable={selectable}
                            onExpand={() => {
                              if (expanded === key) {
                                setExpanded(-1)
                              } else {
                                setExpanded(key)
                              }
                            }}
                            expanded={expanded === key}
                            handleDownload={() =>
                              handleDownloadInvoice(invoice)
                            }
                          />
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              )}
            </StyledTableCont>
          </Card>
        )}
      </Col>
    </Row>
  )
}

const InvoiceLine = ({
  invoice,
  onExpand,
  currency,
  selectable,
  onCheck,
  expanded,
  handleDownload,
  isAdmin,
}) => {
  const user = useSelector((state) => state?.Account?.user)
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: invoice?.currency?.code || 'USD',
  })
  const partner = user?.type === 'client' ? invoice.contractor : invoice.client

  const [show, setShow] = useState(false)

  const upload = useFetch({
    action: uploadYourInvoice,
    onError: (err) => toastr.error(err),
    onComplete: () => {
      toastr.success('Invoice replaced successfully')
    },
  })

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  })

  const actions = []

  useEffect(() => {
    if (acceptedFiles[0]) {
      upload.startFetch({
        invoice_id: invoice?.id,
        file: acceptedFiles[0],
      })
    }
  }, [acceptedFiles])

  if (!selectable) {
    actions[0] = {
      // eslint-disable-next-line react/display-name
      component: () => (
        <div className='text-dark font-size-14   d-flex align-items-center'>
          <p className='mb-0'>Download</p>
        </div>
      ),
      onClick: () => {
        handleDownload()
      },
    }
  }
  if (user?.type === userTypes.CONTRACTOR) {
    actions[1] = {
      // eslint-disable-next-line react/display-name
      component: () => (
        <div className='text-dark font-size-14   d-flex align-items-center'>
          <p className='mb-0'>Replace</p>
        </div>
      ),
      onClick: () => {
        open()
        setTimeout(() => {
          setShow(true)
        }, 200)
      },
    }
  }

  const invoicePartner =
    user?.type === userTypes.CONTRACTOR
      ? invoice.company_name
      : [partner?.first_name, partner?.last_name].filter(Boolean).join(' ')

  return (
    <>
      <tr style={{ borderBottom: '1px solid #E7E8F2' }}>
        <th className='text-center px-4'>
          {invoice?.works?.length !== 0 && (
            <a onClick={onExpand}>
              <i className='bx bx-plus font-size-12 text-dark' />
            </a>
          )}
        </th>
        <td
          className='text-dark font-size-14 p-4'
          style={{ fontWeight: '400' }}
        >
          {invoice.invoice_ref}
        </td>
        {isAdmin ? null : (
          <td
            className='text-dark font-size-14 p-4'
            style={{ fontWeight: '400' }}
          >
            <div className='d-flex align-items-center flex-nowrap'>
              <Avatar
                name={
                  invoice?.type === 'fee'
                    ? 'RemotePass'
                    : user?.type === userTypes.CONTRACTOR
                    ? invoice?.company_name
                    : partner?.first_name
                }
                photo={
                  user?.type === userTypes.CONTRACTOR
                    ? invoice.company_logo
                    : partner?.photo
                }
                flag={user?.type === userTypes.COMPANY && partner?.flag}
              />
              <div
                className='font-size-14 ml-2 rp-font-bold text-gray-h'
                translate='no'
              >
                {invoice?.type === 'fee'
                  ? 'RemotePass'
                  : invoicePartner?.length > 17
                  ? invoicePartner?.slice(0, 17) + '...'
                  : invoicePartner || ''}
              </div>
            </div>
          </td>
        )}
        <td
          className='text-dark font-size-14 p-4'
          style={{ fontWeight: '400' }}
        >
          {formatter.format(invoice?.total)}
        </td>
        <td
          className='text-dark font-size-14 px-4 py-3 '
          style={{ fontWeight: '400' }}
        >
          {moment(invoice.invoice_date * 1000).format('MM/DD/YYYY HH:mm')}
        </td>
        {selectable && (
          <td
            className='text-dark font-size-14  px-4 py-3'
            style={{ fontWeight: '400' }}
          >
            <Col>
              <Row className='justify-content-center'>
                <div className='custom-control custom-switch mb-2' dir='ltr'>
                  <input
                    type='checkbox'
                    className='custom-control-input'
                    disabled={currency && currency !== invoice.currency}
                    id={`invoiceToPay${invoice?.id}`}
                    checked={invoice.selected}
                    onClick={onCheck}
                  />
                  <label
                    className='custom-control-label'
                    htmlFor={`invoiceToPay${invoice?.id}`}
                  />
                </div>
              </Row>
            </Col>
          </td>
        )}
        <td
          className='text-dark font-size-14 px-4 py-3 text-right'
          style={{ fontWeight: '400' }}
        >
          {actions?.length > 0 && (
            <ActionsDropdown
              isOpen={show}
              toggle={() => {
                setTimeout(() => {
                  setShow((t) => !t)
                }, 200)
              }}
              options={actions}
            />
          )}
        </td>
        {/* {user?.type === userTypes.CONTRACTOR && <td>

        </td>} */}
      </tr>
      <div className='container d-none'>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
        </div>
      </div>
      {expanded && (
        <>
          {invoice?.works?.map((t) => {
            return (
              <tr
                style={{
                  backgroundColor: '#F4F5F7',
                  borderLeft: '7px solid  var(--primary) ',
                  borderBottom: '2px solid #eee',
                }}
                className='font-weight-light font-size-12 b  '
                key={'_trans' + t?.id}
              >
                <th
                  style={{ fontWeight: '500' }}
                  className='text-dark font-size-14   px-4 py-4'
                >
                  {''}
                </th>
                <td
                  colSpan={2}
                  style={{ width: '200px' }}
                  className='text-dark font-size-14 px-4 py-4'
                >
                  <p className={'font-weight-bold'}> {t?.name}</p>

                  <p style={{ whiteSpace: 'pre-line' }}>{t?.details}</p>
                  <br />
                  {t.attributes?.map((a) => (
                    <div key={`attr-${a?.name}`}>
                      <span className='text-muted'>{a?.name}: </span>
                      <span>{a?.value}</span>
                      <br />
                    </div>
                  ))}
                </td>
                <td
                  style={{ fontWeight: '500' }}
                  className='text-dark font-size-14   px-4 py-4'
                >
                  <p className={'mb-4'}> {formatter.format(t?.amount)}</p>
                </td>
                {/* <th >
                  {''}
                </th> */}

                <td
                  style={{ fontWeight: '500' }}
                  className='text-dark font-size-14 px-4 py-4'
                >
                  {''}
                </td>
                <td
                  style={{ fontWeight: '500' }}
                  className='text-dark font-size-14 px-4 py-4'
                >
                  {''}
                </td>
              </tr>
            )
          })}
        </>
      )}
    </>
  )
}

export const UploadArea = ({ onFilesDropped, onFileChanges, children }) => {
  return (
    <Dropzone onDrop={onFilesDropped}>
      {({ getRootProps, getInputProps }) => (
        <div
          style={{
            zIndex: 99999999999,
          }}
        >
          <div {...getRootProps()} className='no-outline'>
            <input
              className='no-outline'
              {...getInputProps()}
              onChange={async (e) => {
                onFileChanges(e.target.files)
              }}
            />
            {children}
          </div>
        </div>
      )}
    </Dropzone>
  )
}

const StyledTableCont = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 0px #dfe1e6;
  border-radius: 4px;
`

export default InvoicesList
