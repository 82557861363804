import React, { useEffect, useState } from 'react'
import { Container, Row, TabContent, TabPane } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import ModalHeader from '../../components/ModalHeader'
import Steps from '../../components/Steps'
import StepContainer from '../../components/Steps/StepContainer'
import Col from 'reactstrap/lib/Col'
import { useFetch, useResize } from '../../helpers/hooks'
import { getDocumentsUS, getTaxConditions } from '../../services/api'
import Wootric from '../../components/Wootric/Wootric'
import { useHistory } from 'react-router'
import WiseButton from '../../components/WiseButton'
import NoContent from '../../components/NoContent'
import GenerateTaxForm from './components/GenerateTaxForm'
import { StyledH5 } from '../../components/Typo'
import generateTaxImage from './../../assets/images/generateTax.svg'

const CreateDoc = () => {
  const [activeTab, setActiveTab] = useState(0)
  const isMobile = useResize()
  const [completed] = useState(false)
  const history = useHistory()
  const inputs = useFetch({
    action: getDocumentsUS,
  })
  const taxConditions = useFetch({
    action: getTaxConditions,
  })
  useEffect(() => {
    taxConditions.startFetch()
    inputs.startFetch()
  }, [])

  return (
    <div className='page-content min-vh-100 p-0 m-0'>
      <Container fluid>
        <ModalHeader>
          {!isMobile && (
            <Steps
              activeTab={activeTab === 1 ? 2 : activeTab}
              data={['Tax form', '', 'Review & Sign']}
            />
          )}
        </ModalHeader>
        <Row className='justify-content-center'>
          <Col md={8}>
            <TabContent
              activeTab={activeTab}
              className='twitter-bs-wizard-tab-content'
            >
              <TabPane tabId={0}>
                <StepContainer
                  title='Tax form'
                  nextText='Proceed'
                  width='40vw'
                  index={0}
                  total={2}
                  onNext={() => {
                    setActiveTab(activeTab + 1)
                  }}
                  onBack={() => setActiveTab(activeTab - 1)}
                >
                  {taxConditions && (
                    <div style={{ width: '100%', paddingTop: 10 }}>
                      <StyledH5
                        style={{
                          textAlign: isMobile ? 'start' : 'center',
                          marginLeft: isMobile ? '5%' : '0%',
                        }}
                      >
                        {taxConditions?.data?.title}
                      </StyledH5>

                      <p
                        style={{
                          marginTop: '5%',
                          marginLeft: '5%',
                          color: '#777F9E',
                        }}
                      >
                        {taxConditions?.data?.text}
                      </p>
                    </div>
                  )}
                </StepContainer>
              </TabPane>
              {activeTab === 1 && (
                <TabPane tabId={1}>
                  <StepContainer
                    minHeight={'50vh'}
                    title={'Review & Sign'}
                    noFooter
                    index={1}
                    total={2}
                    onNext={() => {}}
                    onBack={() => setActiveTab(activeTab - 1)}
                  >
                    {inputs && inputs?.data?.fields?.length > 0 && (
                      <GenerateTaxForm
                        fields={inputs?.data}
                        onFinish={() => setActiveTab(activeTab + 1)}
                      />
                    )}
                  </StepContainer>
                </TabPane>
              )}
              <TabPane tabId={2}>
                <NoContent
                  headline={'Form generated'}
                  subtitle={
                    'Your tax form has been generated successfully and shared with you client. You can download it under the documents page.'
                  }
                  image={generateTaxImage}
                  withAction={true}
                  customBottom={
                    <WiseButton
                      onClick={() => history.push('/documents')}
                      text={'Back to Documents '}
                      bold
                      minHeight={'48px'}
                      minWidth={'100%'}
                    />
                  }
                />
              </TabPane>
            </TabContent>
            {completed && <Wootric />}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default withTranslation()(CreateDoc)
