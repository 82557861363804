import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import toastr from 'toastr'

import invoicePdfImage from '../../../../assets/images/Invoice-pdf.png'
import invoiceImage from '../../../../assets/images/Invoice.png'
import UploadPreview from '../../../../components/Common/upload-preview'
import { StyledH2, StyledH4 } from '../../../../components/Typo'
import WiseButton from '../../../../components/WiseButton'
import { useFetch } from '../../../../helpers/hooks'
import { parseInvoiceV2 } from '../../../../services/api'

class Paragraph {
  constructor(node, globalText) {
    this.node = node
    this.globalText = globalText
  }

  get text() {
    if (
      !this.node.layout?.textAnchor.textSegments ||
      this.node.layout?.textAnchor.textSegments.length === 0
    ) {
      return ''
    }

    // First shard in document doesn't have startIndex property
    const startIndex =
      this.node.layout?.textAnchor.textSegments[0].startIndex || 0
    const endIndex = this.node.layout?.textAnchor.textSegments[0].endIndex

    return this.globalText.substring(startIndex, endIndex)
  }
}

const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const InvoiceParser = ({ onParsed, onSelect, onPdfUploaded = () => {} }) => {
  // const ref = useRef(null)
  const [preview, setPreview] = useState(null)
  const [dimensions] = useState({})

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: 'application/pdf',
    onDrop: (acceptedFiles) => {
      const firstFile = acceptedFiles[0]

      toBase64(firstFile).then((file) => {
        const fileData = file.split(',')[1]
        onPdfUploaded(fileData)
        setPreview({ data: file, type: firstFile.type })

        parseInvoice({ file: fileData })
      })
    },
  })

  const {
    startFetch: parseInvoice,
    isLoading: isParsingInvoice,
    data: parsedInvoiceData,
  } = useFetch({
    action: parseInvoiceV2,
    onError: (err) => toastr?.error(err),
    onComplete: onParsed,
  })

  useEffect(() => {
    if (!acceptedFiles || acceptedFiles.length <= 0) {
      onPdfUploaded(null)
    }
  }, [acceptedFiles, onPdfUploaded])

  const handleSelectText = (e) => {
    const p = new Paragraph(e, parsedInvoiceData?.text)
    onSelect(p.text)
  }

  const isLoading =
    !!parsedInvoiceData && !isParsingInvoice && !isParsingInvoice

  return (
    <section className='p-0' style={{ height: '83%' }}>
      <StyledH2 min={24} max={24} className='text-dark mb-3 font-weight-bold'>
        Upload Invoice
      </StyledH2>
      <StyledH4
        className='text-secondary font-weight-normal mb-3'
        min={'12px'}
        max={'14px'}
      >
        Upload your invoice pdf and data will be extracted automatically.
      </StyledH4>
      <div
        className={cx('dropzone d-flex justify-content-center', {
          'border-primary': !isLoading,
          'bg-soft-primary': !isLoading,
          'border-0': isLoading,
        })}
        style={{ height: '100%' }}
      >
        <div
          className='justify-content-center p-0 scan-invoice-input'
          style={{ minHeight: '272px' }}
          {...getRootProps()}
        >
          <input
            className='scan-invoice-input'
            {...getInputProps()}
            onClick={(e) => {
              if (parsedInvoiceData) e.preventDefault()
            }}
          />
          {isLoading ? (
            <div
              className='position-relative h-100'
              style={{ minHeight: '272px' }}
            >
              <div className='p-4 h-100 border border-light rounded-sm'>
                {!parsedInvoiceData?.paragraphs ||
                parsedInvoiceData?.paragraphs?.length <= 0 ? null : (
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      zIndex: 1,
                    }}
                  >
                    {parsedInvoiceData?.paragraphs?.map((e, i) => {
                      return (
                        <Property
                          onPress={() => handleSelectText(e)}
                          key={i}
                          e={e}
                          width={dimensions.width}
                          height={dimensions.height}
                        />
                      )
                    })}
                  </div>
                )}

                <UploadPreview preview={preview} />

                {/* <img
                  alt=''
                  ref={ref}
                  onLoad={() => {
                    setDimensions({
                      width: ref.current?.clientWidth,
                      height: ref.current?.clientHeight,
                    })
                  }}
                  src={`data:${
                    parsedInvoiceData?.image?.mimeType
                  };base64,${arrayBufferToBase64(
                    parsedInvoiceData?.image?.content?.data,
                  )}`}
                /> */}
              </div>
            </div>
          ) : (
            <div
              className='d-flex flex-column justify-content-center align-items-center scan-invoice-input'
              style={{ height: '100%', minHeight: '272px' }}
            >
              <div
                className='d-flex justify-content-center mb-3 position-relative'
                style={{ width: '9rem', height: '9rem' }}
              >
                <img
                  alt=''
                  className='position-absolute'
                  src={
                    isParsingInvoice || isParsingInvoice
                      ? invoicePdfImage
                      : invoiceImage
                  }
                  style={{ width: '5rem', height: 'auto', top: '2rem' }}
                />
                {(isParsingInvoice || isParsingInvoice) && (
                  <div
                    className='spinner-border text-primary'
                    style={{ width: '9rem', height: '9rem' }}
                    role='status'
                  >
                    <span className='sr-only'>Loading...</span>
                  </div>
                )}
              </div>

              <div>
                <h5 className='text-center font-size-16'>
                  Drop your invoice here
                </h5>
                <h4
                  className='text-secondary text-center font-size-12 font-weight-normal'
                  style={{ maxWidth: '30ch' }}
                >
                  Drag and drop your invoice pdf here, Or browse your computer
                </h4>
                <div className='pt-3 text-center justify-content-center d-flex'>
                  {isParsingInvoice || isParsingInvoice ? null : (
                    <WiseButton
                      text={'Browse Files'}
                      bold
                      minHeight={'48px'}
                      minWidth={'100%'}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
const Placeholder = styled.a`
  &:hover {
    border-color: #1a2f78;
    border-style: dashed;
    border-width: 1px;
  }
`

const Property = ({ e, width, height, onPress }) => {
  const pointLeftTop = { ...e?.layout?.boundingPoly?.normalizedVertices[0] }
  const pointRightBottom = { ...e?.layout?.boundingPoly?.normalizedVertices[2] }

  return (
    <Placeholder
      onClick={() => {
        onPress(e)
      }}
      style={{
        left: pointLeftTop?.x * width - 2,
        top: pointLeftTop?.y * height - 2,
        width: (pointRightBottom?.x - pointLeftTop?.x) * width + 4,
        height: (pointRightBottom?.y - pointLeftTop?.y) * height + 4,
        position: 'absolute',
      }}
    />
  )
}
export default InvoiceParser
