import { Button as BsButton } from 'reactstrap'
import React from 'react'
import cx from 'classnames'

export default function Button(props) {
  const {
    icon,
    iconRight,
    text,
    loading,
    onClick,
    children,
    className,
    formId,
    color = 'primary',
    alignLeft,
    ...btnProps
  } = props

  return (
    <BsButton
      onClick={onClick}
      color={color}
      className={cx(
        'd-inline-flex align-items-center',
        alignLeft ? 'justify-content-start' : 'justify-content-center',
        { rounded: ['sm', 'xs'].includes(btnProps?.size) },
        className,
      )}
      {...btnProps}
      style={{
        gap: '0.5rem',
        padding: btnProps?.size !== 'xs' ? null : '0.125rem 0.325rem',
        ...btnProps?.style,
      }}
      form={formId}
    >
      {!loading ? icon : <i className='bx bx-loader bx-spin font-size-14' />}

      {!text && !children ? null : (
        <span style={{ whiteSpace: 'nowrap', fontWeight: 500 }}>
          {text ?? children}
        </span>
      )}

      {iconRight ?? null}
    </BsButton>
  )
}
