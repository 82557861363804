import React from 'react'
import Col from 'reactstrap/lib/Col'
import Row from 'reactstrap/lib/Row'
import styled from 'styled-components'

import bankAccountChecked from '../../../assets/images/cash.svg'
import bankAccount from '../../../assets/images/cashGray.svg'
import { useFetch } from '../../../helpers/hooks'
import { deleteBankAccount } from '../../../services/api'
import { track } from '../../../utils/analytics'
import EVENTS from '../../../utils/events'

const CashPointCard = ({
  withTag,
  withRemove,
  card,
  onUpdate,
  selected,
  onSelect,
  onClick = () => {},
  checked,
}) => {
  let result = []

  let details = ''
  const address = ''
  try {
    details = JSON.parse(card.details)
    result = JSON.parse(details)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
  }

  const deleteCard = useFetch({
    action: deleteBankAccount,
    onComplete: () => {
      onUpdate()
      track(EVENTS.REMOVED_WITHDRAW_METHODS, {
        withdraw_account_name: card.holder_name,
        withdraw_method: 'Bank Transfer',
        currency: card?.currency,
        country: address?.country,
        is_default: false,
      })
    },
  })

  return (
    <StyledCard
      className={`${checked ? 'border-primary' : ''}`}
      onClick={() => onClick(card)}
    >
      <StyledTrash>
        {withRemove && (
          <>
            {deleteCard.isLoading ? (
              <i className='bx bx-loader bx-spin text-danger font-size-14 align-middle ml-2' />
            ) : (
              <button
                type='button'
                className='rp-btn-nostyle d-flex p-1 text-muted font-size-16'
                onClick={() => {
                  deleteCard.startFetch({ id: card?.id })
                }}
              >
                <i className='bx bxs-trash-alt' />
              </button>
            )}
          </>
        )}
      </StyledTrash>
      <Row className='p-md-3  p-3 d-flex m-0'>
        <Col xs={12} md={2} className='iconi p-0 m-0'>
          <div className='position-relative'>
            <img
              className={'mb-3 mb-md-0 mt-md-2'}
              src={checked ? bankAccountChecked : bankAccount}
              alt='walet icon'
              style={{ width: '2rem' }}
            />
          </div>
        </Col>

        <Col xs={12} md={10} className={'p-0 m-0'}>
          <h5
            className={`${
              checked ? 'text-primary' : 'text-dark'
            } mb-2 font-size-16`}
            style={{ fontFamily: 'gilroyB' }}
          >
            {'' + result[0]?.value}
          </h5>
          <div className='d-flex flex-row align-items-center'>
            <div
              className={`${
                checked ? 'text-primary' : 'text-secondary '
              } font-size-14 mb-2`}
            >
              {result[3]?.value}
            </div>
          </div>
          {withTag && (
            <button
              onClick={onSelect}
              className={`btn rounded-sm font-size-10 ${
                selected ? 'btn-primary' : 'btn-outline-light'
              } py-0 px-1 font-weight-normal`}
            >
              {selected ? 'Default' : 'Make default'}
            </button>
          )}
        </Col>
      </Row>
    </StyledCard>
  )
}

const StyledCard = styled.div`
  position: relative;
  background-color: #ffffff;
  border: 1px solid #e7e8f2;
  border-radius: 4px;

  @media (max-width: 768px) {
    margin-bottom: 16px;
  }
`

const StyledTrash = styled.div`
  z-index: 9;
  position: absolute;
  top: 12px;
  right: 12px;
`

export default CashPointCard
