import React, { useState } from 'react'
import { Button, Card, Col, Container, Row, Spinner, Table } from 'reactstrap'
import Paginations from 'react-js-pagination'
import toastr from 'toastr'

import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import {
  downloadInvoice,
  getAdminInsurancePaymentList,
} from '../../../../services/api'
import { useFetch } from '../../../../helpers/hooks'
import StyledTh from '../../../../components/Table/StyledTh'
import { Line } from '../../../Insurance/components/InsurancePayments'
import SearchBar from '../../../../components/SearchBar'
import { BurgerDetails } from '../Users/users'
import { useSelector } from 'react-redux'

const status = [
  { label: 'Not Paid', value: '1' },
  { label: 'Processed', value: '2' },
]

const PaymentsList = () => {
  const loginToken = useSelector((state) => state.Login?.loginToken)
  const defaultState = { label: 'Select Status', value: undefined }
  const [activePage, setActivePage] = useState(1)
  const [query, setQuery] = useState(null)
  const [state, setState] = useState(defaultState)
  const [selectedUserId, setSelectedUserId] = useState(null)
  const [show, setShow] = useState(false)

  const payments = useFetch(
    {
      action: getAdminInsurancePaymentList,
      withAdminAccess: true,
      autoFetch: true,
      body: { page: activePage, status_id: state.value, search: query },
    },
    [activePage, state, query],
  )

  const handlePageChange = (page) => {
    setActivePage(page)
  }
  const handleSearch = (query) => {
    setActivePage(1)
    setQuery(query)
  }
  const clearFilter = () => {
    setActivePage(1)
    setQuery('')
    setState(defaultState)
  }

  const handleDownloadInvoice = (item) => {
    downloadInvoice(item.invoice_ref, loginToken, item.invoice_token).then(
      (r) => {
        if (r.data?.data?.error || r.data?.message) {
          toastr.error(
            r.data?.data?.error || r.data?.message || 'An error occurred',
          )
          return
        }
        const url = window.URL.createObjectURL(new Blob([r.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${item?.invoice_ref}.pdf`) // or any other extension
        document.body.appendChild(link)
        link.click()
      },
    )
  }

  return (
    <div className='page-content'>
      <BurgerDetails
        id={selectedUserId}
        hide={() => setShow(false)}
        show={show}
      />
      <Container fluid className='p-0 m-0'>
        <h1 className='rp-font-gilroyB' style={{ marginBottom: '2rem' }}>
          Insurance payments
        </h1>

        <Card>
          <Container>
            <Row className='p-3' style={{ gap: '1rem' }}>
              <Col md={4} xs={12} className='px-0'>
                <CustomSelect
                  options={
                    Array.isArray(status)
                      ? [
                          defaultState,
                          ...(status?.map((e) => ({
                            label: e.label,
                            value: e.value,
                          })) ?? []),
                        ]
                      : [defaultState]
                  }
                  value={state}
                  onChange={setState}
                />
              </Col>

              <Col md={5} xs={12} className='px-0'>
                <SearchBar
                  query={query}
                  placeholder={'Search by name or email'}
                  onQueryChanged={handleSearch}
                  className='mr-md-2'
                />
              </Col>

              <Col className='px-0'>
                <Button color='primary' onClick={clearFilter}>
                  Clear Filter
                </Button>
              </Col>
            </Row>
          </Container>

          {payments.isLoading ? (
            <div
              className='d-flex justify-content-center align-items-center flex-grow-1'
              style={{ minHeight: 250 }}
            >
              <Spinner type='grow' className='mr-2' color='primary' />
            </div>
          ) : (
            <>
              <div className='table-responsive p-0 m-0'>
                <Table className='table table-centered table-nowrap text-muted'>
                  <thead className='thead-light'>
                    <tr>
                      <StyledTh>Payment ID</StyledTh>
                      <StyledTh>User</StyledTh>
                      <StyledTh>Period</StyledTh>
                      <StyledTh>Status</StyledTh>
                      <StyledTh>Amount</StyledTh>
                      <StyledTh>Actions</StyledTh>
                    </tr>
                  </thead>
                  <tbody>
                    {payments.data?.map((payment, key) => (
                      <Line
                        item={payment}
                        key={key}
                        onUpdate={clearFilter}
                        isAdmin
                        onUserClick={(id) => {
                          setSelectedUserId(id)
                          setShow(true)
                        }}
                        downloadInvoice={handleDownloadInvoice}
                      />
                    ))}
                  </tbody>
                </Table>
              </div>
              <div className='d-flex justify-content-end px-3'>
                <Paginations
                  itemClass='page-item'
                  linkClass='page-link'
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={payments.paginator?.total}
                  onChange={handlePageChange}
                />
              </div>
            </>
          )}
        </Card>
      </Container>
    </div>
  )
}

export default PaymentsList
