import React from 'react'
import { Link } from 'react-router-dom'

const ContractRef = ({ contractId, isAdmin = true }) => {
  return (
    <Link
      className='text-primary font-size-14'
      style={{ fontWeight: '400' }}
      to={
        isAdmin
          ? `/admin/contract-detail?id=${contractId}`
          : `/contract/detail?id=${contractId}`
      }
      target='_blank'
    >
      {contractId}
    </Link>
  )
}

export default ContractRef
