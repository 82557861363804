import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'

export const EmployerTaxView = ({ quote, isMonthly }) => {
  const employer = quote?.employer

  const [showDetails, setShowDetails] = useState(false)

  const toggleDetails = () => {
    setShowDetails(!showDetails)
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'code',
    minimumFractionDigits: 0,
    currency: quote?.input?.convert_to?.code || 'USD',
  })

  return (
    <div className='text-sm md:text-base p-6 lg:p-8 group relative hover:shadow-lg'>
      <h3
        style={{ fontFamily: 'gilroyB' }}
        className='font-weight-bold font-size-18 text-center'
      >
        Employer cost
      </h3>
      <Row className='p-0 m-0 w-100 justify-content-center mt-3 mb-4'>
        <Col className='px-0'>
          <div
            style={{ border: '1px solid', borderRadius: 4 }}
            className='bg-soft-primary p-3 border-primary'
          >
            <Row className='justify-content-center font-size-14 text-secondary'>
              Total {isMonthly ? 'monthly' : 'annual'} cost of employment
            </Row>
            <Row className='justify-content-center align-content-center text-primary'>
              <div
                style={{
                  fontWeight: 'bold',
                  fontSize: '32px',
                  fontFamily: 'gilroyB',
                }}
              >
                {formatter.format(
                  isMonthly ? employer?.cost?.monthly : employer?.cost?.annual,
                )}
              </div>
            </Row>
          </div>
        </Col>
      </Row>
      <Row
        style={{
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
        }}
        className='border-top border-left border-right p-0 m-0 w-100 flex-column items-end font-medium p-3 p-md-4 md:mt-4'
      >
        <span className='text-secondary mb-2  font-size-14 font-weight-light'>
          Gross {isMonthly ? 'monthly' : 'annual'} salary costs
        </span>
        <span className='font-size-14 font-weight-bold text-dark'>
          {formatter.format(
            isMonthly ? employer?.gross?.monthly : employer?.gross?.annual,
          )}
        </span>
      </Row>
      <Row
        className={`border-left border-top border-right ${
          !showDetails && 'border-bottom'
        } font-light text-gray-500 p-md-4 p-3 md:mt-4 flex p-0 m-0 w-100 justify-content-between`}
      >
        <a
          className='d-flex w-100 justify-content-between align-items-center'
          onClick={toggleDetails}
        >
          <div>
            <div className='mb-2 d-flex align-items-center'>
              <span className='text-secondary font-size-14 font-weight-light '>
                Estimated taxes &amp; contributions
              </span>
            </div>
            <div className='font-size-14 font-weight-bold text-dark'>
              {formatter.format(
                isMonthly
                  ? employer?.total_taxes?.monthly
                  : employer?.total_taxes?.annual,
              )}
            </div>
          </div>
          <span className='ml-2' onClick={toggleDetails}>
            {showDetails ? (
              <i className='bx bx-chevron-up font-size-20 text-dark' />
            ) : (
              <i className='bx bx-chevron-down font-size-20 text-dark' />
            )}
          </span>
        </a>
      </Row>
      {showDetails && (
        <div className='rounded-md p-4 bg-soft-light border-bottom border-left border-right'>
          {employer?.taxes?.map((taxItem) => (
            <Row
              className='font-light mb-2 text-gray-500 p-0 m-0 w-100 flex justify-content-between align-items-center'
              key={`${Object.keys(taxItem)[0]}`}
            >
              <div
                style={{ flex: 1 }}
                className='text-secondary  font-size-14 font-weight-light '
              >
                {Object.keys(taxItem)[0]}{' '}
              </div>
              <div className='font-size-14 font-weight-bold text-dark'>
                {formatter.format(
                  isMonthly
                    ? Object.values(taxItem)[0]?.monthly
                    : Object.values(taxItem)[0]?.annual,
                )}
              </div>
            </Row>
          ))}
        </div>
      )}
      {employer?.vat?.percentage > 0 && (
        <>
          <Row className='border-left border-top border-right font-light text-gray-500 p-md-4 p-3 md:mt-4 flex p-0 m-0 w-100 flex-column'>
            <span className='mb-2 '>
              <span className=' text-secondary font-size-14 font-weight-light'>
                Subtotal
              </span>
            </span>

            <span className='font-size-14 font-weight-bold text-dark'>
              {formatter.format(
                isMonthly
                  ? employer?.subtotal?.monthly
                  : employer?.subtotal?.annual,
              )}
            </span>
          </Row>

          <Row className=' border-left border-top border-right font-light text-gray-500 p-md-4 p-3 md:mt-4 flex p-0 m-0 w-100 flex-column'>
            <span className='mb-2'>
              <span className='text-secondary font-size-14 font-weight-light'>
                VAT
              </span>
              <span className='text-secondary font-size-14 font-weight-light'>
                ({employer?.vat?.percentage}%)
              </span>
            </span>

            <span className='font-size-14 font-weight-bold text-dark'>
              {formatter.format(
                isMonthly ? employer?.vat?.monthly : employer?.vat?.annual,
              )}
            </span>
          </Row>
        </>
      )}
    </div>
  )
}

export default EmployerTaxView
