import cx from 'classnames'
import React from 'react'

import ADMIN_PERMISSIONS from '../../config/admin-permissions'
import { useFetch } from '../../helpers/hooks'
import useHasPermission from '../../helpers/hooks/admin/has-permission'
import { unflagUser } from '../../services/api'
import styles from '../user-comments/user-comments.module.scss'

export default function UserFlagging({ user, onCompleteAction }) {
  const hasEditPermission = useHasPermission(ADMIN_PERMISSIONS.MANAGE_USERS)
  const { startFetch: unflagMyUser, isLoading: isUnflagging } = useFetch({
    action: unflagUser,
    withAdminAccess: true,
    onComplete: () => {
      if (typeof onCompleteAction === 'function') {
        onCompleteAction()
      }
    },
    onError: () => {
      // toastr.error('Error unflagging user')
    },
  })

  function handleFlagging() {
    unflagMyUser({ user_id: user.id })
  }

  return (
    <>
      <button
        onClick={handleFlagging}
        title='Unflag user'
        className={cx(
          styles.commentBtn,
          'px-1 rounded border-0 bg-transparent text-muted',
        )}
        type='button'
        disabled={isUnflagging || !hasEditPermission}
      >
        {isUnflagging ? (
          <i className='bx bx-loader-alt bx-spin' />
        ) : (
          <i className='bx bxs-flag-alt text-danger' />
        )}
      </button>
    </>
  )
}
