import { Info } from '@phosphor-icons/react'
import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'

export default function LabelContent({
  children,
  required,
  tooltipText,
  tooltipId,
}) {
  const showTooltip = tooltipText && tooltipId

  return (
    <div className='d-flex align-items-center'>
      {children}

      {!required ? null : (
        <span
          className='text-danger font-size-16'
          style={{ lineHeight: 1, marginLeft: '0.125rem' }}
        >
          *
        </span>
      )}

      {!showTooltip ? null : (
        <>
          <Info size='16' id={tooltipId} className='ml-1' />

          <UncontrolledTooltip placement='top' target={tooltipId}>
            {tooltipText}
          </UncontrolledTooltip>
        </>
      )}
    </div>
  )
}
