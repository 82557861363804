import { AvField, AvForm } from 'availity-reactstrap-validation'
import React, { forwardRef, useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useSelector } from 'react-redux'
import { Col, FormGroup, Label, Popover, Row } from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'

import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import { contractTypes, userTypes } from '../../../../helpers/enum'
import {
  Fixed,
  Fulltime,
  Milestone,
  PayAsYouGo,
} from '../../../../helpers/SvgIcons'
import { mapCountryToOption } from '../../../../utils/map-to-option'
import ContractTypeSelect from '../../CreateContract/ContractTypeSelect'

const StyledCol = styled(Col)`
  height: 221px;
  overflow-y: scroll;

  &.scrollHint {
    position: relative;
    border-bottom: 1px solid var(--gray-300);

    @media (min-width: 754px) {
      border-bottom-color: transparent;
      border-right: 1px solid var(--gray-300);
    }

    ::after {
      content: '';
      position: fixed;
      --height: 40px;
      top: calc(221px - var(--height));
      left: 0;
      right: 0;
      height: var(--height);
      width: 100%;

      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #adb5bd21);
      pointer-events: none;
    }
  }

  ::-webkit-scrollbar {
    width: 2px;
    display: none;
  }
`

const Suggestions = styled.div`
  z-index: 9;
  position: absolute;
  right: 451px;
  left: 0;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 5px;
  max-height: 250px;
  overflow-y: auto;

  box-shadow: 5px 5px 24px -4px rgba(0, 0, 0, 0.07);
  width: 100%;
`

const StyledLabel = styled.div`
  color: var(--gray-700);

  &:not(:last-child) {
    border-bottom: 1px solid var(--gray-300);
  }

  &:hover {
    background-color: rgba(169, 169, 178, 0.2);
    color: var(--primary);
  }
`

const SCOPE_OF_WORK_MIN_CHARACTERS = 0

const InfoForm = forwardRef(function InfoForm(
  {
    data,
    duplicating,
    onSubmit,
    amendData,
    withContractType,
    onValidationChanged = () => {},
    onContractTypeChanged = () => {},
  },
  ref,
) {
  const options = [
    {
      label: 'Fixed',
      value: contractTypes.FIXED,
      description: 'Pay a fixed rate monthly, bi-weekly or weekly',
      icon: <Fixed />,
      activeIcon: <Fixed isActive />,
    },
    {
      label: 'Pay as you go',
      value: contractTypes.PAYG,
      description: 'Pay per day or per hour based on timesheets',
      icon: <PayAsYouGo />,
      activeIcon: <PayAsYouGo isActive />,
    },
    {
      label: 'Milestones',
      value: contractTypes.MILESTONES,
      description: 'Pay as milestones are completed',
      icon: <Milestone />,
      activeIcon: <Milestone isActive />,
    },
  ]
  const user = useSelector((state) => state?.Account?.user)
  const staticData = useSelector((state) => state.Layout.staticData)
  const amending = data?.can_amend && !duplicating

  if (user?.type === userTypes.COMPANY) {
    options.push({
      label: 'Full time',
      value: contractTypes.FULL_TIME,
      description: 'Hire a full-time employee',
      icon: <Fulltime />,
      activeIcon: <Fulltime isActive />,
    })
  }
  const [taxResidence, setTaxResidence] = useState(
    amendData?.taxResidence ||
      (data?.tax_residence
        ? { label: data?.tax_residence.name, value: data?.tax_residence.id }
        : null),
  )

  const [scope, setScope] = useState(data?.scope || '')
  const [scopeText, setScopeText] = useState('')
  function handleScopeChange(content, _, __, editor) {
    const text = editor.getText()
    const textContent = String(text ?? '').replaceAll('\n', '')
    setScope(content)
    setScopeText(textContent)
  }
  const [contractType, setContractType] = useState(
    withContractType
      ? options.indexOf(
          options.find(
            (e) => data?.type?.toUpperCase() === e?.label?.toUpperCase(),
          ),
        )
      : -1,
  )
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const toggle = () => setTooltipOpen((open) => !open)

  useEffect(() => {
    if (data) {
      onContractTypeChanged(
        options.find(
          (e) => data?.type?.toUpperCase() === e?.label?.toUpperCase(),
        ),
      )
    }
  }, [data])

  const [suggestion, setSuggestions] = useState([])
  const [watchValue, setWatchValue] = useState('')
  const [suggestedValue, setSuggestedValue] = useState('')
  const titles = useSelector((state) => state?.Layout?.staticData?.job_titles)

  useEffect(() => {
    const selectedValue =
      titles?.filter((word) => word.title === watchValue).length > 0
    if (watchValue?.length <= 0) {
      setSuggestions([])
    } else {
      if (!selectedValue) {
        const regex = new RegExp(`^${watchValue}`, 'i')
        const newSuggestions = titles?.filter((word) => regex.test(word?.title))
        setSuggestions(newSuggestions?.length > 0 ? newSuggestions : [])
      }
    }
  }, [watchValue])

  return (
    <div>
      <AvForm
        ref={ref}
        onValidSubmit={(e, v) => {
          if (!scope || scope === '<p><br></p>') {
            toastr.error('Scope of work is missing', 'error')
          } else if (scopeText.length < SCOPE_OF_WORK_MIN_CHARACTERS) {
            toastr.error(
              `The description field is a little short. The field must contain at least ${SCOPE_OF_WORK_MIN_CHARACTERS} characters.`,
            )
          } else if (!taxResidence) {
            toastr.error('Please select the Contractor Tax country', 'error')
          } else if (contractType === -1 && withContractType) {
            toastr.error('Contract Type is missing', 'error')
          } else {
            const defaultBody = {
              ...v,
              scope,
              tax_residence_id: taxResidence.value,
              tax: taxResidence,
              // scope_text: scopeText,
              type: options[contractType]?.label,
            }
            if (withContractType) {
              onSubmit({
                ...defaultBody,
                contractType: options[contractType],
              })
            } else {
              onSubmit(defaultBody)
            }
          }
        }}
      >
        <Row className='justify-content-center'>
          <Col>
            {withContractType && (
              <ContractTypeSelect
                options={options}
                onContractTypeChanged={(type) => {
                  setContractType(type)
                  onContractTypeChanged(options[type])
                }}
                value={contractType}
              />
            )}
            {options[contractType]?.value === contractTypes.FULL_TIME ? null : (
              <div className='position-relative'>
                {amending ? null : (
                  <FormGroup>
                    <Label for='basicpill-pancard-input6'>
                      Contractor Tax Country
                      <span className='text-danger font-size-16 ml-1'>*</span>
                    </Label>
                    <CustomSelect
                      name='country'
                      value={taxResidence}
                      onChange={setTaxResidence}
                      options={staticData?.countries.map((c) =>
                        mapCountryToOption(c),
                      )}
                    />
                  </FormGroup>
                )}
                <FormGroup>
                  <Label>
                    Role<span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <AvField
                    onChange={(e) => setWatchValue(e.target.value)}
                    value={suggestedValue}
                    defaultValue={amendData?.name || data?.name}
                    autoComplete='off'
                    required
                    name='name'
                    type='text'
                    className='form-control'
                    placeholder='i.e. Website Design, Content generation'
                    id='basicpill-pancard-input5'
                  />
                  {titles && suggestion.length > 0 && (
                    <Suggestions className='py-2'>
                      {suggestion?.slice(0, 10)?.map((item, i) => {
                        return (
                          <button
                            className='rp-btn-nostyle d-block rounded-0 w-100 text-left font-size-14 hover:bg-light px-3 py-2 cursor-pointer'
                            onClick={() => {
                              setSuggestedValue(item.title)
                              setSuggestions([])
                            }}
                            key={i}
                          >
                            {item?.title}
                          </button>
                        )
                      })}
                    </Suggestions>
                  )}
                </FormGroup>
                <FormGroup id='scope-contract-info'>
                  <Label>
                    Scope Of Work
                    <span className='text-danger font-size-16 ml-1'>*</span>
                  </Label>
                  <ReactQuill
                    formats={[
                      'font',
                      'size',
                      'bold',
                      'italic',
                      'underline',
                      'strike',
                      'blockquote',
                      'indent',
                      'image',
                      'video',
                    ]}
                    modules={{ toolbar: null }}
                    value={scope}
                    onChange={handleScopeChange}
                    placeholder='Describe the deliverables'
                    className='p-0 ql-contract-info'
                    style={{ whiteSpace: 'pre-line' }}
                  />

                  <div className='mt-2 ml-0'>
                    <button
                      type='button'
                      className='rp-btn-nostyle mx-n1 px-1 text-primary'
                      id='DisabledAutoHideExample'
                      onClick={toggle}
                    >
                      Predefined descriptions {'>>'}
                    </button>
                    <Popover
                      className='my-popover'
                      placement='top'
                      isOpen={tooltipOpen}
                      trigger='legacy'
                      target='DisabledAutoHideExample'
                      toggle={toggle}
                    >
                      <Row className='p-0 m-0' style={{ minWidth: 640 }}>
                        <AllTitles
                          click={(text) => {
                            setScope(text)

                            toggle()
                          }}
                        />
                      </Row>
                    </Popover>
                  </div>
                </FormGroup>
              </div>
            )}
          </Col>
        </Row>
      </AvForm>
    </div>
  )
})

export const AllTitles = ({ click, hover }) => {
  const jobDescriptions = useSelector(
    (state) => state.Layout?.staticData?.job_descriptions,
  )

  const [text, setText] = useState(jobDescriptions?.[0]?.scope)

  return (
    <Row className='mx-0'>
      <StyledCol xs={12} md={4} className='scrollHint px-0 py-2'>
        {jobDescriptions?.map((t, i) => (
          <StyledLabel
            onClick={(e) => {
              e.preventDefault()
              click(jobDescriptions[i].scope)
            }}
            onMouseOver={() => setText(jobDescriptions[i].scope)}
            key={i}
            className='text-left font-size-12 px-3 py-2 rp-font-bold cursor-pointer'
          >
            {t.title}
          </StyledLabel>
        ))}
      </StyledCol>

      <StyledCol
        xs={12}
        md={8}
        className='font-size-12 text-dark py-3 px-3 px-md-4'
        style={{ whiteSpace: 'pre-line', lineHeight: '20px' }}
      >
        {text}
      </StyledCol>
    </Row>
  )
}

export default InfoForm
