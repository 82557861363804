import React, { useState } from 'react'
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'

function getInfo(user) {
  const infos = [
    {
      title: 'Name',
      value: [user.first_name, user?.mddile_name, user.last_name]
        .filter(Boolean)
        .join(' '),
    },
    { title: 'Email', value: user.email },
    { title: 'Company', value: user.company?.name },
  ]

  return infos
}

export default function DeactivateUserModal({
  isOpen,
  toggle,
  user,
  isLoading,
  onConfirm,
}) {
  const [sendNotifEmail, setSendNotifEmail] = useState(false)

  if (!isOpen) return null

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader className='d-flex justify-content-between' toggle={toggle}>
        User Deactivation
      </ModalHeader>

      <ModalBody>
        {/* User Info */}
        <div className='font-size-16'>
          {getInfo(user).map(({ title, value }, index) => {
            return (
              <div key={`${title}-${value}-${index}`}>
                <span>{title}: </span>
                <span className='font-weight-bold text-dark'>{value}</span>
              </div>
            )
          })}
        </div>

        <hr />

        {/* Send Notif Email? */}
        <div>
          <FormGroup
            check
            className='pl-0 d-flex align-items-center'
            style={{ gap: '0.5rem' }}
          >
            <Input
              name='notifEmail'
              id='notifEmail'
              type='checkbox'
              onChange={(e) => setSendNotifEmail(e.target.checked)}
              value={sendNotifEmail}
              className='m-0 position-relative'
              style={{
                '--size': '16px',
                height: 'var(--size)',
                width: 'var(--size)',
              }}
            />
            <Label
              check
              for='notifEmail'
              className='font-size-15'
              style={{ cursor: 'pointer' }}
            >
              Send a notification email?
            </Label>
          </FormGroup>
        </div>
      </ModalBody>

      <ModalFooter>
        <Button
          type='button'
          outline
          disabled={isLoading}
          color={isLoading ? 'light' : 'secondary'}
          onClick={toggle}
        >
          Cancel
        </Button>
        <Button
          type='button'
          disabled={isLoading}
          color={isLoading ? 'light' : 'warning'}
          onClick={() => onConfirm(sendNotifEmail)}
        >
          {isLoading ? 'Deacticating ...' : 'Deactivate user'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
