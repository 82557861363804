import { yupResolver } from '@hookform/resolvers/yup'
import { getAgeBracket } from '../../../utils/insurance-age-bracket'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useController, useFieldArray, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Col, FormGroup, Input, Label, Modal, Row } from 'reactstrap'
import * as yup from 'yup'

import ControlledDatePicker from '../../../components/ControlledDatePicker'
import ControlledInput from '../../../components/ControlledInput'
import ControlledSelect from '../../../components/ControlledSelect'
import { StyledH4 } from '../../../components/Typo'
import WiseButton from '../../../components/WiseButton'
import InputFeedback from '../../../components/ui/input-feedback'
import { useResize } from '../../../helpers/hooks'

const schema = yup.object().shape({
  first_name: yup.string().required(),
  middle_name: yup.string().nullable(true),
  last_name: yup.string().required(),
  gender: yup.string().required(),
  birth_date: yup.string().required(),
  age: yup.string().required(),
  email: yup.string().email().required(),
  tax_residence_id: yup.number().required(),
  citizenship_id: yup.number().required(),
  plan: yup.string(),
  dependents: yup.array().of(
    yup.object().shape({
      first_name: yup.string().required(),
      middle_name: yup.string().nullable(true),
      last_name: yup.string().required(),
      gender: yup.string().required(),
      birth_date: yup.string().required(),
      age: yup.string().required(),
      tax_residence_id: yup.number().required(),
      citizenship_id: yup.number().required(),
    }),
  ),
})
const RequestModal = ({
  isOpen,
  toggle,
  onConfirm,
  dependents,
  planeName,
  req,
}) => {
  const countries = useSelector((state) => state?.Layout?.staticData?.countries)
  const user = useSelector((state) => state?.userProfile?.userProfile)

  const isMobile = useResize()
  const defaultValues = req
    ? {
        first_name: req?.first_name,
        middle_name: req?.middle_name,
        last_name: req?.last_name,
        birth_date: req?.birth_date,
        email: req?.email,
        gender: req?.gender,
        tax_residence_id: req?.tax_residence_id?.id,
        citizenship_id: req?.citizenship_id?.id,
        plan: req?.plan?.name,
      }
    : {
        first_name: user?.first_name,
        middle_name: user?.middle_name,
        last_name: user?.last_name,
        birth_date: user?.birth_date,
        email: user?.email,
        tax_residence_id: user?.Country_of_Tax_Residence?.id,
        citizenship_id: user?.Country_of_Citizenship?.id,
        plan: planeName,
        age:
          Array.isArray(dependents) && dependents.length > 1
            ? dependents[0]
            : getAgeBracket(user?.birth_date)[0],
        dependents: dependents?.slice(0, -1).map((e) => ({
          tax_residence_id: user?.Country_of_Tax_Residence?.id,
          citizenship_id: user?.Country_of_Citizenship?.id,
        })),
      }

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  })

  useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'dependents', // unique name for your Field Array
  })

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered size='lg'>
      <div className='modal-header p-3 p-md-4'>
        <h5 className='modal-title mt-0'>Review & Submit request</h5>
        <button
          type='button'
          className='close'
          data-dismiss='modal'
          aria-label='Close'
          onClick={toggle}
        >
          <span aria-hidden='true'>&times;</span>
        </button>
      </div>
      <div className='modal-content'>
        <div className='p-3 p-md-4'>
          <p className='font-size-16 m-0' style={{ color: '#4A4E5B' }}>
            Please review your personal details before submitting your request.
          </p>
        </div>
        <form onSubmit={handleSubmit(onConfirm)}>
          <Row className='p-0 m-0 pb-2 pt-3'>
            <Col md={4} sm={12} className='mb-3'>
              <FormGroup className='d-inline'>
                <Label className='font-size-14' htmlFor='first_name'>
                  First Name
                </Label>
                <ControlledInput
                  control={control}
                  error={errors.first_name}
                  name='first_name'
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={12} className='mb-3'>
              <FormGroup className='d-inline'>
                <Label className='font-size-14' htmlFor='first_name'>
                  Middle Name
                </Label>
                <ControlledInput
                  control={control}
                  error={errors.middle_name}
                  name='middle_name'
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={12} className='mb-3'>
              <FormGroup className='d-inline'>
                <Label className='font-size-14' htmlFor='last_name'>
                  Last Name
                </Label>
                <ControlledInput
                  control={control}
                  error={errors.last_name}
                  name='last_name'
                  disabled
                />
              </FormGroup>
            </Col>

            <Col md={4} sm={12} className='mb-3'>
              <FormGroup className='d-inline'>
                <Label className='font-size-14'>Gender</Label>
                <ControlledSelect
                  error={errors.gender}
                  control={control}
                  name='gender'
                  options={[
                    { label: 'Female', value: 'Female' },
                    { label: 'Male', value: 'Male' },
                  ]}
                />
                {!errors.gender ? null : (
                  <p className='text-danger rp-text-capitalize-first mb-0 mt-1'>
                    {errors.gender.message}
                  </p>
                )}
              </FormGroup>
            </Col>
            <Col md={4} sm={12} className='mb-3'>
              <FormGroup className='mb-0'>
                <Label className='font-size-14'>Birth Date</Label>
                <ControlledDatePicker
                  disabled
                  control={control}
                  name='birth_date'
                  error={errors.birth_date}
                  maxDate={
                    new Date(moment().subtract(18, 'years').toISOString())
                  }
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={12} className='mb-3'>
              <FormGroup className='d-inline'>
                <Label className='font-size-14' htmlFor='age'>
                  Age
                </Label>
                <ControlledInput
                  control={control}
                  error={errors.age}
                  name='age'
                  id='age'
                  disabled
                />
                {user?.birth_date && getValues('age') === null && (
                  <InputFeedback className='mt-1'>Max age is 74.</InputFeedback>
                )}
              </FormGroup>
            </Col>
            <Col md={4} sm={12} className='mb-3'>
              <FormGroup className='d-inline'>
                <Label className='font-size-14' htmlFor='email'>
                  Email
                </Label>
                <ControlledInput
                  control={control}
                  error={errors.email}
                  name='email'
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={12} className='mb-3'>
              <FormGroup className='d-inline'>
                <Label className='font-size-14'>Citizenship</Label>
                <ControlledSelect
                  isDisabled
                  error={errors.citizenship_id}
                  control={control}
                  name='citizenship_id'
                  options={
                    countries
                      ? countries.map((c) => ({
                          ...c,
                          label: c.name,
                          value: c.id,
                          flag: `/${c.svg}`,
                        }))
                      : []
                  }
                />
              </FormGroup>
            </Col>

            <Col md={4} sm={12} className='mb-3'>
              <FormGroup className='d-inline'>
                <Label className='font-size-14'>Residency</Label>
                <ControlledSelect
                  isDisabled
                  error={errors.tax_residence_id}
                  control={control}
                  name='tax_residence_id'
                  options={
                    countries
                      ? countries.map((c) => ({
                          ...c,
                          label: c.name,
                          value: c.id,
                          flag: `/${c.svg}`,
                        }))
                      : []
                  }
                />
              </FormGroup>
            </Col>

            <Col md={4} sm={12} className='mb-3'>
              <FormGroup className='d-inline'>
                <Label className='font-size-14'>Plan</Label>
                <ControlledInput
                  disabled
                  control={control}
                  error={errors.plan}
                  name='plan'
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={12} className='mb-3'>
              <FormGroup className='d-inline p-0 m-0 '>
                <Label className='font-size-14'>Dependents</Label>
                <Input disabled value={dependents?.length - 1} />
              </FormGroup>
            </Col>
            <Col style={{ height: '80px' }} sm={12} className='d-md-none' />
          </Row>
          {dependents?.map((dependent, number) => {
            if (number === dependents.length - 1) return null
            else {
              return (
                <div>
                  <div className='d-flex align-items-center justify-content-start pl-4'>
                    <div>
                      <StyledH4 className='text-secondary'>{`Dependent ${
                        number + 1
                      }`}</StyledH4>
                    </div>
                    <div
                      className='bg-secondary ml-1'
                      style={{ height: '1px', width: '82%' }}
                    />
                  </div>
                  <EntryForm
                    key={`dependent-${number}`}
                    entry={dependent}
                    control={control}
                    number={number}
                    dependents={dependents}
                    countries={countries}
                    errors={errors}
                    user={user}
                    setValue={setValue}
                    req={req}
                  />
                </div>
              )
            }
          })}
          <div
            className={`modal-footer ${
              isMobile && 'fixed-bottom'
            } p-3 bg-white p-md-4 justify-content-between justify-content-md-end`}
          >
            <WiseButton
              border
              bk={'white'}
              textColor={'dark'}
              type='button'
              text={' Cancel'}
              bold
              width={'auto'}
              minWidth={'40%'}
              onClick={toggle}
            />

            <WiseButton
              text='Submit Request'
              bold
              width='auto'
              minWidth='40%'
              type='submit'
            />
          </div>
        </form>
      </div>
    </Modal>
  )
}
const EntryForm = ({
  control,
  number,
  countries,
  errors,
  dependents,
  user,
  setValue,
  req,
}) => {
  useController({
    name: `dependents.${number}.age`,
    control,
    defaultValue: dependents[number + 1],
  })
  useEffect(() => {
    setValue(
      `dependents.${number}.citizenship_id`,
      req ? req?.tax_residence_id?.id : user?.Country_of_Citizenship?.id,
    )
    setValue(
      `dependents.${number}.tax_residence_id`,
      req ? req?.citizenship_id?.id : user?.Country_of_Tax_Residence?.id,
    )
  }, [])

  return (
    <Row className='p-0 m-0 pb-2 pt-3'>
      <Col md={4} sm={12} className='mb-3'>
        <FormGroup className='d-inline'>
          <Label className='font-size-14' htmlFor='first_name'>
            First Name
          </Label>
          <ControlledInput
            control={control}
            error={
              errors &&
              errors.dependents &&
              errors?.dependents[number]?.first_name?.message?.slice(14)
            }
            name={`dependents.${number}.first_name`}
          />
          {errors &&
          errors.dependents &&
          errors?.dependents[number]?.first_name ? (
            <p className='text-danger rp-text-capitalize-first mb-0 mt-1'>
              {errors?.dependents[number]?.first_name?.message?.slice(14)}
            </p>
          ) : null}
        </FormGroup>
      </Col>
      <Col md={4} sm={12} className='mb-3'>
        <FormGroup className='d-inline'>
          <Label className='font-size-14' htmlFor='first_name'>
            Middle Name
          </Label>
          <ControlledInput
            control={control}
            error={
              errors &&
              errors.dependents &&
              errors?.dependents[number]?.middle_name
            }
            name={`dependents.${number}.middle_name`}
          />
        </FormGroup>
      </Col>
      <Col md={4} sm={12} className='mb-3'>
        <FormGroup className='d-inline'>
          <Label className='font-size-14' htmlFor='last_name'>
            Last Name
          </Label>
          <ControlledInput
            control={control}
            error={
              errors &&
              errors.dependents &&
              errors?.dependents[number]?.last_name?.message?.slice(14)
            }
            name={`dependents.${number}.last_name`}
          />
          {errors &&
          errors.dependents &&
          errors?.dependents[number]?.last_name ? (
            <p className='text-danger rp-text-capitalize-first mb-0 mt-1'>
              {errors?.dependents[number]?.last_name.message.slice(14)}
            </p>
          ) : null}
        </FormGroup>
      </Col>

      <Col md={4} sm={12} className='mb-3'>
        <FormGroup className='d-inline'>
          <Label className='font-size-14'>Gender</Label>
          <ControlledSelect
            error={
              errors && errors.dependents && errors?.dependents[number]?.gender
            }
            control={control}
            name={`dependents.${number}.gender`}
            options={[
              { label: 'Female', value: 'Female' },
              { label: 'Male', value: 'Male' },
            ]}
          />
          {errors && errors.dependents && errors?.dependents[number]?.gender ? (
            <p className='text-danger rp-text-capitalize-first mb-0 mt-1'>
              {errors?.dependents[number]?.gender.message.slice(14)}
            </p>
          ) : null}
        </FormGroup>
      </Col>
      <Col md={4} sm={12} className='mb-3'>
        <FormGroup className='mb-0'>
          <Label className='font-size-14'>Birth Date</Label>
          <ControlledDatePicker
            control={control}
            name={`dependents.${number}.birth_date`}
            error={
              errors &&
              errors.dependents &&
              errors?.dependents[number]?.birth_date
            }
            maxDate={new Date(moment().toISOString())}
          />
          {errors &&
          errors.dependents &&
          errors?.dependents[number]?.birth_date ? (
            <p className='text-danger rp-text-capitalize-first mb-0 mt-1'>
              {errors?.dependents[number]?.birth_date.message.slice(14)}
            </p>
          ) : null}
        </FormGroup>
      </Col>
      <Col md={4} sm={12} className='mb-3'>
        <FormGroup className='d-inline p-0 m-0 '>
          <Label className='font-size-14'>Age</Label>
          <Input disabled value={dependents[number + 1]} />
        </FormGroup>
      </Col>
      <Col md={4} sm={12} className='mb-3'>
        <FormGroup className='d-inline'>
          <Label className='font-size-14'>Citizenship</Label>
          <ControlledSelect
            error={
              errors &&
              errors.dependents &&
              errors?.dependents[number]?.citizenship_id
            }
            control={control}
            name={`dependents.${number}.citizenship_id`}
            options={
              countries
                ? countries.map((c) => ({
                    ...c,
                    label: c.name,
                    value: c.id,
                    flag: `/${c.svg}`,
                  }))
                : []
            }
          />
          {/* tax_residence_id: user?.Country_of_Tax_Residence?.id, */}
          {errors &&
          errors.dependents &&
          errors?.dependents[number]?.citizenship_id ? (
            <p className='text-danger rp-text-capitalize-first mb-0 mt-1'>
              {errors?.dependents[number]?.citizenship_id.message.slice(14)}
            </p>
          ) : null}
        </FormGroup>
      </Col>

      <Col md={4} sm={12} className='mb-3'>
        <FormGroup className='d-inline'>
          <Label className='font-size-14'>Residency</Label>
          <ControlledSelect
            error={
              errors &&
              errors.dependents &&
              errors?.dependents[number]?.tax_residence_id
            }
            control={control}
            name={`dependents.${number}.tax_residence_id`}
            options={
              countries
                ? countries.map((c) => ({
                    ...c,
                    label: c.name,
                    value: c.id,
                    flag: `/${c.svg}`,
                  }))
                : []
            }
          />
          {errors &&
          errors.dependents &&
          errors?.dependents[number]?.tax_residence_id ? (
            <p className='text-danger rp-text-capitalize-first mb-0 mt-1'>
              {errors?.dependents[number]?.tax_residence_id.message.slice(14)}
            </p>
          ) : null}
        </FormGroup>
      </Col>
      <Col style={{ height: '80px' }} sm={12} className='d-md-none' />
    </Row>
  )
}
RequestModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default RequestModal
