import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Col, FormGroup, Label, Row } from 'reactstrap'
import * as yup from 'yup'

import ControlledInput from '../../../components/ControlledInput'
import ControlledSelect from '../../../components/ControlledSelect'
import { useCustomResize } from '../../../helpers/hooks'

const schema = yup.object({
  member: yup.array().of(
    yup.object().shape({
      email: yup.string().email(),
      role_id: yup.number(),
    }),
  ),
})

const InviteTeamMembers = ({ onSubmit = () => {} }) => {
  const isMobile = useCustomResize()
  const staticData = useSelector((state) => state?.Layout?.staticData)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: { member: [{}, {}, {}] },
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'member',
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Col className='py-4'>
        <Row className='p-0 m-0 d-none d-md-flex'>
          <Col md={7} className='p-0 m-0 pr-3'>
            <Label>Email</Label>
          </Col>
          <Col md={5} className='p-0 m-0'>
            <Label>Role</Label>
          </Col>
        </Row>
        {fields?.map((e, i) => {
          return (
            <Row
              className={`p-0 m-0 pb-2 align-items-center ${
                i > 0 ? 'pt-2' : ''
              } ${isMobile ? 'border-bottom' : ''}`}
              key={`e${i}`}
            >
              <Col md={7} className='p-0 m-0 pb-1 pb-md-0 pr-md-3'>
                <FormGroup className='mb-0'>
                  <Label for={`member[${i}].email`} className='d-md-none mb-1'>
                    Email
                  </Label>
                  <ControlledInput
                    control={control}
                    name={`member[${i}].email`}
                    id={`member[${i}].email`}
                    error={
                      errors.member?.length > i &&
                      !!errors.member[i]?.email?.message
                    }
                    placeholder='name@example.com'
                  />
                </FormGroup>
              </Col>
              <Col
                md={5}
                className='align-items-center col-md-5 d-flex p-0'
                style={{ gap: '0.25rem' }}
              >
                <ControlledSelect
                  control={control}
                  options={staticData?.roles?.map((e) => ({
                    value: e.id,
                    label: e.name,
                    description: e?.description,
                  }))}
                  name={`member[${i}].role_id`}
                  error={errors.member?.length > i && errors.member[i]?.role_id}
                  wrapperClassName='d-inline-block flex-grow-1'
                  labelClassName='d-md-none mb-1'
                  label='Role'
                />
                <button
                  className='rp-btn-nostyle d-flex text-danger p-1'
                  onClick={() => remove(i)}
                >
                  <i className='bx bx-trash font-size-16' />
                </button>
              </Col>
            </Row>
          )
        })}
        <Row className='p-0 m-0 justify-content-end pb-3'>
          <button
            className='rp-btn-nostyle text-primary'
            onClick={() => {
              append({ email: null, role_id: null })
            }}
          >
            <i className='bx bx-plus' /> Add user
          </button>
        </Row>
      </Col>
      <button
        type='submit'
        id='onboarding-invite-team-submit'
        className='d-none'
      >
        submit
      </button>
    </form>
  )
}

export default InviteTeamMembers
