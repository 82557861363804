import { X } from '@phosphor-icons/react'
import React from 'react'
import { Modal } from 'reactstrap'

import deOnboardIllustration from '../../../../assets/images/de-onboard-illustration.svg'
import BadgeX from '../../../../components/Table/BadgeX'
import Button from '../../../../components/ui/button'

export default function OnboardToDeModal({ isOpen, toggle }) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size='lg'
      contentClassName='flex-row'
    >
      <div style={{ flex: 1 }}>
        <img
          alt=''
          aria-hidden='true'
          src={deOnboardIllustration}
          className='w-100 h-100'
          style={{ objectFit: 'cover' }}
        />
      </div>
      <div className='flex-grow-1 p-4' style={{ flex: 1 }}>
        <div className='d-flex justify-content-between align-items-start mb-4'>
          <BadgeX className='uppercase' pill color='primary' size='sm'>
            New
          </BadgeX>

          <X size={24} onClick={toggle} className='cursor-pointer' />
        </div>
        <div className='mb-5 text-gray-h' style={{ lineHeight: '22px' }}>
          <h2 style={{ fontSize: 32 }} className='rp-font-gilroyB'>
            Direct Employee
          </h2>

          <p>
            With the Direct Employee feature, you can add employees who are
            hired under your own entity.
          </p>
          <p>
            This feature allows you to generate employment contracts instantly,
            manage day-to-day HR operations, and create payslips with just a few
            clicks.
          </p>
          <p>
            You can also generate localized salary, employment, non-objection
            certificates and NDAs quickly and easily.
          </p>
          <p>
            You have the flexibility to choose whether you want to use
            RemotePass to manage payroll or an external provider.
          </p>
          <p>
            Streamline HR and payroll management for your direct employees from
            onboarding to off-boarding, making it easy to manage your workforce
            efficiently.
          </p>
        </div>
        <Button onClick={toggle}>Continue</Button>
      </div>
    </Modal>
  )
}
