import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Table,
} from 'reactstrap'
import toastr from 'toastr'
import isEqual from 'lodash/isEqual'
import xor from 'lodash/xor'
import union from 'lodash/union'

import ADMIN_PERMISSIONS from '../../../../config/admin-permissions'
import { useFetch } from '../../../../helpers/hooks'
import useHasPermission from '../../../../helpers/hooks/admin/has-permission'
import {
  getAdminTeamRoles,
  updateAdminPermissions as updateAdminPermissionsAction,
} from '../../../../services/api'
import PERMISSIONS_LIST, {
  PERMISSIONS_DEPENDENCIES,
} from '../../../../config/admin-permissions-list'
import { CheckItem } from '../../../../components/ui/check-item'

export default function AdminRoles() {
  const [showPermissionsModal, setShowPermissionsModal] = useState(null)

  const adminRoles = useFetch({
    action: getAdminTeamRoles,
    withAdminAccess: true,
    autoFetch: true,
  })

  function handleUpdateError() {
    toastr.error('Error updating permissions', 'Error')
  }

  const {
    startFetch: updateAdminPermissions,
    isLoading: updatePermissionsIsLoading,
  } = useFetch({
    action: updateAdminPermissionsAction,
    withAdminAccess: true,
    onComplete: (data) => {
      if (Object.hasOwn(data, 'success') && data.success === false) {
        handleUpdateError()
      } else {
        adminRoles.startFetch(null, false)
        setShowPermissionsModal(null)
      }
    },
    onError: () => {
      handleUpdateError()
    },
  })

  function handlePermissionsEdit(newPermissions) {
    const newData = adminRoles.data.filter(
      (p) => p.id !== showPermissionsModal.id,
    )

    const newPermission = {
      ...showPermissionsModal,
      permissions: [...newPermissions],
    }

    const data = [...newData, newPermission]

    updateAdminPermissions({ data })
  }

  const hasEditPermission = useHasPermission(
    ADMIN_PERMISSIONS.MANAGE_ADMIN_PERMISSIONS,
  )

  return (
    <div className='page-content'>
      <div
        className='d-flex justify-content-between'
        style={{ marginBottom: '2rem' }}
      >
        <h1 className='mb-0 rp-font-gilroyB'>Admin Roles</h1>
      </div>

      <Card>
        <Col className='p-0 m-0'>
          {adminRoles.isLoading ? (
            <div
              className='d-flex justify-content-center align-items-center'
              style={{ minHeight: 145 }}
            >
              <Spinner type='grow' color='primary' />
            </div>
          ) : (
            <>
              <Table
                responsive
                className='table-centered table-nowrap text-muted mb-5'
              >
                <thead className='thead-light'>
                  <tr>
                    <th className='border-top-0 text-muted'>Role Name</th>
                    <th className='border-top-0 text-muted'>Permissions</th>
                    {!hasEditPermission ? null : (
                      <th className='border-top-0 text-muted'>Actions</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {adminRoles.data?.map((item) => (
                    <AdminRoleItem
                      key={item.id}
                      item={item}
                      initPermissionsEdit={setShowPermissionsModal}
                      hasEditPermission={hasEditPermission}
                      isLoading={adminRoles.isLoading}
                    />
                  ))}
                </tbody>
              </Table>

              <ChangePermissionsModal
                open={!!showPermissionsModal}
                toggle={() => setShowPermissionsModal(null)}
                role={showPermissionsModal}
                onPermissionsEdit={handlePermissionsEdit}
                isLoading={updatePermissionsIsLoading}
              />
            </>
          )}
        </Col>
      </Card>
    </div>
  )
}

function AdminRoleItem({
  item,
  initPermissionsEdit = () => {},
  hasEditPermission,
  isLoading,
}) {
  return (
    <tr>
      <td>{item.name}</td>
      <td className='text-wrap'>{item.permissions?.join(', ')}</td>
      {!hasEditPermission ? null : (
        <td>
          <Button
            type='button'
            onClick={() => initPermissionsEdit(item)}
            color='warning'
            size='sm'
            className='rounded'
            disabled={isLoading}
          >
            Edit permissions
          </Button>
        </td>
      )}
    </tr>
  )
}

function getDependees(list, permissions) {
  return list.reduce((prev, item) => {
    if (item?.subpages && item.subpages.length > 0) {
      return union(prev, getDependees(item.subpages, permissions))
    }

    const d = item.permissions.reduce((prev, { value, requires }) => {
      if (permissions.includes(value) && requires?.length > 0) {
        return union(prev, requires)
      }
      return prev
    }, [])

    return [...prev, ...d]
  }, [])
}

function ChangePermissionsModal({
  open,
  toggle,
  isLoading = false,
  role = {},
  onPermissionsEdit = () => {},
}) {
  const [newPermissions, setNewPermissions] = useState([])
  const [dependees, setDependees] = useState([])

  useEffect(() => {
    if (open) {
      const defaultDeps = getDependees(PERMISSIONS_LIST, role?.permissions)
      setDependees(defaultDeps)
      setNewPermissions(role?.permissions)
    }
  }, [open, role?.permissions])

  const noChanges = useMemo(() => {
    return isEqual(newPermissions, role?.permissions)
  }, [newPermissions, role?.permissions])

  function handlePermissionsChange(event, requires = []) {
    const value = event.target.value
    const checked = event.target.checked

    if (requires?.length > 0) {
      setDependees((prevDependees) => {
        if (checked) {
          return union(prevDependees, requires)
        } else {
          const reqsToRemove = requires.filter((r) => {
            if (!PERMISSIONS_DEPENDENCIES[r]) return true

            return !PERMISSIONS_DEPENDENCIES[r]
              .filter((perm) => perm !== value)
              .some((perm) => newPermissions.includes(perm))
          })
          return prevDependees.filter((p) => !reqsToRemove.includes(p))
        }
      })
    }

    if (!dependees.includes(value)) {
      setNewPermissions((prevPermissions) => {
        // this toggles (removes or adds) the permission
        return xor(prevPermissions, [value])
      })
    }
  }

  return (
    <Modal isOpen={open} toggle={toggle} size='lg' scrollable>
      <ModalHeader toggle={toggle}>
        Editing {role?.name} role permissions
      </ModalHeader>
      <ModalBody className='d-flex flex-column'>
        <div className='pr-5'>
          {PERMISSIONS_LIST.map(
            ({ label, name, permissions, subpages }, index) => {
              return (
                <React.Fragment key={index}>
                  <div
                    className={`d-flex align-items-baseline ${
                      subpages?.length > 0 ? 'pb-1' : 'pb-3 pt-2'
                    }`}
                    style={{ gap: '3rem' }}
                  >
                    <div
                      className='rp-font-gilroyB font-size-18 text-right'
                      style={{ minWidth: 160 }}
                    >
                      {label}
                    </div>
                    <PermissionsList
                      permissions={permissions}
                      name={name}
                      dependees={dependees}
                      values={newPermissions}
                      onValueChange={handlePermissionsChange}
                    />
                  </div>
                  {!subpages || subpages.length <= 0
                    ? null
                    : subpages.map(
                        ({ label, name: subpageName, permissions }, index) => {
                          const isLast = index === subpages.length - 1
                          return (
                            <div
                              className={`d-flex align-items-baseline pt-1 ${
                                isLast ? 'pb-3' : 'pb-4 pb-md-1'
                              }`}
                              style={{ gap: '3rem' }}
                              key={index}
                            >
                              <div
                                className='rp-font-gilroyB font-size-16 text-right'
                                style={{ minWidth: 160, color: '#4A4E5B' }}
                              >
                                {label}
                              </div>
                              <PermissionsList
                                permissions={permissions}
                                name={`${name}-${subpageName}`}
                                dependees={dependees}
                                values={newPermissions}
                                onValueChange={handlePermissionsChange}
                                hideHr={!isLast}
                              />
                            </div>
                          )
                        },
                      )}
                </React.Fragment>
              )
            },
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        {noChanges ? null : <p style={{ color: '#777F9E' }}>Unsaved changes</p>}
        <Button
          color='light'
          type='button'
          outline
          onClick={toggle}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          color='primary'
          type='button'
          disabled={isLoading || noChanges}
          onClick={() => onPermissionsEdit([...newPermissions, ...dependees])}
        >
          {isLoading ? 'Saving permissions ...' : 'Save new permissions'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

function PermissionsList({
  permissions,
  name,
  onValueChange = () => {},
  values = [],
  dependees = [],
  hideHr = false,
}) {
  if (!permissions || !Array.isArray(permissions) || permissions.length <= 0) {
    return null
  }

  return (
    <div className='flex-grow-1'>
      <div className='d-flex flex-wrap' style={{ gap: '1.125rem 3rem' }}>
        {permissions?.map(({ label, value, requires }, index) => {
          return (
            <CheckItem
              key={index}
              name={name}
              label={label}
              checked={values.includes(value) || dependees.includes(value)}
              disabled={dependees.includes(value)}
              value={value}
              onChange={(e) => onValueChange(e, requires)}
            />
          )
        })}
      </div>
      {hideHr ? null : (
        <hr
          className='mb-0 border-top border-light'
          style={{ marginTop: '2rem' }}
        />
      )}
    </div>
  )
}
