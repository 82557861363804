import { yupResolver } from '@hookform/resolvers/yup'
import { AvForm } from 'availity-reactstrap-validation'
import classnames from 'classnames'
import { format } from 'date-fns'
import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useForm } from 'react-hook-form'
import ReactQuill from 'react-quill'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import {
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Popover,
  PopoverBody,
  Row,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import DropzoneInput from '../../../components/Common/dropzone-input'
import ControlledCurrencyInput from '../../../components/ControlledCurrencyInput'
import ControlledDatePicker from '../../../components/ControlledDatePicker'
import ControlledInput from '../../../components/ControlledInput'
import ControlledSelect from '../../../components/ControlledSelect'
import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import Toggle from '../../../components/Forms/Toggle/Toggle'
import Button from '../../../components/ui/button'
import { useFetch, useResize } from '../../../helpers/hooks'
import {
  employmentTermOptions,
  employmentTypeOptions,
  qualificationOptions,
} from '../../../helpers/lists'
import { UploadFile } from '../../../helpers/SvgIcons'
import {
  downloadFTContractExtraFile,
  downloadFullTimeOfferLetter,
  downloadFullTimeOfferLetterAdmin,
  getAdminFullTimeContractFields,
  getFullTimeContractFields,
  getLocalCurrencies,
  uploadAdminFTContractExtraFile,
  uploadFTContractExtraFile,
  uploadOfferLetter,
  uploadOfferLetterAdmin,
} from '../../../services/api'
import openFileV2 from '../../../utils/file/open-v2'
import {
  getOptionFromList,
  mapCurrencyToOption,
} from '../../../utils/map-to-option'
import unSlugify from '../../../utils/un-slugify'
import { AllTitles } from '../components/Forms/InfoForm'
import { customOption, customSingleValue } from './FullTimeForm'

const schema = yup.object().shape({
  employee_first_name: yup.string().required(),
  employee_middle_name: yup.string(),
  employee_last_name: yup.string().required(),
  employee_email: yup.string().required(),
  employee_country_id: yup.string().required(),
  working_from_country_id: yup.string().required(),
  work_visa: yup.boolean(),
  start_date: yup.string().required(),
  job_title: yup.string().required(),
  job_description: yup.string(),
  currency_id: yup.number().required(),
  employment_type: yup.string().required(),
  qualification: yup.string().required(),
  employment_term: yup.string().required(),
  amount: yup.number().required(),
  trial_period: yup.string(),
})

function getDefaultValues(initialData) {
  const dynamicFields =
    initialData?.dynamic_fields?.reduce((acc, { key, value }) => {
      return { ...acc, [key]: value }
    }, {}) || {}

  return {
    employee_first_name: initialData?.employee?.first_name,
    employee_middle_name: initialData?.employee?.middle_name || '',
    employee_last_name: initialData?.employee?.last_name,
    employee_email: initialData?.employee?.email,
    employee_country_id: initialData?.employee?.country?.id,
    work_visa: !!initialData?.work_visa,
    start_date: initialData?.start_date
      ? format(new Date(initialData?.start_date), 'yyyy-MM-dd')
      : '',
    job_title: initialData?.name,
    job_description: initialData?.scope,
    employment_type: initialData?.employment_type,
    qualification: initialData?.qualification,
    employment_term: initialData?.employment_term,
    amount: initialData?.amount,
    trial_period: initialData?.trial_period || 'No probation',
    bonus_amount: initialData?.bonus_amount,
    bonus_details: initialData?.bonus_details,
    ...dynamicFields,
  }
}

const FT_CONTRACT_EXTRA_FIELDS = true

const FullTimeDetailsForm = forwardRef(function FullTimeDetailsForm(
  { onComplete, quote, initialData, readOnly },
  ref,
) {
  const titles = useSelector((state) => state?.Layout?.staticData?.job_titles)
  const location = useLocation()
  const isAdmin = location.pathname.startsWith('/admin')
  const isMobile = useResize()
  const [offerLetterPath, setOfferLetterPath] = useState(null)

  const [extraFieldsData, setExtraFieldsData] = useState(() => {
    return initialData?.dynamic_fields?.reduce((prev, { key, value, type }) => {
      return {
        ...prev,
        [key]: ['radio', 'select'].includes(type)
          ? { label: value, value }
          : value,
      }
    }, {})
  })
  const [extraFieldsFiles, setExtraFieldsFiles] = useState(() => {
    const defaultFiles =
      initialData?.dynamic_fields
        ?.filter((f) => f.type === 'file')
        ?.reduce((acc, { key, value }) => {
          return { ...acc, [key]: value }
        }, {}) || {}

    return defaultFiles
  })

  const countries = useSelector(
    (state) => state?.Layout?.staticData?.countries ?? [],
  )
  const { acceptedFiles, getRootProps, getInputProps, inputRef } = useDropzone({
    acceptedFiles: 'application/pdf',
    onDrop: (acceptedFiles) => {
      if (acceptedFiles?.length > 0) {
        if (acceptedFiles[0].size > 2000000) {
          toastr.error('The file may not be greater than 2MB')
          removeAll()
        } else {
          uploadLetter.startFetch({ file: acceptedFiles[0] })
        }
      }
    },
  })
  const files = acceptedFiles.map((file) => (
    <p className='mb-0' key={file.path}>
      {file.path}
    </p>
  ))

  const removeAll = () => {
    if (inputRef && inputRef.current) {
      acceptedFiles.length = 0
      acceptedFiles.splice(0, acceptedFiles.length)
      inputRef.current.value = ''
    }
  }
  const downloadContract = useFetch({
    action: downloadFullTimeOfferLetter,
    onComplete: (data) => {
      downloadFile(data, `${initialData?.ref}-offer-letter.pdf`)
    },
  })
  const downloadContractAdmin = useFetch({
    action: downloadFullTimeOfferLetterAdmin,
    withAdminAccess: true,
    onComplete: (data) => {
      downloadFile(data, `${initialData?.ref}-offer-letter.pdf`)
    },
  })

  const { startFetch: uploadExtraFiles } = useFetch({
    action: isAdmin
      ? uploadAdminFTContractExtraFile
      : uploadFTContractExtraFile,
    withAdminAccess: isAdmin,
    onComplete: (data, body) => {
      if (data?.path) {
        setExtraFieldsFiles((prev) => ({ ...prev, [body?.key]: data?.path }))
      } else {
        toastr.error(
          `Something went wrong while uploading the file ${body?.key}`,
        )
      }
    },
  })

  const { startFetch: downloadExtraFile, isLoading: downloadingExtraFile } =
    useFetch({
      action: downloadFTContractExtraFile,
      withAdminAccess: true,
      onComplete: (data, body) => {
        if (data?.success === false || data?.type === 'application/json') {
          toastr.error(
            `Something went wrong while downloading the file ${body?.key}`,
          )
        } else {
          openFileV2(data, {
            name: `${initialData?.ref}-${body?.key ?? 'extra-fields-file'}.pdf`,
            download: true,
          })
        }
      },
    })

  function handleUpload(file, key) {
    uploadExtraFiles({ file, key })
  }

  function handleDownload(filePath, key) {
    downloadExtraFile({ file_path: filePath, key })
  }

  const downloadFile = (data, name) => {
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
  }
  const handleDownloadOfferLetter = () => {
    if (isAdmin) {
      downloadContractAdmin.startFetch({ contract_id: initialData?.id })
    } else {
      downloadContract.startFetch({ contract_id: initialData?.id })
    }
  }
  const otherCountries = useSelector(
    (state) => state?.Layout?.staticData?.other_countries ?? [],
  )

  const mapCountry = (c) => {
    return { ...c, label: c.name, value: c.id, flag: `/${c.svg}` }
  }
  const nationalities = [...countries, ...otherCountries].map(mapCountry)

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(initialData),
  })

  const countryCode = useMemo(
    () =>
      getOptionFromList(countries, watch('employee_country_id'), 'id')?.iso2,
    [countries, watch('employee_country_id')],
  )

  const { data: extraFields, startFetch: getExtraFields } = useFetch(
    {
      action: isAdmin
        ? getAdminFullTimeContractFields
        : getFullTimeContractFields,
      withAdminAccess: isAdmin,
      autoFetch: !!countryCode,
      body: { country: countryCode },
    },
    [countryCode],
  )

  function handleChangeExtraFieldsData(field, key, value) {
    const newData = { ...extraFieldsData, [key]: value }

    if (field?.refreshRequirementsOnChange) {
      const fields = Object.entries(newData)
        .filter(([, value]) => {
          return !!value && !(value instanceof File)
        })
        .map(([key, value]) => ({
          key,
          value: typeof value === 'object' ? value?.value : value,
        }))

      getExtraFields({ country: countryCode, fields })
    }

    setExtraFieldsData(newData)
  }

  const onSubmit = (v) => {
    const values = { ...v }

    // Validation for scope/ job description
    if (!scope) {
      toastr.error('Job description is required')
      return
    }

    // Check if there are extra fields and validate them
    const requiredExtraFields = extraFields?.fields?.filter(
      (field) => field?.required,
    )

    if (requiredExtraFields?.length > 0) {
      const missingFields = requiredExtraFields
        .filter(({ key }) => !extraFieldsData?.[key])
        .map(({ name }) => unSlugify(name))
        .join('</li><li>')

      if (missingFields?.length > 0) {
        toastr.error(
          `Please fill in the following required fields:<li> ${missingFields}</li>`,
          null,
          { allowHtml: true },
        )
        return
      }
    }

    // Get extra fields data
    const dynamicFields = requiredExtraFields?.map(({ key, name, type }) => {
      let value = type === 'file' ? extraFieldsFiles[key] : extraFieldsData[key]

      if (typeof value === 'object') {
        value = value?.value ?? value
      }

      return { value, key, name, type }
    })

    // Start to construct the payload
    const data = {
      ...values,
      insurance: insurance ? 1 : 0,
      work_visa: visa ? 1 : 0,
      job_description: scope,
      dynamic_fields: dynamicFields,
    }

    // Add offer letter path payload
    if (offerLetterPath) {
      data.offer_letter = offerLetterPath
    }

    onComplete(data)
  }

  const currencies = useFetch({
    action: getLocalCurrencies,
    autoFetch: true,
    initResult: [],
  })
  const currencyOptions = currencies?.data?.map((c) =>
    mapCurrencyToOption(c, 'id'),
  )

  const uploadLetter = useFetch({
    action: isAdmin ? uploadOfferLetterAdmin : uploadOfferLetter,
    withAdminAccess: isAdmin,
    onComplete: (data) => {
      if (data?.path) {
        setOfferLetterPath(data.path)
      }
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  useEffect(() => {
    setValue('working_from_country_id', quote?.country_id)
    if (isAdmin) {
      setValue('currency_id', quote?.currency_id)
    } else {
      setValue(
        'currency_id',
        currencies?.data?.filter(
          (item) =>
            item.code ===
            countries?.filter((country) => country.id === quote?.country_id)[0]
              ?.currency,
        )[0]?.id,
      )
    }
    setValue('amount', quote?.amount)
  }, [quote])

  const [insurance, setInsurance] = useState(!!initialData?.insurance)
  const [visa, setVisa] = useState(!!initialData?.work_visa)
  const [showDesc, setShowDesc] = useState(false)
  const [scope, setScope] = useState(initialData?.scope || '')
  const toggleDesc = () => setShowDesc((open) => !open)

  useEffect(() => {
    setValue('job_description', scope)
  }, [scope, setValue])

  const watchingJobTitle = watch('job_title')

  const currencyId = watch('currency_id')
  const currencyCode = currencies?.data?.find((e) => e.id === currencyId)?.code

  function handleValidationError(err) {
    const messages = Object.entries(err)?.reduce((acc, [, value]) => {
      return [...acc, value?.message]
    }, [])

    toastr.error(messages.join('<br/>'), 'Error', { allowHtml: true })
  }

  return (
    <div>
      <AvForm
        ref={ref}
        autoComplete='off'
        className='form-horizontal m-0 '
        onSubmit={handleSubmit(onSubmit, handleValidationError)}
      >
        <Col className='p-0'>
          <fieldset disabled={readOnly}>
            <Label
              className='rp-font-gilroyB text-gray-h font-size-16 mb-4'
              tag='div'
            >
              Employee Info
            </Label>
            <Row>
              <Col md={6} sm={12} className='mb-3'>
                <FormGroup className='d-inline p-0 m-0'>
                  <Label
                    style={{ whiteSpace: 'nowrap' }}
                    htmlFor='billing-name'
                    md='3'
                    className='col-form-label p-0 m-0 mb-2 font-size-14'
                  >
                    First Name
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <ControlledInput
                    control={control}
                    name='employee_first_name'
                    error={errors.employee_first_name}
                  />
                </FormGroup>
              </Col>
              <Col md={6} sm={12} className='mb-3'>
                <FormGroup className='d-inline p-0 m-0'>
                  <Label
                    style={{ whiteSpace: 'nowrap' }}
                    htmlFor='billing-name'
                    md='3'
                    className='col-form-label p-0 m-0 mb-2 font-size-14'
                  >
                    Last Name
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <ControlledInput
                    control={control}
                    name='employee_last_name'
                    error={errors.employee_last_name}
                  />
                </FormGroup>
              </Col>
              <Col md={6} sm={12} className='mb-3'>
                <FormGroup className='d-inline p-0 m-0'>
                  <Label
                    style={{ whiteSpace: 'nowrap' }}
                    htmlFor='billing-name'
                    md='3'
                    className='col-form-label p-0 m-0 mb-2 font-size-14'
                  >
                    Middle Name
                  </Label>
                  <ControlledInput
                    control={control}
                    name='employee_middle_name'
                    error={errors.employee_middle_name}
                  />
                </FormGroup>
              </Col>
              <Col md={6} sm={12} className='mb-3'>
                <FormGroup className='d-inline p-0 m-0'>
                  <Label
                    style={{ whiteSpace: 'nowrap' }}
                    htmlFor='billing-name'
                    md='3'
                    className='col-form-label p-0 m-0 mb-2 font-size-14'
                  >
                    Personal Email
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <ControlledInput
                    control={control}
                    name='employee_email'
                    error={errors.employee_email}
                  />
                </FormGroup>
              </Col>
              <Col md={6} sm={12} className='mb-3'>
                <FormGroup className='d-inline p-0 m-0'>
                  <Label
                    style={{ whiteSpace: 'nowrap' }}
                    htmlFor='billing-name'
                    md='3'
                    className='col-form-label p-0 m-0 mb-2 font-size-14'
                  >
                    Nationality
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <ControlledSelect
                    options={nationalities}
                    control={control}
                    name='employee_country_id'
                    error={errors.employee_country_id}
                    isDisabled={readOnly}
                  />
                </FormGroup>
              </Col>
              <Col md={6} sm={12} className='mb-3'>
                <FormGroup className='d-inline p-0 m-0'>
                  <Label
                    style={{ whiteSpace: 'nowrap' }}
                    htmlFor='billing-name'
                    md='3'
                    className='col-form-label p-0 m-0 mb-2 font-size-14'
                  >
                    Country Of Employment
                    <span className='text-danger font-size-16 ml-1'>*</span>
                  </Label>
                  <ControlledSelect
                    options={countries.map(mapCountry)}
                    onChange={(e) => {
                      setValue('working_from_country_id', e.value)
                      setValue(
                        'currency_id',
                        currencies?.data?.filter(
                          (item) => item.code === e?.currency,
                        )[0]?.id,
                      )
                      setValue('amount', '')
                    }}
                    control={control}
                    name='working_from_country_id'
                    error={errors.working_from_country_id}
                    isDisabled={readOnly}
                  />
                </FormGroup>
              </Col>
              <Col>
                <div
                  className='d-flex align-items-center mb-3'
                  style={{ fontSize: 14, gap: 8, paddingLeft: 3 }}
                >
                  <Toggle
                    id='1'
                    check={visa}
                    change={(e) => {
                      setVisa(e.target.checked)
                    }}
                    disabled={readOnly}
                  />
                  <label className='mb-0 text-secondary'>
                    Will the hire require a work visa?
                  </label>
                </div>
              </Col>
            </Row>

            <hr className='mx-n4' />

            <Label
              className='rp-font-gilroyB text-gray-h font-size-16 my-4'
              tag='div'
            >
              Job Info
            </Label>
            <Row>
              <Col md={6} sm={12} className='mb-3'>
                <FormGroup className='d-inline p-0 m-0'>
                  <Label
                    style={{ whiteSpace: 'nowrap' }}
                    htmlFor='billing-name'
                    md='3'
                    className='col-form-label p-0 m-0 mb-2 font-size-14'
                  >
                    Qualification
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <ControlledSelect
                    options={qualificationOptions}
                    control={control}
                    name='qualification'
                    error={errors.qualification}
                    isDisabled={readOnly}
                  />
                </FormGroup>
              </Col>
              <Col md={6} sm={12} className='mb-3'>
                <FormGroup className='d-inline p-0 m-0'>
                  <Label
                    style={{ whiteSpace: 'nowrap' }}
                    htmlFor='billing-name'
                    md='3'
                    className='col-form-label p-0 m-0 mb-2 font-size-14'
                  >
                    Type
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <ControlledSelect
                    options={employmentTypeOptions}
                    control={control}
                    name='employment_type'
                    error={errors.employment_type}
                    isDisabled={readOnly}
                  />
                </FormGroup>
              </Col>
              <Col md={6} sm={12} className='mb-3'>
                <FormGroup className='d-inline p-0 m-0'>
                  <Label
                    style={{ whiteSpace: 'nowrap' }}
                    htmlFor='billing-name'
                    md='3'
                    className='col-form-label p-0 m-0 mb-2 font-size-14'
                  >
                    Term
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <ControlledSelect
                    options={employmentTermOptions}
                    control={control}
                    name='employment_term'
                    error={errors.employment_term}
                    isDisabled={readOnly}
                  />
                </FormGroup>
              </Col>
              <Col md={6} sm={12} className='mb-3'>
                <FormGroup className='d-inline p-0 m-0'>
                  <Label
                    style={{ whiteSpace: 'nowrap' }}
                    htmlFor='billing-name'
                    md='3'
                    className='col-form-label p-0 m-0 mb-2 font-size-14'
                  >
                    Probation Period
                  </Label>
                  <ControlledInput
                    control={control}
                    name='trial_period'
                    error={errors.trial_period}
                  />
                </FormGroup>
              </Col>
              <Col md={12} sm={12} className='mb-3'>
                <FormGroup className='d-inline p-0 m-0'>
                  <Label
                    style={{ whiteSpace: 'nowrap' }}
                    htmlFor='billing-name'
                    md='3'
                    className='col-form-label p-0 m-0 mb-2 font-size-14'
                  >
                    Job Title
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <ControlledInput
                    watchState={watchingJobTitle}
                    autoComplete={titles}
                    control={control}
                    name='job_title'
                    error={errors.job_title}
                    placeholder='i.e. Website Design, Content generation'
                  />
                </FormGroup>
              </Col>
              <Col md={12} sm={12} className='mb-3'>
                <FormGroup id='scope-contract-info'>
                  <Label className='font-size-14'>
                    Job Description
                    <span className='text-danger font-size-16 ml-1'>*</span>
                  </Label>
                  <ReactQuill
                    formats={[
                      'font',
                      'size',
                      'bold',
                      'italic',
                      'underline',
                      'strike',
                      'blockquote',
                      'indent',
                      'image',
                      'video',
                    ]}
                    readOnly={readOnly}
                    id='scopeEdit'
                    modules={{
                      toolbar: null,
                    }}
                    className={errors.job_description ? 'quill-error' : ''}
                    value={scope}
                    onChange={setScope}
                    placeholder='Job description'
                    style={{ whiteSpace: 'pre-line' }}
                  />
                  {readOnly ? null : (
                    <div className='mt-2 ml-0'>
                      <button
                        type='button'
                        className='rp-btn-nostyle mx-n1 px-1 text-primary'
                        id='DisabledAutoHideExample'
                        onClick={toggleDesc}
                      >
                        Predefined descriptions {'>>'}
                      </button>
                      <Popover
                        className='my-popover'
                        placement='top'
                        isOpen={showDesc}
                        trigger='legacy'
                        target='DisabledAutoHideExample'
                        toggle={toggleDesc}
                      >
                        <PopoverBody>
                          <Row className='p-0 m-0' style={{ minWidth: 640 }}>
                            <AllTitles
                              click={(text) => {
                                setScope(text)
                                setShowDesc(false)
                              }}
                            />
                          </Row>
                        </PopoverBody>
                      </Popover>
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col md={12} sm={12} className='mb-3'>
                <Label>Offer Letter</Label>
                <section className='p-0'>
                  <div
                    className={` ${
                      (!!acceptedFiles && acceptedFiles?.length > 0) ||
                      initialData?.offer_letter
                        ? ''
                        : 'dropzone border-primary'
                    } `}
                  >
                    {initialData?.offer_letter ? (
                      <div className='py-2'>
                        {uploadLetter.isLoading && (
                          <i className='bx bx-loader bx-spin text-primary mx-2' />
                        )}

                        <Button
                          size='sm'
                          onClick={handleDownloadOfferLetter}
                          icon={<i className='bx bx-download font-size-16' />}
                          loading={
                            downloadContractAdmin.isLoading ||
                            downloadContract.isLoading
                          }
                          disabled={
                            downloadContractAdmin.isLoading ||
                            downloadContract.isLoading
                          }
                        >
                          Download
                        </Button>
                      </div>
                    ) : (
                      <div
                        className='dz-message needsclick bg-soft-primary rounded'
                        {...getRootProps()}
                        onClick={
                          uploadLetter.error ? () => {} : getRootProps().onClick
                        }
                      >
                        <input {...getInputProps()} />

                        {!!acceptedFiles && acceptedFiles?.length > 0 ? (
                          <div className='font-size-14 d-flex justify-content-between align-items-start'>
                            <div className='d-flex flex-column gap-4 align-items-start'>
                              {acceptedFiles?.length > 0
                                ? files
                                : 'File uploaded'}
                            </div>

                            <button
                              onClick={removeAll}
                              type='button'
                              className='rp-btn-nostyle d-flex p-1'
                            >
                              {uploadLetter.isLoading && (
                                <i className='bx bx-loader bx-spin text-primary mx-2' />
                              )}

                              <i className='bx bx-trash text-danger' />
                            </button>
                          </div>
                        ) : (
                          <>
                            <div className='py-4'>
                              <UploadFile />
                            </div>
                            <Row>
                              <Col className='justify-content-center'>
                                {isMobile ? (
                                  <h4>Click to upload files.</h4>
                                ) : (
                                  <h3>Drop files here or click to upload.</h3>
                                )}
                                <p className='text-muted font-size-12'>
                                  *PDF only. Max file size 2MB
                                </p>
                              </Col>
                            </Row>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </section>
              </Col>
            </Row>

            <hr className='mx-n4' />

            <Label
              className='rp-font-gilroyB text-gray-h font-size-16 my-4'
              tag='div'
            >
              Compensation
            </Label>
            <Row>
              <Col md={6} sm={12} className='mb-3'>
                <FormGroup className='mb-0'>
                  <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                    Gross Salary
                    <span className='text-danger font-size-16 ml-1'>*</span>
                  </Label>
                  <InputGroup style={{ zIndex: 6 }}>
                    <ControlledCurrencyInput
                      control={control}
                      name='amount'
                      error={errors.amount}
                      isDisabled={readOnly}
                    />
                    <InputGroupAddon
                      style={{ width: '125px' }}
                      addonType='append'
                    >
                      <InputGroupText
                        className={classnames({
                          'p-0': true,
                          'border-danger': !!errors.currency_id,
                        })}
                        style={{ width: '100%', border: 0 }}
                      >
                        <div className='w-100'>
                          <ControlledSelect
                            error={errors.currency_id}
                            control={control}
                            name='currency_id'
                            options={currencyOptions}
                            classNamePrefix='RS-Addon'
                            customComponents={{
                              Option: customOption,
                              Value: customSingleValue,
                              SingleValue: customSingleValue,
                            }}
                            isDisabled={readOnly}
                          />
                        </div>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md={6} sm={12} className='mb-3'>
                <FormGroup className='mb-0'>
                  <Label className='col-form-label p-0 m-0 mb-2 font-size-14'>
                    Start Date
                    <span className='text-danger font-size-16 mx-1'>*</span>
                  </Label>
                  <ControlledDatePicker
                    control={control}
                    name='start_date'
                    error={errors.start_date}
                    disabled={readOnly}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col
                className='d-flex align-items-center font-size-14 mb-3'
                style={{ gap: 8 }}
              >
                <label className='align-items-center d-flex hover:bg-light mb-0 pl-1 pr-2 py-1 rounded-pill cursor-pointer'>
                  <Toggle
                    id='2'
                    check={insurance}
                    change={(e) => {
                      setInsurance(e.target.checked)
                    }}
                    disabled={readOnly}
                  />
                  <span className='text-secondary'>
                    Would you like to offer premium health insurance?
                  </span>
                </label>
              </Col>
            </Row>
            <Label
              className='font-weight-bold font-size-14 text-muted my-3'
              tag='div'
            >
              Bonus (Optional)
            </Label>
            <Row>
              <Col>
                <FormGroup>
                  <Label className='font-size-14' for='bonus_amount'>
                    Bonus Amount
                  </Label>
                  <InputGroup>
                    <ControlledCurrencyInput
                      control={control}
                      name='bonus_amount'
                      id='bonus_amount'
                      error={errors.bonus_amount}
                      isDisabled={readOnly}
                    />
                    <InputGroupAddon addonType='append'>
                      {currencyCode}
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col />
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label className='font-size-14' for='bonus_details'>
                    Bonus Details
                  </Label>
                  <ControlledInput
                    control={control}
                    type='textarea'
                    name='bonus_details'
                    id='bonus_details'
                    maxLength='100'
                  />
                </FormGroup>
              </Col>
            </Row>

            {!FT_CONTRACT_EXTRA_FIELDS ? null : !extraFields ||
              extraFields?.fields?.length <= 0 ? null : (
              <>
                <hr className='mx-n4' />

                <Label
                  className='rp-font-gilroyB text-gray-h font-size-16 my-4'
                  tag='div'
                >
                  Extra Fields
                </Label>
                <Row>
                  {extraFields?.fields?.map((field) => {
                    return (
                      <Col
                        md={field.type === 'file' ? null : 6}
                        sm={12}
                        className='mb-3'
                        key={field.key}
                      >
                        <ExtraField
                          values={extraFieldsData ?? {}}
                          onChange={(...args) =>
                            handleChangeExtraFieldsData(field, ...args)
                          }
                          onUpload={
                            field.type === 'file'
                              ? (file) => handleUpload(file, field.key)
                              : null
                          }
                          onDownload={
                            field.type === 'file'
                              ? (filePath) =>
                                  handleDownload(filePath, field.key)
                              : null
                          }
                          downloading={downloadingExtraFile}
                          field={{ ...field, fKey: field.key }}
                        />
                      </Col>
                    )
                  })}
                </Row>
              </>
            )}
          </fieldset>
        </Col>
      </AvForm>
    </div>
  )
})

function ExtraLabel({ fKey, name, required }) {
  return (
    <Label for={fKey}>
      {/* <span className='font-size-12 text-muted'>{unSlugify(fKey)}</span> */}
      <p className='mb-0'>
        {name}
        {!required ? null : <span className='text-danger'> *</span>}
      </p>
    </Label>
  )
}

function ExtraField({
  values,
  onChange,
  onUpload,
  onDownload,
  downloading,
  field,
}) {
  const { fKey, name, type, required, valuesAllowed } = field

  switch (type) {
    case 'text': {
      return (
        <FormGroup className='mb-0'>
          <ExtraLabel fKey={fKey} name={name} required={required} />
          <Input
            name={fKey}
            value={values[fKey]}
            onChange={(e) => onChange(fKey, e.target.value)}
            id={fKey}
            required={required}
          />
        </FormGroup>
      )
    }

    case 'radio': {
      return (
        <FormGroup className='mb-0'>
          <ExtraLabel fKey={fKey} name={name} required={required} />
          <CustomSelect
            name={fKey}
            inputId={fKey}
            value={values[fKey]}
            onChange={(option) => onChange(fKey, option)}
            options={valuesAllowed?.map(({ key, name }) => {
              return { label: name, value: key }
            })}
          />
        </FormGroup>
      )
    }

    case 'file': {
      const value = values[fKey]

      return (
        <FormGroup className='mb-0'>
          <ExtraLabel fKey={fKey} name={name} required={required} />
          {typeof value === 'string' ? (
            <div>
              <Button
                size='sm'
                type='button'
                icon={<i className='bx bx-download font-size-16' />}
                onClick={() => onDownload(value)}
                loading={downloading}
              >
                Download
              </Button>
            </div>
          ) : (
            <FIleInput
              name={fKey}
              value={values[fKey]}
              onChange={(file) => onChange(fKey, file)}
              onUpload={onUpload}
              required={required}
            />
          )}
        </FormGroup>
      )
    }

    default: {
      if (process.env.NODE_ENV !== 'production') {
        return <div className='text-muted'>Unknown type: {type}</div>
      }
      return null
    }
  }
}

function FIleInput({
  accept,
  disabled,
  error,
  required,
  onUpload,
  onChange,
  value,
  name,
}) {
  function handleReset(e) {
    e.stopPropagation()
    onChange(null)
  }
  function handleChange(acceptedFiles) {
    const file = acceptedFiles[0]
    onChange(file)
    onUpload?.(file)
  }

  return (
    <DropzoneInput
      onDropAccepted={handleChange}
      name={name}
      required={required}
      invalid={!!error}
      disabled={disabled}
      accept={accept}
    >
      <div
        style={{ minHeight: '2rem', gap: '0.5rem' }}
        className='d-flex align-items-center rp-font-gilroyB'
      >
        {value ? (
          <>
            <span className='text-body'>{value.name}</span>
            <button
              className='rp-btn-nostyle bg-soft-danger d-flex p-1 rounded'
              onClick={handleReset}
              type='button'
            >
              <i className='bx bx-trash text-danger font-size-16' />
            </button>
          </>
        ) : (
          <>
            <i className='bx bx-upload font-size-28' />
            <span className='text-body'>Drop file or click to upload</span>
          </>
        )}
      </div>
    </DropzoneInput>
  )
}

export default FullTimeDetailsForm
