import React from 'react'
import { Col, FormGroup, Input, Label } from 'reactstrap'

import customStyles from '../../../../components/Common/react-select-custom-styles'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'

export function FilterField({
  name,
  label,
  options,
  type,
  value,
  isClearable,
  onChange,
}) {
  switch (type) {
    case 'email':
    case 'text': {
      return (
        <Col xs={12} md={4} className='px-2' key={name}>
          <FormGroup>
            <Label htmlFor={name}>{label}</Label>
            <Input
              type={type}
              id={name}
              value={value}
              onChange={(e) => onChange(name, e.target.value)}
            />
          </FormGroup>
        </Col>
      )
    }
    case 'select': {
      return (
        <Col xs={12} md={4} className='px-2' key={name}>
          <FormGroup>
            <Label htmlFor={name}>{label}</Label>
            <CustomSelect
              styles={customStyles}
              value={value}
              inputId={name}
              onChange={(option, actionType) => {
                onChange(name, option, { action: actionType.action })
              }}
              options={options}
              isClearable={isClearable}
            />
          </FormGroup>
        </Col>
      )
    }
    default: {
      return process.env.NODE_ENV === 'development'
        ? `Type ${type} not supported in FilterField`
        : null
    }
  }
}
