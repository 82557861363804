import { AvField, AvForm } from 'availity-reactstrap-validation'
import React, { useEffect, useState } from 'react'
import GoogleLogin from 'react-google-login'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import ReactInputVerificationCode from 'react-input-verification-code'
import 'react-phone-input-2/lib/style.css'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import {
  Alert,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap'
import toastr from 'toastr'

import FEATURE_FLAGS from '../../config/feature-flags'
import { userTypes } from '../../helpers/enum'
import { useFetch } from '../../helpers/hooks'
import { nameRegExp } from '../../helpers/info-latin-regex'
import InviteTeamMembers from '../../pages/Authentication/onBoarding/InviteTeamMembers'
import CompanyInfo from '../../pages/CompanySetting/CompanyInfo'
import {
  getIPGeolocation,
  googleSignUp,
  inviteUsers,
  requestNewLink,
  signup,
  validatePhoneNumber,
  verifyOTP,
  verifyReCaptchaV3,
} from '../../services/api'
import {
  clearError,
  OTPSuccessful,
  registerUser,
  toggleLogin,
  updateUserData,
} from '../../store/auth/register/actions'
import { tag, track } from '../../utils/analytics'
import { EVENTS } from '../../utils/analytics/events'
import { deleteCookie, getCookie, setCookie } from '../../utils/cookies'
import { getErrors } from '../../utils/get-errors'
import { PasswordChecklistComp } from '../CommonForBoth/ChangePassword'
import { StyledH6 } from '../Typo'
import Button from '../ui/button'
import googleGLogo from './../../assets/images/Google__G__Logo.svg'
import './../../pages/Authentication/style.css'
import CustomSelect from './CustomSelect/CustomSelect'
import './form-wizard-phone-input.css'
import CustomPhoneInput from './phoneInput/CustomPhoneInput'
import UserTypeSelect from './UserTypeSelect'

function getGoogleSignUpError(e) {
  const errorKey = e?.error
  const errorDetails = e?.details
  if (
    errorKey === 'idpiframe_initialization_failed' &&
    errorDetails === 'Cookies are not enabled in current environment.'
  ) {
    return ''
  }

  switch (errorKey) {
    case 'popup_closed_by_user':
      return 'You have cancelled the sign up process, please try again.'

    default:
      return `An error occurred while signing up with Google (${errorKey}). Please try again.`
  }
}
const REFERRAL_PARAM = 'grsf'

const FormWizard = (props) => {
  const searchParams = new URLSearchParams(props.location.search)
  const tokenP = searchParams.get('token')
  const emailP = searchParams.get('email')
  const typeP = searchParams.get('type')
  const referralCode = searchParams.get(REFERRAL_PARAM)
  const userData = useSelector((state) => state?.Account?.data)
  const params = searchParams.entries()
  const path = props.location.pathname.slice(1)
  const allParams = Object.fromEntries([...params])
  const [activeTab, setActiveTab] = useState(1)
  const [userType, setUserType] = useState(
    typeP ? (typeP === 'employee' ? 2 : typeP === 'client' ? 1 : 0) : 1,
  )
  const [googleToken, setGoogleToken] = useState(userData?.tokenId || null)
  const [email, setEmail] = useState(
    emailP || userData?.profileObj?.email || null,
  )

  const [code, setCode] = useState(null)
  const [firstName, setFirstName] = useState(
    userData?.profileObj?.givenName || null,
  )
  const [lastName, setLastName] = useState(
    userData?.profileObj?.familyName || null,
  )
  const [middleName, setMiddleName] = useState(null)
  const [phone, setPhone] = useState(null)
  const [password, setPassword] = useState(null)
  const [isValid, setIsValid] = useState('')
  const [loading, setLoading] = useState(false)
  const [customchk, setCustomchk] = useState(false)

  // const [nbEmployees, setNbEmployees] = useState(null)
  const [acquisitionChannel, setAcquisitionChannel] = useState(null)
  const user = useSelector((state) => state.Account?.user)

  const link = useSelector((state) => state.Account?.link)
  const message = useSelector((state) => state.Account?.registrationError)
  const isLogged = useSelector((state) => state.Account?.loggedIn)
  const registerLoading = useSelector(
    (state) => state.Account?.loading || state.Account?.OTPloading,
  )
  const countries = useSelector((state) => state.Layout?.staticData?.countries)

  const [companyInfoLoading, setCompanyInfoLoading] = useState(false)

  const { returnTabValue } = props
  const { startFetch: requestSharingLink } = useFetch({
    action: requestNewLink,
    onComplete: () => {
      deleteCookie(REFERRAL_PARAM)
    },
    onError: (err) => {
      toastr.error(getErrors(err))
    },
  })

  const { startFetch: signUpGoogle, data: googleSignUpData } = useFetch({
    action: googleSignUp,
    onComplete: (data, body) => {
      const { eventData } = body
      setLoading(false)
      googleSignUpSuccess(eventData)
      dispatch(OTPSuccessful(data))
    },
    onError: (error) => {
      toastr.error(
        typeof error === 'string'
          ? error
          : typeof error?.message === 'string'
          ? error.message
          : 'An error occurred while signing up with Google.',
      )
      setLoading(false)
    },
  })

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 5) {
        setActiveTab(tab)
        if (returnTabValue) {
          returnTabValue(tab)
        }
      }
    }
  }

  function getInfoFromGoogleEvent(event) {
    return {
      email: event.profileObj.email,
      firstName: event.profileObj.givenName,
      lastName: event.profileObj.familyName,
      tokenId: event.tokenId,
    }
  }

  function googleSignUpSuccess(data) {
    setGoogleToken(data?.tokenId)
    setEmail(data?.email)
    setFirstName(data?.firstName)
    setLastName(data?.lastName)
    setActiveTab(3)
    returnTabValue(3)
  }

  const handleGoogleLogin = (e) => {
    const data = getInfoFromGoogleEvent(e)

    if (tokenP && data?.email !== emailP) {
      toastr.error("Invited email and email used for google login don't match.")
      return
    }

    setLoading(true)

    signUpGoogle({
      token: data?.tokenId,
      eventData: data,
      sourcePath: path,
      sourceData: allParams,
    })
  }

  const handleGoogleLoginError = (e) => {
    const message = getGoogleSignUpError(e)
    if (message) {
      toastr.error(message)
    }
  }

  const dispatch = useDispatch()
  useEffect(() => {
    if (referralCode) {
      setCookie(REFERRAL_PARAM, referralCode)
    }
  }, [])
  useEffect(() => {
    if (message) {
      toastr.error(message, 'error')
      dispatch(clearError())
    }
  }, [dispatch, message])

  useEffect(() => {
    if (isLogged) {
      if (link) {
        props.history.push(link)
      } else {
        props.history.push('/activity')
      }
    }
  }, [isLogged, link, props.history])

  useEffect(() => {
    if (userData) {
      dispatch(updateUserData(null))
    }
  }, [dispatch])

  const users = useFetch({
    action: inviteUsers,
    onComplete: () => {
      track('onBoarding_invite_users', {
        email,
      })
      dispatch(toggleLogin(true))
    },
  })
  const onSubmitEmail = () => {
    if (userType === -1) {
      toastr.error('Please set your account type', 'Error')
      return
    }
    if (!email) {
      toastr.error('Please set a valid Email', 'Error')
      return
    }
    if (!customchk) {
      toastr.error(
        'You need to agree to the Terms of Service and the Use Policy',
        'Error',
      )
      return
    }
    if (tokenP) {
      setActiveTab(3)
      if (returnTabValue) {
        returnTabValue(3)
      }
      dispatch(OTPSuccessful({ token: tokenP }))
    } else {
      const body =
        path === 'signup'
          ? { email, source_path: path, source_data: allParams }
          : { email, source_path: path }

      setLoading(true)
      signup(body, userType === 0 ? userTypes.CONTRACTOR : userTypes.COMPANY)
        .then((r) => {
          setLoading(false)
          if (activeTab !== 3 && r.data.success) {
            setCode(null)
            toggleTab(activeTab + 1)
          } else {
            toastr.error(r.data?.data?.error || r.data?.message, 'Error')
          }
          track('onBoarding_submit_email', { email })

          tag(
            EVENTS.EMAIL_ADDRESS_PROVIDED,
            getDefaultEventData(searchParams, email),
          )
        })
        .catch((e) => {
          toastr.error(e.toLocaleString())
          setLoading(false)
        })
    }
  }

  const onCompanyInfoCompleted = () => {
    track('onBoarding_submit_company_info', {
      email,
    })
    setCompanyInfoLoading(false)
    toggleTab(activeTab + 1)
    const body = {
      id: user?.id,
      email: user?.email,
      firstName: user?.first_name,
      lastName: user?.last_name,
      type: user?.type,
    }
    const referralCookie = getCookie(REFERRAL_PARAM)
    if (FEATURE_FLAGS.REMOTEPASS_REFERRALS_REGISTRATION_ON_SIGNUP) {
      requestSharingLink(
        referralCookie ? { ...body, referrerId: referralCookie } : body,
      )
    }
  }
  const onOtpVerification = () => {
    if (!code) {
      toastr.error('Set the confirmation code you received in your email')
      return
    }

    setLoading(true)

    verifyOTP(
      code,
      email,
      userType === 0 ? userTypes.CONTRACTOR : userTypes.COMPANY,
    )
      .then((r) => {
        if (r.data.success) {
          setActiveTab(3)
          if (returnTabValue) {
            returnTabValue(3)
          }
          dispatch(OTPSuccessful(r.data.data))
          track('onBoarding_otp_verified', { email })

          tag(EVENTS.OTP_RECEIVED, getDefaultEventData(searchParams, email))
        } else {
          track('onBoarding_otp_error', {
            email,
            error:
              r.data.data?.error ||
              r.data?.error ||
              r.data?.message ||
              'An error occurred',
          })
          toastr.error(
            r.data.data?.error ||
              r.data?.error ||
              r.data?.message ||
              'An error occurred',
          )
        }
      })
      .catch((e) => {
        toastr.error('An error occurred')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onRegister = async () => {
    const names = [firstName, lastName, middleName].filter(Boolean)
    const namesAreLatin = names.every((name) => {
      if (name?.length > 0 && !nameRegExp.test(name)) {
        return false
      }
      return true
    })

    if (!firstName || !lastName || !phone) {
      toastr.error(
        'Make sure you fill all required fields before you register.',
      )
      return
    }

    if (!namesAreLatin) {
      toastr.error('Your name should contain only latin letters.')
      return
    }

    setLoading(true)
    const { data } = await validatePhoneNumber(user?.token, { phone })
    setLoading(false)
    const { valid: isPhoneValid } = data?.data ?? {}

    if (isPhoneValid !== null && !isPhoneValid) {
      toastr.error('Make sure to enter a valid phone number.')
      return
    }

    if (!password && !googleToken && !isValid) {
      toastr.error(
        'Weak password easy to guess, password should respect checklist conditions',
      )
      return
    }

    const requestData = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone,
    }

    const optionalField = [
      { key: 'acquisition_channel', value: acquisitionChannel },
      // { key: 'nb_employees', value: nbEmployees },
      { key: 'middle_name', value: middleName },
    ]

    optionalField.forEach(({ key, value }) => {
      if (value) {
        requestData[key] = value
      }
    })

    if (googleToken) {
      requestData.token = googleToken
    } else {
      requestData.password = password
    }

    if (googleSignUpData?.token) {
      requestData.nextStep = 'update-profile'
    }

    window.grecaptcha?.ready(function () {
      window.grecaptcha
        .execute(process.env.REACT_APP_RE_CAPTCHA_SITE_KEY_V3, {
          action: 'register',
        })
        .then(async function (token) {
          const data = await verifyReCaptchaV3(token)

          const score = data?.data?.data?.score

          if (score < 0.5) {
            toastr.error('Please verify you are not a robot.')
          } else {
            dispatch(
              registerUser(
                userType === 1 ? userTypes.COMPANY : userTypes.CONTRACTOR,
                requestData,
                user?.token,
                () => {
                  toggleTab(activeTab + 1)
                  if (tokenP) {
                    dispatch(toggleLogin(true))
                  }
                  userType === 1
                    ? track('onBoarding_create_account', {
                        email,
                        ...requestData,
                      })
                    : track('create_contractor_account', {
                        email,
                        ...requestData,
                      })
                },
              ),
            )
          }
        })
    })
  }

  const onSkip = () => {
    if (activeTab === 4) {
      setActiveTab((v) => v + 1)
      track('onBoarding_skip_company_info')
    }
    if (activeTab === 5) {
      track('onBoarding_skip_invite_users')
      dispatch(toggleLogin(true))
    }
  }
  const onNext = () => {
    if (activeTab === 1) {
      onSubmitEmail()
    }
    if (activeTab === 2) {
      onOtpVerification()
    }
    if (activeTab === 3) {
      onRegister()
    }
    if (activeTab === 4) {
      const btn = document.getElementById('submit-company-info-form')
      setCompanyInfoLoading(true)
      btn.click()

      tag(
        EVENTS.EMAIL_ADDRESS_PROVIDED,
        getDefaultEventData(searchParams, email),
      )
    }
    if (activeTab === 5) {
      const btn = document.getElementById('onboarding-invite-team-submit')
      if (btn) btn.click()
    }
  }

  function handleAddMembers(membersData) {
    let isAllValid = true
    const members = membersData?.member?.filter((e) => {
      const isEmailValid = e.email?.length > 0
      const isRoleValid = !!e.role_id
      const condition = isEmailValid && isRoleValid

      isAllValid = [isEmailValid, isRoleValid].every(Boolean)

      return condition
    })

    if (isAllValid && members.length > 0) {
      users.startFetch({ members })
    } else if (members.length <= 0) {
      toastr.info('Click on skip if you don’t want to add any team members.')
    } else {
      toastr.error(
        'Please fill in the info if you want to add members now.',
        'Error',
      )
    }
  }

  const hearOfUs = [
    { label: 'A friend or colleague', value: 'A friend or colleague' },
    { label: 'A contractor', value: 'A contractor' },
    { label: 'Advertisement', value: 'Advertisement' },
    { label: 'Linkedin', value: 'Linkedin' },
    { label: 'Social media', value: 'Social media' },
    { label: 'Google', value: 'Google' },
    { label: 'Job posting', value: 'Job posting' },
    { label: 'Email', value: 'Email' },
    { label: 'Press', value: 'Press' },
    { label: 'Other', value: 'Other' },
  ]

  const { startFetch: getIPGeo, data: geoData } = useFetch({
    action: getIPGeolocation,
  })

  useEffect(() => {
    if (activeTab > 1) return

    getIPGeo({ fields: 'country,country_code' })
  }, [activeTab])

  return (
    <Container fluid className='align-items-center p-0 m-0'>
      <Row className='justify-content-center p-0 m-0'>
        <Col
          xs={activeTab === 5 ? 12 : 12}
          sm={activeTab === 5 ? 10 : 9}
          md={activeTab === 5 ? 10 : 7}
          lg={activeTab === 5 ? 8 : 6}
          xl={activeTab === 5 ? 6 : 4}
          className='p-0 m-0'
        >
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RE_CAPTCHA_SITE_KEY_V3}
          />

          <div className='twitter-bs-wizard'>
            {activeTab === 5 && (
              <Row className='justify-content-center p-0 m-0 '>
                <div className='px-3 px-md-4 p-0 m-0 pb-3 pb-md-4'>
                  <StyledH6
                    style={{ lineHeight: '24px' }}
                    min='14px'
                    max='14px'
                    className='mb-0 text-center font-weight-light text-secondary'
                  >
                    Add your team members who will be managing this account and
                    assign roles.
                  </StyledH6>
                </div>
              </Row>
            )}
            <Card style={{ boxShadow: '0px 1px 0px #dfe1e6' }}>
              <Row className='justify-content-center p-0 m-0'>
                <Col className={'p-0 m-0'}>
                  <div className='twitter-bs-wizard'>
                    <TabContent
                      activeTab={activeTab}
                      className='twitter-bs-wizard-tab-content p-0 m-0'
                    >
                      <TabPane tabId={1}>
                        <AvForm autoComplete='off'>
                          <Row className='justify-content-center px-3 px-md-4 pt-3 pt-md-4 m-0'>
                            {userType !== 1 && (
                              <UserTypeSelect
                                onUserTypeChanged={(type) => {
                                  setUserType(type)
                                }}
                                withEmployee={typeP === 'employee'}
                                value={userType}
                                disable={typeP}
                              />
                            )}
                          </Row>
                          {userType !== -1 && (
                            <>
                              <Row className='justify-content-center px-3 px-md-4 m-0'>
                                <Col className='p-0 m-0'>
                                  <FormGroup className='justify-content-center p-0'>
                                    <Label for='basicpill-email-input5'>
                                      E-mail
                                      <span className='text-danger font-size-16 ml-1'>
                                        *
                                      </span>
                                    </Label>
                                    <AvField
                                      type='email'
                                      name='email'
                                      disabled={!!emailP}
                                      value={email}
                                      className='form-control'
                                      id='basicpill-email-input5'
                                      autoComplete='email'
                                      onChange={(e) => {
                                        setEmail(e.target.value)
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <div className='p-3 p-md-4 pt-0'>
                                <span
                                  style={{
                                    position: 'absolute',
                                    right: '46%',
                                    fontFamily: 'gilroyB',
                                    padding: '15px',
                                  }}
                                  className='bg-white text-muted'
                                >
                                  Or
                                </span>
                                <div className='mt-4 border-top pt-4'>
                                  <GoogleLogin
                                    className='btn-block btn-outline-light font-weight-bold text-dark text-center google-btn border border-light rounded'
                                    clientId='434551861221-untotaur31aeerf1t5hoj7jra3s7a5ru.apps.googleusercontent.com'
                                    buttonText='Sign up with Google'
                                    onSuccess={handleGoogleLogin}
                                    onFailure={handleGoogleLoginError}
                                    cookiePolicy={'single_host_origin'}
                                    // loginHint={tokenP ? emailP : undefined}
                                    // prompt={tokenP ? 'none' : undefined}
                                    render={(renderProps) => (
                                      <button
                                        className='btn btn-block btn-outline-light font-weight-bold text-dark text-center google-btn border border-light rounded'
                                        onClick={() => {
                                          if (!customchk) {
                                            toastr.error(
                                              'You need to agree to the Terms of Service and the Use Policy',
                                              'Error',
                                            )
                                            return
                                          }
                                          renderProps.onClick()
                                        }}
                                        disabled={renderProps.disabled}
                                      >
                                        <img
                                          height={16}
                                          className='mr-3'
                                          src={googleGLogo}
                                          alt=''
                                        />
                                        Sign up with Google
                                      </button>
                                    )}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                          <div
                            className={'px-3 px-md-4'}
                            style={{ cursor: 'pointer' }}
                          >
                            <div className='custom-control custom-checkbox mb-3'>
                              <input
                                type='checkbox'
                                className='custom-control-input'
                                id='CustomCheck1'
                                onChange={() => false}
                                checked={customchk}
                              />
                              <div
                                className='font-size-14 text-secondary custom-control-label font-weight-normal'
                                onClick={() => {
                                  setCustomchk(!customchk)
                                }}
                              >
                                I agree to the{' '}
                                <span className='font-weight-medium text-primary'>
                                  {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                  <a
                                    target='_blank'
                                    href='https://RemotePass.com/terms'
                                  >
                                    Terms of Service
                                  </a>
                                </span>{' '}
                                and the{' '}
                                <span className='font-weight-bold text-primary'>
                                  {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                  <a
                                    target='_blank'
                                    href='https://remotePass.com/policy'
                                    style={{ whiteSpace: 'nowrap' }}
                                  >
                                    Use Policy
                                  </a>
                                </span>
                              </div>
                            </div>
                          </div>
                        </AvForm>
                      </TabPane>
                      {!tokenP && (
                        <TabPane tabId={2}>
                          <Row className='justify-content-center p-0 m-0 '>
                            <div
                              style={{ backgroundColor: '#F4F5F7' }}
                              className='px-3 px-md-4 p-0 m-0 pb-3 pb-md-4'
                            >
                              <StyledH6
                                style={{ lineHeight: '24px' }}
                                min={'14px'}
                                max={'14px'}
                                className={
                                  'mb-0  text-center font-weight-light text-secondary'
                                }
                              >
                                We’ve sent a confirmation code to
                                <span
                                  className={'mx-1 text-dark font-weight-bold'}
                                >
                                  {email}
                                </span>
                                Enter the code soon, as it will expire shortly.
                              </StyledH6>
                            </div>
                          </Row>
                          <Form
                            autoComplete='off'
                            className='align-items-center mb-0'
                          >
                            <Row className='justify-content-center'>
                              <Col className='align-items-center'>
                                <FormGroup className={'p-0 m-0'}>
                                  <Row className='justify-content-center p-3 p-md-4 p-0 m-0'>
                                    <Col className='align-items-center text-center p-0 m-0'>
                                      <Label className='font-size-16 mb-3 mb-md-4'>
                                        Your Confirmation Code
                                      </Label>
                                      <Row className='justify-content-center p-0 m-0 mb-3 mb-md-4'>
                                        <ReactInputVerificationCode
                                          type='number'
                                          length={4}
                                          inputStyle={{
                                            padding: '12px',
                                            margin: '12px',
                                          }}
                                          onChange={(e) => {
                                            setCode(e)
                                          }}
                                        />
                                      </Row>
                                      <p className='text-secondary font-weight-light font-size-14 mb-0'>
                                        Haven’t received our email? Try spam
                                        folder or{' '}
                                        <button
                                          className='rp-btn-nostyle text-primary p-0 font-weight-medium'
                                          onClick={() => {
                                            signup(
                                              email,
                                              userType === 0
                                                ? userTypes.CONTRACTOR
                                                : userTypes.COMPANY,
                                            )
                                              .then((r) => {
                                                setLoading(false)
                                                if (!r.data.success) {
                                                  toastr.error(r.data.message)
                                                }
                                              })
                                              .catch((e) => {
                                                setLoading(false)
                                              })
                                          }}
                                        >
                                          resend it
                                        </button>
                                      </p>
                                    </Col>
                                  </Row>
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                      )}
                      <TabPane tabId={3}>
                        <AvForm autoComplete='off'>
                          <Row className='p-0 m-0'>
                            <Col className='m-0 p-3 p-md-4'>
                              {!tokenP ? null : (
                                <Alert color='warning'>
                                  Spell your name exactly as it’s shown on your
                                  passport or ID card.
                                </Alert>
                              )}

                              {typeP !== 'employee' && (
                                <>
                                  <FormGroup>
                                    <Label
                                      for='basicpill-firstname-input1 '
                                      className='font-size-14'
                                    >
                                      First name
                                      <span className='text-danger font-size-16 ml-1'>
                                        *
                                      </span>
                                    </Label>
                                    <Input
                                      type='text'
                                      className='form-control'
                                      id='basicpill-firstname-input1'
                                      onChange={(e) => {
                                        setFirstName(e.target.value)
                                      }}
                                      value={firstName}
                                    />
                                  </FormGroup>

                                  <FormGroup>
                                    <Label
                                      for='basicpill-middlename-input1 '
                                      className='font-size-14'
                                    >
                                      Middle name
                                    </Label>
                                    <Input
                                      type='text'
                                      className='form-control'
                                      id='basicpill-middlename-input1'
                                      onChange={(e) => {
                                        setMiddleName(e.target.value)
                                      }}
                                      value={middleName}
                                    />
                                  </FormGroup>

                                  <FormGroup>
                                    <Label
                                      for='basicpill-lastname-input2'
                                      className='font-size-14'
                                    >
                                      Last name
                                      <span className='text-danger font-size-16 ml-1'>
                                        *
                                      </span>
                                    </Label>
                                    <Input
                                      type='text'
                                      className='form-control'
                                      id='basicpill-lastname-input2'
                                      onChange={(e) =>
                                        setLastName(e.target.value)
                                      }
                                      value={lastName}
                                    />
                                  </FormGroup>

                                  <FormGroup>
                                    <CustomPhoneInput
                                      inputClass='form-control'
                                      inputStyle={{
                                        width: '100%',
                                        paddingBottom: '10px',
                                      }}
                                      country={
                                        geoData?.country_code?.toLowerCase() ??
                                        'ae'
                                      }
                                      label='Phone'
                                      enableAreaCodes
                                      onChange={setPhone}
                                      required
                                    />
                                  </FormGroup>
                                </>
                              )}

                              {!tokenP && (
                                <>
                                  {/* <AvField
                                    type='number'
                                    name='employeeNumber'
                                    className='form-control'
                                    id='basicpill-pancard-input5'
                                    label='Number of employees'
                                    onChange={(e) => {
                                      setNbEmployees(e.target.value)
                                    }}
                                  /> */}
                                  <FormGroup className='justify-content-center p-0'>
                                    <Label for='basicpill-pan card-input5'>
                                      How did you hear about us?
                                    </Label>
                                    <CustomSelect
                                      options={hearOfUs}
                                      onChange={(e) =>
                                        setAcquisitionChannel(e?.value)
                                      }
                                      isSearchable={false}
                                    />
                                  </FormGroup>
                                </>
                              )}

                              {!googleToken && (
                                <>
                                  <div hidden>
                                    <input
                                      type='text'
                                      name='email'
                                      value={email}
                                      autoComplete='email'
                                      id='email'
                                    />
                                  </div>
                                  <FormGroup className='mb-0'>
                                    <Label
                                      className='font-size-14'
                                      for='basicpill-password-input4'
                                    >
                                      Password
                                      <span className='text-danger font-size-16 ml-1'>
                                        *
                                      </span>
                                    </Label>
                                    <Input
                                      type='password'
                                      className='form-control'
                                      id='basicpill-password-input4'
                                      autoComplete='new-password'
                                      onChange={(e) =>
                                        setPassword(e.target.value)
                                      }
                                    />
                                  </FormGroup>
                                  <PasswordChecklistComp
                                    TooltipId={'basicpill-password-input4'}
                                    password={password || ''}
                                    onChange={(v) => setIsValid(v)}
                                  />
                                </>
                              )}
                            </Col>
                          </Row>
                        </AvForm>
                      </TabPane>
                      <TabPane tabId={4}>
                        <CompanyInfo
                          isOnboarding
                          onComplete={onCompanyInfoCompleted}
                          countries={countries}
                          setLoading={setCompanyInfoLoading}
                        />
                      </TabPane>
                      {activeTab === 5 && (
                        <TabPane tabId={5}>
                          <InviteTeamMembers onSubmit={handleAddMembers} />
                        </TabPane>
                      )}
                    </TabContent>
                  </div>
                </Col>
              </Row>

              <div
                className='d-flex justify-content-between p-3 p-md-4 border-top'
                style={{ gap: '1rem' }}
              >
                <div>
                  {activeTab === 2 && (
                    <button
                      className='btn btn-outline-light'
                      style={{ padding: '0.65rem 2.75rem', height: 48 }}
                      onClick={() => {
                        if (activeTab === 3) {
                          toggleTab(activeTab - 2)
                        } else {
                          toggleTab(activeTab - 1)
                        }
                      }}
                    >
                      Back
                    </button>
                  )}
                </div>
                <div className='d-flex' style={{ gap: '1rem' }}>
                  {[5].includes(activeTab) && (
                    <Button
                      onClick={onSkip}
                      color='light'
                      outline
                      className='border-0 text-muted'
                      style={{ minWidth: 90 }}
                    >
                      Skip
                    </Button>
                  )}
                  <Button
                    onClick={onNext}
                    type='button'
                    disabled={loading || registerLoading || companyInfoLoading}
                    loading={loading || registerLoading || companyInfoLoading}
                    style={{ minWidth: 120 }}
                  >
                    {activeTab === 3 ? 'Register' : 'Next'}
                  </Button>
                </div>
              </div>
            </Card>

            <div className='mt-5 mb-4 text-center'>
              {activeTab > 1 ? null : (
                <p className='font-size-14'>
                  Already have an account?{' '}
                  <Link to='/login' className='font-weight-medium text-primary'>
                    Login
                  </Link>
                </p>
              )}
              <p className='mb-0'>© {new Date().getFullYear()} RemotePass</p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

function getDefaultEventData(searchParams, email) {
  const utmSource = searchParams.get('utm_source')
  const eventData = {
    date: new Date().toISOString(),
    email,
    utm_source: utmSource,
  }

  return eventData
}

export default withRouter(FormWizard)
