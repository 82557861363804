// This is a generated file. Please do not edit this file manually.
// To update this file, please use https://github.com/rp-omar/tagging-plan
// Generated on 2023-03-27T11:45:17.542Z by git user "Omar Houmz <omar@remotepass.com>"

export const EVENTS = {
  EMAIL_ADDRESS_PROVIDED: 'Email Address provided',
  OTP_RECEIVED: 'OTP Received ',
  REGISTRATION_COMPLETED: 'Registration Completed ',
  KYB_COMPLETED: 'KYB Completed',
  TRANSACTION_COMPLETED: 'Transaction Completed',
}
