import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Container } from 'reactstrap'

import { useFetch } from '../../../../helpers/hooks'
import { generateAdminFulltimeQuotation } from '../../../../services/api'
import InvoiceForm from './InvoiceForm'

const InvoiceGeneration = () => {
  const generate = useFetch({
    action: generateAdminFulltimeQuotation,
    withAdminAccess: true,
    onComplete: (data) => {
      downloadFile(data, 'Quotation.pdf')
    },
  })
  const downloadFile = (data, name) => {
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
  }
  return (
    <div className='page-content'>
      <div
        className='d-flex align-items-center'
        style={{ marginBottom: '2rem', gap: '1rem' }}
      >
        <Link
          to='/admin/contracts-invoices'
          className='rp-btn-nostyle text-muted d-flex p-1'
          title="Back to contracts' invoices"
        >
          <i className='bx bx-arrow-back font-size-28' />
        </Link>
        <h1 className='rp-font-gilroyB mb-0'>Invoice generator</h1>
      </div>
      <Card className='mt-3'>
        <Container fluid className={'p-0 m-0'}>
          <InvoiceForm forGenerator onSubmit={generate.startFetch} />
        </Container>
      </Card>
    </div>
  )
}

export default InvoiceGeneration
