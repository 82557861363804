import React, { useRef, useState } from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import { useLocation } from 'react-router'

import { StyledH5 } from '../../../../components/Typo'
import { useFetch } from '../../../../helpers/hooks'
import {
  adminExportQuote,
  exportQuote as exportQuoteAction,
  getAdminCalculatorResult,
  getCalculatorResult,
} from '../../../../services/api'
import FullTimeForm from '../../../Contract/RequestContract/FullTimeForm'
import useHasPermission from '../../../../helpers/hooks/admin/has-permission'
import ADMIN_PERMISSIONS from '../../../../config/admin-permissions'
import Button from '../../../../components/ui/button'

const EORCalculator = () => {
  const [quote, setQuote] = useState(null)

  const [quoteToExport, setQuoteToExport] = useState(null)
  const location = useLocation()
  const isAdmin = location.pathname.startsWith('/admin')

  const getcurrencyCalculations = useFetch({
    action: isAdmin ? getAdminCalculatorResult : getCalculatorResult,
    withAdminAccess: isAdmin,
    onComplete: (data) => {
      setQuote(data)
    },
  })

  const callBack = (r) => {
    const url = window.URL.createObjectURL(new Blob([r]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute(
      'download',
      `${quoteToExport?.input?.name}/${quoteToExport?.input?.salary}/${quoteToExport?.input?.convert_to?.code}.xls`,
    ) // or any other extension
    document.body.appendChild(link)
    link.click()
  }

  const { startFetch: exportQuote, isLoading } = useFetch({
    action: isAdmin ? adminExportQuote : exportQuoteAction,
    onComplete: callBack,
    withAdminAccess: true,
    body: { data: quoteToExport },
  })

  const fullTimeRef = useRef()

  const hasEditPermission = useHasPermission(
    ADMIN_PERMISSIONS.MANAGE_CALCULATOR,
  )

  return (
    <div className='page-content'>
      <Container fluid className={'p-0 m-0'}>
        <Row className='mb-3 mb-md-4 p-0 m-0'>
          <Col xs={12} md={6} className={'p-0 m-0'}>
            <StyledH5 min={'22px'} max={'32px'}>
              EOR Calculator
            </StyledH5>
          </Col>
          {!hasEditPermission ? null : (
            <Col
              xs={12}
              md={6}
              className={'p-0 m-0 mb-2 mb-md-0 text-md-right'}
            >
              <Button
                disabled={!quoteToExport?.input || isLoading}
                onClick={() => exportQuote()}
                loading={isLoading}
                className='px-2 py-1 px-md-4 py-md-2'
              >
                Export to Excel
              </Button>
            </Col>
          )}
        </Row>
      </Container>
      <Card className={'p-0 m-0'} style={{ position: 'inherit' }}>
        <CardBody
          className='py-4 d-flex justify-content-between flex-column'
          style={{ minHeight: '50vh' }}
        >
          <FullTimeForm
            quoteDetails={(res) => setQuoteToExport(res)}
            edit
            ref={fullTimeRef}
            onComplete={(v) => {
              getcurrencyCalculations.startFetch({
                country_code: v?.country_id,
                annual_salary: Number(v?.amount),
                currency: v?.currency_id,
              })
            }}
            quote={quote}
            readOnly={!hasEditPermission}
          />

          {quote || !hasEditPermission ? null : (
            <div className='text-right'>
              <button
                disabled={getcurrencyCalculations.isLoading}
                onClick={() => fullTimeRef.current.submit()}
                className='btn btn-primary'
              >
                {getcurrencyCalculations.isLoading && (
                  <i className='bx bx-loader bx-spin font-size-16 align-middle mx-2' />
                )}{' '}
                Submit
              </button>
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  )
}

export default EORCalculator
