import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router'
import { Col, Container, Row, TabContent, TabPane } from 'reactstrap'
import toastr from 'toastr'

import ModalHeader from '../../components/ModalHeader'
import Steps from '../../components/Steps'
import StepContainer from '../../components/Steps/StepContainer'
import { useFetch, useResize } from '../../helpers/hooks'
import {
  confirmStripeForInsurance,
  createInsuranceTrx,
  getContractorPaymentMethods,
  getPlaidToken,
  prepareInsuranceTrx,
} from '../../services/api'
import CreditCardsForSteps from '../payInvoices/creditCardsForSteps'
import ConfirmationPage from './ConfirmationPage'
import PaymentReview from './PaymentReview'

import creditCard from '../../assets/images/credit_card.svg'
import creditCardSelected from '../../assets/images/creditCardSelected.svg'
import WalletIcon from '../../assets/images/Walleticon.svg'
import WalletBlue from '../../assets/images/Walletblue.svg'

const PayInsurance = () => {
  const location = useLocation()
  const isConfirmation = new URLSearchParams(location.search).get(
    'confirmation',
  )
  const quoteId = new URLSearchParams(location.search).get('quote_id')
  const paymentIntent = new URLSearchParams(location.search).get(
    'payment_intent',
  )
  const paymentIntentClientSecret = new URLSearchParams(location.search).get(
    'payment_intent_client_secret',
  )
  const balance = useSelector(
    (state) => state.userProfile?.userProfile?.balance_estimated,
  )

  const [options, setOptions] = useState([])
  const isMobile = useResize()
  const [activeTab, setActiveTab] = useState(0)
  const [method, setMethod] = useState(null)
  const [account, setAccount] = useState(null)
  const [confirmed, setConfirmed] = useState(false)
  const [isConfirming, setIsConfirming] = useState(false)
  const { id } = useParams()
  const history = useHistory()

  useEffect(() => {
    if (isConfirmation) {
      setMethod(options[0])
      setActiveTab(2)
    }
  }, [isConfirmation])

  useEffect(() => {
    setOptions([
      {
        label: 'Credit / Debit Card',
        description: '4% Fee',
        icon: creditCard,
        activeIcon: creditCardSelected,
        type: 'card',
        id: 1,
        enabled: true,
      },
      {
        label: 'Balance',
        description: '0% Fee',
        icon: WalletIcon,
        activeIcon: WalletBlue,
        type: 'bc',
        id: 8,
        enabled: balance >= location?.state?.amount,
        errorMessage: 'Insufficient balance',
      },
    ])
  }, [balance])

  const prepareTrx = useFetch({
    action: prepareInsuranceTrx,
    onError: (err) => {
      toastr.error(err)
      history.goBack()
    },
    onComplete: () => {
      setActiveTab((t) => t + 1)
    },
  })

  const createTrx = useFetch({
    action: createInsuranceTrx,
    onComplete: (data) => {
      if (data?.url) {
        setIsConfirming(true)
        window.location.replace(data?.url)
      } else {
        setConfirmed(true)
        setActiveTab((t) => t + 1)
      }
    },
    onError: () => {
      toastr.error('Something went wrong')
    },
  })

  const confirmTrx = useFetch({
    action: confirmStripeForInsurance,
    onError: (err) => {
      toastr.error(err)
      setConfirmed(false)
    },
    onComplete: (data) => {
      setConfirmed(data.status === 'succeeded')
    },
  })

  useEffect(() => {
    if (isConfirmation) {
      confirmTrx.startFetch({
        payment_intent: paymentIntent,
        payment_intent_client_secret: paymentIntentClientSecret,
        quote_id: quoteId,
      })
    }
  }, [])

  const cards = useFetch({
    action: getContractorPaymentMethods,
    autoFetch: true,
    initResult: [],
  })

  const getToken = useFetch({
    action: getPlaidToken,
    autoFetch: true,
  })

  return (
    <div className='page-content min-vh-100 p-0 m-0'>
      <Container fluid>
        <ModalHeader
          action={() => {
            history.push('/insurance')
          }}
        >
          {!isMobile && (
            <Steps
              activeTab={activeTab}
              data={['Payment Methods', 'Review', 'Pay']}
              noLastAction
            />
          )}
        </ModalHeader>
        <Row className='justify-content-center'>
          <Col lg={isMobile ? undefined : 6}>
            <TabContent
              activeTab={activeTab}
              className='twitter-bs-wizard-tab-content'
            >
              <TabPane tabId={0}>
                <StepContainer
                  index={1}
                  total={3}
                  title='Payment Methods'
                  noBack
                  onNext={() => {
                    if (method?.type === 'card') {
                      prepareTrx.startFetch({
                        payment_ids: [id],
                        payment_method_id: 1,
                      })
                    }
                  }}
                  disableNext={!method}
                  loading={prepareTrx.isLoading}
                >
                  {!!getToken.data?.link_token && (
                    <Container className='p-0 m-0'>
                      <CreditCardsForSteps
                        onAccountAdded={(acc) => {
                          setAccount(acc)
                          cards.startFetch(null, false)
                        }}
                        onUpdate={() => {}}
                        onNext={(card) => {
                          setAccount(card)
                        }}
                        onMethodSelected={(item) => {
                          if (item?.type === 'bc') {
                            prepareTrx.startFetch({
                              payment_ids: [id],
                              payment_method_id: 8,
                            })
                          }
                          setMethod(item)
                        }}
                        hasMethodSelected={!!method}
                        isInsurance
                        methods={{ methods: options }}
                        loading={getToken.isLoading || cards.isLoading}
                        cards={cards.data}
                        cardsLoading={cards.isLoading}
                        plaidToken={getToken.data?.link_token}
                      />
                    </Container>
                  )}
                </StepContainer>
              </TabPane>
              <TabPane tabId={1}>
                <StepContainer
                  index={2}
                  total={3}
                  title=''
                  nextText='Confirm'
                  onBack={() => setActiveTab(activeTab - 1)}
                  onNext={() => {
                    if (method?.type === 'bc') {
                      createTrx.startFetch({
                        quote_id: prepareTrx.data.quote_id,
                      })
                    } else {
                      createTrx.startFetch({
                        quote_id: prepareTrx.data.quote_id,
                        payment_method_id: account?.id,
                      })
                    }
                  }}
                  isFlat
                >
                  <PaymentReview
                    data={prepareTrx.data}
                    loading={
                      prepareTrx.isLoading ||
                      createTrx.isLoading ||
                      confirmTrx.isLoading ||
                      isConfirming
                    }
                    method={method}
                  />
                </StepContainer>
              </TabPane>
              <TabPane tabId={2}>
                <ConfirmationPage
                  onComplete={() => {
                    history.push('/insurance')
                  }}
                  loading={confirmTrx.isLoading || createTrx.isLoading}
                  confirmed={confirmed}
                />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default PayInsurance
