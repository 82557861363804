import React, { useCallback, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Col, Container, Row, Spinner } from 'reactstrap'

import { StyledH3 } from '../../components/Typo'
import { userTypes } from '../../helpers/enum'
import { useFetch } from '../../helpers/hooks'
import {
  getAdminInvoicesList,
  getInvoicesList,
  getInvoicesMonths,
  getInvoicesYears,
} from '../../services/api'
import InvoicesList from './InvoicesList'

const Invoices = (props) => {
  const { isAdmin, contractId } = props
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [month, setMonth] = useState(null)
  const [year, setYear] = useState(null)

  const user = useSelector((state) => state.Account?.user)
  const token = useSelector((state) => state.Login?.loginToken)
  const months = useFetch({
    action: getInvoicesMonths,
    autoFetch: !isAdmin,
    onComplete: (data) => {
      if (data?.months?.length > 0 && user?.type === userTypes.COMPANY) {
        setMonth(data?.months[0])
      }
    },
  })
  const years = useFetch({
    action: getInvoicesYears,
    autoFetch: !isAdmin,
    onComplete: (data) => {
      if (data?.years?.length > 0 && user?.type === userTypes.CONTRACTOR) {
        setYear(data?.years[0])
      }
    },
  })
  const updateList = useCallback(() => {
    setLoading(true)

    getInvoicesList(
      user?.token,
      user?.type === userTypes.COMPANY
        ? { month: month?.value }
        : { year: year?.value },
    )
      .then((r) => {
        if (r.data.success) {
          setData(r.data.data)
        }
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
      })
  }, [month?.value, user?.token, user?.type, year?.value])

  const AdminInvoices = useCallback(() => {
    if (!contractId) {
      return
    }

    getAdminInvoicesList(token, { contract_id: contractId })
      .then((r) => {
        if (r.data.success) {
          setData(r.data.data)
        }
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
      })
  }, [token, contractId])

  useEffect(() => {
    if (isAdmin) {
      AdminInvoices()
    } else if (year || month) {
      updateList()
    }
  }, [month, year, isAdmin, AdminInvoices, updateList])

  return (
    <div className={isAdmin ? '' : 'page-content'}>
      {isAdmin ? null : (
        <StyledH3
          min={'22px'}
          max={'32px'}
          style={{ fontFamily: 'gilroyB' }}
          className={'text-dark mb-3 mb-md-4'}
        >
          Invoices
        </StyledH3>
      )}
      {loading || months.isLoading ? (
        <Container style={{ minHeight: '30rem' }}>
          <Col style={{ minHeight: '30rem' }}>
            <Row
              style={{ minHeight: '30rem' }}
              className='justify-content-center align-items-center'
            >
              <Spinner type='grow' className='mr-2' color='primary' />
            </Row>
          </Col>
        </Container>
      ) : (
        <Container className='p-0 m-0' fluid>
          <InvoicesList
            isAdmin={isAdmin}
            Orders={data}
            dataLoading={loading}
            onMonthChanged={
              user?.type === userTypes.COMPANY ? setMonth : setYear
            }
            months={
              user?.type === userTypes.COMPANY
                ? months.data?.months
                : years.data?.years
            }
            month={user?.type === userTypes.COMPANY ? month : year}
          />
        </Container>
      )}
    </div>
  )
}

export default withTranslation()(Invoices)
