import cx from 'classnames'
import { isPast } from 'date-fns'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Card } from 'reactstrap'
import toastr from 'toastr'

import noTransactions from '../../assets/images/no-transactions.svg'
import Toggle from '../../components/Forms/Toggle/Toggle'
import NoContent from '../../components/NoContent'
import Button from '../../components/ui/button'
import ContractorDisplay from '../../components/ui/contractor-display'
import DataTable from '../../components/ui/data-table'
import Loader from '../../components/ui/loader'
import NavTabs from '../../components/ui/nav-tabs'
import { clientRole } from '../../helpers/enum'
import { useFetch } from '../../helpers/hooks'
import { approvePayments, getPaymentsForReview } from '../../services/api'
import {
  getCurrencyFormatter,
  usdFormatter,
} from '../../utils/formatters/currency'
import ContractRef from '../AdminPanel/components/ContractRef'

function getPaymentsAsApprover(payments) {
  return payments?.filter((payment) => payment?.is_approver === true) ?? []
}

function getFilters({ paymentsAsApprover, isHr }) {
  return [
    paymentsAsApprover.length <= 0
      ? null
      : { label: 'Payments as Approver', value: '1' },
    paymentsAsApprover.length <= 0 && !isHr
      ? null
      : { label: 'All Payments', value: '2' },
  ].filter(Boolean)
}

export default function ReviewPayments() {
  const [selectedPayments, setSelectedPayments] = useState({})

  const history = useHistory()

  const userProfile = useSelector((state) => state.userProfile?.userProfile)
  const canManagePayments = userProfile?.is_payroll_approver
  const isHr = userProfile?.role_name === clientRole.HR
  const isAdmin = userProfile?.role_name === clientRole.ADMIN

  const { data: payments, isLoading } = useFetch({
    action: getPaymentsForReview,
    autoFetch: true,
    onComplete: (data) => {
      const paymentsAsApprover = getPaymentsAsApprover(data)
      const filters = getFilters({ paymentsAsApprover, isHr })

      setActiveTab(filters[0]?.value)
    },
  })

  const paymentsAsApprover = useMemo(
    () => getPaymentsAsApprover(payments),
    [payments],
  )

  const filters = getFilters({ paymentsAsApprover, isHr })

  const [activeTab, setActiveTab] = useState(filters[0]?.value)

  const { startFetch: _approvePayments, isLoading: approvingPayments } =
    useFetch({
      action: approvePayments,
      onComplete: (data) => {
        if (data?.success === false) {
          toastr.clear()
          toastr.error('Error approving payments')
        } else {
          setSelectedPayments([])
          history.push('/review-payments-confirmed')
        }
      },
      onError: () => {
        toastr.clear()
        toastr.error('Error approving payments')
      },
    })

  function handleApprovePayments() {
    const allPaymentIds = Object.values(selectedPayments).flat()

    _approvePayments({ payment_ids: allPaymentIds })
  }

  function handleTogglePayment({ contractRef, paymentIds }) {
    setSelectedPayments((prev) => {
      if (Object.keys(prev).includes(contractRef)) {
        const newSelected = { ...prev }
        delete newSelected[contractRef]
        return newSelected
      }

      return { ...prev, [contractRef]: paymentIds }
    })
  }

  const columns = useMemo(
    () =>
      [
        {
          Header: 'Contract Ref',
          accessor: 'contract_ref',
          Cell: ({ cellData }) => (
            <ContractRef contractId={cellData} isAdmin={false} />
          ),
        },
        {
          Header: 'Name',
          accessor: 'payments',
          Cell: ({ cellData }) => {
            return <ContractorDisplay contractor={cellData?.[0]?.contractor} />
          },
        },
        { Header: 'Contract Name', accessor: 'contract_name' },
        {
          Header: 'Due date',
          accessor: 'payments',
          Cell: ({ cellData }) => {
            const isPastDueDate = isPast(new Date(cellData?.[0]?.due_date))

            return (
              <span className={cx({ 'text-danger': isPastDueDate })}>
                {cellData?.[0]?.due_date}
              </span>
            )
          },
        },
        {
          Header: 'Total',
          accessor: 'total_amount',
          Cell: ({ cellData, rowData }) => {
            const formatter = getCurrencyFormatter(rowData?.currency?.code)
            return formatter.format(cellData)
          },
        },
        !canManagePayments
          ? null
          : {
              Header: '',
              accessor: 'payments',
              Cell: ({ cellData, rowData }) => {
                const contractRef = rowData?.contract_ref
                const paymentIds = cellData.map((payment) => payment.id)

                if (rowData?.is_approver === false && !isAdmin) {
                  return null
                }

                return (
                  <Toggle
                    check={Object.keys(selectedPayments).includes(contractRef)}
                    change={() => {
                      handleTogglePayment({ contractRef, paymentIds })
                    }}
                    disabled={approvingPayments}
                  />
                )
              },
            },
      ].filter(Boolean),
    [approvingPayments, canManagePayments, isAdmin, selectedPayments],
  )

  const hideNavTabs =
    isLoading ||
    !payments ||
    (!isAdmin && paymentsAsApprover?.length === payments?.length)

  return (
    <div className='page-content'>
      <div className='d-flex flex-column flex-md-row justify-content-md-between align-items-md-center mb-3 gap-16'>
        <h1 className='rp-font-gilroyB font-size-32 text-gray-h mb-0'>
          Review payments
        </h1>

        {!canManagePayments ? null : (
          <Button
            style={{ minWidth: 110 }}
            disabled={
              Object.keys(selectedPayments).length <= 0 || approvingPayments
            }
            loading={approvingPayments}
            onClick={handleApprovePayments}
          >
            Approve
          </Button>
        )}
      </div>

      <div style={{ height: 30 }} className='align-items-center d-flex mb-2'>
        <NavTabs
          className={cx({ 'd-none': hideNavTabs })}
          options={filters}
          activeOption={activeTab}
          onClickOption={(option) => setActiveTab(option?.value)}
        />
      </div>

      <Card>
        {isLoading || !payments ? (
          <Loader minHeight='max(50vh, 485px)' />
        ) : payments.length <= 0 ? (
          <NoContent
            headline='Payments For Review'
            subtitle='Payments for review will be shown here'
            image={noTransactions}
          />
        ) : (
          <>
            <DataTable
              columns={columns}
              data={
                filters?.length > 1 && activeTab === filters[0]?.value
                  ? paymentsAsApprover
                  : payments
              }
              className='d-none d-md-table'
              expandable={{
                expandedRowRender: (record) => {
                  const formatter = getCurrencyFormatter(record?.currency?.code)

                  return record?.payments?.map((payment) => {
                    return (
                      <tr key={payment?.id} className='bg-light'>
                        {/* 
                      Do not remove height from the td element.
                      The height in the td element is required for the div to take the full height of the cell.
                       */}
                        <td className='p-0' style={{ height: 44 }}>
                          <div
                            className='p-3 h-100'
                            style={{ borderLeft: '3px solid var(--primary)' }}
                          />
                        </td>
                        <td colSpan={3}>
                          {payment?.works?.length <= 0 ? null : (
                            <div className='d-flex flex-column gap-12'>
                              {payment?.works?.map((work) => {
                                return (
                                  <div key={work?.work_id}>
                                    <div className='font-size-14 mb-1 rp-font-bold rp-font-gilroyB text-gray-900'>
                                      {work?.name}
                                    </div>
                                    <div>{work?.details}</div>
                                  </div>
                                )
                              })}
                            </div>
                          )}
                        </td>
                        <td
                          className={cx({
                            'text-danger': isPast(new Date(payment?.due_date)),
                          })}
                        >
                          {payment?.due_date}
                        </td>
                        <td colSpan={2}>{formatter.format(payment?.amount)}</td>
                      </tr>
                    )
                  })
                },
                rowExpandable: (record) => record.payments.length > 0,
              }}
            />

            <div className='d-md-none d-flex flex-column gap-16 p-3'>
              {payments.map((payment) => {
                return (
                  <PaymentCard
                    key={payment?.contract_ref}
                    payment={payment}
                    canManagePayments={canManagePayments}
                    selectedPayments={selectedPayments}
                    onTogglePayment={handleTogglePayment}
                    approvingPayments={approvingPayments}
                  />
                )
              })}
            </div>
          </>
        )}
      </Card>
    </div>
  )
}

function PaymentCard({
  payment,
  canManagePayments,
  selectedPayments,
  onTogglePayment,
  approvingPayments,
}) {
  const info = [
    { title: 'Contact name', value: payment?.contract_name },
    { title: 'Total', value: usdFormatter.format(payment.total_amount) },
  ]

  const contractRef = payment?.contract_ref
  const paymentIds = payment?.payments?.map((payment) => payment.id)

  return (
    <div className='border rp-shadow-2 rounded'>
      <div className='p-3 border-bottom d-flex justify-content-between align-items-center'>
        <ContractorDisplay contractor={payment?.payments?.[0]?.contractor} />

        {!canManagePayments ? null : (
          <Toggle
            check={Object.keys(selectedPayments).includes(contractRef)}
            change={() => onTogglePayment({ contractRef, paymentIds })}
            disabled={approvingPayments}
          />
        )}
      </div>
      <div className='d-flex flex-column gap-12 p-3'>
        {info.map((item, index) => {
          return (
            <div key={index} className='d-flex justify-content-between'>
              <div>{item.title}</div>
              <div>{item.value}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
