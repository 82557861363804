import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import { UncontrolledTooltip } from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'
import calendar from '../../assets/images/calendar.svg'
import help from '../../assets/images/help.svg'
import { useFetch } from '../../helpers/hooks'
import { leaveImpersonation } from '../../services/api'
import {
  changeSidebarType,
  loginUserSuccessful,
  showRightSidebarAction,
  toggleLeftmenu,
  updateAdminToken,
  updateShowCalender,
} from '../../store/actions'
import CalenderModal from '../Calendar'
import HeaderLogoLink from '../Common/header-logo'
import NotificationDropdown from '../CommonForBoth/TopbarDropdown/NotificationDropdown'
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu'
import WiseButton from '../WiseButton'

const StyledHeader = styled.header`
  box-shadow: 0px 0px 3px #eaeaea, inset 0px -1px 0px #eaeaea !important;
  background-color: #fff;
`

const Header = ({
  changeSidebarType,
  toggleLeftmenu,
  leftMenu,
  leftSideBarType,
  location,
  history,
}) => {
  const showCalendar = useSelector((state) => state.Layout?.showCalendar)
  const dispatch = useDispatch()

  const user = useSelector((state) => state.Account?.user)
  const originalUser = useSelector((state) => state.Account?.originalUser)
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const leave = useFetch({
    action: leaveImpersonation,
    withAdminAccess: true,
    onError: toastr.error,
    onComplete: (data) => {
      dispatch(loginUserSuccessful(originalUser))
      dispatch(updateAdminToken(user.admin_token))
      history.push('/admin/users')
    },
  })
  useEffect(() => {
    changeSidebarType('default', isMobile)
  }, [location, isMobile, changeSidebarType])

  function tToggle() {
    toggleLeftmenu(!leftMenu)
    if (leftSideBarType === 'default') {
      changeSidebarType('condensed', isMobile)
    } else if (leftSideBarType === 'condensed') {
      changeSidebarType('default', isMobile)
    }
  }

  return (
    <React.Fragment>
      <StyledHeader id='page-topbar'>
        <div className='navbar-header pr-0 position-relative'>
          <div className='d-flex'>
            <HeaderLogoLink />
          </div>
          {user?.impersonating && (
            <div>
              <WiseButton
                text={'Leave impersonation'}
                minWidth={'32'}
                minHeight={'32px'}
                onClick={() => {
                  leave.startFetch({
                    user_id: user.id,
                    token_id: user.user_token_id,
                  })
                }}
              />
            </div>
          )}
          <div className='d-flex h-100'>
            {/* <LanguageDropdown /> */}

            <button
              className='d-none d-lg-block bg-transparent border-0 p-4 hover:bg-light'
              id='calendar-toggle'
              onClick={() => {
                dispatch(updateShowCalender(true))
              }}
              type='button'
              aria-label='Toggle Calendar'
            >
              <img src={calendar} alt='' width={20} />
            </button>
            <UncontrolledTooltip target='calendar-toggle'>
              Calendar
            </UncontrolledTooltip>

            <a
              className='d-none d-lg-flex p-4 hover:bg-light'
              id='help-center'
              onClick={() => {
                window.analytics.track('Clicked Support', {
                  email_id: user?.email,
                })
              }}
              href='https://help.remotepass.com'
              target='_blank'
              rel='noreferrer'
              aria-label='Help Center'
            >
              <img src={help} alt='' width={20} />
            </a>

            <UncontrolledTooltip target='help-center'>
              Help Center
            </UncontrolledTooltip>

            <NotificationDropdown />

            <button
              type='button'
              onClick={tToggle}
              className='bg-transparent border-0 d-md-none font-size-22 hover:bg-light px-3 text-secondary'
              id='vertical-menu-btn'
              aria-label='Show Side Menu'
            >
              <i className='bx bx-menu' />
            </button>

            <div className='border-left d-none d-md-flex align-items-center'>
              <ProfileMenu isMobile={isMobile} />
            </div>
          </div>
        </div>
      </StyledHeader>
      <div className={'d-none d-md-block'}>
        <CalenderModal
          show={showCalendar}
          hide={() => dispatch(updateShowCalender(false))}
        />
      </div>
    </React.Fragment>
  )
}
const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default withRouter(
  connect(mapStatetoProps, {
    showRightSidebarAction,
    toggleLeftmenu,
    changeSidebarType,
  })(withTranslation()(Header)),
)
