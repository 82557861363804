import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Col,
  FormGroup,
  Label,
  Modal,
  Popover,
  PopoverBody,
  Row,
} from 'reactstrap'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import ControlledInput from '../../../../components/ControlledInput'
import WiseButton from '../../../../components/WiseButton'
import { useResize } from '../../../../helpers/hooks'
import ControlledSelect from '../../../../components/ControlledSelect'
import { Ages } from '../../../Insurance/components/InsuranceForm'

const schema = yup.object().shape({
  plan_id: yup.string().required(),
  members: yup.number().required(),
})
const RequestModal = ({ isOpen, toggle, onConfirm, req }) => {
  const isMobile = useResize()

  const [isAgesPopOpened, setIsAgesPopOpened] = useState(false)
  const [ages, setAges] = useState(req?.dependents?.map((e) => e?.age))
  const defaultValues = {
    plan_id: req?.plan?.id,
    members: req?.dependents?.length,
  }

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  })

  const onSubmit = (v) => {
    onConfirm({ ...v, ages })
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='md'>
      <div className='modal-header p-3 p-md-4'>
        <h5 className='modal-title mt-0'>Review & Submit request</h5>
        <button
          type='button'
          className='close'
          data-dismiss='modal'
          aria-label='Close'
          onClick={toggle}
        >
          <span aria-hidden='true'>&times;</span>
        </button>
      </div>
      <div className='modal-content'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className='p-0 m-0 pb-2 pt-3'>
            <Col md={12} sm={12} className='mb-3'>
              <FormGroup
                style={{ whiteSpace: 'nowrap' }}
                className='d-inline p-0 m-0 '
              >
                <Label className='col-form-label p-0 m-0 mb-2 font-size-14 '>
                  Plan
                </Label>
                <ControlledSelect
                  options={[
                    { label: 'Standard Plan', value: 1 },
                    { label: 'Premium Plan', value: 2 },
                  ]}
                  disabled
                  control={control}
                  error={errors.plan_id}
                  name='plan_id'
                />
              </FormGroup>
            </Col>
            <Col md={12} sm={12} className='mb-3'>
              <FormGroup
                style={{ whiteSpace: 'nowrap' }}
                onClick={() => setIsAgesPopOpened(true)}
                className='d-inline p-0 m-0 '
              >
                <Label className='col-form-label p-0 m-0 mb-2 font-size-14 '>
                  Dependents
                </Label>
                <ControlledInput
                  id='DisabledAutoHideExample'
                  control={control}
                  disabled
                  error={errors.members}
                  name='members'
                />
                <Popover
                  className='dep-popover'
                  placement='bottom'
                  isOpen={isAgesPopOpened}
                  trigger='legacy'
                  target='DisabledAutoHideExample'
                  toggle={() => setIsAgesPopOpened(false)}
                >
                  <PopoverBody>
                    <Ages
                      onChange={(v) => {
                        setAges(v)
                        setValue('members', v?.length)
                      }}
                      savedAges={ages}
                    />
                  </PopoverBody>
                </Popover>
              </FormGroup>
            </Col>

            <Col style={{ height: '80px' }} sm={12} className='d-md-none' />
          </Row>
          <div
            className={`modal-footer ${
              isMobile && 'fixed-bottom'
            } p-3 bg-white p-md-4 justify-content-between justify-content-md-end`}
          >
            <WiseButton
              border
              bk={'white'}
              textColor={'dark'}
              type='button'
              text={' Cancel'}
              bold
              width={'auto'}
              minWidth={'40%'}
              onClick={toggle}
            />

            <WiseButton
              text={'Update Request'}
              bold
              width={'auto'}
              minWidth={'40%'}
              type='submit'
            />
          </div>
        </form>
      </div>
    </Modal>
  )
}

RequestModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default RequestModal
