import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Col, FormGroup, Label, Popover, PopoverBody, Row } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ControlledInput from '../../../components/ControlledInput'
import { useSelector } from 'react-redux'
import ControlledSelect from '../../../components/ControlledSelect'
import { differenceInYears } from 'date-fns'
import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import Button from '../../../components/ui/button'

const schema = yup.object().shape({
  country_id: yup.string().required(),
  members: yup.number(),
  age: yup.number().typeError('Age must be a number').max(99).required(),
})
const InsuranceForm = ({ onSubmit, loading, onError }) => {
  const countries = useSelector((state) => state.Layout?.staticData?.countries)
  const { birth_date: birthDate, country } = useSelector(
    (state) => state.userProfile?.userProfile,
  )
  const contractorAge = differenceInYears(new Date(), new Date(birthDate))

  const [savedAges, setSavedAges] = useState([])
  const [isAgesValid, setIsAgesValid] = useState(true)
  const ref = useRef(null)

  const defaultValues = {
    age: contractorAge,
    country_id: country?.id,
    members: 0,
  }
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  })

  const [isAgesPopOpened, setIsAgesPopOpened] = useState(false)

  const onFormSubmit = (v) => {
    if (!isAgesValid) {
      return onError('Invalid dependent age')
    }
    onSubmit({ ...v, ages: savedAges })
  }
  useEffect(() => {
    if (!isAgesValid) {
      return onError('Invalid dependent age')
    }
    onSubmit(defaultValues)
  }, [])

  const onAgesChange = (ages) => {
    setSavedAges(ages)

    const invalidAges = ages.filter((age) => !age)
    if (invalidAges.length > 0) {
      setIsAgesValid(false)
    } else {
      setIsAgesValid(true)
    }
  }

  return (
    <Card className='mb-3'>
      <form ref={ref} onSubmit={handleSubmit(onFormSubmit)}>
        <Row className='p-0 m-0 pb-2 pt-3'>
          <Col md={5} sm={12} className='p-0 mb-3 pr-md-3 px-3'>
            <FormGroup className='d-inline p-0 m-0'>
              <Label
                style={{ whiteSpace: 'nowrap' }}
                htmlFor='country_id'
                md='3'
                className='col-form-label p-0 m-0 mb-2 font-size-14'
              >
                Country of residence
              </Label>
              <ControlledSelect
                error={errors.country_id}
                control={control}
                name='country_id'
                options={
                  countries
                    ? countries.map((c) => ({
                        ...c,
                        label: c.name,
                        value: c.id,
                        flag: `/${c.svg}`,
                      }))
                    : []
                }
              />
            </FormGroup>
          </Col>
          <Col md={2} sm={12} className='p-0 px-3 pl-md-0 pr-md-0 mb-3'>
            <FormGroup className='d-inline p-0 m-0'>
              <Label
                style={{ whiteSpace: 'nowrap' }}
                htmlFor='age'
                md='3'
                className='col-form-label p-0 m-0 mb-2 font-size-14'
              >
                Age
              </Label>
              <ControlledInput
                maxLength='2'
                type='number'
                control={control}
                name='age'
                id='age'
                error={errors.age}
                disabled
                defaultValue={contractorAge}
              />
            </FormGroup>
          </Col>
          <Col md={3} sm={12} className='p-0 px-3 pr-md-3 mb-3'>
            <FormGroup className='d-inline p-0 m-0'>
              <Label
                md='3'
                className='col-form-label p-0 m-0 mb-2 font-size-14'
              >
                Dependents
              </Label>
              <div
                className='form-control'
                id='DisabledAutoHideExample'
                onClick={() => setIsAgesPopOpened(true)}
              >
                {savedAges?.length} Dependents
              </div>
            </FormGroup>
            <Popover
              className='dep-popover'
              placement='bottom'
              isOpen={isAgesPopOpened}
              trigger='legacy'
              target='DisabledAutoHideExample'
              toggle={() => setIsAgesPopOpened(false)}
            >
              <PopoverBody>
                <Ages onChange={onAgesChange} savedAges={savedAges} />
              </PopoverBody>
            </Popover>
          </Col>
          <Col md={2} sm={12} className='m-0 p-0 px-3 pl-md-0 pr-md-3 mb-3'>
            <FormGroup className='d-inline p-0 m-0'>
              <Label
                md='3'
                className='col-form-label p-0 m-0 mb-2 font-size-14'
              >
                {' '}
              </Label>
              <Button
                className='mt-2'
                block
                type='submit'
                disabled={loading}
                loading={loading}
              >
                Submit
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </form>
    </Card>
  )
}

export const Ages = ({ onChange, savedAges = [] }) => {
  const [ages, setAges] = useState([])

  const increment = () => {
    setAges([...ages, {}])
  }
  const decrement = () => {
    const newAges = [...ages]
    newAges.pop()
    setAges(newAges)
  }

  useEffect(() => {
    onChange(ages.map((e) => e.value))
  }, [ages])

  useEffect(() => {
    const newAges = savedAges.map((e) => ({ label: e, value: e }))
    setAges(newAges)
  }, [])

  return (
    <Col className='p-3'>
      <Row className='border-bottom border-light align-items-center mb-3'>
        <Col>
          <h4>Dependents</h4>
          <p className='text-muted'>Spouse and children</p>
        </Col>
        <Row className='p-0 m-0 p-3 align-items-center'>
          <a
            onClick={decrement}
            style={{ width: 24, height: 24, textAlign: 'center' }}
            className='bg-primary rounded font-size-20 text-white'
          >
            <i className='bx bx-minus' />
          </a>
          <div
            style={{ width: 50, height: 24, textAlign: 'center' }}
            className='px-3'
          >
            <h4>{ages?.length}</h4>
          </div>
          <a
            onClick={increment}
            style={{ width: 24, height: 24, textAlign: 'center' }}
            className='bg-primary rounded font-size-20 text-white'
          >
            <i className='bx bx-plus' />
          </a>
        </Row>
      </Row>
      {ages.map((e, i) => {
        return (
          <Row key={`age-${i}`} className='align-items-center mb-2'>
            <Col>
              <h5>Dependent {i + 1}</h5>
            </Col>
            <Col className='p-0 m-0 pr-3'>
              <CustomSelect
                placeholder={'Age range'}
                value={e?.value ? e : null}
                options={[
                  { label: '0-17', value: '0-17' },
                  { label: '18-39', value: '18-39' },
                  { label: '40-49', value: '40-49' },
                  { label: '50-59', value: '50-59' },
                  { label: '60-74', value: '60-74' },
                ]}
                onChange={(v) => {
                  const newAges = [...ages]
                  newAges[i] = v
                  setAges(newAges)
                }}
              />
            </Col>
          </Row>
        )
      })}
    </Col>
  )
}

const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 0px #dfe1e6;
  border-radius: 4px;
`

InsuranceForm.propTypes = {
  onSubmit: PropTypes.func,
}

export default InsuranceForm
