import { format } from 'date-fns'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Row } from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'

import Avatar from '../../../components/Avatar'
import BadgeX from '../../../components/Table/BadgeX'
import StyledTd from '../../../components/Table/StyledTd'
import StyledTh from '../../../components/Table/StyledTh'
import TableComp from '../../../components/Table/TableComp'
import TableH from '../../../components/Table/TableH'
import { StyledH5 } from '../../../components/Typo'
import Button from '../../../components/ui/button'
import UserFlagging from '../../../components/userFlag'
import { useResize } from '../../../helpers/hooks'
import { downloadInvoice } from '../../../services/api'

const InsurancePayments = ({ payments, planDetails }) => {
  const isMobile = useResize()
  const info = useSelector((state) => state?.userProfile?.userProfile)

  const history = useHistory()
  const handlePay = (item) => {
    history.push(`/pay-insurance/${item.id}`, { amount: item.amount })
  }

  const user = useSelector((state) => state?.Account?.user)

  const handleDownloadInvoice = (item) => {
    downloadInvoice(item.invoice_ref, user?.token, item.invoice_token).then(
      (r) => {
        if (r.data?.data?.error || r.data?.message) {
          toastr.error(
            r.data?.data?.error || r.data?.message || 'An error occurred',
          )
          return
        }
        const url = window.URL.createObjectURL(new Blob([r.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${item?.invoice_ref}.pdf`) // or any other extension
        document.body.appendChild(link)
        link.click()
      },
    )
  }

  return (
    <Card className='mb-3'>
      <div className='p-4 border-bottom'>
        <h5 className='rp-font-gilroyB' style={{ color: 'var(--gray-h)' }}>
          Next billing date{' '}
          {format(new Date(planDetails?.next_billing), 'dd/MM/yyyy')}
        </h5>

        {!!info?.auto_withdraw && (
          <div className='font-size-14' style={{ color: 'var(--gray)' }}>
            Auto-withdraw is enabled on your account. Your monthly health
            insurance payments will be automatically deducted from your balance.
          </div>
        )}
      </div>

      <TableH
        title='Payments'
        noPadding
        className='px-3 px-md-4 pt-4 pb-1'
        style={{ color: 'var(--gray-h)' }}
      />

      {isMobile ? (
        <div
          className='d-flex flex-column px-3 pb-4 pt-2'
          style={{ gap: '1rem' }}
        >
          {React.Children.toArray(
            payments?.map((order, key) => (
              <PaymentCardAdmin
                item={order}
                key={key}
                onPay={handlePay}
                onDownloadInvoice={handleDownloadInvoice}
              />
            )),
          )}
        </div>
      ) : (
        <TableComp>
          <thead style={{ color: 'var(--gray-h)' }}>
            <tr>
              <StyledTh>Billing Period</StyledTh>
              <StyledTh>Status</StyledTh>
              <StyledTh>Amount</StyledTh>
              <StyledTh>Actions</StyledTh>
            </tr>
          </thead>
          <tbody>
            {payments?.map((order, key) => {
              return (
                <Line
                  item={order}
                  key={key}
                  onPay={handlePay}
                  downloadInvoice={handleDownloadInvoice}
                />
              )
            })}
          </tbody>
        </TableComp>
      )}
    </Card>
  )
}

export const getStatusColor = (status, dueDate) => {
  const diff = moment(dueDate).diff(moment(), 'days')
  switch (status) {
    case 'Processing':
      return 'warning'
    case 'Paid':
      return 'success'
    case 'Overdue':
      return 'danger'
    case 'Unpaid':
      if (diff < 0) {
        return 'danger'
      } else {
        return 'warning'
      }
    case 'Not paid':
      return 'danger'
    case 'Cancelled':
      return 'secondary'
    default:
      return 'primary'
  }
}
export const getStatusText = (status, dueDate) => {
  const diff = moment(dueDate * 1000).diff(moment(), 'days')
  if (status === 'Unpaid') {
    if (diff < 0) {
      return `Overdue ${Math.abs(diff)} days`
    } else if (diff === 0) {
      return 'Due today'
    } else {
      return `Due in ${diff} days`
    }
  } else {
    return status
  }
}

export const Line = ({
  item,
  isAdmin,
  onUserClick,
  onPay,
  downloadInvoice,
  onUpdate,
}) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: item?.currency?.code || 'USD',
  })

  return (
    <tr>
      {isAdmin && (
        <StyledTd className='mb-0 font-size-14'>{item.payment_ref}</StyledTd>
      )}
      {isAdmin && (
        <td className='px-0 py-1'>
          <button
            onClick={() => {
              onUserClick(item?.contractor?.id)
            }}
            className='d-flex px-4 py-2 w-100 bg-transparent border-0'
          >
            <Row className='align-items-center flex-nowrap m-0 p-0'>
              <Avatar
                name={item?.contractor?.first_name}
                photo={item?.contractor?.img}
                flag={item?.contractor?.flag}
              />
              <div
                style={{ fontWeight: '600', marginLeft: 10, zIndex: 910 }}
                className='d-flex text-primary'
              >
                {item.contractor.is_flagged ? (
                  <UserFlagging
                    user={item?.contractor}
                    onCompleteAction={() => {
                      onUpdate()
                    }}
                  />
                ) : null}
                {item?.contractor?.first_name} {item?.contractor?.last_name}
              </div>
            </Row>
          </button>
        </td>
      )}
      <StyledTd className='mb-0 font-size-14'>{item.details}</StyledTd>
      <StyledTd>
        <BadgeX
          status={getStatusColor(item.status, item?.due_date)}
          textStatus={getStatusColor(item.status, item?.due_date)}
          name={
            isAdmin ? item.status : getStatusText(item.status, item?.due_date)
          }
        />
      </StyledTd>
      <StyledTd>{formatter.format(item.amount)}</StyledTd>
      <StyledTd>
        <Row className='align-items-center mx-0' style={{ gap: '1rem' }}>
          {!!item.invoice_token && (
            <Button
              type='button'
              outline
              color='light'
              size='sm'
              style={{ color: 'var(--gray-h)' }}
              icon={<i className='bx bx-download font-size-18' />}
              onClick={() => downloadInvoice(item)}
            >
              Invoice
            </Button>
          )}

          {item.status === 'Unpaid' && !isAdmin && (
            <Button
              type='button'
              outline
              color='light'
              size='sm'
              style={{ color: 'var(--gray-h)' }}
              icon={<i className='bx bx-money font-size-18' />}
              onClick={() => onPay(item)}
            >
              Pay
            </Button>
          )}
        </Row>
      </StyledTd>
    </tr>
  )
}

const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 0px #dfe1e6;
  border-radius: 4px;
`

const PaymentCardAdmin = ({ item, onPay, onDownloadInvoice, isAdmin }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: item?.currency?.code || 'USD',
  })

  return (
    <div
      className='d-flex flex-column rounded border p-3'
      style={{ color: 'var(--gray-h)', gap: '1rem' }}
    >
      <StyledH5
        className='font-weight-normal my-0 font-size-14'
        style={{ color: 'var(--gray-h)' }}
      >
        {item?.details}
      </StyledH5>
      <div className='d-flex align-items-center justify-content-between'>
        <span>Status</span>
        <BadgeX
          status={getStatusColor(item.status, item?.due_date)}
          textStatus={getStatusColor(item.status, item?.due_date)}
          name={
            isAdmin ? item.status : getStatusText(item.status, item?.due_date)
          }
        />
      </div>
      <div className='d-flex align-items-center justify-content-between'>
        <span>Amount</span>
        <span>{formatter.format(item.amount)}</span>
      </div>
      <div
        className='d-flex justify-content-between align-items-center'
        style={{ gap: '1rem' }}
      >
        {!!item.invoice_token && (
          <Button
            type='button'
            outline
            color='light'
            size='sm'
            className='flex-grow-1'
            style={{ color: 'var(--gray-h)' }}
            icon={<i className='bx bx-download font-size-18' />}
            onClick={() => onDownloadInvoice(item)}
          >
            Invoice
          </Button>
        )}
        {item.status === 'Unpaid' && !isAdmin && (
          <Button
            type='button'
            outline
            color='light'
            size='sm'
            className='flex-grow-1'
            style={{ color: 'var(--gray-h)' }}
            onClick={() => onPay(item)}
            icon={<i className='bx bx-money font-size-18' />}
          >
            Pay
          </Button>
        )}
      </div>
    </div>
  )
}

export default InsurancePayments
