import { yupResolver } from '@hookform/resolvers/yup'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import axios from 'axios'
import cx from 'classnames'
import format from 'date-fns/format'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { useForm } from 'react-hook-form'
import Paginations from 'react-js-pagination'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import {
  Badge,
  ButtonDropdown,
  Card,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  NavItem,
  NavLink,
  Popover,
  Row,
  Spinner,
  Table,
} from 'reactstrap'
import ModalBody from 'reactstrap/lib/ModalBody'
import ModalFooter from 'reactstrap/lib/ModalFooter'
import toastr from 'toastr'
import * as yup from 'yup'

import archiveIconHover from '../../../../assets/images/archive.svg'
import archiveIcon from '../../../../assets/images/archiveBlack.svg'
import unArchiveIcon from '../../../../assets/images/unarchive.svg'
import unArchiveIconHover from '../../../../assets/images/unarchiveHover.svg'
import ActionsDropdown from '../../../../components/ActionsDropdown'
import customStyles from '../../../../components/Common/react-select-custom-styles'
import ControlledInput from '../../../../components/ControlledInput'
import CustomDatePicker from '../../../../components/Forms/CustomDatePicker/CustomDatePicker'
import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import Toggle from '../../../../components/Forms/Toggle/Toggle'
import SearchBar from '../../../../components/SearchBar'
import BadgeX from '../../../../components/Table/BadgeX'
import { StyledH5, StyledH6, StyledP } from '../../../../components/Typo'
import Button from '../../../../components/ui/button'
import DataTable from '../../../../components/ui/data-table'
import UserComments from '../../../../components/user-comments/user-comments'
import UserFlagging from '../../../../components/userFlag'
import WiseButton from '../../../../components/WiseButton'
import ADMIN_PERMISSIONS from '../../../../config/admin-permissions'
import { storage } from '../../../../helpers/config'
import { useFetch } from '../../../../helpers/hooks'
import useHasPermission from '../../../../helpers/hooks/admin/has-permission'
import {
  archiveTransaction,
  confirmTransaction,
  createZendeskTicket,
  downloadProviderReceipt,
  downloadReceipt,
  downloadWithdrawalReceipt,
  exportWithdraw,
  getAdminCompanies,
  getPaymentReminderHistory,
  getTransactionList,
  manualProcessTransaction,
  multipleProcessWithdraw,
  processWithdraw,
  revertPaymentTransaction,
  revertTransaction,
  reviewTransaction,
  sendPaymentReminder,
  setPreferedProvider,
  unprocessWithdraw as unprocessWithdrawAction,
} from '../../../../services/api'
import { track } from '../../../../utils/analytics'
import capitalizeFirstLetter from '../../../../utils/capitalize-first-letter'
import EVENTS from '../../../../utils/events'
import openFile from '../../../../utils/file/open'
import { getCurrencyFormatter } from '../../../../utils/formatters/currency'
import { StyledNav } from '../../../Contract/ContractList'
import { StyledIcon } from '../../../Contract/ContractList/ContractList'
import ContractRef from '../../components/ContractRef'
import { IssuesDisplay } from '../beneficiaries'
import { BurgerDetails } from '../Users/users'
import ExportReportModal from './ExportReportModal'
import ExportTrxModal from './ExportTrxModal'
import { ManualProcessModal } from './ManualProcessModal'
import PreferedProviderModal from './prefered-provider-modal'
import { RevertTrxModal } from './RevertTrxModal'
import TransactionDetailsSider from './transaction-details-sider'
import UnprocessModal from './unprocess-modal'

const tabsOptions = [
  { label: 'Payments', value: '1' },
  { label: 'Withdraws', value: '2' },
  { label: 'Insurances', value: '3' },
  { label: 'Archived', value: '4' },
  { label: 'Reverted transactions', value: '5', tabKey: 'reverted_withdraws' },
]

const statuses = [
  { label: 'Processing', value: 'processing' },
  { label: 'Confirmed', value: 'confirmed' },
]

const paymentMethodOptions = [
  { label: 'Select payment method', value: null },
  { value: 3, label: 'Bank Transfer' },
  { value: 2, label: 'Paypal' },
  { value: 7, label: 'Payoneer' },
  { value: 6, label: 'CashPoint' },
  { value: 9, label: 'Coinbase' },
]

const searchBarPlaceholder = 'Search by user name, transaction ID, or email'
const defaultCompany = { label: 'Select Company', value: null }

const BULK_PROCESS_PROVIDERS = ['ebury', 'wise', 'transfermate']

const Transactions = () => {
  const user = useSelector((state) => state.Account?.user)

  const [activePage, setActivePage] = useState(1)
  const [activeTab, setActiveTab] = useState(tabsOptions[0].value)
  const [query, setQuery] = useState(null)

  const [selectedUser, setSelectedUser] = useState(null)
  const [show, setShow] = useState(false)
  const [trxDetail, setTrxDetail] = useState(null)
  const [showExportModal, setShowExportModal] = useState(false)
  const [showExportPayment, setShowExportPayment] = useState(false)
  const [expanded, setExpanded] = useState(-1)
  const [date, setDate] = useState(null)
  const [archiving, setArchiving] = useState(-1)
  const [exporting, setExporting] = useState(null)
  const [status, setStatus] = useState(statuses[0])
  const [isOpen, setIsOpen] = useState(false)

  const [toConfirm, setToConfirm] = useState(null)
  const [toRevert, setToRevert] = useState(null)
  const [toManualProcess, setToManualProcess] = useState(null)
  const [isRevertOpen, setIsRevertOpen] = useState(false)
  const [isManualProcessOpen, setIsManualProcessOpen] = useState(false)
  const [isRevertPaymentOpen, setIsRevertPaymentOpen] = useState(false)
  const [balance, setBalance] = useState(0)
  const [sendEmail, setSendEmail] = useState(false)
  const [isPreferedProvider, setIsPreferedProvider] = useState(null)
  const [unprocessModal, setUnprocessModal] = useState(null)
  const [showMultipleConfirm, setShowMultipleConfirm] = useState(false)

  const [company, setCompany] = useState(defaultCompany)
  const [paymentMethodFilter, setPaymentMethodFilter] = useState(
    paymentMethodOptions[0],
  )
  const [trxData, setTrxData] = useState(null)
  const [selectedWithdraws, setSelectedWithdraws] = useState([])

  const hasEditPermission = useHasPermission(
    ADMIN_PERMISSIONS.MANAGE_TRANSACTIONS,
  )

  const {
    // data: trxDt,
    isLoading: isTrxLoading,
    paginator: trxPaginator,
    startFetch: startTrxFetch,
  } = useFetch({
    autoFetch: false,
    initResult: null,
    action: getTransactionList,
    withAdminAccess: true,
    onComplete: (data) => {
      setTrxData(
        data.map((dt) => ({
          ...dt,
          selected: false,
        })),
      )
    },
  })

  const { data: companiesList } = useFetch({
    autoFetch: true,
    action: getAdminCompanies,
    withAdminAccess: true,
  })

  function handleDownloadInvoice(transaction) {
    track(EVENTS.CLICKED_DOWNLOAD_RECEIPT, {
      transaction_id: transaction.transaction_ref,
    })

    return downloadReceipt(
      transaction.transaction_ref,
      user?.token,
      transaction.token,
    )
      .then((r) => {
        openFile(r?.data, `${transaction?.transaction_ref}.pdf`)
      })
      .catch(() => {
        toastr.error('Error downloading receipt', 'Error')
      })
  }

  const confirm = useFetch({
    autoFetch: false,
    initResult: null,
    action: confirmTransaction,
    withAdminAccess: true,
    onComplete: () => {
      setIsOpen(false)
      setToConfirm(null)
      applyFilter(null, true)
      setBalance(0)
      setSendEmail(false)
    },
  })
  const revert = useFetch({
    autoFetch: false,
    initResult: null,
    action: revertTransaction,
    withAdminAccess: true,
    onComplete: () => {
      setIsRevertOpen(false)
      setToRevert(null)
      applyFilter(null, true)
    },
  })
  const manualProcess = useFetch({
    action: manualProcessTransaction,
    withAdminAccess: true,
    onComplete: () => {
      setToManualProcess(null)
      setIsManualProcessOpen(false)
      applyFilter(null, true)
    },
    onError: (err) => {
      toastr.error(err)
    },
  })
  const revertPayment = useFetch({
    autoFetch: false,
    initResult: null,
    action: revertPaymentTransaction,
    withAdminAccess: true,
    onComplete: () => {
      setIsRevertPaymentOpen(false)
      setToRevert(null)
      applyFilter(null, true)
    },
  })
  const review = useFetch({
    autoFetch: false,
    initResult: null,
    action: reviewTransaction,
    withAdminAccess: true,
    onComplete: () => {
      applyFilter(null, true)
    },
  })
  const preferedProvider = useFetch({
    action: setPreferedProvider,
    withAdminAccess: true,
    onComplete: () => {
      setIsPreferedProvider(null)
      applyFilter(null, true)
    },
  })

  const handleConfirm = (balance, email) => {
    confirm.startFetch({
      transaction_id: toConfirm?.transaction_id,
      received_amount: balance,
      received_amount_email: email,
    })
  }
  const handleConfirmRevert = (reason, reasonCustom, archiveBank) => {
    const body = {
      transaction_id: toRevert?.transaction_id,
      reason,
      archive_bank_account: archiveBank,
    }
    if (reason === 'custom') {
      body.reason_custom = reasonCustom
    }
    revert.startFetch(body)
  }
  const handleConfirmManualProcess = (data) => {
    manualProcess.startFetch({
      ...data,
      transaction_id: toManualProcess?.transaction_id,
    })
  }
  const handleConfirmRevertPayment = () => {
    const body = {
      transaction_id: toRevert?.transaction_id,
    }
    revertPayment.startFetch(body)
  }
  const handleReview = (trx) => {
    const body = {
      transaction_id: trx?.transaction_id,
      under_review: !trx?.under_review,
    }
    review.startFetch(body)
  }
  function handleSetPreferedProvider(newProvider) {
    preferedProvider.startFetch({
      transaction_id: isPreferedProvider?.transaction_id,
      preferred_processor: newProvider,
    })
  }

  const { startFetch: unprocessWithdraw, isLoading: unprocessing } = useFetch({
    action: unprocessWithdrawAction,
    withAdminAccess: true,
    onComplete: () => {
      setUnprocessModal(null)
      applyFilter(null, true)
    },
  })

  function handleUnprocessWithdraw(id, reason) {
    unprocessWithdraw({ transaction_id: id, reason })
  }

  const applyFilter = useCallback(
    (cancelToken) => {
      const body = {
        page: activePage,
      }
      if (activeTab === '1' && company?.value) {
        body.company_id = company?.value
      }
      if (activeTab === '4') {
        body.archived = 1
      } else {
        if (activeTab !== '5') {
          body.archived = 0
        }
        const selectedTab = tabsOptions.find(({ value }) => value === activeTab)
        body.tab = selectedTab?.tabKey ?? selectedTab?.label.toLowerCase()
      }
      if (date) {
        body.month = new Date(date).getMonth() + 1
        body.year = new Date(date).getFullYear()
      }
      // limit how many requests we send to the server
      if (query && query?.length > 2) {
        body.search = query
      }
      if (status?.value !== -1 && activeTab !== '5') {
        body.status = status?.value
      }
      if (activeTab === '2') {
        setSelectedWithdraws([])
      }
      if (activeTab === '2' && paymentMethodFilter?.value) {
        body.payment_method_id = paymentMethodFilter?.value
      }
      // const loading = !date && !query && state?.value === -1 && !noLoading
      startTrxFetch(body, true, cancelToken)
    },
    // disabled exhaustive-deps because startTrxFetch is not memoized
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      activePage,
      activeTab,
      date,
      query,
      status?.value,
      company?.value,
      paymentMethodFilter?.value,
    ],
  )

  useEffect(() => {
    let cancel
    // eslint-disable-next-line no-return-assign
    applyFilter(new axios.CancelToken((c) => (cancel = c)))
    return () => {
      if (cancel) cancel()
    }
  }, [date, query, activeTab, status, activePage, applyFilter])

  const archive = useFetch({
    action: archiveTransaction,
    withAdminAccess: true,
    onComplete: () => {
      setArchiving(-1)
      applyFilter(null, true)
    },
  })

  const exportTransaction = useFetch({
    action: exportWithdraw,
    withAdminAccess: true,
    onComplete: (data, body) => {
      openFile(data, body?.transaction_id, '_transactions.xlsx', () => {
        setExporting(null)
      })
    },
  })
  const multipleProcess = useFetch({
    action: multipleProcessWithdraw,
    withAdminAccess: true,
    onComplete: () => applyFilter(null, true),
    onError: (err) => {
      toastr.error(err)
    },
  })
  const handleSearch = (query) => {
    setActivePage(1)
    setQuery(query)
  }

  const handleTrxChecked = (data) => {
    const newData = [...trxData]
    const index = newData.findIndex(
      (e) => e.transaction_id === data.transaction_id,
    )
    newData[index].selected = !newData[index].selected
    setTrxData(newData)
    if (activeTab === '2') {
      const newWithdraws = [...selectedWithdraws]
      if (
        newWithdraws.filter((e) => e.transaction_id === data.transaction_id)
          .length > 0
      ) {
        const withdrawIndex = newWithdraws.findIndex(
          (e) => e.transaction_id === data.transaction_id,
        )
        newWithdraws.splice(withdrawIndex, 1)
        setSelectedWithdraws(newWithdraws)
      } else {
        newWithdraws.push({
          transaction_id: data.transaction_id,
          amount:
            data?.provider === 'coinbase' ? data?.base_amount : data?.total,
          source: data?.base_currency?.code,
          target: data?.currency?.code,
          provider: data?.provider,
        })
        setSelectedWithdraws(newWithdraws)
      }
    }
  }
  function handleClearFilter() {
    setActivePage(1)
    setQuery('')
    setDate(null)
    setStatus(statuses[0])
    setCompany(defaultCompany)
  }
  const handleMultipleProcessing = () => {
    setShowMultipleConfirm(false)
    const body = {
      transactions: selectedWithdraws,
    }
    multipleProcess.startFetch(body)
  }

  const handleUserClick = useCallback((data) => {
    setSelectedUser({
      id: data?.client?.id,
      selectedBankId: data?.bank_account_details?.account_id,
    })
    setShow(true)
  }, [])

  const revertedColumns = useMemo(
    () => [
      {
        Header: '',
        headerClassName: 'sticky-col first-col',
        className: 'sticky-col first-col',
        Cell: () => '',
      },
      {
        Header: 'TRX ID',
        headerClassName: 'sticky-col second-col',
        className: 'sticky-col second-col',
        accessor: 'transaction_ref',
      },
      {
        Header: 'Contracts ID',
        Cell: ({ rowData }) => {
          return (
            <div
              className='d-flex flex-column overflow-auto'
              style={{ maxHeight: '4ch' }}
            >
              {rowData?.contracts.map((id) => {
                return (
                  <Link
                    key={id}
                    target='_blank'
                    to={`contract-detail?id=${id}`}
                  >
                    {id}
                  </Link>
                )
              })}
            </div>
          )
        },
      },
      {
        Header: 'Name',
        headerClassName: 'sticky-col third-col',
        className: 'sticky-col third-col text-truncate',
        Cell: ({ rowData }) => {
          return (
            <UserFullName
              prefillComment={prefillComment(rowData, activeTab)}
              data={rowData}
              onCompleteAction={() => {
                applyFilter(null, true)
              }}
              onUserClick={() => handleUserClick(rowData)}
            />
          )
        },
      },
      {
        Header: 'Account holder',
        headerClassName: 'sticky-col forth-col',
        className: 'sticky-col forth-col',
        Cell: ({ rowData }) => {
          return (
            <>
              <div
                className='text-truncate d-inline-block'
                style={{ maxWidth: 'calc(100% - 20px)' }}
                title={rowData.bank_account_details?.accountHolderName}
              >
                {rowData.bank_account_details?.accountHolderName}
              </div>

              {rowData?.account_status ? (
                <i
                  className={`bx ${
                    rowData.account_status === 'verified'
                      ? 'bxs-check-shield text-success'
                      : rowData.account_status === 'unverified'
                      ? 'bxs-shield-x text-danger'
                      : 'bxs-notification-off text-secondary'
                  } font-size-16 ml-1 position-absolute`}
                />
              ) : null}
            </>
          )
        },
      },
      { Header: 'Type', accessor: 'type.name' },
      { Header: 'Method', accessor: 'method.name' },
      { Header: 'Email', accessor: 'client.email' },
      { Header: 'Company name', accessor: 'company_name' },
      {
        Header: 'Amount',
        Cell: ({ rowData }) => {
          const formatter = getCurrencyFormatter(
            rowData?.currency?.code,
            undefined,
            {
              maximumSignificantDigits:
                rowData?.provider === 'coinbase' ? 6 : undefined,
            },
          )

          return formatter.format(rowData.amount)
        },
      },
      { Header: 'TRX date', accessor: 'created_at' },
      // { Header: 'Confirmed at', accessor: 'confirmed_at' },
      {
        Header: 'Source',
        Cell: ({ rowData }) => {
          const sourceFormatter = getCurrencyFormatter(
            rowData?.base_currency?.code,
          )
          return sourceFormatter.format(rowData.base_amount)
        },
      },
      {
        Header: 'Target',
        Cell: ({ rowData }) => {
          const formatter = getCurrencyFormatter(
            rowData?.currency?.code,
            undefined,
            {
              maximumSignificantDigits:
                rowData?.provider === 'coinbase' ? 6 : undefined,
            },
          )
          const usdFormatter = getCurrencyFormatter()

          return (
            <>
              {rowData.total_usd && (
                <span className='font-weight-semibold'>{`(${usdFormatter.format(
                  rowData.total_usd,
                )})`}</span>
              )}
              {formatter.format(Number(rowData.total))}
            </>
          )
        },
      },
      {
        Header: 'Rate',
        accessor: 'rate',
      },
      {
        Header: 'ETA',
        accessor: 'eta',
      },
      {
        Header: 'Fee',
        Cell: ({ rowData }) => {
          const formatter = getCurrencyFormatter(
            rowData?.currency?.code,
            undefined,
            {
              maximumSignificantDigits:
                rowData?.provider === 'coinbase' ? 6 : undefined,
            },
          )
          return formatter.format(Number(rowData?.fee))
        },
      },
      {
        Header: 'Provider',
        accessor: 'provider',
      },
      {
        Header: 'Reason',
        accessor: 'reason',
      },
      {
        Header: 'Reverted by',
        accessor: 'reverted_by',
      },
    ],
    [applyFilter, handleUserClick],
  )

  const prefillComment = useCallback((order, activeTab) => {
    return ['1', '2'].includes(activeTab) ? `${order?.transaction_ref} ` : null
  }, [])

  return (
    <div className='page-content'>
      <BurgerDetails
        id={selectedUser?.id}
        selectedBankId={selectedUser?.selectedBankId}
        hide={() => {
          setShow(false)
          setSelectedUser({})
        }}
        show={show}
      />
      <TransactionDetailsSider
        transaction={trxDetail}
        hide={() => setTrxDetail(null)}
        isOpen={!!trxDetail}
      />

      <Container fluid className='p-0 m-0'>
        <Row className='justify-content-end mb-4 mx-0'>
          <Col xs={12} md={4} className='px-0 mb-2 mb-md-0'>
            <StyledH5 min='22px' max='32px' className='mb-0'>
              Transactions
            </StyledH5>
          </Col>
          <Col xs={12} md={8} className='px-0'>
            <div
              className='d-flex justify-content-md-end flex-wrap'
              style={{ gap: '0.5rem' }}
            >
              <Link
                to='/admin/fee-simulator'
                className='btn btn-primary px-2 py-1 px-md-4 py-md-2'
              >
                Fee simulator
              </Link>
              {!hasEditPermission ? null : (
                <>
                  <button
                    className='btn btn-primary px-2 py-1 px-md-4 py-md-2'
                    type='button'
                    onClick={() => setShowExportModal(true)}
                  >
                    Export Withdraw
                  </button>
                  <button
                    className='btn btn-primary px-2 py-1 px-md-4 py-md-2'
                    type='button'
                    onClick={() => setShowExportPayment(true)}
                  >
                    Export Report
                  </button>
                </>
              )}
            </div>
            {!hasEditPermission ? null : (
              <>
                <ExportTrxModal
                  isOpen={showExportModal}
                  toggle={() => setShowExportModal(false)}
                  title={'Export Withdraw Transactions'}
                />
                <ExportReportModal
                  isOpen={showExportPayment}
                  toggle={() => setShowExportPayment(false)}
                />
              </>
            )}
          </Col>
        </Row>
        <Card>
          <Row
            style={{ borderTopLeftRadius: 4, borderTopRightRadius: 4 }}
            className='p-3 p-0 m-0 d-block d-md-none justify-content-between align-items-center border-bottom bg-white'
          >
            <CustomSelect
              name='Active'
              value={activeTab.label}
              defaultValue={tabsOptions[0]}
              onChange={(v) => {
                setActivePage(1)
                setActiveTab(v.value)
              }}
              options={tabsOptions}
            />
          </Row>

          <div className='d-none d-md-flex justify-content-between align-items-center m-0 p-3'>
            <StyledNav tabs>
              {tabsOptions.map(({ value, label }) => (
                <NavItem key={`tab-${value}`}>
                  <NavLink
                    className={cx(
                      { active: activeTab === value },
                      'bg-white font-size-14',
                    )}
                    onClick={() => {
                      setActivePage(1)
                      setActiveTab(value)
                    }}
                    tag='button'
                  >
                    {label}
                  </NavLink>
                </NavItem>
              ))}
            </StyledNav>
          </div>

          <Col>
            <Row className='p-3' style={{ gap: '0.75rem' }}>
              {activeTab === '5' ? null : (
                <Col md={3} xs={12} className='p-0'>
                  <CustomSelect
                    options={statuses}
                    value={status}
                    onChange={(e) => {
                      setActivePage(1)
                      setStatus(e)
                    }}
                  />
                </Col>
              )}

              <Col md={3} xs={12} className='p-0'>
                <CustomDatePicker
                  showMonthYearPicker
                  placeholder='Filter by month'
                  dateFormat='MMM - yyyy'
                  value={date}
                  handleOnChange={(v) => {
                    setActivePage(1)
                    setDate(v)
                  }}
                />
              </Col>

              {activeTab === '2' && (
                <Col md={3} xs={12} className='p-0'>
                  <CustomSelect
                    options={paymentMethodOptions}
                    value={paymentMethodFilter}
                    onChange={setPaymentMethodFilter}
                    styles={customStyles}
                  />
                </Col>
              )}

              {activeTab === '1' && (
                <Col md={3} xs={12} className='p-0'>
                  <CustomSelect
                    options={
                      Array.isArray(companiesList)
                        ? [
                            defaultCompany,
                            ...companiesList.map((e) => ({
                              label: e.name,
                              value: e.id,
                            })),
                          ]
                        : [defaultCompany]
                    }
                    value={company}
                    onChange={setCompany}
                  />
                </Col>
              )}

              <Col md={2} xs={12} className='p-0'>
                <Button color='primary' onClick={handleClearFilter}>
                  Clear Filter
                </Button>
              </Col>
            </Row>
            <Row className='p-3'>
              <Col md={12} xs={12} className={'p-0'}>
                <SearchBar
                  query={query}
                  onQueryChanged={handleSearch}
                  placeholder={searchBarPlaceholder}
                  className='mr-md-2'
                />
              </Col>
            </Row>
            {activeTab === '2' && (
              <Row className='align-items-end justify-content-end p-3'>
                <WiseButton
                  onClick={() => {
                    setShowMultipleConfirm(true)
                  }}
                  height={'48px'}
                  minHeight={'48px'}
                  width={'auto'}
                  minWidth={'100%'}
                  disabled={selectedWithdraws.length === 0}
                  textColor={'white'}
                  bk={selectedWithdraws.length === 0 ? 'secondary' : 'primary'}
                  bold
                  text={'Process Payments'}
                />
                <WiseButton
                  onClick={() => {
                    applyFilter(null, true)
                  }}
                  height={'48px'}
                  minHeight={'48px'}
                  width={'auto'}
                  minWidth={'100%'}
                  textColor={'white'}
                  bk={'primary'}
                  bold
                  text={'Refresh Payments'}
                  className='ml-2'
                />
              </Row>
            )}
          </Col>

          <div className='position-relative' style={{ minHeight: '10rem' }}>
            {!trxData || trxData.length <= 0 ? (
              <div
                style={{ minHeight: '10rem', fontWeight: 300, opacity: 0.7 }}
                className='d-flex justify-content-center align-items-center flex-column text-center'
              >
                {isTrxLoading ? null : (
                  <>
                    <p
                      className='text-secondary h4'
                      style={{ fontWeight: 300 }}
                    >
                      No data found.
                    </p>
                    <p
                      className='text-secondary mb-0 h4'
                      style={{ fontWeight: 300 }}
                    >
                      {searchBarPlaceholder} to get some data.
                    </p>
                  </>
                )}
              </div>
            ) : (
              <>
                <div className='p-3 m-0 d-block d-md-none'>
                  {trxData &&
                    trxData.length > 0 &&
                    trxData?.map((order, key) => (
                      <TransactionCardAdmin
                        archive={archive}
                        setToConfirm={(order) => setToConfirm(order)}
                        setIsOpen={() => setIsOpen(true)}
                        order={order}
                        key={key}
                        onUpdate={() => applyFilter(null, true)}
                        withProcess={activeTab === 2}
                        index={key}
                        multipleLoading={multipleProcess.isLoading}
                        selectedWithdraws={selectedWithdraws}
                      />
                    ))}
                </div>
                {activeTab === '5' ? (
                  /* Reverted transactions */
                  <DataTable
                    columns={revertedColumns}
                    data={trxData}
                    responsive
                    className='d-none d-md-table'
                  />
                ) : (
                  <div
                    className='table-responsive d-none d-md-block'
                    style={{ paddingBottom: trxData.length < 8 ? 300 : null }}
                  >
                    <Table className='table table-centered table-nowrap text-muted'>
                      <thead>
                        <tr>
                          <th className='border-top-0 sticky-col first-col'></th>
                          <th className='border-top-0 sticky-col second-col'>
                            TRX ID
                          </th>
                          {activeTab === '2' && (
                            <th className='border-top-0 first-col'>
                              Contract ID
                            </th>
                          )}

                          <th className='border-top-0 border-col sticky-col third-col'>
                            Name
                          </th>
                          {activeTab === '2' && (
                            <th className='border-top-0 border-col sticky-col forth-col'>
                              Account holder
                            </th>
                          )}
                          {activeTab === '2' && (
                            <th className='border-top-0 border-col' />
                          )}
                          <th className='border-top-0'>Type</th>
                          {activeTab === '1' && (
                            <th className='border-top-0'>Bank Name</th>
                          )}
                          {activeTab === '2' && (
                            <th className='border-top-0'>Bank country</th>
                          )}
                          <th className='border-top-0'>Method</th>
                          <th className='border-top-0'>Email</th>
                          <th className='border-top-0'>Company name</th>
                          <th className='border-top-0 text-right'>
                            {activeTab === '1' ? 'Total' : 'Amount'}
                          </th>
                          <th className='border-top-0'>TRX date</th>
                          {activeTab === '2' && (
                            <th className='border-top-0'>Provider amount</th>
                          )}
                          <th className='border-top-0'>Confirmed at</th>
                          {activeTab !== '1' && (
                            <th className='border-top-0'>Source</th>
                          )}
                          {activeTab !== '1' && (
                            <th className='border-top-0'>Target</th>
                          )}
                          <th className='border-top-0'>Rate</th>
                          {activeTab === '1' && (
                            <th className='border-top-0'>Markup</th>
                          )}
                          {activeTab !== '1' && (
                            <th className='border-top-0'>ETA</th>
                          )}
                          <th className='border-top-0'>Fee</th>
                          <th className='border-top-0'>TRX Status</th>
                          {activeTab !== '1' && (
                            <th className='border-top-0'>Provider</th>
                          )}
                          {activeTab === '2' && (
                            <th className='border-top-0'>Process</th>
                          )}
                          {!hasEditPermission ? null : (
                            <>
                              {!['1', '2'].includes(activeTab) && (
                                <th className='border-top-0'>Export</th>
                              )}
                              {!['1', '2'].includes(activeTab) && (
                                <th className='border-top-0'>Archive</th>
                              )}
                              {['1', '2'].includes(activeTab) ? (
                                <th className='border-top-0'>Actions</th>
                              ) : null}
                              {activeTab === '2' && (
                                <th className='border-top-0'></th>
                              )}
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {!trxData || trxData.length <= 0
                          ? null
                          : trxData.map((order, key) => {
                              const formatter = new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: order?.currency?.code || 'USD',
                                maximumSignificantDigits:
                                  order?.provider === 'coinbase'
                                    ? 6
                                    : undefined,
                              })
                              const providerFormatter = getCurrencyFormatter(
                                order?.provider_currency?.code,
                              )
                              const sourceFormatter = new Intl.NumberFormat(
                                'en-US',
                                {
                                  style: 'currency',
                                  currency: order?.base_currency?.code || 'USD',
                                },
                              )
                              const usdFormatter = new Intl.NumberFormat(
                                'en-US',
                                {
                                  style: 'currency',
                                  currency: 'USD',
                                },
                              )

                              return (
                                <React.Fragment key={'_order_' + key}>
                                  <tr>
                                    <td className='first-col sticky-col'>
                                      {order?.contracts?.length > 1 ||
                                      order?.works?.length !== 0 ? (
                                        <button
                                          className='rp-btn-nostyle text-current d-flex p-1'
                                          onClick={() => {
                                            setExpanded(
                                              expanded === key ? -1 : key,
                                            )
                                          }}
                                        >
                                          <i className='bx bx-plus' />
                                        </button>
                                      ) : null}
                                    </td>
                                    <td className='sticky-col second-col'>
                                      {activeTab === '2' ? (
                                        <button
                                          type='button'
                                          onClick={() => setTrxDetail(order)}
                                          className='bg-transparent border-0 text-primary'
                                        >
                                          {order.transaction_ref}
                                        </button>
                                      ) : (
                                        order.transaction_ref
                                      )}
                                    </td>
                                    {activeTab === '2' && (
                                      <td className='second-col'>
                                        <Link
                                          target='_blank'
                                          to={`contract-detail?id=${order?.contracts?.[0]}`}
                                        >
                                          {order?.contracts?.[0]}
                                        </Link>
                                      </td>
                                    )}
                                    <td className='sticky-col third-col border-light border-secondary'>
                                      <UserFullName
                                        data={order}
                                        prefillComment={prefillComment(
                                          order,
                                          activeTab,
                                        )}
                                        onCompleteAction={() => {
                                          applyFilter(null, true)
                                        }}
                                        onUserClick={() =>
                                          handleUserClick(order)
                                        }
                                      />
                                    </td>
                                    {activeTab === '2' && (
                                      <td className='border-light border-secondary sticky-col forth-col'>
                                        <div
                                          className='text-truncate d-inline-block'
                                          style={{
                                            maxWidth: 'calc(100% - 20px)',
                                          }}
                                          title={
                                            order.bank_account_details
                                              ?.accountHolderName
                                          }
                                        >
                                          {
                                            order.bank_account_details
                                              ?.accountHolderName
                                          }
                                        </div>

                                        {order?.account_status ? (
                                          <i
                                            className={`bx ${
                                              order.account_status ===
                                              'verified'
                                                ? 'bxs-check-shield text-success'
                                                : order.account_status ===
                                                  'unverified'
                                                ? 'bxs-shield-x text-danger'
                                                : 'bxs-notification-off text-secondary'
                                            } font-size-16 ml-1 position-absolute`}
                                          />
                                        ) : null}
                                      </td>
                                    )}
                                    {activeTab === '2' && (
                                      <td className='border-light border-secondary'>
                                        <BadgeX status='success'>
                                          {order.under_review
                                            ? 'In compliance review'
                                            : ''}
                                        </BadgeX>
                                      </td>
                                    )}
                                    <td>{order?.type?.name}</td>
                                    {activeTab === '1' && (
                                      <td>{order?.bank_name}</td>
                                    )}
                                    {activeTab === '2' && (
                                      <td>{order?.country}</td>
                                    )}
                                    <td>{order?.method?.name}</td>
                                    <td>{order?.client?.email}</td>
                                    <td>{order?.company_name}</td>
                                    <td className='text-right'>
                                      {formatter.format(
                                        activeTab === '1'
                                          ? order.total
                                          : order.amount,
                                      )}
                                    </td>
                                    <td>{order.created_at}</td>
                                    {activeTab === '2' && (
                                      <td>
                                        {order?.provider_amount &&
                                        order?.provider_amount > 0
                                          ? providerFormatter.format(
                                              order?.provider_amount,
                                            )
                                          : ''}
                                      </td>
                                    )}
                                    <td>{order.confirmed_at}</td>

                                    {activeTab !== '1' && (
                                      <td>
                                        {sourceFormatter.format(
                                          order.base_amount,
                                        )}
                                      </td>
                                    )}
                                    {activeTab !== '1' && (
                                      <td>
                                        {order.total_usd && (
                                          <span className='font-weight-semibold'>{`(${usdFormatter.format(
                                            order.total_usd,
                                          )})`}</span>
                                        )}
                                        {formatter.format(Number(order.total))}
                                      </td>
                                    )}
                                    <td>{order.rate}</td>
                                    {activeTab === '1' && (
                                      <td>
                                        {!!order.markup &&
                                          formatter.format(order.markup)}
                                      </td>
                                    )}
                                    {activeTab !== '1' && <td>{order.eta}</td>}
                                    <td>
                                      {formatter.format(Number(order?.fee))}
                                    </td>
                                    <td>
                                      <div className='w-md'>
                                        {order.status === 'Confirmed' ||
                                        !hasEditPermission ? (
                                          <Badge
                                            className={`font-size-12 rounded py-1 my-2 ${
                                              order.status === 'Confirmed'
                                                ? 'bg-soft-success text-success'
                                                : 'bg-soft-warning text-warning'
                                            }`}
                                            pill
                                          >
                                            {order.status}
                                          </Badge>
                                        ) : (
                                          <Select
                                            name='currency_id'
                                            onChange={(s) => {
                                              setToConfirm(order)
                                              setIsOpen(true)
                                            }}
                                            defaultValue={{
                                              value: order.status,
                                              label: order.status,
                                            }}
                                            options={[
                                              {
                                                value: 'Confirmed',
                                                label: 'Confirmed',
                                              },
                                            ]}
                                            styles={myCustomStyles}
                                            components={{
                                              Option: CustomOption,
                                            }}
                                            isSearchable={false}
                                          />
                                        )}
                                      </div>
                                    </td>
                                    {activeTab !== '1' && (
                                      <td className='text-capitalize'>
                                        {order.provider}
                                      </td>
                                    )}
                                    {activeTab === '2' && (
                                      <td>
                                        <ProcessColumn
                                          item={order}
                                          onUpdate={() =>
                                            applyFilter(null, true)
                                          }
                                          selectedWithdraws={selectedWithdraws}
                                          hasEditPermission={hasEditPermission}
                                          multipleLoading={
                                            multipleProcess.isLoading
                                          }
                                        />
                                      </td>
                                    )}
                                    {!hasEditPermission ? null : (
                                      <>
                                        {!['1', '2'].includes(activeTab) && (
                                          <td className='text-center'>
                                            {exportTransaction.isLoading &&
                                            exporting?.transaction_id ===
                                              order?.transaction_id ? (
                                              <i className='bx bx-loader bx-spin font-size-16 text-primary' />
                                            ) : (
                                              <a
                                                className='text-primary'
                                                onClick={() => {
                                                  setExporting(order)
                                                  exportTransaction.startFetch({
                                                    transaction_id:
                                                      order.transaction_id,
                                                  })
                                                }}
                                              >
                                                <i className='bx bx-export font-size-20' />
                                              </a>
                                            )}
                                          </td>
                                        )}

                                        {!['1', '2'].includes(activeTab) ? (
                                          <td>
                                            <Col>
                                              {archiving === key ? (
                                                <i className='bx bx-loader bx-spin font-size-16 text-primary' />
                                              ) : (
                                                <a
                                                  className='text-primary'
                                                  onClick={() => {
                                                    setArchiving(key)
                                                    archive.startFetch({
                                                      transaction_id:
                                                        order.transaction_id,
                                                      status: order?.archived
                                                        ? 0
                                                        : 1,
                                                    })
                                                  }}
                                                >
                                                  {order?.archived ? (
                                                    <StyledIcon
                                                      icon={unArchiveIcon}
                                                      hoverIcon={
                                                        unArchiveIconHover
                                                      }
                                                      alt={'unarchive icon'}
                                                      style={{ width: 16 }}
                                                    />
                                                  ) : (
                                                    <StyledIcon
                                                      icon={archiveIcon}
                                                      hoverIcon={
                                                        archiveIconHover
                                                      }
                                                      alt={'archive icon'}
                                                      style={{ width: 16 }}
                                                    />
                                                  )}
                                                </a>
                                              )}
                                            </Col>
                                          </td>
                                        ) : null}
                                        {activeTab === '1' ? (
                                          <td>
                                            <PaymentActions
                                              transaction={order}
                                              onActionComplete={() => {
                                                applyFilter(null, true)
                                              }}
                                              onDownloadInvoice={
                                                handleDownloadInvoice
                                              }
                                              onStartRevert={() => {
                                                setToRevert(order)
                                                setIsRevertPaymentOpen(true)
                                              }}
                                            />
                                          </td>
                                        ) : null}
                                        {activeTab === '2' ? (
                                          <td>
                                            <WithdrawActions
                                              transaction={order}
                                              onActionComplete={() => {
                                                applyFilter(null, true)
                                              }}
                                              onStartRevert={() => {
                                                setToRevert(order)
                                                setIsRevertOpen(true)
                                              }}
                                              onStartPreferedProvider={() => {
                                                setIsPreferedProvider(order)
                                              }}
                                              onStartUnprocess={() => {
                                                setUnprocessModal(order)
                                              }}
                                              onStartReview={() => {
                                                handleReview(order)
                                              }}
                                              onStartManualProcess={() => {
                                                setToManualProcess(order)
                                                setIsManualProcessOpen(true)
                                              }}
                                            />
                                          </td>
                                        ) : null}
                                      </>
                                    )}
                                    {activeTab === '2' && (
                                      <td>
                                        {order?.processed !== 1 &&
                                          order.status !== 'Confirmed' &&
                                          order.provider !== 'paypal' &&
                                          !order?.provider_response?.kyc &&
                                          hasEditPermission && (
                                            <>
                                              {order.failed === 1 ? (
                                                <Badge
                                                  className='font-size-14  rounded bg-soft-danger'
                                                  color='white'
                                                  pill
                                                >
                                                  <StyledP
                                                    style={{ padding: 4 }}
                                                    min={'12px'}
                                                    max={'14px'}
                                                    className='mb-0 text-danger'
                                                  >
                                                    failed
                                                  </StyledP>
                                                </Badge>
                                              ) : order.queued === 1 ? (
                                                <i className='bx bx-loader bx-spin' />
                                              ) : (
                                                BULK_PROCESS_PROVIDERS.includes(
                                                  order.provider,
                                                ) && (
                                                  <Toggle
                                                    id={`trx${key}`}
                                                    check={order.selected}
                                                    change={() => {
                                                      handleTrxChecked(order)
                                                    }}
                                                  />
                                                )
                                              )}
                                            </>
                                          )}
                                      </td>
                                    )}
                                  </tr>
                                  {activeTab !== '2' && expanded === key && (
                                    <>
                                      {order?.works?.map((t) => {
                                        const formatter = new Intl.NumberFormat(
                                          'en-US',
                                          {
                                            style: 'currency',
                                            currency: t?.currency || 'USD',
                                          },
                                        )
                                        return (
                                          <tr
                                            className='font-weight-light font-size-12 bg-soft-primary'
                                            key={'_trans' + t?.id}
                                          >
                                            <th></th>
                                            <th className='font-weight-bold font-size-12'>
                                              <ContractRef
                                                contractId={t?.contract_ref}
                                              />
                                            </th>
                                            <td>
                                              <Col>
                                                <Row className='align-items-center flex-nowrap'>
                                                  {!t.contractor?.photo ? (
                                                    <div className='avatar-xs'>
                                                      <span className='avatar-title rounded-circle'>
                                                        {t.contractor?.first_name?.charAt(
                                                          0,
                                                        )}
                                                      </span>
                                                    </div>
                                                  ) : (
                                                    <div>
                                                      <img
                                                        className='rounded-circle avatar-xs'
                                                        src={`${storage}${t.contractor?.photo}`}
                                                        alt=''
                                                      />
                                                    </div>
                                                  )}
                                                  <div
                                                    style={{
                                                      marginLeft: '10px',
                                                    }}
                                                  >
                                                    {t.contractor?.first_name}{' '}
                                                    {t.contractor?.last_name}
                                                  </div>
                                                </Row>
                                              </Col>
                                            </td>

                                            <td className='font-size-14 rp-font-gilroyB'>
                                              {t?.payment_ref}
                                            </td>

                                            <th className='font-weight-normal font-size-12'>
                                              {t?.details}
                                            </th>
                                            <th className='font-weight-normal font-size-12'>
                                              {t?.name}
                                            </th>
                                            <th className='font-weight-normal font-size-12 text-right'>
                                              {formatter.format(t?.amount)}
                                            </th>
                                            <th className='border-top-0 text-muted'></th>
                                            <th className='border-top-0 text-muted'></th>
                                            <th className='border-top-0 text-muted'></th>
                                            <th className='border-top-0 text-muted'></th>
                                            <th className='border-top-0 text-muted'></th>
                                            <th className='border-top-0 text-muted'></th>
                                            <th className='border-top-0 text-muted'></th>
                                            <th className='border-top-0 text-muted'></th>
                                            <th className='border-top-0 text-muted'></th>
                                            <th className='border-top-0 text-muted'></th>
                                            <th className='border-top-0 text-muted'></th>
                                          </tr>
                                        )
                                      })}
                                    </>
                                  )}
                                  {activeTab === '2' && expanded === key && (
                                    <>
                                      {order?.contracts?.map((t) => {
                                        return (
                                          <tr
                                            className='font-weight-light font-size-12 bg-soft-primary'
                                            key={'_trans' + t}
                                          >
                                            <th></th>
                                            <th className='font-weight-bold font-size-12'></th>
                                            <td>
                                              <Link
                                                target='_blank'
                                                to={`contract-detail?id=${t}`}
                                              >
                                                {t}
                                              </Link>
                                            </td>
                                            <th className='font-weight-normal font-size-12'></th>
                                            <th className='font-weight-normal font-size-12'></th>
                                            <th className='font-weight-normal font-size-12 text-right'></th>
                                            <th className='border-top-0 text-muted'></th>
                                            <th className='border-top-0 text-muted'></th>
                                            <th className='border-top-0 text-muted'></th>
                                            <th className='border-top-0 text-muted'></th>
                                            <th className='border-top-0 text-muted'></th>
                                            <th className='border-top-0 text-muted'></th>
                                            <th className='border-top-0 text-muted'></th>
                                            <th className='border-top-0 text-muted'></th>
                                            <th className='border-top-0 text-muted'></th>
                                            <th className='border-top-0 text-muted'></th>
                                            <th className='border-top-0 text-muted'></th>
                                            <th className='border-top-0 text-muted'></th>
                                            <th className='border-top-0 text-muted'></th>
                                          </tr>
                                        )
                                      })}
                                    </>
                                  )}
                                </React.Fragment>
                              )
                            })}
                      </tbody>
                    </Table>
                    <RevertTrxModal
                      isOpen={isRevertPaymentOpen}
                      toggle={() => setIsRevertPaymentOpen(false)}
                      onConfirm={handleConfirmRevertPayment}
                      trx={toRevert}
                      isLoading={revertPayment.isLoading}
                    />
                    <ConfirmationModal
                      isOpen={isOpen}
                      toggle={() => setIsOpen(false)}
                      onConfirm={handleConfirm}
                      trx={toConfirm}
                      loading={confirm.isLoading}
                      balance={balance}
                      setBalance={setBalance}
                      sendEmail={sendEmail}
                      setSendEmail={setSendEmail}
                    />
                    <MultipleTransactionsModal
                      isOpen={showMultipleConfirm}
                      toggle={() => setShowMultipleConfirm(false)}
                      onConfirm={() => handleMultipleProcessing()}
                      loading={multipleProcess.isLoading}
                    />
                    <ManualProcessModal
                      isOpen={isManualProcessOpen}
                      toggle={() => setIsManualProcessOpen(false)}
                      onConfirm={handleConfirmManualProcess}
                      trx={toManualProcess}
                      isLoading={manualProcess.isLoading}
                    />
                    <RevertTrxModal
                      isWithdraw
                      isOpen={isRevertOpen}
                      toggle={() => setIsRevertOpen(false)}
                      onConfirm={handleConfirmRevert}
                      trx={toRevert}
                      isLoading={revert.isLoading}
                    />
                    {!isPreferedProvider ? null : (
                      <PreferedProviderModal
                        isOpen={!!isPreferedProvider}
                        transaction={isPreferedProvider}
                        isLoading={preferedProvider.isLoading}
                        toggle={() => setIsPreferedProvider((open) => !open)}
                        onConfirm={handleSetPreferedProvider}
                      />
                    )}
                    {!unprocessModal ? null : (
                      <UnprocessModal
                        isOpen={!!unprocessModal}
                        transaction={unprocessModal}
                        toggle={() => setUnprocessModal((open) => !open)}
                        isLoading={unprocessing}
                        onConfirm={handleUnprocessWithdraw}
                      />
                    )}
                  </div>
                )}
                {!trxPaginator ? null : (
                  <div className='d-flex justify-content-end pt-3 px-3'>
                    <Paginations
                      itemClass='page-item'
                      linkClass='page-link'
                      activePage={activePage}
                      itemsCountPerPage={trxPaginator?.per_page ?? 50}
                      totalItemsCount={trxPaginator?.total}
                      pageRangeDisplayed={5}
                      onChange={setActivePage}
                    />
                  </div>
                )}
              </>
            )}

            {isTrxLoading ? (
              <div
                style={{
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  backgroundColor: '#5c656d30',
                  zIndex: 2,
                }}
                className='position-absolute'
              >
                <div
                  className='d-flex justify-content-center align-items-center'
                  style={{ height: '100%', maxHeight: '20rem' }}
                >
                  <Spinner type='grow' color='primary' />
                </div>
              </div>
            ) : null}
          </div>
        </Card>
      </Container>
    </div>
  )
}

function ProcessColumn({
  item,
  hasEditPermission,
  onUpdate,
  selectedWithdraws,
  multipleLoading,
}) {
  if (
    item?.processed !== 1 &&
    item.queued !== 1 &&
    item.status !== 'Confirmed'
  ) {
    if (!hasEditPermission) {
      return 'Not processed'
    } else if (item.failed && item?.provider_response?.kyc) {
      return <ReasonPopover item={item} />
    } else {
      return (
        <ProcessDropdown
          item={item}
          onUpdate={onUpdate}
          selectedWithdraws={selectedWithdraws}
          multipleLoading={multipleLoading}
        />
      )
    }
  }

  const trxValue = Array.isArray(item?.provider_response)
    ? item?.provider_response[0]?.payment_id
    : item.provider === 'wise'
    ? item?.provider_response?.id
    : item.provider === 'transfermate'
    ? item?.provider_response?.transaction_id
    : item.provider === 'verto'
    ? item?.provider_response?.reference
    : item.provider === 'paypal'
    ? item?.provider_response?.transaction_id
    : ['payoneer_bt', 'payoneer'].includes(item.provider)
    ? item?.provider_response?.payout_id
    : item?.transaction_ref

  return trxValue ?? item?.transaction_ref ?? null
}

const ReasonPopover = ({ item }) => {
  const [showReason, setShowReason] = useState(false)

  const targetId = `Payoneer_bt_${item.transaction_id}`

  return (
    <div>
      <Popover
        className='unbo-popover'
        placement='top'
        isOpen={showReason && item?.provider_response?.kyc}
        trigger='legacy'
        target={targetId}
        toggle={() => setShowReason(false)}
      >
        <IssuesDisplay issues={item?.provider_response?.kyc[0]?.issues} />
      </Popover>

      <Badge
        className='font-size-12 p-1 bg-soft-danger text-danger'
        id={targetId}
        onMouseEnter={() => {
          setShowReason(true)
        }}
        onMouseLeave={() => setShowReason(false)}
      >
        Failed
      </Badge>
    </div>
  )
}
const CustomOption = (props) => {
  const { innerProps, isDisabled } = props
  return isDisabled ? null : (
    <div className='p-2 cursor-pointer'>
      <Badge
        pill
        {...innerProps}
        className='font-size-12 rounded bg-soft-success text-success py-1'
      >
        {props.value}
      </Badge>
    </div>
  )
}
const myCustomStyles = {
  dropdownIndicator: () => ({
    color: '#556ee6',
    paddingLeft: 8,
  }),
  indicatorSeparator: () => ({ width: 0 }),
  control: () => ({
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 4,
    border: '1px solid transparent',
    padding: '6px 4px',
    transition: '150ms ease-in-out',
    transitionProperty: 'background-color, border-color',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#f8f8f8',
      borderColor: '#dedede',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    minWidth: 66,
  }),
  singleValue: (provided) => ({ ...provided, minWidth: 65 }),
}

function getTrxFormatter(trx) {
  return getCurrencyFormatter(trx?.currency?.code, undefined, {
    maximumSignificantDigits: trx?.provider === 'coinbase' ? 6 : undefined,
  })
}

function getTransactionInfoItems(trx) {
  const formatter = getTrxFormatter(trx)

  return [
    { label: 'Transaction ref:', value: trx?.transaction_ref },
    { label: 'Amount:', value: formatter.format(trx?.total) },
    {
      label: 'Client:',
      value: `${trx?.client?.first_name} ${trx?.client?.last_name}`,
    },
    {
      label: 'Prefered provider:',
      value: capitalizeFirstLetter(trx?.provider ?? ''),
    },
  ]
}

const ProcessModal = ({
  isOpen,
  toggle,
  onConfirm,
  trx,
  loading,
  processorType,
}) => {
  useEffect(() => {
    document.body.classList.toggle('modal-open', isOpen)
  }, [isOpen])
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Confirm Transaction</ModalHeader>
      <ModalBody>
        {[
          ...getTransactionInfoItems(trx),
          { label: 'Selected Provider:', value: processorType },
        ].map(({ label, value }, index) => {
          return (
            <div
              key={index}
              className='border-bottom d-flex py-1'
              style={{ gap: '0.5rem' }}
            >
              <span style={{ minWidth: 110 }}>{label}</span>
              <span className='rp-font-bold'>{value}</span>
            </div>
          )
        })}
        <p className='font-size-16 mb-0 mt-4 text-center text-muted'>
          Are you sure you want to process this transaction?
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          color='light'
          outline
          type='button'
          disabled={loading}
          onClick={toggle}
        >
          Cancel
        </Button>
        <Button
          loading={loading}
          disabled={loading}
          type='button'
          onClick={() => {
            onConfirm()
          }}
        >
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const ConfirmationModal = ({
  isOpen,
  toggle,
  onConfirm,
  trx,
  isWithdraw = false,
  loading,
  balance,
  setBalance = () => {},
  sendEmail,
  setSendEmail,
}) => {
  const [textChecked, setTextChecked] = useState(false)
  const formatter = getTrxFormatter(trx)
  useEffect(() => {
    document.body.classList.toggle('modal-open', isOpen)
  }, [isOpen])
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggle()
        setTextChecked(false)
        setBalance(0)
        setSendEmail(false)
      }}
    >
      <ModalHeader
        toggle={() => {
          toggle()
          setTextChecked(false)
          setBalance(0)
          setSendEmail(false)
        }}
      >
        Confirm Transaction
      </ModalHeader>
      <ModalBody>
        {getTransactionInfoItems(trx).map(({ label, value }, index) => {
          return (
            <div key={index} className='mb-2'>
              <span className='rp-font-bold d-inline-block'>{label}</span>{' '}
              <span className='d-inline-block'>{value}</span>
            </div>
          )
        })}
        <p>{`Are you sure you want to ${
          isWithdraw ? 'process' : 'confirm'
        } this transaction?`}</p>
      </ModalBody>
      {!trx?.processed && trx?.type?.name !== 'Withdraw' && !loading && (
        <ModalBody style={{ borderTop: '1px solid #eff2f7' }}>
          {!textChecked && (
            <div className='px-4'>
              <Label
                style={{
                  fontWeight: 'normal',
                  fontSize: '14px',
                  fontFamily: 'inter',
                  color: '#424652',
                }}
              >
                <Input
                  type='checkbox'
                  checked={textChecked}
                  onChange={(t) => setTextChecked(t.target.checked)}
                />
                The received amount is different
              </Label>
            </div>
          )}
          {textChecked && (
            <div>
              <Label className='font-weight-bold text-dark'>
                Received amount:
              </Label>
              <CurrencyInput
                name='balance'
                type='text'
                onKeyPress={(e) => {
                  if (!/[0-9.]/.test(e.key)) {
                    e.preventDefault()
                  }
                }}
                value={balance}
                className='form-control'
                onValueChange={setBalance}
                // currency input props
                allowDecimals={true}
                decimalsLimit={2}
                decimalSeparator='.'
                groupSeparator=','
              />
              <Label className='font-weight-bold text-dark'>
                Rest to be paid:
              </Label>{' '}
              {formatter.format(trx?.total - balance)}
              <div className='px-4'>
                <Label
                  style={{
                    fontWeight: 'normal',
                    fontSize: '14px',
                    fontFamily: 'inter',
                    color: '#424652',
                  }}
                >
                  <Input
                    type='checkbox'
                    checked={sendEmail}
                    onChange={(t) => setSendEmail(t.target.checked)}
                  />
                  Send email notification
                </Label>
              </div>
            </div>
          )}
        </ModalBody>
      )}
      <ModalFooter>
        <WiseButton
          text={'Confirm'}
          loading={loading}
          type={'submit'}
          onClick={() => {
            onConfirm(balance, sendEmail)
            setTextChecked(false)
          }}
        />
        <WiseButton
          text={'Cancel'}
          bk='secondary'
          onClick={() => {
            toggle()
            setTextChecked(false)
            setBalance(0)
            setSendEmail(false)
          }}
        />
      </ModalFooter>
    </Modal>
  )
}

const MultipleTransactionsModal = ({ isOpen, toggle, onConfirm, loading }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggle()
      }}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title mt-0'>Confirm Transactions</h5>
          <button
            type='button'
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={() => {
              toggle()
            }}
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <div className='modal-body'>
          <p>Are you sure you want to process these transactions?</p>
        </div>
        <div className='modal-footer'>
          <WiseButton
            text={'Confirm'}
            loading={loading}
            disabled={loading}
            type={'submit'}
            onClick={() => {
              onConfirm()
            }}
          />
          <WiseButton
            text={'Cancel'}
            bk='secondary'
            onClick={() => {
              toggle()
            }}
          />
        </div>
      </div>
    </Modal>
  )
}

const ReferenceModal = ({ isOpen, toggle, onConfirm }) => {
  const schema = yup.object().shape({
    reference_id: yup.string().required(),
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {},
  })

  const onSubmit = (data) => {
    onConfirm(data)
    toggle()
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Reference ID</ModalHeader>
      <Form
        autoComplete='off'
        className='form-horizontal m-0 p-3'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='p-3'>
          <FormGroup className='d-inline p-0 m-0'>
            <Label
              style={{ whiteSpace: 'nowrap' }}
              htmlFor='billing-name'
              md='3'
              className='col-form-label p-0 m-0 mb-2 font-size-14'
            >
              Reference ID
              <span className='text-danger font-size-16 mx-1'>*</span>
            </Label>
            <ControlledInput
              control={control}
              name='reference_id'
              error={errors.reference_id}
            />
          </FormGroup>
        </div>
        <ModalFooter>
          <button type='submit' className='btn btn-primary'>
            Confirm
          </button>
          <button
            type='button'
            className='btn btn-secondary'
            data-dismiss='modal'
            onClick={toggle}
          >
            Cancel
          </button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

const processingProviders = [
  'Ebury',
  'Alfardan',
  'ENBD',
  'Wise',
  'Transfermate',
  'Verto',
  'Mercury',
  'Revolut',
  'SVB',
  'AWB',
  'Payoneer',
  'Payoneer BT',
]

function getProcessOptions(methodName) {
  return methodName === 'Cash Point'
    ? ['Cash']
    : methodName === 'PayPal'
    ? ['PayPal']
    : processingProviders
}

const ProcessDropdown = ({
  item,
  onUpdate,
  selectedWithdraws,
  multipleLoading,
}) => {
  const [fields, setFields] = useState([])
  const [processorType, setProcessorType] = useState(null)
  const [referenceId, setReferenceId] = useState('')
  const [showConfirm, setShowConfirm] = useState(false)
  const [showReference, setShowReference] = useState(false)

  const [isOpen, setIsOpen] = useState(false)
  const [show, setShow] = useState(false)

  const process = useFetch({
    action: processWithdraw,
    withAdminAccess: true,
    onComplete: (data) => {
      if (data?.missing_info?.length > 0) {
        setFields(data?.missing_info)
        setShow(true)
      } else {
        toastr.success('Transaction created successfully')
        onUpdate()
      }
    },
    onError: (err) => {
      toastr.error(err)
    },
  })

  const handleProcessing = (type, values) => {
    setShowConfirm(false)
    const body = {
      transaction_id: item?.transaction_id,
      amount: item?.provider === 'coinbase' ? item?.base_amount : item?.total,
      source: item?.base_currency?.code,
      target: item?.currency?.code,
      provider: type?.toLowerCase(),
      reference_id: referenceId,
    }

    if (values) {
      body.fields = Object.entries(values).map(([key, value]) => ({
        key,
        value,
      }))
    }
    setShow(false)
    process.startFetch(body)
  }
  return process.isLoading ||
    (selectedWithdraws.filter((e) => e.transaction_id === item.transaction_id)
      .length > 0 &&
      multipleLoading) ? (
    <i className='bx bx-loader bx-spin font-size-16 text-primary' />
  ) : (
    <>
      {item?.provider === 'coinbase' ? (
        <button
          className='btn btn-primary'
          onClick={() => {
            setProcessorType('coinbase')
            setShowConfirm(true)
          }}
        >
          Process
        </button>
      ) : (
        <ButtonDropdown
          isOpen={isOpen}
          toggle={() => setIsOpen(!isOpen)}
          style={{ position: 'relative' }}
        >
          <DropdownToggle caret color='primary' className='btn-sm rounded'>
            Process
          </DropdownToggle>
          <DropdownMenu>
            {getProcessOptions(item?.method?.name).map((type, i) => (
              <DropdownItem
                onClick={() => {
                  setProcessorType(
                    type === 'Payoneer BT' ? 'payoneer_bt' : type,
                  )
                  if (type.toLowerCase() === 'cash') {
                    setShowReference(true)
                  } else {
                    setShowConfirm(true)
                  }
                }}
                key={`provider:${i}`}
              >
                {type}
              </DropdownItem>
            ))}
          </DropdownMenu>
          <MissingFieldsModal
            fields={fields}
            isOpen={show}
            toggle={() => setShow(false)}
            isLoading={process.isLoading}
            onSubmit={(values) => handleProcessing(processorType, values)}
          />
        </ButtonDropdown>
      )}
      <ProcessModal
        trx={item}
        isOpen={showConfirm}
        toggle={() => setShowConfirm(false)}
        onConfirm={() => handleProcessing(processorType)}
        loading={process.isLoading}
        processorType={processorType}
      />

      <ReferenceModal
        isOpen={showReference}
        toggle={() => setShowReference(false)}
        onConfirm={(data) => {
          setReferenceId(data.reference_id)
          setShowConfirm(true)
        }}
      />
    </>
  )
}

const missingFieldsForm = 'missingFieldsForm'
export const MissingFieldsModal = ({
  fields,
  isOpen,
  toggle,
  onSubmit,
  isLoading,
}) => {
  return (
    <Modal scrollable isOpen={isOpen} toggle={toggle} centered unmountOnClose>
      <ModalHeader toggle={toggle}>Missing Fields</ModalHeader>
      <ModalBody>
        <AvForm
          id={missingFieldsForm}
          onValidSubmit={(_, values) => {
            onSubmit(values)
          }}
        >
          {fields.map((e) => {
            return (
              <AvField
                key={e.key}
                name={e?.key}
                label={
                  <span>
                    {e?.label}{' '}
                    {!e?.comment ? null : (
                      <span className='text-muted'>({e?.comment})</span>
                    )}
                  </span>
                }
                defaultValue={e?.value}
                required
              />
            )
          })}
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <Button outline color='light' onClick={toggle} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          type='submit'
          formId={missingFieldsForm}
          disabled={isLoading}
          loading={isLoading}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export const TransactionCardAdmin = (props) => {
  const {
    index,
    order,
    setIsOpen,
    setToConfirm,
    archive,
    onUpdate,
    withProcess,
    multipleLoading,
    selectedWithdraws,
  } = props
  const [archiving, setArchiving] = useState(-1)

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: order?.currency?.code || 'USD',
  })

  return (
    <div
      key={index}
      className=' py-0   mb-3'
      style={{
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 1px 0px #DFE1E6',
        borderRadius: 4,
        border: '1px solid #E7E8F2',
        position: 'relative',
      }}
    >
      <Row className='p-3 m-0 border-bottom'>
        <Col xs={12} className='p-0 m-0 d-flex justify-content-between'>
          <StyledH5 className={'text-dark font-weight-normal font-size-14'}>
            TRX ID
          </StyledH5>
          <StyledH6 className='text-primary border-secondary font-size-12'>
            {order.transaction_ref}
          </StyledH6>
        </Col>
      </Row>
      <div className='p-3'>
        <div className='d-flex align-items-center justify-content-between align-items-center'>
          <StyledH6 className='text-dark font-weight-light font-size-14'>
            Type
          </StyledH6>
          <StyledH6 className='text-dark font-weight-light font-size-14'>
            {order.type?.name}
          </StyledH6>
        </div>

        <div className='d-flex mb-3 align-items-center justify-content-between align-items-center'>
          <h6 className={'text-dark font-weight-normal mb-0 font-size-14'}>
            Type Method
          </h6>
          <h6 className='font-weight-normal mb-0 text-dark font-size-14'>
            {order.method?.name}
          </h6>
        </div>
        <div className='d-flex mb-3 align-items-center justify-content-between align-items-center'>
          <h6 className={'text-dark font-weight-normal mb-0 font-size-14'}>
            Client name
          </h6>
          <h6 className='text-dark font-size-14 font-weight-normal mb-0'>
            {order.client?.first_name} {order.client?.last_name}
          </h6>
        </div>

        <div className='d-flex mb-3 justify-content-between align-items-center'>
          <h6 className='  text-dark font-weight-normal mb-0 font-size-14'>
            Amount
          </h6>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            {formatter.format(order.amount)}
          </h6>
        </div>

        <div className='d-flex mb-3 justify-content-between align-items-center'>
          <h6 className='  text-dark font-weight-normal mb-0 font-size-14'>
            Source
          </h6>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            {formatter.format(order.base_amount)}
          </h6>
        </div>
        <div className='d-flex mb-3 justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            Rate
          </h6>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            {order.rate}
          </h6>
        </div>

        <div className='d-flex mb-3 justify-content-between align-items-center'>
          <h6 className='  text-dark font-weight-normal mb-0 font-size-14'>
            ETA
          </h6>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            {order.eta}
          </h6>
        </div>

        <div className='d-flex mb-3 justify-content-between align-items-center'>
          <h6 className='  text-dark font-weight-normal mb-0 font-size-14'>
            Fee
          </h6>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            {formatter.format(order?.fee)}
          </h6>
        </div>

        <div className='d-flex mb-3 justify-content-between align-items-center'>
          <h6 className='  text-dark font-weight-normal mb-0 font-size-14'>
            TRX date
          </h6>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            {order.created_at}
          </h6>
        </div>

        <div className='d-flex mb-3 justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            Confirmed at
          </h6>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            {order.confirmed_at}
          </h6>
        </div>

        <div className='d-flex mb-3 justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            archive
          </h6>
          {archiving === index ? (
            <i className='bx bx-loader bx-spin font-size-16 text-primary'></i>
          ) : (
            <Link
              to='#'
              onClick={() => {
                setArchiving(index)
                archive.startFetch({
                  transaction_id: order.transaction_id,
                  status: order?.archived ? 0 : 1,
                })
              }}
            >
              {order?.archived ? (
                <StyledIcon
                  icon={unArchiveIcon}
                  hoverIcon={unArchiveIconHover}
                  alt={'unarchive icon'}
                  style={{ width: 16 }}
                />
              ) : (
                <StyledIcon
                  icon={archiveIcon}
                  hoverIcon={archiveIconHover}
                  alt={'archive icon'}
                  style={{ width: 16 }}
                />
              )}
            </Link>
          )}
        </div>
        <div className='d-flex mb-0 justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0 font-size-14'>
            TRX Status
          </h6>
          <div className={'w-50 d-block text-right'}>
            {order.status === 'Confirmed' ? (
              <Badge
                pill
                className='font-size-12 rounded bg-soft-success text-success py-1 my-2'
              >
                {order.status}
              </Badge>
            ) : (
              <Select
                name='currency_id'
                onChange={(s) => {
                  setToConfirm(order)
                  setIsOpen()
                }}
                defaultValue={{
                  value: order.status,
                  label: order.status,
                }}
                options={[{ value: 'Confirmed', label: 'Confirmed' }]}
                styles={myCustomStyles}
                components={{ Option: CustomOption }}
              />
            )}
          </div>
        </div>
        {withProcess && (
          <td className='text-center'>
            {order?.processed !== 1 &&
            order.status !== 'Confirmed' &&
            order.provider !== 'paypal' ? (
              <ProcessDropdown
                item={order}
                onUpdate={onUpdate}
                multipleLoading={multipleLoading}
                selectedWithdraws={selectedWithdraws}
              />
            ) : Array.isArray(order?.provider_response) ? (
              order?.provider_response[0]?.payment_id
            ) : order.provider === 'wise' ? (
              order?.provider_response?.id
            ) : (
              order?.transaction_ref
            )}
          </td>
        )}
      </div>
    </div>
  )
}

function PaymentActions({
  transaction,
  onActionComplete,
  onDownloadInvoice,
  onStartRevert,
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [showReminderHistory, setShowReminderHistory] = useState(false)
  const [isActionLoading, setIsActionLoading] = useState('')

  const { startFetch: archiveTrx } = useFetch({
    action: archiveTransaction,
    withAdminAccess: true,
    onComplete: () => {
      setIsActionLoading('')
      if (typeof onActionComplete === 'function') {
        onActionComplete()
      }
    },
    onError: () => {
      setIsActionLoading('')
    },
  })

  const actionsOptions = [
    {
      id: 1,
      onClick: () => {
        setIsActionLoading('achiving')
        archiveTrx({
          transaction_id: transaction.transaction_id,
          status: transaction?.archived ? 0 : 1,
        })
      },
      content: (
        <>
          <BxIcon
            name='bx bx-archive-in'
            className='font-size-14 text-muted'
            loading={isActionLoading === 'achiving'}
          />
          <span>Archive</span>
        </>
      ),
    },
    {
      id: 2,
      onClick: async () => {
        setIsActionLoading('downloading')
        await onDownloadInvoice(transaction)
        setIsActionLoading('')
      },
      content: (
        <>
          <BxIcon
            name='bx bx-download'
            className='font-size-14 text-muted'
            loading={isActionLoading === 'downloading'}
          />
          <span>Download</span>
        </>
      ),
    },
    transaction.status !== 'Processed'
      ? null
      : {
          id: 3,
          onClick: () => setShowReminderHistory(true),
          content: (
            <>
              <BxIcon name='bx bx-send' className='font-size-14 text-muted' />
              <span>Email reminders</span>
            </>
          ),
        },
    transaction.processed !== 0 || transaction.status !== 'Processed'
      ? null
      : {
          id: 4,
          onClick: onStartRevert,
          content: (
            <>
              <BxIcon
                name='bx bx-history'
                className='font-size-14 text-muted'
              />
              <span>Revert</span>
            </>
          ),
        },
  ].filter(Boolean)

  return (
    <>
      <ActionsDropdown
        options={actionsOptions}
        isOpen={isOpen}
        toggle={() => setIsOpen((t) => !t)}
      />

      {!showReminderHistory ? null : (
        <RemindersHistoryModal
          isOpen={showReminderHistory}
          toggle={() => setShowReminderHistory((o) => !o)}
          transaction={transaction}
        />
      )}
    </>
  )
}

function RemindersHistoryModal({ isOpen, toggle, transaction }) {
  const { startFetch: sendReminder, isLoading: isSendingEmail } = useFetch({
    action: sendPaymentReminder,
    withAdminAccess: true,
    onComplete: toggle,
  })

  const { data: historyData, isLoading: historyLoading } = useFetch({
    action: getPaymentReminderHistory,
    withAdminAccess: true,
    autoFetch: true,
    body: { transaction_id: transaction.transaction_id },
  })

  const columns = useMemo(
    () => [
      {
        Header: 'Sent',
        Cell: ({ cellData }) => {
          return format(new Date(cellData), 'dd/MM/yyyy hh:mm')
        },
        accessor: 'last_event_time',
      },
      { Header: 'Status', accessor: 'status' },
      { Header: 'subject', accessor: 'subject' },
      { Header: 'Opened', accessor: 'opens_count' },
      { Header: 'Clicks', accessor: 'clicks_count' },
    ],
    [],
  )

  const handleSendReminder = useCallback(() => {
    sendReminder({ transaction_id: transaction.transaction_id })
  }, [transaction.transaction_id])

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='lg'>
      <ModalHeader>Email Reminder History</ModalHeader>
      <ModalBody>
        {historyLoading ? (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{ minHeight: '10rem' }}
          >
            <Spinner color='primary' type='grow' />
          </div>
        ) : historyData?.length <= 0 ? (
          <div
            className='d-flex justify-content-center align-items-center flex-column text-muted'
            style={{ minHeight: '10rem', gap: '0.25rem' }}
          >
            <i className='bx bxs-inbox font-size-24' />
            <p className='font-size-16 mb-0'>No email reminders found.</p>
          </div>
        ) : (
          <DataTable data={historyData} columns={columns} />
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color='light'
          type='button'
          outline
          onClick={toggle}
          disabled={isSendingEmail}
        >
          Cancel
        </Button>
        <Button
          type='button'
          onClick={handleSendReminder}
          icon={<i className='bx bx-send font-size-14' />}
          disabled={isSendingEmail}
          loading={isSendingEmail}
        >
          Send reminder
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const WITHDRAW_ACTIONS_STATUS = {
  EXPORTING: 'EXPORTING',
  DOWNLOAD: 'DOWNLOAD',
  ARCHIVE: 'ARCHIVE',
  TICKET: 'SENDING',
}

function WithdrawActions({
  transaction,
  onStartRevert,
  onStartPreferedProvider,
  onStartUnprocess,
  onStartReview,
  onStartManualProcess,
  onActionComplete = () => {},
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [isActionLoading, setIsActionLoading] = useState(null)

  const exportTransaction = useFetch({
    action: exportWithdraw,
    withAdminAccess: true,
    onComplete: (data, body) => {
      openFile(data, body?.transaction_id, '_transactions.xlsx', () => {
        setIsActionLoading(null)
      })
    },
  })

  const archive = useFetch({
    action: archiveTransaction,
    withAdminAccess: true,
    onComplete: () => {
      setIsActionLoading(null)
      onActionComplete()
    },
  })
  const ticket = useFetch({
    action: createZendeskTicket,
    withAdminAccess: true,
    onComplete: () => {
      setIsActionLoading(null)
      toastr.success('Email Sent Successfully')
    },
    onError: (error) => {
      toastr.error(error)
    },
  })
  const loginToken = useSelector((state) => state.Login?.loginToken)
  function handleDownloadWithdrawalReceipt(transaction) {
    const createdAtDate = transaction.created_at

    const ref = transaction.transaction_ref

    downloadWithdrawalReceipt(ref, loginToken, transaction.token)
      .then((res) => {
        if (
          Object.prototype.hasOwnProperty.call(res, 'success') &&
          res.success === false
        ) {
          return new Error('No data')
        }
        openFile(res?.data, `receipt-${ref}-${createdAtDate}.pdf`)
      })
      .catch(() => {
        toastr.error('Error downloading receipt', 'Error')
      })
      .finally(() => setIsActionLoading(null))
  }
  function handleDownloadProviderReceipt(transaction) {
    const createdAtDate = transaction.created_at

    const ref = transaction.transaction_ref

    downloadProviderReceipt(loginToken, {
      transaction_id: transaction.transaction_id,
    })
      .then((res) => {
        // if (!res.success) {
        //   toastr.error('Receipt can not be found.')
        //   return
        // }
        openFile(res?.data, `receipt-${ref}-${createdAtDate}.pdf`)
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err)
        toastr.error('Error downloading receipt', 'Error')
      })
  }
  const canManageTransactions = useHasPermission(
    ADMIN_PERMISSIONS.MANAGE_TRANSACTION_STATUS,
  )

  const withdrawsOptions = [
    {
      id: 0,
      onClick: () => {
        setIsActionLoading(WITHDRAW_ACTIONS_STATUS.DOWNLOAD)
        handleDownloadWithdrawalReceipt(transaction)
      },
      content: (
        <>
          <BxIcon
            name='bx bx-download'
            className='font-size-14 text-muted'
            loading={isActionLoading === WITHDRAW_ACTIONS_STATUS.DOWNLOAD}
          />
          <span>Download Receipt</span>
        </>
      ),
    },
    {
      id: 1,
      onClick: () => {
        setIsActionLoading(WITHDRAW_ACTIONS_STATUS.EXPORTING)
        exportTransaction.startFetch({
          transaction_id: transaction.transaction_id,
        })
      },
      content: (
        <>
          <BxIcon
            name='bx bx-export'
            className='font-size-14 text-muted'
            loading={isActionLoading === WITHDRAW_ACTIONS_STATUS.EXPORTING}
          />
          <span>Export</span>
        </>
      ),
    },
    transaction.processed !== 1 || !canManageTransactions
      ? null
      : {
          id: 5,
          onClick: onStartUnprocess,
          content: (
            <>
              <BxIcon name='bx bx-export' className='font-size-14 text-muted' />
              <span>Unprocess</span>
            </>
          ),
        },
    transaction.processed !== 0 || transaction.status !== 'Processed'
      ? null
      : {
          id: 2,
          onClick: onStartRevert,
          content: (
            <>
              <BxIcon
                name='bx bx-history'
                className='font-size-14 text-muted'
              />
              <span>Revert</span>
            </>
          ),
        },
    {
      id: 3,
      onClick: () => {
        setIsActionLoading(WITHDRAW_ACTIONS_STATUS.ARCHIVE)
        archive.startFetch({
          transaction_id: transaction.transaction_id,
          status: transaction?.archived ? 0 : 1,
        })
      },
      content: (
        <>
          <BxIcon
            name='bx bx-archive-in'
            className='font-size-14 text-muted'
            loading={isActionLoading === WITHDRAW_ACTIONS_STATUS.ARCHIVE}
          />
          <span>{transaction?.archived ? 'Unarchive' : 'Archive'}</span>
        </>
      ),
    },
    {
      id: 4,
      onClick: onStartPreferedProvider,
      content: (
        <>
          <BxIcon name='bx bx-star' className='font-size-14 text-muted' />
          <span>Prefered Provider</span>
        </>
      ),
    },
    {
      id: 5,
      onClick: onStartReview,
      content: (
        <>
          <BxIcon name='bx bx-revision' className='font-size-14 text-muted' />
          <span>
            {transaction.under_review
              ? 'Unmark under review'
              : 'Mark under review'}
          </span>
        </>
      ),
    },
    transaction.status !== 'Confirmed'
      ? null
      : {
          id: 6,
          onClick: () => {
            handleDownloadProviderReceipt(transaction)
          },
          content: (
            <>
              <BxIcon
                name='bx bx-download'
                className='font-size-14 text-muted'
              />
              <span>Download Provider’s Receipt</span>
            </>
          ),
        },
    ['ebury', 'transfermate'].includes(transaction.provider) &&
    transaction.processed === 1
      ? {
          id: 7,
          onClick: () => {
            setIsActionLoading(WITHDRAW_ACTIONS_STATUS.TICKET)
            ticket.startFetch({
              transaction_id: transaction.transaction_id,
            })
          },
          content: (
            <>
              <BxIcon
                name='bx bx-mail-send'
                className='font-size-14 text-muted'
                loading={isActionLoading === WITHDRAW_ACTIONS_STATUS.TICKET}
              />
              <span>Send email to provider</span>
            </>
          ),
        }
      : null,
    {
      id: 2,
      onClick: onStartManualProcess,
      content: (
        <>
          <BxIcon name='bx bx-transfer' className='font-size-14 text-muted' />
          <span>Manual process</span>
        </>
      ),
    },
  ].filter(Boolean)

  return (
    <ActionsDropdown
      options={withdrawsOptions}
      isOpen={isOpen}
      toggle={() => setIsOpen((t) => !t)}
    />
  )
}

export function BxIcon({ loading, name, className }) {
  return (
    <i
      className={cx(
        `${loading ? 'bx bx-loader-circle bx-spin' : name}`,
        className,
      )}
    />
  )
}

function UserFullName({ data, onCompleteAction, onUserClick, prefillComment }) {
  const fullName = [
    data.client?.first_name,
    data.client?.middle_name,
    data.client?.last_name,
  ]
    .filter(Boolean)
    .join(' ')

  return (
    <>
      {data.client.is_flagged ? (
        <span
          className='position-absolute d-inline-flex align-items-center font-size-16'
          style={{
            top: '50%',
            transform: 'translate(-25px, -50%)',
            gap: '0.25rem',
          }}
        >
          <UserFlagging
            user={data?.client}
            onCompleteAction={onCompleteAction}
          />
        </span>
      ) : null}

      <button
        onClick={onUserClick}
        className='rp-btn-nostyle px-0 text-primary d-inline-block text-truncate'
        style={{
          maxWidth: 'calc(100% - 44px)',
        }}
        title={fullName}
      >
        {fullName}
      </button>

      <span
        className='position-absolute d-inline-flex align-items-center font-size-16'
        style={{
          top: '50%',
          transform: 'translate(5px, -50%)',
        }}
      >
        {data?.kyc_verified ? (
          <i
            className={`bx ${
              data.kyc_verified === 1
                ? 'bxs-check-shield text-success'
                : 'bxs-shield-x text-danger'
            } font-size-16 ml-1`}
            title={data.kyc_verified === 1 ? 'Kyc verified' : 'Kyc unverified'}
          />
        ) : null}
        <UserComments
          comments={data.comments}
          userId={data.client.id}
          onCompleteAction={onCompleteAction}
          prefillComment={prefillComment}
        />
      </span>
    </>
  )
}

export default Transactions
