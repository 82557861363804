import cx from 'classnames'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Alert } from 'reactstrap'

import { useSelector } from 'react-redux'
import BadgeX from '../../components/Table/BadgeX'
import Button from '../../components/ui/button'
import { getCookie, setCookie } from '../../utils/cookies'
import { PHYSICAL_CARD_BANNER_COOKIE } from '../../utils/cookies/cookie-names'
import styles from './physical-card-banner.module.scss'

export default function PhysicalCardBanner() {
  const physicalCardBannerCookie = JSON.parse(
    getCookie(PHYSICAL_CARD_BANNER_COOKIE) ?? 'false',
  )

  const userProfileCard = useSelector(
    (state) => state?.userProfile?.userProfile?.card,
  )

  const physicalBannerVisible = userProfileCard?.physical_card_banner?.visible
  const [showPhysicalCardBanner, setShowPhysicalCardBanner] = useState(
    physicalCardBannerCookie ? false : physicalBannerVisible,
  )

  function handleToggle() {
    setShowPhysicalCardBanner((isShown) => {
      if (isShown) {
        const twoWeeks = 15
        setCookie(PHYSICAL_CARD_BANNER_COOKIE, true, { expires: twoWeeks })
      }

      return !isShown
    })
  }

  return (
    <Alert
      color='light'
      className={cx(
        'mb-4 d-flex justify-content-between align-items-md-center flex-column flex-md-row bg-white py-4',
        styles.physicalBanner,
      )}
      style={{ borderBottom: '2px solid #EEEFF1' }}
      closeClassName='btn-close'
      toggle={handleToggle}
      isOpen={showPhysicalCardBanner}
    >
      <div className='d-flex align-items-start align-items-md-center flex-column flex-md-row gap-16'>
        <div className='align-items-center d-flex'>
          <i
            className='bx bx-credit-card d-flex text-primary-100 bg-primary-20 p-3 rounded-circle'
            style={{ fontSize: 32 }}
          />

          <NewBadge className='d-md-none ml-n1' />
        </div>

        <div>
          <div className='mb-1 d-flex align-items-center gap-12'>
            <h3 className='rp-font-gilroyB font-size-24 mb-0'>
              The physical card is here!
            </h3>

            <NewBadge className='d-none d-md-block' />
          </div>
          <p className='mb-0 font-size-14'>
            Get paid faster with zero fees! Order your card today.
          </p>
        </div>
      </div>

      <div className='position-relative mt-3'>
        <RedIndicator />

        <Button
          iconRight={
            <i className='bx bx-right-arrow-alt font-size-20 d-flex' />
          }
          block
          tag={Link}
          to='/cards'
        >
          Order yours now
        </Button>
      </div>
    </Alert>
  )
}

function NewBadge({ className }) {
  return (
    <BadgeX
      className={cx('rounded-pill uppercase p-1.5 rp-font-bold', className)}
      size='sm'
      color='primary'
    >
      New
    </BadgeX>
  )
}

export function RedIndicator() {
  return (
    <div className='position-absolute d-flex justify-content-center align-items-center'>
      <div
        className='rounded-circle position-absolute bg-white'
        style={{
          '--size': '26px',
          height: 'var(--size)',
          width: 'var(--size)',
        }}
      />
      <div
        className='rounded-circle position-absolute bg-red-20'
        style={{
          '--size': '20px',
          height: 'var(--size)',
          width: 'var(--size)',
        }}
      />
      <div
        className='rounded-circle position-absolute bg-red-40'
        style={{
          '--size': '16px',
          height: 'var(--size)',
          width: 'var(--size)',
        }}
      />
      <div
        className='rounded-circle position-absolute bg-red-60'
        style={{
          '--size': '12px',
          height: 'var(--size)',
          width: 'var(--size)',
        }}
      />
      <div
        className='rounded-circle position-absolute bg-red-100'
        style={{ '--size': '8px', height: 'var(--size)', width: 'var(--size)' }}
      />
    </div>
  )
}
