import { yupResolver } from '@hookform/resolvers/yup'
import { addMinutes, format, isEqual, isFuture } from 'date-fns'
import React, { useEffect, useState } from 'react'
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { useHistory } from 'react-router'
import {
  Card,
  Container,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  TabContent,
  TabPane,
} from 'reactstrap'
import toastr from 'toastr'
import * as yup from 'yup'

import { ReactComponent as CardPinSuccess } from '../../../assets/images/card-pin-success.svg'
import { ControlledVerificationCode } from '../../../components/controlled-verification-code'
import ControlledInput from '../../../components/ControlledInput'
import ModalHeader from '../../../components/ModalHeader'
import Steps from '../../../components/Steps'
import StepContainer from '../../../components/Steps/StepContainer'
import Button from '../../../components/ui/button'
import Loader from '../../../components/ui/loader'
import { useFetch } from '../../../helpers/hooks'
import {
  activatePhysicalCard,
  cardActivity,
  resendCardActivationCode,
} from '../../../services/api'
import { isPhysicalCardPending } from '../organisms/physical-card-states'
import { CARD_STATUSES, CARD_TYPE_ENUM } from '../utils/card-types-status'
import { showYupErrors } from './cards-kyc'
import './pin_code.css'

function formatCreditCardNumber(value) {
  if (!value) return value

  return value
    .toString()
    .replace(/\s+/g, '')
    .replace(/[^0-9]/gi, '')
    .replace(/(.{4})/g, '$1 ')
    .trim()
}

function creditCardNumberToString(value) {
  if (!value) return value

  return value
    .toString()
    .replace(/\s+/g, '')
    .replace(/[^0-9]/gi, '')
}

function ActivationCode({ control, errors, onResendCode }) {
  const { setError } = useFormContext()

  return (
    <>
      <FormGroup className='pin-code mb-5'>
        <Label className='font-size-24'>Enter your activation code</Label>
        <small className='d-block font-size-16 text-muted mb-3'>
          Validation code was sent to your email address
        </small>
        <div className='align-items-center d-flex flex-column mb-2'>
          <ControlledVerificationCode
            control={control}
            name='activation_code'
            id='activation_code'
            length={6}
            error={errors?.activation_code}
          />
        </div>
        <small className='d-block font-size-14 text-muted rp-font-medium'>
          Did not get the activation code? <ResendCode resend={onResendCode} />
        </small>
      </FormGroup>

      <FormGroup>
        <ControlledInput
          control={control}
          labelClassName='font-size-24'
          className='mx-auto mb-1 rp-font-medium tabular-nums placeholder:font-size-34'
          style={{
            '--l-space': '2px',
            width: 'calc(19ch + var(--l-space) * 19 + 4px)',
            letterSpacing: 'var(--l-space)',
            fontSize: '1.5rem',
          }}
          label='Enter your card number'
          name='card_number'
          id='card_number'
          placeholder='____ ____ ____ ____'
          error={errors?.card_number}
          transform={{
            input: (value) => {
              const formattedValue = formatCreditCardNumber(value.slice(0, 16))

              return formattedValue
            },
            output: (e) => {
              const output = e.target.value
              const stringValue = creditCardNumberToString(output)

              if (stringValue.length > 16) {
                const error = 'Card number should be 16 digits'
                setError('card_number', { message: error })
              } else {
                setError('card_number', { message: '' })
              }

              return stringValue.slice(0, 16)
            },
          }}
        />
      </FormGroup>
    </>
  )
}

const confirmPinName = 'confirm_pin_code'
function Pin({ control, errors }) {
  const pinConfirmation = useWatch({ name: confirmPinName, control })
  const { setError } = useFormContext()

  return (
    <>
      <div className='rp-font-gilroyB font-size-24 mb-4'>
        Create your 4-digit PIN
      </div>

      <FormGroup className='pin-code mb-4'>
        <div className='align-items-center d-flex flex-column'>
          <ControlledVerificationCode
            control={control}
            labelClassName='font-size-16 mb-3 mt-4 rp-font-medium text-muted'
            label='The PIN is often required for offline purchases'
            transform={{
              output: (newValue) => {
                if (pinConfirmation && newValue === pinConfirmation) {
                  setError(confirmPinName, { message: '' })
                }
                return newValue
              },
            }}
            name='pin_code'
            id='pin_code'
            length={4}
            error={errors?.pin_code}
          />
        </div>
      </FormGroup>

      <FormGroup className='pin-code'>
        <div className='align-items-center d-flex flex-column'>
          <ControlledVerificationCode
            control={control}
            labelClassName='font-size-16 mb-3 mt-4 rp-font-medium text-muted'
            label='Confirm your 4-digit PIN'
            name={confirmPinName}
            id={confirmPinName}
            length={4}
            error={errors?.[confirmPinName]}
          />
        </div>
      </FormGroup>
    </>
  )
}

const stepsDetails = {
  activationCode: {
    stepIndex: 0,
    label: 'Active',
    title: 'Let’s activate your physical card',
    Step: ActivationCode,
  },
  pin: {
    stepIndex: 1,
    label: 'Set a PIN',
    title: 'Let’s secure your card',
    Step: Pin,
  },
}

const steps = [
  {
    id: stepsDetails.activationCode.stepIndex,
    label: stepsDetails.activationCode.label,
    title: stepsDetails.activationCode.title,
    Step: stepsDetails.activationCode.Step,
    schema: yup.object().shape({
      activation_code: getSchema(6, 'Activation Code'),
      card_number: getSchema(16, 'Card Number'),
    }),
  },
  {
    id: stepsDetails.pin.stepIndex,
    label: stepsDetails.pin.label,
    title: stepsDetails.pin.title,
    Step: stepsDetails.pin.Step,
    schema: yup.object().shape({
      pin_code: getSchema(4, 'PIN Code'),
      [confirmPinName]: yup
        .string()
        .oneOf([yup.ref('pin_code'), null], 'Confirmation PIN Code must match')
        .required('Confirm PIN Code is required'),
    }),
  },
]

function getPhysicalCard(cards) {
  if (!cards || !Array.isArray(cards)) return {}

  return cards.find(
    (card) =>
      card.card_type === CARD_TYPE_ENUM.PHYSICAL &&
      card.status.text === CARD_STATUSES.PENDING_ACTIVATION,
  )
}

export default function ActivatePhysicalCard() {
  const history = useHistory()

  const [activeStep, setActiveStep] = useState(steps[0].id)

  const { startFetch: resendCode, isLoading: sendingCode } = useFetch({
    action: resendCardActivationCode,
    onError: (resp) => {
      toastr.error(resp?.error?.messages?.join(', ') || 'Something went wrong')
    },
  })

  function resendCodeHandler(physicalCard) {
    resendCode({ card_id: physicalCard.id })
  }

  const { data: cards, isLoading: cardsLoading } = useFetch({
    action: cardActivity,
    autoFetch: true,
    onComplete: (data) => {
      const physicalCardPending = isPhysicalCardPending({
        status: data?.card_order?.status,
        type: data?.card_order?.card_type,
      })

      if (!physicalCardPending) {
        history.push('/cards')
      }

      const physicalCard = getPhysicalCard(data.cards)
      resendCodeHandler(physicalCard)
    },
  })

  const stepLoading = cardsLoading || sendingCode

  return (
    <Container fluid>
      <ModalHeader action={() => history.push('/cards')}>
        {steps?.length <= 1 ? null : (
          <Steps
            className='d-none d-md-flex'
            activeTab={activeStep}
            data={steps.map((step) => step.label)}
            noLastAction
          />
        )}
      </ModalHeader>

      <div className='d-flex flex-column' style={{ marginBottom: '9rem' }}>
        <TabContent activeTab={activeStep}>
          {stepLoading ? (
            <Card style={{ maxWidth: 820, margin: '80px auto 0px auto' }}>
              <Loader minHeight='63vh' />
            </Card>
          ) : (
            <ActivationForm
              card={getPhysicalCard(cards?.cards)}
              resendCode={resendCodeHandler}
              steps={steps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          )}
        </TabContent>
      </div>
    </Container>
  )
}

function getSchema(length, label) {
  return yup
    .string()
    .length(length, `${label} must be ${length} characters`)
    .matches(/^\d+$/, `${label} must be numeric`)
    .required(`${label} is required`)
}

const schema = steps[0].schema.concat(steps[1].schema)

function ActivationForm({
  card,
  resendCode,
  steps,
  activeStep,
  setActiveStep,
}) {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)

  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: { card_number: '' },
  })
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
  } = formMethods

  const { startFetch: activateCard, isLoading } = useFetch({
    action: activatePhysicalCard,
    onComplete: () => {
      toastr.clear()
      setIsSuccessModalOpen(true)
    },
    onError: (resp) => {
      toastr.error(resp?.error?.messages.join(', ') || 'Something went wrong')
    },
  })

  const nextIsLoadingAndDisabled = isLoading

  function onSubmit(data) {
    const body = { ...data, card_id: card?.id }
    delete body?.[confirmPinName]

    activateCard(body)
  }

  function onError(error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }

  const submitStep = handleSubmit(onSubmit, onError)

  function handleNext() {
    if (activeStep === steps[0]?.id) {
      toastr.clear()
      steps[0].schema
        .validate(getValues(), { abortEarly: false })
        .then(() => {
          setError('card_number', { message: '' })
          setActiveStep((step) => step + 1)
        })
        .catch(({ inner }) => showYupErrors(inner))
    } else {
      submitStep()
    }
  }

  function handleBack() {
    setActiveStep((step) => step - 1)
  }

  function onResendCode() {
    resendCode(card)
  }

  return (
    <>
      {steps.map((step) => {
        return (
          <TabPane tabId={step.id} key={step.id}>
            <StepContainer
              total={steps.length}
              index={step.id}
              title={step.title}
              loading={nextIsLoadingAndDisabled}
              disableNext={nextIsLoadingAndDisabled}
              nextText={
                steps[steps.length - 1].id === step.id ? 'Continue' : 'Next'
              }
              onNext={handleNext}
              onBack={handleBack}
            >
              <div className='text-center' style={{ minHeight: '50vh' }}>
                <FormProvider {...formMethods}>
                  <step.Step
                    onResendCode={onResendCode}
                    control={control}
                    errors={errors}
                  />
                </FormProvider>
              </div>
            </StepContainer>
          </TabPane>
        )
      })}

      <PinSetSuccessModal isOpen={isSuccessModalOpen} isActivation />
    </>
  )
}

function formatTime(date) {
  return format(new Date(date), 'm:ss')
}

function diffFromNow(date) {
  return new Date(date) - new Date()
}

function ResendCode({ resend }) {
  const [isResend, setIsResend] = useState(true)
  const [future, setFuture] = useState(() => {
    const defaultFuture = addMinutes(new Date(), 1)
    const formatted = formatTime(diffFromNow(defaultFuture))

    return { date: defaultFuture, formatted }
  })

  useEffect(() => {
    const second = setInterval(() => {
      const now = new Date().getTime()
      if (!isFuture(future.date) || isEqual(future.date, now)) {
        clearInterval(second)
        setIsResend(false)
      } else {
        const formatted = formatTime(diffFromNow(future.date))

        setFuture((prev) => ({ ...prev, formatted }))
      }
    }, 900)

    return () => {
      clearInterval(second)
    }
  }, [future])

  if (isResend) {
    return <span>Resend in {future.formatted}</span>
  }

  return (
    <button className='rp-btn-nostyle text-primary p-0' onClick={resend}>
      Resend
    </button>
  )
}

export function PinSetSuccessModal({ isOpen, isActivation }) {
  const history = useHistory()

  return (
    <Modal isOpen={isOpen ?? true} centered>
      <ModalBody className='text-center py-5'>
        <CardPinSuccess className='mb-4' />
        {isActivation ? (
          <>
            <h2 className='rp-font-gilroyB'>Your card is ready!</h2>
            <p className='mb-0 text-muted'>
              Your card is secure and ready to spend.
            </p>
          </>
        ) : (
          <h2 className='rp-font-gilroyB'>
            You have successfully changed your PIN.
          </h2>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            history.push('/cards')
            window.scroll(0, 0)
          }}
        >
          Done
        </Button>
      </ModalFooter>
    </Modal>
  )
}
