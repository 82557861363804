import cx from 'classnames'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { components } from 'react-select'
import { Badge, Col, Container, Row, Spinner, Table } from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'

import archiveIcon from '../../../assets/images/archiveBlack.svg'
import selectedIcon from '../../../assets/images/selectedBlue.svg'
import unArchiveIcon from '../../../assets/images/unarchive.svg'
import ActionsDropdown from '../../../components/ActionsDropdown'
import Avatar from '../../../components/Avatar'
import ConfirmationModal from '../../../components/Common/ConfirmationModal'
import NoContent from '../../../components/NoContent'
import SearchBar from '../../../components/SearchBar'
import { StyledH4, StyledH6, StyledP } from '../../../components/Typo'
import Loader from '../../../components/ui/loader'
import WiseButton from '../../../components/WiseButton'
import { userTypes } from '../../../helpers/enum'
import { useFetch, usePermissions } from '../../../helpers/hooks'
import permissions from '../../../helpers/permissions'
import {
  archiveAdminContract,
  archiveContract,
  deleteContractTag,
  getContractDetail,
  getTagsList,
} from '../../../services/api'
import { setContractToDuplicate } from '../../../store/contract/actions'
import ContractRef from '../../AdminPanel/components/ContractRef'
import Tags from '../components/Tags'
import welcomeImage from './../../../assets/images/welcome.svg'
import FiltersDropdown from './FiltersDropdown'

const StyledRow = styled(Row)`
  @media (max-width: 768px) {
    gap: 0.5rem;
  }
`

const ClientContractList = (props) => {
  const user = useSelector((state) => state?.Account?.user)
  const { hasAccess } = usePermissions()
  const [data, setData] = useState(props.Orders || [])
  const [query, setQuery] = useState(null)
  const [allTags, setTagsList] = useState([])
  const [dataToDelete, setDataToDelete] = useState(null)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [showFilters, setShowFilters] = useState(false)

  const status = useSelector(
    (state) => state?.Layout?.staticData?.contract_statuses,
  )
  const getStatusColor = (status) => {
    switch (status?.id) {
      case 4:
        return 'success'
      case 5:
        return 'danger'
      case 6:
        return 'danger'
      case 7:
        return 'danger'
      default:
        return 'warning'
    }
  }
  const tags = useFetch({
    action: getTagsList,
    autoFetch: true,
  })
  useEffect(() => {
    setTagsList(tags?.data)
  }, [tags])

  useEffect(() => {
    setData(props.Orders)
  }, [props.Orders])

  const handleSearch = (query) => {
    setQuery(query)
    if (props.onQuery && query && query !== '') {
      props.onQuery(query)
      return
    }
    if (!query || query === '') {
      setData(props.Orders)
      return
    }

    const newData = props.Orders.filter((e) => {
      const secondPart =
        user?.type === userTypes.COMPANY ? e?.contractor : e?.client
      return (
        e?.name?.toUpperCase()?.includes(query?.toUpperCase()) ||
        e?.ref?.toUpperCase()?.includes(query?.toUpperCase()) ||
        `${secondPart?.first_name} ${secondPart?.last_name}`
          ?.toUpperCase()
          ?.includes(query?.toUpperCase())
      )
      // secondPart?.last_name?.toUpperCase()?.includes(query)
    })
    if (newData.length || newData.length === 0) {
      setData(newData)
    }
  }

  const deleteTagTotally = (data) => {
    setDataToDelete(data)
    setConfirmDelete(true)
  }

  const getContractDetailUrl = (contract) => {
    if (
      !props.isAdmin &&
      (hasAccess(permissions.ViewContractsDetails) ||
        user?.type === userTypes.CONTRACTOR)
    ) {
      return `/contract/detail?id=${contract.ref}`
    } else if (props.isAdmin) {
      return `/admin/contract/detail?id=${contract.ref}`
    } else {
      return '#'
    }
  }

  const showFiltersBlock =
    props.withArchive && (user?.type !== userTypes.CONTRACTOR || props.isAdmin)

  return (props.Orders && props.Orders?.length > 0) ||
    props.isAdmin ||
    props.filtering ||
    props.statusQueries.length > 0 ||
    props.tagQueries.length > 0 ? (
    <StyledContentDiv>
      {!showFiltersBlock ? null : (
        <Container fluid className='p-3 px-md-4'>
          <StyledRow>
            {props?.withCustomFilter && user?.type === userTypes.COMPANY && (
              <Col md={2} className='pl-md-3 pr-md-0'>
                <FiltersDropdown
                  tagsFilters={allTags}
                  statusFilters={status?.map((e) => ({
                    label: e.name,
                    value: e.id,
                  }))}
                  isOpen={showFilters}
                  toggle={() => setShowFilters((t) => !t)}
                  filterByStatus={props.filterByStatus}
                  filterByTag={props.filterByTag}
                  clearFilters={() => {
                    setShowFilters(false)
                    props.onUpdate([], [], 'clearF')
                  }}
                  tagQueries={props.tagQueries}
                  statusQueries={props.statusQueries}
                />
              </Col>
            )}
            <Col md={10} className='pl-md-0 pr-md-3'>
              <SearchBar
                query={query}
                placeholder={
                  props.isAdmin ? 'Search by name, contract ID' : undefined
                }
                onQueryChanged={handleSearch}
                rightRounded
              />
            </Col>
          </StyledRow>
        </Container>
      )}

      <ConfirmationModal
        toggle={() => setConfirmDelete(false)}
        isOpen={confirmDelete}
        caption='Remove'
        title={'Are you sure you want to remove this option?'}
        message={
          'Are you sure you want to delete this tag? This action is irreversible and the tag will be removed from all contracts.'
        }
        onConfirm={() => {
          deleteContractTag(user?.token, { tag_id: dataToDelete.id })
            .then((r) => {
              if (r.data.success) {
                tags.startFetch()
                props.onUpdate([], [], '')
                setConfirmDelete(false)
              }
            })
            .catch((e) => {
              toastr.error(e)
            })
        }}
      />

      {props.filtering ? (
        <Loader minHeight='400px' />
      ) : (
        <>
          <div
            className='p-3 d-md-none'
            style={{
              backgroundColor: '#FFFFFF',
              boxShadow: '0px 1px 0px #DFE1E6',
              borderRadius: 4,
            }}
          >
            {data?.map((order, i) => (
              <ContractCard
                index={i}
                key={'_order_' + i}
                archiveValue={props.archiveValue}
                withArchive={props.withArchive}
                order={order}
                isAdmin={props.isAdmin}
                getContractDetailUrl={getContractDetailUrl}
                getStatusColor={getStatusColor}
                onUpdate={props.onUpdate}
              />
            ))}
          </div>

          <div
            className={cx('d-none d-md-block', {
              'table-with-dropdown': !props.isAdmin,
            })}
          >
            <div className='table-responsive'>
              <Table className='table table-centered table-nowrap text-muted mb-0'>
                <thead>
                  <tr
                    style={{
                      borderTop: 'hidden',
                      borderBottom: '1px solid #E7E8F2',
                      width: '100px',
                      maxWidth: '100px',
                    }}
                  >
                    <th
                      style={{ fontWeight: '500' }}
                      className='text-dark font-size-14 px-4'
                    >
                      Contract ID
                    </th>
                    {props.isAdmin && (
                      <th
                        style={{ fontWeight: '500' }}
                        className='text-dark font-size-14'
                      >
                        Created on{' '}
                      </th>
                    )}
                    <th
                      colSpan={2}
                      style={{
                        fontWeight: '500',
                        width: '280px',
                        maxWidth: '280px',
                      }}
                      className='text-dark font-size-14'
                    >
                      Role
                    </th>
                    <th
                      style={{
                        fontWeight: '500',
                        width: '120px',
                        maxWidth: '120px',
                      }}
                      className='text-dark font-size-14'
                    >
                      Rate
                    </th>
                    {!!props.isAdmin && (
                      <th
                        style={{ fontWeight: '500' }}
                        className='text-dark font-size-14'
                      >
                        Company
                      </th>
                    )}
                    <th
                      style={{
                        fontWeight: '500',
                        width: '250px',
                        maxWidth: '250px',
                      }}
                      className='text-dark font-size-14'
                    >
                      {!!props.isAdmin || user?.type === 'client'
                        ? 'Name'
                        : 'Client'}
                    </th>

                    {!!props.isAdmin && (
                      <th
                        style={{ fontWeight: '500' }}
                        className='text-dark font-size-14'
                      >
                        Client
                      </th>
                    )}
                    <th
                      style={{
                        fontWeight: '500',
                        width: '100px',
                        maxWidth: '100px',
                      }}
                      className='text-dark font-size-14'
                    >
                      Status
                    </th>
                    {!props.isAdmin &&
                      props.withArchive &&
                      hasAccess(permissions.archiveContracts) && (
                        <th
                          style={{ fontWeight: '500' }}
                          className='text-dark font-size-14'
                        ></th>
                      )}
                  </tr>
                </thead>

                {!props.filtering && (
                  <tbody>
                    {data?.map((order) => {
                      return (
                        <ContractLine
                          key={`contract-${order?.ref}`}
                          allTags={allTags}
                          order={order}
                          isAdmin={props.isAdmin}
                          handleUserClick={props.handleUserClick}
                          archiveValue={props.archiveValue}
                          withArchive={props?.withArchive}
                          getStatusColor={getStatusColor}
                          getContractDetailUrl={getContractDetailUrl}
                          onUpdate={props.onUpdate}
                          deleteTagTotally={deleteTagTotally}
                          updateTags={() => tags.startFetch()}
                          setNewContract={setData}
                          contractsList={data}
                        />
                      )
                    })}
                  </tbody>
                )}
              </Table>
              {props.filtering && (
                <Container style={{ minHeight: '20rem' }}>
                  <Row
                    style={{ minHeight: '30rem' }}
                    className='justify-content-center align-items-center'
                  >
                    <Spinner type='grow' className='mr-2' color='primary' />
                  </Row>
                </Container>
              )}
              {data?.length === 0 && (
                <div
                  style={{ width: '100% important' }}
                  className='py-4 w-100 d-flex align-items-center justify-content-center'
                >
                  <StyledH4>No results match your search</StyledH4>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </StyledContentDiv>
  ) : (
    <div>
      <NoContent
        image={welcomeImage}
        subtitle={
          user?.type === userTypes.COMPANY
            ? undefined
            : 'Manage contracts, invoices and get paid in +126 currencies'
        }
        title={''}
        withAction={hasAccess(permissions.CreateContracts)}
        actionTitle='New Contract'
        onAction={() => {
          props.history.push('/contract/create')
        }}
      />
    </div>
  )
}

const ContractCard = ({
  order,
  withArchive,
  archiveValue,
  getContractDetailUrl,
  getStatusColor,
  isAdmin,
  onUpdate,
}) => {
  const user = useSelector((state) => state?.Account?.user)
  const { hasAccess } = usePermissions()

  const secondPart =
    user?.type === userTypes.COMPANY ? order?.contractor : order?.client

  const archive = useFetch({
    action: isAdmin ? archiveAdminContract : archiveContract,
    withAdminAccess: isAdmin,
    onComplete: (_, body) => {
      onUpdate([], [])
      window.analytics.track('Archived a contract', {
        contract_id: body?.contract_id,
        contract_status: body?.contract?.status?.name,
      })
    },
  })
  return (
    <div
      className=' py-0   mb-3'
      style={{
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 1px 0px #DFE1E6',
        borderRadius: 4,
        border: '1px solid #E7E8F2',
        position: 'relative',
      }}
    >
      <Row className='p-3 m-0 border-bottom'>
        <Col xs={8} className='p-0 m-0'>
          <p
            style={{ fontWeight: 'bold' }}
            className={'text-dark   font-size-14 mb-0'}
          >
            {order.name}
          </p>
          <Link
            style={{ textDecoration: 'none' }}
            className='text-primary border-bottom border-secondary font-size-12'
            to={getContractDetailUrl(order)}
          >
            {order.ref}
          </Link>
        </Col>
        <Col
          xs={4}
          className='align-items-center justify-content-end d-flex p-0 m-0'
        >
          <Badge
            className={`font-size-14  rounded bg-soft-${getStatusColor(
              order?.status,
            )}`}
            color='white'
            pill
          >
            <StyledP
              style={{ padding: 4 }}
              min={'12px'}
              max={'14px'}
              className={`mb-0 text-${getStatusColor(order?.status)}`}
            >
              {order?.status?.name.includes('Pending')
                ? 'Pending'
                : order?.status?.name}
            </StyledP>
          </Badge>
        </Col>
      </Row>
      <div className='p-3'>
        <div className='d-flex mb-3 align-items-center justify-content-between align-items-center'>
          <h6 className={'text-dark font-weight-normal mb-0   font-size-14'}>
            Created on
          </h6>
          <h6 className='font-weight-normal mb-0 text-dark   font-size-14'>
            {moment(order.created_at * 1000).format('MM/DD/YYYY HH:mm')}
          </h6>
        </div>
        <div className='d-flex mb-3 align-items-center justify-content-between align-items-center'>
          <h6 className={'text-dark font-weight-normal mb-0   font-size-14'}>
            Rate
          </h6>
          <h6 className='text-dark   font-size-14 font-weight-normal mb-0'>
            {order.total}
          </h6>
        </div>

        <div className='d-flex mb-3 justify-content-between align-items-center'>
          <h6 className='  text-dark font-weight-normal mb-0   font-size-14'>
            Type
          </h6>
          <h6 className='text-dark font-weight-normal mb-0   font-size-14'>
            {order.type}
          </h6>
        </div>

        <div className='d-flex mb-0 justify-content-between align-items-center'>
          <h6 className='text-dark font-weight-normal mb-0  font-size-14'>
            Contractor
          </h6>
          {secondPart
            ? !secondPart?.photo
            : !isAdmin && (
                <div className='text-dark   font-size-14'>
                  <Link
                    to={`/contract/detail?id=${order.ref}`}
                    className='font-weight-bold  text-primary font-size-14 '
                  >
                    Invite
                  </Link>
                </div>
              )}
          <h6 className={'text-dark font-weight-normal mb-0  font-size-14'}>
            {secondPart?.first_name || secondPart?.first_name
              ? `${secondPart?.first_name || ''}
               ${secondPart?.last_name || ''}`
              : secondPart?.email}
          </h6>
        </div>
        {!!isAdmin && (
          <div className='d-flex mt-3 justify-cInvoicesontent-between align-items-center'>
            <h6 className='text-dark font-weight-normal mb-0  font-size-14'>
              Company
            </h6>
            <StyledH6
              className='font-size-14'
              style={{
                color: '#4A4E5B',
                fontWeight: '400',
                marginLeft: '10px',
              }}
            >
              {order?.company}
            </StyledH6>
          </div>
        )}
        {!!isAdmin && (
          <div className='d-flex mt-3 justify-content-between align-items-center'>
            <h6 className='text-dark font-weight-normal mb-0  font-size-14'>
              Client
            </h6>
            <StyledH6
              className='font-size-14   '
              style={{
                color: '#4A4E5B',
                fontWeight: '400',
                marginLeft: '10px',
              }}
            >
              {order?.client?.first_name || order?.client?.first_name
                ? `${order?.client?.first_name || ''} ${
                    order?.client?.last_name || ''
                  }`
                : order?.client?.email}
            </StyledH6>
          </div>
        )}
      </div>
      {withArchive &&
        hasAccess(permissions.archiveContracts) &&
        (order?.status?.id !== 4 || archiveValue === 0 || isAdmin) && (
          <div className='px-3 pb-3 w-100'>
            {archive.isLoading ? (
              <div className='d-flex justify-content-center align-items-center'>
                <i className='bx bx-loader bx-spin font-size-16 text-primary' />
              </div>
            ) : (
              <WiseButton
                textColor={'dark'}
                onClick={() => {
                  archive.startFetch({
                    contract_id: order.id,
                    status: archiveValue,
                  })
                }}
                bk={'white'}
                border
                width={'100%'}
                minWidth={'100%'}
                text={archiveValue === 0 ? 'UnArchive' : 'Archive'}
                icon={
                  archiveValue === 0 ? (
                    <img
                      src={unArchiveIcon}
                      alt={'archive icon'}
                      style={{ width: 16, marginRight: 8 }}
                    />
                  ) : (
                    <img
                      src={archiveIcon}
                      alt={'archive icon'}
                      style={{ width: 16, marginRight: 8 }}
                    />
                  )
                }
              />
            )}
          </div>
        )}
    </div>
  )
}

const ContractLine = ({
  order,
  isAdmin,
  handleUserClick,
  withArchive,
  archiveValue,
  getContractDetailUrl,
  getStatusColor,
  onUpdate,
  allTags,
  deleteTagTotally,
  updateTags,
  setNewContract,
  contractsList,
}) => {
  const user = useSelector((state) => state?.Account?.user)
  const { hasAccess } = usePermissions()
  const dispatch = useDispatch()
  const history = useHistory()
  const [showActions, setShowActions] = useState(false)

  const toDuplicate = useFetch({
    action: getContractDetail,
    onComplete: (data) => {
      dispatch(setContractToDuplicate(data))
      history.push('/contract/create', {
        duplicating: true,
        backRoute: '/contracts',
      })
    },
  })

  const archive = useFetch({
    action: isAdmin ? archiveAdminContract : archiveContract,
    withAdminAccess: isAdmin,
    onComplete: (_, body) => {
      onUpdate([], [])
      window.analytics.track('Archived a contract', {
        contract_id: body?.contract_id,
        contract_status: body?.contract?.status?.name,
      })
    },
  })
  const secondPart =
    !!isAdmin || user?.type === userTypes.COMPANY
      ? order?.contractor
      : order?.client
  const total = order.total.split('/')
  const canArchive =
    (order?.status?.id !== 4 || archiveValue === 0 || isAdmin) &&
    withArchive &&
    hasAccess(permissions.archiveContracts)
  const canDuplicate =
    order?.type !== 'Full Time' && hasAccess(permissions.archiveContracts)

  const actionsOptions = []

  if (canArchive) {
    actionsOptions[0] = {
      component: () => (archiveValue === 0 ? 'Unarchive' : 'Archive'),
      onClick: () => {
        archive.startFetch({
          contract_id: order.id,
          status: archiveValue,
          contract: order,
        })
      },
    }
  }
  if (canDuplicate && user?.type !== userTypes.CONTRACTOR) {
    actionsOptions[1] = {
      component: () => 'Duplicate',
      onClick: () => {
        toDuplicate.startFetch({ id: order?.ref })
      },
    }
  }

  const contractUser =
    user?.type === userTypes.CONTRACTOR && !isAdmin
      ? order?.company_name || secondPart?.email
      : secondPart?.first_name
      ? `${secondPart?.first_name || ''} ${secondPart?.last_name || ''}`
      : secondPart?.email

  return (
    <tr className='pb-1' style={{ borderBottom: '1px solid #E7E8F2' }}>
      <td className='px-4' style={{ width: '100px', maxWidth: '100px' }}>
        {isAdmin ? (
          <ContractRef contractId={order.ref} />
        ) : (
          <Link
            className='text-primary font-size-14'
            style={{ fontWeight: '400' }}
            to={getContractDetailUrl(order)}
          >
            {order.ref}
          </Link>
        )}
      </td>
      {isAdmin && (
        <td className='text-dark font-size-14 ' style={{ fontWeight: '400' }}>
          {moment(order.created_at * 1000).format('MM/DD/YYYY HH:mm')}
        </td>
      )}
      <td
        colSpan='2'
        className='text-dark font-size-14'
        style={{ fontWeight: '400', width: '280px', maxWidth: '280px' }}
      >
        <StyledH6
          style={{ fontWeight: '400', color: '#424652' }}
          className='font-size-14 m-auto text-truncate'
          title={order?.name || ''}
        >
          {order?.name || ''}
        </StyledH6>
        <Tags
          contract={order}
          contractsList={contractsList}
          tags={order?.tags}
          tagsList={allTags}
          onUpdate={onUpdate}
          deleteTagTotally={deleteTagTotally}
          updateTags={updateTags}
          setNewContract={setNewContract}
        />
      </td>
      {/* <td className="text-dark font-size-14 " style={{ fontWeight: '400' }}>
      {order.type}
    </td> */}
      <td>
        {
          <StyledH6
            style={{
              fontWeight: '400',
              margin: 'auto',
              marginTop: '3px',
              width: '120px',
              maxWidth: '120px',
            }}
            className={'text-dark font-size-14'}
          >
            {total[0]}
            <span className={'text-secondary'}>/{total[1]}</span>
          </StyledH6>
        }
        <div
          style={{
            display: 'inline-block',
            backgroundColor: '#777F9E26',
            padding: '4px',
            marginTop: '8px',
            borderRadius: '4px',
          }}
        >
          <StyledH6
            style={{
              margin: 'auto',
              fontSize: '12px',
              color: '#1C1C1C',
              fontWeight: '400',
              fontFamily: 'inter',
            }}
          >
            {order.type}
          </StyledH6>
        </div>
      </td>
      {!!isAdmin && (
        <td className='text-dark font-size-14 ' style={{ fontWeight: '400' }}>
          {order.company}
        </td>
      )}
      <td style={{ width: '250px', maxWidth: '250px' }}>
        <Col>
          <Row className='align-items-center flex-nowrap overflow-hidden'>
            {secondPart ? (
              <Avatar
                name={
                  user?.type === userTypes.CONTRACTOR && !isAdmin
                    ? order.company_name || secondPart?.email
                    : secondPart?.first_name || secondPart?.email
                }
                photo={
                  user?.type === userTypes.CONTRACTOR && !isAdmin
                    ? order?.company_logo
                    : secondPart?.photo
                }
                flag={
                  (user?.type === userTypes.COMPANY || isAdmin) &&
                  secondPart?.flag
                }
              />
            ) : isAdmin ? null : (
              <div className='py-2'>
                <Link
                  to={`/contract/detail?id=${order.ref}`}
                  className='font-weight-bold'
                >
                  Invite
                </Link>
              </div>
            )}
            <div
              className='font-size-14 ml-2 rp-font-bold text-gray-h'
              translate='no'
            >
              {isAdmin ? (
                <Link onClick={() => handleUserClick(order)} to={'#'}>
                  {contractUser?.length > 17
                    ? contractUser?.slice(0, 17) + '...'
                    : contractUser || ''}
                </Link>
              ) : contractUser?.length > 17 ? (
                contractUser?.slice(0, 17) + '...'
              ) : (
                contractUser || ''
              )}
            </div>
          </Row>
        </Col>
      </td>
      {!!isAdmin && (
        <td>
          <Col>
            <Row className='align-items-center flex-nowrap'>
              {!!order?.client && (
                <Avatar
                  photo={order?.client?.photo}
                  name={order?.client?.first_name || order?.client?.email}
                  flag={order?.client?.flag}
                />
              )}
              <div
                className='font-size-14'
                style={{
                  color: '#4A4E5B',
                  fontWeight: '600',
                  marginLeft: '10px',
                }}
              >
                {order?.client?.first_name || order?.client?.first_name
                  ? `${order?.client?.first_name || ''} ${
                      order?.client?.last_name || ''
                    }`
                  : order?.client?.email}
              </div>
            </Row>
          </Col>
        </td>
      )}
      <td style={{ width: '100px', maxWidth: '100px' }}>
        <div className='flex-row'>
          {order?.amended ? (
            <Badge
              className='font-size-12 rounded bg-soft-warning'
              color='white'
              pill
            >
              <p
                style={{ padding: '3px 2px' }}
                className='font-size-14  mb-0  text-warning'
              >
                Pending
              </p>
            </Badge>
          ) : (
            <Badge
              className={`font-size-12 rounded bg-soft-${getStatusColor(
                order?.status,
              )}`}
              color='white'
              pill
            >
              <p
                style={{ padding: '3px 2px' }}
                className={`font-size-14  mb-0   text-${getStatusColor(
                  order?.status,
                )}`}
              >
                {order?.status?.name.includes('Pending')
                  ? 'Pending'
                  : order?.status?.name}
              </p>
            </Badge>
          )}
        </div>
      </td>
      {!isAdmin && (
        <td className='position-relative'>
          {actionsOptions?.length > 0 && (
            <Row
              style={{ minWidth: '100px' }}
              className='justify-content-center'
            >
              {archive.isLoading || toDuplicate.isLoading ? (
                <i className='bx bx-loader bx-spin text-dark font-size-24' />
              ) : (
                <ActionsDropdown
                  toggle={() => setShowActions((t) => !t)}
                  isOpen={showActions}
                  options={actionsOptions}
                />
              )}
            </Row>
          )}
        </td>
      )}
    </tr>
  )
}

const StyledContentDiv = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 0px #dfe1e6;
  border-radius: 4px;
`

export const StyledIcon = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  background: ${(props) => ` url(${props.icon}) no-repeat `};

  &:hover {
    background: ${(props) => ` url(${props.hoverIcon}) no-repeat 0 0`};
  }

  p {
    margin-left: 25px;

    &:hover {
      color: #3250e7;
    }
  }
`
const { Option } = components
export const customOption = (props) => {
  return (
    <Option {...props}>
      <div>
        <div className='d-flex flex-row align-items-center justify-content-between'>
          <span
            className={`text-${props.isSelected ? 'primary' : 'dark'}`}
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'visible',
              backgroundColor: 'white',
              fontSize: '14px',
              fontWeight: '400',
              fontFamily: 'inter',
            }}
          >
            {props.data.label}
          </span>
          {props.isSelected && <img src={selectedIcon} width={8.8} alt='' />}
        </div>
      </div>
    </Option>
  )
}

export const customSingleValue = (props) => {
  return (
    <div className='input-select'>
      <div className='input-select__single-value d-flex flex-row align-items-center'>
        {props.data.icon}
        <span
          className={props.data.def ? 'text-dark' : 'text-secondary'}
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          <span
            className='text-secondary'
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {props?.data.def}{' '}
          </span>
          {props.data.label}
        </span>
      </div>
    </div>
  )
}

export default withRouter(ClientContractList)
