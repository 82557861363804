import classNames from 'classnames'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { Card, Col, NavItem, NavLink, Row } from 'reactstrap'
import toastr from 'toastr'

import CustomSelect from '../../../../components/Forms/CustomSelect/CustomSelect'
import BadgeX from '../../../../components/Table/BadgeX'
import DataTable from '../../../../components/ui/data-table'
import Pagination from '../../../../components/ui/pagination'
import { useFetch } from '../../../../helpers/hooks'
import {
  getAdminCompanies,
  getClientBalancesAdmin,
  getClientBalancesAdminNew,
} from '../../../../services/api'
import { StyledNav } from '../Users/users'
import ClientBalanceAdjustmentModal from './balances-adjustment-client-modal'
import BalancesList from './balances-list'

const adjustmentStatuses = [
  { value: 'pending', label: 'Pending' },
  { value: 'approved', label: 'Approved' },
  { value: 'rejected', label: 'Rejected' },
]
const balanceStatuses = [
  { value: 'pending', label: 'Pending' },
  { value: 'processed', label: 'Processed' },
]
const tabs = [
  { label: 'Requests', value: 0 },
  { label: 'Balances', value: 1 },
]
const statusColor = {
  pending: 'warning',
  approved: 'success',
  rejected: 'danger',
}

const columns = [
  {
    Header: 'TRX ID',
    accessor: 'base_transaction_ref',
  },
  {
    accessor: 'amount',
    Header: 'Amount',
  },
  {
    accessor: 'currency',
    Header: 'Currency',
    Cell: ({ cellData }) => cellData?.code,
  },
  {
    accessor: 'company_name',
    Header: 'Client',
  },
  {
    accessor: 'details',
    Header: 'Details',
  },
  {
    Header: 'Date',
    accessor: 'created_at',
    Cell: ({ cellData }) =>
      format(new Date(cellData * 1000), 'yyyy/MM/dd hh:mm a'),
  },

  {
    accessor: 'status',
    Header: 'Status',
    Cell: ({ cellData: status }) => {
      return (
        <BadgeX
          className={`font-size-14 rounded ${
            status === 'processed'
              ? 'bg-soft-success text-success'
              : 'bg-soft-warning text-warning'
          }`}
          status={statusColor[status]}
        >
          {status}
        </BadgeX>
      )
    },
  },
]
export default function BalancesClients({ location }) {
  const [filters, setFilters] = useState({
    status: adjustmentStatuses[0].value,
    page: 1,
  })
  const [balanceFilters, setBalanceFilters] = useState({
    status: balanceStatuses[0].value,
    page: 1,
  })
  const [activeTab, setActiveTab] = useState(0)

  const {
    data: balances,
    paginator: balancesPaginator,
    startFetch: refreshClientsBalances,
    isLoading: gettingBalances,
  } = useFetch(
    {
      action: getClientBalancesAdmin,
      withAdminAccess: true,
      autoFetch: true,
      body: filters,
      onError: () => {
        toastr.error('Something went wrong. Please try again later.')
      },
    },
    [filters],
  )
  const {
    data: clientBalances,
    paginator: clientBalancesPaginator,
    // isLoading: gettingClientBalancesNew,
  } = useFetch(
    {
      action: getClientBalancesAdminNew,
      withAdminAccess: true,
      autoFetch: true,
      body: balanceFilters,
      onError: () => {
        toastr.error('Something went wrong. Please try again later.')
      },
    },
    [balanceFilters],
  )
  useEffect(() => {
    if (!location.hash) {
      return () => {}
    }
    const tabFromHash = tabs.find((tab) => {
      const label = '#' + tab.label.replace(' ', '-').toLowerCase()
      return label === location.hash
    })?.value

    setActiveTab(tabFromHash)
  }, [location.hash])

  function refreshBalances() {
    refreshClientsBalances(filters)
  }

  function handleChange(value, name) {
    setFilters((prev) => ({ ...prev, [name]: value }))
  }
  function handleBalanceChange(value, name) {
    setBalanceFilters((prev) => ({ ...prev, [name]: value }))
  }

  return (
    <div className='page-content'>
      <div
        className='d-flex justify-content-between align-items-center'
        style={{ marginBottom: '2rem' }}
      >
        <h1 className='mb-0 rp-font-gilroyB'>Balance Clients</h1>

        <ClientBalanceAdjustmentModal refreshData={refreshBalances} />
      </div>

      <Card className='p-3'>
        <Row
          style={{ borderTopLeftRadius: 4, borderTopRightRadius: 4 }}
          className='d-block d-md-none p-3 p-0 m-0 justify-content-between align-items-center border-bottom bg-white'
        >
          <CustomSelect
            name='selectInput'
            value={tabs[activeTab]}
            defaultValue={tabs[0]}
            onChange={(v) => setActiveTab(v.value)}
            options={tabs}
          />
        </Row>
        <Row className='d-none d-md-flex justify-content-between align-items-center m-0 p-3 pt-2'>
          <StyledNav tabs>
            {tabs.map((e, i) => (
              <NavItem key={`user-tab-${i}`} className='bg-white'>
                <NavLink
                  className={classNames(
                    { active: activeTab === i },
                    'font-size-14 px-1',
                  )}
                  style={{ cursor: 'pointer' }}
                  onClick={() => setActiveTab(i)}
                >
                  {e.label}
                </NavLink>
              </NavItem>
            ))}
          </StyledNav>
        </Row>
        {activeTab === 0 && (
          <>
            <BalanceFilters filters={filters} changeFilters={handleChange} />

            <BalancesList
              balances={balances}
              paginator={balancesPaginator}
              loading={gettingBalances}
              refreshData={refreshBalances}
              page={filters.page}
              setPage={(page) => handleChange(page, 'page')}
              isCompany={true}
            />
          </>
        )}
        {activeTab === 1 && (
          <>
            <BalanceFilters
              filters={balanceFilters}
              changeFilters={handleBalanceChange}
              isBalance={true}
            />
            <DataTable columns={columns} data={clientBalances} />

            <div className='d-flex justify-content-end mt-3 px-4'>
              <Pagination
                activePage={balanceFilters.page}
                onChange={(page) => handleBalanceChange(page, 'page')}
                itemsCountPerPage={clientBalancesPaginator?.per_page ?? 50}
                totalItemsCount={clientBalancesPaginator?.total ?? 0}
              />
            </div>
          </>
        )}
      </Card>
    </div>
  )
}

function BalanceFilters({ filters, changeFilters, isBalance }) {
  const statuses = isBalance ? balanceStatuses : adjustmentStatuses
  return (
    <Row>
      <Col md={3} xs={12} className='px-3'>
        <CustomSelect
          value={statuses.find((status) => status.value === filters.status)}
          options={statuses}
          onChange={(option) => changeFilters(option?.value, 'status')}
        />
      </Col>
      <Col md={3} xs={12} className='px-3'>
        {isBalance ? (
          <CompaniesPicker
            changeFilters={(option) =>
              changeFilters(option?.value, 'company_id')
            }
          />
        ) : null}
      </Col>
    </Row>
  )
}
function CompaniesPicker({ changeFilters }) {
  const { data: companiesList } = useFetch({
    autoFetch: true,
    action: getAdminCompanies,
    withAdminAccess: true,
  })
  const companies = companiesList?.map((e) => ({
    label: e.name,
    value: e.id,
  }))
  return (
    <CustomSelect
      options={companies ?? []}
      onChange={changeFilters}
      isClearable
    />
  )
}
