import React from 'react'
import { Link } from 'react-router-dom'

import logoDark from '../../assets/images/identity/logo_main.png'
import globe from '../../assets/images/identity/symbol_main.png'

export function HeaderLogo() {
  return (
    <>
      <img
        style={{ height: '1.5rem', width: 'auto', objectFit: 'contain' }}
        src={globe}
        className='d-md-none'
        alt='remotepass logo'
      />
      <img
        style={{ height: '1.5rem', width: 'auto', objectFit: 'contain' }}
        src={logoDark}
        className='d-none d-md-block'
        alt='remotepass logo'
      />
    </>
  )
}

export default function HeaderLogoLink() {
  return (
    <Link to='/' className='ml-4'>
      <HeaderLogo />
    </Link>
  )
}
