import { AvForm } from 'availity-reactstrap-validation'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Card,
  CardBody,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from 'reactstrap'
import toastr from 'toastr'

import CustomSelect from '../../../components/Forms/CustomSelect/CustomSelect'
import Toggle from '../../../components/Forms/Toggle/Toggle'
import TableH from '../../../components/Table/TableH'
import { StyledH5 } from '../../../components/Typo'
import Button from '../../../components/ui/button'
import UsdSwiftMessage, {
  UsdSwiftMessageContent,
} from '../../../components/usd-swift-message'
import { useFetch, useResize } from '../../../helpers/hooks'
import { getUsers, updateContract } from '../../../services/api'
import RecurringExpenses from '../RecurringExpenses'
import warningIllustration from '../../../assets/images/warning.png'

const Settings = ({ isAdmin, onUpdate, isVisible }) => {
  const contract = useSelector((state) => state.Contract?.details)
  const user = useSelector((state) => state.userProfile?.userProfile)

  return (
    <Card className='p-0 m-0'>
      <CardBody
        className='p-0 m-0'
        style={{
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 1px 0px #DFE1E6',
          borderRadius: 4,
        }}
      >
        <TableH title='Settings' />

        <div className='px-4 pb-4 pt-3 border-bottom'>
          <StyledH5 className='font-size-16 font-weight-bold mb-3'>
            Transfer fees
          </StyledH5>

          <p className='text-muted' style={{ maxWidth: '74ch' }}>
            The transfer fee to receive funds for the remote team member varies
            based on the chosen currency and country. If enabled, the transfer
            fee will be charged to you and displayed on the payment steps.
          </p>

          <UsdSwiftMessagePopup isAdmin={isAdmin} onUpdate={onUpdate} />
        </div>
        {['Fixed', 'Full Time'].includes(contract?.type) && (
          <RecurringExpenses isAdmin={isAdmin} isVisible={isVisible} />
        )}
        <Row className='  mt-md-4 mt-3 p-0 px-4 m-0 border-bottom pb-3'>
          <Col className='pl-0 mb-3 mb-md-0' xs={12} md={10}>
            <div className='mb-3 mb-md-4'>
              <StyledH5 className='font-size-16 font-weight-bold mb-3'>
                VAT
              </StyledH5>
              <p className='text-muted'>
                VAT will be automatically applied to new & unpaid payments.
              </p>

              <VatModal isAdmin={isAdmin} onUpdate={onUpdate} />
            </div>
          </Col>
        </Row>
        {user?.company?.country?.id === 194 && (
          <Row className='  mt-md-4 mt-3 p-0 px-4 m-0 border-bottom pb-3'>
            <Col className='pl-0 mb-3 mb-md-0' xs={12} md={10}>
              <div className='mb-3 mb-md-4'>
                <StyledH5 className='font-size-16 font-weight-bold mb-3'>
                  Withholding tax
                </StyledH5>
                <p className='text-muted'>
                  The set rate will be automatically deducted from each work
                  payment and displayed on the contractor invoice.{' '}
                  <a
                    className='text-primary'
                    target='_blank'
                    rel='noreferrer'
                    href='https://help.remotepass.com/en/articles/5602817-how-to-automatically-deduct-withholding-tax-for-ksa-contracts'
                  >
                    Learn more
                  </a>
                </p>

                <WithHoldingTaxModal onUpdate={onUpdate} isAdmin={isAdmin} />
              </div>
            </Col>
          </Row>
        )}
        <Row className='mt-md-4 mt-3 p-0 px-4 m-0 pb-3'>
          <Col className='pl-0 mb-3 mb-md-0' xs={12} md={10}>
            <div className='mb-3 mb-md-4'>
              <StyledH5 className='font-size-16 font-weight-bold mb-3'>
                Approver
              </StyledH5>
              <Row className='align-items-center justify-content-between w-100 p-0 m-0'>
                <p className='text-muted'>
                  Approver can approve expenses, time off request and submitted
                  work/timesheet
                </p>
              </Row>

              <ApproverModal onUpdate={onUpdate} isAdmin={isAdmin} />
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export const VatModal = ({ onUpdate, isAdmin }) => {
  const [rate, setRate] = useState(null)
  const ref = useRef(null)
  const [editing, setEditing] = useState(false)
  const contract = useSelector((state) => state.Contract?.details)
  const update = useFetch({
    action: updateContract,
    onComplete: () => {
      toastr.success('VAT updated successfully')
      setEditing(false)
      onUpdate()
    },
    onError: toastr.error,
  })
  useEffect(() => {
    setRate(contract?.vat)
  }, [contract])

  const isMobile = useResize()
  return (
    <AvForm
      onValidSubmit={() => {
        if (rate) {
          update.startFetch({
            contract_id: contract?.id,
            vat: rate,
          })
        } else {
          toastr.error('You need to set VAT rate')
        }
      }}
    >
      <Row className='p-0 m-0 align-items-center'>
        <Col
          md={5}
          sm={8}
          xs={12}
          className='p-0 m-0 mr-2 position-relative bg-soft-primary'
        >
          <Input
            placeholder='Enter a percentage value'
            name='rate'
            type='text'
            ref={ref}
            onKeyPress={(e) => {
              if (!/[0-9.]/.test(e.key)) {
                e.preventDefault()
              }
            }}
            disabled={!editing || isAdmin}
            autocomplete='off'
            value={rate ? `${rate}%` : ''}
            onChange={(e) => {
              setRate(e.target.value.replace('%', ''))
            }}
          />
          {!editing && !isAdmin && (
            <a
              className='text-primary font-weight-bold'
              style={{ position: 'absolute', right: 10, bottom: 12 }}
              onClick={() => {
                setEditing(true, () => {
                  ref.current.focus()
                })
              }}
            >
              Edit
            </a>
          )}
        </Col>
        {editing && (
          <button
            className={`btn btn-primary ${isMobile && 'btn-block mt-3'}`}
            type='submit'
          >
            {update.isLoading && (
              <i className='bx bx-loader bx-spin align-middle mr-2' />
            )}
            Apply
          </button>
        )}
      </Row>
    </AvForm>
  )
}

const WithHoldingTaxModal = ({ onUpdate, isAdmin }) => {
  const [rate, setRate] = useState(null)
  const ref = useRef(null)
  const [editing, setEditing] = useState(false)
  const [applyDeduction, setApplyDeduction] = useState(false)
  const contract = useSelector((state) => state.Contract?.details)

  const update = useFetch({
    action: updateContract,
    onComplete: () => {
      toastr.success('Withholding Tax updated successfully')
      setEditing(false)
      onUpdate()
    },
    onError: toastr.error,
  })
  useEffect(() => {
    if (!update.isLoading) {
      setRate(contract?.withholding_tax)
      setApplyDeduction(contract?.withholding_tax_expense)
    }
  }, [contract])

  const applyChanges = (value) => {
    const body = {
      contract_id: contract?.id,
      withholding_tax_expense: value ? 1 : 0,
      withholding_tax: rate,
    }
    update.startFetch(body)
  }

  const isMobile = useResize()

  return (
    <AvForm
      onValidSubmit={() => {
        if (rate) {
          update.startFetch({
            contract_id: contract?.id,
            withholding_tax: rate,
          })
        } else {
          toastr.error('You need to set withholding tax rate')
        }
      }}
    >
      <Row className='align-items-start p-0 m-0'>
        <Col className='p-0 m-0 mr-2' md={5} sm={8} xs={12}>
          <Col className='p-0 m-0 mr-2 position-relative bg-soft-primary'>
            <Input
              placeholder='Enter a percentage value'
              name='withholding_tax'
              type='text'
              ref={ref}
              onKeyPress={(e) => {
                if (!/[0-9.]/.test(e.key)) {
                  e.preventDefault()
                }
              }}
              disabled={!editing || isAdmin}
              autocomplete='off'
              value={rate ? `${rate}%` : ''}
              onChange={(e) => {
                setRate(e.target.value.replace('%', ''))
              }}
            />
            {!editing && !isAdmin && (
              <a
                className='text-primary font-weight-bold'
                style={{ position: 'absolute', right: 10, bottom: 12 }}
                onClick={() => {
                  setEditing(true, () => {
                    ref.current.focus()
                  })
                }}
              >
                Edit
              </a>
            )}
          </Col>
          {!!Number(rate) && (
            <div
              className='custom-control custom-switch align-items-center mt-3'
              dir='ltr'
            >
              <Input
                name='apply_deduction'
                type='checkbox'
                disabled={isAdmin || !rate || update.isLoading}
                className='custom-control-input'
                onChange={(e) => {
                  if (!update.isLoading) {
                    setApplyDeduction(e.target.checked)
                    applyChanges(e.target.checked)
                  }
                }}
                id='customApplyDeduction'
                checked={applyDeduction}
              />
              <label
                className='custom-control-label'
                htmlFor='customApplyDeduction'
              >
                Apply deduction to expense payments.
              </label>
            </div>
          )}
        </Col>
        {editing && (
          <button
            className={`btn btn-primary ${isMobile && 'btn-block mt-3'}`}
            type='submit'
          >
            {update.isLoading && (
              <i className='bx bx-loader bx-spin align-middle mr-2' />
            )}
            Apply
          </button>
        )}
      </Row>
      {!!Number(rate) && (
        <Row className='p-0 m-0 mt-3 text-muted'>
          <i className='font-size-16 bx bx-info-circle mr-1' />
          <p style={{ lineHeight: '16px' }} className='m-0 font-size-12'>
            Make sure your team member is informed about the deduction
          </p>
        </Row>
      )}
    </AvForm>
  )
}

function mapUserToOption(c) {
  const fullName = [c.first_name, c.last_name].filter(Boolean).join(' ')
  const label = fullName?.length > 0 ? fullName : c.email

  return { label, value: c.id }
}

const ApproverModal = ({ onUpdate, isAdmin }) => {
  const [approver, setApprover] = useState(null)
  const [approver2, setApprover2] = useState(null)
  const [showApprover2Select, setShowApprover2Select] = useState(false)
  const contract = useSelector((state) => state.Contract?.details)

  const getApprovers = useApproversList({ isAdmin })

  const { startFetch: updateContractApprover, isLoading: isUpdatingApprovers } =
    useFetch({
      action: updateContract,
      onComplete: () => {
        toastr.success('Approver updated successfully')
        onUpdate()
      },
      onError: (err) => {
        toastr.error(err)
      },
    })

  useEffect(() => {
    if (contract?.approver) {
      setApprover(mapUserToOption(contract?.approver))
    }
    if (contract?.approver2) {
      setApprover2(mapUserToOption(contract?.approver2))
    }
    setShowApprover2Select(!!contract?.approver2)
  }, [contract])

  function handleChangeApprover(approver, index) {
    const body = { contract_id: contract?.id }

    if (index === '1st') {
      body.approver_id = approver.value
      setApprover(approver)
    } else if (index === '2nd') {
      if (!approver) {
        body.approver2_id_removed = 1
      } else {
        body.approver2_id = approver.value
      }
      setApprover2(approver)
    }

    updateContractApprover(body)
  }

  return (
    <div className='d-flex flex-column' style={{ gap: '1rem' }}>
      <CustomSelect
        name='approver'
        value={approver}
        onChange={(e) => handleChangeApprover(e, '1st')}
        isDisabled={isAdmin || isUpdatingApprovers}
        menuPlacement='top'
        options={getApprovers(approver2?.value)}
        wrapperStyles={{ maxWidth: '100%', width: 350 }}
      />

      {showApprover2Select ? (
        <div className='d-flex align-items-center' style={{ gap: '1rem' }}>
          <CustomSelect
            name='approver2'
            value={approver2}
            onChange={(e) => handleChangeApprover(e, '2nd')}
            isDisabled={isAdmin || isUpdatingApprovers}
            menuPlacement='top'
            options={getApprovers(approver?.value)}
            wrapperStyles={{ maxWidth: '100%', width: 350 }}
          />
          <button
            type='button'
            className='rp-btn-nostyle text-primary'
            onClick={() => handleChangeApprover(null, '2nd')}
          >
            Remove
          </button>
        </div>
      ) : (
        <Button
          type='button'
          color='light'
          outline
          onClick={() => setShowApprover2Select(true)}
          style={{ maxWidth: '100%', width: 350 }}
        >
          Set 2nd Approver
        </Button>
      )}
    </div>
  )
}

function useApproversList({ isAdmin = false } = {}) {
  const { data: users, isLoading } = useFetch({
    action: getUsers,
    autoFetch: !isAdmin,
    body: { statuses: ['active', 'invited'] },
    onError: (err) => {
      toastr.error(err)
    },
  })

  function getApprovers(value) {
    if (users?.list?.length <= 0 || isLoading || isAdmin) {
      return []
    }

    const allUsers = users?.list.map(mapUserToOption)
    if (!value) {
      return allUsers
    }

    return allUsers?.filter(({ value: uVal }) => uVal !== value)
  }

  return getApprovers
}

function UsdSwiftMessagePopup({ onUpdate, isAdmin }) {
  const contract = useSelector((state) => state.Contract?.details)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [feeCovered, setFeeCovered] = useState(contract?.fee_covered === 1)

  useEffect(() => {
    setFeeCovered(contract?.fee_covered === 1)
  }, [contract])

  const showUsdSwiftMessage = contract?.currency?.code === 'USD'

  const update = useFetch({
    action: updateContract,
    onComplete: onUpdate,
  })

  function handleFeeCoveredChange(e) {
    const isCheck = e.target.checked

    if (showUsdSwiftMessage && isCheck) {
      openModal()
    } else {
      toggleFee(isCheck)
    }
  }

  function toggleFee(isCheck) {
    setFeeCovered(isCheck)
    if (!update.isLoading) {
      update.startFetch({ contract_id: contract?.id, fee_covered: isCheck })
    }
  }

  function handleToggleFee() {
    toggleFee(true)
    closeModal()
  }

  function closeModal() {
    setIsModalOpen(false)
  }
  function openModal() {
    setIsModalOpen(true)
  }
  function toggleModal() {
    setIsModalOpen((o) => !o)
  }

  return (
    <>
      <label className='align-items-center d-flex mb-0 cursor-pointer mb-4'>
        <Toggle
          check={feeCovered}
          change={handleFeeCoveredChange}
          disabled={isAdmin || update.isLoading}
        />
        <span>Client will take in-charge the transfer fees.</span>
      </label>

      <UsdSwiftMessage showMessage={showUsdSwiftMessage && feeCovered} />

      <Modal
        style={{ maxWidth: 527 }}
        isOpen={isModalOpen}
        toggle={toggleModal}
        centered
      >
        <ModalBody className='px-4 py-5'>
          <img
            src={warningIllustration}
            alt=''
            style={{ width: 160 }}
            className='d-block mx-auto mb-2'
          />
          <div className='h2 text-gray-h rp-font-gilroyB text-center rp-font-light mb-4'>
            Important Note
          </div>
          <p className='font-size-16 mb-0 text-gray-h'>
            <UsdSwiftMessageContent />
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color='light'
            outline
            style={{ minWidth: 124 }}
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            type='button'
            style={{ minWidth: 144 }}
            onClick={handleToggleFee}
          >
            I agree
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default Settings
