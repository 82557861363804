import cx from 'classnames'
import React from 'react'

import CardButton from './card-button'

export default function CardButtons({
  value,
  onChange,
  options,
  className,
  style,
  horizontal,
}) {
  return (
    <div className={cx('', className)} style={style}>
      {options.map((option) => {
        const {
          label,
          description,
          icon,
          value: optionValue,
          isNew,
          isDisabled,
          id,
        } = option

        return (
          <CardButton
            key={optionValue}
            label={label}
            id={id}
            description={description}
            icon={icon?.(optionValue === value)}
            isActive={optionValue === value}
            onClick={() => onChange(option)}
            horizontal={horizontal}
            isNew={isNew}
            isDisabled={isDisabled}
          />
        )
      })}
    </div>
  )
}
