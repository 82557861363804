import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import toastr from 'toastr'

import { StyledH6 } from '../../../components/Typo'
import { addContractTag } from '../../../services/api'
import TagsDropdown from './TagsDropdown'

const MAX_TAG_ERROR = 'Max of 2 tags per contract reached.'

const Tags = ({
  tags,
  tagsList,
  contract,
  onUpdate,
  deleteTagTotally,
  updateTags,
  setNewContract,
  contractsList,
}) => {
  const user = useSelector((state) => state?.Account?.user)
  const [showActions, setShowActions] = useState(false)
  const [elementTags, setElementTags] = useState(tags)
  const handleDelete = (data) => {
    const newTags = [...elementTags]
    const index = newTags.findIndex((e) => e.id === data.id)
    newTags.splice(index, 1)
    setElementTags(newTags)
    setShowActions(false)
  }
  const addTagData = (value) => {
    if (elementTags?.length < 2 && value && value !== '') {
      addContractTag(user?.token, { contract_id: contract.id, tag: value })
        .then((r) => {
          if (r.data.success) {
            updateTags()
            setElementTags(r.data.data.contract_tags)
            setShowActions(false)
          }
        })
        .catch((e) => {
          toastr.error(e)
        })
    } else {
      toastr.error(MAX_TAG_ERROR)
    }
  }
  useEffect(() => {
    setElementTags(tags)
  }, [tags])

  const actions = tagsList?.map((e, i) => {
    return {
      // eslint-disable-next-line react/display-name
      component: () => (
        <div className='d-flex align-items-center justify-content-between'>
          <div
            style={{
              padding: '4px 6px',
              backgroundColor: e?.color ? e.color : 'rgb(250, 222, 200)',
              borderRadius: '4px',
            }}
          >
            <StyledH6
              style={{
                margin: 'auto',
                fontSize: '12px',
                fontFamily: 'inter',
                color: '#1C1C1C',
                fontWeight: '400',
              }}
            >
              {e.name}
            </StyledH6>
          </div>
          <i
            className='bx bx-trash text-danger font-size-16'
            style={{ zIndex: '1009' }}
            onClick={() => deleteTagTotally(e)}
          />
        </div>
      ),
      onClick: () => {
        if (elementTags.filter((elm) => elm.name === e?.name).length === 0) {
          if (elementTags.length < 2) {
            addContractTag(user?.token, {
              contract_id: contract.id,
              tag: e.name,
            })
              .then((r) => {
                if (r.data.success) {
                  setElementTags(r.data.data.contract_tags)
                }
              })
              .catch((e) => {
                toastr.error(toastr)
              })
          } else {
            toastr.error(MAX_TAG_ERROR)
          }
        }
      },
    }
  })
  return (
    <TagsDropdown
      toggle={() => {
        setShowActions((t) => !t)
      }}
      isOpen={showActions}
      options={actions}
      elementTags={elementTags}
      contract={contract}
      onUpdate={onUpdate}
      handleDelete={handleDelete}
      addTagData={addTagData}
    />
  )
}

export default Tags
