import cx from 'classnames'
import React from 'react'
import { Controller } from 'react-hook-form'
import { Input } from 'reactstrap'

import { getInputErrorMessage } from '../Forms/get-input-error-message'
import InputFeedback from '../ui/input-feedback'

export default function ControlledCheckbox({
  name,
  id,
  control,
  label,
  defaultChecked,
  transform,
  className,
  error,
  style,
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => {
        function handleChange(e) {
          onChange(e.target.checked)
        }

        return (
          <>
            <div
              className={cx('custom-control custom-switch d-flex', className)}
              style={style}
            >
              <Input
                type='checkbox'
                name={name}
                id={id}
                defaultChecked={defaultChecked}
                checked={transform?.input ? transform.input(value) : value}
                onChange={(e) =>
                  transform?.output
                    ? handleChange(transform.output(e))
                    : handleChange(e)
                }
                onBlur={onBlur}
                className='custom-control-input sr-only'
              />

              <label
                className='custom-control-label user-select-none flex-grow-1'
                htmlFor={id}
              >
                <span>{label}</span>
              </label>
            </div>

            {!error ? null : (
              <InputFeedback className='mt-1'>
                {getInputErrorMessage(error)}
              </InputFeedback>
            )}
          </>
        )
      }}
    />
  )
}
