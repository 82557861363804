import moment from 'moment'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Container, Row, Spinner } from 'reactstrap'
import styled from 'styled-components'
import toastr from 'toastr'

import NoContent from '../../components/NoContent'
import { useFetch } from '../../helpers/hooks'
import {
  getInsuranceOverview,
  getInsurancePlans,
  getContractorActiveInsuranceAllowances,
  getContractorInfo,
} from '../../services/api'
import Deductible from './../../assets/images/deductible.svg'
import Dental from './../../assets/images/dental.svg'
import FamilyMembers from './../../assets/images/family_members.svg'
import Maternity from './../../assets/images/maternity.svg'
import Outpatient from './../../assets/images/outpatient.svg'
import Vision from './../../assets/images/vision.svg'
import AddOnCard from './components/AddOnCard'
import InsuranceForm from './components/InsuranceForm'
import InsurancePayments from './components/InsurancePayments'
import InsuranceStatus from './components/InsuranceStatus'
import Plan from './components/Plan'
import QuestionItem from './components/QuestionItem'

import remoteHealth from '../../assets/images/remote-health-safety-wing.png'
import welcomeImage from '../../assets/images/welcome.svg'

const Insurance = () => {
  const user = useSelector((state) => state?.userProfile?.userProfile)
  const [ages, setAges] = useState(null)
  const [isValidSubmission, setIsValidSubmission] = useState(false)
  const data = useRef(null)
  const plans = useFetch({
    action: getInsurancePlans,
    onError: () => setIsValidSubmission(false),
    onComplete: () => setIsValidSubmission(true),
  })
  useFetch({
    action: getContractorInfo,
    autoFetch: true,
    onComplete: (data) => {
      if (data?.has_insurance_allowance === true) {
        insuranceAllowancePlans.startFetch()
      }
    },
  })
  const insuranceAllowancePlans = useFetch({
    action: getContractorActiveInsuranceAllowances,
    autoFetch: false,
  })
  const overView = useFetch({
    action: getInsuranceOverview,
    autoFetch: true,
  })

  const onSubmit = (v) => {
    let age = ''
    if (v?.age < 18) {
      age = '0-17'
    } else if (v?.age < 40) {
      age = '18-39'
    } else if (v?.age < 50) {
      age = '40-49'
    } else if (v?.age < 60) {
      age = '50-59'
    } else if (v?.age <= 74) {
      age = '60-74'
    } else {
      toastr.error(
        `Unfortunately, the age entered is outside the supported age range`,
      )
      return
    }

    const newAges = v?.ages?.length ? [age, ...(v?.ages ?? [])] : [age]
    setAges(newAges)
    plans.startFetch({ ages: newAges, country_id: v?.country_id })
    data.current = v
  }

  const onError = (errorMessage) => {
    setIsValidSubmission(false)
    toastr.error(errorMessage)
  }

  const getPlanCardWidth = (plansCount) => {
    switch (plansCount) {
      case 1:
        return 12
      case 2:
        return 6
      case 3:
        return 4

      default:
        return 4
    }
  }

  return (
    <div className='page-content'>
      {user?.has_active_contract ||
      overView.data?.details?.status === 'active' ? (
        <Container fluid className='p-0 m-0' style={{ minHeight: '75vh' }}>
          {overView.isLoading ? (
            <Col style={{ minHeight: '90vh' }}>
              <Row
                style={{ minHeight: '90vh' }}
                className='justify-content-center align-items-center'
              >
                <Spinner type='grow' color='primary' />
              </Row>
            </Col>
          ) : (
            <>
              <Row className='p-0 m-0 justify-content-between align-items-center'>
                <h1
                  style={{ color: 'var(--gray-h)', marginBottom: 14 }}
                  className='rp-font-gilroyB font-size-32'
                >
                  Remote Health
                </h1>
                <img height={48} src={remoteHealth} alt='' />
              </Row>
              <h2
                style={{ marginBottom: 32, fontWeight: '400' }}
                className='text-secondary font-size-16'
              >
                Use Remote Health at any private or public hospital, or doctor,
                in the world - no matter where you live or travel.{' '}
              </h2>

              {overView.data?.details?.status === 'pending' ? (
                <NoContent
                  image={welcomeImage}
                  subtitle='You will receive an email invitation from our partner Remote Health by SafetyWing with a link to complete your registration and will be notified once your plan is activated.'
                  headline={'Your request has been submitted successfully'}
                  onAction={() => {}}
                />
              ) : (
                <>
                  {['active', 'inactive'].includes(
                    overView.data?.details?.status,
                  ) ? (
                    <InsuranceStatus
                      status={overView.data?.details?.status}
                      number={overView.data?.details?.policy_number}
                      dependents={overView.data?.details?.dependents}
                      date={moment(
                        overView.data?.details?.start_date * 1000,
                      ).format('DD/MM/YYYY')}
                      data={overView.data}
                      refreshData={() => overView.startFetch(null, false)}
                    />
                  ) : (
                    <InsuranceForm
                      onSubmit={onSubmit}
                      onError={onError}
                      loading={plans.isLoading}
                    />
                  )}

                  {['active', 'inactive'].includes(
                    overView.data?.details?.status,
                  ) ? (
                    <>
                      {overView.data?.payments?.length && (
                        <InsurancePayments
                          payments={overView?.data?.payments}
                          planDetails={overView?.data?.details}
                          onUpdate={() => overView.startFetch(null, false)}
                          currency={'USD'}
                          price={130}
                          nextBilling={overView.data?.details?.next_billing}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {plans.data?.country_not_supported && (
                        <Card className='bg-white rounded p-3'>
                          <NoContent
                            image={welcomeImage}
                            subtitle={' '}
                            headline={`${plans?.data?.country_name} is not supported yet`}
                            onAction={() => {}}
                          />
                        </Card>
                      )}
                      {isValidSubmission && !!plans.data?.length && (
                        <Row className='mb-5'>
                          {React.Children.toArray(
                            plans.data.map((e) => (
                              <Col
                                key={`plan-${e?.id}`}
                                md={getPlanCardWidth(plans.data?.length)}
                                sm={12}
                                className='pr-2'
                              >
                                <Plan
                                  country={data.current?.country_id}
                                  ages={ages}
                                  plan={e}
                                  title={e.name}
                                  desc={e.desc}
                                  features={e.conditions}
                                  price={e.amount}
                                  dependents={ages}
                                  currency={e.currency?.code}
                                  onUpdate={() =>
                                    overView.startFetch(null, false)
                                  }
                                  insuranceAllowance={
                                    insuranceAllowancePlans?.data?.details
                                      ?.plan_id === e.id
                                      ? insuranceAllowancePlans?.data
                                      : null
                                  }
                                />
                              </Col>
                            )),
                          )}
                        </Row>
                      )}
                    </>
                  )}
                </>
              )}

              <h1 className='text-dark rp-font-gilroyB mb-3 mt-5'>
                Remote Health Benefits
              </h1>
              <Card className='bg-white rounded p-3'>
                <div
                  className='d-flex flex-wrap'
                  style={{ '--grid-gap': '1rem', gap: 'var(--grid-gap)' }}
                >
                  {React.Children.toArray(
                    [
                      {
                        title: 'Outpatient',
                        icon: (
                          <img
                            src={Outpatient}
                            alt=''
                            style={{ width: 32, height: 32 }}
                          />
                        ),
                        desc:
                          'Outpatient care is when you need medical services outside of being admitted to the hospital. \n' +
                          '\n' +
                          '$5,000 limit with a 10% co-pay, so for a $100 appointment you get reimbursed $90.\n',
                      },
                      {
                        title: 'Dental',
                        icon: (
                          <img
                            src={Dental}
                            alt=''
                            style={{ width: 32, height: 32 }}
                          />
                        ),
                        desc: '$1,500 USD in dental coverage for when you need to see a dentist for routine care such as check-ups, fillings etc. or more complex care like repairing or receiving new crowns, dentures, or inlays.',
                      },
                      {
                        title: 'Maternity',
                        icon: (
                          <img
                            src={Maternity}
                            alt=''
                            style={{ width: 32, height: 32 }}
                          />
                        ),
                        desc: 'Covers medically necessary costs incurred during pregnancy and childbirth up to US$7,500 (with a 20% co-insurance) including pre and post-natal check-ups for up to 30 days following discharge.',
                      },
                      {
                        title: '$0 deductible',
                        icon: (
                          <img
                            src={Deductible}
                            alt=''
                            style={{ width: 32, height: 32 }}
                          />
                        ),
                        desc: 'Your deductible determines how much you must pay out of pocket before receiving reimbursement for claims within one year.',
                      },
                      {
                        title: 'Vision',
                        icon: (
                          <img
                            src={Vision}
                            alt=''
                            style={{ width: 32, height: 32 }}
                          />
                        ),
                        desc: '$500 USD for things like eye exams, frames, and contact lenses.',
                      },
                      {
                        title: 'Family members',
                        icon: (
                          <img
                            src={FamilyMembers}
                            alt=''
                            style={{ width: 32, height: 32 }}
                          />
                        ),
                        desc: 'Add your spouse, partner or child for additional premiums.',
                      },
                    ].map((e, index) => (
                      <CardItemWrapper key={index}>
                        <AddOnCard
                          title={e.title}
                          desc={e.desc}
                          icon={e.icon}
                        />
                      </CardItemWrapper>
                    )),
                  )}
                </div>
              </Card>
              <h1 className='text-dark rp-font-gilroyB mb-3 mt-5'>
                Questions and Answers
              </h1>
              {React.Children.toArray(
                [
                  {
                    title: 'Are there any countries that can’t be covered?',
                    component: <CountriesCoverd />,
                  },
                  {
                    title:
                      'Does Remote Health have any benefits if I live somewhere with public healthcare?',
                    component: <RemoteHealth />,
                  },
                  { title: 'Do you cover COVID-19?', component: <Covid /> },
                  {
                    title: 'How does payment work if I need to see a doctor?',
                    component: <PaymentWork />,
                  },
                  {
                    title: 'Where do I use the insurance?',
                    component: <InsuranceUse />,
                  },
                  {
                    title: 'Do you cover pre-existing conditions?',
                    component: <ExistingConditions />,
                  },
                ].map((e, index) => (
                  <QuestionItem
                    title={e.title}
                    component={e.component}
                    key={index}
                  />
                )),
              )}
            </>
          )}
        </Container>
      ) : (
        <Container fluid className='p-0 m-0' style={{ minHeight: '75vh' }}>
          <NoContent
            image={welcomeImage}
            subtitle={
              <div style={{ maxWidth: '62ch', margin: '0 auto' }}>
                <div className='mb-3'>
                  You need to have an active contract to access Remote Health.
                </div>
                <div>
                  Remote Health offers a premium medical coverage with
                  0-deductible at any private or public hospital, or doctor, in
                  the world.
                </div>
              </div>
            }
            headline={'Remote Health'}
            onAction={() => {}}
          />
        </Container>
      )}
    </div>
  )
}

const PaymentWork = () => (
  <div className='p-4'>
    <p>
      You have two options for payment, based on your preference and situation:
    </p>

    <h5>Option 1: Direct billing</h5>
    <p>
      We provide direct billing for in-patient and day-patient treatment
      (admitted to hospital). This means that SafetyWing handles all payments
      directly with the hospital, so you don’t have to pay out of pocket. Direct
      billing is available for both emergency admissions or when you have an
      upcoming planned procedure. To arrange direct billing call or email the
      claims department.
    </p>
    <p>
      Direct billing can be supported with any hospital/doctor in the link list.
      Here’s a{' '}
      <a
        className='text-primary'
        href='https://drive.google.com/file/d/1t3OmyFS52UsAODkByl8_LvGoYCqXVgRX/view'
      >
        list of hospitals
      </a>{' '}
      we have used direct billing with in the past.
    </p>

    <h5>Option 2: Pay out of pocket and submit a claim</h5>
    <p>
      You can pay out of pocket for your visit and then submit a claim for
      reimbursement online. Any claims under $750 are reimbursed within 48
      hours. Claims over $750 are reimbursed within 10 to 20 days.
    </p>
  </div>
)
const InsuranceUse = () => (
  <div className='p-4'>
    <p>
      You can get treated at any registered hospital in the world - public or
      private. Some exceptions apply.
    </p>
    <p>
      You can also use any medical practitioner (e.g. physiotherapist, massage
      therapist) in the world since you will have the premium outpatient add-on.
    </p>
  </div>
)
const ExistingConditions = () => (
  <div className='p-4'>
    <p>Yes.</p>
  </div>
)
const RemoteHealth = () => (
  <div className='p-4'>
    <p>
      Yes! Here is how Remote Health would work depending on your local
      healthcare:
    </p>
    <h5>In countries with public systems (for example; the UK, Norway)</h5>
    <ul>
      <li>
        Visit any private hospital for the best and quickest service. Skipping
        lines means getting healthy faster and returning to work quicker.
      </li>
      <li>
        If your hospital treatment is covered by your public healthcare system,
        your receive a $250 cash bonus per night {"you're"} in a public hospital
        in your home country.
      </li>
      <li>
        Access treatments that are typically not provided by standard public
        healthcare, for example the latest available cancer treatment regimens.
      </li>
      <li>
        Use this coverage wherever you live, move, or work so you if you are a
        nomad or traveling you don’t need another insurance to cover your
        health.
      </li>
    </ul>
    In countries without public systems (for example: Vietnam) Get coverage at
    the best hospitals in that country or any other country in the world.
    Without it you would pay out of pocket for any medical care.
  </div>
)
const Covid = () => (
  <div className='p-4'>
    <p>Yes, Remote Health covers for treatments related to COVID-19</p>
  </div>
)

const CountriesCoverd = () => (
  <div className='p-4'>
    <p>
      Once you are covered you can go anywhere in the world on long or short
      trips (with the exception of a 30 day limit in the US, SG, and HK). There
      are some countries local citizens cannot be covered by Remote Health due
      to local regulations, notably:
    </p>
    <Row>
      <Col md={4}>
        <ul>
          <li>United Arab Emirates</li>
          <li>American Samoa</li>
          <li>Belarus</li>
          <li>Cuba</li>
          <li>Democratic Republic of Congo</li>
          <li>Ecuador</li>
        </ul>
      </Col>
      <Col md={4}>
        <ul>
          <li>Iran</li>
          <li>North Korea</li>
          <li>North Sudan</li>
          <li>Puerto Rico</li>
          <li>Saudi Arabia</li>
          <li>Somalia</li>
        </ul>
      </Col>
      <Col md={4}>
        <ul>
          <li>South Sudan</li>
          <li>Syria</li>
          <li>US Guam</li>
          <li>US Virgin Islands</li>
          <li>Zimbabwe</li>
        </ul>
      </Col>
    </Row>
  </div>
)

const Card = styled.div`
  box-shadow: 0px 1px 0px #dfe1e6;
`

const CardItemWrapper = styled.div`
  --col-number: 1;

  flex-basis: calc(
    calc(100% - calc(var(--grid-gap) * calc(var(--col-number) - 1))) /
      var(--col-number)
  );

  @media (min-width: 576px) {
    --col-number: 2;
  }

  @media (min-width: 992px) {
    --col-number: 3;
  }
`

export default Insurance
